export enum EStatusKey {
  isGDPR_Only_Required = 'isGDPR_Only_Required',
  isRegulated_Only_Required = 'isRegulated_Only_Required',
  isNon_Regulated_Only_Required = 'isNon_Regulated_Only_Required',
  isNon_Regulated_Field = 'isNon_Regulated_Field',
  isField_Instance = 'isField_Instance',
  isRegulated_Field = 'isRegulated_Field',
  isRegulated = 'isRegulated',
  isNonRegulated = 'isNonRegulated',
  isHideShow = 'isHideShow',
  isGDPR_Required = 'isGDPR_Required',
  isObjectDefinitionRegAndNonRegulated = 'isObjectDefinitionRegAndNonRegulated'
}

export const safeguardsConfig: {
  key: EStatusKey;
  label: string;
  tooltip: string;
  column: number;
}[] = [
  {
    key: EStatusKey.isGDPR_Only_Required,
    label: 'Is GDPR Only Required',
    tooltip: `Check if Rule Should Be GDPR Only (Don't Use If Contains Any Other Rules)`,
    column: 1
  },
  {
    key: EStatusKey.isRegulated_Only_Required,
    label: 'Is Regulated Only Required',
    tooltip: `Check if Regulated Only is required (Don't Use If Contains Any Other Rules)`,
    column: 1
  },
  {
    key: EStatusKey.isNon_Regulated_Only_Required,
    label: 'Is Non-Regulated Only Required',
    tooltip: `Check if Non-Regulated Only is required (Don't Use If Contains Any Other Rules)`,
    column: 1
  },
  {
    key: EStatusKey.isNon_Regulated_Field,
    label: 'Is Non-Regulated Field',
    tooltip:
      'Check if Non-Regulated is required (Will block any Regulated rules if deal is non-regulated)',
    column: 2
  },
  {
    key: EStatusKey.isRegulated_Field,
    label: 'Is Regulated Field',
    tooltip:
      'Check if this is a regulated field (Will block any Non-Regulated fields if deal is regulated)',
    column: 2
  },
  {
    key: EStatusKey.isField_Instance,
    label: 'Is FieldInstance',
    tooltip:
      'Check if this is a field instance (Only use if what we are looking for is a field instance)',
    column: 2
  },
  {
    key: EStatusKey.isGDPR_Required,
    label: 'Is GDPR Required',
    tooltip: 'Check if GDPR is required',
    column: 2
  },
  {
    key: EStatusKey.isRegulated,
    label: 'Is Regulated',
    tooltip:
      'Check if this is regulated (This will hide the rule for non-regulated deals)',
    column: 3
  },
  {
    key: EStatusKey.isNonRegulated,
    label: 'Is Non-Regulated',
    tooltip:
      'Check if this is non-regulated (This will hide the rule for regulated deals)',
    column: 3
  },
  {
    key: EStatusKey.isHideShow,
    label: 'Is HideShow',
    tooltip:
      'Check if the FieldInstance is a HideShow (This will check to see if it is a no or yes then show or hide)',
    column: 3
  },
  {
    key: EStatusKey.isObjectDefinitionRegAndNonRegulated,
    label: 'ObjectDefinition For Reg/Non-Reg',
    tooltip:
      'Allows to add both a Regulated & Non-Regulated Object Definition If Needed',
    column: 4
  }
];
