import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import firebase from 'firebase';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';

export const useColumnSaver = (Id, saveJSON) => {
  const dispatch = useDispatch();

  return useMemo(() => {
    const updateSaveLayout = async (columnLayout, Id) => {
      return firebase
        .firestore()
        .collection('userAccount')
        .doc(Id.toString())
        .update({ column_layout: columnLayout });
    };

    const handleColumnSaving = () => {
      const stringId = Id.toString();
      if (saveJSON.length === 0) return;
      if (saveJSON !== undefined) {
        updateSaveLayout(JSON.stringify(saveJSON), stringId);
        dispatch(
          createNotification(successNotif(`Successfully Saved Table Layout`))
        );
      }
    };

    return { handleColumnSaving };
  }, [Id, saveJSON, dispatch]);
};
