import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Line } from 'react-chartjs-2';
import fecha from 'fecha';
import dummydata from './dummydata';
import Button from '@material-ui/core/Button';
import { triggerBase64Download } from 'react-base64-downloader';
import * as gtag from 'Utils/gtag';

const CashFlowChart = (props) => {
  const theme = useTheme();
  const chartRef = React.useRef(null);
  let cashflow = props.data;
  if (cashflow === undefined) {
    cashflow = dummydata;
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };

  let date = [];
  let receipt = [];
  let balance = [];
  let interest = [];
  let commission = [];
  let costOfCreditRemaining = [];

  cashflow.forEach((item) => {
    const dateconversion = fecha.parse(
      item.ActivityDate,
      'YYYY-MM-DDTHH:mm:ss'
    );
    if (dateconversion) date.push(fecha.format(dateconversion, 'mediumDate'));
    receipt.push(item.Receipt);
    balance.push(item.Balance);
    interest.push(item.Interest);
    commission.push(item.Commission);
    costOfCreditRemaining.push(item.CostOfCreditRemaining);
  });

  let data = {
    labels: date,
    datasets: [
      {
        label: 'Reciepts',
        data: receipt,
        borderWidth: 1,
        backgroundColor: `${theme.palette.primary.maintrans}`,
        borderColor: ''
      },
      {
        label: 'Remaining Credit',
        data: costOfCreditRemaining,
        borderWidth: 1,
        backgroundColor: `${theme.palette.primary.lighttrans}`,
        borderColor: ''
      },
      // {
      //   label: 'Balance',
      //   data: balance,
      //   backgroundColor: `${theme.palette.primary.lighttrans}`,
      //   borderColor: ''
      // },
      {
        label: 'Interest',
        data: interest,
        backgroundColor: `${theme.palette.primary.darktrans}`,
        borderColor: ''
      }
      // {
      //   label: 'Commission',
      //   data: commission,
      //   backgroundColor: `${theme.palette.success.main}`,
      //   borderColor: ''
      // }
    ]
  };

  const legend = {
    display: true,
    position: 'top',
    fullWidth: true
  };

  if (props.display === 'clientView')
    data.datasets = data.datasets.filter((i) => i.label !== 'Commission');

  const download = () => {
    if (chartRef && chartRef.current) {
      const base64Image = chartRef.current.chartInstance.toBase64Image();
      triggerBase64Download(base64Image, 'Calculation');
      gtag.event({
        action: 'Cashflow Chart Downloaded',
        feature: 'CALCULATOR',
        message: 'user downloaded cashflow chart'
      });
    }
  };

  return (
    <div>
      <Line data={data} legend={legend} options={options} ref={chartRef} />
      <Button onClick={download}>download</Button>
    </div>
  );
};

export default CashFlowChart;
