import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
// import WarningIcon from '@material-ui/icons/Warning';
import { useTheme } from '@material-ui/core/styles';

const Xpansion = ({
  summary,
  children,
  selected,
  icon,
  style,
  defaultExpanded,
  className
}) => {
  const theme = useTheme();
  return (
    <Accordion
      className={className}
      defaultExpanded={defaultExpanded}
      style={style}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {icon && (
          <div>
            {selected && (
              <CheckIcon
                style={{ color: theme.palette.success.main, marginRight: 10 }}
              />
            )}
          </div>
        )}

        <h4>{summary}</h4>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default Xpansion;
