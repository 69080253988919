import { makeStyles } from '@material-ui/core';

export const useCalendarStyles = makeStyles((theme) => ({
  calendar: {
    '& .fc-button, & .fc-dayGridMonth-button, & .fc-timeGridWeek-button, & .fc-timeGridDay-button, & .fc-listWeek-button':
      {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:focus': {
          outline: 'none',
          boxShadow: 'none'
        }
      },
    '& .fc-monthButton-button': {
      marginLeft: theme.spacing(1)
    },
    '& .fc-multiMonthButton-button': {
      marginLeft: theme.spacing(1)
    }
  },

  selected: {
    backgroundColor: theme.palette.success.light,
    border: `2px solid  ${theme.palette.success.main} !important`
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  select: {
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  }
}));
