import axios from 'axios';

import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { IStats, INITIAL_STATS } from '../types';

const splitArrayIntoChunks = (
  array: string[],
  chunkSize: number,
  maxChunks: number
) => {
  const result: string[][] = [];
  let chunkCount = 0;

  for (let i = 0; i < array.length && chunkCount < maxChunks; i += chunkSize) {
    const chunk = array.slice(i, i + chunkSize);
    result.push(chunk);
    chunkCount++;
  }

  return result;
};

export const fetchCampaignsStats = async (
  emailIds: string[]
): Promise<IStats> => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    // NOTE: maxChunks is 6 meaning a max of 6 requests will be made (up to 300 campaigns)
    const emailIdChunks = splitArrayIntoChunks(emailIds, 50, 6);

    const promises = emailIdChunks.map((emailIds) =>
      axios.get(
        `${process.env.REACT_APP_PROXY}/marketing/campaigns-stats?ids=${emailIds}`,
        { headers: { token } }
      )
    );

    const results = await Promise.all(promises);

    const stats: IStats[] = results
      .map((res) => res.data.results?.map((r) => r.stats))
      .flat()
      .filter(Boolean);

    const totalStats: IStats = stats.reduce((acc, curr) => {
      return {
        bounce_drops: acc.bounce_drops + curr.bounce_drops,
        bounces: acc.bounces + curr.bounces,
        clicks: acc.clicks + curr.clicks,
        unique_clicks: acc.unique_clicks + curr.unique_clicks,
        delivered: acc.delivered + curr.delivered,
        invalid_emails: acc.invalid_emails + curr.invalid_emails,
        opens: acc.opens + curr.opens,
        unique_opens: acc.unique_opens + curr.unique_opens,
        requests: acc.requests + curr.requests,
        spam_report_drops: acc.spam_report_drops + curr.spam_report_drops,
        spam_reports: acc.spam_reports + curr.spam_reports,
        unsubscribes: acc.unsubscribes + curr.unsubscribes
      };
    }, INITIAL_STATS);

    return totalStats;
  } catch (e) {
    BugTracker.notify(e);

    throw e;
  }
};
