import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * FILE EXPLORER API
 */
export const GetFileExplorer = async ({ action, path, responseType }) => {
  let url = `FileExplorerAPI.ashx`;
  if (action) url = `FileExplorerAPI.ashx?action=${action}&path=${path}`;
  const method = 'get';
  responseType = responseType ? responseType : '';
  const config: AxiosRequestConfig = { url, method, responseType };
  return await dbQueue({ config, url });
};
