import React from 'react';
import { CreateUserButton } from 'components/User/CreateAsUserButton';
import { Button, CircularProgress } from '@material-ui/core';

import { ICreation } from '../functions/interfaces';
import { HandleSupplier, HandleDisabled } from '../components/index';

import { ISupplier } from './Utils';

export const CreationButton = (creation: ICreation) => {
  const {
    loadnewuser,
    is_Company,
    is_Director,
    data,
    NotificationLog,
    newUser,
    userType,
    UserDefinition,
    isValidCompany,
    selectedRadio,
    automationValues,
    createUser,
    setNotificationLog,
    setCreatedUserInstanceId
  } = creation;

  const handleSupplier: ISupplier | any = HandleSupplier({
    UserDefinition,
    isValidCompany
  });

  if (loadnewuser) return <CircularProgress />;
  if (handleSupplier.isValidShortCode) return <div />;

  if (is_Company || is_Director) {
    if (data?.data?.type === 'company' || data?.data?.type === 'directorship') {
      return (
        <CreateUserButton
          automation={automationValues}
          setNotificationLog={setNotificationLog}
          NotificationLog={NotificationLog}
          ProcessDefinitionId={undefined}
          data={
            automationValues
              ? automationValues.company.third_party_apis.redflagOG.data
              : data?.data
          }
          directorships={undefined}
          loading={false}
          setOpen={function (open: boolean): void {
            throw new Error('Function not implemented.');
          }}
          open={false}
          company={
            is_Company
              ? automationValues
                ? automationValues.company.third_party_apis.redflagOG
                : data
              : undefined
          }
          downloadPDF={function (): void {
            throw new Error('Function not implemented.');
          }}
          selectCreated={(id: string) => setCreatedUserInstanceId(parseInt(id))}
          UserDefinition={UserDefinition}
        />
      );
    }
  } else {
    return (
      <Button
        data-cy="create-sole-trader-btn"
        disabled={Boolean(
          HandleDisabled(
            userType,
            newUser,
            is_Company,
            is_Director,
            data,
            selectedRadio
          )
        )}
        color="primary"
        onClick={createUser}
        variant="contained">
        Create
      </Button>
    );
  }
  return <div />;
};
