import { Grid } from '@material-ui/core';
import { FormTabs } from '../Forms/FormTabs';
import { FormContent } from '../Forms/FormContent';
import { XpansionIsolated } from 'common/Xpansion';
import DetailView from '../../../Overview/components/DetailView';
import Attachments from '../../../Overview/components/AttachmentsLog';
import Documents from '../../../Documents';
import StepDocumentHTML from '../../../Documents/StepDocumentHTML';
import { Instructions } from './SystemUserView';
import { useMobile } from 'hooks';

export const DefaultLandingPageView = () => {
  const isMobile = useMobile();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Instructions />
      </Grid>

      {/* <Grid item style={{ overflow: 'auto' }} xs={12}>
        <StepDocumentHTML downloadableIcon />
      </Grid> */}

      {/* <Grid item xs={isMobile ? 12 : 6}>
        <XpansionIsolated expanded summary="Document">
          <Grid container direction="column">
            <Grid item>
              <Documents />
            </Grid>
            <Grid item style={{ overflow: 'auto' }}>
              <StepDocumentHTML />
            </Grid>
          </Grid>
        </XpansionIsolated>
      </Grid> */}

      <Grid item xs={isMobile ? 12 : 12}>
        <XpansionIsolated
          expanded
          summary="Please expand this section and fill in the forms">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormTabs />
            </Grid>
            <Grid item>
              <FormContent />
            </Grid>
          </Grid>
        </XpansionIsolated>
      </Grid>
    </Grid>
  );
};
