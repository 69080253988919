import { CalculatorOutput } from 'redux/database';
import {
  CompleteObjectDefinitionDict,
  CompleteObjectInstanceDict
} from 'types/interfaces';

interface Props {
  ProcessInstanceId: number;
}

interface ILpc {
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
}

type Return = ILpc | null;

export const getCalculatorObjectsForProcess: ({
  ProcessInstanceId
}: Props) => Promise<Return> = async ({ ProcessInstanceId }) => {
  const res = await CalculatorOutput({ ProcessInstanceId });
  if (res?.status === 200) return res.data;
  return null;
};
