interface IOptions {
  message?: string;
}

const checked = (
  value: boolean,
  options: IOptions = {}
): string | undefined => {
  if (value !== true) {
    return options.message || 'must be checked';
  }
};

export default {
  checked
};
