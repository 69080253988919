import React from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import { TransitionProps } from '@material-ui/core/transitions';
import { Header } from '../../Header';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<any>
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

interface IProps {
  handleClose: () => void;
  open: boolean;
}

const AlertDialog = withStyles(styles)((props: IProps) => {
  const dispatch = useDispatch();
  const ref = React.useRef(null);
  const close = () => {
    props.handleClose();
    dispatch({ type: 'MOVE_TARGET_TO_VISITED' });
    dispatch({ type: 'CLEAR_DEAL_SUMMARY' });
  };

  return (
    <Dialog
      style={{ zIndex: 9 }}
      scroll={'paper'}
      TransitionComponent={Transition}
      fullScreen
      onClose={close}
      open={props.open}>
      <div style={{ position: 'relative' }} ref={ref}>
        <Header close={close} />
      </div>
    </Dialog>
  );
});

export default AlertDialog;
