/** FileExplorerUI contains 2 main components
 * 1- expanding & collapsing folder structure tree containing all files use can click on
 * 2- search textbox bar for searching files
 */
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { BsFolder2Open, BsFolder } from 'react-icons/bs';
import {
  GrDocumentPdf,
  GrDocumentPpt,
  GrDocumentCsv,
  GrDocumentExcel,
  GrDocumentWord
} from 'react-icons/gr';
import { AiOutlineFileText } from 'react-icons/ai';
import { Alert } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { ChangeEvent, useEffect, useState } from 'react';

const useStyles = makeStyles({
  root: {
    height: '100vh',
    flexGrow: 1,
    maxWidth: '100%',
    overflow: 'auto',
    padding: 10
  },
  padding: {
    paddingBottom: 5,
    paddingTop: 15
  }
});

interface FileListObject {
  Name: string;
}

interface FolderListObject {
  FolderList: FolderListObject[];
  FileList: FileListObject[];
  Name: string;
}

interface Props {
  tree: FolderListObject[];
  setSearchItem: (e: string) => void;
  searchItem: string;
  loading: boolean;
  getFileData: (props: any) => void;
}

const FileExplorerUI = (props: Props) => {
  const classes = useStyles();

  /**Is used within the expanded props in the MUI treeview component
   * it will collapse the searched file within the directories while also collapsing the folders too
   */
  const [expanded, setExpanded] = useState<string[]>([]);

  // Controlled MUI treeview component folder which collapses the folder either when user clicks on folder or user search file textbox
  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds); // eg. setExpanded(['root', 'folderName']);
  };

  /** API returned data prop for containing all the folder & files
   * @returns {object[]} - type FolderListObject[]
   */
  const { tree } = props;

  /** Contains all files branch path within the tree
   * mainly serve as the debounce state for the upcoming mapFileBranch state
   * @returns {2 dimensional array}
   */
  const [fileBranch, setFileBranch] = useState<string[][]>([]);

  /** Loops & collect all file branch paths from the 'tree' props & save it within the 'fileBranch' state
   * What the function does:
   * - Depth first search algorithm to loop all possible file branch path
   * - recursion function occurs until file is found within a folder (or folder is empty)
   */
  useEffect(() => {
    // save a 2 dimensional array for all file branch path before saving onto the fileBranch state
    const allFileBranchArray: string[][] = [];

    /** Loop 1
     * loop through the folder object to detect excess folders & files within
     */
    for (let folderObject of tree) {
      // 'root' must always be the first branch path in the file branch path
      const folderBranch: string[] = ['root'];

      /** recursion function is for when FileList array is empty but FolderList array is not
       * Uses 'Depth first search' algorithm to search all files
       * @param {object} folder_object: FolderListObject - Same as folderObject but dependent which level of parent folder it is currently on
       * - it is the folder object containing property of FolderList / FileList / Name .
       */
      const folderRecursion = (folder_object: FolderListObject) => {
        /** FileList loop
         * Check if file exist within the current folder & save file path array
         */
        if (folder_object.FileList.length > 0) {
          // enter current folder name within the branch
          folderBranch.unshift(folder_object.Name);

          // create all separate file branch & save each to allFileBranchArray
          for (let fileObject of folder_object.FileList) {
            // one complete file branch path array
            const finalFileBranch = [fileObject.Name, ...folderBranch];

            allFileBranchArray.push(finalFileBranch);
          }
          folderBranch.shift();
        } // END FileList loop

        /** FolderList loop
         * if no files exist, check if any more folders exist within & use recursive function to loop through until file is found or empty folder
         */
        else if (folder_object.FolderList.length > 0) {
          // save current folder name to branch
          folderBranch.unshift(folder_object.Name);

          // loop through current folder using recursive function until file is found or not
          for (let folderList of folder_object.FolderList) {
            folderRecursion(folderList);
          }
          // ensure the first 'root' item is not removed in the folderBranch, as this means we are still on the first folder & cannot go back further
          if (folderBranch.length > 1) {
            folderBranch.shift();
          }
        } // END FolderList loop

        /** If loop reached a dead end & no more folders or files exist
         * reverse back to the current parent folder
         */
        else if (folder_object.FolderList.length === 0) {
          //  ? It saves the folder branch path, which should not be the case, but i cannot remember why I did this & why this works but it does works & should not be removed
          folderBranch.unshift(folder_object.Name);

          allFileBranchArray.push(folderBranch);
          folderBranch.shift();

          return;
        } // END dead end loop
      }; // END folderRecursion

      /** loop through all folders until files or deadend using 'Depth first search' algorithm & stores it all in the allFileBranchArray variable
       * @params {object} - folderObject
       */
      folderRecursion(folderObject);
    } // END loop 1

    // now save all the collected file branch path with the FileBranch state
    setFileBranch([...allFileBranchArray]);
  }, [tree]); // END useEffect (collect & save all branch file path)

  /** all files branch path will be save in a map object for quicker & efficient collection
   * @return {Map object} mapFileBranch state
   */
  const [mapFileBranch, setMapFileBranch] = useState(new Map());

  // convert all the file branch path as mapFileBranch dictionary object for quicker & efficient collection when user uses search file textbox
  useEffect(() => {
    if (fileBranch.length > 0) {
      for (let item of fileBranch) {
        const keyName = item[0].toLowerCase();
        mapFileBranch.set(keyName, item);
      }
    }

    // when component is unmounted, clear expanded & searchItem state
    return () => {
      setExpanded([]);
      props.setSearchItem('');
    };
  }, [fileBranch]); // END useEffect convert all fileBranch to a dictionary map object

  // search textbox onChange event, when user types for a file, the folder will collapse to display the matched file
  const handleOnchangeSearchText = (e: ChangeEvent) => {
    const inputElement = e.target as HTMLInputElement;

    // text value from user search file textbox
    const searchFileText: string = inputElement.value;

    // update the searchItem state, as this update the value of the controlled search textbox for the user
    props.setSearchItem(searchFileText);

    // search text within the mapFileBranch & collapse folder to display the file
    for (const [key] of mapFileBranch) {
      // if searchFileText is empty, than break loop
      if (searchFileText === '') {
        setExpanded([]);
        break;
      } // END if statement
      /** if searchFileText matches with any key, than automatically collapse folder for the user
       * search starts from key index postion 0 (first letter of the file)
       */
      else if (new RegExp(`^${searchFileText}`).test(key)) {
        setExpanded(mapFileBranch.get(key));
        break;
      }
      // if no matching searchFileText with file key name, then close collapsed folder
      else {
        setExpanded([]);
      }
    } // END for loop - mapFileBranch
  }; // END handleOnchangeSearchText

  return (
    <Paper>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<BsFolder2Open />}
        defaultExpandIcon={<BsFolder />}
        // expanded array determine which folder is collapse or not
        expanded={expanded}
        // this pass the nodeId to the function to set the expanded state & collapsing folders
        onNodeToggle={handleToggle}>
        {!props.loading ? (
          <div>
            <Box>
              <Alert severity="info">
                <Typography>
                  To Search For A File, Enter A Folder First.
                </Typography>
              </Alert>
              <div style={{ paddingBottom: 10 }} />
              <TextField
                data-cy="file-search-bar"
                id="text"
                label="File Search"
                type="search"
                variant="outlined"
                fullWidth
                onChange={handleOnchangeSearchText}
              />
            </Box>
            <div className={classes.padding} />
            <TreeItem data-cy="folder" nodeId="root" label="Folders">
              {Object.values(props.tree).map(
                (folder: FolderListObject, key: number) => {
                  let path = `/${folder.Name}`;

                  return (
                    <TreeItem
                      data-cy="afs-file-explorer"
                      key={key}
                      nodeId={`${folder.Name}`}
                      label={folder.Name || 'Unnamed Folder'}>
                      {/* recursion function when folder has no file as direct child, & needs to dig deeper into folders */}
                      <NestedFolder
                        {...props}
                        folder={folder}
                        searchItem={props.searchItem}
                        path={path}
                      />
                    </TreeItem>
                  );
                }
              )}
            </TreeItem>
          </div>
        ) : (
          <CircularProgress />
        )}
      </TreeView>
    </Paper>
  );
}; // END FileExplorerUI component

export default FileExplorerUI;

/************************************************ */
// addional components to assist the FileExplorerUI component
/************************************************ */
/** displays all the folder & files
 * ps. the folders & files are not set up to be on the same level, meaning you will not see folder & file as siblings, either its folders within folder or files within folders
 * use of recursion if file is not located at direct child & needs to dig deeper within folders
 */
const NestedFolder = ({ folder, searchItem, getFileData, path }) => {
  /** NOTE: Not using this function because it filters out all other files to hidden when user is using search text box & clicking on folders at the same time.
   * This will further filter the file to match the searchFileText & mapFileBranch
   * It will hide the other files in the folder that is not matching the searchFileText
   * @returns {array} - the file object
   */
  const filteringFile = folder.FileList.filter((e: any) => {
    // * Using regExp object because we want to search from index 0 position, just like within handleOnchangeSearchText function
    // if (e.Name.toLowerCase().includes(searchItem.toLowerCase())) {
    if (new RegExp(`^${searchItem.toLowerCase()}`).test(e.Name.toLowerCase())) {
      return e;
    }
  });

  return (
    <>
      <FileList
        // folder={searchItem !== '' ? filteringFile : folder.FileList}
        folder={folder.FileList}
        getFileData={getFileData}
        path={path}
      />
      {folder.FolderList.length > 0 && (
        <FolderList
          folder={folder.FolderList}
          searchItem={searchItem}
          open={open}
          getFileData={getFileData}
          path={path}
        />
      )}
    </>
  );
}; // END NestedFolder component

// component for displaying folders within the folder tree structure
const FolderList = ({ folder, searchItem, getFileData, path, open }) => {
  return (
    <>
      {Object.values(folder).map((folder: any, idx: number) => {
        return (
          <TreeItem
            data-cy="contact-sheet"
            key={idx}
            nodeId={folder.Name}
            label={folder.Name || 'Unnamed Folder'}>
            <NestedFolder
              key={idx}
              folder={folder}
              searchItem={searchItem}
              getFileData={getFileData}
              path={`${path}/${folder.Name}`}
            />
          </TreeItem>
        );
      })}
    </>
  );
}; // END FolderList component

// component for displaying each files within its parent folder
const FileList = ({ folder, getFileData, path }) => {
  return (
    <>
      {Object.values(folder).map(({ Name }: any, idx: number) => {
        return (
          <TreeItem
            data-cy="pdf-documents-file"
            icon={docType(Name)}
            key={idx}
            label={Name}
            onLabelClick={() => getFileData({ path: `${path}/${Name}`, Name })}
            nodeId={Name}
          />
        );
      })}
    </>
  );
}; // END FileList component

// Display the correct icons for each file type
const docType = (type: string) => {
  switch (type.split('.').pop()) {
    case 'pdf':
      return <GrDocumentPdf />;
    case 'pptx':
      return <GrDocumentPpt />;
    case 'doc':
      return <GrDocumentWord />;
    case 'docx':
      return <GrDocumentWord />;
    case 'xlsx':
      return <GrDocumentExcel />;
    case 'csv':
      return <GrDocumentCsv />;
    default:
      return <AiOutlineFileText />;
  }
}; // END docType
