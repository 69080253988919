import { useContext, useEffect, useState } from 'react';
import { MessageHubProvider } from '../../../';
import { StepperContext } from 'components/Stepper/context';
import { Message } from '@microsoft/microsoft-graph-types';
import { Thread, IEmail } from '../../../interfaces';
import { UserInstance, Rule } from 'types/interfaces';
import { MessageCard } from '..';
import { useProcess } from 'hooks';
import { Button, Grid } from '@material-ui/core';
import AlertDialog from '../../../../Steps/ActiveStep/components/AlertDialog';

import { ruleGroupFilter, ruleFilter } from '../functions';

export const SystemInbox = ({
  thread,
  fixedToContact
}: {
  thread: Thread;
  fixedToContact: UserInstance;
}) => {
  const { conversation, systemEmailLog, getSystemEmails } =
    useContext(MessageHubProvider);
  const { refreshDealData } = useContext(StepperContext);
  const [merged, setMerged] = useState<Message[]>([] as Message[]);
  const [open, setOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState<number | undefined>();
  const { getRuleGroups, currentStep, currentProcess, currentDeal } =
    useProcess();
  const { InteractiveRuleDict } = currentStep;
  const { ruleGroups, rules } = getRuleGroups();

  const merge = () => {
    const newMessages = [] as Message[];

    systemEmailLog &&
      Object.values(systemEmailLog)
        .sort((a: IEmail, b: IEmail) => {
          const A = a.LastModified;
          const B = b.LastModified;
          if (A && B) return A > B ? -1 : A < B ? 1 : 0;
          return 0;
        })
        .filter(
          (email: IEmail) =>
            email.UserInstanceEmail === fixedToContact.UserInstanceEmail
        )
        .forEach((item: IEmail) => {
          const newMessage: Partial<Message> = {
            id: item.Id.toString(),
            createdDateTime: item.LastModified,
            subject: item.EmailSubject,
            from: {
              emailAddress: {
                address: item.SenderEmailAddress,
                name: item.SenderName
              }
            },
            toRecipients: [
              {
                emailAddress: {
                  address: item.UserInstanceEmail,
                  name: item.UserInstanceEmail
                }
              }
            ],
            bodyPreview: item.EmailBody,
            body: {
              content: item.EmailBody,
              contentType: 'html'
            }
          };
          return newMessages && newMessages.push(newMessage);
        });
    newMessages && setMerged(newMessages);
  };

  useEffect(() => {
    merge();
  }, [conversation, fixedToContact, systemEmailLog]);

  return (
    <>
      {merged &&
        merged
          .sort((a: Message, b: Message) => {
            const A = a.createdDateTime;
            const B = b.createdDateTime;
            if (A && B) return A > B ? -1 : A < B ? 1 : 0;
            return 0;
          })
          .map((message: Message | undefined, idx: number) => {
            if (!message) return <></>;

            return (
              <div key={idx}>
                <MessageCard message={message} thread={thread} isSystem />
              </div>
            );
          })}

      {selectedKey && (
        <AlertDialog
          InteractiveRuleDict={InteractiveRuleDict}
          actions={rules}
          open={open}
          handleClose={() => setOpen(false)}
          refreshDealData={() => {
            refreshDealData();
            getSystemEmails();
          }}
          selectedKey={selectedKey}
        />
      )}
    </>
  );
};

// const handleRuleClick = (id: number) => setSelectedKey(id);
/* <Grid container alignItems="center" justifyContent="center">
        {Object.keys(ruleGroups)
          .filter((name: string) => ruleGroupFilter(name, thread))
          .map((name: string) => {
            const rules: Rule[] = ruleGroups[name];
            return rules
              .filter((rule: Rule) => ruleFilter(rule, thread))
              .map((rule: Rule) => (
                <Grid item key={rule.Id}>
                  <Button
                    onClick={() => {
                      handleRuleClick(rule);
                      // setOpen(true);
                    }}
                    variant="contained"
                    color="secondary"
                    style={{ margin: 1 }}>
                    {rule.Title}
                  </Button>
                </Grid>
              ));
          })}
      </Grid> */

// const handleRuleClick = (rule: Rule) => {
//   const filtered = ruleFilter(rule, thread);
//   let Actions = filtered && ruleActionFunctionFilter(filtered);

//   console.log({ Actions });
// };

// const ruleActionFunctionFilter = (rule: Rule) => {
//   const { ProcessStepDefinitionId, ActionFunction, ActionValue } = rule;

//   switch (ActionFunction) {
//     case 'Invite related user to step': {
//       const ProcessInstanceId = currentDeal.ProcessInstance.Id;
//       const RecipientUserInstanceId = fixedToContact.UserInstanceEmail;
//       const TargetUserInstanceId = 0;
//       const ProcessStepDefinition =
//         currentProcess.CompleteProcessStepDefinitionDict[
//           ProcessStepDefinitionId
//         ].ProcessStepDefinition;

//       // if
//       if (thread.type === 'borrower.contact') {
//       }

//       // If this then what is the related step?
//       return console.log({
//         ActionFunction,
//         ProcessInstanceId,
//         ProcessStepDefinitionId,
//         RecipientUserInstanceId,
//         TargetUserInstanceId,
//         ProcessStepDefinition,
//         thread
//       });
//     }

//     case 'Run rule sequence': {
//       const actionValArray = ActionValue.split(',');
//       const x = actionValArray.forEach((RuleId: string) => {
//         const matchingRule: Rule | undefined = rules.find(
//           (rule) => rule.Id === parseInt(RuleId)
//         );

//         console.log({ rule, rules, matchingRule });
//         return matchingRule;
//       });

//       return console.log({
//         ActionFunction,
//         ProcessStepDefinitionId,
//         rule,
//         rules,
//         actionValArray
//       });
//     }

//     default:
//       return rule;
//   }
// };
