import { Dashboard as DashboardLayout } from 'layouts';
import Marketing from 'components/Marketing';

const Component = () => {
  return (
    <DashboardLayout title="Marketing Campaigns" padding="noPadding">
      <Marketing />
    </DashboardLayout>
  );
};

export default Component;
