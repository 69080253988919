import React from 'react';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import {
  SET_DND_USERS,
  ADD_DND_USER,
  REMOVE_DND_USER,
  UPDATE_DND_USER
} from 'redux/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

export interface IContactDoNotDeal {
  email: string;
  name: string;
  UserDefinitionId: number;
  Description?: string;
  ReportedBy?: string;
  dateCreated: Date;
  dateRemoved?: Date;
  docId?: string;
}

interface contactDocField {
  nameOfDocField: string;
  value: string | number;
}

const DoNotDealList = 'Do_Not_Deal_List';
const History = 'History';

export const useDoNotDealListNonRedFlag = (email: string) => {
  const dispatch = useDispatch();
  const contactDoNotDealList = useSelector(
    (state: RootState) => state.dndReducer.dndusers
  );
  const badContact = useSelector((state: RootState) =>
    state.dndReducer.dndusers.find(
      (user) => 'email' in user && user.email === email
    )
  ) as IContactDoNotDeal | undefined;
  const [contactHistoryList, setContactHistoryList] = React.useState<
    IContactDoNotDeal[]
  >([]);

  const addContactToDoNotDealList = async ({
    email,
    name,
    UserDefinitionId,
    Description = '',
    ReportedBy = ''
  }: IContactDoNotDeal) => {
    const ref = await firebase.firestore().collection(DoNotDealList).doc(email);
    const newContact = {
      email,
      name,
      UserDefinitionId: UserDefinitionId ? UserDefinitionId : 0,
      Description,
      ReportedBy,
      dateCreated: firebase.firestore.Timestamp.fromDate(new Date())
    };
    await ref.set(newContact).catch((e) => {
      console.log('Failed Error: SET, add to do not deal list', e);
      BugTracker.notify('Failed Error: SET, add to do not deal list', e);
    });
    dispatch({ type: ADD_DND_USER, payload: newContact });
    await getContactDoNotDealList();
    return ref;
  };

  const removeContactFromDoNotDealList = async ({
    email
  }: IContactDoNotDeal) => {
    const ref = await firebase.firestore().collection(DoNotDealList).doc(email);
    await ref.delete().catch((e) => {
      console.log('Failed Error: DELETE, remove from do not deal list', e);
      BugTracker.notify(
        'Failed Error: DELETE, remove from do not deal list',
        e
      );
    });
    dispatch({ type: REMOVE_DND_USER, payload: email });
    await getContactDoNotDealList();
    return ref;
  };

  const updateContactDoNotDealListEntry = async ({
    email,
    name,
    UserDefinitionId,
    Description,
    ReportedBy
  }: IContactDoNotDeal) => {
    const ref = firebase.firestore().collection(DoNotDealList).doc(email);
    const updatedContact = {
      email,
      name,
      UserDefinitionId: UserDefinitionId ? UserDefinitionId : 0,
      Description,
      ReportedBy,
      dateCreated: firebase.firestore.Timestamp.fromDate(new Date())
    };
    await ref.update(updatedContact).catch((e) => {
      console.log('Failed Error: UPDATE, update do not deal list', e);
      BugTracker.notify('Failed Error: UPDATE, update do not deal list', e);
    });
    dispatch({ type: UPDATE_DND_USER, payload: updatedContact });
    await getContactDoNotDealList();
    await getContactAllHistory();
    return ref;
  };

  const getContactDoNotDealList = async () => {
    const ref = firebase.firestore().collection(DoNotDealList);
    return ref
      .get()
      .then((query) => {
        const contactDoNotDealList: IContactDoNotDeal[] = [];
        query.forEach((doc) =>
          contactDoNotDealList.push(doc.data() as IContactDoNotDeal)
        );
        dispatch({ type: SET_DND_USERS, payload: contactDoNotDealList });
        return contactDoNotDealList;
      })
      .catch((e) => {
        console.log('Failed Error: GET, get do not deal list', e);
        BugTracker.notify('Failed Error: GET, get do not deal list', e);
      });
  };

  const addToHistory = async () => {
    const timeStampNow = firebase.firestore.Timestamp.fromDate(new Date());
    if (email && email.length > 0) {
      const subRef = await firebase
        .firestore()
        .collection(DoNotDealList)
        .doc(email)
        .collection(History);
      subRef
        .add({
          ...badContact,
          dateRemoved: timeStampNow
        })
        .then(async (docRef) => {
          await docRef.update({ docId: docRef.id }).catch((e) => {
            console.log(
              'Failed Error - UPDATE, did not update with the desired docId',
              e
            );
            BugTracker.notify(
              'Failed Error - UPDATE, did not update with the desired docId',
              e
            );
          });
        })
        .catch((e) => {
          console.log('Failed Error: ADD, add to history', e);
          BugTracker.notify('Failed Error: ADD, add to history', e);
        });
    }
  };
  const getContactAllHistory = async () => {
    if (email.length > 0) {
      const subRef = await firebase
        .firestore()
        .collection(DoNotDealList)
        .doc(email)
        .collection(History);
      const sortedDateRef = subRef.orderBy('dateRemoved', 'desc');
      return await sortedDateRef
        .get()
        .then((query) => {
          const historyList: IContactDoNotDeal[] = [];
          query.forEach((doc) =>
            historyList.push(doc.data() as IContactDoNotDeal)
          );
          setContactHistoryList(historyList);
          return historyList;
        })
        .catch((e) => {
          console.log('Failed Error: GET, get history list', e);
          BugTracker.notify('Failed Error: GET, get history list', e);
        });
    }
  };
  React.useEffect(() => {
    getContactAllHistory();
  }, [badContact]);

  return {
    addContactToDoNotDealList,
    badContact,
    updateContactDoNotDealListEntry,
    removeContactFromDoNotDealList,
    addToHistory,
    getContactAllHistory,
    contactDoNotDealList,
    getContactDoNotDealList,
    contactHistoryList
  };
};
