import { getSysDocFileUrl } from 'helpers/documentRetrieval';
import { useProcess } from 'hooks/useProcess';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'redux/reducers';
import { FieldInstance, Overview } from 'types/interfaces';

export const getDocument = async ({
  currentOverView,
  prostepdefid,
  processInstanceFields
}: {
  currentOverView: Overview;
  prostepdefid: number;
  processInstanceFields: {
    [ProcessInstanceId: number]: FieldInstance[];
  };
}): Promise<boolean> => {
  const _inst: FieldInstance[] =
    processInstanceFields[currentOverView.ProcessInstanceId];
  const zeroField: FieldInstance[] = _inst?.filter((el) => el.Id === 0);
  const findSupplierInvoiceRequest: FieldInstance | undefined = zeroField.find(
    (FieldInstance) => FieldInstance.Title === 'Supplier Invoice Request'
  );

  if (findSupplierInvoiceRequest) {
    const config = {
      ProcessInstanceId: currentOverView.ProcessInstanceId,
      ProcessStepDefinitionId: prostepdefid,
      DocumentStepDefinitionId: parseInt(
        findSupplierInvoiceRequest?.FieldValue
      ),
      action: 'GetPDF'
    };

    const res = await getSysDocFileUrl(config);
    if (res) {
      window.open(res);
      return true;
    }
  }

  return false;
};
