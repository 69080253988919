import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import { useProcess } from 'hooks';
import { useContext } from 'react';
import { OutputContext } from '../context/OutputContext';
import { ReactComponent as CalcLogo } from 'assets/Icons/svg/calculator.svg';
import { tableIcons } from 'components/Calculator/Utils/tableIcons';

const OutputHeader = () => {
  const theme = useTheme();
  const { landingpage } = useProcess();
  const {
    props,
    handleClick,
    checkEmptyAmountAndCashFlow,
    handleFunderDocumentDown
  } = useContext(OutputContext);

  const { readOnly, display, isDeclined } = props;
  if (
    (landingpage && readOnly && display === 'clientView') ||
    isDeclined ||
    readOnly
  ) {
    return <div />;
  }

  const { isEmpty } = checkEmptyAmountAndCashFlow();
  return (
    <Grid container justifyContent="flex-end" spacing={1}>
      {!isEmpty && !landingpage && (
        <Grid item>
          <Button
            color="primary"
            onClick={async () =>
              await handleFunderDocumentDown({
                CompleteObjectInstance: props.ObjectInstance,
                FieldDefinitionList: props.FieldDefinitionList
              })
            }>
            <div style={{ marginRight: 5 }}>
              <tableIcons.Export />
            </div>
            <Typography
              color="primary"
              style={{
                fontWeight: 'bold'
              }}>
              Funder Doc
            </Typography>
          </Button>
        </Grid>
      )}
      <Grid item>
        <Button
          data-cy="start-quote-btn"
          color="primary"
          onClick={() => handleClick('calculator')}>
          <div style={{ marginRight: 5 }}>
            <CalcLogo
              fill={theme.palette.primary.main}
              height="24"
              width="24"
            />
          </div>
          <Typography
            color="primary"
            style={{
              fontWeight: 'bold'
            }}>
            {isEmpty ? 'Start Quote' : 'Edit Quote'}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default OutputHeader;
