import { FormControl, MenuItem, Select } from '@material-ui/core';
import { ProcessSummary } from 'types/interfaces';

export const SelectByDealStatus = ({
  isActiveDealList,
  handleChange
}: {
  isActiveDealList: boolean;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}) => {
  return (
    <FormControl>
      <Select
        data-cy="deal-status-selector"
        labelId="dealStatusSelector"
        value={isActiveDealList ? 'Active Deals' : 'Closed Deals'}
        onChange={handleChange}>
        <MenuItem value="Active Deals">Active Deals</MenuItem>
        <MenuItem data-cy="closed-deals" value="Closed Deals">
          Closed Deals
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export const CustomUserSearch = ({
  term,
  rowData,
  type
}: {
  term: string;
  rowData: ProcessSummary;
  type: 'Customers' | 'Contacts' | 'Lenders' | 'Suppliers';
}): boolean => {
  return !!rowData.AssignedUserList.find((assigned) => {
    if (assigned.UserDefinitionTitle.includes(type)) {
      return assigned?.UserInstanceTitle.toLowerCase().includes(
        term.toLowerCase()
      );
    }
    return false;
  });
};

/**columns heading & setup of the material-table */

/**return either user active deals list or closed deals list
 * data used within the material-table
 * @returns {object[]}
 */
export const displayDealListData = ({
  isActiveDealList,
  processSummaries,
  closedData
}: {
  isActiveDealList: boolean;
  processSummaries: ProcessSummary[];
  closedData: ProcessSummary[];
}) => {
  // true for active list, false for closed list
  const dealsArrayList = isActiveDealList ? processSummaries : closedData;

  if (dealsArrayList && dealsArrayList?.length > 0) {
    // filter out deals with GDPR in the title and sort in order of last modified
    return (
      dealsArrayList &&
      dealsArrayList
        ?.filter((e) => !e.ProcessDefinitionTitle.includes('GDPR'))
        .sort((a, b) => {
          const A = new Date(a.LastModified).getTime();
          const B = new Date(b.LastModified).getTime();
          return B - A;
        })
    );
  } else {
    return [] as ProcessSummary[];
  }
}; //END displayDealListData
