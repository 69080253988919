import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import NumberFormat from 'react-number-format';
import { Paper } from '@material-ui/core';
import { BalanceSheetTitles } from 'components/RedFlag/TabMenu/Titles';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' }
}));

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

const Component = ({ company }) => {
  const classes = useStyles();
  const [columns, setColumns] = React.useState([]);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (company.balance_sheet && company.balance_sheet.length > 0) {
      let columns = [{ title: ' ', field: 'attributeName' }];
      company.balance_sheet.forEach(({ attributes }, i) => {
        // Add to columns
        if (i <= 4) {
          columns.push({
            title: attributes.account_date,
            field: attributes.account_date
          });
        }
      });
      let data = [];
      const search = '_';
      const replaceWith = ' ';
      // for (const key in company.balance_sheet[0].attributes) { // }
      BalanceSheetTitles.forEach((title) => {
        const noUnderscore = title.split(search).join(replaceWith);
        const attributeName = noUnderscore.replace(/\b\w/g, (c) =>
          c.toUpperCase()
        );
        const row = { attributeName };
        company.balance_sheet.forEach(({ attributes }) => {
          row[attributes.account_date] =
            row.attributeName === 'No Weeks' ? (
              attributes[title]
            ) : (
              <Currency value={attributes[title]} />
            );
        });
        data.push(row);
      });
      setColumns(columns);
      setData(data);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Paper elevation={3} className={classes.root}>
        <Table
          title="Balance Sheet"
          data={data}
          columns={columns}
          options={{ pageSize: 23 }}
        />
      </Paper>
    </div>
  );
};

export default Component;
