import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import GroupIcon from '@material-ui/icons/Group';
import BusinessIcon from '@material-ui/icons/Business';
import NumberFormat from 'react-number-format';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { Alert } from '@material-ui/lab';
import { theme } from 'theme';

const useStyles = makeStyles((theme) => ({
  paper: { padding: theme.spacing(4), minHeight: 70 },
  icon: { width: '100%', display: 'flex', alignItems: 'center' }
}));

const Component = ({ company }: { company: IRedflagAPIData }) => {
  const classes = useStyles();
  const { attributes } = company.data;
  const { balance_sheet } = company;
  const { profit_loss } = company;
  const isBalanceSheet = balance_sheet && balance_sheet.length > 0;
  const isProfitLoss = profit_loss && profit_loss.length > 0;

  const renderAlert = (message: string): JSX.Element => (
    <div style={{ paddingTop: theme.spacing(1) }}>
      <Alert severity="warning">
        <Typography variant="body1"> {message}</Typography>
      </Alert>
    </div>
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} md={4}>
        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <GroupIcon style={{ fontSize: '3vw' }} />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="h6" align="center">
                {'Estimated Employees'.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              {attributes?.estimated_employees || attributes?.employees ? (
                <div>
                  <Typography
                    style={{
                      fontSize: '2vw',
                      fontWeight: 900,
                      paddingTop: 10
                    }}>
                    {attributes?.estimated_employees || attributes?.employees}
                  </Typography>
                  <div style={{ paddingTop: 10 }} />
                </div>
              ) : (
                <div>
                  <Typography
                    style={{
                      fontSize: '2vw',
                      fontWeight: 900,
                      paddingTop: 10
                    }}>
                    0
                  </Typography>
                  <div style={{ paddingTop: 10 }} />
                </div>
              )}
            </Grid>
          </Grid>
        </Paper>
        {!attributes?.estimated_employees &&
          !attributes?.employees &&
          renderAlert("No data related to 'Estimated Employees'")}
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <GroupIcon style={{ fontSize: '3vw' }} />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="h6" align="center">
                {'Estimated Turnover'.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <NumberFormat
                style={{ fontSize: '2vw', fontWeight: 900 }}
                value={
                  attributes?.estimated_turnover ||
                  (isProfitLoss && profit_loss[0]?.attributes?.turnover) ||
                  0
                }
                displayType="text"
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                prefix="£"
              />
            </Grid>
          </Grid>
        </Paper>
        {!attributes?.estimated_turnover &&
          !isProfitLoss &&
          renderAlert("No data related to 'Estimated Turnover'")}
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <BusinessIcon style={{ fontSize: '3vw' }} />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="h6" align="center">
                {'Credit Limit'.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <NumberFormat
                style={{ fontSize: '2vw', fontWeight: 900 }}
                value={attributes?.rfa_rating?.credit_limit || 0}
                displayType="text"
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                prefix="£"
              />
            </Grid>
          </Grid>
        </Paper>
        {!attributes?.rfa_rating?.credit_limit &&
          renderAlert("No data related to 'Credit Limit'")}
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <BusinessIcon style={{ fontSize: '3vw' }} />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="h6" align="center">
                {'Cash'.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <NumberFormat
                style={{ fontSize: '2vw', fontWeight: 900 }}
                value={
                  (isBalanceSheet && balance_sheet[0].attributes.cash) || 0
                }
                displayType="text"
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                prefix="£"
              />
            </Grid>
          </Grid>
        </Paper>
        {(!isBalanceSheet || !balance_sheet[0].attributes.cash) &&
          renderAlert("No data related to 'Cash'")}
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <BusinessIcon style={{ fontSize: '3vw' }} />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="h6" align="center">
                {'Net Worth'.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <NumberFormat
                style={{ fontSize: '2vw', fontWeight: 900 }}
                value={
                  (isBalanceSheet && balance_sheet[0].attributes.net_worth) || 0
                }
                displayType="text"
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                prefix="£"
              />
            </Grid>
          </Grid>
        </Paper>
        {(!isBalanceSheet || !balance_sheet[0].attributes.net_worth) &&
          renderAlert("No data related to 'Net Worth'")}
      </Grid>

      <Grid item xs={12} md={4}>
        <Paper className={classes.paper}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <BusinessIcon style={{ fontSize: '3vw' }} />
            </Grid>
            <Grid item>
              <Typography color="textSecondary" variant="h6" align="center">
                {'Working Capital'.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <NumberFormat
                style={{ fontSize: '2vw', fontWeight: 900 }}
                value={
                  (isBalanceSheet &&
                    balance_sheet[0].attributes.working_capital) ||
                  0
                }
                displayType="text"
                thousandSeparator
                decimalScale={0}
                fixedDecimalScale
                prefix="£"
              />
            </Grid>
          </Grid>
        </Paper>
        {(!isBalanceSheet || !balance_sheet[0].attributes.working_capital) &&
          renderAlert("No data related to 'Working Capital'")}
      </Grid>
    </Grid>
  );
};

export default Component;
