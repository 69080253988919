import React from 'react';
import {
  LoadScript,
  LoadScriptProps,
  useJsApiLoader
} from '@react-google-maps/api';
import { CircularProgress } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

const libraries: LoadScriptProps['libraries'] = ['places'];
const API_KEY = process.env.REACT_APP_GCLOUD_API_KEY || '';

// Using Generic Types for GoogleMaps (HOC (Higher-Order Component))
export function useGoogleMapsAPI<T>(
  WrappedComponent: React.ComponentType<T>
): React.ComponentType<T & JSX.IntrinsicAttributes> {
  const ComponentWithGoogleMapsAPI: React.FC<T & JSX.IntrinsicAttributes> = (
    props: T & JSX.IntrinsicAttributes
  ) => {
    const { isLoaded, loadError } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: API_KEY,
      libraries
    });
    const [isLoading, setIsLoading] = React.useState(true);
    let isMounted = true;

    React.useEffect(() => {
      if (isLoaded) {
        setIsLoading(false);
      }

      return () => {
        isMounted = false;
      };
    }, [isLoaded]);

    if (loadError) {
      return (
        <Alert severity="error" style={{ marginBottom: 8 }}>
          <Typography variant="h4">Google Maps Error</Typography>
        </Alert>
      );
    }

    if (isLoading) return <CircularProgress />;
    return (
      <WrappedComponent {...props} isLoaded={isLoaded} loadError={loadError} />
    );
  };

  ComponentWithGoogleMapsAPI.displayName = `useGoogleMapsAPI(${
    WrappedComponent.displayName || WrappedComponent.name
  })`;

  return ComponentWithGoogleMapsAPI;
}
