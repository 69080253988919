import { SignIn } from '../database';
import { store } from 'redux/store';
import {
  SET_TOKEN,
  SET_SESSION,
  SET_USER,
  SET_SETTINGS,
  SET_EVENTS,
  SET_COSNETICS_STATE,
  PROXY_INSTANCE_SETTINGS
} from 'redux/actions/types';
import { getSettings } from './settings';
import { firebase } from 'redux/firebase';
import { RelationShipActions } from 'redux/database';
import axios, { AxiosRequestConfig } from 'axios';
import { BugTracker } from 'Utils/Bugtracker';
import { PROXY_SETTINGS } from 'redux/actions/types';
import { UserInstance, ProxyInstanceSettings } from 'types/interfaces';
import { initProxySettings } from 'redux/reducers/initObjects';
import { OldRelationshipsAPI } from 'redux/database/Relationship API/OldRelationshipAPI';

const { dispatch } = store;
const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

export const signIn = async ({ email, password, returnData, baseUrl }) => {
  try {
    // Login to Cosnetics
    const res = await SignIn({ email, password });
    const success = res && res.status === 200;

    if (success) {
      const user = res.data as UserInstance;
      if (user?.Id) {
        // Login to Firebase auth
        const FbLoginResponse = await logUserIntoFireBase({ res, hostname });
        if (FbLoginResponse.isAxiosError) return FbLoginResponse;
        loginAnalyticsEvent({ res });

        // UPDATE REDUX
        dispatch({ type: SET_TOKEN, payload: res.headers.auth });
        dispatch({ type: SET_SESSION, payload: res.headers.timeout });
        dispatch({ type: SET_USER, payload: user });
        if (returnData === 'token') return res.headers.auth;

        // Get Cosnetics Settings
        const settings = await getSettings({ returnData });

        // Socket with Proxy instance settings
        getProxyInstanceSettings(hostname, settings);

        // Get Cosnetics Relationships
        // Using (OLD) Relationships API
        const config = {
          action: 'LISTFORKEYWORD' as RelationShipActions,
          token: res.headers.auth,
          UserInstanceId: res.data.Id,
          keyword: 'calendar'
        };
        const events = await OldRelationshipsAPI(config);

        // UPDATE REDUX
        dispatch({ type: SET_SETTINGS, payload: settings });
        dispatch({ type: SET_EVENTS, payload: events.data });
        dispatch({ type: SET_COSNETICS_STATE, payload: true });

        return settings;
      }
    }
  } catch (e) {
    console.log('error logging in: ', { e });
  }
};

export const logUserIntoFireBase = async ({ res, hostname }) => {
  const { UserInstanceEmail, Id, SystemAccess } = res.data as UserInstance;
  const { auth } = res.headers;
  const config: AxiosRequestConfig = {
    url: `${process.env.REACT_APP_PROXY}/public/login`,
    method: 'POST',
    data: {
      email: UserInstanceEmail,
      token: auth,
      hostname,
      Id,
      system_user: SystemAccess
    }
  };

  return axios(config)
    .then((response) => {
      return firebase
        .auth()
        .signInWithCustomToken(response.data.fbToken)
        .then((user) => {
          store.dispatch({ type: 'AUTH', payload: user.user });
          store.dispatch({ type: 'HOSTNAME', payload: hostname });

          return getProxyUserAccount({ res })
            .then((response) =>
              store.dispatch({ type: PROXY_SETTINGS, payload: response })
            )
            .catch((e) => BugTracker.notify(e));
        })
        .catch((e) => {
          BugTracker.notify(e);
          // Make a second attempt here TODO
        });
    })
    .catch((e) => e);
};

const getProxyUserAccount = async ({ res }) => {
  const token = await firebase.auth()?.currentUser?.getIdToken();
  const user = res.data as UserInstance;
  const Id: string = user.Id.toString();
  const config: AxiosRequestConfig = {
    url: `${process.env.REACT_APP_PROXY}/user/userAccount`,
    method: 'POST',
    data: { Id, darkMode: false, SystemAccess: user.SystemAccess },
    headers: { token }
  };
  return axios(config)
    .then((response) => response.data)
    .catch((e) => BugTracker.notify(e));
};

const getProxyInstanceSettings = (hostname: string, settings: any) => {
  const { PlatformInstanceId } = settings;

  const ref = firebase
    .firestore()
    .collection('instance')
    .doc(`${PlatformInstanceId}_${hostname}`);

  initProxySettings.PlatformInstanceId = PlatformInstanceId;
  initProxySettings.hostname = hostname;

  return ref.get().then((res) => {
    const { exists } = res;
    if (exists) {
      let currentProxySettings = res.data() as ProxyInstanceSettings;

      // NEWLY LAUNCHED FEATURES
      for (const key in initProxySettings.features) {
        const featureExists = res.data()?.features[key];
        if (!featureExists) {
          // set new features to false when launched
          currentProxySettings.features[key] = false;
          ref.update(currentProxySettings);
        }
      }

      return store.dispatch({
        type: PROXY_INSTANCE_SETTINGS,
        payload: currentProxySettings
      });
    } else {
      // NEW INSTANCE SET UP
      return ref.set(initProxySettings);
    }
  });
};

export const loginAnalyticsEvent = ({ res }) => {
  // We do it this way and don't use the Utils.event method because the redux store is not guaranteed to be populated here.
  const { UserInstanceEmail, Id, Title, SystemAccess } =
    res.data as UserInstance;

  if (SystemAccess >= 5) {
    // @ts-expect-error
    window.gtag('event', 'Login', {
      feature: 'Authentication',
      uid: Id,
      email: UserInstanceEmail,
      name: Title,
      hostname,
      message: 'Successful login'
    });
  } else {
    // @ts-expect-error
    window.gtag('event', `Login SystemAccess:${SystemAccess}`, {
      feature: 'Authentication',
      uid: Id,
      email: UserInstanceEmail,
      name: Title,
      hostname,
      message: `Successful login SystemAccess:${SystemAccess}`
    });
  }
};
