import axios from 'axios';
import { createNotification } from 'react-redux-notify';

import { store } from 'redux/store';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { ISender } from '../types';
import { errorNotif } from 'components/Notifications';
import { validateSenderDomains } from 'components/Marketing/Senders/functions';

const { dispatch } = store;

export const createSender = async (sender: ISender) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/senders`;

  const valid = await validateSenderDomains({
    senderFromEmail: sender.from_email,
    senderReplyToEmail: sender.reply_to
  });

  if (!valid) return false;

  return axios
    .post(url, sender, { headers: { token } })
    .then((res) => {
      gtag.event({
        feature: 'Marketing',
        action: 'Sender Identity Create: Success',
        message: 'Success'
      });
    })
    .catch((e) => {
      BugTracker.notify(e);

      dispatch(createNotification(errorNotif('Error Creating Sender')));

      const errors = e?.response?.data?.response?.body?.errors || [];

      if (errors.length) {
        errors.forEach((error) => {
          const existingEmailError =
            error.field === 'from_email' && error.message === 'already exists';

          if (existingEmailError) {
            dispatch(
              createNotification(
                errorNotif(
                  `A Sender already exists with this exact same from_email. Consider using a different subdomain`
                )
              )
            );

            return;
          }

          dispatch(
            createNotification(errorNotif(`"${error.field}" ${error.message}`))
          );

          BugTracker.notify(error);
        });
      }

      throw errors;
    });
};
