import { store } from 'redux/store';
import { SelectObject } from '../../database';
import { refreshOverViewData } from 'redux/actions/processes/getDealData';
// import { createNotification } from 'react-redux-notify';
// import { successNotif } from 'components/Notifications';
import { CompleteObjectInstance, ObjectInstance } from 'types/interfaces';
import { IUpdateCompleteObjectInstance } from './interfaces';
import { updateUserANDProcessCompleteObjectInstanceInRedux } from './CompleteObjectUpdates/updateUserANDProcessCompleteObjectInstanceInRedux';
import { updateProcessCompleteObjectInstanceInRedux } from './CompleteObjectUpdates/updateProcessCompleteObjectInstanceInRedux';
import { updateProcessObjectInstanceInRedux } from './ObjectUpdates/updateProcessObjectInstanceInRedux';
import { updateUserANDProcessObjectInstanceInRedux } from './ObjectUpdates/updateUserANDProcessObjectInstanceInRedux';
import { clearOverview } from './clearOverview';
import { updateCompleteObjectInstanceList } from '../GraphQlActions';

const { dispatch } = store;

export const selectObjectInstance = async ({
  token,
  data,
  props,
  action
}: IUpdateCompleteObjectInstance) => {
  const res = await SelectObject({
    data,
    action: action,
    ProcessInstanceId: data.ObjectInstance.ProcessInstanceId,
    ObjectDefinitionId: data.ObjectInstance.ObjectDefinitionId,
    ObjectInstanceId: data.ObjectInstance.Id,
    UserInstanceId: data.ObjectInstance.UserInstanceId
  });

  const success = res?.status === 200;
  if (success) {
    const ObjectInstances: ObjectInstance[] = res.data;

    return ObjectInstances.map(async (ObjectInstance: ObjectInstance) => {
      // 1. Clear out the "Deal Overview" forcing a new download when "Deal OverView" opened again so data is in sync.
      await clearOverview({ ObjectInstance });
      const processDependent = ObjectInstance.ProcessInstanceId !== 0;

      if (processDependent) {
        if (props?.ProcessInstance) {
          const { ProcessInstance } = props;
          await refreshOverview({ ProcessInstance });
        }
        return updateProcessObjectInstanceInRedux({
          ObjectInstance
        });
      } else {
        return updateUserANDProcessObjectInstanceInRedux({
          ObjectInstance,
          processDependent
        });
      }
    });
  }
};

export const updateObject = async ({
  data,
  props,
  action,
  fetchPolicy
}: IUpdateCompleteObjectInstance) => {
  const ProcessInstanceId = props.ProcessInstance
    ? props.ProcessInstance.Id
    : props.ProcessInstanceId;

  const response = await updateCompleteObjectInstanceList({
    baseUrl: store.getState().config.baseURL,
    ProcessInstanceId,
    data,
    action: action ? action : '',
    fetchPolicy
  });

  console.log({ response });

  if (response) {
    const CompleteObjectInstance: CompleteObjectInstance = response;
    const { ObjectInstance } = CompleteObjectInstance;

    // const message = `Update Success ${CompleteObjectInstance.ObjectInstance.Id}`;
    // dispatch(createNotification(successNotif(message)));

    // 1. Clear out the "Deal Overview" forcing a new download when "Deal OverView" opened again so data is in sync.
    await clearOverview({ ObjectInstance });

    // TODO : WE SHOULD UPDATE BOTH POTENTIALLY, Yes!
    const processIndependent = ObjectInstance.ProcessInstanceId < 1;
    console.log({ processIndependent });
    if (processIndependent) {
      return updateUserANDProcessCompleteObjectInstanceInRedux({
        CompleteObjectInstance,
        ProcessInstance: props.ProcessInstance
      });
    } else {
      return updateProcessCompleteObjectInstanceInRedux({
        CompleteObjectInstance,
        ProcessInstance: props.ProcessInstance
      });
    }
  }
};

const refreshOverview = async ({ ProcessInstance }) => {
  const currentStepId = store.getState().process.currentStepId;
  return await refreshOverViewData({
    ProcessInstanceId: ProcessInstance.Id,
    ProcessDefinitionId: ProcessInstance.ProcessDefinitionId,
    ProcessStepDefinitionId: currentStepId
  });
};
