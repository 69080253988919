import { useContext } from 'react';
import { ThreadContext } from './context/ThreadsContext';
import { useWindowDimensions } from 'helpers/windowDimensions';
import ArchivedMessages from './components/ArchivedMessages';
import InboxMessages from './components/InboxMessages';
import DraftMessages from './components/DraftMessages';
import { SkeletonThread } from './components';
import CustomFolderMessages from './components/CustomFolderMessages';
import ParentThreadProvider from './context/ParentThreadsContext';
import SentItemsMessages from './components/SentItemsMessages';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { Alert } from '@material-ui/lab';
import { Typography, useTheme } from '@material-ui/core';

const Threads = () => {
  const { selectedCategory } = useContext(MessageHubContext);
  const { loading } = useContext(ThreadContext);

  if (loading.isLoading) {
    return (
      <div
        style={{
          maxHeight: '100%',
          overflowY: 'auto'
        }}>
        <Alert
          severity="info"
          variant="standard"
          style={{ borderEndEndRadius: 0, borderEndStartRadius: 0 }}>
          <Typography style={{ fontWeight: 'bold' }}>
            Important Information
          </Typography>
          <Typography>
            {`We're Diligently Gathering All Your Files And Folders To Fully
            Prepare Your Inbox. This Process May Take A Brief Moment, But We
            Appreciate Your Patience As We Ensure Everything Is Perfectly In
            Place For You.`}
          </Typography>
        </Alert>
        <SkeletonThread amountToLoad={loading.amount} />
      </div>
    );
  }

  switch (selectedCategory.category) {
    case 'Drafts': {
      return (
        <ParentThreadProvider>
          <DraftMessages />
        </ParentThreadProvider>
      );
    }
    case 'Inbox': {
      return (
        <ParentThreadProvider>
          <InboxMessages />
        </ParentThreadProvider>
      );
    }
    case 'Sent Items': {
      return (
        <ParentThreadProvider>
          <SentItemsMessages />
        </ParentThreadProvider>
      );
    }
    case 'Archived': {
      return (
        <ParentThreadProvider>
          <ArchivedMessages />
        </ParentThreadProvider>
      );
    }
    default: {
      return (
        <ParentThreadProvider>
          <CustomFolderMessages />
        </ParentThreadProvider>
      );
    }
  }
};
export default Threads;
