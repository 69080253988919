import React, { useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import _ from 'lodash-es';

export const PercentageField = ({
  name,
  value,
  classes,
  onChange,
  calculate,
  disabled,
  InputLabelProps,
  onBlur,
  onClick,
  onKeyUp,
  style,
  required,
  type
}) => {
  return (
    <TextField
      InputLabelProps={{ classes: { root: InputLabelProps } }}
      InputProps={{ inputComponent: PercentageFormatCustom }}
      className={classes}
      disabled={disabled}
      fullWidth
      label={name}
      margin="dense"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      onKeyDown={(e) => e.key === 'Enter' && calculate()}
      onKeyUp={onKeyUp}
      required={required}
      style={style}
      type={type}
      value={value}
      // variant="outlined"
      onFocus={(e) => setTimeout(() => e.target.select(), 0)}
    />
  );
};

const PercentageFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <div data-cy="yield-value">
      <NumberFormat
        {...other}
        decimalScale={5}
        fixedDecimalScale
        getInputRef={inputRef}
        onValueChange={(values) => {
          const { floatValue } = values;
          onChange({ target: { value: floatValue } });
        }}
        suffix="%"
        thousandSeparator
        values={{ formattedValue: '0.00' }}
      />
    </div>
  );
};

export const CurrencyField = ({
  required,
  name,
  value,
  classes,
  onChange,
  adornment,
  startAdornment,
  calculate,
  error,
  disabled,
  InputLabelProps,
  onBlur,
  onClick,
  onKeyUp
}) => {
  const adorn = {
    endAdornment: (
      <InputAdornment margin="dense" position="end">
        {adornment}
      </InputAdornment>
    ),
    startAdornment:
      startAdornment > 0 ? (
        <InputAdornment margin="dense" position="start">
          {`${startAdornment}+`}
        </InputAdornment>
      ) : null
  };

  return (
    <TextField
      InputLabelProps={{ classes: { root: InputLabelProps } }}
      InputProps={adornment ? adorn : { inputComponent: NumberFormatCustom }}
      className={classes}
      disabled={disabled}
      error={error}
      fullWidth
      label={name}
      // value={value === undefined || value === NaN ? "0" : value}
      margin="dense"
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      onClick={onClick}
      onKeyDown={(e) => (e.key === 'Enter' && calculate ? calculate() : null)}
      onKeyUp={onKeyUp}
      required={required}
      value={value}
      // variant="outlined"
      onFocus={(e) => e.target.select()}
    />
  );
};

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <div data-cy="input-net-advance">
      <NumberFormat
        {...other}
        decimalScale={2}
        fixedDecimalScale
        getInputRef={inputRef}
        onValueChange={(values) => {
          const { floatValue } = values;
          onChange({ target: { value: floatValue } });
        }}
        prefix="£"
        thousandSeparator
        values={{ formattedValue: '0.00' }}
      />
    </div>
  );
};

export const TextFieldInput = (props) => {
  const {
    name,
    value,
    classes,
    adornment,
    startAdornment,
    onChange,
    disabled,
    calculate,
    InputLabelProps,
    onBlur,
    onFocus,
    onClick,
    onKeyUp,
    type
  } = props;
  const adorn = {
    endAdornment: (
      <InputAdornment margin="dense" position="end">
        {adornment}
      </InputAdornment>
    ),
    startAdornment:
      startAdornment > 0 ? (
        <InputAdornment margin="dense" position="start">
          {`${startAdornment}+`}
        </InputAdornment>
      ) : null
  };
  return (
    <Grid data-cy="primary-contact-input" item>
      <TextField
        InputLabelProps={{ classes: { root: InputLabelProps } }}
        InputProps={adornment ? adorn : null}
        className={classes}
        disabled={disabled ? true : false}
        fullWidth
        label={name}
        margin="dense"
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onClick={onClick}
        // onFocus={(e) => onFocus}
        onFocus={(e) =>
          setTimeout(() => {
            e.target.select();
            onFocus && onFocus();
          }, 0)
        }
        onKeyDown={(e) => e.key === 'Enter' && calculate()}
        onKeyUp={onKeyUp}
        // ref={ref}
        type="number"
        value={value}
        // variant="outlined"
        {...props}
      />
    </Grid>
  );
};

export const NewCurrencyField = ({
  name,
  value,
  onChange,
  calculate,
  error,
  adornment,
  startAdornment,
  InputLabelProps,
  setPercent,
  setPounds,
  pounds,
  percent,
  state,
  setState
}) => {
  const adorn = {
    endAdornment: (
      <InputAdornment margin="dense" position="end">
        {adornment}
      </InputAdornment>
    ),
    startAdornment:
      startAdornment > 0 ? (
        <InputAdornment margin="dense" position="start">
          {`${startAdornment}+`}
        </InputAdornment>
      ) : null
  };

  const [activeField, setActiveField] = React.useState(null);
  const isGrossAdvance =
    state._NetAdvance.Vat_Payment === 'ADD_VAT_TO_NET' ||
    state._NetAdvance.Vat_Payment === 'DEFER_VAT';

  const handleFocus = (field, event) => {
    event.target.select();
    setActiveField(field);
  };

  const handleBlur = () => {
    setActiveField(null);
  };

  const handleInputChange = ({ newPounds, newPercent, event }) => {
    setPounds(newPounds);
    setPercent(newPercent);

    onChange(name)(event);
  };

  const handlePoundsChange = (event) => {
    let newPounds = Number(event.target.value);

    // Shamila / Mike Has Requested Deposit To Be Calculated Based On The Purchase Price
    // const Advance = calculateAdvance(state, true, isGrossAdvance);
    const Purchase_Price = state._NetAdvance.Purchase_Price;

    let newPercent = (newPounds / Purchase_Price) * 100;
    handleInputChange({ newPounds, newPercent, event });
  };

  const handlePercentChange = (event) => {
    let newPercent = roundToTwo(Number(event.target.value));

    // Shamila / Mike Has Requested Deposit To Be Calculated Based On The Purchase Price
    // const Advance = calculateAdvance(state, true, isGrossAdvance);
    const Purchase_Price = state._NetAdvance.Purchase_Price;

    let newPounds = (newPercent / 100) * Purchase_Price;
    let myEventTargetObject = {
      target: {
        value: newPounds
      }
    };

    handleInputChange({ newPounds, newPercent, event: myEventTargetObject });
  };

  if (isNaN(percent) || percent === undefined) setPercent('0.00');
  if (isNaN(pounds) || pounds === undefined) setPounds('0.00');
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <TextField
        InputLabelProps={{ classes: { root: InputLabelProps } }}
        InputProps={adornment ? adorn : { inputComponent: NumberFormatCustom }}
        disabled={activeField === 'percent'}
        error={error}
        fullWidth
        label={`${name} (£)`}
        margin="dense"
        name={`${name}Pounds`}
        onBlur={handleBlur}
        onChange={(e) => {
          if (activeField === 'pounds') handlePoundsChange(e);
        }}
        onKeyDown={(e) => (e.key === 'Enter' && calculate ? calculate() : null)}
        value={pounds}
        onFocus={(e) => handleFocus('pounds', e)}
      />
      <TextField
        InputLabelProps={{ classes: { root: InputLabelProps } }}
        InputProps={{ inputComponent: PercentageFormatCustom }}
        disabled={activeField === 'pounds'}
        error={error}
        fullWidth
        label={`${name} (%)`}
        margin="dense"
        name={`${name}Percent`}
        onBlur={handleBlur}
        onChange={(e) => {
          if (activeField === 'percent') handlePercentChange(e);
        }}
        onKeyDown={(e) => (e.key === 'Enter' && calculate ? calculate() : null)}
        value={percent}
        onFocus={(e) => handleFocus('percent', e)}
      />
    </div>
  );
};

export const roundToTwo = (num) => {
  return +(Math.round(num + 'e+3') + 'e-3');
};

export const calculateAdvance = (
  currentState,
  excludeDeposit = false,
  isGross = false
) => {
  let depositValue = excludeDeposit ? 0 : currentState._NetAdvance.Deposit;
  let vatTotal = isGross
    ? currentState._NetAdvance.Purchase_Vat +
      currentState._NetAdvance.Settlement_Vat -
      currentState._NetAdvance.Deposit_Vat -
      currentState._NetAdvance.Part_Exchange_Vat -
      currentState._NetAdvance.Subsidy_Vat
    : 0;

  return (
    currentState._NetAdvance.Purchase_Price +
    currentState._NetAdvance.Settlement +
    currentState._NetAdvance.NonVATableItemsCost -
    depositValue -
    currentState._NetAdvance.Part_Exchange -
    currentState._NetAdvance.Subsidy +
    vatTotal
  );
};
