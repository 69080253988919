import ReactHtmlParser from 'react-html-parser';
import CanEditUserList from '../../CanEditUserList';
import { useProcess } from 'hooks/useProcess';

export const PastStep = () => {
  const { summarycontent } = useProcess();
  return (
    <>
      {ReactHtmlParser(summarycontent)}
      <br />
      <CanEditUserList />
    </>
  );
};
