/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactHtmlParser, { convertNodeToElement } from 'react-html-parser';
import {
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import firebase from 'firebase';
import DOMPurify from 'dompurify';
import { createNotification } from 'react-redux-notify';
import { successNotif, warningNotif } from 'components/Notifications';
import { BugTracker } from 'Utils/Bugtracker';
import { useTypedSelector } from 'redux/reducers';
import { Alert } from '@material-ui/lab';
import { Delete } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { CustomDialog } from 'common/Dialog';
import { useNoticeboardBuilder } from 'hooks/useNoticeboardBuilder';

const InstanceBuilder = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { InstanceNoticeBoard, GlobalNoticeBoard, BrandNoticeBoard } =
    useTypedSelector((s) => s.config.settings);
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  let sanitizedInstanceHTML = DOMPurify.sanitize(InstanceNoticeBoard);
  let sanitizedGlobalHTML = DOMPurify.sanitize(GlobalNoticeBoard);
  let sanitizeBrandHTML = DOMPurify.sanitize(BrandNoticeBoard);

  const {
    setData,
    data,
    dialogOpen,
    history,
    builderType,
    handleGlobalBuilder,
    handleInstanceBuilder,
    handleBrandSelect,
    saveToHistory,
    handleSelectLayout,
    deleteHistoryItem,
    setDialogOpen,
    handleSaveBrand,
    availableBrands,
    selectedBrand
  } = useNoticeboardBuilder();

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setData((prevState) => ({ ...prevState, text: data }));
  };

  const handleImageUpload = (url: string) => {
    setData((prevState) => ({
      ...prevState,
      image: url
    }));
  };

  const selectHistoryItem = (item) => {
    if (!item) return null;
    if (typeof item === 'string') {
      setData({ text: item, image: null, timestamp: '', title: null });
    } else {
      setData(item);
    }
  };

  const handleDeleteCurrentNoticeboard = () => {
    const docRef = firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance');
    const fieldPath = `${hostname}.currentNoticeboardInstance`;

    docRef
      .update({
        [fieldPath]: {
          text: '',
          timestamp: '',
          image: null,
          title: null
        }
      })
      .then(() => {
        console.log('Noticeboard deleted', fieldPath);
        setData({ text: '', image: null, timestamp: '', title: null });

        if (GlobalNoticeBoard) {
          const sanitizedHTML = DOMPurify.sanitize(GlobalNoticeBoard);
          setData({
            text: sanitizedHTML,
            image: null,
            timestamp: '',
            title: null
          });
        } else {
          dispatch(
            createNotification(
              warningNotif(
                `Could not find a default noticeboard to display. Please create a new noticeboard.`
              )
            )
          );
        }
      })
      .catch((e) => {
        console.error('Error deleting noticeboard:', e);
      });
  };

  class UploadAdapter {
    private loader: any;
    private onUploadDone: (url: string) => void;
    private uploadTask: firebase.storage.UploadTask | null = null;

    constructor(loader: any, onUploadDone: (url: string) => void) {
      this.loader = loader;
      this.onUploadDone = onUploadDone;
    }

    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            let uploadTask = firebase
              .storage()
              .ref()
              .child(`images/${file.name}`)
              .put(file);

            uploadTask.on(
              firebase.storage.TaskEvent.STATE_CHANGED,
              (snapshot) => {},
              (e) => {
                BugTracker.notify(e);
                reject(e);
              },
              () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                  this.onUploadDone(downloadURL);
                  resolve({
                    default: downloadURL
                  });
                });
              }
            );
          })
      );
    }

    abort() {
      if (this.uploadTask) {
        this.uploadTask.cancel();
      }
    }

    delete(uploadTaskSnapshot: firebase.storage.UploadTaskSnapshot) {
      uploadTaskSnapshot.ref
        .delete()
        .then(() => {
          console.log('File Deleted Successfully');
        })
        .catch((e) => {
          BugTracker.notify(e);
        });
    }
  }

  if (builderType === '') {
    return (
      <CustomDialog
        open={dialogOpen}
        maxSize="md"
        handleClose={() => {
          dispatch(
            createNotification(
              warningNotif(
                `Please Choose An Option To Proceed: 'Global Bips Builder' Or 'Bips Instance Builder' To Close This Dialog.`
              )
            )
          );
        }}
        alert={{
          title: `Noticeboard Selection`,
          description: `Choose Between The Available Options To Continue.`,
          type: 'info'
        }}>
        <DialogContent>
          <Typography align="left" style={{ marginBottom: '1rem' }}>
            {`Choose either 'Global Bips Builder' or 'Bips Instance Builder' based
            on your needs. 'Global Bips Builder' will affect every user's
            instance and should be reviewed carefully before saving. 'Bips
            Instance Builder' will only affect the current instance.`}
          </Typography>

          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup>
              <Button
                onClick={handleGlobalBuilder}
                variant="contained"
                color="primary">
                Global Bips Builder
              </Button>
              <Button
                onClick={handleInstanceBuilder}
                variant="contained"
                color="secondary">
                Bips Instance Builder
              </Button>
              <Button
                onClick={handleBrandSelect}
                variant="contained"
                color="secondary">
                Brand Bips Builder
              </Button>
            </ButtonGroup>
          </div>
        </DialogContent>
      </CustomDialog>
    );
  }

  if (builderType === 'brand' && !selectedBrand) {
    return (
      <CustomDialog
        open={dialogOpen}
        maxSize="sm"
        handleClose={() => setDialogOpen(false)}
        alert={{
          title: `Select a Brand`,
          description: `Please choose a brand to proceed with the Brand Noticeboard.`,
          type: 'info'
        }}>
        <DialogContent>
          <ButtonGroup fullWidth>
            {availableBrands.map((brand) => (
              <Button
                key={brand}
                onClick={() => handleSaveBrand(brand)}
                variant="contained">
                {brand}
              </Button>
            ))}
          </ButtonGroup>
        </DialogContent>
      </CustomDialog>
    );
  }

  return (
    <div style={{ padding: theme.spacing(2) }}>
      {(builderType === 'brand' || builderType === 'instance') && (
        <div>
          <TextField
            margin="dense"
            placeholder="Enter The Noticeboard Title Here..."
            label="Noticeboard Title"
            variant="outlined"
            fullWidth
            value={data.title}
            onChange={(e) => setData({ ...data, title: e.target.value })}
          />
        </div>
      )}

      <CKEditor
        editor={ClassicEditor}
        data={data.text}
        onReady={(editor) => {
          editor.plugins.get('FileRepository').createUploadAdapter = (
            loader
          ) => {
            return new UploadAdapter(loader, handleImageUpload);
          };
        }}
        onChange={handleEditorChange}
      />

      <div
        style={{
          paddingBottom: theme.spacing(2)
        }}>
        <ButtonGroup fullWidth variant="contained" disabled={!data.text}>
          <Button
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0
            }}
            onClick={saveToHistory}>
            Save Noticeboard Layout
          </Button>
          <Button onClick={handleSelectLayout}>
            Select Noticeboard Layout
          </Button>
          <Button
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0
            }}
            onClick={() => {
              setData({
                image: null,
                text: '',
                timestamp: '',
                title: null
              });
            }}>
            Clear Noticeboard Editor
          </Button>
        </ButtonGroup>
      </div>

      {GlobalNoticeBoard !== '' && (
        <Grid container spacing={2} style={{ padding: theme.spacing(1) }}>
          <Card>
            <CardActionArea
              onClick={() =>
                selectHistoryItem(
                  builderType === 'global'
                    ? GlobalNoticeBoard
                    : builderType === 'instance'
                    ? InstanceNoticeBoard
                    : BrandNoticeBoard
                )
              }>
              <div style={{ paddingBottom: theme.spacing(1) }}>
                <Alert
                  severity="info"
                  style={{
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0
                  }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={10}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {`Current ${
                          builderType === 'global'
                            ? 'Global'
                            : builderType === 'instance'
                            ? 'Instance'
                            : 'Brand'
                        } Noticeboard`}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: 'right' }}>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteCurrentNoticeboard();
                        }}
                        style={{
                          position: 'absolute',
                          right: theme.spacing(1),
                          top: theme.spacing(1)
                        }}>
                        <Delete fontSize="medium" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Alert>
              </div>
              <CardContent>
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      builderType === 'global'
                        ? sanitizedGlobalHTML
                        : builderType === 'instance'
                        ? sanitizedInstanceHTML
                        : sanitizeBrandHTML
                  }}
                />
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}

      <Grid container spacing={2}>
        {history.map((item, index) => {
          const sanitizedHTML = DOMPurify.sanitize(item.text);
          const reactElement = ReactHtmlParser(sanitizedHTML, { transform });

          return (
            <Grid style={{ width: '100%' }} item xs={4} key={index}>
              <Card>
                <CardActionArea onClick={() => selectHistoryItem(item)}>
                  <div style={{ paddingBottom: theme.spacing(1) }}>
                    <Alert
                      severity="info"
                      style={{
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                        display: 'flex',
                        alignItems: 'center'
                      }}>
                      <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item xs={10}>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Noticeboard History - Created At:{' '}
                            {formatDate(item.timestamp)}
                          </Typography>
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'right' }}>
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteHistoryItem(item);
                            }}>
                            <Delete fontSize="medium" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Alert>
                  </div>
                  <CardContent>
                    <div>{reactElement}</div>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default InstanceBuilder;

const formatDate = (timestamp) => {
  if (timestamp && typeof timestamp.toDate === 'function') {
    return timestamp.toDate().toLocaleString();
  } else if (timestamp) {
    return new Date(timestamp).toLocaleString();
  }
  return '';
};

const transform = (node, index) => {
  if (node.type === 'tag' && node.name === 'img') {
    const src = node.attribs?.src || '';

    return (
      <img
        key={index}
        src={src}
        alt=""
        style={{
          maxWidth: '100%',
          height: 'auto'
        }}
      />
    );
  }

  return convertNodeToElement(node, index, transform);
};
