import React, { useEffect } from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, Button, Tooltip } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface Props {
  summary: any;
  children: any;
  expanded?: boolean;
  onChange?: (event: any, isExpanded: any) => void;
  style?: any;
}

const Xpansion = ({ summary, children, expanded = false, onChange }: Props) => (
  <Accordion expanded={expanded} onChange={onChange && onChange}>
    <AccordionSummary data-cy="expand-btn" expandIcon={<ExpandMoreIcon />}>
      {summary}
    </AccordionSummary>
    <AccordionDetails>{children}</AccordionDetails>
  </Accordion>
);

export const XpansionIsolated = ({
  summary,
  children,
  expanded = true,
  style
}: Props) => {
  let [expand, setExpanded] = React.useState(false);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  const toggle = () => setExpanded(!expand);

  return (
    <Accordion
      data-cy="landing-page-tabs"
      expanded={expand}
      onChange={(e) => {
        e.stopPropagation();
        toggle();
      }}
      style={{ ...style, width: '100%' }}>
      <AccordionSummary
        expandIcon={
          <Tooltip title="More detail">
            <ExpandMoreIcon />
          </Tooltip>
        }
        style={{ fontSize: '18px' }}>
        {summary}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%'
  },
  container: {
    display: 'flex'
  },
  paper: {
    margin: theme.spacing(1)
  },
  svg: {
    width: 100,
    height: 100
  },
  polygon: {
    fill: theme.palette.common.white,
    stroke: theme.palette.divider,
    strokeWidth: 1
  }
}));

export const SimpleCollapse = ({ children, action }) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={classes.root}>
        <FormControlLabel
          control={<Switch checked={checked} onChange={handleChange} />}
          label="Show"
        />
        <div style={{ flexGrow: 1 }} />
        <div>{action}</div>
      </div>
      <div className={classes.container}>
        <Collapse in={checked}>{children}</Collapse>
      </div>
    </div>
  );
};

export default Xpansion;
