import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/server';
import { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';
import * as serviceWorker from './common/serviceWorker';
import App from './App';

// const root = createRoot(document.getElementById('root'));

// main component
ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <App />
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();

// expose store when run in Cypress
if (window.Cypress) {
  window.store = store;
}
