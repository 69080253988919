import { useState } from 'react';
import { UserInstance, CompleteUserInstance } from 'types/interfaces';
import { updateUser } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';
import { TextField, Button, Grid } from '@material-ui/core';
import CustomerTypeForm from './CustomerTypeForm';

const variant = 'standard';

const UserForm = ({ data, classes }: { data: UserInstance; classes: any }) => {
  const isCustomer = data.UserDefinitionId === 523;

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const [state, setState] = useState(data);
  const [modified, setModified] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (event) => {
    setModified(true);
    setState((s) => ({ ...s, [event.target.name]: event.target.value }));
  };
  const handleSubmit = async () => {
    setLoading(true);
    // Here we want to update the entire user instance
    // so we will use updateUser mutation
    await updateUser({
      baseUrl,
      ProcessInstanceId: 0,
      data: { UserInstance: state }
    });
    setLoading(false);
    setModified(false);
  };

  return (
    <Grid container className={classes.userContainer}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            margin="dense"
            variant={variant}
            label="Title"
            name="Title"
            value={state.Title}
            onChange={handleChange}
            className={classes.input}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            margin="dense"
            variant={variant}
            label="NickName"
            name="NickName"
            value={state.NickName}
            onChange={handleChange}
            className={classes.input}
          />
        </Grid>

        {!isCustomer && (
          <Grid item xs={6}>
            <TextField
              margin="dense"
              variant={variant}
              label="Email"
              name="UserInstanceEmail"
              value={state.UserInstanceEmail}
              onChange={handleChange}
              className={classes.input}
            />
          </Grid>
        )}

        <Grid item xs={isCustomer ? 12 : 6}>
          <TextField
            margin="dense"
            variant={variant}
            label="Telephone Number"
            name="TelephoneNumber"
            value={state.TelephoneNumber}
            onChange={handleChange}
            className={classes.input}
          />
        </Grid>
      </Grid>

      <div style={{ height: 8 }} />

      <Grid container>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
            disabled={!modified}>
            Save
          </Button>
        </Grid>
      </Grid>

      <div style={{ height: 8 }} />

      <Grid container>
        <Grid item xs={12}>
          <CustomerTypeForm UserInstance={data} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserForm;
