import { Typography, Grid, Paper } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { theme } from 'theme';

import {
  Calculation,
  Asset,
  SeasonalPaymentType,
  SeasonalPayment
} from 'types/calculatorInterfaces';
import { useCalculationStyles } from '../../styles';
import { format } from '../../../';
import { XpansionIsolated } from 'common/Xpansion';
import AssetDisplayItem from '../../AssetDisplayItem';
import SeasonalPaymentItem from './SeasonalPaymentItem';
import BalloonItem from './BalloonItem';
import { truncateString, formatDate, capitalizeAndSpace } from 'Utils';
import { formatOverridePaymentsToRows } from '../../../../SeasonalPaymentsTable/functions';
import { setForValueFromCalculationNo } from '../../../../Calculator/functions/setForValueFromCalculationNo';
import { calculateEndDate } from '../../../../Calculator/functions/calculateEndDate';

const DisplayItem = ({
  calculation,
  id
}: {
  calculation: Calculation;
  id: string;
}) => {
  const classes = useCalculationStyles();

  // NOTE: old calculations did not require name so we use their id instead
  const calculationName =
    calculation.Title && calculation.Title !== 'null' ? calculation.Title : id;

  const emptyValue = '---';

  const SeasonalPayments: SeasonalPayment[] = formatOverridePaymentsToRows(
    calculation.extras || [],
    calculation.StartDate
  ).map((s) => {
    return {
      startPeriod: s.StartPeriod as string,
      endPeriod: s.EndPeriod as string,
      amount: s.Amount as number,
      type:
        s.ExPayOverRide === '1'
          ? SeasonalPaymentType.Overpayment
          : SeasonalPaymentType.SeasonalPayment
    };
  });

  const { newforValue: solveFor, newfromValue: solveFrom } =
    setForValueFromCalculationNo(calculation.Calculation);

  const endDate = calculateEndDate(
    calculation.StartDate,
    calculation.FundingProfile,
    calculation.PaymentFrequency
  );

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <Grid item className={classes.displayContainer}>
        <Typography variant="h4">Name:</Typography>
        <Typography variant="h4" gutterBottom>
          {truncateString(calculationName)}
        </Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Deal Type:</Typography>
        <Typography>{`
        ${capitalizeAndSpace(solveFor)} 
        —
        ${capitalizeAndSpace(solveFrom)}`}</Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>
          Start / End Date:
        </Typography>
        <Typography>{`
        ${formatDate(calculation.StartDate as string)} 
        — 
        ${endDate}
        `}</Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Amount:</Typography>
        <Typography>
          {typeof calculation?.Amount === 'number'
            ? `£ ${format(calculation.Amount?.toFixed(2))}`
            : emptyValue}
        </Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>
          Monthly Payment:
        </Typography>
        <Typography>
          {calculation && typeof calculation.MonthlyPayment === 'number'
            ? `£ ${calculation.MonthlyPayment?.toFixed(2)}`
            : emptyValue}
        </Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>
          {calculation.PaymentFrequency}
        </Typography>
        <Typography>
          {calculation.Term ? `for ${calculation.Term} Months` : emptyValue}
        </Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>
          Funding Profile:
        </Typography>
        <Typography>
          {calculation.InitialRentals} + {calculation.FundingProfile}
        </Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>
          Commission Amount:
        </Typography>
        <Typography>
          {typeof calculation?.CommissionAmount === 'number'
            ? `£ ${format(calculation.CommissionAmount?.toFixed(2))}`
            : emptyValue}
        </Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>
          Finance Product:{' '}
        </Typography>
        <Typography>
          {calculation.FinanceProduct ? calculation.FinanceProduct : emptyValue}
        </Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Notes:</Typography>
        <Typography
          style={{
            overflow: 'scroll',
            whiteSpace: 'nowrap',
            padding: theme.spacing(2),
            width: '100%'
          }}>
          {calculation.Notes ? calculation.Notes : emptyValue}
        </Typography>
      </Grid>
      {calculation._Balloon?.Balloon > 0 && (
        <>
          <div style={{ paddingTop: theme.spacing(1) }} />
          <Grid item className={classes.displayContainer}>
            <XpansionIsolated
              style={{ backgroundColor: grey[400] }}
              summary="Balloon">
              <Grid container direction="column">
                <Grid container>
                  <Grid item xs={12}>
                    <Paper
                      className={classes.storeItemPaper}
                      elevation={3}
                      style={{
                        border: `2px solid ${grey[400]}`
                      }}>
                      <BalloonItem balloon={calculation._Balloon} />
                    </Paper>
                    <div style={{ paddingBottom: theme.spacing(1) }} />
                  </Grid>
                </Grid>
              </Grid>
            </XpansionIsolated>
          </Grid>
        </>
      )}
      {calculation.Assets && calculation.Assets?.length > 0 && (
        <>
          <div style={{ paddingTop: theme.spacing(1) }} />
          <Grid item className={classes.displayContainer}>
            <XpansionIsolated
              style={{ backgroundColor: grey[400] }}
              summary="Assets">
              <Grid container direction="column">
                {calculation.Assets?.map((asset: Asset) => {
                  return (
                    <Grid key={asset.ObjectInstanceId} container>
                      <Grid item xs={12}>
                        <Paper
                          className={classes.storeItemPaper}
                          elevation={3}
                          style={{
                            border: `2px solid ${grey[400]}`
                          }}>
                          <AssetDisplayItem
                            asset={asset}
                            id={asset.ObjectInstanceId}
                          />
                        </Paper>
                        <div style={{ paddingBottom: theme.spacing(1) }} />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </XpansionIsolated>
          </Grid>
        </>
      )}
      {SeasonalPayments?.length > 0 && (
        <>
          <div style={{ paddingTop: theme.spacing(1) }} />
          <Grid item className={classes.displayContainer}>
            <XpansionIsolated
              style={{ backgroundColor: grey[400] }}
              summary="Seasonal Payments">
              <Grid container direction="column">
                {SeasonalPayments?.map((seasonalPayment, i) => {
                  return (
                    <Grid key={i} container>
                      <Grid item xs={12}>
                        <Paper
                          className={classes.storeItemPaper}
                          elevation={3}
                          style={{
                            border: `2px solid ${grey[400]}`
                          }}>
                          <SeasonalPaymentItem
                            seasonalPayment={seasonalPayment}
                          />
                        </Paper>
                        <div style={{ paddingBottom: theme.spacing(1) }} />
                      </Grid>
                    </Grid>
                  );
                })}
              </Grid>
            </XpansionIsolated>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DisplayItem;
