import React, { useState, useEffect, useCallback } from 'react';
import {
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Slider,
  Box,
  makeStyles,
  Theme,
  Divider,
  IconButton,
  withStyles,
  Tooltip
} from '@material-ui/core';
import { FbFileRef } from 'types/interfaces';
import {
  EDocumentResults,
  EDocumentType
} from 'graphql/FileStorageAPI/interface';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '300px',
    padding: theme.spacing(3)
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  title: {
    fontWeight: 'bold'
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  sliderContainer: {
    padding: theme.spacing(2, 1.5),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper
  },
  slider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  sizeDisplay: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(1)
  }
}));

const LargeTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 13,
    padding: theme.spacing(1)
  }
}))(Tooltip);

const ValueLabelComponent = (props) => {
  const { children, open, value } = props;

  return (
    <LargeTooltip
      open={open}
      enterTouchDelay={0}
      placement="top"
      title={bytesToSize(value)}>
      {children}
    </LargeTooltip>
  );
};

interface IDocumentFilterPanelProps {
  files: { [key: string]: FbFileRef };
  setIsFilterApplied: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  filterState: {
    documentType: EDocumentType | '';
    documentStatus: EDocumentResults | '';
    fileType: string;
    sizeRange: [number, number];
  };
  setFilterState: React.Dispatch<
    React.SetStateAction<{
      documentType: EDocumentType | '';
      documentStatus: EDocumentResults | '';
      fileType: string;
      sizeRange: [number, number];
    }>
  >;
}

const DocumentFilterPanel = ({
  files,
  setIsFilterApplied,
  onClose,
  filterState,
  setFilterState
}: IDocumentFilterPanelProps) => {
  const classes = useStyles();

  const uniqueFileTypes = Array.from(
    new Map(
      Object.values(files).map((file) => [
        mimeTypeToReadableName(file.type, file.name),
        {
          mimeType: file.type,
          readableName: mimeTypeToReadableName(file.type, file.name)
        }
      ])
    ).values()
  ).sort((a, b) => a.readableName.localeCompare(b.readableName));

  const maxFileSize = Math.max(
    ...Object.values(files).map((file) => file.size)
  );

  const handleFilterChange = (field: string, value: any) => {
    setFilterState((prev) => ({ ...prev, [field]: value }));
    setIsFilterApplied(true);
  };

  useEffect(() => {
    if (filterState.sizeRange[1] === 0) {
      setFilterState((prev) => ({ ...prev, sizeRange: [0, maxFileSize] }));
    }
  }, [maxFileSize, setFilterState, filterState.sizeRange]);

  const clearFilters = () => {
    setFilterState({
      documentType: '',
      documentStatus: '',
      fileType: '',
      sizeRange: [0, maxFileSize]
    });
    setIsFilterApplied(false);
    onClose();
  };

  return (
    <Box className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>
          Filter Documents
        </Typography>
        <IconButton onClick={clearFilters} size="small">
          <ClearIcon />
        </IconButton>
      </div>

      <Divider className={classes.divider} />

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Document Type</InputLabel>
        <Select
          value={filterState.documentType}
          onChange={(e) => handleFilterChange('documentType', e.target.value)}
          label="Document Type">
          <MenuItem value="">All</MenuItem>
          {Object.values(EDocumentType).map((type) => (
            <MenuItem key={type} value={type}>
              {type.replace('_', ' ')}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>Document Status</InputLabel>
        <Select
          value={filterState.documentStatus}
          onChange={(e) =>
            setFilterState((prev) => ({
              ...prev,
              documentStatus: e.target.value as EDocumentResults
            }))
          }
          label="Document Status">
          <MenuItem value="">All</MenuItem>
          {Object.values(EDocumentResults).map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel>File Type</InputLabel>
        <Select
          value={filterState.fileType}
          onChange={(e) => handleFilterChange('fileType', e.target.value)}
          label="File Type">
          <MenuItem value="">All</MenuItem>
          {uniqueFileTypes.map((type) => (
            <MenuItem key={type.mimeType} value={type.mimeType}>
              {type.readableName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={classes.formControl}>
        <Box className={classes.sliderContainer}>
          <Typography variant="body1" gutterBottom>
            File Size Range
          </Typography>
          <Slider
            value={filterState.sizeRange}
            onChange={(_, newValue) =>
              handleFilterChange('sizeRange', newValue)
            }
            valueLabelDisplay="auto"
            ValueLabelComponent={ValueLabelComponent}
            aria-labelledby="range-slider"
            min={0}
            max={maxFileSize}
            className={classes.slider}
          />
          <div className={classes.sizeDisplay}>
            <Typography variant="caption">
              Min: {bytesToSize(filterState.sizeRange[0])}
            </Typography>
            <Typography variant="caption">
              Max: {bytesToSize(filterState.sizeRange[1])}
            </Typography>
          </div>
        </Box>
      </FormControl>
    </Box>
  );
};

export default DocumentFilterPanel;

const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString());
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i];
};

const mimeTypeToReadableName = (
  mimeType: string,
  fileName?: string
): string => {
  const mimeMap = {
    'application/pdf': 'PDF',
    'audio/mpeg': 'MP3',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'Word Document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      'Excel Spreadsheet',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      'PowerPoint Presentation',
    'image/jpeg': 'JPEG Image',
    'image/png': 'PNG Image',
    'text/plain': 'Text File',
    'application/zip': 'ZIP Archive'
  };

  if (mimeMap[mimeType]) {
    return mimeMap[mimeType];
  }

  if (fileName) {
    const extension = fileName.split('.').pop()?.toUpperCase();
    if (extension) {
      return `${extension} File`;
    }
  }
  return mimeType.split('/')[1].toUpperCase();
};
