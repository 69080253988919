import React from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid lightgrey',
    borderRadius: theme.shape.borderRadius
  },
  btn: { marginTop: theme.spacing(2) }
}));

const Component = ({ title, handleChange, value, readOnly }) => {
  const [temp, setTemp] = React.useState('');
  const classes = useStyles();
  const ref = React.useRef(null);
  const save = (val) => {
    const img = ref.current.getTrimmedCanvas().toDataURL(val);
    setTemp(img);
    handleChange(img);
  };

  // console.log({ temp });

  if (readOnly) return <img height="50" src={value} alt="sig prev" />;
  return (
    <React.Fragment>
      <Typography>{title}</Typography>
      <div className={classes.root}>
        <SignatureCanvas
          ref={ref}
          penColor="black"
          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          onEnd={(val) => save(val)}
        />
      </div>

      <br />
      {/* {value !== '' && <img height="50" src={value} alt="sig" />} */}
      {temp !== '' && <img height="50" src={temp} alt="signature" />}
      <br />

      <ButtonGroup variant="contained" color="primary" margin="dense">
        <Button
          onClick={(val) => {
            ref.current.clear();
            save(val);
          }}>
          Reset
        </Button>
        {/* <Button
          onClick={val => {
            console.log({ val });
            save(val);
          }}>
          Save
        </Button> */}
      </ButtonGroup>
    </React.Fragment>
  );
};

export default Component;
