import {
  CompleteUserInstance,
  UserDefinition,
  CompleteObjectDefinition
} from 'types/interfaces';
import {
  SET_USER_DEFINITION,
  SET_USER_DEFINITIONS,
  SET_LIST,
  SET_ALL_LIST
} from '../actions/types';

interface userDefinition {
  [UserDefinitionId: number]: {
    [UserDefinitionId: number]: {
      UserDefinition: UserDefinition;
      CompleteObjectDefinitionList: CompleteObjectDefinition[];
    };
    Id: string;
  };
}

interface userList {
  [UserDefinitionId: number]: {
    [UserDefinitionId: number]: CompleteUserInstance[];
    Id: string;
  };
}

const INITIAL_STATE = {
  userDefinitionsList: [],
  userDefinition: {} as userDefinition,
  userList: {} as userList
};

interface CLEAR_USER_LIST {
  type: 'CLEAR_USER_LIST';
}

interface ISET_USER_DEFINITIONS {
  type: typeof SET_USER_DEFINITIONS;
  payload: UserDefinition[];
}

interface ISET_USER_DEFINITION {
  type: typeof SET_USER_DEFINITION;
  payload: UserDefinition;
}

interface ISET_LIST {
  type: typeof SET_LIST;
  payload: any;
}

interface ISET_ALL_LIST {
  type: typeof SET_ALL_LIST;
  payload: any;
}

interface ILOG_OUT {
  type: 'LOG_OUT';
  payload: any;
}

type IAction =
  | CLEAR_USER_LIST
  | ISET_USER_DEFINITIONS
  | ISET_USER_DEFINITION
  | ISET_LIST
  | ISET_ALL_LIST
  | ILOG_OUT;

// TODO: FIX HOW USERS ARE SAVED

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAR_USER_LIST':
      return {
        ...state,
        userList: {} as userList,
        userDefinition: {} as userDefinition
      };

    case SET_USER_DEFINITIONS:
      return { ...state, userDefinitionsList: action.payload };

    case SET_USER_DEFINITION: {
      let { id } = action.payload;
      state.userDefinition[id] = action.payload;
      return { ...state, userDefinition: state.userDefinition };
    }

    case SET_LIST: {
      let { id } = action.payload;
      action.payload[id].reverse();
      state.userList[id] = action.payload;
      return { ...state, userList: state.userList };
    }

    case SET_ALL_LIST: {
      return { ...state, userList: action.payload };
    }

    case 'LOG_OUT':
      return { ...state, userDefinition: {}, userList: {} };

    default:
      return state;
  }
};
