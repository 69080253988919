import React, { useEffect, useState } from 'react';

import Typography from '@material-ui/core/Typography';
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select
} from '@material-ui/core';

import { theme } from 'theme';
import { Alert } from '@material-ui/lab';
import { CustomerType } from '../interface';
import { UserDefinition, UserInstance } from 'types/interfaces';
import { GetUserDefinitions } from 'redux/database';

const CustomerTypeChip = ({
  selectedType,
  UserInstance,
  handleTypeChange
}: {
  selectedType: CustomerType;
  UserInstance: UserInstance;
  handleTypeChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}) => {
  const [type, setType] = useState<string>('');
  useEffect(() => {
    const fetchData = async () => {
      const res = await GetUserDefinitions({
        UserDefinitionId: UserInstance.UserDefinitionId
      });

      if (res) {
        let title = res.data.UserDefinition.Title;
        if (title.endsWith('s')) title = title.slice(0, -1);

        setType(title);
      }
    };

    fetchData();
  }, []);

  return (
    <Grid container direction="column">
      <Paper>
        <Grid item xs={12}>
          {selectedType && (
            <Alert
              style={{
                width: '100%',
                marginBottom: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0
              }}
              severity="info">
              <Typography style={{ fontWeight: 'bold' }}>
                Current {type ? type : 'Customer'} Type: {selectedType}
              </Typography>
            </Alert>
          )}
        </Grid>
        <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
          <Box display="flex" justifyContent="center">
            <FormControl
              variant="outlined"
              fullWidth
              margin="dense"
              style={{ margin: 8 }}>
              <InputLabel htmlFor="customer-type">Select Type</InputLabel>
              <Select
                label="Select Type"
                id="customer-type"
                fullWidth
                value={selectedType === 'None' ? '' : selectedType}
                onChange={handleTypeChange}>
                <MenuItem value="Prospect">Prospect</MenuItem>
                <MenuItem value="New Record">New Record</MenuItem>
                <MenuItem value="Existing Record">Existing Record</MenuItem>
                <MenuItem value="Potential Lead">Potential Leads</MenuItem>
                <MenuItem value="Hot Lead">Hot Lead</MenuItem>
                <MenuItem value="Referral">Referral</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default CustomerTypeChip;
