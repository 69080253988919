import { Grid, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardContext } from 'components/MessageHub/context/HoverCardContext';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { useSenderColors } from 'components/MessageHub/hooks/useSenderColors';
import { ICustomMessage } from 'components/MessageHub/interfaces';
import { useWindowDimensions } from 'helpers/windowDimensions';
import { useContext } from 'react';
import { theme } from 'theme';
import { CustomDraft, CustomThreadHover, SkeletonThread } from '.';
import { EmailCompositionCreationContext } from '../../ComposeWindows/context/ComposeWindowsContext';
import { SelectionContext } from '../../Selection/context/SelectionContext';

const DraftMessages = () => {
  const { messages, selectedCategory, drafts } = useContext(MessageHubContext);
  const { hoveredCard } = useContext(CardContext);
  const { handleDeleteMessages } = useContext(SelectionContext);
  const { addComposeEmail } = useContext(EmailCompositionCreationContext);

  const getSenderColor = useSenderColors();
  const { height } = useWindowDimensions();

  if (!messages || selectedCategory.category !== 'Drafts') return null;
  if (Object.values(drafts?.value ?? {}).length <= 0) {
    return (
      <Grid
        direction="column"
        container
        style={{
          padding: theme.spacing(2)
        }}>
        <Grid item>
          <Alert
            style={{ width: '100%' }}
            severity="warning"
            variant="filled"
            elevation={4}>
            <Typography>
              The<strong>{selectedCategory.category}</strong> Folder is
              Currently Empty. No Drafts to Display.
            </Typography>
          </Alert>
        </Grid>
        <Grid item>
          <div
            style={{
              maxHeight: `${height - 100}px`,
              overflowY: 'auto',
              overflowX: 'hidden'
            }}>
            <SkeletonThread amountToLoad={6} />
          </div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      style={{ maxHeight: '100%', overflowY: 'auto' }}
      spacing={3}>
      <Grid item xs={12}>
        {drafts &&
          Object.values(drafts.value).map(
            (conversation: ICustomMessage, idx) => {
              const conversationId = conversation.conversationId || '';
              const senderName = conversation.sender?.emailAddress?.name || '';
              const backgroundColor = getSenderColor(senderName);
              const subject = conversation.subject || '';
              const receivedDateTime = conversation.receivedDateTime || '';
              const deletionID = conversation?.id || '';
              const isHovered = hoveredCard === conversationId;

              const content = CustomDraft({
                senderName,
                backgroundColor,
                subject,
                receivedDateTime,
                isHovered,
                handleDelete: async () =>
                  handleDeleteMessages({ messageId: deletionID })
              });

              return CustomThreadHover({
                key: idx,
                messages: drafts,
                handleAdd: () => addComposeEmail(conversation),
                conversation: conversation,
                content
              });
            }
          )}
      </Grid>
    </Grid>
  );
};

export default DraftMessages;
