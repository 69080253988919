import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useSelector } from 'react-redux';
import { handleUpdateField } from '../../functions';
import CircularProgress from '@material-ui/core/CircularProgress';
import DividerCommon from 'common/DividerCommon';

const Component = (props) => {
  let { value, title } = props;
  const token = useSelector((s) => s.user.auth.token);
  const [updating, setUpdating] = React.useState(false);
  const onChange = handleUpdateField({ setUpdating, token, props });
  if (updating) return <CircularProgress />;
  return (
    <FormControlLabel
      disabled={props.readOnly}
      control={
        <div data-cy="read-accept-checkbox">
          <Checkbox
            checked={value === 'true'}
            onChange={(e) => !props.readOnly && onChange(e.target.checked)}
            name={title}
            color="primary"
          />
        </div>
      }
      label={title}
    />
  );
};

export default Component;
