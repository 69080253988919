import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemText,
  Menu,
  Tooltip,
  Typography,
  Select,
  MenuItem
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { theme } from 'theme';
import { GiCoins } from 'react-icons/gi';
import NumberFormat from 'react-number-format';

import { CarLogo } from 'components/AssetLogos';
import { useProcess } from 'hooks';
import { Asset } from 'types/calculatorInterfaces';
import { calculateQuantityValues } from '../Utils/logic';
import {
  AssetToRender,
  IListAssetPriceImporterProps,
  IImportationData,
  SelectedAssetsTotal,
  SelectedQuantities
} from './interface';
import useAssetComparison from './hooks/useAssetComparison';

const ITEM_HEIGHT = 160;

export const ListAssetPriceImporter = ({
  importPurchasePrice,
  setSnackBar,
  state
}: IListAssetPriceImporterProps) => {
  // all assets you have created
  const { assets, assetsDefs, currentDeal } = useProcess();

  // assets you have previously imported for this quote
  const importedAssets = state?.Assets;

  const initialQuantities = assets.reduce((acc, asset) => {
    acc[asset.ObjectInstanceId] = asset.Quantity;
    return acc;
  }, {});

  const initialTotal = {
    price: 0,
    non_VATable_item: 0,
    vat_Rate: 0,
    deposit: 0,
    vat: 0
  };

  const [assetsToRender, setAssetsToRender] = useState<AssetToRender[]>(
    assets as AssetToRender[]
  );
  const [selectedQuantities, setSelectedQuantities] =
    useState<SelectedQuantities>(initialQuantities);

  const [selectedAssets, setSelectedAssets] = useState<
    Asset['ObjectInstanceId'][]
  >([]);

  const [selectedAssetsTotal, setSelectedAssetsTotal] =
    useState<SelectedAssetsTotal>(initialTotal);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [mismatchedAssets, setMismatchedAssets] = useState<AssetToRender[]>([]);
  const [importation, setImportation] = useState<IImportationData | null>(null);
  useAssetComparison(assetsToRender, currentDeal, assetsDefs, setImportation);

  useEffect(() => {
    if (importation && importation.Assets) {
      const newMismatchedAssets = importation.Assets.filter((importedAsset) => {
        const matchingAsset = assetsToRender.find(
          (asset) => asset.ObjectInstanceId === importedAsset.ObjectInstanceId
        );
        return (
          matchingAsset &&
          JSON.stringify(importedAsset) !== JSON.stringify(matchingAsset)
        );
      });

      setMismatchedAssets(newMismatchedAssets);
    } else {
      setMismatchedAssets([]);
    }
  }, [anchorEl]);

  const handleReimportAsset = (assetToReimport: AssetToRender) => {
    const updatedAssets = assetsToRender.map((asset) =>
      asset.ObjectInstanceId === assetToReimport.ObjectInstanceId
        ? { ...asset, ...assetToReimport }
        : asset
    );

    setAssetsToRender(updatedAssets);

    const safeParseNumber = (value: any): number => {
      if (value === null || value === undefined) return 0;
      const parsed = typeof value === 'string' ? parseFloat(value) : value;
      return !isNaN(parsed) ? parsed : 0;
    };

    // Import the reimported asset into the calculation
    const assetForImport = {
      ...assetToReimport,
      isSelected: true,
      isImported: true
    };

    const price = safeParseNumber(assetForImport.Price);
    const nonVATableItem = safeParseNumber(assetForImport.Non_VATable_item);
    const vatRate = safeParseNumber(assetForImport.VAT_Rate);
    const deposit = safeParseNumber(assetForImport.Deposit);
    const vat = safeParseNumber(assetForImport.Vat);

    importPurchasePrice({
      value: {
        price,
        non_VATable_item: nonVATableItem,
        vat_Rate: vatRate,
        deposit,
        vat
      },
      type: 'both',
      asset: [assetForImport]
    });

    // Update importation state
    if (importation && importation.Assets) {
      const updatedImportationAssets = importation.Assets.filter(
        (ia) => ia.ObjectInstanceId !== assetToReimport.ObjectInstanceId
      );

      setImportation({
        ...importation,
        Assets: updatedImportationAssets,
        needsReporting: updatedImportationAssets.length > 0
      });
    }

    setSnackBar({
      open: true,
      message: `Asset ${assetToReimport.ObjectInstanceId} has been successfully reimported and added to the calculation`,
      variant: 'success'
    });
  };

  // initialQuantities should come from importedAssets if they exist (since they have the correct Quantity)
  useEffect(() => {
    if (!importedAssets) return;

    setSelectedQuantities((prevState) => {
      return {
        ...prevState,
        ...importedAssets.reduce((acc, asset) => {
          acc[asset.ObjectInstanceId] = asset.Quantity;
          return acc;
        }, {})
      };
    });
  }, [importedAssets]);

  useEffect(() => {
    const updatedAssets = assets
      .filter(
        (asset: Asset) =>
          asset.TotalPrice !== 0 &&
          !isNaN(asset.TotalPrice) &&
          !isNaN(asset.Non_VATable_item) &&
          !isNaN(asset.VAT_Rate) &&
          !isNaN(asset.Vat) &&
          !isNaN(asset.Deposit)
      )
      .map((asset) => {
        const importedAsset = importedAssets?.find(
          (a: Asset) => a.ObjectInstanceId === asset.ObjectInstanceId
        );

        const assetToUse = importedAsset || asset;

        const selectedQuantity =
          selectedQuantities[assetToUse.ObjectInstanceId];

        const { totalPrice, totalNon_VATable_item, totalDeposit, totalVat } =
          calculateQuantityValues(selectedQuantity, assetToUse);

        const isImported = !!importedAsset;
        const isSelected =
          isImported ||
          selectedAssets.some((id) => id === assetToUse.ObjectInstanceId);

        const newAsset: AssetToRender = {
          Price: assetToUse.Price,
          TotalPrice: totalPrice,
          Make: assetToUse.Make,
          AssetCategory: assetToUse.AssetCategory,
          Equipments: assetToUse.Equipments,
          Vehicles: assetToUse.Vehicles,
          Model: assetToUse.Model,
          Year: assetToUse.Year,
          Non_VATable_item: totalNon_VATable_item,
          ObjectInstanceId: assetToUse.ObjectInstanceId,
          Deposit: totalDeposit,
          VAT_Rate: assetToUse.VAT_Rate,
          Vat: totalVat,
          Quantity: selectedQuantity,
          MaxQuantity: assetToUse.Quantity,
          isSelected,
          isImported
        };

        return newAsset;
      });

    setAssetsToRender(updatedAssets);
  }, [importedAssets, selectedQuantities, selectedAssets]);

  useEffect(() => {
    const total = assetsToRender
      .filter((a) => a.isSelected)
      .reduce((acc, current) => {
        acc.price += current.TotalPrice;
        acc.non_VATable_item += current.Non_VATable_item;
        if (current.VAT_Rate > 0) acc.vat_Rate = current.VAT_Rate;
        acc.deposit += current.Deposit;
        acc.vat += current.Vat;

        return acc;
      }, initialTotal);

    setSelectedAssetsTotal(total);
  }, [assetsToRender]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleToggle = (newAssetId: Asset['ObjectInstanceId']) => () => {
    const selected = assetsToRender
      .filter((a) => a.isSelected)
      .map((a) => a.ObjectInstanceId);

    const checkedAssets = [...selected];

    const existingAsset = checkedAssets.find((id) => id === newAssetId);

    if (existingAsset) {
      const idx = checkedAssets.indexOf(existingAsset);
      checkedAssets.splice(idx, 1);
    } else checkedAssets.push(newAssetId);

    setSelectedAssets(checkedAssets);
  };

  const importAsset = ({
    value,
    type
  }: {
    value: SelectedAssetsTotal;
    type: 'price' | 'non_VATable_item' | 'both';
  }) => {
    const assetsToImport: Asset[] = assetsToRender
      .filter((a) => a.isSelected)
      .map(({ isSelected, isImported, MaxQuantity, ...rest }) => rest);

    // Include mismatched assets that haven't been reimported yet
    mismatchedAssets.forEach((mismatchedAsset) => {
      if (
        !assetsToImport.some(
          (a) => a.ObjectInstanceId === mismatchedAsset.ObjectInstanceId
        )
      ) {
        assetsToImport.push(mismatchedAsset);
      }
    });

    importPurchasePrice({ value, type, asset: assetsToImport });

    let messageParts = ['Successfully Added "Total Price"'];
    if (value.vat >= 1) messageParts.push('"VAT"');
    if (value.non_VATable_item >= 1) messageParts.push('"Non-VATable"');
    if (value.deposit >= 1) messageParts.push('"Deposit"');
    if (value.vat_Rate >= 1) messageParts.push('"VAT Rate"');
    messageParts.push('Into Calculation');

    setSnackBar({
      open: true,
      message: messageParts.join(' & '),
      variant: 'success'
    });

    handleClose();
  };

  const renderAssets = (assetsToRender: AssetToRender[]) =>
    assetsToRender.map((asset) => {
      const { isImported, isSelected, MaxQuantity } = asset;
      const mismatchedAsset = mismatchedAssets.find(
        (ma) => ma.ObjectInstanceId === asset.ObjectInstanceId
      );

      if (isImported) {
        return (
          <div key={asset.ObjectInstanceId}>
            {mismatchedAsset && (
              <div style={{ padding: theme.spacing(1) }}>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: theme.palette.warning.main,
                        color: 'white'
                      }}
                      size="small"
                      onClick={() => {
                        handleReimportAsset(mismatchedAsset);
                        setMismatchedAssets((prevMismatched) => {
                          const updatedAssets = prevMismatched.filter(
                            (a) =>
                              a.ObjectInstanceId !==
                              mismatchedAsset.ObjectInstanceId
                          );
                          return updatedAssets;
                        });
                      }}>
                      Reimport Asset
                    </Button>
                  }>
                  <Typography style={{ fontWeight: 'bold' }}>
                    Asset {asset.ObjectInstanceId} has differences with the
                    current data. Please reimport to update.
                  </Typography>
                </Alert>
              </div>
            )}

            <ListItem
              dense
              button
              style={{
                backgroundColor: theme.palette.success.light
              }}>
              <ListItemText
                key={asset.ObjectInstanceId}
                primary={
                  <div
                    style={{
                      position: 'relative',
                      width: '100%'
                    }}>
                    <Grid container direction="column">
                      <Grid
                        item
                        style={{
                          marginBottom: theme.spacing(1),
                          width: '50%'
                        }}>
                        <div
                          style={{
                            padding: 5,
                            borderRadius: theme.shape.borderRadius,
                            background: theme.palette.success.light,
                            color: theme.palette.success.main,
                            borderColor: theme.palette.success.main,
                            border: `2px solid ${theme.palette.success.main}`
                          }}>
                          <Typography
                            style={{
                              fontWeight: 'bold'
                            }}>
                            Selected & Imported Asset: {asset.ObjectInstanceId}
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>

                    <div
                      style={{
                        position: 'absolute',
                        top: 38,
                        right: 15
                      }}>
                      <CarLogo
                        Manufacturer={asset.Make}
                        AssetCategory={asset.AssetCategory}
                        Equipments={asset.Equipments}
                        Vehicles={asset.Vehicles}
                        style={{
                          fontSize: 50,
                          zIndex: 10,
                          borderRadius: theme.shape.borderRadius
                        }}
                      />
                    </div>

                    <Grid
                      container
                      style={{
                        border: 1,
                        borderWidth: '2px',
                        borderColor: theme.palette.success.main,
                        borderRadius: theme.shape.borderRadius,
                        borderStyle: 'solid',
                        padding: theme.spacing(1)
                      }}
                      direction="column">
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        {asset.Make !== '' && (
                          <Typography style={{ fontWeight: 'bold' }}>
                            Make:{' '}
                            <span
                              style={{
                                paddingLeft: theme.spacing(1),
                                fontWeight: 'normal'
                              }}>
                              {asset.Make}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        {asset.Model !== '' && (
                          <Typography style={{ fontWeight: 'bold' }}>
                            Model:{' '}
                            <span
                              style={{
                                paddingLeft: theme.spacing(1),
                                fontWeight: 'normal'
                              }}>
                              {asset.Model}
                            </span>
                          </Typography>
                        )}
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        {asset.Non_VATable_item > 0 && (
                          <>
                            <Typography style={{ fontWeight: 'bold' }}>
                              Non VATable Items:
                            </Typography>
                            <Typography
                              component="div"
                              style={{ paddingLeft: theme.spacing(1) }}>
                              <NumberFormat
                                value={asset.Non_VATable_item}
                                prefix={'£'}
                                thousandSeparator
                                displayType={'text'}
                                decimalScale={2}
                              />
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        {asset.Deposit > 0 && (
                          <>
                            <Typography style={{ fontWeight: 'bold' }}>
                              Deposit:
                            </Typography>
                            <Typography
                              component="div"
                              style={{ paddingLeft: theme.spacing(1) }}>
                              <NumberFormat
                                value={asset.Deposit}
                                prefix={'£'}
                                thousandSeparator
                                displayType={'text'}
                                decimalScale={2}
                              />
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Price Per Unit:
                        </Typography>
                        <Typography
                          component="div"
                          style={{ paddingLeft: theme.spacing(1) }}>
                          <NumberFormat
                            value={asset.Price}
                            prefix={'£'}
                            thousandSeparator
                            displayType={'text'}
                            decimalScale={2}
                          />
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Quantity
                        </Typography>
                        <Typography style={{ paddingLeft: theme.spacing(1) }}>
                          {asset.Quantity}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        {asset.Vat > 0 && (
                          <>
                            <Typography style={{ fontWeight: 'bold' }}>
                              Total Vat:
                            </Typography>
                            <Typography
                              component="div"
                              style={{ paddingLeft: theme.spacing(1) }}>
                              <NumberFormat
                                value={asset.Vat}
                                prefix={'£'}
                                thousandSeparator
                                displayType={'text'}
                                decimalScale={2}
                              />
                            </Typography>
                          </>
                        )}
                      </Grid>
                      <Grid
                        item
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Total Price:
                        </Typography>
                        <Typography
                          component="div"
                          style={{ paddingLeft: theme.spacing(1) }}>
                          <NumberFormat
                            value={asset.TotalPrice}
                            prefix={'£'}
                            thousandSeparator
                            displayType={'text'}
                            decimalScale={2}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                }
              />
            </ListItem>
          </div>
        );
      } else {
        return (
          <ListItem
            key={asset.ObjectInstanceId}
            dense
            button
            onClick={handleToggle(asset.ObjectInstanceId)}
            style={{
              backgroundColor: isSelected
                ? theme.palette.success.light
                : 'transparent'
            }}>
            <Checkbox
              data-cy="import-assets-checkbox"
              edge="start"
              checked={isSelected}
              tabIndex={-1}
              disableRipple
              style={{
                color: isSelected ? theme.palette.success.main : 'gray'
              }}
            />
            <ListItemText
              key={asset.ObjectInstanceId}
              primary={
                <div
                  style={{
                    position: 'relative',
                    width: '100%'
                  }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: 15,
                      right: 15
                    }}>
                    <CarLogo
                      Manufacturer={asset.Make}
                      AssetCategory={asset.AssetCategory}
                      Equipments={asset.Equipments}
                      Vehicles={asset.Vehicles}
                      style={{
                        fontSize: 50,
                        zIndex: 10,
                        borderRadius: theme.shape.borderRadius
                      }}
                    />
                  </div>

                  <Grid
                    container
                    style={{
                      border: 1,
                      borderWidth: isSelected ? 3 : 2,
                      borderColor: isSelected
                        ? theme.palette.success.main
                        : 'grey',
                      borderRadius: theme.shape.borderRadius,
                      borderStyle: 'solid',
                      padding: theme.spacing(1)
                    }}
                    direction="column">
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Asset ID:
                      </Typography>
                      <Typography style={{ paddingLeft: theme.spacing(1) }}>
                        {asset.ObjectInstanceId}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      {asset.Make !== '' && (
                        <Typography style={{ fontWeight: 'bold' }}>
                          Make:{' '}
                          <span
                            style={{
                              paddingLeft: theme.spacing(1),
                              fontWeight: 'normal'
                            }}>
                            {asset.Make}
                          </span>
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      {asset.Model !== '' && (
                        <Typography style={{ fontWeight: 'bold' }}>
                          Model:{' '}
                          <span
                            style={{
                              paddingLeft: theme.spacing(1),
                              fontWeight: 'normal'
                            }}>
                            {asset.Model}
                          </span>
                        </Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      {asset.Deposit > 0 && (
                        <>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Deposit:
                          </Typography>
                          <Typography
                            component="div"
                            style={{ paddingLeft: theme.spacing(1) }}>
                            <NumberFormat
                              value={asset.Deposit}
                              prefix={'£'}
                              thousandSeparator
                              displayType={'text'}
                              decimalScale={2}
                            />
                          </Typography>
                        </>
                      )}
                    </Grid>

                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      {asset.Non_VATable_item > 0 && (
                        <>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Non VATable Items:
                          </Typography>
                          <Typography
                            component="div"
                            style={{ paddingLeft: theme.spacing(1) }}>
                            <NumberFormat
                              value={asset.Non_VATable_item}
                              prefix={'£'}
                              thousandSeparator
                              displayType={'text'}
                              decimalScale={2}
                            />
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Price Per Unit:
                      </Typography>
                      <Typography
                        component="div"
                        style={{ paddingLeft: theme.spacing(1) }}>
                        <NumberFormat
                          value={asset.Price}
                          prefix={'£'}
                          thousandSeparator
                          displayType={'text'}
                          decimalScale={2}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      {asset.Vat > 0 && (
                        <>
                          <Typography style={{ fontWeight: 'bold' }}>
                            Total Vat:
                          </Typography>
                          <Typography
                            component="div"
                            style={{ paddingLeft: theme.spacing(1) }}>
                            <NumberFormat
                              value={asset.Vat}
                              prefix={'£'}
                              thousandSeparator
                              displayType={'text'}
                              decimalScale={2}
                            />
                          </Typography>
                        </>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Total Price:
                      </Typography>
                      <Typography
                        component="div"
                        style={{ paddingLeft: theme.spacing(1) }}>
                        <NumberFormat
                          value={asset.TotalPrice}
                          prefix={'£'}
                          thousandSeparator
                          displayType={'text'}
                          decimalScale={2}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        Quantity
                      </Typography>
                      <Select
                        key={asset.ObjectInstanceId}
                        value={asset.Quantity}
                        style={{
                          color: 'black',
                          paddingLeft: theme.spacing(1)
                        }}
                        onChange={(e) => {
                          e.stopPropagation();

                          setSelectedQuantities((prevState) => ({
                            ...prevState,
                            [asset.ObjectInstanceId]: e.target.value as number
                          }));
                        }}>
                        {Array.from({ length: MaxQuantity }, (_, i) => {
                          const quantity = i + 1;
                          return (
                            <MenuItem
                              key={`${asset.ObjectInstanceId}-${quantity}`}
                              value={quantity}>
                              {quantity}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>
                  </Grid>
                </div>
              }
            />
          </ListItem>
        );
      }
    });

  return (
    <div>
      <Button
        data-cy="import-assets-btn"
        variant="contained"
        color="primary"
        onClick={handleClick}>
        Import from Assets
        <GiCoins style={{ fontSize: 30 }} />
      </Button>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            minWidth: '70ch',
            overflow: 'auto'
          }
        }}>
        <div style={{ maxHeight: ITEM_HEIGHT * 3.5, overflow: 'auto' }}>
          <List>{renderAssets(assetsToRender)}</List>
        </div>

        <Alert
          severity="info"
          style={{
            marginBottom: '-8px',
            borderStartStartRadius: 0,
            borderStartEndRadius: 0
          }}>
          {selectedAssets.length <= 0 ? (
            <Typography style={{ fontWeight: 'bold' }}>
              Please Select a Asset to Import into Calculation
            </Typography>
          ) : (
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <div>
                  <Tooltip
                    arrow
                    title={`Selecting 'Import Selected Assets' Will Add ${
                      selectedAssetsTotal.non_VATable_item >= 1
                        ? "'Non-VATable Item'"
                        : ''
                    }${
                      selectedAssetsTotal.deposit >= 1
                        ? (selectedAssetsTotal.non_VATable_item >= 1
                            ? ', '
                            : '') + "'Deposit'"
                        : ''
                    }${
                      selectedAssetsTotal.vat_Rate >= 1
                        ? (selectedAssetsTotal.non_VATable_item >= 1 ||
                          selectedAssetsTotal.deposit >= 1
                            ? ', '
                            : '') + "'VAT Rate'"
                        : ''
                    } Into The Calculation.`}>
                    <Button
                      data-cy="import-selected-assets-btn"
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        importAsset({
                          value: selectedAssetsTotal,
                          type: 'both'
                        })
                      }>
                      Import Selected Assets
                    </Button>
                  </Tooltip>
                </div>
              </Grid>
              <Grid item>
                <div>
                  <Button
                    disabled
                    component={'div'}
                    variant="contained"
                    color="primary">
                    Total Price:
                    <NumberFormat
                      value={selectedAssetsTotal.price}
                      prefix={'£'}
                      thousandSeparator
                      displayType={'text'}
                      decimalScale={2}
                      style={{ paddingLeft: theme.spacing(1) }}
                    />
                  </Button>
                </div>
              </Grid>
              {selectedAssetsTotal.vat >= 1 && (
                <Grid item>
                  <div>
                    <Button
                      disabled
                      component={'div'}
                      variant="contained"
                      color="primary">
                      Total Vat:
                      <NumberFormat
                        value={selectedAssetsTotal.vat}
                        prefix={'£'}
                        thousandSeparator
                        displayType={'text'}
                        decimalScale={2}
                        style={{ paddingLeft: theme.spacing(1) }}
                      />
                    </Button>
                  </div>
                </Grid>
              )}
              {selectedAssetsTotal.non_VATable_item >= 1 && (
                <Grid item>
                  <div>
                    <Button
                      disabled
                      component={'div'}
                      variant="contained"
                      color="primary">
                      Non VATable:
                      <NumberFormat
                        value={selectedAssetsTotal.non_VATable_item}
                        prefix={'£'}
                        thousandSeparator
                        displayType={'text'}
                        decimalScale={2}
                        style={{ paddingLeft: theme.spacing(1) }}
                      />
                    </Button>
                  </div>
                </Grid>
              )}
              {selectedAssetsTotal.deposit >= 1 && (
                <Grid item>
                  <div>
                    <Button
                      disabled
                      component={'div'}
                      variant="contained"
                      color="primary">
                      Deposit:
                      <NumberFormat
                        value={selectedAssetsTotal.deposit}
                        prefix={'£'}
                        thousandSeparator
                        displayType={'text'}
                        decimalScale={2}
                        style={{ paddingLeft: theme.spacing(1) }}
                      />
                    </Button>
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </Alert>
      </Menu>
    </div>
  );
};
