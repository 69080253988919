import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import { GET_COMPLETE_OBJECT_INSTANCE } from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import { UPDATE_OBJECT } from 'graphql/UserInstanceAPI/mutations';
import { CompleteObjectInstance, FieldInstance } from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import { removeTypenames } from '../User Instance API/mutations';

/**
 * Updates an CompleteObjectInstanceList using the provided parameters.
 * @param {string} baseUrl - The base URL for the hostname.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to update.
 * @param {string} action - The action to perform.
 * @param {CompleteObjectInstance} data - The data object containing the CompleteObjectInstanceList information.
 * @returns {Promise<CompleteObjectInstance | ApolloError>} - A promise resolving to the updated CompleteObjectInstanceList or an ApolloError.
 */
export const UpdateObject = async ({
  baseUrl,
  ProcessInstanceId,
  action,
  data: payload,
  fetchPolicy
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  action: string;
  data: CompleteObjectInstance;
  fetchPolicy?: 'cache-first' | 'network-only' | 'no-cache';
}): Promise<CompleteObjectInstance | ApolloError> => {
  try {
    const newData = removeTypenames(payload);
    const cleanedPayload = cleanData(newData as CompleteObjectInstance);
    const processedPayload = ensureUserInstanceIdsAreInts(cleanedPayload);
    const finalPayload = ensureFieldValuesAreStrings(processedPayload);

    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_OBJECT,
      variables: {
        baseUrl,
        processInstanceId: ProcessInstanceId,
        action,
        data: finalPayload
      },
      refetchQueries: [
        {
          query: GET_COMPLETE_OBJECT_INSTANCE,
          variables: {
            baseUrl,
            userInstanceId: finalPayload.ObjectInstance.UserInstanceId
          }
        }
      ],
      awaitRefetchQueries: true
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    return data.UpdateObject as CompleteObjectInstance;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Cleans the given CompleteObjectInstance by removing the FieldInstanceDict field.
 *
 * @param {CompleteObjectInstance} data - The CompleteObjectInstance to be cleaned.
 * @returns {CompleteObjectInstance} The cleaned CompleteObjectInstanceList with the FieldInstanceDict removed if it was present.
 */

const cleanData = (data: CompleteObjectInstance): CompleteObjectInstance => {
  if (data.FieldInstanceDict) {
    const { FieldInstanceDict, ...cleanedData } = data;
    return cleanedData;
  }
  return data;
};

/**
 * Ensures that UserInstanceId inside the given CompleteObjectInstance and its FieldInstances are integers.
 *
 * @param {CompleteObjectInstance} data - The CompleteObjectInstance to be processed.
 * @returns {CompleteObjectInstance} The processed CompleteObjectInstance with UserInstanceId converted to integers where applicable.
 */

export const ensureUserInstanceIdsAreInts = (
  data: CompleteObjectInstance
): CompleteObjectInstance => {
  const processFieldInstances = (
    FieldInstances: FieldInstance[]
  ): FieldInstance[] => {
    return FieldInstances.map((FieldInstance: FieldInstance) => {
      if (
        typeof FieldInstance.UserInstanceId === 'string' &&
        !isNaN(parseInt(FieldInstance.UserInstanceId))
      ) {
        return {
          ...FieldInstance,
          UserInstanceId: parseInt(FieldInstance.UserInstanceId),
          UserDefinitionId: parseInt(
            FieldInstance.UserDefinitionId as unknown as string
          )
        };
      }
      return FieldInstance;
    });
  };

  if (
    data.ObjectInstance &&
    typeof data.ObjectInstance.UserInstanceId === 'string' &&
    !isNaN(parseInt(data.ObjectInstance.UserInstanceId))
  ) {
    data.ObjectInstance.UserInstanceId = parseInt(
      data.ObjectInstance.UserInstanceId
    );
  }

  if (data.FieldInstanceList) {
    data.FieldInstanceList = processFieldInstances(data.FieldInstanceList);
  }
  return data;
};

/**
 * Ensures that all FieldValues in the given CompleteObjectInstance are strings.
 *
 * @param {CompleteObjectInstance} data - The CompleteObjectInstance to be processed.
 * @returns {CompleteObjectInstance} The processed CompleteObjectInstance with FieldValues converted to strings where applicable.
 */

const ensureFieldValuesAreStrings = (
  data: CompleteObjectInstance
): CompleteObjectInstance => {
  const processFieldInstances = (
    FieldInstances: FieldInstance[]
  ): FieldInstance[] => {
    return FieldInstances.map((FieldInstance: FieldInstance) => {
      if (
        FieldInstance.FieldValue !== undefined &&
        FieldInstance.FieldValue !== null &&
        typeof FieldInstance.FieldValue !== 'string'
      ) {
        return {
          ...FieldInstance,
          FieldValue: String(FieldInstance.FieldValue)
        };
      }
      return FieldInstance;
    });
  };

  if (data.FieldInstanceList) {
    data.FieldInstanceList = processFieldInstances(data.FieldInstanceList);
  }
  return data;
};
