import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import app from '../../package.json';
import { store } from 'redux/store';
const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

export const BugTracker: any = Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
  plugins: [new BugsnagPluginReact()],
  appVersion: app.version,
  releaseStage: process.env.NODE_ENV,
  onError: (e) =>
    e.addMetadata('account', {
      type: hostname,
      name: store.getState().user.user.Title,
      email: store.getState().user.user.UserInstanceEmail,
      id: store.getState().user.user.Id
    })
});
