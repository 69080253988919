import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  topbar: {
    position: 'sticky',
    width: '100%',
    top: 0,
    right: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  topbarShift: {
    marginLeft: '270px',
    width: 'calc(-270px + 100vw)',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '70px',
      width: 'calc(-70px + 100vw)'
    }
  },
  drawerPaper: {
    zIndex: 9,
    width: '270px',
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      width: '70px'
    }
  },
  // sidebar: { width: '280px' },
  content: {
    minHeight: '100vh',
    // marginTop: '64px',
    // paddingBottom: '64px',
    background: `linear-gradient(to right bottom, ${theme.palette.background.default} 50%, ${theme.palette.primary.light})`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    marginLeft: '270px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '70px',
      width: 'calc(-70px + 100vw)'
    }
  },
  fab: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2)
  }
}));
