import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  CompleteProcessInstance,
  FieldDefinition,
  FieldInstance,
  ObjectDefinition,
  ObjectInstance,
  UserInstance
} from 'types/interfaces';
import { useTypedSelector } from 'redux/reducers';
import { SET_CURRENT_DEAL } from 'redux/actions/types';
import { useDispatch } from 'react-redux';
import { updateFieldInstance } from 'redux/actions/GraphQlActions';
import { useProcess } from 'hooks';
import { useField } from 'hooks/useField';
import { selectObjectInstance } from 'redux/actions/objectUpdater';
import { useState } from 'react';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

const IndicativeQuoteSelectionObject = 3613;
const IndicativeQuoteSelect = 22712;
const IndicativeQuoteObjectDefinitionId = 3565;

interface ISelectedProps {
  ObjectDefinition: ObjectDefinition;
  UserInstance: UserInstance;
  ProcessInstanceId: number;
  Fields: FieldDefinition[] | Record<string, never> | undefined;
  ProcessStepSelectionType: string;
}

export const useIndicativeQuoteSelection = (
  displayObjects: CompleteObjectDefinition[]
) => {
  const dispatch = useDispatch();

  const { createNewObjectAndAllFields } = useField();
  const { stepdefdict, user } = useProcess();

  const { token } = useTypedSelector((s) => s.user.auth);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { currentDeal, currentOverView, currentProcess } = useTypedSelector(
    (s) => s.process
  );

  const [updating, setUpdating] = useState<boolean>(false);

  const selectObject = async ({
    object,
    IndicativeCOI,
    localCompleteObjectInstance
  }: {
    object: ObjectInstance;
    IndicativeCOI?: CompleteObjectInstance;
    localCompleteObjectInstance?: CompleteObjectInstance;
  }) => {
    if (IndicativeCOI || localCompleteObjectInstance) {
      const FieldInstanceList = getFieldInstances(
        IndicativeCOI || localCompleteObjectInstance
      );

      const FindFieldInstance = FieldInstanceList.find(
        (FI: FieldInstance) => FI.FieldDefinitionId === IndicativeQuoteSelect
      );

      if (FindFieldInstance) {
        FindFieldInstance.FieldValue = object.Id.toString();

        const response = await updateFieldInstance({
          baseUrl,
          data: FindFieldInstance,
          ProcessInstanceId: object.ProcessInstanceId
        });

        if (response) {
          updateDealData({
            currentDeal,
            FindFieldInstance,
            localCompleteObjectInstance
          });
        }
      }
    }
  };

  const updateDealData = ({
    currentDeal,
    FindFieldInstance,
    localCompleteObjectInstance
  }: {
    currentDeal: CompleteProcessInstance;
    FindFieldInstance: FieldInstance;
    localCompleteObjectInstance?: CompleteObjectInstance;
  }) => {
    const updatedDeal: CompleteProcessInstance = JSON.parse(
      JSON.stringify(currentDeal)
    );

    if (localCompleteObjectInstance) {
      const objectId = localCompleteObjectInstance.ObjectInstance.Id;
      updatedDeal.CompleteObjectInstanceDict[objectId] =
        localCompleteObjectInstance;
    }

    const selectedId = parseInt(FindFieldInstance.FieldValue);
    const SelectedIndicativeCOI =
      updatedDeal.CompleteObjectInstanceDict[selectedId];

    if (SelectedIndicativeCOI) {
      Object.values(updatedDeal.CompleteObjectInstanceDict).forEach(
        (element: CompleteObjectInstance) => {
          if (
            element.ObjectInstance.ObjectDefinitionId ===
            IndicativeQuoteObjectDefinitionId
          ) {
            element.ObjectInstance.Selected = false;
          }
        }
      );

      SelectedIndicativeCOI.ObjectInstance.Selected = true;
    }

    dispatch({ type: SET_CURRENT_DEAL, payload: updatedDeal });
  };

  const createNewObject = async () => {
    const CompleteObjectDefinition2 = displayObjects.find(
      (COD: CompleteObjectDefinition) =>
        COD.ObjectDefinition.Id === IndicativeQuoteSelectionObject
    );

    if (!CompleteObjectDefinition2) {
      console.error('CompleteObjectDefinition not found');
      return;
    }

    const props = {
      ProcessStepSelectionType:
        stepdefdict.ProcessStepDefinition.ProcessStepSelectionType,
      ObjectDefinition: CompleteObjectDefinition2.ObjectDefinition,
      fields: CompleteObjectDefinition2.FieldDefinitionList,
      ProcessInstance: currentDeal.ProcessInstance,
      FieldDefinition: { Id: 0 },
      ProcessInstanceId: currentOverView.ProcessInstanceId,
      ProcessDefinitionId: currentProcess?.ProcessDefinition?.Id
    };

    const response = await createNewObjectAndAllFields({
      setUpdating,
      token,
      props,
      val: '',
      UserInstance: user
    });

    return response;
  };

  const handleSelectObjectInstance = async ({
    shouldCreateNew,
    CompleteObjectInstance,
    CompleteObjectDefinition
  }: {
    shouldCreateNew: boolean;
    CompleteObjectInstance: CompleteObjectInstance;
    CompleteObjectDefinition: CompleteObjectDefinition;
  }) => {
    const SelectedProps: ISelectedProps = {
      ObjectDefinition: CompleteObjectDefinition.ObjectDefinition,
      UserInstance: user,
      ProcessInstanceId: currentDeal.ProcessInstance.Id,
      Fields: CompleteObjectDefinition.FieldDefinitionList,
      ProcessStepSelectionType:
        stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType
    };

    const res = await selectObjectInstance({
      token,
      data: CompleteObjectInstance,
      props: SelectedProps,
      action: 'SELECT'
    });

    const newObject: ObjectInstance =
      (res && res[0]?.['PromiseResult']) ||
      CompleteObjectInstance.ObjectInstance;

    if (shouldCreateNew) {
      const response = await createNewObject();

      await selectObject({
        object: newObject,
        localCompleteObjectInstance: response
      });
    } else if (!newObject.Selected) {
      const IndicativeCOI = Object.values(
        currentDeal.CompleteObjectInstanceDict
      ).find(
        (COI: CompleteObjectInstance) =>
          COI.ObjectInstance.ObjectDefinitionId ===
          IndicativeQuoteSelectionObject
      );
      await selectObject({ object: newObject, IndicativeCOI });
    }
  };

  return {
    selectObject,
    createNewObject,
    handleSelectObjectInstance,
    updating
  };
};
