import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from 'components/Fields/TextField';
import { useHistory } from 'react-router-dom';
import { createDeal, getProcessDefinition } from 'redux/actions/processes';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme) => ({
  content: { padding: theme.spacing(2) },
  appBar: { position: 'sticky' },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    color: theme.palette.primary.contrastText
  },
  dialog: {},
  primaryFields: {
    padding: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    minHeight: 500
  },
  sectionTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 16
  }
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Component = ({ definition, steps, UserInstanceId }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector<any>((s) => s.user.auth.token);
  const currentProcess: any = useTypedSelector<any>(
    (state) => state.process.currentProcess
  );

  const { user } = useTypedSelector((s) => s.user);

  const open = true;
  const [selected, setSelected] = React.useState(null);
  const [dealName, setDealName] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const handleClose = () => history.push('/deals');
  const handleChange = (value) => setDealName(value);
  const nextStep = currentProcess.ProcessStepDefinitionSequence[0];
  const deal = makeNewDeal({ dealName, definition, nextStep, selected });

  const handleCreateDeal = async () => {
    setLoading(true);

    const submitobj: {
      ProcessDefinitionId: string;
      ProcessStepDefinitionId?: number;
    } = {
      ProcessDefinitionId: deal.ProcessInstance.ProcessDefinitionId,
      ProcessStepDefinitionId: deal.ProcessInstance.ProcessStepDefinitionId
    };

    await getProcessDefinition(submitobj);

    let dealInRedux = await createDeal({
      deal,
      UserInstanceId,
      definition,
      userId: user.Id
    });

    if (dealInRedux) {
      history.push('/deals');
      setLoading(false);
    }
  }; //END handleCreateDeal

  React.useEffect(() => {
    dispatch({ type: 'SET_CURRENT_DEAL', payload: deal });
  }, []);

  return (
    <Dialog
      scroll="body"
      fullWidth
      // maxWidth="md"
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={classes.dialog}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close">
            <CloseIcon />
          </IconButton>

          <Typography variant="h6" className={classes.title}>
            Create New: {definition.Title}
          </Typography>

          <DialogActions>
            {loading ? (
              <CircularProgress color="inherit" />
            ) : (
              <Button
                data-cy="create-user-button"
                onClick={handleCreateDeal}
                color="secondary"
                variant="contained">
                Create
              </Button>
            )}
          </DialogActions>
        </Toolbar>
      </AppBar>
      <Grid
        container
        justifyContent="space-around"
        className={classes.primaryFields}>
        <Grid item style={{ width: 300 }}>
          <TextField
            autoFocus
            margin="dense"
            title="Deal Name"
            type="text"
            fullWidth
            required
            handleChange={handleChange}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Component;

const makeNewDeal = ({ dealName, definition, nextStep, selected }) => {
  const selectedIsArray = Array.isArray(selected);
  let NextStepUserInstanceIdDict = {};
  if (selected && selectedIsArray) {
    selected.forEach((el) => (NextStepUserInstanceIdDict[el.value] = el.label));
  }
  if (selected && !selectedIsArray) {
    NextStepUserInstanceIdDict[selected.value] = selected.label;
  }

  return {
    ProcessInstance: {
      Title: dealName,
      ProcessDefinitionId: definition.Id,
      ProcessStepDefinitionId: nextStep
    },
    NextStepUserInstanceIdDict
  };
};
