import { Theme, makeStyles } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';

export const useProposalFieldStyles = makeStyles((theme: Theme) => ({
  fieldContainer: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  field: {
    width: '100%',
    '& .MuiInputBase-root': {
      width: '100%',
      borderRadius: theme.shape.borderRadius
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey[300]
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      }
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.text.secondary
    },
    '& .Mui-focused .MuiInputLabel-outlined': {
      color: theme.palette.primary.main
    }
  },
  phoneField: {
    '& .react-tel-input .form-control': {
      width: '100%',
      height: '56px',
      fontSize: '16px',
      borderRadius: '4px',
      '&:focus': {
        borderColor: theme.palette.primary.main,
        boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
      }
    },
    '& .react-tel-input .special-label': {
      display: 'none'
    }
  },
  readonlyField: {
    '& .MuiOutlinedInput-root': {
      backgroundColor: grey[100]
    }
  },
  checkboxRoot: {
    color: theme.palette.grey[400],
    '&$checked': {
      color: theme.palette.success.main
    }
  },
  checked: {},
  checkboxLabel: {
    margin: 0,
    width: '100%',
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.secondary,
      fontSize: '0.9rem'
    }
  },
  requiredSymbol: {
    marginRight: theme.spacing(1),
    color: theme.palette.warning.main
  },
  checkSymbol: {
    marginRight: theme.spacing(1),
    color: theme.palette.success.main
  },
  requiredField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.warning.main,
        borderWidth: 2
      },
      '&:hover fieldset': {
        borderColor: theme.palette.warning.dark
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.warning.dark
      }
    }
  },

  completedField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.success.main,
        borderWidth: 2
      },
      '&:hover fieldset': {
        borderColor: theme.palette.success.dark
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.success.dark
      }
    }
  },

  infoAlert: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& > *:first-child': {
      marginRight: theme.spacing(1)
    }
  },
  errorField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.error.main
      }
    }
  },
  readonlyCheckbox: {
    backgroundColor: grey[100],
    cursor: 'not-allowed',
    opacity: 0.7,
    '&:hover': {
      borderColor: theme.palette.grey[300]
    },
    '& .MuiCheckbox-root': {
      cursor: 'not-allowed'
    },
    '& .MuiFormControlLabel-label': {
      color: theme.palette.text.disabled
    }
  },
  checkboxContainer: {
    width: '100%',
    height: '56px',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    position: 'relative',
    '&:hover': {
      borderColor: theme.palette.primary.main
    }
  },
  lockContainer: {
    marginLeft: 'auto',
    paddingLeft: theme.spacing(1),
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  iconContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center'
  }
}));

export const useProposalHeaderStyles = makeStyles((theme) => ({
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

  actionButtons: {
    display: 'flex',
    alignItems: 'center'
  },
  submitButton: {
    marginRight: theme.spacing(1)
  },
  deleteIcon: {
    marginLeft: theme.spacing(1)
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2)
  },
  statusLabel: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap'
  },
  statusSelect: {
    minWidth: 120,
    '& .MuiSelect-select': {
      display: 'flex',
      alignItems: 'center',
      fontSize: theme.typography.h6.fontSize
    }
  },
  statusReadOnly: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.action.disabledBackground
  },
  statusValue: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  selectLabel: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(1),
    flexShrink: 0
  },
  statusBall: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginRight: theme.spacing(1)
  },
  statusIN_PROGRESS: { backgroundColor: theme.palette.info.main },
  statusUNDER_REVIEW: { backgroundColor: theme.palette.warning.main },
  statusAPPROVED: { backgroundColor: theme.palette.success.main },
  statusAPPROVED_EXPIRED: { backgroundColor: theme.palette.error.light },
  statusCANCELLED: { backgroundColor: theme.palette.error.main },
  statusDEPOSITED: { backgroundColor: theme.palette.success.dark },
  statusFAILED: { backgroundColor: theme.palette.error.dark },
  statusTEST: { backgroundColor: theme.palette.grey[500] },
  statusDRAFT: { backgroundColor: theme.palette.grey[500] },
  statusACTION_REQUIRED: { backgroundColor: theme.palette.warning.dark },
  statusDECISION_MADE: { backgroundColor: theme.palette.success.light },
  leftBottomRow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start'
  },
  rightBottomRow: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  bottomRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  uploadButton: {
    marginLeft: theme.spacing(2),
    whiteSpace: 'nowrap'
  },
  decisionPENDING: { backgroundColor: theme.palette.warning.main },
  decisionAPPROVED: { backgroundColor: theme.palette.success.main },
  decisionREJECTED: { backgroundColor: theme.palette.error.main },
  decisionACTION_REQUIRED: { backgroundColor: theme.palette.error.main },
  decisionOFFERED: { backgroundColor: theme.palette.info.light }
}));

export const useProposalFormStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%'
  },
  accordion: {
    '&.Mui-expanded': {
      margin: `${theme.spacing(1)}px 0`
    }
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightMedium
  },
  content: {
    padding: theme.spacing(2),
    width: '100%'
  },
  arraySection: {
    marginTop: theme.spacing(2)
  },
  arrayItem: {
    backgroundColor: theme.palette.background.default,
    boxShadow: `0 0 0 1px ${theme.palette.divider}`,
    '&.Mui-expanded': {
      margin: `${theme.spacing(2)}px 0`
    }
  },
  arrayItemContent: {
    borderLeft: `4px solid ${theme.palette.info.main}`
  },
  fieldGrid: {
    padding: theme.spacing(1)
  },
  fieldWrapper: {
    width: '100%'
  },
  objectSection: {
    padding: theme.spacing(2),
    border: `2px solid ${theme.palette.info.light}`,
    borderRadius: theme.shape.borderRadius
  },
  objectTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(0.5)
  },
  warningContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  warningText: {
    color: theme.palette.warning.main,
    fontWeight: 'bold',
    fontSize: '0.9rem'
  },
  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: '1rem'
  },
  errorText: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    fontSize: '0.9rem'
  },
  errorIcon: {
    color: theme.palette.error.main,
    fontSize: '1rem'
  },
  iconContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing(0.5)
  },
  deleteButton: {
    marginLeft: theme.spacing(1)
  },
  arrayItemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  }
}));
