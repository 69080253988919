import { LandingPageUserViews } from './LandingPageUserViews';
import { useProcess } from 'hooks/useProcess';
import SystemUserView from './SystemUserView';

const ViewSelector = () => {
  const { landingpage } = useProcess();
  if (landingpage) return <LandingPageUserViews />;
  return <SystemUserView />;
};

export default ViewSelector;
