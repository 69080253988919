import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * PROCESS DEFINITION API
 */
export const GetProductTypes = async ({ action }) => {
  let url = `ProcessDefinitionAPI.ashx?`;
  if (action) url = `ProcessDefinitionAPI.ashx?action=${action}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetProcessDefinitionLite = async ({ processDefinitionId }) => {
  let url = `ProcessDefinitionAPI.ashx?processdefinitionid=${processDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetProcessDefinition = async ({
  ProcessDefinitionId,
  ProcessStepDefinitionId
}: {
  ProcessDefinitionId: any;
  ProcessStepDefinitionId?: number;
}) => {
  let url = `ProcessDefinitionAPI.ashx?processdefinitionid=${ProcessDefinitionId}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  if (!ProcessStepDefinitionId)
    url = `ProcessDefinitionAPI.ashx?processdefinitionid=${ProcessDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetUsersAvailableProcesses = async ({ UserInstanceId }) => {
  let url = `ProcessDefinitionAPI.ashx?&action=LISTFORUSER&userinstanceid=${UserInstanceId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetProcessesByUserDefinition = async ({
  UserDefinitionId
}: {
  token: string;
  UserDefinitionId: string;
}) => {
  let url = `ProcessDefinitionAPI.ashx?&action=LISTFORUSERDEFINITION&userdefinitionid=${UserDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetDealColourList = async ({
  UserInstanceId
}: {
  UserInstanceId: number;
}) => {
  let url = `ProcessDefinitionAPI.ashx?&action=LISTFORUSERWITHCOLORS&userinstanceid=${UserInstanceId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};
