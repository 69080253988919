import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ActiveStep from './ActiveStep';
import { PastStep } from './PastStep';
import { useProcess } from 'hooks/useProcess';

const useStyles = makeStyles((theme) => ({
  content: { padding: theme.spacing(2) }
}));

interface UserInstanceForStep {
  ProcessInstanceId: number;
  ProcessDefinitionId: number;
  Selected: boolean;
  UserDefinitionId: number;
  UserInstanceId: number;
}

export type TRelationShipAction =
  | 'LISTFORKEYWORD'
  | 'DELETE'
  | 'POST'
  | 'LIST-ALL'
  | 'LIST'
  | 'DICT'
  | 'DICT-ALL'
  | 'DEFINE'
  | 'UPDATE-STATUS';

export const Steps = () => {
  const { currentStep, user, landingpage, stepdefdict, currentDeal } =
    useProcess();
  const { UserInstanceListForCurrentStep } = currentStep;
  const isAssignedToStep = UserInstanceListForCurrentStep.find(
    (el: UserInstanceForStep) => el.UserInstanceId === user.Id
  );

  const isProcessOwner = currentDeal.ProcessInstance.UserInstanceId === user.Id;

  const onBehalfOfStep: boolean | undefined =
    stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType ===
    'OnBehalfOf';

  const { SystemAccess } = user;

  const onBehalfOfIfSystemUserAndAbove = onBehalfOfStep && SystemAccess >= 5;

  const canSeeStep =
    onBehalfOfIfSystemUserAndAbove ||
    landingpage ||
    isAssignedToStep ||
    isProcessOwner;

  if (canSeeStep) return <VisibleStep />;
  return <HiddenStep />;
};

const Wrapper = ({ children }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.content} elevation={0}>
      {children}
    </Paper>
  );
};

const VisibleStep = () => (
  <Wrapper>
    <ActiveStep />
  </Wrapper>
);

const HiddenStep = () => (
  <Wrapper>
    <PastStep />
  </Wrapper>
);
