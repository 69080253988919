import { ForValue, FromValue } from '../interfaces';

export const setForValueFromCalculationNo: (num: number) => {
  newforValue: ForValue;
  newfromValue: FromValue;
} = (num) => {
  let newforValue = '' as ForValue;
  let newfromValue = '' as FromValue;

  switch (num) {
    case 16:
      newforValue = ForValue.ForAmount;
      newfromValue = FromValue.FromNominalFlatRate;
    case 15:
      newforValue = ForValue.ForPayment;
      newfromValue = FromValue.FromNominalFlatRate;
      break;
    case 14:
      newforValue = ForValue.ForBalloon;
      newfromValue = FromValue.FromYield;
      break;
    case 13:
      newforValue = ForValue.ForBalloon;
      newfromValue = FromValue.FromRate;
      break;
    case 12:
      newforValue = ForValue.ForBalloon;
      newfromValue = FromValue.FromFlatRate;
      break;
    case 11:
      newforValue = ForValue.ForBalloon;
      newfromValue = FromValue.FromAPR;
      break;
    case 10:
      newforValue = ForValue.ForRates;
      newfromValue = FromValue.None;
      break;
    case 9:
      newforValue = ForValue.ForPayment;
      newfromValue = FromValue.FromYield;
      break;
    case 8:
      newforValue = ForValue.ForPayment;
      newfromValue = FromValue.FromRate;
      break;
    case 7:
      newforValue = ForValue.ForPayment;
      newfromValue = FromValue.FromFlatRate;
      break;
    case 6:
      newforValue = ForValue.ForPayment;
      newfromValue = FromValue.FromAPR;
      break;
    case 5:
      newforValue = ForValue.ForCommission;
      newfromValue = FromValue.FromYield;
      break;
    case 4:
      newforValue = ForValue.ForCommission;
      newfromValue = FromValue.FromRate;
      break;
    case 3:
      newforValue = ForValue.ForAmount;
      newfromValue = FromValue.FromYield;
      break;
    case 2:
      newforValue = ForValue.ForAmount;
      newfromValue = FromValue.FromRate;
      break;
    case 1:
      newforValue = ForValue.ForAmount;
      newfromValue = FromValue.FromFlatRate;
      break;
    case 0:
      newforValue = ForValue.ForAmount;
      newfromValue = FromValue.FromAPR;
      break;
    default:
      newforValue = ForValue.ForPayment;
      newfromValue = FromValue.FromYield;
      break;
  }

  return { newforValue, newfromValue };
};
