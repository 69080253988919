import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import InfoDialog from 'components/InfoDialog';

const CreateCampaignInfo = ({
  open,
  setIsOpen
}: {
  open: boolean;
  setIsOpen?: (value: React.SetStateAction<boolean>) => void;
}) => {
  return (
    <InfoDialog
      title="Create a Campaign"
      withButton={false}
      isOpen={open}
      setIsOpen={setIsOpen}>
      <Alert severity="info">
        <Typography>
          You can create a Campaign Once <b>All</b> of your contacts have a{' '}
          <b>Ready</b> status
        </Typography>
      </Alert>
    </InfoDialog>
  );
};

export default CreateCampaignInfo;
