import React from 'react';
import isEqual from 'react-fast-compare';
import { firebase } from 'redux/firebase';
import { useTypedSelector } from 'redux/reducers';

import Board from './Board';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';

import AddIcon from '@material-ui/icons/Add';
import PollIcon from '@material-ui/icons/Poll';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { initData } from './init-data';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    width: '100%',
    padding: theme.spacing(1)
  },
  content: { padding: theme.spacing(2), overflowX: 'scroll' }
}));

const Component = () => {
  const classes = useStyles();
  const fb = useTypedSelector((s) => s.fb);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [boardKey, setBoardKey] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [state, setState] = React.useState(initData);
  const [loading, setLoading] = React.useState(false);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const updateBoardTitle = ({ value }) => {
    setState({ ...state, title: value });
  };

  const saveBoard = async () => {
    let newBoardKey;
    if (!boardKey) {
      newBoardKey = await firebase
        .database()
        .ref(`${fb.hostname}/kanban`)
        .push().key;
      setBoardKey(newBoardKey);
    } else {
      newBoardKey = boardKey;
    }

    const ref = firebase.database().ref(`${fb.hostname}/kanban/${newBoardKey}`);
    ref.update(state);
  };

  const getData = () => {
    const ref = firebase.database().ref(`${fb.hostname}/kanban`);
    ref.on('value', (snap) => snap.exists() && setData(snap.val()));
  };

  const createBoard = async () => {
    const newBoardKey = await firebase
      .database()
      .ref(`${fb.hostname}/kanban`)
      .push().key;
    const ref = firebase.database().ref(`${fb.hostname}/kanban/${newBoardKey}`);
    ref.update(initData);
  };

  const setBoard = () => {
    if (data && boardKey) {
      setState(data[boardKey]);
      setLoading(false);
    }

    if (data && !boardKey) {
      const firstKey = Object.keys(data)[0];
      setState(data[firstKey]);
      setBoardKey(firstKey);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    setBoard();
  }, [data, boardKey]);

  return (
    <React.Fragment>
      <div className={classes.toolbar}>
        <EditableInput
          {...{
            value: state.title,
            data,
            onChange: (e) => updateBoardTitle({ value: e.target.value })
          }}
        />
        <div>
          <Button onClick={handleClick}>
            <PollIcon />
            Boards
          </Button>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            keepMounted
            onClose={handleClose}
            open={Boolean(anchorEl)}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}>
            {data &&
              Object.keys(data).map((key, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() => {
                    if (key !== boardKey) {
                      setLoading(true);
                      setBoardKey(key);
                    }

                    handleClose();
                  }}
                  value={key}>
                  {data[key].title}
                </MenuItem>
              ))}
          </Menu>
        </div>
        {data && boardKey && (
          <Button
            onClick={saveBoard}
            style={{ color: isEqual(data[boardKey], state) ? '' : 'green' }}>
            {isEqual(data[boardKey], state) ? 'saved' : 'save'}
          </Button>
        )}

        <Button onClick={createBoard}>
          <AddIcon />
          Create Board
        </Button>
      </div>
      <div className={classes.content}>
        {loading && data ? (
          <LinearProgress />
        ) : (
          <Board {...{ boardKey, state, setState, saveBoard }} />
        )}
      </div>
    </React.Fragment>
  );
};

const EditableInput = ({ value, onChange, data }) => {
  const [editTitle, setEditTitle] = React.useState(false);
  const toggleTitle = () => {
    setEditTitle(!editTitle);
    // saveBoard();
  };

  if (editTitle) {
    return (
      <InputBase
        autoFocus
        defaultValue={value}
        inputProps={{ color: 'red' }}
        onBlur={toggleTitle}
        onChange={onChange}
        onKeyPress={(e) => e.charCode === 13 && toggleTitle()}
      />
    );
  } else {
    return (
      <div style={{ position: 'relative' }}>
        <Typography onClick={toggleTitle} variant="h5">
          {value.toUpperCase()}
        </Typography>
        {!data && (
          <Paper
            elevation={3}
            style={{
              position: 'absolute',
              bottom: '-10%',
              left: '110%',
              background: 'white',
              width: 200,
              padding: 15,
              borderRadius: 7,
              zIndex: 10,
              opacity: 0.8,
              display: 'flex',
              alignItems: 'center'
            }}>
            <ArrowBackIcon />
            Change the Board Name to save it as your own ...
          </Paper>
        )}
      </div>
    );
  }
};

export default Component;
