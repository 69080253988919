import React, { createContext, useState, useEffect } from 'react';
import {
  IPublicClientApplication,
  InteractionStatus
} from '@azure/msal-browser';
import {
  ICustomMessage,
  IMicrosoftMessages,
  IMicrosoftUser
} from '../interfaces';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from 'services/microsoft/authConfig';
import { BugTracker } from 'Utils/Bugtracker';
import { callMsGraph } from 'services/microsoft/graph';
import {
  IGroupMailFolder,
  IMessageHubProvider,
  IMicrosoftCustomFolder,
  IMicrosoftFolderIds,
  MicrosoftGraphMailFolder,
  TKnownCategory
} from './interface';
import { useToken } from '../hooks/useToken';
import { MailFolder, Message } from '@microsoft/microsoft-graph-types';
import {
  extractAllFolderIds,
  getGraphFolders
} from '../functions/folderFormatting';
import { useTypedSelector } from 'redux/reducers';
import { getRelationship } from 'redux/actions/GraphQlActions';
import { UserInstance } from 'types/interfaces';

export const MessageHubContext = createContext<IMessageHubProvider>({
  instance: {} as IPublicClientApplication,
  accounts: [],
  inProgress: InteractionStatus.None,
  messages: null,
  profileData: null,
  drafts: null,
  archived: null,
  sentItems: null,
  customFolder: null,
  folderIds: null,

  validAccessToken: async () => '',
  setData: () => {},
  selectedCategory: { category: '', folderId: '' },
  setSelectedCategory: () => {},
  grabRelationshipEmails: async () => [],
  getRefreshEmails: async () => {},
  logout: async () => {},
  setToken: () => {},
  token: '',
  setFullScreen: () => {},
  fullScreen: false
});

const MessageHubProvider = ({ children }: { children: React.ReactNode }) => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuth = useIsAuthenticated();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [fullScreen, setFullScreen] = useState<boolean>(false);
  const [data, setData] = useState<{
    messages: IMicrosoftMessages | null;
    profileData: IMicrosoftUser | null;
    drafts: IMicrosoftMessages | null;
    archived: IMicrosoftMessages | null;
    'Sent Items': IMicrosoftMessages | null;
    customFolder: IMicrosoftMessages | null;
    folderIds: IMicrosoftFolderIds | null;
  }>({
    messages: null,
    profileData: null,
    drafts: null,
    archived: null,
    'Sent Items': null,
    customFolder: null,
    folderIds: null
  });

  const [token, setToken] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<{
    category: TKnownCategory | string;
    folderId: string | undefined;
  }>({ category: '', folderId: '' });

  const getValidAccessToken = async () => {
    if (!isAuth && inProgress === InteractionStatus.None) return '';
    const account = accounts[0];
    const accessTokenRequest = { ...loginRequest, account };

    try {
      const { accessToken } = await instance.acquireTokenSilent(
        accessTokenRequest
      );
      return accessToken;
    } catch (e) {
      BugTracker.notify(e);

      try {
        const { accessToken } = await instance.acquireTokenPopup(
          accessTokenRequest
        );
        return accessToken;
      } catch (e) {
        BugTracker.notify(e);
        return '';
      }
    }
  };

  const grabRelationshipEmails = async (
    user: UserInstance
  ): Promise<UserInstance[]> => {
    const UserInstanceId = user.Id;
    const UserDefinitionId = user.UserDefinitionId;

    try {
      const response = (await getRelationship({
        action: 'LIST_ALL',
        UserInstanceId: UserInstanceId,
        UserDefinitionId: UserDefinitionId,
        baseUrl
      })) as UserInstance[] | undefined;

      if (response) return response;
      else return [];
    } catch (e) {
      BugTracker.notify(e);
      return [];
    }
  };

  const getRefreshEmails = async () => {
    try {
      const accessToken = await getValidAccessToken();
      const inboxFolderResponse = await fetch(
        `https://graph.microsoft.com/v1.0/me/mailFolders/Inbox`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!inboxFolderResponse.ok) return;
      const inboxFolderData = await inboxFolderResponse.json();
      const inboxFolderId = inboxFolderData.id;

      const messagesResponse = await callMsGraph({
        accessToken,
        action: 'ParentFolders',
        parentFolderId: inboxFolderId,
        queryParams: { $expand: 'attachments', $top: 10 }
      });

      const result = await getGraphFolders({
        messages: messagesResponse,
        accessToken
      });

      if (result) {
        const { folderIds, updatedMessages } = result;

        setData((prevData) => {
          if (!prevData.folderIds) return prevData;

          const existingCustomFolderIds = new Set(
            prevData.folderIds.customFolders.map((folder) => folder.id)
          );
          const uniqueNewCustomFolders = folderIds.customFolders.filter(
            (folder) => !existingCustomFolderIds.has(folder.id)
          );
          const updatedCustomFolders = [
            ...prevData.folderIds.customFolders,
            ...uniqueNewCustomFolders
          ];

          const allCustomFolderIds = extractAllFolderIds(updatedCustomFolders);
          const allInboxFolderIds = extractAllFolderIds(
            folderIds.inboxFolder.child
          );
          const allFolderIds = [...allCustomFolderIds, ...allInboxFolderIds];

          const filteredMessages = updatedMessages.value.filter(
            (message: Message) => {
              return typeof message.parentFolderId === 'string'
                ? !allFolderIds.includes(message.parentFolderId)
                : true;
            }
          );

          const validEmails = filteredMessages.filter(
            (message: Message) =>
              message.toRecipients && message.toRecipients.length > 0
          );

          return {
            ...prevData,
            folderIds: {
              ...prevData.folderIds,
              customFolders: updatedCustomFolders
            },
            messages: {
              ...updatedMessages,
              value: validEmails
            }
          };
        });
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const getFromGraph = async () => {
    try {
      const accessToken = await getValidAccessToken();

      const res = callMsGraph({ accessToken, action: 'graphMeEndpoint' });
      const inboxFolderResponse = await fetch(
        `https://graph.microsoft.com/v1.0/me/mailFolders/Inbox`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }
      );

      if (!inboxFolderResponse.ok) return;
      const inboxFolderData = await inboxFolderResponse.json();
      const inboxFolderId = inboxFolderData.id;

      const messagesResponse = await callMsGraph({
        accessToken,
        action: 'ParentFolders',
        parentFolderId: inboxFolderId,
        queryParams: { $expand: 'attachments', $top: 10 }
      });

      const result = await getGraphFolders({
        messages: messagesResponse,
        accessToken
      });

      if (result) {
        const { folderIds, updatedMessages } = result;
        const allCustomFolderIds = extractAllFolderIds(folderIds.customFolders);
        const allInboxFolderIds = extractAllFolderIds(
          folderIds.inboxFolder.child
        );

        const allFolderIds = [...allCustomFolderIds, ...allInboxFolderIds];

        const messages: ICustomMessage[] =
          updatedMessages.value as ICustomMessage[];
        const isInbox = messages.filter((message: Message) => {
          if (typeof message.parentFolderId === 'string') {
            return !allFolderIds.includes(message.parentFolderId);
          }
          return true;
        });

        if (isInbox.length > 0) {
          const filterOutInvalidEmails = Object.values(isInbox).filter(
            (message: Message) => {
              return message.toRecipients && message.toRecipients.length > 0;
            }
          );

          updatedMessages.value = filterOutInvalidEmails;
          setSelectedCategory({
            category: 'Inbox',
            folderId: folderIds.inboxFolder.id
          });
          return setData((prevData: any) => ({
            ...prevData,
            folderIds,
            messages: updatedMessages,
            profileData: res
          }));
        }
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const logout = async () => {
    try {
      await instance.logoutPopup();
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const validAccessToken = async () => {
    const accessToken = await getValidAccessToken();
    return accessToken;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isAuth) {
        await getFromGraph();
      }
    };
    fetchData();
  }, [isAuth]);

  return (
    <MessageHubContext.Provider
      value={{
        instance,
        accounts,
        inProgress,
        profileData: data.profileData,
        messages: data.messages,
        drafts: data.drafts,
        sentItems: data['Sent Items'],
        archived: data.archived,
        folderIds: data.folderIds,
        customFolder: data.customFolder,
        setData,
        validAccessToken,
        selectedCategory,
        setSelectedCategory,
        grabRelationshipEmails,
        getRefreshEmails,
        logout,
        setToken,
        token,
        setFullScreen,
        fullScreen
      }}>
      {children}
    </MessageHubContext.Provider>
  );
};

export default MessageHubProvider;
