import { Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  Button,
  IconButton,
  Divider,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Refresh as RefreshIcon, Delete } from '@material-ui/icons';
import { theme } from 'theme';
import { ICustomLoading } from '../../Threads/context/interface';
import { IConversation } from 'components/MessageHub/interfaces';
import { useDispatch } from 'react-redux';
import { SelectionContext } from '../context/SelectionContext';
import { IMicrosoftCustomFolder } from 'components/MessageHub/context/interface';
import { createNotification } from 'react-redux-notify';
import { warningNotif } from 'components/Notifications';
import { CardContext } from 'components/MessageHub/context/HoverCardContext';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';

interface IExtraParameters {
  amount?: number;
  loading?: ICustomLoading;
  showRefreshIcon?: boolean;
  icon?: JSX.Element;
  label?: string;
  hasDelete?: boolean;
  folderId?: string;
  folderName?: string;
  isChild?: IMicrosoftCustomFolder;
  handleCategorySelection?: () => Promise<void>;
  handleFolderDelete?: () => Promise<void>;
  setLoading?: Dispatch<SetStateAction<ICustomLoading>>;
}

interface ICommonParameters {
  classes: any;
  category: any;
  onClick: () => void;
}

const RefreshButton = ({
  handleCategorySelection,
  category,
  loading,
  classes
}: {
  handleCategorySelection?: () => Promise<void>;
  category: any;
  loading?: ICustomLoading;
  classes: any;
}) => {
  return (
    <IconButton
      size="small"
      onClick={async (e) => {
        e.stopPropagation();
        handleCategorySelection && (await handleCategorySelection());
      }}
      disabled={loading ? loading.isLoading : false}
      className={classes.iconButton}>
      <RefreshIcon />
    </IconButton>
  );
};

const AmountDisplay = ({
  amount,
  classes
}: {
  amount: number;
  classes: any;
}) => {
  return (
    <Typography
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        fontWeight: 'bold'
      }}
      className={classes.listItemText}>
      {amount}
    </Typography>
  );
};

const DeleteButton = ({
  handleFolderDelete,
  setLoading,
  loading,
  classes
}: {
  handleFolderDelete?: () => Promise<void>;
  setLoading?: Dispatch<SetStateAction<ICustomLoading>>;
  loading?: ICustomLoading;
  classes: any;
}) => (
  <IconButton
    size="small"
    onClick={async (e) => {
      e.stopPropagation();
      if (setLoading && handleFolderDelete && loading) {
        setLoading({ ...loading, isLoading: true });
        await handleFolderDelete();
        setLoading({ ...loading, isLoading: false });
      }
    }}
    disabled={loading?.isLoading}
    className={classes.iconButton}>
    <Delete />
  </IconButton>
);

const RenderListItem = ({
  classes,
  icon,
  label,
  onClick,
  showRefreshIcon,
  handleCategorySelection,
  hasDelete,
  loading,
  setLoading,
  handleFolderDelete,
  amount,
  category
}: ICommonParameters & IExtraParameters) => (
  <ListItem
    className={classes.listItem}
    onClick={() => {
      if (loading && loading.isLoading) return;
      onClick();
    }}>
    <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
    <ListItemText classes={{ primary: classes.listItemText }} primary={label} />

    {amount && <AmountDisplay amount={amount} classes={classes} />}
    {showRefreshIcon && (
      <RefreshButton
        category={category}
        handleCategorySelection={handleCategorySelection}
        loading={loading}
        classes={classes}
      />
    )}
    {hasDelete && (
      <DeleteButton
        handleFolderDelete={handleFolderDelete}
        setLoading={setLoading}
        loading={loading}
        classes={classes}
      />
    )}
  </ListItem>
);

interface ISelectionButtonParameters extends ICommonParameters {
  category: string;
  hasMessage: '' | IConversation | null;
  extraProps?: IExtraParameters;
}

const SelectionButton = ({
  category,
  onClick,
  hasMessage,
  classes,
  extraProps
}: ISelectionButtonParameters) => {
  const dispatch = useDispatch();

  const { selectedCategory } = useContext(MessageHubContext);
  const { bookmarkedConversations } = useContext(CardContext);
  const { moveThreadToFolder, fetchMessagesFromFolder } =
    useContext(SelectionContext);

  return (
    <div
      onDragOver={(e) => e.preventDefault()}
      onDrop={async (e) => {
        e.preventDefault();
        const thread = e.dataTransfer.getData('thread');
        const { folderId, folderName, loading, setLoading } = extraProps || {};

        if (thread && folderId) {
          const fullThread: IConversation = JSON.parse(thread);
          const isBookmarked = bookmarkedConversations.some(
            (bookmarked: IConversation) =>
              bookmarked.parentMessage.id === fullThread.parentMessage.id
          );

          const isRestrictedFolder =
            ['Sent Items', 'Drafts', 'Archived'].includes(
              selectedCategory.category
            ) || ['Archived', 'Sent Items'].includes(folderName || '');

          if (isRestrictedFolder) {
            const folderMessage =
              selectedCategory.category === folderName
                ? `The '${folderName}' Folder`
                : `Outside Of The '${selectedCategory.category}'`;
            dispatch(
              createNotification(
                warningNotif(`Sorry, Threads Cannot Be Moved ${folderMessage}.`)
              )
            );
            return;
          }

          if (isBookmarked) {
            dispatch(
              createNotification(
                warningNotif(
                  `Bookmarked Conversations Cannot Be Moved As They Are Pinned To This Group.`
                )
              )
            );
            return;
          }

          if (loading && setLoading) {
            setLoading({ ...loading, isLoading: true });
            await moveThreadToFolder({
              conversation: fullThread,
              folderId: folderId,
              folderName: folderName || 'Unknown Folder Name'
            });

            // Will Move Custom Folders To Inbox
            await fetchMessagesFromFolder(folderId, 'customFolders');
            setLoading({ ...loading, isLoading: false });
          }
        }
      }}
      style={{
        display: hasMessage ? 'flex' : 'block',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      {hasMessage ? (
        <Tooltip
          title={
            extraProps?.isChild
              ? `Subfolder: ${extraProps?.isChild.displayName}`
              : category
          }
          placement="right"
          arrow>
          <Button
            disabled={
              extraProps?.loading ? extraProps.loading.isLoading : false
            }
            className={classes.iconButton}
            onClick={onClick}>
            {extraProps?.icon}
          </Button>
        </Tooltip>
      ) : (
        <RenderListItem {...{ classes, onClick, ...extraProps, category }} />
      )}
      <div style={{ paddingTop: 5, paddingBottom: 5 }} />
    </div>
  );
};

export default SelectionButton;
