import { Button, TextField, Grid } from '@material-ui/core';
import { HomeOutlined, LocationCity, MailOutline } from '@material-ui/icons';
import AutocompleteAddress from 'components/Address/components/IdealPostcodes';
import { INIT_FORM } from 'components/Calendar/interface';
import { useContext } from 'react';
import { theme } from 'theme';
import { IAddressApiFullAddress } from 'types/address';
import { CalendarContext } from '../../context/CalendarContext';

const CalendarAddressBody = () => {
  const { formValues } = useContext(CalendarContext);

  return (
    <div
      style={{
        border: `2px ${theme.palette.grey[200]} solid`,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
      }}>
      {formValues.location === '' && (
        <>
          <div style={{ paddingBottom: theme.spacing(1) }} />
          <CalendarAddressAutocomplete />
        </>
      )}
      <div style={{ paddingTop: theme.spacing(2) }} />
      <CalendarAddresses />
    </div>
  );
};

export default CalendarAddressBody;

const CalendarAddresses = () => {
  const { formValues, setFormValues } = useContext(CalendarContext);

  const isEmptyOrContainsOnlyEmptyStrings = (obj) => {
    return Object.values(obj).every((value) => value === '');
  };

  return (
    <Grid container direction="column" spacing={2}>
      {formValues.address.line_1 && (
        <Grid item>
          <TextField
            label="Address Line 1"
            variant="outlined"
            fullWidth
            value={formValues.address.line_1}
            InputProps={{
              startAdornment: (
                <HomeOutlined style={{ paddingRight: theme.spacing(1) }} />
              )
            }}
            disabled
          />
        </Grid>
      )}

      {formValues.address.line_2 && (
        <Grid item>
          <TextField
            label="Address Line 2"
            variant="outlined"
            fullWidth
            value={formValues.address.line_2}
            InputProps={{
              startAdornment: (
                <HomeOutlined style={{ paddingRight: theme.spacing(1) }} />
              )
            }}
            disabled
          />
        </Grid>
      )}

      {formValues.address.postal_town && (
        <Grid item>
          <TextField
            label="Postal Town"
            variant="outlined"
            fullWidth
            value={formValues.address.postal_town}
            InputProps={{
              startAdornment: (
                <LocationCity style={{ paddingRight: theme.spacing(1) }} />
              )
            }}
            disabled
          />
        </Grid>
      )}

      {formValues.address.postcode && (
        <Grid item>
          <TextField
            label="Postcode"
            variant="outlined"
            fullWidth
            value={formValues.address.postcode}
            InputProps={{
              startAdornment: (
                <MailOutline style={{ paddingRight: theme.spacing(1) }} />
              )
            }}
            disabled
          />
        </Grid>
      )}
      {!isEmptyOrContainsOnlyEmptyStrings(formValues.address) && (
        <Grid item>
          <Button
            data-cy="clear-address-btn"
            onClick={() =>
              setFormValues({
                ...formValues,
                location: '',
                address: INIT_FORM.address
              })
            }>
            Clear Address
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const CalendarAddressAutocomplete = () => {
  const { formValues, setFormValues } = useContext(CalendarContext);

  return (
    <AutocompleteAddress
      setAddress={(address: IAddressApiFullAddress | null) => {
        if (address !== null) {
          const locationString = `${address.line_1}, ${address.line_2}, ${address.postal_town}, ${address.postcode}, ${address.country}`;
          setFormValues((overrideState) => ({
            ...overrideState,
            location: locationString,
            address: address
          }));
        } else {
          setFormValues({
            ...formValues,
            location: '',
            address: INIT_FORM.address
          });
        }
      }}
      props={{ isIntegration: false }}
      icon
    />
  );
};
