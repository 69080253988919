import React from 'react';
import {
  AppBar,
  Badge,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Toolbar,
  Popover
} from '@material-ui/core';
export { default as Dashboard } from './Dashboard';
import {
  NotificationList,
  UserActionBtn
} from './Dashboard/components/Topbar/components';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'redux/reducers';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useHistory } from 'react-router-dom';
import { firebase } from 'redux/firebase';
import { PROXY_SETTINGS } from 'redux/actions/types';
import { BugTracker } from 'Utils/Bugtracker';
import OverViewDialog from 'components/Stepper/components/Overview/Entry';
import { useTarget } from 'hooks/useTarget';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bkgrnd: {
      height: '100vh',
      width: '100vw',
      background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcoincentral.com%2Fwp-content%2Fuploads%2F2019%2F01%2Ffintech-blockchain.png&f=1&nofb=1")`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      overflowY: 'scroll'
    },
    text: { color: theme.palette.primary.contrastText },
    img: {
      filter: 'grayscale(100%)',
      background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcoincentral.com%2Fwp-content%2Fuploads%2F2019%2F01%2Ffintech-blockchain.png&f=1&nofb=1")`,
      backgroundRepeat: 'no-repeat',
      padding: 4,
      borderRadius: 10
    }
  })
);

export const PortalLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const hostname = useTypedSelector((s) => s.config.hostname);
  const Id = useTypedSelector((s) => s.user.user.Id);
  const uid = useTypedSelector((s) => s.fb.auth.uid);
  const currentProcess = useTypedSelector((s) => s.process.currentProcess);
  const { handleTarget, openDeal, open, setOpen, loading, target } =
    useTarget();

  const [state, setState] = React.useState({
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null
  });

  const { notificationsEl, notifications } = state;

  const handleSignOut = () => {
    history.push('/sign-in');
    dispatch({ type: 'LOG_OUT' });
  };

  const handleShowNotifications = (event) => {
    setState({
      ...state,
      notificationsEl: event.currentTarget
    });
  };

  const deleteNotification = (notification) => {
    // console.log({ notification });
    const deleteRef = firebase
      .firestore()
      .collection('userAccount')
      .doc(Id.toString())
      .collection('notifications')
      .doc(notification.id);
    deleteRef
      .delete()
      .then(() => {
        // console.log('successfully deleted')
      })
      .catch((e) => BugTracker.notify(e));
  };

  const showNotifications = Boolean(notificationsEl);

  React.useEffect(() => {
    if (target?.pdid) handleTarget(target);
  }, [target]);

  React.useEffect(() => {
    // can we set up a listener here
    const unsub = firebase
      .firestore()
      .collection('userAccount')
      .doc(Id.toString())
      .onSnapshot((doc) =>
        dispatch({ type: PROXY_SETTINGS, payload: doc.data() })
      );

    const notifRef = firebase
      .firestore()
      .collection('userAccount')
      .doc(uid)
      .collection('notifications')
      .where('read', '==', false)
      .orderBy('ts', 'desc');

    const unsubscribe = notifRef.onSnapshot((snap) => {
      let notifications: any = [];
      snap.forEach((doc) => {
        const item = { ...doc.data(), id: doc.id };
        if (doc.exists) notifications.push(item);
      });
      setState((s) => ({ ...s, ['notifications']: notifications }));
    });
    return () => {
      unsub();
      unsubscribe();
    };
  }, []);

  const handleCloseNotifications = (notification) => {
    // console.log({ notification });
    dispatch({ type: 'SET_TARGET', payload: notification });
    setState({ ...state, notificationsEl: null });
  };

  return (
    <div className={classes.bkgrnd}>
      <OverViewDialog handleClose={() => setOpen(false)} open={open} />
      <AppBar>
        <Toolbar>
          <Grid item>
            <img
              height={40}
              src={`https://${hostname}.cosnetics.co.uk/sites/${hostname}.cosnetics.co.uk/assets/logo.jpg`}
              className={classes.img}
            />
          </Grid>
          <div style={{ flexGrow: 1 }} />
          <Grid item>
            <IconButton
              style={{ marginRight: 8 }}
              onClick={(e) => handleShowNotifications(e)}>
              <Badge
                badgeContent={state?.notifications?.length}
                color="primary">
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item>
            <UserActionBtn signout={handleSignOut} />
          </Grid>
        </Toolbar>
      </AppBar>
      <br />
      <br />
      <br />
      <div>{children}</div>
      <Popover
        anchorEl={notificationsEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handleCloseNotifications}
        open={showNotifications}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <NotificationList
          notifications={notifications}
          onSelect={handleCloseNotifications}
          deleteNotification={deleteNotification}
        />
      </Popover>
    </div>
  );
};
