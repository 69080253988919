import React, { useState } from 'react';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl
} from '@material-ui/core';
import GooglePostcode from './GooglePostcodes'; // Adjust the import path accordingly
import IdealPostcode from './IdealPostcodes'; // Adjust the import path accordingly
import { useGoogleMapsAPI } from 'hooks/useGoogleMapsAPI';
import {
  IAddressApiFullAddress,
  IAddressProps,
  IAddressWarning
} from 'types/address';

interface IAddressSelection {
  props: IAddressProps;
  setWarning?: (warning: IAddressWarning) => void;
  setAddress?: (address: IAddressApiFullAddress | null) => void;
}

const AddressSelection = (props: IAddressSelection) => {
  return (
    <div>
      <IdealPostcode {...props} />
    </div>
  );
};

export default AddressSelection;
