import {
  AUTH,
  HOSTNAME,
  PROXY_SETTINGS,
  PROXY_INSTANCE_SETTINGS
} from '../actions/types';
import { ProxyInstanceSettings } from 'types/interfaces';

import { initProxySettings } from './initObjects';

const initialState = {
  auth: { email: '', uid: '' },
  hostname: '',
  proxy_settings: {
    darkMode: false,
    guideStep: 0,
    help: true,
    Id: ''
  },
  notifications: [],
  proxy_instance_settings: initProxySettings
};

interface SetAuth {
  type: typeof AUTH;
  payload: {
    email: string;
    uid: string;
  };
}

interface SetHostName {
  type: typeof HOSTNAME;
  payload: string;
}

interface SetProxySettings {
  type: typeof PROXY_SETTINGS;
  payload: {
    darkMode: boolean;
    guideStep: number;
    help: boolean;
    Id: string;
  };
}

interface SetProxyInstanceSettings {
  type: typeof PROXY_INSTANCE_SETTINGS;
  payload: ProxyInstanceSettings;
}

type fbAction =
  | SetAuth
  | SetHostName
  | SetProxySettings
  | SetProxyInstanceSettings;

export default (state = initialState, action: fbAction) => {
  switch (action.type) {
    case AUTH:
      return { ...state, auth: action.payload };
    case HOSTNAME:
      return { ...state, hostname: action.payload };
    case PROXY_SETTINGS:
      return { ...state, proxy_settings: action.payload };
    case PROXY_INSTANCE_SETTINGS:
      return { ...state, proxy_instance_settings: action.payload };
    default:
      return state;
  }
};
