import {
  FieldDefinitionDict,
  FieldDefinition,
  CompleteObjectInstance,
  ObjectDefinition,
  ProcessInstance,
  ProcessStepDefinition
} from 'types/interfaces';
import {
  Calculation,
  Init_Config,
  OverridePayment
} from 'types/calculatorInterfaces';
import { IRowData } from 'components/Calculator/SeasonalPaymentsTable/newIndex';

export interface Props {
  classes: any;
  calculation: Calculation;
  ObjectInstance: CompleteObjectInstance;
  ObjectDefinition: ObjectDefinition;
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ProcessInstance: ProcessInstance;
  ProcessInstanceId: number;
  QuoteId: number;
  handleClose: () => void;
  index: number;
  isOverview: boolean;
  status: boolean;
  open: boolean;
  readOnly: boolean;
  setCalculation: () => void;
  stepdefdict: ProcessStepDefinition;
  Output?: Calculation;
  setOutput?: (output: Calculation) => void;
  setState?: (state: Calculation) => void;
}

export interface Extra {
  StartPeriod: number;
  EndPeriod: number;
  Amount: number;
  ExPayOverRide: string;
  isMonth?: boolean;
  isDeferral?: boolean;
}

export interface Tools {
  props: Props;
  state: Calculation;
  setState: any;
  config: Init_Config;
  setConfig: any;
  balloonExtras: Extra[];
  setBalloonExtras: any;
  period: any;
  setPeriod: any;
  setRows: React.Dispatch<React.SetStateAction<IRowData[]>>;
  rows: IRowData[];
  extras: Extra[];
  setExtras: any;
  zeroPayments: Extra[];
  setZeroPayments: any;
  fromValue: FromValue;
  setFromValue: (v: FromValue) => void;
  forValue: ForValue;
  setForValue: (v: ForValue) => void;
}

export interface FunderDoc {
  SETTLEMENT_FIGURE: number;
  FUNDING_PROFILE_DISPLAY: string | number;
  VAT_DEFERRAL_AMOUNT: number;
  VAT_DEFERRAL_PERIOD: number;
  COST_EXCL_VAT_AND_NON_VATABLE_ITEMS: number;
  VAT: number;
  VAT_UPFRONT: boolean;
  NON_VATABLE_ITEMS: number;
  I_COST_INC_VAT: number;
  II_ADVANCE_REPAYMENTS: number;
  III_ADDITIONAL_REPAYMENT: number;
  IV_NET_PART_EXCHANGE_EXCL_VAT: number;
  V_VAT_ON_NET_PART_EXCHANGE: number;
  VI_NET_VAT_PAID_UPFRONT: number;
  TOTAL_INIT_PAYMENT: number;
  AMOUNT_TO_FINANCE: number;
  REPAYMENTS: string | number;
  INTEREST_CHARGES_AMOUNT: number;
  INTEREST_CHARGES_VAT: number;
  INTEREST_CHARGES_TOTAL: number;
  DOCUMENTATION_FEE_AMOUNT: number;
  DOCUMENTATION_FEE_VAT: number;
  DOCUMENTATION_FEE_TOTAL: number;
  OPTION_TO_PURCHASE_AMOUNT: number;
  OPTION_TO_PURCHASE_VAT: number;
  OPTION_TO_PURCHASE_TOTAL: number;
  TOTAL_CHARGE_FOR_FINANCE_AMOUNT: number;
  TOTAL_CHARGE_FOR_FINANCE_VAT: number;
  TOTAL_CHARGE_FOR_FINANCE_TOTAL: number;
  TOTAL_AMOUNT_PAYABLE_INC_VAT: number;
  HIRE_PERIOD: number;
  FUNDING_PROFILE: number;
  DOCUMENTATION_FEE_PAYABLE: string;
  MARGIN: number;
  YIELD: number;
  APR: number;
  FLAT_RATE: number;
  NOMINAL_FLAT_RATE: number;
  RATE_PER_ANNUM: number;
  FUNDING_RATE: number;
  INITIAL_RENTALS: number;
  INSTALMENTS_NUMBER: number;
  INSTALMENTS_TYPE: string;
  INSTALMENTS_AMOUNT: number;
  INSTALMENTS_DATE: string;
  OVERRIDE_PAYMENTS: OverridePayment[];
  HALF_AMOUNT_PAYABLE: number;
  THIRD_AMOUNT_PAYABLE: number;
  VAT_PAID_UPFRONT_AMOUNT: number;
  VAT_PAID_UPFRONT_VAT: number;
  VAT_PAID_UPFRONT_TOTAL: number;
  ADVANCE_PAYMENTS_AMOUNT: number;
  ADVANCE_PAYMENTS_VAT: number;
  ADVANCE_PAYMENTS_TOTAL: number;
  ADDITIONAL_DEPOSIT_AMOUNT: number;
  ADDITIONAL_DEPOSIT_VAT: number;
  ADDITIONAL_DEPOSIT_TOTAL: number;
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_AMOUNT: number;
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_VAT: number;
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL: number;
  I_DOCUMENT_FEE_STANDALONE_AMOUNT: number;
  I_DOCUMENT_FEE_STANDALONE_VAT: number;
  I_DOCUMENT_FEE_STANDALONE_TOTAL: number;
  II_DOCUMENT_FEE_UPSELL_AMOUNT: number;
  II_DOCUMENT_FEE_UPSELL_VAT: number;
  II_DOCUMENT_FEE_UPSELL_TOTAL: number;
  II_TOTAL_DOCUMENT_FEE_AMOUNT: number;
  II_TOTAL_DOCUMENT_FEE_VAT: number;
  II_TOTAL_DOCUMENT_FEE_TOTAL: number;
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_AMOUNT: number;
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_VAT: number;
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_TOTAL: number;
  GROSS_COMMISSION_AMOUNT: number;
  GROSS_COMMISSION_VAT: number;
  GROSS_COMMISSION_TOTAL: number;
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT: number;
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_VAT: number;
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_TOTAL: number;
  INTRODUCER_FEE_AMOUNT: number;
  INTRODUCER_FEE_VAT: number;
  INTRODUCER_FEE_TOTAL: number;
  NET_COMMISSION_AMOUNT: number;
  NET_COMMISSION_VAT: number;
  NET_COMMISSION_TOTAL: number;
  BALLOON: number | string;
  CONTRACT_PERIOD: number;
  FINANCE_PRODUCT: string;
  SUBSIDY: number;
}

export interface ConvertedFunderDoc {
  // Facility
  COST_EXCL_VAT_AND_NON_VATABLE_ITEMS: string;
  VAT: string;
  NON_VATABLE_ITEMS: string;
  I_COST_INC_VAT: string;
  II_ADVANCE_REPAYMENTS: string;
  III_ADDITIONAL_REPAYMENT: string;
  IV_NET_PART_EXCHANGE_EXCL_VAT: string;
  V_VAT_ON_NET_PART_EXCHANGE: string;
  VI_NET_VAT_PAID_UPFRONT: string;
  TOTAL_INIT_PAYMENT: string;
  AMOUNT_TO_FINANCE: string;
  REPAYMENTS: string;
  // Finance Charges
  INTEREST_CHARGES_AMOUNT: string;
  INTEREST_CHARGES_VAT: string;
  INTEREST_CHARGES_TOTAL: string;
  DOCUMENTATION_FEE_AMOUNT: string;
  DOCUMENTATION_FEE_VAT: string;
  DOCUMENTATION_FEE_TOTAL: string;
  OPTION_TO_PURCHASE_AMOUNT: string;
  OPTION_TO_PURCHASE_VAT: string;
  OPTION_TO_PURCHASE_TOTAL: string;
  TOTAL_CHARGE_FOR_FINANCE_AMOUNT: string;
  TOTAL_CHARGE_FOR_FINANCE_VAT: string;
  TOTAL_CHARGE_FOR_FINANCE_TOTAL: string;
  TOTAL_AMOUNT_PAYABLE_INC_VAT: string;
  // Term Structure
  HIRE_PERIOD: string;
  FUNDING_PROFILE: string;
  DOCUMENTATION_FEE_PAYABLE: string;
  // Rates and Returns
  MARGIN: string;
  YIELD: string;
  APR: string;
  FLAT_RATE: string;
  FUNDING_RATE: string;
  NOMINAL_FLAT_RATE: string;
  RATE_PER_ANNUM: string;

  // Instalments
  INSTALMENTS_NUMBER: string;
  INSTALMENTS_TYPE: string;
  INSTALMENTS_AMOUNT: string;
  INSTALMENTS_DATE: string;
  // Termination & Repossession
  HALF_AMOUNT_PAYABLE: string;
  THIRD_AMOUNT_PAYABLE: string;
  // Payment On Signing
  VAT_PAID_UPFRONT_AMOUNT: string;
  VAT_PAID_UPFRONT_VAT: string;
  VAT_PAID_UPFRONT_TOTAL: string;
  ADVANCE_PAYMENTS_AMOUNT: string;
  ADVANCE_PAYMENTS_VAT: string;
  ADVANCE_PAYMENTS_TOTAL: string;
  ADDITIONAL_DEPOSIT_AMOUNT: string;
  ADDITIONAL_DEPOSIT_VAT: string;
  ADDITIONAL_DEPOSIT_TOTAL: string;
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_AMOUNT: string;
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_VAT: string;
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL: string;
  // Commission Invoice
  I_DOCUMENT_FEE_STANDALONE_AMOUNT: string;
  I_DOCUMENT_FEE_STANDALONE_VAT: string;
  I_DOCUMENT_FEE_STANDALONE_TOTAL: string;
  II_DOCUMENT_FEE_UPSELL_AMOUNT: string;
  II_DOCUMENT_FEE_UPSELL_VAT: string;
  II_DOCUMENT_FEE_UPSELL_TOTAL: string;
  II_TOTAL_DOCUMENT_FEE_AMOUNT: string;
  II_TOTAL_DOCUMENT_FEE_VAT: string;
  II_TOTAL_DOCUMENT_FEE_TOTAL: string;
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_AMOUNT: string;
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_VAT: string;
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_TOTAL: string;
  GROSS_COMMISSION_AMOUNT: string;
  GROSS_COMMISSION_VAT: string;
  GROSS_COMMISSION_TOTAL: string;
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT: string;
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_VAT: string;
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_TOTAL: string;
  INTRODUCER_FEE_AMOUNT: string;
  INTRODUCER_FEE_VAT: string;
  INTRODUCER_FEE_TOTAL: string;
  NET_COMMISSION_AMOUNT: string;
  NET_COMMISSION_VAT: string;
  NET_COMMISSION_TOTAL: string;
  BALLOON: string;
}

export enum SaveLabels {
  Autosave = 'Autosave',
  Standalone = 'Standalone'
}

export enum ForValue {
  ForBalloon = 'forBalloon',
  ForRates = 'forRates',
  ForPayment = 'forPayment',
  ForCommission = 'forCommission',
  ForAmount = 'forAmount',
  None = ''
}

export enum FromValue {
  FromYield = 'fromYield',
  FromRate = 'fromRate',
  FromFlatRate = 'fromFlatRate',
  FromAPR = 'fromAPR',
  FromNominalFlatRate = 'fromNominalFlatRate',
  None = ''
}
