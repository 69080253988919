import React from 'react';
import { useSelector } from 'react-redux';
import { Dashboard as DashboardLayout } from 'layouts';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Paper,
  CircularProgress,
  IconButton
} from '@material-ui/core';

import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { getDealData } from 'redux/actions/processes/getDealData';
import { getProcessesByUser } from 'redux/actions/processes';
import OverViewDialog from 'components/Stepper/components/Overview/Entry';
import Progress from './components/Progress';
import ProcessActivityChart from './components/ProcessActivityChartD3';
import DashboardNotification from './components/Notifications';
import Calendar from './components/Calendar';
import User from 'components/User';
import RecentDeals from './components/RecentDeals';
import { CgArrowsExchange } from 'react-icons/cg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    flexGrow: 1
  },
  logo: { fontSize: 'large', color: theme.palette.text.primary },
  dash: {
    padding: theme.spacing(1),
    width: '100%',
    height: '100%',
    borderRadius: 3,
    borderWidth: 0
  },
  chart: {
    padding: theme.spacing(1),
    width: '100%',
    height: 350,
    overflow: 'auto',
    borderRadius: 3,
    borderWidth: 0
  },
  container: {
    width: '100%'
  },
  gridList: {
    width: 500,
    height: 450
  }
}));

const Dashboard = (props) => {
  const theme = useTheme();
  const classes = useStyles();

  const user: any = useSelector<any>((s) => s.user.user);
  const token = useSelector<any>((s) => s.user.auth.token);
  const MyProcessInstances: any = useSelector<any>(
    (s) => s.process.myProcessInstances
  );
  const data = () => {
    return MyProcessInstances.sort((a, b) => {
      const A = new Date(a.LastModified).getTime();
      const B = new Date(b.LastModified).getTime();
      return B - A;
    });
  };
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadingUser, setLoadingUser] = React.useState(false);
  const [activityChart, setActivityChart] = React.useState(false);
  const handleUIChange = () =>
    setActivityChart((activityChart) => !activityChart);

  const getData = async () => await getProcessesByUser({ Id: user.Id });

  React.useEffect(() => {
    getData();
  }, []);

  const openDeal = async ({
    ProcessInstanceId,
    ProcessDefinitionId,
    ProcessStepDefinitionId
  }: {
    ProcessInstanceId: number;
    ProcessDefinitionId: number;
    ProcessStepDefinitionId: number;
  }) => {
    setLoading(true);
    const data = await getDealData({
      ProcessInstanceId,
      ProcessDefinitionId,
      ProcessStepDefinitionId
    });
    if (data) {
      setLoading(false);
      return setOpen(true);
    }
    return alert(
      'We are unable to access this deal currently, please notify administration'
    );
  };

  return (
    <DashboardLayout title="Dashboard">
      {/* <IconButton
        style={{ display: 'flex', float: 'right' }}
        onClick={handleUIChange}>
        <CgArrowsExchange />
      </IconButton> */}

      <OverViewDialog handleClose={() => setOpen(false)} open={open} />

      <div className={classes.container}>
        <Grid container spacing={1}>
          <Grid item md={6} xs={12}>
            <Progress />
          </Grid>

          <Grid item md={6} xs={12}>
            <User data={user} getData={undefined} editable={false} />
          </Grid>

          {activityChart ? (
            <Grid item md={9} xs={12}>
              <Paper className={classes.chart} variant="outlined">
                <ProcessActivityChart MyProcessInstances={MyProcessInstances} />
              </Paper>
            </Grid>
          ) : (
            <Grid item md={6} xs={12}>
              <Paper className={classes.chart} variant="outlined">
                <DashboardNotification />
              </Paper>
            </Grid>
          )}

          <Grid item md={activityChart ? 3 : 6} xs={12}>
            <Paper className={classes.dash} variant="outlined">
              <Typography align="center" color="textPrimary" variant="h6">
                {'Recent Deals'.toUpperCase()}
              </Typography>

              <div style={{ height: 2 }} />

              {loading && (
                <div
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <CircularProgress />
                </div>
              )}

              <RecentDeals loading={loading} data={data} openDeal={openDeal} />
            </Paper>
          </Grid>

          <Grid item md={12} xs={12}>
            <Paper className={classes.dash} variant="outlined">
              <Calendar />
            </Paper>
          </Grid>
        </Grid>
      </div>
    </DashboardLayout>
  );
};
export default Dashboard;
