import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
import { UserInstance } from 'types/interfaces';

interface props {
  data: UserInstance;
  ProcessInstanceId: number;
  PrimaryUserInstanceId: number; // User Instance Id,
  PartiesAndContacts?: boolean;
}

export const NewContactAutomation = async ({
  data,
  ProcessInstanceId,
  PrimaryUserInstanceId,
  PartiesAndContacts = true
}: props) => {
  let url = `CustomAPI.ashx?action=NewContactAutomation&ProcessInstanceId=${ProcessInstanceId}&PrimaryUserInstanceId=${PrimaryUserInstanceId}&PartiesAndContacts=${PartiesAndContacts}`;
  const config: AxiosRequestConfig = { method: 'POST', url, data };
  return await dbQueue({ config, url });
};

export const InviteToGDPR = async ({
  ProcessInstanceId,
  UserInstanceId
}: {
  ProcessInstanceId: number;
  UserInstanceId: number;
}) => {
  let url = `CustomAPI.ashx?action=InviteToGDPR&ProcessInstanceId=${ProcessInstanceId}&UserInstanceId=${UserInstanceId}`;
  const config: AxiosRequestConfig = { method: 'GET', url };
  return await dbQueue({ config, url });
};

export const InviteToConsent = async ({
  ProcessInstanceId,
  UserInstanceId
}: {
  ProcessInstanceId: number;
  UserInstanceId: number;
}) => {
  let url = `CustomAPI.ashx?action=InviteToConsent&ProcessInstanceId=${ProcessInstanceId}&UserInstanceId=${UserInstanceId}`;
  const config: AxiosRequestConfig = { method: 'GET', url };
  return await dbQueue({ config, url });
};

export const DeleteGDPRContact = async ({
  ObjectInstanceId,
  UserInstanceId,
  ProcessInstanceId
}) => {
  let url = `CustomAPI.ashx?action=DeleteGDPRContact&ProcessInstanceId=${ProcessInstanceId}&UserInstanceId=${UserInstanceId}&ObjectInstanceId=${ObjectInstanceId}`;
  const config: AxiosRequestConfig = { method: 'GET', url };
  return await dbQueue({ config, url });
};

export const SupplierAutomation = async ({
  data,
  ProcessInstanceId,
  ObjectInstanceId
}: {
  data: UserInstance[];
  ProcessInstanceId: number;
  ObjectInstanceId: number;
}) => {
  let url = `CustomAPI.ashx?action=SupplierAutomation&ProcessInstanceId=${ProcessInstanceId}&ObjectInstanceId=${ObjectInstanceId}`;
  const config: AxiosRequestConfig = { method: 'POST', url, data };
  return await dbQueue({ config, url });
};

export const SupplierInvoiceRequest = async ({
  ProcessInstanceId,
  SupplierId,
  SupplierContactId,
  ProcessStepDefinitionId,
  CustomerId,
  FieldDefinitionId,
  ObjectDefinitionId
}: {
  ProcessInstanceId: number;
  SupplierId: number;
  SupplierContactId?: number;
  ProcessStepDefinitionId: number;
  CustomerId: number;
  FieldDefinitionId: number;
  ObjectDefinitionId: number;
}) => {
  let url = `CustomAPI.ashx?action=SUPPLIERINVOICEREQUEST&processinstanceid=${ProcessInstanceId}&supplierid=${SupplierId}&processstepdefinitionid=${ProcessStepDefinitionId}&objectdefinitionid=${ObjectDefinitionId}&fielddefinitionid=${FieldDefinitionId}&customerid=${CustomerId}&suppliercontactid=${SupplierContactId}`;
  const config: AxiosRequestConfig = { method: 'GET', url };
  return await dbQueue({ config, url });
};
