import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Task from '../Task';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import InputBase from '@material-ui/core/InputBase';
import Menu from './Menu';

const useStyles = makeStyles(theme => ({
  root: {
    width: 300,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.background.default
  },
  tasklist: {
    display: 'flex',
    flexDirection: 'column',
    transition: 'all 0.2s ease',
    background: props => props.isDraggingOver && theme.palette.primary.light,
    minHeight: 25
  },
  title: { margin: theme.spacing(1) },
  btn: { margin: theme.spacing(1) },
  headerbar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: theme.spacing(1)
  }
}));

const Component = ({
  column,
  tasks,
  index,
  archiveList,
  updateColumn,
  addTask,
  updateTask
}) => {
  const classes = useStyles();
  const [editTitle, setEditTitle] = React.useState(false);
  const toggleTitle = () => setEditTitle(!editTitle);

  return (
    <Draggable draggableId={column.id} index={index}>
      {(provided, snapshot) => {
        return (
          <Paper
            className={classes.root}
            {...provided.draggableProps}
            ref={provided.innerRef}>
            <div className={classes.headerbar}>
              <EditableInput
                {...{
                  editTitle,
                  toggleTitle,
                  column,
                  updateColumn,
                  provided,
                  classes
                }}
              />

              <Menu archiveList={archiveList} id={column.id} />
            </div>

            <Droppable droppableId={column.id} type="task">
              {(provided, snapshot) => (
                <Column
                  tasks={tasks}
                  provided={provided}
                  snapshot={snapshot}
                  updateTask={updateTask}
                />
              )}
            </Droppable>
            <Button
              className={classes.btn}
              onClick={() => addTask({ columnId: column.id })}>
              + Add another card
            </Button>
          </Paper>
        );
      }}
    </Draggable>
  );
};

const Column = React.memo(({ tasks, provided, snapshot, updateTask }) => {
  const { isDraggingOver, draggingOverWith } = snapshot;
  const classes = useStyles({ isDraggingOver, draggingOverWith });
  return (
    <div
      elevation={1}
      className={classes.tasklist}
      ref={provided.innerRef}
      {...provided.droppableProps}>
      {tasks &&
        tasks.map((task, i) => (
          <Task key={task.id} task={task} index={i} updateTask={updateTask} />
        ))}
      {provided.placeholder}
    </div>
  );
});

const EditableInput = ({
  editTitle,
  toggleTitle,
  column,
  updateColumn,
  provided,
  classes
}) => {
  if (editTitle) {
    return (
      <InputBase
        defaultValue={column.title}
        onBlur={toggleTitle}
        onKeyPress={e => e.charCode === 13 && toggleTitle()}
        autoFocus
        inputProps={{ color: 'red' }}
        onChange={e =>
          updateColumn({
            id: column.id,
            value: e.target.value
          })
        }
      />
    );
  } else {
    return (
      <Typography
        variant="h5"
        className={classes.title}
        {...provided.dragHandleProps}
        onClick={toggleTitle}>
        {column.title}
      </Typography>
    );
  }
};

export default Component;
