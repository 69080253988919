import {
  createStyles,
  Grid,
  GridSize,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AxiosResponse } from 'axios';
import { XpansionIsolated } from 'common/Xpansion';
import InlineTextField from 'components/Fields/InlineTextField';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import DeleteObjectButton from 'components/Stepper/components/Steps/ActiveStep/components/DeleteObjectButton';
import { FieldDefinitionTitle } from 'components/Stepper/components/Steps/ActiveStep/components/FieldDefinitionTitle';
import { getLayoutSpacing } from 'components/Stepper/components/Steps/ActiveStep/helper';
import FieldStatusIcon from 'components/Stepper/components/Steps/ActiveStep/SingleObject/components/FieldStatusIcon';
import { HideShowFields } from 'components/Stepper/functions/hideshowFields';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import useRepeatableObject from 'hooks/useDeals/useRepeatableObject';
import { useObject } from 'hooks/useObject';
import { useEffect, useState } from 'react';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import {
  CompleteObjectInstance,
  CompleteUserInstance,
  FieldDefinition,
  FieldDefinitionDict,
  FieldInstance,
  ObjectDefinition,
  UserInstance
} from 'types/interfaces';
import { useDispatch } from 'react-redux';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { useTypedSelector } from 'redux/reducers';
import { getCachedCompleteObjectInstance } from 'redux/actions/GraphQlActions/cachedRequests';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      margin: '8px 0px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start'
    },
    gridContainer: {
      display: 'flex'
    },
    leverCol: {
      width: '50%'
    },
    otherCol: {
      paddingLeft: theme.spacing(2),
      width: '50%'
    },
    hr: {
      borderRadius: 10,
      background: theme.palette.grey['A100'],
      margin: 5
    },
    inlinetext: {
      color: theme.palette.secondary.main
    },
    paper: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      // '&:hover': { background: theme.palette.background.default },
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      // margin: `8px 0px`,
      width: '100%'
    }
  })
);

const RepeatDynamicObject = ({
  FieldDefinitionDict,
  FieldDefinitionList,
  ObjectDefinition,
  rowData,
  getData
}: {
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[] | undefined;
  ObjectDefinition: ObjectDefinition;
  rowData: CompleteUserInstance;
  getData: () => void;
}) => {
  const dispatch = useDispatch();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const [ObjectInstances, setObjectInstances] = useState<
    CompleteObjectInstance[]
  >([]);

  const leverFieldDefinitions: FieldDefinition[] | undefined =
    FieldDefinitionList?.filter(
      (el) => el.FieldType === 'Drop-downHideShowFields'
    );

  const { deleteObject } = useObject({
    ObjectDefinition,
    UserInstance: rowData.UserInstance,
    ProcessInstanceId: 0,
    Fields: FieldDefinitionList || [],
    ProcessStepSelectionType: ''
  });

  const deleteObjectAndRefresh: (Id: number) => Promise<any> = async (Id) => {
    const deleted = await deleteObject(Id);

    if (deleted) {
      dispatch(
        createNotification(
          successNotif(
            `Object With ID: ${Id} Has Been Successfully Deleted. Refreshing Details...`
          )
        )
      );
      await getData();
    }
  };

  const canAddMore =
    ObjectDefinition.ObjectRepeat === 0
      ? true
      : ObjectInstances.length < ObjectDefinition.ObjectRepeat;

  useEffect(() => {
    const cachedUserInstanceDetail = getCachedCompleteObjectInstance(
      rowData.UserInstance.Id,
      baseUrl
    );

    let filterRepeatableObject: CompleteObjectInstance[] | undefined;
    if (cachedUserInstanceDetail) {
      filterRepeatableObject =
        cachedUserInstanceDetail.CompleteObjectInstanceList.filter(
          (CompleteObjectInstance: CompleteObjectInstance) =>
            CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
            ObjectDefinition.Id
        );
    } else {
      filterRepeatableObject = rowData.CompleteObjectInstanceList.filter(
        (CompleteObjectInstance: CompleteObjectInstance) =>
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
          ObjectDefinition.Id
      );
    }

    if (filterRepeatableObject) setObjectInstances(filterRepeatableObject);
  }, [ObjectDefinition, rowData]);

  const readOnly: boolean = ReadOnlyRedflag({ ObjectDefinition });
  const { modifiedObjectInstances, readOnlyFields } = useRepeatableObject({
    CompleteObjectInstanceList: ObjectInstances
  });

  return (
    <div>
      <div>
        {modifiedObjectInstances()
          .filter(
            (CompleteObjectInstance) =>
              !!CompleteObjectInstance.ObjectInstance.IsPublished
          )
          .map(
            (
              CompleteObjectInstance: CompleteObjectInstance,
              localIndex: number
            ) => (
              <ObjectInstantiation
                key={CompleteObjectInstance.ObjectInstance.Id}
                CompleteObjectInstance={CompleteObjectInstance}
                leverFieldDefinitions={leverFieldDefinitions}
                FieldDefinitionList={FieldDefinitionList}
                ObjectDefinition={ObjectDefinition}
                UserInstance={rowData.UserInstance}
                deleteObjectAndRefresh={deleteObjectAndRefresh}
                idx={CompleteObjectInstance.ObjectInstance.Id}
                readOnlyFields={readOnlyFields}
                readOnly={readOnly}
                localIndex={localIndex}
              />
            )
          )}
      </div>
      <br />
      {!readOnly && canAddMore && rowData.UserInstance.Id && (
        <AddFieldAndObjectButton
          ObjectDefinition={ObjectDefinition}
          Fields={Object.values(FieldDefinitionDict)}
          UserInstance={rowData.UserInstance}
          ProcessInstanceId={0}
          ProcessInstance={undefined}
          label={`Add New ${ObjectDefinition.Title} Object`}
          ProcessStepSelectionType={''}
        />
      )}
    </div>
  );
};

const ObjectInstantiation = ({
  CompleteObjectInstance,
  leverFieldDefinitions,
  FieldDefinitionList,
  ObjectDefinition,
  UserInstance,
  deleteObjectAndRefresh,
  idx,
  readOnly,
  localIndex,
  readOnlyFields
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  FieldDefinitionList: FieldDefinition[] | undefined;
  leverFieldDefinitions: FieldDefinition[] | undefined;
  ObjectDefinition: ObjectDefinition;
  UserInstance: UserInstance;
  deleteObjectAndRefresh: (Id: number) => Promise<AxiosResponse<any>>;
  idx: number;
  readOnly: boolean;
  localIndex: number;
  readOnlyFields: number[];
}) => {
  const classes = useStyles();
  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

  let fieldsToShow: FieldDefinition[] = HideShowFields({
    FieldDefinitionList: FieldDefinitionList || [],
    CompleteObjectInstance
  }).sort(
    (a: FieldDefinition, b: FieldDefinition) => a.ItemOrder - b.ItemOrder
  );

  let md: GridSize = getLayoutSpacing(ObjectDefinition.ObjectDescription);
  return (
    <XpansionIsolated
      key={idx}
      expanded
      summary={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'grey',
            width: '100%'
          }}>
          <div
            style={{
              flexGrow: 1
            }}>
            {ObjectDefinition.Title} : {localIndex + 1}
          </div>
          {!readOnly && (
            <DeleteObjectButton
              isDeclined={undefined}
              object={CompleteObjectInstance}
              deleteObject={deleteObjectAndRefresh}
              title={CompleteObjectInstance.ObjectInstance.Title}
            />
          )}
        </div>
      }>
      <Grid container spacing={1}>
        {fieldsToShow.map((FieldDefinition: FieldDefinition) => {
          const isReadOnly: boolean = ReadOnlyRedflag({
            ObjectDefinition: ObjectDefinition,
            FieldDefinition
          });

          if (FieldDefinition.Id === 23126) return;

          const readOnlyField =
            FieldDefinition.FieldType.includes('Information');

          if (readOnlyField) {
            const hexColorMatch = FieldDefinition.FieldDescription.match(
              /hexColor-\[([^[\]]*)\]/
            );

            const borderColorMatch =
              FieldDefinition.FieldDescription.match(/border-\[([^[\]]*)\]/);

            const hexColor = hexColorMatch ? hexColorMatch[1] : '';
            const borderColor = borderColorMatch ? borderColorMatch[1] : '';

            const style = {
              backgroundColor: hexColor,
              ...(borderColor && { border: `2px solid ${borderColor}` })
            };

            return (
              <Grid item key={FieldDefinition.Id} xs={12}>
                <Alert severity="info" style={style} elevation={1}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    {FieldDefinition.Title}
                  </Typography>
                </Alert>
              </Grid>
            );
          }

          const fieldDefinitionExists = FieldInstanceList.some(
            (FieldInstance: FieldInstance) =>
              FieldInstance.FieldDefinitionId === FieldDefinition.Id
          );

          if (!fieldDefinitionExists) {
            return (
              <Grid
                item
                key={FieldDefinition.Id}
                xs={12}
                md={md}
                className={classes.gridContainer}>
                <div className={classes.paper}>
                  <div style={{ display: 'flex' }}>
                    <FieldDefinitionTitle
                      title={FieldDefinition.Title}
                      height="h6"
                    />
                  </div>
                  <div className={classes.inlinetext}>
                    {CompleteObjectInstance.ObjectInstance.IsPublished && (
                      <InlineTextField
                        readOnly={isReadOnly}
                        FieldDefinition={FieldDefinition}
                        FieldInstance={undefined}
                        ObjectDefinition={ObjectDefinition}
                        ObjectInstance={
                          CompleteObjectInstance &&
                          CompleteObjectInstance.ObjectInstance
                        }
                        ProcessInstance={{ Id: 0 }}
                        ProcessStepSelectionType={''}
                        UserInstance={UserInstance}
                        fields={leverFieldDefinitions || []}
                        type={FieldDefinition.FieldType}
                        value={''}
                        parentsIsFormComponent
                        isDetails
                      />
                    )}
                  </div>
                </div>
              </Grid>
            );
          }

          return (
            <Grid
              item
              key={FieldDefinition.Id}
              xs={12}
              md={md}
              className={classes.gridContainer}
              alignItems="stretch">
              {FieldInstanceList.map((FieldInstance: FieldInstance) => {
                const IndicativeDeclineId = [23525, 23592];
                if (
                  IndicativeDeclineId.includes(FieldInstance.FieldDefinitionId)
                )
                  return <div />;

                if (FieldInstance.FieldDefinitionId === FieldDefinition.Id) {
                  let isFieldReadOnly =
                    isReadOnly || readOnlyFields.includes(FieldInstance.Id);

                  return (
                    <div className={classes.paper} key={FieldInstance.Id}>
                      <div style={{ display: 'flex' }}>
                        <FieldStatusIcon
                          FieldDefinition={FieldDefinition}
                          FieldInstance={FieldInstance}
                          Required={FieldDefinition.FieldRequired}
                        />

                        <FieldDefinitionTitle
                          title={FieldDefinition.Title}
                          height="h6"
                        />
                      </div>
                      <div className={classes.inlinetext}>
                        <div style={{ visibility: 'hidden' }}>
                          <FieldStatusIcon
                            FieldDefinition={FieldDefinition}
                            FieldInstance={FieldInstance}
                            Required={FieldDefinition.FieldRequired}
                          />
                        </div>
                        {CompleteObjectInstance.ObjectInstance.IsPublished && (
                          <InlineTextField
                            readOnly={isFieldReadOnly}
                            FieldDefinition={FieldDefinition}
                            FieldInstance={FieldInstance}
                            ObjectDefinition={ObjectDefinition}
                            ObjectInstance={
                              CompleteObjectInstance &&
                              CompleteObjectInstance.ObjectInstance
                            }
                            ProcessInstance={{ Id: 0 }}
                            ProcessStepSelectionType={''}
                            UserInstance={UserInstance}
                            fields={leverFieldDefinitions || []}
                            type={FieldDefinition.FieldType}
                            value={FieldInstance?.FieldValue}
                            parentsIsFormComponent
                            isDetails
                          />
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </Grid>
          );
        })}
      </Grid>
    </XpansionIsolated>
  );
};

export default RepeatDynamicObject;
