import { ELenderAPIType } from '../../interface';
import FleximizeProposal from '.';
import { createTypeSafeIds } from '../../utils';

export const getFleximizeIds = () => {
  return createTypeSafeIds(FleximizeProposal, (_) => ({
    is_test: {
      FieldDefinitionId: 0,
      isReadonly: true,
      required: false,
      forceValues: true,
      FieldDefinition: {
        type: ELenderAPIType.Boolean
      }
    },
    business_type_id: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.OptionsList,
        options: [
          {
            label: 'Sole Trader',
            value: 1
          },
          {
            label: 'Non-Limited Partnership',
            value: 2
          },
          {
            label: 'Limited Company',
            value: 3
          },
          {
            label: 'Limited Partnership (LLP)',
            value: 4
          }
        ]
      }
    },
    company_name: {
      FieldDefinitionId: 18315,
      isReadonly: true,
      required: true
    },
    registration_number: {
      FieldDefinitionId: 18315,
      isReadonly: true,
      required: true
    },
    title_id: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.OptionsList,
        options: [
          {
            label: 'Mr',
            value: 1
          },
          {
            label: 'Mrs',
            value: 2
          },
          {
            label: 'Miss',
            value: 3
          },
          {
            label: 'Ms',
            value: 4
          },
          {
            label: 'Dr',
            value: 4
          },
          {
            label: 'Unknown',
            value: null
          }
        ]
      }
    },
    first_name: {
      FieldDefinitionId: 23108,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.ConcatenatedBySpace
      }
    },
    last_name: {
      FieldDefinitionId: 23108,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.ConcatenatedBySpace
      }
    },
    phone: {
      FieldDefinitionId: 18525,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Requirement,
        requirement: {
          minLength: 8,
          maxLength: 80,
          pattern: '^(0|\\+)\\d+$',
          message:
            'Phone number must be at least 8 digits and start with 0 or +'
        }
      }
    },
    email: {
      FieldDefinitionId: 18452,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Requirement,
        requirement: {
          maxLength: 255,
          pattern:
            '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
          message: 'Please enter a valid email address'
        }
      }
    },
    loan_amount: {
      FieldDefinitionId: 23820,
      isReadonly: false,
      required: true,
      info: 'Loan Amount should be anywhere between 5000 to 500000, anything less or more will not work.',
      FieldDefinition: {
        type: [ELenderAPIType.Currency, ELenderAPIType.Requirement],
        requirement: {
          minAmount: 5000,
          maxAmount: 500000
        }
      }
    },
    term: {
      FieldDefinitionId: 18332,
      isReadonly: false,
      required: true,
      info: 'Term should be anywhere between 3 to 60 Months, anything less or more will not work.',
      FieldDefinition: {
        type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
        requirement: {
          minAmount: 3,
          maxAmount: 60
        }
      }
    },
    funding_urgency: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.OptionsList,
        options: [
          {
            label: 'As soon as possible',
            value: 1
          },
          {
            label: 'Within a few weeks',
            value: 2
          },
          {
            label: `I just want to see what's available`,
            value: 3
          }
        ]
      }
    },
    loan_purpose_id: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.OptionsList,
        options: [
          {
            label: 'Hire staff',
            value: 1
          },
          {
            label: 'Management buyout',
            value: 2
          },
          {
            label: `Marketing`,
            value: 3
          },
          {
            label: `Moving premises`,
            value: 4
          },
          {
            label: `Fulfil an order or contract`,
            value: 5
          },
          {
            label: `Pay a due bill`,
            value: 6
          },
          {
            label: `Pay HMRC`,
            value: 7
          },
          {
            label: `Pay staff`,
            value: 8
          },
          {
            label: `Purchase stock`,
            value: 9
          },
          {
            label: `Purchase equipment`,
            value: 10
          },
          {
            label: `Refinance debt`,
            value: 11
          },
          {
            label: `Refurbish premises`,
            value: 12
          },
          {
            label: `Upgrade website`,
            value: 13
          },
          {
            label: `Business expansion`,
            value: 14
          },
          {
            label: `Working capital / cash flow`,
            value: 15
          },
          {
            label: `Other (please specify)`,
            value: 16
          }
        ]
      }
    },
    privacy_policy_consent: {
      FieldDefinitionId: 21397,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Boolean
      }
    },
    cra_checks_consent: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Boolean
      }
    },
    refer_consent: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Boolean
      }
    },
    company: {
      vat_no: {
        FieldDefinitionId: 21397,
        isReadonly: false,
        required: false
      },
      monthly_sales: {
        FieldDefinitionId: 24094,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Number
        }
      },
      date_of_creation: {
        FieldDefinitionId: 0,
        isReadonly: true,
        required: false,
        forcedValue: 'currentDate'
      },
      trading_date: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Date
        }
      },
      same_address: {
        FieldDefinitionId: 0,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.Boolean,
          condition: 'same_address == true trading_address'
        }
      },
      registered_address: {
        abode_name_no: {
          FieldDefinitionId: 18430,
          isReadonly: false,
          required: true
        },
        street: {
          FieldDefinitionId: 18432,
          isReadonly: false,
          required: true
        },
        street2: {
          FieldDefinitionId: 18434,
          isReadonly: false,
          required: false
        },
        town: {
          FieldDefinitionId: 18435,
          isReadonly: false,
          required: true
        },
        county: {
          FieldDefinitionId: 18436,
          isReadonly: false,
          required: false
        },
        postcode: {
          FieldDefinitionId: 18438,
          isReadonly: false,
          required: true
        }
      },
      trading_address: {
        abode_name_no: {
          FieldDefinitionId: 18430,
          isReadonly: false,
          required: true
        },
        street: {
          FieldDefinitionId: 18432,
          isReadonly: false,
          required: true
        },
        street2: {
          FieldDefinitionId: 18434,
          isReadonly: false,
          required: false
        },
        town: {
          FieldDefinitionId: 18435,
          isReadonly: false,
          required: true
        },
        county: {
          FieldDefinitionId: 18436,
          isReadonly: false,
          required: true
        },
        postcode: {
          FieldDefinitionId: 18438,
          isReadonly: false,
          required: true
        }
      },
      directors: [
        {
          title_id: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Mr',
                  value: 1
                },
                {
                  label: 'Mrs',
                  value: 2
                },
                {
                  label: 'Miss',
                  value: 3
                },
                {
                  label: 'Ms',
                  value: 4
                },
                {
                  label: 'Dr',
                  value: 4
                },
                {
                  label: 'Unknown',
                  value: null
                }
              ]
            }
          },
          first_name: {
            FieldDefinitionId: 18420,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.ConcatenatedBySpace
            }
          },
          last_name: {
            FieldDefinitionId: 18427,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.ConcatenatedBySpace
            }
          },
          date_of_birth: {
            FieldDefinitionId: 18416,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          residential_type_id: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Homeowner',
                  value: 1
                },
                {
                  label: 'Living with Partner',
                  value: 2
                },
                {
                  label: 'Tenant',
                  value: 3
                },
                {
                  label: 'Other',
                  value: 4
                }
              ]
            }
          },
          major_shareholder: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          abode_name_no: {
            FieldDefinitionId: 18414,
            isReadonly: false,
            required: true
          },
          street: {
            FieldDefinitionId: 18414,
            isReadonly: false,
            required: true
          },
          street2: {
            FieldDefinitionId: 18414,
            isReadonly: false,
            required: false
          },
          town: {
            FieldDefinitionId: 18429,
            isReadonly: false,
            required: true
          },
          county: {
            FieldDefinitionId: 18417,
            isReadonly: false,
            required: false
          },
          postcode: {
            FieldDefinitionId: 18425,
            isReadonly: false,
            required: true
          },
          move_in_date: {
            info: 'Previous Addresses Is Required if Move In Date is not over 3 years ago',
            FieldDefinitionId: 23700,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              },
              condition: 'move_in_date > 3years previous_addresses'
            }
          },
          previous_addresses: [
            {
              abode_name_no: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              street2: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: false
              },
              town: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              county: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: false
              },
              postcode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              move_in_date: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            }
          ]
        }
      ],
      shareholders: [
        {
          title_id: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Mr',
                  value: 1
                },
                {
                  label: 'Mrs',
                  value: 2
                },
                {
                  label: 'Miss',
                  value: 3
                },
                {
                  label: 'Ms',
                  value: 4
                },
                {
                  label: 'Dr',
                  value: 4
                },
                {
                  label: 'Unknown',
                  value: null
                }
              ]
            }
          },
          first_name: {
            FieldDefinitionId: 24030,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.ConcatenatedBySpace
            }
          },
          last_name: {
            FieldDefinitionId: 24031,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.ConcatenatedBySpace
            }
          },
          major_shareholder: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          date_of_birth: {
            FieldDefinitionId: 24076,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          residential_type_id: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Homeowner',
                  value: 1
                },
                {
                  label: 'Living with Partner',
                  value: 2
                },
                {
                  label: 'Tenant',
                  value: 3
                },
                {
                  label: 'Other',
                  value: 4
                }
              ]
            }
          },
          abode_name_no: {
            FieldDefinitionId: 24032,
            isReadonly: false,
            required: true
          },
          street: {
            FieldDefinitionId: 24032,
            isReadonly: false,
            required: true,
            info: 'This Contains The Whole Address'
          },

          town: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          postcode: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          move_in_date: {
            info: 'Previous Addresses Is Required if Move In Date is not over 3 years ago, else you can delete',
            FieldDefinitionId: 24032,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              },
              condition: 'move_in_date > 3years previous_addresses'
            }
          },
          previous_addresses: [
            {
              abode_name_no: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              town: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              postcode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              move_in_date: {
                FieldDefinitionId: 24032,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                  requirement: {
                    pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                  }
                }
              }
            }
          ]
        }
      ]
    }
  }));
};
