import {
  CompleteObjectInstance,
  CompleteUserInstance,
  ProcessInstance
} from 'types/interfaces';
import { store } from 'redux/store';
import { SET_ALL_LIST } from 'redux/actions/types';
import { updateProcessCompleteObjectInstanceInRedux } from './updateProcessCompleteObjectInstanceInRedux';

const { dispatch } = store;
interface IUserList {
  [UserDefinitionId: number]: {
    [UserDefinitionId: number]: CompleteUserInstance[];
    Id: string;
  };
}

export const updateUserANDProcessCompleteObjectInstanceInRedux = async ({
  CompleteObjectInstance,
  ProcessInstance
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  ProcessInstance: ProcessInstance | undefined;
}) => {
  const userList: IUserList = store.getState().userAPI.userList;
  const { ObjectInstance } = CompleteObjectInstance;
  const { UserDefinitionId, UserInstanceId } = ObjectInstance;
  const updatedUserList: IUserList = JSON.parse(JSON.stringify(userList));
  const existingUserList = updatedUserList[UserDefinitionId];

  if (existingUserList) {
    const UserInstance = updatedUserList[UserDefinitionId][UserDefinitionId];
    const targetInstance = UserInstance.find(
      (item: CompleteUserInstance) => item.UserInstance.Id === UserInstanceId
    );

    const newCompleteObjectInstance = {
      ...CompleteObjectInstance,
      FieldInstanceDict: {}
    };

    targetInstance &&
      targetInstance.CompleteObjectInstanceList.push(newCompleteObjectInstance);

    dispatch({ type: SET_ALL_LIST, payload: updatedUserList });
  }

  await updateProcessCompleteObjectInstanceInRedux({
    CompleteObjectInstance,
    ProcessInstance
  });

  return CompleteObjectInstance;
};
