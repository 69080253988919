import { SET_SOLVE_LAYOUT_VIEW } from 'redux/actions/types';
import calculations, {
  INIT_CONFIG,
  INIT_BALLOON
} from '../../Utils/calculations';
import { Calculation, Init_Config } from 'types/calculatorInterfaces';
import { ForValue, FromValue } from '../interfaces';

interface Props {
  state: Calculation;
  forValue: ForValue;
  fromValue: FromValue;
  setConfig: (Init_Config: Init_Config) => void;
  setState: (Calculation: Calculation) => void;
  setFromValue: (v: FromValue) => void;
  setBalloonRep: (balloon: number) => void;
  isRecalculate: boolean;
  isManual: boolean;
  dispatch: any;
}
export const solveFromSwitch = ({
  setBalloonRep,
  state,
  forValue,
  fromValue,
  setConfig,
  setState,
  setFromValue,
  isRecalculate,
  isManual,
  dispatch
}: Props): void => {
  const forBalloonType = (calcNum: number): Calculation => {
    if (isRecalculate)
      return { ...state, Calculation: calcNum, _Balloon: INIT_BALLOON };
    return {
      ...state,
      Calculation: calcNum
      // Yield: 0,
      // MarginRate: 0,
      // APR: 0,
      // FlatRate: 0,
      // MonthlyPayment: 0,
      // CommissionPercentage: 0,
      // CommissionAmount: 0,
      // _Balloon: INIT_BALLOON,
      // _Commission: INIT_COMMISSION
    };
  };
  const forPaymentType = (calcNum: number): Calculation => {
    if (isRecalculate) {
      if (!isManual) {
        // console.log('forPaymentType & AUTOMATIC');
        return { ...state, Calculation: calcNum };
      }
      // console.log('forPaymentType & MANUAL');
      return { ...state, Calculation: calcNum, MonthlyPayment: 0 };
    }

    return {
      ...state,
      Calculation: calcNum,
      // Yield: 0,
      // MarginRate: 0,
      // APR: 0,
      // FlatRate: 0,
      MonthlyPayment: 0
      // CommissionPercentage: 0,
      // CommissionAmount: 0,
      // _Commission: INIT_COMMISSION
    };
  };
  const forAmountType = (calcNum: number): Calculation => {
    const _NetAdvance = () => {
      let data = JSON.parse(JSON.stringify(state._NetAdvance));
      data.Purchase_Price = 0;
      data.NetAdvance = 0;
      data.VatTotal = 0;
      data.Purchase_Vat = 0;
      return data;
    };
    if (isRecalculate) {
      if (!isManual) return { ...state, Calculation: calcNum };
      return {
        ...state,
        Calculation: calcNum,
        _NetAdvance: _NetAdvance(),
        Amount: 0
      };
    }

    return {
      ...state,
      Calculation: calcNum
      // Yield: 0,
      // MarginRate: 0,
      // APR: 0,
      // FlatRate: 0,
      // Amount: 0,
      // CommissionPercentage: 0,
      // CommissionAmount: 0,
      // _NetAdvance: _NetAdvance(),
      // _Commission: INIT_COMMISSION
    };
  };
  const forRatesType = (calcNum: number): Calculation => {
    if (isRecalculate) return { ...state, Calculation: calcNum };
    return {
      ...state,
      Calculation: calcNum
      // Yield: 0,
      // MarginRate: 0,
      // APR: 0,
      // FlatRate: 0,
      // CommissionPercentage: 0,
      // CommissionAmount: 0,
      // _Commission: INIT_COMMISSION
    };
  };
  const switchConfig = (id, allowedFromValues) => {
    const newConfig = Object.assign({ ...INIT_CONFIG }, calculations[id]);
    setBalloonRep(state.MonthlyPayment);
    setConfig(newConfig);

    dispatch({
      type: SET_SOLVE_LAYOUT_VIEW,
      payload: allowedFromValues
    });
  };

  const configMap = {
    forRates: { function: forRatesType, default: 10, allowedFromValues: [] },
    forPayment: {
      function: forPaymentType,
      allowedFromValues: [
        FromValue.FromYield,
        FromValue.FromRate,
        FromValue.FromFlatRate,
        FromValue.FromNominalFlatRate,
        FromValue.FromAPR
      ] as FromValue[],
      fromYield: 9,
      fromRate: 8,
      fromFlatRate: 7,
      fromAPR: 6,
      fromNominalFlatRate: 15
    },
    forCommission: {
      function: forRatesType,
      allowedFromValues: [FromValue.FromYield, FromValue.FromRate],
      fromYield: 5,
      fromRate: 4,
      fromAPR: 5,
      fromFlatRate: 5
    },
    forAmount: {
      function: forAmountType,
      allowedFromValues: [
        FromValue.FromYield,
        FromValue.FromRate,
        FromValue.FromFlatRate,
        FromValue.FromNominalFlatRate,
        FromValue.FromAPR
      ] as FromValue[],
      fromYield: 3,
      fromRate: 2,
      fromFlatRate: 1,
      fromAPR: 0,
      fromNominalFlatRate: 16
    },
    forBalloon: {
      function: forBalloonType,
      allowedFromValues: [
        FromValue.FromYield,
        FromValue.FromRate,
        FromValue.FromFlatRate,
        FromValue.FromAPR
      ],
      fromYield: 14,
      fromRate: 13,
      fromFlatRate: 12,
      fromAPR: 11
    }
  };

  const lookupConfig = (forValue, fromValue) => {
    const forConfig = configMap[forValue];

    if (forConfig) {
      const configValue =
        forConfig[fromValue] !== undefined
          ? forConfig[fromValue]
          : forConfig['default'];
      const configFunction = forConfig['function'];
      const allowedFromValues = forConfig['allowedFromValues'] || [];

      if (configFunction) {
        setState(configFunction(configValue));
      }

      switchConfig(configValue, allowedFromValues);
    }
  };

  lookupConfig(forValue, fromValue);
};
