import { Box, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import SimplifiedDocumentCard from 'components/FileStorage/components/SimplifiedDocumentCard';
import React, { useEffect, useState } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { theme } from 'theme';
import { FbFileRef } from 'types/interfaces';
import { extractGroupName } from '..';
import { IConfirmationDocumentProps } from '../interface';
import { useAlertDialogStyles } from '../styles';

const DialogChecklistContent = ({
  ruleId,
  allChecked,
  checklist,
  handleChecklistChange,
  showAssignedViewers,
  additionalCheckboxes,
  additionalContent
}: IConfirmationDocumentProps): JSX.Element => {
  const classes = useAlertDialogStyles({ allChecked });
  const [activeFields, setActiveFields] = useState<FbFileRef[]>([]);
  const { InteractiveRuleDict } = useTypedSelector(
    (s) => s.process.currentStep
  );

  const dealSummary = useTypedSelector((s) => s.dealSummary.deal);
  const { files } = useTypedSelector((s) => s.process);

  const extractedName = extractGroupName(
    InteractiveRuleDict[ruleId]?.Description
  );

  const getFilesBasedOnGroup = () => {
    const receiverMap = {
      Customer: dealSummary?.primaryContact?.Id,
      Lender: dealSummary?.lenders?.selected?.[0]?.Id?.toString(),
      Accounts: dealSummary?.accounts?.all?.[0]?.Id?.toString()
    };

    if (extractedName) {
      const receiverId = receiverMap[extractedName];
      const fileRef = files.filter((file: FbFileRef) =>
        file.users.includes(receiverId)
      );

      setActiveFields(fileRef);
    }
  };

  useEffect(() => {
    getFilesBasedOnGroup();
  }, [dealSummary, files, extractedName]);

  return (
    <React.Fragment>
      {showAssignedViewers && (
        <Box className={classes.warningBox}>
          <Warning className={classes.warningIcon} />
          <Typography variant="body1">
            <span className={classes.boldText}>Important:</span> If viewers are
            not assigned, they will not be able to access the documents on the
            landing page.
          </Typography>
        </Box>
      )}

      {activeFields.map((file: FbFileRef, index: number) => (
        <div
          key={file.fileId ?? index}
          style={{ paddingBottom: theme.spacing(1) }}>
          <SimplifiedDocumentCard key={file.fileId ?? index} item={file} />
        </div>
      ))}

      <Typography className={classes.sectionHeader}>
        Before proceeding, please confirm the following:
      </Typography>

      {showAssignedViewers && (
        <FormControlLabel
          control={
            <Checkbox
              checked={checklist.assignedViewers}
              onChange={handleChecklistChange}
              name="assignedViewers"
              classes={{
                root: classes.checkbox,
                checked: classes.checked
              }}
            />
          }
          label={
            <Typography className={classes.checklistText}>
              I have correctly{' '}
              <span className={classes.boldText}>Assigned Viewers</span> to all
              relevant documents in{' '}
              <span className={classes.boldText}>File Storage</span>.
            </Typography>
          }
          className={classes.checklistItem}
        />
      )}

      {additionalCheckboxes?.map((checkbox) => (
        <FormControlLabel
          key={checkbox.name}
          control={
            <Checkbox
              checked={checklist[checkbox.name] || false}
              onChange={handleChecklistChange}
              name={checkbox.name}
              classes={{
                root: classes.checkbox,
                checked: classes.checked
              }}
            />
          }
          label={
            <Typography className={classes.checklistText}>
              {checkbox.label}
            </Typography>
          }
          className={classes.checklistItem}
        />
      ))}

      {additionalContent && (
        <Box mt={2}>
          <Typography variant="body2">{additionalContent}</Typography>
        </Box>
      )}
    </React.Fragment>
  );
};

export default DialogChecklistContent;
