import { CompleteObjectInstance, ProcessInstance } from 'types/interfaces';
import { store } from 'redux/store';
import { SET_CURRENT_DEAL } from 'redux/actions/types';
import { refreshOverViewData } from 'redux/actions/processes/getDealData';

const { dispatch } = store;

export const updateProcessCompleteObjectInstanceInRedux = async ({
  CompleteObjectInstance,
  ProcessInstance
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  ProcessInstance: ProcessInstance | undefined;
}) => {
  const { ObjectInstance } = CompleteObjectInstance;
  const { Id } = ObjectInstance;

  const currentDeal = store.getState().process.currentDeal;
  const dealExists: boolean = Object.keys(currentDeal).length > 0;

  if (!dealExists) return;
  const updatedCurrentDeal = JSON.parse(JSON.stringify(currentDeal));
  const objExists: boolean | undefined =
    updatedCurrentDeal?.CompleteObjectInstanceDict?.[Id];

  if (!objExists) updatedCurrentDeal.CompleteObjectInstanceDict[Id] = {};
  updatedCurrentDeal.CompleteObjectInstanceDict[Id] = CompleteObjectInstance;

  dispatch({ type: SET_CURRENT_DEAL, payload: updatedCurrentDeal });

  if (ProcessInstance && ProcessInstance.Id !== 0) {
    await refreshOverview({ ProcessInstance });
  }
  return CompleteObjectInstance;
};

const refreshOverview = async ({ ProcessInstance }) => {
  const currentStepId = store.getState().process.currentStepId;
  return await refreshOverViewData({
    ProcessInstanceId: ProcessInstance.Id,
    ProcessDefinitionId: ProcessInstance.ProcessDefinitionId,
    ProcessStepDefinitionId: currentStepId
  });
};
