import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import {
  GET_COMPLETE_OBJECT_INSTANCE,
  GET_COMPLETE_USER_INSTANCE_DETAIL
} from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import {
  UPDATE_FIELD_INSTANCE,
  UPDATE_OBJECT
} from 'graphql/UserInstanceAPI/mutations';
import { FieldInstance } from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import { removeTypenames } from '../User Instance API/mutations';

/**
 * Updates a FieldInstance using the provided parameters.
 * @param {string} baseUrl - The base URL for the hostname.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to update.
 * @param {FieldInstance} data - The data object containing the updated FieldInstance information.
 * @returns {Promise<FieldInstance | ApolloError>} - A promise resolving to the updated FieldInstance or an ApolloError.
 */
export const UpdateField = async ({
  baseUrl,
  ProcessInstanceId,
  data: payload
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  data: FieldInstance;
}): Promise<FieldInstance | ApolloError> => {
  try {
    const newData = removeTypenames(payload);

    console.log({ ProcessInstanceId, newData });
    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_FIELD_INSTANCE,
      variables: {
        baseUrl,
        processInstanceId: ProcessInstanceId,
        data: newData
      },
      refetchQueries: [
        {
          query: GET_COMPLETE_OBJECT_INSTANCE,
          variables: { baseUrl, userInstanceId: newData.UserInstanceId }
        }
      ],
      awaitRefetchQueries: true
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    return data.UpdateField as FieldInstance;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};
