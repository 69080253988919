import React from 'react';
import { useSelector } from 'react-redux';
import { firebase } from 'redux/firebase';
import CustomizedTimeline from './display';
import { emailsByUser } from 'redux/actions/email';
import { getAuditByUser } from 'redux/actions/audits';
import { getProcessesByUser } from 'redux/actions/processes';
import { GetObjectInstanceListByKeyword } from 'redux/database';
import { ProcessSummary, UserDefinition, UserInstance } from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import { useTypedSelector } from 'redux/reducers';
import { getRelationship } from 'redux/actions/GraphQlActions';

export interface Event {
  event: string;
  timestamp: number;
  singlesend_name: string;
  invitedUserInstanceIds?: number[];
}

export interface TimelineRelationship {
  UserDefinition: UserDefinition;
  UserInstance: UserInstance;
}

const db = firebase.firestore();
const Timeline = (props) => {
  const token = useSelector<any>((s) => s.user.auth.token);
  const user = useTypedSelector((s) => s.user.user);
  const { UserInstance } = props.rowData;
  const [events, setEvents] = React.useState<any>([]);
  const [open, setOpen] = React.useState<Boolean>(false);
  const [refreshID, setRefreshID] = React.useState<number>(0);
  const [loading, setLoading] = React.useState<Boolean>(false);
  const [deals, setDeals] = React.useState([]);

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const getSystemEvents = async () => {
    // FROM FIREBASE
    setLoading(true);
    let data: Event[] = [];
    data = await getEvents();

    // FOR EACH PROCESS BY USER
    let structuredFileNotes: Event[] = [];
    await getProcessesByUser({ Id: UserInstance.Id })
      .then((processObjects) => {
        if (processObjects?.data.length > 0) {
          setDeals(processObjects.data);
          processObjects?.data?.forEach(async (dealSummary: any) => {
            const { ProcessInstanceId, Id } = dealSummary;
            return await GetObjectInstanceListByKeyword({
              ProcessInstanceId,
              Keyword: 'file_note'
            })
              .then((res2) => {
                const CompleteObjectInstanceDict = res2?.data;
                const file_noteExists =
                  Object.keys(CompleteObjectInstanceDict).length > 0;
                if (file_noteExists) {
                  return Object.values(CompleteObjectInstanceDict).forEach(
                    (CompleteObjectInstance: any) => {
                      const { FieldInstanceList } = CompleteObjectInstance;
                      if (FieldInstanceList) {
                        const findProcessInstanceId =
                          FieldInstanceList[0].ProcessInstanceId;
                        const findDeal: ProcessSummary =
                          processObjects?.data?.find(
                            (deal: ProcessSummary) =>
                              deal.ProcessInstanceId === findProcessInstanceId
                          );

                        const newStructure = {
                          dealId: findDeal.ProcessInstanceId,
                          dealType: findDeal.ProcessDefinitionTitle,
                          value: decodeURIComponent(
                            FieldInstanceList[0].FieldValue
                          )
                        };

                        const JSON_Structure = JSON.stringify(newStructure);
                        return structuredFileNotes.push({
                          event: 'Deal Note',
                          timestamp:
                            new Date(
                              FieldInstanceList[0].LastModified
                            ).getTime() / 1000,
                          singlesend_name: JSON_Structure
                        });
                      }
                    }
                  );
                }
              })
              .catch((e) => {
                // console.log(e)
                BugTracker.notify(e);
              });
          });
        }
      })
      .catch((e) => {
        // console.log(e);
        BugTracker.notify(e);
      });

    // get emails
    let structuredEmails: Event[] = [];
    const emails: any = await emailsByUser({ UserInstanceId: UserInstance.Id });
    console.log({ emails });
    if (emails?.data?.length > 0) {
      emails.data.forEach((email) => {
        structuredEmails.push({
          event: 'landing page email',
          timestamp: new Date(email.LastModified).getTime() / 1000,
          singlesend_name: email.ProcessStepDefinitionTitle
        });
      });
    }

    // get activities
    // relationships of type activities
    let structuredActivities: Event[] = [];
    const response = (await getRelationship({
      action: 'LIST_ALL',
      UserInstanceId: UserInstance.Id,
      UserDefinitionId: UserInstance.UserDefinitionId,
      baseUrl
    })) as UserInstance[] | undefined;

    if (response) {
      if (response.length > 0) {
        response.forEach((UserInstance: UserInstance) => {
          const { type, start, end, desc, created } = destructureField(
            UserInstance.UserInstanceEmail
          );

          const date = new Date(start).getTime() / 1000;
          if (date) {
            structuredActivities.push({
              event: type,
              timestamp: date,
              singlesend_name: UserInstance.Title
            });
          }
        });
      }
    }

    // get logs
    let structuredLogs: Event[] = [];
    const logs: any = await getAuditByUser({
      token,
      UserInstanceId: UserInstance.Id
    });
    if (logs?.data?.length > 0) {
      logs.data.forEach((log: any) => {
        const name = log.Endpoint.split('/')[2].split('.')[0];
        let singlesend_name = '';
        switch (name) {
          case 'ObjectInstanceAPI':
            singlesend_name = 'Object Changed';
            break;
          case 'FieldInstanceAPI':
            singlesend_name = 'Field Changed';
            break;
          case 'RelationshipAPI':
            singlesend_name = 'Relationship Changed';
            break;
          case 'UserInstanceAPI':
            singlesend_name = 'User Changed';
            break;
          default:
            singlesend_name = name;
            break;
        }
        structuredLogs.push({
          event: 'log',
          timestamp: new Date(log.TimeStamp).getTime() / 1000,
          singlesend_name
        });
      });
    }

    const structuredCalendarEvents = await getCalendarEvents();
    const final = data.concat(
      structuredFileNotes,
      structuredActivities,
      structuredEmails,
      structuredLogs,
      structuredCalendarEvents
    );

    const sorted = final.sort((a, b) => b.timestamp - a.timestamp);
    setEvents(sorted);
    setOpen(true);
    setLoading(false);
  };

  const getEvents = async () => {
    const email = `${UserInstance.UserInstanceEmail}`.toLowerCase();
    const ref = db
      .collection('user')
      .doc(email)
      .collection('event')
      .orderBy('timestamp', 'desc')
      .limit(50);
    return await ref.get().then((snap: any) => {
      let data: Event[] = [];
      snap.forEach((doc: any) => {
        const { event, timestamp, singlesend_name } = doc.data();
        data.push({ event, timestamp, singlesend_name });
      });
      return data;
    });
  };

  const getCalendarEvents = async () => {
    const ownerQuery = db
      .collection('calendar')
      .where('extendedProps.calendarOwner', '==', user.Id)
      .orderBy('start', 'desc')
      .limit(50);

    const invitedQuery = db
      .collection('calendar')
      .where(
        'extendedProps.invitedUserInstanceIds',
        'array-contains',
        UserInstance.Id
      )
      .orderBy('start', 'desc')
      .limit(50);

    const [ownerSnapshot, invitedSnapshot] = await Promise.all([
      ownerQuery.get(),
      invitedQuery.get()
    ]);

    let ownerData: Event[] = [];
    let invitedData: Event[] = [];

    const processSnapshot = (
      snap: firebase.firestore.QuerySnapshot,
      dataArray: Event[]
    ) => {
      snap.forEach((doc: firebase.firestore.DocumentData) => {
        const { title, start, extendedProps } = doc.data();
        const date = new Date(start);
        const unixTimestamp = Math.floor(date.getTime() / 1000);

        if (extendedProps.invitedUserInstanceIds) {
          // Check for invitedUserInstanceIds
          dataArray.push({
            event: extendedProps.eventType,
            singlesend_name: title,
            timestamp: unixTimestamp,
            invitedUserInstanceIds: extendedProps.invitedUserInstanceIds
          });
        }
      });
    };

    processSnapshot(ownerSnapshot, ownerData);
    processSnapshot(invitedSnapshot, invitedData);

    const filteredData = ownerData
      .filter((ownerEvent) =>
        invitedData.some(
          (invitedEvent) =>
            invitedEvent.timestamp === ownerEvent.timestamp &&
            invitedEvent.invitedUserInstanceIds?.includes(UserInstance.Id)
        )
      )
      .filter((ownerEvent) =>
        ownerEvent.invitedUserInstanceIds?.includes(UserInstance.Id)
      );

    filteredData.sort((a, b) => b.timestamp - a.timestamp);
    return filteredData;
  };

  React.useEffect(() => {
    setOpen(false);
    getSystemEvents();
    return () => setDeals([]);
  }, [refreshID]);

  return (
    <CustomizedTimeline
      data={events}
      setOpen={setOpen}
      open={open}
      loading={loading}
      UserInstance={UserInstance}
      getSystemEvents={getSystemEvents}
    />
  );
};

export default Timeline;

const destructureField = (field) => {
  let type = '';
  let start = '';
  let end = '';
  let desc = '';
  let created = '';

  const search = ',';
  const keywords = field.split(search);
  keywords.forEach((el) => {
    const search2 = '=';
    let els = el.split(search2);
    if (els.length === 2) {
      switch (els[0]) {
        case 'type':
          type = els[1];
          break;
        case 'start':
          start = els[1];
          break;
        case 'end':
          end = els[1];
          break;
        case 'desc':
          desc = els[1];
          break;
        case 'created':
          created = els[1];
          break;
      }
    }
  });

  return { type, start, end, desc, created };
};
