import { Message } from '@microsoft/microsoft-graph-types';
import { IConversation } from 'components/MessageHub/interfaces';

export const sortConversations = (conversations: IConversation[]) => {
  return Object.values(conversations).sort((a, b) => {
    const lastMessageA =
      a.replies.length > 0 ? a.replies[a.replies.length - 1] : a.parentMessage;
    const lastMessageB =
      b.replies.length > 0 ? b.replies[b.replies.length - 1] : b.parentMessage;
    return (
      new Date(lastMessageB.receivedDateTime || 0).getTime() -
      new Date(lastMessageA.receivedDateTime || 0).getTime()
    );
  });
};
