/**single object is fairly straight forward, as it collect key value pair data */
import { Grid, Typography, GridSize, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InlineTextField from 'components/Fields/InlineTextField';
import { makeStyles } from '@material-ui/core/styles';
import FieldStatusIcon from './components/FieldStatusIcon';
import { FieldDefinitionTitle } from '../components/FieldDefinitionTitle';
import {
  FieldDefinition,
  FieldDefinitionDict,
  ObjectDefinition,
  CompleteProcessStepDefinition,
  CompleteObjectInstance,
  UserInstance,
  ProcessInstance,
  FieldInstance,
  ObjectInstance
} from 'types/interfaces';
import { useTypedSelector } from 'redux/reducers';
import { getLayoutSpacing } from '../helper';
import Time from 'common/Time';
import { Alert, AlertTitle } from '@material-ui/lab';
import { HideShowFields } from '../../../../functions/hideshowFields';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { useProcess } from 'hooks/useProcess';
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { objectType } from '../Forms/FormContent';
import ObjectSaving, {
  ICustomizeComment,
  IProposalComments
} from './ObjectSaving';
import { globalIds } from 'helpers/globalIdConfig';
import firebase from 'firebase';
import { theme } from 'theme';
import DetailsDialog from '../helper/DetailsDialog';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { StepperContext } from 'components/Stepper/context';
import isPreviewMode from '../helper/previewMode';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  container: {
    width: '100%',
    borderRadius: theme.shape.borderRadius
  },
  item: {
    width: '100%',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1)
    // '&:hover': { background: theme.palette.background.default }
  },
  progress: { width: '100%', marginBottom: 25 }
}));

interface IProps {
  ObjectDefinition: ObjectDefinition;
  FieldDefinitionList: FieldDefinition[];
  FieldDefinitionDict: FieldDefinitionDict;
  calculation: {};
  stepdefdict: CompleteProcessStepDefinition;
  redflagObjects?: any;
  setObjectData: Dispatch<SetStateAction<unknown[]>>;
  setCurrentObjectType: Dispatch<SetStateAction<objectType>>;
  currentObjectType: objectType;
}

const Component = (props: IProps) => {
  const classes = useStyles();
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const { deal } = useTypedSelector((s) => s.dealSummary);
  const {
    currentDeal,
    user: UserInstance,
    currentStep,
    landingpage,
    currentProcess
  } = useProcess();
  const { CompleteObjectInstanceDict, ProcessInstance } = currentDeal;

  const [reviewFields, setReviewFields] = useState<{ [key: string]: boolean }>(
    {}
  );

  let CompleteObjectInstance = {} as CompleteObjectInstance;
  if (Object.keys(CompleteObjectInstanceDict).length > 0) {
    const foundObjectInstance: CompleteObjectInstance | undefined =
      Object.values(CompleteObjectInstanceDict).find(
        (CompleteObjectInstance: CompleteObjectInstance) =>
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
          props.ObjectDefinition.Id
      );
    if (foundObjectInstance) CompleteObjectInstance = foundObjectInstance;
  }

  let md: GridSize = getLayoutSpacing(props.ObjectDefinition.ObjectDescription);
  let fieldsToShow: FieldDefinition[] = HideShowFields({
    FieldDefinitionList: props.FieldDefinitionList,
    CompleteObjectInstance
  }).sort(
    (a: FieldDefinition, b: FieldDefinition) => a.ItemOrder - b.ItemOrder
  );

  // For Future we can pass in the ID of the ObjectDefinitionId we wanna use.
  const handleObjectSaving = async ({
    FieldDefinition,
    ObjectDefinition,
    comment
  }: {
    FieldDefinition: FieldDefinition;
    ObjectDefinition: ObjectDefinition;
    comment: string;
  }) => {
    // Check If User Is Deal Owner If Not Then Return.
    if (UserInstance.Id !== currentDeal.ProcessInstance.UserInstanceId) return;
    if (ObjectDefinition && comment !== '') {
      const ProposalComments = globalIds.objectDefinitionIds.proposalComments;
      if (ProposalComments.includes(ObjectDefinition.Id)) {
        const parentName: string = FieldDefinition.Title;
        const parentSection: IProposalComments = {
          comment,
          fieldDefinitionId: FieldDefinition.Id
        };

        const reduxCustomer = Object.keys(
          currentStep.UserInstanceDictForCurrentStep
        );

        const customerId = deal?.customers?.selected
          ? deal?.customers?.selected[0]?.Id
          : reduxCustomer[0] ?? undefined;

        if (!customerId) return;
        await ObjectSaving({
          parentName,
          parentSection,
          user: UserInstance,
          dealTitle: currentProcess.ProcessDefinition.Title,
          type: FieldDefinition.FieldType,
          customerId: customerId.toString()
        });
      }
    }
  };

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <>
      {Object.values(CompleteObjectInstance).length === 0 &&
        !reviewFields[props.ObjectDefinition.Id] &&
        dealCondition && (
          <Alert severity="warning">
            <AlertTitle>
              No {props.ObjectDefinition.Title} Data Found
            </AlertTitle>
            <Typography variant="h6" color="textSecondary">
              Please note that this deal is $
              {isDealTransferred ? 'transferred' : 'closed'}. You cannot add{' '}
              {props.ObjectDefinition.Title}.
            </Typography>
            <Typography variant="h6" color="textSecondary">
              To review the Fields of this {props.ObjectDefinition.Title},
              please click the button below.
            </Typography>
            <div style={{ paddingTop: theme.spacing(1) }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  setReviewFields((prevState) => ({
                    ...prevState,
                    [props.ObjectDefinition.Id]: true
                  }))
                }>
                Review Empty {props.ObjectDefinition.Title} Fields
              </Button>
            </div>
          </Alert>
        )}

      {(Object.values(CompleteObjectInstance).length > 0 ||
        !dealCondition ||
        reviewFields[props.ObjectDefinition.Id]) && (
        <div className={classes.root}>
          <Grid container className={classes.container} spacing={1}>
            <MappedFields
              handleObjectSaving={handleObjectSaving}
              fieldsToShow={fieldsToShow}
              CompleteObjectInstance={CompleteObjectInstance}
              md={md}
              props={props}
              UserInstance={UserInstance}
              ProcessInstance={ProcessInstance}
            />
          </Grid>
        </div>
      )}
    </>
  );
};

export default Component;

interface IMappedFields {
  fieldsToShow: FieldDefinition[];
  handleObjectSaving: ({
    FieldDefinition,
    ObjectDefinition,
    ObjectInstance,
    comment
  }: {
    FieldDefinition: FieldDefinition;
    ObjectDefinition: ObjectDefinition;
    ObjectInstance: ObjectInstance | undefined;
    comment: string;
  }) => Promise<void>;
  CompleteObjectInstance: CompleteObjectInstance;
  md: GridSize;
  props: IProps;
  UserInstance: UserInstance;
  ProcessInstance: ProcessInstance;
}

const MappedFields = ({
  fieldsToShow,
  CompleteObjectInstance,
  md,
  handleObjectSaving,
  props,
  UserInstance,
  ProcessInstance
}: IMappedFields) => {
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const classes = useStyles();
  const { value } = useTypedSelector((s) => s.formReducer);
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const { landingpage, currentProcess, currentStep } = useProcess();
  const systemAccess = useTypedSelector((s) => s.user.user.SystemAccess);
  const isSubSystemUser = systemAccess <= 4;
  const ProcessDefinitionId = currentProcess.ProcessDefinition.Id;

  /**set local single title value before updating the objectData state within parent component */
  const localSingleObjectData: unknown[] = [];
  const [firestoreFieldInstances, setFirestoreFieldInstances] = useState<
    ICustomizeComment[] | ICustomizeComment
  >([]);
  const [openViewer, setOpenViewer] = useState<{
    open: boolean;
    FieldDefinition: FieldDefinition;
  }>({
    open: false,
    FieldDefinition: {} as FieldDefinition
  });

  useEffect(() => {
    const fetchFieldInstances = async () => {
      const userDocRef = firebase
        .firestore()
        .collection('userAccount')
        .doc(UserInstance.Id.toString());

      const reduxCustomer = Object.keys(
        currentStep.UserInstanceDictForCurrentStep
      );

      const customerId = deal?.customers?.selected
        ? deal?.customers?.selected[0]?.Id
        : reduxCustomer[0] ?? undefined;

      if (!customerId) return;
      const uniqueId = `${customerId}-${currentProcess.ProcessDefinition.Title}`;
      const doc = await userDocRef.get();
      const data = doc.data();
      const fetchedFieldInstances = data
        ? data['customize_Comments']?.[uniqueId]
        : undefined;

      setFirestoreFieldInstances(fetchedFieldInstances);
    };

    if (globalIds.objectDefinitionIds.proposalComments.includes(value)) {
      fetchFieldInstances();
    }
  }, [CompleteObjectInstance]);

  const fieldMap = () => {
    return fieldsToShow.map((FieldDefinition: FieldDefinition, idx: number) => {
      const readOnlyField = FieldDefinition.FieldType.includes('Information');
      let Required = FieldDefinition.FieldRequired;
      let FieldInstance: FieldInstance = {} as FieldInstance;

      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
      if (FieldInstanceList) {
        const newFieldInstance = FieldInstanceList.find(
          (FieldInstance: FieldInstance) =>
            FieldInstance.FieldDefinitionId === FieldDefinition.Id
        );

        if (newFieldInstance) FieldInstance = newFieldInstance;
      }

      // Hide unpublished fields from landingpage users
      if (
        isSubSystemUser &&
        FieldInstance &&
        FieldInstance?.IsPublished &&
        !FieldInstance?.IsPublished
      ) {
        return <div />;
      }

      const SignatureFieldDefinition: FieldDefinition | any = Object.values(
        fieldsToShow
      ).find((fields: FieldDefinition | any) => {
        if (fields?.FieldType === 'Signature') {
          return fields.Id;
        }
      });

      // This is a very specific edge case so that Property Finance deal does not include this fields
      if (
        ProcessDefinitionId === 494 &&
        [23277, 23113, 23278, 23114].includes(FieldDefinition.Id)
      ) {
        return;
      }

      if (
        landingpage &&
        globalIds.borrower.companyDetails.includes(FieldDefinition.Id)
      ) {
        return;
      }

      const isReadOnly: boolean = ReadOnlyRedflag({
        ObjectDefinition: props.ObjectDefinition,
        FieldDefinition
      });

      if (readOnlyField) {
        const hexColorMatch = FieldDefinition.FieldDescription.match(
          /hexColor-\[([^[\]]*)\]/
        );

        const borderColorMatch =
          FieldDefinition.FieldDescription.match(/border-\[([^[\]]*)\]/);

        const hexColor = hexColorMatch ? hexColorMatch[1] : '';
        const borderColor = borderColorMatch ? borderColorMatch[1] : '';

        const style = {
          backgroundColor: hexColor,
          ...(borderColor && { border: `2px solid ${borderColor}` })
        };

        const restrictedIds = [23278, 23275];

        return isSubSystemUser && restrictedIds.includes(FieldDefinition.Id) ? (
          <div />
        ) : (
          <Grid item key={FieldDefinition.Id} xs={12}>
            <Alert severity="info" style={style} elevation={1}>
              <Typography style={{ fontWeight: 'bold' }}>
                {FieldDefinition.Title}
              </Typography>
            </Alert>
          </Grid>
        );
      }

      if (FieldInstance) {
        // update local single object data if FieldInstance is true
        localSingleObjectData.push({
          title: FieldDefinition.Title,
          value:
            FieldInstance && FieldInstance?.FieldValue?.length < 500000
              ? FieldInstance.FieldValue
              : '(Data too long to save onto file)'
        });
      }

      const firestoreInstance = findFirestoreInstance(
        firestoreFieldInstances,
        FieldDefinition
      );
      md = landingpage ? 12 : md;

      const fieldProps: IFieldProps = {
        FieldDefinition,
        md,
        FieldInstance,
        Required,
        firestoreComment: firestoreInstance,
        isReadOnly,
        SignatureFieldDefinition,
        props,
        handleObjectSaving,
        setOpenViewer,
        openViewer
      };

      return <RenderFields key={idx} {...fieldProps} />;
    });
  };

  interface IFieldProps {
    FieldDefinition: FieldDefinition;
    md: GridSize;
    FieldInstance: FieldInstance;
    firestoreComment: ICustomizeComment | undefined;
    Required: boolean;
    isReadOnly: boolean;
    handleObjectSaving: ({
      FieldDefinition,
      ObjectDefinition,
      ObjectInstance,
      comment
    }: {
      FieldDefinition: FieldDefinition;
      ObjectDefinition: ObjectDefinition;
      ObjectInstance: ObjectInstance | undefined;
      comment: string;
    }) => Promise<void>;
    SignatureFieldDefinition: FieldDefinition;
    props: IProps;
    setOpenViewer: Dispatch<
      SetStateAction<{ open: boolean; FieldDefinition: FieldDefinition }>
    >;
    openViewer: { open: boolean; FieldDefinition: FieldDefinition };
  }

  const RenderFields = (fieldProps: IFieldProps) => {
    const {
      FieldDefinition,
      md,
      FieldInstance,
      Required,
      isReadOnly,
      SignatureFieldDefinition,
      props,
      firestoreComment
    } = fieldProps;

    const restrictedIds = [23110, 23116, 23109, 23278, 23114];

    //* isPreview will only work if the magic link has been injected with preview.
    const isPreview = isPreviewMode(window.location.href, landingpage);
    const dealCondition = isDealClosed || isDealTransferred;
    return isSubSystemUser && restrictedIds.includes(FieldDefinition.Id) ? (
      <div />
    ) : (
      <Grid
        item
        key={FieldDefinition.Id}
        xs={12}
        md={md}
        alignItems="stretch"
        style={{ display: 'flex' }}>
        <Paper variant={'outlined'} className={classes.item}>
          <div data-cy="brief-input" style={{ display: 'flex' }}>
            <FieldStatusIcon
              FieldInstance={FieldInstance}
              FieldDefinition={FieldDefinition}
              Required={Required}
            />
            <div style={{ flexGrow: 1 }}>
              <FieldDefinitionTitle
                title={FieldDefinition.Title}
                height={'h6'}
              />
            </div>
          </div>

          <div
            style={{
              padding: 1,
              width: '100%'
            }}>
            {CompleteObjectInstance && (
              <InlineTextField
                readOnly={isReadOnly || dealCondition || isPreview}
                handleObjectSaving={handleObjectSaving}
                type={FieldDefinition.FieldType}
                FieldDefinition={FieldDefinition}
                firestoreComment={firestoreComment}
                FieldInstance={FieldInstance}
                ObjectDefinition={props.ObjectDefinition}
                ObjectInstance={
                  CompleteObjectInstance &&
                  CompleteObjectInstance.ObjectInstance
                }
                UserInstance={UserInstance}
                fields={fieldsToShow}
                value={FieldInstance && FieldInstance.FieldValue}
                ProcessInstance={ProcessInstance}
                ProcessStepSelectionType={
                  props.stepdefdict.ProcessStepDefinition
                    .ProcessStepSelectionType
                }
                parentsIsFormComponent
              />
            )}
          </div>
          {FieldDefinition.FieldDescription === 'Viewer' &&
            FieldInstance &&
            FieldInstance?.FieldValue !== '' && (
              <div style={{ paddingTop: theme.spacing(1) }}>
                <Button
                  variant="outlined"
                  onClick={() =>
                    setOpenViewer({
                      ...openViewer,
                      open: true,
                      FieldDefinition: FieldDefinition
                    })
                  }>
                  View Details
                </Button>
              </div>
            )}
          {SignatureFieldDefinition &&
            SignatureFieldDefinition?.Id ===
              FieldInstance?.FieldDefinitionId && (
              <Typography
                align="right"
                style={{ fontSize: '12px', color: 'gray' }}>
                <Time time={FieldInstance?.LastModified} type="date" />
              </Typography>
            )}
        </Paper>
      </Grid>
    );
  };

  // update objectData state with the singleObject data which will be used for the pdf file if the user wanted to download file
  useEffect(() => {
    props.setCurrentObjectType(objectType.singleObject);

    props.setObjectData([...localSingleObjectData]);

    // unmounted component must be reset so that the next object which could be unidentified, does not use the same object type data
    return () => {
      props.setCurrentObjectType(objectType.nullObject);
      props.setObjectData([]);
    };
  }, [props.ObjectDefinition.Title, props.currentObjectType]);

  const renderedFields = fieldMap();
  const validElements = renderedFields.filter(
    (element) => element !== undefined
  );

  return (
    <>
      {validElements}
      <DetailsDialog
        setOpen={setOpenViewer}
        open={openViewer}
        CompleteObjectInstance={CompleteObjectInstance}
      />
    </>
  );
}; // END MapperFields

{
  /* {!IsNotSystemUser && (
              <div>
                <Tooltip title="Hide/Show">
                  <Checkbox
                    icon={<BiHide />}
                    checkedIcon={<BiShow />}
                    checked={FieldInstance ? FieldInstance.IsPublished : false}
                    onChange={() => {
                      if (FieldInstance) {
                        publishField({
                          field: FieldInstance,
                          props: { ProcessInstance },
                          token
                        });
                      }
                    }}
                  />
                </Tooltip>
              </div>
            )} */
}

const findFirestoreInstance = (
  firestoreFieldInstances: ICustomizeComment[] | ICustomizeComment,
  FieldDefinition: FieldDefinition
): ICustomizeComment | undefined => {
  if (!firestoreFieldInstances) return undefined;
  if (Array.isArray(firestoreFieldInstances)) {
    return firestoreFieldInstances.find(
      (instance: ICustomizeComment) =>
        instance.parentSection.fieldDefinitionId === FieldDefinition.Id
    );
  } else {
    return firestoreFieldInstances.parentSection.fieldDefinitionId ===
      FieldDefinition.Id
      ? firestoreFieldInstances
      : undefined;
  }
};
