import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { IAddressApiFullAddress, IAdminPanelAddress } from 'types/address';
import { useGoogleMapsAPI } from 'hooks/useGoogleMapsAPI';

const mapContainerStyle = {
  width: 'calc(100%)',
  height: '400px',
  borderRadius: '10px'
};

const GoogleMapContainer = ({
  position,
  redflagLocation,
  setWarning
}: {
  position?: IAddressApiFullAddress;
  redflagLocation?: IAdminPanelAddress;
  setWarning?: (warning: boolean) => void;
}) => {
  const getCenters = () => {
    const redflagLocationsArray = Array.isArray(redflagLocation)
      ? redflagLocation
      : [redflagLocation];

    return redflagLocationsArray.map((redflagLocation) => {
      const lat = redflagLocation?.location?.lat || position?.location?.lat;
      const lng = redflagLocation?.location?.lon || position?.location?.lng;
      if (isNaN(Number(lat))) setWarning && setWarning(true);
      return { lat: Number(lat), lng: Number(lng) };
    });
  };

  const centers = getCenters();
  const WrappedGoogleMap = React.memo(() => (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={15}
      center={centers[0]}
      mapTypeId={'satellite'}>
      {centers.map((position, i) => (
        <Marker key={i} position={position} />
      ))}
    </GoogleMap>
  ));

  const GoogleMapWithLoader = useGoogleMapsAPI(WrappedGoogleMap);
  return <GoogleMapWithLoader />;
};

export default GoogleMapContainer;
