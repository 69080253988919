import {
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { CustomDialog } from 'common/Dialog';
import { IFormField, IQuery } from '../interfaces';
import { getValue } from '../helper';

interface IProps {
  isOpen: boolean;
  setIsOpen: (isOpen: false) => void;
  handleSearch: (name: string, value: string) => void;
  formFields: IFormField[];
  entity: IQuery['company'] | IQuery['director'];
  alertText: string;
}
const AdvancedFilterDialog = ({
  isOpen,
  setIsOpen,
  handleSearch,
  formFields,
  entity,
  alertText
}: IProps) => {
  const theme = useTheme();

  return (
    <CustomDialog
      open={isOpen}
      handleClose={() => setIsOpen(false)}
      alert={{
        title: `TODO COME UP WITH NAME`,
        description: alertText,
        type: 'info'
      }}>
      <Alert
        style={{ borderRadius: 0, justifyContent: 'center' }}
        severity="info"
        variant="filled">
        <Typography style={{ fontWeight: 'bold', color: 'white' }}>
          {alertText}
        </Typography>
        <IconButton
          style={{ position: 'absolute', right: 3, top: 3, color: 'white' }}
          onClick={() => setIsOpen(false)}>
          <Close />
        </IconButton>
      </Alert>

      <Grid container spacing={2} style={{ padding: theme.spacing(2) }}>
        {formFields.map((field: IFormField) => (
          <Grid item xs={12} sm={3} key={field.name}>
            {field.type === 'text' ? (
              <TextField
                fullWidth
                variant="outlined"
                value={getValue(entity[field.name])}
                onChange={(e) => handleSearch(field.name, e.target.value)}
                placeholder={field.label}
                label={field.label}
              />
            ) : field.type === 'select' ? (
              <Select
                fullWidth
                value={getValue(entity[field.name])}
                onChange={(e) =>
                  handleSearch(field.name, e.target.value as string)
                }
                displayEmpty
                variant="outlined">
                <MenuItem value="">
                  <em>{field.label}</em>
                </MenuItem>
                {field?.options?.map((option, index) => (
                  <MenuItem key={index} value={option.value as string}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </CustomDialog>
  );
};

export default AdvancedFilterDialog;
