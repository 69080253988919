import { useEffect, useState } from 'react';
import { useDealList } from 'hooks/useDealList';
import { LinearProgress } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import ExtremeCautionTable from './ExtremeCautionTable';

const ExtremeCautionList = () => {
  const { doNotDealList, getDoNotDealList } = useDealList('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (doNotDealList.length === 0) {
      const fetchDndList = async () => {
        await getDoNotDealList();
        setLoading(false);
      };
      fetchDndList();
    } else {
      setLoading(false);
    }
  }, [doNotDealList, getDoNotDealList]);

  const handleRefresh = () => {
    setLoading(true);
    getDoNotDealList().then(() => setLoading(false));
  };

  return (
    <DashboardLayout title="Extreme Caution List">
      {loading ? (
        <LinearProgress />
      ) : (
        <ExtremeCautionTable
          data={doNotDealList}
          title="Extreme Caution List"
          onRefresh={handleRefresh}
        />
      )}
    </DashboardLayout>
  );
};

export default ExtremeCautionList;
