import moment from 'moment';
import {
  CompleteObjectInstance,
  CompleteObjectInstanceDict,
  CompleteProcessInstance,
  FieldInstance,
  UserInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { isEmptyOrTrue, isStructuredField } from '..';
import { patternToMomentFormat } from '../../helper';
import {
  ELenderAPIType,
  ILabelValueOption,
  IMappedLenderIds,
  IStructuredField,
  LenderFieldDefinition,
  TLenderAPIInterfaces,
  TMappedIds,
  TTypeSafeRecord
} from '../../interface';

interface IProposalCreationParams {
  selectedCONST: TLenderAPIInterfaces;
  mappedIds: TMappedIds;
  flattenedFieldInstances: IExtraFallenCompleteObjectInstance[];
  currentDeal: CompleteProcessInstance;
  user: UserInstance;
  entityType: string;
}

interface IExtraFallenCompleteObjectInstance extends FieldInstance {
  isSelected: boolean | undefined;
}

/**
 * @typedef {Object} IProposalCreationParams
 * @property {TLenderAPIInterfaces} selectedCONST - The selected lender API interface
 * @property {TMappedIds} mappedIds - Mapped IDs for the lender API
 * @property {IExtraFallenCompleteObjectInstance[]} flattenedFieldInstances - Flattened field instances
 * @property {CompleteProcessInstance} currentDeal - The current deal instance
 * @property {UserInstance} user - The user instance
 * @property {string} entityType - The type of entity
 */

/**
 * Creates an internal structure for the lender API
 * @param {ICreateInternalStructureParams} params - The parameters for creating the internal structure
 * @returns {TLenderAPIInterfaces} The processed lender API interface
 */
const proposalCreation = ({
  selectedCONST,
  mappedIds,
  flattenedFieldInstances,
  currentDeal,
  user,
  entityType
}: IProposalCreationParams): TLenderAPIInterfaces => {
  // Define keyword actions for replacements
  const keywordActions = [
    { title: 'ProcessOwnerEmail', action: user.UserInstanceEmail },
    {
      title: 'ProcessInstanceId',
      action: currentDeal.ProcessInstance.Id.toString()
    },
    { title: 'BrokerName', action: user.Title },
    { title: 'BrokerNickname', action: user.NickName },
    { title: 'BrokerEmail', action: user.UserInstanceEmail },
    { title: 'currentDate', action: moment().format('YYYY-MM-DD') },
    {
      title: 'randomNumberX',
      action: `X${Array.from({ length: 5 }, () =>
        Math.floor(Math.random() * 10)
      ).join('')}`
    },
    {
      title: 'currentDateTime',
      action: moment().format('YYYY-MM-DDTHH:mm:ss')
    }
  ];

  /**
   *? Helper function to get the field definition ID and forced value for EntityChange type fields.
   */
  const getFieldDefinitionId = (
    mappedIdsObj: Partial<IMappedLenderIds>
  ): { fieldDefinitionId: number; forcedValue?: string | number | boolean } => {
    const fieldDefinition = mappedIdsObj?.FieldDefinition;
    if (
      fieldDefinition &&
      fieldDefinition.type?.includes(ELenderAPIType.EntityChange)
    ) {
      const matchingEntity = fieldDefinition.newIdList?.find(
        (item) => item.entityType === entityType
      );
      if (matchingEntity) {
        return {
          fieldDefinitionId: matchingEntity.FieldDefinitionId,
          forcedValue: matchingEntity.forcedValue
        };
      }
    }
    return { fieldDefinitionId: mappedIdsObj?.FieldDefinitionId ?? 0 };
  };

  /**
   *? Handles splitting a full name into first and last names based on spaces.
   */
  const handleConcatenatedBySpace = (
    fullName: string,
    fieldName: string
  ): string => {
    const nameParts = fullName.trim().split(/\s+/);
    if (nameParts.length > 1) {
      const lowercaseFieldName = fieldName.toLowerCase();
      if (
        ['firstname', 'first_name', 'nameFirst'].includes(lowercaseFieldName)
      ) {
        return nameParts[0];
      } else if (
        ['lastname', 'last_name', 'surname', 'nameLast'].includes(
          lowercaseFieldName
        )
      ) {
        return nameParts.slice(1).join(' ');
      }
    }
    return fullName;
  };

  const getDefaultValueByType = (
    key: string,
    selectedCONST: TLenderAPIInterfaces
  ): string | number | boolean => {
    const flattenObject = (
      obj: any,
      prefix = '',
      res = {}
    ): Record<string, any> => {
      for (let k in obj) {
        if (obj.hasOwnProperty(k)) {
          const newPrefix = prefix ? `${prefix}.${k}` : k;
          const value = obj[k];
          if (
            typeof value === 'object' &&
            value !== null &&
            !Array.isArray(value)
          ) {
            flattenObject(value, newPrefix, res);
          } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
              const arrayPrefix = `${newPrefix}[${index}]`;
              if (typeof item === 'object' && item !== null) {
                flattenObject(item, arrayPrefix, res);
              } else {
                res[arrayPrefix] = item;
              }
            });
          } else {
            res[newPrefix] = value;
          }
        }
      }
      return res;
    };

    const flattenedSelectedCONST = flattenObject(selectedCONST);
    const matchingKey = Object.keys(flattenedSelectedCONST).find((k) =>
      k.includes(key)
    );

    const defaultValue = matchingKey
      ? flattenedSelectedCONST[matchingKey]
      : undefined;

    if (defaultValue === undefined) return '';
    switch (typeof defaultValue) {
      case 'boolean':
        return false;
      case 'number':
        return 0;
      case 'string':
        return '';
      default:
        return '';
    }
  };

  const normalizeValue = (
    value: any,
    key: string,
    selectedCONST: TLenderAPIInterfaces
  ): string | number | boolean => {
    let defaultType = typeof getDefaultValueByType(key, selectedCONST);
    if (defaultType === 'undefined') {
      if (typeof value === 'boolean') {
        defaultType = 'boolean';
      } else if (!isNaN(Number(value))) {
        defaultType = 'number';
      } else {
        defaultType = 'string';
      }
    }

    switch (defaultType) {
      case 'boolean':
        return value === true || value === 'true' || value === 1;
      case 'number':
        return !isNaN(Number(value)) ? Number(value) : 0;
      case 'string':
        return String(value);
      default:
        return value;
    }
  };

  /**
   * Processes an individual field, ensuring an IStructuredField is always returned.
   */
  const processField = (
    fieldValue: string | number | boolean,
    mappedIdsObj: IMappedLenderIds,
    currentKey: string,
    parentValues: Record<string, IStructuredField>
  ): IStructuredField | IStructuredField[] => {
    //* 1. Get field definition ID and forced value
    //* 2. Find matching fields from flattenedFieldInstances
    //* 3. Group matching fields by ObjectInstanceId
    //* 4. Process each group of fields
    //* 5. Handle special types (ConcatenatedBySpace, CascadingDropdown, EntityDependent)
    //* 6. Return processed field(s)

    const { fieldDefinitionId, forcedValue } =
      getFieldDefinitionId(mappedIdsObj);

    // Find matching fields from flattenedFieldInstances
    let matchingFields = flattenedFieldInstances.filter(
      (field) => field.FieldDefinitionId === fieldDefinitionId
    );

    // If there are multiple matching fields, check for 'isSelected' field
    const selectedFields = matchingFields.filter((field) => field.isSelected);

    if (selectedFields.length > 0) {
      // Use only fields where 'isSelected' is true
      matchingFields = selectedFields;
    }

    // Group matching fields by ObjectInstanceId
    let fieldsByObjectInstanceId: Record<
      number,
      IExtraFallenCompleteObjectInstance[]
    > = {};

    if (matchingFields.length > 0) {
      fieldsByObjectInstanceId = groupBy(
        matchingFields,
        (field) => field.ObjectInstanceId
      );
    } else {
      const defaultFieldInstance: IExtraFallenCompleteObjectInstance = {
        FieldDefinitionId: fieldDefinitionId,
        FieldValue: '',
        ObjectInstanceId: 0,
        isSelected: false,
        Id: 0,
        Title: '',
        ProcessInstanceId: 0,
        ObjectDefinitionId: 0,
        UserInstanceId: 0,
        UserDefinitionId: 0
      };
      fieldsByObjectInstanceId = {
        0: [defaultFieldInstance]
      };
    }

    // Process each group of fields
    const processedFieldsArray = Object.values(fieldsByObjectInstanceId).map(
      (fieldsGroup) => {
        // There might be multiple fields in the group, but they all share the same ObjectInstanceId
        const fieldInstance = fieldsGroup[0]; // Representative field instance

        // Determine the value
        let value: string | number | boolean = normalizeValue(
          forcedValue !== undefined
            ? forcedValue
            : mappedIdsObj?.forcedValue !== undefined
            ? mappedIdsObj.forcedValue
            : fieldInstance?.FieldValue ??
              fieldValue ??
              getDefaultValueByType(currentKey, selectedCONST),
          currentKey,
          selectedCONST
        );

        // Replace value if it matches a keyword action
        const keywordAction = keywordActions.find(
          (action) => action.title === value
        );

        if (keywordAction) {
          value = keywordAction.action;
        }

        // Get field definition and types
        let fieldDefinition = mappedIdsObj?.FieldDefinition;
        let isHidden = false;

        // Initialize types array
        let types: ELenderAPIType[] = [];
        let error: string | null = null;

        if (fieldDefinition) {
          // Extract types from fieldDefinition
          if (Array.isArray(fieldDefinition.type)) {
            types = fieldDefinition.type;
          } else if (fieldDefinition.type) {
            types = [fieldDefinition.type];
          }

          // Handle additional types for matching entity
          const matchingEntity = fieldDefinition.newIdList?.find(
            (item) => item.entityType === entityType
          );
          if (matchingEntity?.additionalTypes) {
            types.push(...matchingEntity.additionalTypes);
          }

          // Handle ConcatenatedBySpace type
          if (types.includes(ELenderAPIType.ConcatenatedBySpace)) {
            value = handleConcatenatedBySpace(String(value), currentKey);
          }

          // Handle special types (e.g., CascadingDropdown, EntityDependent)
          if (types.includes(ELenderAPIType.CascadingDropdown)) {
            fieldDefinition = handleCascadingDropdown(
              fieldDefinition,
              parentValues
            );
          }

          if (types.includes(ELenderAPIType.EntityDependent)) {
            isHidden = !checkEntityDependentVisibility(
              fieldDefinition.options,
              entityType
            );

            const defaultValues: Record<string, any> = {
              string: '',
              number: 0,
              boolean: false
            };

            value = defaultValues[typeof value] ?? value;
          }

          if (
            types.includes(ELenderAPIType.Conversion) &&
            fieldDefinition.options
          ) {
            // Type guard to check if we're working with ILabelValueOption array
            const isLabelValueOptions = (
              options: string[] | ILabelValueOption[]
            ): options is ILabelValueOption[] => {
              return (
                options.length > 0 &&
                typeof options[0] === 'object' &&
                options[0] !== null &&
                'label' in options[0]
              );
            };

            if (isLabelValueOptions(fieldDefinition.options)) {
              // Find the option that includes our entity type label (more flexible matching)
              const matchingOption = fieldDefinition.options.find(
                (option) =>
                  option.label
                    .toLowerCase()
                    .includes(entityType.toLowerCase()) ||
                  entityType.toLowerCase().includes(option.label.toLowerCase())
              );

              // If we found a matching option and its value is not null, use its value
              if (matchingOption && matchingOption.value !== null) {
                value = matchingOption.value;
              }
            }
          }

          if (
            types.includes(ELenderAPIType.Date) &&
            fieldDefinition.requirement
          ) {
            const { pattern, message } = fieldDefinition.requirement;
            if (pattern && typeof value === 'string' && value !== '') {
              try {
                const datePattern = patternToMomentFormat(pattern);
                let unescapedPattern = pattern.replace(/\\\\/g, '\\');

                unescapedPattern = unescapedPattern
                  .replace(/^\^/, '')
                  .replace(/\$$/, '');

                const dateRegex = new RegExp(unescapedPattern);
                const dateMatch = value.match(dateRegex);
                if (dateMatch) {
                  value = dateMatch[0];
                } else {
                  throw new Error('Invalid date format');
                }

                // Validate the extracted date
                const parsedDate = moment(value, datePattern, true);
                if (!parsedDate.isValid()) throw new Error('Invalid date');
              } catch (err) {
                error = message || 'Invalid date format';
              }
            }
          }
        }

        // Determine if the field is read-only
        const isReadonly =
          mappedIdsObj?.isReadonly !== undefined
            ? mappedIdsObj?.isReadonly
            : !isEmptyOrTrue(value)
            ? true
            : false;

        return {
          value,
          FieldDefinitionId: fieldDefinitionId,
          ObjectInstanceId: fieldInstance?.ObjectInstanceId ?? 0,
          FieldDefinition: fieldDefinition
            ? { ...fieldDefinition, type: types }
            : undefined,
          isRequired: mappedIdsObj?.required ?? false,
          isReadonly,
          isHidden,
          infoField: mappedIdsObj?.info,
          error
        };
      }
    );

    // Determine if the property should be an array based on the proposal structure
    const shouldReturnArray = processedFieldsArray.length > 1;
    if (shouldReturnArray) {
      return processedFieldsArray;
    } else {
      return processedFieldsArray[0];
    }
  };

  /**
   * Handles cascading dropdown fields by updating options based on parent values.
   */
  const handleCascadingDropdown = (
    fieldDefinition: LenderFieldDefinition,
    parentValues: Record<string, IStructuredField>
  ): LenderFieldDefinition => {
    const dependsOn = fieldDefinition.dependsOn;
    if (dependsOn) {
      const parentFieldValues = Array.isArray(dependsOn)
        ? dependsOn.map((dep) => parentValues[dep]?.value)
        : [parentValues[dependsOn]?.value];

      return {
        ...fieldDefinition,
        options: fieldDefinition.getOptions?.(...parentFieldValues) ?? []
      };
    }
    return fieldDefinition;
  };

  type ProcessStructureOutput<T> = T extends any[]
    ? any[]
    : T extends object
    ? { [K in keyof T]: any }
    : IStructuredField | IStructuredField[];

  interface IProcessContext {
    arrayKeys: string[];
    maxLength: number;
    isProcessingArray?: boolean;
    arrayFields?: Map<
      string,
      {
        fields: Map<string, IStructuredField[]>;
        maxLength: number;
      }
    >;
  }

  const expandParentObject = (
    obj: any,
    arrayFields: Map<string, IStructuredField[]>,
    maxLength: number
  ) => {
    const result: any = [];

    for (let i = 0; i < maxLength; i++) {
      const instance = { ...obj };

      for (const [fieldName, values] of arrayFields.entries()) {
        instance[fieldName] = values[i] || values[values.length - 1];
      }

      result.push(instance);
    }

    return result;
  };

  /**
   * Recursively processes a given structure, handling arrays, objects, and primitive values.
   * Depending on the type of the input, it processes arrays by mapping over elements,
   * objects by iterating over their keys, and primitive values through a specific processing function.
   *
   * @template T - The type of the structure to process.
   * @param {T} obj - The structure to process (can be an array, object, or primitive).
   * @param {TTypeSafeRecord<T> | undefined} mappedIdsObj - A mapping object that associates IDs with elements in the structure.
   * @param {string} currentKey - The current key path used for identifying the nested element.
   * @param {Record<string, IStructuredField>} parentValues - A record of parent values for context during processing.
   * @returns {ProcessStructureOutput<T>} - The processed structure with elements transformed as per defined logic.
   */

  const processStructure = <T>(
    obj: T,
    mappedIdsObj: TTypeSafeRecord<T> | undefined,
    currentKey: string,
    parentValues: Record<string, IStructuredField> = {},
    context: IProcessContext = { arrayKeys: [], maxLength: 0 }
  ): ProcessStructureOutput<T> => {
    // Helper function to check parent type
    const getParentType = (
      key: string,
      originalObj: any
    ): 'array' | 'object' | null => {
      if (!key.includes('.')) return null; // No parent

      const pathParts = key.split('.');
      pathParts.pop(); // Remove the current field
      let current = originalObj;

      // Traverse the path to find parent
      for (const part of pathParts) {
        if (!current) return null;
        // Handle array indices in the path
        if (part.includes('[') && part.includes(']')) {
          const arrayName = part.split('[')[0];
          current = current[arrayName];
          if (!Array.isArray(current)) return null;
        } else {
          current = current[part];
        }
      }

      return Array.isArray(current) ? 'array' : 'object';
    };

    if (Array.isArray(obj)) {
      // Array processing logic remains the same
      if (context.isProcessingArray) {
        const flattenedArray = obj.flatMap((item, index) =>
          processStructure(
            item,
            Array.isArray(mappedIdsObj) ? mappedIdsObj[index] : mappedIdsObj,
            `${currentKey}[${index}]`,
            parentValues,
            context
          )
        );
        return flattenedArray as ProcessStructureOutput<T>;
      }

      const processedArray = obj
        .map((item, index: number) =>
          processStructure(
            item,
            Array.isArray(mappedIdsObj) ? mappedIdsObj[index] : mappedIdsObj,
            `${currentKey}[${index}]`,
            parentValues,
            { ...context, isProcessingArray: true }
          )
        )
        .flat();

      return processedArray as ProcessStructureOutput<T>;
    } else if (typeof obj === 'object' && obj !== null) {
      const processedObj = {} as {
        [K in keyof T]: ProcessStructureOutput<T[K]>;
      };

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const mappedId = mappedIdsObj
            ? mappedIdsObj[key as keyof T]
            : undefined;

          const processedValue = processStructure(
            value,
            mappedId as any,
            currentKey ? `${currentKey}.${key}` : key,
            parentValues,
            context
          );

          processedObj[key as keyof T] = processedValue;
        }
      }

      const arrayFieldContext = context.arrayFields?.get(currentKey);
      if (arrayFieldContext) {
        return expandParentObject(
          processedObj,
          arrayFieldContext.fields,
          arrayFieldContext.maxLength
        ) as ProcessStructureOutput<T>;
      }

      return processedObj as ProcessStructureOutput<T>;
    } else {
      // Process primitive field
      const processedField = processField(
        obj as unknown as string | number | boolean,
        mappedIdsObj as IMappedLenderIds,
        currentKey,
        parentValues
      );

      // If the processed field is an array, check parent type
      if (Array.isArray(processedField)) {
        const parentType = getParentType(currentKey, selectedCONST);

        // If parent is an object, only take first value
        if (parentType === 'object') {
          return processedField[0] as ProcessStructureOutput<T>;
        }

        // For array parents or no parent, continue with array handling
        const pathParts = currentKey.split('.');
        const fieldName = pathParts.pop()!;
        const parentKey = pathParts.join('.');

        if (!context.arrayFields) {
          context.arrayFields = new Map();
        }

        if (!context.arrayFields.has(parentKey)) {
          context.arrayFields.set(parentKey, {
            fields: new Map(),
            maxLength: 0
          });
        }

        const parentContext = context.arrayFields.get(parentKey)!;
        parentContext.fields.set(fieldName, processedField);
        parentContext.maxLength = Math.max(
          parentContext.maxLength,
          processedField.length
        );

        return processedField[0] as ProcessStructureOutput<T>;
      }

      return processedField as ProcessStructureOutput<T>;
    }
  };

  const context: IProcessContext = {
    arrayKeys: [],
    maxLength: 0,
    arrayFields: new Map()
  };

  const result = processStructure(selectedCONST, mappedIds, '', {}, context);
  if (Array.isArray(result)) {
    return result[0];
  } else if (typeof result === 'object' && result !== null) {
    return result;
  } else {
    console.warn('Unexpected result type:', typeof result);
    return {} as TLenderAPIInterfaces;
  }
};

const createEmptyInternalStructure = ({
  selectedCONST,
  mappedIds
}: {
  selectedCONST: TLenderAPIInterfaces;
  mappedIds: TMappedIds | undefined;
}): TLenderAPIInterfaces => {
  const processStructure = <T>(
    obj: T,
    mappedIdsObj: TTypeSafeRecord<T> | undefined,
    currentKey: string
  ): T => {
    if (Array.isArray(obj)) {
      return obj.map((item, index) =>
        processStructure(
          item,
          Array.isArray(mappedIdsObj) ? mappedIdsObj[index] : mappedIdsObj,
          `${currentKey}[${index}]`
        )
      ) as unknown as T;
    } else if (typeof obj === 'object' && obj !== null) {
      const processedObj = {} as T;

      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const value = obj[key];
          const mappedId = mappedIdsObj
            ? mappedIdsObj[key as keyof T]
            : undefined;

          processedObj[key as keyof T] = processStructure(
            value,
            mappedId as any,
            key
          );
        }
      }

      return processedObj;
    } else {
      return createStructuredField(
        obj,
        mappedIdsObj as IMappedLenderIds
      ) as unknown as T;
    }
  };

  return processStructure(selectedCONST, mappedIds, '');
};

const createStructuredField = (
  value: any,
  mappedIdsObj: IMappedLenderIds
): IStructuredField => {
  return {
    value: value,
    FieldDefinitionId: mappedIdsObj?.FieldDefinitionId ?? 0,
    ObjectInstanceId: 0,
    FieldDefinition: mappedIdsObj?.FieldDefinition,
    isRequired: mappedIdsObj?.required ?? false,
    isReadonly: true,
    isHidden: false,
    infoField: mappedIdsObj?.info
  };
};

/**
 * Flattens a CompleteObjectInstanceDict into an array of IExtraFallenCompleteObjectInstance
 * @param {CompleteObjectInstanceDict} completeObjectInstanceDict - The complete object instance dictionary
 * @returns {IExtraFallenCompleteObjectInstance[]} Flattened array of field instances
 */
const flattenCompleteObjectInstanceDict = (
  completeObjectInstanceDict: CompleteObjectInstanceDict
): IExtraFallenCompleteObjectInstance[] => {
  const flattenedInstances: IExtraFallenCompleteObjectInstance[] = [];

  Object.values(completeObjectInstanceDict).forEach(
    (completeObjectInstance: CompleteObjectInstance) => {
      const fieldInstanceList = getFieldInstances(completeObjectInstance);
      fieldInstanceList.forEach((fieldInstance: FieldInstance) => {
        flattenedInstances.push({
          ...fieldInstance,
          isSelected: completeObjectInstance.ObjectInstance.Selected
        });
      });
    }
  );

  return flattenedInstances;
};

export {
  flattenCompleteObjectInstanceDict,
  proposalCreation as createInternalStructure,
  createEmptyInternalStructure
};

/**
 * Checks if a field should be visible based on entity type
 * @param {(string[]|ILabelValueOption[])} options - The options to check against
 * @param {string} entityType - The type of entity
 * @returns {boolean} Whether the field should be visible
 */
const checkEntityDependentVisibility = (
  options: string[] | ILabelValueOption[] | undefined,
  entityType: string
): boolean => {
  if (!options) return false;

  if (typeof options[0] === 'string') {
    return (options as string[]).includes(entityType);
  } else {
    return (options as ILabelValueOption[]).some(
      (option) => option.label === entityType
    );
  }
};

/**
 * Groups an array of items by a key
 * @template T
 * @template {keyof any} K
 * @param {T[]} array - The array to group
 * @param {function(T): K} keyGetter - Function to get the grouping key from an item
 * @returns {Record<K, T[]>} Grouped object
 */
const groupBy = <T, K extends keyof any>(
  array: T[],
  keyGetter: (item: T) => K
): Record<K, T[]> => {
  return array.reduce((result, currentItem) => {
    const key = keyGetter(currentItem);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(currentItem);
    return result;
  }, {} as Record<K, T[]>);
};
