import React from 'react';
import { format, parse, setGlobalDateMasks } from 'fecha';

setGlobalDateMasks({
  myMask: `Do MMM YYYY [at] HH:mm`,
  short: `Do MMM`
});

export const DateParse = ({ date }: { date: string }) => {
  const parsedDate = new Date(date);
  if (!isNaN(parsedDate.valueOf())) {
    const formattedDate = parsedDate.toLocaleString('en-GB', {
      timeZone: 'Europe/London',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });

    return <div>{formattedDate}</div>;
  }
  return <div />;
};
