import React from 'react';
import SearchVRM from './index';
import { CustomDialog } from 'common/Dialog';
import { Alert } from '@material-ui/lab';

import { Typography, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance
} from 'types/interfaces';

export const DialogVRM = ({
  FieldInstance,
  FieldDefinition,
  FieldDefinitionList,
  CompleteObjectInstance,
  refresh,
  isOpen,
  setIsOpen
}: {
  FieldInstance: FieldInstance;
  FieldDefinition: FieldDefinition;
  FieldDefinitionList: FieldDefinition[];
  CompleteObjectInstance: CompleteObjectInstance;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  refresh: () => void;
}) => {
  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const [message, setMessage] = React.useState<any>(
    'If You Wish To Save The Selected Vehicle Click Save'
  );
  const [alert, setAlert] = React.useState<any>('info');

  return (
    <CustomDialog
      open={isOpen}
      handleClose={handleDialogClose}
      alert={{
        title: `Vehicle Registration Mark Dialog`,
        description: message,
        type: 'info'
      }}>
      <SearchVRM
        FieldInstance={FieldInstance}
        FieldDefinition={FieldDefinition}
        FieldDefinitionList={FieldDefinitionList}
        CompleteObjectInstance={CompleteObjectInstance}
        setMessage={setMessage}
        refresh={refresh}
        setAlert={setAlert}
      />
    </CustomDialog>
  );
};
