import { UserInstance } from 'types/interfaces';

export const permissionFilter: (
  Description: string,
  user: UserInstance
) => boolean = (Description: string, user: UserInstance) => {
  // by default we see everything
  let isViewable = true;
  Description.split(',').find((item) => {
    if (item.includes('systemAccess')) {
      if (item) {
        const ruleSystemAccess = parseInt(item.split('#')[1]);
        // if the existing access level is not equal to or above the users system access level
        const canView = user.SystemAccess >= ruleSystemAccess;
        // then group should not be displayed
        if (canView) isViewable = true;
        else if (!canView) isViewable = false;
      }
    }
  });

  return isViewable;
};
