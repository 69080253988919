import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import InfoIcon from '@material-ui/icons/Info';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<any>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  children: any;
  title: string;
  withButton?: boolean;
  isOpen?: boolean;
  setIsOpen?: (value: React.SetStateAction<boolean>) => void;
}

export default function AlertDialogSlide({
  children,
  title,
  isOpen,
  setIsOpen,
  withButton
}: Props) {
  const [open, setOpen] = React.useState(!!isOpen);

  const handleClickOpen = () => {
    setIsOpen ? setIsOpen(true) : setOpen(true);
  };

  const handleClose = () => {
    setIsOpen ? setIsOpen(false) : setOpen(false);
  };

  useEffect(() => {
    setOpen(!!isOpen);
  }, [isOpen]);

  return (
    <div>
      {withButton && (
        <Button color="primary" onClick={handleClickOpen} variant="outlined">
          <InfoIcon style={{ marginRight: 8 }} />
          Info
        </Button>
      )}
      <Dialog
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-slide-description"
        aria-labelledby="alert-dialog-slide-title"
        keepMounted
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
