import MuiButton from './MuiButton';
import MuiChip from './MuiChip';
import MuiFab from './MuiFab';
import MuiFilledInput from './MuiFilledInput';
import MuiIconButton from './MuiIconButton';
import MuiInputBase from './MuiInputBase';
import MuiLinearProgress from './MuiLinearProgress';
import MuiListItemIcon from './MuiListItemIcon';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSvgIcon from './MuiSvgIcon';
import MuiTab from './MuiTab';
import MuiTableCell from './MuiTableCell';
import MuiTableRow from './MuiTableRow';
import MuiTabs from './MuiTabs';
// New
import MuiTableFooter from './MuiTableFooter';
import MuiTablePagination from './MuiTablePagination';
import MuiListItemText from './MuiListItemText';
import MuiDialog from './MuiDialog';
import MuiBadge from './MuiBadge';

export default {
  MuiButton,
  MuiChip,
  MuiFab,
  MuiFilledInput,
  MuiIconButton,
  MuiInputBase,
  MuiLinearProgress,
  MuiListItemIcon,
  MuiOutlinedInput,
  MuiSvgIcon,
  MuiTab,
  MuiTableCell,
  MuiTableRow,
  MuiTabs,
  // New
  MuiTableFooter,
  MuiTablePagination,
  MuiListItemText,
  MuiDialog,
  MuiBadge
};
