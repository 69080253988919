import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import {
  RELATIONSHIP_CREATE,
  RELATIONSHIP_DELETE
} from 'graphql/RelationshipAPI/mutations';
import { GET_RELATIONSHIPS_LIST } from 'graphql/RelationshipAPI/queries';
import {
  IRelationshipData,
  IRelationshipInstance
} from 'redux/actions/GraphQlActions/interface';
import { BugTracker } from 'Utils/Bugtracker';

/**
 * Creates a relationship based on the provided relationship data.
 *
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} action - Action type, expected to be 'CREATE'.
 * @param {IRelationshipData} data - The data for the relationship that includes title, primary and related UserInstanceIds, status, and permissions.
 * @returns {Promise<IRelationshipInstance | ApolloError>} A promise that resolves to a IRelationshipInstance if successful or an ApolloError if there are errors during the operation.
 */

const CreateRelationship = async ({
  baseUrl,
  action,
  UserInstanceId,
  UserDefinitionId,
  data
}: {
  baseUrl;
  action: 'CREATE';
  UserInstanceId: number;
  UserDefinitionId: number;
  data: IRelationshipData;
}): Promise<IRelationshipInstance | ApolloError> => {
  try {
    const { data: responsePayload, errors } = await apolloClient.mutate({
      mutation: RELATIONSHIP_CREATE,
      variables: {
        baseUrl,
        action,
        data
      },
      refetchQueries: [
        {
          query: GET_RELATIONSHIPS_LIST,
          variables: {
            baseUrl,
            action: 'LIST_ALL',
            userInstanceId: UserInstanceId,
            userDefinitionId: UserDefinitionId
          }
        }
      ],
      awaitRefetchQueries: true
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    return responsePayload.Items as IRelationshipInstance;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Deletes a relationship between two user instances based on their IDs.
 *
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} action - Action type, expected to be 'DELETE'.
 * @param {number} PrimaryUserInstanceId - The ID of the primary user in the relationship.
 * @param {number} RelatedUserInstanceId - The ID of the related user in the relationship.
 * @returns {Promise<string | ApolloError>} A promise that resolves with a success message if the relationship is successfully deleted or an ApolloError if there are errors.
 */

const DeleteRelationship = async ({
  baseUrl,
  action,
  UserDefinitionId,
  PrimaryUserInstanceId,
  RelatedUserInstanceId
}: {
  baseUrl: string;
  action: 'DELETE';
  UserDefinitionId: number;
  PrimaryUserInstanceId: number;
  RelatedUserInstanceId: number;
}): Promise<string | ApolloError> => {
  try {
    const { data: responsePayload, errors } = await apolloClient.mutate({
      mutation: RELATIONSHIP_DELETE,
      variables: {
        baseUrl,
        action,
        primaryUserInstanceId: parseInt(PrimaryUserInstanceId.toString()),
        relatedUserInstanceId: parseInt(RelatedUserInstanceId.toString())
      },
      refetchQueries: [
        {
          query: GET_RELATIONSHIPS_LIST,
          variables: {
            baseUrl,
            action: 'LIST_ALL',
            userInstanceId: parseInt(PrimaryUserInstanceId.toString()),
            userDefinitionId: UserDefinitionId
          }
        }
      ],
      awaitRefetchQueries: true
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    return responsePayload.UpdateRelationships.Items as string;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

export { CreateRelationship, DeleteRelationship };
