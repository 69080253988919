import { CompleteObjectInstance } from 'types/interfaces';
import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * OBJECT INSTANCE API
 */
export const SelectObject = async ({
  data,
  action,
  ProcessInstanceId,
  ObjectDefinitionId,
  ObjectInstanceId,
  UserInstanceId
}) => {
  const url = `ObjectInstanceAPI.ashx?action=${action}&processinstanceid=${ProcessInstanceId}&objectdefinitionid=${ObjectDefinitionId}&objectinstanceid=${ObjectInstanceId}&userinstanceid=${UserInstanceId}`;
  let config: AxiosRequestConfig = { method: 'get', url, data };
  return await dbQueue({ config, url });
};

export const getIfObjectIsSelected = async ({ ObjectInstanceId }) => {
  const url = `ObjectInstanceAPI.ashx?action=SELECTED&objectinstanceid=${ObjectInstanceId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetListOfObjects = async ({
  ProcessInstanceId,
  ObjectDefinitionId
}) => {
  let url = `ObjectInstanceAPI.ashx?action=LIST&processinstanceid=${ProcessInstanceId}&objectdefinitionid=${ObjectDefinitionId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const DeleteObject = async ({ ObjectInstanceId }) => {
  let url = `ObjectInstanceAPI.ashx?action=DELETE&objectinstanceid=${ObjectInstanceId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetObjectInstanceListByKeyword = async ({
  ProcessInstanceId,
  Keyword
}) => {
  let url = `ObjectInstanceAPI.ashx?action=COMPLETEDICTBYKEYWORD&processinstanceid=${ProcessInstanceId}&keyword=${Keyword}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const getObjectInstanceDocument = async ({ ObjectInstanceId }) => {
  let url = `ObjectInstanceAPI.ashx?action=DOCUMENT&objectinstanceid=${ObjectInstanceId}`;
  let config: AxiosRequestConfig = { method: 'get', responseType: 'blob', url };
  const res: any = await dbQueue({ config, url });
  return res;
};
