import React from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import Kanban from 'components/Kanban';

const Component = props => (
  <DashboardLayout title="Kanban">
    <Kanban style={{ background: 'blue' }} />
  </DashboardLayout>
);

export default Component;
