import { store } from 'redux/store';

export const GA_TRACKING_ID = process.env.REACT_APP_FB_MEASUREMENTID;

export const pageview = (url: string) => {
  if (process.env.NODE_ENV !== 'production') return;
  // @ts-expect-error
  window.gtag('config', GA_TRACKING_ID, { page_title: url });
};

export const event = ({ action, feature, message, value = 1 }) => {
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  const uid = store.getState().user.user.Id;
  const email = store.getState().user.user.UserInstanceEmail;
  const name = store.getState().user.user.Title;
  if (process.env.NODE_ENV !== 'production') return;
  // @ts-expect-error
  window.gtag('event', action, {
    email,
    feature,
    hostname,
    message,
    name,
    uid,
    value
  });
};
