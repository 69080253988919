import { Button, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useHistory } from 'react-router-dom';
import { useUserFromId } from 'components/User/QuickUserFromId';

import {
  INewUser,
  INotificationLog,
  ISupplierAutomation
} from '../functions/interfaces';
import { validateEmail } from 'helpers/validateEmail';
import { UserDefinition } from 'types/interfaces';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { useTypedSelector } from 'redux/reducers';
import { IMode, IOption } from 'automations/NewSupplierContactAutomation';

// Here we for the automation need a select
export const NavigateToUserButton = ({
  automation,
  createdUserInstanceId,
  setOpen,
  NotificationLog,
  UserDefinition,
  isValidCompany,
  createdUser,
  entityType
}: {
  automation?: ISupplierAutomation;
  createdUserInstanceId: number;
  createdUser?: boolean;
  setOpen: (open: boolean) => void;
  NotificationLog: INotificationLog[];
  UserDefinition: UserDefinition | undefined;
  isValidCompany: IRedflagAPIData;
  entityType?: {
    label: string;
    value: string;
  } | null;
}) => {
  const history = useHistory();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const user = useUserFromId({
    UserInstanceId: createdUserInstanceId,
    baseUrl
  });

  const handleSupplier: ISupplier = HandleSupplier({
    UserDefinition,
    isValidCompany
  });

  return (
    <Button
      data-cy="newly-created-user-button"
      variant="outlined"
      color="primary"
      onClick={async () => {
        if (!automation) {
          if (!user) return;
          const { UserDefinitionId } = user;

          if (UserDefinitionId && createdUserInstanceId) {
            const path = `/UserDefinition/${UserDefinitionId}/${createdUserInstanceId}`;
            history.push(path);
            setOpen(false);
          }
        } else {
          const companyInformation =
            automation.company.third_party_apis.redflagOG.data?.attributes;

          const newOption: IOption = {
            label: companyInformation?.name || '',
            value: parseInt(
              companyInformation?.company_number?.toString() || '0'
            ),
            title: companyInformation?.name || '',
            createdCompany: {
              mode: IMode.Company,
              Id: createdUserInstanceId
            }
          };

          console.log({ newOption });
          await automation.importer(newOption);
          setOpen(false);
        }
      }}>
      {HandleButtonLabelsAndAlerts({
        hasAutomation: automation !== undefined,
        NotificationLog,
        isSupplier: handleSupplier,
        createdUser,
        type: 'Button',
        entityType: entityType !== null ? entityType : undefined
      })}
    </Button>
  );
};

export const isTitleValid = (title: string) => {
  return title !== '' && title.length >= 5;
};

export const HandleValidTitle = (newUser: INewUser) => {
  const isNewUser = newUser.Title;

  const valid =
    isNewUser && !isTitleValid(isNewUser) ? (
      <Typography style={{ color: 'red' }}>
        Must be more than 5 characters long
      </Typography>
    ) : null;

  return valid;
};

export const isEmailValid = (
  userType: string,
  email: string,
  selectedRadio?: { title: string; stateString: string }
) => {
  if (selectedRadio && selectedRadio.title === 'token') return true;
  if (email === '') return false;
  return validateEmail(email);
};

export const HandleValidEmail = (
  userType: string,
  newUser: INewUser,
  selectedRadio?: { title: string; stateString: string }
) => {
  const isEntity = userType == 'Entity';
  const emailIsValid = isEmailValid(
    userType,
    newUser.UserInstanceEmail,
    selectedRadio
  );

  const errorElement = isEntity ? null : newUser.UserInstanceEmail ? (
    !emailIsValid ? (
      <Typography style={{ color: 'red' }}>Invalid Email</Typography>
    ) : null
  ) : null;

  return errorElement;
};
export const HandleDisabled = (
  userType,
  newUser,
  is_Company,
  is_Director,
  data,
  selectedRadio?
) => {
  const isEntity = userType === 'Entity';
  const isCompany = is_Company && data?.data?.type === 'company';
  const isDirector = is_Director && data?.type === 'directorship';
  const hasValidTitleEmailPrimary =
    !isTitleValid(newUser.Title) ||
    !isEmailValid(userType, newUser.UserInstanceEmail, selectedRadio);

  const disabled = isEntity
    ? isCompany
      ? false
      : hasValidTitleEmailPrimary
    : isDirector
    ? false
    : hasValidTitleEmailPrimary;

  return disabled;
};

export interface ISupplier {
  isValidSupplier: boolean;
  isValidShortCode: boolean;
}

export const HandleSupplier = ({
  UserDefinition,
  isValidCompany
}: {
  UserDefinition: UserDefinition | undefined;
  isValidCompany: IRedflagAPIData | any;
}) => {
  let validSupplier;

  const isSupplier: boolean =
    UserDefinition?.Id === 26
      ? true
      : UserDefinition?.Title?.includes('Suppliers')
      ? true
      : false;

  if (isValidCompany === undefined) {
    if (isSupplier) {
      validSupplier = true;
      const types: ISupplier = {
        isValidSupplier: validSupplier,
        isValidShortCode: false
      };
      return types;
    }
  }

  const isValid: IRedflagAPIData[] =
    isValidCompany && Object.values(isValidCompany).length !== 0;
  const hasShortCode: string[] = ['INSOLV', 'str', 'dissolved', 'N/A'];

  let isValidShortCode;
  const isValidCreditScore: boolean =
    isValidCompany?.rfa_rating?.credit_score === null;

  hasShortCode.forEach((element: string) => {
    if (isValidCompany?.rfa_rating?.short_code.includes(element)) {
      isValidShortCode = true;
    }
  });

  if (isValid) {
    if (isSupplier) {
      validSupplier = true;
      if (isValidCreditScore) isValidShortCode = true;
    } else {
      validSupplier = false;
    }
  } else validSupplier = false;

  const types: ISupplier = {
    isValidSupplier: validSupplier,
    isValidShortCode: isValidShortCode
  };

  return types;
};

interface IAlert {
  UserDefinition: UserDefinition | undefined;
  isValidCompany: IRedflagAPIData;
  handleClose: () => void;
  userType: string;
  newUser: INewUser;
  is_Company: boolean;
  is_Director: boolean;
  data: any;
  validPrimary: boolean;
  NotificationLog: INotificationLog[];
  createdUserInstanceId: number | null;
  createdUser: boolean;
  entityType: any;
}

export const HandleAlert = (props: IAlert) => {
  const {
    UserDefinition,
    isValidCompany,
    handleClose,
    userType,
    newUser,
    is_Company,
    is_Director,
    data,
    validPrimary,
    NotificationLog,
    createdUserInstanceId,
    createdUser,
    entityType
  } = props;

  // Is The Current User A Supplier?
  const handleSupplier: ISupplier | any = HandleSupplier({
    UserDefinition,
    isValidCompany
  });

  // Is The Next Step Disabled?
  const isDisabled: boolean = HandleDisabled(
    userType,
    newUser,
    is_Company,
    is_Director,
    data
  );

  // Is The Company Valid?
  const isValid: boolean =
    isValidCompany && Object.values(isValidCompany).length !== 0;

  return (
    <Alert
      variant="filled"
      severity={HandleSeverity({
        isSupplier: handleSupplier,
        isDisabled,
        isValid,
        createdUserInstanceId,
        createdUser,
        entityType
      })}
      style={{ borderRadius: 0, justifyContent: 'center' }}>
      <Typography
        style={
          !handleSupplier.isValidSupplier
            ? { color: 'white' }
            : { color: 'black' }
        }>
        {HandleAlertText({
          isSupplier: handleSupplier,
          isDisabled,
          isValid,
          createdUserInstanceId,
          NotificationLog,
          createdUser,
          entityType
        })}
      </Typography>

      <IconButton
        data-cy="iconbutton-close"
        aria-label="close"
        style={{ position: 'absolute', right: 3, top: 3, color: 'white' }}
        onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Alert>
  );
};

interface ISeverity {
  isSupplier: ISupplier;
  NotificationLog?: INotificationLog[];
  isDisabled: boolean;
  isValid: boolean;
  createdUserInstanceId: number | null;
  createdUser: boolean;
  entityType: any;
}

const HandleAlertText = (props: ISeverity) => {
  const {
    NotificationLog,
    isSupplier,
    isDisabled,
    isValid,
    createdUserInstanceId,
    createdUser,
    entityType
  } = props;

  if (isSupplier.isValidShortCode) {
    return `Cannot Create ${
      isSupplier?.isValidSupplier ? 'Supplier' : 'Company'
    } From Dissolved Companies Or Discontinued Companies`;
  } else if (isDisabled) return 'Fill in the required information';
  else if (createdUserInstanceId !== null) {
    return HandleButtonLabelsAndAlerts({
      NotificationLog,
      isSupplier,
      createdUser,
      entityType,
      type: 'Alert'
    });
  } else if (isValid) return 'Proceed with the create button';
  else {
    return 'Proceed with the create button';
  }
};

const HandleSeverity = (props: ISeverity) => {
  const { isSupplier, isDisabled, isValid, createdUserInstanceId } = props;

  let hasCreatedID = createdUserInstanceId !== null;

  if (isSupplier.isValidShortCode) return 'error';
  else if (isDisabled) return 'warning';
  else if (isValid || hasCreatedID) return 'success';
};

export const HandleButtonLabelsAndAlerts = ({
  hasAutomation,
  NotificationLog,
  isSupplier,
  type,
  createdUser,
  entityType
}: {
  hasAutomation?: boolean;
  NotificationLog?: INotificationLog[];
  isSupplier: ISupplier;
  type: string;
  createdUser?: boolean;
  entityType?: { label: string; value: string };
}) => {
  if (NotificationLog === null || NotificationLog === undefined) return [];

  if (!createdUser) {
    const isCompany: INotificationLog | undefined = NotificationLog.find(
      (el: INotificationLog) => el.event === 'User Creation (Company)'
    );

    const isDirector: INotificationLog | undefined = NotificationLog.find(
      (el: INotificationLog) => el.event === 'User Creation (Director)'
    );

    if (isSupplier.isValidSupplier) {
      switch (isCompany && isCompany?.type) {
        case 'SUCCESS':
          if (type.includes('Alert'))
            return `Supplier ${entityType?.value} Created`;
          if (type.includes('Button'))
            return hasAutomation
              ? 'Import Created Supplier'
              : 'Go to Newly Created Supplier';
          else
            return hasAutomation
              ? 'Import Created Supplier'
              : 'Select Newly Created Supplier';
        default:
          if (type.includes('Alert'))
            return `Supplier ${entityType?.value} Already Exists`;
          if (type.includes('Button'))
            return hasAutomation
              ? 'Import Existing Supplier'
              : 'Go to Existing Supplier';
          else
            return hasAutomation
              ? 'Import Existing Supplier'
              : 'Select Current Supplier';
      }
    } else {
      switch (isCompany && isCompany?.type) {
        case 'SUCCESS':
          if (type.includes('Alert')) return `${entityType?.value} Created`;
          if (type.includes('Button')) return 'Go to Newly Created Company';
          else return 'Select Newly Created Company';
        case 'ALREADY EXISTS':
          if (type.includes('Alert'))
            return `${entityType?.value} Already Exists`;
          if (type.includes('Button')) return 'Company Already Exists';
          else return 'Select Current Company';
      }

      switch (isDirector && isDirector?.type) {
        case 'SUCCESS':
          if (type.includes('Alert')) return 'Director Created';
          if (type.includes('Button')) return 'Go to Newly Created Director';
          else return 'Select Newly Created Director';
        case 'ALREADY EXISTS':
          if (type.includes('Alert') || type.includes('Button'))
            return `Director Already Exists`;
          else 'Select Current Director';
      }
    }
  } else {
    const EventType: EventType = 'User Created';
    const successfullyCreatedUser: INotificationLog | undefined =
      NotificationLog.find((el: INotificationLog) => el.event === EventType);

    if (isSupplier.isValidSupplier) {
      switch (successfullyCreatedUser?.type) {
        case 'SUCCESS':
          return `Supplier ${entityType?.value} Created`;
        default:
          return `Supplier ${entityType?.value} Already Exists`;
      }
    } else {
      switch (successfullyCreatedUser?.type) {
        case 'SUCCESS':
          if (type.includes('Alert'))
            return entityType?.value === undefined
              ? 'User Created'
              : `${entityType?.value} Created`;
          if (type.includes('Button')) return 'Go to Newly Created User';
          else return 'Select Newly Created User';
        case 'ALREADY EXISTS':
          if (type.includes('Alert'))
            return `${entityType?.value} Already Exists`;
          if (type.includes('Button')) return 'User Already Exists';
          else return 'Select Current User';
        default:
          if (type.includes('Button')) return 'Go to Newly Created User';
          else return 'Select Newly Created User';
      }
    }
  }
};

type EventType = 'User Created';
