import { useState, Fragment, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import { PercentageField, CurrencyField } from '../Utils/inputFields';
import { Calculation } from 'types/calculatorInterfaces';
import { Tools } from '../Calculator/interfaces';
import { useCalculator } from '../Calculator/hooks/useCalculator';

interface ITotals {
  DocFeeInclVAT: number;
  DocFeeExclVAT: number;
  DocFeeExclOptionFee: number;
  DocFeeInclOptionFee: number;
  DocFeeExclAnnual: number;
  DocFeeInclAnnual: number;
}

const DocumentFee = (tools: Tools) => {
  const { state } = tools;
  const { handleChange } = useCalculator(tools);
  const [open, setOpen] = useState(false);
  const shouldOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const calculate = () => null;
  const onSubmit = async () => setOpen(false);

  const [totalState, setTotalState] = useState<ITotals>({
    DocFeeInclVAT: 0,
    DocFeeExclVAT: 0,
    DocFeeExclOptionFee: 0,
    DocFeeInclOptionFee: 0,
    DocFeeExclAnnual: 0,
    DocFeeInclAnnual: 0
  });

  useEffect(() => {
    // Handle Document Fee
    const DocFee = state._DocumentFee.DocFee;
    const UpsellFee = state._DocumentFee.DocFeeUpsell;
    const VAT = state._DocumentFee.DocFee_Vat;
    const ExcludingVAT = DocFee + UpsellFee;
    const IncludingVAT = DocFee + UpsellFee + VAT;

    // Handle Option Fee
    const OptionFee = state._DocumentFee.OptionFee;
    const OptionFeeVat = state._DocumentFee.OptionFee_Vat;
    const ExcludingOptionVAT = OptionFee;
    const IncludingOptionVAT = OptionFee + OptionFeeVat;

    // Handle Annual Fee
    const AnnualFee = state._DocumentFee.AnnualServiceFee;
    const AnnualFeeVat = state._DocumentFee.AnnualServiceFee_Vat;
    const ExcludingAnnualVAT = AnnualFee;
    const IncludingAnnualVAT = AnnualFee + AnnualFeeVat;

    setTotalState({
      ...totalState,
      DocFeeExclVAT: ExcludingVAT,
      DocFeeInclVAT: IncludingVAT,
      DocFeeExclOptionFee: ExcludingOptionVAT,
      DocFeeInclOptionFee: IncludingOptionVAT,
      DocFeeExclAnnual: ExcludingAnnualVAT,
      DocFeeInclAnnual: IncludingAnnualVAT
    });
  }, [state._DocumentFee]);

  return (
    <Fragment>
      <Button
        data-cy="calculator-fee-btn"
        color="primary"
        fullWidth
        onClick={shouldOpen}
        variant="contained">
        FEES
        {/* <PaymentOutlinedIcon className={classes.rightIcon} /> */}
      </Button>
      <Dialog fullWidth maxWidth="lg" open={open}>
        <DialogTitle>Document Fee</DialogTitle>

        <DialogContent>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center">
            <Grid item style={{ width: '100%' }}>
              <Grid container spacing={1}>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    error={undefined}
                    name="Document Fee"
                    onBlur={undefined}
                    onChange={handleChange('DocFee')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._DocumentFee.DocFee}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    error={undefined}
                    name="Upsell"
                    onBlur={undefined}
                    onChange={handleChange('DocFeeUpsell')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._DocumentFee.DocFeeUpsell}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <PercentageField
                    InputLabelProps={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    name="VAT Rate %"
                    onBlur={undefined}
                    onChange={handleChange('DocFee_VatRate')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    style={undefined}
                    type={undefined}
                    value={state._DocumentFee.DocFee_VatRate}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="VAT"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._DocumentFee.DocFee_Vat}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="Excl Total Fee"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={totalState.DocFeeExclVAT}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="Incl Total Fee "
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={totalState.DocFeeInclVAT}
                  />
                </Grid>
              </Grid>

              <Grid container>
                <Grid item sm={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Payment Date"
                    margin="dense"
                    onChange={handleChange('DocFee_PaymentDate')}
                    select
                    value={state._DocumentFee.DocFee_PaymentDate}>
                    <MenuItem value={'FIRST_PAYMENT'}>
                      WITH FIRST PAYMENT
                    </MenuItem>
                    <MenuItem value={'CONTRACT_START'}>
                      START OF CONTRACT
                    </MenuItem>
                  </TextField>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                <Grid item sm={3} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    error={undefined}
                    name="Option to Purchase Fee"
                    onBlur={undefined}
                    onChange={handleChange('OptionFee')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._DocumentFee.OptionFee}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <PercentageField
                    InputLabelProps={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    name="VAT Rate %"
                    onBlur={undefined}
                    onChange={handleChange('OptionFee_VatRate')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    style={undefined}
                    type={undefined}
                    value={state._DocumentFee.OptionFee_VatRate}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="VAT"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._DocumentFee.OptionFee_Vat}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="Excl Total Fee"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={totalState.DocFeeExclOptionFee}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="Incl Total Fee"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={totalState.DocFeeInclOptionFee}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={3} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    error={undefined}
                    name="Annual Service Charge"
                    onBlur={undefined}
                    onChange={handleChange('AnnualServiceFee')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._DocumentFee.AnnualServiceFee}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <PercentageField
                    InputLabelProps={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={undefined}
                    name="VAT Rate %"
                    onBlur={undefined}
                    onChange={handleChange('AnnualServiceFee_VatRate')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    style={undefined}
                    type={undefined}
                    value={state._DocumentFee.AnnualServiceFee_VatRate}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="VAT"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._DocumentFee.AnnualServiceFee_Vat}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="Excl Annual Service"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={totalState.DocFeeExclAnnual}
                  />
                </Grid>
                <Grid item sm={2} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={undefined}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="Incl Annual Service"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={totalState.DocFeeInclAnnual}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            data-cy="doc-fee-ok-btn"
            color="primary"
            onClick={onSubmit}
            variant="contained">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default DocumentFee;
