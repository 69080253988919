import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { IoMdContact } from 'react-icons/io';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useTypedSelector } from 'redux/reducers';
import { createList } from '../functions';
import Table from 'components/SimpleTable';
import { deleteList, fetchLists } from '../functions';
import { SuccessButton } from 'common';
import { ListFb } from '../types';
import { QueryKeys } from '../../types';

const List = () => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const user = useTypedSelector((s) => s.user.user);

  const { mutate: create, isLoading: createLoading } = useMutation(createList, {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchLists)
  });

  const { mutate: remove, isLoading: deleteLoading } = useMutation(deleteList, {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchLists)
  });

  const { data: lists = [], isLoading } = useQuery(
    [QueryKeys.FetchLists],
    () => fetchLists(user.Id),
    {
      enabled: !!user.Id
    }
  );

  const handleCreateList = async (name: string) =>
    create({
      name,
      userInstanceId: user.Id
    });

  const handleDeleteList = async (id: string) => remove(id);

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          opacity: 0.8
        }}>
        <IoMdContact
          style={{
            marginRight: 10,
            fontSize: 50,
            color: 'grey'
          }}
        />
        <Typography
          variant="h3"
          style={{
            color: 'grey'
          }}>
          Email Lists
        </Typography>
      </div>
      <Table
        title={`Lists (${lists.length})`}
        columns={[{ field: 'name', title: 'name' }]}
        data={lists.sort((a, b) => b.ts.seconds - a.ts.seconds)}
        isLoading={isLoading || createLoading || deleteLoading}
        options={{ pageSize: 10 }}
        handleRowClick={(l: ListFb) => history.push(`/marketing/lists/${l.id}`)}
        editable={{
          onRowAdd: async (d: ListFb) => await handleCreateList(d.name),
          onRowDelete: async (d: ListFb) => await handleDeleteList(d.id)
        }}
        customIcons={{
          Add: () => (
            <SuccessButton
              onClick={() => {
                window.scrollTo({
                  // scroll to bottom
                  top: document.documentElement.scrollHeight,
                  behavior: 'smooth'
                });
              }}>
              Create New List
            </SuccessButton>
          )
        }}
      />
    </>
  );
};

export default List;
