import { getStepUsers, getProcessById, getProcessFields } from './';
import { PROCESS_FIELDS_ACTION } from 'types/databaseInterfaces';

export const getStepData = async ({
  index,
  ProcessInstanceId,
  ProcessDefinitionId
}: {
  index: number;
  ProcessInstanceId: number;
  ProcessDefinitionId: number;
}) => {
  // Process Step API
  const resStepUsers = await getStepUsers({
    ProcessInstanceId,
    ProcessStepDefinitionId: index
  });

  // Process Instance API
  await getProcessById({
    Id: ProcessInstanceId,
    ProcessStepDefinitionId: index
  });

  // Field Instance APi
  let action: PROCESS_FIELDS_ACTION = 'OVERVIEW';
  if (index < 0) action = 'FORPROCESS';

  await getProcessFields({
    ProcessInstanceId,
    ProcessDefinitionId,
    ProcessStepDefinitionId: index,
    action
  });

  if (resStepUsers?.InteractiveRuleDict) {
    return true;
  } else {
    return false;
  }
};
