import axios, { AxiosRequestConfig } from 'axios';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { Domain } from '../types';

const { dispatch } = store;
const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

export const verifyDomain = async (data: Domain) => {
  const token = await firebase.auth().currentUser?.getIdToken();

  const url = `${process.env.REACT_APP_PROXY}/marketing/domains/${data.id}/validate`;
  const config: AxiosRequestConfig = {
    method: 'POST',
    url,
    data,
    headers: { token }
  };
  try {
    await axios(config);

    dispatch(createNotification(successNotif('Status Updated')));

    gtag.event({
      feature: 'Marketing',
      action: 'Domain Verification Check: Success',
      message: `${data.id} checked on ${hostname}`
    });
  } catch (e) {
    BugTracker.notify(e);
  }
};
