import { Grid } from '@material-ui/core';
import { XpansionIsolated } from 'common/Xpansion';
import { FormTabs } from '../Forms/FormTabs';
import { FormContent } from '../Forms/FormContent';
import { Instructions } from './SystemUserView';
import { useMobile } from 'hooks';

export const FactFindFormView = () => {
  const isMobile = useMobile();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Instructions />
      </Grid>

      <Grid item xs={isMobile ? 12 : 4} style={{ width: '100%' }}>
        <FormTabs view="system" />
      </Grid>
      <Grid item xs={isMobile ? 12 : 8}>
        <XpansionIsolated expanded summary="Form">
          <FormContent />
        </XpansionIsolated>
      </Grid>
    </Grid>
  );
};
