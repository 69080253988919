import React from 'react';
import {
  NOTIFICATION_TYPE_SUCCESS,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_INFO
} from 'react-redux-notify';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import Chatter from './CustomTypes/chatter';
import { Typography, Button, Paper, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { IoIosClose } from 'react-icons/io';

const customStyles = { padding: 50 };

export const successNotif = (message, duration) => ({
  message: `${message}`,
  customStyles,
  type: NOTIFICATION_TYPE_SUCCESS,
  duration: duration ? duration : 3000,
  canDismiss: true,
  icon: <CheckIcon />
});

export const errorNotif = (message) => {
  return {
    message: `${message}`,
    type: NOTIFICATION_TYPE_ERROR,
    customStyles,
    // duration: 3000,
    canDismiss: true,
    icon: <ErrorIcon />
  };
};

export const warningNotif = (message) => {
  return {
    message: `${message}`,
    type: NOTIFICATION_TYPE_WARNING,
    customStyles,
    // duration: 3000,
    canDismiss: true,
    icon: <WarningIcon />
  };
};

export const infoNotif = (message) => ({
  message: `${message}`,
  type: NOTIFICATION_TYPE_INFO,
  customStyles,
  canDismiss: true,
  icon: <InfoIcon />,
  customComponent: ({ canDismiss, handleDismiss, id }) => {
    const theme = useTheme();
    setTimeout(() => canDismiss && handleDismiss(id), 3000);
    return (
      <Paper
        elevation={5}
        style={{
          background: theme.palette.primary.light,
          borderRadius: 3,
          color: 'white',
          padding: 18,
          margin: 1,
          border: '1px solid white',
          float: 'left'
        }}>
        <Grid alignItems="center" container>
          <Grid item>
            <Typography style={{ color: 'white' }}>{message}</Typography>
            {/* <hr />
            <Typography style={{ color: 'white' }} variant="body2">
              More information goes here. More detail you know really good
              information.
            </Typography> */}
          </Grid>
          <div style={{ flexGrow: 1, minWidth: 100 }} />
          <Grid item>
            <Button
              onClick={() => {
                if (canDismiss) handleDismiss(id);
              }}
              style={{ color: 'white' }}>
              <CloseIcon style={{ fontSize: 18 }} />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  }
});

export const chatterNotif = (chat) => ({
  // message: `${chat.Title}`,
  // type: NOTIFICATION_TYPE_INFO,
  // customStyles,
  duration: 3000,
  // canDismiss: true,
  // icon: <InfoIcon />,
  customComponent: (props) => <Chatter chat={chat} />
});
