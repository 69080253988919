import {
  Avatar,
  Button,
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { Archive, Bookmark, Flag } from '@material-ui/icons';
import { CustomDeletionDialog } from 'common/Dialog';
import Time from 'common/Time';
import { CardContext } from 'components/MessageHub/context/HoverCardContext';
import Attachments from 'components/MessageHub/utils/Attachments/DropdownAttachments';
import InlineAttachments from 'components/MessageHub/utils/Attachments/InlineAttachments';
import { useContext } from 'react';
import { theme } from 'theme';
import { CustomThreadHover } from '.';
import { IRenderParentMessage } from '../context/interface';

export const RenderParentThread = ({
  conversation,
  conversations,
  idx,
  messages,
  openStatus,
  hoveredCard,
  setOpenStatus,
  selectedThread,
  openThreads,
  handleOpen,
  updateFlagStatus,
  handleArchiveMessage,
  toggleBookmark,
  getSenderColor,
  isBookmarked
}: IRenderParentMessage) => {
  const { setBookmarkedConversations } = useContext(CardContext);
  const { totalAttachments } = conversation;
  const hasAttachments = totalAttachments.length > 0;

  const parentEmailAddress = conversation.parentMessage?.from?.emailAddress;
  const parentSubject = conversation.parentMessage?.subject || '';
  const parentEmailAddressName = parentEmailAddress?.name || '';
  const parentEmailAddressEmail = parentEmailAddress?.address || '';
  const conversationId = conversation.parentMessage?.conversationId || '';

  const hasMessage = selectedThread && openThreads[selectedThread];
  const isOpen = openStatus[conversationId] || false;
  const isHovered = hoveredCard === conversationId;

  const renderToolbar = () => (
    <Toolbar
      conversation={conversation}
      setOpenStatus={setOpenStatus}
      updateFlagStatus={updateFlagStatus}
      isHovered={isHovered}
      parentSubject={parentSubject}
      toggleBookmark={toggleBookmark}
      isBookmarked={isBookmarked}
      conversationId={conversationId}
      isOpen={isOpen}
    />
  );

  const renderAttachments = () => (
    <div style={{ marginLeft: '8px' }}>
      <Attachments attachments={totalAttachments} />
    </div>
  );

  const renderInlineAttachments = () => (
    <InlineAttachments attachments={totalAttachments} />
  );

  const renderNewestReply = () => {
    const sortedReplies = [...conversation.replies].sort((a, b) => {
      const dateA = a.lastModifiedDateTime
        ? new Date(a.lastModifiedDateTime).getTime()
        : 0;
      const dateB = b.lastModifiedDateTime
        ? new Date(b.lastModifiedDateTime).getTime()
        : 0;
      return dateB - dateA;
    });

    const newestReply = sortedReplies[0];

    if (!newestReply.isRead) {
      return (
        <Grid
          item
          xs={12}
          style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <Card
            style={{
              width: '100%',
              padding: theme.spacing(2),
              border: `2px solid ${green[200]}`,
              backgroundColor: green[50]
            }}>
            <Typography style={{ fontWeight: 'bold' }}>
              Newest Reply: {newestReply.subject}
            </Typography>

            <div>{newestReply.bodyPreview}</div>
          </Card>
        </Grid>
      );
    }

    return null;
  };

  const content = (
    <CardContent>
      <CustomDeletionDialog
        open={isOpen}
        setOpen={setOpenStatus}
        confirmedDeleted={(event) => {
          event.stopPropagation();
          handleArchiveMessage({
            conversation,
            isBookmark: isBookmarked,
            setBookmarkedConversations
          });
        }}
        type="archive"
        title={`${parentSubject}?`}
      />
      <Grid container spacing={2}>
        <Grid
          item
          xs={hasMessage ? 3 : 1}
          style={{
            borderRight: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 30
          }}>
          <Avatar
            style={{
              backgroundColor: getSenderColor(parentEmailAddressName)
            }}>
            {parentEmailAddressName[0]}
          </Avatar>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h5" component="h2">
            {`${parentEmailAddressName} (${parentEmailAddressEmail})`}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontWeight: 'bold', paddingRight: theme.spacing(1) }}>
              Subject:
            </Typography>

            <Typography
              variant="body1"
              color="textSecondary"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: hasMessage ? '300px' : 'none'
              }}>
              {conversation.parentMessage?.subject}
            </Typography>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontWeight: 'bold', paddingRight: theme.spacing(1) }}>
              Date Received:
            </Typography>

            <Typography component={'div'} color="textSecondary">
              <Time
                time={conversation.parentMessage?.receivedDateTime}
                type="date"
              />
            </Typography>
          </div>
          {!hasMessage && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontWeight: 'bold', paddingRight: theme.spacing(1) }}>
                Number of Replies in Thread:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {conversation.replies.length}
              </Typography>
            </div>
          )}
        </Grid>

        {!hasMessage && (
          <Grid
            item
            xs={hasMessage ? 2 : 4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Toolbar
              conversation={conversation}
              setOpenStatus={setOpenStatus}
              updateFlagStatus={updateFlagStatus}
              isHovered={isHovered}
              parentSubject={parentSubject}
              isBookmarked={isBookmarked}
              toggleBookmark={toggleBookmark}
              conversationId={conversationId}
              isOpen={isOpen}
            />
          </Grid>
        )}

        {hasMessage && (
          <>
            {hasAttachments && renderAttachments()}
            {isHovered && renderToolbar()}
          </>
        )}

        {!hasMessage && hasAttachments && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}>
            {renderInlineAttachments()}
            {renderAttachments()}
          </Grid>
        )}

        {conversation.replies.length >= 1 && <>{renderNewestReply()}</>}
      </Grid>
    </CardContent>
  );

  return (
    <CustomThreadHover
      messages={messages}
      key={idx}
      handleOpen={() => handleOpen(conversationId, idx, conversations)}
      conversation={conversation.parentMessage}
      selectedConversation={conversation}
      content={content}
    />
  );
};

const Toolbar = ({
  conversation,
  setOpenStatus,
  updateFlagStatus,
  isHovered,
  parentSubject,
  toggleBookmark,
  conversationId,
  isOpen,
  isBookmarked
}) => (
  <>
    <Tooltip title={`Archive Conversation "${parentSubject}"`}>
      <Button
        style={{
          background: 'none',
          visibility: isHovered ? 'visible' : 'hidden'
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setOpenStatus((prevState) => ({
            ...prevState,
            [conversationId]: !isOpen
          }));
        }}>
        <Archive style={{ color: 'gray' }} />
      </Button>
    </Tooltip>
    <Tooltip title={`Flag Conversation "${parentSubject}"`}>
      <Button
        style={{
          background: 'none',
          visibility: isHovered ? 'visible' : 'hidden'
        }}
        onClick={async (e) => {
          e.stopPropagation();
          e.preventDefault();
          const currentFlagStatus = conversation.parentMessage.flag?.flagStatus;
          const newFlagStatus =
            currentFlagStatus === 'flagged' ? 'notFlagged' : 'flagged';

          await updateFlagStatus({
            conversation,
            newStatus: newFlagStatus,
            isBookmarked
          });
        }}>
        <Flag
          style={{
            color:
              conversation.parentMessage.flag?.flagStatus === 'flagged'
                ? 'red'
                : 'gray'
          }}
        />
      </Button>
    </Tooltip>
    <Tooltip title={`Bookmark Conversation "${parentSubject}"`}>
      <Button
        style={{
          background: 'none',
          visibility: isHovered ? 'visible' : 'hidden'
        }}
        onClick={(e) => {
          e.stopPropagation();
          toggleBookmark(conversation);
        }}>
        <Bookmark style={{ color: 'gray' }} />
      </Button>
    </Tooltip>
  </>
);
