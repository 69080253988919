import { Grid, MenuItem, Select, TextField, useTheme } from '@material-ui/core';
import { SetStateAction } from 'react';
import {
  getFieldInstanceId,
  getHideShowId,
  getNestedAction,
  getNestedId,
  getNestedProperty,
  getNestedSelection
} from '../functions';
import { IObjectIds, IStatus } from '../interface';

const NestedOptions = ({
  idToAdd,
  setIdToAdd,
  status
}: {
  idToAdd: IObjectIds;
  setIdToAdd: React.Dispatch<SetStateAction<IObjectIds>>;
  status: IStatus;
}): JSX.Element => {
  const theme = useTheme();

  const handleRegIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      typeof idToAdd.fieldInstanceId === 'object' &&
      'nestedChildId' in idToAdd.fieldInstanceId &&
      typeof idToAdd.fieldInstanceId.nestedChildId === 'object'
    ) {
      setIdToAdd({
        ...idToAdd,
        fieldInstanceId: {
          ...idToAdd.fieldInstanceId,
          nestedChildId: {
            ...idToAdd.fieldInstanceId.nestedChildId,
            objectDefinitionIdReg: e.target.value ? parseInt(e.target.value) : 0
          }
        }
      });
    }
  };

  const handleNonRegIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (
      typeof idToAdd.fieldInstanceId === 'object' &&
      'nestedChildId' in idToAdd.fieldInstanceId &&
      typeof idToAdd.fieldInstanceId.nestedChildId === 'object'
    ) {
      setIdToAdd({
        ...idToAdd,
        fieldInstanceId: {
          ...idToAdd.fieldInstanceId,
          nestedChildId: {
            ...idToAdd.fieldInstanceId.nestedChildId,
            objectDefinitionIdNonReg: e.target.value
              ? parseInt(e.target.value)
              : 0
          }
        }
      });
    }
  };

  return (
    <div>
      <TextField
        value={getHideShowId(idToAdd.fieldInstanceId).toString()}
        variant="outlined"
        onChange={(e) => {
          if (typeof idToAdd.fieldInstanceId === 'object') {
            setIdToAdd({
              ...idToAdd,
              fieldInstanceId: {
                ...idToAdd.fieldInstanceId,
                hideShowId: e.target.value ? parseInt(e.target.value) : 0
              }
            });
          }
        }}
        label="Parent FieldDefinition ID"
        style={{ marginRight: theme.spacing(1) }}
        type="number"
        disabled={!status.isField_Instance}
      />

      <TextField
        value={getFieldInstanceId(idToAdd.fieldInstanceId).toString()}
        variant="outlined"
        onChange={(e) => {
          if (typeof idToAdd.fieldInstanceId === 'object') {
            setIdToAdd({
              ...idToAdd,
              fieldInstanceId: {
                ...idToAdd.fieldInstanceId,
                fieldInstanceId: e.target.value ? parseInt(e.target.value) : 0
              }
            });
          }
        }}
        label="Child FieldDefinition ID"
        style={{ marginRight: theme.spacing(1) }}
        type="number"
        disabled={!status.isField_Instance}
      />

      <Select
        value={getNestedSelection(idToAdd.fieldInstanceId)}
        variant="outlined"
        onChange={(e) => {
          const value = e.target.value as 'Yes' | 'No';
          if (typeof idToAdd.fieldInstanceId === 'object') {
            const updatedFieldInstanceId = {
              ...idToAdd.fieldInstanceId,
              nestedSelection: value
            };

            if (
              value === 'Yes' &&
              status.isObjectDefinitionRegAndNonRegulated
            ) {
              updatedFieldInstanceId.nestedChildId = {
                objectDefinitionIdReg: 0,
                objectDefinitionIdNonReg: 0
              };
            } else {
              updatedFieldInstanceId.nestedChildId = 0;
            }

            setIdToAdd({
              ...idToAdd,
              fieldInstanceId: updatedFieldInstanceId
            });
          }
        }}
        label="Nested Selection"
        style={{ marginRight: theme.spacing(1) }}
        disabled={!status.isField_Instance}>
        <MenuItem value="Yes">Show Nested Actions</MenuItem>
        <MenuItem value="No">Do Not Show Nested Actions</MenuItem>
      </Select>

      {getNestedSelection(idToAdd.fieldInstanceId) === 'Yes' && (
        <Grid item container xs={12} style={{ paddingTop: theme.spacing(1) }}>
          <TextField
            value={getNestedProperty(idToAdd.fieldInstanceId)}
            variant="outlined"
            onChange={(e) => {
              if (typeof idToAdd.fieldInstanceId === 'object') {
                setIdToAdd({
                  ...idToAdd,
                  fieldInstanceId: {
                    ...idToAdd.fieldInstanceId,
                    nestedName: e.target.value
                  }
                });
              }
            }}
            label="Nested Named For Child"
            style={{ marginRight: theme.spacing(1) }}
            type="text"
            disabled={!status.isField_Instance}
          />

          <Select
            value={getNestedAction(idToAdd.fieldInstanceId)}
            variant="outlined"
            onChange={(e) => {
              const value = e.target.value as
                | 'Completed'
                | 'Sent'
                | 'Starred'
                | 'None';
              if (typeof idToAdd.fieldInstanceId === 'object') {
                setIdToAdd({
                  ...idToAdd,
                  fieldInstanceId: {
                    ...idToAdd.fieldInstanceId,
                    nestedAction: value
                  }
                });
              }
            }}
            label="Nested Action"
            style={{ marginRight: theme.spacing(1) }}
            disabled={!status.isField_Instance}>
            <MenuItem value="None">Completed</MenuItem>
            <MenuItem value="Completed">Accepted</MenuItem>
            <MenuItem value="Starred">Starred</MenuItem>
            <MenuItem value="Sent">Sent</MenuItem>
          </Select>

          {typeof idToAdd.fieldInstanceId === 'object' &&
          'nestedChildId' in idToAdd.fieldInstanceId &&
          typeof idToAdd.fieldInstanceId.nestedChildId === 'object' ? (
            <>
              <TextField
                value={idToAdd.fieldInstanceId.nestedChildId.objectDefinitionIdReg.toString()}
                variant="outlined"
                onChange={handleRegIdChange}
                label="ObjectDefinition ID Reg"
                style={{ marginRight: theme.spacing(1) }}
                type="number"
                disabled={!status.isField_Instance}
              />
              <TextField
                value={idToAdd.fieldInstanceId.nestedChildId.objectDefinitionIdNonReg.toString()}
                variant="outlined"
                onChange={handleNonRegIdChange}
                label="ObjectDefinition ID Non-Reg"
                style={{ marginRight: theme.spacing(1) }}
                type="number"
                disabled={!status.isField_Instance}
              />
            </>
          ) : (
            <TextField
              value={getNestedId(idToAdd.fieldInstanceId).toString()}
              variant="outlined"
              onChange={(e) => {
                if (typeof idToAdd.fieldInstanceId === 'object') {
                  if ('nestedChildId' in idToAdd.fieldInstanceId) {
                    setIdToAdd({
                      ...idToAdd,
                      fieldInstanceId: {
                        ...idToAdd.fieldInstanceId,
                        nestedChildId: e.target.value
                          ? parseInt(e.target.value)
                          : 0
                      }
                    });
                  }
                }
              }}
              label="Nested Id For Action"
              style={{ marginRight: theme.spacing(1) }}
              type="number"
              disabled={!status.isField_Instance}
            />
          )}
        </Grid>
      )}
    </div>
  );
};
export default NestedOptions;
