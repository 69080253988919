import {
  FieldInstance,
  FieldDefinition,
  CompleteObjectInstance
} from 'types/interfaces';
import { DateField } from 'components/Fields';
import { TextField } from '@material-ui/core';

// Read only field definition ids
const DirectorNumber = 21837;
const CD_CorporationDate = 18450;
const CD_LongDescription = 18448;
const CD_RedflagStatus = 18318;
const CD_CreditScore = 18319;
const CD_SICcodes = 21881;
const CD_CompanyNumber = 18315;

const ContactReadOnlyFieldDefinitionIds = [DirectorNumber] as number[];
const CustomerReadOnlyFieldDefinitionIds = [
  CD_CorporationDate,
  CD_LongDescription,
  CD_RedflagStatus,
  CD_CreditScore,
  CD_SICcodes,
  CD_CompanyNumber
] as number[];
const ReadOnlyFieldDefinitionIds = [
  ...ContactReadOnlyFieldDefinitionIds,
  ...CustomerReadOnlyFieldDefinitionIds
] as number[];
// END

interface IFieldTypeSelectorProps {
  FieldDefinition: FieldDefinition;
  FieldInstance: FieldInstance | undefined;
  state: CompleteObjectInstance | undefined;
  handleChange: ({
    FieldDefinition,
    FieldInstance,
    FieldValue
  }: {
    FieldDefinition: FieldDefinition;
    FieldInstance: FieldInstance | undefined;
    FieldValue: string;
  }) => void;
}

export const FieldTypeSelector = ({
  FieldDefinition,
  FieldInstance,
  handleChange,
  state
}: IFieldTypeSelectorProps) => {
  const { FieldType } = FieldDefinition;

  const value: string | undefined = state?.FieldInstanceList?.find(
    (FieldInstance: FieldInstance) =>
      FieldInstance.FieldDefinitionId === FieldDefinition?.Id
  )?.FieldValue;

  const readOnly = ReadOnlyFieldDefinitionIds.includes(FieldDefinition?.Id);

  const FieldProps = {
    FieldDefinition,
    FieldInstance,
    handleChange,
    readOnly,
    state,
    value
  };

  switch (FieldType) {
    case 'Date':
      return <DateField {...FieldProps} />;
    default:
      return (
        <TextField
          disabled={readOnly}
          type=""
          label={FieldDefinition?.Title}
          name={`${FieldDefinition?.Id}`}
          value={value}
          onChange={(e) =>
            handleChange({
              FieldValue: e.target.value,
              FieldInstance,
              FieldDefinition
            })
          }
          style={{ flexGrow: 1, width: '100%' }}
        />
      );
  }
};
