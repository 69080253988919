import {
  GetData,
  GetSearchUser,
  GetUserDefinitions,
  GetUserInstanceSummaryList,
  GetUserDefinitionList
} from '../database';
import { store } from 'redux/store';
import {
  SET_USER_DEFINITION,
  SET_USER_DEFINITIONS,
  SET_LIST,
  UPDATE_LOADING
} from 'redux/actions/types';
import axios, { AxiosRequestConfig } from 'axios';
import { firebase } from 'redux/firebase';

const { dispatch } = store;

/**
 * getUserDefinitionList
 * @constructor
 * @param {string} token
 */
export const getUserDefinitionList = async () => {
  const res: any = await GetUserDefinitionList();
  if (res.headers.auth) {
    dispatch({ type: SET_USER_DEFINITIONS, payload: res.data });
    if (res && res.status === 200)
      return { token: res.headers.auth, data: res.data };
  }
};

/**
 * getUserDefinitions
 * @constructor
 * @param {string} token
 * @param {number} UserDefinitionId
 */
export const getUserDefinitions = async ({ token, UserDefinitionId }) => {
  // 1. Check to see if we have the current user definition data in the redux store
  // const { userDefinition } = store.getState().userAPI;

  // if (!userDefinition[UserDefinitionId]) {
  // console.log('we don't have: ', { UserDefinitionId });

  // console.log('need to get def from db');
  // dispatch({ type: UPDATE_LOADING, payload: true });
  const res: any = await GetUserDefinitions({ UserDefinitionId });

  if (res?.headers?.auth) {
    // dispatch({ type: UPDATE_LOADING, payload: false });

    if (Object.keys(res.data).length > 0) {
      const payload = { [UserDefinitionId]: res.data, id: UserDefinitionId };
      dispatch({ type: SET_USER_DEFINITION, payload });
    }

    if (res && res.status === 200) {
      // GO TO INSTANCE DATA
      const inst = await getUserInstanceSummaryList({
        token: res?.headers?.auth,
        UserDefinitionId
      });
      inst.success = true;
      return inst;
    }
  }
  // If YES SKIP TO INSTANCE DATA
  // GO TO INSTANCE DATA
  return getUserInstanceSummaryList({ token, UserDefinitionId });
};

/**
 * getUserInstanceSummaryList
 * @constructor
 * @param {string} token
 * @param {number} UserDefinitionId
 */
export const getUserInstanceSummaryList = async ({
  token,
  UserDefinitionId
}) => {
  // 1. Check to see if we have the current user definition data in the redux store
  const { userList } = store.getState().userAPI;

  const res: any = await GetUserInstanceSummaryList({ UserDefinitionId });

  if (res?.headers?.auth) {
    const payload = {
      [UserDefinitionId]: Object.values(res.data),
      id: UserDefinitionId
    };
    dispatch({ type: SET_LIST, payload });

    if (res && res.status === 200) {
      return { token: res.headers.auth, data: res.data };
    }
  }

  const cleanlist =
    userList &&
    userList[UserDefinitionId] &&
    userList[UserDefinitionId][UserDefinitionId] &&
    JSON.parse(JSON.stringify(userList[UserDefinitionId][UserDefinitionId]));

  cleanlist && delete cleanlist.id;

  return {
    success: true,
    token,
    data: cleanlist
  };
};
/**
 * getData - currently the DB call is a mix of definition and instance api endpoints. List will get instances where are define will get definition data
 * @constructor
 * @param {string} token
 * @param {number} UserDefinitionId
 * @param {string} action define=SET_USER_DEFINITION, list=SET_LIST
 */
export const getDefinition = async ({ token, action, UserDefinitionId }) => {
  dispatch({ type: UPDATE_LOADING, payload: true });
  const res: any = await GetData({ action, UserDefinitionId });

  if (res?.headers?.auth) {
    dispatch({ type: UPDATE_LOADING, payload: false });

    if (action === 'define') {
      if (Object.keys(res.data).length > 0) {
        const payload = { [UserDefinitionId]: res.data, id: UserDefinitionId };
        dispatch({ type: SET_USER_DEFINITION, payload });
      }
    }
    if (action === 'list') {
      if (Object.keys(res.data).length > 0) {
        const payload = { [UserDefinitionId]: res.data, id: UserDefinitionId };
        dispatch({ type: SET_LIST, payload });
      }
    }

    return res;
  }
  if (!res.headers.auth) {
    dispatch({ type: UPDATE_LOADING, payload: false });
    // console.log('Your account is being used on another device');
  }
};

/**
 * Searches for users based on process parameters
 *
 * This function handles user search operations and manages loading states.
 * It includes authentication validation and proper error handling.
 *
 * @async
 * @param {SearchUserParams} params - Search parameters
 * @returns {Promise<any>} The search results if successful
 * @throws {Error} When the API request fails or authentication is invalid
 *
 * @example
 * try {
 *   const results = await searchUser({
 *     token: 'myAuthToken',
 *     ProcessStepDefinitionId: '123',
 *     ProcessInstanceId: '456'
 *   });
 * } catch (error) {
 *   console.error('Search failed:', error);
 * }
 */
export const searchUser = async ({
  token,
  ProcessStepDefinitionId,
  ProcessInstanceId
}) => {
  try {
    // Start loading state
    dispatch({ type: UPDATE_LOADING, payload: true });

    // Make API request
    const res = await GetSearchUser({
      ProcessStepDefinitionId,
      ProcessInstanceId
    });

    // Ensure response exists
    if (!res) throw new Error('No response received from server');

    // Check authentication
    if (res.headers?.auth) {
      dispatch({ type: UPDATE_LOADING, payload: false });

      // Return data if status is OK
      if (res.status === 200) {
        return res.data;
      }

      throw new Error(`Request failed with status: ${res.status}`);
    }

    // Handle authentication failure
    handleAuthFailure();
    return null;
  } catch (error) {
    dispatch({ type: UPDATE_LOADING, payload: false });
    return null;
  } finally {
    dispatch({ type: UPDATE_LOADING, payload: false });
  }
};
const handleAuthFailure = () => {
  dispatch({ type: UPDATE_LOADING, payload: false });
  // You might want to dispatch an auth error action here
  // dispatch({ type: AUTH_ERROR, payload: 'Session expired' });

  console.warn(
    'Authentication failed - possible session expiry or concurrent login'
  );
};

/**
 * CREATE USER VIA PROXY
 */
export const CreateUser = async (data: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/user/userCreate`;
  const config: AxiosRequestConfig = {
    method: 'POST',
    url,
    data,
    headers: { token }
  };
  return axios(config)
    .then((res) => res.data)
    .catch((e: Error) => e);
};

// THIS DOES NOTHING ANYMORE - GraphQL-Integration
// export const getUserInstanceDetail = async ({
//   token,
//   UserInstanceId,
//   UserDefinitionId,
//   ForceUpdate
// }) => {
//   // THIS NEEDS A REFACTOR TO WORK HOW WE WANT IT TO  - FIN 578
//   let { userList } = store.getState().userAPI;

//   // does userList contain the current ID?
//   if (!userList[UserDefinitionId]) {
//     const res = await getUserInstanceSummaryList({
//       token,
//       UserDefinitionId
//     });
//     token = res && res.token;
//   }

//   const current = userList[UserDefinitionId][UserDefinitionId].find(
//     (el) => el.UserInstance.Id === parseInt(UserInstanceId)
//   );

//   if (
//     (current && current.CompleteObjectInstanceList.length < 1) ||
//     ForceUpdate
//   ) {
//     // console.log("We're force updating ...");
//     dispatch({ type: UPDATE_LOADING, payload: true });

//     // CompleteUserInstanceDetail
//     // const res: any = await GetUserInstanceDetail({ UserInstanceId });
//     const res: any = [];

//     const UserInstanceList = getCompleteUserInstanceDetail({baseUrl, UserInstanceId, action: "UserInstance"});

//     if (res?.headers?.auth) {
//       dispatch({ type: UPDATE_LOADING, payload: false });
//       if (res && res.status === 200) {
//         const { CompleteObjectInstanceList, UserInstance } = res.data;
//         const { Id, UserDefinitionId } = res.data.UserInstance;
//         const updatedUserList = JSON.parse(JSON.stringify(userList));

//         const currentUser = updatedUserList?.[UserDefinitionId]?.[
//           UserDefinitionId
//         ].find((el: CompleteUserInstance) => el.UserInstance.Id === Id);

//         if (currentUser) {
//           currentUser.CompleteObjectInstanceList = CompleteObjectInstanceList;
//           currentUser.UserInstance = UserInstance;
//         } else {
//           // console.log('ADDING NEWLY CREATED USER TO THE TOP OF THE LIST ...');
//           updatedUserList?.[UserDefinitionId]?.[UserDefinitionId]?.unshift(
//             res.data
//           );
//         }
//         dispatch({ type: 'SET_ALL_LIST', payload: updatedUserList });
//         return { data: res };
//       }
//     }
//   } else {
//     // console.log(
//     //   'If we dont force update then are we gettig the correct data from redux??',
//     //   userList
//     // );
//   }
// };
