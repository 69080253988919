import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  FieldDefinition,
  FieldInstance,
  ObjectDefinition,
  CompleteObjectInstance,
  ProcessInstance,
  UserInstance,
  ObjectInstance
} from 'types/interfaces';
import Dialog from './Dialog';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { NewSupplierContactAutomation } from 'automations/NewSupplierContactAutomation';
import { useTypedSelector } from 'redux/reducers';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  toedit: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    minHeight: 10,
    width: '100%',
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      border: `1px ${theme.palette.primary.light} dashed`,
      paddingLeft: 10
    }
  },
  noEdit: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: 5,
    minHeight: 10,
    width: '100%'
  },
  container: { width: '100%' },
  editIcon: {
    marginRight: '10px',
    marginLeft: '5px'
  }
}));

interface Props {
  FieldDefinition: FieldDefinition;
  FieldInstance?: FieldInstance;
  ObjectDefinition: ObjectDefinition;
  ObjectInstance?: ObjectInstance;
  ProcessInstance?: ProcessInstance | { Id: number };
  ProcessStepSelectionType: string;
  UserInstance?: UserInstance;
  type: string;
  value: string;
  fields: FieldDefinition[];
}

const supplierFields = [21944, 23270];

const Editable_Select_Create_From_All = (props: Props) => {
  const landingpage = useTypedSelector((s) => s.utility.landingpage);
  const [showEdit, setShowEdit] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const areSupplierFields = supplierFields.includes(props.FieldDefinition.Id);

  if (edit) return <Dialog edit={edit} setEdit={setEdit} {...props} />;
  return (
    <>
      <NoEdit
        {...props}
        value={props.value}
        setEdit={setEdit}
        setShowEdit={setShowEdit}
        showEdit={showEdit}
        areSupplierFields={areSupplierFields}
      />
      {supplierFields.includes(props.FieldDefinition.Id) && !landingpage && (
        <NewSupplierContactAutomation
          ObjectInstanceId={props?.ObjectInstance?.Id}
        />
      )}
    </>
  );
};

export default Editable_Select_Create_From_All;

const NoEdit = ({
  value,
  setShowEdit,
  showEdit,
  setEdit,
  areSupplierFields
}: {
  value: string;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>>;
  showEdit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  areSupplierFields: boolean;
}) => {
  const classes = useStyles();
  const noValue = value === '' || value === undefined;
  const className = areSupplierFields ? classes.noEdit : classes.toedit;
  const handleShowEdit = (setTo: boolean) => setShowEdit(setTo);
  const handleEdit = () => {
    if (showEdit && !areSupplierFields) setEdit(true);
  };

  return (
    <div
      onMouseEnter={() => handleShowEdit(true)}
      onMouseLeave={() => handleShowEdit(false)}
      onClick={handleEdit}
      style={{ width: '100%' }}>
      <div className={className}>
        <EditIcon fontSize="inherit" className={classes.editIcon} />

        {noValue ? '...' : <QuickUserFromId UserInstanceId={value} />}
      </div>
    </div>
  );
};
