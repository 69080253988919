import Calculator from 'components/Calculator/Calculator';
import { Dashboard as DashboardLayout } from 'layouts';

const CalculatorView = () => {
  return (
    <DashboardLayout title="Calculator">
      <Calculator />
    </DashboardLayout>
  );
};

export default CalculatorView;
