import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Time from 'common/Time';
import { makeStyles } from '@material-ui/core';
import Xpansion from 'components/Xpansion';

const useStyles = makeStyles((theme) => ({
  item: {
    position: 'relative',
    margin: 1
  },
  time: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: 'black',
    fontSize: 10
  }
}));

const OverlayExpansion = ({ notifications, onClose }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (notifications?.length > 0) setOpen(true);
  }, [notifications]);

  const executionLogDetails = () => (
    <>
      {notifications
        ?.filter((i) => !i.event.includes('Relationship'))
        ?.sort((a, b) => a.timestamp - b.timestamp)
        .map((notification, idx) => {
          switch (notification.type) {
            case 'FAILED':
              return (
                <div className={classes.item} key={idx}>
                  <Panel
                    severity="error"
                    notification={notification}
                    classes={classes}
                  />
                </div>
              );
            case 'SUCCESS':
              return (
                <div className={classes.item} key={idx}>
                  <Panel
                    severity="success"
                    notification={notification}
                    classes={classes}
                  />
                </div>
              );
            default:
              return (
                <div className={classes.item} key={idx}>
                  <Panel
                    severity="warning"
                    notification={notification}
                    classes={classes}
                  />
                </div>
              );
          }
        })}

      {notifications?.filter(
        (i) => i.event.includes('Relationship') && i.type === 'SUCCESS'
      )?.length > 0 && (
        <div className={classes.item}>
          <Panel
            severity="success"
            notification={{
              event: 'Relationships Creation',
              type: 'SUCCESS',
              message: `${
                notifications?.filter(
                  (i) =>
                    i.event.includes('Relationship') && i.type === 'SUCCESS'
                )?.length
              } relationships Successfully created`
            }}
            classes={classes}
          />
        </div>
      )}

      {notifications?.filter(
        (i) => i.event.includes('Relationship') && i.type === 'FAILED'
      )?.length > 0 && (
        <div className={classes.item}>
          <Panel
            severity="error"
            notification={{
              event: 'Relationships Creation',
              type: 'FAILED',
              message: `${
                notifications?.filter(
                  (i) => i.event.includes('Relationship') && i.type === 'FAILED'
                )?.length
              } relationships failed to create`
            }}
            classes={classes}
          />
        </div>
      )}
    </>
  );

  return (
    <Xpansion
      summary={'Execution Log Detail'}
      defaultExpanded={false}
      selected={undefined}
      icon={undefined}
      style={undefined}
      className={undefined}>
      {executionLogDetails()}
    </Xpansion>
  );
};

export default OverlayExpansion;

const Panel = ({ severity, notification, classes }) => (
  <Alert severity={severity} variant="filled" style={{ borderRadius: 10 }}>
    <AlertTitle>{notification?.event}</AlertTitle>
    <Typography>{notification?.message}</Typography>
    <Typography variant="body2">[ {notification?.type} ]</Typography>
    {notification?.timestamp && (
      <div className={classes.time}>
        <Time time={notification?.timestamp} type="mymaskC" />
      </div>
    )}
  </Alert>
);
