import { gql } from '@apollo/client';
import { CompleteObjectInstance, UserInstance } from 'types/interfaces';

export const UPDATE_OBJECT = gql`
  mutation UpdateObject(
    $baseUrl: String
    $data: CompleteObjectInstanceInput
    $processInstanceId: Int
    $action: String
  ) {
    UpdateObject(
      baseURL: $baseUrl
      data: $data
      ProcessInstanceId: $processInstanceId
      action: $action
    ) {
      ObjectInstance {
        Id
        IsPublished
        ItemOrder
        LastModified
        ObjectDefinitionId
        ObjectDescription
        ObjectProcessIndependent
        ObjectRepeat
        ProcessInstanceId
        Selected
        Title
        UserDefinitionId
        UserInstanceId
      }
      FieldInstanceList {
        FieldDefinitionId
        FieldValue
        Id
        IsPublished
        ItemOrder
        LastModified
        ObjectDefinitionId
        ObjectInstanceId
        ProcessInstanceId
        Title
        UserDefinitionId
        UserInstanceId
      }
    }
  }
`;

export const UPDATE_FIELD_INSTANCE = gql`
  mutation UpdateFieldInstance(
    $baseUrl: String
    $data: FieldInstanceInput
    $processInstanceId: Int
  ) {
    UpdateField(
      baseURL: $baseUrl
      data: $data
      ProcessInstanceId: $processInstanceId
    ) {
      Id
      Title
      ItemOrder
      LastModified
      FieldDefinitionId
      ObjectDefinitionId
      ObjectInstanceId
      ProcessInstanceId
      UserInstanceId
      UserDefinitionId
      FieldValue
    }
  }
`;

/**
 * Updates userDetails and associated CompleteObjectInstances.
 * Previously known as `postData` mutation.
 * @param {String} baseUrl - The base URL for the Hostname.
 * @param {CompleteUserInstanceInput} data - The data for the user instance to be updated.
 * interface CompleteUserInstanceInput {
 *  UserInstance: UserInstance
 *  CompleteObjectInstanceList: CompleteObjectInstance[]
 * }
 * @param {Int} processInstanceId - Identifier for the process context of the update.
 */
export const UPDATE_USER = gql`
  mutation UpdateUser(
    $baseUrl: String
    $data: CompleteUserInstanceInput
    $processInstanceId: Int
  ) {
    UpdateUser(
      baseURL: $baseUrl
      data: $data
      ProcessInstanceId: $processInstanceId
    ) {
      UserInstance {
        ConsoleUserInstanceId
        DefaultMessage
        DocumentFooter
        DocumentHeader
        EmailFooter
        FromAddress
        FromName
        Id
        IsPublished
        ItemOrder
        LastModified
        LogoURL
        NickName
        OwnerUserInstanceId
        ProfilePicture
        ProxyUserInstanceId
        RelatedUserPermission
        RelationshipStatus
        SmtpHost
        SmtpPassword
        SmtpPort
        SmtpSSL
        SmtpUsername
        SystemAccess
        TelephoneNumber
        ThirdPartyId
        Title
        UserDefinitionId
        UserInstanceEmail
        UserInstancePassword
      }
      CompleteObjectInstanceList {
        ObjectInstance {
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectProcessIndependent
          ObjectDescription
          Selected
          ObjectRepeat
          ProcessInstanceId
          Title
          UserDefinitionId
          UserInstanceId
        }
        FieldInstanceList {
          FieldDefinitionId
          FieldValue
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectInstanceId
          ProcessInstanceId
          Title
          UserDefinitionId
          UserInstanceId
        }
      }
    }
  }
`;
