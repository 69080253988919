import TimeAgo from 'react-timeago';
import { format } from 'fecha';

interface TimeProps {
  time: Date | number | any;
  type: 'date' | 'timeago' | 'iso' | 'mymask' | 'mymaskB' | 'mymaskC';
  style?: any;
}

const Time = ({ time, type, style }: TimeProps) => {
  const isTimestamp = time && time.seconds;
  if (isTimestamp) {
    const { seconds } = time;
    switch (type) {
      case 'date':
        return (
          <div>{new Date(seconds * 1000).toLocaleDateString('en-gb')}</div>
        );
      case 'timeago':
        return <TimeAgo date={new Date(seconds * 1000)} style={style} />;
      default:
        return <div style={{ color: 'green' }}>NEW</div>;
    }
  } else {
    switch (type) {
      case 'date':
        return <div>{new Date(time).toLocaleDateString('en-gb')}</div>;
      case 'iso':
        return <div>{format(time, 'mediumDate')}</div>;
      case 'mymask':
        return <div>{format(time, 'D MMM YYYY at HH:mm')}</div>;
      case 'mymaskB':
        return <div>{format(time, 'D MMM YYYY, HH:mm')}</div>;
      case 'mymaskC':
        return <div>{format(time, 'D MMM YYYY, HH:mm:ss')}</div>;
      default:
        return <TimeAgo date={time} style={style} />;
    }
  }
};

export default Time;
