import { SetStateAction, useContext, useEffect } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  ButtonGroup,
  Box,
  Tooltip
} from '@material-ui/core';
import { Calculation } from 'types/calculatorInterfaces';
import { Alert } from '@material-ui/lab';
import { theme } from 'theme';

import MyImportedAssetsDialog from './components/MyImportedAssetsDialog';
import {
  CalculatorStorageContext,
  CalculatorStorageProvider
} from './context/CalculatorContext';
import MyCalculationsDialog from './components/MyCalculationsDialog';
import { SaveLabels, Tools } from '../Calculator/interfaces';

export const ParentCalculationStore = ({
  state,
  quoteId,
  tools,
  setState
}: {
  state: Calculation;
  tools: Tools;
  setState: React.Dispatch<SetStateAction<Calculation>>;
  quoteId: number;
}) => {
  return (
    <CalculatorStorageProvider>
      <CalculationStore
        state={state}
        tools={tools}
        quoteId={quoteId}
        setState={setState}
      />
    </CalculatorStorageProvider>
  );
};

const CalculationStore = ({
  state,
  quoteId,
  tools,
  setState
}: {
  state: Calculation;
  setState: React.Dispatch<SetStateAction<Calculation>>;
  tools: Tools;
  quoteId: number;
}) => {
  const {
    getMyCalculations,
    getAutosaves,
    loadCalculation,
    setDialogData,
    setTempAutosave,
    toggleAutosave,
    openAutosaveDialog,
    tempAutosave,
    toggleMyCalculations,
    setOpenMyAssets
  } = useContext(CalculatorStorageContext);

  const checkForAutosave = async () => {
    const autosaves = await getAutosaves();

    const { Autosave, Standalone } = SaveLabels;

    if (autosaves) {
      const autoSaveKey = `${Autosave} ${quoteId ? quoteId : Standalone}`;
      if (autosaves[autoSaveKey]) {
        setTempAutosave(autosaves[autoSaveKey]);
        toggleAutosave();
      }
    }
  };

  useEffect(() => {
    if (window.disableCalcAutosave) return;
    checkForAutosave();
  }, []);

  const classes = useStyles(open);

  return (
    <div>
      <Dialog
        open={openAutosaveDialog}
        onClose={toggleAutosave}
        data-cy={openAutosaveDialog ? 'dialog-open' : 'dialog-closed'}>
        <Alert severity="warning" style={{ marginBottom: '20px' }}>
          <Typography style={{ fontWeight: 'bold' }}>
            We Found An Autosaved Version Of Your Work!
          </Typography>
        </Alert>
        <DialogContent>
          <DialogContentText>
            You have a saved version that you created earlier. You can continue
            where you left off or create a new calculation.
          </DialogContentText>
          <DialogActions>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <ButtonGroup variant="contained" color="primary">
                <Tooltip title="Discard The Autosave And Start A New Calculation">
                  <Button
                    data-cy="create-new-calculation"
                    onClick={toggleAutosave}>
                    Close
                  </Button>
                </Tooltip>
                <Tooltip title="Load The Autosaved Calculation And Continue Where You Left Off">
                  <Button
                    onClick={() => {
                      if (tempAutosave) loadCalculation(tempAutosave, '');
                      toggleAutosave();
                    }}
                    autoFocus>
                    Load Autosave
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Grid
        container
        justifyContent="flex-start"
        style={{ backgroundColor: 'transparent' }}>
        <Grid
          item
          style={{
            padding: theme.spacing(1),
            width: '20%',
            cursor: 'pointer',
            borderEndStartRadius: 0,
            borderEndEndRadius: 0,
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: theme.spacing(1)
          }}
          className={classes.menuContainer}
          component={Button}
          onClick={async () => {
            const updatedCalculations = await getMyCalculations();
            setDialogData(updatedCalculations);

            if (updatedCalculations) toggleMyCalculations();
          }}>
          <Typography
            style={{
              textTransform: 'none',
              color: theme.palette.grey[600]
            }}>
            My Calculations
          </Typography>
        </Grid>
        {state.Assets && state.Assets?.length > 0 && (
          <>
            <div style={{ paddingLeft: theme.spacing(1) }} />
            <Grid
              item
              style={{
                padding: theme.spacing(1),
                width: '20%',
                cursor: 'pointer',
                borderEndStartRadius: 0,
                borderEndEndRadius: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                paddingLeft: theme.spacing(1)
              }}
              className={classes.menuContainer}
              component={Button}
              onClick={() => setOpenMyAssets(true)}>
              <Typography
                style={{
                  textTransform: 'none',
                  color: theme.palette.grey[600]
                }}>
                My Imported Assets
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
      <MyCalculationsDialog state={state} quoteId={quoteId} tools={tools} />
      <MyImportedAssetsDialog
        state={state}
        setState={setState}
        quoteId={quoteId}
      />
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      backgroundColor: theme.palette.grey[300],
      borderStartEndRadius: theme.shape.borderRadius,
      borderStartStartRadius: theme.shape.borderRadius
    },
    storeContainer: {
      backgroundColor: theme.palette.grey[400],
      height: (props) => (props ? '100px' : 0),
      transition: 'all 0.5s ease'
    },
    storeItem: {
      transition: 'all 0.5s ease',
      margin: 8,
      height: (props) => (props ? 84 : 0),
      width: 100,
      borderRadius: theme.shape.borderRadius
    },
    storeItemPaper: {
      padding: 8,
      height: '100%',
      width: 100,
      background: theme.palette.grey[300],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

export const format = (arg: string) => {
  return Number(arg).toLocaleString('en', {
    minimumFractionDigits: 2
  });
};
