import { DialogTitle, IconButton, Typography, Paper } from '@material-ui/core';
import { useContext, useState } from 'react';
import { MessageHubContext } from '../../../context/MessageHubContext';
import {
  Close as CloseIcon,
  Maximize,
  Minimize as MinimizeIcon,
  ZoomOutMap as ZoomOutMapIcon
} from '@material-ui/icons';
import { grey } from '@material-ui/core/colors';
import './my-ckeditor-style.css';
import Email from './Email';
import { Message } from '@microsoft/microsoft-graph-types';
import { theme } from 'theme';
import { EmailCompositionCreationContext } from '../context/ComposeWindowsContext';

type CustomStyles = React.CSSProperties;
const ComposeEmail = ({
  index,
  totalOffset,
  isMinimized,
  setIsMinimized,
  removeComposeEmail
}: {
  index: number;
  totalOffset: number;
  isMinimized: boolean;
  setIsMinimized: (isMinimized: boolean) => void;
  removeComposeEmail: (index: number) => void;
}) => {
  const { drafts } = useContext(MessageHubContext);
  const { closeComposeEmail, composeWindows, allEmailBodies, saveDraft } =
    useContext(EmailCompositionCreationContext);

  const [isExpanded, setIsExpanded] = useState(false);
  const [wasExpandedBeforeMinimize, setWasExpandedBeforeMinimize] =
    useState(false);

  const expandedStyles: CustomStyles = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    overflow: 'scroll',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.4)',
    margin: theme.spacing(5)
  };

  const defaultStyles: CustomStyles = {
    position: 'fixed',
    right: totalOffset,
    zIndex: 10,
    bottom: 0,
    overflow: 'scroll',
    width: isMinimized ? 300 : 600,
    height: isMinimized ? 50 : 560,
    margin: 0,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0'
  };

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMinimizeMaximize = () => {
    if (!isMinimized) {
      setWasExpandedBeforeMinimize(isExpanded);
      setIsExpanded(false);
    } else {
      setIsExpanded(wasExpandedBeforeMinimize);
    }

    setIsMinimized(!isMinimized);
  };

  const isEquivalentDraftExists = (draft: Message) => {
    return (
      drafts &&
      drafts.value.some((existingDraft) => {
        const draftToEmails = draft.toRecipients
          ? draft.toRecipients.map(
              (recipient) => recipient?.emailAddress?.address
            )
          : [];
        const draftCcEmails = draft.ccRecipients
          ? draft.ccRecipients.map(
              (recipient) => recipient?.emailAddress?.address
            )
          : [];
        const draftBccEmails = draft.bccRecipients
          ? draft.bccRecipients.map(
              (recipient) => recipient?.emailAddress?.address
            )
          : [];

        return (
          existingDraft.subject === draft.subject &&
          existingDraft?.body?.content === draft?.body?.content &&
          JSON.stringify(
            (existingDraft &&
              existingDraft.toRecipients &&
              existingDraft.toRecipients.map(
                (recipient) => recipient?.emailAddress?.address
              )) ||
              []
          ) === JSON.stringify(draftToEmails) &&
          JSON.stringify(
            (existingDraft &&
              existingDraft.ccRecipients &&
              existingDraft.ccRecipients.map(
                (recipient) => recipient?.emailAddress?.address
              )) ||
              []
          ) === JSON.stringify(draftCcEmails) &&
          JSON.stringify(
            (existingDraft &&
              existingDraft.bccRecipients &&
              existingDraft.bccRecipients.map(
                (recipient) => recipient?.emailAddress?.address
              )) ||
              []
          ) === JSON.stringify(draftBccEmails)
        );
      })
    );
  };

  return (
    <Paper style={isExpanded ? expandedStyles : defaultStyles}>
      <DialogTitle style={{ backgroundColor: grey[100] }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography variant="h6">
            {allEmailBodies[index].subject
              ? allEmailBodies[index].subject
              : 'New Message'}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton size="small" onClick={handleMinimizeMaximize}>
              {isMinimized ? <Maximize /> : <MinimizeIcon />}
            </IconButton>
            <IconButton onClick={toggleExpanded} size="small">
              <ZoomOutMapIcon />
            </IconButton>
            <IconButton
              size="small"
              onClick={(e) => {
                const fullEmailBody = allEmailBodies[index];
                const draftEmail: Message = {
                  subject: fullEmailBody.subject,
                  body: {
                    contentType: 'html',
                    content: fullEmailBody.body
                  },
                  toRecipients: fullEmailBody.to
                    ? fullEmailBody.to.map((email) => ({
                        emailAddress: { address: email }
                      }))
                    : [],
                  ccRecipients: fullEmailBody.cc
                    ? fullEmailBody.cc.map((email) => ({
                        emailAddress: { address: email }
                      }))
                    : [],
                  bccRecipients: fullEmailBody.bcc
                    ? fullEmailBody.bcc.map((email) => ({
                        emailAddress: { address: email }
                      }))
                    : []
                };

                if (
                  fullEmailBody.subject !== '' &&
                  fullEmailBody.to.length > 0 &&
                  !isEquivalentDraftExists(draftEmail)
                ) {
                  saveDraft({ draft: draftEmail });
                } else {
                  const windowIdToClose = composeWindows[index].id;
                  closeComposeEmail(windowIdToClose);
                }

                removeComposeEmail(index);
                setIsMinimized(false);
              }}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      {!isMinimized && <Email panelIndex={index} isExpanded={isExpanded} />}
    </Paper>
  );
};

export default ComposeEmail;
