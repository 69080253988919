import { firebase } from 'redux/firebase';
import { ISender } from '../types';
import { BugTracker } from 'Utils/Bugtracker';

interface FetchSenders {
  isAFS: boolean;
  isSynergy: boolean;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  userInstanceId: number;
}

export const fetchSenders = async ({
  isAFS,
  isSynergy,
  isAdmin,
  isSuperAdmin,
  userInstanceId
}: FetchSenders): Promise<ISender[]> => {
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  const global = 'global';

  try {
    const ref: firebase.firestore.Query = firebase
      .firestore()
      .collection('sender');

    const senders: ISender[] = [];

    const snapshot = await ref.get();
    snapshot.forEach((doc) => senders.push(doc.data() as ISender));

    // Filter for global senders specific to AFS or Synergy
    return senders.filter((sender) => {
      // return all senders
      if (isSuperAdmin) return true;

      if (sender.hostname === global) {
        // AFS global sender for all AFS instances
        if (isAFS && sender.from_email.includes('afs')) return true;
        // Synergy global sender for all Synergy instances
        if (isSynergy && sender.from_email.includes('synergy')) return true;
      }

      if (isAdmin) {
        // return all senders for this current instance
        if (sender.hostname === hostname) return true;
      }

      // For regular users and admins, also return senders they have created:
      return sender.userInstanceId === userInstanceId;
    });
  } catch (e) {
    BugTracker.notify(e);
    return [];
  }
};
