import { IEvent, IGuest } from '../interface';
import { FileAttachment } from '@microsoft/microsoft-graph-types';
import * as gtag from 'Utils/gtag';
import createICalendarContent from './ICS';
import { validateEmail } from 'helpers/validateEmail';

const sendEventEmail = async ({
  accessToken,
  event
}: {
  accessToken: string;
  event: IEvent;
}) => {
  if (!accessToken) return;

  const attachments: FileAttachment[] = [];
  const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) binary += String.fromCharCode(bytes[i]);
    return window.btoa(binary);
  };

  const stringToArrayBuffer = (str: string): ArrayBuffer => {
    const encoder = new TextEncoder();
    return encoder.encode(str).buffer;
  };

  const calendarContent = createICalendarContent(event);
  const calendarBase64 = arrayBufferToBase64(
    stringToArrayBuffer(calendarContent)
  );

  const calendarAttachment = {
    '@odata.type': '#microsoft.graph.fileAttachment',
    name: `${event.title}.ics`,
    contentType: 'text/calendar',
    contentBytes: calendarBase64
  };

  attachments.push(calendarAttachment);
  const message = {
    subject: event.title,
    body: {
      contentType: 'HTML',
      content:
        !event.extendedProps.description && event.extendedProps.sequence >= 2
          ? `The Event That You Were Part Of Has Changed. Here Is The Updated Event`
          : `You Have Been Invited To ${event.title}`
    },
    toRecipients: event.extendedProps.guests
      .filter((UserInstance: IGuest) =>
        validateEmail(UserInstance.UserInstanceEmail)
      )
      .map((UserInstance: IGuest) => ({
        emailAddress: {
          address: UserInstance.UserInstanceEmail
        }
      })),
    ccRecipients: [],
    bccRecipients: [],
    attachments
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ message })
  };

  fetch('https://graph.microsoft.com/v1.0/me/sendMail', requestOptions).then(
    async (response) => {
      if (!response.ok) {
        const errorData = await response.json();
        return;
      }

      gtag.event({
        feature: 'Calendar',
        action: 'Email Sent',
        message: 'Email Sent Successfully'
      });
    }
  );
};

export default sendEventEmail;
