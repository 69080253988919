export interface ISender {
  nickname: string;
  from_email: string;
  from_name: string;
  reply_to: string;
  reply_to_name: string;
  address: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  hostname?: string;
  verified?: boolean;
  id: number;
  key: string;
  userInstanceId: number;
}

export const SenderDetails = {
  nickname: {
    title: 'Nickname',
    required: true,
    hidden: false
  },
  from_email: {
    title: 'From Email',
    required: true,
    hidden: false
  },
  reply_to: {
    title: 'Reply To Email',
    required: true,
    hidden: false
  },
  from_name: {
    title: 'From Name',
    required: true,
    hidden: false
  },
  reply_to_name: {
    title: 'Reply To Name',
    required: false,
    hidden: false
  },
  address: {
    title: 'Address',
    required: true,
    hidden: false
  },
  city: {
    title: 'City',
    required: true,
    hidden: false
  },
  country: {
    title: 'Country',
    required: true,
    hidden: false
  },
  zip: {
    title: 'Postcode',
    required: false,
    hidden: false
  },
  // thses hidden are hidden in the Create / Edit sender form
  verified: {
    title: 'Verified',
    required: false,
    hidden: true
  },
  state: {
    title: 'State',
    required: false,
    hidden: true
  },
  address2: {
    title: 'Address 2',
    required: false,
    hidden: true
  },
  hostname: {
    title: 'Host Name',
    required: false,
    hidden: true
  },
  locked: {
    title: 'Locked',
    required: false,
    hidden: true
  },
  id: {
    title: 'Id',
    required: false,
    hidden: true
  },
  key: {
    title: 'Key',
    required: false,
    hidden: true
  },
  linked: {
    title: 'linked',
    required: false,
    hidden: true
  }
};

export const SENDER = Object.keys(SenderDetails).reduce((acc, key) => {
  acc[key] = '';
  return acc;
}, {}) as ISender;
