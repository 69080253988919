import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import axios from 'axios';
import { useToken } from 'components/MessageHub/hooks/useToken';
import firebase from 'firebase';
import useFileStorage from 'hooks/useFileStorage';
import { createContext, useContext, useState } from 'react';
import { FbFileRef } from 'types/interfaces';
import { CalendarContext } from './CalendarContext';
import { mapEventData } from '../functions';
import { ERecurringEvent, TEventType } from '../interface';

interface IEventContextType {
  handleAttachmentChange: ({ item }: { item: FbFileRef }) => Promise<void>;
  handleRecurringChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDateChange: (name: string) => (date: MaterialUiPickersDate) => void;
  handleSelectChange: (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => void;
  handleAttachmentViewerChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    userId: string,
    attachmentIndex: number
  ) => void;
  handleDownload: (e: React.MouseEvent, file: FbFileRef) => void;
  handleGenerateTeamsLink: (info: any) => void;
  fetchStatusEvents: (eventId: string) => void;
  handleCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const defaultContext: IEventContextType = {
  handleAttachmentChange: () => new Promise(() => {}),
  handleRecurringChange: () => {},
  handleInputChange: () => {},
  handleDateChange: () => () => {},
  handleSelectChange: () => {},
  handleAttachmentViewerChange: () => {},
  handleDownload: () => {},
  handleGenerateTeamsLink: () => {},
  fetchStatusEvents: () => {},
  handleCheckboxChange: () => {}
};

export const EventContext = createContext<IEventContextType>(defaultContext);

export const CalendarEventProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const { instance, inProgress, accounts } = useMsal();
  const { downloadFile } = useFileStorage();
  const isAuth = useIsAuthenticated();

  const {
    formValues,
    setFormValues,
    setAllEvents,
    allEvents,
    setEventDialogOpen,
    eventDialogOpen
  } = useContext(CalendarContext);

  const handleAttachmentChange = async ({ item }: { item: FbFileRef }) => {
    console.log({ item });
    const guestsViewDocuments = {
      ...item
    };

    setFormValues({
      ...formValues,
      attachments: [...formValues.attachments, item]
    });
  };

  const handleRecurringChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setFormValues({
      ...formValues,
      recurring: event.target.value as ERecurringEvent
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value
    });
  };

  const handleDateChange = (name: string) => (date: MaterialUiPickersDate) => {
    if (date) {
      setFormValues({
        ...formValues,
        [name]: date.toDate()
      });
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({
      ...formValues,
      notification: event.target.checked
    });
  };

  const handleSelectChange = (
    event: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setFormValues({
      ...formValues,
      eventType: event.target.value as TEventType
    });
  };

  const handleAttachmentViewerChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    userId: string,
    attachmentIndex: number
  ) => {
    const updatedAttachments = [...formValues.attachments];

    if (updatedAttachments[attachmentIndex].users.includes(userId)) {
      if (!e.target.checked) {
        updatedAttachments[attachmentIndex].users = updatedAttachments[
          attachmentIndex
        ].users.filter((id) => id !== userId);
      }
    } else {
      if (e.target.checked) {
        updatedAttachments[attachmentIndex].users.push(userId);
      }
    }

    setFormValues({
      ...formValues,
      attachments: updatedAttachments
    });
  };

  const handleDownload = async (e: React.MouseEvent, file: FbFileRef) => {
    e.stopPropagation();
    await downloadFile({
      ProcessInstanceId: 0,
      item: file,
      isSelecting: false
    });
  };

  const handleGenerateTeamsLink = async (info) => {
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });

    if (accessToken) {
      const meetingLink = await createTeamsMeeting(accessToken);

      console.log({ meetingLink });
      const newEvent = {
        title: 'Click t o Join Teams Meeting',
        start: info.dateStr,
        url: meetingLink
      };

      setFormValues({ ...formValues, teams: newEvent });
    }
  };

  const fetchStatusEvents = async (eventId: string) => {
    const eventsRef = firebase.firestore().collection('calendar');

    if (eventId) {
      const singleEventSnapshot = await eventsRef.doc(eventId).get();
      const updatedEvent = mapEventData(singleEventSnapshot);
      const updatedEvents = allEvents.map((event) =>
        event.id === eventId ? updatedEvent : event
      );

      setAllEvents(updatedEvents);
      setEventDialogOpen({
        ...eventDialogOpen,
        event: updatedEvent,
        open: true
      });
    }
  };

  const value = {
    handleSelectChange,
    handleDateChange,
    handleInputChange,
    handleRecurringChange,
    handleAttachmentChange,
    handleAttachmentViewerChange,
    handleDownload,
    handleGenerateTeamsLink,
    fetchStatusEvents,
    handleCheckboxChange
  };

  return (
    <EventContext.Provider value={value}>{children}</EventContext.Provider>
  );
};

const createTeamsMeeting = async (token) => {
  if (!token) return;

  const endpoint = 'https://graph.microsoft.com/v1.0/me/onlineMeetings';
  const meetingDetails = {
    subject: 'Your meeting subject'
  };

  try {
    const response = await axios.post(endpoint, meetingDetails, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return response.data.joinWebUrl;
  } catch (error) {
    console.error('Error creating Teams meeting:', error);
  }
};
