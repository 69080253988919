import { ELender, IProposalStructure } from '../../interface';
import {
  Aldermore,
  CAS,
  DLL,
  Fleximize,
  FundingCircle,
  Investec,
  MannIsland,
  Northridge,
  Novuna,
  Praetura,
  PremiumCredit,
  UltimateFinance,

  // Ids
  getAldermoreIds,
  getFleximizeIds,
  getFundingCircleIds,
  getInvestecIds,
  getCASIds,
  getUltimateFinanceIds
} from '../../consts/index';
import { getNorthridgeIds } from '../../consts/Northridge/MappedProposal';
import { getPraeturaIds } from '../../consts/Praetura/MappedProposal';

type TLenderNameToId = {
  [key in ELender]: number[];
};

const lenderNameToId: TLenderNameToId = {
  [ELender.Aldermore]: [28],
  [ELender.CAS]: [73],
  [ELender.Fleximize]: [421, 92],
  [ELender.FundingCircle]: [8035, 26],
  [ELender.Investec]: [111],
  [ELender.MannIsland]: [0],
  [ELender.Northridge]: [130],
  [ELender.Novuna]: [0],
  [ELender.Praetura]: [154],
  [ELender.PremiumCredit]: [8745, 157],
  [ELender.DLL]: [0],
  [ELender.Hitachi]: [0],
  [ELender.MetroBank]: [0],
  [ELender.Paragon]: [0],
  [ELender.Shawbrook]: [0],
  [ELender.Quantum]: [160],
  [ELender.UltimateFinance]: [176]
};

export const proposalConfig: Record<number, IProposalStructure> = {
  28: {
    defaultValues: Aldermore,
    lenderTitle: ELender.Aldermore,
    mappedIds: getAldermoreIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 1
    },
    allowedDealFlow: [466]
  },

  //! Development For Aldermore
  // 389: {
  //   defaultValues: Aldermore,
  //   lenderTitle: ELender.Aldermore,
  //   mappedIds: getAldermoreIds(),
  //   status: true,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 1
  //   },
  //   allowedDealFlow: [466]
  // },
  //! END

  73: {
    defaultValues: CAS,
    lenderTitle: ELender.CAS,
    mappedIds: getCASIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466, 474]
  },

  421: {
    defaultValues: Fleximize,
    lenderTitle: ELender.Fleximize,
    mappedIds: getFleximizeIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 0
    },
    allowedDealFlow: [534, 535],
    allowedEntities: ['Limited Company']
  },
  92: {
    defaultValues: Fleximize,
    lenderTitle: ELender.Fleximize,
    mappedIds: getFleximizeIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 0
    },
    allowedDealFlow: [474],
    allowedEntities: ['Limited Company']
  },

  // 8035: {
  //   defaultValues: FundingCircle,
  //   lenderTitle: ELender.FundingCircle,
  //   mappedIds: getFundingCircleIds(),
  //   status: true,
  //   uploadDocuments: true,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [534, 535]
  // },
  // 26: {
  //   defaultValues: FundingCircle,
  //   lenderTitle: ELender.FundingCircle,
  //   mappedIds: getFundingCircleIds(),
  //   status: true,
  //   uploadDocuments: true,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [474]
  // },

  111: {
    defaultValues: Investec,
    lenderTitle: ELender.Investec,
    mappedIds: getInvestecIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 1
    },
    allowedDealFlow: [466, 474],
    fieldVisibility: [
      {
        field: 'client.soleTrader',
        dependsOn: 'client.companyType',
        showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
      },

      {
        field: 'client.company.partner',
        dependsOn: 'client.companyType',
        showWhen: ['PARTNERSHIP']
      },
      {
        field: 'client.company.director',
        dependsOn: 'client.companyType',
        showWhen: ['LIMITEDCOMPANY']
      }
    ]
  },

  160: {
    defaultValues: Investec,
    lenderTitle: ELender.Quantum,
    mappedIds: getInvestecIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 1
    },
    allowedDealFlow: [466],
    fieldVisibility: [
      {
        field: 'client.soleTrader',
        dependsOn: 'client.companyType',
        showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
      },

      {
        field: 'client.company.partner',
        dependsOn: 'client.companyType',
        showWhen: ['PARTNERSHIP']
      },
      {
        field: 'client.company.director',
        dependsOn: 'client.companyType',
        showWhen: ['LIMITEDCOMPANY']
      }
    ]
  },

  //! Development For Investec
  134378: {
    defaultValues: Investec,
    lenderTitle: ELender.Investec,
    mappedIds: getInvestecIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 1,
      minShareholders: 1,
      minAssets: 1
    },
    allowedDealFlow: [466, 474],
    fieldVisibility: [
      {
        field: 'client.soleTrader',
        dependsOn: 'client.companyType',
        showWhen: ['SOLETRADER', 'PRIVATEINDIVIDUAL']
      },

      {
        field: 'client.company.partner',
        dependsOn: 'client.companyType',
        showWhen: ['PARTNERSHIP']
      },
      {
        field: 'client.company.director',
        dependsOn: 'client.companyType',
        showWhen: ['LIMITEDCOMPANY']
      }
    ]
  },
  //! END

  130: {
    defaultValues: Northridge,
    lenderTitle: ELender.Northridge,
    mappedIds: getNorthridgeIds(),
    status: true,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466]
  },

  //! Development For Northridge
  // 134378: {
  //   defaultValues: Northridge,
  //   lenderTitle: ELender.Northridge,
  //   mappedIds: getNorthridgeIds(),
  //   status: true,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466]
  // },
  //! END

  // 7965: {
  //   defaultValues: Novuna,
  //   lenderTitle: ELender.Novuna,
  //   mappedIds: {},
  //   status: true,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466]
  // },

  154: {
    defaultValues: Praetura,
    lenderTitle: ELender.Praetura,
    mappedIds: getPraeturaIds(),
    status: true,
    uploadDocuments: true,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466],
    allowedEntities: [
      'Limited Company',
      'Sole Trader',
      'Partnership',
      'Limited Liability Partnership'
    ]
  },

  //! Development For Praetura
  // 134378: {
  //  defaultValues: Praetura,
  //  lenderTitle: ELender.Praetura,
  //  mappedIds: getPraeturaIds(),
  //  status: true,
  //  uploadDocuments: true,
  //  requirements: {
  //    minDirectors: 0,
  //    minShareholders: 0,
  //    minAssets: 0
  //  },
  //  allowedDealFlow: [466],
  //  allowedEntities: [
  //    'Limited Company',
  //    'Sole Trader',
  //    'Partnership',
  //    'Limited Liability Partnership'
  //  ]
  // },
  //! END

  // 8745: {
  //   defaultValues: PremiumCredit,
  //   lenderTitle: ELender.PremiumCredit,
  //   mappedIds: {},
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [534, 535]
  // },
  // 157: {
  //   defaultValues: PremiumCredit,
  //   lenderTitle: ELender.PremiumCredit,
  //   mappedIds: {},
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466, 474]
  // },

  176: {
    defaultValues: UltimateFinance,
    lenderTitle: ELender.UltimateFinance,
    mappedIds: getUltimateFinanceIds(),
    status: false,
    uploadDocuments: false,
    requirements: {
      minDirectors: 0,
      minShareholders: 0,
      minAssets: 0
    },
    allowedDealFlow: [466, 474]
  }

  //! Development For UltimateFinance
  // 134378: {
  //   defaultValues: UltimateFinance,
  //   lenderTitle: ELender.UltimateFinance,
  //   mappedIds: getUltimateFinanceIds(),
  //   status: false,
  //   uploadDocuments: false,
  //   requirements: {
  //     minDirectors: 0,
  //     minShareholders: 0,
  //     minAssets: 0
  //   },
  //   allowedDealFlow: [466, 474]
  // }
  //! END
};

/**
 * Retrieves the proposal structure for a given lender ID or name.
 *
 * @param {number | ELender} lenderIdOrName - The numeric ID or enum name of the lender to match.
 * @returns {IProposalStructure | undefined} The matching proposal structure or undefined if not found.
 *
 * @example
 * Returns the proposal structure for FundingCircle by ID
 * const structure = getLenderProposalStructure(26);
 *
 * @example
 * Returns the proposal structure for FundingCircle by name
 * const structure = getLenderProposalStructure(ELender.FundingCircle);
 */
export const getLenderProposalStructure = (
  lenderId: number,
  lenderName: ELender
): IProposalStructure | undefined => {
  const lenderIds = lenderNameToId[lenderName];

  if (!lenderIds.includes(lenderId)) return undefined;
  const structure = proposalConfig[lenderId];

  if (!structure) return undefined;
  return structure;
};

/**
 * Retrieves the proposal structure for a given lender ID, with visibility controlled by environment.
 *
 * @param {number} lenderId - The numeric ID of the lender to match.
 * @returns {IProposalStructure | undefined} The matching proposal structure or undefined if not found or hidden in the current environment.
 *
 * @description
 * This function handles the visibility of proposal structures differently based on the current environment:
 * - In production (REACT_APP_FB_PROJECTID === 'financial--manager'):
 *   - Hides structures for lender IDs [28, 389, 421, 92, 137006].
 *   - Shows all other structures.
 * - In staging (REACT_APP_FB_PROJECTID === 'finbro-tech'):
 *   - Hides structures for lender IDs [28, 389].
 *   - Shows all other structures.
 * - In local or other environments:
 *   - Shows all structures.
 *
 * @example
 * Returns the proposal structure for a lender with ID 26 (visible in all environments)
 * const structure = getProposalStructureById(26);
 *
 * @example
 * Returns undefined in production, but may return a structure in staging or local environments
 * const hiddenStructure = getProposalStructureById(28);
 */
export const getProposalStructureById = (
  lenderId: number
): IProposalStructure | undefined => {
  const ProductionHiddenIds = [
    176, 111, 160, 28, 389, 421, 92, 157, 8745, 154, 7965, 130, 73, 134378
  ];
  const StagingHiddenIds = [0];

  const isProduction =
    process.env.REACT_APP_FB_PROJECTID === 'financial--manager';
  const isStaging = process.env.REACT_APP_FB_PROJECTID === 'finbro-tech';

  const isStagingEnvironment =
    window.location.hostname === 'staging-bips.netlify.app' ||
    window.location.hostname === 'localhost';

  if (!isProduction && !isStaging) {
    return proposalConfig[lenderId];
  }

  // For staging environment
  if (isStaging || isStagingEnvironment) {
    return StagingHiddenIds.includes(lenderId)
      ? undefined
      : proposalConfig[lenderId];
  }

  // For production environment
  if (isProduction && !isStagingEnvironment) {
    return ProductionHiddenIds.includes(lenderId)
      ? undefined
      : proposalConfig[lenderId];
  }

  return undefined;
};
