import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { CreateUserDialogProps, IStateProps, IData } from './interfaces';
import { CreateUserDialog } from './CreateUserDialog';
import { useTypedSelector } from 'redux/reducers';

export const CreateUserButton = (props: CreateUserDialogProps) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div>
      {/* Don't Show in a Deal because the auto populate is set up */}
      {props?.ProcessDefinitionId ? (
        <div />
      ) : (
        <>
          <Button
            data-testid="gateway-btn"
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => setOpen(true)}>
            + {`Create ${props?.data?.type}`}
          </Button>
          <CreateUserDialogWrapper {...props} open={open} setOpen={setOpen} />
        </>
      )}
    </div>
  );
};

/**TODO: THIS COMPONENT IS CAUSING MEMORY LEAKS*/

// This Wrapper is to help with Mocking Values, to test the validity of our state
export const CreateUserDialogWrapper = (props: CreateUserDialogProps) => {
  // const { createUserData } = useTypedSelector((state) => state.user);
  const [isPrimary, setIsPrimary] = React.useState(false);
  const [data, setData] = React.useState<Partial<IData>>({} as IData);
  const stateProps: IStateProps = { isPrimary, data, setIsPrimary, setData };
  return <CreateUserDialog props={props} stateProps={stateProps} />;
};
