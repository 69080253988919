import { Button, Typography, TextField } from '@material-ui/core';
import { useDealList } from 'hooks/useDealList';
import Alert from '@material-ui/lab/Alert';
import { useTypedSelector } from 'redux/reducers';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface IProps {
  name: string;
  company_number: string;
  UserDefinitionId?: number;
  setHistoryAdded?: Dispatch<SetStateAction<boolean>>;
}

export const ExtremeCautionAlert = ({
  name,
  company_number,
  UserDefinitionId,
  setHistoryAdded
}: IProps) => {
  const { user } = useTypedSelector((s) => s.user);
  const isAdmin = user.SystemAccess >= 15;
  const {
    addToDoNotDealList,
    badCompany,
    updateDoNotDealListEntry,
    removeFromDoNotDealList,
    addToHistory
  } = useDealList(company_number);

  const [edit, setEdit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [reportedBy, setReportedBy] = useState<string>('');

  useEffect(() => {
    if (badCompany) {
      setDescription(badCompany?.Description || '');
      setReportedBy(badCompany?.ReportedBy || '');
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [badCompany]);

  const handleAdd = async () => {
    try {
      await addToDoNotDealList({ name, company_number, UserDefinitionId });
      setEdit(true);
    } catch (e) {
      console.error('Error adding company to DoNotDealList:', e);
    }
  };

  const handleRemove = async () => {
    try {
      await addToHistory();
      await removeFromDoNotDealList({ company_number });
      setEdit(false);
      setDescription('');
      setReportedBy('');
      setHistoryAdded && setHistoryAdded(true);
    } catch (e) {
      console.error('Error removing company from DoNotDealList:', e);
    }
  };

  const handleBlur = async (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;

    return await updateDoNotDealListEntry({ company_number, name, value });
  };

  if (edit) {
    return (
      <Alert severity="error" style={{ marginBottom: 8 }}>
        <Typography variant="h4">Extreme Caution</Typography>
        <Typography>{`This company is on the ‘Proceed with extreme caution’ list. Under no circumstances should applications be made for this counter party without consulting the ‘Proceed with Caution’ list first`}</Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 275,
            marginBottom: 8
          }}>
          <TextField
            label="Description"
            onBlur={handleBlur}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            name="Description"
            value={description}
          />
          <TextField
            label="Reported by"
            onBlur={handleBlur}
            onChange={(e) => {
              setReportedBy(e.target.value);
            }}
            name="ReportedBy"
            value={reportedBy}
          />
        </div>

        {isAdmin && (
          <Button onClick={handleRemove} variant="contained">
            Remove from extreme caution list
          </Button>
        )}
      </Alert>
    );
  }

  return (
    <>
      {isAdmin && (
        <Button
          onClick={handleAdd}
          variant="contained"
          style={{ marginBottom: 8 }}>
          Add to Extreme caution list
        </Button>
      )}
    </>
  );
};
