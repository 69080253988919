import React, { useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { Button, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Extra, Tools } from '../Calculator/interfaces';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';

import { useCalculator } from '../Calculator/hooks/useCalculator';
import SeasonalPayments, { IRowData } from './newIndex';
import {
  checkForMissingAmounts,
  checkForOverlappingMonths,
  formatOverridePaymentsToRows,
  getMonthsMultiplier,
  transformDataForExtras
} from './functions';
import { SnackBar } from '../SnackBar';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const ScrollDialog = ({ tools }: { tools: Tools }) => {
  const { customPaymentStructure, setSnackBar, snackBar } =
    useCalculator(tools);
  const { setExtras, rows, setState, state, setRows, extras } = tools;

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    const overlapError = checkForOverlappingMonths(rows);
    if (overlapError) {
      setSnackBar({
        open: true,
        message: `Warning: Overlapping periods detected in row ${overlapError}. Please review and adjust accordingly.`,
        variant: 'warning'
      });

      return;
    }

    const missingAmountError = checkForMissingAmounts(rows);
    if (missingAmountError) {
      setSnackBar({
        open: true,
        message: `Warning: Missing amount in row ${missingAmountError}. Please fill out all amount fields.`,
        variant: 'warning'
      });

      return;
    }

    const transformedData = transformDataForExtras(rows, state.StartDate);
    if (transformedData.length > 0) {
      setExtras(transformedData);

      // We no longer force the Seasonal Payment on Funding Profile
      // let term = 0;
      // rows.forEach((row: IRowData) => {
      //   if (row && row.PaymentFrequency) {
      //     const getPaymentFrequency = row.PaymentFrequency.toString();
      //     const getMonths = getMonthsMultiplier(getPaymentFrequency);
      //     const getNumber = parseInt(row.Number as string);

      //     if (!isNaN(getNumber)) {
      //       term += getMonths * getNumber;
      //     }
      //   }
      // });

      // setState({ ...state, FundingProfile: term });
    }

    setOpen(false);
  };

  useEffect(() => {
    const hasStateExtras = state && state.extras && state.extras.length > 0;
    const hasOverridePayments =
      state &&
      state.OverridePayments &&
      state.OverridePayments.length > 0 &&
      state.OverridePayments.some((payment) => 'IsMonth' in payment);

    if (open && (extras.length > 0 || hasStateExtras || hasOverridePayments)) {
      const combinedPayments = [
        ...(state.extras || []),
        ...extras,
        ...(state.OverridePayments || [])
      ];

      const uniquePaymentsSet = new Set<string>();
      const uniqueCombinedPayments = combinedPayments.filter((payment) => {
        if (payment.isDeferral) return false;

        const paymentKey = `${payment.StartPeriod}-${payment.EndPeriod}-${payment.Amount}`;
        if (uniquePaymentsSet.has(paymentKey)) {
          console.warn(`Duplicate payment found: ${paymentKey}`);
          return false;
        } else {
          uniquePaymentsSet.add(paymentKey);
          return true;
        }
      });

      const newRows = formatOverridePaymentsToRows(
        uniqueCombinedPayments,
        state.StartDate
      );

      setRows(newRows);
    }
  }, [open]);

  return (
    <div>
      <SnackBar
        message={snackBar.message}
        open={snackBar.open}
        toggle={() =>
          setSnackBar({
            open: !snackBar.open,
            message: '',
            variant: 'warning'
          })
        }
        variant={snackBar.variant}
      />

      <Button
        color="primary"
        onClick={() => {
          customPaymentStructure();
          setOpen(!open);
        }}
        style={{ width: '100%' }}
        variant="contained">
        Seasonal Payments
      </Button>
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        onClose={handleClose}
        open={open}>
        <DialogTitle onClose={handleClose}>Seasonal Payments</DialogTitle>
        <DialogContent>
          <SeasonalPayments tools={tools} handleClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ScrollDialog;

export interface DialogTitleProps extends WithStyles<typeof styles> {
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle className={classes.root} disableTypography {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// // interface Props {
// //   balloonExtras: Extra[];
// //   classes: any;
// //   customPaymentStructure: () => Promise<{
// //     balloonExtras: Extra[];
// //     zeroPayments: Extra[];
// //   }>;
// //   extras: Extra[];
// //   isOpen: boolean;
// //   setOpenExtraPayments: Dispatch<SetStateAction<boolean>>;
// //   period: any;
// //   setExtras: Dispatch<SetStateAction<Extra[]>>;
// //   setSnackBar: Dispatch<
// //     SetStateAction<{ open: boolean; variant: string; message: string }>
// //   >;
// //   state: Calculation;
// //   title: string;
// //   toggle: () => void;
// //   zeroPayments: Extra[];
// // }
