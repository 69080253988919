import { useState, useEffect } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { firebase } from 'redux/firebase';
import {
  CompanySearchResource,
  CompanyProfile,
  FilingHistoryList,
  OfficerList,
  persons_with_significant_control
} from 'types/companiesHouseInterfaces';
import { OffkeyCompany } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IRedflagAPIData } from 'types/redflagInterfaces';

interface ICompanyHouse {
  company: CompanyProfile;
  filing_history: FilingHistoryList;
  officers: OfficerList;
  pwsc: persons_with_significant_control;
}

export const useCompaniesHouse = () => {
  const [q, setQ] = useState<string | undefined>();
  const [data, setData] = useState<CompanySearchResource | undefined>();
  const [company, setCompany] = useState<
    ICompanyHouse | OffkeyCompany | undefined
  >();

  const [loading, setLoading] = useState(false);

  const search = async () => {
    setLoading(true);
    const token = await firebase.auth().currentUser?.getIdToken();
    const config: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_PROXY}/companiesHouse/search`,
      method: 'POST',
      headers: { token },
      data: { q }
    };
    axios(config)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log({ e });
        setLoading(false);
      });
  };

  const getCompanyProfile = async (num: string, isConversion?: boolean) => {
    const token = await firebase.auth().currentUser?.getIdToken();
    const config: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_PROXY}/companiesHouse/companyProfile`,
      method: 'POST',
      headers: { token },
      data: { num }
    };
    axios(config)
      .then((res) => {
        if (isConversion) {
          const company = convertCompanyHouseToRedflagOG(res.data);
          setCompany(company);
        } else {
          setCompany(res.data as ICompanyHouse);
        }
      })
      .catch((e) => console.log({ e }));
  };

  const convertCompanyHouseToRedflagOG = (companyProfile: ICompanyHouse) => {
    let newCompany = {} as OffkeyCompany;
    let third_party_apis = {
      creditSafe_company: {},
      redflagOG: {} as IRedflagAPIData,
      redflagOG_Source: {} as IRedflagAPIData
    } as OffkeyCompany['third_party_apis'];

    const mapArrayAttributes = (
      source: any,
      newType: string,
      oldType: string
    ) => {
      const type = source;
      const typeCheck = Array.isArray(type) && type.length > 0;

      if (typeCheck) {
        third_party_apis.redflagOG[`${oldType}`] = type.map(
          (attributes: any) => ({ attributes, type: oldType, id: '' })
        );
      }
    };

    mapArrayAttributes(
      companyProfile?.filing_history.items,
      'filing_history',
      'filing_history'
    );
    mapArrayAttributes(companyProfile.officers?.items, 'officers', 'directors');
    mapArrayAttributes(
      companyProfile.pwsc?.items,
      'persons_with_significant_control',
      'persons_with_significant_control'
    );

    if (companyProfile.company.registered_office_address) {
      third_party_apis.redflagOG['addresses'] = [
        {
          attributes: companyProfile.company.registered_office_address as any,
          type: 'registered_office_address',
          id: ''
        }
      ];
    }

    if (
      companyProfile.company.sic_codes &&
      companyProfile.company.sic_codes.length > 0
    ) {
      const sic_codes = companyProfile.company.sic_codes.map((code) => ({
        attributes: { code },
        type: 'sic_code',
        id: ''
      }));
      third_party_apis.redflagOG['sic_codes'] = sic_codes;
    }

    let grabSicCodes = '';
    let grabSicDescriptions = '';
    if (
      companyProfile.company.sic_codes &&
      companyProfile.company.sic_codes.length > 0
    ) {
      grabSicCodes = companyProfile.company.sic_codes
        .filter(
          (code: string, index: number, array: string[]) =>
            array.indexOf(code) === index
        )
        .join(', ');

      grabSicDescriptions = companyProfile.company.sic_codes[0];
    }

    third_party_apis.redflagOG['data'] = {
      attributes: {
        name: companyProfile.company.company_name,
        company_number: companyProfile.company.company_number,
        company_type: companyProfile.company.type,
        incorporation_date: '',
        legal_status: companyProfile.company.company_status,
        sic07_codes: grabSicCodes,
        rfa_rating: {
          long_description: grabSicDescriptions,
          short_code: grabSicDescriptions
        }
      },
      id: companyProfile.company.company_number,
      type: 'company'
    };

    newCompany.third_party_apis = third_party_apis;
    return newCompany;
  };

  useEffect(() => {
    q && search();
  }, [q]);

  return { q, setQ, data, getCompanyProfile, company, loading };
};
