import { ClickAwayListener, useTheme, ListItemIcon } from '@material-ui/core';
import React, { useState, useMemo, useRef, useEffect } from 'react';
import Creatable, { useCreatable } from 'react-select/creatable';
import { UserDefinition, UserInstance } from 'types/interfaces';
import { Labels } from 'types/enums';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ListSubheader from '@material-ui/core/ListSubheader';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';

import MaterialUISelect from '@material-ui/core/Select';
import Select from 'react-select';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Skeleton from '@material-ui/lab/Skeleton';

import { makeStyles } from '@material-ui/core/styles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export interface IOptionType {
  label: string;
  value: string | number;
}

export interface IMaterialUISelect {
  options: Array<UserDefinition | IOptionType> | null;
  value: string | number | Array<UserDefinition | IOptionType> | null;
  onChange: (event: any) => void;
  open: boolean;
  handleClose: () => void;
  sortAlphabetically: boolean;
  sortNumerically: boolean;

  disabled?: boolean;
  loading?: boolean;
  label?: string;
  creatable?: () => void;
  isMulti?: boolean;
}

export interface IReactSelect {
  options: Array<UserDefinition | IOptionType> | null;
  value: unknown;
  onChange: (event: any) => void;
  placeholder?: string;
  isMulti?: boolean;
  creatable?: boolean;
  label?: string;
  isDisabled?: boolean;
}

interface IStyledSelect {
  useMaterialUI: boolean;
}

export const StyledSelect = (
  props: IStyledSelect & (IMaterialUISelect | IReactSelect)
) => {
  if (props.useMaterialUI)
    return <StyledMaterialUISelect {...(props as IMaterialUISelect)} />;
  return <StyledReactSelect {...(props as IReactSelect)} />;
};

const StyledMaterialUISelect = (props: IMaterialUISelect) => {
  const {
    onChange,
    value,
    options,
    open,
    handleClose,
    label,
    loading,
    isMulti,
    disabled,
    sortAlphabetically = true,
    sortNumerically = false
  } = props;
  const [searchText, setSearchText] = useState<string | null>(null);
  const [loadingDuration, setLoadingDuration] = React.useState(0);

  // Guard Function to determine wether dropdown options are of type IOptionType
  const isOptionType = (
    obj: UserDefinition | IOptionType
  ): obj is IOptionType => 'label' in obj;

  const displayedOptions = useMemo(() => {
    if (searchText === null) return options;

    return (
      options &&
      options.filter((option) =>
        isOptionType(option) ? containsText(option.label, searchText) : false
      )
    );
  }, [options, searchText]);

  const sortedOptions = useMemo(() => {
    if (!displayedOptions) return [];
    let options = displayedOptions;
    if (sortAlphabetically) {
      options = options.sort((a, b) => {
        if (isOptionType(a) && isOptionType(b)) {
          return a.label.localeCompare(b.label);
        }
        return 0;
      });
    }
    if (sortNumerically) {
      options.sort((a, b) => {
        if (isOptionType(a) && isOptionType(b)) {
          return Number(a.value) - Number(b.value);
        }
        return 0;
      });
    }
    options = options.filter((option, index, self) => {
      if (isOptionType(option)) {
        return (
          index ===
          self.findIndex(
            (t) =>
              isOptionType(t) &&
              t.value === option.value &&
              t.label === option.label
          )
        );
      }

      if ('Id' in option) {
        return (
          index ===
          self.findIndex(
            (t) => 'Id' in t && t.Id === option.Id && t.Title === option.Title
          )
        );
      }

      return false;
    });

    // Final sort to ensure 'Reset To None' label always remains at the bottom
    return options.sort((a, b) => {
      if (isOptionType(a) && isOptionType(b)) {
        if (a.label === Labels.ResetToNone) return 1;
        if (b.label === Labels.ResetToNone) return -1;
      }
      return 0;
    });
  }, [displayedOptions, sortAlphabetically, sortNumerically]);

  const renderMenuItems = () => {
    if (loading) {
      // Modify SET_TIME to the desired threshold, e.g., 5 for 5 seconds
      if (loadingDuration < 10) {
        return (
          <>
            <MenuItem
              disabled>{`Please Wait While We Find Related Users`}</MenuItem>
            {skeletonItems(5)}
          </>
        );
      } else {
        return (
          <MenuItem disabled>{`We Couldn't Find Any Relationships`}</MenuItem>
        );
      }
    } else if (sortedOptions) {
      return sortedOptions.map(
        (item: UserDefinition | IOptionType | UserInstance | any, idx) => {
          const isSelected =
            isMulti && Array.isArray(value) && value.includes(item.label);
          return (
            <MenuItem
              data-cy="select-menu-item"
              key={idx}
              value={item.value}
              style={{
                backgroundColor: isSelected ? 'lightgreen' : 'inherit'
              }}>
              {isSelected && (
                <ListItemIcon>
                  <CheckBoxIcon style={{ color: 'green' }} />
                </ListItemIcon>
              )}
              {item.label}
            </MenuItem>
          );
        }
      );
    }
  };

  React.useEffect(() => {
    let timer;
    if (loading) {
      timer = setInterval(() => {
        setLoadingDuration((prevDuration) => prevDuration + 1);
      }, 1000);
    } else {
      setLoadingDuration(0);
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [loading]);

  const skeletonItems = (count: number) => {
    return Array.from({ length: count }, (_, index) => (
      <MenuItem key={index}>
        <Skeleton variant="text" width="100%" height={30} />
      </MenuItem>
    ));
  };

  return (
    <FormControl variant="outlined" fullWidth margin="dense">
      {label && <InputLabel style={{ zIndex: 1 }}>{label}</InputLabel>}
      <MaterialUISelect
        data-cy="user-group-select"
        variant="outlined"
        disabled={disabled}
        value={value}
        multiple={isMulti}
        open={open}
        label={label}
        fullWidth
        onChange={onChange}
        margin="dense"
        onClose={() => {
          setSearchText('');
          handleClose && handleClose();
        }}
        MenuProps={{
          autoFocus: false,
          disableAutoFocusItem: true,
          disableEnforceFocus: true,
          disableAutoFocus: true
        }}>
        {options && options.length >= 15 && (
          <ListSubheader style={{ paddingTop: 10 }}>
            <Paper style={{ height: 37 }}>
              <TextField
                size="small"
                variant="outlined"
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    e.stopPropagation();
                  }
                }}
              />
            </Paper>
          </ListSubheader>
        )}
        {renderMenuItems()}
      </MaterialUISelect>
    </FormControl>
  );
};

const StyledReactSelect = (props: IReactSelect) => {
  const theme = useTheme();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius
    }),
    menu: (provided, state) => ({
      ...provided,
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      zIndex: 10
    }),
    menuList: (provided, state) => ({
      ...provided,
      color: theme.palette.text.primary,
      zIndex: 10
    }),
    input: (provided, state) => ({
      ...provided,
      color: theme.palette.text.primary,
      outline: theme.palette.primary.main
    }),
    option: (provided, state) => ({
      ...provided,
      color:
        (state.isFocused || state.isSelected) &&
        theme.palette.primary.contrastText,
      background:
        (state.isFocused || state.isSelected) && theme.palette.primary.light,
      borderRadius: theme.shape.borderRadius
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color:
        theme.palette.type === 'dark'
          ? theme.palette.primary.contrastText
          : theme.palette.text.primary
    })
  };

  if (props.creatable) return <Creatable styles={customStyles} {...props} />;
  return (
    <div data-cy="entity-type">
      <Select {...props} styles={customStyles} />
    </div>
  );
};

const containsText = (text: string, searchText: string) =>
  text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
