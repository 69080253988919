import { useState, useEffect, useContext } from 'react';
import { useProcess } from 'hooks';
import {
  Grid,
  Button,
  useTheme,
  CircularProgress,
  Tooltip,
  Typography,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@material-ui/core';
import { UserInstance, Rule, CurrentStep } from 'types/interfaces';
import { ruleGroupFilter, ruleFilter } from '../functions';
import { Thread } from '../../../interfaces';
import { GetPopulatedEmail } from 'redux/database/Process Step API';
import { RuleList, SubmitRule } from 'redux/database';
import { EmailEditor } from '../../../components';
import { useDealSummary } from 'hooks/useDealSummary';
import { MessageHubProvider } from '../../..';
import { SupplierInvoiceRequest } from 'redux/database/Custom API';
import { globalIds } from 'helpers/globalIdConfig';
import { IDealSummary } from 'hooks/useDealSummary/interface';
import { useIsAuthenticated } from '@azure/msal-react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CustomDialog } from 'common/Dialog';
import { theme } from 'theme';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from 'redux/reducers';
import { globalHost } from 'helpers';

interface IActionRequest {
  ProcessInstanceId: number;
  ProcessStepDefinitionId: number;
  RecipientUserInstanceId: number;
  TargetUserInstanceId: number;
  Title: string;
  open: boolean;
  rule: Rule;
}

export const MicrosoftInbox = ({
  thread,
  fixedToContact
}: {
  thread: Thread;
  fixedToContact: UserInstance;
}) => {
  const theme = useTheme();
  const isAuth = useIsAuthenticated();
  const { deal, syncProcessInstance } = useDealSummary();
  const { getRuleGroups, currentProcess, currentDeal, currentStep, user } =
    useProcess();

  const { ruleGroups, rules } = getRuleGroups();

  const MessageProvider = useContext(MessageHubProvider);
  const { openDialog, setOpenDialog, PopulatedEmail, setPopulatedEmail } =
    MessageProvider;

  const [handledActionRequest, setHandledActionRequest] =
    useState<IActionRequest>({} as IActionRequest);

  const subSystemUser = user.SystemAccess <= 4;
  const isBrokerOnBrokerTab = thread.type === 'broker.contact';

  const handleRuleClick = async (rule: Rule) => {
    const filtered = ruleFilter(rule, thread);
    filtered && (await ruleActionFunctionFilter(filtered));
  };

  const ruleActionFunctionFilter = async (rule: Rule) => {
    const { ActionFunction, ActionValue, Title } = rule;
    const ProcessInstanceId = currentDeal.ProcessInstance.Id;

    const RecipientUserInstanceId = fixedToContact.Id;
    switch (ActionFunction) {
      case 'Run rule sequence': {
        let res;
        if (globalIds.ruleLists.SupplierContact.includes(rule.Id)) {
          const foundStep = globalIds.stepProcesses.supplierRequestInvoice.find(
            (step) =>
              currentProcess.ProcessStepDefinitionSequence.includes(step)
          );
          if (foundStep && deal.primaryContact) {
            const isParent = thread.parentId ? thread.parentId : 0;
            const SupplierFieldDefinitionId = RecipientUserInstanceId
              ? globalIds.supplier.assetDetail.supplierContact
              : globalIds.supplier.assetDetail.supplier;

            // The RecipientUserInstanceId is the Supplier Contract the IsParent is The Supplier
            await SupplierInvoiceRequest({
              ProcessInstanceId,
              SupplierId: isParent,
              ProcessStepDefinitionId: foundStep,
              SupplierContactId: parseInt(RecipientUserInstanceId.toString()),
              ObjectDefinitionId: globalIds.objectDefinitionIds.assetDetailOBID,
              FieldDefinitionId: SupplierFieldDefinitionId,
              CustomerId: deal?.primaryContact?.Id ?? 0
            });

            const request: IActionRequest = {
              ProcessInstanceId,
              ProcessStepDefinitionId: foundStep,
              RecipientUserInstanceId: parseInt(
                RecipientUserInstanceId.toString()
              ),
              TargetUserInstanceId: parseInt(isParent.toString()),
              Title,
              open: true,
              rule
            };

            return setHandledActionRequest(request);
          }
        } else {
          const RuleId = rule.Id;
          res = await SubmitRule({
            RuleId,
            ProcessInstanceId,
            NoSend: true
          });
        }

        if (res && res.status === 200) {
          const RuleListRes = await RuleList({
            ProcessDefinitionId: currentProcess.ProcessDefinition.Id
          });

          const actionValArray = ActionValue.split(',');
          let actionValueToUse: string | null = null;

          for (const ruleIdStr of actionValArray) {
            const ruleId = parseInt(ruleIdStr);
            const matchingRule = RuleListRes.data.find(
              (rule) => rule.Id === ruleId
            );

            if (!matchingRule) continue;

            const actionFunctions = [
              'Assign and invite fixed user',
              'Invite existing user to step',
              'Invite related user to step',
              'Assign users and invite to step'
            ];

            if (
              matchingRule.ActionFunction &&
              actionFunctions.includes(matchingRule.ActionFunction)
            ) {
              actionValueToUse =
                matchingRule.ActionFunction === 'Assign and invite fixed user'
                  ? matchingRule.ActionValue.split(',')[1]
                  : matchingRule.ActionValue;
              break;
            }
          }

          if (actionValueToUse === null) break;
          const completeProcessStepDict =
            currentProcess.CompleteProcessStepDefinitionDict;

          let matchingPairId: number | null = null;
          const matchingValue = actionValueToUse.split(',');
          for (const RuleId of matchingValue) {
            const index = parseInt(RuleId);
            if (completeProcessStepDict[index] !== undefined) {
              matchingPairId = index;
              break;
            }
          }

          console.log({ matchingPairId });
          if (matchingPairId === null) break;
          const TargetUserInstanceId = await getTargetUser({
            thread,
            syncProcessInstance,
            currentStep,
            RecipientUserInstanceId,
            deal
          });

          if (TargetUserInstanceId > 0) {
            const typedInt = parseInt(actionValueToUse.toString());

            const request: IActionRequest = {
              ProcessInstanceId,
              ProcessStepDefinitionId: typedInt,
              RecipientUserInstanceId,
              TargetUserInstanceId,
              Title,
              open: true,
              rule
            };

            return setHandledActionRequest(request);
          } else {
            // Create a dispatch in why it's not working
          }

          //   if (TargetUserInstanceId <= 0) {
          //     return setOpenDialog(false);
          //   }

          // const res = await GetPopulatedEmail({
          //   ProcessInstanceId,
          //   ProcessStepDefinitionId: parseInt(actionValueToUse),
          //   RecipientUserInstanceId,
          //   TargetUserInstanceId
          // });

          // setOpenDialog(true);
          // return setPopulatedEmail(res.data);
        }
        break;
      }

      case 'Assign and invite fixed user': {
        const splitValues: string[] = ActionValue.split(',');
        const TargetUserInstanceId = await getTargetUser({
          thread,
          syncProcessInstance,
          currentStep,
          RecipientUserInstanceId,
          deal
        });

        //* We are making a request to the Action Dialog Handler with all information related to this "Rule"
        const typedInt = parseInt(splitValues[1].toString());
        const request: IActionRequest = {
          ProcessInstanceId,
          ProcessStepDefinitionId: typedInt,
          RecipientUserInstanceId,
          TargetUserInstanceId,
          Title,
          open: true,
          rule
        };

        return setHandledActionRequest(request);

        // const res = await GetPopulatedEmail({
        //   ProcessInstanceId,
        //   ProcessStepDefinitionId: splitValues[1],
        //   RecipientUserInstanceId,
        //   TargetUserInstanceId
        // });

        // setOpenDialog(true);
        // return setPopulatedEmail(res.data);
      }

      default: {
        const TargetUserInstanceId = await getTargetUser({
          deal,
          RecipientUserInstanceId,
          currentStep,
          syncProcessInstance,
          thread
        });

        //* We are making a request to the Action Dialog Handler with all information related to this "Rule"
        const typedInt = parseInt(ActionValue.toString());
        const request: IActionRequest = {
          ProcessInstanceId,
          ProcessStepDefinitionId: typedInt,
          RecipientUserInstanceId,
          TargetUserInstanceId,
          Title,
          open: true,
          rule
        };

        return setHandledActionRequest(request);
      }
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: theme.palette.background.default,
          color: 'white',

          borderRadius: theme.shape.borderRadius,
          padding: theme.spacing(0.5),
          marginLeft: theme.spacing(2),
          marginRight: theme.spacing(2),
          marginBottom: theme.spacing(2)
        }}>
        <EmailEditor editorOpen={openDialog} setEditorOpen={setOpenDialog} />
      </div>

      <ConfirmingAction
        actionRequest={handledActionRequest}
        setActionRequest={setHandledActionRequest}
      />

      {!subSystemUser && (
        <Grid container alignItems="center" justifyContent="center">
          {Object.keys(ruleGroups)
            .filter((name: string) => ruleGroupFilter(name, thread))
            .map((name: string) => {
              if (isBrokerOnBrokerTab) return <div key={name} />;
              const rules: Rule[] = ruleGroups[name];

              return rules
                .filter((rule: Rule) => ruleFilter(rule, thread))
                .map((rule: Rule) => {
                  return (
                    <GridItem
                      key={rule.Id}
                      rule={rule}
                      auth={isAuth}
                      handleRuleClick={handleRuleClick}
                    />
                  );
                });
            })}
        </Grid>
      )}
    </>
  );
};

const GridItem = ({ rule, handleRuleClick, auth }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Tooltip
      title={
        !auth ? 'Please Connect To Microsoft 365 To Enable This Rule.' : ''
      }
      arrow>
      <Grid item>
        <Button
          onClick={async () => {
            setLoading(true);
            await handleRuleClick(rule);
            setLoading(false);
          }}
          variant="contained"
          color="secondary"
          style={{ margin: 1 }}
          disabled={loading || !auth}>
          {loading ? <CircularProgress /> : rule.Title}
        </Button>
      </Grid>
    </Tooltip>
  );
};

const getTargetUser = async ({
  thread,
  syncProcessInstance,
  RecipientUserInstanceId,
  deal,
  currentStep
}: {
  thread: Thread;
  syncProcessInstance: () => Promise<boolean>;
  RecipientUserInstanceId: number;
  deal: IDealSummary;
  currentStep: CurrentStep;
}) => {
  await syncProcessInstance();
  let TargetUserId = 0;
  /** TARGET USER */

  switch (thread.type) {
    case 'borrower.contact': {
      const customers = deal?.customers;
      if (customers) {
        const target: number | undefined =
          customers?.selected && customers?.selected[0]?.Id;

        if (target) TargetUserId = target;
        else {
          const customer = Object.keys(
            currentStep.UserInstanceDictForCurrentStep
          )[0];

          TargetUserId = parseInt(customer);
        }
      } else {
        const customer = Object.keys(
          currentStep.UserInstanceDictForCurrentStep
        )[0];
        TargetUserId = parseInt(customer);
      }

      break;
    }
    case 'supplier' || 'supplier.contact': {
      TargetUserId = thread.parentId
        ? thread.parentId
        : RecipientUserInstanceId;
      break;
    }
    default:
      TargetUserId = RecipientUserInstanceId;
      break;
  }

  return TargetUserId;
};

const removeSendPrefix = (ruleName: string): string => {
  return ruleName.replace(/^Send\s+/i, '');
};

const extractLinkFromBody = (body: string): string | undefined => {
  const linkRegex = /<a\s+href="([^"]+)"/i;
  const match = body.match(linkRegex);
  return match ? match[1] : undefined;
};

//? We could inject the ruleName into the modified link, for now I have called it "preview" with the mode being what is changes to.
const modifyLink = (link: string, ruleName: string) => {
  const url = new URL(link);
  url.searchParams.set('mode', 'preview');
  return url.toString();
};

const ConfirmingAction = ({
  actionRequest,
  setActionRequest
}: {
  actionRequest: IActionRequest;
  setActionRequest: (actionRequest: IActionRequest) => void;
}): JSX.Element => {
  const MessageProvider = useContext(MessageHubProvider);
  const { setOpenDialog, setPopulatedEmail, setSelectedRule } = MessageProvider;

  const [loadingStates, setLoadingStates] = useState<{
    send: boolean;
    preview: boolean;
  }>({ send: false, preview: false });

  const handleDialogState = () => {
    setActionRequest({ ...actionRequest, open: !actionRequest.open });
  };

  return (
    <CustomDialog
      maxSize="sm"
      open={actionRequest.open}
      handleClose={handleDialogState}
      alert={{
        title: 'Action Confirmation Required:',
        description: 'You have two options to proceed with your landing page:',
        type: 'warning'
      }}>
      <DialogContent>
        <DialogContentText>
          <Typography variant="body1" gutterBottom>
            1. <strong>Preview Landing Page:</strong> Click this option if you
            wish to review and verify the Landing Page before sending it. This
            allows you to ensure everything is correct and make any necessary
            adjustments.
          </Typography>
          <div style={{ paddingTop: theme.spacing(1) }} />
          <Typography variant="body1">
            2. <strong>Create Email Draft To {actionRequest.Title}:</strong>{' '}
            {`Select this option to first draft then send a
            Landing Page directly to the user once you are satisfied with it.
      `}
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleDialogState} color="primary">
          Cancel
        </Button>

        <Tooltip title={`Selecting Option 1 "Preview Landing Page"`} arrow>
          <Button
            onClick={async () => {
              setLoadingStates({ ...loadingStates, preview: true });
              const res = await GetPopulatedEmail({
                ProcessInstanceId: actionRequest.ProcessInstanceId,
                ProcessStepDefinitionId: actionRequest.ProcessStepDefinitionId,
                RecipientUserInstanceId: actionRequest.RecipientUserInstanceId,
                TargetUserInstanceId: actionRequest.TargetUserInstanceId
              });

              const newPopulatedEmail = updatePopulatedEmail(res);
              if (newPopulatedEmail) {
                const link = extractLinkFromBody(newPopulatedEmail.Body);
                if (link) {
                  const modifiedRuleName = removeSendPrefix(
                    actionRequest.Title
                  );
                  const modifiedLink = modifyLink(link, modifiedRuleName);
                  window.open(modifiedLink, '_blank');
                }
              }

              // handleDialogState();
              setLoadingStates({ ...loadingStates, preview: false });
            }}
            variant="contained"
            color="primary"
            autoFocus
            style={{ width: '150px' }}>
            {loadingStates.preview ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Preview Page'
            )}
          </Button>
        </Tooltip>
        <Tooltip
          title={`Selecting Option 2 "Draft ${actionRequest.Title} Email"`}
          arrow>
          <Button
            onClick={async (e) => {
              setLoadingStates({ ...loadingStates, send: true });
              const res = await GetPopulatedEmail({
                ProcessInstanceId: actionRequest.ProcessInstanceId,
                ProcessStepDefinitionId: actionRequest.ProcessStepDefinitionId,
                RecipientUserInstanceId: actionRequest.RecipientUserInstanceId,
                TargetUserInstanceId: actionRequest.TargetUserInstanceId
              });

              const newPopulatedEmail = updatePopulatedEmail(res);

              setPopulatedEmail(newPopulatedEmail);
              setSelectedRule(actionRequest.rule);

              setOpenDialog({ open: true, ruleId: actionRequest.rule.Id });
              handleDialogState();

              setLoadingStates({ ...loadingStates, send: false });
            }}
            variant="contained"
            color="primary"
            autoFocus
            style={{ width: '150px' }}>
            {loadingStates.send ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Create Draft'
            )}
          </Button>
        </Tooltip>
      </DialogActions>
    </CustomDialog>
  );
};

const updatePopulatedEmail = (res) => {
  //! Will Only Work for Localhost & Staging
  let populatedEmail = res.data;
  const hostname = window.location.hostname;
  const regex = /https:\/\/process-builder\.commercial-tech\.com/g;

  if (populatedEmail && populatedEmail.Body) {
    if (hostname.includes('staging')) {
      populatedEmail.Body = populatedEmail.Body.replace(
        regex,
        'https://staging.bips.tech'
      );
      return populatedEmail;
    } else if (hostname.includes('localhost')) {
      populatedEmail.Body = populatedEmail.Body.replace(
        regex,
        'http://localhost:3000'
      );
      return populatedEmail;
    }
  }

  return res.data;
};
