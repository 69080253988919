import { apolloClient } from 'graphql/apolloClient';
import { GET_RELATIONSHIPS_LIST } from 'graphql/RelationshipAPI/queries';
import {
  GET_COMPLETE_OBJECT_INSTANCE,
  GET_COMPLETE_USER_INSTANCE_DETAIL
} from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import { GET_USER_INSTANCES_WITH_OBJECTS } from 'graphql/UserInstanceAPI/GetUserInstancesWithObjects/queries';
import {
  CompleteObjectInstance,
  TUserSummaryLite,
  UserInstance
} from 'types/interfaces';
import {
  ICompleteUserInstanceDetail,
  IUserInstanceObjectData
} from './interface';

// TODO replace with ReadQuery
export const getCachedUser = (
  UserInstanceId: string
): UserInstance | undefined => {
  try {
    const cacheContents = apolloClient.cache.extract();
    const cachedData = cacheContents[
      `UserInstance:{"Id":"${UserInstanceId}"}`
    ] as UserInstance | undefined;

    if (cachedData) return cachedData;
  } catch (e) {
    console.error('Error Reading Fragment From cache', e);
    return undefined;
  }
};

// export const getCachedUsersForProcess = (
//   ProcessInstanceId: number,
//   ProcessDefinitionId: number,
//   UserDefinitionId: number,
//   baseUrl: string
// ): IUserForProcess[] | undefined => {
//   try {
//     const cachedData = apolloClient.cache.readQuery({
//       query: USERS_FOR_PROCESS_QUERY,
//       variables: {
//         baseUrl,
//         processInstanceId: ProcessInstanceId,
//         processDefinitionId: ProcessDefinitionId,
//         userDefinitionId: UserDefinitionId,
//         action: 'SELECTEDUSERFORPROCESS'
//       }
//     }) as { UsersForProcess: IUserForProcess[] };

//     console.log({
//       cachedData,
//       ProcessInstanceId,
//       ProcessDefinitionId,
//       UserDefinitionId
//     });
//     if (cachedData) {
//       return cachedData.UsersForProcess as IUserForProcess[];
//     }
//   } catch (e) {
//     console.error('Error Reading Data From Cache', e);
//   }

//   return undefined;
// };

export const getCachedUserInstanceDetail = (
  UserInstanceId: number,
  baseURL: string
): ICompleteUserInstanceDetail | undefined => {
  try {
    const cachedData = apolloClient.cache.readQuery({
      query: GET_COMPLETE_USER_INSTANCE_DETAIL,
      variables: {
        userInstanceId: parseInt(UserInstanceId.toString()),
        baseUrl: baseURL
      }
    }) as { GetUserInstanceDetail: ICompleteUserInstanceDetail };

    if (cachedData) {
      return cachedData.GetUserInstanceDetail as ICompleteUserInstanceDetail;
    }
  } catch (e) {
    console.error('Error Reading Data From Cache', e);
  }

  return undefined;
};

export const getCachedUserInstanceWithObjects = (
  baseUrl: string,
  ProcessInstanceId: number,
  payload: IUserInstanceObjectData
): TUserSummaryLite[] | undefined => {
  try {
    const cachedData = apolloClient.cache.readQuery({
      query: GET_USER_INSTANCES_WITH_OBJECTS,
      variables: {
        baseUrl,
        processInstanceId: ProcessInstanceId,
        data: payload
      }
    }) as {
      GetUserInstancesWithObjects: {
        UserInstance: UserInstance;
        CompleteObjectInstanceList: CompleteObjectInstance[];
      }[];
    };

    if (cachedData) {
      return cachedData.GetUserInstancesWithObjects as {
        UserInstance: UserInstance;
        CompleteObjectInstanceList: CompleteObjectInstance[];
      }[];
    }
  } catch (e) {
    console.error('Error Reading Data From Cache', e);
  }

  return undefined;
};

export const getCachedCompleteObjectInstance = (
  UserInstanceId: number,
  baseURL: string
): { CompleteObjectInstanceList: CompleteObjectInstance[] } | undefined => {
  try {
    const cachedData = apolloClient.cache.readQuery({
      query: GET_COMPLETE_OBJECT_INSTANCE,
      variables: {
        userInstanceId: parseInt(UserInstanceId.toString()),
        baseUrl: baseURL
      }
    }) as {
      GetUserInstanceDetail: {
        CompleteObjectInstanceList: CompleteObjectInstance[];
      };
    };

    if (cachedData) {
      return cachedData.GetUserInstanceDetail;
    }
  } catch (e) {
    console.error('Error Reading Data From Cache', e);
  }

  return undefined;
};

export const getRelationshipListAll = ({
  UserInstanceId,
  UserDefinitionId,
  baseUrl
}: {
  UserInstanceId: number;
  UserDefinitionId: number;
  baseUrl: string;
}): UserInstance[] | undefined => {
  try {
    const cachedData = apolloClient.cache.readQuery({
      query: GET_RELATIONSHIPS_LIST,
      variables: {
        userInstanceId: UserInstanceId,
        userDefinitionId: UserDefinitionId,
        action: 'LIST_ALL',
        baseUrl
      }
    }) as { GetRelationships: { Items: UserInstance[] } };

    if (cachedData) {
      return cachedData.GetRelationships.Items as UserInstance[];
    }
  } catch (e) {
    console.error('Error Reading Data From Cache', e);
  }

  return undefined;
};

// const cachedData = apolloClient.readFragment({
//   id: idString,
//   fragment: gql`
//     fragment UserInstanceFragment on UserInstance {
//       ProfilePicture
//       Title
//       UserInstanceEmail
//     }
//   `
// });
