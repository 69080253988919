import {
  IMicrosoftFolderIds,
  TKnownCategory
} from 'components/MessageHub/context/interface';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { determineParentCategory } from 'components/MessageHub/functions';
import {
  IConversation,
  IMicrosoftMessages,
  IMicrosoftUser
} from 'components/MessageHub/interfaces';
import { SetStateAction, useContext } from 'react';

const isFolderOrChildMatch = (folder, targetId) =>
  folder.id === targetId ||
  folder.child?.some((childFolder) => childFolder.id === targetId);

export const handleMovementRefresh = async ({
  conversation,
  folderIds,
  selectedCategory,
  accessToken,
  setData
}: {
  conversation: IConversation;
  folderIds: IMicrosoftFolderIds;
  selectedCategory: {
    category: TKnownCategory | string;
    folderId: string | undefined;
  };
  accessToken: string;
  setData: React.Dispatch<
    SetStateAction<{
      messages: IMicrosoftMessages | null;
      profileData: IMicrosoftUser | null;
      drafts: IMicrosoftMessages | null;
      archived: IMicrosoftMessages | null;
      'Sent Items': IMicrosoftMessages | null;
      customFolder: IMicrosoftMessages | null;
      folderIds: IMicrosoftFolderIds | null;
    }>
  >;
}) => {
  const isCustomFolder = folderIds.customFolders.some((folder) =>
    isFolderOrChildMatch(folder, conversation.parentMessage.parentFolderId)
  );

  if (isCustomFolder) {
    const parentFolderId = selectedCategory.folderId;
    if (!parentFolderId) return;

    const messagesResponse = await fetch(
      `https://graph.microsoft.com/v1.0/me/mailFolders/${parentFolderId}/messages`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    const messagesData = await messagesResponse.json();
    setData((prevData: any) => ({
      ...prevData,
      customFolder: {
        ...prevData.customFolder,
        value: messagesData.value
      }
    }));
  } else {
    const messageIdsToRemove = new Set([
      conversation.parentMessage.id,
      ...conversation.replies.map((reply) => reply.id)
    ]);

    const folderToStateField = {
      Inbox: 'messages',
      'Sent Items': 'Sent Items',
      Archive: 'archived',
      Drafts: 'drafts'
    };

    setData((prevData: any) => {
      const stateField =
        folderToStateField[selectedCategory.category] || 'customFolder';

      const updatedMessages =
        prevData[stateField]?.value.filter(
          (message) => !messageIdsToRemove.has(message.id)
        ) ?? [];

      return {
        ...prevData,
        [stateField]: {
          ...prevData[stateField],
          value: updatedMessages
        }
      };
    });
  }
};
