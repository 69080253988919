import { Button } from '@material-ui/core';
import { FileAttachment } from '@microsoft/microsoft-graph-types';
import { useFileStorage } from 'hooks';
import { useEffect } from 'react';
import { UserInstance } from 'types/interfaces';

import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';

const UploadFileToFileStorage = ({
  user,
  attachment,
  handleClose
}: {
  user: UserInstance;
  attachment: FileAttachment;
  handleClose: (event: any) => void;
}) => {
  const { uploadFile, uploadProgress, resetUploadProgress } = useFileStorage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadProgress === 100) {
      setTimeout(() => {
        dispatch(
          createNotification(successNotif('File Uploaded To File Storage'))
        );
      }, 1000);
      handleClose(null);
      resetUploadProgress();
    }
  }, [uploadProgress]);

  const file: File | null = convertToJavascriptFile(attachment);
  if (!file) return null;

  const handleFileUpload = async () => {
    if (file) {
      // We need to open a dialog here
      await uploadFile({
        files: [file],
        ProcessInstanceId: 0,
        UserInstanceId: user.Id
      });

      gtag.event({
        feature: 'Inbox',
        action: 'Upload',
        message: `Email Attachment Uploaded ${file.name}`
      });
    }
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={async (e) => {
          e.preventDefault();
          e.stopPropagation();
          await handleFileUpload();
        }}
        disabled={uploadProgress > 0 && uploadProgress < 100}>
        Upload Attachment To File Storage
      </Button>
    </div>
  );
};

export default UploadFileToFileStorage;

const convertToJavascriptFile = (fileAttachment: FileAttachment) => {
  if (fileAttachment && fileAttachment.contentBytes) {
    const contentBytes = fileAttachment.contentBytes || '';
    let contentType = fileAttachment.contentType || '';

    if (typeof contentBytes !== 'string') return null;
    const sliceSize = 1024;
    const byteCharacters = atob(contentBytes);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);

      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }

      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }

    const fileBlob = new Blob(byteArrays, { type: contentType });
    const fileName =
      typeof fileAttachment.name === 'string' ? fileAttachment.name : 'unknown';
    const file = new File([fileBlob], fileName, { type: contentType });
    return file;
  }

  return null;
};
