import React from 'react';
import { firebase } from 'redux/firebase';
import { useTypedSelector } from 'redux/reducers';
import { getDealData } from 'redux/actions/processes/getDealData';

export const useTarget = () => {
  const target = useTypedSelector((s) => s.communication.target);
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleTarget = (target: any) =>
    openDeal({
      ProcessInstanceId: target.piid,
      ProcessDefinitionId: target.pdid,
      ProcessStepDefinitionId: target.psdid
    });

  const openDeal = async (props: {
    ProcessInstanceId: number;
    ProcessDefinitionId: number;
    ProcessStepDefinitionId: number;
  }) => {
    setLoading(true);
    const { ProcessInstanceId, ProcessDefinitionId, ProcessStepDefinitionId } =
      props;
    const data = await getDealData({
      ProcessInstanceId,
      ProcessDefinitionId,
      ProcessStepDefinitionId
    });
    if (data) {
      setLoading(false);
      return setOpen(true);
    }
    return alert(
      'We are unable to access this deal currently, please notify administration'
    );
  };
  return { handleTarget, openDeal, open, setOpen, loading, target };
};
