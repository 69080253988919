import MaterialTable from '@material-table/core';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { DateParse } from 'common/dateParse';
import NumberFormat from 'react-number-format';
import { Capitalize } from './components/Capitalize';

interface Props {
  list: any;
  getActivityData: (props: any) => void;
  getDocumentList: (props: any) => void;
}

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

const LegacyTable = (props: Props) => {
  const [data, setData] = React.useState([]);
  const dispatch = useDispatch();
  const theme = useTheme();

  const columns = [
    {
      title: 'Deal ID',
      field: 'deal_id',
      render: (props) => (
        <div>
          <Typography style={{ fontWeight: 'bold' }}>
            {props.deal_id}
          </Typography>
        </div>
      )
    },
    {
      title: 'Customer Name',
      field: 'borrower1_name',
      render: (props) => (
        <div>
          {props && props.borrower1_name ? (
            <Typography>{props.borrower1_name}</Typography>
          ) : (
            <Typography style={{ fontStyle: 'italic' }}>No Data</Typography>
          )}
        </div>
      )
    },
    {
      title: 'Contact Name',
      field: 'borrower1_contact1_name',
      render: (props) => (
        <div>
          {props && props.borrower1_contact1_name ? (
            <Typography>{props.borrower1_contact1_name.split('.')}</Typography>
          ) : (
            <Typography style={{ fontStyle: 'italic' }}>No Data</Typography>
          )}
        </div>
      )
    },
    {
      title: 'Product Type',
      field: 'accepted_facility_type_of_product',
      render: (props) => (
        <div>
          {props && props.accepted_facility_type_of_product ? (
            Capitalize(
              props.accepted_facility_type_of_product.replaceAll('_', ' ')
            )
          ) : (
            <Typography style={{ fontStyle: 'italic' }}>No Data</Typography>
          )}
        </div>
      )
    },
    {
      title: 'Deal Status',
      field: 'deal_current_status'
    },
    {
      title: 'Net Advance',
      field: 'accepted_facility_net_advance',
      render: (props) => (
        <div>
          {props && props.accepted_facility_net_advance ? (
            <Currency value={props.accepted_facility_net_advance} />
          ) : (
            <Typography style={{ fontStyle: 'italic' }}>No Data</Typography>
          )}
        </div>
      )
    },
    {
      title: 'Proposal Paid Out Funders',
      field: 'proposal_paid_out_funders',
      render: (props) => (
        <div>
          {props && props.proposal_paid_out_funders ? (
            <Typography>{props.proposal_paid_out_funders}</Typography>
          ) : (
            <Typography style={{ fontStyle: 'italic' }}>No Data</Typography>
          )}
        </div>
      )
    }
    // {
    //   title: 'Updated At',
    //   field: 'deal_updated_at',
    //   render: (props) => <DateParse date={props?.deal_updated_at} />
    // }
  ];

  const settingData = () => {
    if (props.list !== undefined) setData(props?.list?.data);
  };

  React.useEffect(() => {
    settingData();
  }, [props.list]);

  return (
    <div style={{ padding: 10 }}>
      <MaterialTable
        title="Legacy Deal Table"
        data={data}
        columns={columns}
        onRowClick={(evt, selectedRow: any) => {
          props.getActivityData
            ? props.getActivityData({ dealID: selectedRow.deal_id })
            : '';
          props.getDocumentList
            ? props.getDocumentList({ dealID: selectedRow.deal_id })
            : '';

          dispatch({
            type: 'SET_LEGACY_DEAL_DETAIL',
            payload: selectedRow
          });

          dispatch({ type: 'SET_LEGACY_DEAL_BOOL', payload: true });
        }}
        options={{
          pageSize: 10,
          paging: true
        }}
      />
    </div>
  );
};

export default LegacyTable;
