import { store } from 'redux/store';
import { GetProcessDefinition } from 'redux/database';
import { getStepUsers, getProcessById, getProcessFields } from './';
import { PROCESS_FIELDS_ACTION } from 'types/databaseInterfaces';

const { dispatch } = store;

export const refreshOverViewData = async ({
  ProcessInstanceId,
  ProcessDefinitionId,
  ProcessStepDefinitionId
}) => {
  let action: PROCESS_FIELDS_ACTION = 'OVERVIEW';
  if (ProcessStepDefinitionId < 0) action = 'FORPROCESS';
  const config4 = {
    ProcessInstanceId,
    ProcessDefinitionId,
    ProcessStepDefinitionId: ProcessStepDefinitionId
      ? ProcessStepDefinitionId
      : 0,
    action
  };

  const res = await getProcessFields(config4);
  if (res && res.status === 200) return true;
  return false;
};

export const getDealData = async ({
  ProcessDefinitionId,
  ProcessInstanceId,
  ProcessStepDefinitionId
}: {
  ProcessDefinitionId: number;
  ProcessInstanceId: number;
  ProcessStepDefinitionId: number;
}) => {
  const config1 = { ProcessDefinitionId };
  const config2 = { Id: ProcessInstanceId, ProcessStepDefinitionId };
  const config3 = {
    ProcessInstanceId,
    ProcessStepDefinitionId
  };
  const definition = await getProcessDefinition(config1);
  const instance = await getProcessById(config2);
  const stepdata = await getStepUsers(config3);
  dispatch({ type: 'SET_CURRENT_STEP_ID', payload: ProcessStepDefinitionId });

  let action: PROCESS_FIELDS_ACTION = 'OVERVIEW';
  if (ProcessStepDefinitionId < 0) action = 'FORPROCESS';

  const config4 = {
    ProcessInstanceId,
    ProcessDefinitionId,
    ProcessStepDefinitionId: ProcessStepDefinitionId
      ? ProcessStepDefinitionId
      : 0,
    action
  };
  if (ProcessStepDefinitionId < 0) {
    return Promise.all([definition, instance]).then(async () => {
      const res = await getProcessFields(config4);
      if (res && res.status === 200) return true;
      return false;
    });
  } else {
    return Promise.all([definition, stepdata, instance]).then(async () => {
      const res = await getProcessFields(config4);
      if (res && res.status === 200) return true;
      return false;
    });
  }
};

export const getProcessDefinition = async ({
  ProcessDefinitionId,
  ProcessStepDefinitionId
}: {
  ProcessDefinitionId: number;
  ProcessStepDefinitionId?: number;
}) =>
  GetProcessDefinition({
    ProcessDefinitionId,
    ProcessStepDefinitionId // Optional
  })
    .then((res) => {
      if (res && res.status === 200) {
        dispatch({ type: 'SET_PROCESS_DEFINITION', payload: res.data });
        return res;
      }
    })
    .catch((e) => e);
