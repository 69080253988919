import { Button, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CloudDownload } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IEvent } from 'components/Calendar/interface';
import { theme } from 'theme';
import LinkIcon from '@material-ui/icons/Link';
import { useDispatch } from 'react-redux';

const EventDealLinked = ({
  event,
  handleClose
}: {
  event: IEvent;
  handleClose: () => void;
}) => {
  const dispatch = useDispatch();
  const handleCloseNotifications = (notification) => {
    dispatch({ type: 'SET_TARGET', payload: notification });
    handleClose();
  };

  return (
    <>
      <Alert>
        <AlertTitle>
          Deal Linked: {event.extendedProps.dealInformation?.piid}
        </AlertTitle>
        <Typography variant="h6" color="textSecondary">
          This event has been linked in your calendar, allowing you to easily
          navigate to the deal through the provided link.
        </Typography>
      </Alert>
      <div style={{ paddingBottom: theme.spacing(1) }} />
      <Button
        fullWidth
        onClick={() =>
          handleCloseNotifications(event.extendedProps.dealInformation)
        }
        variant="outlined"
        startIcon={
          <LinkIcon
            fontSize="large"
            style={{
              paddingRight: theme.spacing(1),
              color: grey[500],
              width: '30px'
            }}
            color="secondary"
          />
        }>
        Go to Linked Deal
      </Button>
    </>
  );
};

export default EventDealLinked;
