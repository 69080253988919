import { IFirestoreAuditLog } from 'hooks/useDeals/useAuditLog';
import { IReqCmp, IRequiredFields } from 'hooks/useDeals/useRequiredCompletion';
import { createContext, Dispatch, SetStateAction } from 'react';
import { IProposalList } from 'redux/database/Lender API/interface';

export interface IStepperProvider {
  getData: ({
    activeStep,
    move
  }: {
    activeStep?: number | undefined;
    move?: boolean | undefined;
  }) => Promise<void>;
  refreshDealData: () => Promise<boolean>;
  duplicate: () => void;
  close: () => void;
  setQuotesRefreshing: Dispatch<SetStateAction<boolean>>;
  quotesRefreshing: boolean;
  isDealClosed: boolean;
  isDealTransferred: boolean;
  auditLogs: IFirestoreAuditLog[];
  ReqCmp: IReqCmp;
  handleUpdateReqCmp: () => void;
  ReqFields: IRequiredFields[];
  proposalsList: IProposalList[];
  setProposalsList: Dispatch<SetStateAction<IProposalList[]>>;
}

export const StepperContext = createContext<IStepperProvider>({
  getData: async () => undefined,
  refreshDealData: async () => false,
  duplicate: async () => undefined,
  close: () => undefined,
  setQuotesRefreshing: () => undefined,
  quotesRefreshing: false,
  isDealClosed: false,
  isDealTransferred: false,
  auditLogs: [],
  ReqCmp: {
    _Percent_Required_Fields_Complete: 0,
    _Percent_All_Fields_Complete: 0,
    _requiredfieldscomp: 0,
    _reqfieldsnum: [],
    _allfieldsnum: 0,
    _allfieldscomp: 0
  },
  handleUpdateReqCmp: () => undefined,
  ReqFields: [],
  proposalsList: [],
  setProposalsList: () => []
});
