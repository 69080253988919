import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const MotTests = ({ item, theme }) => (
  <Grid
    container
    direction="row"
    justifyContent="space-around"
    alignItems="baseline">
    <Grid
      item
      style={{
        paddingRight: theme.spacing(4)
      }}>
      <Typography
        style={{
          fontSize: '12px',
          color: 'grey'
        }}>
        Mot Test Number:
      </Typography>
      <Typography>
        {item?.motTestNumber ? item.motTestNumber : 'No Data'}
      </Typography>
    </Grid>
    <Grid
      item
      style={{
        paddingRight: theme.spacing(4)
      }}>
      <Typography
        style={{
          fontSize: '12px',
          color: 'grey'
        }}>
        Odometer Unit:
      </Typography>
      <Typography>
        {item?.odometerUnit ? item.odometerUnit : 'No Data'}
      </Typography>
    </Grid>
    <Grid
      item
      style={{
        paddingRight: theme.spacing(4)
      }}>
      <Typography
        style={{
          fontSize: '12px',
          color: 'grey'
        }}>
        Odometer Value:
      </Typography>
      <Typography>
        {item?.odometerValue ? item.odometerValue : 'No Data'}
      </Typography>
    </Grid>
    <Grid
      item
      style={{
        paddingRight: theme.spacing(4)
      }}>
      <Typography
        style={{
          fontSize: '12px',
          color: 'grey'
        }}>
        Odometer Result Type:
      </Typography>
      <Typography>
        {item?.odometerResultType ? item.odometerResultType : 'No Data'}
      </Typography>
    </Grid>
    <Grid
      item
      style={{
        paddingRight: theme.spacing(4)
      }}>
      <Typography
        style={{
          fontSize: '12px',
          color: 'grey'
        }}>
        Expiry Date:
      </Typography>
      <Typography>{item?.expiryDate ? item.expiryDate : 'No Data'}</Typography>
    </Grid>
  </Grid>
);

export default MotTests;
