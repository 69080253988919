import { Calculation } from 'types/calculatorInterfaces';
import { FunderDoc } from '../../interfaces';

const FunderCommissionInvoice = ({
  FUNDER_DOC,
  Output
}: {
  FUNDER_DOC: FunderDoc;
  Output: Calculation;
}) => {
  FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_AMOUNT = Output['_DocumentFee']?.DocFee;

  FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_VAT =
    Output['_DocumentFee']?.DocFee *
    (Output['_DocumentFee']?.DocFee_VatRate / 100);

  FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_TOTAL =
    FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_AMOUNT +
    FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_VAT;

  FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_AMOUNT =
    Output['_DocumentFee']?.DocFeeUpsell;

  FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_VAT =
    Output['_DocumentFee']?.DocFeeUpsell *
    (Output['_DocumentFee']?.DocFee_VatRate / 100); // Does the Doc Fee Upsell have any VAT or is this always 0?

  FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_TOTAL =
    FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_AMOUNT +
    FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_VAT;

  FUNDER_DOC.II_TOTAL_DOCUMENT_FEE_AMOUNT =
    FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_AMOUNT +
    FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_AMOUNT;

  FUNDER_DOC.II_TOTAL_DOCUMENT_FEE_VAT =
    FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_VAT +
    FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_VAT;

  FUNDER_DOC.II_TOTAL_DOCUMENT_FEE_TOTAL =
    FUNDER_DOC.I_DOCUMENT_FEE_STANDALONE_TOTAL +
    FUNDER_DOC.II_DOCUMENT_FEE_UPSELL_TOTAL;

  FUNDER_DOC.DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_AMOUNT =
    Output['_DocumentFee']?.TotalDocFee;

  FUNDER_DOC.DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_VAT =
    Output['_DocumentFee']?.DocFee_Vat;

  FUNDER_DOC.DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_TOTAL =
    Output['_DocumentFee']?.TotalDocFee + Output['_DocumentFee']?.DocFee_Vat;

  FUNDER_DOC.GROSS_COMMISSION_AMOUNT =
    Output['_Commission']?.Broker_Commission +
    Output['_Commission']?.Introducer_Fee +
    Output['_Commission']?.Funder_Fee;

  FUNDER_DOC.GROSS_COMMISSION_VAT =
    Output['_Commission']?.Broker_Vat +
    Output['_Commission']?.Introducer_Vat +
    Output['_Commission']?.Funder_Vat;

  FUNDER_DOC.GROSS_COMMISSION_TOTAL =
    Output['_Commission']?.Broker_Commission +
    Output['_Commission']?.Broker_Vat +
    Output['_Commission']?.Introducer_Fee + // Should Introducer Fees be included here?
    Output['_Commission']?.Introducer_Vat +
    Output['_Commission']?.Funder_Fee +
    Output['_Commission']?.Funder_Vat;

  FUNDER_DOC.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT =
    Output['_Commission']?.Broker_Commission +
    Output['_Commission']?.Introducer_Fee +
    Output['_Commission']?.Funder_Fee +
    Output['_DocumentFee']?.TotalDocFee;

  FUNDER_DOC.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_VAT =
    Output['_Commission']?.Broker_Vat +
    Output['_Commission']?.Introducer_Vat +
    Output['_Commission']?.Funder_Vat +
    Output['_DocumentFee']?.DocFee_Vat;

  FUNDER_DOC.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_TOTAL =
    Output['_Commission']?.Broker_Commission +
    Output['_Commission']?.Broker_Vat +
    Output['_Commission']?.Introducer_Fee + // Should Introducer Fees be included here?
    Output['_Commission']?.Introducer_Vat +
    Output['_Commission']?.Funder_Fee +
    Output['_Commission']?.Funder_Vat +
    Output['_DocumentFee']?.TotalDocFee +
    Output['_DocumentFee']?.DocFee_Vat;

  FUNDER_DOC.INTRODUCER_FEE_AMOUNT = Output['_Commission'].Introducer_Fee;

  FUNDER_DOC.INTRODUCER_FEE_VAT = Output['_Commission'].Introducer_Vat;

  FUNDER_DOC.INTRODUCER_FEE_TOTAL =
    Output['_Commission'].Introducer_Fee + Output['_Commission'].Introducer_Vat;

  FUNDER_DOC.NET_COMMISSION_AMOUNT =
    Output['_Commission']?.Broker_Commission +
    Output['_Commission']?.Funder_Fee;

  FUNDER_DOC.NET_COMMISSION_VAT =
    Output['_Commission']?.Broker_Vat + Output['_Commission']?.Funder_Vat;

  FUNDER_DOC.NET_COMMISSION_TOTAL =
    Output['_Commission']?.Broker_Commission +
    Output['_Commission']?.Broker_Vat +
    Output['_Commission']?.Funder_Fee +
    Output['_Commission']?.Funder_Vat;

  FUNDER_DOC.BALLOON = Output._Balloon.Balloon;
  return FUNDER_DOC;
};

export default FunderCommissionInvoice;
