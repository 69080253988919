import { INIT_STATE } from 'components/Calculator/Utils/calculations';
import { Calculation } from 'types/calculatorInterfaces';
import { FieldDefinition, FieldInstance } from 'types/interfaces';

const parseFieldValue = (value: string): any => {
  if (!isNaN(Number(value))) {
    return parseFloat(value);
  } else if (
    value.toLowerCase() === 'true' ||
    value.toLowerCase() === 'false'
  ) {
    return value.toLowerCase() === 'true';
  } else if (Date.parse(value)) {
    return new Date(value);
  } else {
    try {
      const parsedValue = JSON.parse(value);
      if (typeof parsedValue === 'object' && parsedValue !== null) {
        return sanitizeObjectKeys(parsedValue);
      }
      return parsedValue;
    } catch {
      return value;
    }
  }
};
const sanitizeObjectKeys = (obj: unknown): unknown => {
  if (Array.isArray(obj)) {
    return obj.map(sanitizeObjectKeys);
  } else if (typeof obj === 'object' && obj !== null) {
    return Object.keys(obj).reduce((acc, key) => {
      const sanitizedKey = key.replace(/"/g, '');
      (acc as Record<string, unknown>)[sanitizedKey] = sanitizeObjectKeys(
        (obj as Record<string, unknown>)[key]
      );
      return acc;
    }, {} as Record<string, unknown>);
  }
  return obj;
};

const isCalculationField = (
  fieldName: string
): fieldName is keyof Calculation => {
  return fieldName in INIT_STATE;
};

export const mapFieldsToCalculation = ({
  FieldDefinitionList,
  FieldInstanceList
}: {
  FieldDefinitionList: FieldDefinition[];
  FieldInstanceList: FieldInstance[];
}): Calculation => {
  const calculation: Partial<Calculation> = {};

  FieldInstanceList.forEach((instance) => {
    const definition = FieldDefinitionList.find(
      (def) => def.Id === instance.FieldDefinitionId
    );
    if (definition) {
      const fieldName = definition.Title as keyof Calculation;
      if (isCalculationField(fieldName)) {
        calculation[fieldName] = parseFieldValue(instance.FieldValue);
      }
    }
  });

  return calculation as Calculation;
};
