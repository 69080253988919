import { useApolloClient } from '@apollo/client';
import { RedflagClient } from 'App';
import { useRedFlagGQLAdaptor } from 'components/OffkeyCompanyAndDirectorSearch/adaptors';
// import { FilterCompanyListData } from 'components/RedFlag/functions';
import { mapDataToUserDefinition } from 'components/Table/RedFlag/functions';
import { useCallback } from 'react';
import { importRedFlagData } from 'redux/actions/redFlag';
import { getUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  CompleteUserInstance,
  FieldInstance,
  UserInstance
} from 'types/interfaces';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { getCrmToken } from 'redux/actions/GraphqlCrmActions';

export const useRedflagUpdate = () => {
  // GQL
  const graphqlCrmState = useTypedSelector((s) => s.graphqlCrm);
  const { getCompanyByCompanyNumber } = useRedFlagGQLAdaptor(RedflagClient);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const importData = useCallback(
    async ({
      rowData,
      CompleteObjectDefinition,
      isCompany,
      company
    }: {
      rowData: CompleteUserInstance;
      CompleteObjectDefinition: CompleteObjectDefinition[];
      isCompany: boolean;
      company: IRedflagAPIData | null;
    }) => {
      try {
        //* Company === null Only happens when importing from Deals
        if (company === null) {
          return getRedflagCompany({
            companyNo: rowData.UserInstance.UserInstanceEmail,
            newCompleteObject: CompleteObjectDefinition,
            userInstance: rowData
          });
        }

        const data = await mapDataToUserDefinition({
          isCompany,
          CODL: CompleteObjectDefinition,
          company,
          rowData: rowData
        });

        const res = await importRedFlagData({ data });
        if (res && res.status === 200) {
          await getUserInstanceDetail({
            baseUrl,
            UserInstanceId: rowData.UserInstance.Id,
            fetchPolicy: true
          });
        }
      } catch (error) {
        console.log({ error });
      }
    },

    []
  );

  const getRedflagCompany = useCallback(
    async ({
      companyNo,
      newCompleteObject,
      userInstance
    }: {
      companyNo: number;
      newCompleteObject: CompleteObjectDefinition[];
      userInstance: CompleteUserInstance;
    }) => {
      if (companyNo) {
        let newToken;
        if (
          !graphqlCrmState.loaded ||
          graphqlCrmState.willExpire <= Date.now()
        ) {
          const crmToken = await getCrmToken();
          newToken = crmToken.access_token;
        }

        const company: IRedflagAPIData = await getCompanyByCompanyNumber(
          companyNo.toString(),
          newToken
        );

        try {
          if (company && userInstance) {
            await importData({
              rowData: userInstance,
              isCompany: true,
              company,
              CompleteObjectDefinition: newCompleteObject
            });
          }
        } catch (error) {
          console.error('Error fetching company data:', error);
        }
      }
    },
    [importData]
  );

  return { importData };
};
