import { Dispatch, SetStateAction, useContext, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  Select,
  Tooltip
} from '@material-ui/core';
import SimpleTable from 'components/SimpleTable';
import { RelationShipActions } from 'redux/database';
import { useTypedSelector } from 'redux/reducers';
import { UserInstance } from 'types/interfaces';
import QuickUserFromId from 'components/User/QuickUserFromId';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { PrimaryButton } from 'common';
import { IRelationshipTable } from './interfaces';
import { EmailCompositionCreationContext } from 'components/MessageHub/components/ComposeWindows/context/ComposeWindowsContext';
import { AuthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { ParentComponentComposeWindows } from 'components/MessageHub/components/ComposeWindows';
import { validateEmail } from 'helpers/validateEmail';
import { Block, Delete } from '@material-ui/icons';
import { getRelationship } from 'redux/actions/GraphQlActions';
import {
  IRelationshipData,
  IRelationshipInstance,
  RelatedUserPermission
} from 'redux/actions/GraphQlActions/interface';
import { determineIfUpdateRelationships } from 'helpers/permissions';

export const UserRelationships = ({
  handleRowClick,
  data,
  title,
  getRelationships: getRelationshipData,
  setData,
  Id,
  primaryUserInstance
}: {
  handleRowClick: (props: IRelationshipTable) => void;
  data: UserInstance[];
  title: string;
  getRelationships: () => Promise<void>;
  setData: Dispatch<SetStateAction<UserInstance[]>>;
  Id: number;
  primaryUserInstance: UserInstance;
}) => {
  const { addComposeEmail, composeWindows } = useContext(
    EmailCompositionCreationContext
  );
  const isAuth = useIsAuthenticated();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const loggedInUser = useTypedSelector((s) => s.user.user);

  const [deletingRows, setDeletingRows] = useState<number[]>([]);

  const deleteRelationship = async ({
    PrimaryUserInstanceId,
    RelatedUserInstanceId
  }: {
    PrimaryUserInstanceId: number;
    RelatedUserInstanceId: number;
  }) => {
    setDeletingRows((prev) => [...prev, RelatedUserInstanceId]);

    const response = await getRelationship({
      action: 'DELETE',
      PrimaryUserInstanceId: PrimaryUserInstanceId,
      RelatedUserInstanceId: RelatedUserInstanceId,
      baseUrl,
      UserDefinitionId: primaryUserInstance.UserDefinitionId
    });

    if (response === '') {
      await getRelationshipData();
      setDeletingRows((prev) =>
        prev.filter((id) => id !== RelatedUserInstanceId)
      );
    }
  };

  const baseColumns: any[] = [
    {
      field: 'Title',
      title: 'Title',
      editable: 'never'
    },
    {
      title: 'Email',
      field: 'UserInstanceEmail',
      render: (rowData) => {
        if (!validateEmail(rowData.UserInstanceEmail)) {
          return rowData.UserInstanceEmail;
        }

        if (!isAuth) return rowData.UserInstanceEmail;
        return (
          <span
            style={{ cursor: 'pointer', color: 'blue' }}
            onClick={() => {
              const email: string[] = [rowData.UserInstanceEmail];

              addComposeEmail(undefined, email);
            }}>
            {rowData.UserInstanceEmail}
          </span>
        );
      }
    },
    {
      field: 'RelatedUserPermission',
      title: 'Type',
      render: (rowData: IRelationshipTable) => {
        const permission =
          rowData.RelatedUserPermission === 2
            ? RelatedUserPermission.Write
            : rowData.RelatedUserPermission === 1
            ? RelatedUserPermission.Read
            : RelatedUserPermission.Standard;

        const canEdit = determineIfUpdateRelationships({ rowData });
        if (!canEdit) return <div>{permission}</div>;
        else {
          return (
            <Select
              defaultValue={rowData.RelatedUserPermission}
              native
              onChange={async (e) => {
                const permissionData = e.target.value;
                const relatedUserPermission: RelatedUserPermission =
                  permissionData === '2'
                    ? RelatedUserPermission.Write
                    : permissionData === '1'
                    ? RelatedUserPermission.Read
                    : RelatedUserPermission.Standard;

                const PrimaryUserInstanceId: number = parseInt(Id.toString());
                const RelatedUserInstanceId: number = parseInt(
                  rowData.Id.toString()
                );

                const config: IRelationshipData = {
                  Title: '',
                  RelatedUserInstanceId,
                  PrimaryUserInstanceId,
                  Status: 0,
                  RelatedUserPermission: relatedUserPermission
                };

                const UserDefinitionId = parseInt(
                  primaryUserInstance.UserDefinitionId.toString()
                );

                const UserInstanceId = parseInt(
                  primaryUserInstance.Id.toString()
                );
                const response = (await getRelationship({
                  action: 'CREATE',
                  data: config,
                  baseUrl,
                  UserDefinitionId,
                  UserInstanceId
                })) as IRelationshipInstance | undefined;

                if (response) {
                  const dataUpdate = [...data];

                  // THIS WILL NOT WORK AS RelatedUserPermission ISN'T A NUMBER
                  // const index = rowData.tableData.id;
                  // REVIEW ONCE QUERY IS ADDED TO MUTATION
                  // dataUpdate[index].RelatedUserPermission =
                  //   RelatedUserPermission;

                  await getRelationshipData();
                  return setData([...dataUpdate]);
                }
              }}>
              <option value={0}>Standard</option>
              <option value={1}>Read</option>
              {canEdit && <option value={2}>Write</option>}
            </Select>
          );
        }
      }
    },
    {
      render: (rowData: IRelationshipTable) => (
        <PrimaryButton
          variant="contained"
          onClick={() => handleRowClick(rowData)}>
          View
          <ArrowForwardIosIcon style={{ marginLeft: 8, fontSize: 16 }} />
        </PrimaryButton>
      )
    }
  ];

  const hasActionableRows = data.some((row) => row.OwnerUserInstanceId !== 0);
  if (hasActionableRows) {
    baseColumns.unshift({
      title: 'Actions',
      render: (rowData: IRelationshipTable) => {
        if (deletingRows.includes(rowData.Id)) {
          return <CircularProgress size={30} />;
        }

        if (rowData.OwnerUserInstanceId === 0) {
          return (
            <Tooltip
              arrow
              title={
                "Action Denied: System-Owned Relationships Can't Be Removed"
              }>
              <div style={{ display: 'inline-block' }}>
                <IconButton disabled>
                  <Block />
                </IconButton>
              </div>
            </Tooltip>
          );
        }

        return (
          <Tooltip
            arrow
            title={
              rowData.OwnerUserInstanceId !== loggedInUser.Id &&
              loggedInUser.SystemAccess < 10
                ? "Action Unavailable: You're Not The Relationship Owner"
                : `Delete "${rowData.UserInstanceEmail}" Relationship With ${primaryUserInstance.Title}`
            }>
            <div style={{ display: 'inline-block' }}>
              <IconButton
                disabled={
                  loggedInUser.SystemAccess >= 10
                    ? false
                    : rowData.OwnerUserInstanceId !== loggedInUser.Id
                }
                onClick={async () => {
                  await deleteRelationship({
                    PrimaryUserInstanceId: primaryUserInstance.Id,
                    RelatedUserInstanceId: rowData.Id
                  });
                }}>
                <Delete />
              </IconButton>
            </div>
          </Tooltip>
        );
      }
    });
  }

  const ownerColumn = {
    field: 'OwnerUserInstanceId',
    title: 'Owner',
    editable: 'never',
    render: (data: IRelationshipTable) => {
      const ownerType =
        data.OwnerUserInstanceId === 0 ? (
          'System'
        ) : (
          <QuickUserFromId
            UserInstanceId={data.OwnerUserInstanceId.toString()}
          />
        );
      return <div>{ownerType}</div>;
    }
  };

  const columns = [...baseColumns];
  if (composeWindows.length < 1) {
    columns.splice(columns.length - 1, 0, ownerColumn);
  }

  return (
    <>
      <AuthenticatedTemplate>
        <ParentComponentComposeWindows />
      </AuthenticatedTemplate>

      <SimpleTable columns={columns} data={data} title={title} />
    </>
  );
};
