import { useContext, useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Fab,
  Slide,
  Popover,
  TextField,
  Box,
  Paper,
  CircularProgress,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { Add, ExitToApp } from '@material-ui/icons';
import { theme } from 'theme';

import { SelectionContext } from '../context/SelectionContext';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { useDispatch } from 'react-redux';

import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
import { IMicrosoftCustomFolder } from 'components/MessageHub/context/interface';

interface IFolderState {
  showButtons: boolean;
  parentFolderName: string;
  childFolderName: string;
  anchorEl: HTMLElement | null;
  subfolderAnchorEl: HTMLElement | null;
  allParents: { id: string; name: string }[] | null;
  selectedParentId: { id: string; name: string } | null;
}

const INIT_FOLDER: IFolderState = {
  showButtons: false,
  parentFolderName: '',
  childFolderName: '',
  anchorEl: null,
  subfolderAnchorEl: null,
  allParents: null,
  selectedParentId: null
};

const SelectionAction = ({ handleCategorySelection }) => {
  const dispatch = useDispatch();

  const { logout, folderIds } = useContext(MessageHubContext);
  const { createNewFolder, createNewSubFolder, folderLoading } =
    useContext(SelectionContext);

  const [folderState, setFolderState] = useState<IFolderState>(INIT_FOLDER);

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    setFolderState({ ...folderState, anchorEl: e.currentTarget });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFolderState({ ...folderState, parentFolderName: e.target.value });
  };

  const handleClose = () => {
    setFolderState((prevState) => ({
      ...INIT_FOLDER,
      allParents: prevState.allParents
    }));
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setFolderState({ ...folderState, anchorEl: null });
    e.preventDefault();

    await createNewFolder(folderState.parentFolderName);
    handleClose();
  };

  const handleSubfolderClick = (event: React.MouseEvent<HTMLElement>) => {
    setFolderState({ ...folderState, subfolderAnchorEl: event.currentTarget });
  };

  const handleSubfolderSubmit = () => {
    if (folderState.selectedParentId && folderState.childFolderName) {
      createNewSubFolder(
        folderState.childFolderName,
        folderState.selectedParentId.id
      );
    }
    handleClose();
  };

  useEffect(() => {
    if (!folderState.showButtons) {
      setFolderState((prevState) => ({
        ...INIT_FOLDER,
        allParents: prevState.allParents
      }));
    }
  }, [folderState.showButtons]);

  useEffect(() => {
    let customFolders: { id: string; name: string }[] = [];
    folderIds?.customFolders.forEach((element: IMicrosoftCustomFolder) => {
      if (element.id) {
        const customFoldersIds: { id: string; name: string } = {
          id: element.id,
          name: element.displayName
        };
        customFolders.push(customFoldersIds);
      }
    });

    const inboxId = folderIds?.inboxFolder.id;
    const sentItemsId = folderIds?.sentItemsFolder.id;
    const archiveId = folderIds?.archive.id;

    let allParents = [...customFolders];
    if (inboxId) {
      const inbox = {
        id: inboxId,
        name: 'Inbox'
      };
      allParents.push(inbox);
    }

    if (sentItemsId) {
      const sentItems = {
        id: sentItemsId,
        name: 'Sent Items'
      };
      allParents.push(sentItems);
    }

    if (archiveId) {
      const archive = {
        id: archiveId,
        name: 'Archive'
      };
      allParents.push(archive);
    }

    setFolderState((prevState) => ({ ...prevState, allParents }));
  }, [folderIds, !folderState.showButtons]);

  return (
    <>
      <Tooltip title={'Logout'} arrow>
        <div
          style={{
            position: 'fixed',
            right: '32px',
            bottom: theme.spacing(5),
            zIndex: 5
          }}>
          <Fab
            color="primary"
            aria-label="remove"
            onClick={async () => {
              await logout();
              dispatch(
                createNotification(
                  successNotif("You've Successfully Logged Out!")
                )
              );
            }}>
            <ExitToApp />
          </Fab>
        </div>
      </Tooltip>

      <div
        onMouseEnter={() =>
          setFolderState({ ...folderState, showButtons: true })
        }
        onMouseLeave={() =>
          setFolderState({ ...folderState, showButtons: false })
        }
        style={{
          position: 'fixed',
          right: '100px',
          zIndex: 5,
          bottom: theme.spacing(5)
        }}>
        <Fab color="primary" aria-label="add" style={{ zIndex: 50 }}>
          <Add />
        </Fab>

        {folderState.showButtons && (
          <Slide
            direction="left"
            in={folderState.showButtons}
            mountOnEnter
            unmountOnExit>
            <Box
              display="flex"
              style={{
                padding: theme.spacing(1.3),
                paddingRight: theme.spacing(8),
                position: 'fixed',
                bottom: theme.spacing(5),
                right: '100px',
                backgroundColor: theme.palette.grey[400],
                borderRadius: 20,
                borderStartEndRadius: 30,
                borderEndEndRadius: 30
              }}>
              <Grid container spacing={1}>
                {folderLoading.parentFolder ? (
                  <Grid
                    item
                    style={{
                      paddingRight: theme.spacing(4),
                      paddingLeft: theme.spacing(4),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <CircularProgress size={30} />
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleClick}>
                      Create Folder
                    </Button>
                  </Grid>
                )}
                <Popover
                  open={Boolean(folderState.anchorEl)}
                  anchorEl={folderState.anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}>
                  <Paper style={{ padding: theme.spacing(2) }}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Folder Name"
                          value={folderState.parentFolderName}
                          onChange={handleInputChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          disabled={folderState.parentFolderName === ''}
                          onClick={(e) => handleSubmit(e)}
                          type="submit"
                          variant="contained"
                          color="primary">
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Popover>
                {folderLoading.childFolder ? (
                  <Grid
                    item
                    style={{
                      paddingRight: theme.spacing(4),
                      paddingLeft: theme.spacing(4),
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}>
                    <CircularProgress size={30} />
                  </Grid>
                ) : (
                  <Grid item>
                    <Button
                      color="secondary"
                      variant="contained"
                      onClick={handleSubfolderClick}>
                      Create Subfolder
                    </Button>
                  </Grid>
                )}

                <Popover
                  open={Boolean(folderState.subfolderAnchorEl)}
                  anchorEl={folderState.subfolderAnchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}
                  transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                  }}>
                  <Paper style={{ padding: 16 }}>
                    <Grid container direction="column" spacing={2}>
                      <Grid item xs={12}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="parent-folder-label">
                            Parent Folder
                          </InputLabel>
                          <Select
                            label="Parent Folder"
                            labelId="parent-folder-label"
                            value={folderState.selectedParentId?.id || ''}
                            onChange={(e) => {
                              const selectedId = e.target.value as string;
                              const selectedParent =
                                folderState.allParents?.find(
                                  (p) => p.id === selectedId
                                ) || null;
                              setFolderState({
                                ...folderState,
                                selectedParentId: selectedParent
                              });
                            }}
                            variant="outlined"
                            fullWidth>
                            <MenuItem disabled style={{ fontWeight: 'bold' }}>
                              Predefined Folders Section
                            </MenuItem>
                            {['Inbox', 'Sent Items', 'Archive'].map((name) => {
                              const parent = folderState.allParents?.find(
                                (p) => p.name === name
                              );
                              return parent ? (
                                <MenuItem key={parent.id} value={parent.id}>
                                  {parent.name}
                                </MenuItem>
                              ) : null;
                            })}

                            <MenuItem disabled style={{ fontWeight: 'bold' }}>
                              Custom Folders Section
                            </MenuItem>
                            {folderState.allParents?.map((parent) => {
                              if (
                                ['Inbox', 'Sent Items', 'Archive'].includes(
                                  parent.name
                                )
                              ) {
                                return null;
                              }
                              return (
                                <MenuItem key={parent.id} value={parent.id}>
                                  {parent.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Subfolder Name"
                          value={folderState.childFolderName}
                          onChange={(e) =>
                            setFolderState({
                              ...folderState,
                              childFolderName: e.target.value
                            })
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          fullWidth
                          disabled={
                            !folderState.childFolderName ||
                            !folderState.selectedParentId
                          }
                          onClick={handleSubfolderSubmit}
                          variant="contained"
                          color="primary">
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Popover>

                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleCategorySelection('Create New Email')}>
                    Create Email
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
        )}
      </div>
    </>
  );
};

export default SelectionAction;
