import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress, Box } from '@material-ui/core';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ClearIcon from '@material-ui/icons/Clear';
import RefreshIcon from '@material-ui/icons/Refresh';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { MdDomainVerification } from 'react-icons/md';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Select, MenuItem } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import DeleteDomainDialog from './deleteDomainDialog';
import { SuccessButton } from 'common/Button';
import { CustomTooltip } from '../components';
import {
  fetchDomains,
  deleteDomain,
  createDomain,
  verifyDomain
} from './functions';
import { useInstance } from 'hooks/useInstance';
import { useTypedSelector } from 'redux/reducers';
import { Domain, DNS_TYPES } from './types';
import { QueryKeys } from '../types';
import Paginator from 'components/Marketing/components/Paginator';

import './index.css';

const grey = '#68696b';
const green = '#66CDAA';

const useStyles = makeStyles((theme: Theme) => {
  const grey100 = theme.palette.grey[100];
  const grey300 = theme.palette.grey[300];
  const grey400 = theme.palette.grey[400];

  const fieldset = {
    borderRadius: theme.shape.borderRadius,
    textAlign: 'center' as const,
    border: `2px solid ${grey400}`
  };

  return createStyles({
    container: {
      maxWidth: 900,
      marginBottom: 50,
      margin: '0 auto',
      border: `1px solid ${grey300}`,
      borderRadius: theme.shape.borderRadius,
      backgroundColor: grey100,
      padding: 20
    },
    table: {
      width: '95%',
      padding: 10,
      margin: '0 auto'
    },
    copyable: {
      textAlign: 'center',
      padding: '0px 8px',
      '&:hover': {
        background: `${theme.palette.primary.light}66`,
        border: `1px ${theme.palette.primary.main} dashed`,
        borderRadius: theme.shape.borderRadius,
        opacity: 0.5,
        cursor: 'pointer'
      }
    },
    errorBox: {
      background: 'orange'
    },
    dnsFieldset: {
      ...fieldset,
      marginTop: 10,
      paddingBottom: 10
    },
    formContainer: {
      border: `1px dashed lightgrey`,
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      gap: 20,
      maxWidth: 900,
      margin: '20px auto'
    }
  });
});

const DomainAuthentication = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { isAFS, isSynergy, isDual } = useInstance();
  const user = useTypedSelector((s) => s.user.user);

  const isSuperAdmin = user.SystemAccess === 15;
  const [newDomain, setNewDomain] = React.useState('');

  const { data: domains = [], isLoading } = useQuery(
    [QueryKeys.FetchDomains],
    () =>
      fetchDomains({
        isAFS,
        isSynergy,
        isSuperAdmin
      }),
    { enabled: true }
  );

  const [paginatedDomains, setPaginatedDomains] = useState<Domain[]>(domains);

  const { mutate: create, isLoading: authenticateLoading } = useMutation(
    createDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const { mutate: remove, isLoading: deleteLoading } = useMutation(
    deleteDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const { mutate: verify, isLoading: verifyLoading } = useMutation(
    verifyDomain,
    { onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchDomains) }
  );

  const loading =
    isLoading || authenticateLoading || deleteLoading || verifyLoading;

  const synergy = isSynergy || isDual || isSuperAdmin;
  const afs = isAFS || isDual || isSuperAdmin;
  const localhost =
    `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}` === 'localhost' ||
    isSuperAdmin;

  const validateDomainRow = (val) => {
    if (val) return <VerifiedUserIcon style={{ color: green }} />;
    return <ClearIcon style={{ color: 'red' }} />;
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          opacity: 0.8
        }}>
        <MdDomainVerification
          style={{
            marginRight: 10,
            fontSize: 50,
            color: 'grey'
          }}
        />
        <Typography
          variant="h3"
          style={{
            color: 'grey'
          }}>
          Email Domain Authentication
        </Typography>
      </div>

      <Alert severity="info">
        <Typography variant="h5">
          In order to send marketing emails you must authenticate your domain
          first:
        </Typography>
        <Typography>
          <ol>
            <li>
              Enter your domain name in the <b>Domain*</b> field (e.g.{' '}
              <b>my-business.com</b>).
            </li>
            <li>
              In most cases this is all you will need, press <b>Next</b> (If you
              need to authenticate a specific subdomain or use custom security
              this is possible).
            </li>
            <li>
              Once you have received your <b>cname</b> records, you will need to
              enter these into your email hosting providers DNS configuration.
            </li>
            <li>
              Use the refresh button to test the validity of your records.
            </li>
          </ol>
        </Typography>
      </Alert>
      <br />

      <Grid container className={classes.formContainer}>
        <Typography variant="h4">Add New Domain:</Typography>

        <CustomTooltip
          enterNextDelay={100}
          placement="right-end"
          title="The Domain being authenticated.">
          <FormControl variant="outlined" margin="dense" fullWidth required>
            <InputLabel>Domain</InputLabel>
            <Select
              label="Domain"
              placeholder="Domain"
              value={newDomain}
              onChange={(e) => {
                setNewDomain(e.target.value as string);
              }}>
              {synergy && (
                <MenuItem value="synergy.finance.com" key={1}>
                  synergy.finance.com
                </MenuItem>
              )}
              {afs && (
                <MenuItem value="afsuk.com" key={2}>
                  afsuk.com
                </MenuItem>
              )}
              {localhost && (
                <MenuItem value="offkey-ltd.com" key={3}>
                  offkey-ltd.com
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </CustomTooltip>

        <Box style={{ paddingTop: 4 }}>
          <SuccessButton
            fullWidth
            size="large"
            color="primary"
            onClick={() =>
              create({
                domain: newDomain,
                userInstanceId: user.Id
              })
            }
            variant="outlined">
            {loading ? (
              <span style={{ color: 'white ' }}>
                <CircularProgress color="inherit" size={20} />
              </span>
            ) : (
              'Submit'
            )}
          </SuccessButton>
        </Box>
      </Grid>

      <br />
      <>
        {paginatedDomains.map((domain, i: number) => {
          const { dns } = domain;

          const dnsData: any[] = DNS_TYPES.map((d, i) => {
            const dnsType = dns[d];

            if (!dnsType) return;

            return dnsType;
          }).filter(Boolean);

          return (
            <div key={i} className={classes.container}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginBottom: -10
                }}>
                <div>{validateDomainRow(domain.valid)}</div>
                <div
                  style={{
                    padding: 8,
                    flexGrow: 1
                  }}>
                  <Typography variant="h4" style={{ color: grey }}>
                    {domain.domain.toLowerCase()}
                  </Typography>
                </div>
                <div>
                  <CustomTooltip placement="top" title="Refresh Domain Status">
                    <IconButton onClick={() => verify(domain)}>
                      <RefreshIcon />
                    </IconButton>
                  </CustomTooltip>
                </div>

                <div>
                  <DeleteDomainDialog
                    handleDeleteDomain={() => remove(domain)}
                  />
                </div>
              </div>

              <fieldset className={classes.dnsFieldset}>
                <legend>
                  <h3 style={{ padding: 10 }}>DNS records</h3>
                </legend>

                <table className={classes.table} key={i}>
                  <tr id="dns">
                    <th>Status</th>
                    <th>Type</th>
                    <th>Host</th>
                    <th>Value</th>
                  </tr>

                  {dnsData.map((d, i) => {
                    return (
                      <>
                        <tr id="dns" style={{ fontWeight: 500, color: grey }}>
                          <td id="dns">{validateDomainRow(d.valid)}</td>
                          <td id="dns">{d.type}</td>
                          <td
                            className={classes.copyable}
                            id="dns"
                            onClick={() => copy(d.host, dispatch)}>
                            {d.host}
                          </td>
                          <td
                            className={classes.copyable}
                            id="dns"
                            onClick={() => copy(d.data, dispatch)}>
                            {d.data}
                          </td>
                        </tr>
                        {d.reason && (
                          <tr id="dns">
                            <td colSpan={12} id="dns">
                              <Alert severity="error" variant="outlined">
                                {d.reason}
                              </Alert>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })}
                </table>
              </fieldset>
            </div>
          );
        })}
        <Paginator
          allItems={domains}
          setPaginatedItems={setPaginatedDomains}
          itemsPerPage={3}
        />
      </>
    </>
  );
};

export default DomainAuthentication;

const copy = (value, dispatch) => {
  const fakeElement = document.createElement('input');
  fakeElement.value = value;
  document.body.appendChild(fakeElement);
  fakeElement.select();
  document.execCommand('copy');
  document.body.removeChild(fakeElement);
  return dispatch(createNotification(successNotif('Copied to clipboard')));
};
