// ███████╗ ███████╗ ███████╗ ██╗ ██╗ ███████╗ ██╗  ██╗
// ██   ██║ ████╔══╝ ████╔══╝ █████╔╝ ████╔══╝  ╚███╔═╝
// ███████║ ██╔═╝    ██╔═╝    ██║ ██╗ ███████╗   ╚█╔╝
// ╚══════╝ ╚═╝      ╚═╝      ╚═╝ ╚═╝ ╚══════╝    ╚╝
// import { persistStore, persistReducer, autoRehydrate } from "redux-persist";
// import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

/**
 * Basic Store
 */

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ latency: 0 })
  : compose;

export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);
