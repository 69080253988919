import React from 'react';
import {
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { ExpandMore, Warning, Error, Delete } from '@material-ui/icons';
import { Control, Path, useFieldArray, UseFormSetValue } from 'react-hook-form';
import {
  IProposal,
  IStructuredField,
  TLenderAPIInterfaces
} from '../interface';
import {
  generateLabel,
  isStructuredField,
  countRequiredFields,
  determineFieldRequirement
} from '../functions';
import { computeHiddenFields } from '../helper';
import RenderField from './ProposalField';
import { theme } from 'theme';
import { useProposalFormStyles } from './styles';
import useFieldValidation from '../hooks/useFieldValidation';
import { FbFileRef } from 'types/interfaces';
import SimplifiedDocumentCard from 'components/FileStorage/components/SimplifiedDocumentCard';

const RenderSectionContent = ({
  proposal,
  fieldName,
  fieldData,
  control,
  setValue,
  financeParentValue,
  hiddenFields
}: {
  proposal: IProposal;
  fieldName: string;
  fieldData: TLenderAPIInterfaces;
  control: Control<TLenderAPIInterfaces, object>;
  setValue: UseFormSetValue<TLenderAPIInterfaces>;
  financeParentValue: string;
  hiddenFields: Set<string>;
}) => {
  const classes = useProposalFormStyles();

  const fieldArrays = Object.entries(fieldData).reduce((acc, [key, value]) => {
    if (Array.isArray(value)) {
      acc[key] = useFieldArray({
        control,
        name: `${fieldName}.${key}` as any
      });
    }
    return acc;
  }, {} as Record<string, ReturnType<typeof useFieldArray>>);

  const renderContent = () => {
    const simpleFields: React.ReactNode[] = [];
    const arrayFields: React.ReactNode[] = [];
    const objectFields: React.ReactNode[] = [];

    Object.entries(fieldData).forEach(([key, value]) => {
      const fullFieldName = `${fieldName}.${key}` as Path<TLenderAPIInterfaces>;

      if (isStructuredField(value)) {
        if (hiddenFields.has(fullFieldName)) return;

        const isRequired = determineFieldRequirement(value, financeParentValue);
        simpleFields.push(
          <div style={{ width: '100%' }} key={fullFieldName}>
            <RenderField
              name={fullFieldName}
              control={control}
              structuredField={value}
              watchedFields={fieldData}
              isRequired={isRequired}
            />
          </div>
        );
      } else if (Array.isArray(value)) {
        const { fields, remove } = fieldArrays[key];

        arrayFields.push(
          <div key={fullFieldName} className={classes.arraySection}>
            {fields.map((field, index) => {
              const itemValue = value[index] as TLenderAPIInterfaces;
              if (hiddenFields.has(fullFieldName)) return;

              const { total, completed, errors } = countRequiredFields({
                data: itemValue,
                hiddenFields,
                financeParentValue
              });

              const canDelete = () => {
                const currentCount = fields.length;
                if (key === 'directors' && proposal.requirements.minDirectors) {
                  return currentCount > proposal.requirements.minDirectors;
                }
                if (
                  key === 'shareholders' &&
                  proposal.requirements.minShareholders
                ) {
                  return currentCount > proposal.requirements.minShareholders;
                }
                if (key === 'assets' && proposal.requirements.minAssets) {
                  return currentCount > proposal.requirements.minAssets;
                }
                return true;
              };

              return (
                <Accordion key={field.id} className={classes.arrayItem}>
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <div className={classes.arrayItemHeader}>
                      <Typography className={classes.heading}>
                        <RequiredFieldsWarning
                          total={total}
                          completed={completed}
                          errors={errors}
                        />
                        {`${generateLabel(key)} ${index + 1}`}
                      </Typography>
                      {!proposal?.isImported && (
                        <Tooltip
                          title={
                            canDelete()
                              ? 'Delete'
                              : 'Cannot Delete Due To Minimum Requirement'
                          }>
                          <span>
                            <IconButton
                              size="small"
                              className={classes.deleteButton}
                              onClick={(e) => {
                                e.stopPropagation();
                                if (canDelete()) {
                                  remove(index);
                                  const updatedValue = [...value];
                                  updatedValue.splice(index, 1);
                                  setValue(fullFieldName, updatedValue as any);
                                }
                              }}
                              disabled={!canDelete()}>
                              <Delete />
                            </IconButton>
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </AccordionSummary>
                  <AccordionDetails className={classes.arrayItemContent}>
                    <RenderSectionContent
                      fieldName={`${fullFieldName}.${index}`}
                      fieldData={itemValue}
                      control={control}
                      setValue={setValue}
                      proposal={proposal}
                      financeParentValue={financeParentValue}
                      hiddenFields={hiddenFields}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        );
      } else if (typeof value === 'object' && value !== null) {
        if (hiddenFields.has(fullFieldName)) return;

        const { total, completed, errors } = countRequiredFields({
          data: value,
          hiddenFields,
          financeParentValue
        });

        objectFields.push(
          <div
            style={{ paddingBottom: theme.spacing(0.5) }}
            key={fullFieldName}>
            <div className={classes.objectSection}>
              <Typography className={classes.heading}>
                <RequiredFieldsWarning
                  total={total}
                  completed={completed}
                  errors={errors}
                />
                {`${generateLabel(key)}`}
              </Typography>

              {/* {!proposal?.isImported && (
              // Used to Delete
                <>
                  {parentFieldArray && (
                    <Tooltip title="Delete">
                      <IconButton
                        size="small"
                        className={classes.deleteButton}
                        onClick={() => {
                          setValue(fullFieldName, undefined as any, {
                            shouldDirty: true,
                            shouldValidate: true
                          });
                        }}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )} */}

              <RenderSectionContent
                fieldName={fullFieldName}
                fieldData={value as TLenderAPIInterfaces}
                control={control}
                setValue={setValue}
                proposal={proposal}
                financeParentValue={financeParentValue}
                hiddenFields={hiddenFields}
              />
            </div>
          </div>
        );
      }
    });

    const calculateGridSize = (fieldCount: number) => {
      if (fieldCount === 1) return 12; // One field takes full width
      if (fieldCount === 2) return 6; // Two fields take half width each
      if (fieldCount === 3) return 4; // Three fields take one-third width each
      return 4; // Default to one-third width for 3+ fields
    };

    const fieldCount = simpleFields.length;
    const gridSize = calculateGridSize(fieldCount);

    return (
      <>
        <Grid container spacing={2}>
          {simpleFields.map((field, index) => (
            <Grid item xs={12} sm={gridSize} key={index}>
              {field}
            </Grid>
          ))}
        </Grid>
        {objectFields}
        {arrayFields}
      </>
    );
  };

  return <div className={classes.content}>{renderContent()}</div>;
};

const ProposalForm = ({
  proposal,
  control,
  setValue,
  files
}: {
  proposal: IProposal;
  control: Control<TLenderAPIInterfaces, object>;
  setValue: UseFormSetValue<TLenderAPIInterfaces>;
  files: FbFileRef[];
}) => {
  const classes = useProposalFormStyles();
  const { watchedForm, financeParentValue } = useFieldValidation(
    control,
    proposal
  );

  const hiddenFields = computeHiddenFields(watchedForm, proposal);
  const renderField = (sectionName: string, sectionData: any) => {
    const { total, completed, errors } = countRequiredFields({
      data: sectionData,
      hiddenFields,
      financeParentValue
    });

    if (
      typeof sectionData === 'object' &&
      sectionData !== null &&
      !('value' in sectionData)
    ) {
      return (
        <Accordion key={sectionName} className={classes.accordion}>
          <AccordionSummary
            expandIcon={
              <Tooltip title="More detail">
                <ExpandMore />
              </Tooltip>
            }>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <RequiredFieldsWarning
                total={total}
                completed={completed}
                errors={errors}
              />
              <Typography className={classes.heading}>
                {generateLabel(sectionName)}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <RenderSectionContent
              proposal={proposal}
              fieldName={sectionName}
              fieldData={sectionData}
              control={control}
              setValue={setValue}
              financeParentValue={financeParentValue}
              hiddenFields={hiddenFields}
            />
          </AccordionDetails>
        </Accordion>
      );
    } else {
      const fullName = sectionName as Path<TLenderAPIInterfaces>;
      const isTestField = sectionName.toLowerCase() === 'is_test';
      if (isTestField) return <div key={sectionName} />;

      const isRequired = determineFieldRequirement(
        sectionData,
        financeParentValue
      );

      return (
        <div key={sectionName} style={{ paddingBottom: theme.spacing(1) }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: theme.spacing(0.5)
            }}>
            <RequiredFieldsWarning
              total={total}
              completed={completed}
              errors={errors}
            />
          </div>
          <RenderField
            name={fullName}
            control={control}
            structuredField={sectionData as IStructuredField}
            watchedFields={watchedForm}
            isRequired={isRequired}
          />
        </div>
      );
    }
  };

  const accordions: React.ReactNode[] = [];
  const otherFields: React.ReactNode[] = [];

  Object.entries(watchedForm).forEach(([sectionName, sectionData]) => {
    const renderedField = renderField(sectionName, sectionData);
    if (
      typeof sectionData === 'object' &&
      sectionData !== null &&
      !('value' in sectionData)
    ) {
      accordions.push(renderedField);
    } else {
      otherFields.push(renderedField);
    }
  });

  return (
    <form className={classes.root}>
      {files.map((file: FbFileRef, index: number) => {
        if (!file.fileId) return <div key={index} />;
        return (
          <div key={index} style={{ paddingBottom: theme.spacing(1) }}>
            <SimplifiedDocumentCard item={file} proposalForm />
          </div>
        );
      })}
      {otherFields}
      {accordions}
    </form>
  );
};

export default ProposalForm;

const RequiredFieldsWarning = ({
  total,
  completed,
  errors
}: {
  total: number;
  completed: number;
  errors: number;
}) => {
  const classes = useProposalFormStyles();
  const remaining = total - completed;

  if (remaining <= 0 && errors === 0) return null;

  let text = '';
  let icon;

  if (errors > 0) {
    text = `${errors} ${errors === 1 ? 'Field' : 'Fields'} with ${
      errors === 1 ? 'Error' : 'Errors'
    }`;
    icon = <Error className={classes.errorIcon} />;
  } else if (remaining > 0) {
    text = `${remaining} Required ${
      remaining === 1 ? 'Field' : 'Fields'
    } Remaining`;
    icon = <Warning className={classes.warningIcon} />;
  }

  return (
    <Typography component="span" className={classes.warningContainer}>
      <span className={errors > 0 ? classes.errorText : classes.warningText}>
        {text}
      </span>
      <span className={classes.iconContainer}>({icon})</span>
    </Typography>
  );
};
