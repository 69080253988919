import {
  Button,
  Grid,
  Typography,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { objectProgress } from 'components/Stepper/functions/objectprogress';
import { useProcess } from 'hooks/useProcess';
import Badge from '@material-ui/core/Badge';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CompleteObjectDefinition } from 'types/interfaces';
import { theme } from 'theme';
import { IObjectLayoutProps } from '../interfaces';
import { useStyles } from './GroupedObjectMenu';

import LockIcon from '@material-ui/icons/Lock';
import { useState } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { isHidden } from '../helpers';
import LabelIcon from '@material-ui/icons/Label';

const InheritanceMenu = ({
  CompleteObjectDefinitionList,
  title,
  handleValueChange,
  value,
  isRedflag
}: IObjectLayoutProps) => {
  const classes = useStyles();
  const { currentDeal } = useProcess();
  const { entityType } = useTypedSelector((s) => s.process);

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="flex-start"
        style={{ width: '100%' }}>
        <Grid item xs={12} container direction="row" alignItems="center">
          {isRedflag && (
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: theme.spacing(1.5)
              }}>
              <Tooltip title="Data from companies house is not editable">
                <LockIcon style={{ marginRight: 10, fontSize: 20 }} />
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <Typography className={classes.title}>{title}</Typography>
          </Grid>
        </Grid>
        <div>
          {CompleteObjectDefinitionList.map(
            (CompleteObjectDefinition: CompleteObjectDefinition) => {
              const isReqFields: boolean =
                objectProgress({
                  CompleteObjectDefinition,
                  currentDeal
                })._requiredFieldsTODO > 0;

              const { _requiredFieldsTODO: badgeContent } = objectProgress({
                CompleteObjectDefinition,
                currentDeal
              });

              const { Title, Id } = CompleteObjectDefinition.ObjectDefinition;
              const isSelected = Id === value;

              const result = isHidden(CompleteObjectDefinition, entityType);
              if (result) return <div key={Id} />;
              return (
                <div
                  key={Id}
                  style={{
                    width: '100%',
                    background: isSelected ? theme.palette.primary.main : '',
                    borderRadius: isSelected ? theme.shape.borderRadius : 0
                  }}>
                  <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    alignItems="center"
                    style={{ padding: 3 }}>
                    <Grid item>
                      {isReqFields && (
                        <Badge
                          badgeContent={badgeContent}
                          style={{ color: theme.palette.warning.main }}>
                          <ErrorOutlineIcon
                            style={{
                              color: theme.palette.warning.main,
                              marginLeft: 10
                            }}
                          />
                        </Badge>
                      )}

                      <Button onClick={() => handleValueChange(Id)}>
                        <Typography
                          style={{
                            color: isSelected
                              ? 'white'
                              : theme.palette.text.secondary,
                            fontWeight: 500
                          }}>
                          {Title}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              );
            }
          )}
        </div>
      </Grid>
    </div>
  );
};

export default InheritanceMenu;

const useButtonStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(1),
    transition: 'all 0.3s ease',
    '&:hover': {
      transform: 'translateX(10px)'
    }
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.grey[600]}`,
    backgroundColor: theme.palette.grey[700],
    '&:hover': {
      backgroundColor: theme.palette.grey[600],
      borderColor: theme.palette.grey[500]
    }
  },
  selectedButton: {
    backgroundColor: theme.palette.success.main,
    borderColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main[200],
      borderColor: theme.palette.success.main
    }
  },
  typography: {
    fontWeight: 500
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.common.white
  }
}));

export const ButtonMenu = ({ allObjects, handleValueChange, value }) => {
  const classes = useButtonStyles();
  const handleClick = (Id) => handleValueChange(Id);

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="flex-start">
      {allObjects.map((comObDef) => {
        const { Id, Title } = comObDef.ObjectDefinition;
        const isSelected = Id === value;

        return (
          <div key={Id} className={classes.root}>
            <Grid item xs={12}>
              <Button
                data-cy="quote-confirmation-btn"
                className={`${classes.button} ${
                  isSelected ? classes.selectedButton : ''
                }`}
                onClick={() => handleClick(Id)}>
                {isSelected && <LabelIcon className={classes.icon} />}
                <Typography
                  className={classes.typography}
                  style={{
                    color: theme.palette.common.white
                  }}>
                  {Title}
                </Typography>
              </Button>
            </Grid>
          </div>
        );
      })}
    </Grid>
  );
};
