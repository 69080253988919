import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  CompleteProcessInstance,
  FieldInstance,
  FieldDefinition
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { HideShowFields } from './hideshowFields';
import { CompleteObjectDefinitionAndInstance } from './stepprogress';

/**
 *
 * @description this needs to return the number of required fields still to do within this object
 * @param props
 * @returns number of required fields for the given object in question
 */
export const objectProgress: (props: {
  currentDeal: CompleteProcessInstance;
  CompleteObjectDefinition: CompleteObjectDefinition;
  entityType?: string;
}) => {
  _requiredFieldsTODO: number;
  RequiredFieldInstances: FieldInstance[];
} = (props) => {
  let _requiredFieldsTODO = 0;

  // const RelevantFieldDefinitionIdList = [] as number[];
  // const RelevantFieldDefinitionList = [] as FieldDefinition[];
  const TotalFieldInstanceList = [] as FieldInstance[];
  // const RelevantFieldInstanceList = [] as FieldInstance[];
  // const RelevantRequiredFieldInstanceList = [] as FieldInstance[];
  const RelevantRequiredCompletedFieldInstanceList = [] as FieldInstance[];
  // const RelevantCompletedFieldInstanceList = [] as FieldInstance[];

  const CompleteObjectDefinitionAndInstanceList =
    [] as CompleteObjectDefinitionAndInstance[];

  const { CompleteObjectDefinition, currentDeal } = props;
  let { ObjectDefinition, FieldDefinitionDict } = CompleteObjectDefinition;
  const { Id } = ObjectDefinition;

  const entityTypeCustomerDetails = [
    'Private Individual',
    'Private Individual - High Net Worth'
  ];

  const soleTraderObjectDefinitionIds = [3482, 3691];
  const partnershipObjectDefinitionIds = [3482, 3722];
  const objectDefinitionCustomerIds = [3474];

  // GET TOTAL FIELD INSTANCES
  const getTotalFieldInstance = (list: CompleteObjectDefinitionAndInstance[]) =>
    list.forEach((item: CompleteObjectDefinitionAndInstance) => {
      // For each field definition we find the corresponding field instance and then push it
      Object.values(item.CompleteObjectDefinition.FieldDefinitionDict).forEach(
        (FieldDefinition: FieldDefinition) => {
          const FieldInstanceList = getFieldInstances(
            item.CompleteObjectInstance
          );

          FieldInstanceList.find((FieldInstance: FieldInstance) => {
            if (FieldInstance.FieldDefinitionId === FieldDefinition.Id) {
              TotalFieldInstanceList.push(FieldInstance);
            }
          });
        }
      );
    });

  // GET RELEVANT, REQUIRED AND COMPLETED FIELD INSTANCES
  const getRelevantFieldInstances = (
    TotalFieldInstanceList: FieldInstance[],
    entityType?: string
  ) =>
    TotalFieldInstanceList.forEach((FieldInstance: FieldInstance) => {
      const FieldDefinition: FieldDefinition | undefined = Object.values(
        CompleteObjectDefinition.FieldDefinitionDict
      ).find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Id === FieldInstance.FieldDefinitionId
      );

      // REQUIRED FIELDS
      const isRequired = FieldDefinition?.FieldRequired;
      if (isRequired) {
        let shouldInclude = true;
        if (
          soleTraderObjectDefinitionIds.includes(Id) ||
          partnershipObjectDefinitionIds.includes(Id) ||
          objectDefinitionCustomerIds.includes(Id)
        ) {
          if (entityType === 'Sole Trader') {
            shouldInclude = soleTraderObjectDefinitionIds.includes(Id);
          } else if (entityType === 'Partnership of 3 and less') {
            shouldInclude = partnershipObjectDefinitionIds.includes(Id);
          } else if (entityTypeCustomerDetails.includes(entityType || '')) {
            shouldInclude = objectDefinitionCustomerIds.includes(Id);
          } else {
            shouldInclude = false;
          }
        }
        if (shouldInclude) {
          // RELEVANT REQUIRED AND COMPLETED FIELDS
          let value = FieldInstance.FieldValue;
          if (value === '') {
            RelevantRequiredCompletedFieldInstanceList.push(FieldInstance);
          }
        }
      }
    });

  // Here We need to know if the Definition has a corresponding instance object list and if any of the required fields are not valid
  // Best way would be to pass down a function that is called lower down to set the value

  // Add null checking for CompleteObjectInstanceDict
  let CompleteObjectInstances: CompleteObjectInstance[] = [];
  if (props.currentDeal?.CompleteObjectInstanceDict) {
    CompleteObjectInstances = Object.values(
      props.currentDeal.CompleteObjectInstanceDict
    ).filter(
      (CompleteObjectInstance: CompleteObjectInstance) =>
        CompleteObjectInstance?.ObjectInstance?.ObjectDefinitionId === Id
    );
  }

  // Add safety check for CompleteObjectDefinition.FieldDefinitionDict
  if (CompleteObjectDefinition?.FieldDefinitionDict) {
    CompleteObjectInstances.forEach(
      (CompleteObjectInstance: CompleteObjectInstance) => {
        const FieldDefinitions: FieldDefinition[] = HideShowFields({
          FieldDefinitionList: Object.values(
            CompleteObjectDefinition.FieldDefinitionDict || {}
          ),
          CompleteObjectInstance
        });

        // Replace fields in the object definition with relevant ones
        FieldDefinitionDict = Object.fromEntries(
          FieldDefinitions.map((t) => [t.Id, t])
        );

        CompleteObjectDefinitionAndInstanceList.push({
          CompleteObjectDefinition: {
            FieldDefinitionDict,
            ObjectDefinition
          },
          CompleteObjectInstance
        });
      }
    );

    // Handle empty instances case
    if (CompleteObjectInstances.length === 0) {
      const containsAtLeastOneRequiredField: FieldDefinition | undefined =
        Object.values(CompleteObjectDefinition.FieldDefinitionDict).find(
          (FieldDefinition: FieldDefinition) => FieldDefinition.FieldRequired
        );

      if (containsAtLeastOneRequiredField) {
        CompleteObjectDefinitionAndInstanceList.push(
          createEmptyDefinitionAndInstance({
            CompleteObjectDefinition,
            currentDeal,
            Id
          })
        );
      }
    }
  }

  /**
   * If the object contains a required field there has to be at least one object instance
   * END
   * */

  getTotalFieldInstance(CompleteObjectDefinitionAndInstanceList);
  getRelevantFieldInstances(TotalFieldInstanceList, props.entityType);
  _requiredFieldsTODO = RelevantRequiredCompletedFieldInstanceList.length;

  return {
    _requiredFieldsTODO,
    RequiredFieldInstances: RelevantRequiredCompletedFieldInstanceList
  };
};

/**
 * Helper function to create empty definition and instance
 */
const createEmptyDefinitionAndInstance = ({
  CompleteObjectDefinition,
  currentDeal,
  Id
}: {
  CompleteObjectDefinition: CompleteObjectDefinition;
  currentDeal: CompleteProcessInstance;
  Id: number | string;
}): CompleteObjectDefinitionAndInstance => ({
  CompleteObjectDefinition: {
    FieldDefinitionDict: CompleteObjectDefinition.FieldDefinitionDict || {},
    ObjectDefinition: CompleteObjectDefinition.ObjectDefinition
  },
  CompleteObjectInstance: {
    ObjectInstance: {
      Id: 0,
      ObjectDefinitionId: parseInt(Id.toString()),
      ItemOrder: 0,
      ProcessInstanceId: currentDeal?.ProcessInstance?.Id || 0,
      Selected: false,
      Title: '',
      UserDefinitionId: 0,
      UserInstanceId: 0
    },
    FieldInstanceList: Object.keys(
      CompleteObjectDefinition.FieldDefinitionDict || {}
    ).map((t) => {
      const FieldDefinition = CompleteObjectDefinition.FieldDefinitionDict[t];
      return createEmptyFieldInstance({
        FieldDefinition,
        Id
      });
    })
  }
});

/**
 * Helper function to create empty field instance
 */
const createEmptyFieldInstance = ({
  FieldDefinition,
  Id
}: {
  FieldDefinition: FieldDefinition;
  Id: number | string;
}) => ({
  Id: 0,
  Title: '',
  ProcessInstanceId: 0,
  FieldInstanceId: 0,
  FieldDefinitionId: FieldDefinition?.Id || 0,
  ObjectDefinitionId: parseInt(Id.toString()),
  ObjectInstanceId: 0,
  UserInstanceId: 0,
  UserDefinitionId: 0,
  FieldValue: ''
});
