import axios, { AxiosRequestConfig } from 'axios';
import { Dispatch } from 'redux';
import { firebase } from 'redux/firebase';

export const getCrmToken = async (dispatch?: Dispatch) => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    const url = `${process.env.REACT_APP_PROXY}/crm-graphql/current-token`;
    const request: AxiosRequestConfig = {
      method: 'GET',
      url,
      headers: { token }
    };

    const result = await axios(request);
    if (result?.data?.tokenData?.access_token) {
      if (dispatch) {
        return dispatch({
          type: 'SET_CRM_TOKEN',
          payload: result.data.tokenData
        });
      } else return result.data.tokenData;
    }
  } catch (e) {
    console.error(e);
  }
};
