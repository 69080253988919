import { Page, StyleSheet, Document, View, Text } from '@react-pdf/renderer';
import { ObjectDefinition } from 'types/interfaces';
import style from 'views/UserList/style';
import { IProps } from './interface';
import { styles } from './styles';
import { decodeFormattedText } from './functions';
import Time from 'common/Time';

const SingleObject_pdf = ({
  ObjectDefinition,
  objectData,
  currentDeal
}: IProps) => {
  if (ObjectDefinition && objectData) {
    return (
      <Document>
        <Page size="A4">
          <View>
            <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
            <Text style={styles.subTitle}>
              Deal ID: {currentDeal.ProcessInstance.Id}
            </Text>
            <Text style={styles.subTitle}>
              Date: <Time time={new Date()} type="date" />
            </Text>
            <Text style={{ paddingBottom: 6 }} />
          </View>

          {objectData.map(
            (element: { title: string; value: string | number }, index) => {
              return (
                <View
                  key={index}
                  style={[
                    styles.item,
                    index % 2 === 0
                      ? { backgroundColor: 'rgb(239, 239, 240)' }
                      : { backgroundColor: 'rgb(255,255,255)' }
                  ]}>
                  <Text style={{ fontWeight: 'bold' }}>{element.title}: </Text>
                  <Text style={{ marginRight: 10 }}>
                    {decodeFormattedText(element.value)}
                  </Text>
                  <br />
                </View>
              );
            }
          )}
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4">
        <View>
          <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
        </View>

        <View>
          <Text style={styles.item}>No data to show</Text>
        </View>
      </Page>
    </Document>
  );
};

export default SingleObject_pdf;
