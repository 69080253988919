import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import {
  SET_CHECKLIST_SCHEMA,
  UPDATE_CHECKLIST_RULE
} from 'redux/actions/types';
import { useTypedSelector } from 'redux/reducers';
import { IChecklistRules } from 'redux/reducers/schemaReducer';

const useChecklist = () => {
  const dispatch = useDispatch();
  const { user } = useTypedSelector((s) => s.user);
  const { checklistRules } = useTypedSelector((s) => s.schema);

  const loadChecklistIntoRedux = async () => {
    const snapshot = await firebase
      .firestore()
      .collection('globalSetting')
      .doc('ruleSchema')
      .collection('checklistRuleSchema')
      .get();

    const rules: IChecklistRules = snapshot.docs.reduce((acc, doc) => {
      acc[doc.id] = doc.data();
      return acc;
    }, {});

    dispatch({ type: SET_CHECKLIST_SCHEMA, payload: rules });
  };

  const loadRulePreventionIntoRedux = async () => {};

  const handleChecklistUpdate = async ({
    ruleId,
    isMandatory,
    regulationType,
    dealTypeId
  }: {
    ruleId: string;
    isMandatory: boolean;
    regulationType: 'Regulated' | 'Non-Regulated' | 'Both';
    dealTypeId: string;
  }) => {
    if (user.SystemAccess > 12) {
      const ruleData = {
        isMandatory,
        regulationType
      };

      try {
        await firebase
          .firestore()
          .collection('globalSetting')
          .doc('ruleSchema')
          .collection('checklistRuleSchema')
          .doc(dealTypeId)
          .set({ [ruleId]: ruleData }, { merge: true });

        dispatch({
          type: UPDATE_CHECKLIST_RULE,
          payload: { dealTypeId, ruleId, ruleData }
        });
      } catch (e) {
        console.error('Error updating checklist:', e);
      }
    }
  };

  return {
    loadChecklistIntoRedux,
    handleChecklistUpdate,
    loadRulePreventionIntoRedux,
    checklistRules
  };
};

export default useChecklist;
