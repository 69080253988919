import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import {
  CompleteObjectDefinition,
  CompleteObjectDefinitionDict,
  ObjectInstance,
  CompleteProcessStepDefinition,
  CompleteObjectInstance,
  FieldInstance,
  FieldDefinition,
  Rule,
  RuleGroup,
  InteractiveRuleDict
} from 'types/interfaces';
import { Asset, Calculation } from 'types/calculatorInterfaces';
import { GetStepSystemDocument, GetUserDefinitions } from 'redux/database';
import { getFieldInstanceValue } from 'Utils';
import { Regulated } from 'components/User/NewUserButton';
import { useTypedSelector } from 'redux/reducers';
import { useDispatch } from 'react-redux';
import { BugTracker } from 'Utils/Bugtracker';
import { getCompleteUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

export const useProcess = () => {
  const dispatch = useDispatch();
  const [activeObjects, setActiveObjects] = useState<IObject[]>();

  const history = useHistory();
  const parsed: any = queryString.parse(history.location.search);
  const { token } = useTypedSelector((s) => s.user.auth);
  const { calculation } = useTypedSelector((s) => s.calculator.calculation);
  const { auth, user } = useTypedSelector((s) => s.user);
  const { landingpage } = useTypedSelector((s) => s.utility);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { entityType } = useTypedSelector((s) => s.process);

  const {
    currentProcess,
    currentStep,
    currentDeal,
    currentStepId,
    currentOverView,
    processInstanceFields
  } = useTypedSelector((state) => state.process);
  const { ProcessStepDefinitionSequence, RuleList } = currentProcess;
  const { InteractiveRuleDict } = currentStep;

  const currentStepCanCompleteList = currentStep.UserInstanceListForCurrentStep;
  const prostepdefid: number =
    parsed && parsed.psdid ? parseInt(parsed.psdid) : currentStepId; // this needs to change with the step selection
  const stepdefdict: CompleteProcessStepDefinition =
    currentProcess?.CompleteProcessStepDefinitionDict?.[prostepdefid];
  const summarycontent = currentStep.ProcessStepDescription;
  const objects: CompleteObjectDefinitionDict =
    stepdefdict?.CompleteObjectDefinitionDict;

  let sorted: CompleteObjectDefinition[] | [] = [];
  if (objects) {
    sorted = Object.values(objects)
      .sort(
        (a: CompleteObjectDefinition, b: CompleteObjectDefinition) =>
          a.ObjectDefinition.ItemOrder - b.ObjectDefinition.ItemOrder
      )
      .filter((el: CompleteObjectDefinition) => {
        if (landingpage && el.ObjectDefinition.Title === 'Deal Type')
          return null;
        return el;
      });
  }

  let isOnBehalfOfStep = false;
  let loggedInUserCanEdit = false;
  let isDocs = false;
  let DocumentDict = {};

  let ProcessInstanceId = currentDeal?.ProcessInstance?.Id;
  let ProcessStepDefinitionId = currentStepId; // System Page : current selected step
  if (landingpage)
    ProcessStepDefinitionId = stepdefdict?.ProcessStepDefinition?.Id; // Landing Page : current step

  const getSysDoc = async () => {
    const res = await GetStepSystemDocument({
      ProcessInstanceId,
      ProcessStepDefinitionId
    });

    if (res && res.status === 200) {
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  /** GET RULE GROUPS */
  // const [ruleGroups, setRuleGroups] = useState<RuleGroup>({} as RuleGroup);
  const getRuleGroups: () => { ruleGroups: RuleGroup; rules: Rule[] } = () => {
    let ruleGroups: RuleGroup | undefined = undefined;
    // let selectedKey: string | undefined = undefined;
    // let actions: Rule[] | undefined = undefined;
    const list: InteractiveRuleDict | Rule[] = !isAdminOrProcessOwner
      ? RuleList
      : InteractiveRuleDict;

    const rules: Rule[] = Object.values(list).sort(
      (a: Rule, b: Rule) => a.ItemOrder - b.ItemOrder
    );

    // if (sorted && sorted.length > 0) {
    //   selectedKey = sorted?.[0].Id.toString();
    //   actions = sorted;
    // }

    const grouped: Rule[] = rules.filter((item: Rule) =>
      item?.Description.includes('group#')
    );

    let groups = {} as RuleGroup;
    grouped.forEach((item: Rule) => {
      const split: string[] = item?.Description.split(',');
      const group_name_unsplit: string | undefined = split.find((e) =>
        e.includes('group')
      );
      const group_name: string | undefined = group_name_unsplit?.split('#')[1];
      if (group_name) {
        if (!groups[group_name]) groups[group_name] = [];
        groups[group_name].push(item);
      }
    });
    ruleGroups = groups;

    return { ruleGroups, rules };
  };

  /** REGULATED PARTY LOGIC */
  const checkRegulatedPartyStatus = async (): Promise<{
    hasEntity: boolean;
    isRegulatedParty: boolean;
  }> => {
    if (
      !currentStepId ||
      currentStep?.UserInstanceListForCurrentStep?.length !== 1
    ) {
      return { hasEntity: false, isRegulatedParty: false };
    }

    const UserInstanceId =
      currentStep.UserInstanceListForCurrentStep[0].UserInstanceId;
    const UserDefinitionId =
      stepdefdict?.ProcessStepDefinition?.UserDefinitionId;

    if (!UserDefinitionId || !UserInstanceId) {
      return { hasEntity: false, isRegulatedParty: false };
    }

    let CODL: CompleteObjectDefinition[] = [],
      COIL: CompleteObjectInstance[] = [];
    try {
      const response_def = await GetUserDefinitions({ UserDefinitionId });
      const CompleteObjectInstanceList = (await getCompleteUserInstanceDetail({
        baseUrl,
        UserInstanceId,
        action: 'CompleteObjectInstanceList'
      })) as CompleteObjectInstance[];

      if (
        response_def?.data?.CompleteObjectDefinitionList?.length &&
        CompleteObjectInstanceList.length
      ) {
        const CODLData = response_def?.data?.CompleteObjectDefinitionList;
        const COILData: CompleteObjectInstance[] | undefined =
          CompleteObjectInstanceList.filter(
            (CompleteObjectInstance: CompleteObjectInstance) =>
              CompleteObjectInstance.ObjectInstance.Title === 'Entity'
          );

        if (CODLData && COILData) {
          CODL = CODLData;
          COIL = COILData;
        }
      }
    } catch (e) {
      BugTracker.Notify(e);
    }

    return CODL && COIL
      ? await getRegulatedParty({ CODL, COIL })
      : { hasEntity: false, isRegulatedParty: false };
  };

  const getRegulatedParty = async ({
    CODL,
    COIL
  }: {
    CODL: CompleteObjectDefinition[];
    COIL: CompleteObjectInstance[];
  }) => {
    const EntityType = getFieldInstanceValue({
      CODL,
      COIL,
      DefinitionTitle: 'Entity',
      FieldTitle: 'Entity Type'
    });

    if (EntityType !== undefined) {
      const isRegulatedParty = Regulated.includes(EntityType);
      dispatch({ type: 'SET_REGULATED_STATUS', payload: isRegulatedParty });
      dispatch({ type: 'SET_ENTITY_TYPE', payload: EntityType });
      return { hasEntity: true, isRegulatedParty };
    } else {
      const isRegulatedParty = Regulated.includes(entityType);
      return { hasEntity: true, isRegulatedParty };
    }
  };

  /** ON BEHALF OF AND PROXY LOGIC */
  if (stepdefdict && stepdefdict.ProcessStepDefinition) {
    const { ProcessStepSelectionType } = stepdefdict.ProcessStepDefinition;
    isOnBehalfOfStep =
      ProcessStepSelectionType === 'OnBehalfOf' ||
      ProcessStepSelectionType === 'Proxy';

    loggedInUserCanEdit =
      stepdefdict.ProcessStepDefinition.UserDefinitionId ===
      user.UserDefinitionId;

    DocumentDict =
      stepdefdict && stepdefdict.DocumentDict ? stepdefdict.DocumentDict : {};

    isDocs = Object.keys(DocumentDict).length > 0;
  }

  /** PERMISSIONS */
  const isOwner = currentDeal?.ProcessInstance?.UserInstanceId === user.Id;
  const isAdmin = user.SystemAccess >= 10;
  const isAdminOrProcessOwner = isOwner || isAdmin;

  const canviewform = isOnBehalfOfStep
    ? true
    : isAdminOrProcessOwner || loggedInUserCanEdit;

  /** ASSET OBJECTS */
  // Loop through sorted (Object Definitions) and find Object Definitions of type asset (there could conceivably be more than one...)

  let assets = [] as Asset[];
  let quotes = [] as Calculation[];

  const quoteDefs = 2840;

  const findQuotes = () => {
    const ObjectInstances: ObjectInstance[] | any =
      currentDeal?.CompleteObjectInstanceDict &&
      Object.values(currentDeal?.CompleteObjectInstanceDict).filter(
        (item: ObjectInstance | any): boolean =>
          item.ObjectInstance.ObjectDefinitionId === quoteDefs
      );

    ObjectInstances &&
      ObjectInstances.forEach(
        ({
          FieldInstanceList,
          ObjectInstance
        }: {
          FieldInstanceList: FieldInstance[];
          ObjectInstance: ObjectInstance;
        }) => {}
      );
  };

  const assetsDefs: CompleteObjectDefinition[] = sorted.filter((props, i) => {
    const { ObjectDefinition } = props;
    const isRepeatDynamicAsset = arrayContains({
      description: ObjectDefinition.ObjectDescription,
      query: 'repeat-dynamic-asset'
    });
    if (isRepeatDynamicAsset) return props;
  });

  assetsDefs.forEach((props) => {
    const { ObjectDefinition, FieldDefinitionList } = props;
    const CompleteObjectInstanceList: CompleteObjectInstance[] | undefined =
      currentDeal?.CompleteObjectInstanceDict &&
      Object.values(currentDeal?.CompleteObjectInstanceDict).filter(
        (item: ObjectInstance | any): boolean =>
          item.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
      );

    CompleteObjectInstanceList &&
      CompleteObjectInstanceList.forEach(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
          const getValue = (name: string | number): string | number | null => {
            const FieldDefinitionId: number | undefined =
              FieldDefinitionList?.find(
                (FieldDefinition: FieldDefinition) =>
                  FieldDefinition.Title === name
              )?.Id;

            if (FieldDefinitionId) {
              const instObj: FieldInstance | undefined = FieldInstanceList.find(
                (FieldInstance: FieldInstance) =>
                  FieldInstance.FieldDefinitionId === FieldDefinitionId
              );
              if (instObj) return instObj?.['FieldValue'];
              else return null;
            } else {
              return null;
            }
          };

          const ensureNumber = (value: any, defaultValue = 0): number => {
            if (typeof value === 'string' && value !== '') {
              return parseFloat(value);
            } else if (typeof value === 'number') {
              return value;
            } else {
              return defaultValue;
            }
          };

          const ensureString = (value: any): string => {
            if (value) {
              return value.toString();
            } else {
              return '';
            }
          };

          let Quantity: any = getValue('Quantity');
          Quantity = Quantity !== '' ? parseFloat(Quantity) : 1;

          let Price = ensureString(getValue('Price Per Unit'));
          let Make = ensureString(getValue('Make'));
          let Model = ensureString(getValue('Model'));
          let Year = ensureNumber(getValue('Year'));
          let AssetCategory = ensureString(getValue('Asset Category'));
          let Equipments = ensureString(getValue('Equipments'));
          let Vehicles = ensureString(getValue('Vehicles'));
          let Non_VATable_item = ensureNumber(getValue('Non VATable Items'));
          let Vat = ensureNumber(getValue('VAT'));
          let Deposit = ensureNumber(getValue('Deposit'));
          let VAT_Rate = ensureNumber(getValue('VAT Rate'));

          assets.push({
            AssetCategory,
            Equipments,
            Make,
            Model,
            Price,
            Quantity,
            TotalPrice: parseFloat(Price) * Quantity,
            Vehicles,
            Year,
            Deposit,
            Vat,
            Non_VATable_item,
            ObjectInstanceId: CompleteObjectInstance.ObjectInstance.Id,
            VAT_Rate
          });
        }
      );
  });

  interface IObject {
    odid: number;
    psdid: number;
  }

  interface IField {
    odid: number;
    fdid: number;
  }

  findQuotes();

  useEffect(() => {
    const allFields: IField[] = [];
    const allObjects: IObject[] = [];

    Object.keys(processInstanceFields).forEach((key: string) => {
      const current = processInstanceFields[key];
      current.forEach((FieldInstance: FieldInstance) =>
        allFields.push({
          odid: FieldInstance.ObjectDefinitionId,
          fdid: FieldInstance.FieldDefinitionId
        })
      );
    });

    ProcessStepDefinitionSequence &&
      ProcessStepDefinitionSequence.forEach((index: number) => {
        const step: CompleteProcessStepDefinition =
          currentProcess.CompleteProcessStepDefinitionDict?.[index];
        Object.values(step.CompleteObjectDefinitionDict).forEach(
          (CompleteObjectDefinition: CompleteObjectDefinition) => {
            const { Id } = CompleteObjectDefinition.ObjectDefinition;
            allObjects.push({ odid: Id, psdid: step.ProcessStepDefinition.Id });
          }
        );
      });

    const activeObjects: IObject[] = allObjects.filter((object: IObject) => {
      return allFields.map((field: IField) => field.odid).includes(object.odid);
    });

    setActiveObjects(activeObjects);
  }, [processInstanceFields]);

  return {
    activeObjects,
    assets,
    auth,
    calculation,
    canviewform,
    checkRegulatedPartyStatus,
    currentDeal,
    currentOverView,
    currentProcess,
    currentStep,
    currentStepCanCompleteList,
    currentStepId,
    getRuleGroups,
    getSysDoc,
    isAdminOrProcessOwner,
    landingpage,
    objects,
    processInstanceFields,
    ProcessInstanceId,
    prostepdefid,
    sorted,
    stepdefdict,
    summarycontent,
    token,
    user,
    assetsDefs
  };
};

const arrayContains = ({ query, description }) => {
  const search = ',';
  const keywords = description.split(search);
  if (keywords.includes(query)) {
    return true;
  } else {
    return false;
  }
};
