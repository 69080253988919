import React from 'react';
import Button from '@material-ui/core/Button';
import Office365 from 'assets/Icons/png/office365.png';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from 'services/microsoft/authConfig';
import { Grid, CircularProgress, Typography } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { BugTracker } from 'Utils/Bugtracker';
import { theme } from 'theme';

export const ConnectAccountButton = () => {
  const isAuth = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [listenerConnected, setListener] = React.useState(false);

  const handleLogin = () =>
    instance.loginPopup(loginRequest).catch((e) => {
      // console.log(e)
      BugTracker.notify(e);
    });
  const handleLogout = () => instance.logoutPopup();
  const handleConnect = () => setListener(!listenerConnected);

  // const account = accounts[0];

  if (inProgress === 'login') return <CircularProgress />;
  if (isAuth) {
    return (
      <Grid container direction="column">
        <Grid item>
          <Button
            color="secondary"
            onClick={handleLogout}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <img src={Office365} height={30} width={30} />
            <div style={{ paddingLeft: theme.spacing(1), textAlign: 'center' }}>
              Disconnect From Office 365
            </div>
          </Button>
        </Grid>
        {/* <Grid item>
          <Switch checked={listenerConnected} onChange={handleConnect} />
        </Grid> */}
        {/* <Grid item>
          <IdTokenClaims account={account} />
        </Grid> */}
      </Grid>
    );
  } else {
    return (
      <Button
        color="secondary"
        onClick={handleLogin}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <img src={Office365} height={30} width={30} />
        <div style={{ paddingLeft: theme.spacing(1), textAlign: 'center' }}>
          Connect To Office 365
        </div>
      </Button>
    );
  }
};

// const IdTokenClaims = ({ account }) => {
//   if (!account) return <div>no claims</div>;
//   return (
//     <>
//       {Object.keys(account.idTokenClaims).map((key, idx) => (
//         <p key={idx}>
//           {key}: {account.idTokenClaims[key]}
//         </p>
//       ))}
//     </>
//   );
// };
