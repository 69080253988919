import { useState, ChangeEvent } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  TextField
} from '@material-ui/core';
import { CHANGE_FILE_NAME } from 'redux/actions/types';
import { useDispatch } from 'react-redux';

export const RenameFileDialog = ({ open, toggle, item, id, handleChange }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState(item.nickName ? item.nickName : item.name);
  const handleLocalChange = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setName(value);
  };
  const handleClose = async () => {
    await handleChange(name, id);
    dispatch({
      type: CHANGE_FILE_NAME,
      payload: {
        fileId: id,
        newName: name
      }
    });
    toggle();
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogContent>
        <TextField
          fullWidth
          label="Name"
          value={name}
          onChange={handleLocalChange}
          onKeyDown={(e) => e.key === 'Enter' && handleClose()}
        />
      </DialogContent>
    </Dialog>
  );
};
