import React from 'react';
import {
  makeStyles,
  Theme,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { TransitionGroup } from 'react-transition-group';

const useStyles = makeStyles((theme: Theme) => ({
  errorContainer: {
    marginBottom: theme.spacing(2)
  },
  alert: {
    border: `2px solid ${theme.palette.error.main}`,
    '& .MuiAlert-message': {
      width: '100%'
    }
  },
  list: {
    width: '100%',
    padding: theme.spacing(1, 0),
    maxHeight: '150px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '6px'
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.error.light,
      borderRadius: '3px'
    }
  },
  listItem: {
    padding: theme.spacing(0, 1),
    transition: 'all 0.2s ease',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  resolvedItem: {
    textDecoration: 'line-through',
    color: theme.palette.text.disabled
  },
  checkbox: {
    '&.Mui-checked': {
      color: theme.palette.success.main
    }
  },
  listItemIcon: {
    minWidth: 40
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  errorCount: {
    color: theme.palette.error.main,
    fontSize: '0.875rem',
    fontWeight: 500
  }
}));

const ProposalErrors = ({
  errors,
  resolvedErrors,
  onErrorToggle
}: {
  errors: string[];
  resolvedErrors: Set<number>;
  onErrorToggle: (index: number) => void;
}) => {
  const classes = useStyles();
  if (!errors || errors.length === 0) return null;

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <Box className={classes.errorContainer} onClick={handleClick}>
      <Alert severity="error" className={classes.alert} onClick={handleClick}>
        <AlertTitle>
          <Box className={classes.titleContainer}>
            <Typography variant="subtitle2">
              Please resolve the following issues:
            </Typography>
            <Typography className={classes.errorCount}>
              {resolvedErrors.size} of {errors.length} resolved
            </Typography>
          </Box>
        </AlertTitle>
        <List className={classes.list}>
          {errors.map((error, index) => {
            const isResolved = resolvedErrors.has(index);
            return (
              <ListItem
                key={`${error}-${index}`}
                className={classes.listItem}
                onClick={handleClick}
                button={false}>
                <ListItemIcon className={classes.listItemIcon}>
                  <Checkbox
                    checked={isResolved}
                    onChange={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onErrorToggle(index);
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={classes.checkbox}
                    size="small"
                  />
                </ListItemIcon>
                <ListItemText
                  primary={error}
                  className={isResolved ? classes.resolvedItem : ''}
                  onClick={handleClick}
                />
              </ListItem>
            );
          })}
        </List>
      </Alert>
    </Box>
  );
};

export default ProposalErrors;
