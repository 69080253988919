import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  Text1: {
    fontSize: '10px',
    color: 'gray',
    width: '40%'
  },
  Text2: {
    fontSize: '10px',
    width: '80%'
  }
});

const TableRow = ({ items }) => {
  const rows = items.map((item) => (
    <View style={styles.row} key={item.key.toString()}>
      <Text style={styles.Text1}>{item.Text1}</Text>
      <Text style={styles.Text2}>{item.Text2}</Text>
    </View>
  ));
  return <Fragment>{rows}</Fragment>;
};

export default TableRow;
