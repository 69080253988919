import firebase from 'firebase';
import {
  CustomerType,
  FirestoreDocType,
  HistoryEvent,
  INestedEventType
} from '../interface';

const createInitialNestedEvent = (
  eventType: string,
  newType: CustomerType | string,
  newHistoryEvent: HistoryEvent
): INestedEventType => {
  if (eventType === 'Customer Type') {
    return {
      history: [newHistoryEvent],
      selected: newType as CustomerType
    };
  } else if (eventType === 'File Note') {
    return {
      history: [newHistoryEvent],
      note: newType as string
    };
  } else {
    return {
      history: [],
      selected: CustomerType.None
    };
  }
};

export const handleTypeOrNoteChange = async (
  eventType: 'Customer Type' | 'File Note',
  newType: CustomerType | string,
  newHistoryEvent: HistoryEvent,
  userInstanceId: string
) => {
  const docRef = firebase
    .firestore()
    .collection('timeline')
    .doc(userInstanceId);

  const doc = await docRef.get();

  if (doc.exists) {
    const data = doc.data() as FirestoreDocType;
    const updatedHistory = [
      ...(data[eventType].history || []),
      newHistoryEvent
    ];

    const updatedData = {
      history: updatedHistory,
      ...(eventType === 'Customer Type'
        ? { selected: newType }
        : { note: newType })
    };

    await docRef.update({
      [eventType]: updatedData
    });

    return updatedHistory;
  } else {
    const initialData: FirestoreDocType = {
      'Customer Type':
        eventType === 'Customer Type'
          ? createInitialNestedEvent(eventType, newType, newHistoryEvent)
          : { history: [], selected: CustomerType.None },

      Notes: '',
      TODO: '',
      Call: '',
      Birthdays: '',
      Deal_Expiry_Date: '',
      others: '',
      log: '',
      open: '',
      click: '',
      'File Note':
        eventType === 'File Note'
          ? createInitialNestedEvent(eventType, newType, newHistoryEvent)
          : { history: [], note: '' },

      'Deal Note': ''
    };

    await docRef.set(initialData);

    return [newHistoryEvent];
  }
};
