import { FormTabs, TabPanel } from './FormTabs';
import { FormContent } from './FormContent';
import { Grid } from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import { useForm } from 'hooks/useForm';

export const Forms = () => {
  let { landingpage } = useProcess();
  const { value } = useForm();

  const tabMd = !landingpage && 3;
  const tabXs = !landingpage && 12;
  const conMd = !landingpage && 9;
  return (
    <Grid container>
      <Grid item md={tabMd} xs={tabXs}>
        <FormTabs />
      </Grid>
      <Grid item md={conMd} xs={tabXs}>
        <TabPanel index={value} isReadOnly={() => false}>
          <FormContent />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
