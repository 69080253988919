import NumberFormat from 'react-number-format';

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

const DefaultNumber = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={''}
    decimalScale={2}
  />
);

const Percent = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    suffix={'%'}
    decimalScale={2}
  />
);

export const Formattor = ({ name, value }) => {
  switch (name) {
    case 'Working Capital Turnover':
      return <DefaultNumber value={value} />;
    case 'Profit Capital Employed':
      return <DefaultNumber value={value} />;
    case 'Current Ratio':
      return <DefaultNumber value={value} />;
    case 'Profit Sales	':
      return <Percent value={value} />;
    case 'Interest Pre Interest Profit':
      return <Percent value={value} />;
    case 'Profit Total Assets':
      return <Percent value={value} />;
    case 'Profit Shareholders Funds':
      return <Percent value={value} />;
    case 'Turnover Total Assets':
      return <Percent value={value} />;
    case 'Turnover Fixed Assets':
      return <DefaultNumber value={value} />;
    case 'Total Debt Net Worth':
      return <Percent value={value} />;
    case 'Shareholders Funds Total Assets':
      return <Percent value={value} />;
    case 'Long Term Debt Net Worth':
      return <Percent value={value} />;
    case 'Total Debt Working Capital':
      return <DefaultNumber value={value} />;
    case 'Average Employee Remuneration':
      return <Currency value={value} />;
    case 'Wages Sales':
      return <Percent value={value} />;
    case 'Profit Per Employee':
      return <Currency value={value} />;
    case 'Sales Per Employee':
      return <Currency value={value} />;
    case 'Capital Employed Per Employee':
      return <Currency value={value} />;
    case 'Total Fixed Assets Per Employee':
      return <DefaultNumber value={value} />;
    case 'Total Assets Per Employee':
      return <DefaultNumber value={value} />;
    case 'Creditors Debtors':
      return <DefaultNumber value={value} />;
    case 'Current Liabilities Stocks':
      return <DefaultNumber value={value} />;
    case 'Debtors Total Assets':
      return <DefaultNumber value={value} />;
    case 'Export Sales':
      return <DefaultNumber value={value} />;
    case 'Liquidity Ratio':
      return <DefaultNumber value={value} />;
    case 'Profit Sales':
      return <Percent value={value} />;
    case 'Sales Audit Fees':
      return <DefaultNumber value={value} />;
    case 'Total Assets Audit Fees':
      return <DefaultNumber value={value} />;
    default:
      return <div />;
  }
};
