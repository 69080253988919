import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { XpansionIsolated } from 'common/Xpansion';
import { FilesAndMessages } from '../components';
import TotalStepComponent from 'components/Stepper';

export const ContentGrid = ({ parsed, mobile, classes }) => {
  return (
    <Grid container spacing={mobile ? 0 : 0} className={classes.stepcontainer}>
      <Grid item xs={mobile ? 12 : 8}>
        <TotalStepComponent parsed={parsed} />
      </Grid>
      {!mobile && (
        <Grid item xs={4}>
          <FilesAndMessages />
        </Grid>
      )}
    </Grid>
  );
};

export const FileSection = () => {
  return (
    <XpansionIsolated expanded summary="Files">
      <FilesAndMessages />
    </XpansionIsolated>
  );
};
