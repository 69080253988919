import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' },
  detail: { padding: theme.spacing(2), margin: theme.spacing(2) }
}));

const Component = ({ company }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <Table
        title="Previous Name"
        data={company.previous_names}
        columns={[
          {
            title: 'Previous Name',
            field: 'attributes.name'
          },
          {
            title: 'Changed On',
            field: 'attributes.end_date'
          }
          // {
          //   title: 'Had Name On',
          //   field: 'attributes.start_date'
          // }
        ]}
        options={{ pageSize: 12 }}
      />
    </Paper>
  );
};

export default Component;
