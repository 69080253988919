import { useState, useEffect, ChangeEvent } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { globalHost } from 'helpers/';
import {
  Button,
  TextField,
  Grid,
  ListItem,
  Typography,
  createStyles,
  Theme,
  Checkbox,
  withStyles
} from '@material-ui/core';
import { PostPasswordReset } from 'redux/database/User Instance API';
import { UserInstance } from 'types/interfaces';
import { useDispatch } from 'react-redux';
import { SET_GLOBAL_HOSTNAME, SET_TOKEN } from 'redux/actions/types';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTypedSelector } from 'redux/reducers';

const GreenCheckbox = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.success.main,
      '&$checked': { color: theme.palette.success.main }
    }
  })
)((props: { checked: boolean }) => <Checkbox color="default" {...props} />);

const PasswordReset = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { SupportPhone } = useTypedSelector((s) => s.config.publicSettings);

  let location = globalHost(window.location);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const parsed = queryString.parse(history.location.search) as {
    piid: string;
    psdid: string;
    pdid: string;
    uiid: string;
    sc: string;
    psiid: string;
  };

  const match =
    password.length > 0 &&
    passwordRepeat.length > 0 &&
    password === passwordRepeat;

  const [errors, setErrors] = useState({
    TwoUppercase: false,
    OneSpecial: false,
    TwoDigits: false,
    ThreeLowercase: false,
    LengthOfEight: false
  });

  const updatePassword = async () => {
    const data: UserInstance = emptyUserInstance();
    data.UserInstancePassword = password;
    const res = await PostPasswordReset(data);
    if (res.status === 200) {
      setSuccess(true);
      return setTimeout(() => {
        handleRedirect();
      }, 3000);
    } else {
      setFail(true);
    }
  };

  useEffect(() => {
    dispatch({ type: SET_GLOBAL_HOSTNAME, payload: location });
    dispatch({ type: SET_TOKEN, payload: parsed.uiid });
  }, []);

  const handlePasswordChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    isValid(value);
    setPassword(value);
  };

  const handleRepeatPassword = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e.target;
    setPasswordRepeat(value);
  };

  const handleRedirect = () => history.push('/sign-in');

  const isValid = (password: string) => {
    const TwoUppercase = new RegExp(`(?=.*[A-Z].*[A-Z])`);
    const OneSpecial = new RegExp(`(?=.*[!@#$&*])`);
    const TwoDigits = new RegExp(`(?=.*[0-9].*[0-9])`);
    const ThreeLowercase = new RegExp(`(?=.*[a-z].*[a-z].*[a-z])`);
    const LengthOfEight = new RegExp(`.{8}`);
    if (TwoUppercase.test(password))
      setErrors((s) => ({ ...s, TwoUppercase: true }));
    if (!TwoUppercase.test(password))
      setErrors((s) => ({ ...s, TwoUppercase: false }));
    if (OneSpecial.test(password))
      setErrors((s) => ({ ...s, OneSpecial: true }));
    if (!OneSpecial.test(password))
      setErrors((s) => ({ ...s, OneSpecial: false }));
    if (TwoDigits.test(password)) setErrors((s) => ({ ...s, TwoDigits: true }));
    if (!TwoDigits.test(password))
      setErrors((s) => ({ ...s, TwoDigits: false }));
    if (ThreeLowercase.test(password))
      setErrors((s) => ({ ...s, ThreeLowercase: true }));
    if (!ThreeLowercase.test(password))
      setErrors((s) => ({ ...s, ThreeLowercase: false }));
    if (LengthOfEight.test(password))
      setErrors((s) => ({ ...s, LengthOfEight: true }));
    if (!LengthOfEight.test(password))
      setErrors((s) => ({ ...s, LengthOfEight: false }));
  };

  const disabled = () => {
    const valid =
      errors.LengthOfEight &&
      errors.OneSpecial &&
      errors.ThreeLowercase &&
      errors.TwoDigits &&
      errors.TwoUppercase;
    if (match && valid) return false;
    return true;
  };

  if (fail) {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          padding: '20em'
        }}>
        <Alert severity="error">
          <AlertTitle>PASSWORD RESET FAIL</AlertTitle>
          <Typography>
            Your password reset failed, this is likely due to not completing the
            reset within the 1 hour window allocated. Please head back to the
            login screen and request another password reset link.
          </Typography>

          <br />

          <Typography>
            {`If you’re still having problems, please phone our customer services
            team on ${SupportPhone}`}
          </Typography>
          <Button fullWidth onClick={handleRedirect}>
            Login
          </Button>
        </Alert>
      </div>
    );
  }

  if (success) {
    return (
      <Alert
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh'
        }}>
        <Typography>
          You should be automatically re-directed to sign in. But if you have
          any problem you can click the login button below
        </Typography>
        <Button fullWidth onClick={handleRedirect}>
          Login
        </Button>
      </Alert>
    );
  }
  return (
    <Grid
      container
      direction="column"
      spacing={2}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}>
      <Grid item>
        <Alert severity="info">
          <AlertTitle>PASSWORD SECURITY</AlertTitle>
          <ListItem dense>
            <GreenCheckbox checked={errors.TwoUppercase} />
            <Typography>Ensure password has two uppercase letters.</Typography>
          </ListItem>
          <ListItem dense>
            <GreenCheckbox checked={errors.OneSpecial} />
            <Typography>
              Ensure password has one special case letter.
            </Typography>
          </ListItem>
          <ListItem dense>
            <GreenCheckbox checked={errors.TwoDigits} />
            <Typography>Ensure password has two digits.</Typography>
          </ListItem>
          <ListItem dense>
            <GreenCheckbox checked={errors.ThreeLowercase} />
            <Typography>
              Ensure password has three lowercase letters.
            </Typography>
          </ListItem>
          <ListItem dense>
            <GreenCheckbox checked={errors.LengthOfEight} />
            <Typography>Ensure password is of length 8.</Typography>
          </ListItem>
          <ListItem dense>
            <GreenCheckbox checked={match} />
            <Typography>Password Match</Typography>
          </ListItem>
        </Alert>
      </Grid>
      <br />
      <Grid item>
        <TextField
          type="password"
          placeholder="New Password"
          onChange={handlePasswordChange}
          value={password}
        />
      </Grid>
      <Grid item>
        <TextField
          type="password"
          placeholder="Repeat New Password"
          onChange={handleRepeatPassword}
          value={passwordRepeat}
        />
      </Grid>
      <Grid item>
        <Button onClick={updatePassword} disabled={disabled()}>
          Reset Password
        </Button>
      </Grid>
    </Grid>
  );
};

export default PasswordReset;

export const emptyUserInstance = () => {
  const UserInstance: UserInstance = {
    ConsoleUserInstanceId: 0,
    DefaultMessage: '',
    DocumentFooter: '',
    DocumentHeader: '',
    EmailFooter: '',
    FromAddress: '',
    FromName: '',
    Id: 0,
    IsPublished: false,
    ItemOrder: 0,
    LastModified: '0001-01-01T00:00:00',
    LogoURL: '',
    NickName: '',
    OwnerUserInstanceId: 0,
    ProfilePicture: '',
    ProxyUserInstanceId: 0,
    RelatedUserPermission: 0,
    RelationshipStatus: 0,
    SmtpHost: '',
    SmtpPassword: '',
    SmtpPort: 0,
    SmtpSSL: false,
    SmtpUsername: '',
    SummaryFields: undefined,
    SystemAccess: 0,
    TelephoneNumber: '',
    ThirdPartyId: '',
    Title: '',
    UserDefinitionId: 0,
    UserInstanceEmail: '',
    UserInstancePassword: ''
  };
  return UserInstance;
};
