import { Message } from '@microsoft/microsoft-graph-types';
import {
  IConversation,
  IMicrosoftMessages
} from 'components/MessageHub/interfaces';
import { Dispatch, SetStateAction } from 'react';

export const FolderTypes = {
  drafts: 'Drafts',
  archived: 'Archive',
  ['Sent Items']: 'SentItems',
  customFolder: 'customFolder',
  messages: 'Inbox'
} as const;

export type FolderType = keyof typeof FolderTypes;
export interface IThreadState {
  selectedThread: string;
  setSelectedThread: (threadId: string) => void;
  openThreads: { [key: string]: IConversation | null };
  setOpenThreads: Dispatch<
    SetStateAction<{ [key: string]: IConversation | null }>
  >;
  conversations: { [key: string]: IConversation } | string;
  handleOpen: (
    conversationId: string,
    index: number,
    conversations: any
  ) => Promise<void>;
  archivedConversations: { [key: string]: IConversation };
  customFolderConversations: { [key: string]: IConversation };
  sentItemsConversations: { [key: string]: IConversation };
  loading: ICustomLoading;
  setLoading: Dispatch<SetStateAction<ICustomLoading>>;
  refreshThreadReplies: ({
    conversationId
  }: {
    conversationId: string;
  }) => void;
  loadMoreMessages: (
    folderType: FolderType,
    folderIdentifier: string,
    page: number,
    setPage: React.Dispatch<SetStateAction<number>>,
    setLoading: React.Dispatch<SetStateAction<boolean>>,
    setHasMore: React.Dispatch<SetStateAction<boolean>>
  ) => Promise<void>;
}

export interface IParentThreadContext {
  flaggedLoading: string[];
  isArchiving: string[];
  isRead: string[];
  openStatus: any;
  setOpenStatus: Dispatch<SetStateAction<any>>;
  updateFlagStatus: ({
    conversation,
    newStatus,
    isBookmarked
  }: {
    conversation: IConversation;
    newStatus: 'flagged' | 'notFlagged' | 'complete';
    isBookmarked: boolean;
  }) => Promise<void>;
  handleArchiveMessage: ({
    conversation,
    isBookmark,
    setBookmarkedConversations
  }: {
    conversation: IConversation;
    isBookmark: boolean;
    setBookmarkedConversations: React.Dispatch<SetStateAction<IConversation[]>>;
  }) => Promise<void>;
  handleReadMessage: ({ messageId }: { messageId: string }) => Promise<void>;
}

export interface ICustomLoading {
  isLoading: boolean;
  amount: number | IConversation;
}

export interface IRenderParentMessage {
  conversation: IConversation;
  conversations: string | { [key: string]: IConversation } | IConversation[];
  idx: number;
  messages: IMicrosoftMessages;
  selectedThread: string;
  openThreads: any;
  openStatus: any;
  hoveredCard: string;
  setOpenStatus: any;
  isBookmarked: boolean;
  handleOpen: (
    conversationId: string,
    index: number,
    conversations: string | { [key: string]: IConversation } | IConversation[]
  ) => Promise<void>;
  updateFlagStatus: ({
    conversation,
    newStatus,
    isBookmarked
  }: {
    conversation: IConversation;
    newStatus: 'flagged' | 'notFlagged' | 'complete';
    isBookmarked: boolean;
  }) => Promise<void>;
  handleArchiveMessage: ({
    conversation,
    isBookmark,
    setBookmarkedConversations
  }: {
    conversation: IConversation;
    isBookmark: boolean;
    setBookmarkedConversations: React.Dispatch<SetStateAction<IConversation[]>>;
  }) => Promise<void>;
  toggleBookmark: (conversation: IConversation) => void;
  getSenderColor: (emailAddress: string) => string;
}
