import { Calculation } from 'types/calculatorInterfaces';
import { FunderDoc } from '../../interfaces';

const FunderTermStructure = ({
  FUNDER_DOC,
  Output
}: {
  FUNDER_DOC: FunderDoc;
  Output: Calculation;
}) => {
  FUNDER_DOC.HIRE_PERIOD =
    Output['Term'] + Output['_Balloon']?.ContractPeriod || 0;

  FUNDER_DOC.FUNDING_PROFILE = Output['FundingProfile'];
  FUNDER_DOC.FUNDING_PROFILE_DISPLAY =
    Output['InitialRentals'] && Output['InitialRentals'] !== 0
      ? `${Output['InitialRentals']} + ${Output['FundingProfile']}`
      : Output['FundingProfile'];
  FUNDER_DOC.DOCUMENTATION_FEE_PAYABLE =
    Output['_DocumentFee']?.DocFee_PaymentDate === 'FirstPayment'
      ? 'First Payment'
      : 'Contract Start';

  FUNDER_DOC.CONTRACT_PERIOD = Output['_Balloon']?.ContractPeriod || 0;
  FUNDER_DOC.OVERRIDE_PAYMENTS = (Output['OverridePayments'] || []).filter(
    (payment, index, array) => {
      if (payment.Amount !== 0) {
        return true;
      }
      if (FUNDER_DOC.CONTRACT_PERIOD === 1) {
        const nextPayment = array[index - 1];
        if (
          nextPayment &&
          nextPayment.StartPeriod === FUNDER_DOC.CONTRACT_PERIOD
        ) {
          return true;
        }
      }
      return false;
    }
  );

  return FUNDER_DOC;
};

export default FunderTermStructure;
