import { useState } from 'react';
import { Chip, IconButton, Tooltip } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';

export const ContactChips = ({
  selectedContactEmails
}: {
  selectedContactEmails: string[];
}) => {
  const [page, setPage] = useState(0);
  const itemsPerPage = 15;

  const pages = Math.ceil(selectedContactEmails.length / itemsPerPage);

  const handleNext = () => {
    if (page < pages - 1) {
      setPage(page + 1);
    }
  };

  const handleBack = () => {
    if (page > 0) {
      setPage(page - 1);
    }
  };

  const displayedContacts = selectedContactEmails.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          marginTop: 15,
          padding: '40px 0',
          justifyContent: 'center'
        }}>
        {displayedContacts.map((contactName) => (
          <Chip
            label={contactName}
            key={contactName}
            style={{ marginBottom: 10, marginRight: 10 }}
          />
        ))}
      </div>

      {selectedContactEmails.length >= itemsPerPage && (
        <>
          <IconButton
            onClick={handleBack}
            disabled={page <= 0}
            style={{ position: 'absolute', left: 5, top: '40%' }}>
            <Tooltip title="Previous page">
              <ChevronLeft style={{ fontSize: 30 }} />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={page >= pages - 1}
            style={{ position: 'absolute', right: 5, top: '40%' }}>
            <Tooltip title="Next page">
              <ChevronRight style={{ fontSize: 30 }} />
            </Tooltip>
          </IconButton>
        </>
      )}
    </>
  );
};

export default ContactChips;
