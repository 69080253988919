import { useContext, useEffect, useRef, useState } from 'react';
// import '../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw, EditorState, ContentState } from 'draft-js';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  MenuItem,
  Popper,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { toolBar } from './toolBar';
import { PrimaryButton, SecondaryButton } from 'common';
import { ButtonGroup, Paper } from '@material-ui/core';
import { SiMinutemailer as Plane } from 'react-icons/si';
import { MessageHubProvider } from '../..';
import { useIsAuthenticated } from '@azure/msal-react';
import { ConnectAccountButton } from 'components/Settings/ConnectAccountButton';
import DropTarget from 'common/DropTarget';
import { CustomDialog } from 'common/Dialog';
import htmlToDraft from 'html-to-draftjs';
import { useProcess } from 'hooks';
import { formats } from 'data/formats';
import { Delete } from '@material-ui/icons';
import { useTypedSelector } from 'redux/reducers';
import { Alert } from '@material-ui/lab';
import { validateEmail } from 'helpers/validateEmail';
import RuleConfirmation from 'components/Stepper/components/Steps/ActiveStep/components/AlertDialog/components/RuleConfirmation';

const EmailEditor = ({
  editorOpen,
  setEditorOpen
}: {
  editorOpen?: { open: boolean; ruleId: number };
  setEditorOpen;
}) => {
  const { currentOverView } = useProcess();
  const { deal } = useTypedSelector((s) => s.dealSummary);
  const theme = useTheme();
  const isAuth = useIsAuthenticated();

  const [open, setOpen] = useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const anchorRef = useRef(null);

  function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  const MessageProvider = useContext(MessageHubProvider);
  const {
    clearEditorState,
    editorState,
    files,
    handleHTML,
    loading,
    selectedThreadKey,
    sendEmailToUser,
    setEditorState,
    setFiles,
    setSubject,
    subject,
    threads,
    conversation,
    PopulatedEmail: populatedEmail,
    setEmails,
    emails
  } = MessageProvider;

  const thread = selectedThreadKey && threads?.[selectedThreadKey];
  useEffect(() => {
    if (populatedEmail) {
      const customSubject = `${currentOverView.ProcessDefinitionTitle} - ${currentOverView.UserInstanceId}`;
      setSubject(customSubject);

      const blocksFromHtml = htmlToDraft(populatedEmail.Body);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);

      setEditorState(newEditorState);
      handleHTML(populatedEmail.Body);

      if (populatedEmail.Attachment) {
        const base64String = populatedEmail.Attachment;
        const arrayBuffer = base64ToArrayBuffer(base64String);

        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        const file = new File([blob], 'attachment.pdf', {
          type: 'application/pdf'
        });

        setFiles([file]);
      }
    }
  }, [populatedEmail]);

  const getIsReplyAll = (): boolean | undefined => {
    if (!thread) return;
    if (!deal.ruleConversations) {
      return thread ? thread.conversationId !== undefined : false;
    }

    const ruleList = deal.ruleConversations[thread.members[1]];
    if (thread?.members && ruleList) {
      if (ruleList[thread.type]) {
        return isReplyAllConditionMet({ deal, thread, conversation });
      }
    }
  };

  const handleAddEmail = (email) => {
    if (email && validateEmail(email) && !emails.includes(email)) {
      setEmails((prevEmails) => [...prevEmails, email]);
      setEmail('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && validateEmail(email)) {
      event.preventDefault();
      handleAddEmail(email);
    }
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleClickAway = () => {
    if (isValid) handleAddEmail(email);
  };

  const isValid = validateEmail(email);
  const isReplyAll = getIsReplyAll();

  if (!isAuth) return <ConnectAccountButton />;
  return (
    <>
      <Button onClick={() => setOpen(true)} fullWidth>
        Write / Reply
      </Button>

      <CustomDialog
        open={editorOpen ? editorOpen.open : open}
        handleClose={() => {
          editorOpen ? setEditorOpen(false) : setOpen(false);
          return { event: {}, reason: 'backdropClick' };
        }}
        maxSize="lg"
        alert={{
          title: `Email Draft`,
          description:
            'Fully Customize Your Email Before Sending to the Landing Page User',
          type: 'info'
        }}>
        <Paper
          elevation={3}
          style={{
            margin: theme.spacing(1),
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius
          }}>
          {!isReplyAll && (
            <>
              <Alert severity="warning">
                <Typography style={{ fontWeight: 'bold' }}>
                  {`Please note: CC'ing individuals grants them ongoing access to
                  this conversation, keeping them looped in indefinitely. To add
                  users, simply press 'Enter' after typing their email.`}
                </Typography>
              </Alert>
              <TextField
                label="Subject"
                fullWidth
                margin="dense"
                variant="outlined"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
              />

              <ClickAwayListener onClickAway={handleClickAway}>
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',

                      marginBottom: '8px'
                    }}>
                    {emails.map((email, index) => (
                      <Chip
                        key={index}
                        label={email}
                        onDelete={() =>
                          setEmails(emails.filter((e) => e !== email))
                        }
                      />
                    ))}
                  </Box>
                  <TextField
                    label="CC"
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    value={email}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    ref={anchorRef}
                  />
                  <Popper
                    open={isValid && email !== ''}
                    anchorEl={anchorRef.current}
                    placement="bottom-start">
                    <Paper>
                      <MenuItem onClick={handleAddEmail}>Add {email}</MenuItem>
                    </Paper>
                  </Popper>
                </Box>
              </ClickAwayListener>
            </>
          )}

          <div style={{ height: theme.spacing(0.5) }} />

          <DropTarget
            formats={formats}
            count={2}
            onUpload={(e) => setFiles((s: any) => (s ? s.concat(e) : e))}
            title="Attachments ..."
            keyId="texttyper"
          />

          {files.map((file: any, idx: number) => (
            <div
              key={idx}
              style={{
                padding: theme.spacing(1),
                display: 'flex',
                alignItems: 'center'
              }}>
              <Button
                variant="contained"
                onClick={() => {
                  const url = URL.createObjectURL(file);
                  const a = document.createElement('a');
                  a.href = url;
                  a.download = file.name;
                  document.body.appendChild(a);
                  a.click();
                  document.body.removeChild(a);
                  URL.revokeObjectURL(url);
                }}
                style={{ paddingRight: '15px' }}>
                {file.name}
                <IconButton
                  edge="end"
                  color="secondary"
                  onClick={(e) => {
                    e.stopPropagation();
                    const newFiles = files.filter((_, i) => i !== idx);
                    setFiles(newFiles);
                  }}
                  style={{ marginLeft: 'auto' }}>
                  <Delete />
                </IconButton>
              </Button>
            </div>
          ))}

          <div style={{ height: theme.spacing(0.5) }} />
          <Editor
            editorState={editorState}
            // toolbarHidden={!edit}
            spellCheck
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            toolbar={toolBar}
            placeholder="Email Body ... "
            onContentStateChange={(contentState) => {
              const html = stateToHTML(convertFromRaw(contentState));
              if (handleHTML) handleHTML(html);
            }}
            onEditorStateChange={(editorState) => {
              setEditorState(editorState);
            }}
            toolbarStyle={{
              background: theme.palette.background.default,
              borderRadius: theme.shape.borderRadius
              // border: '1px black solid'
            }}
            editorStyle={{
              border: `1px solid ${theme.palette.divider}`,
              padding: theme.spacing(2),
              borderRadius: theme.shape.borderRadius
            }}
          />
          <div style={{ height: theme.spacing(0.5) }} />
          <ButtonGroup>
            {!populatedEmail && (
              <SecondaryButton onClick={clearEditorState}>
                Clear
              </SecondaryButton>
            )}

            {/* <RuleConfirmation
              open={openConfirmation}
              handleClose={() => setOpenConfirmation(false)}
              action={{
                Title: 'Landing Page Email'
              }}
              ruleId={editorOpen?.ruleId || 0}
              onSubmit={async () => {
                await sendEmailToUser();
                setOpenConfirmation(false);
                setOpen(false);
              }}
              loading={loading}
            /> */}

            <PrimaryButton
              variant="contained"
              onClick={async () => {
                await sendEmailToUser();
                setOpen(false);
              }}
              disabled={(thread && thread?.members.length <= 1) || loading}>
              {loading ? (
                <CircularProgress />
              ) : isReplyAll ? (
                'Reply All'
              ) : (
                'Send'
              )}
              <Plane style={{ marginLeft: 8, fontSize: 20 }} />
            </PrimaryButton>
          </ButtonGroup>
        </Paper>
      </CustomDialog>
    </>
  );
};

export default EmailEditor;

const isReplyAllConditionMet = ({ conversation, deal, thread }) => {
  if (!conversation) return false;

  if (!deal.ruleConversations || !deal.ruleConversations[thread.members[1]])
    return false;
  if (deal.ruleConversations[thread.members[1]][thread.type] === undefined)
    return false;
  return true;
};
