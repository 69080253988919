import { firebase } from 'redux/firebase';
import { EventNames, Event } from '../types';

export const fetchEventDetails = async ({
  emailId,
  eventName
}: {
  emailId: string;
  eventName: EventNames;
}) => {
  const ref = firebase
    .firestore()
    .collection('email')
    .doc(emailId)
    .collection('event')
    .where('event', '==', eventName);

  try {
    const snap = await ref.get();

    let events: Event[] = [];

    snap.forEach((doc: any) => {
      events.push(doc.data() as Event);
    });

    if (eventName === EventNames.Click || eventName === EventNames.Open) {
      events = Object.values(
        events.reduce((acc, event) => {
          if (!acc[event.email]) {
            acc[event.email] = { ...event, count: 1 };
          } else {
            acc[event.email].count++;
          }
          return acc;
        }, {})
      );
    }

    return events;
  } catch (error) {
    console.error('Error fetching event stats: ', error);
  }
};
