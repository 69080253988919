import { Dispatch, SetStateAction } from 'react';
import SimpleTable from 'components/SimpleTable';
import { parse } from 'fecha';
import Time from 'common/Time';
import CreateUserDialog from 'components/User/CreateUserDialog';
import RefreshIcon from '@material-ui/icons/Refresh';
import { BugTracker } from 'Utils/Bugtracker';
import { useTypedSelector } from 'redux/reducers';
import { UserInstance, CompleteUserInstance } from 'types/interfaces';
import { IRelationshipTable } from './interfaces';
import { getRelationship } from 'redux/actions/GraphQlActions';

export const ActivityRelationships = ({
  handleRowClick,
  data,
  title,
  selectedTab,
  setData,
  refreshData,
  rowData
}: {
  handleRowClick: (props: IRelationshipTable) => void;
  data: UserInstance[];
  title: string;
  selectedTab: number;
  setData: Dispatch<SetStateAction<UserInstance[]>>;
  refreshData: () => Promise<void>;
  rowData: CompleteUserInstance;
}) => {
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const token = useTypedSelector((s) => s.user.auth.token);
  const loggedInUser = useTypedSelector((s) => s.user.user);

  const get = (name: string, row: IRelationshipTable) => {
    const hash = row.UserInstanceEmail;
    const search = ',';
    const keywords: string[] = hash.split(search);
    let item: any;

    keywords.forEach((el) => {
      const search2 = '=';
      let els: string[] = el.split(search2);
      if (els.length === 2) {
        if (els[0] === name) {
          if (name === 'start' || name === 'end') {
            const dateobject: Date | null = parse(els[1], 'YYYY-MM-DDTHH:mm');
            if (dateobject) item = <Time time={dateobject} type="mymaskB" />;
          } else {
            item = els[1];
          }
        }
      }
    });
    return item;
  };

  const onSuccess = (e) => {
    refreshData();
    const dataNew = [...data];
    dataNew.unshift(e);
    setData([...dataNew]);
  };

  return (
    <SimpleTable
      actions={[
        {
          icon: () => (
            <CreateUserDialog
              UserDefinitionId={selectedTab}
              loading
              onSuccess={onSuccess}
              selectedUser={{
                UserDefinitionId: selectedTab,
                UserInstanceId: rowData.UserInstance.Id
              }}
            />
          ),
          iconProps: { component: 'div' },
          tooltip: 'Create new record',
          isFreeAction: true
        },
        {
          icon: () => <RefreshIcon />,
          tooltip: 'Refresh Data',
          isFreeAction: true,
          onClick: () => refreshData()
        }
      ]}
      columns={[
        {
          title: 'Type',
          render: (row: IRelationshipTable) => get('type', row)
        },
        {
          title: 'Title',
          field: 'Title',
          searchable: true
        },
        {
          title: 'Starts',
          render: (row: IRelationshipTable) => get('start', row),
          editComponent: (props) => (
            <input
              onChange={(e) => props.onChange(e.target.value)}
              type="text"
              value={props.value}
            />
          )
        }
      ]}
      data={data}
      editable={{
        onRowDelete: (oldData) => async (resolve, reject) => {
          const loggedInUserId = loggedInUser.Id;
          const activityId = oldData.Id;
          const targetUserId: number = rowData.UserInstance.Id;

          // 1. Remove 2 way relationship between loggedInUser and activity
          const deleteFirstRelationship = async (): Promise<
            string | undefined
          > => {
            let PrimaryUserInstanceId = loggedInUserId;
            let RelatedUserInstanceId = activityId;

            // Delete First
            await getRelationship({
              action: 'DELETE',
              PrimaryUserInstanceId,
              RelatedUserInstanceId,
              baseUrl,
              UserDefinitionId: loggedInUser.UserDefinitionId
            });

            // Make Ref & Delete Second
            const response = (await getRelationship({
              action: 'DELETE',
              PrimaryUserInstanceId: RelatedUserInstanceId,
              RelatedUserInstanceId: PrimaryUserInstanceId,
              baseUrl,
              UserDefinitionId: oldData.UserDefinitionId
            })) as string | undefined;

            if (response) return response;
          };

          // 2. Remove 2 way relationship between targetUser and activity
          const deleteSecondRelationship = async (): Promise<
            string | undefined
          > => {
            let PrimaryUserInstanceId: number = targetUserId;
            let RelatedUserInstanceId = activityId;

            // Delete First
            await getRelationship({
              action: 'DELETE',
              PrimaryUserInstanceId,
              RelatedUserInstanceId,
              baseUrl,
              UserDefinitionId: rowData.UserInstance.UserDefinitionId
            });

            // Make Ref & Delete Second
            const response = (await getRelationship({
              action: 'DELETE',
              PrimaryUserInstanceId: RelatedUserInstanceId,
              RelatedUserInstanceId: PrimaryUserInstanceId,
              baseUrl,
              UserDefinitionId: oldData.UserDefinitionId
            })) as string | undefined;

            if (response) return response;
          };

          const firstResponse = await deleteFirstRelationship();

          let secondResponse: string | undefined;
          if (loggedInUserId !== targetUserId) {
            secondResponse = await deleteSecondRelationship();
          }

          if (firstResponse || secondResponse) {
            const dataDelete = [...data];
            const index = oldData.tableData.id;
            dataDelete.splice(index, 1);
            setData([...dataDelete]);
          }

          resolve();
        }
      }}
      handleRowClick={handleRowClick}
      title={title}
    />
  );
};
