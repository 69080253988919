import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import {
  makeStyles,
  createStyles,
  Typography,
  Theme,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from '@material-ui/core';
import firebase from 'firebase';
import { useTypedSelector } from 'redux/reducers';
import { Alert, AlertTitle } from '@material-ui/lab';
import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
import { useDispatch } from 'react-redux';

import alertSound1 from '../../../assets/sounds/alertSound1.ogg';
import alertSound2 from '../../../assets/sounds/alertSound2.ogg';
import alertSound3 from '../../../assets/sounds/alertSound3.ogg';
import { PlayArrow } from '@material-ui/icons';
import { BugTracker } from 'Utils/Bugtracker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%'
    },
    button: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
      width: '100%'
    }
  })
);

interface INotificationSettings {
  notification: {
    soundName: string;
    fileRoot: string;
  };
  notificationTime: number;
  initialNotification: string;
  initialNotificationTime: number;
}

const soundMap = {
  'Notification Sound 1': alertSound1,
  'Notification Sound 2': alertSound2,
  'Notification Sound 3': alertSound3
};

const NotificationSettings = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useTypedSelector((s) => s.user);

  const [settings, setSettings] = useState<INotificationSettings>({
    notification: {
      soundName: '',
      fileRoot: ''
    },
    notificationTime: 10,
    initialNotification: '',
    initialNotificationTime: 10
  });

  const uploadAudioFile = async (fileUrl: string) => {
    const UserInstanceId = user.Id.toString();

    try {
      if (fileUrl) {
        const response = await fetch(fileUrl);

        if (response) {
          const blob = await response.blob();
          const file = new File(
            [blob],
            `${settings.notification.soundName}.ogg`,
            {
              type: 'audio/ogg'
            }
          );

          const storageRef = firebase.storage().ref();
          const fileRef = storageRef.child(
            `users/${UserInstanceId}/${file.name}`
          );

          const snapshot = await fileRef.put(file);
          const uploadedFileUrl = await snapshot.ref.getDownloadURL();
          return uploadedFileUrl;
        }
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const handleSave = async () => {
    const UserInstanceId = user.Id.toString();
    try {
      let fileUrl = settings.notification.fileRoot;
      if (fileUrl) {
        fileUrl = await uploadAudioFile(fileUrl);
      }

      await firebase
        .firestore()
        .collection('userAccount')
        .doc(UserInstanceId)
        .set(
          {
            notification: {
              fileRoot: fileUrl,
              soundName: settings.notification.soundName
            },
            notificationTime: settings.notificationTime
          },
          { merge: true }
        );

      setSettings((prevSettings) => ({
        ...prevSettings,
        initialNotification: fileUrl,
        initialNotificationTime: prevSettings.notificationTime
      }));

      dispatch(
        createNotification(
          successNotif('Notification Settings Saved Successfully!')
        )
      );
    } catch (e) {
      dispatch(
        createNotification(
          errorNotif(
            'Error Saving Notification Settings. Please contact support if this issue persists.'
          )
        )
      );
    }
  };

  const handleChange = (
    key: keyof INotificationSettings,
    value: string | number | unknown
  ) => {
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value
    }));
  };

  const playSound = (src: string) => {
    const audio = new Audio(src);
    audio.play();
  };

  useEffect(() => {
    const userId = user.Id.toString();
    const fetchUserSettings = async () => {
      try {
        const doc = await firebase
          .firestore()
          .collection('userAccount')
          .doc(userId)
          .get();
        if (doc.exists) {
          const data = doc.data();
          if (data) {
            setSettings({
              notification: data.notification || {
                soundName: '',
                fileRoot: ''
              },
              notificationTime: data.notificationTime || 10,
              initialNotification: data.notification || '',
              initialNotificationTime: data.notificationTime || 10
            });
          }
        }
      } catch (error) {
        console.error('Error fetching user settings: ', error);
      }
    };

    fetchUserSettings();
  }, [user]);

  const hasChanges =
    settings.notification.soundName !== settings.initialNotification ||
    settings.notificationTime !== settings.initialNotificationTime;

  return (
    <Paper className={classes.paper}>
      <Alert severity="info">
        <AlertTitle>Notification sounds</AlertTitle>
        <Typography variant="h6" color="textSecondary">
          This affects the calendar notice & allows you to select a new notice
          from a dropdown including how many minutes before you would like to
          get a notification.
        </Typography>
      </Alert>

      <FormControl className={classes.formControl}>
        <InputLabel id="notification-sound-label">
          Notification Sound
        </InputLabel>

        <Select
          labelId="notification-sound-label"
          value={settings.notification.soundName}
          onChange={(e) => {
            const selectedValue = e.target.value as string;
            const selectedText = selectedValue;
            const selectedFileRoot = soundMap[selectedValue];

            handleChange('notification', {
              soundName: selectedText,
              fileRoot: selectedFileRoot
            });
          }}
          renderValue={(selectedValue) => {
            const selectedText = selectedValue as string;
            return selectedText;
          }}>
          <MenuItem value={'Notification Sound 1'}>
            Notification Sound 1
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                playSound(alertSound1);
              }}
              style={{ marginLeft: 'auto' }}>
              <PlayArrow />
            </IconButton>
          </MenuItem>
          <MenuItem value={'Notification Sound 2'}>
            Notification Sound 2
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                playSound(alertSound2);
              }}
              style={{ marginLeft: 'auto' }}>
              <PlayArrow />
            </IconButton>
          </MenuItem>
          <MenuItem value={'Notification Sound 3'}>
            Notification Sound 3
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                playSound(alertSound3);
              }}
              style={{ marginLeft: 'auto' }}>
              <PlayArrow />
            </IconButton>
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel id="notification-time-label">Notification Time</InputLabel>
        <Select
          labelId="notification-time-label"
          value={settings.notificationTime}
          onChange={(e) =>
            handleChange('notificationTime', e.target.value as number)
          }>
          <MenuItem value={5}>5 minutes</MenuItem>
          <MenuItem value={10}>10 minutes</MenuItem>
          <MenuItem value={15}>15 minutes</MenuItem>
          <MenuItem value={30}>30 minutes</MenuItem>
          <MenuItem value={60}>1 hour</MenuItem>
          <MenuItem value={360}>6 hours</MenuItem>
          <MenuItem value={1440}>24 hours</MenuItem>
        </Select>
      </FormControl>

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={handleSave}
        disabled={!hasChanges}>
        Save
      </Button>
    </Paper>
  );
};

export default NotificationSettings;
