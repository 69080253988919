import { gql } from '@apollo/client';

// Relationship API For Mutation "List All Related"
export const RELATIONSHIP_LIST_ALL_RELATED = gql`
  mutation UpdateRelationships(
    $baseUrl: String
    $action: RelationShipAction
    $data: [RelationshipInput]
    $relatedUserInstanceId: Int
  ) {
    UpdateRelationships(
      baseURL: $baseUrl
      action: $action
      data: $data
      RelatedUserInstanceId: $relatedUserInstanceId
    ) {
      ... on EmptyResponse {
        Items {
          ...ItemFields
        }
      }
    }
  }
`;

// Relationship API For Mutation "Delete"
export const RELATIONSHIP_DELETE = gql`
  mutation UpdateRelationships(
    $baseUrl: String
    $primaryUserInstanceId: Int
    $relatedUserInstanceId: Int
    $action: RelationShipAction
  ) {
    UpdateRelationships(
      baseURL: $baseUrl
      PrimaryUserInstanceId: $primaryUserInstanceId
      RelatedUserInstanceId: $relatedUserInstanceId
      action: $action
    ) {
      ... on EmptyResponse {
        Items
      }
    }
  }
`;

// Relationship API For Mutation "Create"
export const RELATIONSHIP_CREATE = gql`
  mutation UpdateRelationships(
    $data: [RelationshipInput]
    $action: RelationShipAction
    $baseUrl: String
  ) {
    UpdateRelationships(data: $data, action: $action, baseURL: $baseUrl) {
      ... on EmptyResponse {
        Items
      }
    }
  }
`;

// Relationship API For Mutation "Post"
export const RELATIONSHIP_POST = gql`
  mutation UpdateRelationships(
    $baseUrl: String
    $action: RelationShipAction
    $primaryUserInstanceId: Int
    $relatedUserInstanceId: Int
    $title: String
  ) {
    UpdateRelationships(
      baseURL: $baseUrl
      action: $action
      PrimaryUserInstanceId: $primaryUserInstanceId
      RelatedUserInstanceId: $relatedUserInstanceId
      Title: $title
    ) {
      ... on EmptyResponse {
        Items
      }
    }
  }
`;

// Relationship API For Mutation "Update Status"
export const RELATIONSHIP_UPDATE_STATUS = gql`
  mutation UpdateRelationships(
    $relatedUserInstanceId: Int
    $status: Int
    $action: RelationShipAction
    $baseUrl: String
  ) {
    UpdateRelationships(
      RelatedUserInstanceId: $relatedUserInstanceId
      Status: $status
      action: $action
      baseURL: $baseUrl
    ) {
      ... on EmptyResponse {
        Items
      }
    }
  }
`;
