import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 10,
      marginBottom: 10
    },
    line: {
      background: theme.palette.divider,
      width: '100%',
      height: 1
    },
    text: {
      color: theme.palette.divider,
      paddingLeft: 10,
      paddingRight: 10
    },
    noTextLine: {
      marginTop: 10,
      marginBottom: 10,
      background: theme.palette.divider,
      width: '100%',
      height: 1
    }
  })
);

const DividerCommon = ({ text, vertical, className }) => {
  const classes = useStyles();
  if (text) {
    return (
      <div className={classes.container}>
        <div className={classes.line} />
        <p className={classes.text}>{text.toUpperCase()}</p>
        <div className={classes.line} />
      </div>
    );
  }
  return <div className={classes.noTextLine} />;
};

export default DividerCommon;
