// This component takes a redflag search on either a company or a directorship and a User Definition ID.
// Can be used in either a
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid, Typography, TextField as MUITEXT } from '@material-ui/core';
import { TextField } from 'components/Fields';
import { StyledSelect as Select } from 'common/StyledSelect';
import { useTheme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getProcessesByUserDefinition } from 'redux/actions/processes';
import Table, { MTableToolbar } from 'components/SimpleTable';
import { Alert } from '@material-ui/lab';
import { UserDefinition, CompleteUserInstance } from 'types/interfaces';
import {
  IDirector,
  IRedFlagObject,
  IRedflagAPIData
} from 'types/redflagInterfaces';

import { IData } from '../CreateAsUserButton/interfaces';
import { useTypedSelector } from 'redux/reducers';
import { IUserDefinitionOptionExtension } from '../NewUserButton/functions/interfaces';
import { validateEmail } from 'helpers/validateEmail';
interface Props {
  UserDefinition: any;
  setSupplier: any;
  data: Partial<IRedFlagObject>;
  company: IRedflagAPIData;
  directorships: any;
  updateSubmissionData: (props: Partial<IData>) => Promise<void>;
  directors: any;
  setDirectors: Dispatch<SetStateAction<IDirector[] | null>>;
  setBlurredDirectors: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
  setProcessToStart: Dispatch<SetStateAction<{ label: string; value: number }>>;
}

const RedFlagCreateImport = (props: Props) => {
  const { setProcessToStart, UserDefinition } = props;
  const UserDefinitionList = useTypedSelector(
    (s) => s.config.settings.UserDefinitionList
  );

  // Relationship
  const [relationship, setRelationship] = React.useState<UserDefinition>(
    {} as UserDefinition
  );

  const [emailInstance, setEmailInstance] = useState<string>('');

  const is_directorship = props?.data?.type === 'directorship';
  const is_company = props?.data?.type === 'company';

  const [noOfResignedDirectors, setNoOfResignedDirectors] = useState<number>(0);
  const [UserInstance, setUserInstance] = useState(() =>
    initializeUserInstance(props?.data, UserDefinition)
  );

  // Using Number for the Cross-Platform but if Number doesn't work use Name
  const isSupplier =
    UserDefinition?.Id === 26
      ? true
      : UserDefinition?.label === 'Suppliers'
      ? true
      : false;

  const handleUIChange = ({ value, key }: { value: string; key: string }) => {
    let newUserInstance = Object.assign({}, UserInstance);
    newUserInstance.UserInstanceEmail = value;

    setUserInstance(newUserInstance);
    setEmailInstance(newUserInstance.UserInstanceEmail);
    if (key === 'UserInstanceEmail') {
      const newState = JSON.parse(JSON.stringify(props?.directors));
      const element = newState[0];
      element.email = value;
      if (value === '') {
        element.isPrimary = false;
        element.sendGDPRonCreate = false;
        element.process = false;
        element.portal = false;
        element.processDefinitionId = 0;
      }

      if (isSupplier) {
        let newCompanyData = props.company;
        const newData: Partial<IRedFlagObject> = {
          attributes: props?.company?.data?.attributes,
          id: props?.company?.data?.id,
          links: props?.company?.data?.links,
          relationships: props?.company?.data?.relationships,
          type: props?.company?.data?.type
          // companyEmail: newUserInstance?.UserInstanceEmail
        };
        newCompanyData.data = newData;

        props.setSupplier(newCompanyData);
      }
      props.setDirectors(newState);
    }
  };

  const handleChange = ({ id, name, row, value }: any) => {
    const newState = JSON.parse(JSON.stringify(props?.directors));
    const element = newState.find((r: IDirector) => r.id === row.id);
    if (name === 'email') {
      element[name] = value;
      if (value === '') {
        element.isPrimary = false;
        element.sendGDPRonCreate = false;
        element.process = false;
        element.portal = false;
        element.processDefinitionId = 0;
      }
    } else {
      element[name] = !element[name];
    }

    props.setDirectors(newState);
  };

  //! NOT USED ANYMORE
  // const getUserInstanceSummaryListMethod = async (value) => {
  //   const UserDefinitionId = value || props.UserDefinition.Id;
  //   const res = await getUserInstanceSummaryList({
  //     token,
  //     UserDefinitionId
  //   });
  //   if (res && res.data) {
  //     let optionsIns: any = [];
  //     Object.values(res.data).forEach(
  //       ({ UserInstance: { Id, Title } }: any) => {
  //         optionsIns.push({
  //           value: Id,
  //           label: Title
  //         });
  //       }
  //     );
  //     setRelationshipUserInstanceList(optionsIns);
  //   }
  // };

  const handleUpdateSubmissionData = async () => {
    const config: Partial<IData> = {
      redflag: {} as CompleteUserInstance,
      company: props.company as IRedflagAPIData,
      data: props.data as IRedFlagObject,
      UserInstance,
      relationship
    };
    await props.updateSubmissionData(config);
  };

  const setInitialStatewithCompany = (props: any) => {
    const areDirectors = props?.company?.directors?.length > 0;
    if (is_company && areDirectors) {
      let directors: any | IDirector = [];
      let noOfResignedDirectors = 0;
      props?.company?.directors?.map(
        (director: {
          attributes: {
            date_resigned: null | string;
            first_name: string;
            surname: string;
            id: string;
          };
          id: string;
        }) => {
          const activeDirector = director.attributes.date_resigned === null;
          if (!activeDirector)
            noOfResignedDirectors = noOfResignedDirectors + 1;

          const isDuplicate = directors.some(
            (i: IDirector) => i.id === director.attributes.id
          );

          if (!isDuplicate && activeDirector) {
            directors.push({
              id: director?.attributes?.id,
              email: '',
              full_name: `${director?.attributes?.first_name} ${director?.attributes?.surname}`,
              isPrimary: false,
              sendGDPRonCreate: false,
              process: false,
              portal: false,
              processDefinitionId: 0
            });
          }
        }
      );
      props?.setDirectors(directors);
      setNoOfResignedDirectors(noOfResignedDirectors);
    }
  };

  const onRowDelete = async (data) => {
    const originalData = JSON.parse(JSON.stringify(props.directors));
    originalData.splice(data.tableData.id, 1);
    props?.setDirectors(originalData);
  };

  const data = props.directors ? props.directors : [];
  const onRowAdd = async (newData) => {
    const { email, full_name } = newData;
    let directors = data;
    directors.push({
      id: email,
      email,
      full_name,
      isPrimary: false,
      sendGDPRonCreate: false,
      process: false,
      portal: false,
      processDefinitionId: 0
    });

    props.setBlurredDirectors((prev) => ({
      ...prev,
      [newData.email]: true
    }));

    props?.setDirectors(directors);
    await handleUpdateSubmissionData();
  };

  React.useEffect(() => {
    let newUserInstance = Object.assign({}, UserInstance);
    newUserInstance.UserDefinitionId = props.UserDefinition.Id;
    setUserInstance(newUserInstance);
  }, [props.UserDefinition]);

  const autoSelectContactUserDefinition = async () => {
    // Make sure there is a list of user definitions ...

    // Get options list
    let options: IUserDefinitionOptionExtension[] = [];
    UserDefinitionList?.forEach((item: UserDefinition) => {
      const extendedItem: IUserDefinitionOptionExtension = {
        ...item,
        value: item.Id,
        label: item.Title
      };
      options.push(extendedItem);
    });

    let ContactUserDefinition: IUserDefinitionOptionExtension | undefined =
      options.find((el: IUserDefinitionOptionExtension) => el.value === 528);

    if (!ContactUserDefinition?.label.includes('Contacts')) {
      ContactUserDefinition = options.find(
        (el: IUserDefinitionOptionExtension) => el?.label.includes('Contacts')
      );
    }

    if (ContactUserDefinition !== undefined) {
      setProcessToStart({
        label: 'Select a process to use as GDPR',
        value: 0
      });

      setRelationship(ContactUserDefinition);

      //! NOT USED ANYMORE
      // const processes = await getProcessesByUserDefinition({
      //   token: typeof token === 'string' ? token : '',
      //   UserDefinitionId: ContactUserDefinition?.Id.toString()
      // });

      // setAvailableProcesses(processes?.data);

      //! NOT USED ANYMORE
      // getUserInstanceSummaryListMethod(ContactUserDefinition.value);
    }
  };

  const runStartUp = async () => {
    setInitialStatewithCompany(props);
    await handleUpdateSubmissionData();
  };

  useEffect(() => {
    runStartUp();
  }, []);

  useEffect(() => {
    // If Not in process Get and set the user definition.
    // 1. Check if the use definition exist and if not go and get it
    runStartUp();
    autoSelectContactUserDefinition();

    // SETTING INIT DIRECTOR HERE IF IS A DIRECTOR
    if (is_directorship) {
      let directors: any | IDirector = [];
      directors.push({
        id: props.data.id,
        email: '',
        full_name: `${props?.data?.attributes?.first_name} ${props?.data?.attributes?.surname}`,
        isPrimary: false,
        sendGDPRonCreate: false,
        process: false,
        portal: false,
        processDefinitionId: 0
      });
      props?.setDirectors(directors);
    }
  }, [props.company]);

  const columns = [
    { field: 'full_name', title: 'Full Name', width: 100 },
    {
      field: 'id',
      title: 'Id',
      width: 100,
      editable: 'never',
      render: (row: IDirector) => {
        const rowId = row?.id;

        if (!rowId) {
          return;
        }

        const splitRowId = rowId.toString().split('-');
        if (splitRowId.length < 3) return;

        const thirdPart = splitRowId[2];
        return <div>{thirdPart}</div>;
      }
    },
    {
      field: 'email',
      title: 'Email',
      width: 200,
      render: (row: IDirector) => {
        return (
          <div data-cy="user-email-address">
            <MUITEXT
              placeholder="Enter Email ..."
              value={row.email}
              onBlur={async () => {
                const isValid = validateEmail(row.email);
                if (isValid) {
                  props.setBlurredDirectors((prev) => ({
                    ...prev,
                    [row.id]: true
                  }));

                  await handleUpdateSubmissionData();
                }
              }}
              onChange={(e) => {
                handleChange({
                  id: row.id,
                  name: 'email',
                  row,
                  value: (e.target as HTMLInputElement).value
                });
              }}
              onKeyDown={async (e) => {
                if (e.key === 'Enter') {
                  const isValid = validateEmail(row.email);
                  if (isValid) {
                    props.setBlurredDirectors((prev) => ({
                      ...prev,
                      [row.id]: true
                    }));

                    await handleUpdateSubmissionData();
                  }
                }
              }}
            />
          </div>
        );
      }
    }
  ];

  return (
    <>
      <Grid container direction="column" spacing={2}>
        <br />

        <Grid item xs={12}>
          {is_directorship && (
            <>
              <Typography variant="h3">Step 2</Typography>
              <Typography variant="h6">Enter a valid Email Address</Typography>
              <Typography variant="caption">
                By default we use email addresses as unique ids, and so you will
                need to fill in a valid email address here for the user you are
                creating, it should be the users primary email address as it
                will be used by the platform to send all email communications
                to.
              </Typography>
              <br />
              <br />
            </>
          )}

          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              required
              title={
                is_directorship
                  ? props?.data?.attributes?.first_name +
                    ' ' +
                    props?.data?.attributes?.surname
                  : props?.company?.data?.attributes?.name
              }
            />
          </Grid>

          {(isSupplier || is_directorship) && !is_company && (
            <TextField
              disabled={is_directorship || isSupplier ? false : is_company}
              fullWidth
              handleChange={(value) => {
                handleUIChange({
                  value,
                  key: 'UserInstanceEmail'
                });
              }}
              required
              title={
                isSupplier || is_directorship
                  ? 'Email'
                  : is_company
                  ? 'Company No.'
                  : 'Email'
              }
              value={
                isSupplier || is_directorship
                  ? emailInstance
                  : is_company
                  ? props?.company?.data?.attributes?.company_number
                  : UserInstance.UserInstanceEmail
              }
            />
          )}

          {/* {props.data.type !== 'company' && (
            isSupplier ? 'Email' : is_company ? 'Company No.' : 'Email'
            isSupplier ? emailInstance : UserInstance.UserInstanceEmail
            isSupplier ? 'Email' : is_company ? 'Company No.' : 'Email'
            */}
        </Grid>
      </Grid>

      <br />

      {/* {is_directorship && (
        <Grid item>
          <>
            <Typography variant="h3">Step 3</Typography>
            <Typography variant="h6">Select a Process</Typography>
            <Typography variant="caption">
              If you wish to send out a GDPR consent request for example then
              you will choose the process here. In the table below if you have
              select a process here you can choose to start this process for one
              or more of the directors when they are created by checking the
              tickbox <b>Start Process</b>
            </Typography>
            <br />
            <br />
          </>
          <SelectAProcess
            UserDefinitionId={UserDefinition.Id}
            onChange={(row) => setProcessToStart(row)}
          />
        </Grid>
      )} */}

      {is_company && (
        <Grid item style={{ overflow: 'scroll', minHeight: 500, padding: 16 }}>
          <Table
            title="Company Directors"
            columns={columns}
            data={data}
            editable={{
              onRowAdd: async (data) => await onRowAdd(data),
              onRowDelete: async (data) => onRowDelete(data)
            }}
            components={{
              Toolbar: (props) => (
                <>
                  <MTableToolbar {...props} />
                  <Grid container style={{ padding: 16 }} spacing={2}>
                    <Grid item xs={12}>
                      <Alert variant="outlined" severity="info">
                        <Typography>
                          This company has <b>{noOfResignedDirectors} </b>
                          resigned directors who will not be imported.
                        </Typography>
                      </Alert>
                    </Grid>
                  </Grid>
                </>
              )
            }}
          />
        </Grid>
      )}
    </>
  );
};

export default RedFlagCreateImport;

const uniqueIdentifier = (data) => {
  if (data.type === 'company') return data.id;
  if (data.type === 'directorship') return '';
};

const title = (data) => {
  if (data.type === 'company') return data.attributes.name;
  if (data.type === 'directorship')
    return `${data.attributes.first_name} ${data.attributes.surname}`;
};

const initializeUserInstance = (
  data: Partial<IRedFlagObject>,
  UserDefinition: UserDefinition
) => ({
  ConsoleUserInstanceId: 0,
  DefaultMessage: '',
  DocumentFooter: '',
  DocumentHeader: '',
  EmailFooter: '',
  FromAddress: '',
  FromName: '',
  Id: 0,
  IsPublished: true,
  ItemOrder: 0,
  LastModified: '',
  LogoURL: '',
  NickName: '',
  OwnerUserInstanceId: 0,
  ProfilePicture: '',
  ProxyUserInstanceId: 0,
  RelatedUserPermission: 0,
  RelationshipStatus: 0,
  SmtpHost: '',
  SmtpPassword: '',
  SmtpPort: 0,
  SmtpSSL: false,
  SmtpUsername: '',
  SummaryFields: undefined,
  SystemAccess: 0,
  TelephoneNumber: '',
  ThirdPartyId: '',
  Title: title(data),
  UserDefinitionId: UserDefinition.Id,
  UserInstanceEmail: uniqueIdentifier(data),
  UserInstancePassword: ''
});

export const SelectAProcess = ({
  UserDefinitionId,
  onChange
}: {
  UserDefinitionId: string;
  onChange: (row: { label: string; value: number }) => void;
}) => {
  const theme = useTheme();
  const token = useSelector<any>((s) => s.user.auth.token);
  const [availableProcesses, setAvailableProcesses] = React.useState<null | {
    [key: number]: string;
  }>(null);
  const [processToStart, setProcessToStart] = React.useState<null | {
    label: string;
    value: number;
  }>(null);

  const getAvailableProcesses = async () => {
    const processes = await getProcessesByUserDefinition({
      token: typeof token === 'string' ? token : '',
      UserDefinitionId
    });
    setAvailableProcesses(processes?.data);
  };

  React.useEffect(() => {
    getAvailableProcesses();
    setProcessToStart(null);
    onChange({ label: '', value: 0 });
  }, [UserDefinitionId]);

  const StyledSelectProps = {
    onChange: (row: { label: string; value: number }) => {
      setProcessToStart(row);
      onChange(row);
    },
    options: availableProcesses
      ? Object.values(availableProcesses).length > 0
        ? Object.keys(availableProcesses).map((key) => ({
            label: availableProcesses[key],
            value: key
          }))
        : []
      : [],
    label: 'Choose else',
    placeholder: 'Choose Process to start GDPR',
    value: processToStart,
    useMaterialUI: false
  };

  return <Select {...StyledSelectProps} />;
};
