import { useCallback, useEffect, useMemo, useState } from 'react';
import { IFieldsProps } from '../../interface';
import { BugTracker } from 'Utils/Bugtracker';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { useTypedSelector } from 'redux/reducers';
import { UserDefinition, UserInstance } from 'types/interfaces';
import { Typography, useTheme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { getUsersForProcess } from 'redux/actions/GraphQlActions';
import { IUserForProcess } from 'redux/actions/GraphQlActions/interface';

const ReadOnlySelectedUserInstance = (props: IFieldsProps) => {
  const theme = useTheme();
  const [selectedUser, setSelectedUser] = useState<
    { label: string; value: number }[]
  >([] as { label: string; value: number }[]);
  const isASelectedUser = Object.keys(selectedUser).length > 0;

  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const [userDefinitionTitle, setUserDefinitionTitle] = useState('');
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const identifyUserDefinitionTitle = () => {
    const { FieldDefinition } = props;
    if (FieldDefinition) {
      const { FieldOptions, FieldType } = FieldDefinition;
      const selectedUserDefinition: UserDefinition | undefined =
        UserDefinitionList.find((el) => el.Id === parseInt(FieldOptions));
      selectedUserDefinition &&
        setUserDefinitionTitle(selectedUserDefinition.Title);
    }
  };

  // if Drop-downUserInstance, Use the process instance's process instance id first
  // if (isUserTypeField) {
  //   ProcessInstanceId = props?.ProcessInstance
  //     ? props?.ProcessInstance?.Id
  //     : props?.FieldInstance?.ProcessInstanceId;
  // }

  const getSelectedUserByAPI = async () => {
    setLoading(true);
    const UserDefinitionId = props?.FieldDefinition?.FieldOptions;
    let ProcessDefinitionId = 0;
    if (props?.ProcessInstance?.ProcessDefinitionId) {
      ProcessDefinitionId = props?.ProcessInstance?.ProcessDefinitionId;
    }

    let ProcessInstanceId: number | undefined =
      Object.values(props?.FieldInstance || {}).length > 0
        ? props?.FieldInstance?.ProcessInstanceId
        : props?.ProcessInstance?.Id;

    type actionType =
      | 'USERSFORPROCESS'
      | 'ALLUSERSFORPROCESS'
      | 'ASSIGNEDUSERSFORPROCESS'
      | 'SELECTEDUSERFORPROCESS';

    let action: actionType = 'SELECTEDUSERFORPROCESS';

    if (ProcessInstanceId && UserDefinitionId) {
      const newUserDefinitionId = parseInt(UserDefinitionId.toString());
      const response = (await getUsersForProcess({
        ProcessInstanceId,
        ProcessDefinitionId,
        UserDefinitionId: newUserDefinitionId,
        action,
        baseUrl
      })) as IUserForProcess[] | undefined;

      if (response) {
        let options: { label: string; value: number }[] = [];
        response.forEach((UsersForProcess: IUserForProcess) =>
          options.push({
            label: UsersForProcess.Title,
            value: parseInt(UsersForProcess.Id.toString())
          })
        );

        setSelectedUser(options);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    identifyUserDefinitionTitle();
  }, []);

  useEffect(() => {
    getSelectedUserByAPI();
  }, []);

  if (loading) {
    return (
      <Typography
        variant="body1"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          minHeight: '35px',
          padding: theme.spacing(1),
          width: '100%',
          background: theme.palette.background.default,
          borderRadius: theme.shape.borderRadius
        }}>
        Loading {userDefinitionTitle}...
      </Typography>
    );
  }

  if (!isASelectedUser && !selectedUser?.[0]?.value) {
    return <>{`No Assigned ${userDefinitionTitle}`.toUpperCase()}</>;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minHeight: '35px',
        padding: theme.spacing(1),
        width: '100%',
        background: theme.palette.background.default,
        borderRadius: theme.shape.borderRadius
      }}>
      {selectedUser?.[0]?.value ? (
        <QuickUserFromId UserInstanceId={selectedUser?.[0]?.value.toString()} />
      ) : (
        <>{`No Assigned ${userDefinitionTitle}`.toUpperCase()}</>
      )}
    </div>
  );
};

export default ReadOnlySelectedUserInstance;
