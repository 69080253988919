import { useState } from 'react';

interface IProgressStatus {
  statusMessage: string;
  isCompleted: boolean;
  showProgress: boolean;
  setStatusMessage: (message: string) => void;
  setIsCompleted: (completed: boolean) => void;
  setShowProgress: (show: boolean) => void;
  updateProgress: (message: string, completed: boolean, show: boolean) => void;
  resetProgress: () => void;
}

/**
 * Custom hook to manage progress status, completion state, and visibility
 * @returns {ProgressStatus} An object containing the current state and functions to update it
 */
const useProgressStatus = (): IProgressStatus => {
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);

  /**
   * Updates all progress-related states at once
   * @param {string} message - The new status message
   * @param {boolean} completed - The new completion state
   * @param {boolean} show - Whether to show or hide the progress indicator
   */
  const updateProgress = (
    message: string,
    completed: boolean,
    show: boolean
  ) => {
    setStatusMessage(message);
    setIsCompleted(completed);
    setShowProgress(show);
  };

  const resetProgress = () => {
    setStatusMessage('');
    setIsCompleted(false);
    setShowProgress(false);
  };

  return {
    statusMessage,
    isCompleted,
    showProgress,
    setStatusMessage,
    setIsCompleted,
    setShowProgress,
    updateProgress,
    resetProgress
  };
};

export default useProgressStatus;
