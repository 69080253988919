import { useEffect, useRef, useState } from 'react';

const useIntersectionObserver = (loadMoreFunction) => {
  const loaderRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(true);

  const handleObserver = async (entities) => {
    const target = entities[0];
    if (target.isIntersecting && !loading) {
      await loadMoreFunction(page, setPage, setLoading, setHasMore);
    }
  };

  useEffect(() => {
    if (!hasMore) return;

    const option = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loaderRef.current) observer.observe(loaderRef.current);

    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [loaderRef, loading]);

  return { loaderRef, loading, hasMore };
};

export default useIntersectionObserver;
