import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Select,
  MenuItem
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import { IObjectIds, ISafeguardChipProps } from '../interface';
import { theme } from 'theme';
import { isObjectDefinitionIds } from '../Tree';

const SafeguardChip = ({
  safeguard,
  onRemoveId,
  onEditId,
  ruleId
}: ISafeguardChipProps) => {
  const [open, setOpen] = useState(false);

  const [editOpen, setEditOpen] = useState(false);
  const [editId, setEditId] = useState<IObjectIds>(() => {
    if (isObjectDefinitionIds(safeguard.id)) {
      return {
        objectDefinitionIds: {
          objectDefinitionIdReg:
            safeguard.id.objectDefinitionIds.objectDefinitionIdReg,
          objectDefinitionIdNonReg:
            safeguard.id.objectDefinitionIds.objectDefinitionIdNonReg
        },
        fieldInstanceId: safeguard.id.fieldInstanceId || 0,
        gdprStepId: safeguard.id.gdprStepId || 0
      };
    } else {
      return {
        objectDefinitionId: safeguard.id.objectDefinitionId,
        fieldInstanceId: safeguard.id.fieldInstanceId || 0,
        gdprStepId: safeguard.id.gdprStepId || 0
      };
    }
  });

  const [editAction, setEditAction] = useState<
    'Sent' | 'Starred' | 'Accepted' | 'Completed'
  >(safeguard.action);

  const handleSave = () => {
    onEditId(ruleId, safeguard.uniqueId, editId, editAction, safeguard.status);
    handleEditClose();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);

  const objectDefinitionIdLabel = isObjectDefinitionIds(safeguard.id)
    ? `Object Definition ID (Reg: ${safeguard.id.objectDefinitionIds.objectDefinitionIdReg}, Non-Reg: ${safeguard.id.objectDefinitionIds.objectDefinitionIdNonReg})`
    : `Object Definition ID: ${safeguard.id.objectDefinitionId}`;

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        {objectDefinitionIdLabel}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Safeguard Details</DialogTitle>
        <DialogContent>
          <Typography>ID: {objectDefinitionIdLabel}</Typography>
          {safeguard.id.fieldInstanceId && (
            <>
              {typeof safeguard.id.fieldInstanceId === 'number' ? (
                <Typography>
                  Field Instance ID: {safeguard.id.fieldInstanceId}
                </Typography>
              ) : (
                <>
                  <Typography>
                    Hide Show ID: {safeguard.id.fieldInstanceId.hideShowId}
                  </Typography>
                  <Typography>
                    Field Instance ID:{' '}
                    {safeguard.id.fieldInstanceId.fieldInstanceId}
                  </Typography>
                </>
              )}
            </>
          )}

          {safeguard.id.gdprStepId && (
            <Typography>GDPR Step ID: {safeguard.id.gdprStepId}</Typography>
          )}

          <Typography>Action: {safeguard.action}</Typography>
          <br />
          <Typography>
            Is Regulated: {safeguard.status.isRegulated ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            Is GDPR Required: {safeguard.status.isGDPR_Required ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            Is Field Instance:{' '}
            {safeguard.status.isField_Instance ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            Is Regulated Field:{' '}
            {safeguard.status.isRegulated_Field ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            Is GDPR Only Required:{' '}
            {safeguard.status.isGDPR_Only_Required ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            Is Regulated Only Required:{' '}
            {safeguard.status.isRegulated_Only_Required ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            Is Non-Regulated Only Required:{' '}
            {safeguard.status.isNon_Regulated_Only_Required ? 'Yes' : 'No'}
          </Typography>
          <Typography>
            Is Non-Regulated:{' '}
            {safeguard.status.isNon_Regulated_Field ? 'Yes' : 'No'}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              onRemoveId(ruleId, safeguard.uniqueId);
              handleClose();
            }}
            color="secondary"
            startIcon={<Cancel />}>
            Remove
          </Button>
          <Button onClick={handleEditOpen} color="default">
            Edit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editOpen} onClose={handleEditClose}>
        <DialogTitle>Edit Safeguard</DialogTitle>
        <DialogContent>
          {isObjectDefinitionIds(editId) ? (
            <>
              <TextField
                style={{ marginBottom: theme.spacing(2) }}
                variant="outlined"
                label="Object Definition ID (Reg)"
                value={editId.objectDefinitionIds.objectDefinitionIdReg}
                onChange={(e) =>
                  setEditId({
                    ...editId,
                    objectDefinitionIds: {
                      ...editId.objectDefinitionIds,
                      objectDefinitionIdReg: parseInt(e.target.value)
                    }
                  })
                }
                fullWidth
              />
              <TextField
                style={{ marginBottom: theme.spacing(2) }}
                variant="outlined"
                label="Object Definition ID (Non-Reg)"
                value={editId.objectDefinitionIds.objectDefinitionIdNonReg}
                onChange={(e) =>
                  setEditId({
                    ...editId,
                    objectDefinitionIds: {
                      ...editId.objectDefinitionIds,
                      objectDefinitionIdNonReg: parseInt(e.target.value)
                    }
                  })
                }
                fullWidth
              />
            </>
          ) : (
            <TextField
              style={{ marginBottom: theme.spacing(2) }}
              variant="outlined"
              label="Object Definition ID"
              value={editId.objectDefinitionId}
              onChange={(e) =>
                setEditId({
                  ...editId,
                  objectDefinitionId: parseInt(e.target.value)
                })
              }
              fullWidth
            />
          )}
          {editId.fieldInstanceId !== undefined && (
            <TextField
              style={{ marginBottom: theme.spacing(2) }}
              variant="outlined"
              label="Field Instance ID"
              value={editId.fieldInstanceId}
              onChange={(e) =>
                setEditId({
                  ...editId,
                  fieldInstanceId: parseInt(e.target.value)
                })
              }
              fullWidth
            />
          )}
          <Select
            variant="outlined"
            value={editAction}
            onChange={(e) =>
              setEditAction(
                e.target.value as 'Sent' | 'Starred' | 'Accepted' | 'Completed'
              )
            }
            fullWidth>
            <MenuItem value="Sent">Sent</MenuItem>
            <MenuItem value="Starred">Starred</MenuItem>
            <MenuItem value="Accepted">Accepted</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SafeguardChip;
