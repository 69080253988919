import { Avatar, Grid, Typography } from '@material-ui/core';
import { Message } from '@microsoft/microsoft-graph-types';
import { useSenderColors } from 'components/MessageHub/hooks/useSenderColors';
import Attachments from 'components/MessageHub/utils/Attachments/DropdownAttachments';

const Reply = ({
  reply,
  isExpanded
}: {
  reply: Message;
  isExpanded: boolean;
}) => {
  const getSenderColor = useSenderColors();

  const senderName = reply.sender?.emailAddress?.name || '';
  const senderSubject = reply.subject || '';
  const receivedDateTime = reply.receivedDateTime || '';
  const formattedReceivedDateTime = receivedDateTime
    ? new Date(receivedDateTime).toLocaleString()
    : '';

  const recipientsString = reply.toRecipients
    ?.map((recipient) => `${recipient.emailAddress?.name}`)
    .join(', ');

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <Avatar
          style={{
            backgroundColor: getSenderColor(senderName)
          }}>
          {senderName[0]}
        </Avatar>
      </Grid>
      <Grid item>
        <Typography variant="h6">{senderName}</Typography>
        <Typography>
          {isExpanded ? `To: ${recipientsString}` : senderSubject}
        </Typography>
      </Grid>
      <Grid item xs>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end'
          }}>
          <Typography>{formattedReceivedDateTime}</Typography>
          <Attachments attachments={reply.attachments} />
        </div>
      </Grid>
    </Grid>
  );
};

export default Reply;
