import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import InlineTextField from 'components/Fields/InlineTextField';
import { FieldInstance } from 'types/interfaces';
import { FieldDefinitionTitle } from '../../../../Stepper/components/Steps/ActiveStep/components/FieldDefinitionTitle';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { CompleteObjectInstance, FieldDefinition } from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { getCachedCompleteObjectInstance } from 'redux/actions/GraphQlActions/cachedRequests';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme) => ({
  container: { width: '100%' },
  paper: { background: theme.palette.background.paper },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2)
  }
}));

const SingleObject = (props) => {
  const { FieldDefinitionList, ObjectDefinition, rowData } = props;
  const UserInstance = rowData.UserInstance;
  const classes = useStyles();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const cachedUserInstanceDetail = getCachedCompleteObjectInstance(
    UserInstance.Id,
    baseUrl
  );

  let CompleteObjectInstance: CompleteObjectInstance | undefined;
  if (cachedUserInstanceDetail) {
    CompleteObjectInstance =
      cachedUserInstanceDetail.CompleteObjectInstanceList.find(
        (instance: CompleteObjectInstance) =>
          instance.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
      );
  } else {
    CompleteObjectInstance = rowData.CompleteObjectInstanceList.find(
      (instance: CompleteObjectInstance) =>
        instance.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
    );
  }

  return (
    <Paper className={classes.paper}>
      <List component="nav" dense>
        {FieldDefinitionList.map(
          (FieldDefinition: FieldDefinition, idx: number) => {
            let readOnly;
            if (FieldDefinition.Id !== 22669) {
              readOnly = ReadOnlyRedflag({
                ObjectDefinition,
                FieldDefinition
              });
            } else readOnly = false;

            const { Title, Id, FieldType } = FieldDefinition;
            const FieldInstanceList = getFieldInstances(
              CompleteObjectInstance || ({} as CompleteObjectInstance)
            );

            let FieldInstance: FieldInstance | undefined;
            if (FieldInstanceList && FieldInstanceList.length > 0) {
              FieldInstance = FieldInstanceList.find(
                (FieldInstance: FieldInstance) =>
                  FieldInstance.FieldDefinitionId === Id
              );
            }

            let value =
              FieldInstance && FieldInstance.FieldValue
                ? FieldInstance.FieldValue
                : '';

            return (
              <React.Fragment key={idx}>
                {idx !== 0 && <Divider style={{ margin: 10 }} />}

                <Grid container>
                  <Grid item xs={2}>
                    <ListItem>
                      <Typography color="textSecondary" variant="caption">
                        <FieldDefinitionTitle title={Title} height="h6" />
                      </Typography>
                    </ListItem>
                  </Grid>
                  <Grid item xs={10} className={classes.item}>
                    <InlineTextField
                      readOnly={readOnly}
                      type={FieldType}
                      FieldDefinition={FieldDefinition}
                      FieldInstance={FieldInstance}
                      ObjectDefinition={ObjectDefinition}
                      ObjectInstance={
                        CompleteObjectInstance &&
                        CompleteObjectInstance.ObjectInstance
                      }
                      UserInstance={UserInstance}
                      fields={FieldDefinitionList}
                      value={value}
                      ProcessInstance={{ Id: 0 }}
                      ProcessStepSelectionType={''}
                      parentsIsFormComponent={false}
                      isDetails
                      refresh={
                        CompleteObjectInstance &&
                        CompleteObjectInstance?.ObjectInstance
                          ?.ObjectDefinitionId === 3464
                          ? props.getData
                          : () => {}
                      }
                    />
                  </Grid>
                </Grid>
              </React.Fragment>
            );
          }
        )}
      </List>
    </Paper>
  );
};

export default SingleObject;
