import { getFieldValueWithFieldDefinitionTitle } from 'components/Stepper/functions';
import { objectProgress } from 'components/Stepper/functions/objectprogress';
import { stepProgress } from 'components/Stepper/functions/stepprogress';
import { useProcess } from 'hooks/useProcess';
import React, { useState, useEffect, useCallback } from 'react';
import {
  CompleteObjectDefinition,
  CompleteObjectDefinitionDict,
  CompleteProcessDefinition,
  CompleteProcessInstance,
  CompleteProcessStepDefinition,
  FieldDefinition,
  FieldInstance,
  IPlatformSettings
} from 'types/interfaces';

export interface IReqCmp {
  _Percent_Required_Fields_Complete: number;
  _Percent_All_Fields_Complete: number;
  _requiredfieldscomp: number;
  _reqfieldsnum: FieldInstance[];
  _allfieldsnum: number;
  _allfieldscomp: number;
}

interface IRequiredCompletionProps {
  currentDeal: CompleteProcessInstance;
  currentProcess: CompleteProcessDefinition;
  parsed: any;
  entityType: string;
  regulated: {
    hasEntity: boolean;
    isRegulatedParty?: boolean;
  };
  currentStepId: number;
  config: IPlatformSettings;
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
}

export interface IRequiredFields {
  required: FieldInstance;
  title: string;
  parent: string;
}

const useRequiredCompletion = ({
  currentDeal,
  currentProcess,
  parsed,
  regulated,
  entityType,
  currentStepId,
  config,
  CompleteObjectDefinitionDict
}: IRequiredCompletionProps) => {
  const [ReqFields, setReqFields] = useState<IRequiredFields[]>([]);
  const { user } = useProcess();
  const subSystemUser = user.SystemAccess <= 4;
  const [ReqCmp, setReqCmp] = useState<IReqCmp>({
    _Percent_Required_Fields_Complete: 0,
    _Percent_All_Fields_Complete: 0,
    _requiredfieldscomp: 0,
    _reqfieldsnum: [] as FieldInstance[],
    _allfieldsnum: 0,
    _allfieldscomp: 0
  });

  // The useCallback here prevents frequent re-creation of handleUpdateReqCmp, avoiding excessive re-renders and improving performance.
  // Without this we create multiple re-renders (useMemo doesn't help), I tried using a few workarounds but I think here a useCallback is perfect.
  const handleUpdateReqCmp = useCallback(() => {
    const hiddenFieldsForSubSystemUser = subSystemUser
      ? ReqFields.filter(
          (field) => field.parent === 'Lender & Proposal Information'
        ).map((field) => field.required.FieldDefinitionId)
      : [];
    const progress = stepProgress({
      currentDeal,
      currentProcess,
      parsed,
      regulated,
      entityType,
      currentStepId,
      hiddenFields: hiddenFieldsForSubSystemUser
    });
    setReqCmp(progress);

    let fields = [] as IRequiredFields[];

    // Safely handle stepId determination
    const landingpageStepId = parsed?.psdid;
    let stepId: string;

    try {
      // Handle the case where both might be undefined
      if (landingpageStepId) {
        stepId = landingpageStepId;
      } else if (currentStepId != null) {
        stepId = currentStepId.toString();
      } else {
        console.warn('No valid step ID found, using default step');
        stepId = '0';
      }

      // Safely get the current step
      const currentStep =
        currentProcess?.CompleteProcessStepDefinitionDict?.[stepId];
      if (!currentStep) {
        console.warn(`No step definition found for stepId: ${stepId}`);
        return;
      }

      // Safely get the deal type
      const DealType: string = getFieldValueWithFieldDefinitionTitle({
        stepdefdict: currentStep,
        currentDeal,
        FieldDefinitionTitle: 'Deal Type'
      });

      CompleteObjectDefinitionDict &&
        Object.values(CompleteObjectDefinitionDict).map(
          (CompleteObjectDefinition: any) => {
            const { RequiredFieldInstances, _requiredFieldsTODO } =
              objectProgress({
                CompleteObjectDefinition,
                currentDeal
              });

            if (_requiredFieldsTODO > 0) {
              RequiredFieldInstances.forEach((element: FieldInstance) => {
                const completeObjectDefinition =
                  CompleteObjectDefinitionDict[element.ObjectDefinitionId];

                if (completeObjectDefinition) {
                  const isFFF_OBJECT =
                    completeObjectDefinition.ObjectDefinition.ObjectDescription.includes(
                      '#Fact Find Form'
                    );

                  if (isFFF_OBJECT && DealType !== '') {
                    const { ObjectDescription } =
                      completeObjectDefinition.ObjectDefinition;
                    const split = ObjectDescription.split(',');
                    const deal_name_unsplit: string[] = split.filter((e) =>
                      e.includes('&Deal=')
                    );
                    if (deal_name_unsplit.length > 0) {
                      const matchesCurrentDeal: string | undefined =
                        deal_name_unsplit.find((el: string) =>
                          el.toUpperCase().includes(DealType.toUpperCase())
                        );

                      if (!matchesCurrentDeal) {
                        return;
                      }
                    }
                  }

                  const objectDefinition =
                    completeObjectDefinition.ObjectDefinition;
                  const fieldDefinitionDict =
                    completeObjectDefinition.FieldDefinitionDict;

                  const fieldDefinition =
                    fieldDefinitionDict[element.FieldDefinitionId];
                  const requiredField: IRequiredFields = {
                    parent: objectDefinition.Title,
                    title: fieldDefinition.Title,
                    required: element
                  };

                  if (regulated.isRegulatedParty) {
                    fields.push(requiredField);
                    return;
                  }

                  const hasRegulatedParty =
                    objectDefinition.ObjectDescription.includes('regulated');
                  if (
                    element.ObjectDefinitionId !== objectDefinition.Id ||
                    !hasRegulatedParty
                  ) {
                    fields.push(requiredField);
                  }
                }
              });
            }
          }
        );

      setReqFields(fields);
    } catch (error) {
      console.error('Error in handleUpdateReqCmp:', error);
      setReqFields([]);
    }
  }, [
    currentDeal,
    currentProcess,
    parsed,
    regulated,
    currentStepId,
    subSystemUser
  ]);

  useEffect(() => {
    handleUpdateReqCmp();
  }, [handleUpdateReqCmp, config]);

  return { ReqCmp, ReqFields, handleUpdateReqCmp };
};

export default useRequiredCompletion;
