import React, { useEffect } from 'react';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

import Switch from '@material-ui/core/Switch';
import Select from 'react-select';
import MaterialUISelect from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

import { useTypedSelector } from 'redux/reducers';
import { useTheme } from '@material-ui/core/styles';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { UserDefinition, UserInstance } from 'types/interfaces';

interface IOptions {
  value: number;
  label: string;
}

interface IRelationship {
  UserDefinition: UserDefinition | undefined | {};
  UserInstance: IOptions | {};
}
import { MenuItem, Typography } from '@material-ui/core';

const RelationshipSelect = (props) => {
  const theme = useTheme();
  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const { token } = useTypedSelector((s) => s.user.auth);

  // Relationship
  const [relationOptions, setRelationOptions] = React.useState<
    UserDefinition[]
  >([]);
  const [relationship, setRelationship] = React.useState<IRelationship>({
    UserDefinition: {},
    UserInstance: {}
  });
  const [relationshipUserInstanceList, setRelationshipUserInstanceList] =
    React.useState<IOptions[]>([]);

  const [isToBeRelated, setIsToBeRelated] = React.useState(false);

  const getUserInstanceSummaryListMethod = async (value) => {
    const UserDefinitionId: number = value || props.UserDefinition.Id;
    const res = await getUserInstanceSummaryList({
      token,
      UserDefinitionId
    });
    if (res && res.data) {
      let optionsIns: IOptions[] = [];
      Object.values(res.data).forEach(
        ({ UserInstance: { Id, Title } }: any) => {
          optionsIns.push({
            value: Id,
            label: Title
          });
        }
      );

      setRelationshipUserInstanceList(optionsIns);
    }
  };

  const handleUpdateSubmissionData = (relationship: IRelationship) => {
    props.updateSubmissionData({
      ...props,
      isToBeRelated,
      relationship: relationship
    });
  };

  const storingRelationship = () => {
    if (!_.isEmpty(relationship.UserDefinition && relationship.UserInstance)) {
      handleUpdateSubmissionData(relationship);

      setRelationship({
        ...relationship,
        UserInstance: {}
      });
    }
  };

  useEffect(() => {
    if (props.TimelineRelationship) {
      const customUserInstance: IOptions = {
        label: props.TimelineRelationship.UserInstance.Title,
        value: props.TimelineRelationship.UserInstance.Id
      };

      const timelineGuest: IRelationship = {
        UserDefinition: props.TimelineRelationship.UserDefinition,
        UserInstance: customUserInstance
      };

      if (timelineGuest) handleUpdateSubmissionData(timelineGuest);
    }
  }, [props.TimelineRelationship]);

  useEffect(() => {
    // Get options list
    let options: UserDefinition[] = [];
    UserDefinitionList &&
      UserDefinitionList.forEach((item: any) => {
        item.value = item.Id;
        item.label = item.Title;
        options.push(item);
      });

    setRelationOptions(options);
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius
    }),
    menu: (provided, state) => ({
      ...provided,
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      zIndex: 10
    }),
    menuList: (provided, state) => ({
      ...provided,
      color: theme.palette.text.primary,
      zIndex: 10
    }),
    input: (provided, state) => ({
      ...provided,
      color: theme.palette.text.primary,
      outline: theme.palette.primary.main
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isFocused && theme.palette.primary.light,
      color: state.isFocused && theme.palette.primary.contrastText,
      borderRadius: theme.shape.borderRadius
    })
  };

  const type = () => {
    if (props && props.newUser && props.newUser.type === 'Meeting') return true;
    else if (props && props.type === 'Meeting') return true;
    else return false;
  };

  return (
    <div>
      {type() ? (
        <Grid
          container
          direction="column"
          spacing={1}
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            padding: theme.spacing(1),
            borderRadius: theme.shape.borderRadius,
            paddingTop: 10,
            minWidth: theme.spacing(27)
          }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={isToBeRelated}
                    color="primary"
                    name="isToBeRelated"
                    onChange={() => {
                      setIsToBeRelated(!isToBeRelated);
                    }}
                  />
                }
                label="Create Guests"
              />
            </Grid>
          </Grid>
          {isToBeRelated && (
            <React.Fragment>
              <Typography
                align="center"
                style={{
                  fontWeight: 'bold',
                  fontSize: '18px',
                  paddingTop: 10
                }}>
                Guest -{' '}
                {props?.updateRelationship
                  ? props.updateRelationship.length +
                    props.relationship.length +
                    1
                  : props.relationship.length + 1}
              </Typography>
              <Grid item>
                <FormControl fullWidth variant="outlined" margin="dense">
                  <InputLabel>Choose Data Group</InputLabel>
                  <MaterialUISelect
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    labelWidth={'text'.length * 30}
                    onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                      const selectedValue = e.target.value as string;
                      const decodedJSON = JSON.parse(selectedValue);
                      getUserInstanceSummaryListMethod(decodedJSON.value);
                      const UserDefinition = relationOptions.find(
                        (el) => el.Id === decodedJSON.Id
                      );
                      setRelationship({
                        ...relationship,
                        UserDefinition
                      });
                    }}>
                    {Object.values(relationOptions).map((item, idx) => {
                      return (
                        <MenuItem key={idx} value={JSON.stringify(item)}>
                          {item.Title}
                        </MenuItem>
                      );
                    })}
                  </MaterialUISelect>
                </FormControl>
              </Grid>

              {relationshipUserInstanceList.length >= 1 && (
                <Grid item>
                  <FormControl fullWidth variant="outlined" margin="dense">
                    <InputLabel>Choose Date Record</InputLabel>
                    <MaterialUISelect
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      labelWidth={'text'.length * 32}
                      onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                        const selectedValue = e.target.value as string;
                        const decodedJSON = JSON.parse(selectedValue);
                        setRelationship({
                          ...relationship,
                          UserInstance: decodedJSON
                        });
                      }}>
                      {Object.values(relationshipUserInstanceList).map(
                        (item, idx) => {
                          return (
                            <MenuItem key={idx} value={JSON.stringify(item)}>
                              {item.label}
                            </MenuItem>
                          );
                        }
                      )}
                    </MaterialUISelect>
                  </FormControl>
                </Grid>
              )}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Grid
                  item
                  style={{ paddingTop: 10, paddingBottom: 10, minWidth: 50 }}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      storingRelationship();
                    }}>
                    Add Guest
                  </Button>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      ) : (
        <div />
      )}
    </div>
  );
};

export default RelationshipSelect;
