import { StyleSheet } from '@react-pdf/renderer';

export default StyleSheet.create({
  table: {
    fontSize: 10,
    width: 535,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'stretch',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 20
  },
  cell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingBottom: 3,
    width: 95
  },

  cellType: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingBottom: 3,
    textAlign: 'right',
    width: 85
  },

  cellAmount: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'center',
    paddingBottom: 3,
    width: 95
  },

  childCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingBottom: 3,
    width: 115
  },
  cellLeft: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingLeft: 5,
    paddingBottom: 3,
    width: 250
  },

  cellRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingRight: 5,
    paddingBottom: 3,
    width: 95
  },

  aloneCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingRight: 5,
    paddingBottom: 3,
    width: 110
  },

  dateCell: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingRight: 5,
    paddingBottom: 3,
    width: 140
  },

  dateCellTitle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingRight: 5,
    paddingBottom: 3,
    width: 85
  },

  cellMiddleAlone: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingLeft: 5,
    paddingBottom: 3,
    width: 78
  },

  InstalType: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingBottom: 3,
    textAlign: 'right',
    width: 65
  },

  InstalAmount: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'flex-end',
    paddingBottom: 3,
    width: 95
  },

  styleRight_1: {
    marginRight: 20
  },
  styleRight_2: {
    marginRight: 32
  },
  styleRight_3: {
    marginRight: 50
  },
  styleRight_4: {
    marginRight: 52
  },

  greyBackground: {
    backgroundColor: '#eee'
  },
  tableText: {
    margin: 10,
    fontSize: 10
  }
});
