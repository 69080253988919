import { Page, StyleSheet, Document, View, Text } from '@react-pdf/renderer';
import { ViewAgendaOutline } from 'mdi-material-ui';
import { ObjectDefinition } from 'types/interfaces';
import { decodeFormattedText } from './functions';
import { IProps } from './interface';
import { styles } from './styles';
import Time from 'common/Time';

const RepeatDynamicObject_pdf = ({
  ObjectDefinition,
  objectData,
  currentDeal
}: IProps) => {
  if (ObjectDefinition && objectData && objectData.length > 0) {
    return (
      <Document>
        <Page size="A4">
          {objectData.map((item: any, index) => {
            return (
              <View key={index} style={styles.section}>
                <Text style={styles.title}>
                  {ObjectDefinition?.Title}: {index + 1}
                </Text>
                <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
                <Text style={styles.subTitle}>
                  Deal ID: {currentDeal.ProcessInstance.Id}
                </Text>
                <Text style={styles.subTitle}>
                  Date: <Time time={new Date()} type="date" />
                </Text>
                <Text style={{ paddingBottom: 6 }} />

                {item.map((obj, idx) => {
                  for (const property in obj) {
                    console.log({ property, obj });
                    return (
                      <View
                        key={idx}
                        style={[
                          styles.item,
                          idx % 2 === 0
                            ? { backgroundColor: 'rgb(239, 239, 240)' }
                            : { backgroundColor: 'rgb(255,255,255)' }
                        ]}>
                        <Text style={{ fontWeight: 'bold' }}>{property}: </Text>
                        <Text style={{ marginRight: 10 }}>
                          {decodeFormattedText(obj[property])}
                        </Text>
                      </View>
                    );
                  }
                })}
              </View>
            );
          })}
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4">
        <View>
          <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
        </View>

        <View>
          <Text style={styles.item}>No data to show</Text>
        </View>
      </Page>
    </Document>
  );
}; //END RepeatDynamicObject_pdf

export default RepeatDynamicObject_pdf;
