import React from 'react';
import { Grid, TextField } from '@material-ui/core';

interface Props {
  calculationNameQuery: string;
  setCalculationNameQuery: React.Dispatch<React.SetStateAction<string>>;
}

function SearchByName({
  calculationNameQuery,
  setCalculationNameQuery
}: Props) {
  return (
    <>
      <Grid item>
        <TextField
          onChange={(e) => setCalculationNameQuery(e.target.value)}
          value={calculationNameQuery}
          fullWidth
          variant="outlined"
          margin="dense"
          placeholder="Search Calculations By Name"
          label="Calculation Name"
          onKeyDown={(e) =>
            e.key === 'Enter' && setCalculationNameQuery(calculationNameQuery)
          }
        />
      </Grid>
    </>
  );
}

export default SearchByName;
