/** component for user to change profile (or company logo) avatar image
 * You can click on the component to upload your avatar or drag your image to the component
 */

import { useRef, useEffect, useState, ChangeEvent } from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';
import CloudDoneIcon from '@material-ui/icons/CloudDone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  dropbox: {
    position: 'relative',
    width: '100%',
    height: (props: { dragging: boolean }) => (props.dragging ? 200 : 150),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    fontSize: 24,
    color: '#555555',
    border: '2px #c3c3c3 dashed',
    borderRadius: 12,
    transition: 'all 0.3s ease-in-out'
  },
  placeholder: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%',
    zIndex: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexFlow: 'column nowrap',
    backgroundColor: '#e7e7e7',
    borderRadius: 12,
    color: '#7f8e99',
    fontSize: 14,
    opacity: 1,
    textAlign: 'center',
    lineHeight: 1.4
  },
  error: { backgroundColor: '#f7e7e7', color: '#cf8e99' },
  success: { backgroundColor: '#e7f7e7', color: '#8ecf99' },
  formats: {
    display: 'flex',
    justifyContent: 'center',
    color: 'lightgrey',
    textAlign: 'center'
  },
  // hide the input file element from user because the label element is what they need to see
  inputFile: {
    opacity: 0,
    height: '1%',
    weight: '1%',
    margin: 0,
    padding: 0
  }
}));

const INIT_MESSAGE = {
  show: false,
  text: null,
  type: null
};

export interface IFile {
  lastModified: number;
  lastModifiedDate: Date;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
}

interface IProps {
  onUpload: (files: File[]) => void;
  count: number;
  formats: string[];
  title: string;
  keyId: string; // ensure it passes a unique value since this will be attached to id attribute
  maxBytes?: number;
}

const DropTarget = ({
  onUpload,
  count,
  formats,
  title,
  keyId,
  maxBytes = 2000000
}: IProps) => {
  const drop = useRef<HTMLLabelElement>(null);
  const drag = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [message, setMessage] = useState(INIT_MESSAGE);
  const classes = useStyles({ dragging });

  useEffect(() => {
    if (drop.current) {
      drop.current.addEventListener('dragover', handleDragOver);
      drop.current.addEventListener('drop', handleDrop);
      drop.current.addEventListener('dragenter', handleDragEnter);
      drop.current.addEventListener('dragleave', handleDragLeave);
    }

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('drop', handleDrop);
      window.removeEventListener('dragenter', handleDragEnter);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, []);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const updateMessage = (newMessage) => {
    if (isMounted.current) {
      setMessage(newMessage);
    }
  };

  const showMessage = (text, type, timeout) => {
    updateMessage({ show: true, text, type });

    setTimeout(() => {
      updateMessage(INIT_MESSAGE);
    }, timeout);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== drag.current) setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === drag.current) setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // upload file function
  const uploadFile = (files: File[]) => {
    if (count && count < files.length) {
      showMessage(
        `Only ${count} file${count !== 1 ? 's' : ''} can be uploaded at a time`,
        'error',
        4000
      );
      return;
    }

    // check if some uploaded file is not in one of the allowed formats
    if (
      formats &&
      files.some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      return showMessage(
        `Only following file formats are acceptable: ${formats.join(', ')}`,
        'error',
        4000
      );
    }

    if (files && files.length) {
      showMessage('Cool, I like these kinds of files', 'success', 2000);
      // if file is greater than 5mb
      if (files[0] && files[0].size > maxBytes) {
        return showMessage(
          `File size is too big! ${maxBytes / 1000000}mb maximum`,
          'error',
          4000
        );
      } else {
        return onUpload(files);
      }
      // if file is less than 5kb
      // if (files[0] && files[0].size < 4000) {
      //   showMessage('File size is too small! 4kb minimum', 'error', 4000);
      //   return;
      // }

      // upload file & ready for fetch request update
    }
  }; // END upLoadFile

  // When user drop (drag & drop) image on the element to change avatar
  function handleDrop(this: HTMLLabelElement | Window, ev: DragEvent) {
    ev.preventDefault();
    ev.stopPropagation();
    setDragging(false);
    if (ev.dataTransfer) {
      const files: File[] = [...ev.dataTransfer.files];
      uploadFile(files);
    }
  } //END handleDrop

  // user clicks label element & uploaded file (with the change event)
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const oldSkoolFileList = event.target.files as FileList;
    const files = [...oldSkoolFileList] as File[];

    uploadFile(files);
  }; //END handleFileChange

  return (
    <label htmlFor={keyId} className={classes.dropbox} ref={drop}>
      {message.show && message.type && (
        <div className={clsx(classes.placeholder, classes[message.type])}>
          {message.text}
        </div>
      )}
      {dragging && (
        <div ref={drag} className={classes.placeholder}>
          <CloudDoneIcon style={{ fontSize: 22, color: 'lightgrey' }} />
        </div>
      )}
      <CloudUploadIcon
        style={{ fontSize: 22, color: 'lightgrey' }}
        className={classes.formats}
      />

      <Typography variant="caption" align="center">
        {title.toUpperCase()}
      </Typography>

      <Grid container spacing={1} className={classes.formats}>
        {formats.map((f) => (
          <Grid item key={f} style={{ paddingTop: 0 }}>
            <Typography variant="caption">{f.toUpperCase()}</Typography>
          </Grid>
        ))}
      </Grid>

      <input
        type="file"
        id={keyId}
        onChange={handleFileChange}
        className={classes.inputFile}
        data-testid="avatar-upload"
      />
    </label>
  );
};

export default DropTarget;
