import React, { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import {
  Box,
  MenuItem,
  List,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  LinearProgress,
  makeStyles,
  Theme,
  createStyles,
  useTheme,
  Link,
  Button
} from '@material-ui/core';
import { getCrmToken } from 'redux/actions/GraphqlCrmActions';
import { useTypedSelector } from 'redux/reducers';
import { SEARCH_COMPANIES, GET_COMPANY_REPORT } from 'graphql/queries';
import { Companies, Company, CompaniesItem } from './interfaces';

import CompaniesHouseSearch from 'components/Fields/CompaniesHouseSearch';

import { StyledSelect } from 'common/StyledSelect';
import sic07_codes_Json from 'data/Sic07 Codes/sic07Codes.json';

import SearchIcon from '@material-ui/icons/Search';

// import { FixedSizeList } from 'react-window';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scorecard: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    divider: {
      height: 28,
      margin: 4
    },
    wholeCard: {
      '&:hover': {
        background: theme.palette.grey[300],
        borderRadius: 10
      }
    },
    lowerCard: {
      // background: theme.palette.grey[200],
      // borderBottomRightRadius: 10,
      // borderBottomLeftRadius: 10
    }
  })
);

const Search = ({
  SelectCompany
}: {
  SelectCompany: (company: Company) => void;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Company Name Search

  const graphqlCrmState = useTypedSelector((s) => s.graphqlCrm);
  const [state, setState] = useState({ loading: true });

  const [companyId, setCompanyId] = useState<null | string>(null);

  const [variables, setVariables] = useState({
    take: 5,
    company_name: '',
    company_number: '',
    sic07_codes: ['']
  });
  const context = {
    headers: { Authorization: `Bearer ${graphqlCrmState.access_token}` }
  };

  const queryCompanies = () => loadCompanies();
  const [
    loadCompanies,
    { data: companiesData, loading: companiesLoading, error: companiesError }
  ] = useLazyQuery<Companies>(SEARCH_COMPANIES, { variables, context });

  const [
    loadCompany,
    { data: companyData, loading: companyLoading, error: companyError }
  ] = useLazyQuery<Company>(GET_COMPANY_REPORT, {
    variables: {
      reportRequest: {
        archiveId: '',
        countryCode: 'GBR',
        fetchLatest: false,
        id: companyId,
        reasonCode: 'DEBT_COLLECTION'
      }
    },
    context
  });

  if (companiesError) {
    console.error(companiesError);
  }

  const loadGraphqlToken = () => {
    if (!graphqlCrmState.loaded || graphqlCrmState.willExpire <= Date.now()) {
      getCrmToken(dispatch);
    }
    setState((s) => ({ ...s, loading: false }));
  };

  const handleSelectCompany = (id: string) => {
    setCompanyId(id);
    loadCompany();
  };

  useEffect(() => {
    if (companyData) {
      SelectCompany(companyData);
    }
  }, [companyData]);

  useEffect(() => {
    loadGraphqlToken();
  }, []);

  useEffect(() => {
    if (graphqlCrmState.loaded) queryCompanies();
  }, [graphqlCrmState]);

  return (
    <Box style={{ margin: 10, padding: 10 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CompaniesHouseSearch />
        </Grid>

        <Grid item xs={6}>
          <TextField
            placeholder="Company Name"
            fullWidth
            value={variables.company_name}
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const { value } = e.target;
              setVariables((s) => ({ ...s, company_name: value }));
            }}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            placeholder="Company Number"
            fullWidth
            value={variables.company_number}
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const { value } = e.target;
              setVariables((s) => ({ ...s, company_number: value }));
            }}
          />
        </Grid>
        {/* <Grid item xs={6}>
          <Paper
            style={{
              padding: '2px 4px',
              display: 'flex',
              alignItems: 'center'
            }}>
            <Typography>SIC </Typography>
            <Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
            <TextField fullWidth placeholder="Search ..." />
            <Divider style={{ height: 28, margin: 4 }} orientation="vertical" />
            <SearchIcon />
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <TextField
            fullWidth
            value={variables.company_name}
            onChange={(
              e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
            ) => {
              const { value } = e.target;
              setVariables((s) => ({ ...s, company_name: value }));
            }}
          />
        </Grid> */}
      </Grid>
      <List>
        {(companiesLoading || companyLoading) && (
          <Grid item xs={12} style={{ padding: 10 }}>
            <LinearProgress color="primary" />
          </Grid>
        )}
        {companiesData?.companies?.items?.map((c: CompaniesItem) => {
          return (
            <MenuItem
              onClick={() => handleSelectCompany(c.company_id)}
              key={c.company_id}
              className={classes.wholeCard}
              style={{ listStylePosition: 'inside' }}>
              <Grid container direction="column" spacing={1}>
                <Grid item>
                  <Grid container spacing={2}>
                    <Grid item>
                      <Typography variant="h5">{c.company_name}</Typography>
                    </Grid>
                    <Grid item>{c.company_number}</Grid>
                    <Grid item>
                      <Typography variant="caption">{c.email}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid
                    container
                    spacing={2}
                    className={classes.lowerCard}
                    alignItems="center">
                    <Grid item className={classes.scorecard}>
                      <Typography style={{ marginRight: 4 }}>
                        {c.credit_score}
                      </Typography>
                      <Typography variant="caption">Credit score </Typography>
                    </Grid>

                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />

                    <Grid item className={classes.scorecard}>
                      <Typography>{c.vat_number}</Typography>
                      <Typography variant="caption">VAT Number </Typography>
                    </Grid>

                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />

                    <Grid item className={classes.scorecard}>
                      <Typography>{c.employees}</Typography>
                      <Typography variant="caption">
                        No of Employees{' '}
                      </Typography>
                    </Grid>

                    <>
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                      />

                      <Grid item className={classes.scorecard}>
                        <Typography>{c.addresses?.[0]?.postcode}</Typography>
                        <Typography variant="caption">Postcode</Typography>
                      </Grid>
                    </>

                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />

                    <Grid item className={classes.scorecard}>
                      <Typography>£ {c.estimated_turnover}</Typography>
                      <Typography variant="caption">Turnover </Typography>
                    </Grid>

                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />

                    <Grid item className={classes.scorecard}>
                      <Typography>{c.rfa_rating}</Typography>
                    </Grid>

                    <Divider
                      className={classes.divider}
                      orientation="vertical"
                    />

                    <Grid
                      item
                      className={classes.scorecard}
                      onClick={(e) => e.stopPropagation()}>
                      <Link
                        href={`https://${c.website}`}
                        rel="nofollow"
                        target="_blank">
                        {c.website}
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </MenuItem>
          );
        })}
      </List>
    </Box>
  );
};

export default Search;
