import React, { useContext } from 'react';
import {
  Grid,
  Button,
  IconButton,
  Divider,
  makeStyles,
  Theme,
  Tooltip
} from '@material-ui/core';
import {
  Inbox,
  Send,
  Drafts,
  Bookmark as BookmarkIcon,
  Refresh as RefreshIcon,
  Flag as FlagIcon,
  Archive as ArchiveIcon,
  ExitToApp as ExitToAppIcon,
  Add,
  Folder,
  Delete
} from '@material-ui/icons';
import { HiOutlineFolderDownload } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';

import { theme } from 'theme';
import { successNotif, errorNotif } from 'components/Notifications';
import { MessageHubContext } from '../../context/MessageHubContext';
import { ThreadContext } from '../Threads/context/ThreadsContext';
import { SelectionContext } from './context/SelectionContext';
import { EmailCompositionCreationContext } from '../ComposeWindows/context/ComposeWindowsContext';
import { CardContext } from 'components/MessageHub/context/HoverCardContext';
import { IConversation } from 'components/MessageHub/interfaces';
import { ICustomLoading } from '../Threads/context/interface';
import {
  IMicrosoftCustomFolder,
  MicrosoftGraphMailFolder
} from 'components/MessageHub/context/interface';
import Remove from '@material-ui/icons/Remove';
import SelectionButton from './components/SelectionButton';
import SelectionAction from './components/SelectionAction';
import { fetchFolders } from 'components/MessageHub/functions/folderFormatting';

type StyleProps = {
  loading: boolean;
};

export const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  iconButton: {
    color: theme.palette.text.secondary
  },
  buttonGroup: {
    paddingTop: theme.spacing(2)
  },
  listItemIcon: { marginRight: 0 },
  listItemText: {
    fontWeight: 500,
    color: theme.palette.text.secondary
  },
  listItem: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    paddingLeft: theme.spacing(2),
    margin: 0,
    cursor: ({ loading }) => (loading ? 'default' : 'pointer'),
    transition: 'all 0.3s ease-in-out',
    opacity: ({ loading }) => (loading ? 0.5 : 1),
    '&:hover': {
      backgroundColor: ({ loading }) =>
        loading ? 'inherit' : theme.palette.primary.light,
      borderLeft: ({ loading }) =>
        loading
          ? 'inherit'
          : `16px solid ${theme.palette.primary?.['maintrans']}`,
      borderRight: ({ loading }) =>
        loading
          ? 'inherit'
          : `16px solid ${theme.palette.primary?.['maintrans']}`,
      borderRadius: theme.shape.borderRadius,
      '& $listItemText': {
        color: ({ loading }) =>
          loading ? 'inherit' : theme.palette.primary.contrastText
      },
      '& $listItemIcon': {
        color: ({ loading }) =>
          loading ? 'inherit' : theme.palette.primary.contrastText,
        marginLeft: ({ loading }) => (loading ? '0' : '-4px'),
        [theme.breakpoints.down('sm')]: {
          margin: 0,
          paddingLeft: 0,
          marginLeft: 0,
          borderLeft: `4px solid ${theme.palette.primary?.['maintrans']}`,
          borderRight: `4px solid ${theme.palette.primary?.['maintrans']}`
        }
      }
    },
    '& + &': { marginTop: theme.spacing(1) },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0
    }
  }
}));

const ButtonConfig = [
  {
    category: 'Inbox',
    icon: <Inbox />,
    label: 'Inbox',
    showRefreshIcon: true
  },
  {
    category: 'Sent Items',
    icon: <Send />,
    label: 'Sent Items',
    showRefreshIcon: true
  },
  { category: 'Drafts', icon: <Drafts />, label: 'Drafts' },
  {
    category: 'Archived',
    icon: <ArchiveIcon />,
    label: 'Archived',
    showRefreshIcon: true
  },
  { category: 'Line_1' }
];

const Selection = ({
  setHasMessage,
  conversations
}: {
  setHasMessage: (hasMessage: boolean) => void;
  conversations: { [key: string]: IConversation } | string;
}) => {
  const dispatch = useDispatch();

  const { addComposeEmail } = useContext(EmailCompositionCreationContext);
  const { loadDraftsFromServer, fetchMessagesFromFolder, deleteFolder } =
    useContext(SelectionContext);
  const {
    setSelectedCategory,
    getRefreshEmails,
    messages,
    folderIds,
    sentItems,
    drafts,
    archived
  } = useContext(MessageHubContext);

  const {
    setOpenThreads,
    setSelectedThread,
    selectedThread,
    openThreads,
    loading,
    setLoading
  } = useContext(ThreadContext);
  const classes = useStyles({ loading: loading.isLoading });

  const defaultSelection = (category: string, folderId: string) => {
    setSelectedCategory({ category, folderId });
    setSelectedThread('');
    setOpenThreads({});
    setHasMessage(false);
    setLoading({
      isLoading: false,
      amount: 10
    });
  };

  const handleCategorySelection = async (category: string) => {
    switch (category) {
      case 'Create New Email':
        addComposeEmail();
        break;
      case 'Drafts': {
        if (!drafts || drafts.value.length <= 0) {
          setLoading({
            isLoading: true,
            amount: 10
          });
          await loadDraftsFromServer();
        }
        // Cannot Save Bookmarks Therefore Doesn't Need FolderId
        defaultSelection(category, '');
        break;
      }
      case 'Sent Items': {
        setLoading({
          isLoading: true,
          amount: 10
        });
        await fetchMessagesFromFolder(
          folderIds?.sentItemsFolder.id || '',
          'Sent Items'
        );

        defaultSelection(category, folderIds?.sentItemsFolder.id || '');
        break;
      }
      case 'Inbox': {
        setLoading({
          isLoading: true,
          amount: 10
        });
        await fetchMessagesFromFolder(
          folderIds?.inboxFolder.id || '',
          'messages'
        );

        // messages means Inbox
        defaultSelection(category, folderIds?.inboxFolder.id || '');
        break;
      }
      case 'Archived': {
        setLoading({
          isLoading: true,
          amount: 10
        });
        await fetchMessagesFromFolder(folderIds?.archive.id || '', 'archived');

        defaultSelection(category, folderIds?.archive.id || '');
        break;
      }
      default:
        // Unknown FolderId
        defaultSelection(category, '');
        break;
    }
  };

  const hasMessage = selectedThread && openThreads[selectedThread];
  return (
    <Grid container direction="column" justifyContent="flex-start">
      <Grid item style={{ margin: theme.spacing(2) }}>
        {ButtonConfig.map(({ category, icon, label, showRefreshIcon }) => {
          if (category === 'Line_1') {
            return (
              <>
                {!loading.isLoading ? (
                  <div
                    style={{
                      paddingTop: theme.spacing(0.5),
                      marginLeft: theme.spacing(0.5),
                      marginRight: theme.spacing(0.5)
                    }}>
                    <Divider
                      style={{
                        borderRadius: theme.spacing(0.2)
                      }}
                    />
                  </div>
                ) : (
                  <div />
                )}
              </>
            );
          }

          let folderId;
          let folderName;
          let totalAmount;
          let childFolders: MicrosoftGraphMailFolder[] = [];

          switch (category) {
            case 'Inbox': {
              childFolders = folderIds?.inboxFolder?.child || [];
              folderId = folderIds?.inboxFolder?.id;
              totalAmount = folderIds?.inboxFolder?.totalItemCount;
              folderName = category;

              break;
            }
            case 'Sent Items': {
              childFolders = folderIds?.sentItemsFolder?.child || [];
              folderId = folderIds?.sentItemsFolder?.id;
              totalAmount = folderIds?.sentItemsFolder?.totalItemCount;
              folderName = category;

              break;
            }
            case 'Archived': {
              childFolders = folderIds?.archive?.child || [];
              folderId = folderIds?.archive?.id;
              totalAmount = folderIds?.archive?.totalItemCount;
              folderName = category;
              break;
            }
            default: {
              childFolders = [];
              folderId = undefined;
              totalAmount = 0;
              folderName = category;
            }
          }

          return (
            <>
              <SelectionButton
                key={category}
                category={category}
                extraProps={{
                  icon,
                  label,
                  amount: totalAmount >= 1 ? totalAmount : undefined,
                  showRefreshIcon,
                  loading: loading,
                  setLoading,
                  folderId,
                  folderName,
                  handleCategorySelection: () =>
                    handleCategorySelection(category)
                }}
                onClick={() => handleCategorySelection(category)}
                hasMessage={hasMessage}
                classes={classes}
              />
              {childFolders &&
                childFolders.length > 0 &&
                childFolders.map((childFolder: IMicrosoftCustomFolder) => {
                  return (
                    <div
                      key={childFolder.id}
                      style={!hasMessage ? { marginLeft: '20px' } : {}}>
                      <SelectionButton
                        category={childFolder.displayName}
                        extraProps={{
                          icon: <Folder />,
                          label: childFolder.displayName,
                          loading,
                          setLoading,
                          amount:
                            childFolder.totalItemCount >= 1
                              ? childFolder.totalItemCount
                              : undefined,
                          hasDelete: true,
                          folderId: childFolder.id,
                          folderName: childFolder.displayName,
                          handleFolderDelete: () =>
                            deleteFolder(childFolder.id, folderId, false)
                        }}
                        hasMessage={hasMessage}
                        onClick={async () => {
                          setLoading({ ...loading, isLoading: true });
                          await fetchMessagesFromFolder(
                            childFolder.id,
                            'customFolder'
                          );
                          defaultSelection(
                            childFolder.displayName,
                            childFolder.id
                          );
                          setLoading({ ...loading, isLoading: false });
                        }}
                        classes={classes}
                      />
                    </div>
                  );
                })}
            </>
          );
        })}
      </Grid>

      {folderIds && (
        <Grid
          item
          style={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
          }}>
          {Object.values(folderIds.customFolders).map(
            (folder: IMicrosoftCustomFolder) => {
              const childFolders = folder?.child || [];
              return (
                <>
                  <SelectionButton
                    key={folder.id}
                    category={folder.displayName}
                    extraProps={{
                      icon: <Folder />,
                      label: folder.displayName,
                      loading,
                      setLoading,
                      amount:
                        folder.totalItemCount >= 1
                          ? folder.totalItemCount
                          : undefined,
                      hasDelete: true,
                      folderId: folder.id,
                      folderName: folder.displayName,
                      handleFolderDelete: () =>
                        deleteFolder(folder.id, '', false)
                    }}
                    hasMessage={hasMessage}
                    onClick={async () => {
                      setLoading({ ...loading, isLoading: true });
                      await fetchMessagesFromFolder(folder.id, 'customFolder');
                      defaultSelection(folder.displayName, folder.id);
                      setLoading({ ...loading, isLoading: false });
                    }}
                    classes={classes}
                  />

                  {childFolders.length >= 1 &&
                    childFolders.map((childFolder: IMicrosoftCustomFolder) => (
                      <div
                        key={childFolder.id}
                        style={!hasMessage ? { marginLeft: '20px' } : {}}>
                        <SelectionButton
                          category={childFolder.displayName}
                          extraProps={{
                            icon: <Folder />,
                            label: childFolder.displayName,
                            loading,
                            setLoading,
                            isChild: childFolder,
                            amount:
                              childFolder.totalItemCount >= 1
                                ? childFolder.totalItemCount
                                : undefined,
                            hasDelete: true,
                            folderId: childFolder.id,
                            folderName: childFolder.displayName,
                            handleFolderDelete: () =>
                              deleteFolder(childFolder.id, folder.id, true)
                          }}
                          hasMessage={hasMessage}
                          onClick={async () => {
                            setLoading({ ...loading, isLoading: true });
                            await fetchMessagesFromFolder(
                              childFolder.id,
                              'customFolder'
                            );
                            defaultSelection(
                              childFolder.displayName,
                              childFolder.id
                            );
                            setLoading({ ...loading, isLoading: false });
                          }}
                          classes={classes}
                        />
                      </div>
                    ))}
                </>
              );
            }
          )}
        </Grid>
      )}

      {/* <Grid
        item
        style={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
        {SecondButtonConfig.map(({ category, icon, label }) => {
          const hasFolder = folderIds && folderIds?.customFolders.length >= 1;
          if (category === 'Line_1') {
            if (!hasFolder) return <div />;
            else {
              return (
                <div
                  style={{
                    paddingTop: theme.spacing(0.5),
                    paddingBottom: theme.spacing(1),
                    marginLeft: theme.spacing(0.5),
                    marginRight: theme.spacing(0.5)
                  }}>
                  <Divider
                    style={{
                      borderRadius: theme.spacing(0.2)
                    }}
                  />
                </div>
              );
            }
          }

          return (
            <SelectionButton
              key={category}
              category={category}
              extraProps={{
                icon: icon,
                label: label,
                loading: loading,
                handleCategorySelection: () => handleCategorySelection(category)
              }}
              onClick={() => handleCategorySelection(category)}
              hasMessage={hasMessage}
              classes={classes}
            />
          );
        })}
      </Grid> */}

      <SelectionAction handleCategorySelection={handleCategorySelection} />
    </Grid>
  );
};

export default Selection;
