export default (theme) => ({
  root: {
    borderBottom: `0.5px solid ${theme.palette.border}`,
    background: theme.palette.background.default,
    // background: `linear-gradient(to right top, ${theme.palette.background.default} 50%, ${theme.palette.primary.light})`,
    display: 'flex',
    alignItems: 'center',
    height: '64px',
    zIndex: theme.zIndex.appBar,
    width: '100%'
  },
  toolbar: {
    minHeight: 'auto',
    width: '100%'
  },
  title: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  menuButton: { marginLeft: '-4px' },
  notificationsButton: { marginLeft: 'auto' },
  lightDarkSwitch: {
    // marginLeft: 'auto'
  },
  signOutButton: { marginLeft: theme.spacing(1) }
});
