import Pivot from './pivot';
import Normal from './normal';
import GroupCompanies from './Graphs/GroupCompanies';

const RepeatingObject = (props) => {
  switch (props.ObjectDefinition.Title) {
    case 'Balance Sheet':
      return <Pivot {...props} />;
    case 'Profit Loss':
      return <Pivot {...props} />;
    case 'Cashflow':
      return <Pivot {...props} />;
    case 'Group Companies':
      return <GroupCompanies {...props} />;
    case 'Ratio Analysis':
      return <Pivot {...props} />;
    default:
      return <Normal {...props} />;
  }
};

export default RepeatingObject;
