import { useState } from 'react';
import { useFileStorage } from 'hooks';
import {
  Grid,
  Box,
  Typography,
  Tooltip,
  CircularProgress,
  ButtonGroup,
  Divider,
  makeStyles,
  Theme,
  Fade
} from '@material-ui/core';
import { Button } from '@material-ui/core';
import { FbFileRef } from 'types/interfaces';
import Time from 'common/Time';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { Alert, AlertTitle } from '@material-ui/lab';
import PermissionsPickerDialog from './PermissionsPicker';

import {
  CloudDownload,
  DeleteOutline,
  Settings,
  VerifiedUser,
  Warning,
  Error,
  Security
} from '@material-ui/icons';
import { BiTransfer } from 'react-icons/bi';

import PDFViewerComponent from './FilePDFViewer';
import { RenameFileDialog } from '../RenamefileDialog';
import { useTypedSelector } from 'redux/reducers';
import { IDocumentCardProps } from '../interface';
import {
  EDocumentResults,
  EDocumentType
} from 'graphql/FileStorageAPI/interface';
import { theme } from 'theme';
import DocumentTypeDialog from 'components/FilePond/components';
import { handleDocumentProgress } from 'hooks/useFileStorage/functions';

import firebase from 'firebase';
import { REMOVE_FILE } from 'redux/actions/types';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      filter: 'brightness(0.95)'
    }
  },
  nameTypography: {
    color: theme.palette.primary.contrastText,
    marginRight: theme.spacing(1),
    paddingBottom: theme.spacing(0.5)
  },
  metadataContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  metadataDivider: {
    height: 24,
    width: 1,
    margin: theme.spacing(1)
  },
  metadataTypography: {
    color: theme.palette.primary.contrastText,
    fontSize: 13
  },
  buttonGroup: {
    '& .MuiButton-root': {
      borderColor: 'white',
      color: 'white'
    }
  },
  statusIcon: {
    marginRight: theme.spacing(1)
  },
  verifyButton: {
    marginTop: theme.spacing(1)
  },
  highRisk: {
    backgroundColor: theme.palette.error.light
  },
  risk: {
    backgroundColor: theme.palette.warning.light
  },
  lowRisk: {
    backgroundColor: theme.palette.warning.light
  },
  acceptable: {
    backgroundColor: theme.palette.success.light
  },
  riskText: {
    fontWeight: 'bold',
    color: theme.palette.error.dark,
    margin: theme.spacing(1)
  },
  warningIcon: {
    color: theme.palette.error.dark
  },
  riskAlert: {
    marginBottom: theme.spacing(1)
  }
}));

/**
 * Props for the DocumentCard component
 * @typedef {Object} DocumentCardProps
 * @property {(props: any) => void} [onSelect] - Callback function when a document is selected
 * @property {FbFileRef} item - File reference object
 * @property {() => void} get - Function to refresh the document list
 * @property {string} id - Unique identifier for the document
 * @property {boolean} [global] - Indicates if the document is in global storage
 * @property {any} [hideControls] - Flag to hide certain controls
 * @property {({key, user, checked, isGrouped}: {key: string, user: AssignedUser | string, checked: boolean, isGrouped: boolean}) => Promise<void>} handleChange - Function to handle changes in document permissions
 */

/**
 * DocumentCard component displays information about a File and provides actions such as download, delete, and permission management.
 * @param {DocumentCardProps} props - The props for the DocumentCard component
 */
const DocumentCard = ({
  item,
  get,
  id,
  global,
  handleChange,
  onSelect,
  hideControls
}: IDocumentCardProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentDeal } = useTypedSelector((s) => s.process);
  const { user } = useTypedSelector((s) => s.user);
  const { landingpage } = useTypedSelector((s) => s.utility);

  const {
    downloadFile,
    deleteFile,
    cancelDeletionRequest,
    updateName,
    moveFileToDeal,
    moveFileToGlobal
  } = useFileStorage();

  const ProcessInstanceId = global ? 0 : currentDeal?.ProcessInstance?.Id || 0;
  const inDeal = ProcessInstanceId > 0;

  const [transferLoading, setTransferLoading] = useState<boolean>(false);
  const [deletingLoading, setDeletingLoading] = useState<boolean>(false);

  const [open, setOpen] = useState(false);
  const [renameOpen, setRenameOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const [openPDF, setOpenPDF] = useState(false);

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogLoading, setDialogLoading] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<EDocumentType>();
  const [verificationStatus, setVerificationStatus] = useState<string>('');

  const handleDialogClose = () => setDialogOpen(false);

  const toggle = () => setOpen(!open);
  const toggleRename = () => setRenameOpen(!renameOpen);

  const isMine = user.Id === item.UserInstanceId;
  const amMoreThanSubSystemUser = user.SystemAccess > 4;
  const canDelete = amMoreThanSubSystemUser || isMine;

  const handleRename = async (value: string, id: string) => {
    const res = await updateName({ ProcessInstanceId, key: id, value });
    if (res === 'document successfully renamed') get();
  };

  const handleDownload = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const url = await downloadFile({
      ProcessInstanceId,
      item,
      isSelecting: false
    });
    if (url) {
      setOpenPDF(true);
      setPdfUrl(url);
    }
  };

  const handlePDFDownload = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await downloadFile({
      ProcessInstanceId,
      item,
      isSelecting: false,
      downloadPdf: true
    });
  };

  const handleDelete = async (e: React.MouseEvent) => {
    setDeletingLoading(true);
    e.stopPropagation();

    const success = await deleteFile({
      amMoreThanSubSystemUser,
      ProcessInstanceId,
      item,
      key: id
    });
    if (success) {
      get();
      dispatch({
        type: REMOVE_FILE,
        payload: id
      });
    }
    setDeletingLoading(false);
  };

  const cancelDeleteRequest = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const success = await cancelDeletionRequest({ ProcessInstanceId, key: id });
    if (success) get();
  };

  const handleDownloadAndSelect = async ({ item }: { item: FbFileRef }) => {
    const url = await downloadFile({
      ProcessInstanceId,
      item,
      isSelecting: true
    });
    if (url && onSelect) await onSelect({ url, item });
  };

  const handleTransfer = async () => {
    setTransferLoading(true);
    if (global) {
      await moveFileToDeal({
        setTransferLoading,
        fileMetadata: item,
        ProcessInstanceId: currentDeal?.ProcessInstance?.Id,
        UserInstanceId: user.Id
      });
    } else {
      await moveFileToGlobal({
        setTransferLoading,
        fileMetadata: item,
        ProcessInstanceId: currentDeal?.ProcessInstance?.Id,
        UserInstanceId: user.Id
      });
    }
  };

  const handleVerifyDocument = async () => {
    setDialogOpen(true);
  };

  const renderStatusIcon = () => {
    if (!item.documentStatus) return null;

    const { documentStatus } = item.documentStatus;
    let icon;
    let color;

    switch (documentStatus) {
      case EDocumentResults.ACCEPTABLE:
        icon = <VerifiedUser className={classes.statusIcon} />;
        color = 'green';
        break;
      case EDocumentResults.HIGH_RISK:
        icon = <Error className={classes.statusIcon} />;
        color = 'red';
        break;
      case EDocumentResults.RISK:
      case EDocumentResults.LOW_RISK:
        icon = <Warning className={classes.statusIcon} />;
        color = 'orange';
        break;
      default:
        return null;
    }

    return (
      <Tooltip title={`Document Status: ${documentStatus}`}>
        <Box component="span" style={{ color }}>
          {icon}
        </Box>
      </Tooltip>
    );
  };

  const getRiskClass = () => {
    if (!item.documentStatus) return '';

    switch (item.documentStatus.documentStatus) {
      case EDocumentResults.HIGH_RISK:
        return classes.highRisk;
      case EDocumentResults.RISK:
        return classes.risk;
      case EDocumentResults.LOW_RISK:
        return classes.lowRisk;
      default:
        return '';
    }
  };

  const renderRiskWarning = () => {
    if (!item.documentStatus) return null;

    const { documentStatus } = item.documentStatus;
    if (
      documentStatus === EDocumentResults.ACCEPTABLE ||
      documentStatus === EDocumentResults.NONE
    )
      return null;

    let severity: 'error' | 'warning' = 'warning';
    let title = '';
    let message = '';

    switch (documentStatus) {
      case EDocumentResults.HIGH_RISK:
        severity = 'error';
        title = 'This Document Has Been Identified As a Risk. ';
        message =
          'We recommend taking action to verify its authenticity. Please contact the Bips team who can run a complete check and report. Bips@afsuk.com, 01254 958786. ';
        break;
      case EDocumentResults.RISK:
        title = 'Document Poses Risk';
        message = 'This document requires immediate attention and review.';
        break;
      case EDocumentResults.LOW_RISK:
        title = 'Low Risk Document';
        message = 'This document has a low risk but should be reviewed.';
        break;
    }

    return (
      <Alert
        severity={severity}
        className={classes.riskAlert}
        icon={
          <div style={{ paddingTop: theme.spacing(0.5) }}>
            <Warning fontSize="large" className={classes.warningIcon} />
          </div>
        }>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    );
  };

  const handleDialogConfirm = async () => {
    setDialogLoading(true);
    if (documentType) {
      const db = firebase.firestore();
      const storage = firebase.storage();

      const ProcessInstanceId = global
        ? 0
        : currentDeal?.ProcessInstance?.Id || 0;

      try {
        const updateStatus = (status: string) => {
          setVerificationStatus(status);
        };

        let tempUrl;
        const typeOfName = item.fileId ? item.nickName + item.fileId : item;

        let fileURL;
        if (global) {
          fileURL = `files/0/${item.UserInstanceId}/${typeOfName}`;
        } else if (currentDeal?.ProcessInstance?.Id) {
          fileURL = `files/${ProcessInstanceId}/${item.UserInstanceId}/${typeOfName}`;
        }

        const originalFileRef = storage.ref().child(fileURL);
        const url = await originalFileRef.getDownloadURL();
        if (url) tempUrl = url;

        const response = await fetch(tempUrl);
        if (!response.ok) return;

        const blob = await response.blob();
        const file = new File([blob], item.name, { type: item.type });

        const { documentProgress } = await handleDocumentProgress({
          documentType,
          updateStatus,
          file,
          user
        });

        const dbRef = db
          .collection('deal')
          .doc(ProcessInstanceId.toString())
          .collection('files')
          .doc(id);

        await dbRef.update({
          tempUrl,
          documentStatus: {
            documentType,
            documentUUID: documentProgress.documentUUID,
            documentStatus: documentProgress.documentStatus
          }
        });

        setVerificationStatus('Document verified and updated successfully.');
        get();
      } catch (error) {
        console.error('Error during document verification:', error);
        setVerificationStatus('Error occurred during verification.');
      } finally {
        setDialogLoading(false);
        setTimeout(() => {
          setDialogOpen(false);
          setVerificationStatus('');
        }, 2000);
      }
    } else {
      setDialogLoading(false);
      setDialogOpen(false);
    }
  };

  return (
    <>
      <DocumentTypeDialog
        isOpen={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
        files={[
          {
            file: {
              name: item.name,
              size: item.size,
              type: item.type
            } as File,
            documentType: documentType ? documentType : null,
            status: verificationStatus
          }
        ]}
        onDocumentTypeChange={(index, newDocumentType) => {
          setDocumentType(newDocumentType);
        }}
        loading={dialogLoading}
      />
      <Fade in>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={
            user.SystemAccess >= 5
              ? `${classes.container} ${getRiskClass()}`
              : classes.container
          }>
          {user.SystemAccess >= 5 && (
            <Grid item xs={12}>
              {renderRiskWarning()}
            </Grid>
          )}
          <Grid item>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
              {user.SystemAccess >= 5 && renderStatusIcon()}

              <Typography variant="h5" className={classes.nameTypography}>
                Name: {item.nickName || item.name}
              </Typography>
            </Box>

            <Box className={classes.metadataContainer}>
              <QuickUserFromId
                UserInstanceId={item.UserInstanceId.toString()}
              />
              <Divider
                orientation="vertical"
                className={classes.metadataDivider}
              />
              <Time
                time={item.uploaded}
                style={{ color: 'white' }}
                type="timeago"
              />
              <Divider
                orientation="vertical"
                className={classes.metadataDivider}
              />
              <Typography
                variant="caption"
                className={classes.metadataTypography}>
                Size: {`${(item.size / 1000000).toFixed(2)} Mb`}
              </Typography>
            </Box>
          </Grid>

          <div style={{ flexGrow: 1 }} />

          <Grid item>
            <Box>
              {item.deleteRequest ? (
                <Alert severity="error">
                  <Typography>File deletion requested</Typography>
                  <Time time={item.deleteRequest} type="timeago" />
                  <br />
                  <br />
                  {amMoreThanSubSystemUser ? (
                    <Tooltip title="This will permanently delete the file from storage">
                      <Button
                        fullWidth
                        onClick={handleDelete}
                        variant="outlined">
                        Accept Delete Request
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      fullWidth
                      onClick={cancelDeleteRequest}
                      variant="outlined">
                      Cancel Delete Request
                    </Button>
                  )}
                </Alert>
              ) : (
                <ButtonGroup variant="outlined" className={classes.buttonGroup}>
                  {!item.documentStatus && (
                    <Tooltip arrow title="Verify Document">
                      <Button onClick={handleVerifyDocument}>
                        <Security />
                      </Button>
                    </Tooltip>
                  )}

                  {!hideControls && canDelete && (
                    <Tooltip arrow title="Edit Document Name">
                      <Button onClick={toggleRename}>
                        <Settings />
                      </Button>
                    </Tooltip>
                  )}

                  {!hideControls &&
                    !landingpage &&
                    Object.keys(currentDeal).length >= 1 &&
                    currentDeal?.ProcessInstance?.Id !== 0 && (
                      <Tooltip
                        arrow
                        title={`Transfer Document From ${
                          global
                            ? 'Global To Deal Storage'
                            : 'Deal Storage To Global'
                        }`}>
                        <Button
                          data-cy="viewers-btn"
                          onClick={handleTransfer}
                          disabled={transferLoading}>
                          {transferLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            <BiTransfer size={20} />
                          )}
                        </Button>
                      </Tooltip>
                    )}

                  {!hideControls && canDelete && (
                    <Tooltip arrow title="Delete Document">
                      <Button onClick={handleDelete}>
                        {deletingLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <DeleteOutline />
                        )}
                      </Button>
                    </Tooltip>
                  )}

                  {pdfUrl && (
                    <PDFViewerComponent
                      fileUrl={pdfUrl}
                      openPDF={openPDF}
                      setOpenPDF={setOpenPDF}
                      handlePDFDownload={handlePDFDownload}
                    />
                  )}

                  <Tooltip arrow title="View Document">
                    <Button
                      data-cy="file-storage-view-btn"
                      onClick={handleDownload}>
                      <CloudDownload />
                    </Button>
                  </Tooltip>

                  {!hideControls && inDeal && !landingpage && (
                    <Button onClick={toggle}>Viewers</Button>
                  )}

                  {onSelect && (
                    <Tooltip arrow title="Select Document">
                      <Button
                        data-cy="file-select-btn"
                        onClick={() => handleDownloadAndSelect({ item })}>
                        Select
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              )}

              <PermissionsPickerDialog
                open={open}
                global={global}
                item={item}
                id={id}
                handleChange={handleChange}
                toggle={toggle}
              />

              {renameOpen && (
                <RenameFileDialog
                  open={renameOpen}
                  toggle={toggleRename}
                  item={item}
                  id={id}
                  handleChange={handleRename}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </>
  );
};

export default DocumentCard;
