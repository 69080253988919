import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getAuditByProcessId } from 'redux/actions/audits';
import Table from 'components/SimpleTable';
import { DateParse } from 'common/dateParse';
import DetailPanel from './DetailPanel';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  }
}));

const Component = (props) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { token } = useTypedSelector((s) => s.user.auth);
  const { user } = useTypedSelector((s) => s.user);
  const { ProcessInstanceId } = props.ProcessInstance;
  const [data, setData] = React.useState([]);
  const isSuperAdmin = user.SystemAccess >= 12;

  const columns = [
    {
      field: 'Endpoint',
      title: 'Activity Type',
      render: (rowData) => {
        if (rowData.Endpoint.includes(`/v1/FieldInstanceAPI.ashx`))
          return 'Field Change';
        if (rowData.Endpoint.includes(`/v1/ProcessInstanceAPI.ashx`))
          return 'Step Change';
        if (rowData.Endpoint.includes(`/v1/ObjectInstanceAPI.ashx`))
          return 'Object Change';
        if (rowData.Endpoint.includes(`/v1/ruleapi.ashx`))
          return 'Rule Triggered';
        return rowData.Endpoint;
      }
    },
    { field: 'UserInstanceEmail', title: 'User' },
    {
      field: 'TimeStamp',
      title: 'Time',
      render: (rowData) => <DateParse date={rowData.TimeStamp} />
    }
  ];

  const getData = async () => {
    if (ProcessInstanceId) {
      let data = await getAuditByProcessId({ token, ProcessInstanceId });
      const excludedEndpoints = [
        '/v1/ProcessStepAPI.ashx',
        '/v1/LandingPageAPI.ashx'
      ];
      if (!isSuperAdmin) {
        data = data.filter((item) => item.Endpoint.includes('EmailOpen'));
      } else {
        data = data.filter(
          (item) =>
            !excludedEndpoints.some((endpoint) =>
              item.Endpoint.includes(endpoint)
            )
        );
      }

      setData(data);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div className={classes.root}>
      <div ref={ref}>
        {data && ref && ref.current && (
          <div style={{ width: ref.current.clientWidth }}>
            <Table
              columns={columns}
              data={data}
              detailPanel={(rowData) => <DetailPanel {...rowData} />}
              title=""
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Component;
