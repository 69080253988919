import { Checkbox, makeStyles, Typography } from '@material-ui/core';
import { IRequiredFields } from 'hooks/useDeals/useRequiredCompletion';
import { groupBy } from 'lodash-es';
import { theme } from 'theme';
import WarningIcon from '@material-ui/icons/Warning';
import { useState } from 'react';
import { useProcess } from 'hooks';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    maxHeight: '400px',
    overflowY: 'auto'
  },
  text: {
    color: 'white'
  },
  parent: {
    border: '3px solid gray',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(1),
    padding: theme.spacing(1)
  }
}));

const RequiredTooltip = ({
  ReqFields,
  confirm
}: {
  ReqFields: IRequiredFields[];
  confirm?: boolean;
}) => {
  const parentsMap = groupBy(ReqFields, 'parent');
  const classes = useStyles();
  const { user } = useProcess();
  const subSystemUser = user.SystemAccess <= 4;

  const shouldHideFields = (field: IRequiredFields) => {
    return subSystemUser && field.parent === 'Lender & Proposal Information';
  };

  const allFieldsCompleted =
    ReqFields.length <= 0 || ReqFields.every(shouldHideFields);

  if (allFieldsCompleted) {
    if (!confirm) return 'Required Fields Completed';
    else return '';
  }

  const filteredParentsMap = Object.entries(parentsMap).filter(
    ([parent, children]) => children.some((child) => !shouldHideFields(child))
  );

  return (
    <div className={classes.tooltip}>
      {filteredParentsMap.map(([parent, children]) => (
        <div key={parent} className={classes.parent}>
          <Typography className={classes.text} variant="h5">
            {parent}
          </Typography>
          <div style={{ paddingTop: 5 }}>
            {children.map((child, index) => (
              <>
                <div
                  key={`${child.title}-${index}`}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid gray',
                    borderRadius: theme.shape.borderRadius,
                    padding: 5
                  }}>
                  <WarningIcon
                    style={{
                      color: theme.palette.warning.main,
                      marginRight: 10
                    }}
                    fontSize="small"
                  />
                  <Typography className={classes.text}>
                    {child.title}
                  </Typography>
                </div>
                <div style={{ padding: 2 }} />
              </>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default RequiredTooltip;
