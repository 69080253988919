import React from 'react';
import Confetti from 'react-confetti';
import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmailIcon from '@material-ui/icons/Email';

export default function FormDialog({ open, handleClose, message, title }) {
  const theme = useTheme();
  const landingpage = useSelector((s) => s.utility.landingpage);
  const { height, width } = UseWindowDimensions();

  return (
    <div>
      {open && <Confetti width={width} height={height} />}
      <Dialog
        fullScreen={Boolean(landingpage)}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ root: { background: 'blue' } }}>
        <DialogTitle id="form-dialog-title">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column'
            }}>
            <EmailIcon
              color="primary"
              style={{ marginRight: 5, fontSize: 100 }}
            />
            <Typography
              style={{ color: theme.palette.primary.main }}
              variant="h1">
              {title ? title : 'Deal Completed!'}
            </Typography>
          </div>
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              flexDirection: 'column'
            }}>
            <DialogContentText style={{ textAlign: 'center' }}>
              {message ? message : 'Congratulations'}
            </DialogContentText>
          </div>
        </DialogContent>

        <DialogActions>
          {!landingpage && (
            <Button onClick={handleClose} color="primary">
              Go Home
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}

const GetWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const UseWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = React.useState(
    GetWindowDimensions()
  );

  React.useEffect(() => {
    function handleResize() {
      setWindowDimensions(GetWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
