import { useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  FieldDefinition,
  FieldInstance,
  CompleteObjectInstance
} from 'types/interfaces';

const useStyles = makeStyles((theme) => ({
  root: { minWidth: 300 }
}));

interface IFieldTypeSelectorProps {
  FieldDefinition: FieldDefinition;
  FieldInstance: FieldInstance | undefined;
  readOnly: boolean;
  state: CompleteObjectInstance | undefined;
  value: string | undefined;
  handleChange: ({
    FieldValue,
    FieldInstance,
    FieldDefinition
  }: {
    FieldValue: string;
    FieldInstance: FieldInstance | undefined;
    FieldDefinition: FieldDefinition;
  }) => void;
}

export const DateField = ({
  FieldDefinition,
  FieldInstance,
  handleChange,
  readOnly = false,
  value
}: IFieldTypeSelectorProps) => {
  const initialValue = value ? moment(value, 'YYYY-MM-DD') : new Date();
  const classes = useStyles();
  const [state, setState] = useState<MaterialUiPickersDate | Date>(
    initialValue
  );

  const onChange = (date: MaterialUiPickersDate) => {
    handleChange({
      FieldValue: moment(date).format('YYYY-MM-DD'),
      FieldInstance,
      FieldDefinition
    });
    setState(date);
  };

  return (
    <DatePicker
      className={classes.root}
      onChange={onChange}
      value={state}
      readOnly={readOnly}
    />
  );
};
