import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RedFlag from 'components/RedFlag';
import {
  IDirectorCompany,
  OffkeyDirectorLite
} from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IRedFlagDirectorship } from 'components/OffkeyCompanyAndDirectorSearch/helper';

const DirectorshipDialog = ({
  open,
  handleClose,
  data
}: {
  open: boolean;
  handleClose: () => void;
  data: IRedFlagDirectorship | IDirectorCompany;
}): JSX.Element => {
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        {'RedFlag Company Search'}
      </DialogTitle>
      <DialogContent>
        <RedFlag directorshipToCompanyData={data} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DirectorshipDialog;
