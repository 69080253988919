import { useCallback } from 'react';

interface PostData {
  [key: string]: string | number | boolean;
}

export const usePostToNewWindow = () => {
  const postToNewWindow = useCallback(
    (url: string, data: PostData, dealId?: number, token?: string) => {
      // Create a hidden form
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = url;
      form.target = '_blank';
      form.enctype = 'application/x-www-form-urlencoded'; // This is important for the data to be sent as a JSON string

      // Add hidden inputs for each data field
      // Object.entries(data).forEach(([key, value]) => {
      //   const input = document.createElement('input');
      //   input.type = 'hidden';
      //   input.name = key;
      //   input.value = String(value);
      //   form.appendChild(input);
      // });

      // Add a single hidden input for the entire data object
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'data'; // The name will be ignored
      input.value = encodeURIComponent(JSON.stringify(data)); // The value needs to be empty
      form.appendChild(input);

      const id = document.createElement('input');
      id.type = 'hidden';
      id.name = 'dealId'; // The name will be ignored
      id.value = JSON.stringify(dealId); // The value needs to be empty
      form.appendChild(id);

      const auth = document.createElement('input');
      auth.type = 'hidden';
      auth.name = 'token'; // The name will be ignored
      auth.value = JSON.stringify(token); // The value needs to be empty
      form.appendChild(auth);

      console.log('form', form);

      // Add the form to the document and submit it
      document.body.appendChild(form);
      form.submit();

      // Remove the form from the document
      document.body.removeChild(form);
    },
    []
  );

  return postToNewWindow;
};

export default usePostToNewWindow;
