import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import {
  FieldDefinitionDict,
  FieldDefinition,
  ObjectDefinition,
  CompleteProcessStepDefinition,
  CompleteObjectInstance,
  FieldInstance,
  Rule
} from 'types/interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import {
  Typography,
  Grid,
  Paper,
  Tooltip,
  Checkbox,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  ButtonGroup
} from '@material-ui/core';
import { BiHide, BiShow } from 'react-icons/bi';
import NumberFormat from 'react-number-format';
import Summary from './components/Summary';

import DeleteObjectButton from '../components/DeleteObjectButton';
import { useObject } from 'hooks/useObject';
import { getDealData } from 'redux/actions/processes/getDealData';
import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';

import { objectType } from '../Forms/FormContent';
import { theme } from 'theme';
import { useProcess } from 'hooks';
import { globalIds } from 'helpers/globalIdConfig';
import { SupplierInvoiceRequest } from 'redux/database/Custom API';
import { useTypedSelector } from 'redux/reducers';
import ReactHtmlParser from 'react-html-parser';
import { FileCopy } from '@material-ui/icons';
import { getDocument } from '../helper/getDocument';
import { updateFieldInstance } from 'redux/actions/GraphQlActions';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { StepperContext } from 'components/Stepper/context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      margin: '8px 0px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start'
    },
    leverCol: {
      width: 270,
      paddingRight: theme.spacing(2),
      borderRight: `1px ${theme.palette.grey['A100']} solid`
    },
    otherCol: {
      paddingLeft: theme.spacing(2),
      width: '100%'
    },
    hr: {
      borderRadius: 10,
      background: theme.palette.grey['A100'],
      margin: 5
    },
    inlinetext: {
      color: theme.palette.secondary.main
    },
    paper: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`
    },
    TotalsBox: {
      border: `3px ${theme.palette.background.default} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`,
      background: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-around'
    },
    TotalsBoxText: {
      color: theme.palette.primary.contrastText
    }
  })
);

interface Props {
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ObjectDefinition: ObjectDefinition;
  stepdefdict: CompleteProcessStepDefinition;
  setObjectData: Dispatch<SetStateAction<unknown[]>>;
  setCurrentObjectType: Dispatch<SetStateAction<objectType>>;
  currentObjectType: objectType;
}

const RepeatDynamicAssetObject = (props: Props) => {
  const dispatch = useDispatch();
  const {
    currentDeal,
    user: UserInstance,
    currentProcess,
    currentOverView,
    prostepdefid
  } = useProcess();

  const { isDealClosed, isDealTransferred } = useContext(StepperContext);

  const { processInstanceFields } = useTypedSelector((s) => s.process);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const [refreshingDeal, setRefreshingDeal] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState<
    Record<string, boolean>
  >({});

  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const [object, setObject] = useState<CompleteObjectInstance | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const ProcessInstanceId = currentDeal?.ProcessInstance?.Id;
  const ProcessStepDefinitionId =
    currentDeal?.ProcessInstance?.ProcessStepDefinitionId;
  const { FieldDefinitionList, ObjectDefinition, stepdefdict } = props;

  const { deleteObject, publishObject, duplicateObject } = useObject({
    ObjectDefinition,
    UserInstance,
    ProcessInstanceId,
    Fields: FieldDefinitionList,
    ProcessStepSelectionType:
      stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType
  });

  const deleteObjectAndRefresh = async (Id) => {
    const deleted = await deleteObject(Id);
    deleted.status === 200 && refreshDealData();
  };

  const refreshDealData = async () => {
    setRefreshingDeal(true);
    const ProcessDefinitionId = currentDeal.ProcessInstance.ProcessDefinitionId;
    const res = await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });

    if (res) {
      setRefreshingDeal(false);
    }
  };

  const classes = useStyles();

  const CompleteObjectInstanceList: CompleteObjectInstance[] = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).filter(
    (CompleteObjectInstance: CompleteObjectInstance): boolean =>
      CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
      props.ObjectDefinition.Id
  );

  const canAddMore =
    props.ObjectDefinition.ObjectRepeat === 0
      ? true
      : CompleteObjectInstanceList.length < props.ObjectDefinition.ObjectRepeat;

  const PricePerUnitDefinitionId = props.FieldDefinitionList.find((i) =>
    i.Title.includes('Price Per Unit')
  )?.Id;

  const QuantityDefId = props.FieldDefinitionList.find((i) =>
    i.Title.includes('Quantity')
  )?.Id;

  let prices: any = [];
  let assetsNumber = 0;
  let sum = 0;
  CompleteObjectInstanceList.forEach(
    async (CompleteObjectInstance: CompleteObjectInstance) => {
      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

      const priceInstObj: FieldInstance | undefined = FieldInstanceList.find(
        (FieldInstance: FieldInstance) =>
          FieldInstance.FieldDefinitionId === PricePerUnitDefinitionId
      );

      if (!priceInstObj) return;

      const pricePerUnit = priceInstObj
        ? Number.isNaN(parseFloat(priceInstObj.FieldValue))
          ? 0
          : parseFloat(priceInstObj.FieldValue)
        : 0;

      const quantityInstObj: FieldInstance | undefined = FieldInstanceList.find(
        (FieldInstance: FieldInstance) =>
          FieldInstance.FieldDefinitionId === QuantityDefId
      );

      if (!quantityInstObj) return;
      if (quantityInstObj && quantityInstObj?.FieldValue === '') {
        let defaultQuantity = '1';

        quantityInstObj.FieldValue = defaultQuantity;
        await updateFieldInstance({
          baseUrl,
          data: quantityInstObj,
          ProcessInstanceId: quantityInstObj.ProcessInstanceId
        });
        return;
      }

      const quantity =
        quantityInstObj.FieldValue !== ''
          ? parseFloat(quantityInstObj.FieldValue)
          : 1;

      assetsNumber += quantity;
      prices.push(pricePerUnit * quantity);
    }
  );
  for (let i = 0; i < prices.length; i++) {
    sum += prices[i];
  }

  /**set local repeatDynamicAssetObject data values before updating the objectData state within parent component */
  const [
    localRepeatDynamicAssetObjectData,
    setLocalRepeatDynamicAssetObjectData
  ] = useState<unknown[]>([]);

  const findField = (fieldInstances: FieldInstance[], fieldDefId: number) =>
    fieldInstances.find(
      (FieldInstance: FieldInstance) =>
        FieldInstance.FieldDefinitionId === fieldDefId
    );

  const hasSupplierAndSupplierContact = (
    CompleteObjectInstance: CompleteObjectInstance
  ) => {
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
    const supplier: string | undefined = findField(
      FieldInstanceList,
      globalIds.supplier.assetDetail.supplier
    )?.FieldValue;

    const supplierContact: string | undefined = findField(
      FieldInstanceList,
      globalIds.supplier.assetDetail.supplierContact
    )?.FieldValue;

    return supplier !== '' && supplierContact !== '';
  };

  const handleSupplierSubmit = async (
    CompleteObjectInstance: CompleteObjectInstance
  ) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [CompleteObjectInstance.ObjectInstance.Id]: true
    }));

    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
    const supplier: FieldInstance | undefined = findField(
      FieldInstanceList,
      globalIds.supplier.assetDetail.supplier
    );
    const supplierContact: FieldInstance | undefined = findField(
      FieldInstanceList,
      globalIds.supplier.assetDetail.supplierContact
    );

    const foundStep = globalIds.stepProcesses.supplierRequestInvoice.find(
      (step) => currentProcess.ProcessStepDefinitionSequence.includes(step)
    );

    if (foundStep) {
      const SupplierFieldDefinitionId = supplierContact?.FieldValue
        ? globalIds.supplier.assetDetail.supplierContact
        : globalIds.supplier.assetDetail.supplier;

      await SupplierInvoiceRequest({
        ProcessInstanceId,
        SupplierId: parseInt(supplier?.FieldValue || '0'),
        ProcessStepDefinitionId: foundStep,
        SupplierContactId: parseInt(supplierContact?.FieldValue || '0'),
        ObjectDefinitionId: globalIds.objectDefinitionIds.assetDetailOBID,
        FieldDefinitionId: SupplierFieldDefinitionId,
        CustomerId: deal?.primaryContact?.Id ?? 0
      });

      setDialogOpen(false);
      setLoading((prevLoading) => ({
        ...prevLoading,
        [CompleteObjectInstance.ObjectInstance.Id]: false
      }));
    }
  };

  useEffect(() => {
    props.setCurrentObjectType(objectType.repeatDynamicAssetObject);

    return () => {
      props.setCurrentObjectType(objectType.nullObject);
      props.setObjectData([]);
    };
  }, [props.currentObjectType, props.ObjectDefinition.Title]);

  useEffect(() => {
    return () => {
      setLocalRepeatDynamicAssetObjectData([]);
    };
  }, [props.ObjectDefinition.Title]);

  useEffect(() => {
    CompleteObjectInstanceList.forEach(
      (CompleteObjectInstance: CompleteObjectInstance, idx: number) => {
        const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
        let perData: { key: string; value: string }[] = [];

        FieldDefinitionList.forEach(
          (FieldDefinition: FieldDefinition, idx2: number) => {
            const FieldInstance: FieldInstance | undefined =
              FieldInstanceList.find(
                (FieldInstance: FieldInstance) =>
                  FieldInstance.FieldDefinitionId === FieldDefinition.Id
              );

            if (FieldInstance) {
              const Title = FieldDefinition.Title;
              const FieldValue =
                FieldInstance && FieldInstance?.FieldValue?.length < 500000
                  ? FieldInstance.FieldValue
                  : 'Data too long to save onto file';

              perData.push({ key: Title, value: FieldValue });
            }
          }
        );

        setLocalRepeatDynamicAssetObjectData((prevState) => [
          ...prevState,
          perData
        ]);
      }
    );
  }, []);

  // update the ObjectData state within the parent component for use with pdf download file
  useEffect(() => {
    props.setObjectData(localRepeatDynamicAssetObjectData);
  }, [localRepeatDynamicAssetObjectData]);

  const { getRuleGroups } = useProcess();
  const { rules } = getRuleGroups();

  const action: Rule | undefined | any = Object.values(rules).find(
    (action: Rule) => action.Id === 205
  );

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <div>
      {action && (
        <Dialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{action.Title}</DialogTitle>
          <DialogContent>{ReactHtmlParser(action.Instruction)}</DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogOpen(false)} color="primary">
              Cancel
            </Button>
            <Button
              data-cy="rule-proceed-btn"
              onClick={() => {
                if (object) handleSupplierSubmit(object);
              }}
              color="primary"
              autoFocus>
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <Paper elevation={1} className={classes.TotalsBox}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant="h2" className={classes.TotalsBoxText}>
            {CompleteObjectInstanceList.length}
          </Typography>
          <Typography className={classes.TotalsBoxText}>ASSET TYPES</Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant="h2" className={classes.TotalsBoxText}>
            {assetsNumber}
          </Typography>
          <Typography className={classes.TotalsBoxText}>ASSETS</Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant="h2" className={classes.TotalsBoxText}>
            {
              <NumberFormat
                value={sum}
                prefix={'£'}
                thousandSeparator
                displayType={'text'}
                decimalScale={2}
              />
            }
          </Typography>
          <Typography className={classes.TotalsBoxText}>TOTAL PRICE</Typography>
        </div>
      </Paper>

      <Grid container spacing={2}>
        {CompleteObjectInstanceList.map(
          (CompleteObjectInstance: CompleteObjectInstance, idx: number) => {
            const isLoading = loading[CompleteObjectInstance.ObjectInstance.Id];
            const isLoadingDocument =
              loadingDocument[CompleteObjectInstance.ObjectInstance.Id];

            return (
              <Grid container item key={idx} md={6} sm={12}>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="flex-end"
                  style={{ padding: theme.spacing(1) }}>
                  <ButtonGroup variant="text" style={{ maxHeight: 40 }}>
                    <Tooltip title="Hide/Show">
                      <Checkbox
                        icon={<BiHide />}
                        checkedIcon={<BiShow />}
                        checked={
                          CompleteObjectInstance.ObjectInstance.IsPublished
                        }
                        onChange={() => publishObject(CompleteObjectInstance)}
                      />
                    </Tooltip>
                    <Tooltip title="Duplicate Asset">
                      <IconButton
                        onClick={() => duplicateObject(CompleteObjectInstance)}>
                        <FileCopy />
                      </IconButton>
                    </Tooltip>
                    <DeleteObjectButton
                      deleteObject={deleteObjectAndRefresh}
                      object={CompleteObjectInstance}
                      title={CompleteObjectInstance.ObjectInstance.Title}
                    />
                  </ButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    border: `1px ${theme.palette.grey[300]} solid`,
                    borderRadius: theme.shape.borderRadius,
                    padding: theme.spacing(1)
                  }}>
                  <Summary ObjectInstance={CompleteObjectInstance} {...props} />

                  {hasSupplierAndSupplierContact(CompleteObjectInstance) && (
                    <Grid
                      item
                      container
                      style={{ padding: theme.spacing(1) }}
                      justifyContent="flex-end">
                      {isLoading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          {isLoadingDocument ? (
                            <>
                              <CircularProgress />

                              <div
                                style={{
                                  paddingRight: theme.spacing(2)
                                }}
                              />

                              <Tooltip
                                arrow
                                title={`Click "Send" To Issue An Invoice Request To The Supplier.`}>
                                <Button
                                  data-cy="send-request-invoice-btn"
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    setObject(CompleteObjectInstance);
                                    if (object) {
                                      setDialogOpen(true);
                                    }
                                  }}>
                                  Send
                                </Button>
                              </Tooltip>
                            </>
                          ) : (
                            <ButtonGroup color="primary" variant="contained">
                              <Tooltip
                                arrow
                                title={`Click "Preview Document" To Review Supplier Invoice Request`}>
                                <Button
                                  onClick={async () => {
                                    setLoadingDocument((prevLoading) => ({
                                      ...prevLoading,
                                      [CompleteObjectInstance.ObjectInstance
                                        .Id]: true
                                    }));
                                    const result = await getDocument({
                                      currentOverView,
                                      prostepdefid,
                                      processInstanceFields
                                    });
                                    if (!result) {
                                      dispatch(
                                        createNotification(
                                          errorNotif(
                                            `We're Unable To Locate The "Supplier Invoice Request". Please Ensure The Correct Process Is Followed Or Contact Support For Assistance.`
                                          )
                                        )
                                      );
                                    }
                                    setLoadingDocument((prevLoading) => ({
                                      ...prevLoading,
                                      [CompleteObjectInstance.ObjectInstance
                                        .Id]: false
                                    }));
                                  }}>
                                  Preview Request
                                </Button>
                              </Tooltip>
                              <Tooltip
                                arrow
                                title={`Click "Send" To Issue An Invoice Request To The Supplier.`}>
                                <Button
                                  data-cy="send-request-invoice-btn"
                                  onClick={() => {
                                    setObject(CompleteObjectInstance);
                                    if (object) {
                                      setDialogOpen(true);
                                    }
                                  }}>
                                  Send
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          )}
                        </>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            );
          }
        )}
      </Grid>

      <br />
      {canAddMore && UserInstance && !dealCondition && (
        <AddFieldAndObjectButton
          Fields={Object.values(props.FieldDefinitionDict)}
          ObjectDefinition={props.ObjectDefinition}
          ProcessInstance={currentDeal.ProcessInstance}
          ProcessInstanceId={currentDeal.ProcessInstance.Id}
          ProcessStepSelectionType={
            props.stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
          }
          UserInstance={UserInstance}
          label={`New ${props.ObjectDefinition.Title}`}
          isAssetObject
        />
      )}
    </div>
  );
}; //END RepeatDynamicAssetObject

export default RepeatDynamicAssetObject;
