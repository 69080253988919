import { Theme, makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      borderRadius: 3,
      padding: 24,
      margin: 8,
      flexBasis: '400px'
      // [theme.breakpoints.down('sm')]: {
      //   paddingLeft: theme.spacing(2),
      //   paddingRight: theme.spacing(2)
      // },
      // [theme.breakpoints.up('md')]: {
      //   width: '50%'
      // }
    },
    title: {},
    subtitle: {
      color: theme.palette.text.secondary,
      marginBottom: theme.spacing(6)
    },
    fields: {
      marginTop: theme.spacing(3)
    },
    textField: {
      color: theme.palette.primary.contrastText,
      width: '100%',
      '& + & ': {
        marginTop: theme.spacing(2)
      }
    },
    progress: {
      display: 'block',
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    signInButton: {
      marginTop: theme.spacing(2),
      width: '100%'
    },
    fieldError: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1)
    },
    submitError: {
      color: theme.palette.error.main,
      alignText: 'center',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2)
    }
  })
);
