export const BalanceSheetTitles = [
  'no_weeks',
  'intangible_assets',
  'total_fixed_assets',
  'stocks',
  'trade_debtors',
  'cash',
  'misc_current_assets',
  'total_current_assets',
  'tangible_fixed_assets',
  'pl_account_reserve',
  'working_capital',
  'shareholders_funds',
  'total_long_term_liabilities',
  'creditors_amounts_falling',
  'total_assets_less_liabilities',
  'revaluation_reserve',
  'share_capital_reserve',
  'total_liabilities',
  'total_assets',
  'contingent_liabilities',
  'net_worth'
];

export const RatioAnalysis = [
  'average_employee_remuneration',
  'capital_employed_per_employee',
  'creditors_debtors',
  'current_liabilities_stocks',
  'current_ratio',
  'debtors_total_assets',
  'export_sales',
  'interest_pre_interest_profit',
  'liquidity_ratio',
  'long_term_debt_net_worth',
  'profit_capital_employed',
  'profit_per_employee',
  'profit_sales',
  'profit_shareholders_funds',
  'profit_total_assets',
  'sales_audit_fees',
  'sales_per_employee',
  'shareholders_funds_total_assets',
  'total_assets_audit_fees',
  'total_assets_per_employee',
  'total_debt_net_worth',
  'total_debt_working_capital',
  'total_fixed_assets_per_employee',
  'turnover_fixed_assets',
  'turnover_total_assets',
  'wages_sales',
  'working_capital_turnover'
];

export const CashflowTitles = [
  'net_from_operating_activities',
  'net_from_return_investment_servicing',
  'increase_in_cash',
  'net_before_financing',
  'net_from_financing'
];

export const ProfitLossTitles = [
  'no_weeks',
  'turnover',
  'cost_of_sales',
  'gross_profit',
  'non_audit_fee',
  'operating_profit',
  'non_trading_income',
  'interest_payable',
  'audit_fee',
  'director_renum',
  'post_tax_profit',
  'taxation',
  'pre_tax_profit',
  'dividends',
  'retained_profit',
  'value_added'
];
