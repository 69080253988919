import { useEffect, useRef } from 'react';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { IColumnOrder } from '../interface';

export const getSortedColumns: (Id: string) => Promise<IColumnOrder[]> = async (
  Id: string
) => {
  return firebase
    .firestore()
    .collection('userAccount')
    .doc(Id)
    .get()
    .then((snapshot) => {
      if (snapshot.exists && snapshot?.data()?.column_layout) {
        return JSON.parse(snapshot?.data()?.column_layout) as IColumnOrder[];
      } else {
        return [] as IColumnOrder[];
      }
    })
    .catch((e) => {
      BugTracker.notify(e);
      return [] as IColumnOrder[];
    });
};

export const useIsMounted = () => {
  const isMounted = useRef<boolean>(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return () => isMounted.current;
};
