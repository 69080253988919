import {
  IconButton,
  Tooltip,
  Grid,
  Typography,
  makeStyles,
  useTheme,
  Theme,
  createStyles
} from '@material-ui/core';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import { HideShowFields } from 'components/Stepper/functions/hideshowFields';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { useProcess } from 'hooks';
import { useObject } from 'hooks/useObject';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useDispatch } from 'react-redux';
import { selectObjectInstance } from 'redux/actions/objectUpdater';
import { getDealData } from 'redux/actions/processes/getDealData';
import { useTypedSelector } from 'redux/reducers';

import { createNotification } from 'react-redux-notify';
import { successNotif, warningNotif } from 'components/Notifications';

import { Alert, AlertTitle } from '@material-ui/lab';
import InlineTextField from 'components/Fields/InlineTextField';
import {
  handleObjectKeys,
  IAddressApiFullAddressTitles
} from 'components/Address/functions';
import { CircularProgress } from '@material-ui/core';

import {
  FieldDefinition,
  FieldDefinitionDict,
  ObjectDefinition,
  CompleteProcessStepDefinition,
  ObjectInstance,
  CompleteObjectInstance,
  FieldInstance
} from 'types/interfaces';
import { objectType } from '../Forms/FormContent';
import { getLayoutSpacing } from '../helper';
import { XpansionIsolated } from 'common/Xpansion';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DeleteObjectButton from '../components/DeleteObjectButton';
import AutocompleteAddress from 'components/Address/components';
import { IAddressApiFullAddress, IAdminPanelAddress } from 'types/address';
import GoogleMapContainer from 'common/Maps';
import FieldStatusIcon from '../SingleObject/components/FieldStatusIcon';
import { FieldDefinitionTitle } from '../components/FieldDefinitionTitle';
import { useGoogleMapsAPI } from 'hooks/useGoogleMapsAPI';
import { globalIds } from 'helpers/globalIdConfig';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { StepperContext } from 'components/Stepper/context';
import isPreviewMode from '../helper/previewMode';

interface IProps {
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ObjectDefinition: ObjectDefinition;
  stepdefdict: CompleteProcessStepDefinition;
  setObjectData: Dispatch<SetStateAction<unknown[]>>;
  setCurrentObjectType: Dispatch<SetStateAction<objectType>>;
  currentObjectType: objectType;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      margin: '8px 0px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start'
    },
    leverCol: {
      width: '50%'
    },
    otherCol: {
      paddingLeft: theme.spacing(2),
      width: '50%'
    },
    hr: {
      borderRadius: 10,
      background: theme.palette.grey['A100'],
      margin: 5
    },
    inlinetext: {
      color: theme.palette.secondary.main,
      display: 'flex'
    },
    paper: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`,
      width: '100%'
    }
  })
);

const CustomAddressObject = (props: IProps) => {
  const {
    ObjectDefinition,
    stepdefdict,
    FieldDefinitionDict,
    FieldDefinitionList
  } = props;
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const UserInstance = useTypedSelector((state) => state.user.user);

  const { currentDeal, currentStepId, landingpage } = useProcess();
  const [CompleteObjectInstanceList, setCompleteObjectInstanceList] =
    React.useState<CompleteObjectInstance[]>([]);

  const [refreshingDeal, setRefreshingDeal] = React.useState(false);

  const { ProcessInstance } = currentDeal;
  const ProcessInstanceId = ProcessInstance?.Id;

  const canAddMore =
    ObjectDefinition.ObjectRepeat === 0
      ? true
      : CompleteObjectInstanceList.length < ObjectDefinition.ObjectRepeat;

  React.useEffect(() => {
    const NewObjectInstances: CompleteObjectInstance[] | undefined =
      Object.values(currentDeal.CompleteObjectInstanceDict).filter(
        (CompleteObjectInstance: CompleteObjectInstance): boolean =>
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
          ObjectDefinition.Id
      );

    if (NewObjectInstances) setCompleteObjectInstanceList(NewObjectInstances);
  }, [ObjectDefinition, currentDeal.CompleteObjectInstanceDict]);

  let readOnly;
  if (
    props.ObjectDefinition.Id !==
    globalIds.customer.addresses.AddressObjectDefinition
  ) {
    readOnly = ReadOnlyRedflag({
      ObjectDefinition: props.ObjectDefinition
    });
  } else readOnly = true;

  const [localRepeatDynamicObjectData, setLocalRepeatDynamicObjectData] =
    useState<unknown[]>([]);

  const { deleteObject } = useObject({
    ObjectDefinition,
    UserInstance,
    ProcessInstanceId,
    Fields: FieldDefinitionList,
    ProcessStepSelectionType:
      stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType
  });

  const refreshDealData = async () => {
    setRefreshingDeal(true);
    const ProcessDefinitionId = currentDeal.ProcessInstance.ProcessDefinitionId;
    const res = await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId: currentStepId,
      ProcessDefinitionId
    });

    if (res) setRefreshingDeal(false);
  };

  const deleteObjectAndRefresh: (Id: number) => Promise<any> = async (Id) => {
    const deleted = await deleteObject(Id);
    return deleted && refreshDealData();
  };

  //* isPreview will only work if the magic link has been injected with preview
  const isPreview = isPreviewMode(window.location.href, landingpage);
  const dealCondition = !isDealClosed || !isDealTransferred;
  return (
    <div>
      <>
        {CompleteObjectInstanceList.length === 0 ? (
          <Alert severity="warning">
            {dealCondition ? (
              <>
                <AlertTitle>No {ObjectDefinition.Title} added</AlertTitle>
                <Typography variant="h6" color="textSecondary">
                  Please note that this deal is{' '}
                  {isDealTransferred ? 'transferred' : 'closed'}. You cannot add{' '}
                  {ObjectDefinition.Title}.
                </Typography>
              </>
            ) : isPreview ? (
              <>
                <AlertTitle>No {props.ObjectDefinition.Title} Found</AlertTitle>
                <Typography variant="h6" color="textSecondary">
                  Please note that this Landing Page is in Preview mode. You
                  cannot add {props.ObjectDefinition.Title} at this time.
                </Typography>
              </>
            ) : (
              <>
                <AlertTitle>No Address Found</AlertTitle>
                <Typography variant="h6" color="textSecondary">
                  Please add a new address to continue with the process.
                </Typography>
              </>
            )}
          </Alert>
        ) : (
          <div>
            {CompleteObjectInstanceList.map(
              (
                CompleteObjectInstance: CompleteObjectInstance,
                localIndex: number
              ) => (
                <ObjectInstantiation
                  key={CompleteObjectInstance.ObjectInstance.Id}
                  CompleteObjectInstance={CompleteObjectInstance}
                  idx={CompleteObjectInstance.ObjectInstance.Id}
                  FieldDefinitionDict={FieldDefinitionDict}
                  FieldDefinitionList={FieldDefinitionList}
                  props={props}
                  readOnly={readOnly}
                  deleteObjectAndRefresh={deleteObjectAndRefresh}
                  localIndex={localIndex}
                  refreshDealData={refreshDealData}
                  setLocalRepeatDynamicObjectData={
                    setLocalRepeatDynamicObjectData
                  }
                />
              )
            )}
          </div>
        )}
      </>

      <br />

      {canAddMore && UserInstance && !dealCondition && !isPreview && (
        <AddFieldAndObjectButton
          Fields={Object.values(FieldDefinitionDict)}
          ObjectDefinition={ObjectDefinition}
          ProcessInstance={currentDeal.ProcessInstance}
          ProcessInstanceId={currentDeal.ProcessInstance.Id}
          ProcessStepSelectionType={
            stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
          }
          UserInstance={UserInstance}
          label={`New ${ObjectDefinition.Title}`}
        />
      )}
    </div>
  );
};

export default CustomAddressObject;

const ObjectInstantiation = ({
  CompleteObjectInstance,
  FieldDefinitionDict,
  FieldDefinitionList,
  idx,
  props,
  readOnly,
  deleteObjectAndRefresh,
  localIndex,
  refreshDealData,
  setLocalRepeatDynamicObjectData
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  idx: number;
  props: IProps;
  readOnly: boolean;
  deleteObjectAndRefresh: (Id: number) => Promise<any>;
  localIndex: number;
  refreshDealData: () => void;
  setLocalRepeatDynamicObjectData: Dispatch<SetStateAction<unknown[]>>;
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();
  const { token, currentDeal } = useProcess();
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<IAdminPanelAddress | any>({});
  const [localReadOnly, setLocalReadOnly] = React.useState<boolean>(false);

  const [warning, setWarning] = React.useState<boolean>(false);

  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  const UserInstance = useTypedSelector((state) => state.user.user);
  const addressData = {};

  let fieldsToShow: FieldDefinition[] = HideShowFields({
    FieldDefinitionList: props.FieldDefinitionList,
    CompleteObjectInstance
  }).sort(
    (a: FieldDefinition, b: FieldDefinition) => a.ItemOrder - b.ItemOrder
  );

  const handleDatabaseData = () => {
    setLoading(true);
    FieldDefinitionList.forEach((FieldDefinition: FieldDefinition) => {
      const FieldInstance: FieldInstance | undefined = FieldInstanceList.find(
        (FieldInstance: FieldInstance) =>
          FieldInstance.FieldDefinitionId === FieldDefinition.Id
      );

      if (FieldInstance) {
        const newTitle = FieldDefinition.Title.replace(
          /\s+/g,
          '_'
        ).toLowerCase();

        if (newTitle.includes('location')) {
          if (FieldInstance.FieldValue !== '') {
            const JSONLocation = JSON.parse(FieldInstance.FieldValue);
            addressData[newTitle] = JSONLocation;
          }
        } else {
          addressData[newTitle] = FieldInstance.FieldValue;
        }
      }
    });

    setAddress(addressData);
  };

  useEffect(() => {
    let perData: {}[] = [];
    fieldsToShow.forEach((FieldDefinition: FieldDefinition) => {
      FieldInstanceList.forEach((FieldInstance: FieldInstance, idx: number) => {
        if (FieldInstance.FieldDefinitionId === FieldDefinition.Id) {
          perData.push({
            [FieldDefinition.Title]: FieldInstance?.FieldValue
          });
        }
      });
    });

    setLocalRepeatDynamicObjectData((prevState) => [...prevState, perData]);
  }, []);

  const handleSelectObject = async () => {
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
    const requiredFields: FieldDefinition[] = fieldsToShow.filter(
      (FieldDefinition) => FieldDefinition.FieldRequired
    );
    const numberOfRequiredFieldToDo: FieldDefinition[] = requiredFields.filter(
      (requiredField: FieldDefinition) =>
        FieldInstanceList.find(
          (FieldInstance: FieldInstance) =>
            FieldInstance.FieldDefinitionId === requiredField.Id &&
            FieldInstance.FieldValue === ''
        )
    );

    if (numberOfRequiredFieldToDo.length === 0) {
      selectObjectInstance({
        token,
        data: CompleteObjectInstance,
        props: { ProcessInstanceId: currentDeal.ProcessInstance.Id },
        action: 'SELECT'
      });
    } else {
      dispatch(
        createNotification(
          warningNotif(
            'This object cannot be selected because all its required fields have not been filled in'
          )
        )
      );
    }
  };

  const useFieldInstanceDictValues = (FieldInstance: FieldInstance[]) => {
    const [fieldValuesSignature, setFieldValuesSignature] = React.useState('');

    React.useEffect(() => {
      const signature = FieldInstanceList.map(
        (element) => (element as FieldInstance).FieldValue
      ).join(',');

      setFieldValuesSignature(signature);
    }, [FieldInstance]);

    return fieldValuesSignature;
  };

  const fieldValuesSignature = useFieldInstanceDictValues(FieldInstanceList);
  React.useEffect(() => {
    handleDatabaseData();
    setLoading(false);
  }, [fieldValuesSignature]);

  const WrappedGoogleMapContainer = useGoogleMapsAPI(GoogleMapContainer);
  const isFieldInIAddressApiFullAddress = (title: string) => {
    return Object.values(IAddressApiFullAddressTitles).includes(title);
  };

  React.useEffect(() => {
    const allFieldsCompleted =
      address && Object.values(address).every((value) => value !== '');

    if (!allFieldsCompleted) {
      setLocalReadOnly(true);
    } else {
      setLocalReadOnly(false);
    }
  }, [address]);

  return (
    <XpansionIsolated
      key={idx}
      summary={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'grey',
            width: '100%'
          }}>
          {[3565].includes(props.ObjectDefinition.Id) ? (
            <div style={{ flexGrow: 1 }}>
              {props.ObjectDefinition.Title} (
              {CompleteObjectInstance.ObjectInstance.Id})
            </div>
          ) : (
            <div style={{ flexGrow: 1 }}>
              {props.ObjectDefinition.Title} : {localIndex + 1}
            </div>
          )}

          {isDealClosed || isDealTransferred ? (
            <Alert severity="info">
              <Typography variant="h6">Read Only</Typography>
            </Alert>
          ) : (
            <>
              <Tooltip title="Select Object">
                <IconButton
                  onClick={(e) => {
                    handleSelectObject();
                    e.stopPropagation();
                  }}>
                  {CompleteObjectInstance.ObjectInstance.Selected ? (
                    <StarIcon style={{ color: theme.palette.success.main }} />
                  ) : (
                    <StarBorderIcon />
                  )}
                </IconButton>
              </Tooltip>

              {localReadOnly && (
                <DeleteObjectButton
                  object={CompleteObjectInstance}
                  deleteObject={deleteObjectAndRefresh}
                  title={CompleteObjectInstance.ObjectInstance.Title}
                />
              )}
            </>
          )}
        </div>
      }>
      <Grid container direction="column">
        {!readOnly && (
          <AutocompleteAddress
            props={{
              isIntegration: true,
              refreshDealData: refreshDealData,
              ProcessInstance: currentDeal.ProcessInstance,
              ProcessInstanceId: currentDeal.ProcessInstance.Id,
              FieldDefinitionDict: FieldDefinitionDict,
              CompleteObjectInstance: CompleteObjectInstance,
              FieldDefinitionList: FieldDefinitionList
            }}
          />
        )}

        {address && (
          <div>
            {!loading && address?.location && (
              <Grid item style={{ padding: theme.spacing(2) }}>
                <WrappedGoogleMapContainer
                  {...(readOnly
                    ? { redflagLocation: address }
                    : { position: address })}
                  setWarning={readOnly ? setWarning : undefined}
                />
              </Grid>
            )}

            {readOnly && !localReadOnly && (
              <Grid item>
                {Object.values(address).map((element: any, index: number) => {
                  const keys = handleObjectKeys({ index, address });

                  if (element === 'null') return <div />;
                  if (typeof element !== 'string') return <div />;
                  if (element === '' || !element) return <div />;

                  return (
                    <div className={classes.paper} key={index}>
                      <Typography>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center">
                          <Grid item>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {keys}
                            </Typography>{' '}
                          </Grid>
                          <Grid item>: {element}</Grid>
                        </Grid>
                      </Typography>
                    </div>
                  );
                })}
              </Grid>
            )}
          </div>
        )}
        <Grid item>
          {localReadOnly && (
            <div>
              {fieldsToShow
                .filter((FieldDefinition: FieldDefinition) => {
                  return !isFieldInIAddressApiFullAddress(
                    FieldDefinition.Title
                  );
                })
                .map((FieldDefinition: FieldDefinition, idx: number) => {
                  return (
                    <Grid item key={FieldDefinition.Id}>
                      {FieldInstanceList.map((FieldInstance: FieldInstance) => {
                        if (
                          FieldInstance.FieldDefinitionId === FieldDefinition.Id
                        ) {
                          return (
                            <div
                              className={classes.paper}
                              key={FieldInstance.Id}>
                              <div style={{ display: 'flex' }}>
                                <FieldStatusIcon
                                  FieldDefinition={FieldDefinition}
                                  FieldInstance={FieldInstance}
                                  Required={FieldDefinition.FieldRequired}
                                />

                                <FieldDefinitionTitle
                                  title={FieldDefinition.Title}
                                  height="h6"
                                />
                              </div>
                              <div className={classes.inlinetext}>
                                <div style={{ visibility: 'hidden' }}>
                                  <FieldStatusIcon
                                    FieldDefinition={FieldDefinition}
                                    FieldInstance={FieldInstance}
                                    Required={FieldDefinition.FieldRequired}
                                  />
                                </div>
                                <InlineTextField
                                  FieldDefinition={FieldDefinition}
                                  FieldInstance={FieldInstance}
                                  ObjectDefinition={props.ObjectDefinition}
                                  ObjectInstance={
                                    CompleteObjectInstance &&
                                    CompleteObjectInstance.ObjectInstance
                                  }
                                  ProcessInstance={currentDeal.ProcessInstance}
                                  ProcessStepSelectionType={
                                    props.stepdefdict.ProcessStepDefinition
                                      .ProcessStepSelectionType
                                  }
                                  UserInstance={UserInstance}
                                  fields={FieldDefinitionList}
                                  type={FieldDefinition.FieldType}
                                  value={FieldInstance?.FieldValue}
                                />
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </Grid>
                  );
                })}
            </div>
          )}
        </Grid>
      </Grid>
    </XpansionIsolated>
  );
};
