import {
  Avatar,
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { FileAttachment, Message } from '@microsoft/microsoft-graph-types';
import Time from 'common/Time';
import {
  IConversation,
  IMicrosoftMessages
} from 'components/MessageHub/interfaces';
import { HoverCard } from 'components/MessageHub/utils';
import { theme } from 'theme';

export const CustomThreadHover = ({
  key,
  conversation,
  selectedConversation,
  content,
  handleOpen,
  handleAdd,
  messages
}: {
  key: number;
  conversation: Message;
  selectedConversation?: IConversation;
  content: React.ReactNode;
  messages: IMicrosoftMessages;

  handleOpen?: (conversationId: string) => Promise<void>;
  handleAdd?: () => void;
}) => (
  <HoverCard
    key={key}
    messages={messages}
    handleOpen={handleOpen}
    selectedConversation={selectedConversation}
    handleAdd={handleAdd}
    conversation={conversation}>
    {content}
  </HoverCard>
);

export const CustomDraft = ({
  backgroundColor,
  senderName,
  subject,
  receivedDateTime,
  isHovered,
  handleDelete
}: {
  backgroundColor: string;
  senderName: string;
  subject: string;
  receivedDateTime: string;
  isHovered: boolean;
  handleDelete: () => Promise<void>;
}) => (
  <CardContent>
    <Grid container spacing={2}>
      <Grid
        item
        xs={1}
        style={{
          borderRight: '1px solid #ccc',
          display: 'flex',
          justifyContent: 'center',
          paddingTop: 20
        }}>
        <Avatar style={{ backgroundColor }}>{senderName[0]}</Avatar>
      </Grid>
      <Grid item xs={10}>
        <Typography variant="h5" component="h2">
          {senderName}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 'none'
          }}>
          {subject}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          <Time time={receivedDateTime} type="date" />
        </Typography>
      </Grid>

      <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton
          style={{ visibility: isHovered ? 'visible' : 'hidden' }}
          onClick={async (e) => {
            e.stopPropagation();
            await handleDelete();
          }}>
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  </CardContent>
);

export const SkeletonArchive = ({
  idx,
  conversation
}: {
  idx: number;
  conversation?: IConversation;
}) => {
  return (
    <Card
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        margin: '10px 10px',
        height: 120
      }}>
      <div style={{ padding: theme.spacing(1) }}>
        <Grid container spacing={2} key={idx}>
          <Grid
            item
            xs={1}
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: 20
            }}>
            <Skeleton variant="circle" width={40} height={40} />
          </Grid>
          <Grid item xs={7}>
            <Skeleton variant="text" width="80%" height={20} />
            <Skeleton variant="text" width="60%" height={20} />
            <Skeleton variant="text" width="50%" height={20} />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
            <Skeleton
              variant="circle"
              width={20}
              height={20}
              style={{ margin: 5 }}
            />
            <Skeleton
              variant="circle"
              width={20}
              height={20}
              style={{ margin: 5 }}
            />
            <Skeleton
              variant="circle"
              width={20}
              height={20}
              style={{ margin: 5 }}
            />
          </Grid>

          {conversation && (
            <>
              {Object.values(conversation.totalAttachments).length > 0 && (
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}>
                  {Object.values(conversation.totalAttachments)
                    .slice(0, 4)
                    .map((_: FileAttachment, index: number) => (
                      <Grid
                        key={index}
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          margin: 5
                        }}>
                        <Skeleton variant="rect" width={40} height={20} />
                      </Grid>
                    ))}
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
    </Card>
  );
};

export const SkeletonThread = ({
  amountToLoad
}: {
  amountToLoad: number | IConversation;
}) => (
  <>
    {Array.from(new Array(amountToLoad)).map((_, index) => (
      <Card
        key={index}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.05)',
          margin: '10px 10px',
          height: 120
        }}>
        <div style={{ padding: theme.spacing(1) }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 20
              }}>
              <Skeleton variant="circle" width={40} height={40} />
            </Grid>
            <Grid item xs={7}>
              <Skeleton variant="text" width="80%" height={20} />
              <Skeleton variant="text" width="60%" height={20} />
              <Skeleton variant="text" width="50%" height={20} />
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              <Skeleton
                variant="circle"
                width={20}
                height={20}
                style={{ margin: 5 }}
              />
              <Skeleton
                variant="circle"
                width={20}
                height={20}
                style={{ margin: 5 }}
              />
              <Skeleton
                variant="circle"
                width={20}
                height={20}
                style={{ margin: 5 }}
              />
            </Grid>
          </Grid>
        </div>
      </Card>
    ))}
  </>
);
