// Define the shape of each part of the state
export interface LegacyDealDetail {
  accepted_facility_accepted: null;
  accepted_facility_amount_to_finance: number;
  accepted_facility_apr_including_residual_value: number;
  accepted_facility_balloon_payment_without_tax: number;
  accepted_facility_cca_status: string;
  accepted_facility_commission_percent: number;
  accepted_facility_commission_without_tax: number;
  accepted_facility_credit_line_term: null;
  accepted_facility_deal_type_of_finance: null;
  accepted_facility_documentation_fee_before_tax: number;
  accepted_facility_documentation_fee_upsell: number;
  accepted_facility_funding_profile: string;
  accepted_facility_funding_rate: number;
  accepted_facility_introducer_fee: number;
  accepted_facility_lender_yield: number;
  accepted_facility_margin: number;
  accepted_facility_max_advance: null;
  accepted_facility_net_advance: number;
  accepted_facility_part_exchange_excluding_tax: number;
  accepted_facility_regulatory_status: string;
  accepted_facility_repayment_without_tax: number;
  accepted_facility_seasonal_payments: null;
  accepted_facility_settlement_excluding_tax: number;
  accepted_facility_type_of_finance: string;
  accepted_facility_type_of_product: string;
  accepted_facility_upfront_payment: number;
  asset1_category: null;
  asset1_condition: null;
  asset1_contact1_email_address: null;
  asset1_contact1_name: null;
  asset1_contact1_phone_number: null;
  asset1_make: null;
  asset1_model: null;
  asset1_name: null;
  asset1_non_taxable_price: null;
  asset1_price_per_unit: null;
  asset1_quantity: null;
  asset1_sub_total: null;
  asset1_supplier_name: null;
  asset2_category: null;
  asset2_condition: null;
  asset2_contact1_email_address: null;
  asset2_contact1_name: null;
  asset2_contact1_phone_number: null;
  asset2_make: null;
  asset2_model: null;
  asset2_name: null;
  asset2_non_taxable_price: null;
  asset2_price_per_unit: null;
  asset2_quantity: null;
  asset2_sub_total: null;
  asset2_supplier_name: null;
  asset3_category: null;
  asset3_condition: null;
  asset3_contact1_email_address: null;
  asset3_contact1_name: null;
  asset3_contact1_phone_number: null;
  asset3_make: null;
  asset3_model: null;
  asset3_name: null;
  asset3_non_taxable_price: null;
  asset3_price_per_unit: null;
  asset3_quantity: null;
  asset3_sub_total: null;
  asset3_supplier_name: null;
  borrower1_address: string;
  borrower1_contact1_email_address: string;
  borrower1_contact1_name: string;
  borrower1_contact1_phone_number: string;
  borrower1_contact2_email_address: string;
  borrower1_contact2_name: string;
  borrower1_contact2_phone_number: string;
  borrower1_contact3_email_address: null;
  borrower1_contact3_name: null;
  borrower1_contact3_phone_number: null;
  borrower1_name: string;
  borrower1_postal_code: string;
  borrower2_address: null;
  borrower2_contact1_email_address: null;
  borrower2_contact1_name: null;
  borrower2_contact1_phone_number: null;
  borrower2_contact2_email_address: null;
  borrower2_contact2_name: null;
  borrower2_contact2_phone_number: null;
  borrower2_contact3_email_address: null;
  borrower2_contact3_name: null;
  borrower2_contact3_phone_number: null;
  borrower2_name: null;
  borrower2_postal_code: null;
  borrower3_address: null;
  borrower3_contact1_email_address: null;
  borrower3_contact1_name: null;
  borrower3_contact1_phone_number: null;
  borrower3_contact2_email_address: null;
  borrower3_contact2_name: null;
  borrower3_contact2_phone_number: null;
  borrower3_contact3_email_address: null;
  borrower3_contact3_name: null;
  borrower3_contact3_phone_number: null;
  borrower3_name: null;
  borrower3_postal_code: null;
  credit_line_approved_funders: null;
  deal_archived: null;
  deal_brokerage_name: null;
  deal_created_at: string;
  deal_created_by_name: null;
  deal_current_status: string;
  deal_id: number;
  deal_inception_date: null;
  deal_kyc_by_funder: null;
  deal_owner_name: string;
  deal_paid_out_event_date: string;
  deal_payout_date: string;
  deal_source_of_lead: string;
  deal_termination_date: null;
  deal_type_of_finance: string;
  deal_updated_at: null;
  exported_on: string;
  guarantor1_contact1_email_address: null;
  guarantor1_contact1_name: null;
  guarantor1_contact1_phone_number: null;
  guarantor1_contact2_email_address: null;
  guarantor1_contact2_name: null;
  guarantor1_contact2_phone_number: null;
  guarantor1_contact3_email_address: null;
  guarantor1_contact3_name: null;
  guarantor1_contact3_phone_number: null;
  guarantor1_name: null;
  guarantor2_contact1_email_address: null;
  guarantor2_contact1_name: null;
  guarantor2_contact1_phone_number: null;
  guarantor2_contact2_email_address: null;
  guarantor2_contact2_name: null;
  guarantor2_contact2_phone_number: null;
  guarantor2_contact3_email_address: null;
  guarantor2_contact3_name: null;
  guarantor2_contact3_phone_number: null;
  guarantor2_name: null;
  guarantor3_contact1_email_address: null;
  guarantor3_contact1_name: null;
  guarantor3_contact1_phone_number: null;
  guarantor3_contact2_email_address: null;
  guarantor3_contact2_name: null;
  guarantor3_contact2_phone_number: null;
  guarantor3_contact3_email_address: null;
  guarantor3_contact3_name: null;
  guarantor3_contact3_phone_number: null;
  guarantor3_name: null;
  introducer1_contact1_email_address: null;
  introducer1_contact1_name: null;
  introducer1_contact1_phone_number: null;
  introducer1_contact2_email_address: null;
  introducer1_contact2_name: null;
  introducer1_contact2_phone_number: null;
  introducer1_contact3_email_address: null;
  introducer1_contact3_name: null;
  introducer1_contact3_phone_number: null;
  introducer1_name: null;
  introducer2_contact1_email_address: null;
  introducer2_contact1_name: null;
  introducer2_contact1_phone_number: null;
  introducer2_contact2_email_address: null;
  introducer2_contact2_name: null;
  introducer2_contact2_phone_number: null;
  introducer2_contact3_email_address: null;
  introducer2_contact3_name: null;
  introducer2_contact3_phone_number: null;
  introducer2_name: null;
  introducer3_contact1_email_address: null;
  introducer3_contact1_name: null;
  introducer3_contact1_phone_number: null;
  introducer3_contact2_email_address: null;
  introducer3_contact2_name: null;
  introducer3_contact2_phone_number: null;
  introducer3_contact3_email_address: null;
  introducer3_contact3_name: null;
  introducer3_contact3_phone_number: null;
  introducer3_name: null;
  proposal_accepted_funders: string;
  proposal_declined_funders: null;
  proposal_paid_out_funders: string;
  proposal_sent_funders: string;
}

export interface LegacyDealActivity {
  ID: string;
  'Deal Id': string;
  'Activity Type': string;
  'Activity Date': string;
  'Activity Time': string;
  'Activity User': string;
  'Activity Description': string;
}

export interface LegacyDealDocument {
  ID: string;
  'Deal Id': string;
  'Document Name': string;
}

// Define the shape of the state
interface LegacyDealState {
  legacyDealDetail: LegacyDealDetail;
  legacyDealActivity: LegacyDealActivity[];
  legacyDealDocumentList: LegacyDealDocument[];
  legacyBool: boolean;
}

// Define action types
interface SetLegacyDealDetailAction {
  type: 'SET_LEGACY_DEAL_DETAIL';
  payload: LegacyDealDetail;
}

interface SetLegacyDealActivityAction {
  type: 'SET_LEGACY_DEAL_ACTIVITY';
  payload: LegacyDealActivity[];
}

interface SetLegacyDealDocumentListAction {
  type: 'SET_LEGACY_DEAL_DOCUMENTLIST';
  payload: LegacyDealDocument[];
}

interface SetLegacyDealBoolAction {
  type: 'SET_LEGACY_DEAL_BOOL';
  payload: boolean;
}

type LegacyDealAction =
  | SetLegacyDealDetailAction
  | SetLegacyDealActivityAction
  | SetLegacyDealDocumentListAction
  | SetLegacyDealBoolAction;

// Initial state
const INITIAL_STATE: LegacyDealState = {
  legacyDealDetail: {} as LegacyDealDetail,
  legacyDealActivity: [],
  legacyDealDocumentList: [],
  legacyBool: false
};

// Reducer
export default (
  state: LegacyDealState = INITIAL_STATE,
  action: LegacyDealAction
): LegacyDealState => {
  switch (action.type) {
    case 'SET_LEGACY_DEAL_DETAIL': {
      return { ...state, legacyDealDetail: action.payload };
    }
    case 'SET_LEGACY_DEAL_ACTIVITY': {
      return { ...state, legacyDealActivity: action.payload };
    }
    case 'SET_LEGACY_DEAL_DOCUMENTLIST': {
      return { ...state, legacyDealDocumentList: action.payload };
    }
    case 'SET_LEGACY_DEAL_BOOL': {
      return { ...state, legacyBool: action.payload };
    }
    default:
      return state;
  }
};
