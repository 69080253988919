import { useState } from 'react';
import { tableIcons } from '../Utils/tableIcons';
import { useTheme } from '@material-ui/core/styles';
import MaterialTable from '@material-table/core';
import moment from 'moment';
import { Currency } from '../Utils/displayFields';
import { CashFlowItem } from 'types/calculatorInterfaces';
import { LinearProgress } from '@material-ui/core';
import { ExportCsv, ExportPdf } from '@material-table/exporters';
import * as gtag from 'Utils/gtag';

interface Props {
  data: CashFlowItem[];
  title: string;
}

export const Cashflow = (props: Props) => {
  const { data, title } = props;
  const theme = useTheme();
  const [state] = useState({
    columns: [
      {
        title: 'ActivityDate',
        field: 'ActivityDate',
        render: (row) => {
          const momentformat = moment(row.ActivityDate).format('Do MMM YYYY');
          return momentformat;
        }
      },
      {
        title: 'Balance',
        field: 'Balance',
        render: (row) => <Currency value={row.Balance} />
      },
      {
        title: 'Cost',
        field: 'Cost',
        render: (row) => <Currency value={row.Cost} />
      },
      { title: 'Description', field: 'Description' },
      {
        title: 'Interest',
        field: 'Interest',
        render: (row) => <Currency value={row.Interest} />
      },
      // {
      //   title: 'Commission',
      //   field: 'Commission',
      //   render: (row) => <Currency value={row.Commission} />
      // },
      {
        title: 'receipt',
        field: 'Receipt',
        render: (row) => <Currency value={row.Receipt} />
      }
    ]
  });

  if (!data || data.length < 1) return <LinearProgress />;

  return (
    <MaterialTable
      columns={state.columns}
      data={data}
      icons={tableIcons}
      options={{
        search: false,
        headerStyle: {
          backgroundColor: theme.palette.primary.main,
          color: '#FFF',
          height: 25
        },
        pageSize: data.length,
        paginationType: 'stepped',
        exportMenu: [
          {
            label: 'Export PDF',
            exportFunc: (cols, datas) => {
              ExportPdf(cols, datas, `Quotation ${new Date().toISOString()}`);
              gtag.event({
                action: 'Export Cashflow PDF',
                feature: 'CALCULATOR',
                message: 'user downloaded a quote cashflow as a pdf'
              });
            }
          },
          {
            label: 'Export CSV',
            exportFunc: (cols, datas) => {
              ExportCsv(cols, datas, `Quotation ${new Date().toISOString()}`);
              gtag.event({
                action: 'Export Cashflow CSV',
                feature: 'CALCULATOR',
                message: 'user downloaded a quote cashflow as a csv'
              });
            }
          }
        ]
      }}
      title={title}
    />
  );
};
