import { useRef } from 'react';
import { pink, blue, green, purple, orange } from '@material-ui/core/colors';

export const useSenderColors = () => {
  const senderColorsRef = useRef({});

  const getSenderColor = (sender) => {
    if (!senderColorsRef.current[sender]) {
      const colors = [
        pink[100],
        blue[100],
        green[100],
        purple[100],
        orange[100]
      ];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];

      senderColorsRef.current = {
        ...senderColorsRef.current,
        [sender]: randomColor
      };
      return randomColor;
    }

    return senderColorsRef.current[sender];
  };

  return getSenderColor;
};
