import { useEffect, useMemo } from 'react';
import {
  Box,
  CircularProgress,
  makeStyles,
  styled,
  Tab,
  Tabs,
  Tooltip,
  useTheme
} from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import { CompleteObjectDefinition } from 'types/interfaces';
import GroupedObjectMenu from './ObjectLayout/GroupedObjectMenu';
import InheritanceMenu, { ButtonMenu } from './ObjectLayout/InheritanceMenu';
import LockIcon from '@material-ui/icons/Lock';
import { Label } from './Label';
import { useForm } from 'hooks/useForm';
import { useMobile } from 'hooks';
import { getFieldValueWithFieldDefinitionId } from '../../../../functions/getFieldValueWithFieldDefinitionId';
import { isHidden } from './helpers';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme) => ({
  tabs: { borderRight: `1px solid ${theme.palette.divider}`, width: '100%' },
  mobileTabs: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: theme.shape.borderRadius,
    boxShadow:
      '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)'
  },

  label: {
    alignSelf: 'flex-start',
    display: 'flex',
    textAlign: 'left',
    width: '100%'
  }
}));

export const TabPanel = (props: {
  isReadOnly: () => boolean;
  children: any;
  index: number;
}) => {
  const { isReadOnly, children, index, ...other } = props;
  const theme = useTheme();

  return (
    <div
      aria-labelledby={`vertical-tab-${index}`}
      id={`vertical-tabpanel-${index}`}
      role="tabpanel"
      {...other}>
      <Box style={{ marginLeft: theme.spacing(2) }}>{children}</Box>
    </div>
  );
};

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({});

const StyledTab = styled((props) => {
  return <Tab {...props} />;
})({});

export const FormTabs = ({ view }: { view?: 'landingPage' | 'system' }) => {
  let { landingpage } = useProcess();

  if (landingpage) {
    switch (view) {
      case 'system':
        return <SystemUserTabs />;
      default:
        return <DropdownFormTabs />;
    }
  }

  return <SystemUserTabs />;
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
};

const DropdownFormTabs = () => {
  const { groups, handleValueChange, noredflag, redflagObjects, value } =
    useForm();

  let groupsMerged = [] as CompleteObjectDefinition[];
  for (const key in groups) {
    const group: CompleteObjectDefinition[] = groups[key];
    group.forEach((group) => groupsMerged.push(group));
  }

  const allObjects = useMemo(() => {
    return [...groupsMerged, ...noredflag, ...redflagObjects];
  }, [groupsMerged, noredflag, redflagObjects]);

  const IndicativeQuoteObjectDefinitionId = 3565;
  const checkToSeeUnwantedObjects = allObjects.filter(
    (element: CompleteObjectDefinition) =>
      element.ObjectDefinition.Id !== IndicativeQuoteObjectDefinitionId
  );

  const greaterThanOneObject = checkToSeeUnwantedObjects.length >= 1;
  if (greaterThanOneObject) {
    return greaterThanOneObject ? (
      <ButtonMenu
        allObjects={checkToSeeUnwantedObjects}
        handleValueChange={handleValueChange}
        value={value}
      />
    ) : null;
  } else return <></>;
};

const SystemUserTabs = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const { entityType, regulatedStatus } = useTypedSelector((s) => s.process);

  let { sorted, stepdefdict, currentDeal, currentProcess, landingpage } =
    useProcess();
  const { groups, handleValueChange, noredflag, redflagObjects, value } =
    useForm();

  const handleTabSelect = (Id: number) => handleValueChange(Id);
  const { Id } = currentProcess.ProcessDefinition;

  if (sorted.length > 0) {
    return (
      <StyledTabs
        className={isMobile ? classes.mobileTabs : classes.tabs}
        orientation={'vertical'}
        textColor="primary"
        variant="scrollable">
        {/* PARTIES & CONTACTS */}
        {noredflag
          .filter((item: CompleteObjectDefinition) =>
            'Parties & Contacts'.includes(item.ObjectDefinition.Title)
          )
          .map((comObDef: CompleteObjectDefinition) => {
            const { Id } = comObDef.ObjectDefinition;
            return (
              <StyledTab
                onClick={() => handleTabSelect(Id)}
                key={Id}
                label={<Label comObDef={comObDef} value={value} />}
                {...a11yProps(Id)}
              />
            );
          })}

        {redflagObjects.length > 0 && landingpage && (
          <InheritanceMenu
            CompleteObjectDefinitionList={redflagObjects}
            title={'Borrower'}
            handleValueChange={handleTabSelect}
            value={value}
            isRedflag
          />
        )}

        {redflagObjects.length > 0 && !landingpage && (
          <StyledTab
            label={
              <div className={classes.label}>
                <Tooltip title="Data from companies house is not editable">
                  <LockIcon style={{ marginRight: 10, fontSize: 20 }} />
                </Tooltip>
                <GroupedObjectMenu
                  CompleteObjectDefinitionList={redflagObjects}
                  title="Borrower"
                  handleValueChange={handleTabSelect}
                  value={value}
                />
              </div>
            }
          />
        )}

        {Object.keys(groups).map((group_name: string) => {
          let CompleteObjectDefinitionList = groups[group_name];

          const PropertyFinanceId = 494;

          if (entityType !== 'Partnership (4+)') {
            const mergedNonReg = isMergedNonRegulatedDeal(
              CompleteObjectDefinitionList,
              regulatedStatus
            );

            if (mergedNonReg) return <div />;
          }

          // const RegulatedDealFlowId = 532;
          // const FFFDeals = [PropertyFinanceId, RegulatedDealFlowId];
          if (Id === PropertyFinanceId && group_name === 'Fact Find Form') {
            const val = getFieldValueWithFieldDefinitionId({
              stepdefdict,
              currentDeal,
              FieldDefinitionId: 22743
            });

            if (!val || val.length < 1) {
              CompleteObjectDefinitionList = groups[group_name].filter(
                (el: CompleteObjectDefinition) =>
                  el.ObjectDefinition.Id === 3622
              );
            }
          }

          if (landingpage) {
            return (
              <div className={classes.label}>
                <InheritanceMenu
                  CompleteObjectDefinitionList={CompleteObjectDefinitionList}
                  title={group_name}
                  handleValueChange={handleTabSelect}
                  value={value}
                />
              </div>
            );
          }

          return (
            <StyledTab
              key={group_name}
              label={
                <div data-cy="group-object-menu" className={classes.label}>
                  <GroupedObjectMenu
                    CompleteObjectDefinitionList={CompleteObjectDefinitionList}
                    title={group_name}
                    handleValueChange={handleTabSelect}
                    value={value}
                  />
                </div>
              }
            />
          );
        })}

        {noredflag
          .filter(
            (item: CompleteObjectDefinition) =>
              !'Parties & Contacts'.includes(item.ObjectDefinition.Title)
          )
          .map((comObDef: CompleteObjectDefinition) => {
            const { Id } = comObDef.ObjectDefinition;

            const merged =
              comObDef.ObjectDefinition.ObjectDescription.includes('merge');

            const result = isHidden(comObDef, entityType);
            if (result || merged) return <div key={Id} />;

            return (
              <StyledTab
                onClick={() => handleTabSelect(Id)}
                key={Id}
                label={<Label comObDef={comObDef} value={value} />}
                {...a11yProps(Id)}
              />
            );
          })}
      </StyledTabs>
    );
  }

  return <CircularProgress />;
};

const isMergedNonRegulatedDeal = (
  CompleteObjectDefinitionList: CompleteObjectDefinition[],
  regulatedStatus: boolean | string
) => {
  const findMergedObjects = CompleteObjectDefinitionList.filter(
    (CompleteObjectDefinition: CompleteObjectDefinition) => {
      return CompleteObjectDefinition.ObjectDefinition.ObjectDescription.includes(
        'merge'
      );
    }
  );

  if (!regulatedStatus && findMergedObjects.length > 0) return true;
  return false;
};
