import { gql } from '@apollo/client';

export const GET_PROPOSALS_FOR_DEAL = gql`
  query GetPropsforDeal($dealId: String!) {
    GetPropsforDeal(dealId: $dealId) {
      uploadedFiles
      meta {
        _action
        _dealId
        _decision
        _lender
        _proposalId
        _status
        _targetObjectInstanceId
      }
      id
      proposal {
        ... on FleximizeProposal {
          is_test
          business_type_id
          company_name
          registration_number
          title_id
          first_name
          last_name
          phone
          email
          loan_amount
          term
          funding_urgency
          loan_purpose_id
          other_purpose
          internal_id
          account_score_customer
          notes
          privacy_policy_consent
          cra_checks_consent
          refer_consent
          company {
            vat_no
            monthly_sales
            date_of_creation
            trading_date
            same_address
            registered_address {
              abode_name_no
              street
              street2
              town
              county
              postcode
            }
            trading_address {
              abode_name_no
              street
              street2
              town
              county
              postcode
            }
            directors {
              title_id
              first_name
              last_name
              date_of_birth
              major_shareholder
              residential_type_id
              abode_name_no
              street
              street2
              town
              county
              postcode
              move_in_date
              previous_addresses {
                abode_name_no
                street
                street2
                town
                county
                postcode
                move_in_date
              }
            }
            shareholders {
              title_id
              first_name
              last_name
              date_of_birth
              major_shareholder
              residential_type_id
              abode_name_no
              street
              street2
              town
              county
              postcode
              move_in_date
            }
          }
        }
        ... on AldermoreProposal {
          createProposalRequest {
            context {
              introducerIdentifier
              submitterEmail
              proposalIdentifier
              clientFacetoFaceIndicator
              informationSourceCode
              userIdentifier
              ProposalCompletedDate
              proposalCreateDate
              proposalReferenceIdentifier
            }
            clientDetails {
              clientTypeCode
              existingClientIndicator
              existingClientReference
              consentToKYCIndicator
              participantDetails {
                roleDetail {
                  rolePlayerTypeCode
                }
                personalDetail {
                  birthCountry
                  middleName
                  surname
                  preferredName
                  title
                  firstName
                  birthTown
                  dateofBirth
                  maidenName
                  gender
                  nationality
                  nameTypeCode
                }
                contactDetails {
                  contactCustomerRelationship
                  contactRole
                  contactTelephoneNumber
                  contactMobileNumber
                  contactEmailAddress
                  preferredContactType
                }
              }
              businessDetails {
                clientBusinessRelationship {
                  directorIndicator
                  highNetWorthClientIndicator
                  businessActivitiesDescription
                }
                industryTypeCode
                industryCode
                businessName
                companiesHouseNumber
                businessTradingName
                establishedDate
                vatStatus
                vatCode
              }
              addressDetails {
                addressTypeCode
                poBoxNumber
                buildingName
                buildingNumber
                streetName
                locality
                town
                county
                postalCode
                country
                movedInDate
                movedOutDate
              }
            }
            productDetails {
              productTypeCode
              productSubTypeCode
            }
            assetDetails {
              assetPurposeType
              assetCategory
              assetType
              assetQuantity
              assetModel
              assetManufacturerDetails
              assetManufacturedYear
              assetDealerDetails
              assetConditionType
              assetNotesDetails
              assetCost
              assetCostTaxAmount
              assetResidualAmount
              assetsMaintenanceCostPerWeek
              fuelType
              assetRegistrationDate
              assetRegistrationNumber
              assetSerialNumber
              assetCAPId
              assetAge
              assetUsageDetails
              assetRating
              buyBackProviderType
              buyBackProviderName
              assetResidualPercentage
            }
            financialDetails {
              totalAmountexcludingTax
              totalAmountIncludingTax
              netAdvanceAmount
              valueaddedTax
              subsidyAmount
              taxDeferralPeriod
              depositAmount
              totalBalloonValue
              totalResidualValue
              depositPercentage
              subsidyPercentage
              totalTaxAmount
              fundingRatePercentage
              nominalMarginPercentage
              yieldPercentage
              partExchangeAmount
              fundVatIndicator
            }
            paymentDetails {
              paymentInAdvance
              totalNumberOfPayments
              paymentFrequencyType
              totalInitialPayments
            }
            notesDetails {
              brokerExternalNotes {
                type
              }
            }
          }
        }
        ... on CosneticsBadRequestResponse {
          type
          title
          status
          detail
          errors
        }
        ... on Investec_QuantumProposal {
          brokerId
          messageId
          operator
          submitDate
          client {
            id
            companyType
            statusConfirmed
            soleTrader {
              title
              forename
              surname
              tradingAs
              dateOfBirth
              proprietorAddress {
                id
              }
            }
            company {
              companyName
              tradingStyle
              contactTitle
              contactForename
              contactSurname
              companyRegNo
              noOfPartners
              noOfSalariedPartners
              partner {
                id
                title
                forename
                surname
                dateOfBirth
              }
              director {
                id
                title
                forename
                surname
                dateOfBirth
                searchPermission
              }
            }
            address {
              buildingName
              streetNumber
              streetName
              district
              townCity
              county
              postcode
              atAddressSince
            }
            contact {
              telephone
              facsimile
              email
            }
            sicCode
            natureOfBusiness
            vatNo
            dateEstablished
          }
          brokerRef
          summary
          scheme
          contractType
          currency
          startDate
          period
          equipType_LoanReason
          cost
          otherCosts {
            costType
            amount
          }
          profile
          deposit
          rental
          frequency
          paymentMethod
          invoiceChain
          otherInformation
          jointHirer {
            id
            title
            forename
            surname
            dateOfBirth
          }
          guarantor {
            id
            guarantorType
            guaranteeType
            title
            forename
            surname
            companyName
            dateOfBirth
            searchPermission
          }
          asset {
            equipmentType
            make
            model
            description
            serialRegNo
            otherSerialNo
            newUsed
            cost
            dateOfManufacture
            supplier {
              id
              companyName
            }
          }
        }
        ... on DLLProposal {
          externalReference
          financeProduct
          lessee {
            id
            legalEntity {
              id
            }
            preferredLocale
            uniqueIdentifier
            uniqueIdentifierType
            countryOfRegistration
            businessType
            businessActivity
            externalIdentifiers {
              type
              id
            }
            phoneNumbers {
              type
              areaCode
              number
            }
            email
            organization {
              name
              establishmentDate
              cityOfRegistration
            }
            individual {
              givenName
              familyName
              middleName
              birthDate
              placeOfBirth
              sex
            }
            address {
              street
              houseNumber
              city
              postalCode
              postTown
              region
              country
              buildingName
              email
              addressLine1
              addressLine2
              postCode
              date
              occupancy
            }
            invoiceAddress {
              street
              houseNumber
              city
              postalCode
              postTown
              region
              country
              buildingName
              email
              addressLine1
              addressLine2
              postCode
              date
              occupancy
            }
            previousAddress {
              street
              houseNumber
              city
              postalCode
              postTown
              region
              country
              buildingName
              email
              addressLine1
              addressLine2
              postCode
              date
              occupancy
            }
            contacts {
              name
              email
              homeAddressCity
              lastName
              homeAddressPostcode
              homeAddressLine1
              homeAddressLine2
              homeAddressLine3
              dateOfBirth
              firstName
              homeAddressCounty
            }
            bankDetails {
              accountNumber
              accountNumberType
              bankIdentifier
              bankIdentifierType
              paymentMethod
            }
            partners {
              givenName
              familyName
              middleName
              birthDate
              mobileNumber
            }
            directors {
              familyName
              middleName
              givenName
              birthDate
              nameFirst
              nameLast
              email
              typename
              dateOfBirth
              isGuarantor
              nationality
              contactNumber
              consentedToSearch
            }
          }
          proposals {
            description
            startDate
            financialProduct {
              id
              contractType
              vatFinancing
            }
            preferred
            calculation {
              financeInput {
                financeStructure {
                  numberOfMonths
                  numberOfPayments
                  delayPayment {
                    type
                    initialPause
                    initialPauseUnit
                    startDate
                  }
                  terminalPauseRegularPayments
                  paymentFrequency
                  paymentTiming
                  paymentMethod
                  delayVatPayment
                  finance {
                    financeAmount
                    initialPaymentMultiplier
                    initialPaymentAmount
                    balloon
                    netDeposit {
                      amount
                      downPayment
                      settlement
                      settlementContractType
                      tradeIn
                    }
                    payments {
                      custom {
                        paymentDate
                        type
                        amount
                        multiplier
                        percentage
                        percentageOf
                      }
                    }
                    rates {
                      effectiveCustomerRate
                      calculate
                      variableNominalInterestRate
                    }
                    paymentProfile {
                      type
                      id
                    }
                    commissions {
                      amount
                      id
                    }
                    subsidies {
                      assetModelIdentifier {
                        masterAssetTypeName
                        categoryName
                        typeName
                        brandName
                        modelName
                      }
                      amount
                      id
                      type
                    }
                  }
                }
                fees {
                  amount
                  id
                  description
                  name
                }
                insurances {
                  amount
                  insuranceTaxPercentage
                  id
                  description
                }
                maintenance {
                  amount
                  quantity
                  id
                  description
                }
                assets {
                  id
                  modelIdentifier {
                    masterAssetTypeName
                    categoryName
                    typeName
                    brandName
                    modelName
                  }
                  description
                  quantity
                  salePrice
                  listPrice
                  vatPercentage
                  vatAmount
                  condition
                  yearOfManufacturing
                  subLeased
                  meterRead
                  usageUnitsPerPeriod
                  usageEnvironment
                  residualValue {
                    value
                    id
                  }
                  repurchaseAmount {
                    amount
                    id
                  }
                  excessiveUsageLimit
                  excessiveUsagePenaltyAmount
                  insurances {
                    amount
                    insuranceTaxPercentage
                    replacement
                    excessAmount
                    id
                    description
                  }
                  instances {
                    serialNumber
                    chassisNumber
                    licenseNumber
                    ipAddress
                    location {
                      street
                      houseNumber
                      city
                      postalCode
                      postTown
                      region
                      country
                      buildingName
                      email
                      addressLine1
                      addressLine2
                      postCode
                      date
                      occupancy
                    }
                  }
                  vat
                  manufacturer
                  model
                  costExVat
                  isNewAsset
                  supplierId
                  balloonAmount
                  nonVatItemsAmount
                  yearOfManufacture
                }
              }
              financeCalculation {
                financeAmount
                financeDeviation
                netPresentValue
                futureValue
                rates {
                  nominalInterestRate
                  effectiveInterestRate
                  nominalCustomerRate
                  nominalCustomerRateIncludingFees
                  effectiveCustomerRate
                  effectiveCustomerRateIncludingFees
                  compoundingFrequency
                }
              }
              consolidatedPayments {
                numberOfPayments
                amount
                type
                startDate
              }
              commissions {
                amount
                id
              }
              subsidies {
                type
                amount
                id
                assetModelIdentifier {
                  masterAssetTypeName
                  categoryName
                  typeName
                  brandName
                  modelName
                }
              }
              payments {
                paymentDate
                balanceOutstanding
                rentalPayment {
                  amount
                  principal
                  interestAmount
                }
                vatPayment {
                  amount
                }
                fees {
                  amount
                  vatAmount
                  id
                }
                insurances {
                  amount
                  vatAmount
                  insuranceTaxAmount
                  id
                }
                maintenance {
                  amount
                  vatAmount
                  id
                }
              }
            }
          }
        }
        ... on CASProposal {
          notes
          assets {
            id
            modelIdentifier {
              masterAssetTypeName
              categoryName
              typeName
              brandName
              modelName
            }
            description
            quantity
            salePrice
            listPrice
            vatPercentage
            vatAmount
            condition
            yearOfManufacturing
            subLeased
            meterRead
            usageUnitsPerPeriod
            usageEnvironment
            residualValue {
              value
              id
            }
            repurchaseAmount {
              amount
              id
            }
            excessiveUsageLimit
            excessiveUsagePenaltyAmount
            fees {
              amount
              id
              description
              name
            }
            subsidies {
              type
              amount
              id
              assetModelIdentifier {
                masterAssetTypeName
                categoryName
                typeName
                brandName
                modelName
              }
            }
            insurances {
              amount
              insuranceTaxPercentage
              replacement
              excessAmount
              id
              description
            }
            maintenance {
              amount
              quantity
              id
              description
            }
            instances {
              serialNumber
              chassisNumber
              licenseNumber
              ipAddress
              location {
                street
                houseNumber
                city
                postalCode
                postTown
                region
                country
                buildingName
                email
                addressLine1
                addressLine2
                postCode
                date
                occupancy
              }
            }
            vat
            manufacturer
            model
            costExVat
            isNewAsset
            supplierId
            balloonAmount
            nonVatItemsAmount
            yearOfManufacture
          }
          remoteId
          sourceId
          directors {
            familyName
            middleName
            givenName
            birthDate
            nameFirst
            nameLast
            email
            address {
              street
              houseNumber
              city
              postalCode
              postTown
              region
              country
              buildingName
              email
              addressLine1
              addressLine2
              postCode
              date
              occupancy
            }
            typename
            dateOfBirth
            isGuarantor
            nationality
            contactNumber
            consentedToSearch
          }
          suppliers {
            id
            name
            type
            registrationNumber
          }
          typename
          background
          salesPerson {
            name
            email
            contactNumber
          }
          brokerReference
          customerCompany {
            name
            type
            contactNameFirst
            contactNameLast
            contactNumber
            contactEmail
            registrationNumber
            dateTrading
          }
          facility {
            term {
              frequency
              number_of_advance_installments
              number_of_periodic_installments
            }
            deposit
            commission
            lenderYield
            vatDeferral
            totalAdvance
            typeOfProduct
            balloonPayment
            repaymentAmount
          }
          guarantors {
            name
            type
            registrationNumber
            nameFirst
            nameLast
            email
            dateOfBirth
            nationality
            contactNumber
            consentedToSearch
          }
          rationaleForFinance
        }
        ... on PraeturaProposal {
          customer {
            website
            industry
            sic_code
            company_type
            trading_name
            trading_address {
              street
              city_or_town
              county_or_state
              country
              postcode
            }
            vat_information {
              vat_number
              is_registered_for_vat
            }
            trading_from_date
            registered_address {
              street
              city_or_town
              county_or_state
              country
              postcode
            }
            external_customer_id
            registered_company_name
            company_registration_number
            has_consented_to_credit_check
            has_consented_to_privacy_policy
          }
          loanApplication {
            urgency
            applicants {
              first_name
              middle_name
              last_name
              date_of_birth
              has_consented_to_privacy_policy
              has_consented_to_credit_check
              is_main_applicant
              address {
                street
                city_or_town
                county_or_state
                country
                postcode
              }
              company_role
              contact_details {
                mobile_phone_number
                other_phone_number
                email
              }
              guarantees {
                can_provide_personal_guarantee
              }
            }
            introducer {
              id
              external_id
              contact_details {
                email
                last_name
                first_name
                middle_name
                other_phone_number
                mobile_phone_number
              }
            }
            customer_id
            party_to_contact
            external_customer_id
            asset_finance_request {
              assets {
                manufacturer
                model
                category
                condition
                is_insured
                description
                vat_per_unit
                cost_per_unit
                serial_number
                number_of_units
                manufacture_date
                manufacture_year
                registration_number
              }
              currency
              duration {
                unit
                amount
              }
              is_refinance
              product_type
              balloon_amount
              vat_paid_up_front_amount
              deposit_or_initial_rental_amount
            }
            commercial_loan_request {
              currency
              amount
            }
            invoice_finance_request {
              annual_turnover
              currency
            }
            external_application_id
            detailed_funding_purpose
          }
        }
        ... on NorthridgeProposal {
          id
          username
          otheR1
          otheR2
          otheR3
          otheR4
          adS_REQUEST {
            loaN_PROPOSAL {
              control {
                dealerref
                repno
                agreementno
                cusT_ID1
                cusT_ID2
                cusT_ID3
                action
                replY_EMAIL
                notes {
                  noteline
                }
              }
              finance {
                noteline
                financetype
                templatelist
                invoiceprice
                settlementamount
                settlement
                term
                instalment
                flatrate
                ppp
                deposit
                directdeposit
                parT_EXCHANGE
                extras
                accfee
                residual
                optioN_FEE
                starT_DATE
                residuaL_DATE
                subsidY_AMT
                subsidY_RATE
                initiaL_RENTAL
                iniT_RENT_VAT
                advancE_PAYMTS
                remaininG_PAYMTS
                esT_ANNUAL_MILEAGE
                commission
                accfeE_SPREAD
                paY_FREQ
                breakmtH1
                breakmtH2
                ccA_EXEMPTION
                depositcash
                sourceofdeposit
                addonsubsidy
                addonloanamount
                addonpayment
                addonfirstpay
                totalsubsidy
                totalpayment
              }
              loaN_PURPOSE {
                vehicle {
                  merchcode
                  make
                  model
                  trim
                  reG_DATE
                  reG_NO
                  chassisno
                  enginE_SIZE
                  mileage
                  bodystyle
                  neW_USED
                  manuaL_AUTO
                  petroL_DIESEL
                  insurancecompany
                  policyno
                  capcode
                  commercial
                  vaT_QUALIFYING
                }
                nonvehicle {
                  merchcode
                  descriptioN1
                  descriptioN2
                  descriptioN3
                  serialno
                  netcost
                  age
                }
              }
              contracT_HIRE_DETAILS {
                admiN_PW
                contracT_HIRE
                contracT_HIRE_TEMPLATE
                roaD_FUND_LICENCE
                roaD_FUND_LICENCE_ANNUAL_NET_AMOUNT
                upfronT_ROAD_TAX_NET_AMOUNT
                totaL_ADDITIONAL_ROAD_TAX_NET_AMOUNT
                totaL_ADDITIONAL_ROAD_TAX_VAT_AMOUNT
                totaL_ADDITIONAL_ROAD_TAX_AMOUNT
                maintenance
                totaL_MAINTENANCE_NET_AMOUNT
                totaL_MAINTENANCE_VAT_AMOUNT
                totaL_MAINTENANCE_AMOUNT
                maintaineR_NAME
                speciaL_CONDITIONS
                relieF_VEHICLE
                relieF_VEHICLE_FREE_OF_CHARGE
                relieF_VEHICLE_CONDITIONS
                unfiT__FOR_CONTINUOUS_PERIOD
                basiC_ANNUAL_MILEAGE
                maximuM_MILEAGE_LIMIT
                firsT_EXCESS_MILEAGE
                firsT_EXCESS_MILEAGE_PENCE_PER_MILE
                seconD_EXCESS_MILEAGE
                seconD_EXCESS_MILEAGE_PENCE_PER_MILE
                thirD_EXCESS_MILEAGE
                thirD_EXCESS_MILEAGE_PENCE_PER_MILE
                additionaL_EXCESS_MILEAGE_PENCE_PER_MILE
                roaD_SIDE_ASSISTANCE
                roaD_SIDE_ASSISTANCE_AMOUNT
                roaD_SIDE_ASSISTANCE_PROVIDER
                extrA_FINANCE_EXPEND_AMT
                accidenT_MANAGEMENT
                accidenT_MANAGEMENT_PROVIDER
              }
              hireR_DETAILS {
                address {
                  facetoface
                  title
                  forename
                  secondname
                  surname
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mnths
                  telephone
                  mobile
                  email
                }
                prevaddr {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                prevaddR2 {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                prevaddR3 {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                prevaddR4 {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                personal {
                  dateofbirth
                  gender
                  marital
                  dependants
                  residential
                  driverlicence
                  mothersmaidenname
                  countryofbirth
                  nationality
                }
                occupation {
                  occupation
                  occupationcode
                  status
                  employer
                  addresS1
                  addresS2
                  addresS3
                  employertelephone
                  yrs
                  mths
                }
                prevoccupation {
                  occupation
                  occupationcode
                  status
                  employer
                  addresS1
                  addresS2
                  addresS3
                  employertelephone
                  yrs
                  mths
                }
                bank {
                  sortcode
                  accountname
                  accountno
                  bankname
                  street
                  locality
                  town
                  yrs
                  mths
                  chequE_CARD
                  visA_CARD
                  mastercard
                  amex
                  other
                  payementmethod
                  payfundsby
                }
                income {
                  netmonthly
                  netweekly
                  grossannual
                  changeindicator
                  other
                  salarymandated
                  salarydate
                  mortgagemth
                  mortgagebalance
                  housevalue
                  rent
                  counciltaX_RATES
                  loanshpmth
                  loanshpbal
                  creditcardmth
                  creditcardbal
                  overdraftmth
                  overdraftbal
                  overdraftfacility
                  othermth
                  otherbal
                }
              }
              jointhireR_DETAILS {
                address {
                  facetoface
                  title
                  forename
                  secondname
                  surname
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mnths
                  telephone
                  mobile
                  email
                }
              }
              guarantoR_DETAILS {
                address {
                  facetoface
                  title
                  forename
                  secondname
                  surname
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mnths
                  telephone
                  mobile
                  email
                }
                bank {
                  sortcode
                  accountname
                  accountno
                  bankname
                  street
                  locality
                  town
                  yrs
                  mths
                  chequE_CARD
                  visA_CARD
                  mastercard
                  amex
                  other
                  payementmethod
                  payfundsby
                }
                income {
                  netmonthly
                  netweekly
                  grossannual
                  changeindicator
                  other
                  salarymandated
                  salarydate
                  mortgagemth
                  mortgagebalance
                  housevalue
                  rent
                  counciltaX_RATES
                  loanshpmth
                  loanshpbal
                  creditcardmth
                  creditcardbal
                  overdraftmth
                  overdraftbal
                  overdraftfacility
                  othermth
                  otherbal
                }
                prevaddr {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                prevaddR2 {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                prevaddR3 {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                prevaddR4 {
                  houseno
                  apartno
                  housename
                  postcode
                  street
                  locality
                  posttown
                  county
                  country
                  yrs
                  mths
                }
                personal {
                  dateofbirth
                  gender
                  marital
                  dependants
                  residential
                  driverlicence
                  mothersmaidenname
                  countryofbirth
                  nationality
                }
                occupation {
                  occupation
                  occupationcode
                  status
                  employer
                  addresS1
                  addresS2
                  addresS3
                  employertelephone
                  yrs
                  mths
                }
                prevoccupation {
                  occupation
                  occupationcode
                  status
                  employer
                  addresS1
                  addresS2
                  addresS3
                  employertelephone
                  yrs
                  mths
                }
              }
              business {
                businesS_TYPE
                businesS_DETAILS {
                  tradinG_NAME
                  businesS_DESCRIPTION
                  yearS_TRADING
                  vaT_NO
                  numbeR_OF_PARTNERS
                  companY_REGNO
                  parenT_COMPANY
                  contacT_NAME
                  tradinG_ADDRESS {
                    houseno
                    apartno
                    housename
                    postcode
                    street
                    locality
                    posttown
                    county
                    country
                    yrs
                    mnths
                    telephone
                  }
                  tradinG_PREVADDR {
                    houseno
                    apartno
                    housename
                    postcode
                    street
                    locality
                    posttown
                    county
                    country
                    yrs
                    mths
                  }
                  businesS_BANK {
                    sortcode
                    accountname
                    accountno
                    bankname
                    street
                    locality
                    town
                    yrs
                    mths
                    chequE_CARD
                    visA_CARD
                    mastercard
                    amex
                    other
                    payementmethod
                    payfundsby
                  }
                }
              }
              supplieR_DETAILS {
                supplier
                addresS1
                posttown
                postcode
                telephone
                status
                detailstofollow
              }
              agrI_DETAILS {
                faD_HAS_HORSES
                faD_HAS_PIGS
                faD_HAS_POULTRY
                faD_HAS_HORTICULTURAL
                faD_HAS_FRUIT
                faD_HAS_HAULAGE
                faD_HAS_BEEF
                faD_HAS_SHEEP
                faD_HAS_DAIRY
                faD_HAS_VEGETABLES
                faD_IS_CONTRACTOR
                faD_IS_DEALER
                faD_OTHER_CATEGORY_DESC
                faD_NO_ACRES_OWNED
                faD_NO_ACRES_RENTED
                faD_NO_ACRES_SHARED_FARMED
                faD_NO_ACRES_CONTRACT_FARMED
                faD_VALUE_PER_ACRE
                faD_TENANCY_REMAINING
                faD_LANDLORD_NAME
                faD_LAND_GRADE
                faD_ACRES_WHEAT
                faD_ACRES_BARLEY
                faD_ACRES_OILSEEDS
                faD_ACRES_PEAS_BEANS
                faD_ACRES_POTATOES
                faD_ACRES_SUGAR_BEET
                faD_ACRES_OTHER_ARABLE
                faD_ACRES_LEYS
                faD_ACRES_PERMANENT_PASTURE
                faD_ACRES_ROUGH_GRAZING
                faD_ACRES_OTHER
                faD_NO_DAIRY_COWS
                faD_NO_DAIRY_REPLACEMENTS
                faD_AMT_MILK_QUOTA_OWNED
                faD_AMT_MILK_QUOTA_LEASED
                faD_AVG_YIELD_PER_COW
                faD_AVG_MONTHLY_MILK_CHQ
                faD_NO_BEEF_SUCKLERS
                faD_NO_BEEF_FATTENERS
                faD_NO_EWES
                faD_NO_FATTENING_LAMBS
                faD_NO_PIGS_SOWS
                faD_NO_PIGS_WEANERS
                faD_NO_PIGS_FATTENERS
                faD_NO_POULTRY_BROILERS
                faD_NO_POULTRY_LAYERS
                faD_NO_POULTRY_OTHERS
                faD_SUBSIDIES_PER_ANNUM
                faD_TOT_VALUE
                faD_ACRES_SET_ASIDE
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_PROPOSAL_STATUS = gql`
  query GetProposalStatus(
    $lender: String!
    $proposalId: String!
    $dealId: String!
  ) {
    GetProposalStatus(
      lender: $lender
      proposalId: $proposalId
      dealId: $dealId
    ) {
      ... on MetaLightResponse {
        _status
        _decision
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

export const GET_FLEXIMIZE_DOCUMENT_TYPES = gql`
  query GetFleximizeDocumentTypes {
    GetFleximizeDocumentTypes {
      id
      type
    }
  }
`;
