import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import * as gtag from 'Utils/gtag';
import Grid from '@material-ui/core/Grid';

import {
  Directors,
  Shareholders,
  BalanceSheet,
  FilingHistory,
  GroupCompanies,
  KeyStatistics,
  Addresses,
  CCJS,
  Mortgage,
  UnpaidDebt,
  Profit_Loss,
  Cashflow,
  RatioAnalysis,
  PreviousName,
  UnpaidLosses
} from 'components/RedFlag/TabMenu/components';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { Warning } from '@material-ui/icons';
import { theme } from 'theme';

export const TabList = ({ value, company, setValue, type, pluginRedflag }) => {
  const classes = useStyles();
  const handleChangeVerticalTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePlugin = (event, newValue) => {
    gtag.event({
      action: `RF: ${redflagTabList[newValue].name} tab selected`,
      feature: 'Company Navigator',
      message: company.data.attributes.name
    });
    setValue(newValue);
  };

  const redflagStyleIndexing = Boolean(type === 'horizontal');
  const isPluginRedflag = Boolean(pluginRedflag === 'true');

  return (
    <Tabs
      orientation={type}
      variant="scrollable"
      value={value}
      onChange={
        redflagStyleIndexing ? handleChangePlugin : handleChangeVerticalTabs
      }
      className={classes.tabs}>
      {!isPluginRedflag ? <Tab label={<Label text="Company Details" />} /> : ''}
      {redflagTabList.map((tab, idx) => {
        const dataPresent = hasRequiredData(company, tab.dataKeys);

        return (
          <Tab
            key={idx}
            label={
              <Grid container>
                <Grid item>
                  {!dataPresent && <Warning fontSize="small" color="error" />}
                </Grid>
                <Grid item style={{ paddingLeft: theme.spacing(0.5) }}>
                  <Label text={tab.name} />
                </Grid>
              </Grid>
            }
          />
        );
      })}
    </Tabs>
  );
};

export const ItemList = ({ company, value, pluginRedflag }) => {
  return (
    <div>
      {redflagTabList.map((Tab, idx) => (
        <TabPanel key={idx} index={pluginRedflag ? idx : idx + 1} value={value}>
          <Tab.Component company={company} />
        </TabPanel>
      ))}
    </div>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </div>
  );
};

const Label = ({ text }) => {
  const classes = useStyles();
  return <div className={classes.label}>{text}</div>;
};

const useStyles = makeStyles((theme) => ({
  label: {
    display: 'flex',
    alignSelf: 'flex-start',
    textAlign: 'left',
    color: theme.palette.text.primary
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}));

/**
 ** HOC to check for the presence of data.
 ** If the data is not present, it renders an alert message instead of the wrapped component.
 *
 * @param {JSX.Element} Component - The component to wrap.
 * @param {string[]} dataKeys - An array of keys in dot notation to check for data presence.
 * @returns {JSX.Element} - A new component that checks for data presence before rendering.
 */

const withDataCheck = (
  Component: ({ company }: { company: IRedflagAPIData }) => JSX.Element,
  dataKeys: string[]
) => {
  return (props) => {
    const dataPresent = hasAnyKey(props.company, dataKeys);

    if (!dataPresent) {
      return (
        <Alert severity="error">
          <AlertTitle>No Data Found</AlertTitle>
          <Typography variant="h6" color="textSecondary">
            No relevant data found for this company. Please try again later. If
            you believe this is a mistake, please contact support.
          </Typography>
        </Alert>
      );
    }
    return <Component {...props} />;
  };
};

const hasRequiredData = (
  company: IRedflagAPIData,
  dataKeys: string[]
): boolean => {
  return dataKeys.some((key) => {
    const path = key
      .split('.')
      .flatMap((part) => part?.match(/([^[\]]+)/g) ?? []);

    return (
      path.reduce((acc, part) => acc?.[part] ?? undefined, company) !==
      undefined
    );
  });
};

/**
 * Helper function to check if any of the provided keys exist in the object,
 * supporting nested keys using dot notation.
 *
 * @param {Object} obj - The object to check.
 * @param {string[]} keys - An array of strings representing the keys to check.
 * @returns {boolean} - True if any key exists, false otherwise.
 */

const hasAnyKey = (obj: object, keys: string[]) => {
  return keys.some((key) => {
    const path = key
      .split('.')
      .flatMap((part) => part?.match(/([^[\]]+)/g) ?? []);
    return (
      path.reduce((acc, part) => acc?.[part] ?? undefined, obj) !== undefined
    );
  });
};

/**
 *? List of tabs with their corresponding components, wrapped with data check HOC.
 *? Each tab represents a section with a specific data requirement.
 */

interface IRedflagComponentProps {
  company: IRedflagAPIData;
}

type RedflagComponentType = React.FC<IRedflagComponentProps>;
interface IRedflagTab {
  name: string;
  Component: RedflagComponentType;
  dataKeys: string[];
}

const redflagTabList: IRedflagTab[] = [
  {
    name: 'Key Statistics',
    Component: withDataCheck(KeyStatistics, [
      'data.attributes.estimated_employees',
      'data.attributes.employees',
      'data.attributes.rfa_rating.credit_limit',
      'data.attributes.estimated_turnover',
      'profit_loss[0].attributes.turnover'
    ]),
    dataKeys: [
      'data.attributes.estimated_employees',
      'data.attributes.employees',
      'data.attributes.rfa_rating.credit_limit',
      'data.attributes.estimated_turnover',
      'profit_loss[0].attributes.turnover'
    ]
  },
  {
    name: 'Filing History',
    Component: withDataCheck(FilingHistory, ['filing_history']),
    dataKeys: ['filing_history']
  },
  {
    name: 'Directors',
    Component: withDataCheck(Directors, ['directors']),
    dataKeys: ['directors']
  },
  {
    name: 'Shareholders',
    Component: withDataCheck(Shareholders, ['shareholders']),
    dataKeys: ['shareholders']
  },
  {
    name: 'Balance Sheet',
    Component: withDataCheck(BalanceSheet, ['balance_sheet']),
    dataKeys: ['balance_sheet']
  },
  {
    name: 'Profit Loss',
    Component: withDataCheck(Profit_Loss, ['profit_loss']),
    dataKeys: ['profit_loss']
  },
  {
    name: 'Addresses',
    Component: withDataCheck(Addresses, ['addresses']),
    dataKeys: ['addresses']
  },
  {
    name: 'CCJS',
    Component: withDataCheck(CCJS, ['ccjs']),
    dataKeys: ['ccjs']
  },
  {
    name: 'Mortgage',
    Component: withDataCheck(Mortgage, ['mortgage']),
    dataKeys: ['mortgage']
  },
  {
    name: 'Cashflow',
    Component: withDataCheck(Cashflow, ['cashflow']),
    dataKeys: ['cashflow']
  },
  {
    name: 'Ratio Analysis',
    Component: withDataCheck(RatioAnalysis, ['ratio_analysis']),
    dataKeys: ['ratio_analysis']
  },
  {
    name: 'Previous Name',
    Component: withDataCheck(PreviousName, ['previous_name']),
    dataKeys: ['previous_name']
  },
  {
    name: 'Group Companies',
    Component: withDataCheck(GroupCompanies, ['group_companies']),
    dataKeys: ['group_companies']
  }
];

// { name: 'Unpaid Debt', Component: UnpaidDebt },
// { name: 'UnpaidLosses', Component: UnpaidLosses },
