// ███████╗ ███████╗ ███████╗ ██╗ ██╗ ███████╗ ██╗  ██╗
// ██   ██║ ████╔══╝ ████╔══╝ █████╔╝ ████╔══╝  ╚███╔═╝
// ███████║ ██╔═╝    ██╔═╝    ██║ ██╗ ███████╗   ╚█╔╝
// ╚══════╝ ╚═╝      ╚═╝      ╚═╝ ╚═╝ ╚══════╝    ╚╝
// copyright © offkey limited 2022
import { combineReducers } from 'redux';
import { useSelector, TypedUseSelectorHook } from 'react-redux';

// Reducers
import auditReducer from './auditReducer';
import calculatorReducer from './calculatorReducer';
import graphqlCrmReducer from './graphqlCrmReducer';
import configReducer from './configReducer';
import fb from './fbReducer';
import legacyDealReducer from './legacyDealReducer';
import schemaReducer from './schemaReducer';
import notifyReducer from 'react-redux-notify';
import process from './processReducer';
import user from './userReducer';
import userAPI from './userAPI';
import utilityReducer from './utilityReducer';
import communication from './notificationReducer';
import formReducer from './formReducer';
import dealSummary from './dealSummaryReducer';
import dndReducer from './dndReducer';

const reducers = combineReducers({
  audits: auditReducer,
  calculator: calculatorReducer,
  config: configReducer,
  fb,
  graphqlCrm: graphqlCrmReducer,
  legacyDealReducer,
  notifications: notifyReducer,
  schema: schemaReducer,
  process,
  user,
  userAPI,
  utility: utilityReducer,
  communication,
  formReducer,
  dealSummary,
  dndReducer
});

// Wipe store on logout
const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT') state = undefined;
  return reducers(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export default rootReducer;
