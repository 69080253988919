import InlineTextField from 'components/Fields/InlineTextField';
import {
  CompleteObjectInstance,
  CompleteProcessInstance,
  FieldDefinition,
  ObjectDefinition,
  UserInstance,
  CompleteProcessStepDefinition
} from 'types/interfaces';

const NonExistedFieldInstance = ({
  ObjectDefinition,
  FieldDefinition,
  CompleteObjectInstance,
  currentDeal,
  stepdefdict,
  leverFieldDefinitions,
  OtherFieldDefinitionToShow,
  classes,
  UserInstance
}: {
  ObjectDefinition: ObjectDefinition;
  FieldDefinition: FieldDefinition;
  CompleteObjectInstance: CompleteObjectInstance;
  currentDeal: CompleteProcessInstance;
  stepdefdict: CompleteProcessStepDefinition;
  leverFieldDefinitions: FieldDefinition[];
  OtherFieldDefinitionToShow: FieldDefinition[];
  classes: any;
  UserInstance: UserInstance;
}) => {
  return (
    <div className={classes.paper}>
      <div style={{ display: 'flex' }}>
        <p>{FieldDefinition.Title}</p>
      </div>

      <hr className={classes.hr} />

      <div className={classes.inlinetext}>
        {CompleteObjectInstance.ObjectInstance.IsPublished && (
          <InlineTextField
            FieldDefinition={FieldDefinition}
            FieldInstance={undefined}
            ObjectDefinition={ObjectDefinition}
            CompleteObjectInstance={CompleteObjectInstance}
            ObjectInstance={CompleteObjectInstance.ObjectInstance}
            ProcessInstance={currentDeal.ProcessInstance}
            ProcessStepSelectionType={
              stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
            }
            UserInstance={UserInstance}
            fields={leverFieldDefinitions}
            type={FieldDefinition.FieldType}
            FieldDefinitionList={
              OtherFieldDefinitionToShow ? OtherFieldDefinitionToShow : ''
            }
            value={''}
            parentsIsFormComponent
          />
        )}
      </div>
    </div>
  );
};

export default NonExistedFieldInstance;
