const FleximizeProposal = {
  is_test: true,
  business_type_id: 0,
  company_name: '',
  registration_number: '',
  title_id: 0,
  first_name: '',
  last_name: '',
  phone: '',
  email: '',
  loan_amount: 0,
  term: 0,
  funding_urgency: 0,
  loan_purpose_id: 0,
  other_purpose: '',
  internal_id: '',
  account_score_customer: '',
  notes: '',
  privacy_policy_consent: false,
  cra_checks_consent: false,
  refer_consent: false,
  company: {
    vat_no: '',
    monthly_sales: 0,
    date_of_creation: '',
    trading_date: '',
    same_address: false,
    registered_address: {
      abode_name_no: '',
      street: '',
      street2: '',
      town: '',
      county: '',
      postcode: ''
    },
    trading_address: {
      abode_name_no: '',
      street: '',
      street2: '',
      town: '',
      county: '',
      postcode: ''
    },
    directors: [
      {
        title_id: 0,
        first_name: '',
        last_name: '',
        date_of_birth: '',
        major_shareholder: false,
        residential_type_id: 0,
        abode_name_no: '',
        street: '',
        street2: '',
        town: '',
        county: '',
        postcode: '',
        move_in_date: '',
        previous_addresses: [
          {
            abode_name_no: '',
            street: '',
            street2: '',
            town: '',
            county: '',
            postcode: '',
            move_in_date: ''
          }
        ]
      }
    ],
    shareholders: [
      {
        title_id: 0,
        first_name: '',
        last_name: '',
        date_of_birth: '',
        major_shareholder: false,
        residential_type_id: 0,
        abode_name_no: '',
        street: '',
        street2: '',
        town: '',
        county: '',
        postcode: '',
        move_in_date: '',
        previous_addresses: [
          {
            abode_name_no: '',
            street: '',
            street2: '',
            town: '',
            county: '',
            postcode: '',
            move_in_date: ''
          }
        ]
      }
    ]
  }
};

export default FleximizeProposal;
