import { callMsGraph, callMsGraphDetailed } from 'services/microsoft/graph';
import { Message } from '@microsoft/microsoft-graph-types';
import { ICustomMessage } from '../interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import { getGraphFolders } from './folderFormatting';
import { IMicrosoftFolderIds } from '../context/interface';

export const getAllEmailsWithConversationId = async ({
  accessToken,
  conversationId
}: {
  accessToken: string;
  conversationId: string;
}) => {
  const method = 'GET';
  const url = `https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${conversationId}'`;
  const res = await callMsGraphDetailed({
    accessToken,
    url,
    method,
    queryParams: { $expand: 'attachments' }
  });
  const receivedMessages: ICustomMessage[] = res?.data?.value;
  return receivedMessages;
};

export const getAttachmentsList = async (accessToken: string, id: string) => {
  const method = 'GET';
  const url = `https://graph.microsoft.com/v1.0/me/messages/${id}/attachments`;
  const res = await callMsGraphDetailed({
    accessToken,
    url,
    method
  });
  return res?.data?.value;
};

export const fetchInboxFolderData = async (
  accessToken: string
): Promise<any | null> => {
  try {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/me/mailFolders/Inbox`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );

    if (!response.ok) return null;
    return response.json();
  } catch (e) {
    BugTracker.notify(e);
    return null;
  }
};

export const fetchInboxMessages = async (
  accessToken: string,
  folderId: string
): Promise<any | null> => {
  try {
    return await callMsGraph({
      accessToken,
      action: 'ParentFolders',
      parentFolderId: folderId,
      queryParams: { $expand: 'attachments', $top: 10 }
    });
  } catch (e) {
    BugTracker.notify(e);
    return null;
  }
};

export const processAndSetUpdatedData = async (
  messages: any,
  accessToken: string
): Promise<any | null> => {
  try {
    return await getGraphFolders({ messages, accessToken });
  } catch (e) {
    BugTracker.notify(e);
    return null;
  }
};

export const determineParentCategory = (
  folderId: string,
  folderIds: IMicrosoftFolderIds | null
) => {
  if (!folderId || !folderIds) return null;

  const categories = [
    'deletedItems',
    'junkEmail',
    'archive',
    'inboxFolder',
    'sentItemsFolder'
  ];
  for (const category of categories) {
    if (folderIds[category] && folderIds[category].id === folderId) {
      return category;
    }
  }
  return 'customFolders';
};
