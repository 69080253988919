import React from 'react';
import { useSelector } from 'react-redux';
import { getCurrentAudit } from 'redux/actions/audits';
import LinearProgress from '@material-ui/core/LinearProgress';
import JSONPretty from 'react-json-pretty';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { Typography, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const Detail = ({ rowData }) => {
  const token = useSelector<any>((s) => s.user.auth.token);
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({});

  const getData = async () => {
    const data = await getCurrentAudit({ token, Id: rowData.Id });

    if (data) {
      setData(data.Payload);
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ padding: 25 }}>
      {loading ? (
        <LinearProgress />
      ) : (
        <DetailPanel data={data} rowData={rowData} />
      )}
    </div>
  );
};

export default Detail;

const DetailPanel = ({ data, rowData }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  // if (Object.values(data).length < 1) return <Typography>No Data</Typography>;

  if (rowData?.Endpoint?.includes(`/v1/FieldInstanceAPI.ashx`)) {
    const { UserInstanceId, FieldValue, ObjectDefinitionId } = JSON.parse(data);
    return (
      <>
        <Grid
          container
          style={{ display: 'flex', alignItems: 'center' }}
          spacing={1}>
          <Grid item>
            <QuickUserFromId UserInstanceId={UserInstanceId} />
          </Grid>
          <Grid item>
            <Typography>Modified a Field to the value</Typography>
          </Grid>
          <Grid item>
            <span
              style={{
                fontSize: 15,
                fontWeight: 800,
                color
              }}>{`${FieldValue}`}</span>
          </Grid>
          <Grid item>
            <Typography>From object with id</Typography>
          </Grid>
          <Grid item>
            <span
              style={{
                fontSize: 15,
                fontWeight: 800,
                color
              }}>
              {`[${ObjectDefinitionId}]`}
            </span>
          </Grid>
        </Grid>

        <br />

        <details>
          <JSONPretty data={data} />
        </details>
      </>
    );
  } else if (rowData?.Endpoint?.includes(`/v1/ObjectInstanceAPI.ashx`)) {
    const {
      ObjectInstance,
      ObjectInstance: { UserInstanceId }
    } = JSON.parse(data);
    return (
      <>
        <Grid
          container
          style={{ display: 'flex', alignItems: 'center' }}
          spacing={1}>
          <Grid item>
            <QuickUserFromId UserInstanceId={UserInstanceId} />
          </Grid>
          <Grid item>
            <Typography>Modified</Typography>
          </Grid>
          <Grid item>
            <span style={{ fontSize: 15, fontWeight: 800, color }}>
              {`${ObjectInstance?.Title} [${ObjectInstance?.ObjectDefinitionId}]`}
            </span>
          </Grid>
        </Grid>

        <br />

        <details>
          <JSONPretty data={data} />
        </details>
      </>
    );
  } else if (rowData?.Endpoint?.includes(`/v1/ProcessInstanceAPI.ashx`)) {
    const { ProcessInstance } = JSON.parse(data);
    const { Title, Id, UserInstanceId, ProcessStepDefinitionId } =
      JSON.parse(data);

    if (!ProcessInstance) {
      return (
        <>
          <Grid
            container
            style={{ display: 'flex', alignItems: 'center' }}
            spacing={1}>
            <Grid item>
              <QuickUserFromId UserInstanceId={UserInstanceId} />
            </Grid>
            <Grid item>
              <Typography>changed</Typography>
            </Grid>
            <Grid item>
              <span style={{ fontSize: 15, fontWeight: 800, color }}>
                {Title}
              </span>
            </Grid>
            <Grid item>
              <Typography>to</Typography>
            </Grid>
            <Grid item>
              <span style={{ fontSize: 15, fontWeight: 800, color }}>
                {ProcessStepDefinitionId === -3 && 'Abandoned'}
                {ProcessStepDefinitionId === -2 && 'Closed Lost'}
                {ProcessStepDefinitionId === -1 && 'Closed Won'}
              </span>
            </Grid>
          </Grid>

          <br />

          <details>
            <JSONPretty data={data} />
          </details>
        </>
      );
    }
    return (
      <>
        <Grid
          container
          style={{ display: 'flex', alignItems: 'center' }}
          spacing={1}>
          {ProcessInstance?.UserInstanceId && (
            <Grid item>
              <QuickUserFromId
                UserInstanceId={ProcessInstance.UserInstanceId}
              />
            </Grid>
          )}

          <Grid item>
            <span style={{ fontSize: 15, fontWeight: 800, color }}>
              {ProcessInstance?.Title}
            </span>
          </Grid>
          <Grid item>
            <Typography> Was changed to step </Typography>
          </Grid>
          <Grid item>
            <span style={{ fontSize: 15, fontWeight: 800, color }}>
              {ProcessInstance?.ProcessStepDefinitionId}
            </span>
          </Grid>
        </Grid>

        <br />

        <details>
          <JSONPretty data={data} />
        </details>
      </>
    );
  } else if (rowData.Endpoint.includes(`/v1/ruleapi.ashx`)) {
    const { Rule } = JSON.parse(data);
    return (
      <>
        <Grid
          container
          style={{ display: 'flex', alignItems: 'center' }}
          spacing={1}>
          <Grid item>
            <Typography>Interactive rule triggered: </Typography>
          </Grid>
          <Grid item>
            <span style={{ fontSize: 15, fontWeight: 800, color }}>{Rule}</span>
          </Grid>
        </Grid>

        <br />

        <details>
          <JSONPretty data={data} />
        </details>
      </>
    );
  }
  return <JSONPretty data={data} />;
};
