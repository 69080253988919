import {
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import InlineTextField from 'components/Fields/InlineTextField';
import { pickFieldsToShow } from 'components/Stepper/functions/hideshowFields';
import { useProcess } from 'hooks';
import {
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance,
  ObjectDefinition
} from 'types/interfaces';
import Xpansion from 'components/Xpansion';
import { useStyles } from '../styles';
import FieldStatusIcon from '../../SingleObject/components/FieldStatusIcon';
import { ContentTitle } from './ContentTitle';
import { Dispatch, SetStateAction, useContext } from 'react';
import NonExistedFieldInstance from '../../helper/nonExistedFieldInstance';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { StepperContext } from 'components/Stepper/context';

const summaryfields = [
  'Reg Number',
  'Make',
  'Model',
  'Condition',
  'Year',
  'Prospective Supplier',
  'Non VATable Items',
  'Quantity',
  'Price Per Unit',
  'Price Excluding VAT',
  'VAT Rate',
  'VAT',
  'Price Including VAT',
  'Deposit',
  'On The Road Price'
];

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          data-cy="close-summary-btn"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <br />
      <br />
      {children}
    </MuiDialogTitle>
  );
});

interface IContentProps {
  FieldDefinitionList: FieldDefinition[] | undefined;
  ObjectDefinition: ObjectDefinition;
  CompleteObjectInstance: CompleteObjectInstance;
}

interface ISummaryDialog extends IContentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  FieldInstanceList: FieldInstance[];
}

const SummaryDialog = ({
  FieldDefinitionList,
  FieldInstanceList,
  ObjectDefinition,
  CompleteObjectInstance,
  setOpen,
  open
}: ISummaryDialog) => {
  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle id="customized-dialog-title" onClose={() => setOpen(!open)}>
        <ContentTitle
          FieldInstanceList={FieldInstanceList}
          FieldDefinitionList={FieldDefinitionList}
        />
      </DialogTitle>
      <DialogContent>
        <Content
          FieldDefinitionList={FieldDefinitionList}
          ObjectDefinition={ObjectDefinition}
          CompleteObjectInstance={CompleteObjectInstance}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SummaryDialog;

const Content = ({
  FieldDefinitionList,
  ObjectDefinition,
  CompleteObjectInstance
}: IContentProps) => {
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const { stepdefdict, currentDeal, user: UserInstance } = useProcess();
  const classes = useStyles();

  if (!FieldDefinitionList) return <div />;
  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

  const leverFieldDefinitions = FieldDefinitionList.filter(
    (el) => el.FieldType === 'Drop-downHideShowFields'
  );
  const fieldDefinitions = FieldDefinitionList.filter(
    (el) => el.FieldType !== 'Drop-downHideShowFields'
  );
  const LeverFieldDefinitionsToShow = pickFieldsToShow({
    FieldDefinitionList: leverFieldDefinitions,
    CompleteObjectInstance
  });
  const OtherFieldDefinitionToShow = pickFieldsToShow({
    FieldDefinitionList: fieldDefinitions,
    CompleteObjectInstance
  });

  let SummaryFieldDefinitions: FieldDefinition[] =
    OtherFieldDefinitionToShow.filter((FieldDefinition: FieldDefinition) =>
      summaryfields.includes(FieldDefinition.Title)
    );

  let DetailFieldDefinitions: FieldDefinition[] =
    OtherFieldDefinitionToShow.filter(
      (FieldDefinition: FieldDefinition) =>
        !summaryfields.includes(FieldDefinition.Title)
    );

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <>
      <Paper className={classes.root} elevation={3}>
        <div className={classes.leverCol}>
          <div>
            {LeverFieldDefinitionsToShow.map(
              (FieldDefinition: FieldDefinition, idx: number) => (
                <div key={FieldDefinition.Id}>
                  {FieldInstanceList.map(
                    (FieldInstance: FieldInstance, idx: number) => {
                      if (
                        FieldInstance.FieldDefinitionId === FieldDefinition.Id
                      ) {
                        return (
                          <div className={classes.paper} key={idx}>
                            <div style={{ display: 'flex' }}>
                              <FieldStatusIcon
                                FieldDefinition={FieldDefinition}
                                FieldInstance={FieldInstance}
                                Required={FieldDefinition.FieldRequired}
                              />
                              <p>{FieldDefinition.Title}</p>
                            </div>
                            <hr className={classes.hr} />
                            <div className={classes.inlinetext}>
                              <InlineTextField
                                readOnly={dealCondition}
                                FieldDefinition={FieldDefinition}
                                FieldInstance={FieldInstance}
                                ObjectDefinition={ObjectDefinition}
                                CompleteObjectInstance={CompleteObjectInstance}
                                ObjectInstance={
                                  CompleteObjectInstance.ObjectInstance
                                }
                                ProcessInstance={currentDeal.ProcessInstance}
                                ProcessStepSelectionType={
                                  stepdefdict.ProcessStepDefinition
                                    .ProcessStepSelectionType
                                }
                                UserInstance={UserInstance}
                                fields={leverFieldDefinitions}
                                type={FieldDefinition.FieldType}
                                value={FieldInstance?.FieldValue}
                                parentsIsFormComponent
                              />
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              )
            )}
          </div>
        </div>

        {/* summary detail section */}
        <Grid container className={classes.otherCol} spacing={2}>
          {SummaryFieldDefinitions.map((FieldDefinition: FieldDefinition) => {
            const isFieldInstance: boolean = FieldInstanceList.some(
              (FieldInstance: FieldInstance) =>
                FieldInstance.FieldDefinitionId === FieldDefinition.Id
            );

            if (!isFieldInstance) {
              return (
                <Grid item key={FieldDefinition.Id} xs={6}>
                  <NonExistedFieldInstance
                    ObjectDefinition={ObjectDefinition}
                    FieldDefinition={FieldDefinition}
                    CompleteObjectInstance={CompleteObjectInstance}
                    currentDeal={currentDeal}
                    stepdefdict={stepdefdict}
                    leverFieldDefinitions={leverFieldDefinitions}
                    OtherFieldDefinitionToShow={OtherFieldDefinitionToShow}
                    classes={classes}
                    UserInstance={UserInstance}
                  />
                </Grid>
              );
            }

            return (
              <Grid item key={FieldDefinition.Id} xs={6}>
                {FieldInstanceList.map(
                  (FieldInstance: FieldInstance, idx: number) => {
                    if (
                      FieldInstance.FieldDefinitionId === FieldDefinition.Id
                    ) {
                      return (
                        <div className={classes.paper} key={idx}>
                          <div style={{ display: 'flex' }}>
                            <FieldStatusIcon
                              FieldDefinition={FieldDefinition}
                              FieldInstance={FieldInstance}
                              Required={FieldDefinition.FieldRequired}
                            />

                            <p>{FieldDefinition.Title}</p>
                          </div>

                          <hr className={classes.hr} />

                          <div className={classes.inlinetext}>
                            <InlineTextField
                              readOnly={dealCondition}
                              FieldDefinition={FieldDefinition}
                              FieldInstance={FieldInstance}
                              ObjectDefinition={ObjectDefinition}
                              CompleteObjectInstance={CompleteObjectInstance}
                              ObjectInstance={
                                CompleteObjectInstance.ObjectInstance
                              }
                              ProcessInstance={currentDeal.ProcessInstance}
                              ProcessStepSelectionType={
                                stepdefdict.ProcessStepDefinition
                                  .ProcessStepSelectionType
                              }
                              UserInstance={UserInstance}
                              fields={leverFieldDefinitions}
                              type={FieldDefinition.FieldType}
                              FieldDefinitionList={
                                OtherFieldDefinitionToShow
                                  ? OtherFieldDefinitionToShow
                                  : ''
                              }
                              value={FieldInstance?.FieldValue}
                              parentsIsFormComponent
                            />
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </Grid>
            );
          })}
        </Grid>
      </Paper>

      {/* More Details section */}
      <Xpansion
        summary={'More Details'}
        selected={undefined}
        icon={undefined}
        style={{ margin: 2 }}
        defaultExpanded={undefined}
        className={undefined}>
        <Grid container className={classes.otherCol} spacing={2}>
          {DetailFieldDefinitions.map((FieldDefinition: FieldDefinition) => {
            return (
              <Grid item key={FieldDefinition.Id} xs={6}>
                {FieldInstanceList.map(
                  (FieldInstance: FieldInstance, idx: number) => {
                    if (
                      FieldInstance.FieldDefinitionId === FieldDefinition.Id
                    ) {
                      const restrictedIds = [21944, 23270];
                      if (
                        UserInstance.SystemAccess <= 4 &&
                        restrictedIds.includes(FieldDefinition.Id)
                      ) {
                        return <div />;
                      }

                      return (
                        <div key={idx}>
                          <div className={classes.paper}>
                            <div style={{ display: 'flex' }}>
                              <FieldStatusIcon
                                FieldDefinition={FieldDefinition}
                                FieldInstance={FieldInstance}
                                Required={FieldDefinition.FieldRequired}
                              />
                              <p>{FieldDefinition.Title}</p>
                            </div>
                            <hr className={classes.hr} />
                            <div className={classes.inlinetext}>
                              <InlineTextField
                                readOnly={dealCondition}
                                FieldDefinition={FieldDefinition}
                                FieldInstance={FieldInstance}
                                ObjectDefinition={ObjectDefinition}
                                CompleteObjectInstance={CompleteObjectInstance}
                                ObjectInstance={
                                  CompleteObjectInstance.ObjectInstance
                                }
                                ProcessInstance={currentDeal.ProcessInstance}
                                ProcessStepSelectionType={
                                  stepdefdict.ProcessStepDefinition
                                    .ProcessStepSelectionType
                                }
                                UserInstance={UserInstance}
                                fields={leverFieldDefinitions}
                                type={FieldDefinition.FieldType}
                                value={FieldInstance?.FieldValue}
                                FieldDefinitionList={
                                  OtherFieldDefinitionToShow
                                    ? OtherFieldDefinitionToShow
                                    : ''
                                }
                                parentsIsFormComponent
                              />
                            </div>
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </Grid>
            );
          })}
        </Grid>
      </Xpansion>
    </>
  );
};
