import { handleUpdateField } from 'components/Fields/InlineTextField/functions';
import { IFieldsProps } from 'components/Fields/InlineTextField/interface';
import { StepperContext } from 'components/Stepper/context';
import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';
import { useProcess } from 'hooks';
import useRepeatableObject, {
  LENDER_PROPOSAL_DECISION
} from 'hooks/useDeals/useRepeatableObject';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { getCalculatorObjectsForProcess } from 'redux/actions/Calculator';
import {
  getRelationship,
  getUsersForProcess
} from 'redux/actions/GraphQlActions';
import {
  IGetRelationshipList,
  IGetRelationshipsRelatedToAssigned,
  IUserForProcess
} from 'redux/actions/GraphQlActions/interface';
import { getListOfObjects } from 'redux/actions/objects';
import { useTypedSelector } from 'redux/reducers';
import {
  CompleteObjectDefinitionDict,
  CompleteObjectInstance,
  CompleteObjectInstanceDict,
  FieldDefinition,
  FieldInstance,
  ObjectDefinition,
  UserInstance
} from 'types/interfaces';
import { Labels } from 'types/enums';
import { BugTracker } from 'Utils/Bugtracker';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import {
  checkDirectorFields,
  DIRECTORS_OBJECT_DEFINITION_ID
} from 'components/Stepper/components/Steps/ActiveStep/SingleObject/processEntityUtils';
import { useDispatch } from 'react-redux';
import firebase from 'firebase';

interface ILPC {
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
}

interface IOptions {
  value: number;
  label: string;
}

interface IEditableDropdown {
  open: boolean;
  value: string;
  ObjectDefinition: ObjectDefinition;
  FieldDefinition: FieldDefinition;
  readOnly: boolean | undefined;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  edit: boolean;
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  updating: boolean;
  setUpdating: React.Dispatch<React.SetStateAction<boolean>>;
  options: { label: string; value: number | string }[];
  setOptions: React.Dispatch<
    React.SetStateAction<{ label: string; value: number | string }[]>
  >;
  isUserTypeField: boolean;
  isRelatedUserTypeField: boolean;
  selectOptions: string[];
  onChange: any;
  turnEditModeOff: () => void;
  handleFocus: () => void;
  getObjectInstanceOptions: () => Promise<void>;
  getUserInstanceOptions: () => Promise<void>;
  getRelatedUserInstances: () => Promise<void>;
  handleDialogClose: (confirmed: boolean) => Promise<void>;
  handleSelectChange: (
    e: React.ChangeEvent<{ value: unknown }>
  ) => Promise<void>;
  detectRequired:
    | (() => {
        FieldDefinition: FieldDefinition;
        FieldInstance: FieldInstance;
      }[])
    | undefined;
}

export const DropDownContext = createContext<IEditableDropdown>({
  open: false,
  value: '',
  readOnly: false,
  setOpen: () => {},
  edit: false,
  ObjectDefinition: {} as ObjectDefinition,
  FieldDefinition: {} as FieldDefinition,
  setEdit: () => {},
  openDialog: false,
  setOpenDialog: () => {},
  updating: false,
  setUpdating: () => {},
  options: [],
  setOptions: () => {},
  isUserTypeField: false,
  isRelatedUserTypeField: false,
  selectOptions: [],
  onChange: () => {},
  turnEditModeOff: () => {},
  handleFocus: () => {},
  getObjectInstanceOptions: async () => {},
  getUserInstanceOptions: async () => {},
  getRelatedUserInstances: async () => {},
  handleDialogClose: async () => {},
  handleSelectChange: async () => {},
  detectRequired: undefined
});

export const DropDownProvider = ({
  children,
  dropdownProps
}: {
  children: React.ReactNode;
  dropdownProps: IFieldsProps;
}) => {
  const stepper = useContext(StepperContext);
  const dispatch = useDispatch();
  const { user, currentDeal, currentStepId, landingpage, currentStep } =
    useProcess();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { deal } = useTypedSelector((s) => s.dealSummary);
  const { token } = useTypedSelector((s) => s.user.auth);
  const loggedInUser = useTypedSelector((state) => state.user.user);

  const {
    type,
    FieldInstance,
    ProcessInstance,
    FieldDefinition,
    value,
    UserInstance,
    readOnly,
    refresh,
    setGuarantor,
    handleChange,
    requiredCreate,
    detectRequired,
    ObjectDefinition,
    completeObjectInstances,
    CompleteObjectInstance
  } = dropdownProps;

  const { completeLenderDecisionOnBehalfOfFunder } = useRepeatableObject({
    CompleteObjectInstanceList: completeObjectInstances || ([] as any),
    isInlineField: true
  });

  const [open, setOpen] = React.useState<boolean>(false);
  const [edit, setEdit] = React.useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [updating, setUpdating] = React.useState<boolean>(false);
  const [options, setOptions] = useState<
    { label: string; value: number | string }[]
  >([]);

  const isUserTypeField: boolean =
    type === 'Drop-downUserInstance' ||
    type === 'Drop-downAssignedUserInstance';

  const isRelatedUserTypeField: boolean =
    type === 'Drop-downRelatedUserInstance' ||
    type === 'Drop-downAutoRelatedUserInstance' ||
    type === 'Drop-downRelatedToAssignedUserInstance';

  // as a default try and use the field instance's process instance id
  let ProcessInstanceId: number | undefined =
    FieldInstance && Object.values(FieldInstance).length > 1
      ? FieldInstance?.ProcessInstanceId
      : ProcessInstance?.Id;

  // if Drop-downUserInstance, Use the process instance's process instance id first
  if (isUserTypeField) {
    ProcessInstanceId = ProcessInstance
      ? ProcessInstance?.Id
      : FieldInstance?.ProcessInstanceId;
  }

  let FieldOptions = FieldDefinition.FieldOptions;
  const selectOptions = FieldOptions ? FieldOptions.split(',') : [];
  const onChange: any = handleUpdateField({
    setUpdating,
    token,
    props: dropdownProps
  });

  /** function for turning off the edit mode */
  const turnEditModeOff = () => {
    setOpen(false);
    setEdit(false);
  };

  // turns off edit mode
  const handleFocus = () => {
    setOpen(false);
    setEdit(false);
  };

  const getObjectInstanceOptions = async () => {
    const res = await getListOfObjects({
      ProcessInstanceId,
      ObjectDefinitionId: FieldOptions
    });

    if (res) {
      try {
        let options: { label: string; value: number }[] = [];
        Object.keys(res.data).forEach((key) =>
          options.push({ label: res.data[key], value: parseInt(key) })
        );

        const QuoteStepId = 1502;
        const IndicativeQuoteStepId = 1738;
        if (!landingpage) {
          return setOptions(options);
        }

        const handleQuoteStepId = async (): Promise<IOptions[]> => {
          const res: ILPC | null = await getCalculatorObjectsForProcess({
            ProcessInstanceId: currentDeal.ProcessInstance.Id
          });

          const QuoteDeclineId = [23524, 23591];
          if (res) {
            return options.filter((element: IOptions) => {
              const CompleteObjectInstance =
                res.CompleteObjectInstanceDict[element.value];

              const FieldInstanceList = getFieldInstances(
                CompleteObjectInstance
              );

              return !FieldInstanceList.some(
                (FieldInstance: FieldInstance) =>
                  QuoteDeclineId.includes(FieldInstance.FieldDefinitionId) &&
                  FieldInstance.FieldValue.includes('true')
              );
            });
          }

          return options;
        };

        if (currentStepId === QuoteStepId) {
          setOptions(await handleQuoteStepId());
        } else if (currentStepId === IndicativeQuoteStepId) {
          const IndicativeQuoteDeclineId = [23525, 23592];

          setOptions(
            options.filter((element: IOptions) => {
              const CompleteObjectInstance =
                currentDeal.CompleteObjectInstanceDict[element.value];

              const FieldInstanceList = getFieldInstances(
                CompleteObjectInstance
              );

              return !FieldInstanceList.some(
                (FieldInstance: FieldInstance) =>
                  IndicativeQuoteDeclineId.includes(
                    FieldInstance.FieldDefinitionId
                  ) && FieldInstance.FieldValue.includes('true')
              );
            })
          );
        } else setOptions(options);
      } catch (e) {
        BugTracker.notify(e);
      }
    }
  };

  const getUserInstanceOptions = async () => {
    const UserDefinitionId = FieldDefinition?.FieldOptions;
    let ProcessDefinitionId = 0;
    if (ProcessInstance?.ProcessDefinitionId) {
      ProcessDefinitionId = ProcessInstance?.ProcessDefinitionId;
    }

    type actionType =
      | 'USERSFORPROCESS'
      | 'ALLUSERSFORPROCESS'
      | 'ASSIGNEDUSERSFORPROCESS'
      | 'SELECTEDUSERFORPROCESS';

    let action: actionType = 'ALLUSERSFORPROCESS';
    const { FieldType } = FieldDefinition;

    if (FieldType === 'Drop-downAssignedUserInstance') {
      action = 'ASSIGNEDUSERSFORPROCESS';
    }

    if (FieldType === 'Read Only Selected UserInstance') {
      action = 'SELECTEDUSERFORPROCESS';
    }

    if (ProcessInstanceId && UserDefinitionId) {
      const newUserDefinitionId = parseInt(UserDefinitionId.toString());
      const response = (await getUsersForProcess({
        ProcessInstanceId,
        ProcessDefinitionId,
        UserDefinitionId: newUserDefinitionId,
        action,
        baseUrl
      })) as IUserForProcess[] | undefined;

      if (response) {
        let options: { label: string; value: number }[] = [];

        response.forEach((UsersForProcess: IUserForProcess) =>
          options.push({
            label: UsersForProcess.Title,
            value: parseInt(UsersForProcess.Id.toString())
          })
        );

        setOptions(options);
      }
    }
  };

  const getRelatedUserInstances = async () => {
    const UserDefinitionId = FieldDefinition?.FieldOptions;

    // Default Broker UserInstance
    let UserInstanceFromFieldInstance: number = parseInt(user.Id.toString());

    // If UserInstance Is a Broker then Find Customer UserInstance
    if (UserInstance && UserInstance.SystemAccess >= 4) {
      if (!deal.customers) {
        const findCurrentCustomerInDeal: string = Object.keys(
          currentStep.UserInstanceDictForCurrentStep
        )[0];

        if (!findCurrentCustomerInDeal) return;

        // We know this is already a string as shown in Redux but we are making sure it is.
        const makeNumber = parseInt(findCurrentCustomerInDeal.toString());
        UserInstanceFromFieldInstance = makeNumber;
      }

      if (deal.customers && deal.customers.selected) {
        if (Object.keys(deal.customers.selected).length > 1) {
          const msg =
            'There is a problem with the process configuration, please contact support';
          alert(msg);
          BugTracker(msg);
        } else {
          // Here we are using the Deal Customer UserInstance which comes back as a number.
          UserInstanceFromFieldInstance = deal.customers?.selected[0]?.Id;
        }
      }
    }

    if (!UserInstanceFromFieldInstance) return;
    let config = {} as
      | IGetRelationshipsRelatedToAssigned
      | IGetRelationshipList;

    if (type === 'Drop-downRelatedToAssignedUserInstance') {
      const list = FieldDefinition?.FieldOptions.split(',');
      const AssignedUserDefinitionId = parseInt(list[0]);
      const RelatedUserDefinitionId = parseInt(list[1]);

      config = {
        action: 'RELATEDTOASSIGNED',
        ProcessInstanceId: currentDeal.ProcessInstance.Id,
        AssignedUserDefinitionId,
        RelatedUserDefinitionId,
        baseUrl
      };
    } else {
      config = {
        action: 'LIST',
        fetchPolicy: true,
        UserInstanceId: UserInstanceFromFieldInstance,
        UserDefinitionId: parseInt(UserDefinitionId),
        baseUrl
      };
    }

    const response = (await getRelationship(config)) as
      | UserInstance[]
      | undefined;

    if (response) {
      const filteredData = response.filter(
        (UserInstance: UserInstance, index: number, self: UserInstance[]) =>
          index ===
          self.findIndex(
            (t: UserInstance) =>
              t.UserInstanceEmail === UserInstance.UserInstanceEmail
          )
      );

      const titleFrequency: { [key: string]: number } = {};
      filteredData.forEach((item: UserInstance) => {
        titleFrequency[item.Title] = (titleFrequency[item.Title] || 0) + 1;
      });

      const options = filteredData.map((item: UserInstance) => ({
        value: item.Id,
        label:
          titleFrequency[item.Title] > 1
            ? `${item.Title} - (${item.UserInstanceEmail})`
            : item.Title
      }));

      setOptions(options);
    }
  };

  const dispatchConfirmLenderDecision = () => {
    const isAdmin = user.SystemAccess >= 12;
    const dealOwner =
      currentDeal.ProcessInstance.UserInstanceId === loggedInUser.Id;

    if (isAdmin && !dealOwner) {
      const adminName = loggedInUser.Title;
      const notificationMessage = `Lender Decision for Deal Id (${currentDeal.ProcessInstance.Id}) has been confirmed by ${adminName}.`;
      const notification = {
        ProcessInstanceTitle: currentDeal.ProcessInstance.Title,
        id: '',
        msg: notificationMessage,
        pdid: currentDeal.ProcessInstance.ProcessDefinitionId,
        piid: currentDeal.ProcessInstance.Id,
        psdid: currentDeal.ProcessInstance.ProcessStepDefinitionId,
        read: false,
        ts: firebase.firestore.FieldValue.serverTimestamp(),
        type: 'deal'
      };

      const dealOwnerId = currentDeal.ProcessInstance.UserInstanceId;
      firebase
        .firestore()
        .collection('userAccount')
        .doc(dealOwnerId.toString())
        .collection('notifications')
        .add(notification);
    }
  };

  // this is the function
  const handleDialogClose = async (confirmed) => {
    const cachedValue = sessionStorage.getItem('confirmedActionValue');
    if (confirmed && cachedValue !== null) {
      await handleSelectChange({
        target: { value: cachedValue, confirmed: confirmed }
      });

      if (LENDER_PROPOSAL_DECISION.includes(FieldDefinition.Id)) {
        const newCompleteObjectInstance = Object.values(
          currentDeal.CompleteObjectInstanceDict
        ).find(
          (newCompleteObjectInstance) =>
            newCompleteObjectInstance.ObjectInstance.Id ===
            CompleteObjectInstance?.ObjectInstance.Id
        );

        if (!newCompleteObjectInstance) return;
        const LenderProposalDecisionStatus = [23292, 23593, 22802];

        const FieldInstanceList = getFieldInstances(newCompleteObjectInstance);
        const createNewCompleteObjectInstance = {
          ...newCompleteObjectInstance,
          FieldInstanceList: FieldInstanceList.map(
            (FieldInstance: FieldInstance) => {
              if (
                LenderProposalDecisionStatus.includes(
                  FieldInstance.FieldDefinitionId
                )
              ) {
                return {
                  ...FieldInstance,
                  FieldValue: cachedValue
                };
              }
              return FieldInstance;
            }
          )
        };

        await completeLenderDecisionOnBehalfOfFunder(
          cachedValue,
          createNewCompleteObjectInstance
        );
        dispatchConfirmLenderDecision();
      }

      sessionStorage.removeItem('confirmedActionValue');
      setOpenDialog(false);
    } else {
      if (cachedValue) sessionStorage.removeItem('confirmedActionValue');
      setOpenDialog(false);
    }
  };

  /**the select option the user selected to update the field value */
  const LENDER_DEFINITION = [21961, 21159];
  const handleSelectChange = async (eventOrValue) => {
    let targetValue;
    let confirmAction = eventOrValue.target?.confirmed;

    if (eventOrValue.target) {
      targetValue = eventOrValue.target.value;
    } else {
      targetValue = eventOrValue;
    }

    const isResetToNone =
      targetValue === '' || targetValue === Labels.ResetToNone;

    //! Using String Name Here Isn't Recommended But We Don't Have Anything Else To Use
    if (targetValue === 'Partnership (4+)') {
      const getDirectors = Object.values(
        currentDeal.CompleteObjectInstanceDict
      ).filter((completeObjectInstance: CompleteObjectInstance) => {
        return (
          completeObjectInstance.ObjectInstance.ObjectDefinitionId ===
          DIRECTORS_OBJECT_DEFINITION_ID
        );
      });

      if (getDirectors && getDirectors.length >= 4) {
        const getDirectorCheck = checkDirectorFields(getDirectors, 4);
        if (getDirectorCheck) {
          return await completedHandler(targetValue);
        } else {
          return dispatch(
            createNotification(
              errorNotif(
                `Partnership (4+) Requires At Least 4 Directors That Contain Information.`
              )
            )
          );
        }
      } else {
        return dispatch(
          createNotification(
            errorNotif(`Partnership (4+) Requires At Least 4 Directors.`)
          )
        );
      }
    }

    if (
      LENDER_PROPOSAL_DECISION.includes(FieldDefinition.Id) &&
      isResetToNone
    ) {
      // Reset LenderDecision to None
      const newCompleteObjectInstance: CompleteObjectInstance | undefined =
        Object.values(currentDeal.CompleteObjectInstanceDict).find(
          (newCompleteObjectInstance: CompleteObjectInstance) => {
            return (
              newCompleteObjectInstance.ObjectInstance.Id ===
              CompleteObjectInstance?.ObjectInstance.Id
            );
          }
        );

      if (!newCompleteObjectInstance) return;
      const createNewCompleteObjectInstance = {
        ...newCompleteObjectInstance,
        FieldInstanceList: newCompleteObjectInstance?.FieldInstanceList.map(
          (FieldInstance: FieldInstance) => {
            if (FieldInstance.FieldDefinitionId === 23292) {
              return {
                ...FieldInstance,
                FieldValue: ''
              };
            }
            return FieldInstance;
          }
        )
      };

      await completeLenderDecisionOnBehalfOfFunder(
        '',
        createNewCompleteObjectInstance
      );
    }

    if (
      FieldDefinition?.FieldDescription.includes('Alert') &&
      !confirmAction &&
      !isResetToNone
    ) {
      sessionStorage.setItem('confirmedActionValue', targetValue);
      setOpenDialog(true);
      return;
    }

    await completedHandler(targetValue);
  };

  const completedHandler = async (targetValue: string) => {
    const findOptionValue = options.find(
      (element) => element.label === targetValue
    );

    let newChange = {
      label: targetValue,
      value:
        findOptionValue?.label === Labels.ResetToNone || findOptionValue?.value
          ? findOptionValue?.value
          : targetValue
    };

    await onChange(newChange.value);
    refresh && refresh();

    setGuarantor && setGuarantor(true);
    const Title = FieldDefinition?.Title;
    if (Title === 'Deal Type') stepper.getData({});

    if (requiredCreate && handleChange) handleChange(newChange.value, Title);

    handleFocus();
  };

  useEffect(() => {
    if (!edit) {
      turnEditModeOff();
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (type === 'Drop-downObjectInstance') {
        await getObjectInstanceOptions();
      } else if (isUserTypeField) {
        await getUserInstanceOptions();
      } else if (isRelatedUserTypeField) {
        await getRelatedUserInstances();
      } else {
        let options: { label: string; value: number | string }[] = [];
        selectOptions.forEach((item: string) => {
          const parsedValue = parseInt(item);
          const value = isNaN(parsedValue) ? (item as string) : parsedValue;
          options.push({ label: item, value });
        });

        if (value) {
          options.push({ label: Labels.ResetToNone, value: '' });
        }

        setOptions(options);
      }
    };

    fetchData();
  }, [value]);

  const contextValue: IEditableDropdown = {
    options,
    setOptions,
    value,
    readOnly,
    openDialog,
    FieldDefinition,
    ObjectDefinition,
    setOpenDialog,
    handleDialogClose,
    handleSelectChange,
    open,
    setOpen,
    handleFocus,
    getObjectInstanceOptions,
    getUserInstanceOptions,
    getRelatedUserInstances,
    edit,
    setEdit,
    updating,
    setUpdating,
    isUserTypeField,
    isRelatedUserTypeField,
    selectOptions,
    onChange,
    turnEditModeOff,
    detectRequired
  };

  return (
    <DropDownContext.Provider value={contextValue}>
      {children}
    </DropDownContext.Provider>
  );
};
