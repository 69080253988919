import { Dashboard as DashboardLayout } from 'layouts';

const Component = () => {
  return (
    <DashboardLayout title="CRM" padding="noPadding">
      <iframe
        src="https://coda.io/embed/oZL1DoMqfK/_suqIQ?viewMode=embedplay"
        width={'100%'}
        height={1280}
        allow="fullscreen"
        style={{ border: 'none' }}
      />
    </DashboardLayout>
  );
};

export default Component;
