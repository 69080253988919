import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { useTypedSelector } from 'redux/reducers';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Calculator from '../Calculator';
import { Calculation } from 'types/calculatorInterfaces';
import {
  ObjectDefinition,
  FieldDefinitionDict,
  CompleteObjectInstance,
  ProcessInstance
} from 'types/interfaces';
import { useProcess } from 'hooks';
import { StepperContext } from 'components/Stepper/context';

interface Props {
  ProcessInstance: ProcessInstance;
  ProcessInstanceId: number;
  ObjectDefinition: ObjectDefinition;
  FieldDefinitionDict: FieldDefinitionDict;
  ObjectInstance: CompleteObjectInstance;
  isOverview: boolean;
  readOnly: boolean;
  calculation: Calculation;
  display?: string;
  index: number;
  selected?: boolean;
  open: boolean;
  handleClose: any;
  QuoteId: number;
  setCalculation?: (Calculation) => void;
}

const FormDialog = (props: Props) => {
  const { open, handleClose, QuoteId } = props;
  const { user } = useProcess();
  const [state, setState] = React.useState<Calculation>();
  const { setQuotesRefreshing } = useContext(StepperContext);

  const handleIntroducerAccess = () => {
    if (user.SystemAccess === 4) return true;
    else return false;
  };

  const subSystemUser = user.SystemAccess <= 4;
  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xl"
      onClose={() => {
        handleClose();
        setQuotesRefreshing(false);
      }}
      open={open}>
      <DialogContent>
        <Typography color="primary" variant="h6">
          Quote Id: {QuoteId}
        </Typography>
        {handleIntroducerAccess() ? (
          <Calculator setState={setState} {...props} />
        ) : (
          <Calculator {...props} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;

// This could be abstracted out as a helper for other functions and methods
const getInstanceValue = ({
  FieldDefinitionList,
  FieldInstanceList,
  FieldTitle
}) => {
  const DefinitionInQuestion = FieldDefinitionList.find(
    (i: any) => i.Title === FieldTitle
  )?.Id;

  const InstanceInsQuestion = FieldInstanceList.find(
    (i: any) => i.FieldDefinitionId === DefinitionInQuestion
  )?.FieldValue;

  return InstanceInsQuestion;
};
