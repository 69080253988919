import React, { Dispatch, SetStateAction } from 'react';
import { CustomDialog } from 'common/Dialog';
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Paper,
  useTheme,
  Box
} from '@material-ui/core';
import { StyledSelect } from 'common';
import { RootState, useTypedSelector } from 'redux/reducers';

import Overlay from 'layouts/Dashboard/components/Overlay';
import { UserCompanyNavigator } from './components/UserCompanyNavigator';

import { UserGroupType } from './functions/index';
import {
  NavigateToUserButton,
  HandleAlert,
  SimpleUserCreate,
  CreationButton,
  SearchForAUserInstance,
  HandleSupplier
} from './components/index';

import { createNotification } from 'react-redux-notify';
import { errorNotif, successNotif } from 'components/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { BugTracker } from 'Utils/Bugtracker';

import { CreateUser, getDefinition } from 'redux/actions/user';
import { HandleButtonLabelsAndAlerts } from './components/Utils';
import { ISupplier } from './components/Utils';
import { IOptionType } from 'common/StyledSelect';
import { UserDefinition, UserInstance } from 'types/interfaces';
import QuickUserFromId from '../QuickUserFromId';
import { INotificationLog, ISupplierAutomation } from './functions/interfaces';
import OverlayExpansion from 'layouts/Dashboard/components/Overlay/OverLayExpansion';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { IRedFlagDirectorship } from 'components/OffkeyCompanyAndDirectorSearch/helper';
import { IContactDoNotDeal } from 'hooks/useDoNotDealNonRedFlag';
import { useDoNotDealListNonRedFlag } from 'hooks/useDoNotDealNonRedFlag';

interface Props {
  handlecreatedUser?: (id: string) => void;
  options?: {
    label: string;
    value: number;
    IsPublished: boolean;
    Description?: string;
    Id?: number;
  }[];

  /**
   * Type definition for the automation values passed as props.
   *! This object comes from the Supplier Automation.
   * It allows us to ensure the UserDefinition is set correctly when creating it for a supplier.
   * Only essential values like UserDefinitionId and UserDefinitionTitle are passed.
   */
  automationValues?: ISupplierAutomation;
}

export const Regulated = [
  'Sole Trader',
  'Partnership of 3 and less',
  'Private Individual',
  'Private Individual - High Net Worth',
  'Other-Regulated'
];

interface ITypeProps {
  onChange: (event: any) => void;
  options: Array<UserDefinition | IOptionType> | null;
  placeholder: string;
  useMaterialUI: boolean;
}

interface IUserDefinitionType extends ITypeProps {
  value: IUserDefinitionRecord | undefined;
  isDisabled?: boolean;
}

interface IEntityType extends ITypeProps {
  value: {
    label: string;
    value: string;
  } | null;
  disabled?: boolean;
}

interface ICustomerTypeProps {
  onChange: (event: any) => void;
  options: [{ label: string; value: string }, { label: string; value: string }];
  placeholder: string;
  value: {
    label: string;
    value: string;
  } | null;
  useMaterialUI: boolean;
}

interface IUserDefinitionRecord extends UserDefinition {
  label: string;
  value: string;
  readonly?: boolean;
}

const NewUserButton = (props: Props) => {
  const theme = useTheme();
  const { user } = useTypedSelector((s) => s.user);
  const { token } = useTypedSelector((s) => s.user.auth);
  const { baseURL } = useTypedSelector((s) => s.config);
  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const dispatch = useDispatch();

  const [showToNav, setShowToNav] = React.useState(false);
  const [options, setOptions] = React.useState<any>(false);
  const [loadnewuser, setLoadnewuser] = React.useState<boolean>(false);

  /**
   * State to store the UserDefinition information.
   * Initialized using the values from props.automationValues if available.
   */
  const [UserDefinition, setUserDefinition] = React.useState<
    IUserDefinitionRecord | undefined
  >(() => {
    if (props.automationValues) {
      return {
        Id: props.automationValues.UserDefinitionId,
        Title: props.automationValues.UserDefinitionTitle,
        Description: 'company',
        SystemAccess: 0,
        label: props.automationValues.UserDefinitionTitle,
        value: props.automationValues.UserDefinitionId.toString(),
        readonly: true
      };
    }
    return undefined;
  });

  const [entityType, setEntityType] = React.useState<{
    label: string;
    value: string;
  } | null>(null);

  const [open, setOpen] = React.useState(() => {
    if (props.automationValues) {
      const { isOpen } = props.automationValues;
      if (isOpen) return true;
      else return false;
    }

    return false;
  });

  const [isValidCompany, setValidCompany] = React.useState<any>();
  const [NotificationLog, setNotificationLog] = React.useState<
    INotificationLog[]
  >([]);

  const { features } = useTypedSelector((s) => s.fb.proxy_instance_settings);

  const [createdUser, setCreatedUser] = React.useState<boolean>(false);
  const [isSupplier, setIsSupplier] = React.useState<ISupplier>({
    isValidShortCode: false,
    isValidSupplier: false
  });

  const subSystemUser = user.SystemAccess <= 4;
  const handleClose = () => {
    setShowToNav(false);
    setOpen(false);
    setUserDefinition(undefined);
    resetAllOptions();
    setLoadnewuser(false);
    setValidCompany(undefined);
    setCreatedUser(false);
    setNotificationLog([]);

    if (props.automationValues !== undefined) {
      props.automationValues.handleClose();
    }
  };

  const handleOpen = () => setOpen(true);
  const userType: 'Entity' | 'Director' | 'Other' =
    UserDefinition?.Title === 'Contacts'
      ? 'Other'
      : UserDefinition?.Description?.includes('company')
      ? 'Entity'
      : UserDefinition?.Description?.includes('directorship')
      ? 'Director'
      : 'Other';

  /**
   * OPEN Create DIALOG
   */

  const [openCompNavCreate, setOpenCompNavCreate] = React.useState(false);

  const [redflagData, setRedflagData] = React.useState<
    IRedflagAPIData | IRedFlagDirectorship
  >({} as IRedFlagDirectorship | IRedflagAPIData);

  const [createdUserInstanceId, setCreatedUserInstanceId] = React.useState<
    number | null
  >(null);

  const INIT_USERINSTANCE = {
    Title: '',
    UserDefinitionId: UserDefinition?.Id,
    UserInstanceEmail: '',
    UserInstancePassword: ''
  };
  const [newUser, setNewUser] = React.useState<any>(INIT_USERINSTANCE);

  const [saveToken, setSaveToken] = React.useState<string>('');
  const [selectedOption, setSelectedOption] = React.useState({
    title: 'token',
    stateString: 'Token Hidden'
  });

  const [proxy, setProxy] = React.useState({
    UserDefinition: {},
    UserInstance: {}
  });

  const createUser = async () => {
    setLoadnewuser(true);
    if (is_Company || is_Director) {
      setLoadnewuser(false);
      return setOpenCompNavCreate(true);
    }

    const CompleteUserDefinition = await getDefinition({
      token,
      action: 'define',
      UserDefinitionId: UserDefinition?.Id
    });

    return CreateUser({
      newUser,
      proxy,
      entityType: entityType?.value,
      LoggedInUser: user,
      CompleteUserDefinition: CompleteUserDefinition?.data,
      baseURL,
      Token: token
    })
      .then((res: INotificationLog[] | Error) => {
        if (res instanceof Error) {
          dispatch(createNotification(errorNotif(res.message)));
        } else {
          const createdUserInstance: INotificationLog | undefined = res?.find(
            (i) => i.event === 'User Created' || i.event === 'User Creation'
          );

          if (!createdUserInstance) {
            return dispatch(createNotification(errorNotif('User Not created')));
          }

          const createdUserInstanceID: number = createdUserInstance.data;
          const type = createdUserInstance.type;

          if (type === 'SUCCESS') setShowToNav(true);

          setCreatedUserInstanceId(createdUserInstanceID);
          setCreatedUser(true);
          setNotificationLog(res);

          dispatch(
            createNotification(successNotif('Successfully created user'))
          );
        }

        return setLoadnewuser(false);
      })
      .catch((e) => {
        setLoadnewuser(false);
        BugTracker.notify(e);
      });
  };

  const handleChange = (name, value) => {
    if (saveToken === '') setSaveToken(value);
    setNewUser({
      ...newUser,
      [name]: value,
      UserDefinitionId: UserDefinition?.Id
    });
  };

  const resetAllOptions = () => {
    setEntityType(null);
    setNewUser(INIT_USERINSTANCE);

    setSaveToken('');
    setSelectedOption({ title: 'token', stateString: 'Token Hidden' });

    setProxy({
      UserDefinition: {},
      UserInstance: {}
    });
    setCreatedUserInstanceId(null);
  };

  // const handleRadioChange = (event) => {
  //   const newTitle = event.target.value;
  //   if (newTitle !== 'token') {
  //     if (newUser.UserInstanceEmail.includes(saveToken)) {
  //       setNewUser({ ...newUser, UserInstanceEmail: '' });
  //     }
  //   }
  //   const newStateString =
  //     newTitle === 'token' ? saveToken : newUser.UserInstanceEmail;

  //   setSelectedOption({ title: newTitle, stateString: newStateString });
  // };

  const is_Company = Boolean(
    entityType?.value &&
      [
        'Limited Company',
        'PLC',
        'Limited Liability Partnership',
        'Partnership (4+)'
      ].includes(entityType.value)
  );

  const is_Director = Boolean(
    entityType?.value && ['company_director'].includes(entityType.value)
  );
  const validPrimary = Boolean(!proxy?.UserInstance['Id']);
  const regulated = Boolean(
    entityType && Regulated.includes(entityType?.value)
  );

  React.useEffect(() => {
    let options: any = [];
    UserDefinitionList?.forEach((item: any) => {
      item.value = item.Id;
      item.label = item.Title;
      options.push(item);
    });
    setOptions(options);

    return () => {
      setUserDefinition(undefined);
      resetAllOptions();
      setShowToNav(false);
    };
  }, []);

  React.useEffect(() => {
    if (props.handlecreatedUser) {
      const handleSupplier: ISupplier = HandleSupplier({
        UserDefinition,
        isValidCompany
      });

      setIsSupplier(handleSupplier);
    }
  }, [props.handlecreatedUser]);

  const UserDefinitionOptions = () => {
    let ops = Array.isArray(props.options)
      ? props.options
      : Array.isArray(options)
      ? options
      : [];
    const labels = subSystemUser
      ? ['Contacts', 'Customers']
      : ['Contacts', 'Customers', 'Suppliers', 'Introducer'];

    if (ops.length > 1) {
      ops = ops.filter((el) => labels.includes(el.label) && el.IsPublished);
    }

    return ops;
  };

  /**
   * Flag to determine if AutomationValues are provided.
   * This is true if props.automationValues is not undefined.
   */
  const hasAutomation = props.automationValues !== undefined;
  const StyledSelectUserGroupProps: IUserDefinitionType = {
    onChange: (v: { label: string; value: string }) => {
      if (!hasAutomation) {
        const newUserDefinition = v as IUserDefinitionRecord;
        setUserDefinition(newUserDefinition);
        resetAllOptions();
      }
    },
    isDisabled: hasAutomation,
    options: UserDefinitionOptions(),
    placeholder: 'User Group',
    value: UserDefinition,
    useMaterialUI: false
  };

  const StyledSelectEntityTypeProps: IEntityType = {
    onChange: (v: { label: string; value: string }) => {
      setEntityType(v);
    },
    options: UserGroupType(props?.options),
    placeholder: 'Entity Type',
    value: entityType,
    useMaterialUI: false
  };

  const StyledSelectCustomerTypeProps: ICustomerTypeProps = {
    onChange: (v: { label: string; value: string }) => {
      setEntityType(v);
      setSaveToken('');
    },
    options: [
      {
        label: 'Company Director',
        value: 'company_director'
      },
      {
        label: 'Non Company Director',
        value: 'non_company_director'
      }
    ],
    placeholder: 'Customer Type',
    value: entityType,
    useMaterialUI: false
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        data-cy="new_record_close_btn">
        + New Record
      </Button>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        alert={
          <>
            <Overlay notifications={NotificationLog} onClose={undefined} />

            <HandleAlert
              handleClose={handleClose}
              UserDefinition={UserDefinition}
              isValidCompany={isValidCompany}
              userType={userType}
              newUser={newUser}
              is_Company={Boolean(is_Company)}
              is_Director={is_Director}
              data={redflagData}
              validPrimary={validPrimary}
              NotificationLog={NotificationLog}
              createdUserInstanceId={createdUserInstanceId}
              createdUser={createdUser}
              entityType={entityType}
            />
          </>
        }>
        <DialogContent style={{ minHeight: 500 }}>
          <UserSelection
            StyledSelectCustomerTypeProps={StyledSelectCustomerTypeProps}
            StyledSelectEntityTypeProps={StyledSelectEntityTypeProps}
            StyledSelectUserGroupProps={StyledSelectUserGroupProps}
            regulated={regulated}
            userType={userType}
            entityType={entityType}
          />

          <br />

          {UserDefinition && (
            <Paper elevation={3} style={{ padding: 16 }}>
              <Typography>Add User</Typography>

              {is_Company ? (
                <>
                  {features.redflag ? (
                    <UserCompanyNavigator
                      importedCompany={
                        props.automationValues?.company.third_party_apis
                          .redflagOG
                      }
                      createdUserInstanceId={createdUserInstanceId}
                      handleClose={handleClose}
                      NotificationLog={NotificationLog}
                      setCreatedUserInstanceId={setCreatedUserInstanceId}
                      setData={setRedflagData}
                      setValidCompany={setValidCompany}
                      type="company"
                    />
                  ) : (
                    <RenderSimpleCreate
                      UserDefinition={UserDefinition}
                      newUser={newUser}
                      NotificationLog={NotificationLog}
                      createdUserInstanceId={createdUserInstanceId}
                      handleChange={handleChange}
                      userType={userType}
                      proxy={proxy}
                      setProxy={setProxy}
                    />
                  )}
                </>
              ) : (
                <>
                  {entityType?.value && !userType.includes('Director') && (
                    <RenderSimpleCreateNormal
                      UserDefinition={UserDefinition}
                      newUser={newUser}
                      NotificationLog={NotificationLog}
                      createdUserInstanceId={createdUserInstanceId}
                      handleChange={handleChange}
                      userType={userType}
                    />
                  )}
                </>
              )}

              {is_Director ? (
                <>
                  {features.redflag && (
                    <UserCompanyNavigator
                      createdUserInstanceId={createdUserInstanceId}
                      handleClose={handleClose}
                      NotificationLog={NotificationLog}
                      setCreatedUserInstanceId={setCreatedUserInstanceId}
                      setData={setRedflagData}
                      setValidCompany={setValidCompany}
                      type="director"
                    />
                  )}
                </>
              ) : (
                <>
                  {!userType.includes('Entity') && (
                    <RenderSimpleCreateLight
                      UserDefinition={UserDefinition}
                      newUser={newUser}
                      NotificationLog={NotificationLog}
                      createdUserInstanceId={createdUserInstanceId}
                      handleChange={handleChange}
                      userType={userType}
                    />
                  )}
                </>
              )}
            </Paper>
          )}

          {NotificationLog && NotificationLog.length > 0 && (
            <div style={{ paddingTop: theme.spacing(3) }}>
              <Paper elevation={3} style={{ padding: 16 }}>
                <OverlayExpansion
                  notifications={NotificationLog}
                  onClose={undefined}
                />
              </Paper>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          {createdUserInstanceId && NotificationLog !== null ? (
            <>
              {props.handlecreatedUser && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    props.handlecreatedUser &&
                    props.handlecreatedUser(createdUserInstanceId.toString())
                  }>
                  {HandleButtonLabelsAndAlerts({
                    hasAutomation: props.automationValues !== undefined,
                    NotificationLog,
                    createdUser,
                    isSupplier: isSupplier,
                    type: 'Select',
                    entityType: entityType !== null ? entityType : undefined
                  })}
                </Button>
              )}

              <NavigateToUserButton
                automation={props.automationValues}
                createdUserInstanceId={createdUserInstanceId}
                setOpen={setOpen}
                NotificationLog={NotificationLog}
                UserDefinition={UserDefinition}
                isValidCompany={isValidCompany}
                createdUser={createdUser}
                entityType={entityType}
              />
            </>
          ) : (
            <>
              <CreationButton
                automationValues={props.automationValues}
                userType={userType}
                newUser={newUser}
                setNotificationLog={setNotificationLog}
                NotificationLog={NotificationLog}
                loadnewuser={loadnewuser}
                is_Company={is_Company}
                is_Director={is_Director}
                data={redflagData}
                setCreatedUserInstanceId={setCreatedUserInstanceId}
                UserDefinition={UserDefinition}
                isValidCompany={isValidCompany}
                validPrimary={validPrimary}
                selectedRadio={selectedOption}
                createUser={createUser}
              />
            </>
          )}
        </DialogActions>
      </CustomDialog>
    </>
  );
};
export default NewUserButton;

const UserSelection = ({
  StyledSelectUserGroupProps,
  StyledSelectEntityTypeProps,
  StyledSelectCustomerTypeProps,
  userType,
  entityType,
  regulated
}: {
  StyledSelectUserGroupProps: IUserDefinitionType;
  StyledSelectEntityTypeProps: IEntityType;
  StyledSelectCustomerTypeProps: ICustomerTypeProps;
  userType: string;
  entityType: { label: string; value: string } | null;
  regulated: boolean;
}) => {
  return (
    <Paper elevation={3} style={{ padding: 16, marginTop: 16 }}>
      <Typography variant="h6">Define User</Typography>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box mb={2}>
            <Typography variant="h5">User Group</Typography>
          </Box>

          <Box data-cy="new_user_group_select">
            <StyledSelect {...StyledSelectUserGroupProps} />
          </Box>
        </Grid>

        {userType.includes('Entity') && (
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="h5">
                Entity Type
                {entityType && entityType.value && (
                  <Typography>
                    {regulated ? 'REGULATED' : 'NON-REGULATED'} PARTY
                  </Typography>
                )}
              </Typography>
            </Box>

            <Box data-cy="entity-type-select">
              <StyledSelect {...StyledSelectEntityTypeProps} />
            </Box>
          </Grid>
        )}

        {userType === 'Director' && (
          <Grid item xs={6}>
            <Box mb={2}>
              <Typography variant="h5">Director Type</Typography>
            </Box>

            <Box data-cy="director-type-select">
              <StyledSelect {...StyledSelectCustomerTypeProps} />
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

const RenderSimpleCreate = ({
  UserDefinition,
  newUser,
  NotificationLog,
  createdUserInstanceId,
  handleChange,
  userType,
  proxy,
  setProxy
}: {
  UserDefinition: UserDefinition;
  newUser: UserInstance;
  NotificationLog: INotificationLog[];
  createdUserInstanceId: number | null;
  handleChange: (value: string, name: string) => void;
  userType: string;
  proxy: { UserDefinition: {}; UserInstance: {} };
  setProxy: Dispatch<SetStateAction<{ UserDefinition: {}; UserInstance: {} }>>;
}) => {
  if (createdUserInstanceId) {
    return (
      <Grid item>
        {handleCreatedUserString({ NotificationLog })}
        <QuickUserFromId UserInstanceId={createdUserInstanceId.toString()} />
      </Grid>
    );
  }

  return (
    <Grid item>
      <Typography variant="h3">Create New {UserDefinition.Title}</Typography>
      <Typography variant="caption">Enter A Name And Email Address.</Typography>

      <SearchForAUserInstance
        onSelect={(v) => {
          const newProxy = Object.assign({}, proxy);
          newProxy.UserInstance = v;
          setProxy(newProxy);
        }}
        selected={proxy.UserInstance?.['Title']}
      />
      <br />
      <SimpleUserCreate
        handleChange={handleChange}
        UserDefinition={UserDefinition}
        newUser={newUser}
        userType={userType}
        extremeCautionList={[]}
      />
    </Grid>
  );
};

const RenderSimpleCreateNormal = ({
  UserDefinition,
  newUser,
  NotificationLog,
  createdUserInstanceId,
  handleChange,
  userType
}: {
  UserDefinition: UserDefinition;
  newUser: UserInstance;
  NotificationLog: INotificationLog[];
  createdUserInstanceId: number | null;
  handleChange: (value: string, name: string) => void;
  userType: string;
}) => {
  const { contactDoNotDealList } = useDoNotDealListNonRedFlag('');
  const [nameExtremeCautionList, setNameExtremeCautionList] = React.useState<
    IContactDoNotDeal[]
  >([]);
  React.useEffect(() => {
    const nameEntries = contactDoNotDealList.filter(
      (entry): entry is IContactDoNotDeal =>
        'name' in entry && entry.name !== undefined
    );
    setNameExtremeCautionList(nameEntries);
  }, [contactDoNotDealList]);

  if (createdUserInstanceId) {
    return (
      <Grid item>
        {handleCreatedUserString({ NotificationLog })}
        <QuickUserFromId UserInstanceId={createdUserInstanceId.toString()} />
      </Grid>
    );
  }

  return (
    <Grid item>
      <Typography variant="h3">Create New {UserDefinition.Title}</Typography>
      <Typography variant="caption">Enter a name</Typography>

      <Grid container>
        <Grid item xs={12} style={{ width: '100%' }}>
          <SimpleUserCreate
            handleChange={handleChange}
            UserDefinition={UserDefinition}
            newUser={newUser}
            userType={userType}
            extremeCautionList={nameExtremeCautionList}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const RenderSimpleCreateLight = ({
  UserDefinition,
  newUser,
  NotificationLog,
  createdUserInstanceId,
  handleChange,
  userType
}: {
  UserDefinition: UserDefinition;
  newUser: UserInstance;
  NotificationLog: INotificationLog[];
  createdUserInstanceId: number | null;
  handleChange: (value: string, name: string) => void;
  userType: string;
}) => {
  const { contactDoNotDealList } = useDoNotDealListNonRedFlag('');
  const [emailExtremeCautionList, setEmailExtremeCautionList] = React.useState<
    IContactDoNotDeal[]
  >([]);
  React.useEffect(() => {
    const emailEntries = contactDoNotDealList.filter(
      (entry): entry is IContactDoNotDeal =>
        'email' in entry && entry.email !== undefined
    );
    setEmailExtremeCautionList(emailEntries);
  }, [contactDoNotDealList]);

  if (createdUserInstanceId) {
    return (
      <Grid item>
        {handleCreatedUserString({ NotificationLog })}
        <QuickUserFromId UserInstanceId={createdUserInstanceId.toString()} />
      </Grid>
    );
  }

  return (
    <Grid item>
      <Typography variant="h3">Simple Create</Typography>
      <Typography variant="caption">
        Enter a name and email address to create user
      </Typography>

      <SimpleUserCreate
        handleChange={handleChange}
        UserDefinition={UserDefinition}
        newUser={newUser}
        userType={userType}
        extremeCautionList={emailExtremeCautionList}
      />
    </Grid>
  );
};

const handleCreatedUserString = ({ NotificationLog }) => {
  if (NotificationLog === null) {
    return;
  }

  return 'You Have Created';
};
