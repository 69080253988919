import axios, { AxiosRequestConfig } from 'axios';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';
import { firebase } from 'redux/firebase';

const { dispatch } = store;

export const fetchListContactCount = async ({ list_id }) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/lists/${list_id}/contacts/count`;
  const config: AxiosRequestConfig = { method: 'GET', url, headers: { token } };

  return axios(config)
    .then((res) => res.data.contact_count)
    .catch((e) => {
      dispatch(
        createNotification(
          errorNotif(
            'Looks like you have deleted the list that was attached to this email'
          )
        )
      );
    });
};
