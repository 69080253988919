export const initData = {
  title: 'New Board',
  tasks: {
    'task-1': { id: 'task-1', title: 'Example Task A' },
    'task-2': { id: 'task-2', title: 'Example Task B' },
    'task-3': { id: 'task-3', title: 'Example Task C' },
    'task-4': { id: 'task-4', title: 'Example Task D' }
  },
  columns: {
    'column-1': {
      id: 'column-1',
      title: 'Backlog',
      taskIds: ['task-1', 'task-2', 'task-3', 'task-4']
    },
    'column-2': {
      id: 'column-2',
      title: 'To do',
      taskIds: []
    },
    'column-3': { id: 'column-3', title: 'Done', taskIds: [] }
  },
  columnOrder: ['column-1', 'column-2', 'column-3']
};

export const initTask = {
  id: '',
  content: ''
};

export const initBoard = {
  title: 'New Board',
  tasks: {},
  columns: {},
  columnOrder: []
};
