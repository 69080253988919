import { useContext, useState } from 'react';
import Output from 'components/Calculator/Output';
import { Grid, ButtonGroup, useTheme, Typography } from '@material-ui/core';
import { INIT_STATE } from 'components/Calculator/Utils/calculations';
import { Alert, Skeleton } from '@material-ui/lab';
import { StepperContext } from 'components/Stepper/context';
import { IndividualCalculatorInterface, IProps } from '../interfaces';
import OutputProvider, {
  OutputInterface
} from 'components/Calculator/Output/context/OutputContext';
import StepDocumentHTML from 'components/Stepper/components/Documents/StepDocumentHTML';
import { useProcess } from 'hooks';
import useDecline from 'hooks/useDeals/useDecline';
import RenderButtons from './QuoteButtons';
import { QuoteObjectContext } from '../context/QuoteObjectContext';
import { CompleteObjectInstance } from 'types/interfaces';

export const CalculatorObjectLayout = ({
  CompleteObjectInstance,
  children,
  props,
  readOnly
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  children: React.ReactNode;
  props: IProps;
  readOnly?: boolean;
}) => {
  const theme = useTheme();
  const { quotesRefreshing } = useContext(StepperContext);

  const { landingpage } = useProcess();
  const { isDeclined } = useDecline({
    FieldDefinitionDict: props.FieldDefinitionDict,
    CompleteObjectInstanceList: props.CompleteObjectInstanceList,
    object: CompleteObjectInstance
  });

  const selected = CompleteObjectInstance.ObjectInstance.Selected;
  return (
    <Grid
      item
      style={{ padding: 8 }}
      xs={props.display === 'clientView' ? 12 : 12}>
      {landingpage && (
        <StepDocumentHTML
          downloadableIcon
          customTitle
          object={CompleteObjectInstance}
        />
      )}

      <div style={{ paddingBottom: theme.spacing(2) }} />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%'
        }}>
        <div />
        <div style={{ paddingBottom: landingpage ? theme.spacing(3) : 8 }}>
          {readOnly ? (
            <Alert severity="info">
              <Typography variant="h6">
                This Quote Id: {CompleteObjectInstance.ObjectInstance.Id} Is
                Read Only
              </Typography>
            </Alert>
          ) : (
            <>
              {quotesRefreshing ? (
                <ButtonGroup variant="text">
                  <Skeleton variant="circle" width={40} height={40} />
                  <Skeleton variant="circle" width={40} height={40} />
                  <Skeleton variant="circle" width={40} height={40} />
                  <Skeleton variant="circle" width={40} height={40} />
                  <Skeleton variant="circle" width={40} height={40} />
                </ButtonGroup>
              ) : (
                <RenderButtons
                  isDeclined={isDeclined}
                  object={CompleteObjectInstance}
                  selected={selected}
                  props={props}
                />
              )}
            </>
          )}
        </div>
      </div>

      {children}
    </Grid>
  );
};

export const IndividualCalculator = (props: IndividualCalculatorInterface) => {
  const [calculation, setCalculation] = useState(INIT_STATE);
  const { isDeclined } = useDecline({
    FieldDefinitionDict: props.FieldDefinitionDict,
    CompleteObjectInstanceList: props.CompleteObjectInstanceList,
    object: props.ObjectInstance
  });

  const selected = props.ObjectInstance.ObjectInstance.Selected;
  const OutputProviderValues = {
    ...props,
    calculation,
    isOverview: false,
    readOnly: props.readOnly ? props.readOnly : false,
    setCalculation,
    selected,
    isDeclined
  } as OutputInterface;

  return (
    <OutputProvider props={OutputProviderValues}>
      <Output />
    </OutputProvider>
  );
};
