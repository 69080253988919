import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { validateEmail } from 'helpers/validateEmail';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { WarningNotice } from './WarningNotice';
import { CreateUserDialogProps } from './interfaces';
import { IDirector, IRedflagAPIData } from 'types/redflagInterfaces';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  ButtonGroup,
  Button,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import RedFlagCreateImport from 'components/User/RedFlagCreateImport';
import { redFlagMapper } from 'components/Table/RedFlag/functions';
import BusinessIcon from '@material-ui/icons/Business';
import ContactsIcon from '@material-ui/icons/Contacts';
import { LinearProgress } from '@material-ui/core';
import { AlertTitle } from '@material-ui/lab';
import { getDefinition } from 'redux/actions/user';
import { BugTracker } from 'Utils/Bugtracker';
import {
  CompleteObjectDefinition,
  UserDefinition,
  CompleteUserInstance
} from 'types/interfaces';
import {
  companyToImport,
  directorToImport
} from 'redux/actions/CompanyNavigator';
import { CustomDialog } from 'common/Dialog';
import { StyledSelect } from 'common';
import * as gtag from 'Utils/gtag';
import { useTypedSelector } from 'redux/reducers';
import { UserInstance } from 'types/interfaces';
import { IStateProps, IData } from './interfaces';
import { IOptionType } from 'common/StyledSelect';

export const CreateUserDialog = ({
  props,
  stateProps
}: {
  props: CreateUserDialogProps;
  stateProps: IStateProps;
}) => {
  const { setIsPrimary, setData, data } = stateProps;

  const history = useHistory();
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [showNavTo, setShowNavTo] = useState(false);
  const [state, setState] = useState({
    UserDefinition: props.UserDefinition || ({} as UserDefinition)
  });

  const [isVaild, setIsVaild] = useState(false);
  const [hasEmail, setHasEmail] = useState(false);
  const [options, setOptions] = useState<IOptionType[]>();
  const [blurredDirectors, setBlurredDirectors] = useState<{
    [key: number]: boolean;
  }>({});

  const [directors, setDirectors] = useState<IDirector[] | null>(null);
  const [supplier, setSupplier] = useState<IRedflagAPIData>();

  const [processToStart, setProcessToStart] = useState<{
    label: string;
    value: number;
  }>({
    label: '',
    value: 0
  });

  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const baseURL = useTypedSelector((s) => s.config.baseURL);
  const token = useTypedSelector((s) => s.user.auth.token);
  const user = useTypedSelector((s) => s.user.user);

  const is_directorship = props?.data?.type === 'directorship';
  const isSupplier =
    state?.UserDefinition?.Id === 26
      ? true
      : state?.UserDefinition?.Title === 'Suppliers'
      ? true
      : false;

  const is_company = props?.data?.type === 'company';
  const handleClose = () => {
    if (props?.setOpen !== undefined) props.setOpen(false);
  };

  const handleChange = async (v, name) =>
    setState((s) => ({ ...s, [name]: v }));

  useEffect(() => {
    const options: IOptionType[] = [];
    UserDefinitionList?.forEach((item: UserDefinition) => {
      const search = ',';
      const keywords = item.Description.split(search);
      if (keywords.includes(props?.data?.type)) {
        const extendedItem: IOptionType = {
          value: item.Id,
          label: item.Title
        };
        options.push(extendedItem);
      }
    });

    setOptions(options);
  }, []);

  useEffect(() => {
    if (directors) {
      const PopulatedEmails = directors.filter(
        (d: IDirector) => d.email.length !== 0
      );
      const isAtLeastOneEntry = PopulatedEmails.length > 0;
      const ValidPopulatedEmails = PopulatedEmails.filter((d: IDirector) =>
        validateEmail(d.email)
      );
      const allNonEmptyEmailsAreValid =
        ValidPopulatedEmails.length === PopulatedEmails.length;

      const allBlurred = PopulatedEmails.every(
        (d: IDirector) => blurredDirectors[d.id] === true
      );

      if (isAtLeastOneEntry) {
        if (allNonEmptyEmailsAreValid && allBlurred) {
          setHasEmail(true);
        } else {
          setHasEmail(false);
        }
      } else {
        setHasEmail(false);
      }
    }
  }, [directors, blurredDirectors]);

  const updateSubmissionData = async (props: Partial<IData>) => {
    // When we update the submissions data we are triggering an infinite loop.
    // I think its the UserDefinition update that is triggering this?
    // using the full Idata props type to update
    let validPrimary =
      directors && directors?.find((el: IDirector) => el.isPrimary);
    const redflag: CompleteUserInstance = await redFlagMapper(props);

    props.redflag = redflag;
    const userDefinition = await getDefinition({
      token,
      action: 'define',
      UserDefinitionId: state?.UserDefinition?.Id
    });

    if (props?.company?.data.type !== 'directorship') {
      if (userDefinition?.data?.CompleteObjectDefinitionList) {
        setIsVaild(false);
        userDefinition?.data?.CompleteObjectDefinitionList?.forEach(
          (CompleteObjectDefinition: CompleteObjectDefinition) => {
            if (
              CompleteObjectDefinition.ObjectDefinition.Title ===
              'Company Details'
            )
              setIsVaild(true);
          }
        );

        if (isSupplier && directors) {
          const allDirectorsBlurred = () => {
            return directors
              .filter((director: IDirector) => validateEmail(director.email))
              .every(
                (director: IDirector) =>
                  blurredDirectors && blurredDirectors[director.id] === true
              );
          };

          const completedBlurredDirectors = allDirectorsBlurred();
          const allValidEmails = directors
            .filter((Director: IDirector) => Director.email.trim() !== '')
            .every((Director: IDirector) => validateEmail(Director.email));

          if (completedBlurredDirectors) setHasEmail(allValidEmails);
        }

        if (!validPrimary) {
          setIsPrimary(false);
        } else {
          setIsPrimary(true);
        }
      } else {
        setIsVaild(true);
      }

      setData(props);
      // setData({
      //   redflag,
      //   UserInstance,
      //   isToBeRelated,
      //   company,
      //   data,
      //   relationship
      // });
    } else {
      setIsVaild(true);
      setData(props);
      // setData({
      //   redflag,
      //   UserInstance,
      //   isToBeRelated,
      //   company,
      //   data,
      //   relationship
      // });
    }
  };

  const handleAlmightySubmit = async (data) => {
    setLoading(true);

    const { relationship } = data;
    const { company } = props;

    const ParentUserDefinition = await getDefinition({
      token,
      action: 'define',
      UserDefinitionId: props.UserDefinition?.Id
    });

    /**
     * DIRECTOR
     */
    if (is_directorship) {
      const directorPayload = {
        ChildUser: {
          Meta: directors as IDirector[],
          ProcessToStart: processToStart as { label: string; value: number },
          ...(ParentUserDefinition.data as UserDefinition)
        },
        LoggedInUser: user as UserInstance,
        Process: processToStart as { label: string; value: number },
        RedflagAPIData: props.data,
        Token: token as string,
        baseURL
      };

      return directorToImport(directorPayload)
        .then((res) => {
          const createdDirectorInstance = res.data.find(
            (i) => i.event === 'User Creation (Director)'
          );
          const createdDirectorInstanceId = createdDirectorInstance?.data;
          const type = createdDirectorInstance?.type;

          createdDirectorInstanceId &&
            props.selectCreated &&
            props.selectCreated(createdDirectorInstanceId);
          if (res.status === 200) {
            gtag.event({
              action: 'Director Created',
              feature: 'Company Navigator',
              message: directors?.[0]?.id
            });
            props?.setNotificationLog && props?.setNotificationLog(res.data);
            if (type === 'SUCCESS') setShowNavTo(true);
          }
        })
        .catch((e) => BugTracker.notify(e));
    }

    const ChildUserDefinition = await getDefinition({
      token,
      action: 'define',
      UserDefinitionId: relationship.Id
    });

    /**
     * COMPANY
     */
    try {
      const isCompany = company && company?.data?.type === 'company';
      const companyPayload = {
        ParentUser: ParentUserDefinition.data as UserDefinition,
        ChildUser: {
          Meta: directors as IDirector[],
          ProcessToStart: processToStart as { label: string; value: number },
          ...(ChildUserDefinition.data as UserDefinition)
        },
        LoggedInUser: user as UserInstance,
        RedflagAPIData:
          isSupplier && !isCompany
            ? (supplier as IRedflagAPIData | undefined)
            : (company as IRedflagAPIData | undefined),
        baseURL: baseURL as string,
        Token: token as string
      };

      const res = await companyToImport(companyPayload);

      const createdCompanyInstanceId = res.data.find(
        (i) => i.event === 'User Creation (Company)'
      )?.data;

      createdCompanyInstanceId &&
        props.selectCreated &&
        props.selectCreated(createdCompanyInstanceId);

      if (res.status === 200) {
        gtag.event({
          action: 'Company Created',
          feature: 'COMPANY NAVIGATOR',
          message: company?.data?.attributes?.name
        });
        setShowNavTo(true);
        return props?.setNotificationLog && props?.setNotificationLog(res.data);
      }
    } catch (e) {
      BugTracker.notify(e);
    }

    handleClose();
    return setLoading(false);
  };

  const StyledSelectProps = {
    onChange: (v) => handleChange(v, 'UserDefinition'),
    options: options ? options : null,
    label: 'User Definition',
    placeholder: 'Select User Group',
    value: state?.UserDefinition,
    isDisabled: !props.UserDefinition || props.automation !== undefined,
    useMaterialUI: false
  };

  return (
    <CustomDialog
      open={props.open}
      handleClose={handleClose}
      alert={
        <WarningNotice
          is_directorship={is_directorship}
          data={data}
          props={props}
          isSupplier={isSupplier}
          hasEmail={hasEmail}
        />
      }>
      <DialogContent
        style={{
          minHeight: 500,
          height: '100%',
          minWidth: 250
        }}>
        <br />
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}>
                {props?.data?.type === 'company' && (
                  <BusinessIcon
                    style={{
                      fontSize: 75,
                      color: theme.palette.primary.main
                    }}
                  />
                )}
                {props?.data?.type === 'directorship' && (
                  <ContactsIcon
                    style={{
                      fontSize: 75,
                      color: theme.palette.primary.main
                    }}
                  />
                )}
              </div>
              <br />
              <p
                style={{
                  fontSize: 15,
                  fontWeight: 800,
                  color: theme.palette.primary.main,
                  textTransform: 'uppercase'
                }}>
                Create {props?.data?.type}
              </p>
            </div>
          </Grid>

          <Grid item>
            <>
              <Typography variant="h3">Step 1</Typography>
              <Typography variant="h6">Select a User Group</Typography>
              <Typography variant="caption">
                Make sure the user group that you select is a valid one for the
                type of user you are creating. For example you cannot create a
                company in a user group dedicated for directors
              </Typography>
              <br />
              <br />
            </>
            <div data-cy="select-user-group">
              <StyledSelect {...StyledSelectProps} />
            </div>
          </Grid>
          {!isVaild && data && (
            <Grid item>
              <Alert variant="outlined" severity="error">
                <AlertTitle>Error</AlertTitle>

                <Typography>
                  You cannot create a <strong>Company</strong> in this user
                  group because it does not contain a
                  <strong>Company Details Object</strong>
                  {''} with a Company Number Field.
                </Typography>
              </Alert>
            </Grid>
          )}
          {state && (
            <Grid item>
              <RedFlagCreateImport
                UserDefinition={state.UserDefinition}
                data={props.data}
                company={props.company}
                directorships={props.directorships}
                updateSubmissionData={updateSubmissionData}
                directors={directors}
                setDirectors={setDirectors}
                setSupplier={setSupplier}
                setProcessToStart={setProcessToStart}
                setBlurredDirectors={setBlurredDirectors}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions style={{ alignItems: 'center' }}>
        {loading ? (
          <div style={{ width: '100%' }}>
            <LinearProgress />
          </div>
        ) : (
          <>
            {showNavTo ? (
              <ButtonGroup
                fullWidth
                orientation="horizontal"
                color="primary"
                variant="outlined">
                <Button onClick={handleClose}>Close</Button>
                <Button
                  onClick={() => {
                    if (is_company) {
                      // If it's a company get created
                      const createdCompanyInstanceId =
                        props?.NotificationLog?.find(
                          (i) => i.event === 'User Creation (Company)'
                        )?.data;

                      const createdCompanyUserDefinitionId =
                        data?.UserDefinition?.Id;

                      const path = `/UserDefinition/${createdCompanyUserDefinitionId}/${createdCompanyInstanceId}`;
                      history.push(path);
                    }

                    if (is_directorship) {
                      // if is a director
                      const createdDirectorInstanceId =
                        props?.NotificationLog?.find(
                          (i) => i.event === 'User Creation (Director)'
                        )?.data;

                      const createdDirectorUserDefinitionId =
                        data?.UserDefinition?.Id;

                      const path = `/UserDefinition/${createdDirectorUserDefinitionId}/${createdDirectorInstanceId}`;

                      history.push(path);
                    }

                    props?.setNotificationLog &&
                      props?.setNotificationLog(null);
                    props.handleClose && props.handleClose();
                    if (props?.setOpen !== undefined) props?.setOpen(false);
                  }}>
                  Go To{' '}
                  {is_directorship
                    ? `${props?.data?.attributes?.first_name || ''} ${' '} ${
                        props?.data?.attributes?.surname || ''
                      }`
                    : `${props?.data?.attributes?.name}`}
                  {`'s`} profile
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup fullWidth orientation="horizontal" color="primary">
                <Button data-cy="cancel-button" onClick={handleClose}>
                  Cancel
                </Button>

                {is_directorship &&
                  validateEmail(data?.UserInstance?.UserInstanceEmail) && (
                    <Button
                      data-cy="create-director-btn"
                      onClick={() => handleAlmightySubmit(data)}
                      color="primary"
                      variant="contained">
                      {props?.selectCreated ? 'Create' : 'Create Director'}
                    </Button>
                  )}

                {CreationLogic({ is_company, data, hasEmail }) ? (
                  <Button
                    data-cy="create-company-btn"
                    onClick={() => handleAlmightySubmit(data)}
                    color="primary"
                    variant="contained">
                    {props?.selectCreated
                      ? 'Create'
                      : 'Create Company & Directors'}
                  </Button>
                ) : (
                  <>
                    {isSupplier && is_company && (
                      <>
                        {CreationLogic({ is_company, data, hasEmail }) && (
                          <Button
                            data-cy="create-company-btn"
                            onClick={() => handleAlmightySubmit(data)}
                            color="primary"
                            variant="contained">
                            {props?.selectCreated
                              ? 'Create'
                              : 'Create Company & Directors'}
                          </Button>
                        )}
                      </>
                    )}
                  </>
                )}
              </ButtonGroup>
            )}
          </>
        )}
      </DialogActions>
    </CustomDialog>
  );
};

const CreationLogic = ({
  is_company,
  data,
  hasEmail
}: {
  is_company: boolean;
  data: Partial<IData>;
  hasEmail: boolean;
}) => {
  if (is_company && data != null) {
    // if (isPrimary) return true;
    if (hasEmail) return true;
    return false;
  }
  return false;
};
