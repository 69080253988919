import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Cashflow } from './';
import { CashFlowItem } from 'types/calculatorInterfaces';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  data: CashFlowItem[];
  title: string;
}

export default function AlertDialogSlide({ data, title }: Props) {
  const [open, setOpen] = React.useState(false);
  return (
    <div>
      <Button
        // className={classes.button}
        fullWidth
        color="primary"
        onClick={() => setOpen(true)}
        variant="contained">
        View Cashflow
        {/* <FormatListNumberedRoundedIcon className={classes.rightIcon} /> */}
      </Button>
      <Dialog
        TransitionComponent={Transition}
        keepMounted
        maxWidth="lg"
        onClose={() => setOpen(!open)}
        open={open}>
        <DialogContent>
          <Cashflow data={data} title={title} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setOpen(!open)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
