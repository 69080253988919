import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { Alert, Skeleton } from '@material-ui/lab';
import { Message } from '@microsoft/microsoft-graph-types';
import { ThreadContext } from 'components/MessageHub/components/Threads/context/ThreadsContext';
import { IFile } from 'components/MessageHub/interfaces';
import { useContext, useState } from 'react';
import { theme } from 'theme';
import { ConversationContext } from '../../context/ConversationContext';
import CloseIcon from '@material-ui/icons/Close';
import { useTypedSelector } from 'redux/reducers';
import { BugTracker } from 'Utils/Bugtracker';
import {
  FileUpload,
  gridArray,
  renderFileItem
} from 'components/MessageHub/utils/Files';
import { getCompleteUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { UserInstance } from 'types/interfaces';

const ReplyEmail = ({
  messageId,
  message,
  type,
  setIsReplying,
  clearAll
}: {
  messageId: string;
  message: Message;
  type: 'reply' | 'replyAll';
  setIsReplying: (isReplying: boolean) => void;
  clearAll: () => void;
}) => {
  const user = useTypedSelector((state) => state.user.user);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const { replyToMessage, replyAll } = useContext(ConversationContext);

  const [replyContent, setReplyContent] = useState<string>('');
  const [attachments, setAttachments] = useState<IFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const handleUploadToFile = async (file: File): Promise<File> => {
    const fileWithModifiedDate = new File([file], file.name, {
      lastModified: file.lastModified,
      type: file.type
    });

    return fileWithModifiedDate;
  };

  const handleFileSelection = async (newFiles: any): Promise<void> => {
    try {
      const promises = newFiles.map(handleUploadToFile);
      const newFileArray = await Promise.all(promises);

      const newAttachments = [...attachments, ...newFileArray];
      setAttachments(newAttachments);
    } catch (e) {
      console.log(e);
    }
  };

  const recipientsString = message.toRecipients
    ?.map((recipient) => `${recipient.emailAddress?.address}`)
    .join(', ');

  const handleSendClick = async (e) => {
    setLoading(true);
    e.stopPropagation();

    try {
      const UserInstance = (await getCompleteUserInstanceDetail({
        baseUrl,
        UserInstanceId: user.Id,
        action: 'UserInstance'
      })) as UserInstance;
      if (!UserInstance) return;

      const emailFooter = UserInstance.EmailFooter;
      const footerHtml = emailFooter ? `<p>${emailFooter}</p>` : '';
      const contentToSend = replyContent.trim();
      if (contentToSend) {
        const finalContent = `${contentToSend}${footerHtml}`;
        const replyOptions = {
          messageId,
          replyContent: finalContent,
          files: attachments,
          message
        };
        if (type === 'replyAll') {
          await replyAll(replyOptions);
        } else {
          await replyToMessage(replyOptions);
        }
        setLoading(false);
        setIsReplying(false);
        setReplyContent('');
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  return (
    <>
      <div style={{ padding: theme.spacing(2), width: '100%' }}>
        <Alert severity="info" elevation={2}>
          <Typography>
            <b>Replying to:</b>{' '}
            {type === 'reply'
              ? message?.from?.emailAddress?.address
              : recipientsString}
          </Typography>
        </Alert>
      </div>

      <Grid
        container
        direction="column"
        spacing={2}
        style={{ paddingBottom: theme.spacing(2) }}>
        {gridArray({
          files:
            attachments.filter((file) => file.type.includes('image/')) || [],
          gridSize: 6
        }).map((fileChunk, chunkIndex) => (
          <Grid key={`photo-row-${chunkIndex}`} item container spacing={1}>
            {fileChunk.map((file, index) => (
              <Grid item key={index}>
                {renderFileItem(file, false)}
              </Grid>
            ))}
          </Grid>
        ))}

        {gridArray({
          files:
            attachments.filter((file) => !file.type.includes('image/')) || [],
          gridSize: 2
        }).map((fileChunk, chunkIndex) => (
          <Grid key={`doc-row-${chunkIndex}`} item container spacing={1}>
            {fileChunk.map((file, index) => (
              <Grid item key={index}>
                {renderFileItem(file, false)}
              </Grid>
            ))}
          </Grid>
        ))}
      </Grid>

      <Grid item style={{ width: '100%' }}>
        <FileUpload handleFileUpload={handleFileSelection} />
      </Grid>

      <div style={{ paddingBottom: theme.spacing(2) }} />

      <Grid container spacing={2} alignItems="flex-end">
        <div
          className="ckeditor-custom"
          style={{ width: '100%' }}
          onClick={(e) => e.stopPropagation()}>
          <CKEditor
            config={{
              toolbar: [
                'heading',
                '|',
                'bold',
                'italic',
                'link',
                'bulletedList',
                'numberedList',
                'blockQuote',
                'undo',
                'redo'
              ]
            }}
            editor={ClassicEditor}
            data={replyContent}
            onChange={(event, editor) => {
              const data = editor.getData();
              setReplyContent(data);
            }}
          />
        </div>
        {loading ? (
          <>
            <Grid item>
              <Skeleton
                variant="rect"
                width={90}
                height={36}
                style={{ borderRadius: theme.shape.borderRadius }}
              />
            </Grid>
            <Grid item>
              <Skeleton
                variant="rect"
                width={90}
                height={36}
                style={{ borderRadius: theme.shape.borderRadius }}
              />
            </Grid>
          </>
        ) : (
          <Grid
            container
            direction="row"
            spacing={2}
            style={{ paddingTop: theme.spacing(1) }}>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={handleSendClick}
                endIcon={<Send />}>
                Send
              </Button>
            </Grid>

            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ textTransform: 'none' }}
                onClick={clearAll}
                endIcon={<CloseIcon />}>
                Close
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ReplyEmail;
