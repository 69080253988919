const PraeturaProposal = {
  customer: {
    website: '',
    industry: '',
    sic_code: '',
    company_type: '',
    trading_name: '',
    trading_address: {
      street: '',
      country: '',
      postcode: '',
      city_or_town: '',
      county_or_state: ''
    },
    vat_information: {
      vat_number: '',
      is_registered_for_vat: false
    },
    trading_from_date: '',
    registered_address: {
      street: '',
      country: '',
      postcode: '',
      city_or_town: '',
      county_or_state: ''
    },
    external_customer_id: '',
    registered_company_name: '',
    company_registration_number: '',
    has_consented_to_credit_check: false,
    has_consented_to_privacy_policy: false
  },
  loanApplication: {
    urgency: '',
    applicants: [
      {
        address: {
          street: '',
          country: '',
          postcode: '',
          city_or_town: '',
          county_or_state: ''
        },
        last_name: '',
        first_name: '',
        guarantees: {
          can_provide_personal_guarantee: false
        },
        middle_name: '',
        company_role: '',
        date_of_birth: '',
        contact_details: {
          email: '',
          other_phone_number: '',
          mobile_phone_number: ''
        },
        is_main_applicant: false,
        has_consented_to_credit_check: false,
        has_consented_to_privacy_policy: false
      }
    ],
    introducer: {
      id: '',
      external_id: '',
      contact_details: {
        email: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        other_phone_number: '',
        mobile_phone_number: ''
      }
    },
    customer_id: '',
    party_to_contact: '',
    external_customer_id: '',
    asset_finance_request: {
      assets: [
        {
          manufacturer: '',
          model: '',
          category: '',
          condition: '',
          is_insured: false,
          description: '',
          vat_per_unit: 0,
          cost_per_unit: 0,
          serial_number: '',
          number_of_units: 0,
          manufacture_date: '',
          manufacture_year: 0,
          registration_number: ''
        }
      ],
      currency: '',
      duration: {
        unit: '',
        amount: 0
      },
      is_refinance: false,
      product_type: '',
      balloon_amount: 0,
      vat_paid_up_front_amount: 0,
      deposit_or_initial_rental_amount: 0
    },
    // invoice_finance_request: {
    //   annual_turnover: 0,
    //   currency: ''
    // },
    // commercial_loan_request: {
    //   currency: '',
    //   amount: 0,
    //   duration: {
    //     unit: '',
    //     amount: 0
    //   }
    // },
    external_application_id: '',
    detailed_funding_purpose: ''
  }
};

export default PraeturaProposal;
