import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Grid,
  Typography,
  Paper,
  IconButton,
  Button,
  Tooltip
} from '@material-ui/core';
import { useSenderColors } from 'components/MessageHub/hooks/useSenderColors';
import { theme } from 'theme';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import ForwardIcon from '@material-ui/icons/Forward';
import ListIcon from '@material-ui/icons/List';
import Reply from './components/Replies/Reply';
import ReplyEmail from './components/Replies/ReplyEmail';
import ReplyParent from './components/Replies/ReplyParent';
import { ThreadContext } from '../Threads/context/ThreadsContext';
import ForwardEmail from './components/ForwardEmail';
import { useTypedSelector } from 'redux/reducers';
import { Body } from 'common/HTMLRenderer';
import {
  Attachment,
  FileAttachment,
  ItemBody,
  NullableOption
} from '@microsoft/microsoft-graph-types';
import { UserInstance } from 'types/interfaces';
import { ConversationContext } from './context/ConversationContext';
import { SkeletonArchive } from '../Threads/components';
import { Alert } from '@material-ui/lab';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { SettingsEthernet } from '@material-ui/icons';

const defaultTime = '1970-01-01';
const Conversation = ({ setHasMessage }) => {
  const [showAllReplies, setShowAllReplies] = useState<boolean>(false);
  const { openThreads, selectedThread } = useContext(ThreadContext);
  const { setFullScreen, fullScreen } = useContext(MessageHubContext);

  useEffect(() => {
    return () => {
      setShowAllReplies(false);
    };
  }, [openThreads[selectedThread]]);

  const user = useTypedSelector((state) => state.user.user);
  const getSenderColor = useSenderColors();

  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [isForwarding, setIsForwarding] = useState<boolean>(false);

  const [type, setType] = useState<'reply' | 'replyAll'>('reply');
  const [expandedReplyId, setExpandedReplyId] = useState<string>('');

  const selectedMessage = openThreads[selectedThread]?.parentMessage;
  if (!selectedMessage) {
    setHasMessage(false);
    return null;
  }
  setHasMessage(true);
  const clearAll = () => {
    setIsForwarding(false);
    setIsReplying(false);
  };

  const handleReplyClick = () => {
    setIsReplying(true);
    setIsForwarding(false);
    setType('reply');
  };

  const handleReplyAllClick = () => {
    setIsReplying(true);
    setIsForwarding(false);
    setType('replyAll');
  };

  const handleForwardClick = () => {
    setIsForwarding(true);
    setIsReplying(false);
  };

  const {
    sender = {},
    subject = '',
    receivedDateTime = '',
    toRecipients = [],
    id = '',
    body = {},
    attachments = []
  } = selectedMessage;

  const senderName =
    (sender && sender.emailAddress && sender.emailAddress?.name) || '';
  const recipientsString =
    toRecipients &&
    toRecipients
      .map((recipient) => `${recipient.emailAddress?.address}`)
      .join(', ');

  const replies = (openThreads[selectedThread]?.replies || []).sort(
    (a, b) =>
      new Date(b.receivedDateTime || defaultTime).getTime() -
      new Date(a.receivedDateTime || defaultTime).getTime()
  );

  const visibleReplies = showAllReplies ? replies : replies.slice(0, 2);
  return (
    <Grid item xs={12}>
      <EmailHeader
        subject={subject}
        fullScreen={fullScreen}
        setFullScreen={setFullScreen}
      />

      <Paper elevation={2} style={{ padding: theme.spacing(2) }}>
        <EmailInfo
          senderName={senderName}
          recipientsString={recipientsString}
          receivedDateTime={receivedDateTime}
          handleReplyClick={handleReplyClick}
          handleReplyAllClick={handleReplyAllClick}
          handleForwardClick={handleForwardClick}
          getSenderColor={getSenderColor}
        />
        {isReplying && (
          <Paper style={{ padding: theme.spacing(2) }}>
            <ReplyEmail
              messageId={id}
              message={selectedMessage}
              setIsReplying={setIsReplying}
              type={type}
              clearAll={clearAll}
            />
          </Paper>
        )}
        {isForwarding && (
          <Paper style={{ padding: theme.spacing(2) }}>
            <ForwardEmail
              messageId={id}
              message={selectedMessage}
              setIsForwarding={setIsForwarding}
              clearAll={clearAll}
            />
          </Paper>
        )}
        <EmailBody body={body} attachments={attachments} user={user} />
        {/* <EmailActions
          isReplying={isReplying}
          handleReplyClick={handleReplyClick}
          handleReplyAllClick={handleReplyAllClick}
        /> */}
      </Paper>
      {replies.length > 2 && !showAllReplies && (
        <div style={{ paddingTop: theme.spacing(1) }}>
          <LoadMoreReplies
            onClick={() => setShowAllReplies(true)}
            remainingCount={replies.length - 2}
          />
        </div>
      )}
      {visibleReplies.map((reply, index) => {
        return (
          <div key={index} style={{ paddingTop: theme.spacing(1) }}>
            <ReplyParent
              reply={reply}
              expandedReplyId={expandedReplyId}
              setExpandedReplyId={setExpandedReplyId}>
              <Reply isExpanded={expandedReplyId === reply.id} reply={reply} />
            </ReplyParent>
          </div>
        );
      })}
    </Grid>
  );
};

const EmailHeader = ({
  subject,
  fullScreen,
  setFullScreen
}: {
  subject: NullableOption<string>;
  fullScreen: boolean;
  setFullScreen: React.Dispatch<SetStateAction<boolean>>;
}) => (
  <div style={{ paddingBottom: theme.spacing(1) }}>
    <Paper
      elevation={2}
      style={{
        height: 50,
        display: 'flex',
        alignItems: 'center'
      }}>
      <div style={{ paddingLeft: theme.spacing(1) }}>
        <Tooltip arrow title={'Fullscreen Conversation'}>
          <IconButton size="small" onClick={() => setFullScreen(!fullScreen)}>
            <SettingsEthernet fontSize="medium" />
          </IconButton>
        </Tooltip>
      </div>
      <Tooltip
        title={subject || ''}
        style={{ width: '100%' }}
        arrow
        placement="bottom">
        <Typography
          variant="h4"
          align="center"
          noWrap
          style={{
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: '100%'
          }}>
          Subject: {subject}
        </Typography>
      </Tooltip>
    </Paper>
  </div>
);

const EmailInfo = ({
  senderName,
  recipientsString,
  receivedDateTime,
  handleReplyClick,
  handleReplyAllClick,
  handleForwardClick,
  getSenderColor
}) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Avatar style={{ backgroundColor: getSenderColor(senderName) }}>
              {senderName[0]}
            </Avatar>
          </Grid>
          <Grid item>
            <Typography variant="h6">{senderName}</Typography>
            <Typography variant="body1">{`To: ${recipientsString}`}</Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Tooltip title={`Reply To ${senderName}`}>
              <IconButton onClick={handleReplyClick}>
                <ReplyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Reply To All Recipients`}>
              <IconButton onClick={handleReplyAllClick}>
                <ReplyAllIcon fontSize="small" />
              </IconButton>
            </Tooltip>
            <Tooltip title={'Forward Email'}>
              <IconButton onClick={handleForwardClick}>
                <ForwardIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item style={{ paddingBottom: theme.spacing(2.2) }}>
            <Typography color="secondary" align="right">
              {receivedDateTime}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const stripHtmlTags = (str: string) => {
  if (str === null || str === '') return '';
  return str.replace(/<\/?[^>]+(>|$)/g, '').trim();
};

const hasDocumentOrImageTag = (str: string) => {
  if (!str) return false;
  const regex = /<(img|document)[\s\S]*?>/i;
  return regex.test(str);
};

const EmailBody = ({
  body,
  attachments,
  user
}: {
  body: NullableOption<ItemBody> | undefined;
  attachments: NullableOption<Attachment[]>;
  user: UserInstance;
}) => {
  const plainTextContent = stripHtmlTags(body?.content || '');
  const containsDocOrImgTag = hasDocumentOrImageTag(body?.content || '');

  if (!plainTextContent && !containsDocOrImgTag) {
    return (
      <Alert severity="info" variant="outlined">
        <Typography style={{ fontWeight: 'bold' }}>
          This Email Appears to be Empty. No Content was Found in the Body.
        </Typography>
      </Alert>
    );
  } else return <Body body={body} attachments={attachments} user={user} />;
};

const EmailActions = ({
  isReplying,
  handleReplyClick,
  handleReplyAllClick
}) => {
  if (isReplying) return null;

  return (
    <Grid
      container
      justifyContent="flex-start"
      style={{ paddingTop: theme.spacing(2) }}>
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={handleReplyClick}
          startIcon={<ReplyIcon />}>
          Reply
        </Button>
      </Grid>
      <div style={{ padding: theme.spacing(1) }} />
      <Grid item>
        <Button
          variant="outlined"
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={handleReplyAllClick}
          startIcon={<ReplyAllIcon />}>
          Reply All
        </Button>
      </Grid>
    </Grid>
  );
};

const LoadMoreReplies = ({ onClick, remainingCount }) => (
  <Paper
    elevation={2}
    style={{
      width: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer'
    }}
    onClick={onClick}>
    <ListIcon
      style={{
        marginRight: theme.spacing(2),
        width: 30,
        height: 30,
        color: 'gray'
      }}
    />
    <Typography style={{ fontSize: '15px' }} color="secondary">
      See {remainingCount} more messages
    </Typography>
  </Paper>
);

export default Conversation;
