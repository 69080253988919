import { Grid, Paper, Typography } from '@material-ui/core';
import { theme } from 'theme';
import { AttachFile } from '@material-ui/icons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import { DropTarget } from 'common';
import { FileAttachment, Message } from '@microsoft/microsoft-graph-types';
import { formats } from 'data/formats';

export const listAttachments = async ({
  message,
  fetchAttachments
}: {
  message: Message;
  fetchAttachments: (attachment: {
    messageId: string;
  }) => Promise<FileAttachment[]>;
}): Promise<{ attachments: FileAttachment[]; newBody: string }> => {
  let attachments: FileAttachment[] = [];
  let newBody = '';

  if (message.hasAttachments && message.id) {
    attachments = await fetchAttachments({
      messageId: message.id || ''
    });
  } else {
    let bodyContent = message?.body?.content;
    if (bodyContent != null) {
      let InlineImg = String(bodyContent).match(
        /<img.*?src="([^"]*)"[^>]*>(?:<\/img>)?/m
      );

      if (InlineImg && message.id) {
        newBody = String(bodyContent).replace(
          /<img.*?src="([^"]*)"[^>]*>(?:<\/img>)?/g,
          ''
        );

        attachments = await fetchAttachments({
          messageId: message.id || ''
        });
      }
    }
  }

  return { attachments, newBody };
};

export const gridArray = ({
  files,
  gridSize
}: {
  files: any[];
  gridSize: number;
}) => {
  let index = 0;
  const arrayLength = files.length;
  const tempArray: any[] = [];

  for (index = 0; index < arrayLength; index += gridSize) {
    const myGrid = files.slice(index, index + gridSize);
    tempArray.push(myGrid);
  }

  return tempArray;
};

export const FileUpload = ({ handleFileUpload }) => {
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <DropTarget
        onUpload={handleFileUpload}
        count={2}
        formats={formats}
        title="Click OR drop files"
        keyId="emailUpload"
        maxBytes={1000000 * 50}
      />
    </div>
  );
};

export const renderFileItem = (file: any, isExpanded: boolean) => {
  if (file.type.includes('image/')) {
    const imageUrl = URL.createObjectURL(file);

    return (
      <Paper style={{ padding: theme.spacing(0.5) }}>
        <Grid container item direction="column" alignItems="center" spacing={1}>
          <Grid item>
            <img
              src={imageUrl}
              alt={file.name}
              width={isExpanded ? 250 : 90}
              height={isExpanded ? 250 : 100}
              style={{ borderRadius: '8px 8px 0 0' }}
            />
          </Grid>

          <Typography
            variant="body2"
            style={{
              maxWidth: isExpanded ? 300 : 100,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}>
            {file.name}
          </Typography>
          <Typography variant="caption">
            {(file.size / 1024).toFixed(2)} KB
          </Typography>
        </Grid>
      </Paper>
    );
  } else {
    let icon: JSX.Element = <AttachFile />;
    if (file.name.endsWith('.docx') || file.name.endsWith('.doc')) {
      icon = <DescriptionIcon />;
    } else if (file.name.endsWith('.pdf')) {
      icon = <PictureAsPdfIcon />;
    }

    return (
      <Paper style={{ padding: theme.spacing(0.5) }}>
        <Grid container item alignItems="center" spacing={1}>
          <Grid item>{icon}</Grid>
          <Grid item>
            <Typography variant="body2">{file.name}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">
              {(file.size / 1024).toFixed(2)} KB
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    );
  }
};
