import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import { FunderDoc } from 'components/Calculator/Calculator/interfaces';

interface IInstallmentsProps {
  state: FunderDoc;
  Currency: ({ value }: { value: number }) => JSX.Element;
  TableStyle: any;
  styles: any;
}

export const Instalments = ({
  state,
  Currency,
  TableStyle,
  styles
}: IInstallmentsProps) => {
  const shouldDisplayVatRows =
    !state.VAT_UPFRONT && state.VAT_DEFERRAL_PERIOD > 0;

  const isLease = (product: string) =>
    product.toLowerCase().startsWith('lease');

  const sortedOverridePayments = [...state.OVERRIDE_PAYMENTS].sort(
    (a, b) => a.StartPeriod - b.StartPeriod
  );

  const rows: JSX.Element[] = [];
  let currentStart = 0;
  let currentEnd = 0;

  const pushPaymentRow = (
    start: number,
    end: number,
    isVatPeriod = false,
    type = state.INSTALMENTS_TYPE,
    amount: number = state.INSTALMENTS_AMOUNT
  ) => {
    rows.push(
      <View
        key={`${type.toLowerCase()}-${start}-${end}`}
        style={[TableStyle.row, isVatPeriod ? TableStyle.greyBackground : {}]}>
        <Text style={[TableStyle.cellLeft]}>
          {start === end ? start : `${start}-${end}`}
        </Text>
        <Text style={[TableStyle.cellType]}>
          {isVatPeriod ? `${type} + VAT` : type}
          {!isVatPeriod && isLease(state.FINANCE_PRODUCT) ? ' + VAT' : ''}
        </Text>
        <Text style={[TableStyle.cellAmount]}>
          <Currency value={amount} />
        </Text>
      </View>
    );
  };

  // Handle Initial Rentals if they exist
  if (state.INITIAL_RENTALS > 0) {
    pushPaymentRow(1, state.INITIAL_RENTALS, false, 'Initial Rental');
  }

  let period = state.INITIAL_RENTALS > 0 ? state.INITIAL_RENTALS + 1 : 1;
  let overrideIndex = 0;
  while (period <= state.INSTALMENTS_NUMBER) {
    if (
      overrideIndex < sortedOverridePayments.length &&
      period === sortedOverridePayments[overrideIndex].StartPeriod
    ) {
      const override = sortedOverridePayments[overrideIndex];
      if (currentStart !== 0) {
        pushPaymentRow(currentStart, currentEnd);
        currentStart = 0;
        currentEnd = 0;
      }
      if (
        shouldDisplayVatRows &&
        state.VAT_DEFERRAL_PERIOD >= override.StartPeriod &&
        state.VAT_DEFERRAL_PERIOD <= override.EndPeriod
      ) {
        for (let i = override.StartPeriod; i <= override.EndPeriod; i++) {
          if (i === state.VAT_DEFERRAL_PERIOD) {
            pushPaymentRow(
              i,
              i,
              true,
              'Override',
              parseInt(override.Amount.toString())
            );
          } else {
            pushPaymentRow(
              i,
              i,
              false,
              'Override',
              parseInt(override.Amount.toString())
            );
          }
        }
      } else {
        pushPaymentRow(
          override.StartPeriod,
          override.EndPeriod,
          false,
          'Override',
          parseInt(override.Amount.toString())
        );
      }
      period = override.EndPeriod + 1;
      overrideIndex++;
    } else {
      if (shouldDisplayVatRows && period === state.VAT_DEFERRAL_PERIOD) {
        if (currentStart !== 0) {
          pushPaymentRow(currentStart, period - 1);
          currentStart = 0;
          currentEnd = 0;
        }
        pushPaymentRow(
          period,
          period,
          true,
          'Monthly',
          parseInt(state.INSTALMENTS_AMOUNT.toString()) +
            parseInt(state.VAT_DEFERRAL_AMOUNT.toString())
        );
      } else {
        if (currentStart === 0) {
          currentStart = period;
          currentEnd = period;
        } else {
          currentEnd = period;
        }
      }
      period++;
    }
  }

  if (currentStart !== 0) {
    pushPaymentRow(currentStart, currentEnd);
  }

  let balloonPaymentRow: JSX.Element | null = null;
  if (state.BALLOON) {
    if (state.CONTRACT_PERIOD === 1) {
      balloonPaymentRow = (
        <View
          key="balloon-payment"
          style={[TableStyle.row, TableStyle.greyBackground]}>
          <Text style={[TableStyle.cellLeft]}>
            {parseInt(state.INSTALMENTS_NUMBER.toString()) + 1}
          </Text>
          <Text style={[TableStyle.cellType]}>
            Balloon{isLease(state.FINANCE_PRODUCT) ? ' + VAT' : ''}
          </Text>
          <Text style={[TableStyle.cellAmount]}>
            <Currency value={parseInt(state.BALLOON.toString())} />
          </Text>
        </View>
      );
    } else {
      const lastRowIndex = rows.findIndex((row) => {
        const rowText = row.props.children[0].props.children;
        return (
          rowText === state.INSTALMENTS_NUMBER ||
          (typeof rowText === 'string' &&
            rowText.split('-')[1] === state.INSTALMENTS_NUMBER.toString())
        );
      });

      if (lastRowIndex !== -1) {
        rows[lastRowIndex] = (
          <View
            key="balloon-payment"
            style={[TableStyle.row, TableStyle.greyBackground]}>
            <Text style={[TableStyle.cellLeft]}>
              {state.INSTALMENTS_NUMBER}
            </Text>
            <Text style={[TableStyle.cellType]}>
              Balloon{isLease(state.FINANCE_PRODUCT) ? ' + VAT' : ''}
            </Text>
            <Text style={[TableStyle.cellAmount]}>
              <Currency value={parseInt(state.BALLOON.toString())} />
            </Text>
          </View>
        );
      } else {
        rows.push(
          <View
            key="balloon-payment"
            style={[TableStyle.row, TableStyle.greyBackground]}>
            <Text style={[TableStyle.cellLeft]}>
              {state.INSTALMENTS_NUMBER}
            </Text>
            <Text style={[TableStyle.cellType]}>
              Balloon{isLease(state.FINANCE_PRODUCT) ? ' + VAT' : ''}
            </Text>
            <Text style={[TableStyle.cellAmount]}>
              <Currency value={parseInt(state.BALLOON.toString())} />
            </Text>
          </View>
        );
      }
    }
  }

  return (
    <div>
      <View style={TableStyle.table}>
        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cellLeft, styles.tableTitleText]}>
            Number
          </Text>
          <Text style={[TableStyle.InstalType, styles.tableTitleText]}>
            Type
          </Text>
          <Text style={[TableStyle.InstalAmount, styles.tableTitleText]}>
            Amount
          </Text>
        </View>
        <div style={styles.line} />
        {rows}
        {balloonPaymentRow}
      </View>
    </div>
  );
};
