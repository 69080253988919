import { useState } from 'react';
import validate from 'validate.js';
import {
  Typography,
  withStyles,
  TextField,
  CircularProgress,
  Button
} from '@material-ui/core';
import afslogo from 'assets/logos/Logo.png';
import schema from './schema';
import { useStyles } from './styles';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { signIn } from 'redux/actions/auth';
import { SET_TARGET } from 'redux/actions/types';
import _ from 'lodash-es';
import { PrimaryButton } from 'common/Button';
import { createNotification } from 'react-redux-notify';
import { errorNotif, infoNotif } from 'components/Notifications';
import { BugTracker } from 'Utils/Bugtracker';
import { PasswordResetLink } from 'redux/database/Auth API';
import { UserInstance } from 'types/interfaces';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTypedSelector } from 'redux/reducers';
import useChecklist from 'hooks/useDeals/useChecklist';

interface IState {
  values: { email: string; password: string };
  touched: { email: boolean; password: boolean };
  errors: { email: null | string; password: null | string };
  isValid: boolean;
  isLoading: boolean;
  submitError: null | string;
}

const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { loadChecklistIntoRedux, loadRulePreventionIntoRedux } =
    useChecklist();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { SupportPhone } = useTypedSelector((s) => s.config.publicSettings);
  const [showForgotten, setShowForgotten] = useState(false);
  const [passwordResetLinkSent, setPasswordResetLinkSent] = useState(false);
  const params: { piid: string; pdid: string; psdid: string } = useParams();
  const [state, setState] = useState<IState>({
    values: { email: '', password: '' },
    touched: { email: false, password: false },
    errors: { email: null, password: null },
    isValid: false,
    isLoading: false,
    submitError: null
  });
  const { values, touched, errors, isValid, submitError, isLoading } = state;
  const showEmailError = touched.email && errors.email;
  const showPasswordError = touched.password && errors.password;

  const validateForm = _.debounce(() => {
    const { values } = state;
    let errors = validate(values, schema);
    let isValid = errors ? false : true;
    errors = errors || {};
    setState({ ...state, isValid });
  }, 300);

  const handleFieldChange = (field, value) => {
    validateForm();
    const newState: IState = { ...state };
    newState.submitError = null;
    newState.touched[field] = true;
    newState.values[field] = value;
    setState(newState);
  };

  // when user submit their email & password with the 'Enter' key
  const handleKeyPressSignIn = async (e) => {
    // only accepts if keycode is 'Enter'
    if (e.key === 'Enter') {
      // if email & password is valid, than proceed to signing in
      if (isValid) {
        await handleSignIn();
      }
    }
  };

  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

  const handleSignIn = async () => {
    try {
      const { values } = state;
      setState((s) => ({ ...s, isLoading: true }));
      const { email, password } = values;
      const validToken = await signIn({
        baseUrl,
        email,
        password,
        returnData: undefined
      });

      if (validToken) {
        if (validToken.isAxiosError) {
          dispatch(
            createNotification(errorNotif(validToken.response.data.message))
          );

          BugTracker.notify(`New Id attempt for: ${hostname}/${email}`);
        }
        if (params.pdid && params.psdid && params.piid) {
          const data = {
            pdid: params.pdid,
            psdid: params.psdid,
            piid: params.piid
          };
          dispatch({ type: SET_TARGET, payload: data });
        }
        setState((s) => ({ ...s, isLoading: false }));

        if (location.pathname === '/sign-in') {
          // These are for loading Checklist & Rule Prevention on Enter instead of every Deal
          await loadChecklistIntoRedux();
          await loadRulePreventionIntoRedux();

          return history.push('/dashboard');
        } else {
          dispatch(
            createNotification(
              infoNotif(
                'You have successfully logged in, you may need to click away and back again on what ever you were trying to do'
              )
            )
          );
        }
      } else {
        setState((s) => ({ ...s, isLoading: false }));
        alert('User unrecognised');
      }
    } catch (error) {
      setState((s) => ({ ...s, isLoading: false }));
    }
  };

  const handleForgottenPassword = async () => {
    const data: UserInstance = emptyUserInstance();
    data.UserInstanceEmail = state.values.email;
    const res = await PasswordResetLink({ data });
    if (res.status === 200) setPasswordResetLinkSent(true);
    // Show Email Sent
    try {
    } catch (error) {
      // Show Error
      console.log({ error });
    }
  };

  const PassWordLink = () => {
    if (passwordResetLinkSent) {
      return (
        <Alert
          style={{ display: 'flex', flexDirection: 'column', marginTop: 16 }}
          severity="success">
          <AlertTitle>Password Reset Email Sent</AlertTitle>
          <br />

          <Typography>
            Please reset your password using the link in the email we’ve sent
            you. The link will expire after 1 hour. If you can’t see the email,
            check your junk email folder.
          </Typography>

          <br />

          <Typography>
            {`If you’re still having problems, please phone our customer services
            team on ${SupportPhone}`}
          </Typography>

          <br />

          <Typography>
            Can’t find the email?{' '}
            <Button onClick={handleForgottenPassword}>
              Resend password reset email
            </Button>
          </Typography>
        </Alert>
      );
    } else {
      if (isLoading) {
        return (
          <CircularProgress
            data-cy="spinner"
            className={classes.progress}
            color="secondary"
          />
        );
      } else {
        return (
          <PrimaryButton
            className={classes.signInButton}
            color="primary"
            // disabled={!RPisValid}
            onClick={handleForgottenPassword}
            size="large"
            variant="contained">
            Send reset Password Link
          </PrimaryButton>
        );
      }
    }
  };

  if (showForgotten) {
    return (
      <form className={classes.form}>
        <Typography className={classes.title} variant="h2">
          Forgotten Password
        </Typography>
        <Typography className={classes.subtitle} variant="body1">
          {`Use your email to receive a reset password link.`}
        </Typography>
        <CustomTextField
          className={classes.textField}
          data-cy="email"
          label="Email Address"
          name="email"
          onChange={(event) => handleFieldChange('email', event.target.value)}
          type="text"
          value={values.email}
          variant="outlined"
        />
        <PassWordLink />
        <Button
          className={classes.signInButton}
          onClick={() => setShowForgotten(false)}
          size="large">
          Sign In
        </Button>
        <Logo />
      </form>
    );
  }

  return (
    <form onKeyPress={handleKeyPressSignIn} className={classes.form}>
      <Typography className={classes.title} variant="h2">
        Login
      </Typography>
      <Typography className={classes.subtitle} variant="body1">
        {`Use your allocated account credentials to login.`}
      </Typography>
      <div className={classes.fields}>
        <CustomTextField
          className={classes.textField}
          data-cy="email"
          label="Email Address"
          name="email"
          onChange={(event) => handleFieldChange('email', event.target.value)}
          type="text"
          value={values.email}
          variant="outlined"
        />
        {showEmailError && (
          <Typography className={classes.fieldError} variant="body2">
            {errors?.email?.[0]}
          </Typography>
        )}

        <br />
        <br />
        <br />
        <br />

        <CustomTextField
          data-cy="password"
          className={classes.textField}
          label="Password"
          name="password"
          onChange={(event) =>
            handleFieldChange('password', event.target.value)
          }
          type="password"
          value={values.password}
          variant="outlined"
        />

        {showPasswordError && (
          <Typography className={classes.fieldError} variant="body2">
            {errors?.password?.[0]}
          </Typography>
        )}
      </div>
      {submitError && (
        <Typography className={classes.submitError} variant="body2">
          {submitError}
        </Typography>
      )}
      <div style={{ paddingTop: 40 }} />
      {isLoading ? (
        <CircularProgress
          data-cy="spinner"
          className={classes.progress}
          color="secondary"
        />
      ) : (
        <PrimaryButton
          data-cy="sign_in_btn"
          className={classes.signInButton}
          color="primary"
          disabled={!isValid}
          onClick={handleSignIn}
          size="large"
          variant="contained">
          Sign in
        </PrimaryButton>
      )}
      <div style={{ padding: 2 }} />

      <Button
        className={classes.signInButton}
        onClick={() => setShowForgotten(true)}
        size="large">
        Forgotten Password
      </Button>

      <div style={{ padding: 10 }} />

      <Logo />
    </form>
  );
};

export default LoginForm;

const CustomTextField = withStyles({
  root: {
    '& label': {},
    '& label.Mui-focused': {},
    '& .MuiInput-underline:before': {},
    '& .MuiInput-underline:after': {},
    '& .MuiOutlinedInput-root': {
      '& fieldset': {},
      '&:hover fieldset': {},
      '&.Mui-focused fieldset': {}
    }
  }
})(TextField);

const Logo = () => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 60,
      paddingLeft: 20
    }}>
    <img height={100} src={afslogo} />
  </div>
);

export const emptyUserInstance = () => {
  const UserInstance: UserInstance = {
    ConsoleUserInstanceId: 0,
    DefaultMessage: '',
    DocumentFooter: '',
    DocumentHeader: '',
    EmailFooter: '',
    FromAddress: '',
    FromName: '',
    Id: 0,
    IsPublished: false,
    ItemOrder: 0,
    LastModified: '0001-01-01T00:00:00',
    LogoURL: '',
    NickName: '',
    OwnerUserInstanceId: 0,
    ProfilePicture: '',
    ProxyUserInstanceId: 0,
    RelatedUserPermission: 0,
    RelationshipStatus: 0,
    SmtpHost: '',
    SmtpPassword: '',
    SmtpPort: 0,
    SmtpSSL: false,
    SmtpUsername: '',
    SummaryFields: undefined,
    SystemAccess: 0,
    TelephoneNumber: '',
    ThirdPartyId: '',
    Title: '',
    UserDefinitionId: 0,
    UserInstanceEmail: '',
    UserInstancePassword: ''
  };
  return UserInstance;
};
