import React, { useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTypedSelector } from 'redux/reducers';
import { withRouter } from 'react-router-dom';
import OverViewDialog from 'components/Stepper/components/Overview/Entry';
import classNames from 'classnames';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core';
import {
  Button,
  Badge,
  IconButton,
  Popover,
  Typography
} from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import Grid from '@material-ui/core/Grid';
import LightSwitch from 'components/Switch';
import { Menu as MenuIcon, MenuOpen as CloseIcon } from '@material-ui/icons';
import { NotificationList, UserActionBtn } from './components';
import styles from './styles';
// import { useExpiration } from 'components/Timer';

import ConnectedIcon from 'components/Settings/ConnectedIcon';

// import Switch from '@material-ui/core/Switch';
// import FormGroup from '@material-ui/core/FormGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

// import DragableCalc from './components/DragableCalc';
// import Help from 'components/Help';

// import DrawerRight from '../Drawer';

// import MSO365 from 'assets/Icons/png/office365.png';
import { useIsAuthenticated } from '@azure/msal-react';

import { firebase } from 'redux/firebase';
import { PROXY_SETTINGS } from 'redux/actions/types';
import { getDealData } from 'redux/actions/processes/getDealData';

import HelpSwitch from './components/HelpSwitch';
import NewUserButton from 'components/User/NewUserButton';
import { BugTracker } from 'Utils/Bugtracker';
import { Redirect } from 'react-router-dom';

import NotificationDrawer from 'components/NotificationDrawer';

interface INotification {
  ProcessInstanceTitle: string;
  id: string;
  msg: string;
  pdid: number;
  piid: number;
  psdid: number;
  read: boolean;
  ts: {
    nanoseconds: number;
    seconds: number;
  };
  type: string;
}

const Topbar = (props) => {
  // const { notifications } = useNotifications();
  const user = useTypedSelector((s) => s.user.user);

  const { uid } = useTypedSelector((s) => s.fb.auth);
  // const expiration = useExpiration();

  const isMSAuth = useIsAuthenticated();
  const titleRef = React.useRef(null);
  const dispatch = useDispatch();
  const calculation = useSelector<any>((s) => s.calculator.calculation);
  const isMobile = useSelector<any>((s) => s.config.navStatus.isMobile);
  const Id: any = useSelector<any>((s) => s.user.user.Id);
  const currentProcess = useSelector<any>((s) => s.process.currentProcess);
  const target = useTypedSelector((s) => s.communication.target);
  const token = useSelector<any>((s) => s.user.auth.token);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openNotificationDrawer, setOpenNotificationDrawer] =
    React.useState(false);

  // const isCalculation = Object.keys(calculation).length > 0;
  // const [signal, setSignal] = useState(true);
  const [state, setState] = useState({
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0,
    notificationsEl: null
  });

  const handleTarget = (target: any) =>
    openDeal({
      ProcessInstanceId: target.piid,
      ProcessDefinitionId: target.pdid,
      ProcessStepDefinitionId: target.psdid
    });

  const openDeal = async (props: {
    ProcessInstanceId: number;
    ProcessDefinitionId: number;
    ProcessStepDefinitionId: number;
  }) => {
    setLoading(true);
    const { ProcessInstanceId, ProcessDefinitionId, ProcessStepDefinitionId } =
      props;
    const data = await getDealData({
      ProcessInstanceId,
      ProcessDefinitionId,
      ProcessStepDefinitionId
    });
    if (data) {
      setLoading(false);
      return setOpen(true);
    }
    return alert(
      'We are unable to access this deal currently, please notify administration'
    );
  };

  const deleteNotification = (notification: INotification) => {
    const deleteRef = firebase
      .firestore()
      .collection('userAccount')
      .doc(Id.toString())
      .collection('notifications')
      .doc(notification.id);

    deleteRef.delete().catch((e) => BugTracker.notify(e));
  };

  const deleteAllNotifications = (notification: INotification[]) => {
    notification.forEach((notifi: INotification) => {
      const deleteRef = firebase
        .firestore()
        .collection('userAccount')
        .doc(Id.toString())
        .collection('notifications')
        .doc(notifi.id);

      deleteRef.delete().catch((e) => BugTracker.notify(e));
    });
  };

  React.useEffect(() => {
    const location = window.location.pathname;
    if (location === '/deals' && state?.notifications?.length > 0) {
      setOpenNotificationDrawer(true);
    }
  }, [state?.notifications?.length]);

  React.useEffect(() => {
    if (target?.pdid) handleTarget(target);
  }, [target]);

  React.useEffect(() => {
    // can we set up a listener here
    const unsub = firebase
      .firestore()
      .collection('userAccount')
      .doc(Id.toString())
      .onSnapshot((doc) =>
        dispatch({ type: PROXY_SETTINGS, payload: doc.data() })
      );

    const notifRef = firebase
      .firestore()
      .collection('userAccount')
      .doc(uid)
      .collection('notifications')
      .where('read', '==', false)
      .orderBy('ts', 'desc');

    const unsubscribe = notifRef.onSnapshot((snap) => {
      let notifications: any = [];
      snap.forEach((doc) => {
        const item = { ...doc.data(), id: doc.id };
        if (doc.exists) notifications.push(item);
      });
      setState((s) => ({ ...s, ['notifications']: notifications }));
    });
    return () => {
      unsub();
      unsubscribe();
    };
  }, []);

  const handleSignOut = () => {
    const { history } = props;
    history.push('/sign-in');
    dispatch({ type: 'LOG_OUT' });
  };

  const handleCloseNotifications = (notification) => {
    // console.log({ notification });
    dispatch({ type: 'SET_TARGET', payload: notification });
    setState({ ...state, notificationsEl: null });
  };

  const { classes, className, title, isSidebarOpen, onToggleSidebar } = props;
  const { notificationsEl, notifications } = state;

  const rootClassName = classNames(classes.root, className);
  const showNotifications = Boolean(notificationsEl);

  if (user?.SystemAccess === 3) {
    return <Redirect to="/portal" />;
  }

  return (
    <Fragment>
      <NotificationDrawer
        open={openNotificationDrawer}
        onClose={() => setOpenNotificationDrawer(false)}>
        <NotificationList
          notifications={notifications}
          onSelect={handleCloseNotifications}
          deleteNotification={deleteNotification}
          deleteAllNotifications={deleteAllNotifications}
        />
      </NotificationDrawer>

      <OverViewDialog handleClose={() => setOpen(false)} open={open} />

      <div className={rootClassName}>
        <Grid
          alignItems="center"
          container
          direction="row"
          justifyContent="flex-start"
          spacing={2}>
          {!isMobile && (
            <React.Fragment>
              <Grid item style={{ paddingLeft: 25 }}>
                <IconButton onClick={onToggleSidebar}>
                  {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                </IconButton>
              </Grid>
              <Grid item>
                <Typography
                  color="textPrimary"
                  style={{
                    width: 400,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  variant="h4">
                  {title}
                </Typography>
              </Grid>
            </React.Fragment>
          )}

          <Grid
            item
            style={{
              justifySelf: 'end',
              position: 'absolute',
              right: 0
            }}>
            <Grid alignItems="center" container>
              {/* {isCalculation && (
                <Grid item style={{ marginRight: 3 }}>
                  <DragableCalc />
                </Grid>
              )} */}

              {/* {process.env.REACT_APP_ENV !== 'production' && (
                <Grid item style={{ marginRight: 8 }}>
                  <Help type={'start_deal'} />
                </Grid>
              )} */}

              {/* {process.env.REACT_APP_ENV !== 'production' && (
                <Grid item style={{ marginRight: 3 }}>
                  <StartDealBtn />
                </Grid>
              )} */}

              <ConnectedIcon />
              {/*
              {isMobile && (
                <Grid item>
                  <Typography
                    color="textPrimary"

                    variant="h4">
                    {title}
                  </Typography>
                </Grid>
              )} */}

              <div style={{ flexGrow: 1 }} />

              {/* <Grid item>
                <HelpSwitch />
              </Grid> */}

              {process.env.REACT_APP_ENV !== 'production' && (
                <Grid item style={{ marginRight: 8 }}>
                  <NewUserButton />
                </Grid>
              )}

              <Grid item>
                <IconButton
                  style={{ marginRight: 8 }}
                  onClick={() => setOpenNotificationDrawer(true)}>
                  <Badge
                    badgeContent={state?.notifications?.length}
                    color="primary">
                    <NotificationsNoneIcon />
                  </Badge>
                </IconButton>
              </Grid>

              <Grid item>
                <LightSwitch classes={classes} />
              </Grid>

              <Grid item>
                <UserActionBtn signout={handleSignOut} />
              </Grid>

              {isMobile && (
                <Grid item>
                  <IconButton onClick={onToggleSidebar}>
                    {isSidebarOpen ? <CloseIcon /> : <MenuIcon />}
                  </IconButton>
                </Grid>
              )}

              {/* <Grid item>
                <DrawerRight />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Popover
        anchorEl={notificationsEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        onClose={handleCloseNotifications}
        open={showNotifications}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <NotificationList
          notifications={notifications}
          onSelect={handleCloseNotifications}
          deleteNotification={deleteNotification}
          deleteAllNotifications={deleteAllNotifications}
        />
      </Popover>
    </Fragment>
  );
};

export default compose(withRouter, withStyles(styles))(Topbar);
