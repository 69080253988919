import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StepZero from 'components/Stepper/StepZero';

const Component = () => {
  const history = useHistory();
  const { process } = useSelector((s) => s);
  const { currentProcess } = process;
  const UserInstanceId = history.location.state.UserInstanceId;

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcoincentral.com%2Fwp-content%2Fuploads%2F2019%2F01%2Ffintech-blockchain.png&f=1&nofb=1")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>
      <StepZero
        UserInstanceId={UserInstanceId}
        definition={currentProcess.ProcessDefinition}
        steps={currentProcess.CompleteProcessStepDefinitionDict}
      />
    </div>
  );
};

export default Component;
