import { AxiosRequestConfig } from 'axios';
import { dbQueue } from './Interceptor';

export { SupplierAutomation } from './Custom API';

export {
  DeleteObject,
  getIfObjectIsSelected,
  GetListOfObjects,
  getObjectInstanceDocument,
  GetObjectInstanceListByKeyword,
  SelectObject
} from './Object Instance API';

export {
  GetUserDefinitionList,
  GetUserDefinitions
} from './User Definition API';

export { SignIn, RefreshToken } from './Auth API';

export type {
  RelationShipActions,
  RelationshipsAPIInterface
} from './Relationship API';

export { GetSettings } from './Settings API';

export { GetFileExplorer } from './File Explorer API';

export { GetLegacyDeals, GetLegacyDocument } from './Legacy Deal API';

export {
  GetUserInstanceSummaryList,
  GetUserInstanceDetail,
  GetData
} from './User Instance API';

export {
  GetProductTypes,
  GetProcessDefinitionLite,
  GetProcessDefinition,
  GetUsersAvailableProcesses,
  GetProcessesByUserDefinition
} from './Process Definition API';

export {
  GetAllProcesses,
  GetProcessesByUser,
  GetAssignedUsersByProcess,
  GetProcessByUserFinishedOrUnfinished,
  GetUserStats
} from './Process Summary API';

export {
  CreateProcess,
  GetProcessById,
  SubmitProcessStep,
  QuickProcessUpdate,
  CopyProcessToStep
} from './Process Instance API';

export {
  GetSearchUser,
  GetStepUsers,
  GetStepDocumentAsHTML,
  GetStepSystemDocument,
  GetGeneratedStepSystemDocument
} from './Process Step API';

export {
  GetAllSummaries,
  GetCurrentAudit,
  GetAuditByProcessId,
  GetAuditByUser
} from './Audit API';

export {
  EmailByProcess,
  EmailByUser,
  GetCurrentEmail,
  ResendLandingpageEmailLink
} from './Email API';

export {
  GetProcessFields,
  GetUserGeneratedDocument
} from './Field Instance API';

/**
 * REDFLAG API
 */
export const ImportRedFlagData = async ({ data }) => {
  let url = `RedFlagAPI.ashx`;
  let config: AxiosRequestConfig = { method: 'post', url, data };
  return await dbQueue({ config, url });
};

/**
 * DOCUMENT API
 */
export const GetDocument = async ({ DocumentId }) => {
  let url = `documentAPI.ashx?documentid=${DocumentId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

/**
 * RULE API
 */
export const SubmitRule = async ({
  RuleId,
  ProcessInstanceId,
  NoSend,
  ObjectInstanceId
}: {
  RuleId: number;
  ProcessInstanceId: number;
  NoSend?: boolean;
  ObjectInstanceId?: number;
}) => {
  const noSend = JSON.stringify(NoSend ? NoSend : false);
  const oiid = JSON.stringify(ObjectInstanceId ? ObjectInstanceId : false);
  let url = `ruleapi.ashx?ruleid=${RuleId}&processinstanceid=${ProcessInstanceId}&noSend=${noSend}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const RuleList = async ({
  ProcessDefinitionId
}: {
  ProcessDefinitionId: number;
}) => {
  let url = `ruleapi.ashx?action=list&processdefinitionid=${ProcessDefinitionId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

/**
 * CALCULATOR OUTPUT API
 */
export const CalculatorOutput = async ({ ProcessInstanceId }) => {
  let url = `CalculatorOutputAPI.ashx?processinstanceid=${ProcessInstanceId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};
