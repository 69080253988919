import React, { useState } from 'react';
import {
  Grid,
  Paper,
  Box,
  Typography,
  Tooltip,
  LinearProgress,
  Button,
  CircularProgress,
  DialogContent,
  DialogActions
} from '@material-ui/core';
import ActionButton from '../Steps/ActiveStep/components/ActionButton';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { useTheme } from '@material-ui/core/styles';
import { useTypedSelector } from 'redux/reducers';
import Alert from '@material-ui/lab/Alert';
import { useProcess } from 'hooks';
import StepDocumentHTML from '../Documents/StepDocumentHTML';
import { PrimaryButton } from 'common/Button';
import RequiredTooltip from 'Utils/tooltip';
import { IRequiredFields } from 'hooks/useDeals/useRequiredCompletion';
import useDecline from 'hooks/useDeals/useDecline';
import useConfirmAcceptLandingPage from 'hooks/useDeals/useConfirmAccept';
import isPreviewMode from '../Steps/ActiveStep/helper/previewMode';
import { globalIds } from 'helpers/globalIdConfig';
import { CustomDialog } from 'common/Dialog';

interface IActionBar {
  abletosubmit?: () => boolean;
  activeDealStep: number;
  activeStep: number;
  complete?: boolean;
  currentStepCanCompleteList: { [UserInstanceId: string]: string };
  loadingStepData: boolean;
  refreshDealData: (activeStep: number, move: boolean) => void;
  ReqCmp: any;
  ReqFields: IRequiredFields[];
  submit: () => Promise<void>;
  loadingAccept: boolean;
  ProcessStepDefinitionTitle: string | undefined;
  open: boolean;
  setOpen: (open: boolean) => void;
  parsed: any;
}

const ActionBar = (props: IActionBar) => {
  const {
    activeDealStep,
    currentStepCanCompleteList,
    loadingStepData,
    refreshDealData,
    ReqCmp,
    ReqFields,
    submit,
    ProcessStepDefinitionTitle,
    open,
    setOpen,
    parsed
  } = props;

  const theme = useTheme();
  const { SystemAccess, Id } = useTypedSelector((s) => s.user.user);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const deal = useTypedSelector((s) => s.dealSummary.deal);
  const { currentProcess, currentStepId, landingpage, currentDeal, user } =
    useProcess();

  const { declineAll, declineDialog, loadingDecline } = useDecline({ parsed });
  const { ConfirmLandingPage, dealConfirmDialog } = useConfirmAcceptLandingPage(
    { currentDeal, parsed, user }
  );

  const { ProcessStepDefinitionSequence } = currentProcess;
  const isLessThanSubSystemUser = SystemAccess < 4;

  const [completion, setCompletion] = React.useState<boolean>(false);
  const [lender, setLender] = React.useState<string>('0');

  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClose = () => setOpenTooltip(false);
  const handleTooltipOpen = () => setOpenTooltip(true);

  //* isPreview will only work if the magic link has been injected with preview.
  const isPreview = isPreviewMode(window.location.href, landingpage);
  const handleCompletion = () => {
    // if the current step title is exactly equal to "Complete" do nothing ?
    if (ProcessStepDefinitionTitle !== 'Complete') return;
    Object.values(currentProcess.ProcessStepGroupDict).map((step: any) => {
      if (step.Title === 'Completion') {
        const currentProcessStepID = step.ProcessStepDefinitionIdList;

        currentProcessStepID.forEach((Id) => {
          const grabCompleteId =
            currentProcess.CompleteProcessStepDefinitionDict[Id];

          if (Id === currentStepId) {
            if (grabCompleteId.ProcessStepDefinition.Title === 'Complete') {
              return setCompletion(true);
            }
          }
        });
      } else return setCompletion(false);
    });
  };

  // open dialog when confirm button is clicked
  const handleClickOpen = () => {
    setOpen(true);
  };

  // close dialog
  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (!isPreview) handleCompletion();
  }, [currentStepId]);

  const confirmAcceptLandingPage = async () => {
    await ConfirmLandingPage();
  };

  const hasSelected = deal?.lenders?.selected;
  const lenderId =
    hasSelected && hasSelected.length > 0 ? hasSelected[0].Id.toString() : '0';

  const IndicativeStep = 1738;
  return (
    <>
      {declineDialog}
      {dealConfirmDialog}
      <Paper
        style={{
          background: theme.palette.background.default,
          padding: 8
        }}>
        <Grid
          container
          spacing={1}
          style={{ padding: 8 }}
          alignItems={landingpage ? 'center' : 'flex-start'}
          direction={landingpage ? 'row' : 'column'}>
          {currentDeal?.CurrentUserInstanceCanSubmit && (
            <Grid direction="column" container>
              {!landingpage && !isLessThanSubSystemUser && (
                <Grid item>
                  <ActionButton
                    ProcessStepDefinitionTitle={ProcessStepDefinitionTitle}
                    activeStep={ProcessStepDefinitionSequence[activeDealStep]}
                    loadingStepData={loadingStepData}
                    refreshDealData={refreshDealData}
                    ReqCmp={ReqCmp}
                  />

                  <div style={{ height: 8 }} />
                </Grid>
              )}
              {completion && (
                <Grid item>
                  <Tooltip
                    title={
                      ReqCmp._Percent_Required_Fields_Complete !== 100
                        ? 'Ensure All Required Fields Are Completed'
                        : 'Submit all the information you have provided'
                    }>
                    <div>
                      {ReqCmp._Percent_Required_Fields_Complete === 100 ? (
                        <div>
                          <Button
                            color="primary"
                            disabled={
                              ReqCmp._Percent_Required_Fields_Complete !== 100
                            }
                            onClick={submit}
                            variant="contained">
                            {completion ? 'Complete Deal' : 'Submit'}
                          </Button>
                        </div>
                      ) : (
                        <Alert variant="outlined" severity="warning">
                          <Typography>
                            Please fill in the <b>Required Fields</b> And ensure
                            the deal is <b>on this step.</b>
                          </Typography>
                        </Alert>
                      )}
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          )}

          <Grid item>
            <Tooltip
              title={RequiredTooltip({ ReqFields })}
              placement="bottom"
              open={openTooltip}
              arrow
              interactive
              onClose={handleTooltipClose}
              onOpen={handleTooltipOpen}>
              <div
                onMouseLeave={handleTooltipClose}
                onMouseEnter={handleTooltipOpen}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <Box alignItems="center" display="flex">
                  <Box mr={1} width="100%">
                    <LinearProgress
                      value={ReqCmp._Percent_Required_Fields_Complete}
                      variant="determinate"
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography color="textSecondary" variant="body2">
                      {`${ReqCmp._Percent_Required_Fields_Complete}%`}
                    </Typography>
                  </Box>
                </Box>
                <Typography color="textSecondary" variant="body2">
                  Required Fields Completed
                </Typography>
              </div>
            </Tooltip>
          </Grid>

          <Grid item>
            <Tooltip title="Percentage All Fields Completed">
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <Box alignItems="center" display="flex">
                  <Box mr={1} width="100%">
                    <LinearProgress
                      color="secondary"
                      value={ReqCmp._Percent_All_Fields_Complete}
                      variant="determinate"
                    />
                  </Box>
                  <Box minWidth={35}>
                    <Typography
                      color="textSecondary"
                      variant="body2">{`${ReqCmp._Percent_All_Fields_Complete}%`}</Typography>
                  </Box>
                </Box>
                <Typography color="textSecondary" variant="body2">
                  All Fields Completed
                </Typography>
              </div>
            </Tooltip>
          </Grid>

          {isLessThanSubSystemUser ? (
            <Grid item>
              <QuickUserFromId UserInstanceId={Id.toString()} />
            </Grid>
          ) : (
            <>
              {Object.keys(currentStepCanCompleteList).map((key, index) => (
                <Grid item key={index}>
                  <QuickUserFromId UserInstanceId={key} />
                </Grid>
              ))}

              {!landingpage && lenderId !== '0' && (
                <Grid container direction="column">
                  <QuickUserFromId UserInstanceId={lenderId} />
                </Grid>
              )}
            </>
          )}

          <div style={{ flexGrow: 1 }} />

          {isLessThanSubSystemUser && !isPreview && (
            <Grid item style={{ display: 'flex', flexDirection: 'row' }}>
              {props?.loadingAccept ? (
                <CircularProgress />
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center">
                  {/* decline button will close the landing page */}
                  {(globalIds.processInstanceStep.quoteSteps.includes(
                    currentStepId
                  ) ||
                    currentStepId === IndicativeStep) && (
                    <Grid item>
                      {loadingDecline ? (
                        <div
                          style={{
                            display: 'inline-flex',
                            alignItems: 'center'
                          }}>
                          <CircularProgress />
                        </div>
                      ) : (
                        <Tooltip
                          title={`Decline, Will Decline ${
                            globalIds.processInstanceStep.quoteSteps.includes(
                              currentStepId
                            )
                              ? 'Quotes'
                              : currentStepId === IndicativeStep
                              ? 'Indicative Quotes'
                              : ''
                          }`}
                          arrow>
                          <PrimaryButton
                            data-cy="primary-button-decline"
                            variant="contained"
                            color="primary"
                            onClick={declineAll}>
                            Decline{' '}
                            {globalIds.processInstanceStep.quoteSteps.includes(
                              currentStepId
                            )
                              ? 'Quotes'
                              : currentStepId === IndicativeStep
                              ? 'Indicative Quotes'
                              : ''}
                          </PrimaryButton>
                        </Tooltip>
                      )}
                    </Grid>
                  )}
                  <div style={{ paddingLeft: theme.spacing(1) }} />
                  {/* confirm button enabled when user completes form */}
                  <Grid item>
                    <Tooltip
                      title={RequiredTooltip({ ReqFields, confirm: true })}
                      placement="bottom"
                      arrow>
                      <span>
                        <PrimaryButton
                          data-cy="primary-button-confirm"
                          variant="contained"
                          color="primary"
                          onClick={handleClickOpen}
                          disabled={
                            ReqCmp._Percent_Required_Fields_Complete !== 100
                          }>
                          Confirm
                        </PrimaryButton>
                      </span>
                    </Tooltip>
                  </Grid>

                  {/* after confirm button is click, open dialog to ensure user has read all documents before submit */}
                  <CustomDialog
                    maxSize="md"
                    open={open}
                    handleClose={handleClose}
                    alert={{
                      title: 'Review Document',
                      description:
                        'Please ensure you have read all the documents before you accept!',
                      type: 'info'
                    }}
                    aria-labelledby="deal-alert-title"
                    aria-describedby="deal-alert-description">
                    <DialogContent>
                      <StepDocumentHTML />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>

                      <PrimaryButton
                        data-cy="primary-button-accept"
                        autoFocus
                        onClick={confirmAcceptLandingPage}
                        color="primary"
                        variant="contained">
                        Accept
                      </PrimaryButton>
                    </DialogActions>
                  </CustomDialog>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
};

export default ActionBar;
