import { Dashboard as DashboardLayout } from 'layouts';
import LegacyDealsViewer from 'components/LegacyDealsViewer';

const LegacyDeals = () => (
  <DashboardLayout title="Legacy Deals Viewer" padding="noPadding">
    <LegacyDealsViewer />
  </DashboardLayout>
);

export default LegacyDeals;
