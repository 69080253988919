import { useEffect, useState } from 'react';
import { useProcess } from 'hooks/useProcess';
import { getCalculatorObjectsForProcess } from 'redux/actions/Calculator';
import {
  CompleteObjectDefinitionDict,
  CompleteObjectInstanceDict,
  FieldDefinitionDict,
  CompleteObjectInstance
} from 'types/interfaces';
import { Alert, AlertTitle } from '@material-ui/lab';
import CalculatorObject from '../CalculatorObject';
import SkeletonComponent from 'common/Skeletons/CalculatorQutoes';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { IProps } from '../CalculatorObject/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

interface ILPC {
  CompleteObjectDefinitionDict: CompleteObjectDefinitionDict;
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
}

const LPCalculatorObjects = () => {
  const { auth, currentDeal } = useProcess();
  const [LPC, setLPC] = useState<ILPC>();
  const [loading, setLoading] = useState(false);

  const LPCSetter = async () => {
    setLoading(true);
    const res: ILPC | null = await getCalculatorObjectsForProcess({
      ProcessInstanceId: currentDeal.ProcessInstance.Id
    });
    res && setLPC(res);
    setLoading(false);
  };

  useEffect(() => {
    LPCSetter();
  }, [currentDeal.CompleteObjectInstanceDict]);

  if (
    LPC &&
    LPC.CompleteObjectDefinitionDict &&
    Object.keys(LPC.CompleteObjectDefinitionDict).length === 0
  ) {
    return (
      <Alert severity="warning">
        <AlertTitle>No Quote Data Found</AlertTitle>
        <Typography variant="h6" color="textSecondary">
          {`We couldn't find any data. Please add a new quote to display the
        information here.`}
        </Typography>
      </Alert>
    );
  }
  return (
    <div style={{ width: '100%' }}>
      {LPC &&
        Object.keys(LPC?.CompleteObjectDefinitionDict)?.map((key, i) => {
          const CompleteObjectDefinition =
            LPC.CompleteObjectDefinitionDict[key];

          const CompleteObjectInstanceList: CompleteObjectInstance[] =
            Object.values(LPC.CompleteObjectInstanceDict)
              .filter(
                (CompleteObjectInstance: CompleteObjectInstance) =>
                  CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
                  parseInt(key)
              )
              .filter((CompleteObjectInstance: CompleteObjectInstance) => {
                const FieldInstanceList = getFieldInstances(
                  CompleteObjectInstance
                );

                return FieldInstanceList[0].FieldValue !== '';
              });

          const CompleteObjectInstanceDict = {} as CompleteObjectInstanceDict;
          CompleteObjectInstanceList.forEach((i) => {
            CompleteObjectInstanceDict[i.ObjectInstance.Id] = i;
          });

          const FieldDefinitionDict = {} as FieldDefinitionDict;
          CompleteObjectDefinition.FieldDefinitionList.forEach((i) => {
            FieldDefinitionDict[i.Id] = i;
          });

          if (loading)
            return (
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 500
                }}>
                <CircularProgress />
              </Box>
            );

          const props: IProps = {
            CompleteObjectInstanceDict: CompleteObjectInstanceDict,
            CompleteObjectInstanceList: CompleteObjectInstanceList,
            FieldDefinitionDict: FieldDefinitionDict,
            FieldDefinitionList: CompleteObjectDefinition.FieldDefinitionList,
            ObjectDefinition: CompleteObjectDefinition.ObjectDefinition,
            display: 'clientView'
          };
          return <CalculatorObject key={i} props={props} />;
        })}
    </div>
  );
};

export default LPCalculatorObjects;
