import {
  UPDATE_CALCULATOR_OBJECT,
  UPDATE_EXTRA_PAYMENTS,
  TOGGLE_CASHFLOW_SCREEN,
  SET_CALCULATION,
  SET_CALCULATION_ID,
  SET_SOLVE_LAYOUT_VIEW
} from '../actions/types';

import { INIT_STATE } from 'components/Calculator/Utils/calculations';

import { Calculation } from 'types/calculatorInterfaces';

const INITIAL_STATE = {
  calculation: INIT_STATE as Calculation,
  extraPayments: {} as {
    StartPeriod: number;
    EndPeriod: number;
    Amount: number;
    ExPayOverRide: number;
  }[],
  stateObj: INIT_STATE as Calculation,
  showCashflow: false,
  calculationId: '',
  solveLayoutView: []
};

const calculatorReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CALCULATION_ID:
      return { ...state, calculationId: action.payload };
    case SET_CALCULATION:
      return {
        ...state,
        calculation: action.payload
      };
    case UPDATE_CALCULATOR_OBJECT:
      return {
        ...state,
        stateObj: action.payload
      };
    case UPDATE_EXTRA_PAYMENTS:
      return {
        ...state,
        extraPayments: action.payload
      };
    case TOGGLE_CASHFLOW_SCREEN:
      return {
        ...state,
        showCashflow: action.payload
      };
    case SET_SOLVE_LAYOUT_VIEW:
      return {
        ...state,
        solveLayoutView: action.payload
      };
    default:
      return state;
  }
};

export default calculatorReducer;
