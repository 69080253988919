import { Grid, ListItem, Typography, useTheme } from '@material-ui/core';
import { setForValueFromCalculationNo } from 'components/Calculator/Calculator/functions';
import { useMobile } from 'hooks';
import NumberFormat from 'react-number-format';
import { FieldDefinition, FieldInstance } from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { OutputInterface } from '../context/OutputContext';

export const NotesUI = ({
  FieldDefinition,
  props
}: {
  FieldDefinition: FieldDefinition;
  props: OutputInterface;
}) => {
  const theme = useTheme();
  const isMobile = useMobile();
  const { ObjectInstance: CompleteObjectInstance } = props;

  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  const FieldInstance: FieldInstance | undefined = FieldInstanceList.find(
    (FieldInstance: FieldInstance) =>
      FieldInstance.FieldDefinitionId === FieldDefinition.Id
  );

  const itemStyles: React.CSSProperties = isMobile
    ? { flexDirection: 'column', textAlign: 'center', alignItems: 'flex-start' }
    : { flexDirection: 'row', alignItems: 'flex-end' };

  switch (FieldDefinition.Title) {
    case 'Notes':
      if (props.display === 'clientView') return <div />;
      if (FieldInstance && FieldInstance.FieldValue === '') return <div />;
      return (
        <Grid item xs={12} style={{ padding: theme.spacing(2) }}>
          <Typography>NOTES: {FieldInstance?.FieldValue}</Typography>
        </Grid>
      );
    case 'FinanceType':
      if (props.display === 'clientView') return <div />;
      if (FieldInstance && FieldInstance.FieldValue === '') return <div />;
      return (
        <ListItem
          style={{
            padding: theme.spacing(2),
            display: 'flex',
            ...itemStyles
          }}>
          <Typography>Finance Type:</Typography>
          <div
            style={{
              flexGrow: 1,
              borderBottom: isMobile ? 'none' : '1px dotted lightgrey',
              marginLeft: 15,
              marginRight: 15
            }}
          />
          <div>
            <Typography align={isMobile ? 'left' : 'center'}>
              {FieldInstance?.FieldValue}
            </Typography>
          </div>
        </ListItem>
      );
    case 'DiscretionaryType': {
      if (props.display === 'clientView') return <div />;
      if (FieldInstance && FieldInstance.FieldValue === '') return <div />;
      return (
        <ListItem
          style={{
            padding: theme.spacing(2),
            display: 'flex',
            ...itemStyles
          }}>
          <Typography>Discretionary Type:</Typography>
          <div
            style={{
              flexGrow: 1,
              borderBottom: isMobile ? 'none' : '1px dotted lightgrey',
              marginLeft: 15,
              marginRight: 15
            }}
          />
          <div>
            <Typography align={isMobile ? 'left' : 'center'}>
              {FieldInstance?.FieldValue}
            </Typography>
          </div>
        </ListItem>
      );
    }

    default:
      return (
        <ListItem
          style={{ padding: theme.spacing(2), display: 'flex', ...itemStyles }}>
          <Typography>Finance Product:</Typography>
          <div
            style={{
              flexGrow: 1,
              borderBottom: isMobile ? 'none' : '1px dotted lightgrey',
              marginLeft: 15,
              marginRight: 15
            }}
          />
          <div>
            <Typography align={isMobile ? 'left' : 'center'}>
              {FieldInstance?.FieldValue}
            </Typography>
          </div>
        </ListItem>
      );
  }
};

export const ForFrom = ({ value }) => {
  return (
    <div style={{ margin: 8, display: 'flex' }}>
      <Typography variant="caption">
        {setForValueFromCalculationNo(parseInt(value)).newforValue}
      </Typography>
      <div style={{ width: 16 }} />
      <Typography variant="caption">
        {setForValueFromCalculationNo(parseInt(value)).newfromValue}
      </Typography>
    </div>
  );
};

export const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

export const StringSpacing = (Title: string) => {
  const removeUnderscore: string = Title.replace(/_/g, ' ');
  if (removeUnderscore.slice(3, removeUnderscore.length)) {
    const spacing: string = removeUnderscore.replace(/([A-Z])/g, ' $1').trim();
    return spacing;
  } else return removeUnderscore;
};
