import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Alert from '@material-ui/lab/Alert';
import { handleObjectKeys } from './functions';

import { IAddressApiFullAddress, IAddressWarning } from 'types/address';
import GoogleMapContainer from 'common/Maps';
import AddressSelection from './components';
import { useGoogleMapsAPI } from 'hooks/useGoogleMapsAPI';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  searchWidth: { width: '100%' },
  paper: {
    border: `1px ${theme.palette.grey['A100']} solid`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    margin: `8px 0px`,
    width: '100%'
  }
}));
const Address = ({ rowData }: { rowData?: IAddressApiFullAddress }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [fullAddress, setFullAddress] =
    React.useState<IAddressApiFullAddress | null>(rowData ? rowData : null);

  const [warning, setWarning] = React.useState<boolean>(false);
  const [warning404, setWarning404] = React.useState<IAddressWarning>({
    warning: false,
    text: ''
  });

  const WrappedGoogleMapContainer = useGoogleMapsAPI(GoogleMapContainer);
  return (
    <div className={classes.root}>
      <Grid container direction="column" spacing={1}>
        {warning ||
          (warning404.warning && (
            <Grid item style={{ paddingBottom: theme.spacing(1.5) }}>
              <Alert severity="error" style={{ width: '100%' }}>
                {warning ? (
                  <Typography>
                    <b>Error:</b> Map cannot Be Loaded Right Now.{' '}
                    <b>Please Contact Support</b>
                  </Typography>
                ) : (
                  <Typography>
                    {warning404.text !== '' ? (
                      warning404.text
                    ) : (
                      <div>
                        <b>Error:</b> {`Could't Load Address.`}{' '}
                        <b>{`Data Couldn't Load`}</b>
                      </div>
                    )}
                  </Typography>
                )}
              </Alert>
            </Grid>
          ))}

        <Grid item>
          <Grid
            alignItems="center"
            container
            justifyContent="flex-start"
            spacing={1}>
            <Grid item style={{ flexGrow: 1, padding: 0 }}>
              <AddressSelection
                setAddress={setFullAddress}
                setWarning={setWarning404}
                props={{ isIntegration: false }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {fullAddress ? (
        <Grid container direction="column">
          <Grid item style={{ padding: theme.spacing(2) }}>
            <WrappedGoogleMapContainer
              position={fullAddress}
              setWarning={setWarning}
            />
          </Grid>

          <Grid item>
            {Object.values(fullAddress).map((element: string, idx: number) => {
              if (typeof element === 'object') return <div key={idx} />;
              const keys = handleObjectKeys({
                index: idx,
                address: fullAddress
              });

              if (keys && keys.includes(''))
                if (element === '' || !element) return <div key={idx} />;
              return (
                <Paper className={classes.paper} key={idx}>
                  <Typography component={'span'}>
                    <Grid
                      data-cy="full-address-details"
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center">
                      <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>
                          {keys}
                        </Typography>{' '}
                      </Grid>
                      <Grid item>: {element}</Grid>
                    </Grid>
                  </Typography>
                </Paper>
              );
            })}
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Address;
