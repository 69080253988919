import { Button, Card, Grid, Paper } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Forward, Reply, ReplyAll } from '@material-ui/icons';
import { FileAttachment, Message } from '@microsoft/microsoft-graph-types';
import { ParentThreadContext } from 'components/MessageHub/components/Threads/context/ParentThreadsContext';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { useContext, useState } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { theme } from 'theme';
import { ConversationContext } from '../../context/ConversationContext';
import ForwardEmail from '../ForwardEmail';
import ReplyEmail from './ReplyEmail';
import ReplyExpansion from './ReplyExpansion';

const ReplyParent = ({
  reply,
  children,
  expandedReplyId,
  setExpandedReplyId
}: {
  reply: Message;
  children: React.ReactNode;
  expandedReplyId: string;
  setExpandedReplyId: (isExpanded: string) => void;
}) => {
  const user = useTypedSelector((state) => state.user.user);
  const { handleMarkReply } = useContext(ConversationContext);
  const { getRefreshEmails } = useContext(MessageHubContext);

  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [type, setType] = useState<'reply' | 'replyAll'>('reply');
  const [isForwarding, setIsForwarding] = useState<boolean>(false);

  const replyId = reply?.id || '';
  const isUnread = !reply.isRead;
  const cardStyles = {
    position: 'relative' as const,
    transition: 'box-shadow 0.3s',
    boxShadow: isHovered
      ? '0px 3px 10px rgba(0, 0, 0, 0.2)'
      : '0px 1.5px 5px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    padding: theme.spacing(2),
    overflow: 'visible',
    border: isUnread ? `2px solid ${green[200]}` : 'none',
    backgroundColor: isUnread ? green[50] : 'none'
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClick = async () => {
    if (expandedReplyId === replyId) {
      clearAll();
      setExpandedReplyId('');
    } else {
      clearAll();
      setExpandedReplyId(replyId);
      if (isUnread) {
        await handleMarkReply(replyId);
        await getRefreshEmails();
      }
    }
  };

  const clearAll = () => {
    setIsReplying(false);
    setIsForwarding(false);
  };

  const handleReplyClick = (e) => {
    e.stopPropagation();
    setIsReplying(true);
    setIsForwarding(false);
  };

  const handleForwardClick = (e) => {
    e.stopPropagation();
    setIsReplying(false);
    setIsForwarding(true);
  };

  return (
    <Card
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={cardStyles}
      onClick={handleClick}>
      {children}

      {expandedReplyId === replyId && (
        <>
          <div
            style={{
              backgroundColor: 'white',
              margin: isUnread ? theme.spacing(2) : 0,
              borderRadius: isUnread ? theme.shape.borderRadius : 0
            }}>
            <ReplyExpansion reply={reply} user={user} />
          </div>

          {isForwarding && (
            <Paper style={{ padding: theme.spacing(2) }}>
              <ForwardEmail
                messageId={reply.id || ''}
                message={reply}
                setIsForwarding={setIsForwarding}
                clearAll={clearAll}
              />
            </Paper>
          )}

          {isReplying && (
            <Paper style={{ padding: theme.spacing(2) }}>
              <ReplyEmail
                messageId={reply.id || ''}
                message={reply}
                setIsReplying={setIsReplying}
                type={type}
                clearAll={clearAll}
              />
            </Paper>
          )}

          {!isReplying && !isForwarding && (
            <Grid container direction="row" spacing={2}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  startIcon={<Reply />}
                  onClick={(e) => {
                    handleReplyClick(e);
                    setType('reply');
                  }}>
                  Reply
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  startIcon={<ReplyAll />}
                  onClick={(e) => {
                    handleReplyClick(e);
                    setType('replyAll');
                  }}>
                  Reply All
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: 'none' }}
                  onClick={handleForwardClick}
                  startIcon={<Forward />}>
                  Forward
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Card>
  );
};

export default ReplyParent;
