export interface ICreateDocumentInput {
  type: EDocumentType;
  side: string;
  file: File;
  account_holder: string;
}

export interface IFraudCheckResult {
  uuid: string;
  result: 'Acceptable' | 'High Risk' | 'Risk' | 'Low Risk';
  documents: Array<{
    name: string;
    type: string;
    result: string;
    indicators: any;
  }>;
}

export interface IAccountDataResult {
  status: string;
  accounts: Array<{
    uuid: string;
    identifiers: {
      number?: string;
      sortcode?: string;
      iban?: string;
      swift?: string;
      bank?: string;
    };
    balance: string;
    transactions: Array<{
      date: string;
      description: string;
      amount: string;
      running_balance: string;
    }>;
  }>;
}

export enum EDocumentResults {
  ACCEPTABLE = 'Acceptable',
  HIGH_RISK = 'High Risk',
  RISK = 'Risk',
  LOW_RISK = 'Low Risk',
  NONE = 'none'
}

export enum EDocumentType {
  BANK_STATEMENT = 'BANK_STATEMENT',
  // UTILITY_BILL = 'UTILITY_BILL',
  // STUDENT_STATUS = 'STUDENT_STATUS',
  // INVESTMENT_PORTFOLIO = 'INVESTMENT_PORTFOLIO',
  // PENSION_STATEMENT = 'PENSION_STATEMENT',
  // PAYSLIP = 'PAYSLIP',
  // NO_CLAIMS_DISCOUNT = 'NO_CLAIMS_DISCOUNT',
  // V5C = 'V5C',
  OTHER = 'OTHER'
}
