import { IRedflagAPIData } from 'types/redflagInterfaces';

export type Adaptors =
  | 'credit_safe'
  | 'companies_house'
  | 'redflag'
  | 'redflag_graphql'
  | 'company_navigator';

export enum SearchType {
  Company = 'company',
  Director = 'director'
}

export interface OffkeyCompanyLite {
  title: string;
  company_status: string;
  address_snippet: string;
  company_type: string;
  company_number: string;
  description: string;
  credit_safe_id: string;
  redflag_GraphQL_id: string;
}

export interface IDirectorCompany {
  company_id: string;
  company_type: string;
  rfa_rating_score: string;
  credit_score: string;
  company_name: string;
}

export interface OffkeyDirectorLite {
  address: string;
  appointment_type: string;
  birthdate: string;
  company: string;
  companyId: number;
  company_id: number;
  company_name: string;
  county: string;
  date_appointed: string;
  date_resigned: string;
  first_name: string;
  id: string;
  is_current: false;
  locality: string;
  nationality: string;
  occupation: string;
  other_appointments: string;
  person_id: string;
  postcode: string;
  rfa_rating: ERfaRating;
  source: string;
  surname: string;
  title: string;
  town: string;
}

export interface OffkeyCompany {
  company_number: string;
  company_name: string;
  vat_number: string;
  sic_codes: [];
  third_party_apis: {
    redflagOG: IRedflagAPIData;
    redflagOG_Source: IRedflagAPIData;
  };
}

export interface IStringQuery {
  eq?: string;
  neq?: string;
  in?: string[];
  nin?: string[];
  contains?: string;
}

export interface INumberQuery {
  eq?: number;
  neq?: number;
  in?: number[];
  nin?: number[];
}

export interface ILongNumberOperation extends INumberQuery {
  gt?: number;
  gte?: number;
  lt?: number;
  lte?: number;
}

export enum ERfaRating {
  THREE_RED_FLAGS = 'Three Red Flags',
  TWO_RED_FLAGS = 'Two Red Flags',
  ONE_RED_FLAG = 'One Red Flag',
  NOT_SET = 'Not Set',
  AMBER = 'Amber',
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  NA = 'N/A',
  NOT_TRADING = 'Not Trading',
  INSOLVENT = 'Insolvent',
  STRIKE_OFF = 'Strike Off',
  DISSOLVED = 'Dissolved',
  BUSINESS_DISCONTINUED = 'Business Discontinued',
  PRE_INSOLVENCY = 'Pre-Insolvency',
  NEWLY_INCORPORATED = 'Newly Incorporated',
  PROVISIONAL_TWO_RED_FLAGS = 'Provisional Two Red Flags',
  PROVISIONAL_ONE_RED_FLAG = 'Provisional One Red Flag',
  PROVISIONAL_BRONZE = 'Provisional Bronze',
  PROVISIONAL_SILVER = 'Provisional Silver',
  INACTIVE = 'Inactive',
  UNKNOWN = 'Unknown'
}

export interface IDirectorSearchCriteria {
  id?: IStringQuery;
  first_name?: IStringQuery;
  surname?: IStringQuery;
  rfa_rating?: ERfaRating;
  nationality?: IStringQuery;
  town?: IStringQuery;
  country?: IStringQuery;
  postcode?: IStringQuery;
  occupation?: IStringQuery;
  locality?: IStringQuery;
  address?: IStringQuery;
  title?: IStringQuery;
  appointment_type?: IStringQuery;
  birthdate?: IStringQuery;
  date_appointed?: IStringQuery;
  date_resigned?: IStringQuery;
  other_appointments?: IStringQuery;
  company?: IStringQuery;
  company_id?: INumberQuery;
}

export interface ICompanySearchCriteria {
  company_name?: IStringQuery;
  company_number?: IStringQuery;
  company_type?: IStringQuery;
  legal_status?: IStringQuery;
  postcode?: IStringQuery;
  is_trading?: IStringQuery;
  is_limited?: IStringQuery;
}

export interface ISelectOption {
  value: string;
  label: string;
}

export interface IFormField {
  name: keyof (IDirectorSearchCriteria & ICompanySearchCriteria);
  label: string;
  type: 'text' | 'select';
  options?: ISelectOption[];
}

export interface IQuery {
  company: ICompanySearchCriteria;
  director: IDirectorSearchCriteria;
}
