import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * PROCESS INSTANCE API
 * @constructor
 * @param {string} token
 * @param {object} deal
 */
export const CreateProcess = async ({ deal, UserInstanceId }) => {
  let url = 'ProcessInstanceAPI.ashx';
  if (UserInstanceId)
    url = `ProcessInstanceAPI.ashx?userinstanceid=${UserInstanceId}`;
  const config: AxiosRequestConfig = { method: 'post', url, data: deal };
  return await dbQueue({ config, url });
};

export const GetProcessById = async ({ Id, ProcessStepDefinitionId }) => {
  let url = `ProcessInstanceAPI.ashx?processinstanceid=${Id}`;
  if (ProcessStepDefinitionId)
    url = `ProcessInstanceAPI.ashx?processinstanceid=${Id}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const SubmitProcessStep = async ({ submitDeal }) => {
  let url = `ProcessInstanceAPI.ashx?processinstanceid=${submitDeal.ProcessInstance.Id}`;
  const config: AxiosRequestConfig = { method: 'post', url, data: submitDeal };
  return await dbQueue({ config, url });
};

export const QuickProcessUpdate = async ({ data }) => {
  let url = `ProcessInstanceAPI.ashx?action=QUICKUPDATE`;
  const config: AxiosRequestConfig = { method: 'post', url, data };
  return await dbQueue({ config, url });
};

export const CopyProcessToStep = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId
}) => {
  let url = `ProcessInstanceAPI.ashx?action=COPY&processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const CopyDeal = async (Id: number) => {
  let url = `ProcessInstanceAPI.ashx?action=COPY&processinstanceid=${Id}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};
