import { useEffect } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { firebase } from 'redux/firebase';
import { useDispatch } from 'react-redux';

import { Calculation } from 'types/calculatorInterfaces';
import { SET_CALCULATION, SET_CALCULATION_ID } from 'redux/actions/types';
import { SaveLabels } from 'components/Calculator/Calculator/interfaces';

const { Autosave } = SaveLabels;

export const useCalculatorStorage = ({
  setDialogData
}: {
  setDialogData?: React.Dispatch<
    React.SetStateAction<{ [key: string]: Calculation }>
  >;
}) => {
  const dispatch = useDispatch();
  const { calculation, calculationId } = useTypedSelector((s) => s.calculator);
  const { user } = useTypedSelector((s) => s.user);
  let newRef = firebase
    .firestore()
    .collection('userAccount')
    .doc(user.Id.toString())
    .collection('calculations');

  const saveCalculation = (newCalculation: Calculation, customId?: string) => {
    let newCalculationRef;

    const isAutoSave = customId?.includes(Autosave);

    const calculationName = (
      isAutoSave ? customId ?? '' : newCalculation.Title
    ).trim();

    const dataToSave = isAutoSave
      ? { ...newCalculation, Title: calculationName }
      : newCalculation;

    if (customId) {
      newCalculationRef = newRef.doc(customId);
    } else {
      newCalculationRef = newRef.doc();
    }

    return newCalculationRef.set(dataToSave).then(() => {
      return newCalculationRef;
    });
  };

  const getMyCalculations = async (): Promise<{
    [key: string]: Calculation;
  }> => {
    const myCalculations = {} as { [key: string]: Calculation };

    await newRef.get().then((snap) => {
      snap.forEach(
        (doc) => (myCalculations[doc.id] = doc.data() as Calculation)
      );
    });

    return myCalculations;
  };

  const loadCalculation = (calculation: Calculation, id: string) => {
    dispatch({ type: SET_CALCULATION, payload: calculation });
    dispatch({ type: SET_CALCULATION_ID, payload: id });
  };

  const getAutosaves = async (): Promise<{ [key: string]: Calculation }> => {
    const autosaves = {} as { [key: string]: Calculation };
    await newRef.get().then((snap) => {
      snap.forEach((doc) => {
        if (doc.id.includes(Autosave)) {
          autosaves[doc.id] = doc.data() as Calculation;
        }
      });
    });

    return autosaves;
  };

  const updateCalculation = (
    newCalculation: Calculation,
    existingId?: string
  ): Promise<void> => {
    const isAutoSave = existingId?.includes(Autosave);

    const calculationName = (
      isAutoSave ? existingId : newCalculation?.Title
    )?.trim();

    const dataToSave = isAutoSave
      ? { ...newCalculation, Title: calculationName }
      : newCalculation;

    if (existingId) {
      return firebase
        .firestore()
        .collection('userAccount')
        .doc(user.Id.toString())
        .collection('calculations')
        .doc(existingId)
        .update(dataToSave)
        .then(async () => {
          const test = await getMyCalculations();
          setDialogData && setDialogData(test);
        })
        .catch((e) => {
          console.log(e);
          throw new Error(e);
        });
    }
    return Promise.resolve();
  };

  const deleteCalculation = (idToDelete: string): Promise<void> => {
    if (!idToDelete) {
      console.log('No ID provided to delete');
      return Promise.resolve();
    }

    return firebase
      .firestore()
      .collection('userAccount')
      .doc(user.Id.toString())
      .collection('calculations')
      .doc(idToDelete)
      .delete()
      .then(async () => {
        if (idToDelete === calculationId) {
          dispatch({ type: SET_CALCULATION_ID, payload: '' });
        }
      });
  };

  useEffect(() => {
    getMyCalculations();
  }, []);

  return {
    getAutosaves,
    saveCalculation,
    updateCalculation,
    deleteCalculation,
    loadCalculation,
    getMyCalculations
  };
};
