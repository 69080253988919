import React, { useEffect, useState } from 'react';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import {
  DialogContent,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import { tableIcons } from 'components/ProcessTable/tableIcons';
import { IDontDeal } from 'hooks/useDealList';
import RefreshIcon from '@material-ui/icons/Refresh';
import { CustomDialog } from 'common/Dialog';
import { ExtremeCautionAlert } from './ExtremeCautionAlert';
import { ExtremeCautionAlertContact } from './ExtremeCautionAlert/ExtremeCautionAlertContact';
import { useTypedSelector } from 'redux/reducers';
import { IContactDoNotDeal } from 'hooks/useDoNotDealNonRedFlag';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginRight: '50px',
    marginBottom: '12px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center'
  },
  toolbarGrow: {
    flexGrow: 1
  },
  tableRow: {
    cursor: 'move'
  },
  clickableRow: {
    cursor: 'pointer'
  }
}));

export type DoNotDealEntry = IDontDeal | IContactDoNotDeal;

interface ExtremeCautionTableProps {
  data: DoNotDealEntry[];
  title: string;
  onRefresh: () => void;
}

const formatDate = (
  date: Date | string | number | { seconds: number; nanoseconds: number }
): string => {
  let dateObject: Date;

  if (date instanceof Date) {
    dateObject = date;
  } else if (typeof date === 'string' || typeof date === 'number') {
    dateObject = new Date(date);
  } else if (typeof date === 'object' && 'seconds' in date) {
    dateObject = new Date(date.seconds * 1000);
  } else {
    return 'Invalid Date';
  }

  return dateObject.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
};

const userDefinitions = [
  { id: 523, name: 'Customers' },
  { id: 528, name: 'Contacts' },
  { id: 26, name: 'Suppliers' },
  { id: 21, name: 'Lenders' },
  { id: 641, name: 'Introducers' }
];

const CompanyDetailsDialog = ({
  open,
  onClose,
  company,
  onRefresh
}: {
  open: boolean;
  onClose: () => void;
  company: DoNotDealEntry | null;
  onRefresh: () => void;
}) => {
  const [historyAdded, setHistoryAdded] = useState(false);

  useEffect(() => {
    if (historyAdded) {
      onRefresh();
      onClose();
    }
  }, [historyAdded, onRefresh, onClose]);

  if (!company) return null;

  const handleClose = () => {
    onClose();
  };

  const isIDontDeal = (entry: DoNotDealEntry): entry is IDontDeal => {
    return 'company_number' in entry;
  };

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      maxSize="md"
      alert={{
        title: 'Extreme Caution Alert',
        description: 'Only Super Admins Can Review This Dialog',
        type: 'info'
      }}>
      <DialogContent>
        <Typography color="textPrimary" variant="h3">
          {company.name}
        </Typography>
        <Typography>
          {isIDontDeal(company) ? 'Company Number' : 'Email'}:{' '}
          {isIDontDeal(company) ? company.company_number : company.email}
        </Typography>
        <Typography>Description: {company.Description}</Typography>
        <Typography>Reported By: {company.ReportedBy}</Typography>
        <Typography>Date Created: {formatDate(company.dateCreated)}</Typography>
        <Typography>
          User Definition:{' '}
          {userDefinitions.find((def) => def.id === company.UserDefinitionId)
            ?.name || 'Unknown'}
        </Typography>

        <br />
        {isIDontDeal(company) ? (
          <ExtremeCautionAlert
            name={company.name}
            company_number={company.company_number}
            UserDefinitionId={company.UserDefinitionId}
            setHistoryAdded={setHistoryAdded}
          />
        ) : (
          <ExtremeCautionAlertContact
            name={company.name}
            email={company.email}
            UserDefinitionId={company.UserDefinitionId}
            setHistoryAdded={setHistoryAdded}
          />
        )}
      </DialogContent>
    </CustomDialog>
  );
};

const ExtremeCautionTable = ({
  data,
  title,
  onRefresh
}: ExtremeCautionTableProps) => {
  const { user } = useTypedSelector((s) => s.user);
  const isSuperAdmin = user.SystemAccess >= 12;
  const classes = useStyles();
  const [filter, setFilter] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<DoNotDealEntry | null>(
    null
  );
  const isIDontDeal = (entry: DoNotDealEntry): entry is IDontDeal => {
    return 'company_number' in entry;
  };

  const columns = [
    {
      title: 'Company Name',
      field: 'name',
      render: (rowData: DoNotDealEntry) => (
        <Typography color="textPrimary">{rowData.name}</Typography>
      )
    },
    {
      title: 'Company Number / Email',
      field: 'identifier',
      render: (rowData: DoNotDealEntry) =>
        isIDontDeal(rowData) ? rowData.company_number : rowData.email
    },
    {
      title: 'Description',
      field: 'Description'
    },
    {
      title: 'Reported By',
      field: 'ReportedBy'
    },
    {
      title: 'Date Created',
      field: 'dateCreated',
      render: (rowData: DoNotDealEntry) => formatDate(rowData.dateCreated)
    }
    // {
    //   title: 'User Definition',
    //   field: 'UserDefinitionId',
    //   render: (rowData: DoNotDealEntry) => {
    //     const userDef = userDefinitions.find(
    //       (def) => def.id === rowData.UserDefinitionId
    //     );
    //     return userDef ? userDef.name : 'Unknown';
    //   }
    // }
  ];

  const handleRowClick = (rowData: DoNotDealEntry) => {
    setSelectedCompany(rowData);
    setDialogOpen(true);
  };

  const filteredData = React.useMemo(() => {
    if (filter === null) return data;
    return data.filter((item) => item.UserDefinitionId === filter);
  }, [data, filter]);

  const CustomToolbar = (props: any) => {
    return (
      <div className={classes.toolbar}>
        <div className={classes.toolbarGrow}>
          {props.searchText !== undefined && <MTableToolbar {...props} />}
        </div>
        {/* <FormControl className={classes.formControl}>
          <InputLabel id="user-definition-filter-label">
            User Definition
          </InputLabel>
          <Select
            labelId="user-definition-filter-label"
            value={filter || ''}
            onChange={(e) =>
              setFilter(
                e.target.value ? parseInt(e.target.value as string, 10) : null
              )
            }>
            <MenuItem value="">All</MenuItem>
            {userDefinitions.map((def) => (
              <MenuItem key={def.id} value={def.id}>
                {def.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
    );
  };

  return (
    <>
      <MaterialTable
        columns={columns}
        data={filteredData}
        title={title}
        icons={tableIcons}
        options={{
          exportAllData: true,
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          headerStyle: { zIndex: 1 },
          rowStyle: (rowData) => ({
            cursor: isSuperAdmin ? classes.clickableRow : classes.tableRow
          })
        }}
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: onRefresh
          }
        ]}
        onRowClick={(event, rowData) => {
          if (isSuperAdmin && rowData) {
            handleRowClick(rowData);
          }
        }}
      />
      <CompanyDetailsDialog
        open={dialogOpen && isSuperAdmin}
        onClose={() => setDialogOpen(false)}
        company={selectedCompany}
        onRefresh={onRefresh}
      />
    </>
  );
};

export default ExtremeCautionTable;
