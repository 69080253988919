import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Grid,
  Popover,
  TextField,
  Typography
} from '@material-ui/core';
import { Description, CloudDownload, Delete } from '@material-ui/icons';
import { IGuest } from 'components/Calendar/interface';
import FileStoragePicker from 'components/FileStorage/components/FileStoragePicker';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { useContext, useState } from 'react';
import { theme } from 'theme';
import { FbFileRef } from 'types/interfaces';
import { CalendarContext } from '../../context/CalendarContext';
import { EventContext } from '../../context/EventContext';

const CalendarBody = () => {
  return (
    <div
      style={{
        border: `2px ${theme.palette.grey[200]} solid`,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
      }}>
      <CalendarDescription />
      <CalendarAttachments />
    </div>
  );
};

export default CalendarBody;

const CalendarAttachments = () => {
  const { formValues, setFormValues } = useContext(CalendarContext);
  const {
    handleAttachmentChange,
    handleAttachmentViewerChange,
    handleDownload
  } = useContext(EventContext);

  const [openViewers, setOpenViewers] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentFileIndex, setCurrentFileIndex] = useState<number>(0);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentFileIndex(index);
    setOpenViewers(true);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenViewers(false);
  };

  return (
    <div>
      <FileStoragePicker
        plugin
        title={'Add Attachments'}
        onSelect={handleAttachmentChange}
      />

      <div style={{ paddingBottom: 5 }} />
      {formValues.attachments.length > 0 && (
        <div
          style={{
            border: `1px ${theme.palette.grey[400]} solid`,
            padding: theme.spacing(1),
            borderRadius: theme.shape.borderRadius
          }}>
          <Grid container spacing={2}>
            {formValues.attachments.map((element: FbFileRef, index: number) => {
              return (
                <div
                  key={index}
                  style={{ padding: theme.spacing(1), width: '100%' }}>
                  <Grid
                    container
                    style={{
                      backgroundColor: theme.palette.success.main,
                      borderRadius: theme.shape.borderRadius
                    }}
                    alignItems="center"
                    justifyContent="center">
                    <Grid item style={{ padding: 8 }}>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start'
                        }}>
                        <Typography
                          variant="h6"
                          style={{
                            color: theme.palette.primary.contrastText,
                            marginRight: 8
                          }}>
                          Name:
                          {element.nickName ? element.nickName : element.name}
                        </Typography>
                      </Box>

                      <Box
                        style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography
                          variant="caption"
                          style={{ color: theme.palette.primary.contrastText }}>
                          Size: {`${(element.size / 1024).toFixed(2)} KB`}
                        </Typography>
                      </Box>
                    </Grid>

                    <div style={{ flexGrow: 1 }} />

                    <Grid item style={{ padding: 8 }}>
                      <ButtonGroup
                        variant="outlined"
                        style={{ color: 'white' }}>
                        <Button
                          onClick={(e) => handleDownload(e, element)}
                          style={{ borderColor: 'white', color: 'white' }}>
                          <CloudDownload style={{ color: 'white' }} />
                        </Button>

                        <Button
                          onClick={() => {
                            const updatedAttachments =
                              formValues.attachments.filter(
                                (_item, idx) => idx !== index
                              );
                            setFormValues({
                              ...formValues,
                              attachments: updatedAttachments
                            });
                          }}
                          style={{ borderColor: 'white', color: 'white' }}>
                          <Delete style={{ color: 'white' }} />
                        </Button>

                        {formValues.guests.length > 0 && (
                          <Button
                            onClick={(e) => handlePopoverOpen(e, index)}
                            style={{ borderColor: 'white', color: 'white' }}>
                            Viewers
                          </Button>
                        )}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
          </Grid>
          <Popover
            open={openViewers}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            {formValues.guests.map((guest: IGuest, index: number) => {
              const attachmentForCurrentViewer = formValues.attachments[
                currentFileIndex
              ]?.users.includes(guest.UserInstanceId.toString());

              return (
                <div key={index} style={{ padding: theme.spacing(1) }}>
                  <div
                    style={{
                      border: `2px ${
                        attachmentForCurrentViewer
                          ? theme.palette.success.main
                          : theme.palette.grey[200]
                      } solid`,
                      padding: theme.spacing(1),
                      backgroundColor: attachmentForCurrentViewer
                        ? theme.palette.success.light
                        : '',
                      borderRadius: theme.shape.borderRadius
                    }}>
                    <Grid container direction="row">
                      <Grid item>
                        <Checkbox
                          checked={!!attachmentForCurrentViewer}
                          onChange={(e) =>
                            handleAttachmentViewerChange(
                              e,
                              guest.UserInstanceId.toString(),
                              currentFileIndex
                            )
                          }
                        />
                      </Grid>
                      <Grid item>
                        <QuickUserFromId
                          UserInstanceId={guest.UserInstanceId.toString()}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              );
            })}
          </Popover>
        </div>
      )}
    </div>
  );
};

const CalendarDescription = () => {
  const { handleInputChange } = useContext(EventContext);
  const { formValues } = useContext(CalendarContext);

  return (
    <TextField
      data-cy="event-description"
      label="Event Description"
      placeholder="Event Description"
      variant="outlined"
      fullWidth
      value={formValues.description}
      name="description"
      onChange={handleInputChange}
      margin="normal"
      InputProps={{
        startAdornment: (
          <Description
            fontSize="medium"
            style={{ paddingRight: theme.spacing(1) }}
          />
        )
      }}
    />
  );
};
