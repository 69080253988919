import { Dispatch, SetStateAction } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box
} from '@material-ui/core';
import { MTableToolbar } from '@material-table/core';
import { makeStyles } from '@material-ui/core/styles';

import { mapUserDefinitionIdToName } from 'helpers';
import { globalIds } from 'helpers/globalIdConfig';
import { CustomerType } from 'types/interfaces';
import { Status, SelectedFilters } from '../types';

interface Props {
  hasSelectedRows: boolean;
  selectedFilters: SelectedFilters;
  setSelectedFilters: Dispatch<SetStateAction<SelectedFilters>>;
  renderStatus?: boolean;
}

const useStyles = makeStyles((theme) => ({
  // MTableToolbar container class:
  toolbarRoot: {
    overflow: 'visible',
    padding: 16,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    // MUI adds a spacer div between the title and search bar. Remove it:
    '& > div[class*="spacer"]': {
      display: 'none'
    }
  },
  // MTableToolbar icons (buttons) container class:
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  container: {
    marginLeft: 15,
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    justifyContent: 'flex-end'
  }
}));

export const TableFilters = (props: Props) => {
  const classes = useStyles();

  const {
    setSelectedFilters,
    selectedFilters,
    hasSelectedRows,
    renderStatus = false
  } = props;

  const filterOptions = {
    userGroup: Object.values(globalIds.userDefinitionIds).map((id) => ({
      value: id,
      label: mapUserDefinitionIdToName(id)
    })),
    customerType: Object.values(CustomerType).map((c) => ({
      value: c,
      label: c
    })),
    status: Object.values(Status).map((s) => ({
      // NOTE: contacts dont actually have a status when they are Ready (implicit)
      value: s === Status.Ready ? undefined : s,
      label: s
    }))
  };

  const customerType = 'Customer Type';
  const userGroup = 'User Group';
  const status = 'Status';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{
        paddingRight: 10,
        backgroundColor: hasSelectedRows ? 'rgb(238, 238, 239)' : 'transparent'
      }}>
      <div>
        <MTableToolbar
          {...props}
          // searchFieldStyle={{ maxWidth: 190 }}
          classes={{
            // override MTableToolbar classes
            root: classes.toolbarRoot,
            actions: classes.actions
          }}
        />
      </div>
      <div className={classes.container}>
        <FormControl variant="outlined" size="small" style={{ minWidth: 145 }}>
          <InputLabel>{userGroup}</InputLabel>
          <Select
            autoWidth
            multiple
            value={selectedFilters.userGroup || []}
            onChange={(e) =>
              setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                userGroup: (e.target.value as number[]) || []
              }))
            }
            label={userGroup}>
            {filterOptions.userGroup.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl variant="outlined" size="small" style={{ minWidth: 145 }}>
          <InputLabel>{customerType}</InputLabel>
          <Select
            autoWidth
            multiple
            value={selectedFilters.customerType || []}
            onChange={(e) =>
              setSelectedFilters((prevFilters) => ({
                ...prevFilters,
                customerType: (e.target.value as (string | undefined)[]) || []
              }))
            }
            label={customerType}>
            {filterOptions.customerType.map((o) => (
              <MenuItem key={o.value} value={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {renderStatus && (
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 145 }}>
            <InputLabel>{status}</InputLabel>
            <Select
              autoWidth
              multiple
              value={selectedFilters.status || []}
              onChange={(e) =>
                setSelectedFilters((prevFilters) => ({
                  ...prevFilters,
                  status: (e.target.value as (string | undefined)[]) || []
                }))
              }
              label={status}>
              {filterOptions.status.map((o) => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </div>
    </Box>
  );
};
