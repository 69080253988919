import {
  ChangeEvent,
  useState,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react';
import {
  Box,
  TextField,
  Typography,
  MenuItem,
  useTheme,
  Paper,
  Card,
  CardContent,
  Grid
} from '@material-ui/core';
import { useCompaniesHouse } from 'hooks/useCompaniesHouse';
import {
  CompanySearchResourceItem,
  CompanyProfile,
  FilingHistoryList,
  OfficerList,
  persons_with_significant_control,
  FilingHistoryItem
} from 'types/companiesHouseInterfaces';

const CompaniesHouseSearch = () => {
  const theme = useTheme();
  const { q, setQ, data, getCompanyProfile, company } = useCompaniesHouse();
  const areItems = data?.items;
  const [filters, setFilters] = useState();
  const [selected, setSelected] = useState<
    CompanySearchResourceItem | undefined
  >();

  const handleSelectItem = (item: CompanySearchResourceItem) => {
    setSelected(item);
  };

  const handleSearch = (e: ChangeEvent) => {
    const { value } = e.target as HTMLInputElement;
    setQ(value);
  };

  useEffect(() => {
    if (selected) getCompanyProfile(selected.company_number);
  }, [selected]);

  // const createUser = () => {};
  // const addToDeal = () => {};

  return (
    <Box style={{ padding: 8 }}>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <TextField onChange={handleSearch} value={q} fullWidth />
        </Grid>

        <Grid item>
          {selected && (
            <Card style={{ background: theme.palette.background.default }}>
              <CardContent>
                <Typography variant="h6">{selected.title}</Typography>

                <Typography variant="body1">
                  {selected.address_snippet}
                </Typography>

                <Typography variant="caption">
                  {selected.company_status}
                </Typography>
                <Typography>{selected.company_number}</Typography>
                <Typography>{selected.company_type.toUpperCase()}</Typography>
              </CardContent>
            </Card>
          )}
        </Grid>

        <Grid item>
          {company && <CompanyProfileComponent company={company} />}
        </Grid>

        <Grid item>
          {areItems &&
            areItems
              .filter((e) => e)
              .map((item, idx) => (
                <CustomMenuItem
                  key={idx}
                  item={item}
                  selected={selected}
                  setSelected={setSelected}
                  handleSelectItem={handleSelectItem}
                />
              ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompaniesHouseSearch;

interface Props {
  company: CompanyProfile;
  filing_history: FilingHistoryList;
  officers: OfficerList;
  pwsc: persons_with_significant_control;
}

const CompanyProfileComponent = (props) => {
  let { company, filing_history, officers, pwsc } = props.company;
  const theme = useTheme();

  const handleDownloadDoc = (item: FilingHistoryItem) => {
    console.log('Item to download', { item });
  };

  return (
    <Paper
      elevation={3}
      style={{
        background: theme.palette.background.default,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius
      }}>
      SIC CODE: {company.sic_codes}
      {filing_history &&
        filing_history.items.length > 0 &&
        filing_history.items.map((item: FilingHistoryItem) => (
          <MenuItem
            key={item.transaction_id}
            onClick={() => handleDownloadDoc(item)}>
            <p>{item.date}</p>
            <p style={{ marginLeft: 100 }}>{item.category.toUpperCase()}</p>
          </MenuItem>
        ))}
    </Paper>
  );
};

const CustomMenuItem = ({
  item,
  selected,
  setSelected,
  handleSelectItem
}: {
  item: CompanySearchResourceItem;
  selected?: CompanySearchResourceItem | undefined;
  setSelected?: Dispatch<SetStateAction<CompanySearchResourceItem | undefined>>;
  handleSelectItem: (item: CompanySearchResourceItem) => void;
}) => {
  const theme = useTheme();
  return (
    <MenuItem
      onClick={() => {
        handleSelectItem(item);
      }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
      }}>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}>
        <Typography variant="h6">{item.title}</Typography>
      </Box>

      <Typography
        variant="caption"
        style={{
          color: theme.palette.error.main
          // marginLeft: theme.spacing(2)
        }}>
        {item.company_status}
      </Typography>

      <Typography style={{ color: theme.palette.success.main }}>
        {item.company_number}
      </Typography>
    </MenuItem>
  );
};

// const debounce = (func: (...args: any[]) => void, delay: number) => {
//   let debounceTimer: any;

//   const debounced = function (...args: any[]) {
//     clearTimeout(debounceTimer);
//     debounceTimer = setTimeout(() => func(...args), delay);
//   };

//   debounced.cancel = () => clearTimeout(debounceTimer);

//   return debounced;
// };
