import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: { marginTop: theme.spacing(2) },
  label: {
    background: theme.palette.background.paper,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
}));

const Component = ({ title, options, value, handleChange, fullWidth }) => {
  const classes = useStyles();
  const selectOptions = options ? options.split(',') : [];
  React.useEffect(() => {
    if (!value) handleChange('');
    // eslint-disable-next-line
  }, []);
  return (
    <React.Fragment>
      <FormControl
        variant="outlined"
        className={classes.formControl}
        fullWidth={fullWidth}>
        <InputLabel id="label" shrink className={classes.label}>
          {title}
        </InputLabel>
        <Select
          margin="dense"
          labelId="label"
          id="select"
          value={value}
          defaultValue={''}
          onChange={e => handleChange(e.target.value)}>
          {selectOptions.map((option, idx) => (
            <MenuItem value={option} key={idx}>
              <em>{option}</em>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
};

export default Component;
