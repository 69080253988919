import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { SetStateAction, useContext, useEffect, useState } from 'react';
import { theme } from 'theme';

import { Asset, Calculation } from 'types/calculatorInterfaces';
import { CalculatorStorageContext } from '../context/CalculatorContext';
import { useCalculationStyles } from './styles';
import AssetDisplayItem from './AssetDisplayItem';

const MyImportedAssetsDialog = ({
  state,
  setState,
  quoteId
}: {
  state: Calculation;
  setState: React.Dispatch<SetStateAction<Calculation>>;
  quoteId: number;
}) => {
  const { openMyAssets: open, setOpenMyAssets: setOpen } = useContext(
    CalculatorStorageContext
  );
  const classes = useCalculationStyles();

  const [hoveringStates, setHoveringStates] = useState({});
  const [localAssets, setLocalAssets] = useState<Asset[] | undefined>(
    state.Assets
  );

  useEffect(() => {
    setLocalAssets(state.Assets);
  }, [state.Assets]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogContent style={{ minWidth: 600, minHeight: 200 }}>
        <Grid container style={{ padding: 8 }}>
          <Grid item className={classes.storeItem}>
            <Typography variant="h4">{`Imported Assets (${
              localAssets?.length || 0
            })`}</Typography>
          </Grid>
        </Grid>

        <Grid container style={{ padding: 8, minWidth: 550 }} spacing={2}>
          {localAssets?.map((asset: Asset, idx) => {
            if (localAssets) {
              const isSingleAsset = localAssets.length === 1;

              return (
                <Grid
                  key={asset.ObjectInstanceId}
                  item
                  xs={isSingleAsset ? 12 : 6}
                  className={classes.storeItem}
                  onMouseEnter={() =>
                    setHoveringStates((prev) => ({
                      ...prev,
                      [asset.ObjectInstanceId]: true
                    }))
                  }
                  onMouseLeave={() =>
                    setHoveringStates((prev) => ({
                      ...prev,
                      [asset.ObjectInstanceId]: false
                    }))
                  }>
                  <Paper
                    className={classes.storeItemPaper}
                    elevation={3}
                    style={{
                      border: hoveringStates[asset.ObjectInstanceId]
                        ? `2px solid ${grey[400]}`
                        : ``
                    }}>
                    <AssetDisplayItem
                      asset={asset}
                      id={asset.ObjectInstanceId}
                    />
                    <div style={{ paddingBottom: theme.spacing(1) }} />

                    {quoteId && (
                      <>
                        {
                          <ButtonGroup
                            variant="contained"
                            color="primary"
                            fullWidth>
                            <Button
                              onClick={(event) => {
                                event.stopPropagation();
                                if (localAssets) {
                                  const updatedAssets = localAssets.filter(
                                    (stateAsset: Asset) =>
                                      stateAsset.ObjectInstanceId !==
                                      asset.ObjectInstanceId
                                  );

                                  setLocalAssets(updatedAssets);
                                  setState((prevState) => ({
                                    ...prevState,
                                    Assets: updatedAssets
                                  }));
                                }
                              }}>
                              Delete
                            </Button>
                          </ButtonGroup>
                        }
                      </>
                    )}
                  </Paper>
                </Grid>
              );
            }

            return <div key={idx} />;
          })}
        </Grid>
        {!localAssets ||
          (localAssets?.length === 0 && (
            <Grid style={{ margin: theme.spacing(5) }}>
              <Typography variant="h5" align="center">
                No Selected Assets
              </Typography>
            </Grid>
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default MyImportedAssetsDialog;
