import {
  InputAdornment,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { Warning } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import { Calculation, PaymentFrequency } from 'types/calculatorInterfaces';
import FundingProfile from '../Calculator/FundingProfile';
import { useCalculator } from '../Calculator/hooks/useCalculator';
import { TextFieldInput } from '../Utils/inputFields';

const InitialRentals = ({ tools }) => {
  const theme = useTheme();
  const { handleChange, config, state, isContractPlus1, submitForm } =
    useCalculator(tools);

  const [warning, setWarning] = useState(false);

  return (
    <Grid container spacing={1}>
      <Grid item sm={3} xs={12}>
        <TextFieldInput
          adornment="+"
          calculate={submitForm}
          disabled={!config.InitialRentals.show}
          name={'Initial Rentals'}
          onChange={handleChange('InitialRentals')}
          value={state.InitialRentals}
        />
      </Grid>

      <Grid item sm={3} xs={12}>
        <FundingProfile
          config={config}
          state={state}
          handleChange={handleChange}
        />
      </Grid>

      {config.Term.show && (
        <Grid item sm={3} xs={12}>
          <TextFieldInput
            InputProps={{
              startAdornment: warning ? (
                <div style={{ paddingRight: theme.spacing(1) }}>
                  <Tooltip
                    arrow
                    title="Term Must Equal The Sum Of Initial Rentals And Funding Profile">
                    <Warning style={{ color: theme.palette.warning.main }} />
                  </Tooltip>
                </div>
              ) : undefined,
              endAdornment: (
                <InputAdornment position="end">
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: 'bold',
                      color: theme.palette.info.main,
                      whiteSpace: 'nowrap'
                    }}>
                    {isContractPlus1 ? '+1' : ''} (Months)
                  </Typography>
                </InputAdornment>
              )
            }}
            adornment="Mts"
            calculate={submitForm}
            disabled={!config.Term.show}
            name={isContractPlus1 ? 'Term + 1' : 'Term'}
            onChange={handleChange('Term')}
            type="number"
            value={state.Term}
          />
        </Grid>
      )}
      <Grid item sm={3} xs={12}>
        <TextField
          data-cy="payment-frequency"
          fullWidth
          label="Payment Frequency"
          margin="dense"
          onChange={handleChange('PaymentFrequency')}
          select
          value={state.PaymentFrequency}>
          <MenuItem value={'Monthly'}> Monthly </MenuItem>
          <MenuItem data-cy="frequency" value={'Quarterly'}>
            {' '}
            Quarterly{' '}
          </MenuItem>
          <MenuItem value={'SemiAnnually'}>Semi Annually </MenuItem>
          <MenuItem value={'Annually'}> Annually </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};

export default InitialRentals;

//? Removing Auto-populating of Term
// const handleAutoUploading = (state: Calculation): number => {
//   const { FundingProfile } = state;

//   let fundingProfileAdjustment;
//   switch (state.PaymentFrequency) {
//     case PaymentFrequency.Quarterly:
//       fundingProfileAdjustment = Number(FundingProfile) * 3;
//       break;
//     case PaymentFrequency.SemiAnnually:
//       fundingProfileAdjustment = Number(FundingProfile) * 6;
//       break;
//     case PaymentFrequency.Annually:
//       fundingProfileAdjustment = Number(FundingProfile) * 12;
//       break;
//     default:
//       fundingProfileAdjustment = Number(FundingProfile);
//       break;
//   }

//   return fundingProfileAdjustment;
// };

//? Removing Auto-populating of Term
// const calculateContractPeriodAdjustment = (): number => {
//   if (state._Balloon.ContractPeriod === 0) {
//     return 0;
//   } else if (state._Balloon.ContractPeriod === 1) {
//     return 1;
//   }
//   return 0;
// };

//? Removing Auto-populating of Term
// useEffect(() => {
//   const { Term, InitialRentals } = state;

//   const fundingProfile = handleAutoUploading(state);
//   const initialRentals = Number(InitialRentals) || 0;
//   let termAmount: number = initialRentals + fundingProfile;
//   termAmount += calculateContractPeriodAdjustment();

//   const customEventType = {
//     target: {
//       value: termAmount
//     }
//   };

//   if (termAmount && termAmount !== Term) {
//     handleChange('Term')(customEventType);
//   }
// }, [
//   state.InitialRentals,
//   state.FundingProfile,
//   state._Balloon.ContractPeriod,
//   state.PaymentFrequency
// ]);
