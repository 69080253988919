import { Button, List, ListItem, Popover, Tooltip } from '@material-ui/core';
import zIndex from '@material-ui/core/styles/zIndex';
import { FileAttachment } from '@microsoft/microsoft-graph-types';
import { useState } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { theme } from 'theme';
import UploadingFiles, { downloadAttachment } from '../Files/UploadingFiles';

const Attachments = ({ attachments }) => {
  const user = useTypedSelector((state) => state.user.user);

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipEl, setTooltipEl] = useState(null);
  const [imageSrc, setImageSrc] = useState<string>('');

  const [isUploadingFilesOpen, setUploadingFilesOpen] =
    useState<boolean>(false);
  const [currentAttachment, setCurrentAttachment] = useState<FileAttachment>();

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setPopoverOpen(true);
  };

  const handleClose = () => {
    setPopoverOpen(false);
  };

  const handleAttachmentClick = (event) => {
    event.stopPropagation();
    handleOpen(event);
  };

  const handleTooltipOpen = (event, attachment: any) => {
    const { '@odata.mediaContentType': mediaContentType, contentBytes } =
      attachment;

    if (mediaContentType.startsWith('image')) {
      const src = `data:${mediaContentType};base64,${contentBytes}`;
      setImageSrc(src);
      setTooltipEl(event.currentTarget);
    } else {
      setImageSrc('');
      setTooltipEl(null);
    }
  };

  const handleTooltipClose = () => {
    setTooltipEl(null);
    setImageSrc('');
  };

  const handleCloseDialog = () => {
    setUploadingFilesOpen(false);
  };

  const tooltipOpen = Boolean(tooltipEl);

  const handleCount = () => {
    let count = 0;
    for (let i = 0; i < attachments.length; i++) {
      if (!attachments[i].contentType.startsWith('message')) {
        count++;
      }
    }

    return count;
  };

  return handleCount() > 0 ? (
    <div>
      {isUploadingFilesOpen && (
        <UploadingFiles
          isOpen={isUploadingFilesOpen}
          user={user}
          attachment={currentAttachment}
          handleClose={handleCloseDialog}
        />
      )}

      <Button
        size="small"
        onClick={handleAttachmentClick}
        style={{ textTransform: 'none', marginTop: '5px' }}>
        Attachments ({handleCount()})
      </Button>
      <Popover
        open={popoverOpen}
        onClose={handleClose}
        onMouseLeave={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <List>
          {attachments.map((attachment, index) => {
            if (attachment.contentType.startsWith('message')) return;
            return (
              <ListItem
                key={index}
                button
                onMouseEnter={(e) => handleTooltipOpen(e, attachment)}
                onMouseLeave={handleTooltipClose}
                onClick={(e) => {
                  e.stopPropagation();
                  handleTooltipClose();
                  setCurrentAttachment(attachment);
                  setUploadingFilesOpen(true);
                }}>
                {attachment.name}
              </ListItem>
            );
          })}
        </List>
      </Popover>
      <Tooltip
        open={tooltipOpen}
        title={
          <img
            src={imageSrc}
            alt="Preview"
            style={{
              maxWidth: '150px',
              borderRadius: theme.shape.borderRadius
            }}
          />
        }
        placement="left-start"
        PopperProps={{ disablePortal: true, style: { zIndex: 100 } }}>
        <div />
      </Tooltip>
    </div>
  ) : null;
};

export default Attachments;
