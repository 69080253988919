import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
  useTheme,
  Grid
} from '@material-ui/core';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Payment as PaymentIcon,
  PeopleOutlined as PeopleIcon,
  Code as CodeIcon,
  Store as StoreIcon
} from '@material-ui/icons';
import { FaHandshake } from 'react-icons/fa';

import Time from 'common/Time';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomDeletionDialog } from 'common/Dialog';

import { INotification } from './index';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%'
  },
  header: {
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'url("/images/connected_uk.svg")',
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    paddingBottom: '34px',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '34px',
    overflow: 'hidden'
  },
  subtitle: {
    color: theme.palette.text.secondary
  },
  content: {},
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  listItemIcon: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    padding: '10px',
    borderRadius: '50%',
    marginRight: theme.spacing(2)
  },
  listItemTextSecondary: {
    marignTop: '4px',
    color: theme.palette.text.secondary
  },
  arrowForward: {
    color: theme.palette.text.secondary,
    height: '16px',
    width: '16px'
  },
  footer: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  empty: {
    textAlign: 'center',
    padding: theme.spacing(3)
  },
  emptyImageWrapper: {
    marginBottom: theme.spacing(3)
  },
  emptyImage: {
    width: '240px',
    maxWidth: '100%',
    height: 'auto'
  }
}));

const DashboardNotificationList = ({
  onSelect,
  notifications,
  deleteNotification,
  deleteAllNotifications
}: {
  notifications: INotification[];
  deleteNotification: (notification: INotification) => void;
  deleteAllNotifications: (notifications: INotification[]) => void;
  onSelect: (select: INotification) => void;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const rmvNotification = (e, notification) => {
    e.stopPropagation();
    e.preventDefault();
    deleteNotification(notification);
  };

  const icons: any = {
    order: {
      icon: <PaymentIcon />,
      color: 'blue'
    },
    user: {
      icon: <PeopleIcon />,
      color: 'red'
    },
    product: {
      icon: <StoreIcon />,
      color: theme.palette.success.main
    },
    feature: {
      icon: <CodeIcon />,
      color: 'purple'
    },
    deal: {
      icon: <FaHandshake fontSize={24} />,
      color: theme.palette.primary.main
    }
  };

  return (
    <div className={classes.root}>
      <CustomDeletionDialog
        open={open}
        setOpen={setOpen}
        type="delete"
        confirmedDeleted={() => deleteAllNotifications(notifications)}
        title={'All Notifications'}
      />
      {notifications?.length > 0 ? (
        <Fragment>
          <div className={classes.header}>
            <Typography variant="h6">Notification Centre</Typography>
            <Typography className={classes.subtitle} variant="body2">
              {notifications.length} new notifications
            </Typography>
          </div>
          <div className={classes.content}>
            {notifications?.length > 1 && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item style={{ paddingTop: 7 }}>
                  <Typography
                    variant="h6"
                    style={{ padding: 5, paddingLeft: 20 }}>
                    Delete All <b>Notifications</b>?
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setOpen(true)}>
                    <DeleteIcon color="error" />
                  </Button>
                </Grid>
              </Grid>
            )}
            <List component="div">
              {notifications.map((notification) => (
                <Link key={notification.id} to="#">
                  <ListItem
                    className={classes.listItem}
                    component="div"
                    onClick={() => onSelect(notification)}>
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ color: icons[notification.type].color }}>
                      {icons[notification.type].icon}
                    </ListItemIcon>
                    <ListItemText
                      classes={{ secondary: classes.listItemTextSecondary }}
                      primary={<Typography>{notification.msg}</Typography>}
                      secondary={<Time time={notification.ts} type="timeago" />}
                    />
                    <Button onClick={(e) => rmvNotification(e, notification)}>
                      <DeleteIcon color="error" />
                    </Button>

                    <ArrowForwardIosIcon className={classes.arrowForward} />
                  </ListItem>
                  <Divider />
                </Link>
              ))}
            </List>
          </div>
        </Fragment>
      ) : (
        <div className={classes.empty}>
          <div className={classes.emptyImageWrapper}>
            <img
              alt="Empty list"
              className={classes.emptyImage}
              src="/images/empty.png"
            />
          </div>
          <Typography variant="h4">{`There's nothing here...`}</Typography>
        </div>
      )}
    </div>
  );
};

export default DashboardNotificationList;
