import {
  SET_DEAL_PROCESSES,
  UPDATE_OBJECT,
  SET_RULE_SCHEMA,
  SET_CURRENT_STEP_ID,
  SET_CURRENT_STEP,
  CLEAR_DEAL_PROCESS,
  CLEAR_CURRENT_DEAL,
  CLEAR_PROCESS_OVERVIEW,
  SET_PROCESS_DEFINITIONS,
  SET_CURRENT_OVERVIEW,
  SET_PROCESS_DEFINITION,
  SET_PROCESS_DEFINITION_FULL,
  SET_PROCESS_DEFINITIONS_SUMMARY,
  SET_CURRENT_PROCESS_DEFINITION,
  SET_PROCESS_SUMMARIES,
  SET_CURRENT_DEAL,
  SET_CURRENT_INSTANCE,
  SET_MY_PROCESSES,
  SET_PROCESS_FIELDS,
  SET_REGULATED_STATUS,
  SET_ENTITY_TYPE,
  SET_USER_RELATIONSHIPS,
  SET_DEAL_FILES,
  CLEAR_DEAL_FILES,
  ADD_USER_TO_FILE,
  REMOVE_USER_FROM_FILE,
  CHANGE_FILE_NAME,
  REMOVE_FILE,
  ADD_FILE
} from 'redux/actions/types';
import {
  CompleteProcessDefinition,
  CompleteProcessInstance,
  Overview,
  CurrentStep,
  FieldInstance,
  ProcessInstance,
  ProcessSummary,
  UserInstance,
  FbFileRef
} from 'types/interfaces';
import { ICustomTreeData } from 'views/Admin/components/RuleSchema';

const INITIAL_STATE = {
  processDefinitions: {},
  processDefinitionsSummary: {},
  processSummaries: [] as ProcessSummary[],
  currentDealProcess: {} as ProcessSummary,
  currentProcess: {} as CompleteProcessDefinition,
  currentDeal: {} as CompleteProcessInstance,
  currentOverView: {} as Overview,
  currentStep: {} as CurrentStep,
  ruleSchema: {} as ICustomTreeData,
  currentStepId: 0,
  myProcessInstances: [],
  processInstanceFields: {} as { [ProcessInstanceId: number]: FieldInstance[] },
  entityType: '',
  regulatedStatus: false,
  files: [] as FbFileRef[]
};

interface SET_CURRENT_STEP_ID {
  type: typeof SET_CURRENT_STEP_ID;
  payload: number;
}

interface SET_CURRENT_STEP {
  type: typeof SET_CURRENT_STEP;
  payload: CurrentStep;
}

interface CLEAR_DEAL_PROCESS {
  type: typeof CLEAR_DEAL_PROCESS;
}

interface CLEAR_CURRENT_DEAL {
  type: typeof CLEAR_CURRENT_DEAL;
}

interface CLEAR_DEAL_FILES {
  type: typeof CLEAR_DEAL_FILES;
}

interface CLEAR_PROCESS_OVERVIEW {
  type: typeof CLEAR_PROCESS_OVERVIEW;
}

interface SET_CURRENT_OVERVIEW {
  type: typeof SET_CURRENT_OVERVIEW;
  payload: Overview;
}

interface SET_PROCESS_DEFINITIONS {
  type: typeof SET_PROCESS_DEFINITIONS;
  payload: any;
}

interface SET_PROCESS_DEFINITION {
  type: typeof SET_PROCESS_DEFINITION;
  payload: CompleteProcessDefinition;
}

interface SET_PROCESS_DEFINITION_FULL {
  type: typeof SET_PROCESS_DEFINITION_FULL;
  payload: any;
}

interface SET_PROCESS_DEFINITIONS_SUMMARY {
  type: typeof SET_PROCESS_DEFINITIONS_SUMMARY;
  payload: any;
}

interface SET_CURRENT_PROCESS_DEFINITION {
  type: typeof SET_CURRENT_PROCESS_DEFINITION;
  payload: CompleteProcessDefinition;
}

interface SET_PROCESS_SUMMARIES {
  type: typeof SET_PROCESS_SUMMARIES;
  payload: ProcessSummary[];
}

interface SET_DEAL_PROCESSES {
  type: typeof SET_DEAL_PROCESSES;
  payload: ProcessSummary;
}

interface SET_CURRENT_DEAL {
  type: typeof SET_CURRENT_DEAL;
  payload: CompleteProcessInstance;
}

interface SET_CURRENT_INSTANCE {
  type: typeof SET_CURRENT_INSTANCE;
  payload: ProcessInstance;
}
interface SET_MY_PROCESSES {
  type: typeof SET_MY_PROCESSES;
  payload: any;
}
interface SET_PROCESS_FIELDS {
  type: typeof SET_PROCESS_FIELDS;
  payload: { [ProcessInstanceId: number]: FieldInstance[] };
}

interface SET_REGULATED_STATUS {
  type: typeof SET_REGULATED_STATUS;
  payload: boolean;
}

interface SET_ENTITY_TYPE {
  type: typeof SET_ENTITY_TYPE;
  payload: string;
}

interface SET_USER_RELATIONSHIPS {
  type: typeof SET_USER_RELATIONSHIPS;
  payload: { key: string; data: UserInstance[] };
}

interface SET_RULE_SCHEMA {
  type: typeof SET_RULE_SCHEMA;
  payload: ICustomTreeData;
}

interface SET_DEAL_FILES {
  type: typeof SET_DEAL_FILES;
  payload: FbFileRef[];
}

interface ADD_FILE {
  type: typeof ADD_FILE;
  payload: FbFileRef;
}

interface ADD_USER_TO_FILE {
  type: typeof ADD_USER_TO_FILE;
  payload: { fileId: string; userId: string };
}

interface REMOVE_USER_FROM_FILE {
  type: typeof REMOVE_USER_FROM_FILE;
  payload: { fileId: string; userId: string };
}

interface REMOVE_FILE {
  type: typeof REMOVE_FILE;
  payload: string;
}

interface CHANGE_FILE_NAME {
  type: typeof CHANGE_FILE_NAME;
  payload: {
    fileId: string;
    newName: string;
  };
}

interface UPDATE_OBJECT {
  type: typeof UPDATE_OBJECT;
  payload: { key: string; data: UserInstance[] };
}

type IAction =
  | SET_CURRENT_STEP_ID
  | SET_CURRENT_STEP
  | CLEAR_DEAL_PROCESS
  | CLEAR_CURRENT_DEAL
  | CLEAR_PROCESS_OVERVIEW
  | SET_CURRENT_OVERVIEW
  | SET_PROCESS_DEFINITIONS
  | SET_PROCESS_DEFINITIONS_SUMMARY
  | SET_PROCESS_DEFINITION
  | SET_PROCESS_DEFINITION_FULL
  | SET_CURRENT_PROCESS_DEFINITION
  | SET_PROCESS_SUMMARIES
  | SET_DEAL_PROCESSES
  | SET_CURRENT_DEAL
  | SET_CURRENT_INSTANCE
  | SET_MY_PROCESSES
  | SET_PROCESS_FIELDS
  | SET_REGULATED_STATUS
  | SET_ENTITY_TYPE
  | SET_USER_RELATIONSHIPS
  | UPDATE_OBJECT
  | CLEAR_DEAL_FILES
  | SET_DEAL_FILES
  | ADD_USER_TO_FILE
  | REMOVE_FILE
  | CHANGE_FILE_NAME
  | REMOVE_USER_FROM_FILE
  | ADD_FILE
  | SET_RULE_SCHEMA;

export default (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case CLEAR_CURRENT_DEAL:
      return { ...state, currentDeal: {} as CompleteProcessInstance };
    case CLEAR_DEAL_PROCESS:
      return { ...state, currentDealProcess: {} as ProcessSummary };
    case CLEAR_PROCESS_OVERVIEW:
      return { ...state, processInstanceFields: {} };
    case CLEAR_DEAL_FILES:
      return { ...state, files: [] };

    // Setters
    case SET_REGULATED_STATUS:
      return { ...state, regulatedStatus: action.payload };
    case SET_ENTITY_TYPE:
      return { ...state, entityType: action.payload };
    case SET_CURRENT_STEP_ID:
      return { ...state, currentStepId: action.payload };
    case SET_CURRENT_STEP:
      return { ...state, currentStep: action.payload };
    case SET_CURRENT_OVERVIEW:
      return { ...state, currentOverView: action.payload };
    case SET_PROCESS_DEFINITIONS:
      return {
        ...state,
        processDefinitions: action.payload
      };
    case SET_PROCESS_DEFINITIONS_SUMMARY:
      return { ...state, processDefinitionsSummary: action.payload };
    case SET_PROCESS_DEFINITION:
      return {
        ...state,
        currentProcess: action.payload
      };
    case SET_PROCESS_DEFINITION_FULL:
      return { ...state, processDefinitions: action.payload };

    case SET_CURRENT_PROCESS_DEFINITION:
      return {
        ...state,
        currentProcess: action.payload
      };
    case SET_PROCESS_SUMMARIES:
      return {
        ...state,
        processSummaries: action.payload
      };
    case SET_DEAL_PROCESSES:
      return { ...state, currentDealProcess: action.payload };
    case SET_CURRENT_DEAL:
      return { ...state, currentDeal: action.payload };
    case SET_CURRENT_INSTANCE:
      return {
        ...state,
        currentDeal: { ...state.currentDeal, ProcessInstance: action.payload }
      };
    case SET_MY_PROCESSES:
      return { ...state, myProcessInstances: action.payload.reverse() };
    case SET_PROCESS_FIELDS: {
      return { ...state, processInstanceFields: action.payload };
    }
    case SET_RULE_SCHEMA: {
      return {
        ...state,
        ruleSchema: action.payload
      };
    }

    case SET_DEAL_FILES: {
      return { ...state, files: action.payload };
    }

    case ADD_FILE: {
      return {
        ...state,
        files: [...state.files, action.payload]
      };
    }

    case ADD_USER_TO_FILE: {
      const { fileId, userId } = action.payload;
      return {
        ...state,
        files: state.files.map((file: FbFileRef) =>
          file.fileId === fileId
            ? { ...file, users: [...file.users, userId] }
            : file
        )
      };
    }

    case REMOVE_USER_FROM_FILE: {
      const { fileId, userId } = action.payload;
      return {
        ...state,
        files: state.files.map((file: FbFileRef) =>
          file.fileId === fileId
            ? { ...file, users: file.users.filter((id) => id !== userId) }
            : file
        )
      };
    }

    case REMOVE_FILE: {
      return {
        ...state,
        files: state.files.filter(
          (file: FbFileRef) => file.fileId !== action.payload
        )
      };
    }

    case CHANGE_FILE_NAME: {
      const { fileId, newName } = action.payload;
      return {
        ...state,
        files: state.files.map((file: FbFileRef) =>
          file.fileId === fileId
            ? { ...file, name: newName, nickName: newName }
            : file
        )
      };
    }

    default:
      return state;
  }
};
