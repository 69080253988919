import React, { useContext, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Dialog from './dialog';
import Time from 'common/Time';
import NumberFormat from 'react-number-format';
import { ReactComponent as CalcLogo } from 'assets/Icons/svg/calculator.svg';
import { FieldDefinition, FieldInstance, IRegulated } from 'types/interfaces';
import { useMobile, useProcess } from 'hooks';
import { theme } from 'theme';
import { OutputContext } from './context/OutputContext';
import { Currency, NotesUI, StringSpacing } from './components';
import QuoteHeader from './components/QuoteHeader';
import { red } from '@material-ui/core/colors';
import { useTypedSelector } from 'redux/reducers';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

const OutputLight = ({ classes }) => {
  const { user, landingpage } = useProcess();
  const { regulatedStatus } = useTypedSelector((s) => s.process);
  const {
    props,
    checkEmptyAmountAndCashFlow,
    open,
    handleClick,
    lists,
    regulated,
    QuoteId
  } = useContext(OutputContext);
  const { ObjectInstance: CompleteObjectInstance } = props;

  const isMobile = useMobile();
  const subsystemUser = user.SystemAccess <= 4;

  const { isEmpty, hasCashFlow } = checkEmptyAmountAndCashFlow();
  return (
    <Paper
      style={{
        position: 'relative',
        padding: theme.spacing(2),
        marginBottom: 10,
        background: isEmpty
          ? theme.palette.success.light
          : props.isDeclined
          ? red[200]
          : 'inherit',
        borderColor: isEmpty ? theme.palette.success.main : 'inherit',
        borderWidth: isEmpty ? 3 : 0,
        border: props.selected
          ? `2px solid ${theme.palette.success.main}`
          : props.isDeclined
          ? `2px solid ${theme.palette.error.main}`
          : `1px solid ${theme.palette.grey[300]}`
      }}
      variant="outlined">
      <QuoteHeader />
      <List
        className={classes.root}
        component="nav"
        style={{
          background: isEmpty ? theme.palette.success.light : '',
          borderRadius: theme.shape.borderRadius
        }}
        subheader={
          <ListSubheader
            component="div"
            disableSticky
            style={{
              display: 'flex',
              flexDirection: isMobile ? 'column' : 'row',
              alignItems: isMobile ? 'flex-start' : 'center',
              color: theme.palette.primary.main,
              padding: 0
            }}>
            <div style={{ flexGrow: isMobile ? 0 : 1 }} />
            {!landingpage &&
              !props.readOnly &&
              props.display !== 'clientView' && (
                <div>
                  {isEmpty ? (
                    <Button
                      data-cy="start-quote-introducer-btn"
                      color="primary"
                      onClick={() => handleClick('calculator')}>
                      <div style={{ marginRight: 5 }}>Start Quote</div>
                      <CalcLogo
                        fill={theme.palette.primary.main}
                        height="24"
                        width="24"
                      />
                    </Button>
                  ) : (
                    <div>
                      {hasCashFlow && (
                        <Button
                          color="primary"
                          onClick={() => handleClick('calculator')}>
                          <div style={{ marginRight: 5 }}>Edit Quote</div>
                          <CalcLogo
                            fill={theme.palette.primary.main}
                            height="24"
                            width="24"
                          />
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
            {/* {!props.readOnly && (
              <Tooltip
                placement="top"
                title={
                  'The commission index displays the order of quotes based on the amount of commission included, 1 being the least.'
                }>
                <div style={{ paddingBottom: theme.spacing(1) }}>
                  <Button
                    className={classes.commissionIndex}
                    style={{
                      alignSelf: isMobile ? 'flex-start' : 'auto',
                      borderRadius: theme.shape.borderRadius
                    }}>
                    Commission Index: {props.index + 1}
                  </Button>
                </div>
              </Tooltip>
            )} */}
          </ListSubheader>
        }>
        {isEmpty ? (
          <div />
        ) : (
          <div>
            <div className={classes.display}>
              {lists.primarymetrics.map(
                (FieldDefinition: FieldDefinition, i: number) => {
                  const FieldInstanceList = getFieldInstances(
                    CompleteObjectInstance
                  );
                  const FieldInstance: FieldInstance | undefined =
                    FieldInstanceList.find(
                      (FieldInstance: FieldInstance) =>
                        FieldInstance.FieldDefinitionId === FieldDefinition.Id
                    );

                  let FieldValue = FieldInstance && FieldInstance.FieldValue;
                  let Title = FieldDefinition.Title;
                  let startAdornment = '';
                  let endAdornment = '';

                  if (Title === 'MonthlyPayment') {
                    startAdornment = '£ ';
                    Title = 'Repayment Value';
                  }
                  if (Title === 'Amount') {
                    startAdornment = '£ ';
                    Title = 'Advance';
                  }
                  if (Title === 'Term') {
                    Title = 'Term';
                    endAdornment = ' MTS';
                  }
                  return (
                    <div className={classes.displayBox} key={i}>
                      <div style={{ fontSize: isMobile ? '0.85rem' : 'none' }}>
                        {Title}
                      </div>
                      <div className={classes.displayBoxMetric}>
                        <Typography
                          style={
                            !isMobile
                              ? {
                                  marginRight: 3,
                                  color: 'white',
                                  fontWeight: 800
                                }
                              : {
                                  marginRight: 3,
                                  fontSize: '0.9rem',
                                  color: 'white',
                                  fontWeight: 800
                                }
                          }>
                          {startAdornment}
                        </Typography>
                        <NumberFormat
                          decimalScale={2}
                          displayType={'text'}
                          style={{
                            fontSize: '0.9rem',
                            fontWeight: 800
                          }}
                          thousandSeparator
                          value={FieldValue}
                        />
                        <Typography
                          style={
                            !isMobile
                              ? {
                                  marginRight: 3,
                                  color: 'white',
                                  fontWeight: 800
                                }
                              : {
                                  marginRight: 3,
                                  fontSize: '0.9rem',
                                  color: 'white',
                                  fontWeight: 800
                                }
                          }>
                          {endAdornment}
                        </Typography>
                      </div>
                    </div>
                  );
                }
              )}
            </div>

            <div>
              {lists.notes.map((FieldDefinition, i) => {
                return (
                  <Paper square key={i}>
                    <NotesUI FieldDefinition={FieldDefinition} props={props} />
                  </Paper>
                );
              })}
            </div>

            {lists.mainlist.map(
              (FieldDefinition: FieldDefinition, i: number) => {
                const FieldInstanceList = getFieldInstances(
                  CompleteObjectInstance
                );
                const FieldInstance: FieldInstance | undefined =
                  FieldInstanceList.find(
                    (FieldInstance: FieldInstance) =>
                      FieldInstance.FieldDefinitionId === FieldDefinition.Id
                  );

                let FieldValue = FieldInstance && FieldInstance.FieldValue;
                let Title = FieldDefinition.Title;

                if (Title === '_Balloon') {
                  const FieldInstance: FieldInstance | undefined =
                    FieldInstanceList.find(
                      (FieldInstance: FieldInstance) =>
                        FieldInstance.FieldDefinitionId === FieldDefinition.Id
                    );

                  const objConvertor =
                    FieldInstance?.FieldValue &&
                    JSON.parse(FieldInstance?.FieldValue);
                  const hiddenParameters = [
                    'Balloon_VatRate',
                    'Balloon_Vat',
                    'TotalBalloon',
                    'ContractPeriod',
                    'Balloon'
                  ];
                  return Object.entries(objConvertor).map((item, key) => {
                    if (hiddenParameters.includes(item[0])) return null;

                    return (
                      <ListItem key={key} style={{ alignItems: 'flex-end' }}>
                        <p>{item[0]}</p>

                        <div
                          style={{
                            flexGrow: 1,
                            borderBottom: '1px dotted lightgrey',
                            marginLeft: 15,
                            marginRight: 15
                          }}
                        />

                        <Currency value={item[1]} />
                      </ListItem>
                    );
                  });
                }

                if (Title.includes('Deposit')) return null;
                return (
                  <div key={i}>
                    {props.display !== 'clientView' ||
                      (subsystemUser && (
                        <ListItem style={{ alignItems: 'flex-end' }}>
                          <p>{StringSpacing(FieldDefinition.Title)}</p>
                          <div
                            style={{
                              flexGrow: 1,
                              borderBottom: '1px dotted lightgrey',
                              marginLeft: 15,
                              marginRight: 15
                            }}
                          />

                          {Title === 'StartDate' && FieldValue ? (
                            <Time time={FieldValue} type="date" />
                          ) : (
                            <NumberFormat
                              decimalScale={2}
                              displayType={'text'}
                              prefix={'£'}
                              thousandSeparator
                              value={FieldValue}
                            />
                          )}
                        </ListItem>
                      ))}
                  </div>
                );
              }
            )}

            {props.display !== 'clientView' ||
              (subsystemUser && !landingpage && (
                <>
                  <ListItem button onClick={() => handleClick('rates')}>
                    <Typography color="secondary" style={{ flexGrow: 1 }}>
                      {'Rates'.toUpperCase()}
                    </Typography>
                    {open.rates ? (
                      <ExpandLess color="secondary" />
                    ) : (
                      <ExpandMore color="secondary" />
                    )}
                  </ListItem>
                  <Collapse in={open.rates} timeout="auto" unmountOnExit>
                    {lists.rateslist.map((FieldDefinition, i) => {
                      const FieldInstanceList = getFieldInstances(
                        CompleteObjectInstance
                      );
                      const FieldInstance: FieldInstance | undefined =
                        FieldInstanceList.find(
                          (FieldInstance: FieldInstance) =>
                            FieldInstance.FieldDefinitionId ===
                            FieldDefinition.Id
                        );

                      const clientViewExcludes = [
                        'Yield',
                        'MarginRate',
                        'NominalFlatRate'
                      ];
                      const regulatedPartyCheck = ['APR'];
                      const non_regulated = ['NominalFlatRate'];

                      if (regulatedStatus) {
                        if (non_regulated.includes(FieldDefinition.Title))
                          return null;
                      }

                      if (!regulated.isRegulatedParty) {
                        if (regulatedPartyCheck.includes(FieldDefinition.Title))
                          return null;
                      }

                      if (
                        props.display === 'clientView' &&
                        clientViewExcludes.includes(FieldDefinition.Title)
                      ) {
                        return null;
                      }

                      return (
                        <ListItem key={i} style={{ alignItems: 'flex-end' }}>
                          <p style={{ paddingLeft: 15 }}>
                            {StringSpacing(FieldDefinition.Title)}
                          </p>

                          <div
                            style={{
                              flexGrow: 1,
                              borderBottom: '1px dotted lightgrey',
                              marginLeft: 15,
                              marginRight: 15
                            }}
                          />

                          <NumberFormat
                            decimalScale={5}
                            displayType={'text'}
                            suffix={'%'}
                            thousandSeparator
                            value={FieldInstance && FieldInstance.FieldValue}
                          />
                        </ListItem>
                      );
                    })}
                  </Collapse>
                </>
              ))}

            {props.display !== 'clientView' ||
              (subsystemUser && !landingpage && (
                <>
                  <ListItem button onClick={() => handleClick('fees')}>
                    <Typography color="secondary" style={{ flexGrow: 1 }}>
                      {'Fees'.toUpperCase()}
                    </Typography>
                    {open.fees ? (
                      <ExpandLess color="secondary" />
                    ) : (
                      <ExpandMore color="secondary" />
                    )}
                  </ListItem>
                  <Collapse in={open.fees} timeout="auto" unmountOnExit>
                    {lists.advancedlist.map(
                      (FieldDefinition: FieldDefinition, i: number) => {
                        const FieldInstanceList = getFieldInstances(
                          CompleteObjectInstance
                        );
                        const FieldInstance: FieldInstance | undefined =
                          FieldInstanceList.find(
                            (FieldInstance: FieldInstance) =>
                              FieldInstance.FieldDefinitionId ===
                              FieldDefinition.Id
                          );

                        const clientViewExcludes = [
                          'NPV',
                          'CustomerYield',
                          'DocFeeUpsell'
                        ];

                        if (
                          props.display === 'clientView' &&
                          clientViewExcludes.includes(FieldDefinition.Title)
                        )
                          return null;
                        return (
                          <ListItem key={i} style={{ alignItems: 'flex-end' }}>
                            <p style={{ paddingLeft: 15 }}>
                              {StringSpacing(FieldDefinition.Title)}
                            </p>

                            <div
                              style={{
                                flexGrow: 1,
                                borderBottom: '1px dotted lightgrey',
                                marginLeft: 15,
                                marginRight: 15
                              }}
                            />

                            <NumberFormat
                              decimalScale={2}
                              displayType={'text'}
                              prefix={'£'}
                              thousandSeparator
                              value={FieldInstance && FieldInstance.FieldValue}
                            />
                          </ListItem>
                        );
                      }
                    )}
                  </Collapse>
                </>
              ))}

            {props.display !== 'clientView' ||
              (subsystemUser && !landingpage && (
                <>
                  <ListItem button onClick={() => handleClick('commission')}>
                    <Typography color="secondary" style={{ flexGrow: 1 }}>
                      {'Commission'.toUpperCase()}
                    </Typography>
                    {open.commission ? (
                      <ExpandLess color="secondary" />
                    ) : (
                      <ExpandMore color="secondary" />
                    )}
                  </ListItem>
                  <Collapse in={open.commission} timeout="auto" unmountOnExit>
                    {lists.commissionlist.map((FieldDefinition, i) => {
                      const FieldInstanceList = getFieldInstances(
                        CompleteObjectInstance
                      );
                      const FieldInstance: FieldInstance | undefined =
                        FieldInstanceList.find(
                          (FieldInstance: FieldInstance) =>
                            FieldInstance.FieldDefinitionId ===
                            FieldDefinition.Id
                        );

                      return (
                        <ListItem key={i} style={{ alignItems: 'flex-end' }}>
                          <p style={{ paddingLeft: 15 }}>
                            {StringSpacing(FieldDefinition.Title)}
                          </p>

                          <div
                            style={{
                              flexGrow: 1,
                              borderBottom: '1px dotted lightgrey',
                              marginLeft: 15,
                              marginRight: 15
                            }}
                          />

                          {FieldDefinition.Title === 'CommissionPercentage' ? (
                            <NumberFormat
                              decimalScale={5}
                              displayType={'text'}
                              suffix={'%'}
                              thousandSeparator
                              value={FieldInstance && FieldInstance.FieldValue}
                            />
                          ) : (
                            <NumberFormat
                              decimalScale={2}
                              displayType={'text'}
                              prefix={'£'}
                              thousandSeparator
                              value={FieldInstance && FieldInstance.FieldValue}
                            />
                          )}
                        </ListItem>
                      );
                    })}
                  </Collapse>
                </>
              ))}
          </div>
        )}
      </List>

      {regulated?.isRegulatedParty && (
        <Tooltip
          placement="top"
          title={
            'The commission index displays the order of quotes based on the amount of commission included, 1 being the least.'
          }>
          <Typography variant="caption">
            Commission Index: {props.index + 1}
          </Typography>
        </Tooltip>
      )}

      <Dialog
        QuoteId={QuoteId}
        handleClose={() => handleClick('calculator')}
        open={open.calculator}
        {...props}
      />
    </Paper>
  );
};

export default OutputLight;
