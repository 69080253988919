import { useEffect, useContext, useState } from 'react';
import { Typography, Grid, Button, ButtonGroup } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core';
import { deleteGroup } from '../../functions';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { useAccount } from '@azure/msal-react';
import { useTypedSelector } from 'redux/reducers';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { MessageHubProvider } from '../..';
import { SelectedThreadView } from './SelectedThreadView';
import { useProcess } from 'hooks/useProcess';
import GlobalSystemInbox from './GlobalSystemInbox';
import { theme } from 'theme';

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start'
    },
    emailAddress: { color: 'blue' },
    emailMismatch: { color: 'red' },
    box: {
      display: 'flex',
      flexDirection: 'column'
    }
  })
);

const RightContent = () => {
  const { user } = useProcess();
  const classes = useStyles();
  const history = useHistory();
  const isAuth = useIsAuthenticated();
  const MessageProvider = useContext(MessageHubProvider);
  const { threads, selectedThreadKey, setSelectedThreadKey, syncThread } =
    MessageProvider;
  const { UserInstanceEmail } = useTypedSelector((s) => s.user.user);
  const { accounts } = useMsal();
  const [open, setOpen] = useState<boolean>(false);

  const isSubSystemUser = user?.SystemAccess <= 4;

  const account: any = useAccount({
    homeAccountId: accounts[0]?.homeAccountId
  });

  const handleDeleteGroup = () => {
    setSelectedThreadKey(null);
    if (selectedThreadKey) deleteGroup(selectedThreadKey);
  };

  useEffect(() => {
    syncThread();
  }, [selectedThreadKey]);

  if (threads && selectedThreadKey) {
    return (
      <SelectedThreadView
        open={open}
        setOpen={setOpen}
        handleDeleteGroup={handleDeleteGroup}
      />
    );
  }

  const emailsMatch =
    isAuth && accounts?.map((el) => el.username).includes(UserInstanceEmail);
  const emailClass = emailsMatch ? classes.emailAddress : classes.emailMismatch;

  return (
    <Grid container direction="column">
      <Typography variant="h1">Global System Inbox</Typography>
      {isAuth && emailsMatch && (
        <Grid item>
          {accounts?.map((account) => (
            <Typography key={account?.tenantId}>
              <span style={{ color: 'grey', paddingLeft: theme.spacing(0.5) }}>
                Currently Logged In As:
              </span>
              <span className={emailClass}> {account?.username}</span>
              {emailsMatch ? '' : '— Associated with a Microsoft 365 Account'}
            </Typography>
          ))}
        </Grid>
      )}

      <Grid item>
        {isAuth && !emailsMatch && (
          <Alert
            severity="warning"
            action={
              <ButtonGroup orientation="vertical">
                <Button onClick={() => history.push('/profile')}>Change</Button>
              </ButtonGroup>
            }>
            <Typography>
              You have a mismatch between your system email address and your
              connected Microsoft office 365 email address, It is advised that
              you change your system email address to be the same as your 365
              account for optimal performance. This can be done in your profile.
            </Typography>

            <br />

            <Typography className={emailClass}>
              {UserInstanceEmail} - (system account)
            </Typography>

            <Typography key={account?.tenantId} className={emailClass}>
              {account?.username} - (365 account)
            </Typography>
          </Alert>
        )}

        {!isSubSystemUser && (
          <Grid item>
            <GlobalSystemInbox />
          </Grid>
        )}
      </Grid>
      <br />

      {/* {isAuth && <Inbox classes={classes} />}
      {isAuth && <Contacts />} */}
    </Grid>
  );
};

export default RightContent;
