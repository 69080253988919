import React from 'react';
import { useSelector } from 'react-redux';
import { firebase } from 'redux/firebase';
import { makeStyles } from '@material-ui/core/styles';

// import Editor from 'common/Editor';
import { EditorState, convertToRaw } from 'draft-js';

import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';

const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    alignItems: 'flex-end'
  },
  editor: { flexGrow: 1 }
}));

const Component = ({ mentions }) => {
  const classes = useStyles();
  const ref = React.createRef();
  const user = useSelector((s) => s.user.user);
  const init_state = EditorState.createEmpty();
  const [editorState, setEditorState] = React.useState(init_state);
  const onChange = (editorState) => setEditorState(editorState);

  const send = () => {
    const currentContent = editorState.getCurrentContent();
    const obj = {
      Draftjs: JSON.stringify(convertToRaw(currentContent)),
      DraftjsObj: convertToRaw(currentContent),
      Id: user.Id,
      Title: user.Title,
      Group: 'general',
      Time: `${new Date()}`
    };
    const ref = firebase.database().ref(`${hostname}/chatter`);
    ref.push(obj);
    setEditorState(init_state);
  };

  return (
    <div className={classes.root}>
      <div className={classes.editor}>
        {/* <Editor
          mentions={mentions}
          editorState={editorState}
          onChange={onChange}
          readOnly={false}
          ref={ref}
        /> */}
      </div>
      <div style={{ width: 5 }} />
      <div>
        <Button variant="contained" color="primary" onClick={send}>
          Send
          <SendIcon />
        </Button>
      </div>
    </div>
  );
};

export default Component;
