/**component for handling transferring a deal to a new owner */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography, Button, Grid, Paper } from '@material-ui/core';
import { Box, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { quickProcessUpdate } from 'redux/actions/processes';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import { useTypedSelector } from 'redux/reducers';
import {
  UserDefinition,
  UserInstance,
  CompleteUserInstance
} from 'types/interfaces';
import { PrimaryButton } from 'common/Button';
import { StyledSelect } from 'common/StyledSelect';
import { IMaterialUISelect } from 'common/StyledSelect';
import * as gtag from 'Utils/gtag';

import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation';

import {
  useDealOwnershipHistory,
  IDealOwnership
} from 'hooks/useDealOwnershipHistory';

import QuickUserFromId from 'components/User/QuickUserFromId';
import Timeline from '@material-ui/lab/Timeline';
import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator
} from '@material-ui/lab';

import PersonIcon from '@material-ui/icons/Person';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import firebase from 'firebase';
import { globalIds } from 'helpers/globalIdConfig';

interface ExtendedUserDefinition extends UserDefinition {
  value: number;
  label: string;
}

interface ExtendedUserInstance extends UserInstance {
  value: number;
  label: string;
  uid: string;
}

export const TransferDealOwnership = ({
  closeDeal,
  handleCloseMenu,
  isSummary
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const { token } = useTypedSelector((s) => s.user.auth);
  const { SystemAccess } = useTypedSelector((s) => s.user.user);
  const { ProcessInstance } = useTypedSelector((s) => s.process.currentDeal);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [options, setOptions] = React.useState<ExtendedUserDefinition[] | null>(
    null
  );
  const loggedInUser = useTypedSelector((state) => state.user.user);
  const [userOption, setUserOptions] = React.useState<
    ExtendedUserInstance[] | null
  >(null);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [state, setState] = React.useState({
    UserDefinition: null as number | null,
    UserInstance: null as number | null
  });

  const [openSelectDef, setOpenSelectDef] = React.useState(false);
  const [openSelectIns, setOpenSelectIns] = React.useState(false);

  const { transferDealToNewOwner, getDealOwnershipHistory } =
    useDealOwnershipHistory();

  // list of all previous owners
  const [ownershipHistoryList, setOwnershipHistoryList] = useState<
    IDealOwnership[] | null
  >(null);

  // last deal owner if exist
  const [lastPrevOwner, setLastPrevOwner] = useState<IDealOwnership | null>(
    null
  );

  // open deal owner history Timeline component
  const [openTimeline, setOpenTimeline] = useState<boolean>(false);

  // collect owner list & previous owner
  useEffect(() => {
    const collectDealOwners = async () => {
      if (ProcessInstance) {
        const dealObjects = await getDealOwnershipHistory(ProcessInstance.Id);

        if (
          dealObjects?.ownershipHistory &&
          dealObjects?.ownershipHistory.length > 0
        ) {
          setOwnershipHistoryList(dealObjects.ownershipHistory);
          setLastPrevOwner(dealObjects.lastPreviousOwner);
        }
      }
    };

    collectDealOwners();
  }, []); //END useEffect[]

  const handleChange = (v: number, name: string) => {
    if (name === 'UserInstance') {
      return setState({ ...state, [name]: v });
    }
    if (name === 'UserDefinition') {
      return setState({ ...state, ['UserInstance']: null, [name]: v });
    }
  };

  const handleTransfer = async () => {
    if (state?.UserDefinition && state?.UserInstance) {
      setLoading(true);
      const data = Object.assign({}, ProcessInstance);

      data.UserInstanceId = state.UserInstance;
      const success = await quickProcessUpdate({ data });
      if (success) {
        // deal will transfer to selected new owner for the firestore db
        await transferDealToNewOwner(
          data?.Id,
          StyleSelectUserInstanceProps?.value as number
        );

        const isSenderIntroducer =
          loggedInUser.UserDefinitionId ===
          globalIds.userDefinitionIds.introducersUDID;

        const receiverUser = userOption?.find(
          (user) => user.value === state.UserInstance
        )?.Id;

        const senderName = loggedInUser.Title;
        const receiverNotificationMessage = `Deal Id (${
          ProcessInstance.Id
        }) was transferred from ${senderName} ${
          isSenderIntroducer ? 'an Introducer' : 'a Broker'
        }`;

        const receiverNotification = {
          ProcessInstanceTitle: data.Title,
          id: '',
          msg: receiverNotificationMessage,
          pdid: data.ProcessDefinitionId,
          piid: data.Id,
          psdid: data.ProcessStepDefinitionId,
          read: false,
          ts: firebase.firestore.FieldValue.serverTimestamp(),
          type: 'deal'
        };

        try {
          if (receiverUser) {
            await firebase
              .firestore()
              .collection('userAccount')
              .doc(receiverUser.toString())
              .collection('notifications')
              .add(receiverNotification);
          }
          console.log('Notification added');
        } catch (e) {
          console.error('Error adding notification:', e);
        }

        setLoading(false);
        dispatch(
          createNotification(successNotif(`Deal Transferred Successfully`))
        );
        handleClose();
        closeDeal();
        gtag.event({
          action: 'Deal Transferred',
          feature: 'Deals',
          message: `success`
        });
        return history.push('/deals');
      }
    }
  }; //END handleTransfer

  React.useEffect(() => {
    let options = [] as ExtendedUserDefinition[];
    UserDefinitionList &&
      UserDefinitionList.forEach((item: UserDefinition) => {
        const Extension = item as ExtendedUserDefinition;
        Extension.value = item.Id;
        Extension.label = item.Title;
        options.push(Extension);
      });
    setOptions(options);
  }, []);

  const getUserInstances = async () => {
    if (state?.UserDefinition) {
      const users = await getUserInstanceSummaryList({
        token,
        UserDefinitionId: state.UserDefinition
      });
      const options = [] as ExtendedUserInstance[];
      const userList = users.data as CompleteUserInstance[];
      Object.values(userList).forEach((item: CompleteUserInstance) => {
        const Extension = item.UserInstance as ExtendedUserInstance;
        Extension.value = item.UserInstance.Id;
        Extension.label = item.UserInstance.Title;
        options.push(Extension);
      });
      setUserOptions(options);
    }
  };

  React.useEffect(() => {
    if (state.UserDefinition) getUserInstances();
  }, [state.UserDefinition]);

  const handleFocus = (selectType: 'def' | 'ins') => {
    if (selectType === 'def') {
      setOpenSelectDef(false);
    } else {
      setOpenSelectIns(false);
    }
  };

  const definitionOptions: string[] =
    SystemAccess >= 5 ? ['Brokers', 'Introducer'] : ['Brokers'];

  const StyleSelectUserDefinitionProps: IMaterialUISelect = {
    label: 'User Group',
    value: state?.UserDefinition,
    onChange: ({ target: { value } }) => handleChange(value, 'UserDefinition'),
    options:
      options &&
      options.filter(({ label }: { label: string }) =>
        definitionOptions.includes(label)
      ),
    sortAlphabetically: true,
    sortNumerically: false,
    open: openSelectDef,
    handleClose: () => handleFocus('def')
  };

  const StyleSelectUserInstanceProps: IMaterialUISelect = {
    label: 'User Instance',
    value: state?.UserInstance,
    open: openSelectIns,
    handleClose: () => handleFocus('ins'),
    onChange: ({ target: { value } }) => {
      handleChange(value, 'UserInstance');
    },
    options: userOption,
    sortAlphabetically: true,
    sortNumerically: false
  };

  // Timeline Component which displays deal owner history in chronological order
  const ownerTimeline = () => {
    if (ownershipHistoryList && ownershipHistoryList.length > 0) {
      const timelineItems = ownershipHistoryList.map(
        (dealOwner: IDealOwnership, idx: number) => {
          const startDate: any = dealOwner?.ownerStartDate;
          // if ownerEndDate does not exist then they are current owner, otherwise they were previous owners
          const endDate: any = dealOwner.ownerEndDate
            ? dealOwner.ownerEndDate
            : null;

          const startDateString = startDate.toDate().toDateString();
          const endDateString = endDate
            ? endDate.toDate().toDateString()
            : null;

          return (
            <TimelineItem key={idx}>
              <TimelineOppositeContent style={{ marginTop: '.5rem' }}>
                <Typography variant="body2" color="textSecondary">
                  Start Date:&nbsp;
                  {startDateString}
                </Typography>
                {endDateString ? (
                  <Typography variant="body2" color="textSecondary">
                    End Date:&nbsp;
                    {endDateString}
                  </Typography>
                ) : null}
              </TimelineOppositeContent>

              <TimelineSeparator>
                <TimelineDot>
                  <PersonIcon />
                </TimelineDot>
                {ownershipHistoryList.length > 1 ? <TimelineConnector /> : null}
              </TimelineSeparator>

              <TimelineContent>
                <QuickUserFromId
                  UserInstanceId={`${dealOwner.userInstanceId}`}
                />
              </TimelineContent>
            </TimelineItem>
          );
        }
      );

      return <Timeline align="alternate">{timelineItems}</Timeline>;
    }

    return null;
  }; //END ownerTimeline

  if (isSummary) {
    return (
      <>
        <Button style={{ flexGrow: 1 }} onClick={handleClickOpen}>
          <ListItemIcon>
            <TransferWithinAStationIcon fontSize="small" />
          </ListItemIcon>
          <Typography color="primary">Transfer Deal</Typography>
        </Button>

        <Dialog
          style={{ backdropFilter: `blur(3px)` }}
          aria-labelledby="form-dialog-title"
          fullWidth
          maxWidth={'md'}
          onClose={handleClose}
          open={open}>
          <DialogTitle id="form-dialog-title">Transfer Deal Owner</DialogTitle>

          <DialogContent style={{ height: '100%' }}>
            {/* only display if a previous owner existed */}
            {lastPrevOwner ? (
              <Grid container direction="row" alignItems="center">
                <Typography>Last previous owner: &nbsp;&nbsp;&nbsp;</Typography>
                <Box>
                  <QuickUserFromId
                    UserInstanceId={`${lastPrevOwner.userInstanceId}`}
                  />
                </Box>
              </Grid>
            ) : null}

            <Box
              onMouseDown={(event) => {
                event.preventDefault();
                setOpenSelectDef(true);
              }}>
              <StyledSelect {...StyleSelectUserDefinitionProps} useMaterialUI />
            </Box>

            <br />

            {state?.UserDefinition && (
              <Box
                onMouseDown={(event) => {
                  event.preventDefault();
                  setOpenSelectIns(true);
                }}>
                <StyledSelect {...StyleSelectUserInstanceProps} useMaterialUI />
              </Box>
            )}
          </DialogContent>

          <DialogActions>
            {/* deal owner history button */}
            <Button
              onClick={() => {
                setOpenTimeline(!openTimeline);
              }}
              style={{
                marginRight: 'auto',
                marginLeft: '1rem'
              }}
              color="primary">
              {!openTimeline ? (
                <>
                  Deal Owner History&nbsp;
                  <ArrowRightIcon style={{ marginBottom: '.25rem' }} />
                </>
              ) : (
                <>
                  Deal Owner History&nbsp;
                  <ArrowDropDownIcon style={{ marginBottom: '.25rem' }} />
                </>
              )}
            </Button>

            <Button color="primary" onClick={handleClose}>
              Cancel
            </Button>
            <PrimaryButton color="primary" onClick={handleTransfer}>
              {loading ? <CircularProgress /> : 'Transfer'}
            </PrimaryButton>
          </DialogActions>

          {openTimeline ? (
            <DialogContent>{ownerTimeline()}</DialogContent>
          ) : null}
        </Dialog>
      </>
    );
  }

  return (
    <MenuItem onClick={handleCloseMenu}>
      <>
        <ListItemIcon>
          <TransferWithinAStationIcon fontSize="small" />
        </ListItemIcon>
        <Typography
          data-cy="transfer-deal"
          color="primary"
          onClick={handleClickOpen}>
          Transfer Deal
        </Typography>
      </>

      <Dialog
        style={{ backdropFilter: `blur(3px)` }}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth={'md'}
        onClose={handleClose}
        open={open}>
        <DialogTitle id="form-dialog-title">Transfer Deal Owner</DialogTitle>

        <DialogContent style={{ height: '100%' }}>
          {/* only display if a previous owner existed */}
          {lastPrevOwner ? (
            <Grid container direction="row" alignItems="center">
              <Typography>Last previous owner: &nbsp;&nbsp;&nbsp;</Typography>
              <Box>
                <QuickUserFromId
                  UserInstanceId={`${lastPrevOwner.userInstanceId}`}
                />
              </Box>
            </Grid>
          ) : null}

          <Box
            onMouseDown={(event) => {
              event.preventDefault();
              setOpenSelectDef(true);
            }}>
            <StyledSelect {...StyleSelectUserDefinitionProps} useMaterialUI />
          </Box>

          <br />

          {state?.UserDefinition && (
            <Box
              onMouseDown={(event) => {
                event.preventDefault();
                setOpenSelectIns(true);
              }}>
              <StyledSelect {...StyleSelectUserInstanceProps} useMaterialUI />
            </Box>
          )}
        </DialogContent>

        <DialogActions>
          {/* deal owner history button */}
          <Button
            onClick={() => {
              setOpenTimeline(!openTimeline);
            }}
            style={{
              marginRight: 'auto',
              marginLeft: '1rem'
            }}
            color="primary">
            {!openTimeline ? (
              <>
                Deal Owner History&nbsp;
                <ArrowRightIcon style={{ marginBottom: '.25rem' }} />
              </>
            ) : (
              <>
                Deal Owner History&nbsp;
                <ArrowDropDownIcon style={{ marginBottom: '.25rem' }} />
              </>
            )}
          </Button>

          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <PrimaryButton
            data-cy="transfer-btn"
            color="primary"
            onClick={handleTransfer}>
            {loading ? <CircularProgress /> : 'Transfer'}
          </PrimaryButton>
        </DialogActions>

        {openTimeline ? <DialogContent>{ownerTimeline()}</DialogContent> : null}
      </Dialog>
    </MenuItem>
  );
}; //END TransferDealOwnership
