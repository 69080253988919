import {
  Button,
  DialogActions,
  Paper,
  Typography,
  useTheme
} from '@material-ui/core';
import { CustomDialog } from '.';

interface ICustomDeletionDialogProps {
  open: boolean;
  title: string;
  setOpen: (open) => void;
  confirmedDeleted: (event?: any) => void;
  type: 'delete' | 'archive';
}

/**
 ** CustomDeletionDialog Component
 ** This component displays a customDialog that asks the user to confirm if they want to delete or archive an item.
 *
 * @param {ICustomDeletionDialogProps} confirm - The object containing properties to control the dialog behavior.
 * @param {boolean} confirm.open - State variable to control whether the dialog is open.
 * @param {string} confirm.title - The title or name of the item to be deleted or archived.
 * @param {function} confirm.setOpen - Function to update the open state of the dialog.
 * @param {function} confirm.confirmedDeleted - Function to handle the deletion or archiving action.
 * @param {'delete' | 'archive'} confirm.type - The type of action, either 'delete' or 'archive'.
 *
 * @returns {JSX.Element} The rendered CustomDeletionDialog component.
 */

const CustomDeletionDialog = (confirm: ICustomDeletionDialogProps) => {
  const theme = useTheme();
  const { confirmedDeleted, open, setOpen, title, type } = confirm;

  const handleCloseDeletion = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setOpen(false);
    confirmedDeleted(e);
  };
  return (
    <CustomDialog
      open={open}
      maxSize="xs"
      handleClose={() => setOpen(false)}
      alert={{
        title: 'This Action Cannot Be Undone',
        description: `Request to Delete OR Archive ${title}`,
        type: 'warning'
      }}>
      <Paper style={{ width: '100%', height: '100%' }}>
        <Typography
          style={{ paddingTop: theme.spacing(1) }}
          align="center"
          variant="h6">
          {`Are you sure that you wish to ${
            type === 'archive' ? 'Archive' : 'Delete'
          } ${title}?`}
        </Typography>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseDeletion}>
            {`${type === 'archive' ? 'Archive' : 'Delete'}`}
          </Button>
        </DialogActions>
      </Paper>
    </CustomDialog>
  );
};

export default CustomDeletionDialog;
