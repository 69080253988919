import { Dispatch, SetStateAction } from 'react';
import { store } from 'redux/store';
import { useField } from 'hooks/useField';
import { IUpdateField } from '../interface';
import { BugTracker } from 'Utils/Bugtracker';

export interface FieldSubmitPropsInterface {
  setUpdating: Dispatch<SetStateAction<boolean>>;
  token: string | undefined;
  props: IUpdateField;
}

export const handleUpdateField: ({
  setUpdating,
  token,
  props
}: FieldSubmitPropsInterface) =>
  | void
  | ((val: string, Title?: string) => Promise<any>) = ({
  setUpdating,
  token,
  props
}) => {
  const {
    updateExistingFieldInExistingObject,
    createNewFieldInExistingObject,
    createNewObjectAndAllFields
  } = useField();

  if (typeof token !== 'string') return console.log('Token is undefined');

  const { FieldInstance } = props;
  let UserInstance = Object.assign({}, props.UserInstance);

  if (
    UserInstance.SystemAccess >= 4 &&
    props.ProcessStepSelectionType === 'OnBehalfOf'
  ) {
    const obosUserDefinitionId = props.ObjectDefinition.UserDefinitionId;
    const currentStepCanCompleteList =
      store?.getState()?.process?.currentStep?.UserInstanceDictForCurrentStep;

    if (currentStepCanCompleteList > 1) {
      const msg =
        'There is a problem with the process configuration, please contact support';
      alert(msg);
      BugTracker(msg);
    }

    const obhoUserInstanceId =
      currentStepCanCompleteList &&
      parseInt(Object.keys(currentStepCanCompleteList)[0]);
    UserInstance.Id = obhoUserInstanceId;
    UserInstance.UserDefinitionId = obosUserDefinitionId;
  }

  return async (val: string, Title?: string) => {
    // Does it have an object instance?
    if (props?.ObjectInstance) {
      // YES
      // Does it have a Field Instance?
      if (FieldInstance?.UserDefinitionId) {
        // YES
        // Update the field

        return updateExistingFieldInExistingObject({
          setUpdating,
          props,
          val,
          token,
          Title
        });
      } else {
        // NO
        // Create a new field in the existing object
        return createNewFieldInExistingObject({
          setUpdating,
          val,
          token,
          props,
          UserInstance,
          Title
        });
      }
    } else {
      // NO
      // Create Object and Field
      return createNewObjectAndAllFields({
        setUpdating,
        val,
        token,
        props,
        UserInstance
      });
    }
  };
};
