import React, { Dispatch, SetStateAction } from 'react';
import { Alert } from '@material-ui/lab';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme
} from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { StyledSelect } from 'common/StyledSelect';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import {
  UserInstance,
  FieldDefinition,
  FieldInstance,
  ObjectDefinition,
  ObjectInstance,
  ProcessInstance,
  UserDefinition
} from 'types/interfaces';
import Table from 'components/SimpleTable';
// import { FaUserPlus } from 'react-icons/fa';
import { handleUpdateField } from '../../functions';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Collapse from '@material-ui/core/Collapse';
import RedFlag from 'components/RedFlag';
import { CreateUserDialogWrapper } from 'components/User/CreateAsUserButton';
import { IRedFlagDirectorship } from 'components/OffkeyCompanyAndDirectorSearch/helper';
import { SearchType } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { useTypedSelector } from 'redux/reducers';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';
import { EInlineTextField } from '../..';
import { CustomDialog } from 'common/Dialog';
import { Check, Warning } from '@material-ui/icons';

interface UserSelected {
  value: number;
  label: string;
  Description: string;
}

interface Props {
  FieldDefinition: FieldDefinition;
  FieldInstance: FieldInstance;
  ObjectDefinition: ObjectDefinition;
  ObjectInstance: ObjectInstance;
  ProcessInstance: ProcessInstance;
  ProcessStepSelectionType: string;
  UserInstance: UserInstance;
  fields: FieldDefinition[];
  handleClose: () => void;
  setAction?: () => void;
  type: string;
  value: string;
}

const columns = [
  { title: 'Title', field: 'UserInstance.Title' },
  { title: 'Email', field: 'UserInstance.UserInstanceEmail' },
  {
    title: 'Owner',
    field: 'UserInstance.OwnerUserInstanceId',
    render: (data) => {
      const id = data.UserInstance.OwnerUserInstanceId;
      const show = id === 0 ? 'system' : id;
      return <div>{show}</div>;
    },
    editable: 'never'
  }
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: 500
    },
    header: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center'
    },
    btn: { marginLeft: theme.spacing(1) },
    divider: { margin: `${theme.spacing(3)}px; 0px;` },
    select: { flexGrow: 1 },
    box: {
      // border: `1px solid ${theme.palette.grey.A100}`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    plugins: {
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.primary.light}`,
      borderRadius: theme.shape.borderRadius,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
    dialogContainer: {
      border: `2px solid #FFA500`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(3),
      margin: theme.spacing(2)
    },
    title: {
      fontSize: '1.25rem',
      marginBottom: theme.spacing(3)
    },
    checkbox: {
      color: '#FFA500',
      '&.Mui-checked': {
        color: '#4CAF50'
      }
    },
    proceedButton: {
      backgroundColor: (props: { isChecked: boolean }) =>
        props.isChecked ? '#4CAF50' : theme.palette.grey[300],
      color: (props: { isChecked: boolean }) =>
        props.isChecked
          ? theme.palette.common.white
          : theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: (props: { isChecked: boolean }) =>
          props.isChecked ? '#45a049' : theme.palette.grey[400]
      }
    },
    sectionHeader: {
      fontSize: '1rem',
      marginBottom: theme.spacing(1)
    },
    warningIcon: {
      color: '#D32F2F',
      marginRight: theme.spacing(1)
    },
    warningBox: {
      backgroundColor: '#FFEBEE',
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      marginBottom: theme.spacing(2),
      display: 'flex',
      alignItems: 'center'
    }
  })
);

const SearchAndCreateUser = (props) => {
  const classes = useStyles({ isChecked: false });

  const token = useTypedSelector((s) => s.user.auth.token);
  const currentProcess = useTypedSelector((s) => s.process.currentProcess);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [UserSelected, setUserSelected] = React.useState<UserSelected | null>(
    null
  );

  const [data, setData] = React.useState<UserInstance[] | null>(null);
  const [updating, setUpdating] = React.useState(false);
  const [redflagData, setRedflagData] = React.useState();

  const [confirmationDialog, setConfirmationDialog] = React.useState<{
    isOpen: boolean;
    Id: number;
  }>({ isOpen: false, Id: 0 });

  React.useEffect(() => {
    if (redflagData) props.setAction(redflagData);
  }, [redflagData]);

  const ProcessDefinitionId = currentProcess?.ProcessDefinition?.Id
    ? currentProcess.ProcessDefinition.Id
    : 0;

  const fetchUsers = async () => {
    const UserDefinitionId = UserSelected?.value;
    const res = await getUserInstanceSummaryList({
      token,
      UserDefinitionId
    });
    if (res) {
      setData(Object.values(res.data));
    }
  };

  const onChange: any = handleUpdateField({
    setUpdating,
    token,
    props
  });

  // const actions = [
  //   {
  //     tooltip: `Add Selected ${UserSelected?.label} to List`,
  //     icon: () => <FaUserPlus />,
  //     onClick: (evt, data) => {
  //       console.log({ evt, data });
  //       // setContacts(data);
  //       // setOpen(true);
  //     }
  //   }
  // ];

  const StyledSelectProps = {
    onChange: (e) => setUserSelected(e),
    options: props.options,
    placeholder: 'Select a data group',
    value: UserSelected,
    useMaterialUI: false
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.select}>
          <StyledSelect {...StyledSelectProps} />
        </div>
        <Button
          disabled={!UserSelected}
          className={classes.btn}
          color="primary"
          onClick={fetchUsers}
          variant="contained">
          Search
        </Button>
      </div>
      <div className={classes.divider} />

      {data && (
        <>
          <Table
            columns={columns}
            data={data}
            options={options}
            title="Contacts"
            editable={{
              onRowAdd: async (data: any) => {
                const UserInstance = {
                  ...data.UserInstance,
                  UserDefinitionId: UserSelected?.value,
                  IsPublished: true
                };

                const typedProcessDefinitionId: number = ProcessDefinitionId;
                const response = await getQuickLiteUser({
                  baseUrl,
                  ProcessDefinitionId: typedProcessDefinitionId,
                  data: UserInstance,
                  action: 'UPDATE'
                });

                const hasUserInstance = response?.UserInstance;
                if (hasUserInstance) fetchUsers();
              }
            }}
            handleRowClick={async (u) => {
              const FieldType =
                EInlineTextField.DropDownAdvancedUserInstanceAutoAssign;
              if (props.type === FieldType) {
                // Confirmation for there action
                setConfirmationDialog({ isOpen: true, Id: u.UserInstance.Id });
              } else {
                await onChange(u.UserInstance.Id);
                props.handleClose();
              }
            }}
          />

          <ConfirmationJSX
            props={props}
            onChange={onChange}
            setConfirmationDialog={setConfirmationDialog}
            confirmationDialog={confirmationDialog}
          />
        </>
      )}
      <div className={classes.divider} />
      <Plugins
        setRedflagData={setRedflagData}
        onChange={onChange}
        props={props}
      />
    </div>
  );
};

export default SearchAndCreateUser;

const options = {
  // selection: true,
  selectionProps: (rowData) => ({
    // disabled: !rowData.UserInstance.UserInstanceEmail.includes('@'),
    color: 'primary'
  })
};

const ConfirmationJSX = ({
  onChange,
  props,
  setConfirmationDialog,
  confirmationDialog
}: {
  onChange: (val: string, Title?: string | undefined) => Promise<void>;
  props: Props;
  setConfirmationDialog: Dispatch<
    SetStateAction<{ isOpen: boolean; Id: number }>
  >;
  confirmationDialog: { isOpen: boolean; Id: number };
}) => {
  const [isChecked, setIsChecked] = React.useState<boolean>(false);
  const classes = useStyles({ isChecked });

  const [loading, setLoading] = React.useState<boolean>(false);

  return (
    <CustomDialog
      handleClose={() => {
        setConfirmationDialog({ isOpen: false, Id: 0 });
        setIsChecked(false);
      }}
      maxSize="sm"
      open={confirmationDialog.isOpen}
      alert={{
        title: 'Are You Sure You Wish To proceed?',
        description: 'This Will Override The Current Introducer',
        type: 'warning'
      }}>
      <DialogContent>
        <Typography style={{ fontWeight: 'bold' }}>
          {`
                Action paused: Please review and confirm before proceeding. If
                you're unsure, contact BIPS for guidance before making a
                decision.`}
        </Typography>
        <Box className={classes.dialogContainer}>
          <Box className={classes.warningBox}>
            <Warning className={classes.warningIcon} />
            <Typography variant="body1">
              <span style={{ fontWeight: 'bold' }}>Important:</span> You are
              about to assign this Introducer to the Deal. If you have already
              created another &quot;Lender &amp; Proposal&quot; with the same
              Introducer, you can disregard this message. Otherwise, you will be
              overriding the existing Introducer.{' '}
            </Typography>
          </Box>

          <Typography className={classes.sectionHeader}>
            Before proceeding, please confirm the following:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                className={classes.checkbox}
              />
            }
            label="I understand that this action will override the existing Introducer"
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setConfirmationDialog({ isOpen: false, Id: 0 });
            setIsChecked(false);
          }}
          variant="outlined">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            setLoading(true);
            await onChange(confirmationDialog.Id.toString());
            setConfirmationDialog({ isOpen: false, Id: 0 });
            setIsChecked(false);
            props.handleClose();
            setLoading(false);
          }}
          variant="contained"
          className={classes.proceedButton}
          disabled={!isChecked}
          startIcon={loading ? <CircularProgress size={20} /> : <Check />}>
          {loading ? 'Processing...' : 'Proceed'}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

const Plugins = ({ setRedflagData, onChange, props }) => {
  const { handleClose, options } = props;
  const classes = useStyles({ isChecked: false });
  const [openPlugins, setOpenPlugins] = React.useState(false);
  const currentProcess: any = useSelector<any>((s) => s.process.currentProcess);
  const [redflagNavData, setRedflagNavData] = React.useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);

  const [confirmationDialog, setConfirmationDialog] = React.useState<{
    isOpen: boolean;
    Id: number;
  }>({ isOpen: false, Id: 0 });

  const UserDefinition = UserDefinitionList.find(
    (UserDefinition: UserDefinition) => UserDefinition.Id === options[0].value
  );

  const isReady =
    redflagNavData && redflagNavData.data && redflagNavData.data.id;

  return (
    <div className={classes.plugins}>
      <DialogContentText>
        Alternatively you can use a plugin to create a user, then find them in
        the search above.
      </DialogContentText>
      <FormControlLabel
        control={
          <Switch
            checked={openPlugins}
            name="checkedA"
            onChange={() => {
              setOpenPlugins(!openPlugins);
              setRedflagNavData(null);
            }}
          />
        }
        label="Red Flag Search"
      />

      <div className={classes.divider} />

      <Collapse in={openPlugins}>
        <Alert severity="info">
          <Typography>
            {`Once you have searched and picked a company, click the "Create Company" button and chose the desired data group.
              \n Then close the window and you will be able to find the newly created user in the main search.`}
          </Typography>
        </Alert>

        <div className={classes.divider} />

        <div style={{ opacity: redflagNavData ? 1 : 1 }}>
          {openPlugins && (
            <RedFlag
              lock={SearchType.Company}
              showDirectorCompany={{ company: false, director: true }}
              ProcessDefinitionId={currentProcess.ProcessDefinition.Id}
              action={(data: IRedFlagDirectorship | IRedflagAPIData) => {
                if (data) {
                  const name =
                    data &&
                    data?.data?.attributes?.first_name +
                      ' ' +
                      data?.data?.attributes?.surname;

                  setRedflagData({
                    data,
                    title: `Create and Select ${name}`,
                    action: () => setOpen(true)
                  });

                  setRedflagNavData(data);
                }
              }}
            />
          )}
          {isReady && (
            <>
              <CreateUserDialogWrapper
                setOpen={(v) => setOpen(v)}
                open={open}
                data={redflagNavData?.data}
                company={redflagNavData}
                UserDefinition={UserDefinition}
                ProcessDefinitionId={currentProcess.ProcessDefinition.Id}
                downloadPDF={function (): void {
                  throw new Error('Function not implemented.');
                }}
                loading={false}
                selectCreated={(id: any) => {
                  const FieldType =
                    EInlineTextField.DropDownAdvancedUserInstanceAutoAssign;
                  if (props.type === FieldType) {
                    setConfirmationDialog({
                      isOpen: true,
                      Id: id
                    });

                    setOpen(false);
                  } else {
                    onChange(id);
                    setOpen(false);
                    handleClose();
                  }
                }}
              />

              <ConfirmationJSX
                props={props}
                onChange={onChange}
                setConfirmationDialog={setConfirmationDialog}
                confirmationDialog={confirmationDialog}
              />
            </>
          )}
        </div>
      </Collapse>
    </div>
  );
};
