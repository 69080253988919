import React from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import { useSelector } from 'react-redux';
import { Header } from 'components/Stepper/Header';

const Component = () => {
  const { currentProcess, currentDeal } = useSelector((s) => s.process);
  const [open, setOpen] = React.useState(false);
  const Title =
    currentProcess &&
    currentProcess.ProcessDefinition &&
    currentProcess.ProcessDefinition.Title;
  const title =
    currentDeal &&
    currentDeal.ProcessInstance &&
    currentDeal.ProcessInstance.Title;

  return (
    <DashboardLayout title={`${Title}: ${title}`}>
      <Header ProcessDefinition={currentProcess} />
    </DashboardLayout>
  );
};

export default Component;
