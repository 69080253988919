import {
  IUser,
  Thread,
  ThreadDict,
  ThreadExtension,
  ThreadType
} from '../interfaces';
import {
  CompleteObjectInstance,
  CompleteProcessDefinition,
  CompleteProcessInstance,
  CompleteProcessStepDefinition,
  UserInstance,
  FieldInstance
} from 'types/interfaces';
import { createGroup, removeThread, updateGroup } from '.';
import { getFieldValueWithFieldDefinitionId } from '../../../functions/getFieldValueWithFieldDefinitionId';
import { globalIds } from 'helpers/globalIdConfig';
import { parse } from 'handlebars';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

export const threadCreate = async ({
  currentProcess,
  currentDeal,
  stepdefdict,
  ProcessInstanceId,
  user,
  threads,
  dealSummary,
  baseUrl,
  processAssignedUsers
}: {
  currentProcess: CompleteProcessDefinition;
  currentDeal: CompleteProcessInstance;
  stepdefdict: CompleteProcessStepDefinition;
  ProcessInstanceId: number;
  user: UserInstance;
  threads: ThreadDict | null;
  dealSummary;
  baseUrl: string;
  processAssignedUsers: IUser[];
}) => {
  // Look at deal type and create the necessary structure;
  const { Id } = currentProcess.ProcessDefinition;
  const AssetFinanceDeal = 466;
  const LoanFacility = 474;
  const LoanFacilityTOBA = 534;
  const Loan_Facility = 535;

  const PropertyFinanceDeal = 494;
  const AlternativeFinance = 483;
  const Alternative_Finance = 540;
  const ResidentialMortgageReferral = 532;

  interface IMakeThread {
    Id?: string;
    parentId?: string;
    updateId?: string;
    FieldDefinitionId?: number;
    CompleteObjectInstance?: CompleteObjectInstance;
    type: ThreadType;
    label: string;
    exists: boolean;
    accounts?: boolean;
  }

  const isThereOne: (type: ThreadType) => boolean = (type) =>
    Boolean(threads && Object.values(threads).find((el) => el.type === type));

  const isThereAny: (type: ThreadType, Id: string) => boolean = (type, Id) => {
    const exists = threads
      ? Object.values(threads).find((el: Thread) => {
          const isFixedTo = el.fixedTo;
          const fixedToMatchesId = el.fixedTo === parseInt(Id);
          const matchingTypes = el.type === type;
          if (isFixedTo && fixedToMatchesId && matchingTypes) {
            return true;
          } else {
            return false;
          }
        })
      : false;
    return Boolean(exists);
  };

  const isABorrowerContactThread = isThereOne('borrower.contact');
  const isALenderProposalThread = isThereOne('lender.proposal');
  const isALenderPayoutThread = isThereOne('lender.payouts');
  const isALenderThread = isThereOne('lender');
  const isAccountsThread = isThereOne('accounts');
  const isABrokerContactThread = isThereOne('broker.contact');

  const makeThread = async ({
    Id,
    FieldDefinitionId,
    CompleteObjectInstance,
    type,
    label,
    exists,
    parentId,
    updateId,
    accounts
  }: IMakeThread) => {
    const isValidId = (id: string | undefined): id is string => {
      return id !== undefined && id !== '';
    };

    if (!isValidId(Id) && FieldDefinitionId) {
      Id = getFieldValueWithFieldDefinitionId({
        stepdefdict,
        currentDeal,
        FieldDefinitionId
      });
    }

    if (isValidId(Id) && !exists) {
      await createThread({
        Id,
        ProcessInstanceId,
        parentId: parentId ? parseInt(parentId) : 0,
        CompleteObjectInstance,
        label,
        type,
        user,
        fixedTo: parseInt(Id)
      });
      return;
    }

    if (exists && updateId) {
      const updateThread = async () => {
        if (isValidId(Id)) {
          const Ids = { Id, ProcessInstanceId: ProcessInstanceId.toString() };
          const members = [user.Id.toString(), Id];
          const selectedThreadKey = threads
            ? Object.values(threads).find((thread) => thread.type === type)?.id
            : undefined;

          await updateGroup({
            Ids,
            members,
            CompleteObjectInstance,
            label,
            type,
            fixedTo: parseInt(Id),
            selectedThreadKey
          });
        }
      };

      if (accounts) {
        const findAccounts =
          threads &&
          Object.values(threads).find(
            (accountsThread) => accountsThread.type === 'accounts'
          );

        if (findAccounts?.fixedTo?.toString() !== updateId) {
          await updateThread();
        }
      } else if (isValidId(Id) && Id !== updateId) {
        await updateThread();
      }
    }
  };

  const threadConfig = {
    'borrower.contact': 'Contact',
    'lender.proposal': 'Proposal',
    'lender.payouts': 'Payout',
    'broker.contact': 'Broker'
  };

  const threadLookup = {};
  if (threads) {
    Object.values(threads).forEach((thread) => {
      threadLookup[thread.type] = thread;
    });
  }

  const makeThreadWithConfig = (type, otherArgs) => {
    const existingThread = threadLookup[type];
    makeThread({
      type,
      label: threadConfig[type],
      updateId: existingThread?.members[1],
      ...otherArgs
    });
  };

  if (
    [AssetFinanceDeal, LoanFacility, LoanFacilityTOBA, Loan_Facility].includes(
      Id
    )
  ) {
    // 1. Create Borrower Thread
    makeThreadWithConfig('borrower.contact', {
      FieldDefinitionId: globalIds.customer.partiesAndContacts.PrimaryContact,
      exists: isABorrowerContactThread
    });

    // 2. Create Lender Proposal Thread
    makeThreadWithConfig('lender.proposal', {
      FieldDefinitionId:
        globalIds.customer.partiesAndContacts.LenderProposalContact,
      exists: isALenderProposalThread
    });

    // 3. Create Lender Payouts Thread
    makeThreadWithConfig('lender.payouts', {
      FieldDefinitionId:
        globalIds.customer.partiesAndContacts.LenderPayoutsContact,
      exists: isALenderPayoutThread
    });

    // 4. Create Broker Thread
    makeThreadWithConfig('broker.contact', {
      FieldDefinitionId: globalIds.customer.partiesAndContacts.SelectedBroker,
      exists: isABrokerContactThread
    });

    // Create a Lender Thread
    const createLender = async () => {
      const selected: { [id: string]: string }[] | undefined =
        dealSummary?.lenders?.selected;

      if (selected) {
        const selectedLender: string = Object.keys(selected)?.[0];
        const updateLender = threads
          ? Object.values(threads).find((thread) => thread.type === 'lender')
          : null;

        makeThread({
          Id: selectedLender,
          type: 'lender',
          label: 'Lender',
          exists: isALenderThread,
          updateId: updateLender?.members[1]
        });
      }
    };
    createLender();

    //Create Introducer Thread
    const createIntroducer = async () => {
      const selectedIntroducer = processAssignedUsers.find(
        (u) => u.UserDefinitionId === 641
      );
      const updateIntroducer = threads
        ? Object.values(threads).find((thread) => thread.type === 'introducer')
        : null;

      if (selectedIntroducer) {
        makeThread({
          Id: selectedIntroducer.UserInstanceId.toString(),
          type: 'introducer',
          label: 'Introducer',
          exists: updateIntroducer ? true : false,
          updateId: updateIntroducer?.members[1]
        });
      }
    };
    createIntroducer();

    const getBrokerIntroducerData = () => {
      let selectedIntroducerId: string | undefined;
      let selectedBrokerId: string | undefined;
      const selectedIntroducerFromAssignedUsers = processAssignedUsers.find(
        (u) => u.UserDefinitionId === 641
      );
      const selectedBrokerFromAssignedUsers = processAssignedUsers.find(
        (u) => u.UserDefinitionId === 522
      );
      if (
        selectedIntroducerFromAssignedUsers &&
        selectedBrokerFromAssignedUsers
      ) {
        selectedIntroducerId =
          selectedIntroducerFromAssignedUsers.UserInstanceId.toString();
        selectedBrokerId =
          selectedBrokerFromAssignedUsers.UserInstanceId.toString();
      } else {
        const selectedIntroducerFromDealSummary =
          dealSummary?.introducer?.selected;
        const selectedBrokerFromDealSummary = dealSummary?.broker?.selected;
        if (
          selectedIntroducerFromDealSummary &&
          selectedBrokerFromDealSummary
        ) {
          selectedIntroducerId =
            selectedIntroducerFromDealSummary.Id.toString();
          selectedBrokerId = selectedBrokerFromDealSummary.Id.toString();
        }
      }
      return { selectedIntroducerId, selectedBrokerId };
    };

    const createBrokerIntroducerThread = async ({
      threads,
      ProcessInstanceId,
      user
    }: {
      threads: ThreadDict | null;
      ProcessInstanceId: number;
      user: UserInstance;
    }) => {
      const { selectedIntroducerId, selectedBrokerId } =
        getBrokerIntroducerData();

      if (!selectedIntroducerId || !selectedBrokerId) {
        console.error('Introducer or Broker not selected');
        return;
      }
      const subSystemUser = user.SystemAccess <= 4;
      const label = subSystemUser ? 'Introducer' : 'Broker';
      const members = subSystemUser
        ? [user.Id.toString(), selectedBrokerId]
        : [user.Id.toString(), selectedIntroducerId];

      const existingSharedThread = threads
        ? Object.values(threads).find(
            (thread) => thread.type === 'broker.contact'
          )
        : null;

      if (existingSharedThread) {
        await updateGroup({
          Ids: {
            Id: existingSharedThread.id,
            ProcessInstanceId: ProcessInstanceId.toString()
          },
          members,
          label,
          type: 'broker.contact',
          fixedTo: ProcessInstanceId,
          selectedThreadKey: existingSharedThread.id
        });
      } else {
        const newThread = await createThread({
          Id: ProcessInstanceId.toString(),
          ProcessInstanceId,
          label: label,
          user,
          type: 'broker.contact',
          fixedTo: ProcessInstanceId
        });

        if (newThread) {
          await updateGroup({
            Ids: {
              Id: newThread.id,
              ProcessInstanceId: ProcessInstanceId.toString()
            },
            members,
            label: label,
            type: 'broker.contact',
            fixedTo: ProcessInstanceId,
            selectedThreadKey: newThread.id
          });
        } else {
          console.error('Failed to create new thread');
        }
      }
    };
    createBrokerIntroducerThread({
      threads,
      ProcessInstanceId,
      user
    });

    // 4. Create Supplier Thread
    const AssetDetailODID = 2838;
    const SupplierFDID = 21944;
    const SupplierContactFDID = 23270;

    const AssetDetailOIList: CompleteObjectInstance[] = Object.values(
      currentDeal.CompleteObjectInstanceDict
    ).filter(
      (el: CompleteObjectInstance) =>
        el.ObjectInstance.ObjectDefinitionId === AssetDetailODID
    );

    const findField = (asset: CompleteObjectInstance, fieldId: number) => {
      const fieldInstances = getFieldInstances(asset);
      return fieldInstances.find(
        (field: FieldInstance) => field.FieldDefinitionId === fieldId
      )?.FieldValue;
    };

    const checkAndRemoveThreads = async () => {
      if (!threads) return;

      for (const [threadId, thread] of Object.entries(threads)) {
        if (thread.type === 'supplier.contact') {
          const matchingAsset = AssetDetailOIList.find(
            (asset) =>
              asset.ObjectInstance.Id.toString() ===
              thread.label?.split(' - Id: ')[1]
          );

          if (!matchingAsset) {
            await removeThread(threadId);
          }
        }
      }
    };

    await checkAndRemoveThreads();
    await Promise.all(
      AssetDetailOIList.map(async (Asset: CompleteObjectInstance) => {
        const Supplier: string | undefined = findField(Asset, SupplierFDID);
        const SupplierContactId: string | undefined = findField(
          Asset,
          SupplierContactFDID
        );

        if (Supplier && SupplierContactId && threads) {
          const existingThread = Object.values(threads).find(
            (thread: ThreadExtension) =>
              thread.type === 'supplier.contact' &&
              thread.fixedTo === parseInt(SupplierContactId)
          );

          const UserInstanceId = parseInt(SupplierContactId);
          const response = await getQuickLiteUser({
            baseUrl,
            UserInstanceId,
            action: 'GET'
          });

          const UserInstance = response?.UserInstance;
          if (!UserInstance) return;

          if (existingThread && !existingThread.parentId) {
            const Ids = {
              Id: SupplierContactId,
              ProcessInstanceId: ProcessInstanceId.toString()
            };

            const members = [user.Id.toString(), SupplierContactId];
            const selectedThreadKey = threads
              ? Object.values(threads).find(
                  (thread) => thread.fixedTo === parseInt(SupplierContactId)
                )
              : null;

            await updateGroup({
              Ids,
              members,
              CompleteObjectInstance: Asset,
              label: `${UserInstance.Title} - Id: ${Asset.ObjectInstance.Id}`,
              parentId: parseInt(Supplier),
              type: 'supplier.contact',
              fixedTo: parseInt(SupplierContactId),
              selectedThreadKey: selectedThreadKey?.id
            });
            return;
          }

          const isAlreadyAMatchingSupplierContact = isThereAny(
            'supplier.contact',
            SupplierContactId
          );

          if (!isAlreadyAMatchingSupplierContact) {
            await makeThread({
              Id: SupplierContactId,
              CompleteObjectInstance: Asset,
              parentId: Supplier,
              type: 'supplier.contact',
              label: `${UserInstance.Title} - Id: ${Asset.ObjectInstance.Id}`,
              exists: isAlreadyAMatchingSupplierContact
            });
          }
        }
      })
    );
  }

  if (
    [
      PropertyFinanceDeal,
      AlternativeFinance,
      Alternative_Finance,
      ResidentialMortgageReferral
    ].includes(Id)
  ) {
    // 1. Create Borrower Thread
    makeThreadWithConfig('borrower.contact', {
      FieldDefinitionId: globalIds.customer.partiesAndContacts.PrimaryContact,
      exists: isABorrowerContactThread
    });

    // 2. Create Lender Proposal Thread
    makeThreadWithConfig('lender.proposal', {
      FieldDefinitionId:
        globalIds.customer.partiesAndContacts.LenderProposalContact,
      exists: isALenderProposalThread
    });

    // 3. Create Lender Payouts Thread
    makeThreadWithConfig('lender.payouts', {
      FieldDefinitionId:
        globalIds.customer.partiesAndContacts.LenderPayoutsContact,
      exists: isALenderPayoutThread
    });
  }

  const SynergyFinanceAccounts = [
    PropertyFinanceDeal,
    LoanFacilityTOBA,
    Loan_Facility,
    AlternativeFinance,
    ResidentialMortgageReferral
  ];

  const AfsAccounts = [AssetFinanceDeal, LoanFacility, Alternative_Finance];
  let AccountsId;
  if (SynergyFinanceAccounts.includes(Id)) {
    AccountsId = globalIds.accountSynergyFinanceId;
  } else if (AfsAccounts.includes(Id)) {
    AccountsId = globalIds.accountCommissionId;
  }

  makeThread({
    Id: AccountsId.toString(),
    type: 'accounts',
    label: 'Accounts',
    exists: isAccountsThread,
    updateId: AccountsId.toString(),
    accounts: true
  });
};

const createThread = async ({
  Id = '',
  ProcessInstanceId,
  CompleteObjectInstance,
  label,
  user,
  parentId,
  fixedTo,
  type
}: {
  Id: string;
  ProcessInstanceId: number;
  CompleteObjectInstance?: CompleteObjectInstance;
  label: string;
  user: UserInstance;
  fixedTo?: number;
  parentId?: number;
  type: ThreadType;
}): Promise<Thread | undefined> => {
  const Ids = { Id, ProcessInstanceId: ProcessInstanceId.toString() };
  const members = [user.Id.toString(), Id];

  try {
    const threadId = await createGroup({
      Ids,
      members,
      label,
      CompleteObjectInstance,
      type,
      fixedTo,
      parentId
    });
    return {
      id: threadId,
      ProcessInstanceId: ProcessInstanceId.toString(),
      CompleteObjectInstance,
      members,
      label,
      type,
      fixedTo,
      parentId
    } as Thread;
  } catch (e) {
    console.error('Failed to create thread', e);
    return undefined;
  }

  // return createGroup({
  //   Ids,
  //   members,
  //   label,
  //   type,
  //   fixedTo,
  //   parentId
  // });
};
