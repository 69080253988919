import {
  SiMclaren,
  SiBentley,
  SiBmw,
  SiPorsche,
  SiHonda,
  SiFord
} from 'react-icons/si';
import { AiFillCar } from 'react-icons/ai';
import {
  IoAirplaneSharp,
  IoConstructSharp,
  IoFastFoodSharp,
  IoBusSharp
} from 'react-icons/io5';
import {
  GiCctvCamera,
  GiComputerFan,
  GiMineTruck,
  GiFullMotorcycleHelmet,
  GiTyre
} from 'react-icons/gi';
import { MdAgriculture, MdLocalTaxi } from 'react-icons/md';

export const CarLogo = ({
  Manufacturer,
  AssetCategory,
  Equipments,
  Vehicles,
  style
}) => {
  Manufacturer = Manufacturer && Manufacturer.toLowerCase();
  AssetCategory = AssetCategory && AssetCategory.toLowerCase();
  Equipments = Equipments && Equipments.toLowerCase();
  Vehicles = Vehicles && Vehicles.toLowerCase();

  switch (Manufacturer) {
    case 'mclaren':
      return <SiMclaren style={style} />;
    case 'bentley':
      return <SiBentley style={style} />;
    case 'bmw':
      return <SiBmw style={style} />;
    case 'porsche':
      return <SiPorsche style={style} />;
    case 'honda':
      return <SiHonda style={style} />;
    case 'ford':
      return <SiFord style={style} />;
    case 'pirelli':
      return <GiTyre style={{ fontSize: 20 }} />;
    default:
      if (typeof AssetCategory === 'string') {
        if (AssetCategory.includes('vehicles')) {
          if (typeof Vehicles === 'string') {
            if (Vehicles.includes('cars')) {
              return <AiFillCar style={{ fontSize: 20 }} />;
            }
            if (Vehicles.includes('commercial')) {
              return <GiMineTruck style={{ fontSize: 20 }} />;
            }
            if (Vehicles.includes('commercial')) {
              return <MdLocalTaxi style={{ fontSize: 20 }} />;
            }
            if (Vehicles.includes('motorcycle')) {
              return <GiFullMotorcycleHelmet style={{ fontSize: 20 }} />;
            }
          }
          return <IoBusSharp style={{ fontSize: 20 }} />;
        }
        if (AssetCategory.includes('equipments')) {
          if (typeof Equipments === 'string') {
            if (Equipments.includes('aircraft')) {
              return <IoAirplaneSharp style={{ fontSize: 20 }} />;
            }
            if (Equipments.includes('cctv')) {
              return <GiCctvCamera style={{ fontSize: 20 }} />;
            }
            if (Equipments.includes('agriculture')) {
              return <MdAgriculture style={{ fontSize: 20 }} />;
            }
            if (Equipments.includes('air conditioning')) {
              return <GiComputerFan style={{ fontSize: 20 }} />;
            }
            if (Equipments.includes('catering')) {
              return <IoFastFoodSharp style={{ fontSize: 20 }} />;
            }
          }
          return <IoConstructSharp style={{ fontSize: 20 }} />;
        }
      }
      return <div />;
  }
};
