import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { getProcessesByUser } from 'redux/actions/processes';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from './Table';
import { DateParse } from 'common/dateParse';
import { useProcess } from 'hooks';
import { useTypedSelector } from 'redux/reducers';

const dealStatus = (ProcessStepDefinitionId) => {
  if (ProcessStepDefinitionId > 0) {
    return { status: 'ACTIVE', type: 'warning' };
  }

  if (ProcessStepDefinitionId === -1) {
    return { status: 'CLOSED WON', type: 'success' };
  }

  if (ProcessStepDefinitionId === -2) {
    return { status: 'CLOSED LOST', type: 'error' };
  }

  if (ProcessStepDefinitionId === -3) {
    return { status: 'ABANDONED', type: 'error' };
  }
};

const useStyles = makeStyles((theme) => ({
  root: { paddingTop: theme.spacing(2) }
}));

const Component = ({ rowData }) => {
  const classes = useStyles();
  const theme = useTheme();

  const { token } = useSelector((s) => s.user.auth);
  const { Id } = rowData.UserInstance;
  const [loading, setLoading] = React.useState(true);
  const [deals, setDeals] = React.useState([]);
  const { user } = useProcess();
  const loggedInUser = useTypedSelector((state) => state.user.user);
  const subSystemUser = user?.SystemAccess <= 4;

  const getDeals = async () => {
    const res = await getProcessesByUser({ token, Id });
    setLoading(false);
    if (res && res.data) setDeals(res.data.reverse());
  };

  const filteredDeals = subSystemUser
    ? deals.filter((deal) => deal?.UserInstanceId === loggedInUser?.Id)
    : deals;

  React.useEffect(() => {
    getDeals();
    return () => setDeals([]);
  }, []);

  if (loading) return <LinearProgress />;

  return (
    <div data-cy="deal-name" className={classes.root}>
      <Table
        getDeals={getDeals}
        userInstance={rowData}
        title="Deals"
        data={filteredDeals}
        columns={[
          {
            title: 'Deal Id',
            field: 'ProcessInstanceId',
            render: (rowData) => {
              const { ProcessInstanceId } = rowData;
              return (
                <p
                  style={{
                    fontWeight: 800,
                    color: theme.palette.primary.dark
                  }}>
                  {ProcessInstanceId}
                </p>
              );
            }
          },
          { title: 'Name', field: 'ProcessInstanceTitle' },
          { title: 'Product', field: 'ProcessDefinitionTitle' },
          {
            title: 'Last Modified',
            field: 'LastModified',
            render: (rowData) => <DateParse date={rowData.LastModified} />
          },
          {
            title: 'Active Step',
            field: 'ProcessStepDefinitionTitle',
            // customFilterAndSearch: (term, rowData) => term == rowData.name.length,
            render: (rowData) => {
              const { ProcessStepDefinitionId, ProcessStepDefinitionTitle } =
                rowData;
              const type = dealStatus(ProcessStepDefinitionId)
                ? dealStatus(ProcessStepDefinitionId).type
                : 'error';
              return (
                <div
                  style={{
                    // background: `${theme.palette[type].main}88`,
                    background: `linear-gradient(to bottom, ${theme.palette[type].main}88 0%, ${theme.palette[type].main} 100%)`,
                    color: theme.palette.primary.contrastText,
                    padding: theme.spacing(2),
                    borderRadius: theme.shape.borderRadius,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  <div>{ProcessStepDefinitionTitle}</div>
                </div>
              );
            }
          }
        ]}
      />
    </div>
  );
};

export default Component;
