export const white = '#FFFFFF';

export const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121'
};

export const black = '#000000';

export const blue = '#0767DB';

export const green = '#45B880';

export const pink = '#797a7d';
export const pink_light = '#C22392';
export const pink_dark = 'black';

export const orange = '#797a7d';
export const orange_light = '#eb7434';
export const orange_dark = 'black';

export const red = '#790F0F';

export const indigo = '#D94BE4';

export const primaryColor =
  'linear-gradient(to right rgba(255, 142, 83, 100), rgba(254, 107, 139, 100))';
