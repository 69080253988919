import { useEffect, useState } from 'react';

export const TextSlide = (secondaryText: string[]) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const [transform, setTransform] = useState('translateX(0%)');

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Start fading out and sliding out the text
      setOpacity(0);
      setTransform('translateX(100%)'); // Slide out to the left

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % secondaryText.length);
        // Reset opacity and slide from the right
        setOpacity(1);
        setTransform('translateX(100%)');

        // Immediately start sliding in from the right to its original position
        requestAnimationFrame(() => {
          setTransform('translateX(0%)');
        });
      }, 200); // This delay should be less than the interval to allow for the slide effect
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return { opacity, currentIndex, transform };
};
