import { store } from 'redux/store';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable
} from '@apollo/client';
import firebase from 'firebase';

const httpLink = new HttpLink({ uri: process.env.REACT_APP_OFFKEY_GRAPH_URL });
const authLink = new ApolloLink((operation, forward) => {
  // We using the Observable class to handle async operations as we need the firebase token,
  // from documentation this would be the correct way to handle async operations inside a ApolloLink.
  return new Observable((observer) => {
    // Cannot use Reducers store here as it will not be updated in time therefore we need to use the getState method.
    const state = store.getState();
    const cosneticsToken = state.user.auth.token;

    const handleToken = async () => {
      try {
        const firebaseToken = await firebase.auth().currentUser?.getIdToken();
        operation.setContext({
          headers: {
            CosneticsToken: cosneticsToken,
            FirebaseToken: firebaseToken
          }
        });

        const observable = forward(operation);
        observable.subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer)
        });
      } catch (error) {
        observer.error(error);
      }
    };

    handleToken();
  });
});

const link: any = authLink.concat(httpLink);
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      CompleteUserInstance: {
        keyFields: (obj) => {
          // Assert obj as any to bypass TypeScript's type checking
          const userInstance = (obj as any).UserInstance;
          // Check if userInstance is an object and has an 'Id' property
          if (
            typeof userInstance === 'object' &&
            userInstance !== null &&
            'Id' in userInstance
          ) {
            return `CompleteUserInstance:${userInstance.Id}`;
          }
          return '';
        }
      },
      CompleteUserDefinition: {
        keyFields: (obj) => {
          // Assert obj as any to bypass TypeScript's type checking
          const userDefinition = (obj as any).UserDefinition;
          // Check if userInstance is an object and has an 'Id' property
          if (
            typeof userDefinition === 'object' &&
            userDefinition !== null &&
            'Id' in userDefinition
          ) {
            return `CompleteUserDefinition:${userDefinition.Id}`;
          }
          return '';
        }
      },
      UserInstance: {
        keyFields: ['Id']
      }
    }
  }),
  link,
  connectToDevTools: true
});
