import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import {
  ICreateDocumentInput,
  EDocumentType
} from 'graphql/FileStorageAPI/interface';
import { FRAUDULENT_DOCUMENT_UUID } from 'graphql/FileStorageAPI/mutations';
import { BugTracker } from 'Utils/Bugtracker';

/**
 * Creates a new document using the provided input.
 * @param {ICreateDocumentInput} input - The input data for creating the document.
 * @returns {Promise<{ data: { createDocument: {uuid: string} }; loading: boolean } | ApolloError>} - A promise resolving to the created document's UUID or an ApolloError.
 */
export const CreateFraudulentDocumentUUID = async ({
  input
}: {
  input: ICreateDocumentInput;
}): Promise<string | undefined> => {
  try {
    const query = `
          mutation($input: CreateDocumentInput!) {
            createDocument(input: $input) {
              uuid
            }
          }`;

    const formData = new FormData();
    formData.append(
      'operations',
      JSON.stringify({
        query,
        variables: { input }
      })
    );
    formData.append('map', JSON.stringify({ '0': ['variables.input.file'] }));
    formData.append('0', input.file);

    const response = await fetch(
      process.env.REACT_APP_OFFKEY_GRAPH_URL || 'http://localhost:4000/',
      {
        method: 'POST',
        headers: {
          'apollo-require-preflight': 'true'
        },
        body: formData
      }
    );

    const result = await response.json();
    if (result) return result.data.createDocument.uuid;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    console.error({ errorMessage });
  }
};

// TODO: Convert This Function into apolloClient.mutate
// const { data, errors } = await apolloClient.mutate({
//   mutation: FRAUDULENT_DOCUMENT_UUID,
//   variables: { input },
//   context: {
//     headers: {
//       'apollo-require-preflight': 'true'
//     }
//   },
//   fetchPolicy: 'no-cache'
// });

// if (errors && errors.length > 0) {
//   return new ApolloError({
//     errorMessage: errors.map((err) => err.message).join(', ')
//   });
// }
