import { createContext, ReactNode, useState } from 'react';
import { Calculation } from 'types/calculatorInterfaces';
import { useCalculatorStorage } from '../hooks/useCalculatorStorage';

interface ICalculatorStorageContext {
  getAutosaves: () => Promise<{ [key: string]: Calculation }>;
  saveCalculation: (
    newCalculation: Calculation,
    customId?: string
  ) => Promise<any>;
  updateCalculation: (
    newCalculation: Calculation,
    existingId?: string
  ) => Promise<void>;
  deleteCalculation: (idToDelete: string) => Promise<void>;
  loadCalculation: (calculation: Calculation, id: string) => void;
  getMyCalculations: () => Promise<{ [key: string]: Calculation }>;
  dialogData: { [key: string]: Calculation };
  setDialogData: React.Dispatch<
    React.SetStateAction<{ [key: string]: Calculation }>
  >;
  openMyCalculations: boolean;
  openAutosaveDialog: boolean;
  openMyAssets: boolean;
  tempAutosave: Calculation | null;
  toggleMyCalculations: () => void;
  toggleAutosave: () => void;
  setTempAutosave: (calculation: Calculation) => void;
  setOpenMyAssets: (open: boolean) => void;
}

export const CalculatorStorageContext =
  createContext<ICalculatorStorageContext>({} as ICalculatorStorageContext);

export const CalculatorStorageProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [dialogData, setDialogData] = useState<{
    [key: string]: Calculation;
  }>({} as { [key: string]: Calculation });

  const calculatorStorage = useCalculatorStorage({ setDialogData });
  const [openMyCalculations, setOpenMyCalculations] = useState<boolean>(false);
  const [openAutosaveDialog, setOpenAutosaveDialog] = useState<boolean>(false);
  const [openMyAssets, setOpenMyAssets] = useState<boolean>(false);
  const [tempAutosave, setTempAutosave] = useState<Calculation | null>(null);

  const toggleMyCalculations = () => setOpenMyCalculations((prev) => !prev);
  const toggleAutosave = () => setOpenAutosaveDialog((prev) => !prev);

  const startValues = {
    setOpenMyAssets,
    setTempAutosave,
    openMyCalculations,
    openAutosaveDialog,
    openMyAssets,
    tempAutosave,
    toggleMyCalculations,
    toggleAutosave
  };

  const value: ICalculatorStorageContext = {
    ...calculatorStorage,
    ...startValues,
    dialogData,
    setDialogData
  };

  return (
    <CalculatorStorageContext.Provider value={value}>
      {children}
    </CalculatorStorageContext.Provider>
  );
};
