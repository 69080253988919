import { ProxyInstanceSettings } from 'types/interfaces';

export const initProxySettings: ProxyInstanceSettings = {
  PlatformInstanceId: '',
  hostname: '',
  features: {
    address_lookup: false,
    afs_cribsheets: false,
    afs_legacydealviewer: false,
    audit: false,
    calculator: false,
    chatter: false,
    marketing: false,
    fca: false,
    redflag: false,
    rfgql: false,
    storage: false,
    test_flight: false,
    vrm: false,
    message_hub: false,
    bips_weekly: false,
    noticeboard_instance: false,
    dashboard: true,
    deal: true,
    data: true,
    extreme_caution: false
  }
};
