import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTypedSelector } from 'redux/reducers';
import LoginForm from './LoginForm';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<any>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SignBackInDialog = () => {
  const { cosnetics } = useTypedSelector((s) => s.user);
  const handleClose = () => window.location.replace('/');
  return (
    <Dialog
      style={{ zIndex: 11, backdropFilter: `blur(3px)` }}
      open={!cosnetics}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description">
      <DialogContent>
        <LoginForm />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" data-cy="login-dialog">
          Close Session
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignBackInDialog;
