import { Dashboard as DashboardLayout } from 'layouts';
import BetaBanner from 'components/BetaBanner';
import CompaniesHouseSearch from 'components/Fields/CompaniesHouseSearch';

const Component = () => {
  return (
    <DashboardLayout title="CRM" padding="noPadding">
      <BetaBanner />
      <CompaniesHouseSearch />
    </DashboardLayout>
  );
};

export default Component;
