import React, { useState } from 'react';
import axios from 'axios';
import TotalStepComponent from 'components/Stepper';
import { useHistory, Prompt } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slide,
  Checkbox,
  Tooltip
} from '@material-ui/core/';

import Grid from '@material-ui/core/Grid';
import queryString from 'query-string';
import { getProcessById } from 'redux/actions/processes';
import { getDealData } from 'redux/actions/processes/getDealData';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import { getSettings } from 'redux/actions/settings';
import { updateTheme } from 'redux/actions/configActions';
import { SET_GLOBAL_HOSTNAME } from 'redux/actions/types';
import { globalHost } from 'helpers/';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { BugTracker } from 'Utils/Bugtracker';
import { firebase } from 'redux/firebase';
import { useTypedSelector } from 'redux/reducers';
import { useProcess, useMobile, useGlobal } from 'hooks';
import { FilesAndMessages } from './components';
import { XpansionIsolated } from 'common/Xpansion';
import MobileHeader from './MobileView/MobileHeader';
import { ContentGrid } from './MobileView';
import useConfirmAcceptLandingPage from 'hooks/useDeals/useConfirmAccept';
import { stepProgress } from 'components/Stepper/functions/stepprogress';
import useRequiredCompletion from 'hooks/useDeals/useRequiredCompletion';
import { useRegulated } from 'hooks/useRegulated';
import StepDocumentHTML from 'components/Stepper/components/Documents/StepDocumentHTML';
import { PrimaryButton } from 'common';
import { SubmitProcessStep } from 'redux/database';
import { groupBy } from 'lodash-es';
import RequiredTooltip from 'Utils/tooltip';
import useDecline from 'hooks/useDeals/useDecline';
import { logUserIntoFireBase, loginAnalyticsEvent } from 'redux/actions/auth';
import { UserInstance } from 'types/interfaces';
import isPreviewMode from 'components/Stepper/components/Steps/ActiveStep/helper/previewMode';
import { globalIds } from 'helpers/globalIdConfig';

const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

const useStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  titlebox: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: 15,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  title: { color: theme.palette.primary.contrastText },
  expiredlink: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  },
  stepcontainer: {
    padding: theme.spacing(2),
    background: theme.palette.background.default
  }
}));

const Component = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { user, currentDeal } = useProcess();
  const isMobile = useMobile();

  const ref = React.useRef<HTMLDivElement | null>(null);
  const parsed = queryString.parse(history.location.search) as {
    piid: string;
    psdid: string;
    pdid: string;
    uiid: string;
    sc: string;
    psiid: string;
  };

  const { declineDialog } = useDecline({ parsed });
  const { dealConfirmDialog } = useConfirmAcceptLandingPage({
    currentDeal,
    parsed,
    user
  });

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [publicSettings, setPublicSettings] = React.useState<{
    SupportPhone: string;
    SupportEmail: string;
  } | null>(null);
  const [isDismount, setIsDismount] = React.useState(false);

  let location = globalHost(window.location);

  const getdata = () => {
    if (!parsed.piid || !parsed.psdid)
      return console.log('Erroneous query params');

    dispatch({ type: 'SET_LANDING_PAGE' });
    setError(null);
    // 1. Our first call will get back a user instance object instead
    // 2. Will be to the process instance API to get the process instance instead of the current landing page api
    // 3. The third call will be to get the definition
    let url = `${location.baseURL}/LandingPageAPI.ashx?piid=${parsed.piid}&uiid=${parsed.uiid}&psdid=${parsed.psdid}&sc=${parsed.sc}&psiid=${parsed.psiid}`;
    axios
      .get(url)
      .then(async (res) => {
        if (res.data as UserInstance) {
          // Set the User Here (Need to validate field updates.)
          dispatch({ type: 'SET_USER', payload: res.data as UserInstance });
          dispatch({ type: 'SET_TOKEN', payload: res.headers.auth });

          // Login to Firebase auth
          const FbLoginResponse = await logUserIntoFireBase({ res, hostname });
          if (FbLoginResponse.isAxiosError) return FbLoginResponse;
          loginAnalyticsEvent({ res });

          try {
            // This sets the Process instance (current Deal)
            const res1 = await getProcessById({
              Id: parseInt(parsed.piid),
              ProcessStepDefinitionId: parseInt(parsed.psdid)
            });

            const { ProcessDefinitionId } = res1?.data.ProcessInstance;

            await getDealData({
              ProcessInstanceId: parseInt(parsed.piid),
              ProcessStepDefinitionId: parseInt(parsed.psdid),
              ProcessDefinitionId
            });

            const settingsReturn = await getSettings({ returnData: undefined });
            dispatch({ type: 'SET_SETTINGS', payload: settingsReturn });
          } catch (e) {
            BugTracker.notify(e);
          }

          setLoading(false);
        } else {
        }
      })
      .catch(async (e) => {
        // Get Non Auth
        let url = `${location.baseURL}publicsettingsapi.ashx`;
        axios
          .get(url)
          .then((res) => {
            updateTheme(res.data.Theme);
            setPublicSettings(res.data);
          })
          .catch((e) => BugTracker.notify(e));

        setError(e.message);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    getdata();
    dispatch({ type: SET_GLOBAL_HOSTNAME, payload: location });
  }, []);

  React.useEffect(() => {
    if (user && user?.Title?.length > 0) {
      setIsDismount(true);
      load();
    }
  }, [user.Title]);

  const dealRef = firebase
    .firestore()
    .collection('deal')
    .doc(parsed.piid as string);

  const load = () =>
    dealRef.update({
      liveUsers: firebase.firestore.FieldValue.arrayUnion(user)
    });

  const unload = () =>
    dealRef.update({
      liveUsers: firebase.firestore.FieldValue.arrayRemove(user)
    });

  window.addEventListener('beforeunload', () => {
    unload();
  });

  if (error) {
    return (
      <div className={classes.expiredlink}>
        {publicSettings && (
          <img height="50" src={process.env.REACT_APP_INSTANCE_LOGO} />
        )}
        <br />

        <LinkOffIcon style={{ fontSize: 50 }} />
        <h1>EXPIRED LINK</h1>
        <h4>{'Please contact your agent to re-issue your link'.toString()}</h4>
        <h3>{publicSettings && publicSettings.SupportPhone}</h3>

        {publicSettings && publicSettings.SupportEmail && (
          <Link
            color="inherit"
            href={`mailto:${publicSettings.SupportEmail}`}
            target="_blank">
            {publicSettings.SupportEmail}
          </Link>
        )}
      </div>
    );
  }

  if (loading)
    return (
      <Backdrop open={loading} style={{ color: '#FFF', zIndex: 10 }}>
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
          spacing={2}>
          <Grid item>
            <CircularProgress color="inherit" />
          </Grid>
        </Grid>
      </Backdrop>
    );

  return (
    <>
      {dealConfirmDialog}
      {declineDialog}
      <div className={classes.root} ref={ref}>
        <Header />
        <ContentGrid parsed={parsed} mobile={isMobile} classes={classes} />
        <ScrollToTopBtn pageRef={ref} parsed={parsed} />
      </div>
    </>
  );
};
export default Component;

const Header = () => {
  const classes = useStyles();
  const isMobile = useMobile();

  const { currentDeal, currentProcess, user } = useProcess();
  let location = globalHost(window.location);

  if (isMobile) {
    return <MobileHeader classes={classes} />;
  }

  return (
    <div className={classes.titlebox}>
      <div style={{ filter: 'drop-shadow(2px 2px 1px black)' }}>
        <img
          height={37}
          src={location.logo}
          style={{ filter: 'grayscale(100%)' }}
        />
      </div>
      <div>
        <ListItem
          primary="Product Type"
          secondary={currentProcess?.ProcessDefinition?.Title}
        />
      </div>
      <div>
        <ListItem
          primary="Process Name"
          secondary={currentDeal?.ProcessInstance?.Title}
        />
      </div>
      <div>
        <ListItem primary="Customer" secondary={user?.Title} />
      </div>
      <div>
        <ListItem primary="Landing Page" secondary="Powered by Bips" />
      </div>
    </div>
  );
};

export const ScrollToTopBtn = ({ pageRef, parsed }) => {
  const theme = useTheme();
  const { currentDeal, currentProcess, currentStepId, user, stepdefdict } =
    useProcess();

  const { declineDialog, declineAll, loadingDecline } = useDecline({ parsed });
  const {
    ConfirmLandingPage,
    dealConfirmDialog,
    setOpen,
    open,
    loadingAccept
  } = useConfirmAcceptLandingPage({ currentDeal, parsed, user });
  const { entityType } = useTypedSelector((s) => s.process);

  const { regulated } = useRegulated();
  const { config } = useGlobal();

  const [openTooltip, setOpenTooltip] = useState(false);
  const handleTooltipClose = () => setOpenTooltip(false);
  const handleTooltipOpen = () => setOpenTooltip(true);

  const { ReqCmp, ReqFields } = useRequiredCompletion({
    currentDeal,
    currentProcess,
    parsed,
    regulated,
    entityType,
    currentStepId,
    config,
    CompleteObjectDefinitionDict: stepdefdict.CompleteObjectDefinitionDict
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleScrollToTop = () => {
    pageRef?.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  };

  const IndicativeStep = 1738;

  //* isPreview will only work if the magic link has been injected with preview
  const isPreview = isPreviewMode(window.location.href, true);
  return (
    <>
      {dealConfirmDialog}
      {declineDialog}
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="deal-alert-title"
        aria-describedby="deal-alert-description">
        <DialogTitle id="deal-alert-title">
          Please ensure you have read all the documents before you accept!
        </DialogTitle>
        <DialogContent>
          <StepDocumentHTML />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>

          {!loadingAccept ? (
            <PrimaryButton
              autoFocus
              onClick={ConfirmLandingPage}
              color="primary"
              variant="contained">
              Accept
            </PrimaryButton>
          ) : (
            <CircularProgress />
          )}
        </DialogActions>
      </Dialog>
      <Grid
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{
          position: 'sticky',
          bottom: theme.spacing(2),
          padding: 20,
          zIndex: 10
        }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          style={{
            backgroundColor: theme.palette.grey[400],
            borderRadius: isPreview ? 30 : 20,
            borderStartEndRadius: 30,
            borderEndEndRadius: 30,
            padding: isPreview ? 0 : 2
          }}
          marginRight={1}>
          {!isPreview && (
            <>
              {(globalIds.processInstanceStep.quoteSteps.includes(
                currentStepId
              ) ||
                currentStepId === IndicativeStep) && (
                <Tooltip
                  title={`Decline, Will Decline ${
                    globalIds.processInstanceStep.quoteSteps.includes(
                      currentStepId
                    )
                      ? 'Quotes'
                      : currentStepId === IndicativeStep
                      ? 'Indicative Quotes'
                      : ''
                  }`}
                  arrow>
                  <Box marginRight={1} marginLeft={2}>
                    <LoadingButton
                      isLoading={loadingDecline}
                      hasTooltip={false}
                      onClick={declineAll}>
                      Decline{' '}
                      {globalIds.processInstanceStep.quoteSteps.includes(
                        currentStepId
                      )
                        ? 'Quotes'
                        : currentStepId === IndicativeStep
                        ? 'Indicative Quotes'
                        : ''}
                    </LoadingButton>
                  </Box>
                </Tooltip>
              )}

              <Tooltip
                title={RequiredTooltip({ ReqFields, confirm: true })}
                placement="top"
                open={openTooltip}
                arrow
                interactive
                onClose={handleTooltipClose}
                onOpen={handleTooltipOpen}>
                <Box
                  marginRight={1}
                  marginLeft={
                    !globalIds.processInstanceStep.quoteSteps.includes(
                      currentStepId
                    ) && currentStepId !== IndicativeStep
                      ? 2
                      : 0
                  }>
                  <LoadingButton
                    isLoading={loadingAccept}
                    hasTooltip={
                      ReqCmp._Percent_Required_Fields_Complete !== 100
                    }
                    onClick={() => setOpen(true)}>
                    Confirm
                  </LoadingButton>
                </Box>
              </Tooltip>
            </>
          )}

          <Box>
            <Fab color="primary" onClick={handleScrollToTop}>
              <ArrowUpwardIcon />
            </Fab>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

const LoadingButton = ({ isLoading, hasTooltip, ...props }) => {
  const theme = useTheme();
  if (isLoading)
    return (
      <div style={{ display: 'inline-flex', alignItems: 'center' }}>
        <CircularProgress />
      </div>
    );
  return (
    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
      <PrimaryButton
        style={{ padding: theme.spacing(1) }}
        variant="contained"
        color="primary"
        {...props}
        disabled={hasTooltip ? true : isLoading || props.disabled}>
        {props.children}
      </PrimaryButton>
    </div>
  );
};

export const ListItem = ({ primary, secondary }) => {
  const theme = useTheme();
  return (
    <div>
      <div>{primary}</div>
      <div style={{ color: theme.palette.text.secondary }}>{secondary}</div>
    </div>
  );
};
