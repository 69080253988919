import { Audit } from "types/audit";


const initialState = {
  auditSummaries: [] as Audit[],
};

interface  SetSummary {
  type: "SET_AUDIT_SUMMARIES";
  payload: Audit[];
}

type AuditAction = SetSummary;

const auditReducer = (state = initialState, action: AuditAction) => {
  switch (action.type) {
    case 'SET_AUDIT_SUMMARIES':
      return {
        ...state,
        auditSummaries: action.payload
      };
    default:
      return state;
  }
};

export default auditReducer;
