import { CardContext } from 'components/MessageHub/context/HoverCardContext';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { IConversation } from 'components/MessageHub/interfaces';
import {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { BugTracker } from 'Utils/Bugtracker';
import {
  FolderType,
  FolderTypes,
  ICustomLoading,
  IThreadState
} from './interface';
import getSortedConversations from '../functions/getSortedConversations';
import getSortedArchives from '../functions/getSortedArchives';
import { callMsGraph } from 'services/microsoft/graph';
import { Message } from '@microsoft/microsoft-graph-types';

export const ThreadContext = createContext<IThreadState>({
  selectedThread: '',
  setSelectedThread: () => {},
  openThreads: {},
  setOpenThreads: () => {},
  conversations: {},
  handleOpen: async () => {},
  archivedConversations: {},
  customFolderConversations: {},
  sentItemsConversations: {},
  loading: { isLoading: true, amount: 10 },
  setLoading: () => {},
  refreshThreadReplies: () => {},
  loadMoreMessages: async () => {}
});

const ThreadProvider = ({ children }: { children: React.ReactNode }) => {
  const { validAccessToken } = useContext(MessageHubContext);
  const {
    messages,
    selectedCategory,
    profileData,
    archived,
    customFolder,
    sentItems,
    setData
  } = useContext(MessageHubContext);

  const [loading, setLoading] = useState<ICustomLoading>({
    isLoading: true,
    amount: 10
  });
  const [selectedThread, setSelectedThread] = useState<string>('');
  const [openThreads, setOpenThreads] = useState<{
    [key: string]: IConversation | null;
  }>({});

  const handleOpen = async (
    conversationId: string,
    index: number,
    conversations: string | { [key: string]: IConversation }
  ) => {
    try {
      await fetchThreadReplies({ conversationId }).then((response) => {
        const spliceReplies = response.splice(1);
        const threadMessages: IConversation = {
          parentMessage: conversations[index].parentMessage,
          replies: spliceReplies,
          totalAttachments: []
        };

        setOpenThreads({ [conversationId]: threadMessages });
        setSelectedThread(conversationId);
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const refreshThreadReplies = async ({
    conversationId
  }: {
    conversationId: string;
  }) => {
    try {
      const newReplies = await fetchThreadReplies({ conversationId });
      const spliceReplies = newReplies.splice(1);

      setOpenThreads((prevOpenThreads) => {
        const thread = prevOpenThreads[conversationId];
        if (thread) {
          return {
            ...prevOpenThreads,
            [conversationId]: {
              ...thread,
              replies: spliceReplies
            }
          };
        }
        return prevOpenThreads;
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const fetchThreadReplies = async ({
    conversationId
  }: {
    conversationId: string;
  }) => {
    const accessToken = await validAccessToken();

    try {
      const replies = await callMsGraph({
        accessToken,
        action: 'messages',
        queryParams: {
          $filter: `conversationId eq '${conversationId}'`,
          $expand: 'attachments'
        }
      });

      return replies.value;
    } catch (e) {
      BugTracker.notify(e);
      return [];
    }
  };

  const loadMoreMessages = async (
    folderType: FolderType,
    folderIdentifier: string,
    page: number,
    setPage: React.Dispatch<SetStateAction<number>>,
    setLoading: React.Dispatch<SetStateAction<boolean>>,
    setHasMore: React.Dispatch<SetStateAction<boolean>>
  ) => {
    setLoading(true);
    const accessToken = await validAccessToken();
    const predefinedFolderTypes = [
      'messages',
      'Sent Items',
      'archived',
      'drafts'
    ];

    let folderUrl;
    if (predefinedFolderTypes.includes(folderType)) {
      folderUrl = `https://graph.microsoft.com/v1.0/me/mailFolders/${FolderTypes[folderType]}`;
    } else {
      folderUrl = `https://graph.microsoft.com/v1.0/me/mailFolders/${folderIdentifier}`;
    }

    const folderResponse = await fetch(folderUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    });

    if (!folderResponse.ok) return;
    const folderData = await folderResponse.json();
    const folderId = folderData.id;

    const messagesResponse = await callMsGraph({
      accessToken,
      action: 'ParentFolders',
      parentFolderId: folderId,
      queryParams: { $skip: 10 * page, $expand: 'attachments' }
    });

    const newMessageValue = messagesResponse.value;
    if (newMessageValue.length === 0) {
      setHasMore(false);
      setLoading(false);
      return;
    }

    setData((prevData: any) => {
      const existingMessages =
        prevData[folderType] && Array.isArray(prevData[folderType].value)
          ? prevData[folderType].value
          : [];

      const existingConversationIds = new Set(
        existingMessages.map((msg: Message) => msg.id)
      );

      const uniqueNewMessages = newMessageValue.filter(
        (newMsg: Message) => !existingConversationIds.has(newMsg.id)
      );

      return {
        ...prevData,
        [folderType]: {
          ...prevData[folderType],
          value: [...existingMessages, ...uniqueNewMessages]
        }
      };
    });

    setLoading(false);
    setPage((prevPage) => prevPage + 1);
  };

  const conversations = useMemo(() => {
    const sortedAchievedConversations = getSortedArchives({
      archived: messages
    });

    return sortedAchievedConversations;
  }, [messages, selectedCategory, profileData]);

  const archivedConversations = useMemo(() => {
    const sortedAchievedConversations = getSortedArchives({
      archived
    });

    return sortedAchievedConversations;
  }, [archived, selectedCategory, profileData]);

  const customFolderConversations = useMemo(() => {
    const sortedAchievedConversations = getSortedArchives({
      archived: customFolder
    });

    return sortedAchievedConversations;
  }, [customFolder, selectedCategory, profileData]);

  const sentItemsConversations = useMemo(() => {
    const sortedAchievedConversations = getSortedArchives({
      archived: sentItems
    });

    return sortedAchievedConversations;
  }, [sentItems, selectedCategory, profileData]);

  useEffect(() => {
    if (Object.values(conversations).length !== 0) {
      setLoading({
        isLoading: false,
        amount: 10
      });
    }
  }, [conversations]);

  return (
    <ThreadContext.Provider
      value={{
        selectedThread,
        setSelectedThread,
        openThreads,
        setOpenThreads,
        conversations,
        handleOpen,
        loadMoreMessages,
        archivedConversations,
        customFolderConversations,
        sentItemsConversations,
        loading,
        setLoading,
        refreshThreadReplies
      }}>
      {children}
    </ThreadContext.Provider>
  );
};

export default ThreadProvider;
