import React from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import CloseIcon from '@material-ui/icons/Close';

import TextTyper from './components/TextTyper';

const useStyles = makeStyles(theme => ({
  btns: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

const MenuButton = withStyles(theme => ({
  root: {
    textTransform: 'none',
    textAlign: 'left',
    display: 'flex',
    alignSelf: 'flex-start'
    // width: '100%',
    // minWidth: 300
    // '&$label': { display: 'flex', alignSelf: 'flex-start' }
  }
}))(Button);

const ScrollDialog = ({ open, setOpen, task, updateTask }) => {
  const classes = useStyles();
  const handleClose = () => setOpen(false);
  const descriptionElementRef = React.useRef(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} scroll="body" fullWidth>
        <Grid container direction="row">
          <Grid item xs={9}>
            <DialogTitle id="scroll-dialog-title">
              <EditableInput
                variant="h2"
                value={task.title}
                onChange={e =>
                  updateTask({
                    id: task.id,
                    value: e.target.value,
                    field: 'title'
                  })
                }
              />
            </DialogTitle>
            <DialogContent>
              <Grid container direction="column">
                <Grid item>
                  <Typography variant="h6">Description</Typography>
                  <EditableInput
                    multiline
                    variant="body1"
                    value={task.description}
                    onChange={e =>
                      updateTask({
                        id: task.id,
                        value: e.target.value,
                        field: 'description'
                      })
                    }
                  />
                </Grid>

                <br />

                <Grid item>
                  <Typography variant="h6">Activity</Typography>
                  <TextTyper />
                </Grid>
              </Grid>
            </DialogContent>
          </Grid>

          <Grid item xs={3} style={{ paddingTop: 5 }}>
            {/* <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions> */}

            <Grid align="right">
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>

            {/* <Typography>Add to Card</Typography>

            <Grid container direction="column" spacing={1}>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Members
                </MenuButton>
              </Grid>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Labels
                </MenuButton>
              </Grid>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Checklist
                </MenuButton>
              </Grid>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Due Date
                </MenuButton>
              </Grid>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Attachment
                </MenuButton>
              </Grid>
            </Grid>

            <br />

            <Typography>Actions</Typography>

            <Grid container direction="column" spacing={1}>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Move
                </MenuButton>
              </Grid>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Copy
                </MenuButton>
              </Grid>
              <Grid item>
                <MenuButton variant="contained" color="primary">
                  Archive
                </MenuButton>
              </Grid>
            </Grid> */}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

const EditableInput = ({ value, onChange, variant, multiline }) => {
  const theme = useTheme();
  const [editTitle, setEditTitle] = React.useState(false);
  const toggleTitle = () => setEditTitle(!editTitle);

  const Text = withStyles(theme => ({
    root: {
      padding: 3,
      '&:hover': {
        background: theme.palette.primary.light,
        borderRadius: theme.shape.borderRadius
      }
    }
  }))(Typography);

  if (editTitle) {
    return (
      <div
        style={{
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: theme.shape.borderRadius,
          background: theme.palette.primary.light
        }}>
        <InputBase
          multiline
          fullWidth
          defaultValue={value || ''}
          onBlur={toggleTitle}
          onKeyPress={e => !multiline && e.charCode === 13 && toggleTitle()}
          autoFocus
          inputProps={{ color: 'red' }}
          onChange={onChange}
        />
      </div>
    );
  } else {
    return (
      <React.Fragment>
        <Text
          variant={variant}
          onClick={toggleTitle}
          onDoubleClick={toggleTitle}>
          {value ? value : 'Add a more detailed description'}
        </Text>
      </React.Fragment>
    );
  }
};

export default ScrollDialog;
