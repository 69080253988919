import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  FieldDefinitionDict,
  FieldDefinition,
  ObjectDefinition,
  UserInstance,
  // ObjectInstance,
  CompleteProcessStepDefinition,
  // ProcessInstance,
  CompleteProcessInstance
} from 'types/interfaces';
import { useSelector } from 'react-redux';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
// import SingleObject from '../SingleObject';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import { Typography, Grid, Paper } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import Summary from './summary';

import DeleteObjectButton from '../components/DeleteObjectButton';
import { useObject } from 'hooks/useObject';
import { getDealData } from 'redux/actions/processes/getDealData';

import { objectType } from '../Forms/FormContent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      margin: '8px 0px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start'
    },
    leverCol: {
      width: 270,
      paddingRight: theme.spacing(2),
      borderRight: `1px ${theme.palette.grey['A100']} solid`
    },
    otherCol: {
      paddingLeft: theme.spacing(2),
      width: '100%'
    },
    hr: {
      borderRadius: 10,
      background: theme.palette.grey['A100'],
      margin: 5
    },
    inlinetext: {
      color: theme.palette.secondary.main
    },
    paper: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`
    },
    TotalsBox: {
      border: `3px ${theme.palette.background.default} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`,
      background: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-around'
    },
    TotalsBoxText: {
      color: theme.palette.primary.contrastText
    }
  })
);

interface Props {
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ObjectDefinition: ObjectDefinition;
  stepdefdict: CompleteProcessStepDefinition;
  setCurrentObjectType: Dispatch<SetStateAction<objectType>>;
  currentObjectType: objectType;
}

const RepeatDynamicObject = (props: Props) => {
  const [refreshingDeal, setRefreshingDeal] = React.useState(false);
  let currentDeal: CompleteProcessInstance | any = {};
  let UserInstance: UserInstance | any = {};
  currentDeal = useSelector<any>((s) => s.process.currentDeal);
  UserInstance = useSelector<any>((s) => s.user.user);

  const ProcessInstanceId = currentDeal?.ProcessInstance?.Id;
  const ProcessStepDefinitionId =
    currentDeal?.ProcessInstance?.ProcessStepDefinitionId;
  const { FieldDefinitionList, ObjectDefinition, stepdefdict } = props;

  const { deleteObject } = useObject({
    ObjectDefinition,
    UserInstance,
    ProcessInstanceId,
    Fields: FieldDefinitionList,
    ProcessStepSelectionType:
      stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType
  });

  const deleteObjectAndRefresh = async (Id) => {
    const deleted = await deleteObject(Id);
    deleted.status === 200 && refreshDealData();
  };

  const refreshDealData = async () => {
    setRefreshingDeal(true);
    const ProcessDefinitionId = currentDeal.ProcessInstance.ProcessDefinitionId;
    const res = await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });

    if (res) {
      setRefreshingDeal(false);
    }
  };

  const classes = useStyles();

  const ObjectInstances = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).filter(
    (item: any): boolean =>
      item.ObjectInstance.ObjectDefinitionId === props.ObjectDefinition.Id
  );

  const canAddMore =
    props.ObjectDefinition.ObjectRepeat === 0
      ? true
      : ObjectInstances.length < props.ObjectDefinition.ObjectRepeat;

  //let prices: any = [];
  //let assetsNumber = 0;
  //let sum = 0;

  useEffect(() => {
    props.setCurrentObjectType(objectType.repeatDynamicPropertyObject);

    return () => {
      props.setCurrentObjectType(objectType.nullObject);
    };
  }, [props.currentObjectType, props.ObjectDefinition.Title]);

  return (
    <div>
      <Paper elevation={1} className={classes.TotalsBox}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant="h2" className={classes.TotalsBoxText}>
            {ObjectInstances.length}
          </Typography>
          <Typography className={classes.TotalsBoxText}>
            PROPERTY TYPES
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant="h2" className={classes.TotalsBoxText}>
            0
          </Typography>
          <Typography className={classes.TotalsBoxText}>
            LEASEHOLD (MONTHS)
          </Typography>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Typography variant="h2" className={classes.TotalsBoxText}>
            {
              <NumberFormat
                value={0}
                prefix={'£'}
                thousandSeparator
                displayType={'text'}
                decimalScale={2}
              />
            }
          </Typography>
          <Typography className={classes.TotalsBoxText}>
            PURCHASE PRICE
          </Typography>
        </div>
      </Paper>

      <Grid container spacing={2}>
        {ObjectInstances.map((ObjectInstance: any, idx: number) => {
          return (
            <Grid item key={idx} md={6} sm={12}>
              <DeleteObjectButton
                deleteObject={deleteObjectAndRefresh}
                object={ObjectInstance}
                title={ObjectInstance.ObjectInstance.Title}
              />
              <Summary ObjectInstance={ObjectInstance} {...props} />
            </Grid>
          );
        })}
      </Grid>

      <br />

      {canAddMore && UserInstance && (
        <AddFieldAndObjectButton
          Fields={Object.values(props.FieldDefinitionDict)}
          ObjectDefinition={props.ObjectDefinition}
          ProcessInstance={currentDeal.ProcessInstance}
          ProcessInstanceId={currentDeal.ProcessInstance.Id}
          ProcessStepSelectionType={
            props.stepdefdict.ProcessStepDefinition.ProcessStepSelectionType
          }
          UserInstance={UserInstance}
          label={`New ${props.ObjectDefinition.Title}`}
          isAssetObject
        />
      )}
    </div>
  );
};

export default RepeatDynamicObject;

//const PricePerUnitDefinitionId = props.FieldDefinitionList.find(
//  (i) => i.Title === 'Price Per Unit'
//)?.Id;

// const QuantityDefId = props.FieldDefinitionList.find(
//   (i) => i.Title === 'Quantity'
// )?.Id;

//ObjectInstances.forEach((i: any) => {
//  const priceInstObj: any = Object.values(i.FieldInstanceDict).find(
//    (j: any) => j.FieldDefinitionId === PricePerUnitDefinitionId
//  );
//const pricePerUnit = priceInstObj
//  ? Number.isNaN(parseFloat(priceInstObj.FieldValue))
//    ? 0
//    : parseFloat(priceInstObj.FieldValue)
//  : 0;
// const quantityInstObj: any = Object.values(i.FieldInstanceDict).find(
//   (j: any) => j.FieldDefinitionId === QuantityDefId
// );
// const quantity =
//   quantityInstObj.FieldValue !== ''
//     ? parseFloat(quantityInstObj.FieldValue)
//     : 1;
//  assetsNumber += quantity;
//  prices.push(pricePerUnit * quantity);
//});
//for (let i = 0; i < prices.length; i++) {
//  sum += prices[i];
// }
