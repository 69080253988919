export const formats = [
  'pdf',
  'docx',
  'doc',
  'rtf',
  'wav',
  'csv',
  'eml',
  'xlsx',
  'xls'
];
