import axios from 'axios';
import { firebase } from 'redux/firebase';
import { createNotification } from 'react-redux-notify';
import { store } from 'redux/store';

import { errorNotif, successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';
import { Email } from '../../types';
import { validateSenderDomains } from 'components/Marketing/Senders/functions';

const { dispatch } = store;

export const sendEmail = async ({
  email,
  contactsCount
}: {
  email: Email;
  contactsCount: number;
}): Promise<boolean | void> => {
  const { id } = email;

  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/marketing/sendEmail/${id}`;

  const valid = await validateSenderDomains({
    senderFromEmail: email.senderFromEmail,
    senderReplyToEmail: email.senderReplyEmail
  });

  if (!valid) return false;

  const res = await axios
    .put(url, { count: contactsCount }, { headers: { token } })
    .catch((e) => {
      dispatch(createNotification(errorNotif(`Email Not Sent`)));

      if (e?.response?.data?.response?.body?.errors?.length > 0) {
        const errors = e?.response?.data?.response?.body?.errors;

        errors.forEach((error) => {
          const errorString = `Field: ${error.field} \n Message: ${error.message}`;
          dispatch(createNotification(errorNotif(errorString)));
          BugTracker.notify(new Error(errorString), e);
        });
      }
    });

  if (res?.data[0]?.statusCode === 200) {
    const status = res?.data[1]?.status;
    dispatch(
      createNotification(successNotif(`Email status updated: ${status}`))
    );
    gtag.event({
      feature: 'Marketing',
      action: 'Email Send: Success',
      message: id,
      value: contactsCount
    });

    return true;
  }
};
