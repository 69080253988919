export const decodeFormattedText = (element: string | number) => {
  if (typeof element === 'string') {
    if (element === '') {
      return "Couldn't Find Data";
    }

    // We have a Formatted Text
    if (element.charAt(0).includes('%')) {
      const decodedText = decodeURIComponent(element);
      return decodedText.replace(/<[^>]*>/g, '');
    } else return element;
  } else {
    return element;
  }
};
