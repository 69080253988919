import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { Select, MenuItem } from '@material-ui/core';

import { CustomerType } from 'types/interfaces';
import { handleTypeOrNoteChange } from 'components/Table/Timeline/functions/handleTypeOrNoteChange';
import { successNotif } from 'components/Notifications';
import { useMarketing } from '../../context';

interface Props {
  currentCustomerType?: CustomerType;
  userInstanceId: number;
}

const CustomerTypeRow = (props: Props) => {
  const { currentCustomerType = CustomerType.None, userInstanceId } = props;

  const dispatch = useDispatch();

  const { refetch } = useMarketing();
  const [customerType, setCustomerType] = useState(currentCustomerType);

  return (
    <Select
      autoWidth
      value={customerType}
      onChange={async (e) => {
        const newCustomerType = e.target.value as string;

        const newHistoryEvent = {
          name: newCustomerType,
          timestamp: Date.now(),
          prevType: customerType
        };

        await handleTypeOrNoteChange(
          'Customer Type',
          newCustomerType,
          newHistoryEvent,
          userInstanceId.toString()
        );

        setCustomerType(newCustomerType as CustomerType);

        await refetch();

        dispatch(createNotification(successNotif('Customer Type Updated')));
      }}>
      {Object.values(CustomerType).map((c) => (
        <MenuItem key={c} value={c}>
          {c}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CustomerTypeRow;
