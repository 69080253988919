import { useCalculatorStorage } from '../../CalculationStore/hooks/useCalculatorStorage';

interface Props {
  setSnackBar: React.Dispatch<
    React.SetStateAction<{
      open: boolean;
      variant: string;
      message: string;
    }>
  >;
}

export const useValidateCalculationName = ({ setSnackBar }: Props) => {
  const { getMyCalculations } = useCalculatorStorage({});

  async function validateCalculationName(name: string | null): Promise<{
    isInvalid: boolean;
    existingCalculationId?: string;
  }> {
    const calculations = await getMyCalculations();

    const existingCalculation = Object.entries(calculations).find(
      ([id, calculation]) => {
        const nameMatch =
          calculation?.Title?.toLowerCase() === name?.toLowerCase();

        if (nameMatch) return true;
      }
    );

    const existingCalculationId = existingCalculation?.[0];

    const hasName = !!name;

    if (!hasName) {
      setSnackBar({
        open: true,
        message: 'Please enter a Name for your Calculation',
        variant: 'warning'
      });
    }

    return {
      isInvalid: !hasName,
      existingCalculationId
    };
  }

  return { validateCalculationName };
};
