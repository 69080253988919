import React, { useContext, useState } from 'react';
import { Avatar, Box, Button, Chip, Grid } from '@material-ui/core';
import { Send, Close as CloseIcon } from '@material-ui/icons';
import { Message } from '@microsoft/microsoft-graph-types';

import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { useSenderColors } from 'components/MessageHub/hooks/useSenderColors';
import { useTypedSelector } from 'redux/reducers';
import { theme } from 'theme';
import EmailField from '../../ComposeWindows/components/EmailField';
import { ConversationContext } from '../context/ConversationContext';
import { Alert, AlertTitle } from '@material-ui/lab';

interface ForwardEmailProps {
  messageId: string;
  message: Message;
  setIsForwarding: (isForwarding: boolean) => void;
  clearAll: () => void;
}

interface Recipients {
  to: string[];
  bcc: string[];
  cc: string[];
}

const ForwardEmail: React.FC<ForwardEmailProps> = ({
  messageId,
  message,
  setIsForwarding,
  clearAll
}) => {
  const user = useTypedSelector((state) => state.user.user);
  const { forwardMessage } = useContext(ConversationContext);
  const { grabRelationshipEmails } = useContext(MessageHubContext);
  const getSenderColor = useSenderColors();

  const [showCC, setShowCC] = useState<boolean>(false);
  const [showBCC, setShowBCC] = useState<boolean>(false);

  const [recipientsString, setRecipientsString] = useState<Recipients>({
    to: [],
    bcc: [],
    cc: []
  });

  const handleForwardClick = async () => {
    await forwardMessage({
      messageId,
      content: message?.body?.content || '',
      recipients: recipientsString,
      subject: message?.subject || '',
      message
    })
      .then(() => {
        setRecipientsString({
          to: [],
          bcc: [],
          cc: []
        });
        setIsForwarding(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleBccClick = () => setShowBCC(true);
  const handleCcClick = () => setShowCC(true);

  const handleDeleteChip = (index: number, type: keyof Recipients) => {
    setRecipientsString((prevRecipients) => {
      const updatedRecipients = { ...prevRecipients };
      updatedRecipients[type] = updatedRecipients[type].filter(
        (_, i) => i !== index
      );
      return updatedRecipients;
    });
  };

  const toEndAdornments = (
    <>
      <Box
        component="span"
        onClick={handleBccClick}
        style={{ cursor: 'pointer', marginRight: '8px' }}>
        Bcc
      </Box>
      <Box
        component="span"
        onClick={handleCcClick}
        style={{ cursor: 'pointer' }}>
        Cc
      </Box>
    </>
  );

  return (
    <>
      <Grid container spacing={2} alignItems="flex-end">
        {recipientsString.to.length > 0 && (
          <div style={{ padding: theme.spacing(1), width: '100%' }}>
            <Alert severity="info" elevation={2}>
              <AlertTitle>
                {recipientsString.to.length} Recipient
                {recipientsString.to.length > 1 && 's'}
              </AlertTitle>
              <Box>
                {recipientsString.to.map((email, index) => (
                  <Chip
                    key={index}
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: getSenderColor(email)
                        }}>
                        {email[0].toUpperCase()}
                      </Avatar>
                    }
                    style={{ margin: '2px' }}
                    label={email}
                    variant="outlined"
                    onDelete={() => handleDeleteChip(index, 'to')}
                  />
                ))}
              </Box>
            </Alert>
          </div>
        )}
        <Grid item style={{ width: '100%', display: 'flex', height: '100%' }}>
          <EmailField
            label="To"
            type="to"
            handleDeleteClick={() => {}}
            onFocus={async () => {
              return await grabRelationshipEmails(user);
            }}
            panelIndex={'to'}
            endAdornments={toEndAdornments}
            setRecipients={setRecipientsString}
            recipients={recipientsString}
          />
        </Grid>

        {showBCC && (
          <>
            {recipientsString.bcc.length > 0 && (
              <div style={{ padding: theme.spacing(1), width: '100%' }}>
                <Alert severity="info" elevation={2}>
                  <AlertTitle>
                    {recipientsString.bcc.length} Recipient
                    {recipientsString.bcc.length > 1 && 's'}
                  </AlertTitle>
                  <Box>
                    {recipientsString.bcc.map((email, index) => (
                      <Chip
                        key={index}
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: getSenderColor(email)
                            }}>
                            {email[0].toUpperCase()}
                          </Avatar>
                        }
                        style={{ margin: '2px' }}
                        label={email}
                        variant="outlined"
                        onDelete={() => handleDeleteChip(index, 'bcc')}
                      />
                    ))}
                  </Box>
                </Alert>
              </div>
            )}
            <Grid item style={{ width: '100%', display: 'flex' }}>
              <EmailField
                label="Bcc"
                type="Bcc"
                handleDeleteClick={() => setShowBCC(false)}
                onFocus={async () => {
                  return await grabRelationshipEmails(user);
                }}
                endAdornments={undefined}
                panelIndex={'bcc'}
                setRecipients={setRecipientsString}
                recipients={recipientsString}
              />
            </Grid>
          </>
        )}

        {showCC && (
          <>
            {recipientsString.cc.length > 0 && (
              <div style={{ padding: theme.spacing(1), width: '100%' }}>
                <Alert severity="info" elevation={2}>
                  <AlertTitle>
                    {recipientsString.cc.length} Recipient
                    {recipientsString.cc.length > 1 && 's'}
                  </AlertTitle>
                  <Box>
                    {recipientsString.cc.map((email, index) => (
                      <Chip
                        key={index}
                        avatar={
                          <Avatar
                            style={{
                              backgroundColor: getSenderColor(email)
                            }}>
                            {email[0].toUpperCase()}
                          </Avatar>
                        }
                        style={{ margin: '2px' }}
                        label={email}
                        variant="outlined"
                        onDelete={() => handleDeleteChip(index, 'cc')}
                      />
                    ))}
                  </Box>
                </Alert>
              </div>
            )}
            <Grid item style={{ width: '100%', display: 'flex' }}>
              <EmailField
                label="Cc"
                type="cc"
                handleDeleteClick={() => setShowCC(false)}
                onFocus={async () => {
                  return await grabRelationshipEmails(user);
                }}
                endAdornments={undefined}
                panelIndex={'cc'}
                setRecipients={setRecipientsString}
                recipients={recipientsString}
              />
            </Grid>
          </>
        )}

        <Grid item>
          <Button
            disabled={recipientsString.to.length === 0}
            variant="outlined"
            color="primary"
            onClick={handleForwardClick}
            style={{ textTransform: 'none' }}
            endIcon={<Send />}>
            Send
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            onClick={clearAll}
            style={{ textTransform: 'none' }}
            endIcon={<CloseIcon />}>
            Close
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ForwardEmail;
