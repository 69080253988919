import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import {
  CompleteObjectInstance,
  FieldInstance,
  FieldDefinitionDict,
  FieldDefinition,
  ObjectDefinition,
  CompleteUserInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

interface Props {
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ObjectDefinition: ObjectDefinition;
  rowData: CompleteUserInstance;
}

const RepeatingObject = (props: Props) => {
  const { FieldDefinitionList, ObjectDefinition, rowData } = props;
  let chart = useRef<any>(null);

  const Graph = () => {
    let x = am4core.create(
      'chartdiv',
      am4plugins_forceDirected.ForceDirectedTree
    );
    let networkSeries = x.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    );
    x.zoomable = true;

    const descendant_companies: CompleteObjectInstance[] =
      rowData.CompleteObjectInstanceList.filter(
        (el: CompleteObjectInstance) =>
          el.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
      );

    let ultimate_parent = undefined as CompleteObjectInstance | undefined;

    const group_companies: CompleteObjectInstance[] | undefined =
      rowData.CompleteObjectInstanceList.filter(
        (el) => el.ObjectInstance.Title === 'Group Companies'
      );

    if (group_companies) {
      group_companies.forEach(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          if (CompleteObjectInstance.FieldInstanceList.length > 0) {
            CompleteObjectInstance.FieldInstanceList.forEach(
              (el: FieldInstance) => {
                if (el.Title === 'Parent Company Number') {
                  if (el.FieldValue === '0') {
                    ultimate_parent = CompleteObjectInstance;
                  }
                }
              }
            );
          }
        }
      );
    }

    interface ChartDataObject {
      name: string;
      id: string;
      value: string;
      fixed: boolean;
      color?: string;
      children?: ChartDataObject;
    }

    if (ultimate_parent) {
      let name = '';
      let id = '';
      let value = '';

      const FieldInstanceList = getFieldInstances(ultimate_parent);
      FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
        switch (FieldInstance.Title) {
          case 'Name':
            return (name = FieldInstance.FieldValue);
          case 'Company Number':
            return (id = FieldInstance.FieldValue);
          case 'Rfa Rating':
            return (value = JSON.parse(
              FieldInstance.FieldValue
            ).trading_payment_parity);
          default:
            return;
        }
      });

      let UltimateParent: ChartDataObject = {
        name: name,
        id: id,
        value: value,
        fixed: true
      };

      x.data = [UltimateParent];

      if (descendant_companies) {
        const getFieldValueWithTitle = (
          Title: string,
          FieldInstanceList: FieldInstance[]
        ) => {
          const FieldInstance: FieldInstance | undefined =
            FieldInstanceList.find((el) => el.Title === Title);
          if (FieldInstance) return FieldInstance.FieldValue;
          return 'No Data';
        };

        const nest = (
          data: CompleteObjectInstance[],
          parentId: null | string = null
        ) => {
          return data.reduce((r: any, e: CompleteObjectInstance) => {
            let obj = {} as ChartDataObject;
            let rfa_rating: string = getFieldValueWithTitle(
              'Rfa Rating',
              e.FieldInstanceList
            );
            const deserialized = rfa_rating ? JSON.parse(rfa_rating) : '';
            const CurrentCompanyNumber = getFieldValueWithTitle(
              'Company Number',
              e.FieldInstanceList
            );
            const Name = getFieldValueWithTitle('Name', e.FieldInstanceList);
            obj.name = Name;
            obj.value = deserialized['trading_payment_parity'];
            obj.id = CurrentCompanyNumber;
            const parentIdOfCurrentCompany = getFieldValueWithTitle(
              'Parent Company Number',
              e.FieldInstanceList
            );
            if (parentId === parentIdOfCurrentCompany) {
              let children = nest(data, CurrentCompanyNumber);
              if (children.length > 0) obj.children = children;
              r.push(obj);
            }
            return r;
          }, []);
        };

        const ultimateParentCompanyNumber = getFieldValueWithTitle(
          'Company Number',
          ultimate_parent.FieldInstanceList
        );

        let structure = nest(descendant_companies, ultimateParentCompanyNumber);

        x.data[0].children = structure;
      }
    }

    networkSeries.dataFields.value = 'value';
    networkSeries.dataFields.name = 'name';
    networkSeries.dataFields.id = 'id';
    networkSeries.dataFields.children = 'children';

    networkSeries.nodes.template.tooltipText =
      '{name} \n Trading Payment Parity: {value} \n Company Number: {id}';
    networkSeries.nodes.template.fillOpacity = 1;
    networkSeries.nodes.template.togglable = false;

    networkSeries.nodes.template.label.text = '{name}';
    networkSeries.fontSize = 8;

    networkSeries.links.template.strokeWidth = 1;

    let hoverState = networkSeries.links.template.states.create('hover');
    hoverState.properties.strokeWidth = 3;
    hoverState.properties.strokeOpacity = 1;

    networkSeries.nodes.template.events.on('over', function (event) {
      event.target.dataItem.childLinks.each(function (link) {
        link.isHover = true;
      });
      if (event.target.dataItem.parentLink) {
        event.target.dataItem.parentLink.isHover = true;
      }
    });

    networkSeries.nodes.template.events.on('out', function (event) {
      event.target.dataItem.childLinks.each(function (link) {
        link.isHover = false;
      });
      if (event.target.dataItem.parentLink) {
        event.target.dataItem.parentLink.isHover = false;
      }
    });

    var hl = networkSeries.nodes.template.states.create('selected');
    hl.properties.fill = am4core.color('#c55');

    var selectedNode;
    networkSeries.nodes.template.events.on('hit', function (event) {
      if (selectedNode == event.target) {
        selectedNode.fill = selectedNode.defaultState.properties.fill;
        selectedNode = undefined;
      } else {
        if (selectedNode) {
          selectedNode.fill = selectedNode.defaultState.properties.fill;
        }
        selectedNode = event.target;
        selectedNode.setState('selected');

        // setChildMeta({
        //   name: selectedNode.dataItem.name,
        //   id: selectedNode.dataItem.id
        // });

        // setOpen(true);
      }
    });

    chart.current = x;
  };

  useLayoutEffect(() => {
    Graph();
  }, []);

  return <div id="chartdiv" style={{ width: '100%', height: '400px' }} />;
};

export default RepeatingObject;
