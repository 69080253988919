import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
  WithStyles,
  withStyles
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { getFieldInstanceValue } from 'Utils';
import { BugTracker } from 'Utils/Bugtracker';
import { Regulated } from 'Utils/RegulatedParties';
import { globalIds } from 'helpers/globalIdConfig';
import React, { Dispatch, SetStateAction } from 'react';
import { GetUserDefinitions } from 'redux/database';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance,
  UserDefinition,
  UserInstance
} from 'types/interfaces';
import InlineTextField from 'components/Fields/InlineTextField';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { Alert } from '@material-ui/lab';
import { CustomDialog } from 'common/Dialog';
import { Close } from '@material-ui/icons';
import { getCompleteUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { ICompleteUserInstanceDetail } from 'redux/actions/GraphQlActions/interface';
import { useTypedSelector } from 'redux/reducers';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { getCachedUserInstanceDetail } from 'redux/actions/GraphQlActions/cachedRequests';
import { store } from 'redux/store';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme) => ({
  reg: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  },

  details: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: theme.spacing(2)
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',

    padding: theme.spacing(1),
    alignItems: 'center'
  },

  container: { width: '100%' },
  paper: { background: theme.palette.background.paper },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1),

    width: 'calc(100% - 32px)',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          data-cy="close-summary-btn"
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <br />
      <br />
      {children}
    </MuiDialogTitle>
  );
});

interface IDetailsDialogProps {
  open: { open: boolean; FieldDefinition: FieldDefinition };
  setOpen: Dispatch<
    SetStateAction<{ open: boolean; FieldDefinition: FieldDefinition }>
  >;
  CompleteObjectInstance: CompleteObjectInstance;
}

const DetailsDialog = ({
  open,
  setOpen,
  CompleteObjectInstance
}: IDetailsDialogProps) => {
  const [entity, setEntity] = React.useState<{
    UserInstanceSummary: ICompleteUserInstanceDetail | undefined;
    CompleteObjectDefinition: CompleteObjectDefinition | undefined;
    UserInstance: UserInstance | undefined;
  }>({
    UserInstanceSummary: undefined,
    CompleteObjectDefinition: undefined,
    UserInstance: undefined
  });

  return (
    <CustomDialog
      handleClose={() => {
        setOpen({
          ...open,
          open: false,
          FieldDefinition: {} as FieldDefinition
        });

        setEntity({
          ...entity,
          UserInstanceSummary: undefined,
          UserInstance: undefined,
          CompleteObjectDefinition: undefined
        });
      }}
      open={open.open}
      maxSize="md"
      alert={{
        title: `Customer and Contact Information Overview`,
        description:
          'A Comprehensive Summary of All Customer and Contact Details',
        type: 'info'
      }}>
      <DialogContent>
        <DetailsContent
          CompleteObjectInstance={CompleteObjectInstance}
          open={open}
          entity={entity}
          setOpen={setOpen}
          setEntity={setEntity}
        />
      </DialogContent>
    </CustomDialog>
  );
};

export default DetailsDialog;

const DetailsContent = ({
  CompleteObjectInstance,
  open,
  setOpen,
  entity,
  setEntity
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  open: { open: boolean; FieldDefinition: FieldDefinition };
  setOpen: Dispatch<
    SetStateAction<{ open: boolean; FieldDefinition: FieldDefinition }>
  >;
  entity: {
    UserInstanceSummary: ICompleteUserInstanceDetail | undefined;
    CompleteObjectDefinition: CompleteObjectDefinition | undefined;
    UserInstance: UserInstance | undefined;
  };
  setEntity: Dispatch<
    SetStateAction<{
      UserInstanceSummary: ICompleteUserInstanceDetail | undefined;
      CompleteObjectDefinition: CompleteObjectDefinition | undefined;
      UserInstance: UserInstance | undefined;
    }>
  >;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const [guarantor, setGuarantor] = React.useState<boolean>(false);
  const [guarantorDetails, setGuarantorDetails] = React.useState<{
    ObjectInstance: CompleteObjectInstance | undefined;
    CompleteObjectDefinition: CompleteObjectDefinition;
    UserInstance: UserInstance;
    UserInstanceSummary: ICompleteUserInstanceDetail | undefined;
  }>({
    ObjectInstance: undefined,
    CompleteObjectDefinition: {} as CompleteObjectDefinition,
    UserInstance: {} as UserInstance,
    UserInstanceSummary: undefined
  });

  const [entityType, setEntityType] = React.useState<string | undefined>(
    undefined
  );

  const handleINIT_Guarantor = () => {
    setGuarantorDetails({
      ...guarantorDetails,
      ObjectInstance: undefined,
      CompleteObjectDefinition: {} as CompleteObjectDefinition,
      UserInstance: {} as UserInstance,
      UserInstanceSummary: undefined
    });
  };
  const handleClose = () => {
    handleINIT_Guarantor();

    setEntity({
      ...entity,
      UserInstanceSummary: undefined,
      UserInstance: undefined,
      CompleteObjectDefinition: undefined
    });

    setOpen({
      ...open,
      open: false,
      FieldDefinition: {} as FieldDefinition
    });

    setGuarantor(false);
  };

  const handleRefresh = async () => {
    await getGuarantorDetails();
  };

  const handleContact = async ({
    UserInstanceSummary
  }: {
    UserInstanceSummary: ICompleteUserInstanceDetail;
  }) => {
    const UserInstance = UserInstanceSummary.UserInstance;
    const CompleteObjectInstanceList =
      UserInstanceSummary.CompleteObjectInstanceList;

    const Director = 'Director Details';
    const CompanyContact = 'Company Details';

    const isCompanyDetail: CompleteObjectInstance | undefined =
      CompleteObjectInstanceList.find(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          return CompleteObjectInstance.ObjectInstance.Title === CompanyContact;
        }
      );

    const UserDefinition = await GetUserDefinitions({
      UserDefinitionId: globalIds.userDefinitionIds.contactUDID
    });

    let FilteredObjectInstance: CompleteObjectInstance | undefined;
    if (isCompanyDetail) {
      FilteredObjectInstance = CompleteObjectInstanceList.find(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          return CompleteObjectInstance.ObjectInstance.Title === CompanyContact;
        }
      ) as CompleteObjectInstance | undefined;
    } else {
      FilteredObjectInstance = CompleteObjectInstanceList.find(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          return CompleteObjectInstance.ObjectInstance.Title === Director;
        }
      ) as CompleteObjectInstance | undefined;
    }

    const ObjectDefinition: UserDefinition = UserDefinition.data;
    if (ObjectDefinition) {
      const FilteredObjectDefinition =
        ObjectDefinition &&
        (ObjectDefinition?.CompleteObjectDefinitionList?.find(
          (CompleteObjectDefinition: CompleteObjectDefinition) => {
            return CompleteObjectDefinition.ObjectDefinition.Title === Director;
          }
        ) as CompleteObjectDefinition | undefined);

      if (FilteredObjectDefinition) {
        setGuarantorDetails({
          ...guarantorDetails,
          ObjectInstance: FilteredObjectInstance,
          CompleteObjectDefinition: FilteredObjectDefinition,
          UserInstance: UserInstance
        });
      }
    }
  };

  const handleSupplier = async ({
    UserInstanceSummary
  }: {
    UserInstanceSummary: ICompleteUserInstanceDetail;
  }) => {
    const UserInstance = UserInstanceSummary.UserInstance;
    const CompleteObjectInstanceList =
      UserInstanceSummary.CompleteObjectInstanceList;

    const CompanyName = 'Company name';

    const UserDefinition = await GetUserDefinitions({
      UserDefinitionId: globalIds.userDefinitionIds.suppliersUDID
    });

    const FilteredObjectInstance: CompleteObjectInstance | undefined =
      CompleteObjectInstanceList.find(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          return CompleteObjectInstance.ObjectInstance.Title === CompanyName;
        }
      ) as CompleteObjectInstance | undefined;

    const ObjectDefinition: UserDefinition = UserDefinition.data;
    if (ObjectDefinition && FilteredObjectInstance) {
      const FilteredObjectDefinition =
        ObjectDefinition &&
        (ObjectDefinition?.CompleteObjectDefinitionList?.find(
          (CompleteObjectDefinition: CompleteObjectDefinition) => {
            return (
              CompleteObjectDefinition.ObjectDefinition.Title === CompanyName
            );
          }
        ) as CompleteObjectDefinition | undefined);

      if (FilteredObjectDefinition) {
        setGuarantorDetails({
          ...guarantorDetails,
          ObjectInstance: FilteredObjectInstance,
          CompleteObjectDefinition: FilteredObjectDefinition,
          UserInstance: UserInstance,
          UserInstanceSummary: UserInstanceSummary
        });
      }
    }
  };

  const handleCompanyDetails = async ({
    UserInstanceSummary,
    type
  }: {
    UserInstanceSummary: ICompleteUserInstanceDetail;
    type: number;
  }) => {
    const UserInstance = UserInstanceSummary.UserInstance;
    const CompleteObjectInstanceList =
      UserInstanceSummary.CompleteObjectInstanceList;

    const CompanyDetails = 'Company Details';
    const CompanyDetailsEdge = 'Company Details:';

    const UserDefinitions = await GetUserDefinitions({
      UserDefinitionId: type
    });

    const isEdgeCase = CompleteObjectInstanceList.find(
      (CompleteObjectInstance: CompleteObjectInstance) => {
        return (
          CompleteObjectInstance.ObjectInstance.Title === CompanyDetailsEdge
        );
      }
    );

    let FilteredCompleteObjectInstanceList: CompleteObjectInstance | undefined;
    if (isEdgeCase) {
      FilteredCompleteObjectInstanceList = CompleteObjectInstanceList.find(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          return (
            CompleteObjectInstance.ObjectInstance.Title === CompanyDetailsEdge
          );
        }
      ) as CompleteObjectInstance | undefined;
    } else {
      FilteredCompleteObjectInstanceList = CompleteObjectInstanceList.find(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          return CompleteObjectInstance.ObjectInstance.Title === CompanyDetails;
        }
      ) as CompleteObjectInstance | undefined;
    }

    const ObjectDefinition: any = UserDefinitions.data;
    const FilteredObjectDefinition = Object.values(
      ObjectDefinition.CompleteObjectDefinitionList
    ).find((CompleteObjectDefinition: any) => {
      return (
        globalIds.objectDefinitionIds.viewer.includes(
          CompleteObjectDefinition.ObjectDefinition.Id
        ) &&
        CompleteObjectDefinition.ObjectDefinition.Id ===
          globalIds.objectDefinitionIds.viewer[2]
      );
    }) as CompleteObjectDefinition;

    setGuarantorDetails({
      ...guarantorDetails,
      ObjectInstance: FilteredCompleteObjectInstanceList,
      CompleteObjectDefinition: FilteredObjectDefinition,
      UserInstance: UserInstance,
      UserInstanceSummary: UserInstanceSummary
    });
  };

  const handleCustomer = async ({
    UserInstanceSummary
  }: {
    UserInstanceSummary: ICompleteUserInstanceDetail;
  }) => {
    const UserInstance = UserInstanceSummary.UserInstance;
    const UserDefinition = await GetUserDefinitions({
      UserDefinitionId: globalIds.userDefinitionIds.customerUDID
    });

    const COIL: CompleteObjectInstance[] | undefined =
      UserInstanceSummary.CompleteObjectInstanceList.filter(
        (CompleteObjectInstance: CompleteObjectInstance) =>
          CompleteObjectInstance.ObjectInstance.Title === 'Entity'
      );

    if (COIL && COIL?.length >= 1) {
      const CODL = UserDefinition.data.CompleteObjectDefinitionList;
      const newEntityType = getFieldInstanceValue({
        CODL: CODL,
        COIL: UserInstanceSummary.CompleteObjectInstanceList,
        DefinitionTitle: 'Entity',
        FieldTitle: 'Entity Type'
      });

      setEntityType(newEntityType);
      const isRegulatedParty = Regulated.includes(newEntityType as string);

      const filteredCODL = CODL.filter(
        (CompleteObjectDefinition: CompleteObjectDefinition) =>
          globalIds.objectDefinitionIds.viewer.includes(
            CompleteObjectDefinition.ObjectDefinition.Id
          )
      );

      if (!isRegulatedParty) {
        const CompleteObjectInstanceList: CompleteObjectInstance | undefined =
          UserInstanceSummary.CompleteObjectInstanceList.find(
            (CompleteObjectInstance: CompleteObjectInstance) =>
              CompleteObjectInstance.ObjectInstance.Title === 'Company Details'
          );

        const CompleteObjectDefinition: CompleteObjectDefinition =
          filteredCODL.find(
            (CompleteObjectDefinition: CompleteObjectDefinition) => {
              return (
                CompleteObjectDefinition.ObjectDefinition.Id ===
                globalIds.objectDefinitionIds.viewer[1]
              );
            }
          );

        setGuarantorDetails({
          ...guarantorDetails,
          ObjectInstance: CompleteObjectInstanceList
            ? CompleteObjectInstanceList
            : undefined,
          CompleteObjectDefinition: CompleteObjectDefinition,
          UserInstance: UserInstance,
          UserInstanceSummary: UserInstanceSummary
        });
      }

      if (isRegulatedParty) {
        const CompleteObjectInstanceList: CompleteObjectInstance | undefined =
          UserInstanceSummary.CompleteObjectInstanceList?.find(
            (CompleteObjectInstance: CompleteObjectInstance) =>
              CompleteObjectInstance.ObjectInstance.Title === 'Details'
          );

        const CompleteObjectDefinition: CompleteObjectDefinition =
          filteredCODL.find(
            (CompleteObjectDefinition: CompleteObjectDefinition) => {
              return (
                CompleteObjectDefinition.ObjectDefinition.Id ===
                globalIds.objectDefinitionIds.viewer[0]
              );
            }
          );

        setGuarantorDetails({
          ...guarantorDetails,
          ObjectInstance: CompleteObjectInstanceList
            ? CompleteObjectInstanceList
            : undefined,
          CompleteObjectDefinition: CompleteObjectDefinition,
          UserInstance: UserInstance,
          UserInstanceSummary: UserInstanceSummary
        });
      }
    } else {
      const EntityId = 3464;
      const UserObjectDefinition: CompleteObjectDefinition[] =
        UserDefinition.data.CompleteObjectDefinitionList;

      const Entity_CompleteObjectDefinition = UserObjectDefinition.find(
        (CompleteObjectDefinition: CompleteObjectDefinition) =>
          CompleteObjectDefinition.ObjectDefinition.Id === EntityId
      );

      setEntity({
        ...entity,
        UserInstanceSummary: UserInstanceSummary,
        CompleteObjectDefinition: Entity_CompleteObjectDefinition,
        UserInstance: UserInstance
      });
    }
  };

  const getGuarantorDetails = async () => {
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

    const ContactUserInstance = FieldInstanceList.find(
      (FieldInstance: FieldInstance) => {
        return open?.FieldDefinition?.Id === FieldInstance.FieldDefinitionId;
      }
    );

    if (ContactUserInstance) {
      setLoading(true);
      let UserInstanceSummary: ICompleteUserInstanceDetail =
        {} as ICompleteUserInstanceDetail;

      if (entity.UserInstanceSummary) {
        UserInstanceSummary = entity.UserInstanceSummary;
      } else {
        try {
          const UserInstanceId = parseInt(ContactUserInstance.FieldValue);
          let CompleteUserInstanceDetail = await getCachedUserInstanceDetail(
            UserInstanceId,
            baseUrl
          );

          if (!CompleteUserInstanceDetail) {
            CompleteUserInstanceDetail = (await getCompleteUserInstanceDetail({
              baseUrl,
              UserInstanceId,
              action: 'CompleteUserInstanceDetail'
            })) as ICompleteUserInstanceDetail;
          }

          UserInstanceSummary = CompleteUserInstanceDetail;
        } catch (e) {
          BugTracker.notify(e);
        }
      }

      if (UserInstanceSummary) {
        const UserInstance = UserInstanceSummary.UserInstance;

        // IDs
        const ContactId = globalIds.userDefinitionIds.contactUDID;
        const LenderId = globalIds.userDefinitionIds.lenderUDID;
        const SupplierId = globalIds.userDefinitionIds.suppliersUDID;
        const IntroducersId = globalIds.userDefinitionIds.introducersUDID;

        switch (UserInstance.UserDefinitionId) {
          case ContactId:
            await handleContact({ UserInstanceSummary });
            break;
          case LenderId:
            await handleCompanyDetails({ UserInstanceSummary, type: LenderId });
            break;
          case SupplierId:
            await handleSupplier({ UserInstanceSummary });
            break;
          case IntroducersId:
            await handleCompanyDetails({
              UserInstanceSummary,
              type: IntroducersId
            });
            break;
          default:
            await handleCustomer({ UserInstanceSummary });
            break;
        }
        setLoading(false);
      } else {
        setError(true);
      }
    }
  };

  React.useEffect(() => {
    if (open.open) getGuarantorDetails();
  }, [open]);

  React.useEffect(() => {
    handleINIT_Guarantor();
  }, [CompleteObjectInstance]);

  React.useEffect(() => {
    if (guarantor && entity.CompleteObjectDefinition) {
      handleClose();
    }
  }, [guarantor]);

  if (error) {
    return (
      <Alert severity="error" style={{ width: '100%' }}>
        <Typography
          style={{ fontWeight: 'bold' }}>{`Error Encountered:`}</Typography>
        <Typography>
          {`Oops! We Couldn't Find The User Details With The Provided ID. Please Double-check The User Or Contact Our Support Team For Assistance.`}
        </Typography>
      </Alert>
    );
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%'
        }}>
        <CircularProgress />
      </div>
    );
  }

  if (entity.CompleteObjectDefinition) {
    return (
      <>
        <Alert severity="info" style={{ width: '100%' }}>
          <Typography style={{ fontWeight: 'bold' }}>
            Important Information:
          </Typography>
          <Typography>
            {`Please Select An Entity Type For '${entity.UserInstance?.Title}'. This Step Is Needed As The Current Record Doesn't Specify One. After Selecting, This Dialog Will Close. When You Reopen It, All The Relevant Data Will Be Displayed.`}
          </Typography>
        </Alert>
        <div style={{ paddingBottom: theme.spacing(1) }} />
        {Object.values(
          entity.CompleteObjectDefinition?.FieldDefinitionList || {}
        ).map((FieldDefinition: FieldDefinition, idx: number) => {
          const { Title, Id, FieldType } = FieldDefinition;

          if (!entity.CompleteObjectDefinition) return <div />;
          const FieldDefinitionList =
            entity.CompleteObjectDefinition.FieldDefinitionList;

          const ObjectDefinition =
            entity.CompleteObjectDefinition.ObjectDefinition;
          return (
            <Grid key={Id} className={classes.item}>
              <Grid item xs={2}>
                <ListItem>
                  <Typography color="textSecondary" variant="caption">
                    {FieldDefinition.Title}
                  </Typography>
                </ListItem>
              </Grid>
              <Grid item xs={10}>
                <div className={classes.fieldContainer}>
                  <InlineTextField
                    readOnly={false}
                    type={FieldType}
                    FieldDefinition={FieldDefinition}
                    FieldInstance={undefined}
                    ObjectDefinition={ObjectDefinition}
                    ObjectInstance={undefined}
                    UserInstance={entity.UserInstance}
                    fields={FieldDefinitionList || []}
                    value={''}
                    ProcessInstance={{ Id: 0 }}
                    ProcessStepSelectionType={''}
                    parentsIsFormComponent={false}
                    setGuarantor={setGuarantor}
                  />
                </div>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }

  const regulated = entityType && Regulated.includes(entityType);
  return (
    <>
      {entityType ? (
        <div className={classes.details}>
          <Typography variant="h5" display="block" color="primary">
            {guarantorDetails.UserInstance.Title}{' '}
            {regulated ? 'REGULATED' : 'NON-REGULATED'} PARTY
          </Typography>
        </div>
      ) : (
        <div className={classes.details}>
          <Typography variant="h5" display="block" color="primary">
            User: {guarantorDetails.UserInstance.Title}
          </Typography>
        </div>
      )}
      <Paper className={classes.paper}>
        <List component="nav" dense>
          {guarantorDetails.CompleteObjectDefinition && (
            <div>
              {Object.values(
                guarantorDetails.CompleteObjectDefinition
                  ?.FieldDefinitionList || []
              ).map((FieldDefinition: FieldDefinition) => {
                const { Title, Id, FieldType } = FieldDefinition;
                const isReadOnly: boolean = ReadOnlyRedflag({
                  ObjectDefinition:
                    guarantorDetails.CompleteObjectDefinition.ObjectDefinition,
                  FieldDefinition
                });

                const { value, FieldInstance } = getCachedFieldInstanceValue({
                  Id,
                  guarantorDetails
                });

                let valueToDisplay = value;
                let fieldInstance = FieldInstance;

                if (!guarantorDetails.CompleteObjectDefinition)
                  return <div key={FieldDefinition.Id} />;

                return (
                  <Grid key={FieldDefinition.Id} className={classes.item}>
                    <Grid item xs={2}>
                      <ListItem>
                        <Typography color="textSecondary" variant="caption">
                          {FieldDefinition.Title}
                        </Typography>
                      </ListItem>
                    </Grid>
                    <Grid item xs={10}>
                      <div className={classes.fieldContainer}>
                        <InlineTextField
                          readOnly={isReadOnly}
                          type={FieldType}
                          FieldDefinition={FieldDefinition}
                          FieldInstance={fieldInstance}
                          ObjectDefinition={
                            guarantorDetails.CompleteObjectDefinition
                              .ObjectDefinition
                          }
                          ObjectInstance={
                            guarantorDetails.ObjectInstance &&
                            guarantorDetails.ObjectInstance.ObjectInstance
                          }
                          UserInstance={guarantorDetails.UserInstance}
                          fields={
                            (guarantorDetails &&
                              guarantorDetails.CompleteObjectDefinition &&
                              guarantorDetails.CompleteObjectDefinition
                                .FieldDefinitionList) ||
                            []
                          }
                          value={valueToDisplay}
                          ProcessInstance={{ Id: 0 }}
                          ProcessStepSelectionType={''}
                          parentsIsFormComponent={false}
                          refresh={async () => {
                            await handleRefresh();
                          }}
                          isDetails
                        />
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          )}
        </List>
      </Paper>
    </>
  );
};

const getCachedFieldInstanceValue = ({
  Id,
  guarantorDetails
}: {
  Id: number;
  guarantorDetails: {
    ObjectInstance: CompleteObjectInstance | undefined;
    CompleteObjectDefinition: CompleteObjectDefinition;
    UserInstance: UserInstance;
    UserInstanceSummary: ICompleteUserInstanceDetail | undefined;
  };
}): { value: string; FieldInstance: FieldInstance } => {
  const baseUrl = store.getState().config.baseURL;
  let value;
  let FieldInstance;

  const cachedUserInstanceDetail = getCachedUserInstanceDetail(
    guarantorDetails.UserInstance.Id,
    baseUrl
  );

  if (
    cachedUserInstanceDetail &&
    cachedUserInstanceDetail.CompleteObjectInstanceList.length > 0
  ) {
    const objectInstance =
      cachedUserInstanceDetail.CompleteObjectInstanceList.find(
        (item) =>
          item.ObjectInstance.ObjectDefinitionId ===
          guarantorDetails.ObjectInstance?.ObjectInstance.ObjectDefinitionId
      );

    if (objectInstance) {
      const cachedFieldInstance: FieldInstance | undefined =
        objectInstance.FieldInstanceList.find(
          (item) => item.FieldDefinitionId === Id
        );

      if (cachedFieldInstance) {
        FieldInstance = cachedFieldInstance;
        value = cachedFieldInstance.FieldValue;
      }
    }
  } else if (
    guarantorDetails &&
    guarantorDetails.ObjectInstance &&
    Object.values(guarantorDetails.ObjectInstance).length >= 1
  ) {
    const guarantorFieldInstance: FieldInstance | undefined =
      guarantorDetails.ObjectInstance.FieldInstanceList.find(
        (FieldInstance: FieldInstance) => FieldInstance.FieldDefinitionId === Id
      );

    if (guarantorFieldInstance) {
      FieldInstance = guarantorFieldInstance;
      value = guarantorFieldInstance.FieldValue;
    }
  } else {
    value = '';
  }

  return { value, FieldInstance };
};
