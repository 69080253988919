/**extreme caution components */
import { useState } from 'react';
import { ExtremeCautionAlert } from './ExtremeCautionAlert';
import { DoNotDealHistory } from './DoNotDealHistory';
import { useTypedSelector } from 'redux/reducers';
import { ExtremeCautionAlertContact } from './ExtremeCautionAlert/ExtremeCautionAlertContact';

interface IProps {
  name: string;
  company_number?: string;
  email?: string;
  UserDefinitionId?: number;
  type: 'company' | 'contact';
  showOnly?: 'all' | 'history';
}

const DoNotDeal = ({
  name,
  company_number,
  email,
  UserDefinitionId,
  type,
  showOnly = 'all'
}: IProps) => {
  const [historyAdded, setHistoryAdded] = useState<boolean>(false);
  const { user } = useTypedSelector((s) => s.user);
  const identifier = type === 'company' ? company_number : email;

  if (user.SystemAccess >= 10 && identifier) {
    return (
      <>
        {type === 'company' ? (
          <>
            {showOnly === 'all' && (
              <>
                <ExtremeCautionAlert
                  name={name}
                  company_number={identifier}
                  UserDefinitionId={UserDefinitionId}
                  setHistoryAdded={setHistoryAdded}
                />
                &nbsp;
              </>
            )}
            <DoNotDealHistory
              name={name}
              company_number={identifier}
              UserDefinitionId={UserDefinitionId}
              historyAdded={historyAdded}
              type="company"
            />
          </>
        ) : (
          <>
            {showOnly === 'all' && (
              <>
                <ExtremeCautionAlertContact
                  name={name}
                  email={identifier}
                  UserDefinitionId={UserDefinitionId}
                  setHistoryAdded={setHistoryAdded}
                />
                &nbsp;
              </>
            )}
            <DoNotDealHistory
              name={name}
              email={identifier}
              UserDefinitionId={UserDefinitionId}
              historyAdded={historyAdded}
              type="contact"
            />
          </>
        )}
      </>
    );
  } else {
    return <div />;
  }
};

export default DoNotDeal;
