import { gql } from '@apollo/client';

export const GET_USER_DEFINITION = gql`
  query GetUserDefinitions($baseUrl: String, $userDefinitionId: Int) {
    GetUserDefinitions(baseURL: $baseUrl, UserDefinitionId: $userDefinitionId) {
      UserDefinition {
        Id
        Title
        ItemOrder
        IsPublished
        Description
        SystemAccess
        SummaryFields
        HasDetails
        HasProcesses
        HasAuditLog
        HasFCA
        HasEmailLog
        HasRedFlag
        HasRelationships
        HasOSTicket
        HasSuiteCRM
        ShowInNav
        IsCrossPlatform
      }
      CompleteObjectDefinitionList {
        ObjectDefinition {
          Id
          Title
          ItemOrder
          UserDefinitionId
          ObjectDefinitionId
          ObjectDescription
          ObjectRepeat
          ObjectProcessIndependent
        }
        FieldDefinitionList {
          Id
          Title
          ItemOrder
          ObjectDefinitionId
          FieldDescription
          FieldType
          FieldOptions
          FieldRequired
        }
      }
    }
  }
`;
