import { Dashboard as DashboardLayout } from 'layouts';
import MessageHub from 'components/MessageHub';

const Component = () => {
  return (
    <DashboardLayout title="Message Hub" padding="noPadding">
      <MessageHub />
    </DashboardLayout>
  );
};

export default Component;
