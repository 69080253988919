import firebase from 'firebase';
import { useProcess } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SET_RULE_SCHEMA } from 'redux/actions/types';
import { useTypedSelector } from 'redux/reducers';
import {
  CompleteObjectDefinition,
  CompleteObjectDefinitionDict,
  CompleteObjectInstance,
  CompleteObjectInstanceDict,
  CompleteProcessDefinition,
  CompleteProcessInstance,
  FieldDefinition,
  FieldInstance,
  ProcessStepInstance,
  ProcessSummary,
  Rule
} from 'types/interfaces';
import { errorNotif } from 'components/Notifications';
import { createNotification } from 'react-redux-notify';
import {
  ICustomTreeData,
  ICustomTreeDataChildren,
  ISafeguardAction
} from 'views/Admin/components/RuleSchema';
import { IFirestoreAuditLog } from './useAuditLog';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { IStatus } from 'views/Admin/components/RuleSchema/interface';
import {
  getFieldInstanceId,
  getHideShowId,
  getNestedAction,
  getNestedId,
  getNestedProperty,
  getNestedSelection
} from 'views/Admin/components/RuleSchema/functions';
import {
  isObjectDefinitionIds,
  isSingleObjectDefinitionId
} from 'views/Admin/components/RuleSchema/Tree';
import { store } from 'redux/store';

export interface IDisabled {
  success: boolean;
  overallStatus: IStatus;
  incompleteSafeguards?:
    | {
        id: string;
        action: string;
        status: IStatus;
      }[]
    | undefined;
  regulated?: boolean;
  nonRegulated?: boolean;
}

export interface IDisabledLite {
  success: boolean;
  status: IStatus;
}

/**
 * Rule Schema for managing and evaluating "Rule Schemas" within a Deal.
 * It fetches Rule Schemas, evaluates Rule Requirements, and updates the component state accordingly.
 *
 * @param {{ _reqfieldsnum: FieldInstance[] } | undefined} ReqCmp - NEEDED FOR "getRuleRequirements", required for compliance checks.
 * @returns {Object} Returns an object containing the `getRuleRequirements` function.
 */

export const useRuleSchema = (ReqCmp?: { _reqfieldsnum: FieldInstance[] }) => {
  const dispatch = useDispatch();
  const { globalHostName } = useTypedSelector((s) => s.config.hostname);

  const { currentOverView, regulatedStatus, currentDeal } = useTypedSelector(
    (s) => s.process
  );

  const ProcessStepDefinitionId = currentOverView.ProcessStepDefinitionId;
  const { user, currentProcess } = useProcess();

  const [treeData, setTreeData] = useState<ICustomTreeData>(
    {} as ICustomTreeData
  );

  useEffect(() => {
    const schemaDocRef = firebase
      .firestore()
      .collection('globalSetting')
      .doc('ruleSchema');

    const hostname = globalHostName;
    const unsubscribe = schemaDocRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data();
        if (data) {
          const mode = data.mode;
          let schema;

          //! Only Changes Local for this Instance for Testing
          if (mode === 'Local' && hostname in data) {
            schema = data[hostname]?.ruleSchema;
          } else {
            schema = data.ruleSchema;
          }

          if (schema) {
            const processStepId = currentProcess.ProcessDefinition.Id;
            const getRulesFromDealType = schema.find(
              (schemaItem) => parseInt(schemaItem.id) === processStepId
            );

            if (getRulesFromDealType) {
              setTreeData(getRulesFromDealType);
              dispatch({
                type: 'SET_RULE_SCHEMA',
                payload: getRulesFromDealType
              });
            } else {
              console.error('No Matching Rules Found For The Process Step ID.');
            }
          } else {
            console.error('Schema not found for the current mode or hostname.');
          }
        }
      } else {
        if (user.SystemAccess > 5) {
          dispatch(
            createNotification(
              errorNotif(
                'Rule Schema Not Found. Please Contact Development Support.'
              )
            )
          );
        }
      }
    });

    return () => unsubscribe();
  }, []);

  /**
   * Processes the given requirements promises and actions to determine which rules are disabled.
   *
   * @param {Promise<(IDisabled | { success: boolean })>[]} requirementsPromises - An array of promises that resolve to either IDisabled objects or objects with a success boolean.
   * @param {Rule[]} actions - An array of rules to be evaluated.
   * @returns {Promise<{ [key: string]: IDisabled }>} - A promise that resolves to an object mapping action IDs to their corresponding IDisabled objects.
   */

  const getAllDisabledRules = async ({
    requirementsPromises,
    actions
  }: {
    requirementsPromises: Promise<
      | IDisabled
      | {
          success: boolean;
        }
    >[];
    actions: Rule[];
  }): Promise<{ [key: string]: IDisabled }> => {
    const requirementsResults = await Promise.all(requirementsPromises);
    const newDisabledActions = actions.reduce((acc, action, index) => {
      const requirements = requirementsResults[index];

      if (
        'incompleteSafeguards' in requirements &&
        requirements.incompleteSafeguards
      ) {
        const relevantSafeguards = requirements?.incompleteSafeguards.filter(
          (safeguard: { id: string; action: string; status: IStatus }) => {
            if (regulatedStatus) {
              return !(
                safeguard.status.isNon_Regulated_Field ||
                safeguard.status.isNon_Regulated_Only_Required
              );
            } else {
              return !(
                safeguard.status.isRegulated_Field ||
                safeguard.status.isRegulated_Only_Required
              );
            }
          }
        );

        if (relevantSafeguards.length === 0) {
          requirements.incompleteSafeguards = undefined;
          requirements.success = true;
        } else {
          requirements.incompleteSafeguards = relevantSafeguards;
          requirements.success = false;
        }
      }

      acc[action.Id] = requirements;
      return acc;
    }, {});

    return newDisabledActions;
  };

  /**
   * Checks if a rule is regulated or non-regulated.
   *
   * @param {Rule} rule - The rule to be evaluated.
   * @returns {Promise<IDisabledLite>} Returns a promise that resolves to an IDisabled object containing:
   *   - `success`: Boolean indicating if the rule's requirements are met.
   *   - `status`: Object that contains boolean values.
   */

  const getLiteRuleRequirements = async (
    rule: Rule
  ): Promise<IDisabledLite> => {
    const findRuleInTree: ICustomTreeDataChildren | undefined =
      treeData.children?.find((childRule: ICustomTreeDataChildren) => {
        return childRule.ruleId?.toString() === rule.Id?.toString();
      });

    if (!findRuleInTree) return { success: true, status: {} as IStatus };

    const isRegulated = findRuleInTree.safeGuards.some(
      (sg) => sg.status.isRegulated_Only_Required || sg.status.isRegulated
    );

    const isNonRegulated = findRuleInTree.safeGuards.some(
      (sg) =>
        sg.status.isNonRegulated || sg.status.isNon_Regulated_Only_Required
    );

    const result: IDisabledLite = {
      success: false,

      status: {
        isRegulated_Only_Required: findRuleInTree.safeGuards.some(
          (sg) => sg.status.isRegulated_Only_Required
        ),
        isNon_Regulated_Only_Required: findRuleInTree.safeGuards.some(
          (sg) => sg.status.isNon_Regulated_Only_Required
        ),
        isRegulated,
        isNonRegulated: isNonRegulated,
        isGDPR_Required: false,
        isGDPR_Only_Required: false,
        isNon_Regulated_Field: false,
        isRegulated_Field: false,
        isField_Instance: false,
        isHideShow: false,
        isObjectDefinitionRegAndNonRegulated: false
      }
    };

    if (isRegulated && regulatedStatus) {
      result.success = true;
    } else if (isNonRegulated && !regulatedStatus) {
      result.success = true;
    }

    return result;
  };

  /**
   * @param {Rule} rule - The rule to be evaluated.
   * @param {IFirestoreAuditLog[]} auditLogs - Audit logs relevant to the rule's evaluation.
   * @returns {Promise<Object>} Returns a promise that resolves to an object containing:
   *   - `success`: Boolean indicating if the rule's requirements are met.
   *   - `incompleteSafeguards`: Array of objects detailing incomplete safeguards, if any.
   *   - `status`: Object that contains boolean values for the following:
   *      - `isGDPR_Required`: Boolean indicating if GDPR compliance is required for the rule.
   *      - `isNon_Regulated_Only_Required`: Boolean indicating if Non-Regulated compliance is required for the rule.
   *      - `isRegulated_Only_Required`: Boolean indicating if Regulated compliance is required for the rule.
   *      - `isGDPR_Only_Required`: Boolean indicating if GDPR compliance is the only requirement for the rule.
   */

  const getRuleRequirements = async (
    rule: Rule,
    auditLogs: IFirestoreAuditLog[]
  ): Promise<IDisabled | { success: boolean }> => {
    const findRuleInTree: ICustomTreeDataChildren | undefined =
      treeData.children?.find((childRule: ICustomTreeDataChildren) => {
        return childRule.ruleId?.toString() === rule.Id?.toString();
      });

    if (!findRuleInTree) return { success: true };

    //* Step 1: Identify the safeguards with isGDPR_Required and collect their GDPR step IDs
    const gdprRequiredSafeguards = findRuleInTree.safeGuards
      .filter(
        (sg: ISafeguardAction) =>
          sg.status.isGDPR_Required || sg.status.isGDPR_Only_Required
      )
      .map((sg: ISafeguardAction) => sg.id.gdprStepId);

    let allGDPRsCompleted = false;

    //* Step 2: Match these GDPR step IDs against the ProcessStepInstanceList in currentDeal and retrieve their statuses
    if (gdprRequiredSafeguards) {
      const completedGDPRs = currentDeal.ProcessStepInstanceList.filter(
        (step: ProcessStepInstance) =>
          gdprRequiredSafeguards.includes(step.ProcessStepDefinitionId)
      ).map((step: ProcessStepInstance) => ({
        id: step.UserInstanceId,
        status: step.Status
      }));

      //* Step 3: Additional check in CompleteObjectInstanceDict for users related to ObjectDefinitionId 3388 (Id Works for both Staging & Development)
      const gdprUsers: number[] | undefined = Object.values(
        currentDeal.CompleteObjectInstanceDict
      )
        .filter(
          (CompleteObjectInstance: CompleteObjectInstance) =>
            CompleteObjectInstance.ObjectInstance.ObjectDefinitionId === 3388
        )
        .map((CompleteObjectInstance: CompleteObjectInstance) => {
          const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
          return parseInt(FieldInstanceList[0].FieldValue.toString());
        });

      let incompleteGDPRs;
      if (gdprUsers) {
        incompleteGDPRs = gdprUsers.filter(
          (FieldValue: number) =>
            !completedGDPRs.some(
              (gdpr: { id: number; status: number }) =>
                gdpr.id === FieldValue && gdpr.status === 2
            )
        );
      }

      //* Step 4: Check if all completed GDPRs have a status of 2 and if there are no incomplete GDPRs
      allGDPRsCompleted =
        completedGDPRs.every((gdpr) => gdpr.status === 2) &&
        incompleteGDPRs.length === 0;
    }

    //? This is how we are handling the skipping conditions if are ONLYs
    const hasSkippingCondition = findRuleInTree.safeGuards.some(
      (sg) =>
        sg.status.isGDPR_Only_Required ||
        sg.status.isNon_Regulated_Only_Required ||
        sg.status.isRegulated_Only_Required
    );

    if (hasSkippingCondition) {
      const isGDPRCompliant: boolean = findRuleInTree.safeGuards.some(
        (sg) => sg.status.isGDPR_Only_Required && allGDPRsCompleted
      );
      const isRegulatedRequired: boolean = findRuleInTree.safeGuards.some(
        (sg) => sg.status.isRegulated_Only_Required
      );
      const isNonRegulatedRequired: boolean = findRuleInTree.safeGuards.some(
        (sg) => sg.status.isNon_Regulated_Only_Required
      );

      const isGDPR_Only_Required: boolean = findRuleInTree.safeGuards.some(
        (sg) => sg.status.isGDPR_Only_Required
      );

      let result = {
        success: false,
        regulated: false,
        nonRegulated: false,
        status: {
          isRegulated: false,
          isNonRegulated: false,
          isField_Instance: false,
          isRegulated_Field: false,
          isNon_Regulated_Field: false,
          isGDPR_Required: false,
          isHideShow: false,
          isNon_Regulated_Only_Required: isNonRegulatedRequired,
          isRegulated_Only_Required: isRegulatedRequired,
          isGDPR_Only_Required: isGDPR_Only_Required
        }
      };

      if (
        isGDPRCompliant &&
        findRuleInTree.safeGuards.some((sg) => sg.status.isGDPR_Only_Required)
      ) {
        result.success = true;
        (result.regulated = isRegulatedRequired),
          (result.nonRegulated = isNonRegulatedRequired),
          (result.status = {
            ...result.status
          });
      } else if (isRegulatedRequired && regulatedStatus) {
        result = {
          success: true,
          regulated: isRegulatedRequired,
          nonRegulated: isNonRegulatedRequired,
          status: {
            ...result.status
          }
        };
      } else if (isNonRegulatedRequired && !regulatedStatus) {
        result = {
          success: true,
          regulated: regulatedStatus,
          nonRegulated: isNonRegulatedRequired,
          status: {
            ...result.status
          }
        };
      }

      return result;
    }

    let incompleteSafeguards: {
      id: string;
      status: IStatus;
      action: string;
    }[] = [];

    let groupedSafeguards: ICustomTreeDataChildren = {
      ...findRuleInTree,
      safeGuards: findRuleInTree.safeGuards.map((sg) => ({ ...sg }))
    };

    const promises = findRuleInTree.safeGuards.map(
      async (safeGuard: ISafeguardAction, index) => {
        let isCompleted = false;

        const shouldProceed = shouldProceedWithAction({
          isGDPR: safeGuard.status.isGDPR_Required,
          isRegulatedField: safeGuard.status.isRegulated_Field,
          isNonRegulatedField: safeGuard.status.isNon_Regulated_Field,
          completedGDPRStatus: allGDPRsCompleted,
          regulatedStatus
        });

        switch (safeGuard.action) {
          // Check If ID Of Step Has Been Sent
          case 'Sent':
            isCompleted =
              shouldProceed &&
              evaluateSafeguardCompletion(
                safeGuard,
                auditLogs,
                'Sent',
                regulatedStatus
              );

            break;

          /**
           * Checks if an object has been starred based on its ObjectDefinitionId.
           * The check considers both regulated and non-regulated ObjectDefinitionIds
           * if the `isObjectDefinitionRegAndNonRegulated` flag is set.
           */
          case 'Starred': {
            if (shouldProceed) {
              const selected = hasOneSelected({
                CompleteObjectInstanceDict:
                  currentDeal.CompleteObjectInstanceDict,
                safeGuard,
                regulatedStatus
              });

              if (selected) {
                isCompleted = true;
              }
            }

            break;
          }

          // Check If ID Of Step Has Been Accepted
          case 'Accepted': {
            isCompleted =
              shouldProceed &&
              evaluateSafeguardCompletion(
                safeGuard,
                auditLogs,
                'Completed',
                regulatedStatus
              );
            break;
          }

          /**
           * Checks if the object is completed based on its ObjectDefinitionId.
           * The check considers both regulated and non-regulated ObjectDefinitionIds
           * if the `isObjectDefinitionRegAndNonRegulated` flag is set.
           */

          case 'Completed': {
            if (safeGuard.status.isField_Instance && shouldProceed) {
              /**
               ** Find the appropriate CompleteObjectInstance from the current deal's dictionary.
               *
               ** Determine the correct ObjectDefinitionId to check based on safeGuard ID and regulation status.
               ** Compare it against the ObjectDefinitionId of each complete object instance.
               */

              const getCompleteObjectInstance: CompleteObjectInstance =
                Object.values(currentDeal.CompleteObjectInstanceDict).find(
                  (completeObjectInstance: CompleteObjectInstance) => {
                    let objectDefinitionIdToCheck: number | undefined;

                    if (isObjectDefinitionIds(safeGuard.id)) {
                      objectDefinitionIdToCheck = regulatedStatus
                        ? safeGuard.id.objectDefinitionIds.objectDefinitionIdReg
                        : safeGuard.id.objectDefinitionIds
                            .objectDefinitionIdNonReg;
                    } else {
                      objectDefinitionIdToCheck =
                        safeGuard.id.objectDefinitionId;
                    }

                    return (
                      completeObjectInstance.ObjectInstance.ObjectDefinitionId?.toString() ===
                      objectDefinitionIdToCheck?.toString()
                    );
                  }
                );

              if (getCompleteObjectInstance) {
                /**
                 ** Retrieve the list of FieldInstances from the CompleteObjectInstance.
                 */
                const FieldInstanceList = getFieldInstances(
                  getCompleteObjectInstance
                );

                /**
                 ** Find the specific FieldInstance by its hide/show ID.
                 ** Use the getHideShowId function to determine the hide/show ID.
                 ** Search for a FieldInstance in the list with this ID.
                 */
                const IdType = getHideShowId(safeGuard.id.fieldInstanceId);
                const FieldInstance: FieldInstance | undefined =
                  FieldInstanceList.find((fieldInstance: FieldInstance) => {
                    return fieldInstance.FieldDefinitionId === IdType;
                  });

                if (FieldInstance) {
                  const hideShowValue = FieldInstance.FieldValue.toLowerCase();
                  /**
                   ** If the FieldValue includes 'no', mark the task as completed.
                   */
                  if (hideShowValue.includes('no')) {
                    isCompleted = true;
                  } else if (hideShowValue.includes('yes')) {
                    /**
                     ** Retrieve the nested selection value using the getNestedSelection function.
                     */
                    const nestedSelection = getNestedSelection(
                      safeGuard.id.fieldInstanceId
                    );

                    if (nestedSelection === 'Yes') {
                      /**
                       ** If nested selection is 'Yes':
                       ** - Retrieve the nested field definition ID and value name using getNestedProperty.
                       ** - Find the nested field instance with the nested field definition ID.
                       ** - If the nested field instance's value matches the nested value name:
                       **   - Retrieve the nested child ID using getNestedId.
                       **   - Check the nested action and determine if the task is completed.
                       */

                      const childFieldDefinitionId = getFieldInstanceId(
                        safeGuard.id.fieldInstanceId
                      );

                      const nestedName = getNestedProperty(
                        safeGuard.id.fieldInstanceId
                      );

                      const nestedFieldInstance: FieldInstance | undefined =
                        FieldInstanceList.find(
                          (fieldInstance: FieldInstance) => {
                            return (
                              fieldInstance.FieldDefinitionId ===
                              childFieldDefinitionId
                            );
                          }
                        );

                      if (
                        nestedFieldInstance &&
                        nestedFieldInstance.FieldValue.includes(
                          nestedName.toString()
                        )
                      ) {
                        const nestedChildId = getNestedId(
                          safeGuard.id.fieldInstanceId,
                          regulatedStatus ? 'regulated' : 'non-regulated'
                        );

                        const nestedAction = getNestedAction(
                          safeGuard.id.fieldInstanceId
                        );

                        if (
                          nestedAction === 'Sent' ||
                          nestedAction === 'Completed'
                        ) {
                          /**
                           ** If the nested action is 'Sent' or 'Completed':
                           ** - Evaluate the safeguard completion using evaluateSafeguardCompletion.
                           ** - Pass the nested child ID to evaluateSafeguardCompletion.
                           */

                          isCompleted = evaluateSafeguardCompletion(
                            safeGuard,
                            auditLogs,
                            nestedAction,
                            regulatedStatus,
                            nestedChildId
                          );
                        } else if (nestedAction === 'Starred') {
                          /**
                           ** If the nested action is 'Starred':
                           ** - Check if at least one CompleteObjectInstance is selected using hasOneSelected.
                           */
                          isCompleted = hasOneSelected({
                            CompleteObjectInstanceDict:
                              currentDeal.CompleteObjectInstanceDict,
                            safeGuard,
                            regulatedStatus,
                            idToCheck: nestedChildId
                          });
                        } else {
                          /**
                           ** Otherwise:
                           ** - Find the nested child FieldInstance using the nested child ID.
                           ** - Mark the task as completed if the nested child FieldInstance's value is not empty.
                           */
                          const NestedChildFieldInstance:
                            | FieldInstance
                            | undefined = FieldInstanceList.find(
                            (fieldInstance: FieldInstance) => {
                              return (
                                fieldInstance.FieldDefinitionId ===
                                nestedChildId
                              );
                            }
                          );

                          if (NestedChildFieldInstance) {
                            isCompleted =
                              NestedChildFieldInstance.FieldValue !== '';
                          }
                        }
                      }
                    } else if (nestedSelection === 'No') {
                      /**
                       ** If nested selection is 'No':
                       ** - Retrieve the hidden FieldInstanceId using getFieldInstanceId.
                       ** - Find the hidden FieldInstance with this ID.
                       ** - Mark the task as completed if the hidden FieldInstance's value is not empty.
                       */
                      const HiddenIdType = getFieldInstanceId(
                        safeGuard.id.fieldInstanceId
                      );
                      const FieldInstanceHidden: FieldInstance | undefined =
                        FieldInstanceList.find(
                          (fieldInstance: FieldInstance) => {
                            return (
                              fieldInstance.FieldDefinitionId === HiddenIdType
                            );
                          }
                        );

                      if (FieldInstanceHidden) {
                        isCompleted = FieldInstanceHidden.FieldValue !== '';
                      }
                    }
                  } else {
                    /**
                     ** Mark the task as completed if the FieldInstance's value is not empty.
                     */
                    isCompleted = FieldInstance.FieldValue !== '';
                  }
                }
              }
            } else {
              const objectCompleted = (() => {
                const findAllRequiredFieldsById: FieldInstance[] | undefined =
                  ReqCmp &&
                  ReqCmp._reqfieldsnum.filter(
                    (fieldInstance: FieldInstance) => {
                      let objectDefinitionIdToCheck: number | undefined;

                      if (isObjectDefinitionIds(safeGuard.id)) {
                        objectDefinitionIdToCheck = regulatedStatus
                          ? safeGuard.id.objectDefinitionIds
                              .objectDefinitionIdReg
                          : safeGuard.id.objectDefinitionIds
                              .objectDefinitionIdNonReg;
                      } else {
                        objectDefinitionIdToCheck =
                          safeGuard.id.objectDefinitionId;
                      }

                      return (
                        fieldInstance?.ObjectDefinitionId?.toString() ===
                        objectDefinitionIdToCheck?.toString()
                      );
                    }
                  );

                if (
                  findAllRequiredFieldsById &&
                  findAllRequiredFieldsById.length > 0
                ) {
                  return hasFields(findAllRequiredFieldsById);
                } else {
                  const findAtLeastOneField:
                    | CompleteObjectInstance[]
                    | undefined = Object.values(
                    currentDeal.CompleteObjectInstanceDict
                  ).filter((completeObjectInstance: CompleteObjectInstance) => {
                    let objectDefinitionIdToCheck: number | undefined;

                    if (isObjectDefinitionIds(safeGuard.id)) {
                      objectDefinitionIdToCheck = regulatedStatus
                        ? safeGuard.id.objectDefinitionIds.objectDefinitionIdReg
                        : safeGuard.id.objectDefinitionIds
                            .objectDefinitionIdNonReg;
                    } else {
                      objectDefinitionIdToCheck =
                        safeGuard.id.objectDefinitionId;
                    }

                    return (
                      completeObjectInstance.ObjectInstance
                        .ObjectDefinitionId === objectDefinitionIdToCheck
                    );
                  });

                  return findAtLeastOneField.some(
                    (completeObjectInstance: CompleteObjectInstance) => {
                      const FieldInstanceList = getFieldInstances(
                        completeObjectInstance
                      );

                      return FieldInstanceList.some(
                        (fieldInstance: FieldInstance) => {
                          return fieldInstance.FieldValue !== '';
                        }
                      );
                    }
                  );
                }
              })();

              isCompleted = shouldProceed && objectCompleted;
            }
            break;
          }
          default:
            isCompleted = shouldProceed;
            break;
        }

        if (!isCompleted) {
          let findNameFromId = '';
          switch (safeGuard.action) {
            case 'Sent': {
              findNameFromId = getStepName(
                safeGuard,
                currentProcess,
                regulatedStatus
              );
              break;
            }
            case 'Starred': {
              findNameFromId = getObjectName(
                safeGuard,
                currentProcess,
                ProcessStepDefinitionId,
                regulatedStatus
              );
              break;
            }
            case 'Accepted': {
              findNameFromId = getStepName(
                safeGuard,
                currentProcess,
                regulatedStatus
              );
              break;
            }
            case 'Completed': {
              findNameFromId = getObjectName(
                safeGuard,
                currentProcess,
                ProcessStepDefinitionId,
                regulatedStatus
              );
              break;
            }
          }

          incompleteSafeguards.push({
            id: findNameFromId,
            status: safeGuard.status,
            action: safeGuard.action
          });
        } else {
          groupedSafeguards.safeGuards[index] = {
            ...safeGuard,
            completed: true
          };
        }
      }
    );

    await Promise.all(promises);

    const allSafeguardsCompleted = groupedSafeguards.safeGuards.every(
      (sg) => sg.completed
    );

    if (allSafeguardsCompleted) {
      groupedSafeguards = {
        ...groupedSafeguards,
        allSafeguardsCompleted: true
      };
    }

    const overallStatus = calculateStatus(groupedSafeguards.safeGuards);
    const result: IDisabled = {
      success: allSafeguardsCompleted,
      overallStatus,
      incompleteSafeguards: allSafeguardsCompleted
        ? undefined
        : incompleteSafeguards,
      regulated: overallStatus.isRegulated,
      nonRegulated: overallStatus.isNonRegulated
    };

    return result;
  };

  return { getRuleRequirements, getLiteRuleRequirements, getAllDisabledRules };
};

/**
 * @param {ISafeguardAction} safeGuard - The safeguard action to retrieve the object name for.
 * @param {CompleteProcessDefinition} currentProcess - The CompleteProcessDefinition.
 * @param {number} ProcessStepDefinitionId - Id of the StepDefinitionId.
 * @returns {string} The name of the object associated with the safeguard, or an empty string if not found.
 */

const getObjectName = (
  safeGuard: ISafeguardAction,
  currentProcess: CompleteProcessDefinition,
  ProcessStepDefinitionId: number,
  regulatedStatus: boolean
): string => {
  const getDiscovery = ProcessStepDefinitionId;
  let objectDefinitionIdToCheck: number;

  if (isObjectDefinitionIds(safeGuard.id)) {
    objectDefinitionIdToCheck = regulatedStatus
      ? safeGuard.id.objectDefinitionIds.objectDefinitionIdReg
      : safeGuard.id.objectDefinitionIds.objectDefinitionIdNonReg;
  } else {
    objectDefinitionIdToCheck = safeGuard.id.objectDefinitionId;
  }

  const getDiscoveryName: CompleteObjectDefinition | undefined =
    currentProcess.CompleteProcessStepDefinitionDict?.[getDiscovery]
      ?.CompleteObjectDefinitionDict?.[objectDefinitionIdToCheck];

  if (getDiscoveryName) {
    if (safeGuard.status.isField_Instance) {
      const IdType = getHideShowId(objectDefinitionIdToCheck);
      const getFieldName = Object.values(
        getDiscoveryName.FieldDefinitionDict
      ).find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.ObjectDefinitionId === IdType
      );

      return (
        `${getFieldName?.Title} In ${getDiscoveryName?.ObjectDefinition?.Title}` ??
        ''
      );
    } else {
      return getDiscoveryName.ObjectDefinition?.Title ?? '';
    }
  }

  return '';
};

/**
 * Retrieves the step name associated with a safeguard based on its ID.
 * @param {ISafeguardAction} safeGuard - The safeguard action to retrieve the step name for.
 * @param {CompleteProcessDefinition} currentProcess - The CompleteProcessDefinition.
 * @returns {string} The step name associated with the safeguard, or an empty string if not found.
 */

const getStepName = (
  safeGuard: ISafeguardAction,
  currentProcess: CompleteProcessDefinition,
  regulatedStatus: boolean
): string => {
  let objectDefinitionIdToCheck: number | undefined;

  if (isObjectDefinitionIds(safeGuard.id)) {
    objectDefinitionIdToCheck = regulatedStatus
      ? safeGuard.id.objectDefinitionIds.objectDefinitionIdReg
      : safeGuard.id.objectDefinitionIds.objectDefinitionIdNonReg;
  } else {
    objectDefinitionIdToCheck = safeGuard.id.objectDefinitionId;
  }

  const foundAcceptedName =
    currentProcess.CompleteProcessStepDefinitionDict[objectDefinitionIdToCheck]
      ?.ProcessStepDefinition.Title;

  if (foundAcceptedName) return foundAcceptedName;
  return '';
};

/**
 * @param {FieldInstance[]} FieldInstanceList - The list of field instances to check.
 * @returns {boolean} True if all fields have non-empty values, false otherwise.
 */

const hasFields = (FieldInstanceList: FieldInstance[]): boolean => {
  const hasFields = FieldInstanceList.every((FieldInstance: FieldInstance) => {
    return FieldInstance.FieldValue !== '';
  });

  if (hasFields) return true;
  return false;
};

/**
 * @param {ISafeguardAction} safeGuard - The safeguard action to evaluate.
 * @param {IFirestoreAuditLog[]} auditLogs - The collection of audit logs to assess.
 * @param {'Sent' | 'Completed'} type - The type of completion to check for ('Sent' or 'Completed').
 * @returns {boolean} - Returns true if the latest relevant audit log matches the specified type, false otherwise.
 */
const evaluateSafeguardCompletion = (
  safeGuard: ISafeguardAction,
  auditLogs: IFirestoreAuditLog[],
  type: 'Sent' | 'Completed' | 'Accepted',
  regulatedStatus: boolean,
  idToCheck?: number
): boolean => {
  const checklistRules = store.getState().schema.checklistRules;
  const currentDeal = store.getState().process.currentDeal;

  let isSent = false;
  let lastCompletedTimestamp = -1;
  let lastSentTimestamp = -1;
  let objectDefinitionIdToCheck: number;

  const checkListId = Number(checklistRules?.checklistLegacy?.dealId ?? 0);
  const currentDealId = Number(currentDeal?.ProcessInstance?.Id ?? 0);
  if (checkListId && currentDealId && currentDealId < checkListId) return true;

  if (idToCheck) {
    objectDefinitionIdToCheck = idToCheck;
  } else {
    if (isObjectDefinitionIds(safeGuard.id)) {
      objectDefinitionIdToCheck = regulatedStatus
        ? safeGuard.id.objectDefinitionIds.objectDefinitionIdReg
        : safeGuard.id.objectDefinitionIds.objectDefinitionIdNonReg;
    } else {
      objectDefinitionIdToCheck = safeGuard.id.objectDefinitionId;
    }
  }

  auditLogs.forEach((log: IFirestoreAuditLog) => {
    if (log.payload === objectDefinitionIdToCheck.toString()) {
      if (log.firebaseStatus === 'Sent') {
        isSent = true;
        lastSentTimestamp = log.timestamp;
      } else if (log.firebaseStatus === 'Completed') {
        lastCompletedTimestamp = log.timestamp;
      } else if (log.firebaseStatus === 'Accepted') {
        lastSentTimestamp = log.timestamp;
      }
    }
  });

  if (type === 'Sent' || type === 'Accepted') {
    return isSent;
  }

  if (type === 'Completed') {
    return isSent
      ? lastCompletedTimestamp > lastSentTimestamp
      : lastCompletedTimestamp !== -1;
  }

  return false;
};

/**
 * Checks if at least one CompleteObjectInstance is selected based on the provided idToCheck.
 *
 * @param {CompleteObjectInstanceDict} CompleteObjectInstanceDict - The current deal object containing CompleteObjectInstanceDict.
 * @param {ISafeguardAction} safeGuard - The safeguard action object.
 * @param {boolean} regulatedStatus - The regulated status flag.
 * @param {number} idToCheck - The ID to check against the ObjectDefinitionId.
 * @returns {boolean} - Returns true if at least one CompleteObjectInstance is selected, otherwise undefined.
 */
const hasOneSelected = ({
  CompleteObjectInstanceDict,
  safeGuard,
  regulatedStatus,
  idToCheck
}: {
  CompleteObjectInstanceDict: CompleteObjectInstanceDict;
  safeGuard: ISafeguardAction;
  regulatedStatus: boolean;
  idToCheck?: number;
}): boolean => {
  return Object.values(CompleteObjectInstanceDict).some(
    (completeObjectInstance: CompleteObjectInstance) => {
      let objectDefinitionIdToCheck: number;

      if (idToCheck) {
        objectDefinitionIdToCheck = idToCheck;
      } else {
        if (isObjectDefinitionIds(safeGuard.id)) {
          objectDefinitionIdToCheck = regulatedStatus
            ? safeGuard.id.objectDefinitionIds.objectDefinitionIdReg
            : safeGuard.id.objectDefinitionIds.objectDefinitionIdNonReg;
        } else {
          objectDefinitionIdToCheck = safeGuard.id.objectDefinitionId;
        }
      }

      return (
        completeObjectInstance.ObjectInstance.ObjectDefinitionId?.toString() ===
          objectDefinitionIdToCheck?.toString() &&
        completeObjectInstance.ObjectInstance.Selected
      );
    }
  );
};

/**
 * Calculates and returns the status of a collection of safeguard actions.
 * Each status is determined by checking if any of the safeguards meet the specified conditions.
 *
 * @param {ISafeguardAction[]} safeGuards - An array of safeguard actions from which the status will be derived.
 * @returns {IStatus} An object representing various statuses, each indicating whether any safeguard action
 * within the provided array meets the corresponding condition.
 */
const calculateStatus = (safeGuards: ISafeguardAction[]): IStatus => {
  const statusKeys: (keyof IStatus)[] = [
    'isRegulated',
    'isField_Instance',
    'isNonRegulated',
    'isGDPR_Required',
    'isRegulated_Field',
    'isGDPR_Only_Required',
    'isNon_Regulated_Only_Required',
    'isRegulated_Only_Required'
  ];

  return statusKeys.reduce((statusAccumulator, key) => {
    statusAccumulator[key] = safeGuards.some((sg) => sg.status[key]);
    return statusAccumulator;
  }, {} as IStatus);
};

/**
 * Determines whether an action should proceed based on GDPR compliance, field regulation status,
 * and the actual statuses of GDPR and field regulation.
 *
 * @param {boolean} isGDPR - Indicates whether GDPR compliance is required for the action.
 * @param {boolean} isRegulatedField - Indicates whether the action is applicable only to regulated fields.
 * @param {boolean} isNonRegulatedField - Indicates whether the action is applicable only to non-regulated fields.
 * @param {number | undefined} completedGDPRStatus - The status code indicating the level of GDPR compliance.
 * @param {boolean} regulatedStatus - Indicates the current regulation status of the deal or process.
 * @returns {boolean} Returns true if the action should proceed based on the provided parameters, otherwise false.
 */

const shouldProceedWithAction = ({
  isGDPR,
  isRegulatedField,
  isNonRegulatedField,
  completedGDPRStatus,
  regulatedStatus
}: {
  isGDPR: boolean;
  isRegulatedField: boolean;
  isNonRegulatedField: boolean;
  completedGDPRStatus: boolean;
  regulatedStatus: boolean;
}): boolean => {
  const gdprCompliant = !isGDPR || (isGDPR && completedGDPRStatus);
  const isRegulatedFieldCompliant = isRegulatedField && regulatedStatus;
  const isNonRegulatedFieldCompliant = isNonRegulatedField && !regulatedStatus;

  return (
    gdprCompliant &&
    (isRegulatedFieldCompliant ||
      isNonRegulatedFieldCompliant ||
      (!isRegulatedField && !isNonRegulatedField))
  );
};
