import UltimateFinanceProposal from '.';
import { ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';

export const getUltimateFinanceIds = () => {
  return createTypeSafeIds(UltimateFinanceProposal, (_) => ({
    productType: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Dropdown,
        options: ['HP (Non-Reg)']
      }
    },
    businessName: {
      FieldDefinitionId: 18317,
      isReadonly: false,
      FieldDefinition: {
        type: ELenderAPIType.EntityChange,
        newIdList: [
          {
            entityType: 'Sole Trader',
            FieldDefinitionId: 21866
          },
          {
            entityType: 'Partnership',
            FieldDefinitionId: 21866
          },
          {
            entityType: 'Private Individual',
            FieldDefinitionId: 21810
          }
        ]
      }
    },
    businessType: {
      FieldDefinitionId: 18449,
      isReadonly: false,
      FieldDefinition: {
        type: ELenderAPIType.EntityChange,
        newIdList: [
          {
            entityType: 'Sole Trader',
            FieldDefinitionId: 21867
          },
          {
            entityType: 'Partnership',
            FieldDefinitionId: 21867
          }
        ]
      }
    },
    crn: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true
    },
    applicantFirstName: {
      FieldDefinitionId: 18420,
      required: true,
      isReadonly: false,
      FieldDefinition: {
        type: ELenderAPIType.EntityChange,
        newIdList: [
          {
            entityType: 'Sole Trader',
            FieldDefinitionId: 23497
          },
          {
            entityType: 'Partnership',
            FieldDefinitionId: 23977
          },
          {
            entityType: 'Private Individual',
            FieldDefinitionId: 21810
          }
        ]
      }
    },
    applicantLastName: {
      FieldDefinitionId: 18427,
      required: true,
      isReadonly: false,
      FieldDefinition: {
        type: ELenderAPIType.EntityChange,
        newIdList: [
          {
            entityType: 'Sole Trader',
            FieldDefinitionId: 23499
          },
          {
            entityType: 'Partnership',
            FieldDefinitionId: 24402
          },
          {
            entityType: 'Private Individual',
            FieldDefinitionId: 24396
          }
        ]
      }
    },
    applicantEmail: {
      FieldDefinitionId: 18452,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
        requirement: {
          maxLength: 255,
          pattern:
            '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
          message: 'Please enter a valid email address'
        },
        newIdList: [
          {
            entityType: 'Sole Trader',
            FieldDefinitionId: 23499
          },
          {
            entityType: 'Partnership',
            FieldDefinitionId: 23978
          },
          {
            entityType: 'Private Individual',
            FieldDefinitionId: 24410
          }
        ]
      }
    },

    applicantDoB: {
      FieldDefinitionId: 18416,
      isReadonly: false,
      FieldDefinition: {
        type: [
          ELenderAPIType.Date,
          ELenderAPIType.Requirement,
          ELenderAPIType.EntityChange
        ],
        requirement: {
          pattern: '^\\d{4}-\\d{2}-\\d{2}$'
        },
        newIdList: [
          {
            entityType: 'Sole Trader',
            FieldDefinitionId: 23501
          },
          {
            entityType: 'Partnership',
            FieldDefinitionId: 23980
          },
          {
            entityType: 'Private Individual',
            FieldDefinitionId: 21811
          }
        ]
      }
    },
    applicantType: {
      FieldDefinitionId: 21745,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Dropdown,
        options: ['Director']
      }
    },
    TradingAddressLine1: {
      FieldDefinitionId: 18431,
      isReadonly: false,
      required: true,
      info: 'For sole traders, partnerships, and private individuals, please ensure the full address is accurately formatted for display in this field.',
      FieldDefinition: {
        type: ELenderAPIType.EntityChange,
        newIdList: [
          {
            entityType: 'Sole Trader',
            FieldDefinitionId: 23662
          },
          {
            entityType: 'Partnership',
            FieldDefinitionId: 23662
          },
          {
            entityType: 'Private Individual',
            FieldDefinitionId: 23962
          }
        ]
      }
    },
    TradingAddressCity: {
      FieldDefinitionId: 18432,
      isReadonly: false,
      required: true
    },
    TradingAddressPostcode: {
      FieldDefinitionId: 18433,
      isReadonly: false,
      required: true
    },
    RegAddressLine1: {
      FieldDefinitionId: 18431,
      isReadonly: false,
      required: true
    },
    RegAddressLine2: {
      FieldDefinitionId: 18432,
      isReadonly: false,
      required: true
    },
    RegAddressLine3: {
      FieldDefinitionId: 18431,
      isReadonly: false,
      required: true
    },
    RegAddressCity: {
      FieldDefinitionId: 18433,
      isReadonly: false,
      required: true
    },
    RegAddressPostcode: {
      FieldDefinitionId: 18438,
      isReadonly: false,
      required: true
    },
    estPayoutDate: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
        requirement: {
          pattern: '^\\d{4}-\\d{2}-\\d{2}$'
        }
      }
    },
    amountRequested: {
      FieldDefinitionId: 23958,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Currency
      }
    },
    introducerCode: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'BrokerNickname'
    },
    brokerDealID: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'ProcessInstanceId'
    },
    costIncVAT: {
      FieldDefinitionId: 23997,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Currency
      }
    },
    totalDeposit: {
      FieldDefinitionId: 23957,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Currency
      }
    },
    term: {
      FieldDefinitionId: 18332,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Number
      }
    },
    brokerRate: {
      FieldDefinitionId: 0,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Percentage
      }
    },
    balloonPayment: {
      FieldDefinitionId: 23949,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Currency
      }
    },
    targetYield: {
      FieldDefinitionId: 18343,
      isReadonly: false,
      required: true,
      FieldDefinition: {
        type: ELenderAPIType.Percentage
      }
    },
    goods: [
      {
        make: {
          FieldDefinitionId: 18309,
          isReadonly: false
        },
        model: {
          FieldDefinitionId: 18310,
          isReadonly: false
        },
        description: {
          FieldDefinitionId: 18455,
          isReadonly: false
        },
        cost: {
          FieldDefinitionId: 18314,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        quantity: {
          FieldDefinitionId: 18313,
          isReadonly: false,
          required: true,
          info: 'Between 1 - 99',
          FieldDefinition: {
            type: [ELenderAPIType.Requirement, ELenderAPIType.Number],
            requirement: {
              maxAmount: 99,
              minAmount: 1
            }
          }
        },
        condition: {
          FieldDefinitionId: 18311,
          isReadonly: false
        },
        yearOfMake: {
          FieldDefinitionId: 18312,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        age: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        vehicleRegNo: {
          FieldDefinitionId: 21938,
          isReadonly: false
        },
        extras: {
          FieldDefinitionId: 18455,
          isReadonly: false
        },
        supplierName: {
          FieldDefinitionId: 21944,
          isReadonly: false
        },
        vatRate: {
          FieldDefinitionId: 20693,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Percentage
          }
        },
        currentMileage: {
          FieldDefinitionId: 18536,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Float
          }
        }
      }
    ],
    suppliers: [
      {
        name: {
          FieldDefinitionId: 21944,
          isReadonly: false
        },
        contactFirstName: {
          FieldDefinitionId: 23270,
          isReadonly: false
        },
        contactEmail: {
          FieldDefinitionId: 23270,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 255,
              pattern:
                '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
              message: 'Please enter a valid email address'
            }
          }
        }
      }
    ],
    contacts: [
      {
        firstName: {
          FieldDefinitionId: 18420,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 23497
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 23977
              },
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 21810
              }
            ]
          }
        },
        lastName: {
          FieldDefinitionId: 18427,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 23499
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 24402
              },
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 24396
              }
            ]
          }
        },
        dateOfBirth: {
          FieldDefinitionId: 18416,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 23501
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 23980
              },
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 21811
              }
            ]
          }
        },
        homeAddressLine1: {
          FieldDefinitionId: 18414,
          isReadonly: false
        },
        homeAddressLine2: {
          FieldDefinitionId: 18429,
          isReadonly: false
        },
        homeAddressCounty: {
          FieldDefinitionId: 18417,
          isReadonly: false
        },
        homeAddressPostcode: {
          FieldDefinitionId: 18425,
          isReadonly: false
        }
      }
    ]
  }));
};
