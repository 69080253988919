import { Grid, Typography } from '@material-ui/core';

import { Asset } from 'types/calculatorInterfaces';
import { format } from '..';
import { useCalculationStyles } from './styles';

const AssetDisplayItem = ({ asset, id }: { asset: Asset; id: number }) => {
  const classes = useCalculationStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Asset Id</Typography>
        <Typography>{id}</Typography>
      </Grid>

      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Make</Typography>
        <Typography>{asset.Make}</Typography>
      </Grid>

      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Model</Typography>
        <Typography>{asset.Model}</Typography>
      </Grid>

      {asset.Non_VATable_item > 0 && (
        <Grid item className={classes.displayContainer}>
          <Typography className={classes.itemHeading}>
            Non VATable Item
          </Typography>
          <Typography>£ {format(asset.Non_VATable_item.toString())}</Typography>
        </Grid>
      )}

      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Price Per Unit</Typography>
        <Typography>£ {format(asset.Price)}</Typography>
      </Grid>

      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Quantity</Typography>
        <Typography>{asset.Quantity}</Typography>
      </Grid>

      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Total Price</Typography>
        <Typography>£ {format(asset.TotalPrice.toString())}</Typography>
      </Grid>
    </Grid>
  );
};

export default AssetDisplayItem;
