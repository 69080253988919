import React from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import RedFlag from 'components/RedFlag';

const Component = () => (
  <DashboardLayout title="Company Navigator">
    <RedFlag />
  </DashboardLayout>
);

export default Component;
