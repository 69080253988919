/**
 * Folder Structure
 * @constant {Promise<MicrosoftGraphMailFolder[]>} fetchFolders Will Return Both Parent & Sub Folders
 * @constant {MicrosoftGraphMailFolder | undefined} findFolderByName Helps Sort Out Folders By Name
 * @constant {IMicrosoftFolderIds} extractFolderIds Returns The List Of All Folders Inside Inbox With The Correct Structure
 */

import { callMsGraph } from 'services/microsoft/graph';
import {
  IGroupMailFolder,
  IMicrosoftCustomFolder,
  IMicrosoftFolderIds,
  MicrosoftGraphMailFolder
} from '../context/interface';
import { BugTracker } from 'Utils/Bugtracker';
import { Message } from '@microsoft/microsoft-graph-types';
import { IMicrosoftMessages } from '../interfaces';

const defaultFolderNames = [
  'Archive',
  'Junk Email',
  'Deleted Items',
  'Drafts',
  'Inbox',
  'Sent Items',
  'Conversation History',
  'Outbox'
];

export const fetchFolders = async (
  accessToken: string,
  top: number = 10,
  skip: number = 0
): Promise<MicrosoftGraphMailFolder[]> => {
  try {
    const predefinedFolderNames = ['Inbox', 'SentItems', 'Drafts', 'Archive'];
    const removeParentFoldersFolderNames = [
      'Inbox',
      'Sent Items',
      'Drafts',
      'Archive',
      'Conversation History',
      'Deleted Items',
      'Junk Email'
    ];

    const predefinedParentFolders =
      (await batchRequesting({
        accessToken,
        folderNames: predefinedFolderNames,
        type: 'PARENT_BATCH',
        excludeNames: []
      })) || [];

    const childFoldersResponses =
      (await batchRequesting({
        accessToken,
        folderNames: predefinedParentFolders,
        type: 'CHILDREN_BATCH',
        excludeNames: []
      })) || [];

    const customParentFolders =
      (await batchRequesting({
        accessToken,
        type: 'NONE',
        excludeNames: removeParentFoldersFolderNames
      })) || [];

    const allFolders = [...predefinedParentFolders, ...customParentFolders];
    allFolders.forEach((parentFolder) => {
      childFoldersResponses.forEach((testFolder) => {
        const childFolders = testFolder.value;
        const findParentId = childFolders[0]?.parentFolderId;

        if (parentFolder.id === findParentId) {
          parentFolder.childFolders = childFolders;
        }
      });
    });

    return allFolders;
  } catch (e) {
    BugTracker.notify(e);
    return [];
  }
};

const batchRequesting = async ({
  accessToken,
  folderNames,
  type,
  excludeNames
}: {
  accessToken: string;
  folderNames?: any;
  type: 'PARENT_BATCH' | 'CHILDREN_BATCH' | 'NONE';
  excludeNames: string[];
}) => {
  let queryParams = {};
  if (excludeNames.length > 0) {
    const filterQuery = excludeNames
      .map((name) => `displayName ne '${name}'`)
      .join(' and ');
    queryParams['$filter'] = filterQuery;
  }

  let batchRequest;
  if (folderNames) {
    batchRequest = {
      requests: folderNames.map((folderName, index) => ({
        id: `request-${index}`,
        method: 'GET',
        url:
          type === 'PARENT_BATCH'
            ? `me/mailFolders/${folderName}?${new URLSearchParams({
                ...queryParams,
                $top: '50'
              }).toString()}`
            : `me/mailFolders/${
                folderName.id
              }/childFolders?${new URLSearchParams({
                ...queryParams,
                $top: '50'
              }).toString()}`,
        headers: {
          'Content-Type': 'application/json'
        }
      }))
    };
  } else {
    batchRequest = {
      requests: [
        {
          id: `request-1`,
          method: 'GET',
          url: `me/mailFolders/?${new URLSearchParams({
            ...queryParams,
            $top: '50'
          }).toString()}`,
          headers: {
            'Content-Type': 'application/json'
          }
        }
      ]
    };
  }

  const batchResponse = await fetch('https://graph.microsoft.com/v1.0/$batch', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(batchRequest)
  });
  if (!batchResponse.ok) return null;

  const batchResult = await batchResponse.json();
  const folders = await Promise.all(
    batchResult.responses.map(async (response) => {
      if (response.status !== 200) return null;

      if (type === 'NONE') {
        const foldersWithChildren = await Promise.all(
          response.body.value.map(async (folder) => {
            if (folder.childFolderCount > 0) {
              const childFoldersResponse = await batchRequesting({
                accessToken,
                folderNames: [{ id: folder.id }],
                type: 'CHILDREN_BATCH',
                excludeNames
              });

              if (!childFoldersResponse) return [];
              const childFolders = childFoldersResponse.flatMap((cf) =>
                cf.value ? cf.value : []
              );

              return { ...folder, childFolders };
            }
            return folder;
          })
        );

        return foldersWithChildren.filter((folder) => folder !== null);
      }

      try {
        return typeof response.body === 'object'
          ? response.body
          : JSON.parse(response.body);
      } catch (error) {
        console.error(`Error Parsing Response: ${error}`);
        return null;
      }
    })
  );

  return folders.flat().filter((folder) => folder !== null);
};

const findFolderByName = (
  folders: MicrosoftGraphMailFolder[],
  name: string
): MicrosoftGraphMailFolder | undefined => {
  return folders.find((folder) => folder.displayName === name);
};

export const mapToCustomFolder = (
  folder: MicrosoftGraphMailFolder
): IMicrosoftCustomFolder => {
  return {
    id: folder.id,
    displayName: folder.displayName,
    totalItemCount: folder.totalItemCount,
    unreadItemCount: folder.unreadItemCount,
    child: folder.childFolders
      ? folder.childFolders.map(mapToCustomFolder)
      : undefined
  };
};

type TAnyFolder = IGroupMailFolder | IMicrosoftCustomFolder;
export const extractAllFolderIds = (
  folders: TAnyFolder | TAnyFolder[] | undefined
): string[] => {
  if (!folders) return [];

  const foldersArray = Array.isArray(folders) ? folders : [folders];
  return foldersArray.flatMap((folder) => {
    const id = folder.id;
    const child = folder.child;

    return [id, ...extractAllFolderIds(child)];
  });
};

export const extractFolderIds = (
  mailFolder: MicrosoftGraphMailFolder[]
): IMicrosoftFolderIds => {
  const allFolders = Object.values(mailFolder) as MicrosoftGraphMailFolder[];
  const findFolderId = (name: string) => {
    const folder = findFolderByName(allFolders, name);
    const fileStructure: IGroupMailFolder = {
      id: folder?.id || '',
      child: folder?.childFolders,
      totalItemCount: folder?.totalItemCount || 0
    };

    return fileStructure;
  };

  const customFolders = allFolders
    .filter((folder) => !defaultFolderNames.includes(folder.displayName))
    .map(mapToCustomFolder);

  return {
    deletedItems: findFolderId('Deleted Items'),
    junkEmail: findFolderId('Junk Email'),
    archive: findFolderId('Archive'),
    customFolders,
    inboxFolder: findFolderId('Inbox'),
    sentItemsFolder: findFolderId('Sent Items')
  };
};

export const getGraphFolders = async ({ messages, accessToken }) => {
  try {
    const mailFolder = await fetchFolders(accessToken);
    if (!mailFolder) return;

    const folderIds = extractFolderIds(mailFolder);
    const markedMessages: Message[] = messages.value.map((message) => ({
      ...message,
      isInDeletedItems: message.parentFolderId === folderIds.deletedItems.id,
      isInJunkEmail: message.parentFolderId === folderIds.junkEmail.id,
      isInArchive: message.parentFolderId === folderIds.archive.id
    }));

    const updatedMessages: IMicrosoftMessages = {
      ...messages,
      value: markedMessages
    };

    return { updatedMessages, folderIds };
  } catch (e) {
    BugTracker.notify(e);
  }
};
