import React, { useEffect, useMemo } from 'react';
import { Grid, Tooltip, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import AlertDialog from '../AlertDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import { Typography, Menu } from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { CompleteObjectInstance, Rule, FieldInstance } from 'types/interfaces';
import { permissionFilter } from '../../helper/permissionFilter';
import { Warning } from '@material-ui/icons';
import { IDisabled, useRuleSchema } from 'hooks/useDeals/useRuleSchema';
import {
  getAuditLogs,
  IFirestoreAuditLog,
  IUseAuditLog
} from 'hooks/useDeals/useAuditLog';
import { useTypedSelector } from 'redux/reducers';
import {
  ISafeguard,
  IStatus
} from 'views/Admin/components/RuleSchema/interface';

export default function SplitButton({
  refreshDealData,
  activeStep,
  loadingStepData,
  ProcessStepDefinitionTitle,
  ReqCmp
}) {
  const theme = useTheme();
  const {
    currentDeal,
    currentProcess,
    currentStep,
    currentStepId,
    isAdminOrProcessOwner,
    user
  } = useProcess();
  const { getRuleRequirements, getAllDisabledRules } = useRuleSchema(ReqCmp);
  const { InteractiveRuleDict } = currentStep;
  const { RuleList } = currentProcess;
  const [actions, setActions] = React.useState<Rule[]>([] as Rule[]);
  const { regulatedStatus } = useTypedSelector((s) => s.process);

  const [selectedKey, setSelectedKey] = React.useState<number | undefined>();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [completion, setCompletion] = React.useState<boolean>(false);
  const [groups, setGroups] = React.useState({});
  const [selectedGroup, setSelectedGroup] = React.useState('');

  const [loading, setLoading] = React.useState<boolean>(false);
  const [auditLogs, setAuditLogs] = React.useState<IFirestoreAuditLog[]>([]);
  const [disabledActions, setDisabledActions] = React.useState<{
    [key: string]: IDisabled;
  }>({});
  const subSystemUser = user.SystemAccess <= 4;

  const handleMenuItemClick = (key: number) => setSelectedKey(key);
  const handleClose = () => setAnchorEl(null);
  const handleToggle = async (
    event: React.MouseEvent<HTMLButtonElement>,
    group: any
  ) => {
    setSelectedGroup(group);
    setAnchorEl(event.currentTarget);
    const auditLogs = await getAuditLogs(currentDeal.ProcessInstance.Id);
    setAuditLogs(auditLogs);
  };

  const handleCompletion = () => {
    if (ProcessStepDefinitionTitle !== 'Complete') return;

    Object.values(currentProcess.ProcessStepGroupDict).map((step: any) => {
      if (step.Title === 'Completion') {
        const currentProcessStepID = step.ProcessStepDefinitionIdList;

        currentProcessStepID.forEach((Id) => {
          const grabCompleteId =
            currentProcess.CompleteProcessStepDefinitionDict[Id];

          if (Id === currentStepId) {
            if (grabCompleteId.ProcessStepDefinition.Title === 'Complete') {
              return setCompletion(true);
            }
          }
        });
      } else return setCompletion(false);
    });
  };

  const checkforActions = (actions: any, selectedKey: any) => {
    handleCompletion();
    /* depending on the type of user, we want to show a different list. */
    /* if admin || owner show the list from process definition rule list */
    const list = isAdminOrProcessOwner ? RuleList : InteractiveRuleDict;
    // let list = InteractiveRuleDict;
    const sorted: Rule[] = Object.values(list).sort(
      (a: any, b: any) => a.ItemOrder - b.ItemOrder
    );
    // .filter((el: any) => el.IsPublished); // TODO after the boolean is passed from the back end
    if (sorted && sorted.length > 0) setSelectedKey(sorted?.[0].Id);
    setActions(sorted);

    const grouped: any = sorted.filter((item: { Description: string }) =>
      item?.Description.includes('group#')
    );

    const SortGroups = () => {
      let groups = {};
      grouped.forEach((item: { Description: string }) => {
        const split = item?.Description.split(',');
        const group_name_unsplit = split.find((e) => e.includes('group'));
        const group_name = group_name_unsplit?.split('#')[1];
        if (group_name) {
          if (!groups[group_name]) groups[group_name] = [];
          groups[group_name].push(item);
        }
      });
      setGroups(groups);
    };
    SortGroups();
  };

  let findEntityFieldInstance: FieldInstance | undefined;
  const findEntityCompleteObjectInstance: CompleteObjectInstance | undefined =
    Object.values(currentDeal.CompleteObjectInstanceDict).find(
      (CompleteObjectInstance: CompleteObjectInstance) => {
        return (
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId === 3464
        );
      }
    );

  if (findEntityCompleteObjectInstance) {
    findEntityFieldInstance = Object.values(
      findEntityCompleteObjectInstance
    ).find((FieldInstance: FieldInstance) => {
      return FieldInstance.ObjectDefinitionId === 3464;
    });
  }

  const fetchRequirements = async () => {
    setLoading(true);

    const requirementsPromises = actions.map((action) =>
      getRuleRequirements(action, auditLogs)
    );

    const newDisabledActions = await getAllDisabledRules({
      requirementsPromises,
      actions
    });

    setDisabledActions(newDisabledActions);
    setLoading(false);
  };

  const filterVisibleRules = (rules) => {
    return rules.filter((el) => permissionFilter(el.Description, user));
  };

  const isGroupVisible = (groupRules) => {
    const findSupplierRequest = groupRules.find((rule) => rule.Id === 205);
    if (findSupplierRequest) return false;

    const viewableRules = filterVisibleRules(groupRules);
    if (viewableRules.length === 0) return false;

    return viewableRules.some((rule) => {
      const isDisabled = disabledActions[rule.Id];
      const isEmptyObject =
        isDisabled &&
        typeof isDisabled === 'object' &&
        Object.keys(isDisabled).length === 0;

      if (!isDisabled || isEmptyObject) return true;

      const { isRegulated_Only_Required, isNon_Regulated_Only_Required } =
        isDisabled?.overallStatus || {};

      const isRegulatedOnly = isRegulated_Only_Required && !regulatedStatus;
      const isNonRegulatedOnly =
        isNon_Regulated_Only_Required && regulatedStatus;

      const isRegulatedFlag = isDisabled.regulated && !regulatedStatus;
      const isNonRegulatedFlag = isDisabled.nonRegulated && regulatedStatus;

      return !(
        isRegulatedOnly ||
        isNonRegulatedOnly ||
        isRegulatedFlag ||
        isNonRegulatedFlag
      );
    });
  };

  useEffect(() => {
    fetchRequirements();
  }, [actions, auditLogs, findEntityFieldInstance]);

  React.useEffect(() => {
    checkforActions(InteractiveRuleDict, selectedKey);
    return () => setActions([]);
  }, []);

  React.useEffect(() => {
    checkforActions(InteractiveRuleDict, selectedKey);
  }, [InteractiveRuleDict, currentDeal]);

  if (loadingStepData) return <CircularProgress />;

  if (selectedKey === undefined && !completion) {
    return (
      <Grid alignItems="center" container direction="column">
        <Alert severity="info" variant="outlined">
          <Typography>
            There are no <b>interactive actions</b> available on this step
          </Typography>
        </Alert>
      </Grid>
    );
  }

  if (
    !isAdminOrProcessOwner &&
    InteractiveRuleDict &&
    Object.keys(InteractiveRuleDict).length === 0
  )
    return <div />;

  let actionList: Rule[] = groups[selectedGroup]
    ? groups[selectedGroup]
    : actions;
  let group: string[] =
    Object.keys(groups).length > 0 ? Object.keys(groups) : ['send'];

  return (
    <Grid item xs={12}>
      {Object.entries(groups)
        .filter(([key, groupRules]) => isGroupVisible(groupRules))
        .map(([group, groupRules], idx) => (
          <Button
            data-cy="action-button-customer"
            key={idx}
            fullWidth
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={async (e) => await handleToggle(e, group)}
            variant="contained"
            color="primary"
            style={{ margin: 1 }}>
            {group}
            <DoubleArrowIcon />
          </Button>
        ))}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {actionList
          .filter((el: Rule) => {
            const viewable = permissionFilter(el.Description, user);
            if (viewable) return true;
            return false;
          })
          .map((action: Rule) => {
            const isDisabled = disabledActions[action.Id];
            const isEmptyObject =
              isDisabled &&
              typeof isDisabled === 'object' &&
              Object.keys(isDisabled).length === 0;

            if (!isDisabled || isEmptyObject) return <div key={action.Id} />;

            const { isRegulated_Only_Required, isNon_Regulated_Only_Required } =
              isDisabled?.overallStatus || {};

            const isRegulatedOnly =
              isRegulated_Only_Required && !regulatedStatus;
            const isNonRegulatedOnly =
              isNon_Regulated_Only_Required && regulatedStatus;

            const isRegulatedFlag = isDisabled.regulated && !regulatedStatus;
            const isNonRegulatedFlag =
              isDisabled.nonRegulated && regulatedStatus;

            if (
              isRegulatedOnly ||
              isNonRegulatedOnly ||
              isRegulatedFlag ||
              isNonRegulatedFlag
            ) {
              return <div key={action.Id} />;
            }

            const isDisabledJSX = !isDisabled.success;
            const tooltipTitle = generateTooltipTitle(
              isDisabled,
              isDisabledJSX || false,
              action
            );

            return (
              <Grid container key={action.Id}>
                <Grid item xs={1}>
                  {isDisabledJSX && (
                    <Tooltip arrow title={tooltipTitle}>
                      <Warning
                        style={{
                          color: theme.palette.warning.main,
                          margin: theme.spacing(0.5),
                          paddingLeft: theme.spacing(0.5)
                        }}
                      />
                    </Tooltip>
                  )}
                </Grid>
                <Grid item xs={isDisabledJSX ? 11 : 12}>
                  <MenuItem
                    data-cy="action-menu-item"
                    disabled={isDisabledJSX}
                    onClick={() => {
                      handleMenuItemClick(action.Id);
                      setOpenDialog(true);
                    }}
                    selected={action.Id === selectedKey}>
                    {action.Title}
                  </MenuItem>
                </Grid>
              </Grid>
            );
          })}
      </Menu>

      {selectedKey && (
        <AlertDialog
          InteractiveRuleDict={InteractiveRuleDict}
          actions={actions}
          handleClose={() => setOpenDialog(false)}
          open={openDialog}
          refreshDealData={refreshDealData}
          selectedKey={selectedKey}
        />
      )}
    </Grid>
  );
}

const generateTooltipTitle = (
  isDisabled: IDisabled,
  isDisabledJSX: boolean,
  action: Rule
) => {
  if (!isDisabledJSX) {
    if (!isDisabled || isDisabled.success) {
      return action.Title;
    }
  }

  let messages = [`Please ensure ${action.Title} is completed.`];

  if (isDisabled.incompleteSafeguards?.length) {
    const safeguards = isDisabled.incompleteSafeguards
      .map((sg) => `(Action Name: ${sg.id}, Action Needed: ${sg.action})`)
      .join(', ');
    messages.push(`Missing: ${safeguards}`);
  } else {
    messages.push('All safeguards are completed.');
  }

  const conditions: string[] = [];
  if (isDisabled.overallStatus?.isGDPR_Required)
    conditions.push('GDPR is Required.');
  if (isDisabled.overallStatus?.isGDPR_Only_Required)
    conditions.push('Only GDPR is required.');
  if (isDisabled.overallStatus?.isRegulated_Only_Required)
    conditions.push('Only Regulated Deals Can Complete This Action.');
  if (isDisabled.overallStatus?.isNon_Regulated_Only_Required)
    conditions.push('Only Non-Regulated Deals Can Complete This Action.');
  if (isDisabled.overallStatus?.isRegulated_Field)
    conditions.push('Can Only Be Sent On Regulated Deals.');

  if (conditions.length) {
    messages.push(...conditions);
  }

  return messages.join(' ');
};
