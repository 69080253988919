import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';

import Details from '../Details';
import Deals from '../Deals';
import RedFlag from '../RedFlag';
import Relationships from '../Relationships';
import FCA from '../FCA';

import LinearProgress from '@material-ui/core/LinearProgress';

import User from 'components/User';
import Timeline from '../Timeline';
import {
  CompleteObjectDefinition,
  CompleteUserInstance,
  UserDefinition
} from 'types/interfaces';
import { getFieldInstanceValue } from 'Utils/index';
import { getUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';

// Permissions
import { determineIfUserCanEdit } from 'helpers/permissions';

const companyTypes = [
  'Limited Company',
  'PLC',
  'Limited Liability Partnership'
];

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBar: {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  content: {
    padding: theme.spacing(2),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0
  }
}));

const TabPanel = ({ children, value, index, ...other }) => (
  <div
    aria-labelledby={`simple-tab-${index}`}
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    role="tabpanel"
    {...other}>
    {value === index && <Box>{children}</Box>}
  </div>
);

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`
});

interface Props {
  hasData: boolean;
  ID: number;
  CODL: CompleteObjectDefinition[];
  getAllData: () => void | undefined | Promise<void>;
  rowData: CompleteUserInstance;
}

const Detail = ({ rowData, getAllData, ID, CODL, hasData }: Props) => {
  const theme = useTheme();
  const [loading, setLoading] = React.useState<boolean>(false);
  // const [loadingUser, setLoadingUser] = React.useState<boolean>(false);

  const navStatus = useTypedSelector((s) => s.config.navStatus);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const ProcessesName = useSelector<any>(
    (state) => state.config.instanceSettings.ProcessesName
  );
  const classes = useStyles(navStatus);
  const UserDefinition = useTypedSelector((s) =>
    s.config.settings.UserDefinitionList.find(
      (el: UserDefinition) => el.Id === ID
    )
  );
  const user = useTypedSelector<any>((s) => s.user.user);
  const atLeastSystemUser = user.SystemAccess >= 4;
  const subSystemUser = user.SystemAccess <= 4;

  let attributesSQL: any = [];

  orderofPlugins.forEach((el) => {
    // Only show redflag for Company Type Entities
    for (const key in UserDefinition) {
      if (UserDefinition[key] && key === el.value) {
        // If Entity Type is included in our list of types ...
        const entityType = getFieldInstanceValue({
          CODL,
          COIL: rowData.CompleteObjectInstanceList,
          DefinitionTitle: 'Entity',
          FieldTitle: 'Entity Type'
        });

        if (entityType) {
          if (companyTypes.includes(entityType)) {
            return attributesSQL.push({
              [el.name]: { show: UserDefinition[key], value: key }
            });
          } else {
            if (el.name !== 'Redflag') {
              return attributesSQL.push({
                [el.name]: { show: UserDefinition[key], value: key }
              });
            }
          }
        } else {
          const portalUsersHiddenModules = ['HasRelationships', 'HasRedFlag'];

          if (!atLeastSystemUser && portalUsersHiddenModules.includes(el.value))
            return null;

          // Handle if directorship with director details object and director number field
          const is_Director = getFieldInstanceValue({
            CODL,
            COIL: rowData.CompleteObjectInstanceList,
            DefinitionTitle: 'Director Details',
            FieldTitle: 'Director Number'
          });

          if (is_Director) {
            return attributesSQL.push({
              [el.name]: { show: UserDefinition[key], value: key }
            });
          } else {
            if (el.name !== 'Redflag') {
              return attributesSQL.push({
                [el.name]: { show: UserDefinition[key], value: key }
              });
            }
          }
        }
      }
    }
  });
  if (!subSystemUser) {
    attributesSQL.push({ Timeline: { show: true, value: 'timeline' } });
  }

  // TODO: If the user is able to edit the user then create a tab and make editable
  // * This will just remove the profile tab if the user is not able to edit the user
  // * hiding for now as it looks okay without it...?
  // const canEdit = determineIfUserCanEdit({
  //   loggedInUser: user,
  //   targetUser: rowData
  // });

  // if (canEdit)
  attributesSQL.unshift({ Profile: { show: true, value: 'hello' } });

  const [value, setValue] = React.useState('');
  const UserInstanceId = rowData.UserInstance.Id;
  // const UserDefinitionId = rowData.UserInstance.UserDefinitionId;

  const getData = async () => {
    setLoading(true);
    await getUserInstanceDetail({
      baseUrl,
      UserInstanceId
    });
    setLoading(false);
  };

  React.useEffect(() => {
    if (!hasData) getData();
  }, []);

  React.useEffect(() => {
    if (value === '' && attributesSQL && attributesSQL.length > 0) {
      const initVal = Object.keys(attributesSQL[0])[0];
      setValue(initVal);
    }
  }, [attributesSQL]);

  if (loading) return <LinearProgress />;
  return (
    <Grid className={classes.container} container>
      <Grid item style={{ width: '100%' }}>
        <AppBar className={classes.appBar} position="static">
          <Tabs value={value} variant="scrollable">
            {attributesSQL &&
              attributesSQL.map((index) => {
                return Object.keys(index).map((attribute: any) => {
                  const { show } = index[attribute];
                  if (!show) return <React.Fragment />;

                  let label = attribute;
                  if (label === 'Processes') label = `${ProcessesName}s`;
                  return (
                    <Tab
                      data-cy="detail-tab"
                      style={{
                        background:
                          value === attribute
                            ? theme.palette.primary.light
                            : '',
                        borderRadius: 10
                      }}
                      key={attribute}
                      label={label}
                      onClick={() => setValue(attribute)}
                      {...a11yProps(attribute.name)}
                    />
                  );
                });
              })}
          </Tabs>
        </AppBar>

        <Paper className={classes.content}>
          {attributesSQL &&
            attributesSQL.map((index, i) => {
              return Object.keys(index).map((attribute) => {
                return (
                  <TabPanel index={attribute} key={i} value={value}>
                    <Component
                      CODL={CODL}
                      component={attribute}
                      getAllData={getAllData}
                      rowData={rowData}
                      UserDefinition={UserDefinition}
                    />
                  </TabPanel>
                );
              });
            })}
        </Paper>
      </Grid>
    </Grid>
  );
};

const orderofPlugins = [
  { name: 'Redflag', value: 'HasRedFlag' },
  { name: 'Processes', value: 'HasProcesses' },
  { name: 'Details', value: 'HasDetails' },
  { name: 'Relationships', value: 'HasRelationships' }
];

const Component = (props) => {
  const { component, ...rest } = props;

  switch (component) {
    case 'Profile':
      return (
        <User
          data={props?.rowData?.UserInstance}
          CODL={props.CODL}
          CompleteObjectInstanceList={props.rowData?.CompleteObjectInstanceList}
        />
      );
    case 'Details':
      return <Details {...rest} getData={rest.getAllData} />;
    case 'Relationships':
      return <Relationships {...rest} />;
    case 'Processes':
      return <Deals {...rest} />;
    case 'FCA':
      return <FCA {...rest} />;
    case 'Redflag':
      return <RedFlag {...rest} />;
    case 'Timeline':
      return <Timeline {...rest} />;
    default:
      return <div>No Component Built yet</div>;
  }
};

export default Detail;
