import React, { useState } from 'react';
import firebase from 'firebase/app';
import Dialog from 'components/Stepper/components/Dialog';
import {
  CompleteObjectInstance,
  CompleteProcessInstance,
  FieldInstance,
  UserInstance
} from 'types/interfaces';
import { SubmitProcessStep } from 'redux/database';
import { useProcess } from 'hooks/useProcess';
import { IUseAuditLog, saveAuditLog } from './useAuditLog';
import { updateFieldInstance } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

interface IConfirmAcceptProps {
  currentDeal: CompleteProcessInstance;
  parsed: any;
  user: UserInstance;
}

export interface ILenderDecision {
  lenderDecision: string;
  lastModified: string;
  lenderId: number;
  selected: boolean;
  lenderDecisionDocument: string;
  lenderFunderReference: string;
  objectInstanceId?: number;
  completedByUserId?: string;
}

const useConfirmAcceptLandingPage = ({
  currentDeal,
  parsed,
  user
}: IConfirmAcceptProps) => {
  const { currentStepId, currentOverView, currentProcess } = useProcess();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [loadingAccept, setLoadingAccept] = useState(false);
  const [dialogInfo, setDialogInfo] = useState({ title: '', message: '' });
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = React.useState<boolean>(false);

  const ConfirmLandingPage = async () => {
    setLoadingAccept(true);

    let submitDeal: CompleteProcessInstance = {
      CompleteObjectInstanceDict: {},
      CompleteObjectInstanceList: [],
      CurrentUserInstanceCanSubmit: false,
      NextStepUserInstanceIdDict: {},
      ProcessInstance: {
        Guid: '',
        Id: 0,
        LastModified: '',
        ProcessDefinitionId: 0,
        ProcessStepDefinitionId: 0,
        Title: '',
        UserInstanceId: 0,
        CreatedOn: '',
        ClosedOn: ''
      },
      ProcessStepBodyContentDictionary: {},
      ProcessStepInstanceList: []
    };
    submitDeal.ProcessInstance = currentDeal.ProcessInstance;

    if (parsed !== undefined) {
      submitDeal.ProcessInstance.ProcessStepDefinitionId = parsed?.psdid;
    }

    const res = await SubmitProcessStep({ submitDeal });
    const ProposalDecisionStepId = 1501;
    const IndicativeQuoteId = 1738;

    // Handle IndicativeQuote
    if (currentStepId === IndicativeQuoteId) {
      const QuoteSelectionObjectDefinitionId = 3613;
      const IndicativeQuoteSelection: CompleteObjectInstance = Object.values(
        currentDeal.CompleteObjectInstanceDict
      ).find((CompleteObjectInstance: CompleteObjectInstance) => {
        return (
          CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
          QuoteSelectionObjectDefinitionId
        );
      });

      const SelectFieldId = 22712;
      const FieldInstanceList = getFieldInstances(IndicativeQuoteSelection);
      const FieldInstance: FieldInstance | undefined = FieldInstanceList.find(
        (FieldInstance: FieldInstance) => {
          return FieldInstance.FieldDefinitionId === SelectFieldId;
        }
      );

      if (!FieldInstance) return;
      const updateFieldPromises: Promise<FieldInstance | undefined>[] = [];
      Object.values(currentDeal.CompleteObjectInstanceDict).forEach(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          const Id = CompleteObjectInstance.ObjectInstance.ObjectDefinitionId;
          const IndicativeObjectDefinitionId = 3565;

          if (Id === IndicativeObjectDefinitionId) {
            if (CompleteObjectInstance.ObjectInstance.Selected) return;

            FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
              if (FieldInstance.FieldValue === 'false') {
                FieldInstance.FieldValue = '';

                const response = updateFieldInstance({
                  baseUrl,
                  ProcessInstanceId:
                    CompleteObjectInstance.ObjectInstance.ProcessInstanceId,
                  data: FieldInstance
                });

                if (response) {
                  updateFieldPromises.push(response);
                }
              }
            });
          }
        }
      );

      await Promise.all(updateFieldPromises);
      const SelectedIndicativeQuote =
        currentDeal.CompleteObjectInstanceDict[
          parseInt(FieldInstance.FieldValue)
        ];

      if (SelectedIndicativeQuote.ObjectInstance.Selected) {
        const IndicativeQuoteDeclineId = [23525, 23592];
        const selectedFieldInstanceList = getFieldInstances(
          SelectedIndicativeQuote
        );

        const AcceptIndicativeQuote: FieldInstance | undefined =
          selectedFieldInstanceList.find((FieldInstance: FieldInstance) => {
            return IndicativeQuoteDeclineId.includes(
              FieldInstance.FieldDefinitionId
            );
          });

        if (AcceptIndicativeQuote) {
          AcceptIndicativeQuote.FieldValue = 'false';
          await updateFieldInstance({
            baseUrl,
            ProcessInstanceId:
              SelectedIndicativeQuote.ObjectInstance.ProcessInstanceId,
            data: AcceptIndicativeQuote
          });
        }
      }
    }

    // Handle LenderDecision
    if (currentStepId === ProposalDecisionStepId) {
      const dealRef = firebase
        .firestore()
        .collection('deal')
        .doc(currentOverView.ProcessInstanceId.toString());

      dealRef.get().then((doc) => {
        let lenderDecisions: { [key: number]: ILenderDecision[] } =
          doc.get('lenderDecision') || {};

        // FFI - FieldInstance ID
        const LenderDecisionProposalDecisionFII = 21262;
        const LenderDecisionLastModifiedFFI = 21263;
        const LenderDecisionFunderReference = 21267;
        const LenderDecisionDocument = 21266;

        const LenderInstance = user.Id;

        Object.values(currentDeal.CompleteObjectInstanceDict).forEach(
          (CompleteObjectInstance: CompleteObjectInstance) => {
            const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
            let lenderDecisionValue;
            let lastModifiedValue;
            let funderReference;
            let document;

            const LenderDecisionId = CompleteObjectInstance.ObjectInstance.Id;
            FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
              const Id = FieldInstance.FieldDefinitionId;
              if (Id === LenderDecisionProposalDecisionFII)
                lenderDecisionValue = FieldInstance.FieldValue;
              if (Id === LenderDecisionLastModifiedFFI)
                lastModifiedValue = FieldInstance.FieldValue;
              if (Id === LenderDecisionFunderReference)
                funderReference = FieldInstance.FieldValue;
              if (Id === LenderDecisionDocument)
                document = FieldInstance.FieldValue;
            });

            if (lenderDecisionValue && lastModifiedValue) {
              const newDecision: ILenderDecision = {
                lenderDecision: lenderDecisionValue,
                lenderDecisionDocument: document,
                lenderFunderReference: funderReference,
                lastModified: lastModifiedValue,
                lenderId: LenderInstance,
                selected: true
              };

              const existingDecisions = lenderDecisions[LenderInstance] || [];
              const existingDecisionIndex = existingDecisions.findIndex(
                (decision: ILenderDecision) =>
                  decision.objectInstanceId === LenderDecisionId
              );

              if (existingDecisionIndex > -1) {
                existingDecisions[existingDecisionIndex] = newDecision;
              } else {
                existingDecisions.push(newDecision);
              }

              lenderDecisions[LenderInstance] = existingDecisions;
            }
          }
        );

        dealRef.update({
          lenderDecision: lenderDecisions
        });
      });
    }

    if (res) {
      const customPayload: IUseAuditLog = {
        payload: currentStepId.toString(),
        firebaseStatus: 'Completed',
        action:
          currentProcess.CompleteProcessStepDefinitionDict[currentStepId]
            .ProcessStepDefinition.Title,
        timestamp: new Date(),
        userInstanceId: user.Id,
        userInstanceEmail: user.UserInstanceEmail,
        dealId: currentDeal.ProcessInstance.Id,
        actionFunction: '',
        ProcessDefinitionId: currentProcess.ProcessDefinition.Id
      };

      const firebaseAuditLog = await saveAuditLog(customPayload);
      if (firebaseAuditLog) {
        setSuccess(true);
        setDialogInfo({
          title: 'Accepted',
          message:
            'Thank You For Accepting. The Necessary Information Has Been Communicated To The Individuals Involved.'
        });
      }
    }
    setOpen(false);
    setLoadingAccept(false);
  };

  const handleClose = () => setSuccess(false);
  const dealConfirmDialog = (
    <Dialog
      handleClose={handleClose}
      message={dialogInfo.message}
      open={success}
      title={dialogInfo.title}
    />
  );

  return {
    loadingAccept,
    dealConfirmDialog,
    ConfirmLandingPage,
    setSuccess,
    setOpen,
    open
  };
};

export default useConfirmAcceptLandingPage;
