import { makeStyles, Theme } from '@material-ui/core';

export const useLenderAPIStyles = makeStyles((theme: Theme) => ({
  root: {
    '& > .MuiAccordion-root': {
      '&:not(:last-child)': {
        borderBottom: 'none'
      },
      '&:before': {
        display: 'none'
      },
      border: '2px solid transparent',
      transition: 'border-color 0.3s ease-in-out'
    },
    '& .MuiAccordion-root .MuiAccordion-root': {
      border: 'none'
    }
  },
  proposal: {
    marginBottom: theme.spacing(2)
  },
  proposalIN_PROGRESS: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.info.main
    }
  },
  proposalDRAFT: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.grey[500]
    }
  },
  proposalUNDER_REVIEW: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.warning.main
    }
  },
  proposalAPPROVED: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.success.main
    }
  },
  proposalAPPROVED_EXPIRED: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.error.light
    }
  },
  proposalCANCELLED: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.error.main
    }
  },
  proposalDEPOSITED: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.success.dark
    }
  },
  proposalFAILED: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.error.dark
    }
  },
  proposalTEST: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.grey[500]
    }
  },
  proposalACTION_REQUIRED: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.warning.dark
    }
  },
  proposalDECISION_MADE: {
    '& > .MuiAccordion-root': {
      borderColor: theme.palette.success.light
    }
  },
  displayMode: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'auto'
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1, 2)
  },
  fileUpload: {
    height: '200px',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    }
  },
  formField: {
    marginBottom: theme.spacing(2)
  },
  assignButton: {
    padding: theme.spacing(1, 2),
    fontWeight: 'bold',
    fontSize: '0.9rem',
    transition: 'all 0.3s ease-in-out',
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],
    '&:hover': {
      transform: 'translateY(-2px)',
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.success.light
    }
  },
  deleteIcon: {
    cursor: 'pointer',
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.dark
    }
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  addButton: {
    marginTop: theme.spacing(2),
    transition: 'all 0.3s ease-in-out'
  },
  formControl: {
    marginTop: theme.spacing(1)
  }
}));
