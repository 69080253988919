import React from 'react';
import { tableIcons } from 'components/ProcessTable/tableIcons';
import MaterialTable from '@material-table/core';
import { useTheme } from '@material-ui/core/styles';

const Table = ({
  title,
  data,
  columns,
  options,
  editable,
  actions,
  dontranspose
}) => {
  const theme = useTheme();
  // const newdata = transpose({ name: 'data', data, columns, dontranspose });
  // const newcolumns = transpose({
  //   name: 'columns',
  //   data,
  //   columns,
  //   dontranspose
  // });

  // const pageSize = () => {
  //   let pageSize;
  //   if (!dontranspose && newdata && newdata.length > 0) {
  //     pageSize = newdata[0].length - 1;
  //   } else {
  //     pageSize = undefined;
  //   }
  //   return pageSize;
  // };

  return (
    <MaterialTable
      title={title}
      icons={tableIcons}
      data={data}
      columns={columns}
      actions={actions}
      options={{
        ...options,
        search: true,
        pageSize: 20,
        headerStyle: {
          zIndex: 1,
          // display: 'none',
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText
        }
        // pageSize: pageSize() && pageSize()
      }}
      // options={{
      //   exportButton: true,
      //   exportAllData: true,
      //   filtering: false,
      //   pageSize: 10,
      //   pageSizeOptions: [10, 50, 100],

      //   grouping: true
      // }}
      editable={editable}
    />
  );
};

export default Table;

const transpose = ({ name, data, columns, dontranspose }) => {
  // if (dontranspose) {
  if (name === 'data') return data;
  if (name === 'columns') return columns;
  // }

  /** DATA **/
  // 1. Join all the field instance lists first
  let Alldatalist = [];
  data.forEach((row) =>
    row.FieldInstanceList.forEach((entry) => Alldatalist.push(entry))
  );
  // 2. Group each row by definition id (column name)
  const newrows = groupBy(Alldatalist, 'FieldDefinitionId');
  // 3. Make the first row entry the definition name
  Object.keys(newrows).forEach((FieldDefinitionId) => {
    const firstcel = columns.find((el) => el.field === FieldDefinitionId);
    firstcel.FieldValue = firstcel.title;
    newrows[FieldDefinitionId].unshift(firstcel);
  });

  // TODO: Can we maintain the original list order rather that the alphabetical order from object

  /** COLUMNS **/
  // 1. Set first column
  const newcols = [{ render: (rowData) => rowData[0].FieldValue }];
  // 2. add other columns
  data.forEach(({ ObjectInstance: { Id } }) => {
    newcols.push({
      title: `${Id}`,
      render: (rowData) => {
        const target = rowData.find((el) => el.ObjectInstanceId === Id);
        return target.FieldValue;
      }
    });
  });

  if (name === 'data') return Object.values(newrows);
  if (name === 'columns') return newcols;
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
