import React from 'react';
import { connect } from 'react-redux';
// import Switch from '@material-ui/core/Switch';
import { LightbulbOutline, LightbulbOn } from 'mdi-material-ui';
import { updateTheme } from '../../redux/actions/configActions';
import { IconButton } from '@material-ui/core';

export const LightSwitch = (props) => {
  const [state, setState] = React.useState({
    checked: null
  });

  const handleChange = (name) => (event) => {
    // console.log('Switch switched');
    setState({ ...state, [name]: event.target.checked });
    updateTheme(undefined, true);
  };

  if (props.mode === 'dark') {
    return (
      <IconButton onClick={handleChange('checked')}>
        <LightbulbOn />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={handleChange('checked')}>
      <LightbulbOutline />
    </IconButton>
  );
};

const mapStateToProps = (state) => ({
  mode: state.config.theme.palette.type
});

export default connect(mapStateToProps)(LightSwitch);
