/**Mainly to caution users of company history dealings with other users
 * button to display dialog of company extreme caution history
 * only level 4 or above users can view this component
 */
import {
  Button,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  DialogActions,
  Divider,
  Grid,
  DialogContent
} from '@material-ui/core';
import { useDealList } from 'hooks/useDealList';
import { useDoNotDealListNonRedFlag } from 'hooks/useDoNotDealNonRedFlag';
import { useTypedSelector } from 'redux/reducers';
import { useEffect, useState } from 'react';
import { CustomDialog } from 'common/Dialog';

interface IProps {
  name: string;
  company_number?: string;
  email?: string;
  UserDefinitionId?: number;
  historyAdded?: boolean;
  type: 'company' | 'contact';
}

export const DoNotDealHistory = ({
  name,
  company_number,
  email,
  UserDefinitionId = 0,
  historyAdded = false,
  type
}: IProps) => {
  const { user } = useTypedSelector((s) => s.user);
  const userLevel = user.SystemAccess >= 4;
  const { badCompany, getAllHistory, historyList } = useDealList(
    company_number || ''
  );
  const { badContact, getContactAllHistory, contactHistoryList } =
    useDoNotDealListNonRedFlag(email || '');

  // open company history dialog
  const [open, setOpen] = useState<boolean>(false);

  // does company have previous extreme caution history
  const [hasHistory, setHasHistory] = useState<boolean>(false);

  useEffect(() => {
    // if history been added from the DoNotDeal component
    if (type === 'company' && historyList.length >= 1) {
      setHasHistory(true);
    } else if (type === 'contact' && contactHistoryList.length >= 1) {
      setHasHistory(true);
    }
  }, [historyList, contactHistoryList, badCompany, badContact]);

  const openDialog = async () => {
    if (hasHistory) {
      if (type === 'company') {
        await getAllHistory();
      } else if (type === 'contact') {
        await getContactAllHistory();
      }

      setOpen(true);
    }
  }; //END openDialog+

  // if user is level is under 4, they dont get to see the history list
  if (!userLevel) return null;

  const finalHistoryList =
    type === 'company' ? historyList : contactHistoryList;

  // if user is level 4 or above, they get to see the history list
  return (
    <>
      <Button
        onClick={openDialog}
        variant="contained"
        disabled={hasHistory ? false : true}
        style={{ marginBottom: 8 }}>
        {hasHistory ? (
          'View extreme caution history'
        ) : (
          <>No previous extreme caution listed</>
        )}
      </Button>

      <CustomDialog
        open={open}
        handleClose={() => setOpen(false)}
        maxSize="md"
        alert={{
          title: 'Extreme caution history',
          description:
            'Detailed Record of High-Risk Companies AND/OR Individual',
          type: 'info'
        }}>
        <DialogContent>
          <List>
            {finalHistoryList.map((detail) => {
              const createdDate: Date = detail.dateCreated
                ? detail.dateCreated.toDate().toDateString()
                : 'N/A';
              const removedDate: Date = detail.dateRemoved
                ? detail.dateRemoved.toDate().toDateString()
                : 'N/A';

              return (
                <>
                  <ListItem alignItems="flex-start">
                    <ListItemText
                      style={{ margin: '2%' }}
                      primary={
                        <Grid container spacing={2}>
                          {/* description */}
                          <Grid item xs={6}>
                            <Typography variant="h5" color="textPrimary">
                              Description:
                            </Typography>

                            <Typography variant="subtitle1" color="textPrimary">
                              {detail.Description || ''}
                            </Typography>
                          </Grid>

                          {/* reported by */}
                          <Grid item xs={6}>
                            <Typography
                              component="h2"
                              variant="h5"
                              color="textPrimary">
                              Reported By:
                            </Typography>

                            <Typography variant="subtitle1" color="textPrimary">
                              {detail.ReportedBy || ''}
                            </Typography>
                          </Grid>

                          {/* dateCreated */}
                          <Grid item xs={6}>
                            <Typography
                              component="h2"
                              variant="h5"
                              color="textPrimary">
                              Date Created:
                            </Typography>

                            <Typography variant="subtitle1" color="textPrimary">
                              {createdDate}
                            </Typography>
                          </Grid>

                          {/* dateRemoved */}
                          <Grid item xs={6}>
                            <Typography
                              component="h2"
                              variant="h5"
                              color="textPrimary">
                              Date Removed:
                            </Typography>

                            <Typography variant="subtitle1" color="textPrimary">
                              {removedDate}
                            </Typography>
                          </Grid>
                        </Grid>
                      }
                    />
                  </ListItem>

                  <Divider variant="inset" component="li" />
                </>
              );
            })}
          </List>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
            color="primary">
            Close
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
}; //END DoNotDealHistory
