import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import Table from './Table/Table';
import TableStyle from './Table/TableStyle';
import { store } from 'redux/store';
import NumberFormat from 'react-number-format';
// import { Calculation } from 'types/calculatorInterfaces';
import { FunderDoc } from '../../interfaces';
import {
  termStructure,
  ratesandReturns,
  termination,
  productDetails
} from './Table/data';
import {
  Facility,
  FinanceCharges,
  Instalments,
  PaymentRequired,
  CommissionInvoice
} from './Viewer';

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
    fixedDecimalScale
  />
);

// const theme = store.getState().config.theme;
const logo = store.getState().config.settings.Logo;

const PDF = ({ state, theme }: { state: FunderDoc; theme: any }) => {
  const styles = StyleSheet.create({
    mainPage: {
      marginLeft: 30,
      marginRight: 30
    },
    SmallText: {
      fontSize: '12px'
    },
    MainText: {
      fontSize: '15px'
    },
    tableTitleText: {
      fontSize: '12px',
      color: 'gray'
    },
    line: {
      border: `1px solid ${theme.palette.primary.main}`
    },
    container: {
      flex: 1,
      flexDirection: 'row',
      '@media max-width: 400': {
        flexDirection: 'column'
      }
    },
    image: {
      marginBottom: 10
    },
    leftColumn: {
      flexDirection: 'column',
      width: 170,
      paddingTop: 30,
      paddingRight: 15,
      '@media max-width: 400': {
        width: '100%',
        paddingRight: 0
      },
      '@media orientation: landscape': {
        width: 200
      }
    }
  });

  return (
    <Document>
      <Page style={styles.mainPage}>
        <View>
          <div style={{ paddingBottom: 15 }} />
          <Text style={styles.MainText}>Facility</Text>
          <div style={{ paddingBottom: 15 }} />
        </View>

        <Facility
          state={state}
          Currency={Currency}
          TableStyle={TableStyle}
          styles={styles}
        />

        <View>
          <div style={{ paddingBottom: 15 }} />
          <Text style={styles.MainText}>Finance Charges</Text>
          <div style={{ paddingBottom: 15 }} />
        </View>

        <FinanceCharges
          state={state}
          Currency={Currency}
          TableStyle={TableStyle}
          styles={styles}
        />

        <View>
          <div style={{ paddingBottom: 30 }} />
          <Text style={styles.MainText}>Term Structure</Text>
          <div style={{ paddingBottom: 15 }} />
          <Table data={termStructure({ state })} />
        </View>

        <View>
          <div style={{ paddingBottom: 30 }} />
          <Text style={styles.MainText}>Rates and Returns</Text>
          <div style={{ paddingBottom: 15 }} />
          <Table data={ratesandReturns({ state })} />
        </View>
      </Page>

      <Page style={styles.mainPage}>
        <View>
          <div style={{ paddingBottom: 15 }} />
          <Text style={styles.MainText}>Instalments</Text>
          <div style={{ paddingBottom: 15 }} />
        </View>

        <Instalments
          state={state}
          Currency={Currency}
          TableStyle={TableStyle}
          styles={styles}
        />

        <View>
          <div style={{ paddingBottom: 24 }} />
          <Text style={styles.MainText}>
            {'Termination & Repossession Rights'}
          </Text>
          <div style={{ paddingBottom: 15 }} />
          <Table data={termination({ state, Currency })} />
        </View>

        <View>
          <div style={{ paddingBottom: 15 }} />
          <Text style={styles.MainText}>Payment Required on Signing</Text>
          <div style={{ paddingBottom: 15 }} />
        </View>

        <PaymentRequired
          state={state}
          Currency={Currency}
          TableStyle={TableStyle}
          styles={styles}
        />

        <View>
          <div style={{ paddingBottom: 15 }} />
          <Text style={styles.MainText}>Commission Invoice</Text>
          <div style={{ paddingBottom: 15 }} />
        </View>

        <CommissionInvoice
          state={state}
          Currency={Currency}
          TableStyle={TableStyle}
          styles={styles}
        />
      </Page>
    </Document>
  );
};

export default PDF;
