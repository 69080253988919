import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Box,
  IconButton,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
  Typography,
  useTheme
} from '@material-ui/core';
import { AttachFile, Clear, Info } from '@material-ui/icons';
import FileStoragePicker from 'components/FileStorage/components/FileStoragePicker';
import { IFieldsProps } from 'components/Fields/InlineTextField/interface';
import { IProposalAttachedFiles } from '../interface';
import { FbFileRef } from 'types/interfaces';

interface IFileDisplayProps {
  attachedFiles: IProposalAttachedFiles[];
  files: FbFileRef[];
  handleFileUpload: ({
    url,
    item
  }: {
    url: string;
    item: any;
  }) => Promise<void>;
  handleFileRemove: (key: string) => void;
  isDisabled: boolean;
}

const ProposalFileDisplay = ({
  attachedFiles,
  files,
  handleFileUpload,
  handleFileRemove,
  isDisabled
}: IFileDisplayProps) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'file-popover' : undefined;

  return (
    <>
      <FileStoragePicker
        plugin={false}
        title={'Select File(s)'}
        bordered
        onSelect={handleFileUpload}
        isDisabled={isDisabled}
      />
      <div style={{ paddingRight: theme.spacing(1) }} />
      <IconButton
        size="small"
        disabled={
          (attachedFiles.length === 0 && files.length === 0) || isDisabled
        }
        onClick={handleClick}>
        <Badge
          badgeContent={attachedFiles.length + files.length}
          color="primary">
          <AttachFile />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}>
        <List>
          {attachedFiles.map((file) => (
            <ListItem key={file.key}>
              <ListItemText primary={file.name} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => {
                    handleFileRemove(file.key);
                    setAnchorEl(null);
                  }}>
                  <Clear />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
          {files.map((file: FbFileRef, index: number) => (
            <ListItem key={file?.fileId ?? index} style={{ opacity: 0.6 }}>
              <ListItemText
                primary={file.name}
                secondary={
                  <Typography variant="caption" color="textSecondary">
                    Already uploaded
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <Tooltip title="This file has already been uploaded">
                  <IconButton edge="end" aria-label="info" disabled>
                    <Info />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default ProposalFileDisplay;
