import { Grid, Typography } from '@material-ui/core';

import RedFlag from 'components/RedFlag';
import QuickUserFromId from 'components/User/QuickUserFromId';

import { ICompanyNavigator } from '../functions/interfaces';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { IRedFlagDirectorship } from 'components/OffkeyCompanyAndDirectorSearch/helper';
import { SearchType } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';

export const UserCompanyNavigator = (props: ICompanyNavigator) => {
  const {
    createdUserInstanceId,
    NotificationLog,
    handleClose,
    setValidCompany,
    setCreatedUserInstanceId,
    setData,
    type
  } = props;

  const typeToShow =
    type === 'company'
      ? {
          company: true,
          director: false
        }
      : {
          company: false,
          director: true
        };

  return (
    <Grid item>
      <>
        <Typography variant="h3">{`Create a ${type}`}</Typography>
        <br />
        <br />

        {createdUserInstanceId ? (
          <div>
            {handleCreatedUserString({ NotificationLog })}{' '}
            <QuickUserFromId
              UserInstanceId={createdUserInstanceId.toString()}
            />
          </div>
        ) : (
          <RedFlag
            importedCompany={props.importedCompany}
            lock={
              type === SearchType.Company
                ? SearchType.Director
                : SearchType.Company
            }
            ProcessDefinitionId={undefined}
            action={(res: IRedflagAPIData | IRedFlagDirectorship) =>
              setData(res)
            }
            showDirectorCompany={typeToShow}
            setValidCompany={setValidCompany}
            selectCreated={(UserInstanceId: string) => {
              setCreatedUserInstanceId(parseInt(UserInstanceId));
              handleClose();
            }}
            hideCreateUser
          />
        )}
      </>
    </Grid>
  );
};

const handleCreatedUserString = ({ NotificationLog }) => {
  if (NotificationLog === null) {
    return;
  }

  return 'You Have Created';
};
