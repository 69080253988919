import { ImportRedFlagData } from '../database';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
const { dispatch } = store;

export const importRedFlagData = async ({ data }) => {
  const res = await ImportRedFlagData({ data });
  if (res.status === 200) {
    dispatch(createNotification(successNotif(`Updated with Redflag Data`)));
    return res;
  } else {
    dispatch(createNotification(errorNotif(`Update Unsuccessful`)));
  }
};
