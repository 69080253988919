import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

export const AlertExplainer = () => {
  return (
    <Alert severity="info">
      <Typography variant="h5">Relationships </Typography>
      <Typography variant="body1">{`Relationships describe how users can interact with other users, there are three types of relationship:`}</Typography>
      <br />
      <ul style={{ listStylePosition: 'inside', fontSize: 14 }}>
        <li>
          A <b>Standard</b> relationship
        </li>
        <br />
        <Typography>
          Describes a connection between so for example if you a broker are
          linked to a customer or lender they will show up in dropdowns where
          you can search for them
        </Typography>
        <br />
        <br />
        <li>
          A <b>Read</b> relationship
        </li>
        <br />
        <Typography>
          Allows the user to read information on behalf of the other user.
        </Typography>
        <br />
        <br />
        <li>
          A <b>Write</b> relationship
        </li>
        <br />
        <Typography>
          Allows the user to act on behalf of the other user.
        </Typography>
      </ul>
      <br />
    </Alert>
  );
};
