import { Card, makeStyles } from '@material-ui/core';
import { useContext } from 'react';
import { red, green, blue } from '@material-ui/core/colors';
import { CardContext } from '../context/HoverCardContext';
import { Conversation, Message } from '@microsoft/microsoft-graph-types';
import { ParentThreadContext } from '../components/Threads/context/ParentThreadsContext';
import { MessageHubContext } from '../context/MessageHubContext';
import { IConversation, IMicrosoftMessages } from '../interfaces';

const useStyles = makeStyles({
  messageCard: {
    margin: '10px 10px'
  }
});

export const HoverCard = ({
  conversation,
  selectedConversation,
  children,
  handleOpen,
  handleAdd,
  messages
}: {
  conversation: Message;
  selectedConversation?: IConversation;
  children: React.ReactNode;
  messages: IMicrosoftMessages | IConversation[];

  handleAdd?: () => void;
  handleOpen?: (conversationId: string) => Promise<void>;
}) => {
  const classes = useStyles();
  const {
    hoveredCard,
    setHovered,
    clearHovered,
    bookmarkedConversations,
    setBookmarkedConversations
  } = useContext(CardContext);
  const { setData } = useContext(MessageHubContext);
  const { handleReadMessage } = useContext(ParentThreadContext);
  const conversationId = conversation?.conversationId || '';

  const handleMouseEnter = () => {
    setHovered(conversationId);
  };

  const handleMouseLeave = () => {
    clearHovered();
  };

  const isHovered = hoveredCard === conversationId;
  const isBookmarked = bookmarkedConversations.some(
    (bookmarkedConversation) =>
      bookmarkedConversation.parentMessage.conversationId === conversationId
  );
  const isUnread = !conversation?.isRead;

  const cardStyles = {
    transition: 'box-shadow 0.3s',
    boxShadow: isHovered
      ? '0px 3px 10px rgba(0, 0, 0, 0.2)'
      : '0px 1.5px 5px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    border: isBookmarked ? `2px solid ${blue[200]}` : 'none',
    backgroundColor: isUnread
      ? green[100]
      : conversation.flag?.flagStatus === 'flagged'
      ? red[100]
      : 'none'
  };

  if (!conversation) return null;
  return (
    <Card
      className={classes.messageCard}
      style={cardStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      draggable
      onDragStart={(e) => {
        if (selectedConversation) {
          e.dataTransfer.setData(
            'thread',
            JSON.stringify(selectedConversation)
          );
        }
      }}
      onClick={async () => {
        if (handleOpen) {
          await handleOpen(conversationId);

          const messageId = conversation?.id || '';
          if (isUnread) {
            if (!messages) return;
            if (isMicrosoftMessages(messages)) {
              const updatedMessages = {
                value: [...messages.value]
              };

              const conversationIndex = updatedMessages.value.findIndex(
                (conv) => conv.id === messageId
              );

              if (conversationIndex !== -1) {
                updatedMessages.value[conversationIndex] = {
                  ...updatedMessages.value[conversationIndex],
                  isRead: true
                };

                conversation.isRead = true;
                await handleReadMessage({ messageId });
                setData((prevData) => ({
                  ...prevData,
                  messages: updatedMessages
                }));
              }
            } else if (isArrayOfConversations(messages)) {
              const updatedConversations = messages.map((conversation) => {
                if (conversation.parentMessage.id === messageId) {
                  return {
                    ...conversation,
                    parentMessage: {
                      ...conversation.parentMessage,
                      isRead: true
                    }
                  };
                }
                return conversation;
              });

              conversation.isRead = true;
              await handleReadMessage({ messageId });
              setBookmarkedConversations(updatedConversations);
            }
          }
        } else if (handleAdd) {
          handleAdd();
        }
      }}>
      {children}
    </Card>
  );
};

const isMicrosoftMessages = (
  obj: IMicrosoftMessages | IConversation[]
): obj is IMicrosoftMessages => {
  return (obj as IMicrosoftMessages).value !== undefined;
};

const isArrayOfConversations = (
  obj: IMicrosoftMessages | IConversation[]
): obj is IConversation[] => {
  return (
    Array.isArray(obj) &&
    obj.every(
      (item) => item.parentMessage !== undefined && Array.isArray(item.replies)
    )
  );
};
