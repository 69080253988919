import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { QueryClient, QueryClientProvider } from 'react-query';

import { useTypedSelector } from 'redux/reducers';
import Dashboard from './Dashboard';
import Lists from './Lists';
import Designs from './Designs';
import Senders from './Senders';
import DomainAuthentication from './DomainAuthentication';
import Reports from './Dashboard/components/Reports';
import { MarketingProvider } from './context';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`nav-tab-${index}`}
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      role="tabpanel"
      style={{ minHeight: '100vh' }}
      {...other}>
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index: number) => ({
  id: `nav-tab-${index}`,
  'aria-controls': `nav-tabpanel-${index}`
});

interface LinkTabProps {
  label?: string;
  href?: string;
}
const LinkTab = (props: LinkTabProps) => (
  <Tab
    component="a"
    onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
    }}
    {...props}
  />
);

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

export default function Marketing() {
  return (
    <QueryClientProvider client={queryClient}>
      <MarketingProvider>
        <NavTabs />
      </MarketingProvider>
    </QueryClientProvider>
  );
}

const NavTabs = () => {
  const user = useTypedSelector((s) => s.user.user);
  const isAdmin = user.SystemAccess >= 10;
  const isSuperAdmin = user.SystemAccess === 15;
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState('dashboard');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    let uriEl = event.target['innerText'].toLowerCase();
    history.push(`/marketing/${uriEl}`);
    setValue(uriEl);
  };

  React.useEffect(() => {
    const pathname = history.location.pathname;
    const parts = pathname.split('/');
    const tabName = parts[2] || '';

    if (!tabName) setValue('dashboard');
    else setValue(tabName);
  }, [history.location.pathname]);

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs
          aria-label="nav tabs example"
          onChange={handleChange}
          value={value}
          variant="scrollable">
          <LinkTab href="/drafts" label="Dashboard" {...a11yProps(0)} />
          <LinkTab label="Lists" {...a11yProps(2)} />
          <LinkTab label="Designs" {...a11yProps(4)} />
          <LinkTab label="Senders" {...a11yProps(6)} />
          {(isAdmin || isSuperAdmin) && (
            <LinkTab label="Domain Authentication" {...a11yProps(7)} />
          )}
        </Tabs>
      </AppBar>
      <TabPanel index="dashboard" value={value}>
        <Dashboard />
      </TabPanel>
      <TabPanel index="lists" value={value}>
        <Lists />
      </TabPanel>
      <TabPanel index="designs" value={value}>
        <Designs />
      </TabPanel>
      <TabPanel index="senders" value={value}>
        <Senders />
      </TabPanel>
      <TabPanel index="reports" value={value}>
        <Reports />
      </TabPanel>
      <TabPanel index="domain authentication" value={value}>
        <DomainAuthentication />
      </TabPanel>
    </div>
  );
};
