import { gql } from '@apollo/client';

export const SEARCH_DIRECTORS = gql`
  query RedflagDirectorship($where: DirectorFilterInput) {
    directors(where: $where) {
      items {
        id
        person_id
        source
        is_current
        company_name
        rfa_rating
        first_name
        surname
        nationality
        town
        county
        postcode
        occupation
        locality
        address
        title
        appointment_type
        birthdate
        date_appointed
        date_resigned
        other_appointments
        company
        companyId
        company_id
      }
    }
  }
`;

export const SEARCH_COMPANIES_FROM_DIRECTOR = gql`
  query SearchCompaniesFromDirector($where: PersonSearchFilterInput) {
    people(where: $where) {
      items {
        company {
          company_id
          company_type
          rfa_rating_score
          credit_score
          company_name
        }
      }
    }
  }
`;

export const SEARCH_DIRECTORSHIPS = gql`
  query SearchDirectorships($where: PersonSearchFilterInput) {
    people(where: $where) {
      items {
        id
      }
    }
  }
`;
