import { useState } from 'react';
import DescriptionIcon from '@material-ui/icons/Description';
import { FileAttachment } from '@microsoft/microsoft-graph-types';
import { theme } from 'theme';

import { Grid, Paper, Typography } from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import UploadingFiles, { downloadAttachment } from '../Files/UploadingFiles';
import { useTypedSelector } from 'redux/reducers';

const InlineAttachments = ({
  attachments
}: {
  attachments: FileAttachment[];
}) => {
  const maxHeight = '50px';
  const maxDocWidth = '150px';

  const user = useTypedSelector((state) => state.user.user);
  const [hoveredIndex, setHoveredIndex] = useState<number>(100);

  const [isUploadingFilesOpen, setUploadingFilesOpen] =
    useState<boolean>(false);
  const [currentAttachment, setCurrentAttachment] = useState<FileAttachment>();

  const handleAttachmentClick = (event, attachment) => {
    event?.stopPropagation();
    event?.preventDefault();
    setCurrentAttachment(attachment);
    setUploadingFilesOpen(true);
  };

  const handleClose = () => {
    setUploadingFilesOpen(false);
  };

  const imageStyles = (index, attachment) => ({
    width: '50px', // Set some actual width and height
    height: '50px',
    borderRadius: '4px', // Example of a border radius in pixels
    cursor: 'pointer',
    boxShadow:
      hoveredIndex === index
        ? '0px 3px 10px rgba(0, 0, 0, 0.2)'
        : '0px 1.5px 5px rgba(0, 0, 0, 0.1)',
    backgroundImage: `url(data:${attachment['@odata.mediaContentType']};base64,${attachment.contentBytes})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  });

  const documentStyles = (index) => ({
    maxWidth: maxDocWidth,
    height: maxHeight,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5),
    overflow: 'hidden',
    boxShadow:
      hoveredIndex === index
        ? '0px 3px 10px rgba(0, 0, 0, 0.2)'
        : '0px 1.5px 5px rgba(0, 0, 0, 0.1)'
  });

  return (
    <div style={{ display: 'flex', overflow: 'clip' }}>
      {isUploadingFilesOpen && (
        <UploadingFiles
          isOpen={isUploadingFilesOpen}
          user={user}
          attachment={currentAttachment}
          handleClose={handleClose}
        />
      )}

      {attachments
        .slice(0, 4)
        .map((attachment: FileAttachment, index: number) => {
          const isMessage =
            attachment.contentType?.startsWith('message') || false;

          if (isMessage) return <div key={index} />;
          const name = attachment.name || '';

          let icon: JSX.Element = <AttachFile />;
          if (name.endsWith('.docx') || name.endsWith('.doc')) {
            icon = <DescriptionIcon />;
          } else if (name.endsWith('.pdf')) {
            icon = <PictureAsPdfIcon />;
          }

          const isImage =
            attachment['@odata.mediaContentType'].startsWith('image');
          return (
            <div
              key={index}
              style={{ marginRight: '8px' }}
              onMouseEnter={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(100)}>
              {isImage ? (
                <div
                  style={imageStyles(index, attachment)}
                  onClick={(event) => handleAttachmentClick(event, attachment)}
                />
              ) : (
                <Paper
                  style={documentStyles(index)}
                  onClick={(event) => handleAttachmentClick(event, attachment)}>
                  <div style={{ marginRight: theme.spacing(1), color: 'gray' }}>
                    {icon}
                  </div>
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                    <Typography
                      style={{
                        fontWeight: 500,
                        color: theme.palette.text.secondary
                      }}
                      noWrap>
                      {name}
                    </Typography>
                  </div>
                </Paper>
              )}
            </div>
          );
        })}
    </div>
  );
};

export default InlineAttachments;
