import axios from 'axios';

import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { Campaigns, Email } from '../../types';

const sortEmails = (unsorted) => {
  let sorted = {
    draft: [] as Email[],
    triggered: [] as Email[],
    scheduled: [] as Email[]
  };

  unsorted.forEach((email) => {
    sorted[email.status].push(email);
  });

  return sorted;
};

export const fetchCampaigns = async (
  userId: number,
  days: number
): Promise<Campaigns | null> => {
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  const token = await firebase.auth().currentUser?.getIdToken();

  const url = `${process.env.REACT_APP_PROXY}/marketing/campaigns/${userId}?hostname=${hostname}&days=${days}`;
  const config = { headers: { token } };

  const res = await axios.get(url, config).catch((e) => {
    BugTracker.notify(e);
    console.log('fetchCampaigns Error: ', { e });
    throw e;
  });

  if (!res?.data) return null;

  const emails = sortEmails(res?.data?.emails);
  res.data.emails_sorted = emails;

  return res.data;
};
