import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
  Box,
  MenuItem,
  useTheme,
  TextFieldProps,
  Typography
} from '@material-ui/core';
import { CheckCircle, Error, Lock, Warning } from '@material-ui/icons';
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form';
import { generateLabel } from '../functions';
import {
  ELenderAPIType,
  ILabelValueOption,
  IStructuredField
} from '../interface';
import { useProposalFieldStyles } from './styles';
import { useState } from 'react';
import {
  LenderCurrencyField,
  ICurrencyField
} from './types/LenderCurrencyField';
import {
  KeyboardDatePicker,
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import { Alert } from '@material-ui/lab';
import { patternToMomentFormat } from '../helper';
import {
  isFieldOfType,
  validateAutoCorrectValue,
  validateField,
  validateFieldOnBlur
} from '../functions/validateField';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import React from 'react';
import { theme } from 'theme';
import {
  IPercentageField,
  LenderPercentageField
} from './types/LenderPercentageField';

interface IProposalFieldProps<T extends FieldValues> {
  name: FieldPath<T>;
  control: Control<T, object>;
  structuredField: IStructuredField;
  watchedFields: any;
  isRequired: boolean;
}

/**
 * ProposalField component
 *
 * This component renders different types of form fields based on the structuredField prop.
 * It can render checkboxes, dropdowns, or text fields.
 *
 * @template T
 * @param {Path<T>} name - The name of the field
 * @param {Control<T, object>} control - The react-hook-form control object
 * @param {IStructuredField} structuredField - The structured field object containing field properties
 * @param {any} watchedFields - The watched fields object for dependent fields
 * @returns {JSX.Element | null} The rendered form field or null if structuredField is null
 */
const ProposalField = <T extends FieldValues>({
  name,
  control,
  structuredField,
  watchedFields,
  isRequired
}: IProposalFieldProps<T>) => {
  const classes = useProposalFieldStyles();

  if (structuredField === undefined || structuredField === null) {
    return <div />;
  }

  // TODO Fix this
  const { FieldDefinition } = structuredField;
  const determineFieldType = () => {
    if (!FieldDefinition?.type) {
      return null;
    }

    const types = Array.isArray(FieldDefinition.type)
      ? FieldDefinition.type
      : [FieldDefinition.type];

    if (types.includes(ELenderAPIType.Boolean)) {
      return ELenderAPIType.Boolean;
    }
    if (types.includes(ELenderAPIType.Phone)) {
      return ELenderAPIType.Phone;
    }
    if (types.includes(ELenderAPIType.Dropdown)) {
      return ELenderAPIType.Dropdown;
    }
    if (types.includes(ELenderAPIType.CascadingDropdown)) {
      return ELenderAPIType.CascadingDropdown;
    }
    if (types.includes(ELenderAPIType.OptionsList)) {
      return ELenderAPIType.OptionsList;
    }

    return types[0];
  };

  const fieldType = determineFieldType();
  if (structuredField.isHidden) return <div />;
  const renderField = () => {
    switch (fieldType) {
      case ELenderAPIType.Boolean:
        return (
          <RenderCheckbox
            name={name}
            control={control}
            structuredField={structuredField}
            watchedFields={watchedFields}
            isRequired={isRequired}
          />
        );

      case ELenderAPIType.Dropdown:
      case ELenderAPIType.CascadingDropdown:
      case ELenderAPIType.OptionsList:
        return (
          <RenderDropdown
            name={name}
            control={control}
            structuredField={structuredField}
            watchedFields={watchedFields}
            isRequired={isRequired}
          />
        );

      case ELenderAPIType.Phone:
        return (
          <RenderPhoneField
            name={name}
            control={control}
            structuredField={structuredField}
            watchedFields={watchedFields}
            isRequired={isRequired}
          />
        );

      default:
        return (
          <RenderTextField
            name={name}
            control={control}
            structuredField={structuredField}
            watchedFields={watchedFields}
            isRequired={isRequired}
          />
        );
    }
  };

  return (
    <Box className={classes.fieldContainer}>
      {renderField()}
      {structuredField?.infoField && (
        <Alert severity="info" className={classes.infoAlert}>
          {structuredField?.infoField ?? ''}
        </Alert>
      )}
    </Box>
  );
};

export default ProposalField;

/**
 * RenderDropdown component
 *
 * This component renders a dropdown field using Material-UI TextField with select prop.
 * It supports both regular dropdowns and cascading dropdowns.
 *
 * @template T
 * @param {Path<T>} name - The name of the field
 * @param {Control<T, object>} control - The react-hook-form control object
 * @param {IStructuredField} structuredField - The structured field object containing field properties
 * @param {any} watchedFields - The watched fields object for dependent fields
 * @returns {JSX.Element} The rendered dropdown field
 */
const RenderDropdown = <T extends FieldValues>({
  name,
  control,
  structuredField,
  watchedFields,
  isRequired
}: IProposalFieldProps<T>) => {
  const { FieldDefinition } = structuredField;

  const classes = useProposalFieldStyles();
  const theme = useTheme();

  const isCascading = isFieldOfType(
    FieldDefinition,
    ELenderAPIType.CascadingDropdown
  );
  const isOptionsList = isFieldOfType(
    FieldDefinition,
    ELenderAPIType.OptionsList
  );

  const getParentValues = () => {
    if (isCascading) {
      const dependsOn = FieldDefinition?.dependsOn || [];
      return dependsOn.map((parentField) => {
        const parts = parentField.split('.');
        return parts.reduce((acc, part) => acc?.[part]?.value, watchedFields);
      });
    }
    return [];
  };

  const getOptions = () => {
    let options = FieldDefinition?.options || [];

    if (isCascading) {
      if (FieldDefinition?.getOptions) {
        const parentValues = getParentValues();
        options = FieldDefinition.getOptions(...parentValues) || [];
      }
    }

    return options;
  };

  const autoCorrectOption = (option: string) => {
    if (FieldDefinition?.requirement?.pattern) {
      return validateAutoCorrectValue(
        option,
        FieldDefinition.requirement.pattern
      );
    }
    return option;
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={structuredField.value as any}
      render={({ field }) => {
        const options = getOptions();
        const isCurrentValueValid = isOptionsList
          ? (options as ILabelValueOption[]).some(
              (opt) => opt.value === field.value.value
            )
          : (options as string[]).includes(field.value.value);

        const isFieldFilled =
          field.value.value !== undefined && isCurrentValueValid;

        return (
          <TextField
            {...field}
            label={generateLabel(name)}
            value={field.value.value || 0 || ''}
            select
            fullWidth
            variant="outlined"
            disabled={structuredField.isReadonly}
            className={`${classes.field} ${
              structuredField.isReadonly ? classes.readonlyField : ''
            } ${
              isRequired
                ? isFieldFilled
                  ? classes.completedField
                  : classes.requiredField
                : ''
            }`}
            InputProps={{
              endAdornment: (
                <div
                  style={{
                    marginRight: theme.spacing(1),
                    paddingTop: theme.spacing(0.5)
                  }}>
                  {isRequired &&
                    !structuredField.isReadonly &&
                    (isFieldFilled ? (
                      <CheckCircle className={classes.checkSymbol} />
                    ) : (
                      <Warning className={classes.requiredSymbol} />
                    ))}
                  {structuredField.isReadonly && (
                    <InputAdornment position="end">
                      <Lock color="action" />
                    </InputAdornment>
                  )}
                </div>
              ),
              readOnly: structuredField.isReadonly
            }}
            onChange={(e) => {
              let newValue = e.target.value === '' ? null : e.target.value;
              if (typeof newValue === 'string') {
                newValue = autoCorrectOption(newValue);
              }
              field.onChange({
                ...field.value,
                value: newValue
              });
            }}>
            {isOptionsList
              ? (options as ILabelValueOption[]).map((option) => (
                  <MenuItem key={option.label} value={option.value || 0 || ''}>
                    {option.label}
                  </MenuItem>
                ))
              : (options as string[]).map((option: string) => {
                  const correctedOption = autoCorrectOption(option);
                  return (
                    <MenuItem key={correctedOption} value={correctedOption}>
                      {option}
                    </MenuItem>
                  );
                })}
          </TextField>
        );
      }}
    />
  );
};

/**
 * RenderCheckbox component
 *
 * This component renders a checkbox field using Material-UI Checkbox and FormControlLabel.
 *
 * @template T
 * @param {Path<T>} name - The name of the field
 * @param {Control<T, object>} control - The react-hook-form control object
 * @param {IStructuredField} structuredField - The structured field object containing field properties
 * @returns {JSX.Element} The rendered checkbox field
 */
const RenderCheckbox = <T extends FieldValues>({
  name,
  control,
  structuredField,
  isRequired
}: IProposalFieldProps<T>) => {
  const classes = useProposalFieldStyles();
  const isDisabled = structuredField.isReadonly;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={structuredField.value as any}
      render={({ field }) => {
        const isFieldFilled = field.value?.value ?? false;

        return (
          <Box
            className={`${classes.checkboxContainer} ${
              isDisabled ? classes.readonlyCheckbox : ''
            } `}>
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={isFieldFilled}
                  onChange={(e) => {
                    field.onChange({
                      ...field.value,
                      value: e.target.checked
                    });
                  }}
                  disabled={isDisabled}
                  classes={{
                    root: classes.checkboxRoot,
                    checked: classes.checked
                  }}
                />
              }
              label={
                <div style={{ paddingRight: theme.spacing(1) }}>
                  {generateLabel(name)}
                  {isRequired && !isDisabled && (
                    <span className={classes.requiredSymbol}>*</span>
                  )}
                </div>
              }
              className={classes.checkboxLabel}
            />

            {isDisabled && (
              <InputAdornment position="end" className={classes.lockContainer}>
                <Lock color="action" />
              </InputAdornment>
            )}
          </Box>
        );
      }}
    />
  );
};

/**
 * RenderTextField component
 *
 * This component renders a text field using Material-UI TextField.
 * It supports both text and number inputs.
 *
 * @template T
 * @param {Path<T>} name - The name of the field
 * @param {Control<T, object>} control - The react-hook-form control object
 * @param {IStructuredField} structuredField - The structured field object containing field properties
 * @returns {JSX.Element} The rendered text field
 */
const RenderTextField = <T extends FieldValues>({
  name,
  control,
  structuredField,
  isRequired
}: IProposalFieldProps<T>) => {
  const classes = useProposalFieldStyles();
  const { FieldDefinition } = structuredField;

  const isPercentage = isFieldOfType(
    FieldDefinition,
    ELenderAPIType.Percentage
  );

  const isCurrency = isFieldOfType(FieldDefinition, ELenderAPIType.Currency);

  const isDate = isFieldOfType(FieldDefinition, ELenderAPIType.Date);
  const isDateTime = isFieldOfType(FieldDefinition, ELenderAPIType.DateTime);
  const isFloat = isFieldOfType(FieldDefinition, ELenderAPIType.Float);

  const requirement = isFieldOfType(FieldDefinition, ELenderAPIType.Requirement)
    ? FieldDefinition?.requirement
    : undefined;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={structuredField.value as any}
      render={({ field }) => {
        const isFieldFilled = Boolean(field.value.value);
        const validationResult = isFieldFilled
          ? validateField(field.value.value, FieldDefinition, requirement)
          : undefined;

        const error = validationResult?.error ?? null;
        const isNumber = validationResult?.isNumber ?? false;

        const CommonProps = {
          label: generateLabel(name),
          fullWidth: true,
          disabled: structuredField.isReadonly,
          className: `${classes.field} ${
            structuredField.isReadonly ? classes.readonlyField : ''
          } ${
            isRequired
              ? isFieldFilled && !error
                ? classes.completedField
                : classes.requiredField
              : ''
          }`,
          InputLabelProps: {
            shrink: isFieldFilled
          } as TextFieldProps['InputLabelProps'],
          InputProps: {
            endAdornment: (
              <>
                {isRequired &&
                  !structuredField.isReadonly &&
                  (isFieldFilled && !error ? (
                    <CheckCircle className={classes.checkSymbol} />
                  ) : isFieldFilled && error ? (
                    <Error color="error" />
                  ) : (
                    <Warning className={classes.requiredSymbol} />
                  ))}
                {structuredField.isReadonly && (
                  <InputAdornment position="end">
                    <Lock color="action" />
                  </InputAdornment>
                )}
              </>
            ),
            readOnly: structuredField.isReadonly
          } as TextFieldProps['InputProps'],
          error: !!error,
          helperText: error || '',
          onBlur: (e: React.FocusEvent<HTMLInputElement>) => {
            field.onBlur();
            if (e.target.value) {
              const isValid = validateFieldOnBlur(
                e.target.value,
                FieldDefinition,
                requirement
              );

              console.log({ isValid });
              if (!isValid && requirement?.pattern) {
                const correctedValue = validateAutoCorrectValue(
                  e.target.value,
                  requirement.pattern
                );
                field.onChange({
                  ...field.value,
                  value: correctedValue
                });
              }
            }
          }
        };

        if (isDate || isDateTime) {
          const maxDate = requirement?.dateLength
            ? moment().subtract(requirement.dateLength, 'years').endOf('day')
            : undefined;

          const datePattern = patternToMomentFormat(
            isDateTime
              ? '^\\d{4}-\\d{2}-\\d{2}T\\d{2}:\\d{2}:\\d{2}$'
              : requirement?.pattern ?? '^\\d{4}-\\d{2}-\\d{2}$'
          );

          return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
              {isDateTime ? (
                <KeyboardDateTimePicker
                  {...CommonProps}
                  variant="inline"
                  inputVariant="outlined"
                  format={datePattern}
                  value={field.value.value ? moment(field.value.value) : null}
                  onChange={(date) => {
                    const formattedDate = date
                      ? date.format('YYYY-MM-DDTHH:mm:ss')
                      : '';
                    const newError = validateField(
                      formattedDate,
                      FieldDefinition,
                      requirement
                    );

                    if (newError?.error && newError?.error !== null) {
                      field.onChange({
                        ...field.value,
                        value: formattedDate,
                        error: newError
                      });
                    } else {
                      field.onChange({ ...field.value, value: formattedDate });
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date and time'
                  }}
                  maxDate={maxDate}
                />
              ) : (
                <KeyboardDatePicker
                  {...CommonProps}
                  variant="inline"
                  inputVariant="outlined"
                  format={datePattern}
                  value={field.value.value ? moment(field.value.value) : null}
                  onChange={(date) => {
                    const formattedDate = date ? date.format(datePattern) : '';
                    const newError = validateField(
                      formattedDate,
                      FieldDefinition,
                      requirement
                    );

                    if (newError?.error && newError?.error !== null) {
                      field.onChange({
                        ...field.value,
                        value: formattedDate,
                        error: newError
                      });
                    } else {
                      field.onChange({ ...field.value, value: formattedDate });
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date'
                  }}
                  maxDate={maxDate}
                />
              )}
            </MuiPickersUtilsProvider>
          );
        }

        const TextFieldProps = {
          ...CommonProps,
          value: field.value.value ?? '',
          variant: 'outlined' as const,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value;
            const newError = newValue
              ? validateField(newValue, FieldDefinition, requirement)?.error
              : null;

            let parsedValue: string | number = newValue;
            if (isFloat || isNumber) {
              const floatValue = parseFloat(newValue);
              if (!isNaN(floatValue)) {
                parsedValue = floatValue;
              }
            }

            field.onChange({
              ...field.value,
              value: parsedValue,
              error: newError
            });
          },
          inputProps: {
            maxLength: requirement?.maxLength,
            minLength: requirement?.minLength,
            ...(isNumber && {
              max: requirement?.maxAmount,
              min: requirement?.minAmount
            }),
            ...(isFloat && {
              step: 'any'
            })
          }
        };

        if (isCurrency) {
          const CurrencyFieldProps: ICurrencyField = {
            ...CommonProps,
            value: field.value.value || '',
            variant: 'outlined',
            onChange: (event: { target: { name: string; value: string } }) =>
              field.onChange({
                ...field.value,
                value: parseFloat(event.target.value)
              })
          };
          return (
            <LenderCurrencyField
              {...CurrencyFieldProps}
              maxAmount={requirement?.maxAmount}
              minAmount={requirement?.minAmount}
            />
          );
        }

        if (isPercentage) {
          const PercentageFieldProps: IPercentageField = {
            ...CommonProps,
            value: field.value.value || '',
            variant: 'outlined',
            onChange: (event: { target: { name: string; value: string } }) =>
              field.onChange({
                ...field.value,
                value: parseFloat(event.target.value)
              })
          };
          return (
            <LenderPercentageField
              {...PercentageFieldProps}
              minPercentage={requirement?.minAmount}
              maxPercentage={requirement?.maxAmount}
            />
          );
        }

        return (
          <TextField
            {...TextFieldProps}
            type={isNumber || isFloat ? 'number' : 'text'}
          />
        );
      }}
    />
  );
};

/**
 * RenderPhoneField component
 *
 * This component renders a phone input field using react-phone-input-2.
 * It supports validation, readonly mode, and required field indicators.
 *
 * @template T
 * @param {FieldPath<T>} name - The name of the field
 * @param {Control<T, object>} control - The react-hook-form control object
 * @param {IStructuredField} structuredField - The structured field object containing field properties
 * @param {boolean} isRequired - Indicates if the field is required
 * @returns {JSX.Element} The rendered phone input field
 */
export const RenderPhoneField = <T extends FieldValues>({
  name,
  control,
  structuredField,
  isRequired
}: IProposalFieldProps<T>) => {
  const classes = useProposalFieldStyles();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={structuredField.value as any}
      render={({ field, fieldState }) => {
        const [localValue, setLocalValue] = useState(field.value.value || '');
        const error = fieldState.error?.message || field.value.error;
        const label = generateLabel(name);

        const handleChange = (phone: string, country: any) => {
          setLocalValue(phone);
        };

        const handleBlur = () => {
          let cleanNumber = localValue
            .replace(/\s+/g, '')
            .replace(/[()-]/g, '');

          if (cleanNumber.startsWith('44')) {
            cleanNumber = '+' + cleanNumber;
          }

          const isValidUKNumber = /^(?:(?:\+44)|0)\d{10}$/.test(cleanNumber);
          if (!isValidUKNumber) {
            field.onChange({
              ...field.value,
              value: localValue,
              error:
                'Please enter a valid UK phone number (e.g., 07123456789 or +447123456789)'
            });
          } else {
            field.onChange({
              ...field.value,
              value: cleanNumber,
              error: null
            });
          }
        };

        const isFieldFilled = !!localValue;

        const getBorderColor = () => {
          if (isRequired && !isFieldFilled) return theme.palette.warning.main;
          if (error) return theme.palette.error.main;
          if (isFieldFilled && !error) return theme.palette.success.main;
          return undefined;
        };

        return (
          <Box className={classes.fieldContainer} position="relative">
            <PhoneInput
              country={'gb'}
              onlyCountries={['gb']}
              value={localValue}
              disableDropdown
              countryCodeEditable={false}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                name: field.name,
                required: isRequired,
                disabled: structuredField.isReadonly,
                id: field.name
              }}
              containerClass={`${classes.field} ${
                structuredField.isReadonly ? classes.readonlyField : ''
              } ${
                isRequired
                  ? !error && isFieldFilled
                    ? classes.completedField
                    : classes.requiredField
                  : ''
              }`}
              inputStyle={{
                width: '100%',
                height: '56px',
                fontSize: '16px',
                borderRadius: theme.shape.borderRadius,
                paddingTop: '20px',
                paddingBottom: '4px',
                border: `2px solid ${getBorderColor() || theme.palette.divider}`
              }}
              buttonStyle={{
                borderTopLeftRadius: theme.shape.borderRadius,
                borderBottomLeftRadius: theme.shape.borderRadius,
                border: `2px solid ${getBorderColor() || theme.palette.divider}`
              }}
              dropdownStyle={{
                width: 'max-content'
              }}
            />
            <Box
              position="absolute"
              top="4px"
              left="50px"
              right="8px"
              fontSize="10px"
              color={theme.palette.text.secondary}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {label}
            </Box>
            {error && (
              <Box color="error.main" fontSize="0.75rem" mt={0.5}>
                {error}
              </Box>
            )}
            {structuredField.infoField && (
              <div className={classes.infoAlert}>
                {structuredField.infoField}
              </div>
            )}
          </Box>
        );
      }}
    />
  );
};
