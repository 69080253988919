import { firebase } from 'redux/firebase';
import axios, { AxiosRequestConfig } from 'axios';
import { IAddressApiFullAddress } from 'types/address';

export const UdprnLookup_API = async (udprn: number) => {
  const token = await firebase.auth().currentUser?.getIdToken();

  const config: AxiosRequestConfig = {
    method: 'POST',
    url: `${process.env.REACT_APP_PROXY}/address/lookup`,
    data: { udprn },
    headers: { token }
  };

  return config;
};

export const handleObjectKeys = ({
  index,
  address,
  noFormatting = false
}: {
  index: number;
  address: IAddressApiFullAddress;
  noFormatting?: boolean;
}) => {
  if (address) {
    const keys = Object.keys(address);
    let isEmpty = true;

    for (const key of keys) {
      if (address[key] !== '') {
        isEmpty = false;
        break;
      }
    }

    if (isEmpty) {
      return 'Empty Address Object';
    }

    // If noFormatting is Applied DO NOT FORMAT
    const key = keys[index];

    if (index < 0 || index >= keys.length) {
      if (noFormatting) {
        return key;
      } else return 'Invalid Index';
    }

    const formattedString =
      keys &&
      keys[index] &&
      keys[index]
        .replace(/_/g, ' ')
        .split(' ')
        .map((word) => word.replace(/^\w/, (c) => c.toUpperCase()))
        .join(' ');

    const value = address[keys[index]];
    if (typeof value === 'object' && value !== null) {
      return handleObjectKeys({
        index,
        address: value
      });
    }

    return formattedString;
  }

  return 'Empty Address Object';
};

// Genetic Type For IAddressAPI Changing
type AddressTitles<T> = {
  [K in keyof T]: string;
};

// NOTE: If we want to add more fields update this.
export const IAddressApiFullAddressTitles: AddressTitles<IAddressApiFullAddress> =
  {
    line_1: 'Line 1',
    line_2: 'Line 2',
    line_3: 'Line 3',
    postcode: 'postcode',
    county: 'County',
    country: 'Country',
    location: 'Location',
    postal_town: 'Postal Town',
    thoroughfare: 'Thoroughfare',
    building_number: 'Building Number',
    building_name: 'Building Name',
    organization_name: 'Organization Name'
  };
