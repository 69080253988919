import { FbFileRef } from 'types/interfaces';
import { isStructuredField } from '../functions';
import {
  ELenderStatus,
  IProposal,
  IStructuredField,
  TAttachedFile
} from '../interface';

/**
 * Normalizes a document type string for comparison.
 *
 * @param {string} type - The document type string to normalize.
 * @returns {string} The normalized document type string.
 */
export const normalizeDocumentType = (type: string): string => {
  return type.toLowerCase().replace(/[_\s]/g, '');
};

export const isFileExplorer = (file: TAttachedFile): file is File => {
  return (file as File).lastModified !== undefined;
};

export const isFileStorage = (file: TAttachedFile): file is FbFileRef => {
  return (file as FbFileRef).link !== undefined;
};

export const patternToMomentFormat = (pattern: string): string => {
  // Remove start and end anchors
  pattern = pattern.replace(/^\^/, '').replace(/\$$/, '');

  // Remove backslashes
  pattern = pattern.replace(/\\/g, '');

  // Split the pattern into parts (numbers and non-numbers)
  const parts = pattern.split(/(d\{\d+\})/).filter(Boolean);

  // Initialize counters for date components
  let d2Count = 0;
  let d4Count = 0;

  // Map of date component replacements
  const dateComponentMap = {};
  const getMomentToken = (match) => {
    if (match === 'd{4}') {
      d4Count++;
      dateComponentMap[match] = 'YYYY';
      return 'YYYY';
    } else if (match === 'd{2}') {
      d2Count++;
      // Decide what 'd{2}' represents based on occurrence
      if (d2Count === 1) {
        dateComponentMap[match + d2Count] = 'MM';
        return 'MM';
      } else if (d2Count === 2) {
        dateComponentMap[match + d2Count] = 'DD';
        return 'DD';
      } else {
        dateComponentMap[match + d2Count] = 'YY';
        return 'YY';
      }
    } else {
      return match;
    }
  };

  // Replace 'd{N}' patterns with corresponding Moment.js tokens
  const momentFormat = parts
    .map((part) => (part.match(/d\{\d+\}/) ? getMomentToken(part) : part))
    .join('');

  return momentFormat;
};

export const evaluateProposalCondition = (
  condition: string,
  parentValues: Record<string, any>
): { affectedField: string | null; result: boolean } => {
  const conditionRegex =
    /^(\w+)\s*(==|!=|<=|>=|<|>)\s*(\S+?)(?:\s*(years|months|days))?\s*(\w+)$/;
  const match = condition.match(conditionRegex);

  if (!match) {
    console.error('Invalid condition format');
    return { affectedField: null, result: false };
  }

  const [, field, operator, valueStr, unit, affectedField] = match;

  const fieldValue = parentValues[field]?.value;
  if (fieldValue === undefined || fieldValue === null) {
    return { affectedField, result: false };
  }

  if (unit) {
    const value = Number(valueStr);
    if (isNaN(value)) {
      console.error('Invalid numerical value in condition');
      return { affectedField, result: false };
    }

    const fieldDate = new Date(fieldValue);
    if (isNaN(fieldDate.getTime())) {
      return { affectedField, result: false };
    }

    const currentDate = new Date();
    let diffInTime = currentDate.getTime() - fieldDate.getTime();
    let diff;

    switch (unit) {
      case 'years':
        diff = diffInTime / (1000 * 3600 * 24 * 365.25);
        break;
      case 'months':
        diff = diffInTime / (1000 * 3600 * 24 * 30.44);
        break;
      case 'days':
        diff = diffInTime / (1000 * 3600 * 24);
        break;
      default:
        diff = diffInTime / (1000 * 3600 * 24 * 365.25);
        break;
    }

    let result: boolean;
    switch (operator) {
      case '==':
        result = diff === value;
        break;
      case '!=':
        result = diff !== value;
        break;
      case '>=':
        result = diff >= value;
        break;
      case '<=':
        result = diff <= value;
        break;
      case '>':
        result = diff > value;
        break;
      case '<':
        result = diff < value;
        break;
      default:
        console.error('Invalid operator in condition');
        return { affectedField: null, result: false };
    }

    return { affectedField, result };
  } else {
    let result: boolean;
    const numericValue = Number(valueStr);
    const isNumericComparison =
      !isNaN(numericValue) && !isNaN(Number(fieldValue));

    if (isNumericComparison) {
      switch (operator) {
        case '==':
          result = Number(fieldValue) === numericValue;
          break;
        case '!=':
          result = Number(fieldValue) !== numericValue;
          break;
        case '>=':
          result = Number(fieldValue) >= numericValue;
          break;
        case '<=':
          result = Number(fieldValue) <= numericValue;
          break;
        case '>':
          result = Number(fieldValue) > numericValue;
          break;
        case '<':
          result = Number(fieldValue) < numericValue;
          break;
        default:
          console.error('Invalid operator in condition');
          return { affectedField: null, result: false };
      }
    } else {
      switch (operator) {
        case '==':
          result = String(fieldValue) === valueStr;
          break;
        case '!=':
          result = String(fieldValue) !== valueStr;
          break;
        default:
          console.error('Invalid operator for string comparison');
          return { affectedField: null, result: false };
      }
    }

    return { affectedField, result };
  }
};

/**
 * Computes which fields should be hidden based on both field-level conditions
 * and proposal-level visibility rules
 *
 * @param data - The form data object to evaluate
 * @param proposal - The proposal configuration containing visibility rules
 * @param fieldPath - Current path in the object tree being processed
 * @param hiddenFields - Set of field paths that should be hidden
 * @param parentValues - Values from parent objects for condition evaluation
 * @returns Set of field paths that should be hidden
 */
export const computeHiddenFields = (
  data: any,
  proposal: IProposal,
  fieldPath: string[] = [],
  hiddenFields: Set<string> = new Set<string>(),
  parentValues?: Record<string, any>
): Set<string> => {
  // Handle proposal-level visibility rules
  if (proposal.fieldVisibility && fieldPath.length === 0) {
    for (const rule of proposal.fieldVisibility) {
      const dependentValue = getNestedValue(data, rule.dependsOn);
      // console.log({
      //   rule: rule.field,
      //   dependentValue,
      //   shouldShow: rule.showWhen.includes(dependentValue),
      //   currentHiddenFields: Array.from(hiddenFields)
      // });

      if (dependentValue) {
        if (!rule.showWhen.includes(dependentValue)) {
          if (!hiddenFields.has(rule.field)) {
            // console.log(`Adding ${rule.field} to hidden fields`);
            hiddenFields.add(rule.field);
          }
        } else {
          if (hiddenFields.has(rule.field)) {
            // console.log(`Removing ${rule.field} from hidden fields`);
            hiddenFields.delete(rule.field);
          }
        }
      }
    }
  }

  // Handle field-level conditions
  if (isStructuredField(data)) {
    if (data.FieldDefinition?.condition) {
      const { affectedField, result } = evaluateProposalCondition(
        data.FieldDefinition.condition,
        parentValues || {}
      );
      if (result && affectedField) {
        const hiddenFieldPath = [...fieldPath.slice(0, -1), affectedField].join(
          '.'
        );
        hiddenFields.add(hiddenFieldPath);
      }
    }
  } else if (Array.isArray(data)) {
    data.forEach((item, index) => {
      computeHiddenFields(
        item,
        proposal,
        [...fieldPath, index.toString()],
        hiddenFields,
        item
      );
    });
  } else if (typeof data === 'object' && data !== null) {
    Object.entries(data).forEach(([key, value]) => {
      // Check if this path should be hidden based on proposal rules
      const currentPath = [...fieldPath, key].join('.');
      const shouldSkip = Array.from(hiddenFields).some((hiddenPath) =>
        currentPath.startsWith(hiddenPath)
      );

      if (!shouldSkip) {
        computeHiddenFields(
          value,
          proposal,
          [...fieldPath, key],
          hiddenFields,
          data
        );
      }
    });
  }

  return hiddenFields;
};

/**
 * Gets the value from a field by traversing the nested path
 * @param obj - The root object to search in
 * @param path - The dot-notation path to the field (e.g., 'client.companyType')
 * @returns The value of the field if found
 */
const getNestedValue = (obj: any, path: string): any => {
  // Split the path into parts
  const parts = path.split('.');

  // Traverse the object following the path
  let current = obj;
  for (const part of parts) {
    if (!current || typeof current !== 'object') {
      return undefined;
    }

    current = current[part];

    // If we hit a structured field, return its value
    if (isStructuredField(current)) {
      return current.value;
    }
  }

  // If we reached the end but didn't find a structured field
  // (This might happen for nested objects)
  return undefined;
};
