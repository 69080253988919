import {
  FieldDefinition,
  CompleteObjectInstance,
  FieldInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
interface IHideShowFields {
  FieldDefinitionList: FieldDefinition[];
  CompleteObjectInstance: CompleteObjectInstance;
}

export const HideShowFields = ({
  FieldDefinitionList,
  CompleteObjectInstance
}: IHideShowFields) => {
  const leverFieldDefinitions: FieldDefinition[] = FieldDefinitionList.filter(
    (el) => el.FieldType === 'Drop-downHideShowFields'
  );
  const fieldDefinitions: FieldDefinition[] = FieldDefinitionList.filter(
    (el) => el.FieldType !== 'Drop-downHideShowFields'
  );
  const LeverFieldDefinitionsToShow = pickFieldsToShow({
    FieldDefinitionList: leverFieldDefinitions,
    CompleteObjectInstance
  });
  const OtherFieldDefinitionToShow = pickFieldsToShow({
    FieldDefinitionList: fieldDefinitions,
    CompleteObjectInstance
  });

  const fieldsToShow: FieldDefinition[] = [
    ...LeverFieldDefinitionsToShow,
    ...OtherFieldDefinitionToShow
  ];

  return fieldsToShow;
};

interface IPickFieldsToShow {
  FieldDefinitionList: FieldDefinition[];
  CompleteObjectInstance: CompleteObjectInstance;
}

export const pickFieldsToShow = ({
  FieldDefinitionList,
  CompleteObjectInstance
}: IPickFieldsToShow) => {
  let listOfFieldIdsToRemove: number[] = [];
  let newFieldsToShow = FieldDefinitionList;

  // For each field definition
  FieldDefinitionList.forEach((FieldDefinition: FieldDefinition) => {
    const description = FieldDefinition.FieldDescription;
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
    if (description.length > 0 && CompleteObjectInstance && FieldInstanceList) {
      const removeList: FieldInstance[] = FieldInstanceList.filter(
        (FieldInstance: FieldInstance) => {
          const search = ',';
          const keywords = description.split(search);
          return keywords.includes(FieldInstance.FieldValue);
        }
      );

      if (removeList.length > 0)
        listOfFieldIdsToRemove.push(FieldDefinition.Id);
    }
  });
  listOfFieldIdsToRemove.forEach((item: number) => {
    const idx = newFieldsToShow.findIndex(
      (el: FieldDefinition) => el.Id === item
    );
    newFieldsToShow.splice(idx, 1);
  });

  return newFieldsToShow;
};
