import { withStyles, Button, Theme } from '@material-ui/core';

export const PrimaryButton = withStyles((theme: Theme) => ({
  root: {
    background: '#04094B',
    color: 'white',
    '&:hover': {
      background: `linear-gradient(45deg, ${theme.palette.primary.light} 30%, #EB7333 90%)`,
      color: 'white'
    },
    '&.Mui-disabled': {
      // background: '#04094B',
      color: 'white'
    }
  }
}))(Button);

export const SecondaryButton = withStyles((theme: Theme) => ({
  root: {
    background: 'lightgrey',
    color: theme.palette.text.primary,
    '&:hover': {
      background: `linear-gradient(45deg, ${theme.palette.primary.light} 30%, #EB7333 90%)66`,
      color: theme.palette.text.primary
    },
    '&.Mui-disabled': {
      background: '#04094B',
      color: 'white'
    }
  }
}))(Button);

export const SuccessButton = withStyles((theme: Theme) => ({
  root: {
    background: theme.palette.success.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      background: theme.palette.success.dark
    },
    '&.Mui-disabled': {
      background: theme.palette.success.light
    }
  }
}))(Button);

export const DangerButton = withStyles((theme: Theme) => ({
  root: {
    background: '#FF6347',
    color: 'white',
    '&:hover': {
      opacity: 0.8,
      background: '#FF6347',
      color: 'white'
    },
    '&.Mui-disabled': {
      background: theme.palette.success.light
    }
  }
}))(Button);
