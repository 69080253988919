import { useState, useEffect } from 'react';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance,
  CompleteUserDefinition,
  UserInstance
} from 'types/interfaces';
import { useStyles } from '../styles';
import _ from 'lodash';
import { FieldTypeSelector } from './FieldTypeSelector';
import { Grid, Button } from '@material-ui/core';
import { updateCompleteObjectInstanceList } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';

// Regulated Party Logic
import { Regulated } from 'Utils/RegulatedParties';

interface ICustomerForm {
  CompanyDetailsId: number;
  CompleteObjectInstanceList: CompleteObjectInstance[];
  CompleteUserDefinition: CompleteUserDefinition;
  CustomerDetailsId: number;
  DirectorDetailsId: number;
  EntityObjectDefinitionId: number;
  EntityTypeFieldDefinitionId: number;
  UserInstance: UserInstance;
}

export const CustomerForm = (props: ICustomerForm) => {
  // styles
  const classes = useStyles();
  // data sorting
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const {
    CompanyDetailsId,
    CompleteObjectInstanceList,
    CompleteUserDefinition,
    CustomerDetailsId,
    EntityObjectDefinitionId,
    EntityTypeFieldDefinitionId,
    UserInstance
  } = props;

  let ObjectInQuestionsDefinitionId = CompanyDetailsId;

  // Determine Entity Type
  const EntityType: string | undefined = CompleteObjectInstanceList.find(
    (CompleteObjectInstance) =>
      CompleteObjectInstance?.ObjectInstance?.ObjectDefinitionId ===
      EntityObjectDefinitionId
  )?.FieldInstanceList.find(
    (FieldInstance) =>
      FieldInstance?.FieldDefinitionId === EntityTypeFieldDefinitionId
  )?.FieldValue;

  if (EntityType && Regulated.includes(EntityType)) {
    // * The entity is regulated
    ObjectInQuestionsDefinitionId = CustomerDetailsId;
  }
  // END

  // functions
  const CompanyDetailsDefinition =
    CompleteUserDefinition?.CompleteObjectDefinitionList.find(
      (i: CompleteObjectDefinition) =>
        i.ObjectDefinition.Id === ObjectInQuestionsDefinitionId
    );
  const FieldDefinitionList: FieldDefinition[] | undefined =
    CompanyDetailsDefinition?.FieldDefinitionList;
  const ObjectInQuestion_DetailsInstance: CompleteObjectInstance | undefined =
    CompleteObjectInstanceList?.find(
      (i: CompleteObjectInstance) =>
        i.ObjectInstance.ObjectDefinitionId === ObjectInQuestionsDefinitionId
    );
  const FieldInstanceList: FieldInstance[] | undefined =
    ObjectInQuestion_DetailsInstance?.FieldInstanceList;

  // useStates
  const [state, setState] = useState<CompleteObjectInstance | undefined>(
    ObjectInQuestion_DetailsInstance
  );
  const [newFields, setNewFields] = useState<{ [id: string]: FieldInstance }>(
    {}
  );
  const [modified, setModified] = useState(false);

  // useEffects
  useEffect(() => {
    setState(ObjectInQuestion_DetailsInstance);
  }, [ObjectInQuestionsDefinitionId]);

  // functions
  const handleChange = ({
    FieldDefinition,
    FieldInstance,
    FieldValue
  }: {
    FieldDefinition: FieldDefinition;
    FieldInstance: FieldInstance | undefined;
    FieldValue: string;
  }) => {
    setModified(true);
    let newState = _.cloneDeep(state);
    const index = newState?.FieldInstanceList?.findIndex(
      (i: FieldInstance) => i.Id === FieldInstance?.Id
    );

    const firstField = newState?.FieldInstanceList[0];

    if (!newState) {
      // * No Fields Exist
      // * Create a new fields for each using their FieldDefinition Id to be added as a new field instance on submit
      // ! Using the FieldDefinition Id means this will not work for repeating objects !
      const newFieldInstance: FieldInstance = {
        Id: 0,
        FieldDefinitionId: FieldDefinition?.Id,
        Title: FieldDefinition?.Title,
        FieldValue,
        ProcessInstanceId: 0, // ? We can leave this as 0 for now as we are only updating user data
        ObjectDefinitionId: ObjectInQuestionsDefinitionId,
        ObjectInstanceId: 0, // * new instances take 0
        UserDefinitionId: CompleteUserDefinition?.UserDefinition?.Id,
        UserInstanceId: UserInstance?.Id
      };
      setNewFields((s) => ({ ...s, [FieldDefinition?.Id]: newFieldInstance }));
    } else if (index && index !== -1) {
      // * The Field Exists
      newState.FieldInstanceList[index] = {
        ...newState.FieldInstanceList[index],
        FieldValue
      };
      setState(newState);
    } else if (firstField) {
      // * The Field Does Not Exist, but there are others
      // * Save this is to local state to be added as a new field instance on submit
      const newFieldInstance: FieldInstance = {
        ...firstField,
        FieldValue,
        Id: 0,
        FieldDefinitionId: FieldDefinition?.Id,
        Title: FieldDefinition?.Title
      };
      setNewFields((s) => ({ ...s, [FieldDefinition?.Id]: newFieldInstance }));
    }
  };

  const handleSubmit = async () => {
    let postState = _.cloneDeep(state);
    if (state) {
      Object.values(newFields).forEach((field) => {
        postState?.FieldInstanceList.push(field);
      });
    } else if (CompanyDetailsDefinition) {
      // * No Object instance exists so we will have to make one
      // * Create an entire COMPANY DETAILS object instance
      const newCompleteObjectInstance: CompleteObjectInstance = {
        ObjectInstance: {
          Id: 0,
          ItemOrder: 0,
          ObjectDefinitionId: ObjectInQuestionsDefinitionId,
          ProcessInstanceId: 0, // ? We can leave this as 0 for now as we are only updating user data
          Selected: false,
          Title: CompanyDetailsDefinition?.ObjectDefinition?.Title,
          UserDefinitionId: parseInt(
            CompleteUserDefinition?.UserDefinition?.Id as unknown as string
          ),
          UserInstanceId: UserInstance?.Id
        },
        FieldInstanceList: Object.values(newFields).map((field) => field)
      };

      postState = newCompleteObjectInstance;
    }

    if (postState) {
      updateCompleteObjectInstanceList({
        data: postState,
        action: 'UPDATE',
        baseUrl,
        ProcessInstanceId: 0,
        fetchPolicy: 'no-cache'
      });
      setModified(false);
    }
  };

  // render
  return (
    <Grid container className={classes.userContainer}>
      <h1>{CompanyDetailsDefinition?.ObjectDefinition?.Title}</h1>
      <Grid container spacing={2}>
        {FieldDefinitionList &&
          FieldDefinitionList.map((FieldDefinition, idx) => {
            const FieldInstance = FieldInstanceList?.find(
              (i) => i.FieldDefinitionId === FieldDefinition?.Id
            );

            return (
              <Grid item xs={6} key={idx}>
                <FieldTypeSelector
                  FieldInstance={FieldInstance}
                  FieldDefinition={FieldDefinition}
                  handleChange={handleChange}
                  state={state}
                />
              </Grid>
            );
          })}
      </Grid>

      <div style={{ height: 16 }} />

      <Grid container>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
            disabled={!modified}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
