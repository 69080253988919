import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import { CustomDialog } from 'common/Dialog';
import { Email, Broker } from 'components/Marketing/types';
import { injectEmailDisclaimer } from 'components/Marketing/Designs/utils';
import { useInstance } from 'hooks/useInstance';
import { IDesign } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      paddingTop: 30
    }
  })
);

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  email: Email | IDesign;
  disclaimerHtml?: string;
}

const EmailViewer = ({ open, setOpen, email, disclaimerHtml }: Props) => {
  const classes = useStyles();

  const email_config: keyof Email = 'email_config';

  const emailHtml =
    email_config in email
      ? email.email_config?.html_content
      : email.html_content;

  return (
    <CustomDialog maxSize="md" handleClose={() => setOpen(false)} open={open}>
      <DialogTitle className={classes.title}>
        <h2>{email.name}</h2>
      </DialogTitle>

      <DialogContent>
        <EmailDesign emailHtml={emailHtml} disclaimerHtml={disclaimerHtml} />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </CustomDialog>
  );
};

export const EmailDesign = ({
  emailHtml,
  disclaimerHtml
}: {
  emailHtml: string;
  disclaimerHtml?: string;
}) => {
  const { isAFS, isSynergy } = useInstance();

  let finalHtml = '';

  // NOTE: emailHtml may already contain the disclaimer (when injected from UpserCampaignDialog)
  if (disclaimerHtml == undefined) finalHtml = emailHtml;
  else {
    finalHtml = injectEmailDisclaimer({
      disclaimerHtml,
      emailHtml,
      broker: isAFS ? Broker.AFS : Broker.Synergy
    });
  }

  return (
    <iframe
      srcDoc={finalHtml}
      width="100%"
      height="900"
      // style={{
      //   width: '600px'
      //   // border: 'none'
      //   // boxSizing: 'content-box'
      // }}
    />
  );
};

export default EmailViewer;
