import { firebase } from 'redux/firebase';
import { store } from 'redux/store';
import { createTheme } from '@material-ui/core/styles';
import axios, { AxiosRequestConfig } from 'axios';
import { BugTracker } from 'Utils/Bugtracker';
import { UPDATED_THEME, PROXY_SETTINGS } from './types';

import { pinkDark, orangeDark, purpleDark } from '../../theme/paletteDark';
import { typography as typographyDark } from '../../theme/typographyDark';
import { pink, orange } from '../../theme/palette';
import typography from '../../theme/typography';
import overrides from '../../theme/overrides';
import overridesDark from '../../theme/overridesDark';

const synergy = '#cc0066';
const afs = '#eb7434';
const compliance = '#2c2e6f';
const approved = '#00C3FE';
const dark_green = '#0F5946';
const purple = '#5C1A4F';

export const toggleThemeMode = async (themeName, changeLightness) => {
  let { type } = store.getState().config.theme.palette;
  let Id = store.getState()?.fb?.proxy_settings?.Id;
  themeName =
    themeName === undefined ? store.getState().config.themeName : themeName;

  // console.log({ themeName });

  const color = () => {
    switch (themeName) {
      case 'Approved':
        return approved;
      case 'Compliance':
        return compliance;
      case 'Synergy':
        return synergy;
      case 'Dark Green':
        return dark_green;
      case 'Purple':
        return purple;
      default:
        return afs;
    }
  };

  // console.log(color());

  const makeDark = async (themeName: string, changeLightness: boolean) => {
    // let paletteDark =
    //   themeName === 'AFS'
    //     ? orangeDark
    //     : themeName === 'Synergy'
    //     ? pinkDark
    //       : purpleDark;

    let paletteDark = orangeDark;
    let theme = {};
    theme['palette'] = paletteDark;
    theme['typography'] = typographyDark;
    theme['overrides'] = overrides;
    theme['palette']['primary']['light'] = color();
    theme['palette']['primary']['lighttrans'] = `${color()}66`;

    if (Id && changeLightness) {
      store.dispatch({
        type: PROXY_SETTINGS,
        payload: { Id, darkMode: true }
      });
      updateUserThemeinProxy({ Id, darkMode: true });
    }

    return store.dispatch({ type: UPDATED_THEME, payload: createTheme(theme) });
  };

  const makeLight = async (themeName: string, changeLightness: boolean) => {
    // let palette =
    //   themeName === 'AFS' ? orange : themeName === 'Synergy' ? pink : purple;
    let palette = orange;

    let theme = {};
    theme['overrides'] = overrides;
    theme['palette'] = palette;
    theme['typography'] = typography;
    theme['palette']['primary']['light'] = color();
    theme['palette']['primary']['lighttrans'] = `${color()}66`;

    if (Id && changeLightness) {
      store.dispatch({
        type: PROXY_SETTINGS,
        payload: { Id, darkMode: false }
      });
      updateUserThemeinProxy({ Id, darkMode: false });
    }

    return store.dispatch({
      type: UPDATED_THEME,
      payload: createTheme(theme)
    });
  };

  // Light Mode to Dark Mode
  if (type === 'light') {
    if (changeLightness) return makeDark(themeName, changeLightness);
    return makeLight(themeName, changeLightness);
  }

  // Dark to Light Mode
  if (type === 'dark') {
    if (changeLightness) return makeLight(themeName, changeLightness);
    return makeDark(themeName, changeLightness);
  }
};

export const updateTheme = (
  themeName: string | undefined,
  changeLightness = false
) => toggleThemeMode(themeName, changeLightness);

const updateUserThemeinProxy = async ({ Id, darkMode }) => {
  return firebase
    .firestore()
    .collection('userAccount')
    .doc(Id.toString())
    .update({ darkMode });
};
