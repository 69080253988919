import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import InlineTextField from 'components/Fields/InlineTextField';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { Alert } from '@material-ui/lab';
import QuickUser from 'components/User/UserLozenge';
import Time from 'common/Time';
import {
  CompleteObjectDefinition,
  FieldInstance,
  FieldDefinitionDict,
  ObjectDefinition,
  UserInstance
} from 'types/interfaces';
import { useProcess, useGlobal } from 'hooks';
import { FieldDefinitionTitle } from 'components/Stepper/components/Steps/ActiveStep/components/FieldDefinitionTitle';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';

interface IUserFieldInstanceListObject {
  [UserInstanceId: string]: FieldInstance[];
}

const useStyles = makeStyles((theme) => ({
  root: { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
  item: { padding: theme.spacing(4) },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(1)
  },
  check: { color: theme.palette.success.main },
  warning: { color: theme.palette.warning.main }
}));

const Component = ({ object }: { object: CompleteObjectDefinition }) => {
  const { ObjectDefinition, FieldDefinitionDict } = object;
  const { currentDeal, processInstanceFields } = useProcess();

  const ProcessInstanceId = currentDeal.ProcessInstance.Id;

  // This needs to fetch data if the deal has been changed, maybe just get all data every time for now
  const InstanceFields: FieldInstance[] =
    processInstanceFields[ProcessInstanceId];

  const FieldInstanceList: FieldInstance[] = InstanceFields.filter(
    (item) => item.ObjectDefinitionId === ObjectDefinition.Id
  );

  if (!FieldInstanceList) return <div>No FieldInstanceList</div>;

  const userFieldInstanceListObject: IUserFieldInstanceListObject | undefined =
    FieldInstanceList && groupBy(FieldInstanceList, 'UserInstanceId');

  return (
    <>
      {userFieldInstanceListObject ? (
        Object.keys(userFieldInstanceListObject).map(
          (UserInstanceId, index) => (
            <SingleObject
              FieldDefinitionDict={FieldDefinitionDict}
              ObjectDefinition={ObjectDefinition}
              UserInstanceId={UserInstanceId}
              key={index}
              userFieldInstanceListObject={userFieldInstanceListObject}
            />
          )
        )
      ) : (
        <div />
      )}
    </>
  );
};

const SingleObject = ({
  userFieldInstanceListObject,
  UserInstanceId,
  FieldDefinitionDict,
  ObjectDefinition
}: {
  userFieldInstanceListObject: IUserFieldInstanceListObject;
  UserInstanceId: string;
  FieldDefinitionDict: FieldDefinitionDict;
  ObjectDefinition: ObjectDefinition;
}) => {
  const { config } = useGlobal();
  const classes = useStyles();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const [user, setUser] = React.useState<UserInstance>({} as UserInstance);

  const getUser = async () => {
    const newUserInstanceId = parseInt(UserInstanceId);
    const response = await getQuickLiteUser({
      baseUrl,
      UserInstanceId: newUserInstanceId,
      action: 'GET'
    });

    const UserInstance = response?.UserInstance;
    if (UserInstance) setUser(UserInstance);
  };

  React.useEffect(() => {
    getUser();
  }, []);

  return (
    <div style={{ padding: 20, width: '100%' }}>
      {user && (
        <Typography style={{ marginBottom: 16 }}>
          <QuickUser user={user} />
        </Typography>
      )}

      <Grid container spacing={2}>
        {userFieldInstanceListObject &&
          userFieldInstanceListObject[UserInstanceId] &&
          userFieldInstanceListObject[UserInstanceId].map(
            (props: FieldInstance, index: number) => {
              if (!FieldDefinitionDict[props.FieldDefinitionId]) return <div />;

              const { Title, FieldType, FieldDescription } =
                FieldDefinitionDict[props.FieldDefinitionId];

              // fieldtype 'For Information Only' will be an alert component
              if (FieldType.includes('For Information Only')) {
                const hexColorMatch = FieldDescription.match(
                  /hexColor-\[([^[\]]*)\]/
                );

                const borderColorMatch =
                  FieldDescription.match(/border-\[([^[\]]*)\]/);

                const hexColor = hexColorMatch ? hexColorMatch[1] : '';
                const borderColor = borderColorMatch ? borderColorMatch[1] : '';

                const style = {
                  backgroundColor: hexColor,
                  ...(borderColor && { border: `2px solid ${borderColor}` })
                };

                return (
                  <Grid item key={index} xs={12}>
                    <Alert severity="info" style={style} elevation={1}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {Title}
                      </Typography>
                    </Alert>
                  </Grid>
                );
              }

              return (
                <Grid className={classes.paper} item key={index} xs={6}>
                  <div style={{ marginRight: 16 }}>
                    {props && props.FieldValue === '' ? (
                      <div>
                        <ClearIcon className={classes.warning} />
                      </div>
                    ) : (
                      <div>
                        {props.FieldValue === 'false' ? (
                          <CheckIcon style={{ opacity: 0, height: 0 }} />
                        ) : (
                          <CheckIcon className={classes.check} />
                        )}
                      </div>
                    )}
                  </div>
                  {FieldType === 'Signature' ? (
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="flex-start">
                      <Grid item>
                        <Typography
                          color="textPrimary"
                          style={{ marginRight: 16 }}
                          variant="h6">
                          {Title}:
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Time time={props.LastModified} type="date" />
                      </Grid>
                    </Grid>
                  ) : (
                    <div style={{ marginRight: 16 }}>
                      <FieldDefinitionTitle title={Title} height={'h6'} />
                    </div>
                  )}
                  <InlineTextField
                    FieldDefinition={
                      FieldDefinitionDict[props.FieldDefinitionId]
                    }
                    FieldInstance={props}
                    ObjectDefinition={ObjectDefinition}
                    readOnly
                    type={FieldType}
                    value={props.FieldValue}
                    fields={[]}
                    ProcessStepSelectionType={''}
                  />

                  {config.TimeStampFields.includes(
                    props.FieldDefinitionId.toString()
                  ) && (
                    <Grid item>
                      <Time time={props.LastModified} type="date" />
                    </Grid>
                  )}
                </Grid>
              );
            }
          )}
      </Grid>
    </div>
  );
};

export default Component;

const groupBy: (
  xs: FieldInstance[],
  key: string
) => { [UserInstanceId: string]: FieldInstance[] } = function (xs, key) {
  return xs.reduce(function (rv, x: FieldInstance) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
