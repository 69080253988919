import { useState } from 'react';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  CompleteUserDefinition,
  FieldDefinition,
  FieldInstance,
  UserInstance
} from 'types/interfaces';
import { useStyles } from '../styles';
import _ from 'lodash';
import { FieldTypeSelector } from './FieldTypeSelector';
import { Grid, Button } from '@material-ui/core';
import { updateCompleteObjectInstanceList } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';

interface IHandleChange {
  FieldDefinition: FieldDefinition;
  FieldInstance: FieldInstance | undefined;
  FieldValue: string;
}

interface IContactForm {
  CompanyDetailsId: number;
  CompleteObjectInstanceList: CompleteObjectInstance[];
  CompleteUserDefinition: CompleteUserDefinition;
  CustomerDetailsId: number;
  DirectorDetailsId: number;
  EntityObjectDefinitionId: number;
  EntityTypeFieldDefinitionId: number;
  UserInstance: UserInstance;
  DirectorNumberFieldDefinitionId: number;
}

export const ContactForm = (props: IContactForm) => {
  // styles
  const classes = useStyles();
  // data sorting
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const {
    CompleteObjectInstanceList,
    CompleteUserDefinition,
    DirectorDetailsId,
    UserInstance,
    DirectorNumberFieldDefinitionId
  } = props;
  const DirectorDetailsDefinition =
    CompleteUserDefinition?.CompleteObjectDefinitionList.find(
      (i: CompleteObjectDefinition) =>
        i.ObjectDefinition.Id === DirectorDetailsId
    );
  const FieldDefinitionList: FieldDefinition[] | undefined =
    DirectorDetailsDefinition?.FieldDefinitionList;
  const DirectorDetailsInstance: CompleteObjectInstance | undefined =
    CompleteObjectInstanceList?.find(
      (CompleteObjectInstance: CompleteObjectInstance) =>
        CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
        DirectorDetailsId
    );

  const FieldInstanceList: FieldInstance[] | undefined =
    DirectorDetailsInstance?.FieldInstanceList;

  const { ObjectTitle } = isADirector({
    DirectorDetailsDefinition,
    FieldInstanceList,
    DirectorNumberFieldDefinitionId
  });

  // useStates
  const [state, setState] = useState<CompleteObjectInstance | undefined>(
    DirectorDetailsInstance
  );
  const [newFields, setNewFields] = useState<{ [id: string]: FieldInstance }>(
    {}
  );
  const [modified, setModified] = useState(false);

  // functions
  const handleChange = ({
    FieldDefinition,
    FieldInstance,
    FieldValue
  }: IHandleChange) => {
    setModified(true);
    let newState = _.cloneDeep(state) as CompleteObjectInstance;
    const index = newState?.FieldInstanceList?.findIndex(
      (item: FieldInstance) => item.Id === FieldInstance?.Id
    );

    const firstField = newState?.FieldInstanceList[0];

    if (!newState) {
      // * No Fields Exist
      // * Create a new fields for each using their FieldDefinition Id to be added as a new field instance on submit
      // ! Using the FieldDefinition Id means this will not work for repeating objects !
      const newFieldInstance: FieldInstance = {
        Id: 0,
        FieldDefinitionId: FieldDefinition?.Id,
        Title: FieldDefinition?.Title,
        FieldValue,
        ProcessInstanceId: 0, // ? We can leave this as 0 for now as we are only updating user data
        ObjectDefinitionId: DirectorDetailsId,
        ObjectInstanceId: 0, // * new instances take 0
        UserDefinitionId: CompleteUserDefinition?.UserDefinition?.Id,
        UserInstanceId: UserInstance?.Id
      };
      return setNewFields((s) => ({
        ...s,
        [FieldDefinition?.Id]: newFieldInstance
      }));
    } else if ((index && index !== -1) || index === 0) {
      // * The Field Exists
      newState.FieldInstanceList[index] = {
        ...newState.FieldInstanceList[index],
        FieldValue
      };
      return setState(newState);
    } else if (firstField) {
      // * The Field Does Not Exist, but there are others
      // * Save this is to local state to be added as a new field instance on submit
      const newFieldInstance: FieldInstance = {
        ...firstField,
        FieldValue,
        Id: 0,
        FieldDefinitionId: FieldDefinition?.Id,
        Title: FieldDefinition?.Title
      };
      return setNewFields((s) => ({
        ...s,
        [FieldDefinition?.Id]: newFieldInstance
      }));
    }
  };

  const handleSubmit = async () => {
    let postState = _.cloneDeep(state);
    if (state) {
      Object.values(newFields).forEach((field) => {
        postState?.FieldInstanceList.push(field);
      });
    } else if (DirectorDetailsDefinition) {
      // * No Object instance exists so we will have to make one
      // * Create an entire COMPANY DETAILS object instance
      const newCompleteObjectInstance: CompleteObjectInstance = {
        ObjectInstance: {
          Id: 0,
          ItemOrder: 0,
          ObjectDefinitionId: DirectorDetailsId,
          ProcessInstanceId: 0, // ? We can leave this as 0 for now as we are only updating user data
          Selected: false,
          Title: DirectorDetailsDefinition?.ObjectDefinition?.Title,
          UserDefinitionId: parseInt(
            CompleteUserDefinition?.UserDefinition?.Id as unknown as string
          ),
          UserInstanceId: UserInstance?.Id
        },
        FieldInstanceList: Object.values(newFields).map((field) => field)
      };

      postState = newCompleteObjectInstance;
    }

    // TODO: Check the data and the issue with some fields not updating correctly.
    // This may be due to the response data coming back anyway even if the database is not updated...
    // It may also need to use the Redflag import potentially?? does it create new objects?
    // console.log({ postState });

    if (postState) {
      updateCompleteObjectInstanceList({
        data: postState,
        action: 'UPDATE',
        baseUrl,
        ProcessInstanceId: 0,
        fetchPolicy: 'no-cache'
      });
      setModified(false);
    }
  };

  return (
    <Grid container className={classes.userContainer}>
      <h1>{ObjectTitle}</h1>

      <Grid container spacing={2}>
        {FieldDefinitionList &&
          FieldDefinitionList.map(
            (FieldDefinition: FieldDefinition, idx: number) => {
              const FieldInstance = FieldInstanceList?.find(
                (FieldInstance: FieldInstance) =>
                  FieldInstance.FieldDefinitionId === FieldDefinition?.Id
              );

              // If it is the director number field, return nothing
              if (FieldDefinition.Id === DirectorNumberFieldDefinitionId) {
                if (
                  FieldInstance?.FieldValue === '' ||
                  FieldInstance?.FieldValue === undefined
                ) {
                  // Do something
                  return <div key={idx} />;
                }
              } // END

              return (
                <Grid item xs={6} key={idx}>
                  <FieldTypeSelector
                    FieldInstance={FieldInstance}
                    FieldDefinition={FieldDefinition}
                    handleChange={handleChange}
                    state={state}
                  />
                </Grid>
              );
            }
          )}
      </Grid>

      <div style={{ height: 16 }} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
            disabled={!modified}>
            Save
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const isADirector = ({
  DirectorDetailsDefinition,
  FieldInstanceList,
  DirectorNumberFieldDefinitionId
}) => {
  // * Add a check for the director number field to see if it is empty and if so determine the object title
  let ObjectTitle = DirectorDetailsDefinition?.ObjectDefinition?.Title;

  // find the fieldFieldInstanceList instance for the director number
  const DirectorNumberFieldInstance: FieldInstance | undefined =
    FieldInstanceList?.find((FieldInstance: FieldInstance) => {
      return (
        FieldInstance.FieldDefinitionId === DirectorNumberFieldDefinitionId
      );
    });

  if (
    !DirectorNumberFieldInstance ||
    DirectorNumberFieldInstance.FieldValue === ''
  ) {
    ObjectTitle = 'Contact Details';
  }
  // * END

  return { ObjectTitle } as { ObjectTitle: string };
};
