import axios from 'axios';

import { firebase } from 'redux/firebase';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';

export const deleteList = async (id) => {
  const token = await firebase.auth().currentUser?.getIdToken();

  const url = `${process.env.REACT_APP_PROXY}/marketing/lists/${id}`;

  return axios
    .delete(url, { headers: { token } })
    .then(() => {
      gtag.event({
        feature: 'Marketing',
        action: 'List Delete: Success',
        message: `List: ${id} Delete: Success`
      });
    })
    .catch((e) => {
      BugTracker.notify(e);
    });
};
