import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { firebase } from 'redux/firebase';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { useTypedSelector } from 'redux/reducers';
import NoticeBoard from 'views/Profile/NoticeBoard';
import { BugTracker } from 'Utils/Bugtracker';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<any>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Dismissal {
  Id: string;
  InstanceNoticeBoardLastModified: string | Date;
  PlatformBoardLastModified: string | Date;
  InstanceNoticeBoardSetter: (Boolean) => void;
  PlatformNoticeBoardSetter: (Boolean) => void;
}

const AlertDialogSlide = () => {
  if (window.disableNoticeBoard) return null;

  const userSocket = useTypedSelector((s) => s.fb.proxy_settings);
  const { user } = useTypedSelector((s) => s.user);
  const subSystemUser = user.SystemAccess <= 4;

  const configSettings = useTypedSelector((s) => s.config.settings);
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  const [instanceNoticeBoardCaller, setInstanceNoticeBoardCaller] =
    React.useState<boolean>(false);
  const [platformNoticeBoardCaller, setPlatformNoticeBoardCaller] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (!userSocket?.['guideStep']) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    GetFirestoreUserDismissalAll({
      Id: user.Id.toString(),
      InstanceNoticeBoardLastModified:
        configSettings?.InstanceNoticeBoardLastModified,
      PlatformBoardLastModified: configSettings?.BrandNoticeBoardLastModified,
      InstanceNoticeBoardSetter: setInstanceNoticeBoardCaller,
      PlatformNoticeBoardSetter: setPlatformNoticeBoardCaller
    });
  }, []);

  const handleDismissal = () => {
    updateDismissal({
      lastModified: configSettings?.InstanceNoticeBoardLastModified,
      platformLastModified: configSettings?.BrandNoticeBoardLastModified,
      Id: user.Id
    });
    setOpen(false);
  };

  const handleNoticeBoard = () => {
    if (instanceNoticeBoardCaller && platformNoticeBoardCaller) return true;
    if (!instanceNoticeBoardCaller && platformNoticeBoardCaller) return true;
    if (instanceNoticeBoardCaller && !platformNoticeBoardCaller) return true;
    return false;
  };

  if (!handleNoticeBoard()) return <div />;

  if (subSystemUser) {
    return <div />;
  }

  return (
    <div>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <DialogContent>
          <NoticeBoard />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDismissal} color="primary">
            Dismiss Noticeboard
          </Button>
          <Button
            onClick={handleClose}
            color="primary"
            data-cy="noticeboard_close_btn">
            close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AlertDialogSlide;

export const updateDismissal = async ({
  lastModified,
  platformLastModified,
  Id
}) => {
  if (!lastModified || !platformLastModified || !Id) return;
  return firebase
    .firestore()
    .collection('userAccount')
    .doc(Id.toString())
    .update({
      NoticeBoardLastModified: lastModified,
      PlatformBoardLastModified: platformLastModified
    });
};

export const GetFirestoreUserDismissalAll = async (dismiss: Dismissal) => {
  await firebase
    .firestore()
    .collection('userAccount')
    .doc(dismiss.Id)
    .get()
    .then((snapshot) => {
      if (snapshot.exists) {
        const isInstanceModified: string =
          snapshot?.data()?.NoticeBoardLastModified;
        const isPlatformModified: string =
          snapshot?.data()?.PlatformBoardLastModified;

        // console.log({ isInstanceModified, isPlatformModified });

        if (dismiss.InstanceNoticeBoardLastModified === isInstanceModified) {
          dismiss.InstanceNoticeBoardSetter(false);
        } else dismiss.InstanceNoticeBoardSetter(true);

        if (dismiss.PlatformBoardLastModified === isPlatformModified) {
          dismiss.PlatformNoticeBoardSetter(false);
        } else dismiss.PlatformNoticeBoardSetter(true);
      }
    })
    .catch((e) => {
      BugTracker.notify(e);
    });
};
