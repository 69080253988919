import { TKnownCategory } from 'components/MessageHub/context/interface';
import {
  IConversation,
  IMicrosoftMessages
} from 'components/MessageHub/interfaces';
import { useMemo } from 'react';

const getSortedBookmarks = ({ conversationsArray, bookmarkedConversations }) =>
  useMemo(() => {
    const bookmarkedIds = new Set(
      bookmarkedConversations.map(
        (conversation: IConversation) =>
          conversation.parentMessage.conversationId
      )
    );

    return conversationsArray.filter(
      (conversation: IConversation) =>
        !bookmarkedIds.has(conversation.parentMessage.conversationId)
    );
  }, [conversationsArray, bookmarkedConversations]);

export default getSortedBookmarks;

export const isBookmarkInCurrentFolder = (
  bookmarkedConversations: IConversation[],
  selectedCategory: {
    category: TKnownCategory | string;
    folderId: string | undefined;
  }
) => {
  return bookmarkedConversations.some(
    (conversation) =>
      conversation.parentMessage.parentFolderId === selectedCategory.folderId
  );
};
