import {
  DialogContent,
  IconButton,
  TextField,
  Grid,
  Box,
  InputAdornment,
  Button,
  Chip,
  Avatar,
  Tooltip,
  Typography,
  Paper
} from '@material-ui/core';
import { useContext, useState } from 'react';
import { MessageHubContext } from '../../../context/MessageHubContext';
import {
  Close as CloseIcon,
  Maximize,
  Minimize as MinimizeIcon,
  ZoomOutMap as ZoomOutMapIcon,
  Delete as DeleteIcon,
  AttachFile
} from '@material-ui/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { IEmailCompositionBody, IFile } from '../../../interfaces';
import { validateEmail } from 'helpers/validateEmail';
import { Alert, AlertTitle } from '@material-ui/lab';
import { theme } from 'theme';
import './my-ckeditor-style.css';
import { useSenderColors } from 'components/MessageHub/hooks/useSenderColors';
import SendIcon from '@material-ui/icons/Send';
import {
  EmailCompositionContext,
  EmailCompositionCreationContext
} from '../context/ComposeWindowsContext';
import EmailField from './EmailField';
import { useTypedSelector } from 'redux/reducers';
import { DropTarget } from 'common';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import {
  FileUpload,
  gridArray,
  renderFileItem
} from 'components/MessageHub/utils/Files';

const Email = ({
  panelIndex,
  isExpanded
}: {
  panelIndex: number;
  isExpanded: boolean;
}) => {
  const user = useTypedSelector((s) => s.user.user);
  const { showBcc, setShowBcc, setShowCc, showCc } = useContext(
    EmailCompositionContext
  );
  const { setAllEmailBodies, sendEmail, allEmailBodies } = useContext(
    EmailCompositionCreationContext
  );

  const { grabRelationshipEmails } = useContext(MessageHubContext);
  const getSenderColor = useSenderColors();

  const handleUploadToFile = async (file: File): Promise<File> => {
    const fileWithModifiedDate = new File([file], file.name, {
      lastModified: file.lastModified,
      type: file.type
    });

    return fileWithModifiedDate;
  };

  const handleFileSelection = async (newFiles: any): Promise<void> => {
    try {
      const promises = newFiles.map(handleUploadToFile);
      const newFileArray = await Promise.all(promises);

      setAllEmailBodies((prevAllEmailBodies) => {
        const newAllEmailBodies = [...prevAllEmailBodies];
        const currentEmailBody = newAllEmailBodies[panelIndex];

        if (!currentEmailBody.attachments) {
          currentEmailBody.attachments = [];
        }

        currentEmailBody.attachments = [
          ...currentEmailBody.attachments,
          ...newFileArray
        ];
        newAllEmailBodies[panelIndex] = currentEmailBody;
        return newAllEmailBodies;
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const handleDeleteChip = ({ index, type }) => {
    setAllEmailBodies((prevAllEmailBodies) => {
      const newAllEmailBodies = [...prevAllEmailBodies];
      const currentEmailBody = newAllEmailBodies[panelIndex];
      const updatedArray = currentEmailBody[type].filter((_, i) => i !== index);

      newAllEmailBodies[panelIndex] = {
        ...currentEmailBody,
        [type]: updatedArray
      };

      return newAllEmailBodies;
    });
  };

  const handleBccClick = () => {
    setShowBcc(true);
  };

  const handleCcClick = () => {
    setShowCc(true);
  };

  const toEndAdornments = (
    <>
      <Box
        component="span"
        onClick={handleBccClick}
        style={{ cursor: 'pointer', marginRight: '8px' }}>
        Bcc
      </Box>
      <Box
        component="span"
        onClick={handleCcClick}
        style={{ cursor: 'pointer' }}>
        Cc
      </Box>
    </>
  );

  return (
    <DialogContent>
      <Grid container direction="column" spacing={2}>
        {selectedEmails({
          type: 'to',
          allEmailBodies,
          panelIndex,
          handleDeleteChip,
          getSenderColor
        })}
        <Grid item style={{ width: '100%', display: 'flex', height: '100%' }}>
          <EmailField
            label="To"
            type="to"
            handleDeleteClick={() => {}}
            onFocus={async () => {
              return await grabRelationshipEmails(user);
            }}
            endAdornments={toEndAdornments}
            panelIndex={panelIndex}
            setRecipients={setAllEmailBodies}
            recipients={allEmailBodies}
          />
        </Grid>
        {showBcc && (
          <>
            {selectedEmails({
              type: 'bcc',
              allEmailBodies,
              panelIndex,
              handleDeleteChip,
              getSenderColor
            })}

            <Grid item>
              <EmailField
                label="Bcc"
                type="Bcc"
                handleDeleteClick={() => setShowBcc(false)}
                onFocus={async () => {
                  return await grabRelationshipEmails(user);
                }}
                endAdornments={undefined}
                panelIndex={panelIndex}
                setRecipients={setAllEmailBodies}
                recipients={allEmailBodies}
              />
            </Grid>
          </>
        )}
        {showCc && (
          <>
            {selectedEmails({
              type: 'cc',
              allEmailBodies,
              panelIndex,
              handleDeleteChip,
              getSenderColor
            })}

            <Grid item>
              <EmailField
                label="Cc"
                type="cc"
                handleDeleteClick={() => setShowCc(false)}
                onFocus={async () => {
                  return await grabRelationshipEmails(user);
                }}
                endAdornments={undefined}
                panelIndex={panelIndex}
                setRecipients={setAllEmailBodies}
                recipients={allEmailBodies}
              />
            </Grid>
          </>
        )}
        <Grid item>
          <TextField
            onChange={(e) => {
              const newSubject = e.target.value;
              setAllEmailBodies((prevAllEmailBodies) => {
                const newAllEmailBodies = [...prevAllEmailBodies];
                const currentEmailBody = newAllEmailBodies[panelIndex];

                newAllEmailBodies[panelIndex] = {
                  ...currentEmailBody,
                  subject: newSubject
                };

                return newAllEmailBodies;
              });
            }}
            required
            fullWidth
            label={
              allEmailBodies[panelIndex].subject !== ''
                ? allEmailBodies[panelIndex].subject
                : 'Subject'
            }
            variant="outlined"
          />
        </Grid>

        <Grid container direction="column" spacing={2}>
          {gridArray({
            files:
              allEmailBodies[panelIndex].attachments?.filter((file) =>
                file.type.includes('image/')
              ) || [],
            gridSize: 5
          }).map((fileChunk, chunkIndex) => (
            <Grid key={`photo-row-${chunkIndex}`} item container spacing={1}>
              {fileChunk.map((file, index) => (
                <Grid item key={index}>
                  {renderFileItem(file, isExpanded)}
                </Grid>
              ))}
            </Grid>
          ))}

          {gridArray({
            files:
              allEmailBodies[panelIndex].attachments?.filter(
                (file) => !file.type.includes('image/')
              ) || [],
            gridSize: 2
          }).map((fileChunk, chunkIndex) => (
            <Grid key={`doc-row-${chunkIndex}`} item container spacing={1}>
              {fileChunk.map((file, index) => (
                <Grid item key={index}>
                  {renderFileItem(file, isExpanded)}
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>

        {allEmailBodies[panelIndex]?.attachments?.length === 0 && (
          <div style={{ padding: theme.spacing(1) }} />
        )}

        <Grid item>
          <FileUpload handleFileUpload={handleFileSelection} />
        </Grid>

        <Grid item style={{ width: '100%' }}>
          <div className="ckeditor-custom">
            <CKEditor
              editor={ClassicEditor}
              data={allEmailBodies[panelIndex].body}
              config={{
                toolbar: [
                  'heading',
                  '|',
                  'bold',
                  'italic',
                  'link',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                  'undo',
                  'redo'
                ]
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setAllEmailBodies((prevAllEmailBodies) => {
                  const newAllEmailBodies = [...prevAllEmailBodies];
                  const currentEmailBody = newAllEmailBodies[panelIndex];

                  newAllEmailBodies[panelIndex] = {
                    ...currentEmailBody,
                    body: decodeURIComponent(data)
                  };

                  return newAllEmailBodies;
                });
              }}
            />
          </div>
        </Grid>

        <div style={{ padding: theme.spacing(1) }}>
          <Tooltip
            title={getTooltipMessage({ allEmailBodies, panelIndex })}
            arrow
            placement="top-start"
            PopperProps={{
              popperOptions: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0]
                    }
                  }
                ]
              }
            }}>
            <span>
              <Button
                disabled={
                  allEmailBodies[panelIndex].to.length <= 0 ||
                  allEmailBodies[panelIndex].subject === ''
                }
                variant="contained"
                color="primary"
                onClick={() => sendEmail(panelIndex)}
                endIcon={<SendIcon />}>
                Send
              </Button>
            </span>
          </Tooltip>
        </div>
      </Grid>
    </DialogContent>
  );
};

const getTooltipMessage = ({
  allEmailBodies,
  panelIndex
}: {
  allEmailBodies: IEmailCompositionBody[];
  panelIndex: number;
}) => {
  let message = '';
  if (allEmailBodies[panelIndex].to.length <= 0) {
    message += 'Recipients are required. ';
  }
  if (allEmailBodies[panelIndex].subject === '') {
    message += 'Subject is required. ';
  }
  return message;
};

const selectedEmails = ({
  type,
  allEmailBodies,
  panelIndex,
  handleDeleteChip,
  getSenderColor
}: {
  type: string;
  allEmailBodies: IEmailCompositionBody[];
  handleDeleteChip: ({ index, type }: { index: number; type: string }) => void;
  panelIndex: number;
  getSenderColor: (sender: string) => string;
}) => {
  if (
    allEmailBodies &&
    allEmailBodies[panelIndex][type] &&
    allEmailBodies[panelIndex][type].length > 0
  ) {
    return (
      <div style={{ padding: theme.spacing(1) }}>
        <Alert severity="info" elevation={2}>
          <AlertTitle>
            {allEmailBodies[panelIndex][type].length} Recipient
            {allEmailBodies[panelIndex][type].length > 1 && 's'}
          </AlertTitle>
          <Box>
            {allEmailBodies[panelIndex][type].map((email, index) => (
              <Chip
                key={index}
                avatar={
                  <Avatar
                    style={{
                      backgroundColor: getSenderColor(email)
                    }}>
                    {email[0].toUpperCase()}
                  </Avatar>
                }
                style={{ margin: '2px' }}
                label={email}
                variant="outlined"
                onDelete={() => handleDeleteChip({ index, type })}
              />
            ))}
          </Box>
        </Alert>
      </div>
    );
  }
};

export default Email;
