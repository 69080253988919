import React from 'react';
import { tableIcons } from '../../ProcessTable/tableIcons';
import MaterialTable from '@material-table/core';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DirectorsDetail } from '../TabMenu/components/Directors';
import RatioAnalysisTable from '../TabMenu/components/RatioAnalysis/LegacyCharts/RatioAnalysisTable';
import { OffkeyDirectorLite } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IRedFlagDirectorship } from 'components/OffkeyCompanyAndDirectorSearch/helper';

const Table = (props) => {
  const { title, data, columns, options, onRowClick } = props;
  const theme = useTheme();
  const isDetail = Boolean(title === 'Directors');

  return (
    <MaterialTable
      title={title}
      icons={tableIcons}
      data={data}
      columns={columns}
      options={{ ...options, search: false, headerStyle: { zIndex: 1 } }}
      components={{
        Toolbar: (props) => <ToolBar props={props} theme={theme} />
      }}
      detailPanel={
        isDetail
          ? (rowData: any) => {
              const data = {
                data: rowData.rowData
              };

              return <DirectorsDetail directorship={data} />;
            }
          : undefined
      }
      onRowClick={async (event, rowData, togglePanel) => {
        if (onRowClick) onRowClick(rowData);
      }}
    />
  );
};

const ToolBar = ({ props, theme }) => {
  return (
    <div
      style={{
        background: theme.palette.primary.main,
        padding: theme.spacing(2),
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius
      }}>
      <Typography
        style={{ color: theme.palette.primary.contrastText }}
        variant="h6">
        {props.title}
      </Typography>
    </div>
  );
};

export default Table;
