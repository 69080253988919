import React, { useState, ChangeEvent, useContext } from 'react';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@material-ui/core';
import { useProcess } from 'hooks';
import { useDispatch } from 'react-redux';
import { UserInstance } from 'types/interfaces';
import { NewContactAutomation } from 'redux/database/Custom API';
import { validateEmail } from 'helpers/validateEmail';
import { StepperContext } from 'components/Stepper/context';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import { SupplierAutomation } from 'redux/database';
import {
  CreatableTextSearch_Entity,
  CreatableTextSearch_Contact
} from 'common/CreatableTextSearch';
import { getFieldValueWithObjectInstanceIdAndFieldDefinitionId } from 'components/Stepper/functions/getFieldValueWithFieldDefinitionId';
import { SuccessButton } from 'common/Button';
import { CustomDialog } from 'common/Dialog';
import { Alert } from '@material-ui/lab';
import { Close } from '@material-ui/icons';
import { useTypedSelector } from 'redux/reducers';
import {
  getRelationship,
  getUsersForProcess
} from 'redux/actions/GraphQlActions';
import { IUserForProcess } from 'redux/actions/GraphQlActions/interface';

export enum IMode {
  Company = 'company',
  Director = 'director'
}
export interface IOption {
  label: string;
  value: number;
  title: string;
  createdCompany?: {
    mode: IMode;
    Id: number;
  };
}

export const NewSupplierContactAutomation = ({
  ObjectInstanceId
}: {
  ObjectInstanceId?: number;
}) => {
  const { currentDeal, currentProcess } = useTypedSelector((s) => s.process);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const dispatch = useDispatch();
  const stepper = useContext(StepperContext);

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [suppliers, setSuppliers] = useState<IOption[]>([] as IOption[]);
  const [contacts, setContacts] = useState<IOption[]>([] as IOption[]);
  const [selectedSupplier, setSelectedSupplier] = useState<IOption>(
    {} as IOption
  );
  const [emailInput, setEmailInput] = useState('');
  const [selectedContact, setSelectedContact] = useState<IOption>(
    {} as IOption
  );

  const ProcessStepDefinitionId =
    currentDeal?.ProcessInstance?.ProcessStepDefinitionId;
  const stepdefdict =
    currentProcess.CompleteProcessStepDefinitionDict[ProcessStepDefinitionId];

  const handleEmailInputChange = (email: string) => {
    setEmailInput(email);
  };

  const isSupplier: () => boolean = () => {
    if ('label' in selectedSupplier) {
      // Single
      if (selectedSupplier.label) return true;
      return false;
    } else {
      // Nothing
      return false;
    }
  };

  const isSelectedContact: () => boolean = () => {
    if ('title' in selectedContact) {
      // Single
      if (selectedContact.title) return true;
      return false;
    } else {
      // Nothing
      return false;
    }
  };

  const toggle = () => setOpen(!open);
  const close = () => setOpen(false);

  const getSupplierContactList = async (selectedSupplier: IOption) => {
    const response = (await getRelationship({
      action: 'LIST',
      baseUrl,
      UserInstanceId: selectedSupplier.createdCompany
        ? selectedSupplier.createdCompany.Id
        : selectedSupplier.value,
      UserDefinitionId: 528,
      fetchPolicy: true
    })) as UserInstance[] | undefined;

    if (response) {
      const contactsList = response.map((UserInstance: UserInstance) => ({
        label: UserInstance.UserInstanceEmail,
        value: UserInstance.Id,
        title: UserInstance.Title
      }));

      setContacts(contactsList);
    }
  };

  const getSupplierList = async () => {
    const response = (await getUsersForProcess({
      ProcessInstanceId: currentDeal.ProcessInstance.Id,
      ProcessDefinitionId: currentDeal.ProcessInstance.ProcessDefinitionId,
      UserDefinitionId: 26,
      action: 'ALLUSERSFORPROCESS',
      baseUrl,
      fetchQueries: true
    })) as IUserForProcess[] | undefined;

    if (response) {
      const supplierList: IOption[] = response.map(
        (UserForProcess: IUserForProcess) => ({
          value: parseInt(UserForProcess.Id.toString()),
          label: UserForProcess.Title,
          title: UserForProcess.Title
        })
      );

      setSuppliers(supplierList);
    }
  };

  const populateAlreadySelectedSupplier = () => {
    // if the value of the supplier field is populated then populate them
    const supplierField = 21944;
    if (ObjectInstanceId) {
      const supplier = getFieldValueWithObjectInstanceIdAndFieldDefinitionId({
        stepdefdict,
        currentDeal,
        FieldDefinitionId: supplierField,
        ObjectInstanceId
      });

      const supplierOption = suppliers.find(
        ({ value }) => value === parseInt(supplier)
      );

      supplierOption && setSelectedSupplier(supplierOption);
    }
  };

  const populateAlreadySelectedSupplierContact = () => {
    // if the value of the supplier contact field is populated then populate them
    const supplierContactField = 23270;
    if (ObjectInstanceId) {
      const supplierContact =
        getFieldValueWithObjectInstanceIdAndFieldDefinitionId({
          stepdefdict,
          currentDeal,
          FieldDefinitionId: supplierContactField,
          ObjectInstanceId
        });
      const supplierContactOption = contacts.find(
        ({ value }) =>
          parseInt(value.toString()) === parseInt(supplierContact.toString())
      );

      supplierContactOption && setSelectedContact(supplierContactOption);
    }
  };

  /**
   * Effect hook that fetches the contact list for a selected supplier.
   * Runs when `selectedSupplier` changes.
   */
  React.useEffect(() => {
    const supplier = isSupplier();
    if (supplier) {
      getSupplierContactList(selectedSupplier);
    }
  }, [selectedSupplier]);

  /**
   * Effect hook that populates the already selected supplier based on existing field values.
   * Runs when `suppliers` list changes.
   */
  React.useEffect(() => {
    populateAlreadySelectedSupplier();
  }, [suppliers]);

  /**
   * Effect hook that populates the already selected supplier contact based on existing field values.
   * Runs when `contacts` list changes.
   */
  React.useEffect(() => {
    populateAlreadySelectedSupplierContact();
  }, [contacts]);

  /**
   * Effect hook that fetches the supplier list when the component is opened.
   * Runs when `open` changes.
   * Resets the `suppliers` list and `selectedSupplier` on cleanup.
   */
  React.useEffect(() => {
    if (open) {
      getSupplierList();
    }
    return () => {
      setSuppliers([] as IOption[]);
      setSelectedSupplier({} as IOption);
    };
  }, [open]);

  const onCreateSupplier = async (email: string, title: string) => {
    setSelectedSupplier({
      value: 0,
      label: email,
      title
    });
  };

  const onSelectSupplier = async (option: IOption) => {
    setSelectedSupplier(option);
    setSelectedContact({} as IOption);
  };

  const onCreateContact = (email: string, title: string) => {
    setSelectedContact({ value: 0, label: email, title: title });
  };

  const onSelectContact = (option: IOption) => setSelectedContact(option);

  const submit = async () => {
    setLoading(true);

    try {
      if (emailInput && !validateEmail(emailInput)) {
        setLoading(false);
        return;
      }
      const supplier = emptyUserInstance();
      supplier.UserInstanceEmail = selectedSupplier.label;
      supplier.Title = selectedSupplier.title;
      supplier.Id = selectedSupplier.createdCompany
        ? selectedSupplier.createdCompany.Id
        : selectedSupplier.value;
      supplier.UserDefinitionId = 26;

      let data = [supplier];
      let contactToUse = selectedContact;

      if (!selectedContact?.label && emailInput) {
        contactToUse = {
          value: 0,
          label: emailInput,
          title: emailInput
        };
      }

      if (contactToUse?.label && contactToUse?.title) {
        const contact = emptyUserInstance();
        contact.UserInstanceEmail = contactToUse.label;
        contact.Title = contactToUse.title;
        contact.Id = contactToUse.value;
        contact.UserDefinitionId = 528;
        data.push(contact);
      }

      if (isSelectedContact()) {
        const contact = emptyUserInstance();
        contact.UserInstanceEmail = selectedContact.label;
        contact.Title = selectedContact.title;
        contact.Id = selectedContact.value;
        contact.UserDefinitionId = 528;
        data = [supplier, contact];
      }

      if (ObjectInstanceId) {
        const res = await SupplierAutomation({
          data,
          ProcessInstanceId: currentDeal.ProcessInstance.Id,
          ObjectInstanceId
        });
        if (res && res.status === 200) {
          dispatch(createNotification(successNotif('Success')));
          toggle();
          stepper.refreshDealData();
        } else {
          console.log('Error', res);
        }
      } else {
        console.log('Error', ObjectInstanceId);
      }
    } catch (e) {
      console.log('Error', e);
    } finally {
      setLoading(false);
    }
  };

  const disabled = () => {
    if (
      Object.keys(selectedSupplier).length > 0 &&
      selectedSupplier.title !== ''
    ) {
      return false;
    }
    return true;
  };

  return (
    <>
      <Button data-cy="new-supplier-btn" onClick={toggle}>
        + new
      </Button>
      <CustomDialog
        maxSize="md"
        open={open}
        handleClose={close}
        alert={{
          title: 'Add a New Supplier or Supplier Contact Easily',
          description:
            'Create new Supplier profiles AND/OR their  Supplier Contact effortlessly.',
          type: 'info'
        }}>
        <DialogContent>
          <div style={{ margin: '10px' }}>
            <Typography variant="h4">Create / Search Supplier</Typography>
          </div>
          <CreatableTextSearch_Entity
            existingOptionIdList={selectedSupplier}
            onCreate={onCreateSupplier}
            onSelect={onSelectSupplier}
            options={suppliers}
          />
          <br />

          <br />
          {isSupplier() && (
            <>
              <div style={{ marginBottom: '10px' }}>
                <Typography variant="h4">Supplier Contact</Typography>
              </div>
              <CreatableTextSearch_Contact
                existingOptionIdList={selectedContact}
                onCreate={onCreateContact}
                onSelect={onSelectContact}
                onEmailInputChange={handleEmailInputChange}
                options={contacts}
              />
            </>
          )}
        </DialogContent>

        <DialogActions>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <SuccessButton
              data-cy="create-supplier-btn"
              variant="contained"
              onClick={submit}
              disabled={disabled()}>
              Create / Select
            </SuccessButton>
          )}
        </DialogActions>
      </CustomDialog>
    </>
  );
}; //END NewSupplierContactAutomation

const emptyUserInstance = () => {
  const UserInstance: UserInstance = {
    ConsoleUserInstanceId: 0,
    DefaultMessage: '',
    DocumentFooter: '',
    DocumentHeader: '',
    EmailFooter: '',
    FromAddress: '',
    FromName: '',
    Id: 0,
    IsPublished: false,
    ItemOrder: 0,
    LastModified: '0001-01-01T00:00:00',
    LogoURL: '',
    NickName: '',
    OwnerUserInstanceId: 0,
    ProfilePicture: '',
    ProxyUserInstanceId: 0,
    RelatedUserPermission: 0,
    RelationshipStatus: 0,
    SmtpHost: '',
    SmtpPassword: '',
    SmtpPort: 0,
    SmtpSSL: false,
    SmtpUsername: '',
    SummaryFields: undefined,
    SystemAccess: 0,
    TelephoneNumber: '',
    ThirdPartyId: '',
    Title: '',
    UserDefinitionId: 0,
    UserInstanceEmail: '',
    UserInstancePassword: ''
  };
  return UserInstance;
}; //END emptyUserInstance
