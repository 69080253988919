import {
  useState,
  useEffect,
  useContext,
  Dispatch,
  SetStateAction
} from 'react';
import {
  Button,
  ClickAwayListener,
  Typography,
  useTheme
} from '@material-ui/core';
import { NewContactAutomation } from 'redux/database/Custom API';
import { useProcess } from 'hooks';
import { UserInstance } from 'types/interfaces';
import { CreatableTextSearch } from './CreatableTextSearch';
import { StepperContext } from 'components/Stepper/context';
import { useTypedSelector } from 'redux/reducers';
import { getRelationship } from 'redux/actions/GraphQlActions';
import { Alert, AlertTitle } from '@material-ui/lab';

type Option = { label: string; value: number };
interface Props {
  contactIds: string[];
  setDialogOpen: Dispatch<SetStateAction<boolean>>;
  setTempUser: Dispatch<SetStateAction<number | string | undefined>>;
}

export const NewGdprContactDialog = ({
  contactIds,
  setDialogOpen,
  setTempUser
}: Props) => {
  const theme = useTheme();
  const stepper = useContext(StepperContext);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Option[] | null>(null);
  const [alert, setAlert] = useState<{ isOpen: boolean; message: string }>({
    isOpen: false,
    message: ''
  });

  const { currentDeal, currentStep } = useProcess();
  const { UserInstanceListForCurrentStep } = currentStep;
  const Customer = UserInstanceListForCurrentStep[0];
  const CustomerUDID = 523;
  const ContactUDID = 528;

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const getUserList = async () => {
    const response = (await getRelationship({
      action: 'RELATEDTOASSIGNED',
      ProcessInstanceId: currentDeal.ProcessInstance.Id,
      AssignedUserDefinitionId: CustomerUDID,
      RelatedUserDefinitionId: ContactUDID,
      baseUrl
    })) as UserInstance[] | undefined;

    if (response) {
      setOptions(
        response.map((UserInstance: UserInstance) => ({
          label: UserInstance.UserInstanceEmail,
          value: UserInstance.Id
        }))
      );
    }
  };

  useEffect(() => {
    getUserList();
  }, [open]);

  const onCreate = async (email: string, title: string): Promise<boolean> => {
    const data: UserInstance = createNewUserInstance();
    data.Title = title;
    data.UserInstanceEmail = email;
    data.LastModified = `${new Date().toISOString()}`;
    const res = await NewContactAutomation({
      PartiesAndContacts: false,
      data,
      ProcessInstanceId: currentDeal.ProcessInstance.Id,
      PrimaryUserInstanceId: Customer.UserInstanceId
    });

    if (res.status === 200) {
      setTempUser && setTempUser(data.Title);
      setOpen(false);
      setDialogOpen(false);

      await stepper.refreshDealData();
      return true;
    } else {
      setAlert({
        isOpen: true,
        message:
          'It looks like this contract may already exist. We recommend using the dropdown below to locate and select it.'
      });

      return false;
    }
  };

  const onSelect = async (option: Option): Promise<boolean> => {
    const { value } = option;
    const data: UserInstance = createNewUserInstance();
    data.Id = value;
    data.LastModified = `${new Date().toISOString()}`;
    if (data) {
      const res = await NewContactAutomation({
        PartiesAndContacts: false,
        data,
        ProcessInstanceId: currentDeal.ProcessInstance.Id,
        PrimaryUserInstanceId: Customer.UserInstanceId
      });
      if (res.status === 200) {
        setTempUser && setTempUser(value);
        setOpen(false);
        setDialogOpen(false);

        await stepper.refreshDealData();
        return true;
      } else return false;
    }
    return false;
  };

  const dealCondition = stepper.isDealClosed || stepper.isDealTransferred;
  if (dealCondition) {
    return (
      <Alert severity="warning">
        <AlertTitle>Cannot Add New GDPR Contacts</AlertTitle>
        <Typography variant="h6" color="textSecondary">
          Please note that this deal is $
          {stepper.isDealTransferred ? 'transferred' : 'closed'}. You cannot add
          new GDPR Contacts.
        </Typography>
      </Alert>
    );
  }
  return (
    <>
      {!open && (
        <Button fullWidth variant="text" onClick={() => setOpen(true)}>
          + New Customer Contact
        </Button>
      )}

      {open && (
        <ClickAwayListener
          onClickAway={() => {
            setOpen(false);
            setAlert({ isOpen: false, message: '' });
          }}>
          <div>
            <div
              style={{
                backdropFilter: `blur(3px)`,
                padding: theme.spacing(1),
                display: 'flex',
                width: '100%'
              }}>
              <CreatableTextSearch
                contactIds={contactIds}
                options={options}
                onCreate={onCreate}
                onSelect={onSelect}
              />
            </div>

            {alert.isOpen && (
              <Alert severity="warning">
                <AlertTitle>Error Encountered:</AlertTitle>
                <Typography variant="h6" color="textSecondary">
                  {alert.message}
                </Typography>
              </Alert>
            )}
          </div>
        </ClickAwayListener>
      )}
    </>
  );
};

const createNewUserInstance = () => {
  const obj: UserInstance = {
    ConsoleUserInstanceId: 0,
    DefaultMessage: '',
    DocumentFooter: '',
    DocumentHeader: '',
    EmailFooter: '',
    FromAddress: '',
    FromName: '',
    Id: 0,
    IsPublished: false,
    ItemOrder: 0,
    LastModified: '',
    LogoURL: '',
    NickName: '',
    OwnerUserInstanceId: 0,
    ProfilePicture: '',
    ProxyUserInstanceId: 0,
    RelatedUserPermission: 0,
    RelationshipStatus: 0,
    SmtpHost: '',
    SmtpPassword: '',
    SmtpPort: 0,
    SmtpSSL: false,
    SmtpUsername: '',
    SummaryFields: undefined,
    SystemAccess: 0,
    TelephoneNumber: '',
    ThirdPartyId: '',
    Title: '',
    UserDefinitionId: 0,
    UserInstanceEmail: '',
    UserInstancePassword: ''
  };
  return obj;
};
