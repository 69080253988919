import { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import { CustomDialog } from 'common/Dialog';
import { DangerButton } from 'common';
import { QueryKeys } from 'components/Marketing/types';
import { deleteSender } from '../functions';
import { ISender } from '../types';

interface Props {
  sender: ISender;
  hasWritePermissions: boolean;
}

const DeleteSenderDialog = ({ sender, hasWritePermissions }: Props) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState<boolean>(false);

  const { mutate, isLoading: isDeleting } = useMutation(deleteSender, {
    onSuccess: () => queryClient.invalidateQueries(QueryKeys.FetchSenders)
  });

  return (
    <>
      <DangerButton
        onClick={() => setOpen(true)}
        disabled={!hasWritePermissions}>
        Delete
      </DangerButton>
      <CustomDialog
        open={open}
        handleClose={() => setOpen(false)}
        maxSize="sm"
        aria-labelledby="delete-sender-dialog"
        alert={{
          title: `Delete Sender`,
          description: `Are you sure you want to Delete Sender: ${sender.nickname}`,
          type: 'warning'
        }}>
        <DialogContent>
          <div
            style={{
              padding: '20px 0',
              display: 'flex',
              justifyContent: 'space-between'
            }}>
            <ButtonGroup color="inherit" variant="contained">
              <Button onClick={() => setOpen(false)}>Close</Button>
            </ButtonGroup>
            <DangerButton
              onClick={() => mutate(sender)}
              disabled={!hasWritePermissions}>
              {isDeleting ? 'Deleting' : 'Delete'}
            </DangerButton>
          </div>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default DeleteSenderDialog;
