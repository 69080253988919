import { Dispatch, SetStateAction, useState, useEffect, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Divider, Typography, Paper, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { Currency, Percentage } from '../Utils/displayFields';
import AssignmentIcon from '@material-ui/icons/Assignment';
import FormatListNumberedRoundedIcon from '@material-ui/icons/FormatListNumberedRounded';
import { Calculation } from 'types/calculatorInterfaces';
import { Tools } from './interfaces';
import CashFlowDialog from '../Cashflow/dialog';
import { useProcess } from 'hooks';

interface Props {
  tools: Tools;
  Output: Calculation | {};
  forValue: string;
  balloonRep: number;
  classes: any;
  loading: boolean;
  setBalloonRep: (balloon: number) => void;
}

const OutputScreen = ({
  tools,
  Output,
  forValue,
  balloonRep,
  classes,
  loading,
  setBalloonRep
}: Props) => {
  const { state, setState } = tools;
  const { currentProcess } = useProcess();
  // const [open, setOpen] = useState(false);
  const [advance, setAdvance] = useState(Output['Amount']);
  const [balloon, setBalloon] = useState(Output['_Balloon'].Balloon);
  const [showMoreResults, setShowMoreResults] = useState(false);
  const [CommissionAmount, setCommissionAmount] = useState(
    state.CommissionAmount
  );
  const ContractPeriodPlusOne =
    state._Balloon && state._Balloon.ContractPeriod === 1;

  useEffect(() => {
    if (forValue === 'forBalloon') {
      if (!ContractPeriodPlusOne) {
        const CashFlow = Output['CashFlow'];
        // 1. Filter the CashFlow array for the elements whose Description field === "Repay"
        const receipts = CashFlow.filter((e) => e.Description === 'Repay');
        // 2. Take the last item in the Array and the penultimate item in the array.
        const lastReceipt = receipts[receipts.length - 1].Receipt;

        // Rather than looking for the penultimate receipt we need to find the last one with a value? or do we just use the Repayment Value number?
        // For now lets use the Repayment Value number because if an extra payment is added between the last Repayment value and the Balloon we'd pick that up instead by accident.
        const penultimateReceipt = balloonRep
          ? balloonRep
          : Output['MonthlyPayment'];

        // 3. Subtract the "Receipt" value from the penultimate item from the "Receipt" value of the last item.
        const Balloon = lastReceipt - penultimateReceipt;
        setBalloon(Balloon);

        // Setting the default MonthlyPayment to RepaymentValue
        state.MonthlyPayment = penultimateReceipt;
        setState(state);

        // 4. Update the original object with this value.
        // const lastItem = CashFlow[CashFlow.length - 1];
        // console.log({ outPutState: state });
      }
    } else {
      setBalloonRep(Output['MonthlyPayment']);
      setBalloon(Output['_Balloon'].Balloon);
    }

    if (forValue === 'forAmount') {
      const advance = Output['Amount'] - state.CommissionAmount;
      setAdvance(advance);
    }
    setCommissionAmount(Output['CommissionAmount']);
    setAdvance(Output['Amount']);
  }, [Output]);

  let isLoanDeal = false;
  if (Object.keys(currentProcess).length !== 0) {
    const Title = currentProcess?.ProcessDefinition?.Title;
    isLoanDeal = Title.includes('Loan');
  }
  return (
    <Grid data-cy="output-values" item md={6} sm={12} xs={12}>
      <Paper className={classes.output} elevation={0}>
        {loading ? (
          <Grid container justifyContent="center">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <Grid alignItems="center" container spacing={1}>
              <Grid item>
                <Typography
                  align="right"
                  className={classes.screentext}
                  variant="h4">
                  RESULTS
                </Typography>
              </Grid>
              <div style={{ flexGrow: 1 }} />
              <Grid item>
                <Typography style={{ color: 'green' }}>
                  {state.AmountIncludesVAT ? 'Inc. VAT' : 'Ex. VAT'}
                </Typography>
              </Grid>

              <Grid item>
                <AssignmentIcon style={{ color: 'green' }} />
              </Grid>
            </Grid>

            <Divider className={classes.spacer} />

            <Grid container direction="column">
              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>Advance</Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Currency value={advance} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Repayment Value
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  {balloonRep ? (
                    <Currency value={balloonRep} />
                  ) : (
                    <Currency value={Output['MonthlyPayment']} />
                  )}
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Funding Profile
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  {Output['InitialRentals'] !== 0
                    ? `${Output['InitialRentals']} +`
                    : null}
                  {Output['FundingProfile']}
                </Typography>
                {ContractPeriodPlusOne && (
                  <Tooltip
                    arrow
                    title={
                      ContractPeriodPlusOne
                        ? '* Indicates That The Contract Period Has Been Extended By 1'
                        : ''
                    }>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {ContractPeriodPlusOne && '+ 1'}
                    </Typography>
                  </Tooltip>
                )}
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Term (Months)
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  {Output['Term']}
                </Typography>
                {ContractPeriodPlusOne && (
                  <Tooltip
                    arrow
                    title={
                      ContractPeriodPlusOne
                        ? '* Indicates That The Contract Period Has Been Extended By 1'
                        : ''
                    }>
                    <Typography style={{ fontWeight: 'bold' }}>
                      {ContractPeriodPlusOne && '+ 1'}
                    </Typography>
                  </Tooltip>
                )}
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>Deposit</Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Currency value={Output['Deposit']} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Deposit Vat Rate:
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Percentage value={state._NetAdvance.Deposit_VatRate} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Start Date
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  {moment(Output['StartDate']).format('Do MMM YYYY')}
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>Balloon</Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  {state._Balloon ? <Currency value={balloon} /> : '---'}
                </Typography>
              </Grid>
            </Grid>

            <Divider className={classes.spacer} />

            <Grid container direction="column">
              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>APR</Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Percentage value={Output['APR']} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Flat Rate
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Percentage value={Output['FlatRate']} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Nominal Flat Rate
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Percentage value={Output['NominalFlatRate']} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>Yield</Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Percentage value={Output['Yield']} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>Margin</Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Percentage value={Output['MarginRate']} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>
                  Rate of Interest Per Annum
                </Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Percentage value={Output['RateOfInterestPerAnnum']} />
                </Typography>
              </Grid>

              <Grid className={classes.resultRow} item sm={12}>
                <Typography className={classes.screentext}>NPV</Typography>
                <div style={{ flexGrow: 1 }} />
                <Typography className={classes.screentext}>
                  <Currency value={Output['NPV']} />
                </Typography>
              </Grid>
            </Grid>

            <Grid className={classes.resultRow} item sm={12}>
              <Typography className={classes.screentext}>Interest</Typography>
              <div style={{ flexGrow: 1 }} />
              <Typography className={classes.screentext}>
                <Currency value={Output['Interest']} />
              </Typography>
            </Grid>

            <Divider className={classes.spacer} />

            <Grid container justifyContent="flex-end">
              <Button
                data-cy="show-more-less-btn"
                color="primary"
                onClick={() => setShowMoreResults(!showMoreResults)}>
                {showMoreResults ? 'show Less' : 'show More'}
              </Button>
            </Grid>

            {showMoreResults && (
              <Fragment>
                <Grid container direction="column">
                  {/* <Grid item sm={12} className={classes.resultRow}>
                    <Typography className={classes.screentext}>
                      Commission %:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Percentage value={Output.CommissionPercentage} />
                    </Typography>
                  </Grid> */}
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Commission Amount:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={CommissionAmount} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Introducer Fee:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency
                        value={Output['_Commission']['Introducer_Fee']}
                      />
                    </Typography>
                  </Grid>
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Funder Fee:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['_Commission']['Funder_Fee']} />
                    </Typography>
                  </Grid>
                </Grid>

                <Divider className={classes.spacer} />

                <Grid container direction="column">
                  {/* <Grid item sm={12} className={classes.resultRow}>
                    <Typography className={classes.screentext}>
                      Amount Includes VAT:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      {`${Output.AmountIncludesVAT}`}
                    </Typography>
                  </Grid> */}
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Document Fee:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['DocFee']} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Option to Purchase Fee:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['OptionFee']} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Customer Yield:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Percentage value={Output['CustomerYield']} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Initial Rental Payment:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['InitialPayment']} />
                    </Typography>
                  </Grid>

                  <Divider className={classes.spacer} />

                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Total Cost of Credit:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['TotalCostOfCredit']} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Asset Value:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['AssetValue']} />
                    </Typography>
                  </Grid>
                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Payment Frequency:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      {Output['PaymentFrequency']}
                    </Typography>
                  </Grid>

                  <Divider className={classes.spacer} />

                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Half Amount Payable:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['Halves']} />
                    </Typography>
                  </Grid>

                  <Grid className={classes.resultRow} item sm={12}>
                    <Typography className={classes.screentext}>
                      Third Amount Payable:
                    </Typography>
                    <div style={{ flexGrow: 1 }} />
                    <Typography className={classes.screentext}>
                      <Currency value={Output['Thirds']} />
                    </Typography>
                  </Grid>

                  <Divider className={classes.spacer} />
                </Grid>
              </Fragment>
            )}

            <Grid item style={{ paddingTop: 10 }}>
              {/* <Button
                className={classes.button}
                color="primary"
                onClick={() => setOpen(!open)}
                variant="contained">
                View Cashflow
                <FormatListNumberedRoundedIcon className={classes.rightIcon} />
              </Button> */}
              <CashFlowDialog data={Output['CashFlow']} title="Cashflow" />
            </Grid>
          </Fragment>
        )}
      </Paper>
    </Grid>
  );
};

export default OutputScreen;
