import React, { useState } from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import Table from 'components/Table';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import { getUserDefinitions } from 'redux/actions/user';
import { useParams } from 'react-router-dom';

const Customers = () => {
  const [selectedDefinition, setSelectedDefinition] = React.useState(null);
  const { ID } = useParams();
  const UserDefinitions = useSelector(
    (state) => state.config.settings.UserDefinitionList
  );

  const token = useSelector((s) => s.user.auth.token);
  const customersUserDef = useSelector(
    (state) => state.userAPI.userDefinition[ID]
  );
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    setSelectedDefinition(null);
    const selectedDefinition = UserDefinitions.find(
      (el) => el.Id === parseInt(ID)
    );
    setSelectedDefinition(selectedDefinition);
    // eslint-disable-next-line
  }, [ID]);

  const getAllData = async (props) => {
    setLoading(true);
    const { token, ID } = props;
    const success = await getUserDefinitions({ token, UserDefinitionId: ID });
    success.success && setLoading(false);
  };

  React.useEffect(() => {
    getAllData({ ID, token });
    // eslint-disable-next-line
  }, [ID]);

  return (
    <DashboardLayout
      title={selectedDefinition ? selectedDefinition.Title : 'Loading ...'}>
      {loading || !customersUserDef ? (
        <LinearProgress />
      ) : (
        <div data-cy="customer-name">
          <Table
            ID={ID}
            customersUserDef={customersUserDef}
            getAllData={getAllData}
            title={selectedDefinition && selectedDefinition.Title}
          />
        </div>
      )}
    </DashboardLayout>
  );
};

export default Customers;
