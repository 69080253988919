import { GetSettings } from '../database';
import { store } from 'redux/store';
import { SET_TOKEN } from 'redux/actions/types';
import { BugTracker } from 'Utils/Bugtracker';
import { updateTheme } from 'redux/actions/configActions';

const { dispatch } = store;

export const getSettings = async ({ returnData }: { returnData?: 'token' }) => {
  return GetSettings()
    .then((res: any) => {
      if (res?.data?.Theme) updateTheme(res.data.Theme, false);
      if (returnData === 'token') return res;
      dispatch({ type: SET_TOKEN, payload: res?.headers?.auth });
      return res?.data;
    })
    .catch((e) => BugTracker.notify(e));
};
