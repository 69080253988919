import React from 'react';
import { useSelector } from 'react-redux';
import { Typography, Button, Grid, IconButton, Chip } from '@material-ui/core';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  makeStyles
} from '@material-ui/core/styles';
import NumberFormat from 'react-number-format';
import { Dialog, DialogContent, Paper } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import _ from 'lodash';
import InlineTextField from 'components/Fields/InlineTextField';
import FieldStatusIcon from '../SingleObject/components/FieldStatusIcon';
import {
  CompleteObjectInstance,
  CompleteProcessInstance,
  FieldDefinition,
  FieldInstance,
  UserInstance
} from 'types/interfaces';
import { PropertyLogo } from 'components/AssetLogos';
import { pickFieldsToShow } from 'components/Stepper/functions/hideshowFields';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { useTypedSelector } from 'redux/reducers';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      margin: '8px 0px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start'
    },
    leverCol: {
      width: 270,
      paddingRight: theme.spacing(2),
      borderRight: `1px ${theme.palette.grey['A100']} solid`
    },
    otherCol: {
      paddingLeft: theme.spacing(2),
      width: '100%'
    },
    hr: {
      borderRadius: 10,
      background: theme.palette.grey['A100'],
      margin: 5
    },
    inlinetext: {
      color: theme.palette.secondary.main
    },
    paper: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`
    },
    TotalsBox: {
      border: `1px ${theme.palette.grey.A100} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`,
      background: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-around'
    },
    TotalsBoxText: {
      color: theme.palette.primary.contrastText
    }
  })
);

const Summary = (props) => {
  let UserInstance: UserInstance | any = {};
  UserInstance = useSelector<any>((s) => s.user.user);
  let currentDeal: CompleteProcessInstance | any = {};
  currentDeal = useSelector<any>((s) => s.process.currentDeal);
  const { ObjectInstance } = props;
  const { FieldInstanceDict } = ObjectInstance;
  const getValue = (name) => {
    const Id = props.FieldDefinitionList.find((i) => i.Title === name)?.Id;
    if (Id) {
      const instObj: any = Object.values(FieldInstanceDict).find(
        (i: any) => i.FieldDefinitionId === Id
      );
      return instObj?.['FieldValue'];
    } else {
      return null;
    }
  };
  const dealType: any = getValue('Deal Type');
  const ownershipType: any = getValue('Ownership Type');
  const leasehold: any = getValue('Leasehold - Term Remaining (months)');
  const purchasePrice: any = getValue('Purchase Price');
  const estimatedValuation: any = getValue('Estimated Valuation');
  const valuation: any = getValue('Valuation');
  const existingCharges: any = getValue('Existing Charges');
  const chargeHolders: any = getValue('Charge Holders');

  const bridge: any = getValue('Bridge');
  const buyToLet: any = getValue('Buy To Let');
  const commercialTerm: any = getValue('Commercial Term');
  const development: any = getValue('Development');
  const agriculture: any = getValue('Agriculture');

  const [open, setOpen] = React.useState(false);

  const handleDealType = () => {
    switch (dealType) {
      case dealType === 'Bridge':
        return bridge;
      case dealType === 'Buy To Let':
        return buyToLet;
      case dealType === 'Commercial Term':
        return commercialTerm;
      case dealType === 'Development':
        return development;
      case dealType === 'Agriculture':
        return agriculture;
      default:
        return '';
    }
  };

  const DialogTitleContent = () => (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0
        }}>
        <PropertyLogo />
      </div>

      <div style={{ minHeight: 50 }}>
        <Typography variant="caption" style={{ margin: 5 }}>
          {dealType}{' '}
          {dealType === 'Bridge'
            ? bridge
            : dealType === 'Buy To Let'
            ? buyToLet
            : dealType === 'Commercial Term'
            ? commercialTerm
            : dealType === 'Development'
            ? development
            : dealType === 'Agriculture'}
        </Typography>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Chip
            disabled
            size="small"
            style={{ width: '100%' }}
            variant="outlined"
            label={
              <Typography variant="body2">
                Estimated Valuation:{' '}
                <NumberFormat
                  value={estimatedValuation ? estimatedValuation : 0}
                  prefix={'£'}
                  thousandSeparator
                  displayType={'text'}
                  decimalScale={2}
                />
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Chip
            disabled
            size="small"
            style={{ width: '100%' }}
            variant="outlined"
            label={
              <Typography variant="body2">
                Purchase Price:{' '}
                <NumberFormat
                  value={purchasePrice ? purchasePrice : 0}
                  prefix={'£'}
                  thousandSeparator
                  displayType={'text'}
                />
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Chip
            disabled
            size="small"
            style={{ width: '100%' }}
            variant="outlined"
            label={
              <Typography variant="body2">
                Leasehold (Months): {leasehold ? leasehold : 0}
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </div>
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <DialogTitleContent />
      <br />
      <Button
        color="primary"
        onClick={() => setOpen(!open)}
        variant="contained"
        fullWidth>
        Open
      </Button>

      <SummaryDialog
        open={open}
        setOpen={setOpen}
        {...props}
        DialogTitleContent={() => <DialogTitleContent />}
      />
    </div>
  );
};

export default Summary;

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
      <br />
      <br />
      {children}
    </MuiDialogTitle>
  );
});

const SummaryDialog = (props) => {
  const { open, setOpen } = props;

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      <DialogTitle id="customized-dialog-title" onClose={() => setOpen(!open)}>
        {props.DialogTitleContent()}
      </DialogTitle>
      <DialogContent>
        <Content {...props} />
      </DialogContent>
    </Dialog>
  );
};

const summaryfields = [
  'Property Address Line 1',
  'Property Address Line 2',
  'City',
  'County',
  'Post Code',
  'Ownership Type',
  'Leasehold - Term Remaining (months)',
  'Purchase Price',
  'Estimated Valuation',
  'Valuation',
  'Existing Charges',
  'Charge Holders'
];

const Content = (props) => {
  const UserInstance = useTypedSelector((s) => s.user.user);
  const { currentDeal } = useTypedSelector((s) => s.process);

  const { ObjectInstance: CompleteObjectInstance } = props;
  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  const classes = useStyles();

  const leverFieldDefinitions: FieldDefinition[] =
    props.FieldDefinitionList.filter(
      (FieldDefinition: FieldDefinition) =>
        FieldDefinition.FieldType === 'Drop-downHideShowFields'
    );

  const fieldDefinitions: FieldDefinition[] = props.FieldDefinitionList.filter(
    (FieldDefinition: FieldDefinition) =>
      FieldDefinition.FieldType !== 'Drop-downHideShowFields'
  );

  const LeverFieldDefinitionsToShow: FieldDefinition[] = pickFieldsToShow({
    FieldDefinitionList: leverFieldDefinitions,
    CompleteObjectInstance
  });

  const OtherFieldDefinitionToShow: FieldDefinition[] = pickFieldsToShow({
    FieldDefinitionList: fieldDefinitions,
    CompleteObjectInstance
  });

  let SummaryFieldDefinitions: any = OtherFieldDefinitionToShow.filter(
    (FieldDefinition: any) => summaryfields.includes(FieldDefinition.Title)
  );

  return (
    <>
      <Paper className={classes.root} elevation={3}>
        <div className={classes.leverCol}>
          <div>
            {LeverFieldDefinitionsToShow.map(
              (FieldDefinition: FieldDefinition) => (
                <div key={FieldDefinition.Id}>
                  {FieldInstanceList.map(
                    (FieldInstance: FieldInstance, idx: number) => {
                      if (
                        FieldInstance.FieldDefinitionId === FieldDefinition.Id
                      ) {
                        return (
                          <div className={classes.paper} key={idx}>
                            <div style={{ display: 'flex' }}>
                              <FieldStatusIcon
                                FieldDefinition={FieldDefinition}
                                FieldInstance={FieldInstance}
                                Required={FieldDefinition.FieldRequired}
                              />
                              <p>{FieldDefinition.Title}</p>
                            </div>
                            <hr className={classes.hr} />
                            <div className={classes.inlinetext}>
                              <InlineTextField
                                FieldDefinition={FieldDefinition}
                                FieldInstance={FieldInstance}
                                ObjectDefinition={props.ObjectDefinition}
                                CompleteObjectInstance={CompleteObjectInstance}
                                ObjectInstance={
                                  CompleteObjectInstance.ObjectInstance
                                }
                                ProcessInstance={currentDeal.ProcessInstance}
                                ProcessStepSelectionType={
                                  props.stepdefdict.ProcessStepDefinition
                                    .ProcessStepSelectionType
                                }
                                UserInstance={UserInstance}
                                fields={leverFieldDefinitions}
                                type={FieldDefinition.FieldType}
                                value={FieldInstance?.FieldValue}
                              />
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              )
            )}
          </div>
        </div>

        <Grid container className={classes.otherCol} spacing={2}>
          {SummaryFieldDefinitions.map((FieldDefinition: FieldDefinition) => {
            return (
              <Grid item key={FieldDefinition.Id} xs={6}>
                {FieldInstanceList.map(
                  (FieldInstance: FieldInstance, idx: number) => {
                    if (
                      FieldInstance.FieldDefinitionId === FieldDefinition.Id
                    ) {
                      return (
                        <div className={classes.paper} key={idx}>
                          <div style={{ display: 'flex' }}>
                            <FieldStatusIcon
                              FieldDefinition={FieldDefinition}
                              FieldInstance={FieldInstance}
                              Required={FieldDefinition.FieldRequired}
                            />
                            <p>{FieldDefinition.Title}</p>
                          </div>

                          <hr className={classes.hr} />

                          <div className={classes.inlinetext}>
                            <InlineTextField
                              FieldDefinition={FieldDefinition}
                              FieldInstance={FieldInstance}
                              ObjectDefinition={props.ObjectDefinition}
                              CompleteObjectInstance={CompleteObjectInstance}
                              ObjectInstance={
                                CompleteObjectInstance.ObjectInstance
                              }
                              ProcessInstance={currentDeal.ProcessInstance}
                              ProcessStepSelectionType={
                                props.stepdefdict.ProcessStepDefinition
                                  .ProcessStepSelectionType
                              }
                              UserInstance={UserInstance}
                              fields={leverFieldDefinitions}
                              type={FieldDefinition.FieldType}
                              FieldDefinitionList={
                                OtherFieldDefinitionToShow
                                  ? OtherFieldDefinitionToShow
                                  : ''
                              }
                              value={FieldInstance?.FieldValue}
                            />
                          </div>
                        </div>
                      );
                    }
                  }
                )}
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </>
  );
};
