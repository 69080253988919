import { useContext, useEffect, useState } from 'react';
import {
  CompleteObjectInstance,
  FieldInstance,
  ObjectDefinition
} from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import firebase from 'firebase';
import { ILenderDecision } from './useConfirmAccept';
import { useProcess } from 'hooks/useProcess';
import { useTypedSelector } from 'redux/reducers';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

export const LENDER_FIELD_DEFINITION = [21961, 21159];
export const LENDER_PROPOSAL_DECISION_STATUS = [23292, 23593, 22802];
const LENDER_PROPOSAL_SYNERGY_DECISION_STATUS = [22802];

export const LENDER_PROPOSAL_DECISION = [
  ...LENDER_PROPOSAL_DECISION_STATUS,
  ...LENDER_PROPOSAL_SYNERGY_DECISION_STATUS
];

// Contains Lender & Proposal For Synergy & Normal
const stagingDocument = [23601, 23604];
const devDocument = [23532, 23529];
const LENDER_PROPOSAL_DECISION_DOCUMENT = [...devDocument, ...stagingDocument];

// Contains Lender & Proposal For Synergy & Normal
const stagingFunder = [23603, 23602];
const devFunder = [23530, 23531];
const LENDER_PROPOSAL_DECISION_FUNDER = [...stagingFunder, ...devFunder];

const ADDITIONAL_FIELD_IDS = [
  ...LENDER_PROPOSAL_DECISION_DOCUMENT,
  ...LENDER_PROPOSAL_DECISION_FUNDER,
  LENDER_FIELD_DEFINITION
];

/**
 * Custom hook for handling repeatable object instances in the context of lender decisions.
 *
 * @description This hook manages the state and operations related to object instances in the lending decision process.
 * It provides functionalities for modifying object instances, completing lender decisions on behalf of a funder, and determining read-only fields.
 *
 * @param {CompleteObjectInstance[]} CompleteObjectInstanceList - Array of CompleteObjectInstances to be processed.
 * @param {ObjectDefinition} ObjectDefinition - ObjectDefinition to be processed.
 *
 * @returns {Object} An object containing modified object instances, a function to complete lender decisions, and an array of read-only field IDs.
 */

const useRepeatableObject = ({
  CompleteObjectInstanceList,
  isInlineField
}: {
  CompleteObjectInstanceList: CompleteObjectInstance[];
  isInlineField?: boolean;
}) => {
  const { currentOverView, user, currentDeal } = useProcess();
  const firestoreLenderInfo = useTypedSelector(
    (s) => s.dealSummary.deal.lenderDecision
  );

  const [brokerAccepted, setBrokerAccepted] = useState<
    {
      completeObjectInstanceId: number;
      completedByUser: string;
      status: string;
    }[]
  >([]);

  const [readOnlyFields, setReadOnlyFields] = useState<number[]>([]);

  const modifiedObjectInstances = (): CompleteObjectInstance[] => {
    return CompleteObjectInstanceList.map(
      (CompleteObjectInstance: CompleteObjectInstance) =>
        modifyInstance({
          CompleteObjectInstance,
          firestoreLenderInfo
        })
    );
  };

  const completeLenderDecisionOnBehalfOfFunder = async (
    status: string,
    CompleteObjectInstance: CompleteObjectInstance
  ) => {
    if (!currentOverView || !CompleteObjectInstance) return;
    const dealRef = firebase
      .firestore()
      .collection('deal')
      .doc(currentOverView.ProcessInstanceId.toString());

    if (CompleteObjectInstance) {
      const objectId = CompleteObjectInstance.ObjectInstance.Id;
      const getLenderStatus = findFieldInstance({
        CompleteObjectInstance,
        fieldDefinitionId: LENDER_PROPOSAL_DECISION
      });

      const getLender = findFieldInstance({
        CompleteObjectInstance,
        fieldDefinitionId: LENDER_FIELD_DEFINITION
      });

      if (
        !getLenderStatus ||
        getLenderStatus.FieldValue === '' ||
        !getLender ||
        isNaN(parseInt(getLender.FieldValue))
      ) {
        return;
      }

      const getLenderDocuments = findFieldInstance({
        CompleteObjectInstance,
        fieldDefinitionId: LENDER_PROPOSAL_DECISION_DOCUMENT
      });

      const getLenderFunderReference = findFieldInstance({
        CompleteObjectInstance,
        fieldDefinitionId: LENDER_PROPOSAL_DECISION_FUNDER
      });

      if (!getLenderDocuments || !getLenderFunderReference) return;
      const lenderId = parseInt(getLender.FieldValue);
      const newDecision: ILenderDecision = {
        lenderDecision: status,
        lenderDecisionDocument: getLenderDocuments.FieldValue,
        lenderFunderReference: getLenderFunderReference.FieldValue,
        completedByUserId: user.Id.toString(),
        lastModified: new Date().toISOString(),
        lenderId: parseInt(getLender.FieldValue),
        objectInstanceId: objectId,
        selected: false
      };

      dealRef.get().then((doc) => {
        let lenderDecisions: { [key: number]: ILenderDecision[] } =
          doc.get('lenderDecision') || {};

        const existingDecisions = lenderDecisions[lenderId] || [];
        if (!existingDecisions) return;

        const existingIndex = existingDecisions.findIndex(
          (decision: ILenderDecision) => decision.objectInstanceId === objectId
        );

        if (existingIndex > -1) {
          existingDecisions[existingIndex] = {
            ...existingDecisions[existingIndex],
            ...newDecision
          };
        } else {
          existingDecisions.push(newDecision);
        }

        lenderDecisions[lenderId] = existingDecisions;
        return dealRef.update({
          lenderDecision: lenderDecisions
        });
      });
    }
  };

  const lenderDecisionCompletedByUser = () => {
    if (!firestoreLenderInfo) return undefined;

    let newObjects: {
      completeObjectInstanceId: number;
      completedByUser: string;
      status: string;
    }[] = [];

    for (const CompleteObjectInstance of CompleteObjectInstanceList) {
      const objectId = CompleteObjectInstance.ObjectInstance.Id;

      const getLender = findFieldInstance({
        CompleteObjectInstance,
        fieldDefinitionId: LENDER_FIELD_DEFINITION
      });

      const getLenderStatus = findFieldInstance({
        CompleteObjectInstance,
        fieldDefinitionId: LENDER_PROPOSAL_DECISION
      });

      if (
        !getLenderStatus ||
        getLenderStatus.FieldValue === '' ||
        !getLender ||
        isNaN(parseInt(getLender.FieldValue))
      ) {
        continue;
      }

      const lenderId = parseInt(getLender.FieldValue);
      const lenderDecisions = firestoreLenderInfo[lenderId];
      if (!lenderDecisions) continue;

      const findFromLenderDecisions = lenderDecisions.find((decision) => {
        return decision.objectInstanceId === objectId;
      });

      if (findFromLenderDecisions) {
        if (findFromLenderDecisions.completedByUserId) {
          newObjects.push({
            completeObjectInstanceId: objectId,
            completedByUser: findFromLenderDecisions.completedByUserId,
            status: findFromLenderDecisions.lenderDecision
          });
        }
      }
    }

    setBrokerAccepted(newObjects);
  };

  useEffect(() => {
    if (!isInlineField) lenderDecisionCompletedByUser();
  }, [CompleteObjectInstanceList, firestoreLenderInfo]);

  useEffect(() => {
    if (!isInlineField) {
      const newReadOnlyFields = determineReadOnlyFields({
        CompleteObjectInstanceList,
        firestoreLenderInfo
      });

      setReadOnlyFields(newReadOnlyFields);
    }
  }, [CompleteObjectInstanceList, firestoreLenderInfo]);

  useEffect(() => {
    const fetchData = async () => {
      if (!firestoreLenderInfo) return;
      for (const CompleteObjectInstance of CompleteObjectInstanceList) {
        if (!CompleteObjectInstance.ObjectInstance.Selected) continue;

        const objectId = CompleteObjectInstance.ObjectInstance.Id;
        const compareLender = findFieldInstance({
          CompleteObjectInstance,
          fieldDefinitionId: LENDER_FIELD_DEFINITION
        });
        if (!compareLender) continue;

        const lenderId = parseInt(compareLender.FieldValue);
        const lenderRefs = firestoreLenderInfo[lenderId];
        if (!lenderRefs || !Array.isArray(lenderRefs)) continue;

        const isObjectInstanceIdAlreadySet = (
          Object.values(firestoreLenderInfo).flat() as ILenderDecision[]
        ).some((lender) => lender.objectInstanceId === objectId);

        if (isObjectInstanceIdAlreadySet) {
          continue;
        }

        const updatedLenderRefs = lenderRefs.map((lender) => {
          if (lender.selected) {
            return {
              ...lender,
              objectInstanceId: CompleteObjectInstance.ObjectInstance.Id,
              selected: false
            };
          }
          return lender;
        });

        const dealRef = firebase
          .firestore()
          .collection('deal')
          .doc(currentOverView.ProcessInstanceId.toString());

        try {
          await dealRef.update({
            [`lenderDecision.${lenderId}`]: updatedLenderRefs
          });
        } catch (e) {
          BugTracker.notify(e);
        }
      }
    };

    fetchData();
  }, [CompleteObjectInstanceList, firestoreLenderInfo]);

  return {
    modifiedObjectInstances,
    completeLenderDecisionOnBehalfOfFunder,
    readOnlyFields,
    brokerAccepted
  };
};

export default useRepeatableObject;

const modifyInstance = ({
  CompleteObjectInstance,
  firestoreLenderInfo
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  firestoreLenderInfo: { [key: number]: ILenderDecision[] } | undefined;
}): CompleteObjectInstance => {
  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  const compareLender: FieldInstance | undefined = findFieldInstance({
    CompleteObjectInstance,
    fieldDefinitionId: LENDER_FIELD_DEFINITION
  });

  if (!compareLender || !firestoreLenderInfo) return CompleteObjectInstance;
  const lenderId = parseInt(compareLender.FieldValue);
  const lenderRefs = firestoreLenderInfo[lenderId];

  if (!lenderRefs) {
    return CompleteObjectInstance;
  }

  const objectId = CompleteObjectInstance.ObjectInstance.Id;
  const matchingLenderRef = lenderRefs.find(
    (lender) => lender.objectInstanceId === objectId
  );

  if (matchingLenderRef) {
    FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
      if (
        LENDER_PROPOSAL_DECISION.includes(FieldInstance.FieldDefinitionId) &&
        matchingLenderRef.lenderDecision
      ) {
        FieldInstance.FieldValue = matchingLenderRef.lenderDecision;
      }

      if (ADDITIONAL_FIELD_IDS.includes(FieldInstance.FieldDefinitionId)) {
        if (
          LENDER_PROPOSAL_DECISION_FUNDER.includes(
            FieldInstance.FieldDefinitionId
          ) &&
          matchingLenderRef.lenderFunderReference !== ''
        ) {
          FieldInstance.FieldValue = matchingLenderRef.lenderFunderReference;
        }

        if (
          LENDER_PROPOSAL_DECISION_DOCUMENT.includes(
            FieldInstance.FieldDefinitionId
          ) &&
          matchingLenderRef.lenderDecisionDocument !== ''
        ) {
          FieldInstance.FieldValue = matchingLenderRef.lenderDecisionDocument;
        }
      }
    });
  }

  return CompleteObjectInstance;
};

export const findFieldInstance = ({
  CompleteObjectInstance,
  fieldDefinitionId
}: {
  CompleteObjectInstance: CompleteObjectInstance;
  fieldDefinitionId: number | number[];
}): FieldInstance | undefined => {
  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  const fieldDefinitionIds = Array.isArray(fieldDefinitionId)
    ? fieldDefinitionId
    : [fieldDefinitionId];

  return FieldInstanceList.find((FieldInstance: FieldInstance) =>
    fieldDefinitionIds.includes(FieldInstance.FieldDefinitionId)
  );
};

const determineReadOnlyFields = ({
  CompleteObjectInstanceList,
  firestoreLenderInfo
}: {
  CompleteObjectInstanceList: CompleteObjectInstance[];
  firestoreLenderInfo: { [key: number]: ILenderDecision[] } | undefined;
}): number[] => {
  const readOnlyFieldIds: number[] = [];
  if (!firestoreLenderInfo) return readOnlyFieldIds;

  CompleteObjectInstanceList.forEach(
    (CompleteObjectInstance: CompleteObjectInstance) => {
      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
      const compareLender: FieldInstance | undefined = findFieldInstance({
        CompleteObjectInstance,
        fieldDefinitionId: LENDER_FIELD_DEFINITION
      });

      if (!compareLender) return;
      const lenderId = parseInt(compareLender.FieldValue);
      const lenderRefs = firestoreLenderInfo[lenderId] || [];

      if (lenderRefs.length === 0) return;
      const objectId = CompleteObjectInstance.ObjectInstance.Id;
      const matchingLenderRef = lenderRefs.find(
        (lender) => lender.objectInstanceId === objectId
      );

      if (matchingLenderRef) {
        FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
          if (
            LENDER_PROPOSAL_DECISION.includes(
              FieldInstance.FieldDefinitionId
            ) &&
            matchingLenderRef.lenderDecision
          ) {
            if (
              FieldInstance.FieldValue === matchingLenderRef.lenderDecision &&
              FieldInstance.FieldValue !== ''
            ) {
              readOnlyFieldIds.push(FieldInstance.Id);
            }
          }

          if (ADDITIONAL_FIELD_IDS.includes(FieldInstance.FieldDefinitionId)) {
            if (
              LENDER_PROPOSAL_DECISION_FUNDER.includes(
                FieldInstance.FieldDefinitionId
              ) &&
              matchingLenderRef.lenderFunderReference !== ''
            ) {
              if (
                FieldInstance.FieldValue ===
                  matchingLenderRef.lenderFunderReference &&
                FieldInstance.FieldValue !== ''
              ) {
                readOnlyFieldIds.push(FieldInstance.Id);
              }
            }
            if (
              LENDER_PROPOSAL_DECISION_DOCUMENT.includes(
                FieldInstance.FieldDefinitionId
              ) &&
              matchingLenderRef.lenderDecisionDocument !== ''
            ) {
              if (
                FieldInstance.FieldValue ===
                  matchingLenderRef.lenderDecisionDocument &&
                FieldInstance.FieldValue !== ''
              ) {
                readOnlyFieldIds.push(FieldInstance.Id);
              }
            }
          }
        });
      }
    }
  );

  return readOnlyFieldIds;
};
