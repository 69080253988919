import { useProcess } from 'hooks';
import { createContext, ReactNode, useEffect, useState } from 'react';
import {
  CompleteObjectInstance,
  FieldDefinition,
  FieldDefinitionDict,
  FieldInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { IProps } from '../interfaces';

interface IQuoteBooleans {
  open: boolean;
  HighestToLowest: boolean;
  LowestToHighest: boolean;
  LastModified: boolean;
}

interface IQuoteCreationContext {
  newlyCreatedQuotes: CompleteObjectInstance[];
  startedQuotes: CompleteObjectInstance[];
  openBooleans: IQuoteBooleans;
  setOpenBooleans: React.Dispatch<React.SetStateAction<IQuoteBooleans>>;
  objectsToShow: CompleteObjectInstance[];
  canAddMore: boolean;
  SortByLastModified: CompleteObjectInstance[];
}

export const QuoteCreationContext = createContext<IQuoteCreationContext>({
  newlyCreatedQuotes: [],
  startedQuotes: [],
  openBooleans: {
    open: false,
    HighestToLowest: false,
    LowestToHighest: false,
    LastModified: false
  },
  setOpenBooleans: () => {},
  objectsToShow: [],
  canAddMore: false,
  SortByLastModified: []
});
const QuoteCreationProvider = ({
  children,
  props
}: {
  children: ReactNode;
  props: IProps;
}) => {
  const { currentDeal, landingpage } = useProcess();
  const [newlyCreatedQuotes, setNewlyCreatedQuotes] = useState<
    CompleteObjectInstance[]
  >([]);
  const [startedQuotes, setStartedQuotes] = useState<any>([]);

  const [openBooleans, setOpenBooleans] = useState({
    open: false,
    HighestToLowest: true,
    LowestToHighest: false,
    LastModified: false
  });

  const {
    FieldDefinitionList,
    FieldDefinitionDict,
    CompleteObjectInstanceList,
    ObjectDefinition
  } = props;

  /** COMMISSION INDEX > FILTER ON COMMISSION AMOUNT */
  const objects: CompleteObjectInstance[] = CompleteObjectInstanceList
    ? CompleteObjectInstanceList
    : Object.values(currentDeal.CompleteObjectInstanceDict).filter(
        (item: CompleteObjectInstance) =>
          item.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
      );

  const CommissionFieldDefinition: FieldDefinition = FieldDefinitionList
    ? FieldDefinitionList.find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Title === 'CommissionAmount'
      )
    : Object.values(FieldDefinitionDict).find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Title === 'CommissionAmount'
      );

  const findingQuotesFieldInstanceDict: FieldInstance[] = objects
    .map((object: CompleteObjectInstance) => {
      const FieldInstanceList = getFieldInstances(object);
      return FieldInstanceList.find(
        (index: FieldInstance) =>
          index.FieldDefinitionId === CommissionFieldDefinition?.Id
      );
    })
    .filter(
      (FieldInstance): FieldInstance is FieldInstance =>
        FieldInstance !== undefined
    );

  const findingEmptyFieldValues = findingQuotesFieldInstanceDict.map(
    (FieldInstance: FieldInstance) => {
      if (FieldInstance?.FieldValue === '') {
        return FieldInstance;
      }
    }
  );

  const pushingNewlyCreatedQuotes = () => {
    let sub_array: FieldInstance[] | undefined = [];
    const filterObjects: (FieldInstance | undefined)[] =
      findingEmptyFieldValues.filter((filter: FieldInstance | undefined) => {
        return filter !== undefined;
      });

    grabbingAllNewlyCreatedQuotes(filterObjects, sub_array);
    const subArrayMapping = sub_array.map((subarray: FieldInstance) => {
      return subarray[0];
    });

    setOpenBooleans({ ...openBooleans, LastModified: false });
    setNewlyCreatedQuotes(subArrayMapping);
  };

  const grabbingAllNewlyCreatedQuotes = (
    filterObjects: (FieldInstance | undefined)[],
    sub_array: FieldInstance[] | undefined
  ) => {
    for (let index = 0; index < filterObjects.length; index++) {
      const findingValues: any = objects.filter(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
          return FieldInstanceList.find((FieldInstance: FieldInstance) => {
            const filterObject = filterObjects[index];
            if (filterObject) {
              return FieldInstance?.Id === filterObject.Id;
            }
          });
        }
      );

      sub_array && sub_array.push(findingValues);
    }
  };

  const newlyCreatedQuotesOverride = new Set(newlyCreatedQuotes);
  const startedQuotesArray = objects.filter((filter: any) => {
    return !newlyCreatedQuotesOverride.has(filter);
  });

  const sortByCommissionIndex = startedQuotes.sort(
    (a: CompleteObjectInstance, b: CompleteObjectInstance) => {
      const FieldInstanceListA = getFieldInstances(a);
      const FieldInstanceListB = getFieldInstances(b);

      let A: FieldInstance | undefined = FieldInstanceListA.find(
        (i: FieldInstance) =>
          i?.FieldDefinitionId === CommissionFieldDefinition?.Id
      );
      let B: FieldInstance | undefined = FieldInstanceListB.find(
        (i: FieldInstance) =>
          i?.FieldDefinitionId === CommissionFieldDefinition?.Id
      );

      if (!A || !B) return !A ? 1 : -1;
      const valueA = parseFloat(A?.FieldValue || '0');
      const valueB = parseFloat(B?.FieldValue || '0');

      if (openBooleans.HighestToLowest) return valueB - valueA;
      if (openBooleans.LowestToHighest) return valueA - valueB;

      if (openBooleans.LastModified) {
        return (
          new Date(b.ObjectInstance.LastModified || '').getTime() -
          new Date(a.ObjectInstance.LastModified || '').getTime()
        );
      }

      return valueB - valueA;
    }
  );

  const SortByLastModified: CompleteObjectInstance[] = newlyCreatedQuotes.sort(
    (a: CompleteObjectInstance, b: CompleteObjectInstance) => {
      return b.ObjectInstance.ItemOrder - a.ObjectInstance.ItemOrder;
    }
  );

  const newCreatedQuoteIndexLayout = async () => {
    const getIndex: number[] = Object.entries(newlyCreatedQuotes).map(
      (item: [string, CompleteObjectInstance]) => {
        return parseInt(item[0]);
      }
    );

    newlyCreatedQuotes.map((quote: CompleteObjectInstance, index: number) => {
      return (quote.ObjectInstance.ItemOrder = getIndex[index]);
    });
  };

  const indexLayout = () => {
    const getIndex = Object.entries(sortByCommissionIndex).map((item: any) => {
      return parseInt(item[0]);
    });

    startedQuotes.map((quote: any, index: number) => {
      return (quote.ObjectInstance.ItemOrder = getIndex[index]);
    });
    setOpenBooleans({ ...openBooleans, LastModified: false });
  };

  const landingPageFilter = sortByCommissionIndex.filter(
    (item: any) => item?.ObjectInstance.IsPublished
  );
  const objectsToShow: CompleteObjectInstance[] = landingpage
    ? landingPageFilter
    : sortByCommissionIndex;

  const canAddMore =
    !landingpage &&
    (props.ObjectDefinition.ObjectRepeat === 0 ||
      objects.length < props.ObjectDefinition.ObjectRepeat);

  useEffect(() => {
    pushingNewlyCreatedQuotes();
  }, [currentDeal]);

  useEffect(() => {
    setStartedQuotes(startedQuotesArray);
    newCreatedQuoteIndexLayout();
  }, [newlyCreatedQuotes]);

  useEffect(() => {
    indexLayout();
  }, [startedQuotes]);

  const value = {
    newlyCreatedQuotes,
    startedQuotes,
    openBooleans,
    setOpenBooleans,
    objectsToShow,
    canAddMore,
    SortByLastModified
  };

  return (
    <QuoteCreationContext.Provider value={value}>
      {children}
    </QuoteCreationContext.Provider>
  );
};

export default QuoteCreationProvider;

const findCommissionFieldDefinition = ({
  FieldDefinitionList,
  FieldDefinitionDict
}: {
  FieldDefinitionList: FieldDefinition[];
  FieldDefinitionDict: FieldDefinitionDict;
}) => {
  return FieldDefinitionList
    ? FieldDefinitionList.find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Title === 'CommissionAmount'
      )
    : Object.values(FieldDefinitionDict).find(
        (FieldDefinition: FieldDefinition) =>
          FieldDefinition.Title === 'CommissionAmount'
      );
};

// const getObjects = ({
//   CompleteObjectInstanceList,
//   currentDeal,
//   ObjectDefinition
// }: {
//   CompleteObjectInstanceList: CompleteObjectInstance[] | undefined;
//   currentDeal: CompleteProcessInstance;
//   ObjectDefinition: ObjectDefinition;
// }) => {
//   return CompleteObjectInstanceList
//     ? CompleteObjectInstanceList
//     : Object.values(currentDeal.CompleteObjectInstanceDict).filter(
//         (item: CompleteObjectInstance) =>
//           item.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
//       );
// };

// const getFilteredQuotes = ({
//   objects,
//   CommissionFieldDefinition,
//   openBooleans
// }: {
//   objects: CompleteObjectInstance[];
//   CommissionFieldDefinition: FieldDefinition;
//   openBooleans: IQuoteBooleans;
// }) => {
//   const findingQuotesFieldInstanceDict = objects.map(
//     (object: CompleteObjectInstance) => {
//       return Object.values(object.FieldInstanceDict).find(
//         (index: FieldInstance) =>
//           index?.FieldDefinitionId === CommissionFieldDefinition?.Id
//       );
//     }
//   );

//   const findingEmptyFieldValues = findingQuotesFieldInstanceDict.map(
//     (quotes: FieldInstance) => (quotes?.FieldValue === '' ? quotes : undefined)
//   );

//   let subArray: any = [];
//   const filterObjects = findingEmptyFieldValues.filter(
//     (filter) => filter !== undefined
//   );

//   filterObjects.forEach((filter: FieldInstance | undefined) => {
//     const findingValues = objects.filter(
//       (filterObject: CompleteObjectInstance) => {
//         return Object.values(filterObject?.FieldInstanceDict).find(
//           (key: FieldInstance) => {
//             if (filter) {
//               return key?.Id === filter.Id;
//             }
//           }
//         );
//       }
//     );

//     subArray.push(findingValues);
//   });

//   const newlyCreatedQuotes = subArray.map((subarray) => subarray[0]);
//   const newlyCreatedQuotesOverride = new Set(newlyCreatedQuotes);
//   const startedQuotesArray = objects.filter(
//     (filter) => !newlyCreatedQuotesOverride.has(filter)
//   );

//   const sortByCommissionIndex = startedQuotesArray.sort(
//     (a: CompleteObjectInstance, b: CompleteObjectInstance) => {
//       let A = Object.values(b?.FieldInstanceDict).find(
//         (i) => i?.FieldDefinitionId === CommissionFieldDefinition?.Id
//       );
//       let B = Object.values(a.FieldInstanceDict).find(
//         (i) => i?.FieldDefinitionId === CommissionFieldDefinition?.Id
//       );

//       if (openBooleans.HighestToLowest)
//         return parseFloat(A?.FieldValue) - parseFloat(B?.FieldValue);
//       if (openBooleans.LowestToHighest)
//         return parseFloat(B?.FieldValue) - parseFloat(A?.FieldValue);
//       if (openBooleans.LastModified) {
//         return (
//           new Date(b.ObjectInstance.LastModified || '').getTime() -
//           new Date(a.ObjectInstance.LastModified || '').getTime()
//         );
//       }
//       return parseFloat(B?.FieldValue) - parseFloat(A?.FieldValue);
//     }
//   );

//   return {
//     created: newlyCreatedQuotes,
//     started: sortByCommissionIndex
//   };
// };
