const InvestecProposal = {
  brokerId: '',
  messageId: '',
  operator: '',
  submitDate: '',
  client: {
    id: '',
    companyType: '',
    statusConfirmed: true,
    soleTrader: {
      title: '',
      forename: '',
      surname: '',
      tradingAs: '',
      dateOfBirth: '',
      proprietorAddress: {
        id: '',
        address: {
          buildingName: '',
          streetNumber: '',
          streetName: '',
          district: '',
          townCity: '',
          county: '',
          postcode: '',
          atAddressSince: ''
        },
        contact: {
          telephone: '',
          facsimile: '',
          email: ''
        }
      }
    },
    company: {
      companyName: '',
      tradingStyle: '',
      contactTitle: '',
      contactForename: '',
      contactSurname: '',
      companyRegNo: '',
      noOfPartners: 0,
      noOfSalariedPartners: 0,
      partner: [
        {
          id: '',
          title: '',
          forename: '',
          surname: '',
          address: {
            buildingName: '',
            streetNumber: '',
            streetName: '',
            district: '',
            townCity: '',
            county: '',
            postcode: '',
            atAddressSince: ''
          },
          dateOfBirth: '',
          contact: {
            telephone: '',
            facsimile: '',
            email: ''
          }
        }
      ],
      director: [
        {
          id: '',
          title: '',
          forename: '',
          surname: '',
          address: {
            buildingName: '',
            streetNumber: '',
            streetName: '',
            district: '',
            townCity: '',
            county: '',
            postcode: '',
            atAddressSince: ''
          },
          dateOfBirth: '',
          contact: {
            telephone: '',
            facsimile: '',
            email: ''
          },
          searchPermission: true
        }
      ]
    },
    address: {
      buildingName: '',
      streetNumber: '',
      streetName: '',
      district: '',
      townCity: '',
      county: '',
      postcode: '',
      atAddressSince: ''
    },
    contact: {
      telephone: '',
      facsimile: '',
      email: ''
    },
    sicCode: '',
    natureOfBusiness: '',
    vatNo: '',
    dateEstablished: ''
  },
  brokerRef: '',
  summary: '',
  scheme: '',
  contractType: '',
  currency: '',
  startDate: '',
  period: 0,
  equipType_LoanReason: '',
  cost: 0,
  otherCosts: [
    {
      costType: '',
      amount: 0
    }
  ],
  profile: '',
  deposit: 0,
  rental: 0,
  frequency: '',
  paymentMethod: '',
  invoiceChain: true,
  otherInformation: '',
  jointHirer: [
    {
      id: '',
      title: '',
      forename: '',
      surname: '',
      address: {
        buildingName: '',
        streetNumber: '',
        streetName: '',
        district: '',
        townCity: '',
        county: '',
        postcode: '',
        atAddressSince: ''
      },
      dateOfBirth: '',
      contact: {
        telephone: '',
        facsimile: '',
        email: ''
      }
    }
  ],
  guarantor: [
    {
      id: '',
      guarantorType: '',
      guaranteeType: '',
      title: '',
      forename: '',
      surname: '',
      companyName: '',
      address: {
        buildingName: '',
        streetNumber: '',
        streetName: '',
        district: '',
        townCity: '',
        county: '',
        postcode: '',
        atAddressSince: ''
      },
      dateOfBirth: '',
      contact: {
        telephone: '',
        facsimile: '',
        email: ''
      },
      searchPermission: true
    }
  ],
  asset: [
    {
      equipmentType: '',
      make: '',
      model: '',
      description: '',
      serialRegNo: '',
      otherSerialNo: '',
      newUsed: '',
      cost: 0,
      dateOfManufacture: '',
      supplier: {
        id: '',
        companyName: '',
        address: {
          buildingName: '',
          streetNumber: '',
          streetName: '',
          district: '',
          townCity: '',
          county: '',
          postcode: '',
          atAddressSince: ''
        },
        contact: {
          telephone: '',
          facsimile: '',
          email: ''
        }
      }
    }
  ]
};

export default InvestecProposal;
