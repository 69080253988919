import { UserInstance } from 'types/interfaces';
import Calendar from './components';
import { CalendarProvider } from './context/CalendarContext';
import { CalendarEventProvider } from './context/EventContext';
import { IEvent } from './interface';

export interface ITimelineEventsState {
  UserInstance: UserInstance | undefined;
  Event: IEvent[];
}

const MainCalendar = ({
  timelineEvents,
  setTimelineEvents,
  refreshTimelineEvents,
  isTimeline = false
}: {
  setTimelineEvents?: React.Dispatch<
    React.SetStateAction<ITimelineEventsState | undefined>
  >;
  timelineEvents?: { UserInstance: UserInstance | undefined; Event: IEvent[] };
  refreshTimelineEvents?: () => void;
  isTimeline?: boolean;
}) => {
  const timelineData = timelineEvents ? timelineEvents : undefined;
  return (
    <CalendarProvider
      timelineEvents={timelineData}
      setTimelineEvents={setTimelineEvents}
      isTimeline={isTimeline}
      refreshTimelineEvents={refreshTimelineEvents}>
      <CalendarEventProvider>
        <Calendar timelineEvents={timelineData} />
      </CalendarEventProvider>
    </CalendarProvider>
  );
};

export default MainCalendar;
