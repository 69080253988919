import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    padlock: { fontSize: 200, color: theme.palette.primary.dark },
    root: { position: 'relative', height: '100vh' },
    grid: { height: '100%' },

    quoteWrapper: {
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },

    quote: {
      backgroundColor: theme.palette.common.black,
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: 'url(/images/bkgrnd8.jpg)',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },

    quoteInner: {
      textAlign: 'center',
      flexBasis: '600px'
    },

    content: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center'
    },

    contentBody: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'center'
      }
    },

    version: {
      display: 'flex',
      background: theme.palette.primary.main,
      color: 'white',
      paddingRight: 15
    }
  })
);
