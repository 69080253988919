import { IData } from 'components/User/CreateAsUserButton/interfaces';
import { format } from 'fecha';
import { GetUserDefinitions } from 'redux/database';
import {
  CompleteObjectInstance,
  FieldInstance,
  UserInstance,
  CompleteObjectDefinition,
  FieldDefinition,
  CompleteUserInstance,
  ObjectInstance
} from 'types/interfaces';
import {
  IRedflagAPIData,
  IRedFlagObject,
  IRedflagObjects
} from 'types/redflagInterfaces';

const LastModified = format(new Date(), 'YYYY-MM-DDThh:mm:ss');

/** RED FLAG MAPPER IN USER CREATION */
export const redFlagMapper: (
  props: Partial<IData>
) => Promise<CompleteUserInstance> = async (props) => {
  let CODL;
  const UserInstance = props.UserInstance;
  const UserDefinitionId = props?.UserInstance?.UserDefinitionId;
  const { company } = props;
  const res = await GetUserDefinitions({ UserDefinitionId });
  if (res && res.data) CODL = res.data.CompleteObjectDefinitionList;
  let data: CompleteUserInstance = {
    UserInstance: UserInstance ? UserInstance : ({} as UserInstance),
    CompleteObjectInstanceList: [],
    ObjectDefinitionReplaceList: [],
    CompleteObjectInstanceDict: {},
    CurrentUserInstanceCanSubmit: false,
    SummaryFields: null
  };

  if (company) {
    /**
     * NAME IMPORTER
     */
    const name = props?.data?.attributes?.name;
    data.UserInstance.Title = name || '';

    /**
     * ARRAY TYPE IMPORTERS
     */
    const { CompleteObjectInstanceList, ObjectDefinitionReplaceList } =
      ArrayDataTypeMapper({ CODL, data, company });
    data.CompleteObjectInstanceList.push(...CompleteObjectInstanceList);
    data.ObjectDefinitionReplaceList = ObjectDefinitionReplaceList;

    /**
     * COMPANY DETAILS IMPORTER
     */
    const { newObject }: any = CompanyDataMapper({
      CODL,
      data,
      company
    });

    data.CompleteObjectInstanceList.push(newObject);
    // data.ObjectDefinitionReplaceList.push(ObjectDefinitionReplaceId);

    return data;
  } else {
    /**
     * NAME IMPORTER
     */
    const first_name = props?.data?.attributes?.first_name;
    const surname = props?.data?.attributes?.first_name;
    data.UserInstance.Title = `${first_name} ${surname}`;

    /**
     * DIRECTORSHIPS IMPORT
     */

    const newObject: any = DirectorDataMapper({ CODL, data, props });
    data.CompleteObjectInstanceList.push(newObject);
    return data;
  }
};

/** RED FLAG IMPORT BUTTON */
export const mapDataToUserDefinition = async ({
  isCompany,
  CODL,
  company,
  rowData
}: {
  isCompany: boolean;
  rowData: CompleteUserInstance;
  CODL: CompleteObjectDefinition[];
  company: IRedflagAPIData;
}) => {
  if (isCompany) {
    const data: CompleteUserInstance = JSON.parse(JSON.stringify(rowData));
    data.CompleteObjectInstanceList = [];

    /**
     * NAME IMPORTER
     */
    if (company && company.data && company.data.attributes) {
      const { name } = company.data.attributes;
      const { company_number } = company.data.attributes;
      data.UserInstance.Title = name || '';
      data.UserInstance.UserInstanceEmail = company_number || '';
    }

    /**
     * ARRAY TYPE IMPORTERS
     */
    const { CompleteObjectInstanceList, ObjectDefinitionReplaceList } =
      ArrayDataTypeMapper({ CODL, data, company });

    data.CompleteObjectInstanceList.push(...CompleteObjectInstanceList);
    data.ObjectDefinitionReplaceList = ObjectDefinitionReplaceList;

    /**
     * COMPANY DETAILS IMPORTER
     */
    const { newObject, ObjectDefinitionReplaceId }: any = CompanyDataMapper({
      CODL,
      data,
      company,
      rowData
    });
    data.CompleteObjectInstanceList.push(newObject);
    data.ObjectDefinitionReplaceList.push(ObjectDefinitionReplaceId);

    if (data.CompleteObjectInstanceList === null)
      data.CompleteObjectInstanceList = [];

    return data;
  }
};

export interface RedFlagObject {
  attributes: any;
  id: string;
  type: string;
  links?: { self: string };
  companyEmail?: string;
  relationships?: [];
  ultimate_parent?: any;
}

interface IArrayDataTypeMapper {
  CODL: CompleteObjectDefinition[];
  data: CompleteUserInstance;
  company: IRedflagAPIData;
}

const ArrayDataTypeMapper = ({ CODL, data, company }: IArrayDataTypeMapper) => {
  let CompleteObjectInstanceList = [] as CompleteObjectInstance[];
  let ObjectDefinitionReplaceList: number[] = [];

  const dataTypes: IRedflagObjects[] = [
    'addresses',
    'balance_sheet',
    'cashflow',
    'ccjs',
    'contact',
    'directors',
    'filing_history',
    'group_companies',
    'mortgage',
    'previous_names',
    'profit_loss',
    'ratio_analysis',
    'shareholders',
    'unpaid_debt',
    'unpaid_losses'
  ];

  const makeNames: (dataType: string) => { name: string; slug: string } = (
    dataType
  ) => {
    const search: string = '_';
    const replaceWith: string = ' ';
    const noUnderscore: string = dataType.split(search).join(replaceWith);
    const CasedTitle: string = noUnderscore.replace(/\b\w/g, (c) =>
      c.toUpperCase()
    );
    return { name: CasedTitle, slug: dataType };
  };

  const UltimateParentMapper = (ultimate_parent: any) => {
    if (!ultimate_parent) return;
    let ultimate_parentName = 'ultimate_parent';
    let attributes = ultimate_parent.attributes;
    if (!attributes) return;
    return { ultimate_parentName, ...attributes };
  };

  const NameMapper: (dataType: string) => any[] = (dataType) => {
    if (dataType === 'group_companies') {
      const ultimate_parent_object = {
        id: company.group_companies?.ultimate_parent?.id || '',
        type: company.group_companies?.ultimate_parent?.type || '',
        attributes: UltimateParentMapper(
          company.group_companies?.ultimate_parent
        )
      };
      const ultimate_parentArray = [ultimate_parent_object];
      let newAttributesList: IRedFlagObject[];

      if (
        company.group_companies?.descendant_companies &&
        company.group_companies?.descendant_companies.length > 0
      ) {
        newAttributesList = [
          ...ultimate_parentArray,
          ...company.group_companies?.descendant_companies
        ];
      } else {
        newAttributesList = [...ultimate_parentArray];
      }

      return newAttributesList;
    } else return company[dataType];
  };

  const StringifyObject: (attributes: any, key: string) => string = (
    attributes,
    key
  ) => {
    if (typeof attributes[key] === 'string') {
      return attributes[key] || '';
    } else {
      return JSON.stringify(attributes[key]);
    }
  };

  dataTypes.forEach((dataType) => {
    const hasDescendantCompanies: boolean =
      company[dataType]?.['descendant_companies'] &&
      company[dataType]?.['descendant_companies'].length > 0;
    const hasDataTypeData: boolean =
      company[dataType] && company[dataType].length > 0;
    const hasUltimateParent: boolean = company[dataType]?.['ultimate_parent'];

    // IF IT EXISTS IN REDFLAG
    if (hasDataTypeData || hasDescendantCompanies || hasUltimateParent) {
      // MAKE NAME FOR THE DATATYPE

      const { name, slug } = makeNames(dataType);

      // MAKE A LIST OF ALL THE FIELDS IN THE REDFLAG ATTRIBUTE OBJECT USING THE FIRST ONE
      let FieldsList: { name: string; slug: string }[] = [];

      if (dataType === 'group_companies') {
        if (hasDescendantCompanies) {
          for (const key in company[slug]['descendant_companies'][0]
            .attributes) {
            FieldsList.push(makeNames(key));
          }
        }

        for (const key in company[slug]['ultimate_parent'].attributes) {
          FieldsList.push(makeNames(key));
        }
      } else {
        for (const key in company[slug][0].attributes)
          FieldsList.push(makeNames(key));
      }
      // OBJECT DEFINITION: Repeating objects

      const OBJECTDEF: CompleteObjectDefinition | undefined = CODL?.find(
        (el: CompleteObjectDefinition) => el.ObjectDefinition.Title === name
      );

      // IF THE OBJECT DEFINITION EXISTS
      if (OBJECTDEF) {
        const ObjectInstanceList = [] as CompleteObjectInstance[];
        const { FieldDefinitionList, ObjectDefinition } = OBJECTDEF;

        // FOR EACH RF ROW
        NameMapper(dataType).forEach(
          ({
            attributes,
            id
          }: {
            attributes: { [key: string]: string | {} };
            id: string;
          }) => {
            /**
             * OBJECT
             */
            let ObjectInstance: ObjectInstance = {
              Id: 0,
              LastModified,
              ObjectDefinitionId: ObjectDefinition.Id,
              Title: ObjectDefinition.Title,
              UserDefinitionId: ObjectDefinition.UserDefinitionId,
              UserInstanceId: data.UserInstance.Id,
              ItemOrder: 0,
              ProcessInstanceId: 0,
              Selected: false
            };

            ObjectDefinitionReplaceList.push(ObjectDefinition.Id);

            /**
             * FIELDS
             */
            let FieldInstanceList = [] as FieldInstance[];
            for (const key in attributes) {
              const FieldTitle: { name: string; slug: string } | undefined =
                FieldsList.find(
                  (el: { name: string; slug: string }) => el.slug === key
                );

              // TODO: FieldTitle is always undefined for ultimate_parentName
              if (FieldTitle) {
                const FieldDefinition: FieldDefinition | undefined =
                  FieldDefinitionList &&
                  FieldDefinitionList.find(
                    (el: FieldDefinition) => el.Title === FieldTitle.name
                  );

                if (FieldDefinition) {
                  const FieldValue = StringifyObject(attributes, key);
                  let FieldInstance: FieldInstance = {
                    FieldDefinitionId: FieldDefinition.Id,
                    FieldValue,
                    Id: 0,
                    ObjectDefinitionId: ObjectDefinition.Id,
                    ObjectInstanceId: 0,
                    ProcessInstanceId: 0,
                    Title: FieldDefinition?.Title,
                    UserDefinitionId: ObjectDefinition.UserDefinitionId,
                    UserInstanceId: data.UserInstance.Id
                  };

                  FieldInstanceList.push(FieldInstance);
                }
              }
            }

            if (dataType === 'group_companies') {
              const FieldTitle: { name: string; slug: string } | undefined =
                FieldsList.find(
                  (el: { name: string; slug: string }) =>
                    el.slug === 'company_number'
                );

              if (FieldTitle) {
                const FieldDefinition: FieldDefinition | undefined =
                  FieldDefinitionList &&
                  FieldDefinitionList.find(
                    (el: FieldDefinition) => el.Title === FieldTitle.name
                  );

                if (FieldDefinition) {
                  const FieldValue = id;
                  let FieldInstance: FieldInstance = {
                    FieldDefinitionId: FieldDefinition.Id,
                    FieldValue,
                    Id: 0,
                    ObjectDefinitionId: ObjectDefinition.Id,
                    ObjectInstanceId: 0,
                    UserDefinitionId: ObjectDefinition.UserDefinitionId,
                    UserInstanceId: data.UserInstance.Id,
                    Title: FieldDefinition?.Title,
                    ProcessInstanceId: 0
                  };

                  FieldInstanceList.push(FieldInstance);
                }
              }
            }

            ObjectInstanceList.push({
              ObjectInstance,
              FieldInstanceList,
              FieldInstanceDict: {}
            });
          }
        );

        CompleteObjectInstanceList.push(...ObjectInstanceList);
      }
    } else {
      //TODO: Redflag Issue on Updating
      // const { name } = makeNames(dataType);
      // const OBJECTDEF = CODL.find(
      //   (el: CompleteObjectDefinition) => el.ObjectDefinition.Title === name
      // );
      // if (OBJECTDEF) {
      //   const { ObjectDefinition } = OBJECTDEF;
      //   if (ObjectDefinition) {
      //     ObjectDefinitionReplaceList.push(ObjectDefinition.Id);
      //   }
      // }
    }
  });

  // Remove duplicate value (using a set)
  ObjectDefinitionReplaceList = Array.from(
    new Set(ObjectDefinitionReplaceList)
  );

  return { CompleteObjectInstanceList, ObjectDefinitionReplaceList };
};

const DirectorDataMapper = ({ CODL, data, props }) => {
  const ObjectTitle = 'Directorships';

  // const DirectorshipsFieldsList = {
  //   'Company Number': 'company_number',
  //   'Date Appointed': 'date_appointed',
  //   Name: 'name',
  //   Rating: 'rating'
  // };

  const DirectorshipsFieldsList = {
    Town: 'town',
    'First Name': 'first_name',
    Surname: 'surname',
    Suffix: 'suffix',
    Title: 'title',
    'Date Resigned': 'date_resigned',
    Locality: 'locality',
    Birthdate: 'birthdate',
    County: 'county',
    'Appointment Type': 'appointment_type',
    'Date Appointed': 'date_appointed',
    Postcode: 'postcode',
    Address: 'address',
    Nationality: 'nationality',
    Occupation: 'occupation',
    'Other Appointments': 'other_appointments',
    'Person Id': 'person_id'
  };

  // FIND Directorships
  const CompanyDetailsDEF = CODL?.find(
    (el) => el.ObjectDefinition.Title === ObjectTitle
  );

  if (CompanyDetailsDEF) {
    let newObject: any = {};
    let FieldInstanceList: FieldInstance[] | any = [];
    let FieldInstance: FieldInstance | any = {};

    // GET THE OBJECT REPEAT
    const { ObjectRepeat } = CompanyDetailsDEF.ObjectDefinition;

    // FOR EACH FIELD IN THE MAPPING OBJECT
    Object.keys(DirectorshipsFieldsList).forEach((FieldName) => {
      // GET THE FIELD DEFINITION
      const FieldDefinition = CompanyDetailsDEF.FieldDefinitionList.find(
        (el) => el.Title === FieldName
      );

      // IF THERE IS A FIELD DEFINITION
      if (FieldDefinition) {
        // GET THE FIELD ID AND THE OBJECT DEFINITION ID
        const { Id, ObjectDefinitionId } = FieldDefinition;

        // GET THE FIELDVALUE FROM RED FLAG
        let attribute = DirectorshipsFieldsList[FieldName];
        let FieldValue = props.data.attributes[attribute];

        // GET THE OBJECT INSTANCE BY THE DEFINITION
        const ExistingInstance = data.CompleteObjectInstanceList.find(
          (el) => el.ObjectInstance.ObjectDefinitionId === ObjectDefinitionId
        );

        const newFieldInstance = {
          FieldDefinitionId: Id,
          FieldValue: `${FieldValue}` || '',
          Id: 0,
          ObjectDefinitionId,
          ObjectInstanceId: 0,
          UserDefinitionId: data.UserInstance.UserDefinitionId,
          UserInstanceId: data.UserInstance.Id
        };

        if (ExistingInstance) {
          // GET THE FIELD INSTANCE FROM THE OBJECT INSTANCE
          const ExistingFieldInstance = ExistingInstance.FieldInstanceList.find(
            (el) => el.FieldDefinitionId === Id
          );

          if (ExistingFieldInstance) {
            // UPDATE
            FieldInstance = {
              FieldDefinitionId: ExistingFieldInstance.FieldDefinitionId,
              FieldValue: `${FieldValue}` || '',
              Id: ExistingFieldInstance.Id,
              ObjectDefinitionId,
              ObjectInstanceId: ExistingFieldInstance.ObjectInstanceId,
              UserDefinitionId: ExistingFieldInstance.UserDefinitionId,
              UserInstanceId: ExistingFieldInstance.UserInstanceId
            };
          } else FieldInstance = newFieldInstance;
        } else FieldInstance = newFieldInstance;

        FieldInstanceList.push(FieldInstance);

        let ObjectInstance = {
          Id: ExistingInstance ? ExistingInstance.ObjectInstance.Id : 0,
          LastModified,
          ObjectDefinitionId,
          Title: ObjectTitle,
          UserDefinitionId: data.UserInstance.UserDefinitionId,
          UserInstanceId: data.UserInstance.Id
        };

        newObject.ObjectInstance = ObjectInstance;
        newObject.FieldInstanceList = FieldInstanceList;
      }
    });

    if (ObjectRepeat === 1) {
      //   Filter the data array and remove any object with  title ObjectTitle
      const i = data.CompleteObjectInstanceList.filter(
        (el) => el.ObjectInstance.Title !== ObjectTitle
      );
      data.CompleteObjectInstanceList = i;
    }

    return newObject;
  }
};

interface ICompanyDataMapper {
  CODL: CompleteObjectDefinition[];
  data: CompleteUserInstance;
  rowData?: CompleteUserInstance;
  company: any;
}

const CompanyDataMapper = ({
  CODL,
  data,
  company,
  rowData
}: ICompanyDataMapper) => {
  const ObjectTitle = 'Company Details';
  const CompanyDetailsFieldsList = {
    'Company Number': 'company_number',
    Website: 'website',
    Name: 'name',
    'Short Description': 'short_description',
    'Credit Score': 'credit_score',
    'RedFlag Status': 'short_code',
    'Long Description': 'long_description',
    'Company Type': 'company_type',
    'Incorporation Date': 'incorporation_date',
    'Legal Status': 'legal_status',
    'Estimated Turnover': 'estimated_turnover',
    'Estimated Employees': 'estimated_employees',
    Auditor: 'auditor',
    'Dissolution Status': 'dissolution_status',
    Email: 'email',
    'Sic07 Codes': 'sic07_codes',
    'Rfa Rating': 'rfa_rating'
  };

  const rfa_rating = {
    'Health Rating Code': 'health_rating_code',
    'Credit Limit': 'credit_limit',
    'RedFlag Status': 'short_code',
    'Credit Score': 'credit_score',
    'Trading Payment Parity': 'trading_payment_parity',
    'Short Description': 'short_description',
    'Long Description': 'long_description'
  };

  // FIND COMPANY DETAILS
  const CompanyDetailsDEF: CompleteObjectDefinition | any = CODL?.find(
    (el) => el?.ObjectDefinition.Title === ObjectTitle
  );
  let newObject: any = {};
  if (!CompanyDetailsDEF) return { newObject };

  // IF THE COMPANY DETAILS DEFINITION EXISTS
  if (CompanyDetailsDEF) {
    const ObjectDefinitionReplaceId = CompanyDetailsDEF.ObjectDefinition.Id;

    let FieldInstanceList: FieldInstance[] | any = [];
    let FieldInstance: FieldInstance | any = {};

    // GET THE OBJECT REPEAT
    const { ObjectRepeat } = CompanyDetailsDEF.ObjectDefinition;

    // START === SPECIAL FIELDS //
    // If there are fields in the definition but not in the mapping object
    const SpecialFieldTitles = [
      'Phone',
      'VAT Number',
      'Email',
      'FCA Permission Number',
      'Type',
      'Sic07 Codes'
    ];

    const specialFieldDefinitions =
      CompanyDetailsDEF.FieldDefinitionList.filter(
        (FieldDefinition: FieldDefinition) =>
          SpecialFieldTitles.includes(FieldDefinition?.Title)
      );

    // 1. remove Special Fields from CompanyDetailsFieldsList
    const nonSpecialFields: string[] = Object.keys(
      CompanyDetailsFieldsList
    ).filter((key: string) => !SpecialFieldTitles.includes(key));

    // 2. pass through existing Field Instance for special fields

    // GET THE OBJECT INSTANCE BY THE DEFINITION
    const ExistingInstance =
      rowData &&
      rowData.CompleteObjectInstanceList.find(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          const { ObjectDefinitionId } = CompleteObjectInstance.ObjectInstance;
          return ObjectDefinitionId === ObjectDefinitionReplaceId;
        }
      );

    if (ExistingInstance) {
      specialFieldDefinitions.forEach((FieldDefinition: FieldDefinition) => {
        // GET THE FIELD DEFINITION ID AND THE OBJECT DEFINITION ID
        const { Id, ObjectDefinitionId } = FieldDefinition;

        // GET THE FIELD INSTANCE FROM THE OBJECT INSTANCE
        const ExistingFieldInstance = ExistingInstance.FieldInstanceList.find(
          (el) => el.FieldDefinitionId === Id
        );

        if (ExistingFieldInstance) {
          const FieldInstance: FieldInstance = {
            FieldDefinitionId: ExistingFieldInstance.FieldDefinitionId,
            FieldValue: ExistingFieldInstance.FieldValue,
            Id: ExistingFieldInstance.Id,
            ObjectDefinitionId,
            ObjectInstanceId: ExistingFieldInstance.ObjectInstanceId,
            UserDefinitionId: ExistingFieldInstance.UserDefinitionId,
            UserInstanceId: ExistingFieldInstance.UserInstanceId,
            Title: ExistingFieldInstance.Title,
            ProcessInstanceId: ExistingFieldInstance.ProcessInstanceId
          };
          FieldInstanceList.push(FieldInstance);
        }
      });
    }

    // FOR EACH FIELD IN THE MAPPING OBJECT
    nonSpecialFields.forEach((FieldName: string) => {
      // IF ITS THE NESTED
      if (FieldName === 'Rfa Rating')
        Object.keys(rfa_rating).forEach((key: string) => (FieldName = key));

      // GET THE FIELD DEFINITION
      const FieldDefinition = CompanyDetailsDEF.FieldDefinitionList.find(
        (el) => el.Title === FieldName
      );

      // IF THERE IS A FIELD DEFINITION
      if (FieldDefinition) {
        // GET THE FIELD DEFINITION ID AND THE OBJECT DEFINITION ID
        const { Id, ObjectDefinitionId } = FieldDefinition;

        // GET THE FIELD VALUE FROM RED FLAG
        let FieldValue;
        if (Object.keys(rfa_rating).find((el) => el === FieldName)) {
          FieldValue =
            company?.data?.attributes?.['rfa_rating'][rfa_rating[FieldName]];
        } else {
          FieldValue =
            company?.data?.attributes[CompanyDetailsFieldsList[FieldName]];
        }

        // GET THE OBJECT INSTANCE BY THE DEFINITION
        const ExistingInstance = data.CompleteObjectInstanceList.find(
          (el) => el.ObjectInstance.ObjectDefinitionId === ObjectDefinitionId
        );

        // CREATE THE NEW FIELD INSTANCE
        const newFieldInstance = {
          FieldDefinitionId: Id,
          FieldValue: `${FieldValue}` || '',
          Id: 0,
          ObjectDefinitionId,
          ObjectInstanceId: 0,
          UserDefinitionId: data.UserInstance.UserDefinitionId,
          UserInstanceId: data.UserInstance.Id
        };

        if (ExistingInstance) {
          // GET THE FIELD INSTANCE FROM THE OBJECT INSTANCE
          const ExistingFieldInstance = ExistingInstance.FieldInstanceList.find(
            (el) => el.FieldDefinitionId === Id
          );

          if (ExistingFieldInstance) {
            // UPDATE
            FieldInstance = {
              FieldDefinitionId: ExistingFieldInstance.FieldDefinitionId,
              FieldValue: `${FieldValue}` || '',
              Id: ExistingFieldInstance.Id,
              ObjectDefinitionId,
              ObjectInstanceId: ExistingFieldInstance.ObjectInstanceId,
              UserDefinitionId: ExistingFieldInstance.UserDefinitionId,
              UserInstanceId: ExistingFieldInstance.UserInstanceId
            };
          } else FieldInstance = newFieldInstance;
        } else FieldInstance = newFieldInstance;

        FieldInstanceList.push(FieldInstance);

        let ObjectInstance = {
          Id: ExistingInstance ? ExistingInstance.ObjectInstance.Id : 0,
          LastModified,
          ObjectDefinitionId,
          Title: 'Company Details',
          UserDefinitionId: data.UserInstance.UserDefinitionId,
          UserInstanceId: data.UserInstance.Id
        };

        newObject.ObjectInstance = ObjectInstance;
        newObject.FieldInstanceList = FieldInstanceList;
      }
    });

    if (ObjectRepeat === 1) {
      //   Filter the data array and remove any object with  title ObjectTitle
      const i = data.CompleteObjectInstanceList.filter(
        (el) => el.ObjectInstance.Title !== ObjectTitle
      );
      data.CompleteObjectInstanceList = i;
    }
    return { newObject, ObjectDefinitionReplaceId };
  }
};
