import React, { useEffect, useState, useCallback, SetStateAction } from 'react';
import {
  Button,
  Card,
  CardContent,
  DialogContent,
  Divider,
  Grid,
  GridSize,
  IconButton,
  List,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Close, Filter, FilterList, Person } from '@material-ui/icons';
import { CustomDialog } from 'common/Dialog';
import { Alert, Pagination } from '@material-ui/lab';
import { GetLevel12Search } from 'redux/database/Process Summary API';
import { theme } from 'theme';
import { getDealData } from 'redux/actions/processes/getDealData';
import { debounce } from '.';
import { store } from 'redux/store';
import { SET_GLOBAL_HOSTNAME } from 'redux/actions/types';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { BugTracker } from 'Utils/Bugtracker';

interface IDealProcess {
  HostName: string | null;
  ProcessInstanceId: number;
  HostTitle: string;
  ProcessInstanceTitle: string | null;
  UserInstanceId: number;
  UserInstanceTitle: string | null;
  ProcessDefinitionId: number;
  ProcessDefinitionTitle: string | null;
  ProcessStepDefinitionId: number;
  ProcessStepDefinitionTitle: string | null;
  UserDefinitionId: number;
  UserDefinitionTitle: string | null;
  LastModified: string;
  Guid: string | null;
  ThirdPartyId: string | null;
  AssignedUserList: any[];
  ProcessStepGroupList: any[];
  SelectedFieldList: any[];
}

interface IFilter {
  'Deal Id': string;
  'Deal Title': string;
  'Customer Name': string;
  'Lender Name': string;
  'Supplier Name': string;
}

const INIT_FILTER: IFilter = {
  'Deal Id': '',
  'Deal Title': '',
  'Customer Name': '',
  'Lender Name': '',
  'Supplier Name': ''
};

const Level12Search = ({
  openLevel12Access,
  setOpenLevel12Access,
  setOpen
}: {
  openLevel12Access: boolean;
  setOpenLevel12Access: React.Dispatch<SetStateAction<boolean>>;
  setOpen: (open: boolean) => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dealProcesses, setDealProcesses] = useState<IDealProcess[]>([]);
  const [searchFields, setSearchFields] = useState<string | undefined>(
    undefined
  );
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(2);
  const [closed, setClosed] = useState<boolean>(false);

  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [filters, setFilters] = useState<IFilter>(INIT_FILTER);

  const getData = async (offkeyset?: string, query?: string) => {
    const results = await GetLevel12Search({ offkeyset, query, closed });
    setDealProcesses(results.data);

    if (!offkeyset) {
      setFilters(INIT_FILTER);
    }
  };

  const debouncedGetData = useCallback(
    debounce((query) => {
      if (query !== '') {
        getData(String(pageIndex - 1), query);
      } else {
        getData();
      }
    }, 1000),
    [searchFields, closed]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchFields(value);
    debouncedGetData(value);
  };

  const handleSelectChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = e.target.value as string;
    setClosed(selectedValue === 'closed');
  };

  const openDeal = async ({ dealProcess }: { dealProcess: any }) => {
    setLoading(true);
    const {
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId,
      HostName
    } = dealProcess.additionalInfo;

    const value = {
      hostname: HostName,
      baseURL: `https://${HostName}/v1/`,
      globalHostName: HostName
    };

    store.dispatch({
      type: SET_GLOBAL_HOSTNAME,
      payload: value
    });

    const data = await getDealData({
      ProcessInstanceId,
      ProcessStepDefinitionId,
      ProcessDefinitionId
    });

    if (data) {
      setLoading(false);
      setOpenLevel12Access(false);
      return setOpen(true);
    }
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageIndex(value);
    getData(String(value - 1), searchFields ? searchFields : undefined);
  };

  useEffect(() => {
    const shouldFetchData = () => {
      if (openLevel12Access) return true;
      if (searchFields === '') return true;
      return false;
    };

    if (shouldFetchData()) {
      getData(String(pageIndex - 1), searchFields ? searchFields : undefined);
    }
  }, [openLevel12Access]);

  useEffect(() => {
    getData(String(pageIndex - 1), searchFields ? searchFields : undefined);
  }, [closed]);

  type GroupedEntry = {
    [key: string]: IDealProcess[];
  } & {
    additionalInfo?: IDealProcess;
  };

  const [groupedData, setGroupedData] = useState({});
  const groupData = (data: IDealProcess[]) => {
    const grouped: { [key: number]: GroupedEntry } = {};

    if (data) {
      data.forEach((item) => {
        const id = item.ProcessInstanceId;
        const userType = item.UserDefinitionTitle || 'Unknown';

        if (!grouped[id]) {
          grouped[id] = {};
        }

        if (!grouped[id][userType]) {
          grouped[id][userType] = [];
        }

        grouped[id][userType].push(item);

        if (!grouped[id].additionalInfo) {
          grouped[id].additionalInfo = {
            ...item
          };
        }
      });

      return grouped;
    } else {
      return null;
    }
  };

  const handleFilterChange = (filterName, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: value
    }));
  };

  const filterGroupedData = useCallback(() => {
    return Object.entries(groupedData).reduce(
      (acc, [dealId, dealGroup]: any) => {
        const matchesDealId =
          filters['Deal Id'] === '' ||
          dealGroup.additionalInfo?.ProcessInstanceId.toString().includes(
            filters['Deal Id']
          );

        const customers = dealGroup.Customers || [];
        const matchesCustomerName =
          filters['Customer Name'] === '' ||
          customers.some((customer) =>
            customer.UserInstanceTitle?.toLowerCase().includes(
              filters['Customer Name'].toLowerCase()
            )
          );

        const lenders = dealGroup.Lenders || [];
        const matchesLenderName =
          filters['Lender Name'] === '' ||
          lenders.some((lender) =>
            lender.UserInstanceTitle?.toLowerCase().includes(
              filters['Lender Name'].toLowerCase()
            )
          );

        const matchesDealTitle =
          filters['Deal Title'] === '' ||
          dealGroup.additionalInfo?.ProcessInstanceTitle?.toLowerCase().includes(
            filters['Deal Title'].toLowerCase()
          );

        const suppliers = dealGroup.Suppliers || [];
        const matchesSupplier =
          filters['Supplier Name'] === '' ||
          suppliers.some(
            (supplier) =>
              supplier.UserInstanceTitle === filters['Supplier Name']
          );

        if (
          matchesDealId &&
          matchesCustomerName &&
          matchesLenderName &&
          matchesDealTitle &&
          matchesSupplier
        ) {
          acc[dealId] = dealGroup;
        }

        return acc;
      },
      {}
    );
  }, [groupedData, filters]);

  useEffect(() => {
    const groupedItems = groupData(dealProcesses);
    if (groupedItems) setGroupedData(groupedItems);
  }, [dealProcesses]);

  const filteredData = filterGroupedData();
  const toggle = () => setOpenLevel12Access(!openLevel12Access);
  return (
    <CustomDialog
      maxSize="md"
      open={openLevel12Access}
      handleClose={() => setOpenLevel12Access(false)}
      alert={{
        title: `Level 12 & Level 15 Access Only`,
        description:
          'Exclusive Access for Levels 12 & 15 to Discover Deals Across Instances',
        type: 'info'
      }}>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder="Search Customers, Lenders, Deal Ids"
              value={searchFields}
              onChange={handleSearchChange}
            />
          </Grid>
          <Grid item xs={2} style={{ paddingTop: theme.spacing(2) }}>
            <Select
              variant="outlined"
              fullWidth
              margin="dense"
              value={closed ? 'closed' : 'open'}
              onChange={handleSelectChange}>
              <MenuItem value="closed">Closed Deals</MenuItem>
              <MenuItem value="open">Open Deals</MenuItem>
            </Select>
          </Grid>

          <Grid
            item
            xs={1}
            style={{
              paddingTop: theme.spacing(1.5)
            }}>
            <Tooltip arrow title={'Filter Loaded Data'}>
              <IconButton
                onClick={() => {
                  setShowFilters(!showFilters);
                  setFilters(INIT_FILTER);
                }}>
                <FilterList
                  style={{
                    color: showFilters ? theme.palette.success.main : 'gray'
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>

          {showFilters && (
            <Grid
              container
              direction="row"
              spacing={1}
              style={{
                marginLeft: theme.spacing(1),
                marginRight: theme.spacing(1),
                paddingBottom: theme.spacing(2)
              }}>
              {Object.keys(filters).map((filterName: string, idx: number) => (
                <Grid item key={idx}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder={filterName}
                    onChange={(e) =>
                      handleFilterChange(filterName, e.target.value)
                    }
                  />
                </Grid>
              ))}
            </Grid>
          )}

          <>
            {Object.values(filteredData).length >= 1 ? (
              <Grid container spacing={1}>
                {Object.keys(filteredData).map((dealId, idx) => {
                  const dealGroup = filteredData[dealId];
                  const userTypes = Object.keys(dealGroup).filter(
                    (k) => k !== 'additionalInfo'
                  );

                  const numUserTypes = userTypes.length;

                  let gridValue: GridSize = 12;
                  if ([1, 2, 3, 4, 6, 12].includes(numUserTypes)) {
                    gridValue = (12 / numUserTypes) as GridSize;
                  }

                  return (
                    <Grid item xs={12} key={idx}>
                      <Button
                        style={{
                          textTransform: 'none',
                          width: '100%',
                          textAlign: 'left',
                          display: 'block'
                        }}
                        onClick={() =>
                          openDeal({ dealProcess: filteredData[dealId] })
                        }>
                        <Paper
                          elevation={1}
                          style={{
                            margin: theme.spacing(1),
                            padding: theme.spacing(1)
                          }}>
                          <div>
                            <Typography variant="h6">
                              {`Deal ID: ${dealId}`}
                            </Typography>
                            <Typography variant="h6">
                              {`Customer: ${
                                dealGroup?.Customers?.[0]?.UserInstanceTitle ??
                                'Unknown'
                              }`}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                              {`Deal Title: ${
                                dealGroup.additionalInfo
                                  ?.ProcessInstanceTitle ?? 'N/A'
                              }`}
                            </Typography>
                          </div>

                          <Typography variant="body2">
                            {`Process Definition: ${
                              dealGroup.additionalInfo
                                ?.ProcessDefinitionTitle ?? 'N/A'
                            }`}
                          </Typography>
                          <Typography variant="body2">
                            {`Instance: ${
                              dealGroup.additionalInfo?.HostName ?? 'N/A'
                            }`}
                          </Typography>

                          <Divider style={{ margin: theme.spacing(0.5) }} />
                          <Grid container spacing={1}>
                            {userTypes.map((userType, idx) => (
                              <Grid item xs={gridValue} key={idx}>
                                <Card
                                  elevation={1}
                                  style={{
                                    height: '100%'
                                  }}>
                                  <Typography
                                    variant="h6"
                                    style={{
                                      fontWeight: 'bold',
                                      margin: 10
                                    }}>{`User Definition: ${userType}`}</Typography>

                                  <CardContent>
                                    {openLevel12Access && (
                                      <List dense>
                                        {dealGroup[userType].map(
                                          (user, idx) => (
                                            <QuickUserFromId
                                              key={idx}
                                              UserInstanceId={
                                                user.UserInstanceId
                                              }
                                            />
                                          )
                                        )}
                                      </List>
                                    )}
                                  </CardContent>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </Paper>
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Alert
                severity="warning"
                style={{
                  width: '100%',
                  padding: theme.spacing(1),
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1)
                }}>
                <Typography>
                  {`We Couldn't Locate Any Data Matching Your Search Criteria. Please Try Adjusting Your Search Terms Or Explore Other Categories.`}
                </Typography>
              </Alert>
            )}
          </>

          <Grid
            container
            spacing={3}
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              padding: theme.spacing(1)
            }}>
            <Grid item>
              <Pagination
                count={totalPages}
                page={pageIndex}
                onChange={handlePageChange}
                color="primary"
                variant="outlined"
                shape="rounded"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default Level12Search;
