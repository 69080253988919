import React, { useEffect, useState } from 'react';
import { UserInstance } from 'types/interfaces';
import InlineTextField from 'components/Fields/InlineTextField';
import {
  useTheme,
  Grid,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  IconButton
} from '@material-ui/core';
import QuickUser from 'components/User/UserLozenge';
import {
  FieldInstance,
  CompleteObjectDefinition,
  FieldDefinitionDict,
  FieldDefinition,
  ObjectDefinition
} from 'types/interfaces';
import { useProcess } from 'hooks/useProcess';
import { useTypedSelector } from 'redux/reducers';
import { XpansionIsolated } from 'common/Xpansion';
import { getIfObjectIsSelected } from 'redux/database';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { useMobile } from 'hooks';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
    item: { padding: theme.spacing(4) },
    paper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: theme.spacing(1)
    },
    check: { color: theme.palette.success.main },
    warning: { color: theme.palette.warning.main }
  })
);

const Component = ({ object }: { object: CompleteObjectDefinition }) => {
  const { FieldDefinitionDict, FieldDefinitionList, ObjectDefinition } = object;
  const { currentDeal } = useProcess();

  const ProcessInstanceId = currentDeal.ProcessInstance.Id;
  const { processInstanceFields } = useTypedSelector((s) => s.process);

  // This needs to fetch data if the deal has been changed, maybe just get all data every time for now

  const InstanceFields =
    processInstanceFields && processInstanceFields[ProcessInstanceId];

  const FieldInstanceList =
    InstanceFields &&
    InstanceFields.filter(
      (item) => item.ObjectDefinitionId === ObjectDefinition.Id
    );

  const userFieldInstanceList =
    FieldInstanceList && groupBy(FieldInstanceList, 'UserInstanceId');

  return (
    <div style={{ width: '100%' }}>
      {userFieldInstanceList
        ? Object.keys(userFieldInstanceList).map(
            (UserInstanceId: string, index: number) => {
              return (
                <UserTable
                  CompleteObjectDefinition={object}
                  FieldDefinitionDict={FieldDefinitionDict}
                  FieldDefinitionList={FieldDefinitionList}
                  key={index}
                  ObjectDefinition={ObjectDefinition}
                  userFieldInstanceList={userFieldInstanceList}
                  UserInstanceId={UserInstanceId}
                />
              );
            }
          )
        : null}
    </div>
  );
};

export default Component;

const UserTable = ({
  userFieldInstanceList,
  UserInstanceId,
  FieldDefinitionDict,
  FieldDefinitionList,
  ObjectDefinition,
  CompleteObjectDefinition
}: {
  userFieldInstanceList: FieldInstance[];
  UserInstanceId: string;
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList?: FieldDefinition[];
  ObjectDefinition: ObjectDefinition;
  CompleteObjectDefinition: CompleteObjectDefinition;
}) => {
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const [user, setUser] = React.useState<UserInstance>({} as UserInstance);
  const ref: any = React.useRef(null);
  const theme = useTheme();

  const data: { [FieldDefinitionId: string]: FieldInstance }[] = getData({
    FieldInstanceList: userFieldInstanceList[UserInstanceId]
  });

  const columns = getColumns({
    FieldDefinitionList,
    ObjectDefinition
  });

  const getUser = async () => {
    const newUserInstanceId = parseInt(UserInstanceId);
    const response = await getQuickLiteUser({
      baseUrl,
      UserInstanceId: newUserInstanceId,
      action: 'GET'
    });

    const UserInstance = response?.UserInstance;
    if (UserInstance) setUser(UserInstance);
  };

  React.useEffect(() => {
    getUser();
  }, []);

  return (
    <div ref={ref} style={{ width: '100%', marginTop: theme.spacing(2) }}>
      {user && (
        <Typography style={{ marginBottom: theme.spacing(2) }}>
          <QuickUser user={user} />
        </Typography>
      )}

      <div>
        {data.map(
          (
            FieldDefinitionDict: {
              [FieldDefinitionId: string]: FieldInstance;
            },
            index: number
          ) => (
            <ObjectInstantiated
              key={index}
              index={index}
              ObjectDefinition={ObjectDefinition}
              FieldDefinitionDict={FieldDefinitionDict}
              FieldDefinitionList={FieldDefinitionList}
            />
          )
        )}
      </div>
      {/* <div>
        {ref && ref.current && (
          <div style={{ width: ref?.current?.clientWidth - theme.spacing(2) }}>
            <Table
              columns={columns}
              data={data}
              handleRowClick={() => {
                // console.log('No Action')
              }}
              title=""
            />
          </div>
        )}
      </div> */}
    </div>
  );
};

const getColumns = ({ FieldDefinitionList, ObjectDefinition }) => {
  let columns: any = [];
  FieldDefinitionList.forEach((FieldDefinition) => {
    const { Title, FieldType, Id } = FieldDefinition;
    columns.push({
      title: Title,
      field: Id.toString(),
      render: (rowData: any) => {
        if (!rowData[Id]) return null;
        return (
          <InlineTextField
            FieldDefinition={FieldDefinition}
            FieldInstance={rowData && rowData[Id]}
            ObjectDefinition={ObjectDefinition}
            readOnly
            type={FieldType}
            value={rowData && rowData[Id] && rowData[Id].FieldValue}
            fields={[]}
            ProcessStepSelectionType={''}
            ObjectInstance={undefined}
            UserInstance={undefined}
            ProcessInstance={undefined}
            isOverview
          />
        );
      }
    });
  });
  return columns;
};

interface IgetData {
  FieldInstanceList: FieldInstance[];
}

const getData = ({ FieldInstanceList }: IgetData) => {
  // For Each Object Instance create a row and push each FieldInstance by FieldDefinition into that row.
  let data: {
    [ObjectInstanceId: string]: { [FieldDefinitionId: string]: FieldInstance };
  } = {};
  FieldInstanceList &&
    FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
      const { ObjectInstanceId, FieldDefinitionId } = FieldInstance;
      if (!data[ObjectInstanceId]) data[ObjectInstanceId] = {};
      if (!data[ObjectInstanceId][FieldDefinitionId])
        data[ObjectInstanceId][FieldDefinitionId] = FieldInstance;
    });
  return Object.values(data);
};

const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const ObjectInstantiated = ({
  index,
  ObjectDefinition,
  FieldDefinitionDict,
  FieldDefinitionList
}: {
  index: number;
  ObjectDefinition: ObjectDefinition;
  FieldDefinitionDict: { [FieldDefinitionId: string]: FieldInstance };
  FieldDefinitionList: FieldDefinition[] | undefined;
}) => {
  const landingpage = useTypedSelector((s) => s.utility.landingpage);
  const classes = useStyles();
  const isMobile = useMobile();
  const [isSelected, setIsSelected] = useState<null | string>(null);
  const ObjectInstanceId =
    Object.values(FieldDefinitionDict)[0].ObjectInstanceId;
  const getIfSelected = async () => {
    const res = await getIfObjectIsSelected({ ObjectInstanceId });
    if (res && res.data) {
      const isSelected = res.data as string;
      if (isSelected === 'True') {
        setIsSelected(isSelected);
      }
    }
  };

  useEffect(() => {
    getIfSelected();
  }, []);

  const QuoteObject = 3565;
  const LenderCommissionInvoice = 3486;
  const IdDisplayObjects = [QuoteObject, LenderCommissionInvoice];

  return (
    <XpansionIsolated
      key={index}
      summary={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            color: 'grey',
            width: '100%'
          }}>
          {IdDisplayObjects.includes(ObjectDefinition.Id) ? (
            <div style={{ flexGrow: 1 }}>
              {ObjectDefinition.Title} {ObjectInstanceId}
            </div>
          ) : (
            <div style={{ flexGrow: 1 }}>
              {ObjectDefinition.Title} : {index + 1}
            </div>
          )}
          {/* <div style={{ flexGrow: 1 }}>
            {ObjectDefinition.Title} : {index + 1}
          </div> */}

          {isSelected === 'True' ? <StarIcon /> : <StarBorderIcon />}
        </div>
      }>
      <Grid
        container
        alignItems={'center'}
        style={{ overflow: 'scroll', width: isMobile ? '200px' : '' }}>
        {Object.values(FieldDefinitionDict).map(
          (FieldInstance: FieldInstance, idx: number, x: any) => {
            const FieldDefinition = FieldDefinitionList?.find(
              (el) => el.Id === FieldInstance.FieldDefinitionId
            );
            if (FieldDefinition) {
              if (isMobile || landingpage) {
                return (
                  <MobileView
                    FieldDefinition={FieldDefinition}
                    FieldInstance={FieldInstance}
                    ObjectDefinition={ObjectDefinition}
                    idx={idx}
                  />
                );
              }

              return (
                <Grid item className={classes.paper} xs={6} key={idx}>
                  <Typography
                    color="textPrimary"
                    style={{ marginRight: 16 }}
                    variant="h6">
                    {FieldDefinition.Title}:
                  </Typography>

                  <InlineTextField
                    key={idx}
                    FieldDefinition={FieldDefinition}
                    FieldInstance={FieldInstance}
                    ObjectDefinition={ObjectDefinition}
                    readOnly
                    type={FieldDefinition.FieldType}
                    value={FieldInstance.FieldValue}
                    fields={[]}
                    ProcessStepSelectionType={''}
                    ObjectInstance={undefined}
                    UserInstance={undefined}
                    ProcessInstance={undefined}
                    isOverview
                  />
                </Grid>
              );
            }

            return null;
          }
        )}
      </Grid>
    </XpansionIsolated>
  );
};

const MobileView = ({
  FieldDefinition,
  FieldInstance,
  ObjectDefinition,
  idx
}) => (
  <Grid
    container
    direction="column"
    justifyContent="flex-start"
    alignItems="flex-start"
    key={idx}>
    <Grid item xs={12}>
      <Typography color="textPrimary" style={{ marginRight: 16 }} variant="h6">
        {FieldDefinition.Title}:
      </Typography>
    </Grid>
    <div
      style={{
        padding: 1,
        width: '100%'
      }}>
      <InlineTextField
        key={idx}
        FieldDefinition={FieldDefinition}
        FieldInstance={FieldInstance}
        ObjectDefinition={ObjectDefinition}
        readOnly
        type={FieldDefinition.FieldType}
        value={FieldInstance.FieldValue}
        fields={[]}
        ProcessStepSelectionType={''}
        ObjectInstance={undefined}
        UserInstance={undefined}
        ProcessInstance={undefined}
        isOverview
      />
    </div>

    <div style={{ padding: '10px' }} />
  </Grid>
);

//return (
//  <Grid item className={classes.paper} xs={6} key={idx}>
//    <Typography color="textPrimary" style={{ marginRight: 16 }} variant="h6">
//      {FieldDefinition.Title}:
//    </Typography>
//
//    <InlineTextField
//      key={idx}
//      FieldDefinition={FieldDefinition}
//      FieldInstance={FieldInstance}
//      ObjectDefinition={ObjectDefinition}
//      readOnly
//      type={FieldDefinition.FieldType}
//      value={FieldInstance.FieldValue}
//      fields={[]}
//      ProcessStepSelectionType={''}
//      ObjectInstance={undefined}
//      UserInstance={undefined}
//      ProcessInstance={undefined}
//      isOverview
//    />
//  </Grid>
//);
