import { ERecurringEvent, IEvent, IEventFormValues } from '../interface';

const convertEventToFormValues = (event: IEvent): IEventFormValues => {
  const validRecurrenceTypes: ERecurringEvent[] = [
    ERecurringEvent.DoesNotRepeat,
    ERecurringEvent.Daily,
    ERecurringEvent.Weekly,
    ERecurringEvent.Monthly,
    ERecurringEvent.Quarterly,
    ERecurringEvent.Annually
  ];

  const eventRecurring = event.extendedProps.recurring;
  const recurring = validRecurrenceTypes.includes(
    eventRecurring as ERecurringEvent
  )
    ? eventRecurring
    : ERecurringEvent.DoesNotRepeat;

  let endDateRRule: Date | undefined;
  let interval: number | undefined;

  if (
    recurring !== ERecurringEvent.DoesNotRepeat &&
    event.extendedProps.rrule
  ) {
    const rruleValues = extractRRULEValues(event.extendedProps.rrule);
    endDateRRule = rruleValues.endDateRRule;
    interval = rruleValues.interval;
  }

  return {
    title: event.title,
    startDate: event.start,
    endDate: endDateRRule ? endDateRRule : event.end ? event.end : new Date(),
    location: event.extendedProps.location || '',
    attachments: event.extendedProps.attachments || [],
    guests: event.extendedProps.guests || [],
    invitedUserInstanceIds: event.extendedProps.invitedUserInstanceIds || [],
    eventType: event.extendedProps.eventType || '',
    recurring,
    notification: event.extendedProps.notification || false,
    description: event.extendedProps.description || '',
    address: event.extendedProps.address || {},
    teams: {
      title: '',
      start: new Date(),
      url: ''
    },
    sequence: event.extendedProps.sequence,
    endDateRRule,
    interval
  };
};

export default convertEventToFormValues;

interface IRRULEValues {
  endDateRRule?: Date;
  interval?: number;
}

const convertToDate = (dateStr: string): Date | undefined => {
  if (dateStr.length !== 16 || dateStr[8] !== 'T' || dateStr[15] !== 'Z') {
    return undefined;
  }

  const year = parseInt(dateStr.substring(0, 4), 10);
  const month = parseInt(dateStr.substring(4, 6), 10) - 1;
  const day = parseInt(dateStr.substring(6, 8), 10);
  const hours = parseInt(dateStr.substring(9, 11), 10);
  const minutes = parseInt(dateStr.substring(11, 13), 10);
  const seconds = parseInt(dateStr.substring(13, 15), 10);

  return new Date(Date.UTC(year, month, day, hours, minutes, seconds));
};

const extractRRULEValues = (rrule: string): IRRULEValues => {
  const untilMatch = rrule.match(/UNTIL=([\w\d:TZ]+)/);
  const intervalMatch = rrule.match(/INTERVAL=(\d+)/);

  return {
    endDateRRule: untilMatch ? convertToDate(untilMatch[1]) : undefined,
    interval: intervalMatch ? parseInt(intervalMatch[1], 10) : undefined
  };
};
