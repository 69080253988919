import React from 'react';
import { FilePond } from 'react-filepond';

/**
 * Handles the dragenter event.
 * Prevents default behavior, stops propagation, and sets the dragging state to true.
 *
 * @param {React.DragEvent} event - The drag event object.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsDragging - Function to update the dragging state.
 */
const handleDragEnter = (
  event: React.DragEvent,
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
) => {
  event.preventDefault();
  event.stopPropagation();
  setIsDragging(true);
};

/**
 * Handles the dragleave event.
 * Prevents default behavior, stops propagation, and sets the dragging state to false
 * when the cursor leaves the component's boundaries.
 *
 * @param {React.DragEvent} event - The drag event object.
 * @param {React.RefObject<HTMLDivElement>} rootRef - Reference to the root element of the component.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsDragging - Function to update the dragging state.
 */
const handleDragLeave = (
  event: React.DragEvent,
  rootRef: React.RefObject<HTMLDivElement>,
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>
) => {
  event.preventDefault();
  event.stopPropagation();
  const rect = rootRef.current?.getBoundingClientRect();
  if (rect) {
    const { left, top, right, bottom } = rect;
    if (
      event.clientX <= left ||
      event.clientX >= right ||
      event.clientY <= top ||
      event.clientY >= bottom
    ) {
      setIsDragging(false);
    }
  }
};

/**
 * Handles the dragover event.
 * Prevents default behavior and stops propagation to allow for a drop event.
 *
 * @param {React.DragEvent} event - The drag event object.
 */
const handleDragOver = (event: React.DragEvent) => {
  event.preventDefault();
  event.stopPropagation();
};

/**
 * Handles the drop event.
 * Prevents default behavior, stops propagation, sets the dragging state to false,
 * and adds the dropped files to FilePond.
 *
 * @param {React.DragEvent} event - The drop event object.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setIsDragging - Function to update the dragging state.
 * @param {React.RefObject<FilePond>} filePondRef - Reference to the FilePond component.
 */
const handleDrop = (
  event: React.DragEvent,
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>,
  filePondRef: React.RefObject<FilePond>
) => {
  event.preventDefault();
  event.stopPropagation();
  setIsDragging(false);

  if (event.dataTransfer?.files) {
    const droppedFiles = Array.from(event.dataTransfer.files);
    droppedFiles.forEach((file) => {
      if (filePondRef.current) {
        filePondRef.current.addFile(file);
      }
    });
  }
};

export { handleDragEnter, handleDragLeave, handleDragOver, handleDrop };
