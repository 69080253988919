import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import React from 'react';
import { useTypedSelector } from 'redux/reducers';
import { theme } from 'theme';
import { handleUpdateField } from '../../functions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fieldInput: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      minHeight: '35px',
      width: '100%',
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius
    }
  })
);

const EditableStarRating = (props) => {
  const classes = useStyles();
  const [updating, setUpdating] = React.useState(false);
  const { token } = useTypedSelector((s) => s.user.auth);
  const onChange: any = handleUpdateField({
    setUpdating,
    token,
    props
  });

  const handleOnChange = async (event) => {
    await onChange(event.target.value);
  };

  return (
    <div className={classes.fieldInput}>
      <Rating value={props.value} onChange={handleOnChange} />
    </div>
  );
};

export default EditableStarRating;
