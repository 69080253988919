import {
  CompleteObjectDefinition,
  CompleteProcessInstance,
  CompleteProcessStepDefinition,
  FieldDefinition,
  FieldInstance,
  CompleteObjectInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

export interface IgetFieldValueWithFieldDefinitionId {
  stepdefdict: CompleteProcessStepDefinition;
  currentDeal: CompleteProcessInstance;
  FieldDefinitionId: number;
}

export const getFieldValueWithFieldDefinitionId: (
  props: IgetFieldValueWithFieldDefinitionId
) => string = ({ stepdefdict, currentDeal, FieldDefinitionId }) => {
  let result = '';

  stepdefdict?.CompleteObjectDefinitionDict &&
    Object.values(stepdefdict.CompleteObjectDefinitionDict).forEach(
      (CompleteObjectDefinition: CompleteObjectDefinition) => {
        return Object.values(
          CompleteObjectDefinition.FieldDefinitionDict
        ).forEach((FieldDefinition: FieldDefinition) => {
          if (FieldDefinition.Id === FieldDefinitionId) {
            return Object.values(
              currentDeal.CompleteObjectInstanceDict
            ).forEach((CompleteObjectInstance: CompleteObjectInstance) => {
              const { ObjectDefinitionId } =
                CompleteObjectInstance.ObjectInstance;
              if (ObjectDefinitionId === FieldDefinition.ObjectDefinitionId) {
                const FieldInstanceList = getFieldInstances(
                  CompleteObjectInstance
                );

                return FieldInstanceList.find(
                  (FieldInstance: FieldInstance) => {
                    if (FieldInstance.FieldDefinitionId === FieldDefinitionId) {
                      const { FieldValue } = FieldInstance;
                      return (result = FieldValue);
                    }
                  }
                );
              }
            });
          }
        });
      }
    );

  return result;
};

// For repeating objects
interface IgetFieldValueWithObjectInstanceIdAndFieldDefinitionId {
  stepdefdict: CompleteProcessStepDefinition;
  currentDeal: CompleteProcessInstance;
  FieldDefinitionId: number;
  ObjectInstanceId: number;
}

export const getFieldValueWithObjectInstanceIdAndFieldDefinitionId: (
  props: IgetFieldValueWithObjectInstanceIdAndFieldDefinitionId
) => string = ({
  stepdefdict,
  currentDeal,
  FieldDefinitionId,
  ObjectInstanceId
}) => {
  let result = '';

  stepdefdict?.CompleteObjectDefinitionDict &&
    Object.values(stepdefdict.CompleteObjectDefinitionDict).forEach(
      (CompleteObjectDefinition: CompleteObjectDefinition) => {
        return Object.values(
          CompleteObjectDefinition.FieldDefinitionDict
        ).forEach((FieldDefinition: FieldDefinition) => {
          if (FieldDefinition.Id === FieldDefinitionId) {
            return Object.values(
              currentDeal.CompleteObjectInstanceDict
            ).forEach((CompleteObjectInstance: CompleteObjectInstance) => {
              if (
                CompleteObjectInstance.ObjectInstance.Id === ObjectInstanceId
              ) {
                const { ObjectDefinitionId } =
                  CompleteObjectInstance.ObjectInstance;
                if (ObjectDefinitionId === FieldDefinition.ObjectDefinitionId) {
                  const FieldInstanceList = getFieldInstances(
                    CompleteObjectInstance
                  );

                  return FieldInstanceList.find(
                    (FieldInstance: FieldInstance) => {
                      if (
                        FieldInstance.FieldDefinitionId === FieldDefinitionId
                      ) {
                        const { FieldValue } = FieldInstance;
                        return (result = FieldValue);
                      }
                    }
                  );
                }
              }
            });
          }
        });
      }
    );

  return result;
};
