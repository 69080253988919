import NorthridgeProposal from '.';
import { ELender, ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';
import { countryOptions } from './helper/countryOptions';

export const getNorthridgeIds = () => {
  return createTypeSafeIds(NorthridgeProposal, (_) => ({
    username: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'BrokerName'
    },
    otheR1: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'BrokerNickname'
    },
    otheR2: {
      FieldDefinitionId: 0,
      isReadonly: true,
      forcedValue: 'Asset Finance Solutions UK Ltd'
    },
    adS_REQUEST: {
      loaN_PROPOSAL: {
        control: {
          dealerref: {
            FieldDefinitionId: 0,
            isReadonly: true,
            required: true,
            forcedValue: 'ProcessInstanceId'
          },
          cusT_ID1: {
            FieldDefinitionId: 18317,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.EntityChange,
              newIdList: [
                {
                  entityType: 'Sole Trader',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Partnership',
                  FieldDefinitionId: 21866
                },
                {
                  entityType: 'Private Individual',
                  FieldDefinitionId: 21810
                }
              ]
            }
          },
          action: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          replY_EMAIL: {
            FieldDefinitionId: 0,
            isReadonly: true,
            required: true,
            forcedValue: 'BrokerEmail',
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              }
            }
          }
        },
        finance: {
          financetype: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Hire Purchase',
                  value: 'HP'
                },
                {
                  label: 'Personal Loan',
                  value: 'PL'
                },
                {
                  label: 'Leasing',
                  value: 'LS'
                }
              ]
            }
          },
          templatelist: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          invoiceprice: {
            FieldDefinitionId: 23836,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Currency
            }
          },
          settlementamount: {
            FieldDefinitionId: 23827,
            isReadonly: false
          },
          term: {
            FieldDefinitionId: 18332,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
              requirement: {
                maxAmount: 84,
                minAmount: 12
              }
            }
          },
          instalment: {
            FieldDefinitionId: 0,
            isReadonly: false
          },
          flatrate: {
            FieldDefinitionId: 23956,
            isReadonly: false
          },
          deposit: {
            FieldDefinitionId: 23956,
            isReadonly: false
          },
          ppp: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Dropdown,
              options: ['1', '2', '3', '4']
            }
          },
          parT_EXCHANGE: {
            FieldDefinitionId: 23830,
            isReadonly: false
          },
          residual: {
            FieldDefinitionId: 23949,
            isReadonly: false
          },
          residuaL_DATE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          optioN_FEE: {
            FieldDefinitionId: 22740,
            isReadonly: false
          },
          iniT_RENT_VAT: {
            FieldDefinitionId: 18348,
            isReadonly: false
          },
          advancE_PAYMTS: {
            FieldDefinitionId: 18348,
            isReadonly: false
          },
          esT_ANNUAL_MILEAGE: {
            FieldDefinitionId: 18537,
            isReadonly: false
          },
          paY_FREQ: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Annually',
                  value: 'A'
                },
                {
                  label: 'Half-Yearly',
                  value: 'H'
                },
                {
                  label: 'Quarterly',
                  value: 'Q'
                },
                {
                  label: 'Monthly',
                  value: 'M'
                }
              ]
            }
          },
          breakmtH1: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'January',
                  value: '01'
                },
                {
                  label: 'February',
                  value: '02'
                },
                {
                  label: 'March',
                  value: '03'
                },
                {
                  label: 'April',
                  value: '04'
                },
                {
                  label: 'May',
                  value: '05'
                },
                {
                  label: 'June',
                  value: '06'
                },
                {
                  label: 'July',
                  value: '07'
                },
                {
                  label: 'August',
                  value: '08'
                },
                {
                  label: 'September',
                  value: '09'
                },
                {
                  label: 'October',
                  value: '10'
                },
                {
                  label: 'November',
                  value: '11'
                },
                {
                  label: 'December',
                  value: '12'
                }
              ]
            }
          },
          breakmtH2: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'January',
                  value: '01'
                },
                {
                  label: 'February',
                  value: '02'
                },
                {
                  label: 'March',
                  value: '03'
                },
                {
                  label: 'April',
                  value: '04'
                },
                {
                  label: 'May',
                  value: '05'
                },
                {
                  label: 'June',
                  value: '06'
                },
                {
                  label: 'July',
                  value: '07'
                },
                {
                  label: 'August',
                  value: '08'
                },
                {
                  label: 'September',
                  value: '09'
                },
                {
                  label: 'October',
                  value: '10'
                },
                {
                  label: 'November',
                  value: '11'
                },
                {
                  label: 'December',
                  value: '12'
                }
              ]
            }
          }
        },
        loaN_PURPOSE: {
          vehicle: {
            merchcode: {
              FieldDefinitionId: 21290,
              isReadonly: false,
              required: true
            },
            make: {
              FieldDefinitionId: 18309,
              isReadonly: false,
              required: true
            },
            model: {
              FieldDefinitionId: 18310,
              isReadonly: false,
              required: true
            },
            reG_DATE: {
              FieldDefinitionId: 20697,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            reG_NO: {
              FieldDefinitionId: 21938,
              isReadonly: false
            },
            chassisno: {
              FieldDefinitionId: 18454,
              isReadonly: false,
              required: true
            },
            enginE_SIZE: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            mileage: {
              FieldDefinitionId: 18454,
              isReadonly: false
            },
            neW_USED: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'New',
                    value: 'N'
                  },
                  {
                    label: 'Used',
                    value: 'U'
                  }
                ]
              }
            },
            manuaL_AUTO: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Manual',
                    value: 'M'
                  },
                  {
                    label: 'Automatic',
                    value: 'A'
                  }
                ]
              }
            },
            petroL_DIESEL: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Petrol',
                    value: 'P'
                  },
                  {
                    label: 'Diesel',
                    value: 'D'
                  },
                  {
                    label: 'Electric',
                    value: 'E'
                  },
                  {
                    label: 'Petrol/Bio Ethanol (E85)',
                    value: 'F'
                  },
                  {
                    label: 'Petrol/CNG',
                    value: 'G'
                  },
                  {
                    label: 'Hybrid',
                    value: 'H'
                  },
                  {
                    label: 'Petrol/Plugin Electric',
                    value: 'X'
                  },
                  {
                    label: 'Diesel/Electric',
                    value: 'Y'
                  },
                  {
                    label: 'Diesel/Plugin Electric',
                    value: 'Z'
                  },
                  {
                    label: 'Bi Fuel:Petrol/LPG',
                    value: 'B'
                  }
                ]
              }
            },
            commercial: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            vaT_QUALIFYING: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            }
          },
          nonvehicle: {
            merchcode: {
              FieldDefinitionId: 18309,
              isReadonly: false,
              required: true
            },
            descriptioN1: {
              FieldDefinitionId: 18310,
              isReadonly: false,
              required: true
            },
            serialno: {
              FieldDefinitionId: 18454,
              isReadonly: false
            },
            age: {
              FieldDefinitionId: 18312,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          }
        },
        contracT_HIRE_DETAILS: {
          contracT_HIRE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          contracT_HIRE_TEMPLATE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          roaD_FUND_LICENCE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          maintenance: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          relieF_VEHICLE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          roaD_SIDE_ASSISTANCE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          }
        },
        jointhireR_DETAILS: {
          address: {
            facetoface: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            title: {
              FieldDefinitionId: 18428,
              isReadonly: false,
              required: true
            },
            forename: {
              FieldDefinitionId: 18420,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23977
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23977
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            surname: {
              FieldDefinitionId: 18427,
              isReadonly: false,
              required: true
            },
            houseno: {
              FieldDefinitionId: 18414,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23984
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23984
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            postcode: {
              FieldDefinitionId: 18425,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 18414,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 18429,
              isReadonly: false,
              required: true
            },
            county: {
              FieldDefinitionId: 18417,
              isReadonly: false
            },
            country: {
              FieldDefinitionId: 18437,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            mnths: {
              FieldDefinitionId: 23700,
              isReadonly: false
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            telephone: {
              FieldDefinitionId: 23704,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23979
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23979
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            mobile: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Phone, ELenderAPIType.EntityChange],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23970
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23624
                  }
                ]
              }
            },
            email: {
              FieldDefinitionId: 18452,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                },
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23499
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 21873
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            }
          },
          prevaddr: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23973
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23965
                  }
                ]
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR2: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23973
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23965
                  }
                ]
              }
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR3: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR4: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          personal: {
            dateofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            gender: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Male',
                    value: 'M'
                  },
                  {
                    label: 'Female',
                    value: 'F'
                  }
                ]
              }
            },
            marital: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            residential: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Homeowner',
                    value: 'O'
                  },
                  {
                    label: 'Tenant',
                    value: 'T'
                  },
                  {
                    label: 'With Parents',
                    value: 'P'
                  },

                  {
                    label: 'Work provided/other',
                    value: 'W'
                  }
                ]
              }
            },
            countryofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            nationality: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          },
          occupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              forcedValue: 'Director',
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0,
                    forcedValue: 'Proprietor'
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0,
                    forcedValue: 'Partner'
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21786
                  }
                ]
              }
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21783
                  }
                ]
              }
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23681
                  }
                ]
              }
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 23681
                  }
                ]
              }
            },
            employertelephone: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.EntityChange, ELenderAPIType.Phone],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21784
                  }
                ]
              }
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.EntityChange, ELenderAPIType.Number],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 21788
                  }
                ]
              }
            }
          },
          prevoccupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          bank: {
            sortcode: {
              FieldDefinitionId: 21804,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement, ELenderAPIType.OnBlur],
                requirement: {
                  pattern: '^[0-9][0-9]-?[0-9][0-9]-?[0-9][0-9]$'
                }
              }
            },
            accountname: {
              FieldDefinitionId: 23448,
              isReadonly: false,
              required: true
            },
            accountno: {
              FieldDefinitionId: 21803,
              isReadonly: false,
              required: true
            },
            bankname: {
              FieldDefinitionId: 23447,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            chequE_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            visA_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            mastercard: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            amex: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            other: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            }
          },
          income: {
            salarymandated: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            salarydate: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        },
        guarantoR_DETAILS: {
          address: {
            facetoface: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            title: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            forename: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            surname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            country: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            telephone: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            mobile: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Phone
              }
            },
            email: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          },
          prevaddr: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR2: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR3: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevaddR4: {
            postcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            posttown: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          personal: {
            dateofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            gender: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Male',
                    value: 'M'
                  },
                  {
                    label: 'Female',
                    value: 'F'
                  }
                ]
              }
            },
            marital: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            residential: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Homeowner',
                    value: 'O'
                  },
                  {
                    label: 'Tenant',
                    value: 'T'
                  },
                  {
                    label: 'With Parents',
                    value: 'P'
                  },

                  {
                    label: 'Work provided/other',
                    value: 'W'
                  }
                ]
              }
            },
            countryofbirth: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: countryOptions
              }
            },
            nationality: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            }
          },
          occupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          prevoccupation: {
            occupation: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            occupationcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            status: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Permanent Full time',
                    value: 'PF'
                  },
                  {
                    label: 'Permanent Part time',
                    value: 'PP'
                  },
                  {
                    label: 'Temporary Full time',
                    value: 'TF'
                  },
                  {
                    label: 'Temporary Part time',
                    value: 'TP'
                  },
                  {
                    label: 'Self Employed',
                    value: 'SE'
                  },
                  {
                    label: 'Contractor/Agency',
                    value: 'CO'
                  },
                  {
                    label: 'Homemaker',
                    value: 'HO'
                  },
                  {
                    label: 'Full time student',
                    value: 'FS'
                  },
                  {
                    label: 'Part time student',
                    value: 'PS'
                  },
                  {
                    label: 'Retired',
                    value: 'RE'
                  },
                  {
                    label: 'Unemployed',
                    value: 'UE'
                  }
                ]
              }
            },
            employer: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS1: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            addresS2: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            yrs: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            }
          },
          bank: {
            sortcode: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [ELenderAPIType.Requirement, ELenderAPIType.OnBlur],
                requirement: {
                  pattern: '^[0-9][0-9]-?[0-9][0-9]-?[0-9][0-9]$'
                }
              }
            },
            accountname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            accountno: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            bankname: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            street: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            chequE_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            visA_CARD: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            mastercard: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            amex: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            other: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            }
          },
          income: {
            salarymandated: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Yes',
                    value: 'Y'
                  },
                  {
                    label: 'No',
                    value: 'N'
                  }
                ]
              }
            },
            salarydate: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          }
        },
        business: {
          businesS_TYPE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Sole Trader',
                  value: 'ST'
                },
                {
                  label: 'Limited Company',
                  value: 'CO'
                },
                {
                  label: 'Partnership',
                  value: 'PS'
                }
              ]
            }
          },
          businesS_DETAILS: {
            tradinG_NAME: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true
            },
            businesS_DESCRIPTION: {
              FieldDefinitionId: 21881,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 21867
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            yearS_TRADING: {
              FieldDefinitionId: 18450,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.EntityChange, ELenderAPIType.Number],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23533
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            vaT_NO: {
              FieldDefinitionId: 23547,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 21875
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            companY_REGNO: {
              FieldDefinitionId: 18315,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            contacT_NAME: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.EntityChange,
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23976
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 0
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ]
              }
            },
            tradinG_ADDRESS: {
              houseno: {
                FieldDefinitionId: 18431,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.EntityChange,
                  newIdList: [
                    {
                      entityType: 'Sole Trader',
                      FieldDefinitionId: 23662
                    },
                    {
                      entityType: 'Partnership',
                      FieldDefinitionId: 0
                    },
                    {
                      entityType: 'Private Individual',
                      FieldDefinitionId: 0
                    }
                  ]
                }
              },
              postcode: {
                FieldDefinitionId: 18438,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 18432,
                isReadonly: false,
                required: true
              },
              posttown: {
                FieldDefinitionId: 18435,
                isReadonly: false
              },
              county: {
                FieldDefinitionId: 18436,
                isReadonly: false
              },
              country: {
                FieldDefinitionId: 18437,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: countryOptions
                }
              },
              yrs: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              telephone: {
                FieldDefinitionId: 18525,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              }
            },
            tradinG_PREVADDR: {
              postcode: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              country: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: countryOptions
                }
              },
              yrs: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              telephone: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.Phone
                }
              }
            },
            businesS_BANK: {
              sortcode: {
                FieldDefinitionId: 21804,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: [ELenderAPIType.Requirement, ELenderAPIType.OnBlur],
                  requirement: {
                    pattern: '^[0-9][0-9]-?[0-9][0-9]-?[0-9][0-9]$'
                  }
                }
              },
              accountname: {
                FieldDefinitionId: 23448,
                isReadonly: false,
                required: true
              },
              accountno: {
                FieldDefinitionId: 21803,
                isReadonly: false,
                required: true
              },
              bankname: {
                FieldDefinitionId: 23447,
                isReadonly: false,
                required: true
              },
              street: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true
              },
              yrs: {
                FieldDefinitionId: 23969,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              mths: {
                FieldDefinitionId: 0,
                isReadonly: false,
                FieldDefinition: {
                  type: ELenderAPIType.Number
                }
              },
              chequE_CARD: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              visA_CARD: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              mastercard: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              amex: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              },
              other: {
                FieldDefinitionId: 0,
                isReadonly: false,
                required: true,
                FieldDefinition: {
                  type: ELenderAPIType.OptionsList,
                  options: [
                    {
                      label: 'Yes',
                      value: 'Y'
                    },
                    {
                      label: 'No',
                      value: 'N'
                    }
                  ]
                }
              }
            }
          }
        },
        supplieR_DETAILS: {
          supplier: {
            FieldDefinitionId: 18412,
            isReadonly: false,
            required: true
          },
          addresS1: {
            FieldDefinitionId: 20699,
            isReadonly: false
          },
          posttown: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true
          },
          telephone: {
            FieldDefinitionId: 20702,
            isReadonly: false
          },
          status: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Franchised',
                  value: 'F'
                },
                {
                  label: 'Fide',
                  value: 'B'
                },
                {
                  label: 'Unknown to broker',
                  value: 'U'
                }
              ]
            }
          },
          detailstofollow: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          }
        },
        agrI_DETAILS: {
          faD_HAS_HORSES: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_PIGS: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_POULTRY: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_HORTICULTURAL: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_FRUIT: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_HAULAGE: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_BEEF: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_SHEEP: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_DAIRY: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_HAS_VEGETABLES: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_IS_CONTRACTOR: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          },
          faD_IS_DEALER: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Yes',
                  value: 'Y'
                },
                {
                  label: 'No',
                  value: 'N'
                }
              ]
            }
          }
        }
      }
    }
  }));
};
