import { AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { GetDealColourList } from 'redux/database/Process Definition API';
import { useTypedSelector } from 'redux/reducers';
import {
  CompleteObjectInstance,
  CompleteProcessInstance,
  FieldInstance,
  ProcessInstance,
  ProcessSummary
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import * as gtag from 'Utils/gtag';

interface IDealColour {
  BgColor: string;
  Description: string;
  Id: string;
  IsPublished: boolean;
  ItemOrder: number;
  LastModified: string;
  Title: string;
}

interface IAnalyticsEvent {
  action: string;
  feature: string;
  message: string;
  value?: number;
}

type TEventData = {
  objectId: number;
  fieldId: number;
};

// For AFS Alternative-Finance uses Finance Facility ObjectDefinitionId: 3497 Funded Amount FieldDefinitionId: 23794
// For AFS Contract Hire Referral uses Referral Fee ObjectDefinitionId: 3719 Referral Fee FieldDefinitionId: 23951
// For AFS "Others" uses Quote ObjectDefinitionId: 2840 NetAdvance FieldDefinitionId: 18321

// For Synergy "Alternative Finance" uses Finance Facility ObjectDefinitionId: 3497 Funded Amount FieldDefinitionId: 23794
// For Synergy "Property Finance" uses Indicative Quote ObjectDefinitionId: 3565 Loan Amount FieldDefinitionId: 22370
// For Synergy "Residential Mortgage Referral" uses Referral Fee ObjectDefinitionId: 3721 Fee Amount FieldDefinitionId: 23960
// For Synergy "Others" uses Quote ObjectDefinitionId: 2840 NetAdvance FieldDefinitionId: 18321
const Quote: TEventData = { objectId: 2840, fieldId: 18321 };
const FinanceFacility: TEventData = { objectId: 3497, fieldId: 23794 };
const ContractHireReferral: TEventData = { objectId: 3719, fieldId: 23951 };
const LenderCommission: TEventData = { objectId: 3486, fieldId: 21900 };
const ResidentialMortgageReferral: TEventData = {
  objectId: 3721,
  fieldId: 23960
};

const useDealAnalytics = () => {
  const { currentDeal, currentOverView } = useTypedSelector((s) => s.process);

  const sendGTag = () => {
    let AFS: number[] = [466, 474, 540, 542];
    let Synergy: number[] = [483, 494, 532, 534, 535];

    const stepId = currentOverView.ProcessDefinitionId;
    const dealId = currentOverView.ProcessInstanceId;
    if (AFS.includes(stepId)) {
      handleEventData(dealId.toString(), 'AFS', currentDeal);
    } else if (Synergy.includes(stepId)) {
      handleEventData(dealId.toString(), 'Synergy', currentDeal);
    }
  };

  return { sendGTag };
};

export default useDealAnalytics;

/**
 * Retrieves a field instance based on the specified object and FieldDefinitionIds within a deal.
 * If multiple instances are found, concatenates their values. If no matching instance is found, returns an object with an empty string value.
 *
 * @param currentDeal - The current deal's CompleteProcessInstance.
 * @param ObjectDefinitionId - The objectDefinitionId to search for.
 * @param FieldDefinitionId - The FieldDefinitionId to retrieve within the matching object definition.
 * @returns Either a single FieldInstance or an object with a concatenated `FieldValue` of all matching instances.
 */

const getFieldInstance = (
  currentDeal: CompleteProcessInstance,
  ObjectDefinitionId: number,
  FieldDefinitionId: number
): FieldInstance | { FieldValue: string } => {
  const CompleteObjectInstance: CompleteObjectInstance[] = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).filter((CompleteObjectInstance: CompleteObjectInstance) => {
    return (
      CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
      ObjectDefinitionId
    );
  });

  if (CompleteObjectInstance.length > 1) {
    const FieldInstances: FieldInstance[] = CompleteObjectInstance.flatMap(
      (CompleteObjectInstance: CompleteObjectInstance) => {
        const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

        return FieldInstanceList.filter(
          (FieldInstance: FieldInstance) =>
            FieldInstance.FieldDefinitionId === FieldDefinitionId
        );
      }
    );

    if (FieldInstances.length > 1) {
      let addingForAllValues = '';
      FieldInstances.forEach((FieldInstance: FieldInstance) => {
        addingForAllValues += FieldInstance.FieldValue;
      });

      return { FieldValue: addingForAllValues };
    }
  }

  if (CompleteObjectInstance.length === 1) {
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance[0]);
    const FieldInstance: FieldInstance | undefined = FieldInstanceList.find(
      (FieldInstance: FieldInstance) => {
        return FieldInstance.FieldDefinitionId === FieldDefinitionId;
      }
    );

    return FieldInstance ? FieldInstance : { FieldValue: '' };
  } else return { FieldValue: '' };
};

/**
 * Determines if there is a selected ObjectInstance among the complete ObjectInstances in a deal
 * matching a specific ObjectDefinitionId.
 *
 * @param currentDeal - The current deal's CompleteProcessInstance.
 * @param ObjectDefinitionId - The ID of the ObjectDefinition to find among the deal's complete ObjectInstances.
 * @returns `true` if there is a selected ObjectInstance that matches the provided ObjectDefinitionId, otherwise `false`.
 */

const getCompleteObjectInstance = (
  currentDeal: CompleteProcessInstance,
  ObjectDefinitionId: number
): boolean => {
  const CompleteObjectInstance: CompleteObjectInstance[] = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).filter((CompleteObjectInstance: CompleteObjectInstance) => {
    return (
      CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
      ObjectDefinitionId
    );
  });

  if (CompleteObjectInstance.length < 0) return false;
  const findSelectedQuote = CompleteObjectInstance.find(
    (CompleteObjectInstance: CompleteObjectInstance) => {
      return CompleteObjectInstance.ObjectInstance.Selected;
    }
  );

  if (findSelectedQuote) return true;
  return false;
};

/**
 * Sends an analytics event to Google Analytics.
 * @param category The category of the event, used to group similar events together (e.g., 'AFS', 'Synergy').
 * @param FieldInstance An object representing the FieldInstance, containing at least the FieldValue to be sent with the event.
 */

const sendEvent = (
  currentDeal: CompleteProcessInstance,
  FieldInstance: FieldInstance | { FieldValue: string }
): void => {
  if (FieldInstance.FieldValue !== '') {
    const event: IAnalyticsEvent = {
      action: `Deal Value`,
      feature: 'Deals',
      message: `${currentDeal.ProcessInstance.Id}`,
      value: parseInt(FieldInstance.FieldValue)
    };

    gtag.event({
      action: event.action,
      feature: event.feature,
      message: event.message
    });
  }
};

/**
 * Processes and sends analytics events based on the provided dealId and type within the context of the currentDeal.
 * It uses predefined event data mappings to identify which FieldInstances to gather from the currentDeal,
 * then constructs and sends appropriate analytics events for both finance-related and quote-related instances.
 *
 * @param id - The ID of the currentStep or action, used to determine the specific event data mapping.
 * @param dealType - A string indicating the type of deal (e.g., 'AFS', 'Synergy'), used in the analytics event name.
 * @param currentDeal - The CompleteProcessInstance for the currentDeal, containing necessary data for field instance retrieval.
 */

const handleEventData = (
  id: string,
  dealType: string,
  currentDeal: CompleteProcessInstance
): void => {
  let eventData: TEventData | null = null;
  switch (id) {
    case '540':
    case '483':
      eventData = FinanceFacility;
      break;
    case '542':
      eventData = ContractHireReferral;
      break;
    case '494':
      eventData = LenderCommission;
      break;
    case '532':
      eventData = ResidentialMortgageReferral;
      break;
  }

  if (eventData) {
    const FieldInstance = getFieldInstance(
      currentDeal,
      eventData.objectId,
      eventData.fieldId
    );
    sendEvent(currentDeal, FieldInstance);
  }

  const CompleteObjectInstance = getCompleteObjectInstance(
    currentDeal,
    Quote.objectId
  );

  if (CompleteObjectInstance) {
    const quoteInstance = getFieldInstance(
      currentDeal,
      Quote.objectId,
      Quote.fieldId
    );

    sendEvent(currentDeal, quoteInstance);
  }
};
