import { useState, Dispatch, SetStateAction } from 'react';
import {
  OffkeyCompanyLite,
  IQuery,
  ICompanySearchCriteria,
  IDirectorSearchCriteria
} from '../interfaces';
import { IRedflagDirectorArray } from '../helper';
import { cleanSearchCriteria, hasSearchCriteria } from '../functions';
import { IRedflagAPIData } from 'types/redflagInterfaces';

interface IProps {
  setParentCompany?: Dispatch<SetStateAction<IRedflagAPIData>>;
  companyNavigator: 'CreditSafe' | 'RedflagOG' | 'RedflagGRaphQL';
  setLocalLoading: Dispatch<SetStateAction<boolean>>;
  setCompanies: Dispatch<SetStateAction<OffkeyCompanyLite[] | null>>;
  setDirectors?: Dispatch<SetStateAction<IRedflagDirectorArray | null>>;
  getRFGQLCompanyList: ({
    query,
    token
  }: {
    query: ICompanySearchCriteria;
    token?: string;
  }) => Promise<OffkeyCompanyLite[]>;
  getRFGQLDirectorList?: (
    query: IDirectorSearchCriteria
  ) => Promise<IRedflagDirectorArray>;
}

export const useCompanyAndDirectorSearch = ({
  companyNavigator,
  setLocalLoading,
  setCompanies,
  setDirectors,
  getRFGQLCompanyList,
  getRFGQLDirectorList,
  setParentCompany
}: IProps) => {
  const [query, setQuery] = useState<IQuery>({
    company: {},
    director: {}
  });

  const handleCompanySearch = (name: string, value: string) => {
    const company_name: keyof ICompanySearchCriteria = 'company_name';
    const company_number: keyof ICompanySearchCriteria = 'company_number';

    const isNameOrNumber = name === company_name || name === company_number;
    // NOTE: some company numbers have letters e.g. E0473271 so they will be treated as company_name
    const isNumeric = isNameOrNumber ? /^\d+$/.test(value) : '';

    setQuery((prevQuery) => ({
      ...prevQuery,
      company: {
        ...prevQuery.company,
        [name]: { eq: value },
        ...(isNameOrNumber && {
          [company_number]: isNumeric ? { eq: value } : { eq: '' },
          [company_name]: !isNumeric ? { eq: value } : { eq: '' }
        })
      }
    }));
  };

  const companySearch = async () => {
    setLocalLoading(true);
    let companies: OffkeyCompanyLite[] = [];

    if (hasSearchCriteria(query.company)) {
      if (companyNavigator === 'RedflagGRaphQL') {
        const cleanedCompany = cleanSearchCriteria(query.company);
        companies = await getRFGQLCompanyList({ query: cleanedCompany });
      }

      setCompanies(companies);
      setLocalLoading(false);
    }
  };

  const companyClear = () => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      company: {}
    }));

    setCompanies(null);
    setParentCompany?.({} as IRedflagAPIData);
  };

  const handleDirectorSearch = (name: string, value: string) => {
    setQuery((prevQuery) => ({
      ...prevQuery,
      director: {
        ...prevQuery.director,
        [name]: { eq: value }
      }
    }));
  };

  const directorSearch = async () => {
    setLocalLoading(true);
    let directors = {} as IRedflagDirectorArray;
    if (hasSearchCriteria(query.director)) {
      if (companyNavigator === 'RedflagGRaphQL') {
        const cleanedDirector = cleanSearchCriteria(query.director);

        directors = (await getRFGQLDirectorList?.(
          cleanedDirector
        )) as IRedflagDirectorArray;
      }

      setDirectors?.(directors);
      setLocalLoading(false);
    }
  };

  return {
    handleCompanySearch,
    companySearch,
    companyClear,
    handleDirectorSearch,
    directorSearch,
    query,
    setQuery
  };
};
