import { useContext } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { deleteUserFromGroup } from '../../functions';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddNewUserToGroup } from '../../components';
import { useTypedSelector } from 'redux/reducers';
import { EmailEditor, Conversation } from '../../components';
import { TitleMain } from 'common/Typography';
import { MessageHubProvider } from '../..';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Thread } from '../../interfaces';
import { CustomDeletionDialog } from 'common/Dialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {},
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    titleWarping: {
      maxWidth: theme.spacing(100),
      wordWrap: 'break-word'
    }
  })
);

export const SelectedThreadView = ({ open, setOpen, handleDeleteGroup }) => {
  const classes = useStyles();
  const MessageProvider = useContext(MessageHubProvider);
  const { syncThread, threads, selectedThreadKey } = MessageProvider;

  let thread: Thread | undefined = undefined;
  if (selectedThreadKey !== null && threads !== null) {
    thread = threads[selectedThreadKey];
  }

  if (!thread) return <div />;
  return (
    <div>
      <CustomDeletionDialog
        open={open}
        setOpen={setOpen}
        confirmedDeleted={handleDeleteGroup}
        type="delete"
        title={thread.label}
      />
      <>
        <div className={classes.title}>
          <TitleMain className={classes.titleWarping} variant="h1">
            {thread.label}
          </TitleMain>
          <div>
            <IconButton onClick={syncThread}>
              <RefreshIcon />
            </IconButton>
            {thread.fixedTo ? (
              <></>
            ) : (
              <IconButton onClick={() => setOpen(true)}>
                <DeleteIcon />
              </IconButton>
            )}
          </div>
        </div>
        <br />
        <Grid container spacing={1}>
          {thread.members.map((id: string, idx: number) => {
            return (
              <Grid item key={idx}>
                <QuickUserFromId UserInstanceId={id} />
              </Grid>
            );
          })}

          {/* {!conversationId && (
            <Grid item>
              <AddNewUserToGroup />
            </Grid>
          )} */}
        </Grid>
        <br />
        <Conversation />
      </>
    </div>
  );
};
