import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon
};

const useStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: 'green'
  },
  warning: {
    backgroundColor: 'orange'
  },
  close: {
    padding: theme.spacing(0.5)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: { marginRight: theme.spacing(1) }
}));

export const SnackBar = ({ open, toggle, variant, message }) => {
  const classes = useStyles();
  const Icon = variantIcon[variant];

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    toggle();
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        ContentProps={{
          'aria-describedby': 'message-id'
        }}>
        <SnackbarContent
          className={classes[variant]}
          message={
            <span id="message-id" className={classes.message}>
              <Icon className={classes.icon} />
              <Message message={message} />
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};

const Message = ({ message }) => {
  const messageMap = {
    'Nominal FlatRate cannot be less':
      'Nominal FlatRate cannot be less than or equal to 0% or greater than or equal to 100%',
    'FlatRate cannot be less':
      'FlatRate cannot be less than or equal to 0% or greater than or equal to 100%',
    'Rate cannot be less':
      'Margin Rate or Flat Rate cannot be less than or equal to 0% or greater than or equal to 100%',
    'Yield cannot be less':
      'Yield cannot be less than or equal to 0% or greater than or equal to 100%',
    'APR cannot be less':
      'APR cannot be less than or equal to 0% or greater than or equal to 100%'
  };

  for (const key in messageMap) {
    if (message.includes(key)) {
      return messageMap[key];
    }
  }

  return message;
};
