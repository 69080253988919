import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Box,
  Grid,
  CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { UserInstance } from 'types/interfaces';
import { validateEmail } from 'helpers/validateEmail';
import Autocomplete from '@material-ui/lab/Autocomplete';

const EmailField = ({
  label,
  type,
  handleDeleteClick,
  onFocus,
  endAdornments,
  recipients,
  setRecipients,
  panelIndex
}) => {
  const [relationships, setRelationships] = useState<UserInstance[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const inputRef: any = useRef(null);
  const handleFocus = async () => {
    setLoading(true);
    let storedEmails = JSON.parse(
      localStorage.getItem('sentEmailAddresses') || '[]'
    );
    storedEmails = storedEmails.map((email: string) => ({
      UserInstanceEmail: email
    }));

    let validEmails = [];
    if (onFocus) {
      const result = await onFocus();

      if (result) {
        let currentEmailList: string[] = [];

        if (typeof panelIndex === 'number' && Array.isArray(recipients)) {
          currentEmailList = recipients[panelIndex]?.[type] || [];
        } else if (
          typeof panelIndex === 'string' &&
          typeof recipients === 'object'
        ) {
          currentEmailList = recipients[panelIndex] || [];
        }

        validEmails = result.filter(
          (item: UserInstance) =>
            validateEmail(item.UserInstanceEmail) &&
            !currentEmailList.includes(item.UserInstanceEmail)
        );
      }
    }

    const combinedEmails = [...storedEmails, ...validEmails];
    const deduplicatedEmails = Array.from(
      new Set(combinedEmails.map((e) => e.UserInstanceEmail))
    ).map((UserInstanceEmail) =>
      combinedEmails.find((e) => e.UserInstanceEmail === UserInstanceEmail)
    );

    setRelationships(deduplicatedEmails);
    setLoading(false);
  };

  const clearInput = () => {
    setInputValue('');
  };

  const addEmail = ({ email }: { email: string }) => {
    if (validateEmail(email)) {
      setRecipients((prevRecipients) => {
        if (typeof panelIndex === 'number') {
          const newAllEmailBodies = [...prevRecipients];
          const currentEmailBody = newAllEmailBodies[panelIndex];

          let currentEmailList = currentEmailBody[type] || [];
          if (!currentEmailList.includes(email)) {
            newAllEmailBodies[panelIndex] = {
              ...currentEmailBody,
              [type]: [...currentEmailList, email]
            };
          }
          return newAllEmailBodies;
        } else if (['to', 'cc', 'bcc'].includes(panelIndex)) {
          const currentEmailList = prevRecipients[panelIndex] || [];
          if (!currentEmailList.includes(email)) {
            return {
              ...prevRecipients,
              [panelIndex]: [...currentEmailList, email]
            };
          }
          clearInput();
          return prevRecipients;
        } else {
          clearInput();
          return prevRecipients;
        }
      });
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const email = event.target.value.trim();
    addEmail({ email });
    event.target.value = '';
  };

  const handleEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Enter') {
      const inputElement = event.target as HTMLInputElement;
      const email = inputElement.value.trim();

      addEmail({ email });
      inputElement.value = '';
    }
  };

  useEffect(() => {
    setInputValue('');
  }, [recipients]);

  return (
    <Autocomplete
      style={{ width: '100%' }}
      loading={loading}
      options={relationships}
      freeSolo
      getOptionLabel={(option: UserInstance) => option.UserInstanceEmail || ''}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      inputValue={inputValue}
      onChange={(event, value) => {
        event.stopPropagation();
        let email;
        if (typeof value === 'string') {
          email = value;
        } else if (value && value.UserInstanceEmail) {
          email = value.UserInstanceEmail;
        }

        if (email) {
          addEmail({ email });
          if (inputRef.current) {
            inputRef.current.blur();
          }
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          inputRef={inputRef}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onClick={(e) => e.stopPropagation()}
          type="email"
          onKeyPress={handleEnterKeyPress}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}

                <InputAdornment position="end">
                  {endAdornments}
                  {type !== 'to' && (
                    <IconButton edge="end" onClick={handleDeleteClick}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              </>
            )
          }}
        />
      )}
    />
  );
};

export default EmailField;
