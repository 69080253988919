import React, { useContext } from 'react';
import { useProcess } from 'hooks/useProcess';
import {
  Button,
  Grid,
  IconButton,
  Typography,
  withStyles,
  TextField,
  Paper
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';
import { renameGroup } from '../functions';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { MessageHubProvider } from '../';
import { Thread, StaticFixedGroups } from '../interfaces';
import { getFieldValueWithFieldDefinitionId } from 'components/Stepper/functions/getFieldValueWithFieldDefinitionId';
import StarIcon from '@material-ui/icons/Star';
import { globalIds } from 'helpers/globalIdConfig';
import { Alert } from '@material-ui/lab';
import { CompleteProcessDefinition } from 'types/interfaces';
import { ConnectAccountButton } from 'components/Settings/ConnectAccountButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    segmentHeader: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      margin: 1,
      padding: `2px ${theme.spacing(2)}px `,
      borderRadius: theme.shape.borderRadius
    },
    user: {
      background: `${theme.palette.primary.light}99`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      margin: 1,
      overflowWrap: 'break-word'
    },
    userTitle: {
      display: 'flex',
      alignItems: 'center'
    },
    selected: {
      background: `red`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      margin: 2,
      overflowWrap: 'break-word'
    },
    UserDefinitionTitle: {
      // background: theme.palette.grey[50],
      background: theme.palette.primary.light,
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      color: theme.palette.primary.contrastText
    },
    FixedThreadTitle: {
      // background: theme.palette.grey[50],
      borderRadius: theme.shape.borderRadius,
      margin: theme.spacing(1),
      padding: theme.spacing(1)
    }
  })
);

const LeftNavigationMenu = () => {
  const MessageProvider = useContext(MessageHubProvider);
  const { threads, selectThread } = MessageProvider;

  const classes = useStyles();
  const { ProcessInstanceId, currentProcess, user } = useProcess();
  const subSystemUser = user.SystemAccess <= 4;
  const isBroker = user.SystemAccess >= 5;

  const { Id } = useTypedSelector((s) => s.user.user);

  const [staticFixedGroup, setStaticFixedGroups] = React.useState<
    Partial<StaticFixedGroups>
  >({} as StaticFixedGroups);
  const handleMain = () => selectThread(null);
  const Ids = {
    Id: Id.toString(),
    ProcessInstanceId: ProcessInstanceId.toString()
  };
  const threadsArr = threads && (Object.keys(threads) as string[]);
  const PropertyFinanceDeal = 494;

  const staticFixedGroupsSetup = () => {
    let staticFixedGroupSetup: Partial<StaticFixedGroups>;
    const { Id } = currentProcess.ProcessDefinition;
    const AFS: StaticFixedGroups = {
      Borrower: { Contact: 'borrower.contact' },
      // Lender: {
      //   Lender: 'lender',
      //   Proposal: 'lender.proposal',
      //   Payouts: 'lender.payouts'
      // },
      Supplier: {
        Supplier: 'supplier',
        SupplierContact: 'supplier.contact'
      },
      Introducer: 'introducer',
      Accounts: 'accounts',
      Broker: { Contact: 'broker.contact' }
    };

    const Synergy: Partial<StaticFixedGroups> = {
      Borrower: { Contact: 'borrower.contact' },
      // Lender: {
      //   Lender: 'lender',
      //   Proposal: 'lender.proposal',
      //   Payouts: 'lender.payouts'
      // },
      Accounts: 'accounts',
      Broker: { Contact: 'broker.contact' }
    };

    if (subSystemUser) {
      staticFixedGroupSetup = { ...AFS };
      delete staticFixedGroupSetup.Accounts;
    } else if ([PropertyFinanceDeal].includes(Id)) {
      staticFixedGroupSetup = Synergy;
    } else {
      staticFixedGroupSetup = AFS;
    }

    return setStaticFixedGroups(staticFixedGroupSetup);
  };

  React.useEffect(() => {
    staticFixedGroupsSetup();
  }, []);

  return (
    <Grid container spacing={2} direction="column">
      <Grid item style={{ width: '100%' }}>
        <Paper>
          <ConnectAccountButton />
        </Paper>
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <Button fullWidth onClick={handleMain} variant="contained">
          HOME
        </Button>
      </Grid>

      <Grid item style={{ width: '100%' }}>
        {Object.keys(staticFixedGroup).map((key) => {
          const FixedGroup = staticFixedGroup[key];
          const nestedKeys = Object.keys(staticFixedGroup[key]);
          const isNestedKeys = typeof FixedGroup === 'object';
          if (subSystemUser && key === 'Introducer') return <div key={key} />;

          if (!isNestedKeys) {
            const type = staticFixedGroup[key];
            const existingThread: Thread | undefined | null =
              threads && Object.values(threads).find((el) => el.type === type);

            if (existingThread && threads && existingThread.id) {
              return (
                <div key={key}>
                  <div className={classes.UserDefinitionTitle}>{key}</div>
                  <div style={{ marginLeft: '10%' }}>
                    <ThreadTitle
                      threads={threads}
                      threadKey={existingThread.id}
                    />
                  </div>
                </div>
              );
            }

            return (
              <>
                <div className={classes.UserDefinitionTitle}>{key}</div>
                <Alert
                  severity="info"
                  variant="outlined"
                  style={{
                    marginLeft: 25,
                    paddingTop: 2,
                    paddingBottom: 2
                  }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    No Assigned {key} Yet
                  </Typography>
                </Alert>
              </>
            );
          }

          return (
            <div key={key}>
              <div className={classes.UserDefinitionTitle}>{key}</div>
              {isNestedKeys &&
                nestedKeys.map((nestedKey: string) => {
                  const type = staticFixedGroup[key][nestedKey];
                  const existingThread: Thread | undefined | null =
                    threads &&
                    Object.values(threads).find((el) => el.type === type);

                  const existingThreads: Thread[] | undefined | null =
                    threads &&
                    Object.values(threads).filter((el) => el.type === type);

                  if (type === 'supplier.contact') {
                    if (
                      existingThreads &&
                      threads &&
                      existingThreads.length > 0
                    ) {
                      return existingThreads.map((thread: Thread) => {
                        return (
                          <div key={thread.id} style={{ marginLeft: '10%' }}>
                            <ThreadTitle
                              threads={threads}
                              threadKey={thread.id}
                            />
                          </div>
                        );
                      });
                    }
                  }

                  if (existingThread && threads && existingThread.id) {
                    return (
                      <div style={{ marginLeft: '10%' }}>
                        <ThreadTitle
                          threads={threads}
                          threadKey={existingThread.id}
                        />
                      </div>
                    );
                  } else if (type !== 'supplier') {
                    const [mainType, subType] = type.split('.');
                    let rawLabel;

                    if (
                      mainType === 'supplier' ||
                      mainType === 'borrower' ||
                      mainType === 'broker'
                    ) {
                      rawLabel = mainType;
                    } else {
                      rawLabel = subType || mainType;
                    }

                    const label =
                      rawLabel.charAt(0).toUpperCase() + rawLabel.slice(1);

                    return (
                      <div style={{ padding: 1 }}>
                        <AssignedUsers
                          currentProcess={currentProcess}
                          label={label}
                          type={type}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          );
        })}
      </Grid>

      <Grid item>
        <Typography>
          {`If you don't see a user here you may need to add them in the deal first`}
        </Typography>
      </Grid>

      <Grid item style={{ width: '100%' }}>
        {threadsArr &&
          threads &&
          threadsArr
            .filter((threadKey) => !threads[threadKey].fixedTo)
            .filter((threadKey) => !threads[threadKey].type)
            .map((threadKey) => (
              <div key={threadKey}>
                <ThreadTitle threads={threads} threadKey={threadKey} />
              </div>
            ))}
      </Grid>

      {/* <Grid item style={{ width: '100%' }}>
        <Button
          fullWidth
          onClick={() => createGroup({ Ids })}
          variant="contained">
          + Conversation
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default LeftNavigationMenu;

const renderAlert = (label: string) => (
  <Alert
    severity="info"
    variant="outlined"
    style={{
      marginLeft: 25,
      paddingTop: 2,
      paddingBottom: 2
    }}>
    <Typography style={{ fontWeight: 'bold' }}>
      No Assigned {label} Yet
    </Typography>
  </Alert>
);

const AssignedUsers = ({
  currentProcess,
  label,
  type
}: {
  currentProcess: CompleteProcessDefinition;
  label: string;
  type: string;
}) => {
  const { Id } = currentProcess.ProcessDefinition;
  const PropertyFinanceDeal = 494;

  if (Id === PropertyFinanceDeal) {
    if (type === 'lender') return null;
    return renderAlert(label);
  } else {
    const lenderTypesToIgnore = ['lender.proposal', 'lender.payouts'];
    if (lenderTypesToIgnore.includes(type)) return null;

    return renderAlert(label);
  }
};

const ThreadTitle = ({
  threadKey,
  threads
}: {
  threadKey: string;
  threads: { [key: string]: Thread };
}) => {
  const classes = useStyles();
  const { selectThread } = useContext(MessageHubProvider);
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const thread = threads[threadKey];

  const [edit, setEdit] = React.useState(false);
  const toggleEdit = () => setEdit(!edit);
  const handleClick = async () => toggleEdit();

  const handleSelectThread = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    selectThread(threadKey);
  };

  return (
    <div className={classes.user} onClick={() => selectThread(threadKey)}>
      <div onClick={handleClick} className={classes.userTitle}>
        <div
          style={{
            width: 250,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
          <DisplayName thread={thread} name={''} />
        </div>
        <div style={{ flexGrow: 1 }} />
        <IconButton onClick={handleSelectThread} size="small">
          <ChevronRightIcon style={{ color: 'white' }} />
        </IconButton>
      </div>
    </div>
  );
};

//    {edit ? (
//      <ClickAwayListener onClickAway={handleClickAway}>
//        <CustomTextField
//          value={value}
//          ref={ref}
//          fullWidth
//          contentEditable={edit}
//          onChange={(e) => setValue(e.target.value)}
//          onKeyDown={(e) => e.key === 'Enter' && handleClickAway()}
//        />
//      </ClickAwayListener>
//    ) : (
//      <div onClick={handleClick} className={classes.userTitle}>
//        <div
//          style={{
//            width: 250,
//            whiteSpace: 'nowrap',
//            overflow: 'hidden',
//            textOverflow: 'ellipsis'
//          }}>
//          <DisplayName thread={thread} name={name} />
//        </div>
//        <div style={{ flexGrow: 1 }} />
//        <IconButton onClick={handleSelectThread} size="small">
//          <ChevronRightIcon style={{ color: 'white' }} />
//        </IconButton>
//      </div>
//    );
//  }

const DisplayName = ({ thread, name }: { thread: Thread; name?: string }) => {
  const { currentDeal, stepdefdict } = useProcess();
  const Id = thread.fixedTo;

  if (!Id) return <>{thread.label}</>;

  const PrimaryContact = getFieldValueWithFieldDefinitionId({
    stepdefdict,
    currentDeal,
    FieldDefinitionId: globalIds.customer.partiesAndContacts.PrimaryContact
  });

  const LenderProposalContact = getFieldValueWithFieldDefinitionId({
    stepdefdict,
    currentDeal,
    FieldDefinitionId:
      globalIds.customer.partiesAndContacts.LenderProposalContact
  });

  const LenderPayoutsContact = getFieldValueWithFieldDefinitionId({
    stepdefdict,
    currentDeal,
    FieldDefinitionId:
      globalIds.customer.partiesAndContacts.LenderProposalContact
  });

  switch (name) {
    case 'Contacts':
      if (Id === parseInt(PrimaryContact))
        return <SpecialName label="Primary Customer Contact" />;
      return <>{thread.label}</>;
    case 'Lenders':
      if (Id === parseInt(LenderProposalContact))
        return <SpecialName label="Lender Proposal Contact" />;
      if (LenderPayoutsContact)
        return <SpecialName label="Lender Payout Contact" />;
      return <>{thread.label}</>;
    default:
      return <>{thread.label}</>;
  }
};

const SpecialName = ({ label }: { label: string }) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <StarIcon style={{ marginRight: 8 }} />
    <Typography color="inherit">{label}</Typography>
  </div>
);
