import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Column from '../Column';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Button from '@material-ui/core/Button';
import uuid from 'react-uuid';

const useStyles = makeStyles((theme) => ({
  root: { display: 'flex', alignItems: 'flex-start' },
  columnList: {
    display: 'flex',
    transition: 'all 0.2s ease',
    background: (props) => props.isDraggingOver && theme.palette.primary.light
  }
}));

const Component = ({ boardKey, state, setState, saveBoard }) => {
  const classes = useStyles();

  const addColumn = () => {
    const id = uuid();
    const newColumn = { id, title: 'new list', taskIds: [] };
    const newState = JSON.parse(JSON.stringify(state));
    if (!newState.columns) newState.columns = [];
    newState.columns[id] = newColumn;
    if (!newState.columnOrder) newState.columnOrder = [];
    newState.columnOrder.push(id);
    setState(newState);
  };

  const updateColumn = ({ id, value }) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.columns[id].title = value;
    setState(newState);
  };

  const addTask = ({ columnId }) => {
    const taskId = uuid();
    const newTask = {
      id: taskId,
      title: 'New Card',
      timecreated: `${new Date()}`
    };

    const newState = JSON.parse(JSON.stringify(state));
    if (!newState.tasks) newState.tasks = [];
    newState.tasks[taskId] = newTask;

    if (!newState.columns[columnId].taskIds)
      newState.columns[columnId].taskIds = [];
    newState.columns[columnId].taskIds.push(taskId);
    setState(newState);
  };

  const updateTask = ({ id, value, field }) => {
    const newState = JSON.parse(JSON.stringify(state));
    if (!newState.tasks[id][field]) newState.tasks[id][field] = '';
    newState.tasks[id][field] = value;
    setState(newState);
  };

  const archiveList = ({ id }) => {
    const newState = JSON.parse(JSON.stringify(state));
    const index = newState.columnOrder.indexOf(id);
    if (index > -1) newState.columnOrder.splice(index, 1);
    setState(newState);
  };

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (destination !== null) {
      const sameId = destination.droppableId === source.droppableId;
      const sameIndex = destination.index === source.index;
      if (!destination) return;
      if (sameId && sameIndex) return;

      if (type === 'column') {
        const newColumnOrder = state.columnOrder
          ? Array.from(state.columnOrder)
          : [];
        newColumnOrder.splice(source.index, 1);
        newColumnOrder.splice(destination.index, 0, draggableId);

        const newState = { ...state, columnOrder: newColumnOrder };
        setState(newState);
        return;
      }

      const home = state.columns[source.droppableId];
      const foreign = state.columns[destination.droppableId];

      if (home === foreign) {
        // console.log({ home, foreign });

        const newTaskIds = home.taskIds ? Array.from(home.taskIds) : [];
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);

        const newHome = { ...home, taskIds: newTaskIds };
        const newState = {
          ...state,
          columns: { ...state.columns, [newHome.id]: newHome }
        };

        setState(newState);
        return;
      }

      // Moving from one list to another
      const homeTaskIds = home.taskIds ? Array.from(home.taskIds) : [];
      homeTaskIds.splice(source.index, 1);
      const newHome = { ...home, taskIds: homeTaskIds };

      const foreignTaskIds = foreign.taskIds ? Array.from(foreign.taskIds) : [];
      foreignTaskIds.splice(destination.index, 0, draggableId);
      const newForeign = { ...foreign, taskIds: foreignTaskIds };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newHome.id]: newHome,
          [newForeign.id]: newForeign
        }
      };

      setState(newState);
    }
  };

  // console.log({ state });

  return (
    <div className={classes.root}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column">
          {(provided) => (
            <Columns
              state={state}
              provided={provided}
              archiveList={archiveList}
              updateColumn={updateColumn}
              addTask={addTask}
              updateTask={updateTask}
            />
          )}
        </Droppable>
      </DragDropContext>
      <Button onClick={addColumn}>+ Add another list</Button>
    </div>
  );
};

const Columns = React.memo((props) => {
  const { provided, state, archiveList, updateColumn, addTask, updateTask } =
    props;
  const classes = useStyles();
  return (
    <div
      {...provided.droppableProps}
      ref={provided.innerRef}
      className={classes.columnList}>
      {state.columnOrder &&
        state.columnOrder.map((columnId, index) => {
          const column = state.columns[columnId];
          const tasks =
            column.taskIds &&
            column.taskIds.map((taskId) => state.tasks[taskId]);

          return (
            <Column
              key={column.id}
              column={column}
              tasks={tasks}
              index={index}
              archiveList={archiveList}
              updateColumn={updateColumn}
              addTask={addTask}
              updateTask={updateTask}
            />
          );
        })}
      {provided.placeholder}
    </div>
  );
});

export default Component;
