import { store } from 'redux/store';
import { SET_PROCESS_FIELDS } from 'redux/actions/types';
import { FieldInstance } from 'types/interfaces';

const { dispatch } = store;

export const clearOverview = async ({ ObjectInstance }) => {
  const ProcessInstanceFields: FieldInstance[] =
    store.getState().process.processInstanceFields;
  const updateProcessInstanceFields: FieldInstance[] = JSON.parse(
    JSON.stringify(ProcessInstanceFields)
  );

  const exists =
    ProcessInstanceFields.length > 0 &&
    ProcessInstanceFields[ObjectInstance.ProcessInstanceId];

  if (exists) {
    // Remove the entry in Redux.
    updateProcessInstanceFields[ObjectInstance.ProcessInstanceId] = {
      Id: 0,
      Title: '',
      ProcessInstanceId: 0,
      FieldDefinitionId: 0,
      ObjectDefinitionId: 0,
      ObjectInstanceId: 0,
      UserInstanceId: 0,
      UserDefinitionId: 0,
      FieldValue: ''
    };
    const config = {
      type: SET_PROCESS_FIELDS,
      payload: updateProcessInstanceFields
    };
    await dispatch(config);
  }
};
