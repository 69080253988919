import { Grid } from '@material-ui/core';
import LPCalculatorObjects from '../LPCalculatorObjects';
import { FormTabs } from '../Forms/FormTabs';
import { FormContent } from '../Forms/FormContent';
import { XpansionIsolated } from 'common/Xpansion';
import { Instructions } from './SystemUserView';
import { useMobile } from 'hooks';

export const QuoteStepView = () => {
  const isMobile = useMobile();

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Instructions />
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <XpansionIsolated expanded summary="Quotes">
          <LPCalculatorObjects />
        </XpansionIsolated>
      </Grid>
      <Grid item xs={isMobile ? 12 : 6}>
        <XpansionIsolated expanded summary="Form">
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <FormTabs />
            </Grid>
            <Grid item>
              <FormContent />
            </Grid>
          </Grid>
        </XpansionIsolated>
      </Grid>
    </Grid>
  );
};
