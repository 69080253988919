import { Extra } from 'components/Calculator/Calculator/interfaces';

export interface Calculation {
  Assets?: Asset[];
  Title: string;
  _AdditionalInputs: _AdditionalInputs;
  _Balloon: _Balloon;
  _Commission: _Commission;
  _DocumentFee: _DocumentFee;
  _NetAdvance: _NetAdvance;
  Amount: number;
  AmountIncludesVAT: boolean;
  APR: number;
  Calculation: number;
  CashFlow: CashFlowItem[];
  CashFlowAsText: string;
  CommissionAmount: number;
  CommissionPercentage: number;
  Counting: string;
  CustomerYield: number;
  Debug: string;
  Deposit: number;
  DocFee: number;
  DocFeeBase: number;
  DocFeeUpsell: number;
  ExecutionTime: number;
  ExtraPayments: ExtraPayment[];
  FinanceProduct: string;
  FlatRate: number;
  FundingRate: number;
  NominalFlatRate: number;
  Halves: number;
  InitialPayment: number;
  InitialRentals: number;
  Interest: number;
  IntroducerFee: number;
  IntroducerFeePercentOfCommission: number;
  MarginRate: number;
  MonthlyPayment: number;
  NonVATableItemsCost: number;
  Notes: string;
  NPV: number;
  OptionFee: number;
  OutputMessage: string;
  OutputStatus: number;
  OverridePayments: OverridePayment[];
  PaymentFrequency: PaymentFrequency;
  RateOfInterestPerAnnum: number;
  StartDate: Date | string;
  Term: number;
  Thirds: number;
  VATPercentage: number;
  Yield: number;
  FundingProfile: number;
  VAT: number;
  FinanceType: string;
  DiscretionaryType: string;
  Token?: string;
  extras?: Extra[];
  QuoteName?: string;
}

export enum PaymentFrequency {
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  SemiAnnually = 'SemiAnnually',
  Annually = 'Annually'
}

export enum SeasonalPaymentType {
  SeasonalPayment = 'Seasonal Payment',
  Overpayment = 'Overpayment'
}

export interface SeasonalPayment {
  amount: number;
  startPeriod: string;
  endPeriod: string;
  type: SeasonalPaymentType;
}

// export interface DBCalculator {
//   APR: string;
//   Amount: string | number;
//   AmountIncludesVAT: boolean;
//   Calculation: number;
//   CashFlow: CashFlowItem[] | null;
//   CashFlowAsText: string;
//   CommissionAmount: string;
//   CommissionPercentage: string;
//   Counting: string;
//   CustomerYield: string;
//   Debug: string;
//   Deposit: number;
//   DocFee: number;
//   DocFeeBase: number;
//   DocFeeUpsell: number;
//   ExtraPayments: ExtraPayment[];
//   ExecutionTime: number;
//   FlatRate: string;
//   FundingRate: number;
//   InitialPayment: number;
//   InitialRentals: number;
//   IntroducerFee: number;
//   IntroducerFeePercentOfCommission: number;
//   MarginRate: string;
//   MonthlyPayment: number | string;
//   NPV: number;
//   NonVATableItemsCost: number;
//   OptionFee: number;
//   OutputMessage: string;
//   OutputStatus: number;
//   OverridePayments: OverridePayment[];
//   PaymentFrequency: string;
//   StartDate: string;
//   Term: number;
//   Token?: null;
//   VATPercentage: string;
//   Yield: string;
//   '_NetAdvance/Deposit': string;
// }

export interface _Balloon {
  Balloon: number;
  Balloon_VatRate: number;
  Balloon_Vat: number;
  ContractPeriod: number;
  TotalBalloon: number;
}

export interface _Commission {
  Broker_Commission: number;
  Broker_Rate: number;
  Broker_VatRate: number;
  Broker_Vat: number;
  Introducer_Fee: number;
  Introducer_VatRate: number;
  Introducer_Vat: number;
  Funder_Fee: number;
  Funder_VatRate: number;
  Funder_Vat: number;
  TotalPayable: number;
  TotalPayable_IncVat: number;
}

export interface _NetAdvance {
  Purchase_Price: number;
  Purchase_VatRate: number;
  Purchase_Vat: number;
  NonVATableItemsCost: number;
  Deposit: number;
  Deposit_VatRate: number;
  Deposit_Vat: number;
  Settlement: number;
  Settlement_VatRate: number;
  Settlement_Vat: number;
  Part_Exchange: number;
  Part_Exchange_VatRate: number;
  Part_Exchange_Vat: number;
  Subsidy: number;
  Subsidy_VatRate: number;
  Subsidy_Vat: number;
  NetAdvance: number;
  Vat_Payment: string;
  VatTotal: number;
  VatDeferral: number;
  TotalDeposit: number;
  isLoading?: boolean;
}

export interface _DocumentFee {
  DocFee: number;
  DocFeeUpsell: number;
  DocFee_VatRate: number;
  DocFee_Vat: number;
  DocFee_PaymentDate: string;
  TotalDocFee: number;
  OptionFee: number;
  OptionFee_VatRate: number;
  OptionFee_Vat: number;
  TotalOptionFee: number;
  AnnualServiceFee: number;
  AnnualServiceFee_Vat: number;
  AnnualServiceFee_VatRate: number;
}

export interface _AdditionalInputs {
  RefundOfSalesProceeds: number;
  SecondaryPeriodRental: number;
  CustomerType: string;
  BaseRate: string;
  MinimumBaseRatePerAnnum: number;
  ManufacturerSubsidy: number;
  ManufacturerSubsidyDate: Date | string;
  DealersSubsidyAmount: number;
  DealersSubsidyAmountDate: Date | string;
}

export interface OverridePayment {
  Amount: number;
  EndPeriod: number;
  StartPeriod: number;
  ExPayOverRide?: string;
  isMonth?: boolean;
  isDeferral?: boolean;
}

export interface ExtraPayment {
  Amount: number;
  EndPeriod: number;
  StartPeriod: number;
}

export interface CashFlowItem {
  ActivityDate: string;
  Balance: number;
  Cost: number;
  Description: string;
  Interest: number;
  Month: number;
  NumberOfDays: number;
  Period: number;
  Receipt: number;
  Year: number;
}

export interface Init_Config {
  Calculation: { show: boolean };
  OutputMessage: { show: boolean };
  OutputStatus: { show: boolean };
  Counting: { show: boolean };
  Amount: { show: boolean };
  MonthlyPayment: { show: boolean };
  VATPercentage: { show: boolean };
  AmountIncludesVAT: { show: boolean };
  Deposit: { show: boolean };
  InitialRentals: { show: boolean };
  IntroducerFee: { show: boolean };
  IntroducerFeePercentOfCommission: { show: boolean };
  CommissionPercentage: { show: boolean };
  DocFee: { show: boolean };
  StartDate: { show: boolean };
  Term: { show: boolean };
  OptionFee: { show: boolean };
  TargetYield: { show: boolean };
  CommissionAmount: { show: boolean };
  TargetRate: { show: boolean };
  APR: { show: boolean };
  TargetAPR: { show: boolean };
  EXtraPayments: { show: boolean };
  OverridePayments: { show: boolean };
  CashFlow: { show: boolean };
  FlatRate: { show: boolean };
  NominalFlatRate: { show: boolean };
  FundingRate: { show: boolean };
  Yield: { show: boolean };
  MarginRate: { show: boolean };
  CustomerYield: { show: boolean };
  InitialPayment: { show: boolean };
  CashFlowAsText: { show: boolean };
  NonVATableItemsCost: { show: boolean };
  BalloonRepaymentValue: { show: boolean };
  Balloon: { show: boolean };
  FundingProfile: { show: boolean };
}

export interface Asset {
  Price: string;
  TotalPrice: number;
  Make: string;
  AssetCategory: string;
  Equipments: string;
  Vehicles: string;
  Model: string;
  Quantity: number;
  Year: number;
  Non_VATable_item: number;
  ObjectInstanceId: number;
  Deposit: number;
  VAT_Rate: number;
  Vat: number;
}
