import React from 'react';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    // border: '1px solid #e2e2e1',
    overflow: 'hidden',
    // borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': { backgroundColor: '#fff' },
    '&$focused': {
      backgroundColor: '#fff',
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main
    },
    '&$disabled': {
      border: '0px solid #e2e2e1'
    }
  },
  focused: {},
  disabled: {
    color: 'black',
    border: '0px solid #e2e2e1'
  }
}));

export const CurrencyField = ({ name, value, InputLabelProps }) => {
  const classes = useStyles();
  return (
    <TextField
      disabled
      fullWidth
      name={name}
      value={value}
      variant="outlined"
      margin="dense"
      InputProps={{ inputComponent: NumberFormatCustom, classes }}
      InputLabelProps={{ classes: { root: InputLabelProps } }}
      style={{ color: 'black' }}
    />
  );
};

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      //   onValueChange={values => {
      //     const { floatValue } = values;
      //     onChange({ target: { value: floatValue } });
      //   }}
      values={{ formattedValue: '0.00' }}
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      prefix="£"
    />
  );
};
