import React from 'react';
import Divider from '@material-ui/core/Divider';
import _ from 'lodash';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Paper';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import MotTest from './components/MotTests';
import RFRDialog from './components/RFRDialog';

export const VRMUI = ({ history, ves, theme, open, setOpen, handleClose }) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Paper
        style={{
          width: '100%',
          paddingBottom: theme.spacing(2),
          maxHeight: '100%',
          overflow: 'scroll'
        }}>
        <div style={{ padding: theme.spacing(2) }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start">
            <Grid
              data-cy="vrm-reg-number"
              item
              style={{ paddingRight: theme.spacing(2) }}>
              <Typography style={{ fontWeight: 'bold' }}>
                Registration:
              </Typography>
              <Typography data-cy="result-reg">
                {history?.registration}
              </Typography>
            </Grid>
            <Grid
              data-cy="vrm-car-model"
              item
              style={{ paddingRight: theme.spacing(2) }}>
              <Typography style={{ fontWeight: 'bold' }}>Car Model:</Typography>
              <Typography data-cy="res-model" align="center">
                {history?.model}
              </Typography>
            </Grid>
            <Grid
              data-cy="vrm-tax-status"
              item
              style={{ paddingRight: theme.spacing(2) }}>
              <Typography style={{ fontWeight: 'bold' }}>Tax Status</Typography>
              <Typography data-cy="res-tax-status" align="center">
                {ves?.taxStatus}
              </Typography>
            </Grid>
            <Grid
              data-cy="vrm-tax-due-date"
              item
              style={{ paddingRight: theme.spacing(2) }}>
              <Typography style={{ fontWeight: 'bold' }}>
                Tax Due Date
              </Typography>
              <Typography data-cy="res-tax-due" align="right">
                {ves?.taxDueDate}
              </Typography>
            </Grid>
          </Grid>
        </div>

        <div
          style={{
            border: `1px solid ${theme.palette.primary.main}`,
            borderRadius: theme.shape.borderRadius,
            paddingTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            padding: theme.spacing(2),
            minWidth: '95%'
          }}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center">
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Car Make:
              </Typography>
              <Typography align="center">
                {history?.make ? history.make : ves.make}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                First Used Date:
              </Typography>
              <Typography align="center">
                {history?.firstUsedDate
                  ? history.firstUsedDate
                  : 'Not Available'}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Fuel Type:
              </Typography>
              <Typography align="center">
                {history?.fuelType ? history.fuelType : ves.fuelType}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Primary Colour:
              </Typography>
              <Typography align="center">
                {history?.primaryColour ? history.primaryColour : ves.colour}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Registration Date:
              </Typography>
              <Typography align="center">
                {history.firstUsedDate
                  ? history.firstUsedDate
                  : ves.monthOfFirstRegistration}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Manufacture Date:
              </Typography>
              <Typography align="center">
                {history?.manufactureDate
                  ? history.manufactureDate
                  : ves.yearOfManufacture}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Date Of Last V5C Issued:
              </Typography>
              <Typography align="center">
                {ves?.dateOfLastV5CIssued
                  ? ves.dateOfLastV5CIssued
                  : 'Not Available'}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Engine Size:
              </Typography>
              <Typography align="center">
                {history?.engineSize ? history.engineSize : ves.engineCapacity}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                paddingRight: theme.spacing(4)
              }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                CO2 Emissions:
              </Typography>
              <Typography align="center">
                {ves?.co2Emissions ? ves.co2Emissions : 'Not Available'}
              </Typography>
            </Grid>
            <Grid
              item
              style={{
                paddingRight: theme.spacing(4)
              }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Revenue Weight:
              </Typography>
              <Typography align="center">
                {ves?.revenueWeight ? ves.revenueWeight : 'Not Available'}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Euro Status:
              </Typography>
              <Typography align="center">
                {ves?.euroStatus ? ves.euroStatus : 'Not Available'}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Real Driving Emissions:
              </Typography>
              <Typography align="center">
                {ves?.realDrivingEmissions
                  ? ves.realDrivingEmissions
                  : 'Not Available'}
              </Typography>
            </Grid>
            <Grid item style={{ paddingRight: theme.spacing(4) }}>
              <Typography style={{ fontSize: '12px', color: 'grey' }}>
                Wheel Plan:
              </Typography>
              <Typography align="center">
                {ves?.wheelplan ? ves.wheelplan : 'Not Available'}
              </Typography>
            </Grid>
          </Grid>

          {ves?.motStatus && (
            <div>
              <br />
              <Divider style={{ marginTop: 5, marginBottom: 2 }} />
              <br />
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start">
                <Grid
                  item
                  style={{
                    paddingBottom: theme.spacing(2),
                    marginLeft: theme.spacing(2)
                  }}>
                  <Typography style={{ fontWeight: 'bold' }}>
                    MOT Tests:
                  </Typography>
                  <Typography align="center">{ves?.motStatus}</Typography>
                </Grid>
              </Grid>
            </div>
          )}

          {history?.motTests && (
            <div>
              {Object.values(history?.motTests).map(
                (item: any, key: number) => {
                  let indexNum = 0;
                  return (
                    <div key={key}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        style={{ paddingBottom: theme.spacing(2) }}>
                        <Paper style={{ width: '100%', minHeight: 135 }}>
                          <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="baseline"
                            style={{ padding: theme.spacing(2) }}>
                            <Grid item>
                              {item?.testResult === 'PASSED' ? (
                                <Typography
                                  style={{ color: 'green', fontWeight: 800 }}>
                                  {item?.testResult}
                                </Typography>
                              ) : (
                                <Typography
                                  style={{ color: 'red', fontWeight: 800 }}>
                                  {item?.testResult}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item />
                            <Grid
                              item
                              style={{
                                paddingRight: theme.spacing(7)
                              }}>
                              <Grid
                                container
                                direction="column"
                                justifyContent="center"
                                alignItems="center">
                                <Grid item>
                                  <Typography style={{ fontWeight: 'bold' }}>
                                    Completed Date:
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Typography>{item?.completedDate}</Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item />
                            <Grid item />
                            <Grid item />
                          </Grid>
                          <MotTest item={item} theme={theme} />
                          {!_.isEmpty(item.rfrAndComments) && (
                            <div>
                              <div
                                style={{
                                  paddingLeft: theme.spacing(6),
                                  paddingRight: theme.spacing(10)
                                }}>
                                <Divider
                                  style={{
                                    marginTop: theme.spacing(2),
                                    marginBottom: theme.spacing(2)
                                  }}
                                />
                              </div>

                              {Object.values(item?.rfrAndComments).map(
                                (comments: any, index: number) => {
                                  indexNum = index;
                                  return (
                                    <Grid
                                      container
                                      direction="column"
                                      justifyContent="flex-start"
                                      alignItems="flex-start"
                                      style={{ paddingLeft: theme.spacing(6) }}
                                      key={index}>
                                      {index <= 4 && (
                                        <>
                                          <Grid item>
                                            <Typography
                                              style={{
                                                color: 'grey'
                                              }}>
                                              Type: {comments.type}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <Typography>
                                              {comments.text}
                                            </Typography>
                                          </Grid>
                                          <Grid
                                            item
                                            style={{ paddingBottom: 10 }}>
                                            <Typography>
                                              Is Dangerous:{' '}
                                              {comments.dangerous
                                                ? comments.dangerous
                                                : 'false'}
                                            </Typography>
                                          </Grid>
                                        </>
                                      )}
                                    </Grid>
                                  );
                                }
                              )}
                              {indexNum > 4 && (
                                <Grid
                                  container
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  style={{ padding: theme.spacing(4) }}>
                                  <RFRDialog
                                    item={item}
                                    open={open}
                                    setOpen={setOpen}
                                    handleClose={handleClose}
                                  />
                                </Grid>
                              )}
                            </div>
                          )}
                        </Paper>
                      </Grid>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
      </Paper>
    </Grid>
  );
};
