import { makeStyles, Typography } from '@material-ui/core';
import { ReactNode, useContext } from 'react';
import { theme } from 'theme';
import { FieldDefinition, FieldInstance } from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { OutputContext } from '../context/OutputContext';

const useStyles = makeStyles((theme) => ({
  common: {
    position: 'absolute',
    top: -22,
    borderRadius: theme.shape.borderRadius,
    padding: 10,
    border: '2px solid'
  },

  normal: {
    background: theme.palette.grey[50],
    borderColor: theme.palette.grey[300]
  },
  selected: {
    background: theme.palette.success.light,
    color: theme.palette.success.main,
    borderColor: theme.palette.success.main
  },
  declined: {
    background: theme.palette.error.light,
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main
  }
}));

const QuoteHeader = () => {
  const {
    props,
    lists,
    QuoteId,
    OverviewQuoteId,
    checkEmptyAmountAndCashFlow
  } = useContext(OutputContext);

  const {
    selected,
    isOverview,
    isDeclined,
    ObjectInstance: CompleteObjectInstance
  } = props;
  const { isEmpty } = checkEmptyAmountAndCashFlow();

  let quoteStatus;
  if (selected) {
    quoteStatus = 'Selected Quote';
  } else if (isDeclined) {
    quoteStatus = 'Declined Quote';
  } else {
    quoteStatus = 'Quote Id';
  }

  const quoteId = !isOverview ? QuoteId : OverviewQuoteId;
  const quoteText = `${quoteStatus}: ${quoteId}`;
  return (
    <>
      {isDeclined ? (
        <div>
          <div style={{ paddingBottom: theme.spacing(2) }} />

          <QuoteDiv
            selected={selected || false}
            isName={false}
            isDeclined={isDeclined}>
            <QuoteText
              text={`Declined Quote: ${quoteId}`}
              color={'white'}
              fontWeight="bold"
            />
          </QuoteDiv>
        </div>
      ) : (
        <>
          {!isEmpty && (
            <div>
              <div style={{ paddingBottom: theme.spacing(2) }} />

              <QuoteDiv
                selected={selected || false}
                isName={false}
                isDeclined={isDeclined}>
                <QuoteText
                  text={quoteText}
                  color={selected ? theme.palette.success.main : ''}
                  fontWeight="bold"
                />
              </QuoteDiv>
            </div>
          )}
        </>
      )}

      {lists.quoteTitle.map((FieldDefinition: FieldDefinition, i: number) => {
        const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
        const FieldInstance: FieldInstance | undefined = FieldInstanceList.find(
          (FieldInstance: FieldInstance) =>
            FieldInstance.FieldDefinitionId === FieldDefinition.Id
        );

        const hasTitleNoQuote: string = (() => {
          const QuoteNameId = 24156;
          const QuoteTitleId = 23537;
          const quoteInstance = FieldInstanceList.find(
            (FieldInstance: FieldInstance) => {
              return (
                FieldInstance.FieldDefinitionId === QuoteNameId &&
                FieldInstance.FieldValue !== ''
              );
            }
          );

          if (quoteInstance) return quoteInstance.FieldValue;
          const titleInstance = FieldInstanceList.find(
            (FieldInstance: FieldInstance) => {
              return FieldInstance.FieldDefinitionId === QuoteTitleId;
            }
          );

          return titleInstance ? titleInstance.FieldValue : '';
        })();

        const FieldValue = FieldInstance && FieldInstance.FieldValue;
        if (
          FieldValue === '' ||
          FieldValue === '""' ||
          FieldValue === null ||
          FieldInstance === undefined ||
          FieldValue === 'null'
        )
          return <div key={i} />;

        return (
          <QuoteDiv
            selected={selected || false}
            isDeclined={isDeclined}
            isName
            key={i}>
            <QuoteText
              text={`Quote Name: ${hasTitleNoQuote}`}
              color={
                selected
                  ? theme.palette.success.main
                  : isDeclined
                  ? 'white'
                  : ''
              }
              fontWeight="bold"
            />
          </QuoteDiv>
        );
      })}
    </>
  );
};

export default QuoteHeader;

const QuoteDiv = ({
  children,
  selected,
  isDeclined,
  isName
}: {
  children: ReactNode;
  selected: boolean;
  isDeclined: boolean | undefined;
  isName: boolean;
}) => {
  const classes = useStyles(selected);
  let statusClass;
  if (selected) {
    statusClass = classes.selected;
  } else if (isDeclined) {
    statusClass = classes.declined;
  } else {
    statusClass = classes.normal;
  }

  if (isName)
    return (
      <div
        style={{
          position: 'absolute',
          border: selected
            ? `2px solid ${theme.palette.success.main}`
            : isDeclined
            ? `2px solid ${theme.palette.error.main}`
            : `1px solid ${theme.palette.grey[300]}`,
          borderRadius: theme.shape.borderRadius,
          background: selected
            ? theme.palette.success.light
            : isDeclined
            ? theme.palette.error.light
            : theme.palette.grey[50],
          top: -22,
          left: selected || isDeclined ? 195 : 150,
          padding: 10,
          color: selected
            ? theme.palette.success.main
            : isDeclined
            ? theme.palette.error.main
            : ''
        }}>
        {children}
      </div>
    );
  return <div className={`${classes.common} ${statusClass}`}>{children}</div>;
};

const QuoteText = ({
  text,
  color,
  fontWeight
}: {
  text: string;
  color?: string;
  fontWeight?: 'normal' | 'bold' | 'bolder' | 'lighter' | number;
}) => (
  <Typography data-cy="quote-text-id" style={{ color, fontWeight }}>
    {text}
  </Typography>
);
