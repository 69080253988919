import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import ForceDirectedTree from './ForceDirectedTree';
import Dialog from '../../../Dialog';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' }
}));

const Component = ({ company }) => {
  const [open, setOpen] = React.useState(false);
  const [childmeta, setChildMeta] = React.useState();
  const handleClose = () => setOpen(false);
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <ForceDirectedTree
        company={company}
        setOpen={setOpen}
        setChildMeta={setChildMeta}
      />
      {/* <Dialog open={open} handleClose={handleClose} data={childmeta} /> */}
    </Paper>
  );
};

export default Component;
