import { Rule } from 'types/interfaces';
import { Thread } from '../../../interfaces';
import { globalIds } from 'helpers/globalIdConfig';

export const ruleGroupFilter = (name: string, thread: Thread) => {
  switch (thread.type) {
    case 'borrower.contact':
      if (name === 'Customer') return name;
      break;
    case 'lender.payouts':
    case 'lender.proposal':
      if (name === 'Lender') return name;
      break;
    case 'supplier.contact':
      if (name === 'Accounts' || name === 'Supplier') return name;
      break;
    case 'accounts':
      if (name === 'Accounts') return name;
      break;
    default:
      return name;
  }
};

export const ruleFilter = (rule: Rule, thread: Thread) => {
  let avRules = [0];
  switch (thread.type) {
    case 'borrower.contact': {
      avRules.push(...globalIds.ruleLists.BorrowerContactRules);
      if (avRules.includes(rule.Id)) return rule;
      break;
    }

    case 'lender': {
      avRules.push(...globalIds.ruleLists.LenderRules);
      if (avRules.includes(rule.Id)) return rule;
    }

    case 'lender.proposal': {
      avRules.push(...globalIds.ruleLists.LenderProposalRules);
      if (avRules.includes(rule.Id)) return rule;
      break;
    }

    case 'lender.payouts': {
      avRules.push(...globalIds.ruleLists.LenderPayoutRules);
      if (avRules.includes(rule.Id)) return rule;
      break;
    }

    case 'supplier.contact':
      avRules.push(...globalIds.ruleLists.SupplierContact);
      if (avRules.includes(rule.Id)) return rule;
      break;

    case 'accounts':
      avRules.push(...globalIds.ruleLists.AccountsRules);
      if (avRules.includes(rule.Id)) return rule;
      break;

    default:
      return rule;
  }
};
