import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextTyper from './TextTyper';
import Feed from './Feed';
import LinearProgress from '@material-ui/core/LinearProgress';

import { getUserInstanceSummaryList } from 'redux/actions/user';

const useStyles = makeStyles((theme) => ({
  root: { borderRadius: theme.shape.borderRadius, padding: theme.spacing(4) }
}));

const Component = () => {
  const classes = useStyles();
  const userDefsToAt = useSelector(
    (s) => s.config.instanceSettings.userDefsToAt
  );
  const userList = useSelector((s) => s.userAPI.userList);
  const token = useSelector((s) => s.user.auth.token);
  const [mentions, setMentions] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const getData = async () => {
    // console.log({ userList, userDefsToAt });
    const res = await getUserInstanceSummaryList({
      token,
      UserDefinitionId: userDefsToAt
    });

    // console.log({ res });
    let mentions = [];
    Object.values(userList[userDefsToAt][userDefsToAt]).forEach(
      ({ UserInstance }) => {
        // console.log({ UserInstance });
        mentions.push({ name: UserInstance && UserInstance.Title });
      }
    );
    setMentions(mentions);
    setLoading(false);
  };

  React.useEffect(() => {
    if (userDefsToAt) getData();
    if (!userDefsToAt) setLoading(false);
    // eslint-disable-next-line
  }, [userDefsToAt]);

  // If Loading show loader.
  if (loading) return <LinearProgress />;

  return (
    <div className={classes.root}>
      <TextTyper mentions={mentions} />
      <Feed />
    </div>
  );
};

export default Component;
