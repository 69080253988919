import { createContext, useContext, useState, useEffect } from 'react';

import { useTypedSelector } from 'redux/reducers';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { globalIds } from 'helpers/globalIdConfig';
import { UserInstance } from 'types/interfaces';
import { validateEmail } from 'helpers/validateEmail';

interface ContextData {
  loading: boolean;
  marketingData: UserInstance[];
  refetch: () => Promise<UserInstance[] | undefined | void>;
}

const MarketingContext = createContext<ContextData>({
  loading: false,
  marketingData: [],
  refetch: async () => {}
});

export const useMarketing = () => useContext(MarketingContext);

export const MarketingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [marketingData, setMarketingData] = useState<UserInstance[]>([]);
  const user = useTypedSelector((s) => s.user.user);
  const token = useTypedSelector((s) => s.user.auth.token);

  const getMarketingRecords = async () => {
    setLoading(true);

    const promises: Promise<
      | { token: any; data: any; success?: undefined }
      | { success: boolean; token: any; data: any }
    >[] = [];

    for (const UserDefinitionId of Object.values(globalIds.userDefinitionIds)) {
      const promise = getUserInstanceSummaryList({
        token,
        UserDefinitionId
      });

      promises.push(promise);
    }

    const responses = await Promise.all(promises).catch((error) => {
      console.error('getMarketingRecords: One or more promises failed', error);
    });

    const allUserInstances: UserInstance[] =
      responses
        ?.map((r) => Object.values(r.data).map((v: any) => v.UserInstance))
        .flat() || [];

    const validatedUserInstances: UserInstance[] = [];

    for (const user of allUserInstances) {
      if (!validateEmail(user.UserInstanceEmail)) continue;
      validatedUserInstances.push(user);
    }

    setMarketingData(validatedUserInstances);
    setLoading(false);
  };

  useEffect(() => {
    if (!token || !user) return;

    getMarketingRecords();
  }, [token, user]);

  return (
    <MarketingContext.Provider
      value={{ loading, marketingData, refetch: getMarketingRecords }}>
      {children}
    </MarketingContext.Provider>
  );
};
