import { useState, ChangeEvent } from 'react';
import {
  Input,
  MenuItem,
  Button,
  Tooltip,
  Typography,
  Paper,
  Grid,
  IconButton,
  makeStyles,
  createStyles
} from '@material-ui/core';
import { validateEmail } from 'helpers/validateEmail';
import SearchIcon from '@material-ui/icons/Search';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import { theme } from 'theme';

const useStyles = makeStyles(() =>
  createStyles({
    scrollContainer: {
      width: '100%',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '0px'
      },
      '&:hover': {
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(0,0,0,.2)'
        }
      }
    }
  })
);

type Option = { label: string; value: number; title: string };
interface Props {
  existingOptionIdList: Option[] | Option; // a list of already selected option ids
  onCreate: (email: string, title: string) => void;
  onSelect: (option: Option) => void;
  options: Option[]; // are menu items
  onEmailInputChange: (email: string) => void;
}

export const CreatableTextSearch_Contact = ({
  options,
  onCreate,
  onSelect,
  existingOptionIdList,
  onEmailInputChange
}: Props) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('');
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setValue(value);
    onEmailInputChange(value);
  };

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as HTMLInputElement;
    setTitle(value);
  };

  const disabled = !validateEmail(value);
  const tooltip = disabled ? 'Please enter a valid email' : 'Create new user';

  const handleSelect = (el: Option) => {
    setOpen(false);
    const selectedTitle = title || el.title;
    setTitle(selectedTitle);
    onSelect({ ...el, title: selectedTitle });
  };

  const SearchBtn = (props: any) => (
    <Button
      data-cy="contact-search-btn"
      {...props}
      onClick={() => setOpen(true)}
      variant="contained"
      color="primary">
      <SearchIcon style={{ marginRight: 8 }} />
    </Button>
  );

  if (!open) {
    if ('label' in existingOptionIdList) {
      return (
        <Paper elevation={1} style={{ padding: 10 }}>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={3} className={classes.scrollContainer}>
              <Typography variant="caption">Title</Typography>
              <Typography>{existingOptionIdList.title}</Typography>
            </Grid>
            <Grid item xs={4} className={classes.scrollContainer}>
              <Typography variant="caption">Email / Id</Typography>
              <Typography>{existingOptionIdList.label}</Typography>
            </Grid>
            <Grid item xs={4} style={{ width: '100%', display: 'flex' }}>
              <SearchBtn fullWidth />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() =>
                  handleSelect({ value: 0, title: '', label: '' })
                }>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Paper>
      );
    } else {
      return (
        <>
          <SearchBtn fullWidth />
          {existingOptionIdList.length > 0 &&
            existingOptionIdList.map((i, idx) => {
              return <div key={idx}>{i}</div>;
            })}
        </>
      );
    }
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div style={{ flexGrow: 1 }}>
        <div style={{ display: 'flex' }}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Input
                style={{ margin: 2 }}
                value={title}
                onChange={handleTitleChange}
                fullWidth
                placeholder="Supplier Contact Name"
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                data-cy="contact-input"
                value={value}
                onChange={handleChange}
                fullWidth
                placeholder="Enter Valid Email"
                endAdornment={
                  value.length > 0 && (
                    <Tooltip title={tooltip}>
                      <div>
                        <Button
                          data-cy="contact-create-btn"
                          style={{ width: 100 }}
                          disabled={disabled}
                          onClick={() => {
                            //handleCreate();
                            onCreate(value, title);
                            setOpen(false);
                          }}>
                          + Create
                        </Button>
                      </div>
                    </Tooltip>
                  )
                }
              />
            </Grid>
          </Grid>

          <Button onClick={() => setOpen(false)}>
            <CloseIcon />
          </Button>
        </div>

        {options
          // ?.filter(
          //   (el: Option) => !existingOptionIdList.includes(el.value.toString())
          // )
          .filter((el: Option) =>
            el.label.toLowerCase().includes(value.toLowerCase())
          )
          ?.map((el: Option) => {
            const disabled = !validateEmail(el.label);
            return (
              <Tooltip
                key={el.value}
                title={
                  disabled
                    ? 'You cannot add a user with an invalid email address'
                    : ''
                }>
                <div>
                  <MenuItem
                    data-cy="contact-menu-item"
                    onClick={() => handleSelect(el)}
                    disabled={disabled}>
                    {el.label}
                  </MenuItem>
                </div>
              </Tooltip>
            );
          })}
      </div>
    </ClickAwayListener>
  );
};
