import {
  TextField,
  MenuItem,
  Checkbox,
  Grid,
  Typography,
  FormControlLabel,
  Box
} from '@material-ui/core';
import { Event, Settings } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { getEventColor } from 'components/Calendar/functions/mapEventData';
import { useProcess } from 'hooks';
import { useContext, useState } from 'react';
import { theme } from 'theme';
import { CalendarContext } from '../../context/CalendarContext';
import { EventContext } from '../../context/EventContext';

const CalendarSubject = () => {
  return (
    <div
      style={{
        border: `2px ${theme.palette.grey[200]} solid`,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
      }}>
      <CalendarTitle />
      <div style={{ paddingBottom: theme.spacing(1) }} />
      <CalendarType />
    </div>
  );
};

export default CalendarSubject;

const CalendarTitle = () => {
  const { currentDeal } = useProcess();
  const { handleInputChange } = useContext(EventContext);
  const { formValues, error } = useContext(CalendarContext);

  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          label="Event Title"
          placeholder="Event Title"
          variant="outlined"
          fullWidth
          required
          error={error.component === 'Title' ? error.mark : false}
          helperText={error.component === 'Title' ? error.reason : ''}
          value={formValues.title}
          name="title"
          onChange={handleInputChange}
          margin="normal"
          InputProps={{
            startAdornment: (
              <Event
                fontSize="medium"
                style={{ paddingRight: theme.spacing(1) }}
              />
            )
          }}
        />
      </Grid>
      {Object.values(currentDeal).length > 0 && (
        <Grid item xs={12} style={{ paddingBottom: theme.spacing(1) }}>
          <Alert>
            <AlertTitle>
              Deal Linked: {currentDeal.ProcessInstance.Id}
            </AlertTitle>
            <Typography variant="h6" color="textSecondary">
              This event will be linked in your calendar, allowing you to easily
              navigate to the deal through the provided link.
            </Typography>
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

const CalendarType = () => {
  const { formValues } = useContext(CalendarContext);
  const { handleSelectChange } = useContext(EventContext);
  const eventColor = getEventColor(formValues.eventType);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
      <div
        style={{
          width: '20px',
          height: '20px',
          borderRadius: '25%',
          backgroundColor: eventColor
        }}
      />
      <TextField
        data-cy="event-type"
        variant="outlined"
        fullWidth
        select
        label="Event"
        value={formValues.eventType}
        onChange={handleSelectChange}
        InputProps={{
          startAdornment: <Settings style={{ paddingRight: 8 }} />
        }}>
        <MenuItem disabled value={''}>
          Select Event Type
        </MenuItem>
        <MenuItem value={'Call'}>
          {formValues.eventType !== 'Call' && (
            <ColoredCircle color={`${theme.palette.primary.light}66`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Call
        </MenuItem>
        <MenuItem value={'Meeting'}>
          {formValues.eventType !== 'Meeting' && (
            <ColoredCircle color={`#0d730d`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Meeting
        </MenuItem>
        <MenuItem value={'Notes'}>
          {formValues.eventType !== 'Notes' && (
            <ColoredCircle color={`#e69e19`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Notes
        </MenuItem>
        <MenuItem value={'Personal_Note'}>
          {formValues.eventType !== 'Personal_Note' && (
            <ColoredCircle color={`#FFFF00`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Personal Note
        </MenuItem>
        <MenuItem value={'Birthdays'}>
          {formValues.eventType !== 'Birthdays' && (
            <ColoredCircle color={`#05696B`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Birthdays
        </MenuItem>
        <MenuItem value={'Deal_Expiry_Date'}>
          {formValues.eventType !== 'Deal_Expiry_Date' && (
            <ColoredCircle color={`#800080`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Deal Expiry Date
        </MenuItem>
        <MenuItem value={'End_of_Lease_Date'}>
          {formValues.eventType !== 'End_of_Lease_Date' && (
            <ColoredCircle color={`#FF0000`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          End of Lease Date
        </MenuItem>
        <MenuItem value={'Passing_Time_Date'}>
          {formValues.eventType !== 'Passing_Time_Date' && (
            <ColoredCircle color={`#ff007f`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Passing Time Date
        </MenuItem>
        <MenuItem value={'Annual_Leave'}>
          {formValues.eventType !== 'Annual_Leave' && (
            <ColoredCircle color={`#0096FF`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Annual Leave
        </MenuItem>

        <MenuItem value={'Buying_Appointment'}>
          {formValues.eventType !== 'Buying_Appointment' && (
            <ColoredCircle color={`#557397`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          Buying Appointment (forward buy date)
        </MenuItem>

        <MenuItem value={'TODO'}>
          {formValues.eventType !== 'TODO' && (
            <ColoredCircle color={`#245985`} />
          )}
          <div style={{ paddingLeft: 8 }} />
          TODO
        </MenuItem>
        <MenuItem value={'Other'}>
          {formValues.eventType !== 'Other' && <ColoredCircle color={`grey`} />}
          <div style={{ paddingLeft: 8 }} />
          Other
        </MenuItem>
      </TextField>
    </div>
  );
};

const ColoredMenuItem = ({
  value,
  color,
  label
}: {
  value: string;
  color: string;
  label: string;
}) => (
  <MenuItem value={value}>
    <ColoredCircle color={color} />
    <div style={{ paddingLeft: 8 }} />
    {label}
  </MenuItem>
);

const ColoredCircle = ({ color }: { color: string }) => {
  return (
    <div
      style={{
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: color
      }}
    />
  );
};
