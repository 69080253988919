import { firebase } from 'redux/firebase';
import axios, { AxiosRequestConfig } from 'axios';
import { store } from 'redux/store';
import { callMsGraphDetailed } from 'services/microsoft/graph';
import { Message } from '@microsoft/microsoft-graph-types';
import { BugTracker } from 'Utils/Bugtracker';
import { CompleteObjectInstance } from 'types/interfaces';
export { sendEmail, saveConversationIdToThread } from './sendEmail';

const db = firebase.firestore();

// Proxy database
interface CreateGroupProps {
  Ids: IProps;
  label?: string;
  CompleteObjectInstance?: CompleteObjectInstance;
  fixedTo?: number;
  parentId?: number;
  members?: string[];
  type?: string;
  selectedThreadKey?: string;
}
interface IProps {
  Id: string;
  ProcessInstanceId: string;
}

export const createGroup: (
  props: CreateGroupProps
) => Promise<string> = async ({
  Ids,
  label,
  fixedTo,
  parentId,
  members,
  type
}) => {
  const ref = db.collection('thread').doc();
  await ref.set({
    ProcessInstanceId: Ids.ProcessInstanceId,
    members: members ? members : ([Ids.Id] as string[]),
    label: label ? label : 'Click to name',
    fixedTo: fixedTo ? fixedTo : null,
    type: type ? type : null,
    id: ref.id,
    parentId: parentId ? parentId : null
  });
  return ref.id;
};

export const removeThread = async (threadId: string) => {
  try {
    await db.collection('thread').doc(threadId).delete();
    console.log(`Successfully removed thread with ID: ${threadId}`);
  } catch (error) {
    console.error(`Error removing thread with ID ${threadId}:`, error);
  }
};

export const updateGroup: (props: CreateGroupProps) => Promise<void> = async ({
  Ids,
  label,
  CompleteObjectInstance,
  fixedTo,
  members,
  parentId,
  type,
  selectedThreadKey
}) => {
  const ref = db.collection('thread').doc(selectedThreadKey);
  return await ref.update({
    ProcessInstanceId: Ids.ProcessInstanceId,
    CompleteObjectInstance,
    members: members ? members : ([Ids.Id] as string[]),
    label: label ? label : 'Click to name',
    fixedTo: fixedTo ? fixedTo : null,
    type: type ? type : null,
    parentId: parentId ? parentId : null
  });
};

export const renameGroup = (key: string, newName: string) => {
  return db.collection('thread').doc(key).update({ label: newName });
};

export const deleteGroup = (key: string) => {
  return db.collection('thread').doc(key).delete();
};

export const addUserToGroup = (key: string, Id: string) => {
  return db
    .collection('thread')
    .doc(key)
    .update({
      members: firebase.firestore.FieldValue.arrayUnion(Id)
    });
};

export const deleteUserFromGroup = (key: string, Id: string) =>
  db
    .collection('thread')
    .doc(key)
    .update({
      members: firebase.firestore.FieldValue.arrayRemove(Id)
    });

export const getConversationForThread = (key: string | null) => {
  if (!key) return null;
  return db
    .collection('thread')
    .doc(key)
    .collection('messages')
    .onSnapshot((querySnapshot) => {
      let messages: Message[] = [];
      querySnapshot.forEach((doc) => messages.push(doc.data()));
      return messages;
    });
};

export const addMessageToConversation = async (
  key: string,
  message: Message
) => {
  /**THIS WILL UPDATE THE TREADS CONVERSATION ID BUT IM NOT SURE WE ACTUALLY WANT THIS BEHAVIOUR */
  // let shouldUpdateConversationId = false;
  // const ref = db.collection('thread').doc(key).collection('messages');
  // await ref.get().then(async (querySnapshot: any) => {
  //   if (querySnapshot.empty) shouldUpdateConversationId = true;
  // });

  // if (shouldUpdateConversationId) {
  //   const ref = db.collection('thread').doc(key);
  //   await ref.update({ conversationId: message.conversationId });
  // }
  return db
    .collection('thread')
    .doc(key)
    .collection('messages')
    .doc(message.id)
    .set(message)
    .then((res) => res)
    .catch((e) => e);
};

// Proxy Server

interface Imo365MailSubscribe {
  id: string;
  accessToken: string;
}

export const mo365MailSubscribe = async ({
  id,
  accessToken
}: Imo365MailSubscribe) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const url = `${process.env.REACT_APP_PROXY}/mo/email`;
  const config: AxiosRequestConfig = {
    url,
    method: 'POST',
    data: { id, accessToken },
    headers: { token }
  };
  try {
    const result = await axios(config);
    return result.data;
  } catch (e) {
    // console.log({ e });
    BugTracker.notify(e);
    return e;
  }
};

// MICROSOFT GRAPH

export const getSuggestedMessagesForConversationByEmails = () => {
  const token = store.getState().user.auth.token;
  const loggedInUser = store.getState().user.user;
  // Get emails for all the members
  // const membersEmails = await getMembersList({
  //   loggedInUser,
  //   token,
  //   members
  // });
};

export const getAllEmailsWithConversationId = async ({
  key,
  accessToken,
  conversationId
}) => {
  const method = 'GET';
  const url = `https://graph.microsoft.com/v1.0/me/messages?$filter=conversationId eq '${conversationId}'`;
  const res = await callMsGraphDetailed({ accessToken, url, method });
  const receivedMessages: Message[] = res?.data?.value;
  const batch = db.batch();

  receivedMessages.forEach((message) => {
    if (message.isDraft === false) {
      const ref = firebase
        .firestore()
        .collection('thread')
        .doc(key)
        .collection('messages')
        .doc(message.id);
      batch.set(ref, message);
    }
  });
  return await batch.commit();
};

export const getAndFilterConversations = async ({ accessToken, threads }) => {
  if (!threads) return;
  // filter Messages for Each thread
  Object.keys(threads).forEach(async (key) => {
    const thread = threads[key];
    const { members, conversationId } = thread;
    if (!members) return;
    let messages: Message[] = [];
    try {
      if (conversationId) {
        await getAllEmailsWithConversationId({
          key,
          accessToken,
          conversationId
        });
      }
      return messages;
    } catch (e) {
      return e;
    }
  });

  return 'Hello';
};

const memberList = (membersEmails) => {
  let url = `participants:${membersEmails[0]}`;
  for (const index in membersEmails) {
    const email = membersEmails[index];
    url = url + ` AND participants:${email}`;
  }
  return url;
};

export const getAttachmentsList = async (accessToken: string, id: string) => {
  const method = 'GET';
  const url = `https://graph.microsoft.com/v1.0/me/messages/${id}/attachments`;
  const res = await callMsGraphDetailed({
    accessToken,
    url,
    method
  });
  return res?.data?.value;
};
