import { gql } from '@apollo/client';

export const UPDATE_QUICK_USER = gql`
  mutation QuickAddUser(
    $baseUrl: String
    $data: UserInstanceInput
    $processdefinitionid: Int
  ) {
    QuickAddUser(
      baseURL: $baseUrl
      data: $data
      processdefinitionid: $processdefinitionid
    ) {
      ConsoleUserInstanceId
      DefaultMessage
      DocumentFooter
      DocumentHeader
      EmailFooter
      FromAddress
      FromName
      Id
      IsPublished
      ItemOrder
      LastModified
      LogoURL
      NickName
      OwnerUserInstanceId
      ProfilePicture
      ProxyUserInstanceId
      RelatedUserPermission
      RelationshipStatus
      SmtpHost
      SmtpPassword
      SmtpPort
      SmtpSSL
      SmtpUsername
      SystemAccess
      TelephoneNumber
      ThirdPartyId
      Title
      UserDefinitionId
      UserInstanceEmail
      UserInstancePassword
    }
  }
`;
