import { useState, forwardRef } from 'react';
import { Extension } from 'types/interfaces';
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Checkbox,
  CardActions,
  Button,
  useTheme,
  Dialog,
  DialogContent,
  Slide
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useTypedSelector } from 'redux/reducers';
import { Typography } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

const Extensions = () => {
  const { features } = useTypedSelector((s) => s.fb.proxy_instance_settings);
  const theme = useTheme();
  const [selected, setSelected] = useState<string | null>(null);
  const handleClose = () => setSelected(null);

  console.log({ selected, open: selected });
  return (
    <Grid container direction="column" spacing={1}>
      <InformationDialog
        open={selected}
        selected={selected}
        handleClose={handleClose}
      />
      <Grid item>
        <Typography variant="h1">Extensions</Typography>
      </Grid>
      <Grid item>
        <Alert severity="info">
          <Typography>
            Welcome to the extensions market place. Here you can view all the
            available extensions and manage subscriptions.
          </Typography>
        </Alert>
      </Grid>
      <Grid item>
        <Grid container spacing={1}>
          {Object.keys(features)
            .sort((a: any, b: any) => b - a)
            .map((key, i) => (
              <Grid item key={i} xs={4}>
                <Card raised>
                  <CardActionArea>
                    <CardMedia
                      image="https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.wallpapersden.com%2Fimage%2Fdownload%2Fabstract-changing-colors_63497_5120x2880.jpg&f=1&nofb=1&ipt=10ac062b068c4be1044371cc52a528d59b44233d7064b1a56a550126df6de661&ipo=images"
                      title="Contemplative Reptile"
                      style={{ height: 50 }}
                    />
                    <CardContent>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Typography
                          variant="h3"
                          style={{ color: theme.palette.grey[500] }}>
                          {HumanReadableExtensionName(key)}
                        </Typography>
                      </div>

                      <div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox checked={features[key]} />
                          <Typography>Instance Subscription</Typography>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Checkbox checked={false} />
                          <Typography>User Subscription</Typography>
                        </div>
                      </div>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    <Button
                      size="small"
                      style={{ color: theme.palette.primary.light }}
                      onClick={() => setSelected(key)}>
                      Info
                    </Button>
                    <Button
                      size="small"
                      style={{ color: theme.palette.primary.light }}>
                      Manage
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Extensions;

const HumanReadableExtensionName = (extensionName) => {
  switch (extensionName) {
    case 'afs_legacydealviewer':
      return 'Legacy Deal Viewer'.toUpperCase();
    case 'rfgql':
      return 'Lead Builder'.toUpperCase();
    case 'afs_cribsheets':
      return 'Cribsheets'.toUpperCase();
    default:
      return extensionName.replace('_', ' ').toUpperCase();
  }
};

const InformationDialog = ({ selected, open, handleClose }) => (
  <Dialog
    open={open}
    onClose={handleClose}
    TransitionComponent={Transition}
    transitionDuration={500}>
    <DialogContent>
      {selected && HumanReadableExtensionName(selected)}
    </DialogContent>
  </Dialog>
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
