import { Typography, withStyles } from '@material-ui/core';
import { store } from 'redux/store';

const theme = store.getState().config.theme;

export const TitleMain = withStyles({
  root: {
    color: theme.palette.primary.light,
    textDecoration: 'none',
    backgroundPosition: '0 0.9em',
    backgroundRepeat: 'repeat-x',
    backgroundSize: '28px 9px',
    backgroundMarginLeft: 10
  }
})(Typography);
