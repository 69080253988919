import React, { useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getAllSummaries } from 'redux/actions/audits';
import { useTypedSelector } from 'redux/reducers';
import Table from './components/Table';
import { Dashboard as DashboardLayout } from '../../layouts';
import { DateParse } from 'common/dateParse';

const Audit = () => {
  const token = useTypedSelector((s) => s.user.auth.token);
  const { auditSummaries } = useTypedSelector((s) => s.audits);
  const [loading, setLoading] = React.useState(true);

  const getData = async () => {
    const isData = await getAllSummaries({ token });
    if (isData) {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'dev') {
      getData();
    }
  }, []);

  const columns = [
    { title: 'Email', field: 'UserInstanceEmail' },
    { title: 'Endpoint', field: 'Endpoint' },
    {
      title: 'TimeStamp',
      field: 'TimeStamp',
      render: (rowData) => <DateParse date={rowData.TimeStamp} />
    }
  ];

  const title = 'Audit Log';

  if (process.env.REACT_APP_ENV !== 'dev') {
    return <DashboardLayout title="Audit" />;
  }

  return (
    <DashboardLayout title="Audit">
      {loading ? (
        <LinearProgress />
      ) : (
        <Table
          columns={columns}
          data={auditSummaries}
          getData={getData}
          title={title}
        />
      )}
    </DashboardLayout>
  );
};

export default Audit;
