import { useTypedSelector } from 'redux/reducers';

export const useInstance = () => {
  const { config } = useTypedSelector((s) => s);
  const { BrandList } = config.settings;
  const isAFS = BrandList.split(',').includes('AFS');
  const isSynergy = BrandList.split(',').includes('Synergy');
  const isDual = isAFS && isSynergy;
  const isLocal = config.hostname.globalHostName === 'process-builder-dev';
  const isStaging =
    config.hostname.globalHostName === 'processbuildercosneticscouk';

  return { config, isAFS, isSynergy, isDual, isLocal, isStaging };
};
