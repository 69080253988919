/**
 * Check if the URL contains mode=preview.
 * @param {string} url - The URL to be checked.
 * @returns {boolean} - True if the URL contains mode=preview, false otherwise.
 */
const isPreviewMode = (url: string, landingpage: boolean): boolean => {
  if (landingpage) {
    try {
      const urlObj = new URL(url);
      const mode = urlObj.searchParams.get('mode');
      return mode === 'preview';
    } catch (e) {
      return false;
    }
  }

  return false;
};
export default isPreviewMode;
