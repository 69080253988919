import { UserInstance } from 'types/interfaces';

export enum Status {
  Adding = 'Adding',
  Deleting = 'Deleting',
  Ready = 'Ready' // implicit
}

export interface SelectedFilters {
  userGroup: number[];
  customerType: (string | undefined)[];
  status?: (string | undefined)[];
}

export interface Contact extends UserInstance {
  id: string; // sendgrid contact id
  status?: string;
  email: string;
}

export interface List {
  name: string;
  id: string;
  contact_count: number;
}

export interface ListFb extends List {
  hostname: string;
  userInstanceId: number;
  ts: {
    seconds: number;
    nanoseconds: number;
  };
}
