import { useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import EmailIcon from '@material-ui/icons/Email';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export default function FormDialog({ open, handleClose, message, title }) {
  const theme = useTheme();
  const landingpage = useSelector((s) => s.utility.landingpage);

  const Icon = () => {
    switch (title) {
      case 'Accepted':
        return (
          <CheckCircleIcon
            style={{
              marginRight: 5,
              fontSize: 100,
              color: theme.palette.success.main
            }}
          />
        );
      case 'Declined':
        return (
          <CancelIcon
            style={{
              marginRight: 5,
              fontSize: 100,
              color: theme.palette.error.main
            }}
          />
        );
      default:
        return (
          <EmailIcon
            color="primary"
            style={{ marginRight: 5, fontSize: 100 }}
          />
        );
    }
  };

  return (
    <Dialog
      fullScreen={Boolean(landingpage)}
      open={open}
      onClose={handleClose}
      disableEscapeKeyDown
      aria-labelledby="form-dialog-title">
      {/* <DialogTitle id="form-dialog-title"></DialogTitle> */}
      <DialogContent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%'
            // flexDirection: 'column'
          }}>
          <Icon />
          <Typography
            style={{
              color:
                title === 'Declined'
                  ? theme.palette.error.main
                  : theme.palette.success.main
            }}
            variant="h1">
            {title ? title : 'Email Sent'}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            flexDirection: 'column'
          }}>
          {landingpage && (
            <DialogContentText data-cy="acceptance-msg">
              THANK YOU FOR YOUR SUBMISSION.
            </DialogContentText>
          )}

          <DialogContentText style={{ textAlign: 'center' }}>
            {message
              ? message
              : 'Well done, you have successfully moved this deal on to the next assignee. They will be sent an Email prompting them to fill out the next step.'}
          </DialogContentText>
        </div>
      </DialogContent>

      <DialogActions>
        {!landingpage && (
          <Button onClick={handleClose} color="primary">
            ok
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
