import { useEffect, useState } from 'react';
import { firebase } from 'redux/firebase';
import { useProcess } from 'hooks';
import { dealSummaryLender, formDeal } from './formDeal';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'redux/reducers';

// const storage = firebase.storage();
const db = firebase.firestore();

export const useDealSummary = () => {
  const dispatch = useDispatch();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { deal } = useTypedSelector((s) => s.dealSummary);
  const { currentDeal, currentProcess, currentStep, assets } = useProcess();
  const [status, setStatus] = useState<'pending' | 'active'>('pending');

  const ProcessInstanceId = currentDeal?.ProcessInstance?.Id.toString();
  const syncProcessDefinition = () => {
    const ref = db.collection('dealDefinition').doc(ProcessInstanceId);

    return ref
      .get()
      .then((doc) => {
        if (doc.exists) {
          ref.update({ definition: currentProcess });
        } else {
          ref.set({ definition: currentProcess });
        }
      })
      .catch((err) => console.log({ err }));
  };

  const syncProcessInstance = async (): Promise<boolean> => {
    const deal = await formDeal({ currentDeal, currentStep, assets, baseUrl });

    const ref = db.collection('deal').doc(ProcessInstanceId);
    const sync = await ref
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          if (deal) {
            ref.update(deal);
          }
        } else {
          if (deal) {
            ref.set(deal);
          }
        }

        setStatus('active');
        return true;
      })
      .catch((err) => console.log({ err }));

    console.log({ sync });
    return sync ? sync : false;
  };

  const syncProcessInstanceLender = async () => {
    // const dealLender = await dealSummaryLender({ currentDeal, baseUrl });
    // if (!dealLender) return;
    // if (dealLender.selected !== deal.lenders.selected) {
    //   const ref = db.collection('deal').doc(ProcessInstanceId);
    //   return ref
    //     .get()
    //     .then(async (lender) => {
    //       if (lender.exists) {
    //         if (dealLender) ref.update({ lenders: dealLender });
    //       } else {
    //         if (dealLender) ref.set({ lenders: dealLender });
    //       }
    //     })
    //     .catch((err) => console.log({ err }));
    // }
  };

  useEffect(() => {
    const dealRef = db.collection('deal').doc(ProcessInstanceId);
    const unsub = dealRef.onSnapshot((doc) => {
      if (doc.exists) dispatch({ type: 'UPDATE_DEAL', payload: doc.data() });
    });
    return () => unsub();
  }, []);

  return {
    deal,
    status,
    syncProcessDefinition,
    syncProcessInstance,
    syncProcessInstanceLender
  };
};
