import React, { useEffect, useState } from 'react';
import { LinearProgress, Typography, Box, makeStyles } from '@material-ui/core';
import {
  CheckCircle,
  Info,
  Send,
  Description as DescriptionOutlined,
  Assignment as AssignmentOutlined,
  ArrowForward,
  Error as ErrorIcon
} from '@material-ui/icons';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

interface IStage {
  name: string;
  icon: React.ComponentType<SvgIconProps>;
  color: string;
}

const stages: IStage[] = [
  { name: 'Preparing', icon: Info, color: '#3498db' },
  { name: 'Processing', icon: DescriptionOutlined, color: '#f39c12' },
  { name: 'Submitting', icon: Send, color: '#f1c40f' },
  { name: 'Completing', icon: AssignmentOutlined, color: '#2ecc71' },
  { name: 'Finished', icon: CheckCircle, color: '#27ae60' },
  { name: 'Failed', icon: ErrorIcon, color: '#e74c3c' }
];

const getStageIndex = (statusMessage: string): number => {
  if (statusMessage.includes('Error') || statusMessage.includes('Failed'))
    return 5;
  if (statusMessage.includes('Preparing')) return 0;
  if (statusMessage.includes('Processing')) return 1;
  if (statusMessage.includes('Submitting')) return 2;
  if (statusMessage.includes('Creating')) return 3;
  if (statusMessage.includes('completed')) return 4;
  return 0;
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  stagesContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%'
  },
  stageBox: {
    display: 'flex',
    alignItems: 'center'
  },
  stageIcon: {
    marginRight: theme.spacing(0.5)
  },
  stageText: {
    whiteSpace: 'nowrap'
  },
  arrow: {
    margin: theme.spacing(0, 1),
    fontSize: 20
  },
  progressBar: {
    marginTop: theme.spacing(1),
    '& .MuiLinearProgress-bar': {
      transition: 'none'
    }
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  statusText: {
    fontWeight: 'bold'
  },
  activeIcon: {
    animation: '$pulse 1.5s ease-in-out infinite'
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1
    },
    '50%': {
      transform: 'scale(1.1)',
      opacity: 0.7
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1
    }
  }
}));

const ProposalSubmissionProgress = ({
  statusMessage,
  isCompleted,
  onRemove
}: {
  statusMessage: string;
  isCompleted: boolean;
  onRemove: () => void;
}) => {
  const classes = useStyles();
  const stageIndex = getStageIndex(statusMessage);
  const isError = stageIndex === 5;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const targetProgress = isError
      ? 100
      : ((stageIndex + 1) / (stages.length - 1)) * 100;

    const animateProgress = () => {
      setProgress((prevProgress) => {
        if (prevProgress < targetProgress) {
          return Math.min(prevProgress + 1, targetProgress);
        }
        return prevProgress;
      });
    };

    const intervalId = setInterval(animateProgress, 20);

    return () => clearInterval(intervalId);
  }, [stageIndex, isError]);

  useEffect(() => {
    if (isCompleted) {
      const timer = setTimeout(() => {
        onRemove();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isCompleted, onRemove]);

  return (
    <Box className={classes.root}>
      <Box className={classes.stagesContainer}>
        {stages.slice(0, -1).map((stage, index) => {
          const Icon = stage.icon;
          const isActiveStage =
            index === stageIndex && !isCompleted && !isError;
          return (
            <React.Fragment key={stage.name}>
              <Box
                className={classes.stageBox}
                style={{
                  opacity: isError
                    ? index === stageIndex
                      ? 1
                      : 0.5
                    : index <= stageIndex
                    ? 1
                    : 0.5
                }}>
                <Icon
                  className={`${classes.stageIcon} ${
                    isActiveStage ? classes.activeIcon : ''
                  }`}
                  style={{
                    color:
                      isError && index === stageIndex
                        ? stages[5].color
                        : stage.color
                  }}
                />
                <Typography
                  variant="caption"
                  className={classes.stageText}
                  style={{
                    color:
                      isError && index === stageIndex
                        ? stages[5].color
                        : stage.color
                  }}>
                  {stage.name}
                </Typography>
              </Box>
              {index < stages.length - 2 && (
                <ArrowForward
                  className={classes.arrow}
                  style={{
                    color: isError
                      ? '#bdc3c7'
                      : index < stageIndex
                      ? stage.color
                      : '#bdc3c7'
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </Box>
      <Box className={classes.statusBox}>
        <Typography
          variant="body2"
          className={classes.statusText}
          style={{ color: stages[stageIndex].color }}>
          {statusMessage}
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        className={classes.progressBar}
        style={{ backgroundColor: isError ? stages[5].color : undefined }}
      />
    </Box>
  );
};

export default ProposalSubmissionProgress;
