// Material helpers
import { createTheme } from '@material-ui/core/styles';

// LightTheme
import palette from './palette';
import overrides from './overrides';
import typography from './typography';

export const theme = createTheme({
  palette,
  typography,
  overrides,
  shape: { borderRadius: 10 },
  zIndex: { appBar: 8, drawer: 7 }
});
