import React, { useState } from 'react';
import { MdMarkEmailRead } from 'react-icons/md';
import SendIcon from '@material-ui/icons/Send';
import DraftsIcon from '@material-ui/icons/Drafts';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { Grid, Box, LinearProgress, Typography } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Alert } from '@material-ui/lab';
import { useQuery } from 'react-query';

import { useTypedSelector } from 'redux/reducers';
import InfoDialog from 'components/InfoDialog';
import EventStats from './components/EventStats';
import { EmailsTotal } from 'views/Dashboard/components/CRMDashboardViews';
import { INITIAL_STATS } from './types';
import { fetchCampaigns } from './functions';
import { fetchCampaignsStats } from './functions';
import { QueryKeys } from '../types';

const CampaignsTable = React.lazy(() => import('./components/CampaignsTable'));

const Dashboard = () => {
  const [days, setDays] = useState<number>(90);
  const user = useTypedSelector((s) => s.user.user);

  const { data: campaigns, isLoading } = useQuery(
    [QueryKeys.FetchCampaigns, user.Id, days],
    () => fetchCampaigns(user.Id, days),
    {
      enabled: !!user.Id && !!days
    }
  );

  const emailIds = campaigns?.emails.map((e) => e.id);

  const { data, isLoading: totalStatsLoading } = useQuery(
    [QueryKeys.FetchCampaignsStats],
    () => fetchCampaignsStats(emailIds as string[]),
    {
      enabled: !!emailIds
    }
  );

  const totalStats = data || INITIAL_STATS;

  return (
    <>
      <Box style={{ padding: 8 }}>
        <Grid
          alignItems="center"
          container
          justifyContent="flex-start"
          spacing={2}>
          <Grid
            item
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0.8
            }}>
            <MdMarkEmailRead
              style={{
                marginRight: 10,
                fontSize: 50,
                color: 'grey'
              }}
            />
            <Typography
              variant="h3"
              style={{
                color: 'grey'
              }}>{`Email Campaigns`}</Typography>
          </Grid>

          <Grid item>
            <InfoDialog title="Marketing Campaigns Information">
              <Alert severity="info">
                <Typography>
                  Use the Send Email Icon <SendIcon />
                  to Trigger the send of an email broadcast
                </Typography>
                <br />
                <Typography>
                  Once an email has been sent the send email icon will be greyed
                  out <SendIcon style={{ color: 'lightgrey' }} />
                  and you will no longer be able to use it.
                </Typography>
                <br />
                <Typography>
                  The Refresh Icon is used to check the status of an email, if
                  you have recently sent an email you might wish to check if it
                  has been sent from the queue yet.
                </Typography>
              </Alert>
            </InfoDialog>
          </Grid>
          <div style={{ flexGrow: 1 }} />
          <Grid item>
            <FormControl>
              <InputLabel>Period</InputLabel>
              <Select
                inputProps={{ name: 'Days' }}
                native
                onChange={(
                  e: React.ChangeEvent<{
                    name?: string;
                    value: unknown;
                  }>
                ) => setDays(e.target.value as number)}
                value={days}>
                <option value={1}>Within Last Day</option>
                <option value={7}>Within Last Week</option>
                <option value={30}>Within Last Month</option>
                <option value={90}>Within Last 3 Months</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>

      <Box style={{ padding: 8 }}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <EmailsTotal
              backgroundColor="#66CDAA"
              Icon={(props) => (
                <SendIcon className={props.className} style={props.style} />
              )}
              amount={campaigns?.emails_sorted?.triggered?.length || 0}
              title="Sent"
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <EmailsTotal
              backgroundColor="#FFB347"
              Icon={(props) => (
                <TouchAppIcon className={props.className} style={props.style} />
              )}
              amount={campaigns?.emails_sorted?.scheduled?.length || 0}
              title="Scheduled"
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <EmailsTotal
              backgroundColor="#BEBEBE"
              Icon={(props) => (
                <DraftsIcon className={props.className} style={props.style} />
              )}
              amount={campaigns?.emails_sorted?.draft?.length || 0}
              title="Drafts"
            />
          </Grid>
        </Grid>
      </Box>

      <EventStats stats={totalStats} loading={totalStatsLoading} />

      <Box style={{ padding: 8 }}>
        <React.Suspense fallback={<LinearProgress />}>
          <CampaignsTable
            emails={campaigns?.emails || []}
            loading={isLoading}
          />
        </React.Suspense>
      </Box>
    </>
  );
};

export default Dashboard;
