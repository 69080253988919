export const init_data = [
  {
    id: 'japan',
    color: 'hsl(169, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 68
      },
      {
        x: 'helicopter',
        y: 53
      },
      {
        x: 'boat',
        y: 244
      },
      {
        x: 'train',
        y: 183
      },
      {
        x: 'subway',
        y: 184
      },
      {
        x: 'bus',
        y: 249
      },
      {
        x: 'car',
        y: 88
      },
      {
        x: 'moto',
        y: 68
      },
      {
        x: 'bicycle',
        y: 25
      },
      {
        x: 'horse',
        y: 66
      },
      {
        x: 'skateboard',
        y: 50
      },
      {
        x: 'others',
        y: 49
      }
    ]
  },
  {
    id: 'france',
    color: 'hsl(247, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 133
      },
      {
        x: 'helicopter',
        y: 96
      },
      {
        x: 'boat',
        y: 109
      },
      {
        x: 'train',
        y: 56
      },
      {
        x: 'subway',
        y: 268
      },
      {
        x: 'bus',
        y: 166
      },
      {
        x: 'car',
        y: 123
      },
      {
        x: 'moto',
        y: 168
      },
      {
        x: 'bicycle',
        y: 114
      },
      {
        x: 'horse',
        y: 20
      },
      {
        x: 'skateboard',
        y: 53
      },
      {
        x: 'others',
        y: 197
      }
    ]
  },
  {
    id: 'us',
    color: 'hsl(70, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 138
      },
      {
        x: 'helicopter',
        y: 221
      },
      {
        x: 'boat',
        y: 52
      },
      {
        x: 'train',
        y: 106
      },
      {
        x: 'subway',
        y: 198
      },
      {
        x: 'bus',
        y: 165
      },
      {
        x: 'car',
        y: 16
      },
      {
        x: 'moto',
        y: 95
      },
      {
        x: 'bicycle',
        y: 98
      },
      {
        x: 'horse',
        y: 34
      },
      {
        x: 'skateboard',
        y: 197
      },
      {
        x: 'others',
        y: 189
      }
    ]
  },
  {
    id: 'germany',
    color: 'hsl(360, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 14
      },
      {
        x: 'helicopter',
        y: 254
      },
      {
        x: 'boat',
        y: 3
      },
      {
        x: 'train',
        y: 153
      },
      {
        x: 'subway',
        y: 109
      },
      {
        x: 'bus',
        y: 293
      },
      {
        x: 'car',
        y: 141
      },
      {
        x: 'moto',
        y: 245
      },
      {
        x: 'bicycle',
        y: 41
      },
      {
        x: 'horse',
        y: 224
      },
      {
        x: 'skateboard',
        y: 181
      },
      {
        x: 'others',
        y: 101
      }
    ]
  },
  {
    id: 'norway',
    color: 'hsl(78, 70%, 50%)',
    data: [
      {
        x: 'plane',
        y: 257
      },
      {
        x: 'helicopter',
        y: 165
      },
      {
        x: 'boat',
        y: 15
      },
      {
        x: 'train',
        y: 110
      },
      {
        x: 'subway',
        y: 213
      },
      {
        x: 'bus',
        y: 190
      },
      {
        x: 'car',
        y: 55
      },
      {
        x: 'moto',
        y: 79
      },
      {
        x: 'bicycle',
        y: 144
      },
      {
        x: 'horse',
        y: 100
      },
      {
        x: 'skateboard',
        y: 263
      },
      {
        x: 'others',
        y: 112
      }
    ]
  }
];
