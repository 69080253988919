import React, { useState } from 'react';
import {
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  List,
  ListItem,
  Typography,
  Box,
  useTheme,
  Grid,
  CircularProgress,
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { CustomDialog } from 'common/Dialog';
import { EDocumentType } from 'graphql/FileStorageAPI/interface';
import { motion, AnimatePresence } from 'framer-motion';
import {
  CheckCircleOutline,
  Close,
  ErrorOutline,
  CloudUpload,
  Info
} from '@material-ui/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      padding: theme.spacing(2)
    },
    formControl: {
      width: '100%',
      marginTop: theme.spacing(2)
    },
    listItem: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[2],
      overflow: 'hidden',
      padding: theme.spacing(2),
      transition: 'all 0.3s ease',
      border: '2px solid transparent'
    },
    listItemRequired: {
      borderColor: theme.palette.warning.main
    },
    listItemCompleted: {
      borderColor: theme.palette.success.main
    },
    fileName: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(1),
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1)
    },
    select: {
      '&:before': {
        borderColor: theme.palette.primary.main
      },
      '&:after': {
        borderColor: theme.palette.primary.main
      }
    },
    cancelButton: {
      color: theme.palette.text.secondary
    },
    confirmButton: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: theme.spacing(2)
    },
    statusContainer: {
      marginTop: theme.spacing(2)
    },
    statusText: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      fontWight: 'bold',
      marginBottom: theme.spacing(1)
    },
    progressBar: {
      marginTop: theme.spacing(1)
    },
    selectAllContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      backgroundColor: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      boxShadow: theme.shadows[1]
    },
    selectAllInstructions: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    selectAllActions: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2)
    },
    selectAllTypeControl: {
      flexGrow: 1
    },
    applyAllButton: {
      minWidth: 120
    }
  })
);

const DocumentTypeDialog = ({
  isOpen,
  onClose,
  onConfirm,
  files,
  onDocumentTypeChange,
  loading
}: {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  files: {
    file: File;
    documentType: EDocumentType | null;
    status?: string;
  }[];
  onDocumentTypeChange: (index: number, documentType: EDocumentType) => void;
  loading: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const [selectAllType, setSelectAllType] = useState<EDocumentType | ''>('');

  const handleChange =
    (index: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
      onDocumentTypeChange(index, event.target.value as EDocumentType);
    };

  const allTypesSelected = files.every((file) => file.documentType !== null);

  const handleApplyAll = () => {
    if (selectAllType) {
      files.forEach((_, index) => {
        onDocumentTypeChange(index, selectAllType);
      });
    }
  };

  const getStatusColor = (status: string | undefined): string => {
    if (status?.includes('Generating')) return theme.palette.info.main;
    if (status?.includes('Checking')) return theme.palette.warning.main;
    if (status?.includes('Uploaded')) return theme.palette.success.main;
    return theme.palette.text.secondary;
  };

  const getStatusIcon = (status: string | undefined): React.ReactNode => {
    if (status?.includes('Generating')) return <CloudUpload />;
    if (status?.includes('Checking')) return <ErrorOutline />;
    if (status?.includes('Uploaded')) return <CheckCircleOutline />;
    return null;
  };

  return (
    <CustomDialog
      open={isOpen}
      handleClose={onClose}
      alert={{
        title: 'Select Document Types',
        description:
          'Please select the type for each document you are uploading.',
        type: 'info'
      }}
      maxSize="sm">
      <DialogContent className={classes.content}>
        {files.length > 1 && (
          <Box className={classes.selectAllContainer}>
            <Typography
              variant="subtitle1"
              className={classes.selectAllInstructions}>
              <Info color="primary" />
              {`Select a type, then click 'Apply to All' to set it for all documents`}
            </Typography>
            <Box className={classes.selectAllActions}>
              <FormControl className={classes.selectAllTypeControl}>
                <InputLabel id="select-all-type-label">
                  Document Type for All
                </InputLabel>
                <Select
                  labelId="select-all-type-label"
                  id="select-all-type"
                  value={selectAllType}
                  onChange={(e) =>
                    setSelectAllType(e.target.value as EDocumentType)
                  }>
                  {Object.values(EDocumentType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type.replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip
                title={
                  !selectAllType ? 'Please select a document type first' : ''
                }>
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleApplyAll}
                    disabled={!selectAllType}
                    className={classes.applyAllButton}>
                    Apply to All
                  </Button>
                </span>
              </Tooltip>
            </Box>
          </Box>
        )}
        <List>
          <Grid container spacing={2}>
            {files.map((file, index) => (
              <Grid key={index} item xs={12}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}>
                  <ListItem
                    className={`${classes.listItem} ${
                      file.documentType
                        ? classes.listItemCompleted
                        : classes.listItemRequired
                    }`}>
                    <Box width="100%">
                      <Typography
                        className={classes.fileName}
                        variant="subtitle1">
                        {file.documentType ? (
                          <CheckCircleOutline
                            style={{ color: theme.palette.success.main }}
                          />
                        ) : (
                          <ErrorOutline
                            style={{ color: theme.palette.warning.main }}
                          />
                        )}
                        {file.file.name}
                      </Typography>
                      <FormControl className={classes.formControl}>
                        <InputLabel id={`document-type-select-label-${index}`}>
                          Document Type
                        </InputLabel>
                        <Select
                          labelId={`document-type-select-label-${index}`}
                          id={`document-type-select-${index}`}
                          value={file.documentType || ''}
                          onChange={handleChange(index)}
                          className={classes.select}>
                          {Object.values(EDocumentType).map((type) => (
                            <MenuItem key={type} value={type}>
                              {type.replace(/_/g, ' ')}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <AnimatePresence>
                        {file.status && (
                          <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: 'auto' }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.3 }}
                            className={classes.statusContainer}>
                            <Typography
                              variant="body2"
                              className={classes.statusText}
                              style={{ color: getStatusColor(file.status) }}>
                              {getStatusIcon(file.status)}
                              {file.status}
                            </Typography>
                            <LinearProgress
                              variant="indeterminate"
                              className={classes.progressBar}
                              style={{
                                backgroundColor: getStatusColor(file.status)
                              }}
                            />
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </Box>
                  </ListItem>
                </motion.div>
              </Grid>
            ))}
          </Grid>
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className={classes.cancelButton}
          startIcon={<Close />}>
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          className={classes.confirmButton}
          disabled={!allTypesSelected}
          startIcon={
            loading ? <CircularProgress size={20} /> : <CheckCircleOutline />
          }>
          {loading ? 'Uploading...' : 'Confirm'}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default DocumentTypeDialog;
