import img from 'assets/gifs/giphy_1.gif';
import { Typography, Box, Button } from '@material-ui/core';

export const ErrorView = (props) => {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fcoincentral.com%2Fwp-content%2Fuploads%2F2019%2F01%2Ffintech-blockchain.png&f=1&nofb=1")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
      }}>
      <div
        style={{
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          textAlign: 'center'
        }}>
        <img src={img} width="300px" />
        <br />

        <Box style={{ background: `#00000066`, padding: 16, borderRadius: 10 }}>
          <Typography variant="h1" style={{ color: 'white' }}>
            You have been Logged Out
          </Typography>

          <Typography style={{ color: 'white' }}>
            This is likely due to inactivity or an unhandled error
          </Typography>
          <br />
          <Typography style={{ color: 'white' }}>
            The application development team have been informed.
          </Typography>

          <Typography style={{ color: 'white' }}>
            Please try refreshing the page or:
          </Typography>
          <br />
          <Button
            color="primary"
            variant="contained"
            onClick={() => window.location.replace('/')}>
            Log in
          </Button>
          <br />
          <br />
          <Typography style={{ color: 'white' }}>
            If the problem persists or is recurring, please inform your
            administrator
          </Typography>
          <br />
        </Box>

        <br />
        <details style={{ color: 'blue', width: '50vw', textAlign: 'center' }}>
          {props.info.componentStack}
        </details>
      </div>
    </div>
  );
};
