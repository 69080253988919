import {
  GetUserGeneratedDocument,
  GetGeneratedStepSystemDocument
} from 'redux/database';

interface IGetUserDoc {
  token: string;
  FieldInstanceId: number;
  ProcessInstanceId: number;
  ProcessStepDefinitionId: number;
}

export const getUserDoc = async ({
  FieldInstanceId,
  ProcessInstanceId,
  ProcessStepDefinitionId
}: IGetUserDoc) => {
  const res = await GetUserGeneratedDocument({
    FieldInstanceId,
    ProcessInstanceId,
    ProcessStepDefinitionId
  });

  if (res) {
    fetch(res.data.FieldValue).then(async (res) => {
      const file = await res.blob();
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  }
};

interface IGetSysDoc {
  ProcessInstanceId: number;
  ProcessStepDefinitionId: number;
  DocumentStepDefinitionId: number;
  action?: string;
}

/**opens the file url in a new tab*/
export const getSysDoc = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId,
  DocumentStepDefinitionId,
  action
}: IGetSysDoc) => {
  const res = await GetGeneratedStepSystemDocument({
    ProcessInstanceId,
    ProcessStepDefinitionId,
    DocumentStepDefinitionId,
    action
  });

  if (res && res.status === 200) {
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
};

/**returns the file url (for href/etc)*/
export const getSysDocFileUrl = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId,
  DocumentStepDefinitionId,
  action
}: IGetSysDoc) => {
  const res = await GetGeneratedStepSystemDocument({
    ProcessInstanceId,
    ProcessStepDefinitionId,
    DocumentStepDefinitionId,
    action
  });

  if (res && res.status === 200) {
    const file = new Blob([res.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    return fileURL;
  }
};
