import { Message } from '@microsoft/microsoft-graph-types';
import { CardContext } from 'components/MessageHub/context/HoverCardContext';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { IConversation } from 'components/MessageHub/interfaces';
import { createContext, SetStateAction, useContext, useState } from 'react';
import { BugTracker } from 'Utils/Bugtracker';
import { IParentThreadContext } from './interface';

export const ParentThreadContext = createContext<IParentThreadContext>({
  flaggedLoading: [],
  isArchiving: [],
  isRead: [],
  updateFlagStatus: async ({ conversation, newStatus, isBookmarked }) =>
    Promise.resolve(),
  handleArchiveMessage: async ({
    conversation,
    isBookmark,
    setBookmarkedConversations
  }) => Promise.resolve(),
  handleReadMessage: async ({ messageId }) => Promise.resolve(),
  setOpenStatus: () => {},
  openStatus: {}
});
const ParentThreadProvider = ({ children }: { children: React.ReactNode }) => {
  const { getRefreshEmails } = useContext(MessageHubContext);
  const { setFlaggedStatus, archiveMessage, markAsRead } =
    useContext(CardContext);

  const [flaggedLoading, setFlaggedLoading] = useState<string[]>([]);
  const [isArchiving, setIsArchiving] = useState<string[]>([]);
  const [isRead, setIsRead] = useState<string[]>([]);
  const [openStatus, setOpenStatus] = useState({});

  const updateFlagStatus = async ({
    conversation,
    newStatus,
    isBookmarked
  }: {
    conversation: IConversation;
    newStatus: 'flagged' | 'notFlagged' | 'complete';
    isBookmarked: boolean;
  }) => {
    const messageId = conversation?.parentMessage?.id || '';
    setFlaggedLoading((prev) => [...prev, messageId]);

    try {
      await setFlaggedStatus({
        messageId,
        flagStatus: newStatus,
        conversation,
        isBookmarked
      });
    } catch (e) {
      BugTracker.notify(e);
    } finally {
      setFlaggedLoading((prev) => prev.filter((id) => id !== messageId));
    }
  };

  const handleArchiveMessage = async ({
    conversation,
    isBookmark,
    setBookmarkedConversations
  }: {
    conversation: IConversation;
    isBookmark: boolean;
    setBookmarkedConversations: React.Dispatch<SetStateAction<IConversation[]>>;
  }) => {
    const messageId = conversation?.parentMessage?.id || '';
    setIsArchiving((prev) => [...prev, messageId]);

    try {
      if (isBookmark) {
        const currentBookmarked = JSON.parse(
          localStorage.getItem('favorites') || '[]'
        );

        const updatedBookmarked = currentBookmarked.filter(
          (conversation: IConversation) =>
            conversation.parentMessage.id !== messageId
        );

        localStorage.setItem('favorites', JSON.stringify(updatedBookmarked));
        setBookmarkedConversations(updatedBookmarked);
      }

      await archiveMessage({ conversation });
    } catch (e) {
      BugTracker.notify(e);
    } finally {
      setIsArchiving((prev) => prev.filter((id) => id !== messageId));
    }
  };

  const handleReadMessage = async ({ messageId }: { messageId: string }) => {
    setIsRead((prev) => [...prev, messageId]);

    try {
      await markAsRead({
        messageId,
        isRead: true
      });
    } catch (e) {
      BugTracker.notify(e);
    } finally {
      setIsRead((prev) => prev.filter((id) => id !== messageId));
    }
  };

  return (
    <ParentThreadContext.Provider
      value={{
        flaggedLoading,
        isArchiving,
        openStatus,
        isRead,
        setOpenStatus,
        updateFlagStatus,
        handleArchiveMessage,
        handleReadMessage
      }}>
      {children}
    </ParentThreadContext.Provider>
  );
};

export default ParentThreadProvider;
