import { rrulestr, RRule, Frequency, RRuleStrOptions } from 'rrule';
import { IEvent } from '../interface';
import { Component, Property } from 'immutable-ics';

const createICalendarContent = (event: IEvent): string => {
  let newEndData: Date | null = null;
  if (event.end === null && event.extendedProps.rrule) {
    const rruleObj = rrulestr(event.extendedProps.rrule);
    const untilDate = getUntilDateFromRRuleObj(rruleObj);
    if (untilDate) {
      newEndData = untilDate;
    }
  }

  // Structuring Guests For ICS Content
  const attendeesProps = event.extendedProps.guests
    ? event.extendedProps.guests.map(
        (guest) =>
          new Property({
            name: 'ATTENDEE',
            parameters: { CN: guest.title },
            value: `mailto:${guest.UserInstanceEmail}`
          })
      )
    : [];

  const otherProps = [
    event.extendedProps.description &&
      new Property({
        name: 'DESCRIPTION',
        value: event.extendedProps.description
      }),
    event.extendedProps.location &&
      new Property({ name: 'LOCATION', value: event.extendedProps.location })
  ].filter(Boolean);

  console.log({ event });

  const eventProps = [
    new Property({
      name: 'UID',
      value: `${event.extendedProps.calendarOwner}-${event.id}@offkey-ltd.com`
    }),
    new Property({ name: 'SUMMARY', value: event.title }),
    new Property({ name: 'DTSTART', value: formatDateToICS(event.start) }),
    new Property({
      name: 'DTEND',
      value: formatDateToICS(newEndData === null ? event.end : newEndData)
    }),
    new Property({
      name: 'SEQUENCE',
      value: event.extendedProps.sequence || '1'
    }),
    new Property({ name: 'DTSTAMP', value: formatDateToICS(new Date()) }),
    ...attendeesProps,
    ...otherProps
  ];

  const rruleForICal = cleanRRuleForICal(event.extendedProps.rrule || '');
  if (rruleForICal) {
    eventProps.push(new Property({ name: 'RRULE', value: rruleForICal }));
  }

  const vevent = new Component({
    name: 'VEVENT',
    properties: eventProps
  });

  const calendar = new Component({
    name: 'VCALENDAR',
    properties: [
      new Property({ name: 'VERSION', value: '2.0' }),
      new Property({ name: 'PRODID', value: '-//Bips//Calendar//EN' }),
      new Property({ name: 'METHOD', value: 'REQUEST' })
    ],
    components: [vevent]
  });

  return calendar.toString();
};

export default createICalendarContent;

const formatDateToICS = (date: Date | string): string => {
  const validDate = typeof date === 'string' ? new Date(date) : date;
  return validDate.toISOString().replace(/-|:|\.\d+/g, '');
};

const getUntilDateFromRRuleObj = (rruleObj: RRule): Date | null => {
  const rruleOptions = rruleObj.options;
  return rruleOptions.until || null;
};

type MutableRRuleOptions = {
  -readonly [K in keyof RRuleStrOptions]?: RRuleStrOptions[K];
};

const cleanRRuleForICal = (rruleString: string): string => {
  if (rruleString) {
    const rruleObj = rrulestr(rruleString);
    const rruleOptions: MutableRRuleOptions =
      rruleObj.options as MutableRRuleOptions;

    delete rruleOptions.dtstart;

    const cleanedRRule = new RRule(rruleOptions)
      .toString()
      .replace('DTSTART:', '')
      .replace(/;$/, '');

    return cleanedRRule;
  }

  return '';
};
