import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { withStyles, useTheme } from '@material-ui/core';
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid
} from '@material-ui/core';
import {
  ArrowForwardIos as ArrowForwardIosIcon,
  Payment as PaymentIcon,
  PeopleOutlined as PeopleIcon,
  Code as CodeIcon,
  Store as StoreIcon
} from '@material-ui/icons';
import { FaHandshake } from 'react-icons/fa';
import styles from './styles';
import Time from 'common/Time';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomDeletionDialog } from 'common/Dialog';

const NotificationList = (props) => {
  const theme = useTheme();
  const {
    className,
    classes,
    onSelect,
    notifications,
    deleteNotification,
    deleteAllNotifications
  } = props;
  const rootClassName = classNames(classes.root, className);
  const [open, setOpen] = React.useState(false);
  const rmvNotification = (e, notification) => {
    e.stopPropagation();
    e.preventDefault();
    deleteNotification(notification);
  };

  const icons = {
    order: {
      icon: <PaymentIcon />,
      color: 'blue'
    },
    user: {
      icon: <PeopleIcon />,
      color: 'red'
    },
    product: {
      icon: <StoreIcon />,
      color: theme.palette.success.main
    },
    feature: {
      icon: <CodeIcon />,
      color: 'purple'
    },
    deal: {
      icon: <FaHandshake fontSize={24} />,
      color: theme.palette.primary.main
    }
  };

  return (
    <div className={rootClassName}>
      <CustomDeletionDialog
        open={open}
        type="delete"
        setOpen={setOpen}
        confirmedDeleted={() => deleteAllNotifications(notifications)}
        title={'All Notifications'}
      />
      {notifications?.length > 0 ? (
        <Fragment>
          <div className={classes.header}>
            <Typography variant="h6">Notification Centre</Typography>
            <Typography className={classes.subtitle} variant="body2">
              {notifications.length} new notifications
            </Typography>
          </div>
          <div className={classes.content}>
            {notifications?.length > 1 && (
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item style={{ paddingTop: 7 }}>
                  <Typography
                    variant="h6"
                    style={{ padding: 5, paddingLeft: 20 }}>
                    Delete All <b>Notifications</b>?
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setOpen(true)}>
                    <DeleteIcon color="error" />
                  </Button>
                </Grid>
              </Grid>
            )}
            <List component="div">
              {notifications.map((notification) => (
                <Link key={notification.id} to="#">
                  <ListItem
                    className={classes.listItem}
                    component="div"
                    onClick={() => onSelect(notification)}>
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ color: icons[notification.type].color }}>
                      {icons[notification.type].icon}
                    </ListItemIcon>
                    <ListItemText
                      classes={{ secondary: classes.listItemTextSecondary }}
                      primary={<Typography>{notification.msg}</Typography>}
                      secondary={<Time time={notification.ts} type="timeago" />}
                    />
                    <Button onClick={(e) => rmvNotification(e, notification)}>
                      <DeleteIcon color="error" />
                    </Button>

                    <ArrowForwardIosIcon className={classes.arrowForward} />
                  </ListItem>
                  <Divider />
                </Link>
              ))}
            </List>
            {/* <div className={classes.footer}>
              <Button
                color="primary"
                component={Link}
                size="small"
                to="#"
                variant="contained">
                See all
              </Button>
            </div> */}
          </div>
        </Fragment>
      ) : (
        <div className={classes.empty}>
          <div className={classes.emptyImageWrapper}>
            <img
              alt="Empty list"
              className={classes.emptyImage}
              src="/images/empty.png"
            />
          </div>
          <Typography variant="h4">{`There's nothing here...`}</Typography>
        </div>
      )}
    </div>
  );
};

export default withStyles(styles)(NotificationList);
