import { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { OffkeyCompanyLite, SearchType } from './interfaces';
import { useCreditSafe, useRedFlagGQLAdaptor } from './adaptors';
import { useTypedSelector } from 'redux/reducers';
import { getCrmToken } from 'redux/actions/GraphqlCrmActions';
import { Grid, Radio } from '@material-ui/core';

import { Typography, useTheme } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { CompanyList, CompanySearch } from './components/Company';
import { DirectorList, DirectorSearch } from './components/Director';
import {
  IRedflagDirectorArray,
  IRedFlagDirectorship,
  IRedFlagDirectorshipData
} from './helper';
import { RedflagClient } from 'App';
import { IRedflagAPIData } from 'types/redflagInterfaces';
import { useCompanyAndDirectorSearch } from './hooks/useCompanyAndDirectorSearch';

const { Company, Director } = SearchType;

export const OffkeyCompanyAndDirectorSearch: ({
  onSelect,
  setDirectorship,
  setLoading,
  setSearchType,
  setParentCompany,
  searchType,
  isNewRecord,
  lock
}: {
  onSelect: (result: any) => void;
  setDirectorship: Dispatch<SetStateAction<IRedFlagDirectorship | undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchType: Dispatch<SetStateAction<SearchType>>;
  setParentCompany?: Dispatch<React.SetStateAction<IRedflagAPIData>>;
  searchType: SearchType;
  isNewRecord: boolean;
  lock: SearchType | 'both' | undefined;
}) => JSX.Element = ({
  onSelect,
  setLoading,
  setDirectorship,
  setSearchType,
  setParentCompany,
  searchType,
  isNewRecord,
  lock
}) => {
  const theme = useTheme();
  const { companyNavigator } = useTypedSelector((s) => s.config);
  const graphqlCrmState = useTypedSelector((s) => s.graphqlCrm);
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState<OffkeyCompanyLite[] | null>(null);
  const [directors, setDirectors] = useState<IRedflagDirectorArray | null>(
    null
  );
  const [localLoading, setLocalLoading] = useState(false);

  const { getCompanyReport } = useCreditSafe();
  const {
    getRFGQLCompanyList,
    getRFGQLCompany,
    getRFGQLDirector,
    getRFGQLDirectorList
  } = useRedFlagGQLAdaptor(RedflagClient);

  const {
    query,
    handleCompanySearch,
    companySearch,
    companyClear,
    directorSearch,
    handleDirectorSearch
  } = useCompanyAndDirectorSearch({
    setParentCompany,
    companyNavigator,
    setLocalLoading,
    setCompanies,
    setDirectors,
    getRFGQLCompanyList,
    getRFGQLDirectorList
  });

  const loadGraphqlToken = () => {
    if (!graphqlCrmState.loaded || graphqlCrmState.willExpire <= Date.now()) {
      getCrmToken(dispatch);
    }
  };

  useEffect(() => {
    if (companyNavigator === 'RedflagGRaphQL') {
      loadGraphqlToken();
    }
  }, []);

  const getCompany = async (company: OffkeyCompanyLite) => {
    setCompanies(null);
    setLoading(true);

    if (companyNavigator === 'CreditSafe') {
      const result = await getCompanyReport(company.company_number);
      onSelect(result);
    }

    if (companyNavigator === 'RedflagGRaphQL') {
      const result = await getRFGQLCompany(company.redflag_GraphQL_id);

      console.log({ result });
      onSelect(result);
    }

    setLoading(false);
  };

  const getDirector = async (director: IRedFlagDirectorshipData) => {
    setDirectors(null);
    setLoading(true);

    if (companyNavigator === 'RedflagGRaphQL') {
      const result = await getRFGQLDirector(director.id);
      setDirectorship(result);
    }

    setLoading(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} style={{ paddingBottom: theme.spacing(1) }}>
        {searchType === Company ? (
          <SearchInfo searchType={Company} />
        ) : (
          <SearchInfo searchType={Director} />
        )}
      </Grid>

      <Grid container direction="row" item xs={1}>
        <Grid
          item
          style={{
            marginBottom: theme.spacing(0.8),
            marginTop: theme.spacing(0.8)
          }}>
          <Radio
            disabled={isNewRecord && lock === Company}
            checked={searchType === Company}
            onChange={() => {
              setCompanies(null);
              setDirectors(null);
              setSearchType(Company);
            }}
            value="company"
            name="radio-buttons"
            inputProps={
              {
                'aria-label': Company,
                'data-cy': 'company-search-radio-btn'
              } as any
            }
          />
        </Grid>

        <Grid
          item
          style={{
            marginBottom: theme.spacing(0.8),
            marginTop: theme.spacing(0.8)
          }}>
          <Radio
            disabled={lock === Director}
            checked={searchType === Director}
            onChange={() => {
              setCompanies(null);
              setDirectors(null);
              setSearchType(Director);
            }}
            value="director"
            name="radio-buttons"
            inputProps={
              {
                'aria-label': Director,
                'data-cy': 'director-search-radio-btn'
              } as any
            }
          />
        </Grid>
      </Grid>

      {searchType === Director && (
        <DirectorSearch
          handleSearch={handleDirectorSearch}
          query={query}
          search={directorSearch}
        />
      )}

      {searchType === Company && (
        <CompanySearch
          handleSearch={handleCompanySearch}
          query={query}
          search={companySearch}
          clear={companyClear}
        />
      )}

      <Grid item xs={12}>
        {searchType === Company ? (
          <CompanyList
            companies={companies}
            localLoading={localLoading}
            getCompany={getCompany}
          />
        ) : (
          <DirectorList
            directors={directors}
            localLoading={localLoading}
            getDirector={getDirector}
          />
        )}
      </Grid>
    </Grid>
  );
};

const SearchInfo = ({
  searchType
}: {
  searchType: SearchType;
}): JSX.Element => {
  const info = {
    [Company]: {
      title: 'Company Selected',
      description:
        'Search for a company to see detailed information such as company status, registration details, historical data, and more.'
    },
    [Director]: {
      title: 'Director Selected',
      description: `Search for a director by "First Name" or click "Advance Filter" for more options after searching to see information such as their profile, appointment history, current positions, and associated companies.`
    }
  };

  return (
    <Alert severity="info" style={{ width: '100%' }}>
      <AlertTitle>
        <b>{info[searchType].title}</b>
      </AlertTitle>
      <Typography>{info[searchType].description}</Typography>
    </Alert>
  );
};
