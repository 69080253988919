import { Button } from '@material-ui/core';
import { CircularProgress, DialogActions, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';

import { CustomDialog } from 'common/Dialog';
import { upsertContacts } from '../../../functions';
import { Contact } from 'types/crmTypes';
import { validateEmail } from 'helpers/validateEmail';
import ContactChips from './ContactChips';
import { UserInstance } from 'types/interfaces';
import { SuccessButton } from 'common';
import { QueryKeys } from '../../../../types';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedContacts: UserInstance[];
}

const SelectedContactsDialog = ({ open, setOpen, selectedContacts }: Props) => {
  const { listId } = useParams() as { listId: string };
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutate, isLoading } = useMutation(upsertContacts, {
    onSuccess: async () => {
      await queryClient.refetchQueries([QueryKeys.FetchListContacts, listId]);

      history.push(`/marketing/lists/${listId}`, {
        showCreateCampaignInfo: true
      });
    }
  });

  const submit = () => {
    const contacts: Contact[] = [];

    selectedContacts.forEach((i: UserInstance) => {
      const { UserInstanceEmail, Title, Id } = i;

      if (validateEmail(UserInstanceEmail)) {
        contacts.push({
          custom_fields: { e5_N: +Id },
          UserInstanceId: +Id,
          email: UserInstanceEmail,
          first_name: Title
        });
      }
    });

    mutate({
      list_ids: [listId],
      contacts
    });
  };

  const amountSelected = selectedContacts.length;
  const disabled = amountSelected < 1;

  return (
    <CustomDialog
      handleClose={() => setOpen(false)}
      open={open}
      maxSize={amountSelected > 3 ? 'md' : 'sm'}>
      <div style={{ padding: 25 }}>
        <Typography variant="h4" gutterBottom>
          {`${amountSelected} Selected Contact(s)`}
        </Typography>
        <ContactChips
          selectedContactEmails={selectedContacts.map(
            (c) => c.UserInstanceEmail
          )}
        />
        <DialogActions
          style={{
            padding: 0,
            display: 'flex',
            justifyContent: 'space-between'
          }}>
          <Button
            color="primary"
            onClick={() => setOpen(false)}
            variant="contained">
            Close
          </Button>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <SuccessButton
              color="secondary"
              disabled={disabled}
              onClick={submit}
              variant="contained">
              Submit
            </SuccessButton>
          )}
        </DialogActions>
      </div>
    </CustomDialog>
  );
};

export default SelectedContactsDialog;
