import { Calculation } from 'types/calculatorInterfaces';
import { FunderDoc } from '../../interfaces';

const isLease = (product: string) => product.toLowerCase().startsWith('lease');

const FunderFinanceCharges = ({
  FUNDER_DOC,
  Output
}: {
  FUNDER_DOC: FunderDoc;
  Output: Calculation;
}) => {
  FUNDER_DOC.INTEREST_CHARGES_AMOUNT = Output['Interest'];
  FUNDER_DOC.INTEREST_CHARGES_VAT = 0;
  FUNDER_DOC.INTEREST_CHARGES_TOTAL = Output['Interest'];

  FUNDER_DOC.DOCUMENTATION_FEE_AMOUNT = Output['_DocumentFee']?.DocFee;
  FUNDER_DOC.DOCUMENTATION_FEE_VAT = Output['_DocumentFee']?.DocFee_Vat;

  if (Output['_NetAdvance']?.VatDeferral > 0) {
    FUNDER_DOC.DOCUMENTATION_FEE_TOTAL = Output['_DocumentFee']?.TotalDocFee;
  } else {
    FUNDER_DOC.DOCUMENTATION_FEE_TOTAL =
      Output['_DocumentFee']?.TotalDocFee + Output['_DocumentFee']?.DocFee_Vat;
  }

  FUNDER_DOC.OPTION_TO_PURCHASE_AMOUNT = Output['_DocumentFee']?.OptionFee;
  FUNDER_DOC.OPTION_TO_PURCHASE_VAT = Output['_DocumentFee']?.OptionFee_Vat;

  // If VAT Payed UpFront Then Do this
  if (Output['_NetAdvance']?.VatDeferral > 0) {
    FUNDER_DOC.OPTION_TO_PURCHASE_TOTAL =
      Output['_DocumentFee']?.TotalOptionFee;
  } else {
    FUNDER_DOC.OPTION_TO_PURCHASE_TOTAL =
      Output['_DocumentFee']?.TotalOptionFee +
      Output['_DocumentFee']?.OptionFee_Vat;
  }
  FUNDER_DOC.TOTAL_CHARGE_FOR_FINANCE_AMOUNT =
    Output['Interest'] +
    Output['_DocumentFee']?.DocFee +
    Output['_DocumentFee']?.OptionFee;

  FUNDER_DOC.TOTAL_CHARGE_FOR_FINANCE_VAT =
    Output['_DocumentFee']?.DocFee_Vat + Output['_DocumentFee']?.OptionFee_Vat;

  const totalVAT =
    Output['_DocumentFee']?.OptionFee_Vat + Output['_DocumentFee']?.DocFee_Vat;
  if (Output['_NetAdvance']?.VatDeferral > 0) {
    FUNDER_DOC.TOTAL_CHARGE_FOR_FINANCE_TOTAL =
      Output['Interest'] +
      Output['_DocumentFee']?.TotalDocFee +
      Output['_DocumentFee']?.TotalOptionFee;
  } else {
    FUNDER_DOC.TOTAL_CHARGE_FOR_FINANCE_TOTAL =
      Output['Interest'] +
      Output['_DocumentFee']?.TotalDocFee +
      Output['_DocumentFee']?.TotalOptionFee +
      totalVAT;
  }

  if (Output['_NetAdvance']?.VatDeferral > 0) {
    FUNDER_DOC.TOTAL_AMOUNT_PAYABLE_INC_VAT =
      Output['Interest'] +
      Output['_DocumentFee']?.TotalDocFee +
      Output['_DocumentFee']?.TotalOptionFee +
      FUNDER_DOC.I_COST_INC_VAT;
  } else {
    if (isLease(FUNDER_DOC.FINANCE_PRODUCT)) {
      FUNDER_DOC.TOTAL_AMOUNT_PAYABLE_INC_VAT =
        Output['Interest'] +
        Output['_DocumentFee']?.TotalDocFee +
        Output['_DocumentFee']?.TotalOptionFee +
        FUNDER_DOC.I_COST_INC_VAT;
    } else {
      FUNDER_DOC.TOTAL_AMOUNT_PAYABLE_INC_VAT =
        Output['Interest'] +
        Output['_DocumentFee']?.TotalDocFee +
        Output['_DocumentFee']?.TotalOptionFee +
        totalVAT +
        FUNDER_DOC.I_COST_INC_VAT;
    }
  }

  return FUNDER_DOC;
};

export default FunderFinanceCharges;
