import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import {
  IAccountDataResult,
  IFraudCheckResult
} from 'graphql/FileStorageAPI/interface';
import {
  GET_DATA_RESULTS,
  GET_FRAUD_RESULTS
} from 'graphql/FileStorageAPI/queries';
import { BugTracker } from 'Utils/Bugtracker';

/**
 * Fetches fraud check results for a given collection ID.
 * @param {string} collectionId - The ID of the collection to check for fraud.
 * @returns {Promise<{ data: IFraudCheckResult; loading: boolean } | ApolloError>} - A promise resolving to the fraud check results or an ApolloError.
 */
export const getFraudResults = async ({
  collectionId
}: {
  collectionId: string;
}): Promise<{ data: IFraudCheckResult; loading: boolean } | ApolloError> => {
  try {
    const { data, errors } = await apolloClient.query({
      query: GET_FRAUD_RESULTS,
      variables: { collectionId }
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    return { data: data.getFraudResults, loading: false };
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Fetches account data results for a given collection ID.
 * @param {string} baseUrl - The base URL for the API.
 * @param {string} collectionId - The ID of the collection to retrieve account data for.
 * @returns {Promise<{ data: IAccountDataResult; loading: boolean } | ApolloError>} - A promise resolving to the account data results or an ApolloError.
 */
export const getDataResults = async ({
  baseUrl,
  collectionId
}: {
  baseUrl: string;
  collectionId: string;
}): Promise<{ data: IAccountDataResult; loading: boolean } | ApolloError> => {
  try {
    const { data, errors } = await apolloClient.query({
      query: GET_DATA_RESULTS,
      variables: { baseUrl, collectionId }
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    return { data: data.getDataResults, loading: false };
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};
