import {
  CompleteProcessDefinition,
  CompleteProcessInstance,
  ProcessStepGroup,
  UserInstance
} from 'types/interfaces';

import { getStepData } from 'redux/actions/processes/getStepData';
import {
  submitProcessStep,
  getProcessDefinition
} from 'redux/actions/processes';

interface IStepper {
  token: string;
  currentDeal: CompleteProcessInstance;
  currentProcess: CompleteProcessDefinition;
  parsed: any;
}

interface ISetup extends IStepper {
  landingPage: boolean;
  HandleUpdateReqCmp: () => void;
  ProcessStepDefinitionSequence: number[];
  ProcessGroupList: ProcessStepGroup[];
  ProcessStepDefinitionId: number;
  user: UserInstance;

  setStepGroupIndex: (stepGroupIndex: number) => void;
  setActiveDealStep: (landingStepIndex: number) => void;
  setActiveStep: (landingStepIndex: number) => void;
  setLandingStep: (landingStepIndex: number | null) => void;
}

interface ISubmitRule extends IStepper {
  selected: any;
  ProcessStepDefinitionTitle: string | undefined;
  currentStepId: number | string;

  setLoadNextStepSelect: (loadingNext: boolean) => void;
  setSuccess: (success: boolean) => void;
  setSelected: (selected: boolean | null) => void;
  setComplete: (completed: boolean) => void;
}

export const StepSetUp = async (props: ISetup) => {
  const {
    landingPage,
    HandleUpdateReqCmp,
    ProcessStepDefinitionSequence,
    parsed,
    ProcessGroupList,
    setStepGroupIndex,
    setActiveDealStep,
    setActiveStep,
    setLandingStep,
    ProcessStepDefinitionId,
    user,
    currentProcess,
    token,
    currentDeal
  } = props;

  if (landingPage) {
    HandleUpdateReqCmp();

    // Index in the ProcessStepDefinitionSequence
    const landingstepIndex: number = ProcessStepDefinitionSequence.findIndex(
      (el: number) => el === parseInt(parsed.psdid)
    );

    // Id of the active step
    const activeStep: number | undefined = ProcessStepDefinitionSequence.find(
      (el: number) => el === parseInt(parsed.psdid)
    );

    // Set Parent Group Tab
    if (ProcessGroupList.length > 0) {
      ProcessGroupList.forEach((StepGroup: any) => {
        const selected = StepGroup?.ProcessStepDefinitionIdList.find(
          (i: number) => i === activeStep
        );
        const stepGroupIndex: number = ProcessGroupList.findIndex(
          (i) => i.Id === StepGroup.Id
        );
        if (selected) setStepGroupIndex(stepGroupIndex);
      });
    }

    setActiveDealStep(landingstepIndex);
    setActiveStep(landingstepIndex);
    return setLandingStep(landingstepIndex);
  } else {
    //! We need to get the Discovery Active Step Instead Of Passing a -3, -2, -1 or 0,
    //! Therefore we will get ProcessStepDefinitionSequence[0]

    const isDeactivated = (): number => {
      const deactivatedTypes = [0, -1, -2, -3];
      if (deactivatedTypes.includes(ProcessStepDefinitionId)) {
        const deactivatedStep = ProcessStepDefinitionSequence[0];
        return deactivatedStep;
      }

      return ProcessStepDefinitionId;
    };

    if (ProcessStepDefinitionId) {
      const stepID = isDeactivated();

      // Index in the ProcessStepDefinitionSequence
      let activeStepIndex: number = ProcessStepDefinitionSequence.findIndex(
        (el: number) => el === stepID
      );

      // Id of the active step
      let activeStep: number | undefined = ProcessStepDefinitionSequence.find(
        (el: number) => el === stepID
      );

      // If the user is lower than a system user
      const isNotSystemUser = user.SystemAccess < 4;
      if (isNotSystemUser) {
        const CompleteProcessStepDefinitionDict =
          currentProcess.CompleteProcessStepDefinitionDict;
        const usersFirstAssignedStep: any = Object.keys(
          CompleteProcessStepDefinitionDict
        ).find((key: any) => {
          const {
            ProcessStepDefinition: { UserDefinitionId }
          } = CompleteProcessStepDefinitionDict[key];
          return UserDefinitionId === user.UserDefinitionId;
        });

        // [PORTAL USER] Index in the ProcessStepDefinitionSequence
        if (usersFirstAssignedStep) {
          activeStepIndex = ProcessStepDefinitionSequence.findIndex(
            (el: number) => el === parseInt(usersFirstAssignedStep)
          );
        }

        // [PORTAL USER] Id of the active step
        activeStep = ProcessStepDefinitionSequence.find(
          (el: number) => el === parseInt(usersFirstAssignedStep)
        );
      }

      // Set Parent Group Tab
      if (ProcessGroupList.length > 0) {
        ProcessGroupList.forEach((StepGroup: any) => {
          const selected = StepGroup?.ProcessStepDefinitionIdList.find(
            (i: number) => i === activeStep
          );
          const stepGroupIndex = ProcessGroupList.findIndex(
            (i) => i.Id === StepGroup.Id
          );
          if (selected) setStepGroupIndex(stepGroupIndex);
        });
      }

      if (activeStep === undefined) {
        return;
      }

      await getStepData({
        index: activeStep,
        ProcessInstanceId: currentDeal.ProcessInstance.Id,
        ProcessDefinitionId: currentProcess?.ProcessDefinition?.Id
      });

      // Sub Tab
      setActiveDealStep(activeStepIndex);

      // Data - Hiding this because we need to be able to edit non active steps for email personalisations
      return setActiveStep(activeStepIndex);
    }
  }
};

export const SubmittingRule = async (props: ISubmitRule) => {
  const {
    token,
    currentDeal,
    currentProcess,
    parsed,
    selected,
    currentStepId,
    setLoadNextStepSelect,
    setComplete,
    setSuccess,
    setSelected,
    ProcessStepDefinitionTitle
  } = props;

  let submitDeal: any = {};
  let submitToken = token;
  submitDeal.ProcessInstance = currentDeal.ProcessInstance;

  // swap the url psdid in the submit object if we are on the landing page
  if (parsed) submitDeal.ProcessInstance.ProcessStepDefinitionId = parsed.psdid;

  if (selected) {
    const NextStepUserInstanceIdDict = {};
    const selectedIsArray = Array.isArray(selected);

    if (selectedIsArray) {
      selected.forEach(
        (item) => (NextStepUserInstanceIdDict[item.value] = item.label)
      );
    } else {
      NextStepUserInstanceIdDict[selected.value] = selected.label;
    }
    submitDeal.NextStepUserInstanceIdDict = NextStepUserInstanceIdDict;
  }

  const submitObj: any = {
    token,
    ProcessDefinitionId: submitDeal.ProcessInstance.ProcessDefinitionId
  };
  submitToken = await getProcessDefinition(submitObj);

  /**
   * SUBMIT THE ACTUAL STEP
   */
  const processSubmitted = await submitProcessStep({
    token: submitToken,
    submitDeal
  });

  if (processSubmitted) {
    setLoadNextStepSelect(true);
    // if the next steps "ProcessStepSelectionType" exists show the
    if (ProcessStepDefinitionTitle === 'Complete') {
      Object.values(currentProcess.ProcessStepGroupDict).map((step: any) => {
        if (step.Title === 'Completion') {
          const currentProcessStepID = step.ProcessStepDefinitionIdList;

          currentProcessStepID.forEach((Id) => {
            const grabCompleteId =
              currentProcess.CompleteProcessStepDefinitionDict[Id];

            if (Id === currentStepId) {
              if (grabCompleteId.ProcessStepDefinition.Title === 'Complete') {
                return setComplete(true);
              }
            }
          });
        } else return setComplete(false);
      });
    }

    setSuccess(true);
  }

  setLoadNextStepSelect(false);
  setSelected(null);
};
