import { IEmailComposition } from 'components/MessageHub/interfaces';
import { useContext, useEffect, useState } from 'react';
import ComposeEmail from './components/ComposeEmail';
import {
  EmailCompositionCreationContext,
  EmailCompositionProvider
} from './context/ComposeWindowsContext';

export const ParentComponentComposeWindows = () => {
  const { composeWindows, setComposeWindows, removeComposeEmail } = useContext(
    EmailCompositionCreationContext
  );

  const [offsets, setOffsets] = useState<number[]>([]);

  const minimizedWidth = 300;
  const expandedWidth = 600;

  const calculateTotalOffset = (index) => {
    let totalOffset = 50;
    for (let i = 0; i < index; i++) {
      totalOffset += offsets[i] || minimizedWidth;
    }
    return totalOffset;
  };

  useEffect(() => {
    setOffsets(
      composeWindows.map((panel) =>
        panel.isMinimized ? minimizedWidth : expandedWidth
      )
    );
  }, [composeWindows]);

  return (
    <EmailCompositionProvider>
      {composeWindows.map((panel, index) => (
        <div key={index}>
          <ComposeEmail
            index={index}
            totalOffset={calculateTotalOffset(index)}
            isMinimized={panel.isMinimized}
            setIsMinimized={(isMinimized) => {
              setComposeWindows((prevComposeWindows) => {
                const updatedComposeWindows = [...prevComposeWindows];
                if (updatedComposeWindows.length > index && index >= 0) {
                  const updatedPanel = {
                    ...updatedComposeWindows[index],
                    isMinimized: isMinimized
                  };
                  updatedComposeWindows[index] = updatedPanel;
                  return updatedComposeWindows;
                } else {
                  return prevComposeWindows;
                }
              });
            }}
            removeComposeEmail={() => removeComposeEmail(index)}
          />
        </div>
      ))}
    </EmailCompositionProvider>
  );
};
