import React from 'react';
import { useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import { useHistory } from 'react-router-dom';

export default function UserActionBtn({ signout }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const user = useSelector((s) => s.user.user);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    anchorRef.current?.focus();
  }, [open]);

  return (
    <div>
      {user.ProfilePicture === '' ? (
        <IconButton
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleOpen}>
          <Avatar>
            {`${user.Title}`
              .split(' ')
              .map((n) => n[0])
              .join('')
              .toUpperCase()}
          </Avatar>
        </IconButton>
      ) : (
        <IconButton onClick={handleOpen} ref={anchorRef}>
          <img
            src={user.ProfilePicture}
            height="40"
            width="40"
            style={{ borderRadius: '50%' }}
          />
        </IconButton>
      )}
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: placement === 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow">
                  <MenuItem
                    onClick={() => history.push('/profile')}
                    disabled={process.env.REACT_APP_ENV === 'production'}>
                    <Typography color="textPrimary">Profile</Typography>
                  </MenuItem>
                  {user.SystemAccess >= 10 && (
                    <MenuItem onClick={() => history.push('/admin')}>
                      <Typography color="textPrimary">Admin</Typography>
                    </MenuItem>
                  )}
                  <MenuItem onClick={signout}>Logout</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
