import React from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography
} from '@material-ui/core';
import uuid from 'react-uuid';
import Fuse from 'fuse.js';
import { ISimpleUserCreate } from '../functions/interfaces';
import { HandleValidEmail, HandleValidTitle } from './index';
import { IContactDoNotDeal } from 'hooks/useDoNotDealNonRedFlag';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

export const SimpleUserCreate = (props: ISimpleUserCreate) => {
  const {
    handleChange,
    newUser,
    UserDefinition,
    userType,
    selectedRadio,
    extremeCautionList
  } = props;
  // const extremeCautionList = useSelector((state: RootState) =>
  //   state.dndReducer.dndusers.filter((user) => 'email' in user)
  // ) as IContactDoNotDeal[];
  const [emailFuse, setEmailFuse] =
    React.useState<Fuse<IContactDoNotDeal> | null>(null);
  const [nameFuse, setNameFuse] =
    React.useState<Fuse<IContactDoNotDeal> | null>(null);
  const [isEmailSimilar, setIsEmailSimilar] = React.useState<boolean>(false);
  const [isNameSimilar, setIsNameSimilar] = React.useState<boolean>(false);
  const [similarEmails, setSimilarEmails] = React.useState<IContactDoNotDeal[]>(
    []
  );
  const [similarNames, setSimilarNames] = React.useState<IContactDoNotDeal[]>(
    []
  );

  React.useEffect(() => {
    if (userType === 'Entity') handleChange('UserInstanceEmail', uuid());
    if (extremeCautionList.length > 0) {
      const emailOptions = {
        keys: ['email'],
        threshold: 0.4
      };
      const nameOptions = {
        keys: ['name'], // Assuming there's a 'name' field in IContactDoNotDeal
        threshold: 0.4
      };
      setEmailFuse(new Fuse(extremeCautionList, emailOptions));
      setNameFuse(new Fuse(extremeCautionList, nameOptions));
    }
  }, [extremeCautionList]);

  const checkSimilarity = (
    fuse: Fuse<IContactDoNotDeal> | null,
    value: string
  ) => {
    if (!fuse || value.length < 3) return [];
    const results = fuse.search(value);
    return results.map((result) => result.item);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    handleChange('UserInstanceEmail', newEmail);
    const similarEmails = checkSimilarity(emailFuse, newEmail);
    setSimilarEmails(similarEmails);
    setIsEmailSimilar(similarEmails.length > 0);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value;
    handleChange('Title', newName);
    const similarNames = checkSimilarity(nameFuse, newName);
    setSimilarNames(similarNames);
    setIsNameSimilar(similarNames.length > 0);
  };

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid data-cy="enter-name-input" item xs={6}>
        <TextField
          fullWidth
          margin="dense"
          name="Title"
          onChange={handleNameChange}
          placeholder="Enter name ..."
          type="text"
          value={newUser.Title}
          variant="standard"
          error={isNameSimilar}
          helperText={
            isNameSimilar ? (
              <Typography style={{ color: 'red' }}>
                This name is similar to one on the extreme caution list
              </Typography>
            ) : (
              ''
            )
          }
        />
        {HandleValidTitle(newUser)}
        {similarNames.length > 0 && (
          <div>
            <Typography>Similar names found:</Typography>
            <List>
              {similarNames.map((entry, index) => (
                <ListItem key={index}>
                  <ListItemText primary={entry.name} />
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </Grid>

      {userType !== 'Entity' && (
        <Grid data-cy="enter-email-input" item xs={6}>
          <TextField
            disabled={
              selectedRadio?.title === 'email' ? false : userType === 'Entity'
            }
            fullWidth
            margin="dense"
            name="UserInstanceEmail"
            onChange={handleEmailChange}
            placeholder="Enter email ..."
            type="email"
            value={
              selectedRadio?.title === 'token'
                ? selectedRadio.stateString
                : newUser.UserInstanceEmail
            }
            variant="standard"
            error={isEmailSimilar}
            helperText={
              isEmailSimilar ? (
                <Typography style={{ color: 'red' }}>
                  This email is similar to one on the extreme caution list
                </Typography>
              ) : (
                ''
              )
            }
          />
          {HandleValidEmail(UserDefinition, newUser, selectedRadio)}
          {similarEmails.length > 0 && (
            <div>
              <Typography>Similar emails found:</Typography>
              <List>
                {similarEmails.map((entry, index) => (
                  <ListItem key={index}>
                    <ListItemText primary={entry.email} />
                  </ListItem>
                ))}
              </List>
            </div>
          )}
        </Grid>
      )}
    </Grid>
  );
};
