import axios from 'axios';
import { createNotification } from 'react-redux-notify';

import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import { errorNotif, successNotif } from 'components/Notifications';
import { store } from 'redux/store';

const { dispatch } = store;

export const deleteCampaign = async (id: string): Promise<void> => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();

    const url = `${process.env.REACT_APP_PROXY}/marketing/campaign/${id}`;
    const config = { headers: { token } };

    await axios.delete(url, config);

    dispatch(createNotification(successNotif(`Campaign Deleted`)));
  } catch (e) {
    BugTracker.notify(e);
    console.log('deleteCampaign Error: ', { e });

    dispatch(createNotification(errorNotif(`Failed to delete Campaign`)));
  }
};
