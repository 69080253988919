import React, { useContext, createContext } from 'react';
import { Button, Grid, ButtonGroup } from '@material-ui/core';
import { addUserToGroup } from '../functions';
import { StyledSelect } from 'common/StyledSelect';
import { MessageHubProvider } from '../';
import { IUserDefinition } from '../interfaces';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { UserInstance } from 'types/interfaces';
import { useTypedSelector } from 'redux/reducers';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { useProcess } from 'hooks/useProcess';

export { default as Contacts } from './Contacts';
export { default as Inbox } from './Inbox';
export { default as EmailEditor } from './TextTyper';
export { default as Conversation } from './Conversation';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    segmentHeader: {
      background: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      margin: 1,
      padding: `2px ${theme.spacing(2)}px `,
      borderRadius: theme.shape.borderRadius
    },
    user: {
      background: `${theme.palette.primary.light}99`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      margin: 2,
      overflowWrap: 'break-word'
    },
    userTitle: {
      display: 'flex',
      alignItems: 'center'
    },
    selected: {
      background: `red`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      color: theme.palette.primary.contrastText,
      margin: 2,
      overflowWrap: 'break-word'
    }
  })
);

export const AddNewUserToGroup = () => {
  const [userOption, setUserOptions] = React.useState(null);
  const classes = useStyles();
  const { ProcessInstanceId, token } = useProcess();
  const { Id } = useTypedSelector((s) => s.user.user);
  const MessageProvider = useContext(MessageHubProvider);
  const { selectedThreadKey } = MessageProvider;
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => setOpen(!open);
  const [addNewEntity, setAddNewEntity] = React.useState(false);
  const [state, setState] = React.useState<{
    UserDefinition: null | IUserDefinition;
    UserInstance: null | UserInstance;
  }>({
    UserDefinition: null,
    UserInstance: null
  });
  const UserDefinitionList = useTypedSelector(
    (s) => s.config.settings.UserDefinitionList
  );

  const handleClear = () => {
    setState({
      UserDefinition: null,
      UserInstance: null
    });
    setOpen(false);
  };

  const addSelectedUserToGroup = () => {
    if (state && state.UserInstance && selectedThreadKey) {
      addUserToGroup(selectedThreadKey, state.UserInstance.Id.toString());
    }
  };

  const handleChange = (v, name) => {
    switch (name) {
      case 'UserInstance': {
        setState({ ...state, [name]: v });
        break;
      }
      default:
        return setState({ ...state, ['UserInstance']: null, [name]: v });
    }
  };

  const getUserInstances = async () => {
    const users = await getUserInstanceSummaryList({
      token,
      UserDefinitionId: state?.UserDefinition?.value
    });
    const options: any = [];
    Object.values(users.data).forEach(({ UserInstance }: any) => {
      UserInstance.value = UserInstance.Id;
      UserInstance.label = UserInstance.Title;
      options.push(UserInstance);
    });
    setUserOptions(options);
  };

  React.useEffect(() => {
    if (state.UserDefinition) getUserInstances();
  }, [state.UserDefinition]);

  const StyledSelectUserDefinitionProps = {
    onChange: (v) => handleChange(v, 'UserDefinition'),
    options: list(UserDefinitionList),
    placeholder: 'Select A User Group',
    value: state.UserDefinition,
    useMaterialUI: false
  };

  const StyledSelectUserInstanceProps = {
    onChange: (v) => handleChange(v, 'UserInstance'),
    options: userOption,
    placeholder: 'Choose User...',
    value: state.UserInstance,
    useMaterialUI: false
  };

  if (open) {
    if (!UserDefinitionList) {
      return <div>Loading ...</div>;
    } else {
      return (
        <Grid container direction="column" style={{ width: 500 }} spacing={1}>
          <Grid item>
            <StyledSelect {...StyledSelectUserDefinitionProps} />
          </Grid>

          {state.UserDefinition && state.UserDefinition.value && (
            <Grid item>
              <StyledSelect {...StyledSelectUserInstanceProps} />
            </Grid>
          )}

          <Grid item>
            <ButtonGroup fullWidth variant="text">
              <Button onClick={handleClear}>Close</Button>
              {state.UserInstance && (
                <Button onClick={addSelectedUserToGroup}>
                  + Add to conversation
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      );
    }
  } else {
    return (
      <Button fullWidth onClick={toggleOpen}>
        +
      </Button>
    );
  }
};

const list = (UserDefinitionList) => {
  const keywords = ['company', 'no-email', ' no-email', 'calendar'];
  let newList: any = [];
  UserDefinitionList.forEach((i, idx: number) => {
    const search = ',';
    const desc_key = i.Description.split(search);
    if (desc_key.length > 0) {
      const exists = desc_key.filter((j) => {
        if (j !== '') return keywords.includes(j);
      });
      if (exists.length < 1)
        newList.push({ label: i.Title, value: i.Id, desc: i.Description });
    }
  });
  return newList;
};
