import { Message, NullableOption } from '@microsoft/microsoft-graph-types';
import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * AUDIT API
 */
export const GetAllSummaries = async () => {
  let url = `AuditAPI.ashx`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetCurrentAudit = async ({ Id }) => {
  let url = `AuditAPI.ashx?id=${Id}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetAuditByProcessId = async ({ ProcessInstanceId }) => {
  let url = `AuditAPI.ashx?action=AUDITPROCESSINSTANCE&id=${ProcessInstanceId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetAuditByUser = async ({ UserInstanceId }) => {
  let url = `AuditAPI.ashx?action=AUDITUSERINSTANCE&id=${UserInstanceId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const PostAudit = async ({
  data
}: {
  data: {
    TimeStamp: NullableOption<string> | undefined;
    UserInstanceId: number;
    UserInstanceEmail: string;
    Payload: string;
    AuditUserInstanceId: number;
    AuditProcessInstanceId: number;
  };
}) => {
  const PostAuditData = {
    ...data,
    Endpoint: 'AuditAPI.ashx'
  };
  let url = `AuditAPI.ashx`;
  const config: AxiosRequestConfig = {
    method: 'POST',
    url,
    data: PostAuditData
  };
  return await dbQueue({ config, url });
};
