/**dialog for user profile summary */
import React, { MouseEvent } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import User from './';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<any>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const UserDialog = ({
  open,
  setOpen,
  user,
  setEdit,
  setLoading,
  loading,
  showOnlyHistoryButton
}) => {
  const handleClose = (event: MouseEvent<HTMLDivElement>) => {
    setOpen(false);
    // turn off edit mode within the parent EditableDropDown component
    setEdit?.(false);
  };

  return (
    <Dialog
      scroll="body"
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      onMouseDown={(event) => event.stopPropagation()}>
      <User data={user} showOnlyHistoryButton={showOnlyHistoryButton} />
    </Dialog>
  );
}; //END UserDialog

export default UserDialog;
