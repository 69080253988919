import { Grid, Typography, makeStyles, Button } from '@material-ui/core';
import { theme } from 'theme';
import { People } from '@material-ui/icons';
import { grey } from 'common/colors';
import { CalendarContext } from 'components/Calendar/context/CalendarContext';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { useTypedSelector } from 'redux/reducers';
import firebase from 'firebase';
import { useContext, useEffect, useState } from 'react';
import { IGuest } from 'components/Calendar/interface';
import { EventContext } from 'components/Calendar/context/EventContext';

const useStyles = makeStyles(() => ({
  container: {
    border: `2px ${theme.palette.grey[200]} solid`,
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius
  },
  iconStyle: {
    color: grey[500],
    width: '40px'
  },
  tagContainer: {
    position: 'relative',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius
  },
  tag: {
    position: 'absolute',
    top: -22,
    right: 10,
    borderRadius: theme.shape.borderRadius,
    padding: 10,
    minWidth: 80
  },
  userContainer: {
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    position: 'relative'
  }
}));

const UserLozengeWrapper = ({ children }) => (
  <div style={{ maxWidth: 320 }}>{children}</div>
);

const EventGuest = ({ eventDialogOpen }) => {
  const classes = useStyles();

  const eventProps = eventDialogOpen.event.extendedProps;

  if (eventProps && eventProps.guests && eventProps.guests.length > 0) {
    return (
      <div className={classes.container}>
        <ShowGuestStatus
          eventProps={eventProps}
          eventDialogOpen={eventDialogOpen}
        />
      </div>
    );
  }

  return <div />;
};

export default EventGuest;

const ShowGuestStatus = ({ eventProps, eventDialogOpen }) => {
  const { allEvents, setAllEvents } = useContext(CalendarContext);
  const { fetchStatusEvents } = useContext(EventContext);
  const [refreshFlag, setRefreshFlag] = useState(false);

  const user = useTypedSelector((s) => s.user.user);
  const classes = useStyles();

  useEffect(() => {
    const handleFetchEvents = async () => {
      if (refreshFlag) {
        await fetchStatusEvents(eventDialogOpen.event.id);
        setRefreshFlag(false);
      }
    };

    handleFetchEvents();
  }, [refreshFlag, setAllEvents, allEvents, eventDialogOpen.event.id]);

  const awaitingGuests = eventProps.guests.filter(
    (guest) => guest.status === 0
  ).length;

  const acceptedGuests =
    eventProps.guests.filter((guest) => guest.status === 1).length + 1;

  const declinedGuests = eventProps.guests.filter(
    (guest) => guest.status === 2
  ).length;

  // TODO: Add something here
  if (refreshFlag) {
    return <div>Hello world</div>;
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <People fontSize="medium" className={classes.iconStyle} />
      </Grid>
      <Grid item xs>
        <Typography>{eventProps.guests.length} Guests</Typography>
        <Grid container direction="row" spacing={1} alignItems="center">
          {acceptedGuests > 0 && (
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Accepted: {acceptedGuests}
              </Typography>
            </Grid>
          )}
          {awaitingGuests > 0 && (
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Awaiting: {awaitingGuests}
              </Typography>
            </Grid>
          )}
          {declinedGuests > 0 && (
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                Declined: {declinedGuests}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <div style={{ paddingTop: theme.spacing(1) }} />
      <Grid item xs={12}>
        <div
          className={classes.tagContainer}
          style={{
            backgroundColor: theme.palette.success.light,
            border: `1px ${theme.palette.success.main} solid`
          }}>
          <div
            className={classes.tag}
            style={{
              background: theme.palette.success.light,

              borderColor: theme.palette.success.main,
              border: `1px ${theme.palette.success.main} solid`
            }}>
            <Typography
              align="center"
              variant="h6"
              style={{
                color: theme.palette.success.main,
                fontWeight: 'bold'
              }}>
              Organizer
            </Typography>
          </div>

          {eventProps.calendarOwner !== undefined && (
            <UserLozengeWrapper>
              <QuickUserFromId
                UserInstanceId={eventProps.calendarOwner.toString()}
              />
            </UserLozengeWrapper>
          )}
        </div>

        {eventProps.guests.map((guest: IGuest, _) => {
          const guestBorderColor = getStatusColor(guest.status, 'border');
          const guestBackgroundColor = getStatusColor(
            guest.status,
            'background'
          );

          const isUserAGuest = guest.UserInstanceId === user.Id;
          return (
            <div key={guest.UserInstanceId}>
              <div style={{ paddingTop: 30 }} />
              <div
                style={{
                  padding: theme.spacing(1),
                  borderRadius: theme.shape.borderRadius,
                  position: 'relative',
                  backgroundColor: guestBackgroundColor,
                  border: `1px ${guestBorderColor} solid`
                }}>
                <div
                  style={{
                    position: 'absolute',
                    top: -22,
                    right: 10,
                    borderRadius: theme.shape.borderRadius,
                    padding: 10,
                    minWidth: 80,
                    backgroundColor: guestBackgroundColor,
                    border: `1px ${guestBorderColor} solid`
                  }}>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{
                      color:
                        guest.status === 0
                          ? theme.palette.primary.main
                          : guest.status === 1
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                      fontWeight: 'bold'
                    }}>
                    Guest Status:{' '}
                    {guest.status === 0
                      ? 'Awaiting'
                      : guest.status === 1
                      ? 'Accepted'
                      : 'Declined'}
                  </Typography>
                </div>
                <UserLozengeWrapper>
                  <QuickUserFromId
                    UserInstanceId={guest.UserInstanceId.toString()}
                  />
                </UserLozengeWrapper>
              </div>

              {isUserAGuest && guest.status <= 0 && (
                <div style={{ padding: theme.spacing(1) }}>
                  <Grid container direction="row" spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        data-cy="calendar-accept-invitation"
                        fullWidth
                        variant="contained"
                        style={{
                          color: 'white',
                          backgroundColor: theme.palette.success.main
                        }}
                        onClick={async () => {
                          await updateGuestStatus(
                            eventDialogOpen.event,
                            user.Id,
                            1,
                            setRefreshFlag
                          );
                        }}>
                        Accept Invitation
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        data-cy="calendar-decline-invitation"
                        fullWidth
                        variant="contained"
                        style={{
                          color: 'white',
                          backgroundColor: theme.palette.error.main
                        }}
                        onClick={async () => {
                          await updateGuestStatus(
                            eventDialogOpen.event,
                            user.Id,
                            2,
                            setRefreshFlag
                          );
                        }}>
                        Decline Invitation
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              )}
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

const getStatusColor = (status: number, type: string) => {
  switch (status) {
    case 0:
      return type === 'background' ? grey[100] : grey[500];
    case 1:
      return type === 'background'
        ? theme.palette.success.light
        : theme.palette.success.main;
    case 2:
      return theme.palette.error.light;
    default:
      return '';
  }
};

const updateGuestStatus = async (event, userId, status, setRefreshFlag) => {
  if (event.id) {
    const eventRef = firebase.firestore().collection('calendar').doc(event.id);
    const eventData = (await eventRef.get()).data();

    if (eventData && eventData.extendedProps.guests) {
      const updatedGuests = eventData.extendedProps.guests.map((guest) => {
        if (guest.UserInstanceId === userId) {
          return { ...guest, status };
        }
        return guest;
      });

      await eventRef.update({
        'extendedProps.guests': updatedGuests
      });

      setRefreshFlag(true);
    }
  }
};
