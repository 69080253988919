import { Dashboard as DashboardLayout } from 'layouts';
import VRM from 'components/VRM';

const Component = () => {
  return (
    <DashboardLayout title="VRM" padding="noPadding">
      <VRM />
    </DashboardLayout>
  );
};

export default Component;
