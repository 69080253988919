import React from 'react';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { fade, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import { questions } from './data';

const useStyles = makeStyles(theme => ({
  pad: { padding: theme.spacing(2) },
  answer: {},
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch'
    }
  }
}));

const Questions = () => {
  const classes = useStyles();
  const [currentAnswer] = React.useState('');

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        background: 'rgb(0,0,0,0.2)'
      }}>
      <header className={classes.pad}>
        <Typography variant="h5">Questions & Answers Knowledge</Typography>
      </header>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          inputProps={{ 'aria-label': 'search' }}
        />
      </div>

      <List>
        {questions.map((question, i) => (
          <ListItem key={i}>
            <Typography>{question.question}</Typography>
          </ListItem>
        ))}
      </List>

      <div className={classes.pad}>
        <Paper className={classes.answer}>
          <Typography>{currentAnswer}</Typography>
        </Paper>
      </div>
    </div>
  );
};

export default Questions;
