import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useTheme
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';
import { hasSearchCriteria } from '../functions';
import {
  IRedflagDirectorArray,
  IRedFlagDirectorshipData,
  getValue
} from '../helper';
import { ERfaRating, IFormField, IQuery, ISelectOption } from '../interfaces';
import { nationalities } from './NationalityData';
import AdvancedFilterDialog from './AdvancedFilterDialog';

const rfaRatingOptions: ISelectOption[] = Object.entries(ERfaRating).map(
  ([value, label]) => ({ value, label })
);

export const nationalityOptions = nationalities.map((nation) => ({
  value: nation.alpha_3_code,
  label: nation.nationality
}));

const formFields: IFormField[] = [
  { name: 'first_name', label: 'First Name', type: 'text' },
  { name: 'surname', label: 'Surname', type: 'text' },
  {
    name: 'rfa_rating',
    label: 'RFA Rating',
    type: 'select',
    options: rfaRatingOptions
  },
  {
    name: 'nationality',
    label: 'Nationality',
    type: 'select',
    options: nationalityOptions
  },
  { name: 'town', label: 'Town', type: 'text' },
  { name: 'country', label: 'Country', type: 'text' },
  { name: 'postcode', label: 'Postcode', type: 'text' },
  { name: 'occupation', label: 'Occupation', type: 'text' },
  { name: 'locality', label: 'Locality', type: 'text' },
  { name: 'title', label: 'Title', type: 'text' },
  { name: 'appointment_type', label: 'Appointment Type', type: 'text' },
  { name: 'birthdate', label: 'Birthdate', type: 'text' },
  { name: 'date_appointed', label: 'Date Appointed', type: 'text' },
  { name: 'date_resigned', label: 'Date Resigned', type: 'text' },
  { name: 'other_appointments', label: 'Other Appointments', type: 'text' },
  { name: 'company', label: 'Company', type: 'text' }
];

const DirectorSearch = ({
  search,
  handleSearch,
  query
}: {
  search: () => Promise<void>;
  handleSearch: (name: string, value: string) => void;
  query: IQuery;
}): JSX.Element => {
  const theme = useTheme();
  const [advanceSearch, setAdvanceSearch] = useState<boolean>(false);

  const hasAdditionalCriteria = (): boolean => {
    const { first_name, ...otherCriteria } = query.director;
    return Object.keys(otherCriteria).some((key) =>
      getValue(otherCriteria[key])
    );
  };

  const RenderChips = (): JSX.Element[] => {
    return formFields
      .filter(({ name }) => getValue(query.director[name]))
      .map(({ name, label }) => (
        <Chip
          key={name}
          label={`${label}: ${getValue(query.director[name])}`}
          onDelete={() => handleSearch(name, '')}
          style={{ margin: '0 4px' }}
        />
      ));
  };

  const allowSearching = !hasSearchCriteria(query.director);
  const allowTyping = !hasAdditionalCriteria();

  return (
    <>
      <Grid item xs={9}>
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center'
          }}>
          <TextField
            fullWidth
            variant="outlined"
            onChange={(e) => handleSearch('first_name', e.target.value)}
            value={allowTyping ? query.director?.first_name?.eq || '' : ''}
            margin="dense"
            label={allowTyping ? 'Director Name' : 'Search Criteria'}
            onKeyDown={(e) => e.key === 'Enter' && search()}
            disabled={!allowTyping}
            InputProps={{
              startAdornment: !allowTyping && RenderChips(),
              style: { paddingRight: 0, minWidth: '100px' }
            }}
            style={{ flexGrow: 1, display: 'flex' }}
          />
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          data-cy="search-button"
          onClick={search}
          disabled={allowSearching}
          style={{ margin: theme.spacing(1) }}>
          Search
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          data-cy="filter-button"
          onClick={() => setAdvanceSearch(true)}
          style={{ margin: theme.spacing(1) }}>
          Filter
        </Button>
      </Grid>
      <AdvancedFilterDialog
        isOpen={advanceSearch}
        setIsOpen={setAdvanceSearch}
        handleSearch={handleSearch}
        formFields={formFields}
        entity={query.director}
        alertText={
          'Use Advanced Search to refine your results with specific search criteria such as nationality, birthdate, or company affiliation. This allows for more targeted searches.'
        }
      />
    </>
  );
};

const DirectorList = ({
  directors,
  localLoading,
  getDirector
}: {
  directors: IRedflagDirectorArray | null;
  localLoading: boolean;
  getDirector: (director: IRedFlagDirectorshipData) => Promise<void>;
}): JSX.Element => {
  const theme = useTheme();

  if (localLoading) {
    return <LinearProgress />;
  }

  const loadedDirectors = directors?.data.map(
    (director: IRedFlagDirectorshipData) => (
      <div key={director.id} style={{ padding: theme.spacing(0.5) }}>
        <Paper elevation={1}>
          <MenuItem
            data-company-number={director.attributes.company_id}
            onClick={() => getDirector(director)}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'flex-start'
              }}>
              <Typography variant="h6">
                {director.attributes.first_name}
              </Typography>
            </Box>

            <Typography
              variant="caption"
              style={{ color: theme.palette.grey[500] }}>
              {director.attributes.surname}
            </Typography>

            <Typography style={{ color: theme.palette.success.main }}>
              {director.attributes.company_name}
            </Typography>
          </MenuItem>
        </Paper>
      </div>
    )
  );

  return <>{loadedDirectors}</>;
};

export { DirectorSearch, DirectorList };
