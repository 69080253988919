import { Button, Grid } from '@material-ui/core';
import { Message, FileAttachment } from '@microsoft/microsoft-graph-types';
import { Dispatch, SetStateAction, useState } from 'react';
import { theme } from 'theme';

import ReplyIcon from '@material-ui/icons/Reply';
import ForwardIcon from '@material-ui/icons/Forward';
import ForwardEmail from '../ForwardEmail';
import ReplyEmail from './ReplyEmail';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import { UserInstance } from 'types/interfaces';
import { Body } from 'common/HTMLRenderer';

const ReplyExpansion = ({
  reply,
  user
}: {
  reply: Message;
  user: UserInstance;
}) => {
  return (
    <>
      <Grid container spacing={2}>
        <div style={{ padding: theme.spacing(4), width: '100%' }}>
          <Body
            body={reply.body}
            attachments={reply?.attachments || []}
            user={user}
          />
        </div>
      </Grid>
    </>
  );
};

export default ReplyExpansion;
