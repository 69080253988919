import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.warning.main,
      color: 'black',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'center'
    }
  })
);

const BetaBanner = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography color="inherit" variant="h6">
        BETA
      </Typography>
      <Typography color="inherit" variant="caption">
        This feature is in early stages of development, it may not work as
        expected just yet
      </Typography>
    </div>
  );
};

export default BetaBanner;
