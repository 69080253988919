import { Dashboard as DashboardLayout } from 'layouts';
import FileExplorer from 'components/FileExplorer';

const Component = () => {
  return (
    <DashboardLayout title="File Explorer" padding="noPadding">
      <FileExplorer />
    </DashboardLayout>
  );
};

export default Component;
