import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * USER DEFINITION API
 */
export const GetUserDefinitionList = async () => {
  let url = `UserDefinitionAPI.ashx?action=DEFINITIONLIST`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetUserDefinitions = async ({ UserDefinitionId }) => {
  let url = `UserDefinitionAPI.ashx?action=define&userdefinitionid=${UserDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};
