import React, { useState } from 'react';
import { Dashboard as DashboardLayout } from '../../layouts';
import Table from '../../components/Table';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import {
  getUserDefinitions,
  getUserInstanceSummaryList
} from 'redux/actions/user';

const ID = 21;

const Lenders = (props) => {
  const title = 'Lenders';
  const token = useSelector((s) => s.user.auth.token);
  const customersUserDef = useSelector(
    (state) => state.userAPI.userDefinition[ID]
  );
  const customersUserList = useSelector((s) => s.userAPI.userList[ID]);
  const [loading, setLoading] = useState(true);

  const getAllData = async (props) => {
    setLoading(true);
    const response = await getUserDefinitions({
      token: props ? props : token,
      UserDefinitionId: ID
    });
    await getUserInstanceSummaryList({
      token: response.token,
      UserDefinitionId: ID
    });
    setLoading(false);
  };

  React.useEffect(() => {
    if (customersUserList && Object.keys(customersUserList).length > 0) return;
    if (token !== '') getAllData();
    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if ((customersUserDef, customersUserList)) setLoading(false);
    // eslint-disable-next-line
  }, [customersUserList]);

  return (
    <DashboardLayout title={title}>
      {loading ? (
        <LinearProgress />
      ) : (
        <Table
          ID={ID}
          customersUserDef={customersUserDef}
          customersUserList={customersUserList}
          getAllData={getAllData}
          title={title}
        />
      )}
    </DashboardLayout>
  );
};

export default Lenders;
