import { Text, View } from '@react-pdf/renderer';
import { FunderDoc } from 'components/Calculator/Calculator/interfaces';

export const Facility = ({
  state,
  Currency,
  TableStyle,
  styles
}: {
  state: FunderDoc;
  Currency: ({ value }: { value: number }) => JSX.Element;
  TableStyle: any;
  styles: any;
}) => {
  const isLease = (product: string) =>
    product.toLowerCase().startsWith('lease');

  const Row = ({
    name,
    value,
    grey
  }: {
    name: string;
    value: any;
    grey?: boolean;
  }) => {
    const style = grey
      ? [TableStyle.row, TableStyle.greyBackground]
      : [TableStyle.row];
    return (
      <View style={style}>
        <Text style={[TableStyle.cellLeft]}>{name}</Text>
        <Text style={[TableStyle.cell]} />
        <Text style={[TableStyle.cell]} />
        <Text style={[TableStyle.cellRight]}>{value}</Text>
      </View>
    );
  };

  return (
    <div>
      <View style={TableStyle.table}>
        <View style={TableStyle.row}>
          <Text style={[TableStyle.cellLeft, styles.tableTitleText]}>Item</Text>
          <Text style={[TableStyle.cell]} />
          <Text style={[TableStyle.cell]} />
          <Text style={[TableStyle.cellRight, styles.tableTitleText]}>
            Value
          </Text>
        </View>

        <div style={styles.line} />

        <Row
          name="Cost excl VAT & Non-VATable Items"
          value={<Currency value={state.COST_EXCL_VAT_AND_NON_VATABLE_ITEMS} />}
          grey
        />
        <Row name="VAT" value={<Currency value={state.VAT} />} />
        <Row
          name="Non-VATable Items"
          value={<Currency value={state.NON_VATABLE_ITEMS} />}
          grey
        />

        {!isLease(state.FINANCE_PRODUCT) && (
          <>
            <Row
              name="Settlement Figure"
              value={<Currency value={state.SETTLEMENT_FIGURE} />}
            />
            <Row
              name="(i) Cost incl VAT"
              value={<Currency value={state.I_COST_INC_VAT} />}
              grey
            />
          </>
        )}

        <Row
          name={`${
            isLease(state.FINANCE_PRODUCT) ? '(i)' : '(ii)'
          } Advance Repayments`}
          value={
            <>
              <Currency value={state.II_ADVANCE_REPAYMENTS} />
              {isLease(state.FINANCE_PRODUCT) && <div> + VAT</div>}
            </>
          }
        />
        <Row
          name={`${
            isLease(state.FINANCE_PRODUCT) ? '(ii)' : '(iii)'
          } Additional Repayment`}
          value={
            <>
              <Currency value={state.III_ADDITIONAL_REPAYMENT} />
              {isLease(state.FINANCE_PRODUCT) && <div> + VAT</div>}
            </>
          }
          grey
        />
        <Row
          name={`${
            isLease(state.FINANCE_PRODUCT) ? '(iii)' : '(iv)'
          } Net Part-Exchange ${
            !isLease(state.FINANCE_PRODUCT) ? 'excl VAT' : ''
          }`}
          value={
            <>
              <Currency value={state.IV_NET_PART_EXCHANGE_EXCL_VAT} />
              {isLease(state.FINANCE_PRODUCT) && <div> + VAT</div>}
            </>
          }
        />

        {!isLease(state.FINANCE_PRODUCT) && (
          <>
            <Row
              name="(v) VAT on Net Part Exchange"
              value={<Currency value={state.V_VAT_ON_NET_PART_EXCHANGE} />}
            />
            <Row
              name="(vi) Net VAT, paid upfront"
              value={<Currency value={state.VI_NET_VAT_PAID_UPFRONT} />}
              grey
            />
          </>
        )}

        <Row
          name={`Total Initial Payment, sum of ${
            isLease(state.FINANCE_PRODUCT)
              ? '(i) through (iii)'
              : '(ii) through (vi)'
          }`}
          value={
            <>
              <Currency value={state.TOTAL_INIT_PAYMENT} />
              {isLease(state.FINANCE_PRODUCT) && <div> + VAT</div>}
            </>
          }
          grey
        />
        <Row
          name={`Amount to Finance ${
            !isLease(state.FINANCE_PRODUCT)
              ? ', (i) - Total Initial Payment'
              : ''
          }`}
          value={
            <>
              <Currency value={state.AMOUNT_TO_FINANCE} />
              {isLease(state.FINANCE_PRODUCT) && <div> + VAT</div>}
            </>
          }
        />

        <Row
          name="Repayments"
          value={
            <>
              <div>{state.REPAYMENTS}</div>
              {isLease(state.FINANCE_PRODUCT) && <div> + VAT</div>}
            </>
          }
          grey
        />

        {!isLease(state.FINANCE_PRODUCT) && (
          <>
            <Row
              name="Balloon"
              value={<Currency value={parseInt(state.BALLOON.toString())} />}
            />
            <Row
              name="VAT Deferral Period"
              value={state.VAT_DEFERRAL_PERIOD}
              grey
            />
          </>
        )}
      </View>
    </div>
  );
};
