import { store } from 'redux/store';
import { UPDATE_LOADING } from 'redux/actions/types';
import { GetListOfObjects } from '../database';

const { dispatch } = store;

export const getListOfObjects = async ({
  ProcessInstanceId,
  ObjectDefinitionId
}) => {
  dispatch({ type: UPDATE_LOADING, payload: true });
  const res = await GetListOfObjects({ ProcessInstanceId, ObjectDefinitionId });

  if (res && res.headers && res.headers.auth) {
    dispatch({ type: UPDATE_LOADING, payload: false });
    if (res && res.status === 200) return res;
  }

  dispatch({ type: UPDATE_LOADING, payload: false });
};
