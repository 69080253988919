import React from 'react';
import { useDispatch } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { tableIcons } from '../ProcessTable/tableIcons';
import MaterialTable from '@material-table/core';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import Badge from '@material-ui/core/Badge';
import HttpsIcon from '@material-ui/icons/Https';
import { parse } from 'fecha';
import Time from 'common/Time';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { useTypedSelector } from 'redux/reducers';
import { CompleteUserInstance } from 'types/interfaces';
import { getCrmToken } from 'redux/actions/GraphqlCrmActions';

const useStyles = makeStyles((theme) => ({
  icon: { color: 'inherit' },
  title: { paddingLeft: 15 }
}));

interface IProps {
  ID: number;
  title: string;
}

const Table = ({ ID, title }: IProps) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const graphqlCrmState = useTypedSelector((s) => s.graphqlCrm);
  const token = useTypedSelector((s) => s.user.auth.token);
  const [data, setData] = React.useState<any>([]);
  const customersUserDef = useTypedSelector(
    (s) => s.userAPI.userDefinition[ID]
  );
  const Description = customersUserDef[ID].UserDefinition.Description;
  const search = ',';
  const keywords = Description.split(search);

  const titleFromDescription = () => {
    if (keywords.includes('company')) return 'Company No.';
    if (keywords.includes('no-email')) return 'Reference';
    return 'Email';
  };
  const customersUserList = useTypedSelector((s) => s.userAPI.userList[ID]);

  const refreshData = async ({ token, UserDefinitionId }) => {
    const { data } = await getUserInstanceSummaryList({
      token,
      UserDefinitionId
    });
    if (data) {
      setData(Object.values(data).reverse());
    }
  };

  const loadGraphqlToken = async () => {
    if (!graphqlCrmState.loaded || graphqlCrmState.willExpire <= Date.now()) {
      getCrmToken(dispatch);
    }
  };

  React.useEffect(() => {
    loadGraphqlToken();
    if (customersUserList && customersUserList[customersUserList.id]) {
      const initialSortedData = customersUserList[customersUserList.id].sort(
        (a, b) =>
          (a.UserInstance.Title || '').localeCompare(b.UserInstance.Title || '')
      );
      setData(initialSortedData);
    }
  }, []);
  // Compare this snippet from src/components/Table/index.tsx:
  let columns: any = [
    {
      title: 'Avatar',
      field: 'UserInstance.ProfilePicture',
      width: 25,
      render: (rowData: CompleteUserInstance) => (
        <Avatar src={rowData.UserInstance.ProfilePicture} />
      )
    },
    {
      title: 'Title',
      field: 'UserInstance.Title',
      searchable: true,
      render: (rowData: CompleteUserInstance) => (
        <Typography className={classes.title} color="textPrimary">
          {rowData.UserInstance.Title}
        </Typography>
      )
    },
    {
      title: titleFromDescription(),
      field:
        Description === 'no-email'
          ? 'UserInstance.Id'
          : 'UserInstance.UserInstanceEmail',
      searchable: true,
      render: (rowData: CompleteUserInstance) => (
        <Typography color="textPrimary">
          {Description === 'no-email'
            ? rowData.UserInstance.Id
            : rowData.UserInstance.UserInstanceEmail.split('-')[0]}
        </Typography>
      ),
      cellStyle: {}
    },
    {
      title: 'SystemAccess',
      field: 'UserInstance.SystemAccess',
      render: (rowData: CompleteUserInstance) => {
        const badgeContent =
          rowData && rowData.UserInstance && rowData.UserInstance.SystemAccess;
        return (
          <Badge badgeContent={badgeContent} color="secondary">
            <HttpsIcon />
          </Badge>
        );
      },
      cellStyle: {}
    }
  ];

  if (keywords.includes('calendar')) {
    columns = [
      {
        title: 'Type',
        render: (row) => get('type', row)
      },
      {
        title: 'Title',
        field: 'UserInstance.Title',
        searchable: true
      },
      {
        title: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <ScheduleIcon style={{ marginRight: 3 }} />
            Starts
          </div>
        ),
        render: (row) => get('start', row)
      },
      {
        title: 'Status',
        render: (row) => get('status', row)
      }
    ];
  }

  const get = (name: any, row: any) => {
    const hash = row.UserInstance.UserInstanceEmail;
    const search = ',';
    const keywords = hash.split(search);
    let item: any = '';
    keywords.forEach((el: string) => {
      const search2 = '=';
      let els = el.split(search2);
      if (els.length === 2) {
        if (els[0] === name) {
          if (name === 'start' || name === 'end') {
            const dateobject = parse(els[1], 'YYYY-MM-DDTHH:mm');

            item = <Time time={dateobject} type="mymaskB" />;
          } else {
            item = els[1];
          }
        }
      }
    });
    return item;
  };

  return (
    <div>
      <MaterialTable
        actions={[
          {
            icon: () => <RefreshIcon />,
            tooltip: 'Refresh Data',
            isFreeAction: true,
            onClick: (event, rowData) => {
              refreshData({ token, UserDefinitionId: ID });
            }
          }
        ]}
        columns={data.length > 0 ? columns : []}
        data={data}
        icons={tableIcons}
        onRowClick={(event, rowData: any, togglePanel) => {
          history.push(
            `/UserDefinition/${rowData?.UserInstance?.UserDefinitionId}/${rowData?.UserInstance?.Id}`
          );
        }}
        options={{
          exportAllData: true,
          filtering: false,
          pageSize: 10,
          pageSizeOptions: [10, 50, 100],
          headerStyle: { zIndex: 1 }
        }}
        title={title || ''}
      />
    </div>
  );
};

export default Table;
