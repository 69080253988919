import Search from './Search';
import { useState } from 'react';
import { GET_COMPANY_DETAIL } from 'graphql/queries';
import { Company } from './interfaces';
import { useTypedSelector } from 'redux/reducers';
import { CircularProgress, Typography, Paper } from '@material-ui/core';

const Index = () => {
  const [company, setCompany] = useState<null | Company>(null);
  const SelectCompany = (company: Company) => setCompany(company);
  if (company) return <CompanyDetail company={company} />;
  return <Search SelectCompany={SelectCompany} />;
};

export default Index;

const CompanyDetail = ({ company }: { company: Company }) => {
  console.log({ company });
  const data = company.companyReport;
  return (
    <Paper style={{ padding: 10, margin: 10 }} elevation={3}>
      Company
      <Typography variant="h1">{data?.company_name}</Typography>
      <Typography>Company Number: {data?.company_number}</Typography>
      <Typography>Credit Score: {data?.credit_score}</Typography>
      <Typography>RFA Rating: {data?.rfa_rating}</Typography>
      <Typography>Estimated Turnover: {data?.estimated_turnover}</Typography>
      <Typography>Estimated Employees: {data?.estimated_employees}</Typography>
      {/* {data?.active_directors.map((director, idx) => {
        return (
          <Paper style={{ padding: 10, margin: 10 }} elevation={3} key={idx}>
            <Typography variant="h1">Active Directors</Typography>
            <Typography>{director.address}</Typography>
            <Typography>{director.appointment}</Typography>
            <Typography>{director.appointment_type}</Typography>
            <Typography>{director.birthdate}</Typography>
            <Typography>{director.company}</Typography>
            <Typography>{director.companyId}</Typography>
            <Typography>{director.company_name}</Typography>
            <Typography>{director.county}</Typography>
            <Typography>{director.date_appointed}</Typography>
            <Typography>{director.date_resigned}</Typography>
            <Typography>{director.first_name}</Typography>
            <Typography>{director.id}</Typography>
            <Typography>{director.is_current}</Typography>
            <Typography>{director.locality}</Typography>
            <Typography>{director.birthdate}</Typography>
            <Typography>{director.nationality}</Typography>
            <Typography>{director.occupation}</Typography>
            <Typography>{director.other_appointments}</Typography>
            <Typography>{director.person_id}</Typography>
            <Typography>{director.postcode}</Typography>
            <Typography>{director.rfa_rating}</Typography>
            <Typography>{director.source}</Typography>
            <Typography>{director.surname}</Typography>
            <Typography>{director.title}</Typography>
            <Typography>{director.town}</Typography>
          </Paper>
        );
      })} */}
      {/* {data?.ccjs.map((ccj, idx) => {
        return (
          <Paper style={{ padding: 10, margin: 10 }} elevation={3} key={idx}>
            <Typography>{ccj.address}</Typography>
            <Typography>{ccj.amount}</Typography>
            <Typography>{ccj.case_number}</Typography>
            <Typography>{ccj.companyId}</Typography>
            <Typography>{ccj.court_name}</Typography>
            <Typography>{ccj.currency}</Typography>
            <Typography>{ccj.date_of_birth}</Typography>
            <Typography>{ccj.date_of_judgement}</Typography>
            <Typography>{ccj.entity_type}</Typography>
            <Typography>{ccj.given_name}</Typography>
            <Typography>{ccj.judgement_type}</Typography>
            <Typography>{ccj.record_type}</Typography>
            <Typography>{ccj.rtl_image_number}</Typography>
            <Typography>{ccj.run_date}</Typography>
            <Typography>{ccj.satisfaction_date}</Typography>
            <Typography>{ccj.suffix}</Typography>
            <Typography>{ccj.surname}</Typography>
            <Typography>{ccj.title}</Typography>
            <Typography>{ccj.trading_style}</Typography>
          </Paper>
        );
      })} */}
    </Paper>
  );
};

// <>
// <Grid item>
{
  /* <FormControl> */
}
{
  /* <InputLabel>Period</InputLabel>
              <Select
                inputProps={{ name: 'Days' }}
                native
                onChange={(e: any) => setDays(parseInt(e.target.value))}
                value={days}>
                <option value={1}>Last Day</option>
                <option value={7}>Last 7 Days</option>
                <option value={30}>Last 30 Days</option>
                <option value={60}>Last 60 Days</option>
              </Select> */
}
{
  /* </FormControl>
          </Grid>
        </Grid>
      </Box> 
      // <Box style={{ padding: 16 }}>
      //   <Grid container spacing={2}>
      //     <ul style={{ display: 'flex', flexDirection: 'column' }}>
      //       {data?.companies?.items?.map((c) => (
      //         <li key={c.company_id} style={{ listStylePosition: 'inside' }}>
      //           {c.company_name}
      //           {c.website}
      //         </li>
      //       ))}
      //     </ul>
      //   </Grid>
      // </Box>
      {/* 
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <EmailsTotal
              Icon={(props) => <DraftsIcon className={props.className} />}
              amount={data?.emails_sorted?.draft?.length}
              title="Drafts"
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <EmailsTotal
              Icon={(props) => <TouchAppIcon className={props.className} />}
              amount={data?.emails_sorted?.scheduled?.length}
              title="Scheduled"
            />
          </Grid>

          <Grid item md={4} xs={12}>
            <EmailsTotal
              Icon={(props) => <SendIcon className={props.className} />}
              amount={data?.emails_sorted?.triggered?.length}
              title="Triggered"
            />
          </Grid>
        </Grid>
      </Box> */
}

{
  /* <Box style={{ padding: 8 }}>
        <React.Suspense fallback={<LinearProgress />}>
          <EmailsTable
            emails={data.emails}
            getDashboardData={getDashboardData}
            loading={loading}
          />
        </React.Suspense>
      </Box> */
}
// </>
