import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { AddressFinder } from '@ideal-postcodes/address-finder';

import { CircularProgress, TextField, InputLabel } from '@material-ui/core';
import { normalizeIdealPostcodeData } from '../functions/apiMiddleware';
import { handleAddressObject } from 'components/Fields/InlineTextField/functions/handleAddressObject';

import {
  IAddressApiFullAddress,
  IAddressWarning,
  IAddressProps
} from 'types/address';
import './idealPostcodesStyles.css';
import RoomIcon from '@material-ui/icons/Room';

import { UdprnLookup_API } from '../functions';
import { theme } from 'theme';

type RequiredProperties<T> = T extends { isIntegration: true }
  ? { [K in keyof T]-?: T[K] }
  : T;
type RequiredAddressProps = RequiredProperties<IAddressProps>;
const AutocompleteAddress = ({
  setAddress,
  setWarning,
  props,
  icon
}: {
  props: RequiredAddressProps;
  setWarning?: (warning: IAddressWarning) => void;
  setAddress?: (address: IAddressApiFullAddress | null) => void;
  icon?: boolean;
}) => {
  const {
    ProcessInstance,
    ProcessInstanceId,
    FieldDefinitionDict,
    CompleteObjectInstance,
    FieldDefinitionList,
    isIntegration,
    refreshDealData
  } = props;
  const [inputAddress, setInputAddress] = useState<string>('');
  const [localAddress, setLocalAddress] = useState<
    IAddressApiFullAddress | undefined
  >();

  const [isAddressSelected, setIsAddressSelected] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const retrieveUDPRNData = async (address) => {
    axios(await UdprnLookup_API(address.udprn)).then((response) => {
      if (response) {
        const idealPostcodeData = response.data.Address.result;
        const normalizedIdealPostcodeData =
          normalizeIdealPostcodeData(idealPostcodeData);

        if (isIntegration) {
          if (
            ProcessInstance &&
            CompleteObjectInstance &&
            ProcessInstanceId &&
            FieldDefinitionDict
          ) {
            handleAddressObject({
              ProcessInstance,
              CompleteObjectInstance,
              ProcessInstanceId,
              FieldDefinitionDict,
              fullAddress: normalizedIdealPostcodeData
            });
          }
        } else {
          setLocalAddress(normalizedIdealPostcodeData);
          setAddress && setAddress(normalizedIdealPostcodeData);
        }
      }
    });
    setIsAddressSelected(true);
  };

  useEffect(() => {
    if (inputRef.current) {
      AddressFinder.setup({
        inputField: inputRef.current,
        apiKey: process.env.REACT_APP_ADDRESS_API_KEY || '',
        onAddressSelected: (address) => retrieveUDPRNData(address),
        containerClass: 'ideal-postcodes-autocomplete'
      });
    }
  }, []);

  return (
    <div
      style={{ width: '100%' }}
      onClick={() => {
        if (localAddress !== undefined) {
          setLocalAddress(undefined);
        }

        setInputAddress('');
      }}>
      <InputLabel htmlFor="autocomplete-address-input" />
      <TextField
        data-cy="autocomplete-address-input"
        fullWidth
        id="autocomplete-address-input"
        label="Address"
        placeholder="Address Lookup"
        value={
          localAddress !== undefined
            ? [
                localAddress.line_1,
                localAddress.line_2,
                localAddress.postal_town,
                localAddress.postcode,
                localAddress.country
              ]
                .filter(Boolean)
                .join(', ')
            : inputAddress
        }
        variant="outlined"
        inputRef={inputRef}
        onChange={(e) => {
          setInputAddress(e.target.value);
          setIsAddressSelected(false);
        }}
        InputProps={{
          startAdornment: icon && (
            <RoomIcon style={{ paddingRight: theme.spacing(1) }} />
          )
        }}
        error={!isAddressSelected && inputAddress !== ''}
        helperText={
          !isAddressSelected && inputAddress !== ''
            ? 'Invalid or incomplete address'
            : ''
        }
      />
    </div>
  );
};

export default AutocompleteAddress;
