import { Dashboard as DashboardLayout } from 'layouts';
import { FileStorage } from 'components/FileStorage';
import { CustomFilePond } from 'components/FilePond';

const Component = () => {
  return (
    <DashboardLayout title="File Storage">
      <FileStorage global />
    </DashboardLayout>
  );
};

export default Component;
