import { useEffect, useRef, useState } from 'react';
import {
  Typography,
  IconButton,
  makeStyles,
  List,
  Paper,
  ListItem,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { Alert, Skeleton } from '@material-ui/lab';
import { Close, GetApp } from '@material-ui/icons';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { CustomDialog } from 'common/Dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100vh',
    backgroundColor: '#f5f5f5'
  },
  thumbnailList: {
    overflow: 'auto',
    maxWidth: 200,
    margin: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    padding: theme.spacing(2)
  },
  thumbnail: {
    marginBottom: theme.spacing(1),
    backgroundColor: '#fff',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.72
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
    boxShadow: `0 2px 4px rgba(0, 0, 0, 0.1)`,
    borderRadius: theme.shape.borderRadius
  },
  pageThumbnail: {
    canvas: {
      maxWidth: '100%',
      height: 'auto',
      borderRadius: theme.shape.borderRadius
    }
  },
  documentWrapper: {
    flex: 1,
    overflow: 'auto',
    overflowY: 'scroll',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: theme.spacing(2),
    backgroundColor: '#f5f5f5',
    borderRadius: theme.shape.borderRadius
  },
  pageContainer: {
    boxShadow: `0 2px 8px rgba(0, 0, 0, 0.15)`,
    borderRadius: theme.shape.borderRadius,

    marginBottom: theme.spacing(2),
    backgroundColor: '#fff',
    '& canvas': {
      display: 'block',
      maxWidth: '100%',
      width: 'auto',
      borderRadius: theme.shape.borderRadius
    },
    margin: 'auto',
    overflow: 'visible'
  }
}));

const PDFViewerComponent = ({
  fileUrl,
  setOpenPDF,
  openPDF,
  handlePDFDownload
}) => {
  const classes = useStyles();
  const [numPages, setNumPages] = useState<number>(0);
  const [documentReady, setDocumentReady] = useState<boolean>(false);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setDocumentReady(true);
  };

  const onThumbnailClick = (index: number) => {
    const pageId = `page_${index}`;
    const pageElement = document.getElementById(pageId);

    if (pageElement) {
      pageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const RenderThumbnails = (): JSX.Element => {
    if (numPages > 0 && documentReady) {
      const thumbnails = Array.from({ length: numPages }, (_, index) => (
        <ListItem
          key={`thumb_${index + 1}`}
          className={classes.thumbnail}
          onClick={() => onThumbnailClick(index + 1)}
          button>
          <ListItemText primary={`Page ${index + 1}`} />
          <Page
            className={classes.pageThumbnail}
            width={150}
            pageNumber={index + 1}
            renderAnnotationLayer={false}
            renderTextLayer={false}
          />
        </ListItem>
      ));

      return (
        <Document file={fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
          {thumbnails}
        </Document>
      );
    }

    return <div />;
  };

  return (
    <CustomDialog
      open={openPDF}
      handleClose={() => setOpenPDF(false)}
      hasDownload={{
        tooltip: 'Download PDF',
        action: handlePDFDownload,
        boolean: true
      }}
      alert={{
        title: `PDF Viewer (Total Pages: ${numPages})`,
        description: `Agreeing will lock this object in "Read-Only" mode and prevent further edits.`,
        type: 'warning'
      }}>
      {fileUrl && (
        <div className={classes.root}>
          <List component={Paper} className={classes.thumbnailList}>
            <RenderThumbnails />
          </List>
          <Document
            file={fileUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            className={classes.documentWrapper}>
            {Array.from(new Array(numPages), (_, index) => (
              <div
                id={`page_${index + 1}`}
                key={`page_${index + 1}`}
                className={classes.pageContainer}>
                <Page pageNumber={index + 1} width={window.innerWidth * 0.6} />
              </div>
            ))}
          </Document>
        </div>
      )}
    </CustomDialog>
  );
};

export default PDFViewerComponent;
