interface UtiityState {
  updating: boolean;
  landingpage: boolean;
  openQuoteCalc: number;
}

interface UPDATING {
  type: 'UPDATING';
  payload: boolean;
}

interface SET_LANDING_PAGE {
  type: 'SET_LANDING_PAGE';
  payload: boolean;
}

interface SET_OPENQUOTECALC {
  type: 'SET_OPENQUOTECALC';
  payload: number;
}

type UtilityActions = UPDATING | SET_LANDING_PAGE | SET_OPENQUOTECALC;

const INITIAL_STATE = {
  updating: false,
  landingpage: false,
  openQuoteCalc: 0
};

const utilityReducer = (
  state = INITIAL_STATE,
  action: UtilityActions
): UtiityState => {
  switch (action.type) {
    case 'UPDATING':
      return { ...state, updating: !state.updating };

    case 'SET_LANDING_PAGE':
      return { ...state, landingpage: true };

    case 'SET_OPENQUOTECALC':
      return { ...state, openQuoteCalc: action.payload };

    default:
      return state;
  }
};

export default utilityReducer;
