import { gql } from '@apollo/client';

// Relationship API For Action "List For Keywords"
export const GET_RELATIONSHIPS_LIST_FOR_KEYWORD = gql`
  query GetRelationships(
    $baseUrl: String
    $action: RelationShipAction
    $userInstanceId: Int
    $keyword: String
  ) {
    GetRelationships(
      action: $action
      baseURL: $baseUrl
      UserInstanceId: $userInstanceId
      keyword: $keyword
    ) {
      ... on RelationshipList {
        Items {
          Id
          Title
          PrimaryUserInstanceId
          RelatedUserInstanceId
          Status
          ProcessInstanceId
          AssignedUserDefinitionId
          RelatedUserDefinitionId
        }
      }
      ... on UserInstanceList {
        Items {
          Id
          Title
          UserDefinitionId
          UserInstanceEmail
          UserInstancePassword
          SystemAccess
          RelationshipStatus
        }
      }
    }
  }
`;

// Relationship API For Action "List Related To Assigned"
export const GET_RELATIONSHIPS_RELATED_TO_ASSIGNED = gql`
  query GetRelationships(
    $baseUrl: String
    $action: RelationShipAction
    $processInstanceId: Int
    $assignedUserDefinitionId: Int
    $relatedUserDefinitionId: Int
  ) {
    GetRelationships(
      baseURL: $baseUrl
      action: $action
      ProcessInstanceId: $processInstanceId
      AssignedUserDefinitionId: $assignedUserDefinitionId
      RelatedUserDefinitionId: $relatedUserDefinitionId
    ) {
      ... on RelationshipList {
        Items {
          Id
          Title
          PrimaryUserInstanceId
          RelatedUserInstanceId
          Status
          ProcessInstanceId
          AssignedUserDefinitionId
          RelatedUserDefinitionId
        }
      }
      ... on UserInstanceList {
        Items {
          Id
          Title
          UserDefinitionId
          UserInstanceEmail
          UserInstancePassword
          SystemAccess
          RelationshipStatus
        }
      }
    }
  }
`;

// Relationship API For Action "List"
export const GET_RELATIONSHIPS_LIST = gql`
  query GetRelationships(
    $action: RelationShipAction
    $baseUrl: String
    $userInstanceId: Int
    $userDefinitionId: Int
  ) {
    GetRelationships(
      action: $action
      baseURL: $baseUrl
      UserInstanceId: $userInstanceId
      UserDefinitionId: $userDefinitionId
    ) {
      ... on RelationshipList {
        Items {
          Id
          Title
          PrimaryUserInstanceId
          RelatedUserInstanceId
          Status
          ProcessInstanceId
          AssignedUserDefinitionId
          RelatedUserDefinitionId
        }
      }
      ... on UserInstanceList {
        Items {
          Id
          Title
          ItemOrder
          LastModified
          IsPublished
          UserDefinitionId
          UserInstanceEmail
          UserInstancePassword
          OwnerUserInstanceId
          SystemAccess
          ProfilePicture
          ProxyUserInstanceId
          ThirdPartyId
          RelationshipStatus
          RelatedUserPermission
          ConsoleUserInstanceId
          SmtpHost
          SmtpPort
          SmtpSSL
          SmtpUsername
          SmtpPassword
          FromName
          FromAddress
          LogoURL
          DefaultMessage
          EmailFooter
          DocumentHeader
          DocumentFooter
          NickName
          TelephoneNumber
        }
      }
    }
  }
`;
