import axios from 'axios';
import { createNotification } from 'react-redux-notify';

import { store } from 'redux/store';
import { errorNotif, successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';
import { firebase } from 'redux/firebase';
import { ISender } from 'components/Marketing/Senders/types';
import { isPendingList } from 'components/Marketing/Lists/functions';

const { dispatch } = store;
const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

interface UpsertEmail {
  userId: number;
  subject: string;
  listId: string;
  listName: string;
  selectedSender: ISender;
  injectedhtml: string;
  designId: string;
  customArgs: any;
  campaignId?: string;
}

export const upsertCampaign = async ({
  userId,
  subject,
  listId,
  listName,
  selectedSender,
  injectedhtml,
  designId,
  customArgs,
  campaignId
}: UpsertEmail): Promise<boolean | void> => {
  const isEditMode = !!campaignId;

  try {
    if (!selectedSender) throw new Error('Please select a Sender');
    if (!listId || !listName) throw new Error('Please select a List');
    if (!designId) throw new Error('Please select a Design');
    if (await isPendingList(listId))
      throw new Error(
        `Please Make sure all of the Contacts in your List have a "Ready" Status!`
      );
  } catch (e: any) {
    dispatch(createNotification(errorNotif(e.message)));

    const valid = false;
    return valid;
  }

  const minutesToAdd = 2;
  const currentDate = new Date();
  const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);

  const data = {
    hostname,
    userInstanceId: userId,
    name: subject,
    send_to: {
      list_ids: [listId]
    },
    send_at: futureDate.toISOString(),
    email_config: {
      suppression_group_id: 17868, // currently just using a global suppression group.
      sender_id: selectedSender.id,
      html_content: injectedhtml,
      subject
    },
    listName,
    listId,
    designId,
    customArgs,
    senderFromEmail: selectedSender.from_email,
    senderReplyEmail: selectedSender.reply_to
  };

  const token = await firebase.auth().currentUser?.getIdToken();
  const url = isEditMode
    ? `${process.env.REACT_APP_PROXY}/marketing/update-campaign/${campaignId}`
    : `${process.env.REACT_APP_PROXY}/marketing/create-campaign`;

  const method = isEditMode
    ? axios.patch(url, data, { headers: { token } })
    : axios.post(url, data, { headers: { token } });

  return method
    .then((res) => {
      if (res?.data?.sendgrid[0]?.statusCode === 201) {
        const { status, id } = res?.data?.sendgrid[1];

        dispatch(
          createNotification(successNotif(`Campaign Status Updated: ${status}`))
        );

        if (!isEditMode)
          gtag.event({
            feature: 'Marketing',
            action: 'Campaign Create: Success',
            message: `${id}, ${status}`
          });
      }
    })
    .catch((e) => {
      BugTracker.notify(e);

      const errors = e.response?.data?.response?.body?.errors;

      if (errors) {
        errors.forEach((error: { field: string; message: string }) => {
          dispatch(
            createNotification(errorNotif(`${error.message}: ${error.field}`))
          );
        });
      }
    });
};
