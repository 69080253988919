import { black, white, grey } from '../../common/colors';

export default {
  type: 'light',
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4'
  },
  primary: {
    contrastText: white,
    light: '#9246FF',
    lighttrans: '#9246FF66',
    main: '#440E91',
    maintrans: '#440E9166',
    dark: '#18181B',
    darktrans: '#18181B66'
  },
  secondary: {
    contrastText: white,
    main: '#9246FF',
    light: '',
    dark: 'rgba(0,0,0,100)'
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08'
  },
  text: {
    primary: '#12161B',
    secondary: grey[500],
    disabled: '#A6B1BB'
  },
  background: { paper: '#fff', default: '#f8f8f8' },
  border: '#DBDBDB',
  divider: '#DBDBDB'
};
