import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import Signature from '../Signature';
import Select from '../Select';
import AppsIcon from '@material-ui/icons/Apps';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';

import { Editor } from 'react-draft-wysiwyg';
import {
  convertFromRaw,
  convertFromHTML,
  ContentState,
  EditorState
} from 'draft-js';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Typography from '@material-ui/core/Typography';
import { stateToHTML } from 'draft-js-export-html';

const useStyles = makeStyles((theme) => ({
  root: { minWidth: 300 },
  notfull: {},
  label: { fontSize: 10 },
  textArea: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1)
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  readOnly: {
    color: 'red !important',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    borderColor: 'blue'
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

const Component = (props) => {
  let {
    readOnly,
    style,
    type,
    title,
    shrink,
    handleChange,
    required,
    value,
    helperText,
    options,
    disabled,
    fullWidth,
    error,
    importVal,
    importCalcVal,
    multiline,
    onBlur,
    instance
  } = props;
  const ref = React.createRef();
  const classes = useStyles(readOnly);

  if (type === 'Date') {
    shrink = true;
    // if (process.env.REACT_APP_TYPE === 'full')
    //   return (
    //     <DateField
    //       title={title}
    //       handleChange={handleChange}
    //       value={value}
    //       readOnly={readOnly}
    //     />
    //   );
  }

  if (type === 'Whole Number') type = 'number';
  if (type === 'Decimal') type = 'number';
  // if (type === 'Document') type = 'file';
  if (type === 'Document') {
    if (readOnly || value) {
      const getAndGoToFile = (value) => {
        fetch(value).then(async (res) => {
          const file = await res.blob();
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        });
      };
      if (!value) return <div />;
      return (
        <div>
          <div>{title}</div>
          <Link href="#" onClick={() => getAndGoToFile(value)}>
            link
          </Link>
        </div>
      );
    }

    return (
      <input
        type="file"
        onChange={(e) => {
          let reader = new FileReader();
          if (e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = () => handleChange(reader.result);
            reader.onerror = (e) => console.log(e);
          }
        }}
      />
    );
  }

  if (type === 'Signature')
    return (
      <Signature
        title={title}
        handleChange={handleChange}
        value={value}
        readOnly={readOnly}
      />
    );

  if (type === 'Drop-down') {
    if (readOnly) return value;
    return (
      <Select
        fullWidth={fullWidth}
        title={title}
        options={options}
        handleChange={handleChange}
        value={value}
      />
    );
  }

  if (type === 'Tickbox') {
    return (
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            onChange={(e) => handleChange(e.target.checked)}
            name={title}
            color="primary"
          />
        }
        label={title}
      />
    );
  }

  if (type === 'Formatted Text') {
    let editorState = EditorState.createEmpty();
    if (value) {
      const blocksFromHTML = convertFromHTML(decodeURIComponent(value));
      let state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      editorState = EditorState.createWithContent(state);
    }

    return (
      <div className={!readOnly && classes.textArea}>
        {!readOnly && <Typography>{title}</Typography>}
        <Editor
          defaultEditorState={editorState}
          // toolbarHidden={readOnly}
          readOnly={readOnly}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
          toolbar={{
            options: ['inline', 'list'],
            inline: { options: ['bold', 'italic', 'underline'] },
            list: { options: ['unordered'] }
          }}
          onContentStateChange={(contentState) => {
            const html = stateToHTML(convertFromRaw(contentState));
            handleChange(encodeURIComponent(html));
          }}
        />
      </div>
    );
  }

  if (readOnly) return <Typography>{value ? value : '...'}</Typography>;

  return (
    <React.Fragment>
      <FormControl margin="dense" variant="outlined" fullWidth={fullWidth}>
        <InputLabel htmlFor="outlined-adornment-password" shrink={shrink}>
          {title}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          style={style}
          error={error}
          value={value}
          type={type}
          label={title}
          className={classes.root}
          variant={readOnly ? 'standard' : 'outlined'}
          margin="dense"
          onChange={(e) => handleChange(e.target.value)}
          onBlur={onBlur}
          required={required}
          // helperText={helperText}
          disabled={disabled}
          multiline={multiline}
          labelWidth={70}
          endAdornment={
            Boolean(importVal) && (
              <IconButton
                size="small"
                color="primary"
                className={classes.iconButton}
                onClick={importCalcVal}
                aria-label="directions">
                <AppsIcon />
              </IconButton>
            )
          }
        />
      </FormControl>
    </React.Fragment>
  );
};

export default Component;
