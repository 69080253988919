import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import { IRedflagAPIData, IShareholder } from 'types/redflagInterfaces';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' },
  detail: { padding: theme.spacing(2), margin: theme.spacing(2) }
}));

const Currency = ({ value }: { value: string | number }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

const Percentage = ({ value }: { value: string | number }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    suffix={'%'}
    decimalScale={2}
  />
);

const Quantity = ({ value }: { value: string | number }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    decimalScale={0}
  />
);

const Component = ({ company }: { company: IRedflagAPIData }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <Table
        title="Shareholders"
        data={company?.shareholders}
        columns={[
          {
            title: 'Name',
            render: (rowData: IShareholder) =>
              rowData.attributes.first_name
                ? rowData.attributes.first_name
                : rowData.attributes.surname
          },
          {
            title: 'Share Percentage',
            render: (rowData: IShareholder) => (
              <Percentage value={rowData.attributes.class_percentage} />
            )
          },
          {
            title: 'Share Quantity',
            render: (rowData: IShareholder) => (
              <Quantity value={rowData.attributes.share_quantity} />
            )
          },
          {
            title: 'Share Value',
            render: (rowData: IShareholder) => (
              <Currency value={rowData.attributes.share_value} />
            )
          }
        ]}
        options={{ pageSize: 8 }}
      />
    </Paper>
  );
};

export default Component;
