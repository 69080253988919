import Typography from '@material-ui/core/Typography';
import { Box, Button, Grid, Paper, TextField } from '@material-ui/core';

import { theme } from 'theme';
import { Alert } from '@material-ui/lab';
import { useState } from 'react';

const FileNotes = ({
  handleNoteChange
}: {
  handleNoteChange: (event: string) => void;
}) => {
  const [inputValues, setInputValues] = useState<string>('');

  return (
    <Grid container direction="column">
      <Paper>
        <Grid item xs={12}>
          <Alert
            style={{
              width: '100%',
              marginBottom: 0,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0
            }}
            severity="info">
            <Typography style={{ fontWeight: 'bold' }}>
              Each File Note you create will be automatically be saved into the
              timeline.
            </Typography>
          </Alert>
        </Grid>
        <Grid item xs={12}>
          <Grid
            container
            style={{ padding: theme.spacing(2) }}
            alignItems="center"
            spacing={1}>
            <Grid item xs>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Type Your Note Here..."
                value={inputValues}
                onChange={(e) => setInputValues(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  if (inputValues !== '') {
                    handleNoteChange(inputValues);
                    setInputValues('');
                  }
                }}
                color="primary"
                variant="contained">
                Save
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default FileNotes;
