import { CompleteObjectInstance, FieldInstance } from 'types/interfaces';

/**
 * Fetches the FieldInstance from either FieldInstanceDict or FieldInstanceList
 * based on the availability of FieldInstanceDict. It prioritizes FieldInstanceDict if present.
 *
 * @param {CompleteObjectInstance} item - The CompleteObjectInstance containing the fields.
 * @returns {FieldInstance[]} An array of FieldInstance objects.
 */

//! Please Beware Changing This File Without Proper Testing Could Break The Application.
export const getFieldInstances = (
  CompleteObjectInstance: CompleteObjectInstance | undefined
): FieldInstance[] => {
  if (!CompleteObjectInstance) return [] as FieldInstance[];
  const fieldInstancesMap: Map<number, FieldInstance> = new Map();
  if (
    CompleteObjectInstance.FieldInstanceDict &&
    Object.keys(CompleteObjectInstance.FieldInstanceDict).length > 0
  ) {
    Object.values(CompleteObjectInstance.FieldInstanceDict).forEach(
      (FieldInstance: FieldInstance) => {
        fieldInstancesMap.set(FieldInstance.FieldDefinitionId, FieldInstance);
      }
    );
  }

  if (CompleteObjectInstance.FieldInstanceList) {
    CompleteObjectInstance.FieldInstanceList.forEach(
      (FieldInstance: FieldInstance) => {
        const existingInstance = fieldInstancesMap.get(
          FieldInstance.FieldDefinitionId
        );

        if (!existingInstance || !existingInstance.FieldValue) {
          fieldInstancesMap.set(FieldInstance.FieldDefinitionId, FieldInstance);
        }
      }
    );
  }

  return Array.from(fieldInstancesMap.values());
};

/**
 * @param {FieldInstance[]} FieldInstanceList - The list of field instances to check.
 * @returns {boolean} True if all fields have non-empty values, false otherwise.
 */

export const hasFields = (FieldInstanceList: FieldInstance[]): boolean => {
  const hasFields = FieldInstanceList.every((FieldInstance: FieldInstance) => {
    return FieldInstance.FieldValue !== '';
  });

  if (hasFields) return true;
  return false;
};
