import {
  FormControl,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { useContext, useEffect, useRef, useState } from 'react';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { useTypedSelector } from 'redux/reducers';
import { RecentActors, Person, PeopleAlt, Search } from '@material-ui/icons';
import {
  CompleteUserInstance,
  UserDefinition,
  UserInstance
} from 'types/interfaces';
import { CalendarContext } from '../../context/CalendarContext';
import { theme } from 'theme';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { Alert } from '@material-ui/lab';
import { IGuest } from 'components/Calendar/interface';
import { validateEmail } from 'helpers/validateEmail';
import { useIsAuthenticated } from '@azure/msal-react';
import { getRelationship } from 'redux/actions/GraphQlActions';

const CalendarRelationship = () => {
  const isAuth = useIsAuthenticated();
  const { formValues, setFormValues, eventDialogOpen, edit, timelineEvents } =
    useContext(CalendarContext);

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const user = useTypedSelector((s) => s.user.user);
  const token = useTypedSelector((s) => s.user.auth.token);
  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const [currentItem, setCurrentItem] = useState<{
    UserDefinition?: { label: string; value: number };
    UserInstance?: { label: string; value: number; email: string };
  }>({});

  const [selectedRelatedUser, setSelectedRelatedUser] = useState<{
    value: number;
    label: string;
    email: string;
  }>();

  const [UserInformation, setUserInformation] = useState<
    Array<{
      UserDefinition: { label: string; value: number };
      UserInstance: { value: number; label: string; email: string };
    }>
  >([]);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [RelationshipUI, setRelationshipUI] = useState<
    { value: number; label: string; email: string }[]
  >([]);

  const getUserInstanceSummaryListMethod = async (value: number) => {
    const UserDefinitionId: number = value;
    const res = await getUserInstanceSummaryList({
      token,
      UserDefinitionId
    });

    if (res.data) {
      let optionsIns: { value: number; label: string; email: string }[] = [];
      Object.keys(res.data).forEach((key) => {
        const completeUserInstance = res.data[key] as CompleteUserInstance;
        const {
          UserInstance: { Id, Title, UserInstanceEmail }
        } = completeUserInstance;

        if (!formValues.invitedUserInstanceIds.includes(Id)) {
          optionsIns.push({
            value: Id,
            label: Title,
            email: UserInstanceEmail
          });
        }
      });

      optionsIns.sort((a, b) => a.label.localeCompare(b.label));
      setRelationshipUI(optionsIns);
    }
  };

  const [relatedUsers, setRelatedUsers] = useState<UserInstance[]>([]);
  useEffect(() => {
    const getConnectedContacts = async () => {
      const response = (await getRelationship({
        action: 'LIST',
        UserInstanceId: user.Id,
        UserDefinitionId: user.UserDefinitionId,
        baseUrl
      })) as UserInstance[] | undefined;

      if (response) setRelatedUsers(response);
    };

    getConnectedContacts();
  }, []);

  const [UDList, setUDList] = useState<UserDefinition[]>([]);
  useEffect(() => {
    let options: UserDefinition[] = [];
    const filteredUserDefinitionList = UserDefinitionList?.filter(
      (item: UserDefinition) =>
        [
          'Customers',
          'Contacts',
          'Suppliers',
          'Brokers',
          'Lenders',
          'Introducer'
        ].includes(item.Title)
    );

    filteredUserDefinitionList?.forEach((item: any) => {
      item.value = item.Id;
      item.label = item.Title;
      options.push(item);
    });

    setUDList(options);
  }, []);

  useEffect(() => {
    const newUserInformation: IGuest[] = [];
    const newUserInstanceId: number[] = [];

    UserInformation.forEach(
      (element: {
        UserDefinition: { label: string; value: number };
        UserInstance: { value: number; label: string; email: string };
      }) => {
        newUserInformation.push({
          title: element.UserInstance.label,
          UserInstanceId: element.UserInstance.value,
          UserDefinitionId: element.UserDefinition.value,
          status: 0,
          UserInstanceEmail: element.UserInstance.email
        });

        newUserInstanceId.push(element.UserInstance.value);
      }
    );

    setFormValues({
      ...formValues,
      guests: newUserInformation,
      invitedUserInstanceIds: newUserInstanceId
    });
  }, [UserInformation]);

  const filteredRelationshipUI = RelationshipUI.filter((item) =>
    item.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    if (eventDialogOpen && eventDialogOpen.event) {
      const guests = eventDialogOpen.event.extendedProps.guests;
      if (edit && guests) {
        const newUserInstances = guests.map((guest) => {
          const {
            UserDefinitionId: UserDefinitionId,
            UserInstanceEmail: email,
            UserInstanceId: UserInstanceId,
            title: label
          } = guest;

          return {
            UserDefinition: { label: label, value: UserDefinitionId },
            UserInstance: { value: UserInstanceId, label, email }
          };
        });

        setUserInformation((prevUserInformation) => [
          ...prevUserInformation,
          ...newUserInstances
        ]);
      }
    }
  }, [edit]);

  /**
   * Sets up event guest information for both the customer and primary contact within a deal context.
   * This effect will only execute when both a primary contact and customer are present in the deal.
   *
   * The guests are added as follows:
   * 1. Customer - Using UserDefinitionID (523)
   * 2. Primary Contact - Using their specific UserDefinitionId
   *
   * This information is used to register these contacts as guests for an event that
   * the broker will be creating. Each guest requires both UserDefinition (role/type information)
   * and UserInstance (specific user details) to be properly registered.
   *
   * @dependency {deal} - Requires deal object with primaryContact and customers.selected
   * @effects Updates userInformation state with both customer and primary contact details
   */
  useEffect(() => {
    if (deal?.primaryContact && deal?.customers?.selected) {
      const selectedCustomer = deal.customers.selected[0];
      const customerInfo = {
        UserDefinition: {
          label: selectedCustomer.Title,
          value: 523
        },
        UserInstance: {
          label: selectedCustomer.Title,
          value: selectedCustomer.Id,
          email: ''
        }
      };

      const primaryContactInfo = {
        UserDefinition: {
          label: deal.primaryContact.Title,
          value: deal.primaryContact.UserDefinitionId
        },
        UserInstance: {
          label: deal.primaryContact.Title,
          value: deal.primaryContact.Id,
          email: deal.primaryContact.UserInstanceEmail ?? ''
        }
      };

      setUserInformation((prevUserInformation) => {
        const customerExists = prevUserInformation.some(
          (info) => info.UserInstance.value === customerInfo.UserInstance.value
        );

        const primaryContactExists = prevUserInformation.some(
          (info) =>
            info.UserInstance.value === primaryContactInfo.UserInstance.value
        );

        const newEntries = [
          ...(customerExists ? [] : [customerInfo]),
          ...(primaryContactExists ? [] : [primaryContactInfo])
        ];

        return newEntries.length > 0
          ? [...prevUserInformation, ...newEntries]
          : prevUserInformation;
      });
    }
  }, [deal]);

  useEffect(() => {
    if (timelineEvents && timelineEvents.UserInstance) {
      const {
        UserInstance: {
          UserDefinitionId: UserDefinitionId,
          UserInstanceEmail: email,
          Id: UserInstanceId,
          Title: label
        }
      } = timelineEvents;

      const newUserInformation = {
        UserDefinition: { label: label, value: UserDefinitionId },
        UserInstance: { value: UserInstanceId, label, email }
      };

      const userExists = formValues.guests.some(
        (guest) => guest.UserInstanceId === UserInstanceId
      );

      if (!userExists) {
        setUserInformation((prevUserInformation) => [
          ...prevUserInformation,
          newUserInformation
        ]);
      }
    }
  }, [timelineEvents]);

  if (formValues.eventType === 'TODO') {
    return <div />;
  }

  return (
    <div
      style={{
        border: `2px ${theme.palette.grey[200]} solid`,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
      }}>
      {relatedUsers.length > 0 && (
        <>
          <div style={{ paddingTop: theme.spacing(1) }} />
          <TextField
            data-cy="related-users"
            variant="outlined"
            fullWidth
            select
            label="Related Users"
            value={
              (selectedRelatedUser && selectedRelatedUser.value) ||
              'Choose Related Users'
            }
            onChange={({ target: { value } }) => {
              const UserInstance = relatedUsers.find(
                (el) => el.Id === parseInt(value)
              );

              setSelectedRelatedUser({
                value: UserInstance?.Id || 0,
                label: UserInstance?.Title || '',
                email: UserInstance?.UserInstanceEmail || ''
              });

              let updatedUserInformation = [...UserInformation];
              updatedUserInformation.push({
                UserDefinition: {
                  value: UserInstance?.UserDefinitionId || 0,
                  label: ''
                },
                UserInstance: {
                  value: parseInt(value),
                  label: UserInstance?.Title || '',
                  email: UserInstance?.UserInstanceEmail || ''
                }
              });

              setUserInformation(updatedUserInformation);
            }}
            InputProps={{
              startAdornment: (
                <PeopleAlt style={{ paddingRight: theme.spacing(1) }} />
              )
            }}>
            <MenuItem disabled value={'Choose Related Users'}>
              Choose Related Users
            </MenuItem>
            {relatedUsers.map((object, index) => (
              <MenuItem key={index} value={object.Id}>
                {object.Title}
              </MenuItem>
            ))}
          </TextField>
          <div style={{ paddingBottom: theme.spacing(1) }} />
        </>
      )}

      <div style={{ paddingBottom: theme.spacing(1) }} />

      <TextField
        data-cy="user-definition"
        variant="outlined"
        fullWidth
        select
        label="Choose User Definition"
        helperText="Please Select The Guests Data Group"
        value={currentItem.UserDefinition?.value || 'Choose Data Group'}
        onChange={({ target: { value } }) => {
          getUserInstanceSummaryListMethod(parseInt(value));
          const UserDefinition = UDList.find((el) => el.Id === parseInt(value));

          if (UserDefinition) {
            setCurrentItem({
              ...currentItem,
              UserDefinition: {
                value: UserDefinition.Id,
                label: UserDefinition.Title
              },
              UserInstance: {
                value: 0,
                label: '',
                email: ''
              }
            });
          }
        }}
        InputProps={{
          startAdornment: (
            <RecentActors style={{ paddingRight: theme.spacing(1) }} />
          )
        }}>
        <MenuItem disabled value={'Choose Data Group'}>
          Choose Data Group
        </MenuItem>
        {UDList.map((item) => (
          <MenuItem data-cy="data-group-item" key={item.Id} value={item.Id}>
            {item.Title}
          </MenuItem>
        ))}
      </TextField>

      {RelationshipUI.length > 0 && (
        <>
          <div style={{ paddingBottom: theme.spacing(2) }} />
          <FormControl variant="outlined" fullWidth>
            <InputLabel>Choose User Instance</InputLabel>
            <Select
              onClose={() => setSearchQuery('')}
              label="Choose User Instance"
              value={currentItem.UserInstance?.value || 'Choose Data Record'}
              onChange={({ target: { value } }) => {
                const stringValue = value as string;
                if (currentItem.UserDefinition) {
                  const UserInstance = RelationshipUI.find(
                    (el) => el.value === parseInt(stringValue)
                  );

                  setCurrentItem({
                    ...currentItem,
                    UserInstance: {
                      value: parseInt(stringValue),
                      label: UserInstance?.label || '',
                      email: UserInstance?.email || ''
                    }
                  });

                  let updatedUserInformation = [...UserInformation];
                  updatedUserInformation.push({
                    UserDefinition: {
                      value: currentItem.UserDefinition.value,
                      label: currentItem.UserDefinition.label
                    },
                    UserInstance: {
                      value: parseInt(stringValue),
                      label: UserInstance?.label || '',
                      email: UserInstance?.email || ''
                    }
                  });

                  setUserInformation(updatedUserInformation);
                  setSearchQuery('');
                }
              }}
              startAdornment={
                <Person style={{ paddingRight: theme.spacing(1) }} />
              }
              MenuProps={{
                autoFocus: false,
                disableAutoFocusItem: true,
                disableEnforceFocus: true,
                disableAutoFocus: true,
                MenuListProps: {
                  subheader: (
                    <ListSubheader style={{ padding: theme.spacing(1) }}>
                      <Paper style={{ height: 37 }}>
                        <TextField
                          size="small"
                          variant="outlined"
                          autoFocus
                          placeholder="Type to search..."
                          fullWidth
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Search />
                              </InputAdornment>
                            )
                          }}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key !== 'Escape') {
                              e.stopPropagation();
                            }
                          }}
                        />
                      </Paper>
                    </ListSubheader>
                  )
                }
              }}>
              <MenuItem disabled value={'Choose Data Record'}>
                Choose Data Record
              </MenuItem>
              {Object.values(RelationshipUI)
                .filter((item) =>
                  item.label.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .filter((item) => item.label !== '')
                .map((item: { value: number; label: string }) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </>
      )}

      {UserInformation.length > 0 && (
        <>
          <div style={{ paddingTop: theme.spacing(2) }} />
          <Alert
            severity="info"
            style={{
              width: '100%',
              borderEndEndRadius: 0,
              borderEndStartRadius: 0
            }}>
            <Typography>Currently Invited: {UserInformation.length}</Typography>
          </Alert>
          <Paper
            style={{
              width: '100%',
              borderStartStartRadius: 0,
              borderStartEndRadius: 0
            }}>
            <div style={{ padding: theme.spacing(1) }}>
              {UserInformation.map(
                (
                  item: {
                    UserDefinition: { value: number; label: string };
                    UserInstance: {
                      value: number;
                      label: string;
                      email: string;
                    };
                  },
                  index: number
                ) => {
                  console.log({ item });
                  return (
                    <div key={index}>
                      <QuickUserFromId
                        deleteFunction={() => {
                          const updatedUserInformation = UserInformation.filter(
                            (_item, idx) => idx !== index
                          );
                          setUserInformation(updatedUserInformation);

                          setCurrentItem({
                            ...currentItem,
                            UserInstance: {
                              value: 0,
                              label: '',
                              email: ''
                            }
                          });
                          setSelectedRelatedUser(undefined);
                        }}
                        deletable
                        UserInstanceId={item.UserInstance.value.toString()}
                      />

                      {!validateEmail(item.UserInstance.email) && isAuth && (
                        <Alert
                          severity="warning"
                          style={{
                            width: '100%',
                            marginTop: theme.spacing(1),
                            marginBottom: theme.spacing(1)
                          }}>
                          <Typography>
                            This User Will Be Restricted From Receiving Emails.
                          </Typography>
                        </Alert>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </Paper>
        </>
      )}
    </div>
  );
};

export default CalendarRelationship;
function getUserInstance(arg0: { action: string; UserInstance: any }) {
  throw new Error('Function not implemented.');
}
