import { firebase } from 'redux/firebase';
import { useTypedSelector } from 'redux/reducers';
import { FieldDefinition, UserInstance } from 'types/interfaces';

export interface IProposalComments {
  comment: string;
  fieldDefinitionId: number;
}

export interface ICustomizeComment {
  parentName: string;
  type: string;
  parentSection: IProposalComments;
}

export const ObjectSaving = async ({
  parentName,
  parentSection,
  type,
  dealTitle,
  user,
  customerId
}: {
  parentName: string;
  parentSection: IProposalComments;
  type: string;
  dealTitle: string;
  user: UserInstance;
  customerId: string;
}) => {
  const userDocRef = firebase
    .firestore()
    .collection('userAccount')
    .doc(user.Id.toString());

  const doc = await userDocRef.get();
  const existingData = doc.data();
  const existingCustomizeComments: ICustomizeComment[] = existingData
    ? existingData['customize_Comments']?.[`${customerId}-${dealTitle}`] ?? []
    : [];

  const updatedElement: ICustomizeComment = {
    parentName,
    type,
    parentSection
  };

  const updatedCustomizeComments = updateCustomizeComments(
    existingCustomizeComments,
    parentName,
    updatedElement
  );

  await userDocRef.update({
    [`customize_Comments.${customerId}-${dealTitle}`]: updatedCustomizeComments
  });
};

export default ObjectSaving;

const updateCustomizeComments = (
  existingCustomizeComments: ICustomizeComment[] | null,
  parentName: string,
  updatedElement: ICustomizeComment
): ICustomizeComment[] => {
  if (!existingCustomizeComments || !Array.isArray(existingCustomizeComments)) {
    return [updatedElement];
  }

  const index = existingCustomizeComments.findIndex(
    (el) => el.parentName === parentName
  );

  if (index >= 0) {
    return existingCustomizeComments.map((el, i) =>
      i === index ? updatedElement : el
    );
  } else {
    return [...existingCustomizeComments, updatedElement];
  }
};
