import { Paper, withStyles, Theme, Grid, useTheme } from '@material-ui/core';
import { useMobile } from 'hooks';
import { FileStorage } from 'components/FileStorage';
import { XpansionIsolated } from 'common/Xpansion';
import DetailView from 'components/Stepper/components/Overview/components/DetailView';
import Attachments from 'components/Stepper/components/Overview/components/AttachmentsLog';
import StepDocumentHTML from 'components/Stepper/components/Documents/StepDocumentHTML';
import isPreviewMode from 'components/Stepper/components/Steps/ActiveStep/helper/previewMode';

interface IMobile {
  isMobile: boolean;
}

const Papered = withStyles((theme: Theme) => ({
  root: (props: IMobile) => ({
    width: props.isMobile ? '100%' : 'none',
    padding: props.isMobile ? 0 : theme.spacing(1),
    background: theme.palette.background.default
  })
}))(Paper);

const FilesAndMessages = () => {
  const theme = useTheme();
  const isMobile = useMobile();

  //* isPreview will only work if the magic link has been injected with preview.
  const isPreview = isPreviewMode(window.location.href, true);
  return (
    <Papered isMobile={isMobile}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <StepDocumentHTML downloadableIcon isQuote />
        </Grid>

        <div>
          <XpansionIsolated
            expanded
            summary="Document Preview"
            style={{ background: theme.palette.background.paper }}>
            <Grid container direction="column">
              <Grid item>
                <Attachments />
              </Grid>
              <Grid item>
                <DetailView />
              </Grid>
            </Grid>
          </XpansionIsolated>

          <XpansionIsolated
            expanded
            summary="Files"
            style={{ background: theme.palette.background.paper }}>
            <Grid container direction="column" spacing={1}>
              <FileStorage hideControls={isPreview} />
            </Grid>
          </XpansionIsolated>
        </div>
      </Grid>
    </Papered>
  );
};

export default FilesAndMessages;
