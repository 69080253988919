import { rrulestr, RRule, Frequency, RRuleStrOptions } from 'rrule';
import { ERecurringEvent } from '../interface';

interface IGenerateRRule {
  recurring: string;
  startDate: Date;
  endDateRRule?: Date;
  interval?: number;
}

interface IRRuleOptions {
  freq?: Frequency;
  byweekday?: any;
  bymonthday?: number;
  bymonth?: number;
  dtstart: Date;
  until?: Date;
  interval?: number;
}

const generateRRule = ({
  recurring,
  startDate,
  endDateRRule,
  interval
}: IGenerateRRule): string | null => {
  let options: IRRuleOptions = { dtstart: startDate };
  if (!startDate) {
    return null;
  }

  if (endDateRRule) {
    options.until = endDateRRule;
  }

  if (interval && interval > 1) {
    options.interval = interval;
  }

  switch (recurring) {
    case ERecurringEvent.Daily:
      options.freq = RRule.DAILY;
      options.until = endDateRRule;
      options.interval = interval;
      break;
    case ERecurringEvent.Weekly:
      options.freq = RRule.WEEKLY;
      options.byweekday =
        RRule[
          startDate
            .toLocaleString('en-GB', { weekday: 'short' })
            .toUpperCase() as keyof typeof RRule
        ];
      options.until = endDateRRule;
      options.interval = interval;
      break;
    case ERecurringEvent.Monthly:
      options.freq = RRule.MONTHLY;
      options.bymonthday = startDate.getDate();
      options.until = endDateRRule;
      options.interval = interval;
      break;
    case ERecurringEvent.Quarterly:
      options.freq = RRule.MONTHLY;
      options.interval = 3;
      options.until = endDateRRule;
      options.bymonthday = startDate.getDate();
      break;
    case ERecurringEvent.Annually:
      options.freq = RRule.YEARLY;
      options.bymonth = startDate.getMonth() + 1;
      options.bymonthday = startDate.getDate();
      options.until = endDateRRule;
      options.interval = interval;
      break;
    default:
      return null;
  }

  const rrule = new RRule(options).toString();
  console.log({ rrule });
  return rrule;
};

export default generateRRule;
