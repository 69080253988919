import {
  Page,
  Image,
  Document,
  View,
  Text,
  StyleSheet
} from '@react-pdf/renderer';
import { decodeFormattedText } from './functions';
import { IProps } from './interface';
import { styles } from './styles';
import Time from 'common/Time';

const RepeatDynamicAssetObject_pdf = ({
  ObjectDefinition,
  objectData,
  currentDeal,
  location,
  settings
}: IProps) => {
  if (ObjectDefinition && objectData && objectData.length > 0) {
    return (
      <Document>
        <Page size="A4" wrap>
          {objectData.map(
            (itemData: { key: string; value: string | number }[], index) => {
              const findMake = itemData.find(
                (element: { key: string; value: string | number }) =>
                  element.key.includes('Make')
              );

              const findModel = itemData.find(
                (element: { key: string; value: string | number }) =>
                  element.key.includes('Model')
              );

              return (
                <View key={index} style={styles.section}>
                  <Text style={styles.title}>
                    {ObjectDefinition?.Title}:{' '}
                    {findMake && findMake.value.toString()}{' '}
                    {findModel && findModel?.value.toString()}
                  </Text>
                  <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
                  <Text style={styles.subTitle}>
                    Deal ID: {currentDeal.ProcessInstance.Id}
                  </Text>
                  <Text style={styles.subTitle}>
                    Date: <Time time={new Date()} type="date" />
                  </Text>
                  <Text style={{ paddingBottom: 6 }} />

                  {itemData.map(
                    (
                      obj: { key: string; value: string | number },
                      idx: number
                    ) => {
                      if (!obj?.key?.includes('Reg Number')) {
                        return (
                          <View
                            style={[
                              styles.item,
                              idx % 2 === 0
                                ? { backgroundColor: 'rgb(239, 239, 240)' }
                                : { backgroundColor: 'rgb(255,255,255)' }
                            ]}>
                            <Text style={{ fontWeight: 'bold' }}>
                              {obj?.key}:{' '}
                            </Text>
                            <Text style={{ marginRight: 10 }}>
                              {decodeFormattedText(obj?.value)}
                            </Text>
                          </View>
                        );
                      }
                    }
                  )}
                </View>
              );
            }
          )}
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <Page size="A4">
        <View>
          <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
        </View>

        <View>
          <Text style={styles.item}>No data to show</Text>
        </View>
      </Page>
    </Document>
  );
}; //END RepeatDynamicAssetObject_pdf

export default RepeatDynamicAssetObject_pdf;
