import React from 'react';
import { tableIcons } from 'components/ProcessTable/tableIcons';
import MaterialTable from '@material-table/core';

const Table = ({ title, data, columns, options, editable, actions }) => {
  return (
    <MaterialTable
      actions={actions}
      columns={columns}
      data={data}
      editable={editable}
      icons={tableIcons}
      options={{ ...options, search: false }}
      title={title}
    />
  );
};

export default Table;
