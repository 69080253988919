import { Grid, Typography } from '@material-ui/core';
import { Event, LocationOn, Description } from '@material-ui/icons';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { theme } from 'theme';
import { grey } from 'common/colors';

const EventBody = ({ eventDialogOpen }) => {
  const eventProps = eventDialogOpen.event.extendedProps;
  return (
    <div
      style={{
        border: `2px ${theme.palette.grey[200]} solid`,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius
      }}>
      <Grid container direction="column" spacing={1}>
        {eventProps && eventProps.location && (
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <LocationOn
                fontSize="medium"
                style={{
                  color: grey[500],
                  width: '30px'
                }}
                color="secondary"
              />
            </Grid>
            <Grid item xs>
              <Typography data-cy="event-address" color="textPrimary">
                {eventProps.address.line_1}
              </Typography>
              <Typography color="textSecondary">
                {eventProps.location}
              </Typography>
            </Grid>
          </Grid>
        )}
        {eventProps && eventProps.description && (
          <Grid item container alignItems="center" spacing={2}>
            <Grid item>
              <Description
                fontSize="medium"
                style={{
                  color: grey[500],
                  width: '24px'
                }}
              />
            </Grid>
            <Grid item xs style={{ overflow: 'auto', wordWrap: 'break-word' }}>
              {eventProps.description}
            </Grid>
          </Grid>
        )}

        {eventProps.calendarOwner !== undefined && (
          <Grid container alignItems="center" spacing={1}>
            {eventProps && eventProps.calendarOwner !== 0 && (
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <Event
                    fontSize="medium"
                    style={{
                      color: grey[500],
                      width: '32px'
                    }}
                  />
                </Grid>

                <Grid item xs>
                  <QuickUserFromId UserInstanceId={eventProps.calendarOwner} />
                </Grid>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default EventBody;
