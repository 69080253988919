import {
  black,
  white,
  grey,
  pink,
  pink_light,
  pink_dark
} from '../../common/colors';

export default {
  type: 'light',
  common: {
    black,
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4'
  },
  primary: {
    contrastText: white,
    light: pink_light,
    lighttrans: `${pink_light}66`,
    main: pink,
    maintrans: `${pink}66`,
    dark: pink_dark,
    darktrans: `${pink_dark}66`
  },
  secondary: {
    contrastText: white,
    main: pink,
    light: '',
    dark: 'rgba(0,0,0,100)'
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E'
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489'
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E'
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08'
  },
  text: {
    primary: '#12161B',
    secondary: grey[500],
    disabled: '#A6B1BB'
  },
  background: { paper: '#fff', default: '#f8f8f8' },
  border: '#DBDBDB',
  divider: '#DBDBDB'
};
