import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InlineTextField from 'components/Fields/InlineTextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { useTypedSelector } from 'redux/reducers';
import {
  CompleteProcessStepDefinitionDict,
  CompleteProcessStepDefinition,
  CompleteObjectDefinition,
  FieldDefinition,
  FieldInstance
} from 'types/interfaces';
import { getSysDoc, getSysDocFileUrl } from 'helpers/documentRetrieval';
import { useProcess } from 'hooks/useProcess';
import { CircularProgress, Link } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { GetProcessFields } from 'redux/database';
import { StepperContext } from 'components/Stepper/context';
import { useRegulated } from 'hooks/useRegulated';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  div: { height: 1, background: theme.palette.primary.contrastText },
  containsNoDoc: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  }
}));

const Component = ({ loadDocuments }: { loadDocuments?: boolean }) => {
  const classes = useStyles();
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const { baseURL } = useTypedSelector((s) => s.config);
  const { currentOverView, currentProcess, stepdefdict, user } = useProcess();
  const { regulated } = useRegulated();

  const [filteredDocuments, setFilteredDocuments] = useState<FieldInstance[]>(
    []
  );
  const subSystemUser = user.SystemAccess === 4;

  const DocumentDict =
    stepdefdict && stepdefdict.DocumentDict ? stepdefdict.DocumentDict : {};
  const isDocs = Object.keys(DocumentDict).length > 0;

  // 1. Find all the Field Definitions that have a Type of document.
  const _seq: number[] =
    currentProcess && currentProcess.ProcessStepDefinitionSequence;
  const _stepObj: CompleteProcessStepDefinitionDict =
    currentProcess && currentProcess.CompleteProcessStepDefinitionDict;
  const _stepArray: CompleteProcessStepDefinition[] = [];
  _seq &&
    _seq.forEach((el) => {
      if (_stepObj[el].ProcessStepDefinition.Id !== _seq[0]) {
        _stepArray.push(_stepObj[el]);
      }
    });

  // 2. Get all the corresponding Field Instances.
  const { processInstanceFields } = useTypedSelector((s) => s.process);
  const _inst: FieldInstance[] | undefined =
    processInstanceFields[currentOverView.ProcessInstanceId];

  const FactFindForm_StepProcessId = [1732, 1585, 2213, 2245, 2233];
  const GDPR_StepProcessId = [
    1731, 1493, 1545, 2214, 2246, 1607, 2286, 2234, 2303
  ];
  const Quote_StepProcessId = [1502, 2209, 2211, 2243, 2297, 2296];
  const CustomerSatisfactionDocument_StepProcessId = [
    2187, 1743, 2188, 2221, 2253, 2329, 2330
  ];
  const CustomerProposalDecision_StepProcessId = [
    1586, 1726, 1609, 2217, 2249, 2366, 2288
  ];

  const CustomerDulyInformation = [2350, 2351, 2352, 2353, 2354, 2360];

  const filterDocumentsRegulated: number[] = [
    ...CustomerProposalDecision_StepProcessId
  ];

  const filterDocumentsIntroducer: number[] = [
    ...FactFindForm_StepProcessId,
    ...GDPR_StepProcessId,
    ...Quote_StepProcessId,
    ...CustomerSatisfactionDocument_StepProcessId
  ];

  const DocumentChecker = () => {
    let exists = false;
    _stepArray.forEach((step: CompleteProcessStepDefinition) => {
      Object.values(step.CompleteObjectDefinitionDict).forEach(
        (CompleteObjectDefinitionDict: CompleteObjectDefinition) => {
          const has: FieldDefinition = Object.values(
            CompleteObjectDefinitionDict.FieldDefinitionDict
          ).find((d: FieldDefinition) => d.FieldType === 'Document');
          if (has) {
            const _fields: FieldInstance[] = _inst?.filter(
              (el: FieldInstance) => el.FieldDefinitionId === has.Id
            );
            if (_fields.length > 0) exists = true;
          }
        }
      );
    });
    return exists;
  };

  const zeroField: FieldInstance[] | undefined = _inst?.filter(
    (el) => el.Id === 0
  );

  let preFilteredZeroField: FieldInstance[] | undefined = zeroField;
  if (subSystemUser && preFilteredZeroField) {
    preFilteredZeroField = zeroField.filter((FieldInstance: FieldInstance) =>
      filterDocumentsIntroducer.includes(parseInt(FieldInstance.FieldValue))
    );
  }

  if (regulated.isRegulatedParty) {
    preFilteredZeroField = preFilteredZeroField?.filter(
      (FieldInstance: FieldInstance) =>
        !filterDocumentsRegulated.includes(parseInt(FieldInstance.FieldValue))
    );
  } else if (!regulated.isRegulatedParty) {
    preFilteredZeroField = preFilteredZeroField?.filter(
      (FieldInstance: FieldInstance) =>
        !CustomerDulyInformation.includes(parseInt(FieldInstance.FieldValue))
    );
  }

  useEffect(() => {
    const handleClosedDealDocuments = async () => {
      const res = await GetProcessFields({
        ProcessInstanceId: currentOverView.ProcessInstanceId,
        ProcessDefinitionId: currentOverView.ProcessDefinitionId,
        action: 'OVERVIEW',
        ProcessStepDefinitionId: currentProcess.ProcessStepDefinitionSequence[0]
      });

      let fitechedZeroField: FieldInstance[] = res.data.filter(
        (el) => el.Id === 0
      );

      if (preFilteredZeroField && fitechedZeroField) {
        const filteredZeroField = fitechedZeroField.filter((f) =>
          preFilteredZeroField?.some((p) => p.FieldValue === f.FieldValue)
        );

        setFilteredDocuments(filteredZeroField);
      }
    };

    if (loadDocuments || isDealClosed || isDealTransferred) {
      handleClosedDealDocuments();
    }
  }, [loadDocuments, isDealClosed, isDealTransferred]);

  const hasProcessInstanceFields =
    preFilteredZeroField && preFilteredZeroField.length > 0
      ? preFilteredZeroField
      : filteredDocuments.length > 0
      ? filteredDocuments
      : [];

  if (!currentProcess) return <div>{'No Data'}</div>;
  return (
    <>
      {hasProcessInstanceFields && (
        <Grid container direction="column" spacing={1}>
          {hasProcessInstanceFields.map((FieldInstance: FieldInstance) => (
            <ZeroFieldStepDocument
              FieldInstance={FieldInstance}
              loadDocuments={loadDocuments}
              key={FieldInstance.Id}
            />
          ))}

          {isDocs &&
            Object.keys(DocumentDict).map((key) => (
              <Grid item key={key}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: 1 }}>
                  <Link
                    href={`${baseURL}/documentAPI.ashx?documentId=${key}`}
                    target="_blank">
                    <Grid container>
                      {DocumentDict[key]}
                      &nbsp;
                      <DoubleArrowIcon />
                    </Grid>
                  </Link>
                </Button>
              </Grid>
            ))}
        </Grid>
      )}

      {DocumentChecker() && (
        <div className={classes.root}>
          {_stepArray.map((step: CompleteProcessStepDefinition, i) => (
            <div key={i}>
              {Object.values(step.CompleteObjectDefinitionDict).map(
                (object: CompleteObjectDefinition, i) => (
                  <Grid container key={i} spacing={1}>
                    {Object.values(object.FieldDefinitionDict).map(
                      (field: FieldDefinition, i) => {
                        const isDoc = field.FieldType === 'Document';

                        if (!isDoc) return;
                        const _fields: FieldInstance[] = _inst?.filter(
                          (el) => el.FieldDefinitionId === field.Id
                        );
                        return (
                          <Grid item key={i} xs>
                            {_fields.map((el: FieldInstance, i) => {
                              if (el.Title.length === 0) return;

                              return (
                                <Card
                                  key={i}
                                  style={{
                                    margin: 12,
                                    minHeight: 150,
                                    display: 'flex',
                                    flexDirection: 'column'
                                  }}>
                                  <CardContent>
                                    <Typography
                                      variant="h6"
                                      color="textSecondary">
                                      {/* Step: */}
                                      {step.ProcessStepDefinition.Title}
                                    </Typography>

                                    <Typography variant="body2">
                                      {/* Object: */}
                                      {object.ObjectDefinition.Title}
                                    </Typography>

                                    <Typography variant="body2">
                                      {/* Field: */}
                                      {field.Title}
                                    </Typography>
                                  </CardContent>
                                  <CardActions style={{ marginTop: 'auto' }}>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="center"
                                      alignItems="center">
                                      <Grid item>
                                        <Button
                                          variant="outlined"
                                          color="primary">
                                          <InlineTextField
                                            readOnly
                                            type={field.FieldType}
                                            value={el.FieldValue}
                                            FieldDefinition={field}
                                            FieldInstance={el}
                                            ObjectDefinition={
                                              object.ObjectDefinition
                                            }
                                            fields={[]}
                                            ProcessStepSelectionType={''}
                                          />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </CardActions>
                                </Card>
                              );
                            })}
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                )
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
}; //END Component

export default Component;

const ZeroFieldStepDocument = ({ FieldInstance, loadDocuments }) => {
  const { Title, FieldValue } = FieldInstance;

  const { currentOverView, prostepdefid, currentProcess } = useProcess();
  const [loading, setLoading] = useState(false);

  const getAndGoToFile = async () => {
    setLoading(true);
    const config = {
      ProcessInstanceId: currentOverView.ProcessInstanceId,
      ProcessStepDefinitionId: loadDocuments
        ? currentProcess.ProcessStepDefinitionSequence[0]
        : prostepdefid,
      DocumentStepDefinitionId: parseInt(FieldValue),
      action: 'GetPDF'
    };

    const res = await getSysDocFileUrl(config);
    if (res) window.open(res);
    setLoading(false);
  };

  return (
    <Grid item key={FieldValue}>
      <Button
        variant="outlined"
        color="primary"
        onClick={getAndGoToFile}
        disabled={loading}>
        <Grid container>
          {Title}
          &nbsp;
          {!loading ? (
            <DoubleArrowIcon />
          ) : (
            <CircularProgress size={'1rem'} style={{ margin: 'auto' }} />
          )}
        </Grid>
      </Button>
    </Grid>
  );
}; //END ZeroFieldStepDocument

//  const preventDefault = (event: React.SyntheticEvent) => {
//    if (fileUrl) {
//      setLoading(true);
//    }
//  };

// const openDocument = async (FieldInstance: FieldInstance) => {
//   // Field Value = Step for Document passed down in zero field step document
//   // const { FieldValue } = FieldInstance;

//   // setLoading(true);
//   const config = {
//     ProcessInstanceId: currentOverView.ProcessInstanceId,
//     ProcessStepDefinitionId: prostepdefid,
//     DocumentStepDefinitionId: parseInt(FieldValue),
//     action: 'GetPDF'
//   };

//   const url = await getSysDoc(config);
//   const url = await getSysDocFileUrl(config);

//   setFileUrl(url);

//   // setLoading(false);
// }; //END openDocument
