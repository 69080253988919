import { useContext, useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography
} from '@material-ui/core';
import { emailByProcess, getCurrentEmail } from 'redux/actions/email';
import Time from 'common/Time';
import { useProcess } from 'hooks/useProcess';
import List from '@material-ui/core/List';
import { theme } from 'theme';
import {
  Message,
  NullableOption,
  Recipient
} from '@microsoft/microsoft-graph-types';
import { Body } from 'components/Stepper/components/MessageHub/components/Conversation';
import Xpansion from 'common/Xpansion';
import { Alert } from '@material-ui/lab';
import { useTypedSelector } from 'redux/reducers';
import { MessageHubProvider } from '../..';

const useStyles = makeStyles(() => ({
  root: { display: 'flex', flexDirection: 'column' },
  list: { display: 'flex' }
}));

interface IEmail {
  EmailBody: string;
  EmailSubject: string;
  Id: number;
  ItemOrder: 0;
  LastModified: string;
  ProcessDefinitionId: number;
  ProcessDefinitionTitle: string;
  ProcessInstanceId: number;
  ProcessInstanceTitle: string;
  ProcessStepDefinitionId: number;
  ProcessStepDefinitionTitle: string;
  UserInstanceEmail: string;
  UserInstanceId: number;
}

interface IEmailObject {
  [key: number]: IEmail;
}

const GlobalSystemInbox = () => {
  const classes = useStyles();
  const { systemEmailLog } = useContext(MessageHubProvider);
  const [merged, setMerged] = useState<Message[]>([] as Message[]);
  const loggedInUser = useTypedSelector((state) => state.user.user);

  const fetchedEmails = () => {
    const newMessages = [] as Message[];
    Object.values(systemEmailLog)
      .sort((a: IEmail, b: IEmail) => {
        const A = a.LastModified;
        const B = b.LastModified;
        if (A && B) return A > B ? -1 : A < B ? 1 : 0;
        return 0;
      })
      .forEach((item: IEmail) => {
        const newMessage: Partial<Message> = {
          id: item.Id.toString(),
          createdDateTime: item.LastModified,
          subject: item.EmailSubject,
          from: {
            emailAddress: {
              address: loggedInUser.UserInstanceEmail,
              name: loggedInUser.Title
            }
          },
          toRecipients: [
            {
              emailAddress: {
                address: item.UserInstanceEmail,
                name: item.UserInstanceEmail
              }
            }
          ],
          bodyPreview: item.EmailBody,
          body: {
            content: item.EmailBody,
            contentType: 'html'
          }
        };
        return newMessages && newMessages.push(newMessage);
      });
    newMessages && setMerged(newMessages);
  };

  useEffect(() => {
    fetchedEmails();
  }, [systemEmailLog]);

  if (!systemEmailLog || merged.length <= 0) {
    return (
      <div style={{ padding: theme.spacing(1) }}>
        <Alert severity="warning" variant="outlined">
          <Typography style={{ fontWeight: 'bold' }}>
            No Global System Emails Found: We were unable to locate any emails
            within the Global System Inbox at this time.
          </Typography>
        </Alert>
      </div>
    );
  }

  return (
    <List className={classes.root} component="nav">
      {Object.values(merged).map((item: Message, idx: number) => {
        return <SystemEmailCard key={idx} message={item} />;
      })}
    </List>
  );
};

export default GlobalSystemInbox;

const useSystemCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white'
    }
  })
);

const SystemEmailCard = ({ message }: { message: Message }) => {
  const classes = useSystemCardStyles();
  const [newBody, setNewBody] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);

  const getSystemEmailBody = async () => {
    const data: IEmail = await getCurrentEmail({ Id: message.id });
    if (data) setNewBody(data.EmailBody);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    getSystemEmailBody();
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Paper elevation={3} style={{ margin: 8 }}>
      <Card
        style={{
          backgroundColor: theme.palette.grey[500]
        }}>
        <CardHeader
          classes={{ title: classes.root }}
          title={message.subject}
          action={
            <Time
              time={message.createdDateTime}
              type="timeago"
              style={{ color: 'white' }}
            />
          }
        />

        <CardContent>
          <ToAndFrom message={message} />

          <div>
            <Xpansion
              summary={message.subject}
              expanded={expanded}
              onChange={handleChange(message.id)}>
              <Body
                body={message.body}
                newBody={newBody}
                attachments={undefined}
                downloadAttachment={undefined}
              />
            </Xpansion>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
};

const ToAndFrom = ({ message }: { message: Message }) => {
  return (
    <Grid
      container
      style={{ color: 'white', fontSize: 12, padding: theme.spacing(1) }}
      direction="column">
      <Grid item>
        <p>
          From: {message.from?.emailAddress?.name}{' '}
          {`(${message.from?.emailAddress?.address})`}
        </p>
      </Grid>
      <Grid item>
        <Grid container direction="row" alignItems="center">
          <p style={{ marginRight: '3px' }}>To:</p>
          {message &&
            message.toRecipients &&
            message?.toRecipients.map(
              (recipient: NullableOption<Recipient>, idx: number, arr: any) => {
                if (recipient) {
                  return (
                    <span key={idx}>
                      {recipient.emailAddress?.name}{' '}
                      {`(${recipient.emailAddress?.address})`}
                      {idx < arr.length - 1 ? ',  ' : ''}
                    </span>
                  );
                }
                return null;
              }
            )}
        </Grid>
      </Grid>
    </Grid>
  );
};
