import firebase from 'firebase';
import { BugTracker } from 'Utils/Bugtracker';
import React from 'react';
import { Rule } from 'types/interfaces';
import { RuleList } from 'redux/database';

export interface IFirestoreAuditLog {
  payload: string;
  timestamp: any;
  userInstanceId: number | string;
  userInstanceEmail: string;
  action: string;
  firebaseStatus: TFirebaseStatus;
  id: string;
  actionFunction?: string;
}

export interface IUseAuditLog {
  payload: string;
  action: string;
  timestamp: Date;
  userInstanceId: number | string;
  userInstanceEmail: string;
  firebaseStatus: TFirebaseStatus;
  dealId: number;
  actionFunction: string;
  ProcessDefinitionId: number;
}

export type TFirebaseStatus =
  | 'Sent'
  | 'Received'
  | 'Accepted'
  | 'Rejected'
  | 'Completed';

export const saveAuditLog = async ({
  payload,
  action,
  dealId,
  timestamp,
  firebaseStatus,
  userInstanceId,
  userInstanceEmail,
  actionFunction,
  ProcessDefinitionId
}: IUseAuditLog) => {
  try {
    const dealDocRef = firebase
      .firestore()
      .collection('deal')
      .doc(dealId.toString());

    if (actionFunction && actionFunction === 'Run rule sequence') {
      const ruleIds = payload.split(',').map((id) => parseInt(id.trim()));
      const RuleListRes = await RuleList({ ProcessDefinitionId });

      for (const ruleId of ruleIds) {
        const rule: Rule = RuleListRes.data.find(
          (rule: Rule) => rule.Id === ruleId
        );

        if (rule) {
          const actionFunctionRuleIds = rule.ActionValue.split(',').map((id) =>
            parseInt(id.trim())
          );

          for (const actionFunctionRuleId of actionFunctionRuleIds) {
            const customId = `log_${Date.now()}_${Math.random()
              .toString(36)
              .substring(2, 15)}`;

            const payloadData: IFirestoreAuditLog = {
              payload: actionFunctionRuleId.toString(),
              action,
              timestamp,
              userInstanceId,
              userInstanceEmail,
              firebaseStatus,
              id: customId,
              actionFunction: rule.ActionFunction
            };

            const dealAuditLogsRef = dealDocRef
              .collection('dealAuditLogs')
              .doc(customId);
            await dealAuditLogsRef.set(payloadData);
          }
        }
      }
    } else {
      const customId = `log_${Date.now()}`;
      const payloadData: IFirestoreAuditLog = {
        payload,
        action,
        timestamp,
        userInstanceId,
        userInstanceEmail,
        firebaseStatus,
        id: customId
      };
      const dealAuditLogsRef = dealDocRef
        .collection('dealAuditLogs')
        .doc(customId);
      await dealAuditLogsRef.set(payloadData);
    }
    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const getAuditLogs = async (
  dealId: number
): Promise<IFirestoreAuditLog[]> => {
  try {
    const dealDocRef = firebase
      .firestore()
      .collection('deal')
      .doc(dealId.toString());

    const dealAuditLogsRef = dealDocRef.collection('dealAuditLogs');
    const snapshot = await dealAuditLogsRef.get();
    if (snapshot.empty) return [];

    let logs: IFirestoreAuditLog[] = [];
    snapshot.forEach((doc) => {
      const logData = doc.data() as IFirestoreAuditLog;
      logs.push({ ...logData });
    });

    return logs;
  } catch (e) {
    BugTracker.notify(e);
    throw e;
  }
};
