import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import {
  useCreditSafe,
  useRedFlagGQLAdaptor
} from 'components/OffkeyCompanyAndDirectorSearch/adaptors';

import { IDirectorCompany } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IconButton } from '@material-ui/core';
import { FindInPage } from '@material-ui/icons';
import Time from 'common/Time';
import { nationalities } from 'components/OffkeyCompanyAndDirectorSearch/components/NationalityData';
import DirectorshipDialog from '../../../Dialog';
import {
  createOGRedflagData,
  IRedflagDirectorArray,
  IRedFlagDirectorship,
  IRedFlagDirectorshipData
} from 'components/OffkeyCompanyAndDirectorSearch/helper';
import { RedflagClient } from 'App';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    background: 'inherit',
    overflow: 'hidden'
  },
  detail: {
    padding: theme.spacing(2),
    width: '100%',
    marginTop: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  nationalityFlag: {
    marginRight: theme.spacing(0.5)
  }
}));

const CompanyDirectorsTable = ({ company }) => {
  const classes = useStyles();

  const [selectedDirector, setSelectedDirector] =
    useState<IRedFlagDirectorship>({} as IRedFlagDirectorship);
  const [open, setOpen] = useState<boolean>(false);

  const onRowClick = (e) => {
    const createdDirector: IRedFlagDirectorshipData = createOGRedflagData(
      e.attributes,
      e.type
    );

    const director: IRedFlagDirectorship = {
      data: createdDirector
    };

    setSelectedDirector(director);
    setOpen(true);
  };

  const newCompanyDirectors =
    company &&
    company?.directors &&
    company?.directors.map((director) => {
      let newDirector = { ...director };

      const createdDirector: IRedFlagDirectorshipData = createOGRedflagData(
        newDirector.attributes,
        newDirector.type
      );

      newDirector = createdDirector;
      return newDirector;
    });

  return (
    <Paper className={classes.root} elevation={3}>
      <Table
        columns={[
          { title: 'First name', field: 'attributes.first_name' },
          { title: 'Surname', field: 'attributes.surname' },
          { title: 'Occupation', field: 'attributes.occupation' },
          { title: 'Appointed', field: 'attributes.date_appointed' },
          { title: 'Resigned', field: 'attributes.date_resigned' }
        ]}
        data={newCompanyDirectors}
        title="Directors"
        onRowClick={onRowClick}
        options={{ pageSize: 10 }}
      />

      <DirectorshipDialog
        data={selectedDirector}
        handleClose={() => setOpen(false)}
        open={open}
      />
    </Paper>
  );
};

export default CompanyDirectorsTable;

const isTimeBased = (value: string | null): boolean => {
  if (!value) return false;

  const timeRegex = /(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/;
  const dateTimeRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}(:\d{2})?/;

  return timeRegex.test(value) || dateTimeRegex.test(value);
};

const DetailItem = ({
  label,
  value
}: {
  label: string;
  value: string;
}): JSX.Element => (
  <Grid item>
    <Typography variant="h6">{label}</Typography>
    <Typography variant="body1">{value}</Typography>
  </Grid>
);

const getNationalityFlag = (nationality: string): string | null => {
  const foundNationality = nationalities.find((n) =>
    n.nationality
      .split(', ')
      .some((part) => nationality.toLowerCase().includes(part.toLowerCase()))
  );

  return foundNationality ? foundNationality.alpha_2_code.toLowerCase() : null;
};

export const DirectorsDetail = ({
  directorship
}: {
  directorship: IRedFlagDirectorship | undefined;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const { getRFGQLDirectorList, getRFGQLCompanyDirector } =
    useRedFlagGQLAdaptor(RedflagClient);

  const [open, setOpen] = React.useState(false);
  const [tableData, setTableData] = React.useState<IDirectorCompany[]>([]);

  const [selectedDirector, setSelectedDirector] =
    React.useState<IRedFlagDirectorship>({} as IRedFlagDirectorship);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const RenderNationality = ({
    nationality
  }: {
    nationality: string | null;
  }): JSX.Element => {
    const classes = useStyles();
    const flag = nationality ? getNationalityFlag(nationality) : null;

    if (flag) {
      return (
        <Grid container alignItems="center">
          <img
            src={`https://flagcdn.com/w20/${flag}.png`}
            alt={nationality || 'Unknown'}
            className={classes.nationalityFlag}
          />
          <Typography variant="body1">{nationality}</Typography>
        </Grid>
      );
    }

    return <div />;
  };

  const RenderDetailItem = ({
    label,
    value
  }: {
    label: string;
    value: string | null;
  }): JSX.Element => {
    if (value && value.trim() !== '') {
      if (label === 'Nationality') {
        return (
          <Grid item>
            <Typography variant="h6">{label}</Typography>
            <RenderNationality nationality={value} />
          </Grid>
        );
      }

      if (isTimeBased(value)) {
        return (
          <Grid item>
            <Typography variant="h6">{label}</Typography>
            <Time time={value} type="date" />
          </Grid>
        );
      }

      return <DetailItem value={value} label={label} />;
    }

    return <div />;
  };

  const onRowClick = (e) => {
    setSelectedDirector(e);
    handleClickOpen();
  };

  useEffect(() => {
    if (directorship) {
      // const getDirectorList = async () => {
      //   const query: IDirectorSearchCriteria = {
      //     company_id: {
      //       eq: directorship.data.attributes.company_id
      //     }
      //   };

      //   const directorships = await getRFGQLDirectorList(query);
      //   const tableData: IRedFlagDirectorship[] = directorships.data.map(
      //     (director: IRedFlagDirectorshipData): IRedFlagDirectorship => ({
      //       data: director
      //     })
      //   );

      //   setTableData(tableData);
      // };

      // getDirectorList();

      const getDirector = async () => {
        console.log({ directorship });
        const director = await getRFGQLCompanyDirector(
          directorship.data.attributes.person_id
        );

        if (director?.length > 0) {
          setTableData(director);
        }
      };

      getDirector();
    }
  }, [directorship]);

  if (!directorship) return <div />;
  return (
    <div>
      <Paper className={classes.detail} elevation={3}>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography variant="h2">
              {directorship.data.attributes.first_name}{' '}
              {directorship.data.attributes.surname}
            </Typography>
          </Grid>

          <Grid item container direction="row" xs={12}>
            <Grid item style={{ paddingTop: theme.spacing(0.5) }}>
              <Typography
                style={{ color: theme.palette.success.main }}
                variant="h6">
                {directorship.data.attributes.company_name}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={() => {
                  setSelectedDirector(directorship);
                  handleClickOpen();
                }}>
                <FindInPage fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <br />

        <Grid container spacing={2}>
          <RenderDetailItem
            label="Birth-Date"
            value={directorship.data.attributes.birthdate}
          />
          <RenderDetailItem
            label="Nationality"
            value={directorship.data.attributes.nationality}
          />
          <RenderDetailItem
            label="Address"
            value={directorship.data.attributes.address}
          />
          <RenderDetailItem
            label="Town"
            value={directorship.data.attributes.town}
          />
          <RenderDetailItem
            label="Postcode"
            value={directorship.data.attributes.postcode}
          />
          <RenderDetailItem
            label="Appointment Type"
            value={directorship.data.attributes.appointment_type}
          />
          <RenderDetailItem
            label="Other Appointments"
            value={directorship.data.attributes.other_appointments}
          />
        </Grid>

        <br />

        <Grid item style={{ flexGrow: 1 }}>
          <Table
            columns={[
              { title: 'Company ID', field: 'company_id' },
              { title: 'company Name', field: 'company_name' },
              { title: 'Company Type', field: 'company_type' },
              { title: 'Credit Score', field: 'credit_score' },
              {
                title: 'RFA Rating Score',
                field: 'rfa_rating_score'
              }
            ]}
            data={tableData}
            onRowClick={onRowClick}
            title="Directorships"
            options={{ pageSize: 5 }}
          />
        </Grid>
        <DirectorshipDialog
          data={selectedDirector}
          handleClose={handleClose}
          open={open}
        />
      </Paper>
    </div>
  );
};
