export default (theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '100%'
  },
  details: {
    display: 'flex',
    // flexDirection: 'column',
    'flex-direction': 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
    // paddingLeft: theme.spacing(3),
    // paddingRight: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
    border: theme.palette.background.default,
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: theme.palette.primary.lighttrans,
      color: theme.palette.primary.contrastText
    }
  },
  title: {
    color: theme.palette.text.secondary,
    fontWeight: 700
  },
  value: {
    marginTop: theme.spacing(),
    fontSize: 40
  },
  iconWrapper: {
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '50%',
    display: 'inline-flex',
    height: '4rem',
    justifyContent: 'center',
    marginLeft: 'auto',
    width: '4rem'
  },
  icon: {
    color: theme.palette.common.white,
    fontSize: '2rem',
    height: '2rem',
    width: '2rem'
  },
  footer: {
    marginTop: theme.spacing(3)
  }
});
