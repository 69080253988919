import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withStyles, Paper, Typography, Grid, Button } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
// import { ColorPicker } from 'material-ui-color';
import { getUserDefinitionList } from 'redux/actions/user';
import TextField from 'components/Fields/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { firebase } from 'redux/firebase';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import _ from 'lodash';
import Link from '@material-ui/core/Link';
import Xpansion from 'common/Xpansion';
import { SET_INSTANCE_SETTINGS } from 'redux/actions/types';

const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
const styles = (theme) => ({ root: { padding: theme.spacing(4) } });

const Settings = (props) => {
  const { classes } = props;
  const [definitions, setDefinitions] = React.useState([]);
  const dispatch = useDispatch();
  const instanceSettings = useSelector(
    (state) => state.config.instanceSettings
  );
  const token = useSelector((s) => s.user.auth.token);
  const userDefinitionsList = useSelector(
    (state) => state.userAPI.userDefinitionsList
  );

  const handleChange = (name, value) => {
    const update = Object.assign({}, instanceSettings);
    update[name] = value;
    dispatch({ type: SET_INSTANCE_SETTINGS, payload: update });
  };

  const save = () => {
    instanceSettings.UserDefinitions = definitions;
    firebase.database().ref(`${hostname}/settings`).update(instanceSettings);
  };

  const handleOnDragEnd = (result) => {
    const items = Array.from(definitions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDefinitions(items);
  };

  const attributes = [
    'Details',
    'Relationships',
    'Processes',
    'Emails',
    'Redflag',
    'FCA',
    'Log'
  ];

  const handleCheck = ({ name, Id, checked, index }) => {
    const items = Array.from(definitions);
    const selected = items.find((el) => el.Id === Id);

    // Can we make selected an array so we have an order?

    if (name !== 'checked') {
      if (!selected.attributes) {
        let defaultObj = [];
        attributes.forEach((att) => defaultObj.push({ [att]: false }));
        selected.attributes = defaultObj;
      }
      selected.attributes[index] = { [name]: checked };
    } else {
      selected[name] = checked;
    }

    setDefinitions(items);
  };

  const getData = async () => {
    await getUserDefinitionList({ token });

    await firebase
      .database()
      .ref(`${hostname}/settings/UserDefinitions`)
      .on('value', (snap) => {
        // console.log('Snap exists: ', snap.exists());
        snap.exists() && setDefinitions(snap.val());
        !snap.exists() && setDefinitions([]);
      });
  };

  const importUserDefinitions = () => {
    // console.log('importUserDefinitions', { userDefinitionsList });
    if (userDefinitionsList.length > 0) {
      let newDefArray = [];
      userDefinitionsList.forEach((UserDefinition) =>
        newDefArray.push(UserDefinition)
      );
      // console.log({ newDefArray });
      setDefinitions(newDefArray);
    }
  };

  React.useEffect(() => {
    if (definitions.length > 0 && userDefinitionsList.length > 0) {
      if (definitions.length !== userDefinitionsList.length) {
        // console.log({ definitions, userDefinitionsList });
        let newDefArray = [];
        userDefinitionsList.forEach((UserDefinition) => {
          const newDef = definitions.find(
            (def) => def.Id === UserDefinition.Id
          );
          if (!newDef) newDefArray.push(UserDefinition);
        });
        const items = _.concat(definitions, newDefArray);
        setDefinitions(items);
      }
    }
  }, [userDefinitionsList, definitions]);

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  // console.log({ userDefinitionsList, definitions });

  return (
    <DashboardLayout title="Settings">
      <Grid className={classes.root} container direction="column" spacing={2}>
        <Grid item>
          <Paper className={classes.root}>
            <Typography variant="h1">Instance Settings</Typography>
            <Grid
              container
              direction="column"
              justifyContent="center"
              spacing={2}>
              <Grid item>
                <TextField
                  handleChange={(v) => handleChange('InstanceName', v)}
                  onBlur={() => {}}
                  title="Instance Name"
                  type="Text"
                  value={instanceSettings.InstanceName}
                />
              </Grid>
              <Grid item>
                <TextField
                  handleChange={(v) => handleChange('AdminURL', v)}
                  title="Admin URL"
                  type="Text"
                  value={instanceSettings.AdminURL}
                />
              </Grid>
              <Grid item>
                <Link
                  href={instanceSettings.AdminURL}
                  rel="noopener"
                  target="_blank">
                  {instanceSettings.AdminURL}
                </Link>
              </Grid>
              <Grid item>
                <h3>Primary Color: </h3>
                {/* <ColorPicker
                  hideTextfield
                  onChange={(e) => handleChange('PrimaryColor', e.hex)}
                  value={`#${instanceSettings.PrimaryColor}`}
                /> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Paper className={classes.root}>
            <Typography variant="h1">User Definitions</Typography>

            {definitions.length === 0 && (
              <Button onClick={importUserDefinitions}>
                Import User Definitions
              </Button>
            )}

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    spacing={1}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {definitions.map(({ Title, Id }, i) => {
                      return (
                        <Draggable
                          draggableId={Id.toString()}
                          index={i}
                          key={Id}>
                          {(provided) => (
                            <Grid
                              className={'list'}
                              item
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}>
                              <Xpansion
                                summary={
                                  <div onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                      checked={definitions[i].checked}
                                      color="primary"
                                      onChange={(e) => {
                                        const checked = e.target.checked;
                                        handleCheck({
                                          Id,
                                          checked,
                                          name: 'checked'
                                        });
                                      }}
                                    />
                                    {`${Id} : ${Title}`}
                                  </div>
                                }>
                                <Grid alignItems="center" container>
                                  <Grid container direction="column">
                                    {attributes.map((attribute, index) => (
                                      <Grid item key={index}>
                                        {attribute}:
                                        <Checkbox
                                          checked={
                                            definitions[i].attributes &&
                                            definitions[i].attributes[index][
                                              attribute
                                            ]
                                          }
                                          color="primary"
                                          onChange={(e) => {
                                            handleCheck({
                                              Id,
                                              checked: e.target.checked,
                                              name: attribute,
                                              index
                                            });
                                          }}
                                        />
                                      </Grid>
                                    ))}
                                  </Grid>
                                </Grid>
                              </Xpansion>
                            </Grid>
                          )}
                        </Draggable>
                      );
                    })}

                    {provided.placeholder}
                  </Grid>
                )}
              </Droppable>
            </DragDropContext>
          </Paper>
        </Grid>

        <Grid item>
          <Paper className={classes.root}>
            <Typography variant="h1">Process Definitions</Typography>
            <TextField
              handleChange={(v) => handleChange('ProcessesName', v)}
              title="Processes Name"
              type="Text"
              value={instanceSettings.ProcessesName}
            />
          </Paper>
        </Grid>

        <Grid align="right" item>
          <Button
            align="right"
            color="primary"
            onClick={save}
            variant="contained">
            SAVE
          </Button>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default withStyles(styles)(Settings);
