import { Grid, ListItem, makeStyles, Paper, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  display: {
    display: 'flex',
    justifyContent: 'space-around',
    background: theme.palette.primary.main,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  displayBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.contrastText
  },
  commissionIndex: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
    border: 0,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    color: 'white',
    height: 38,
    padding: '0 10px',
    margin: 1
  },
  skeletonCommission: {
    borderRadius: theme.shape.borderRadius,
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
    border: 0,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)'
  }
}));

const SkeletonComponent = ({
  landing_page = false
}: {
  landing_page: boolean;
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const DISPLAY_BOXES = [0, 1, 2];
  const NOTES = landing_page ? [0] : [0, 1];
  const PAYMENT = [0, 1];
  const LIST_ITEMS = [0, 1];
  const EXPANDABLE_PANELS = landing_page ? [0, 1] : [0, 1, 2];

  const displayBoxes = Array.from(DISPLAY_BOXES).map((_, index) => (
    <div className={classes.displayBox} key={index}>
      <Skeleton variant="text" width={70} height={30} />
      <div style={{ paddingBottom: theme.spacing(1) }}>
        <Skeleton
          variant="rect"
          style={{
            borderRadius: theme.shape.borderRadius
          }}
          width={120}
          height={40}
        />
      </div>
    </div>
  ));

  const notes = (
    <Paper square>
      {Array.from(NOTES).map((_, index) => (
        <Paper square key={index}>
          <ListItem key={index} style={{ alignItems: 'flex-end' }}>
            <Skeleton
              variant="rect"
              style={{
                borderRadius: theme.shape.borderRadius
              }}
              width="40%"
              height={30}
            />
            <div
              style={{
                flexGrow: 1,
                marginLeft: 15,
                marginRight: 15
              }}
            />
            <Skeleton
              variant="rect"
              style={{
                borderRadius: theme.shape.borderRadius
              }}
              width="10%"
              height={30}
            />
          </ListItem>
        </Paper>
      ))}
    </Paper>
  );

  const forPayment = (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      style={{ padding: theme.spacing(1) }}>
      {Array.from(PAYMENT).map((_, index) => (
        <Grid item key={index}>
          <Skeleton
            variant="rect"
            style={{
              borderRadius: theme.shape.borderRadius
            }}
            width={80}
            height={20}
          />
        </Grid>
      ))}
    </Grid>
  );

  const listItems = Array.from(LIST_ITEMS).map((_, index) => (
    <ListItem key={index} style={{ alignItems: 'flex-end' }}>
      <Skeleton
        variant="rect"
        style={{
          borderRadius: theme.shape.borderRadius
        }}
        width="30%"
        height={30}
      />
      <div
        style={{
          flexGrow: 1,
          borderBottom: '1px dotted lightgrey',
          marginLeft: 15,
          marginRight: 15
        }}
      />
      <Skeleton
        variant="rect"
        style={{
          borderRadius: theme.shape.borderRadius
        }}
        width="10%"
        height={30}
      />
    </ListItem>
  ));

  const expandablePanels = Array.from(EXPANDABLE_PANELS).map((_, index) => (
    <div key={index} style={{ paddingBottom: theme.spacing(1) }}>
      <Skeleton variant="rect" width="100%" height={40} />
    </div>
  ));

  return (
    <Paper
      style={{
        position: 'relative',
        padding: theme.spacing(2),
        marginBottom: 10,
        backgroundColor: theme.palette.background.paper,
        borderWidth: 1
      }}
      variant="outlined">
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={1}
        style={{ paddingBottom: theme.spacing(1) }}>
        <Grid item>
          <Skeleton variant="text" width={150} height={40} />
        </Grid>
        <Grid item container xs justifyContent="flex-end">
          <div style={{ padding: theme.spacing(1) }} />

          {landing_page ? (
            <Grid item>
              <Skeleton
                variant="rect"
                className={classes.skeletonCommission}
                width={180}
                height={35}
              />
            </Grid>
          ) : (
            <Grid item>
              <Skeleton
                variant="rect"
                style={{
                  borderRadius: theme.shape.borderRadius
                }}
                width={120}
                height={35}
              />
            </Grid>
          )}
        </Grid>
      </Grid>

      <div>
        <div className={classes.display}>{displayBoxes}</div>
        <div>
          {notes}
          {!landing_page && <>{forPayment}</>}
        </div>

        {!landing_page && (
          <div style={{ padding: theme.spacing(1) }}>
            <Skeleton
              variant="rect"
              width="25%"
              className={classes.skeletonCommission}
              height={40}
            />
          </div>
        )}

        <div>{listItems}</div>
        {expandablePanels}
      </div>
    </Paper>
  );
};

export default SkeletonComponent;
