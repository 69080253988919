import React from 'react';
import { Button, Chip, Grid, TextField, Typography } from '@material-ui/core';
import { validateEmail } from 'helpers/validateEmail';
import Table, { MTableEditRow } from 'components/SimpleTable';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { useTheme } from '@material-ui/core/styles';
import { BugTracker } from 'Utils/Bugtracker';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTypedSelector } from 'redux/reducers';
import { IUpdateUserInstance } from 'redux/actions/GraphQlActions/interface';
import { getQuickLiteUser, updateUser } from 'redux/actions/GraphQlActions';

export const SearchForAUserInstance = ({ onSelect, selected }) => {
  const theme = useTheme();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const { token } = useTypedSelector((s) => s.user.auth);
  const { user } = useTypedSelector((s) => s.user);
  const { currentProcess } = useTypedSelector((s) => s.process);

  const [data, setData] = React.useState<any>(null);
  const [showAdd, setShowAdd] = React.useState(false);

  const processDefinition = currentProcess?.ProcessDefinition?.Id;
  const processDefinitionId = processDefinition ? processDefinition : 0;

  const [newUser, setNewUser] = React.useState<{
    Title: string;
    UserInstanceEmail: string;
    OwnerUserInstanceId: number;
    UserDefinitionId: number;
  }>({
    Title: '',
    UserInstanceEmail: '',
    OwnerUserInstanceId: user.Id,
    UserDefinitionId: 528
  });

  const fetchUsers = async () => {
    const UserDefinitionId = 528;
    return getUserInstanceSummaryList({
      token,
      UserDefinitionId
    })
      .then((res) => setData(Object.values(res.data).reverse()))
      .catch((e) => {
        BugTracker.notify(e);
      });
  };

  React.useEffect(() => {
    fetchUsers();
  }, []);

  const columns = [
    { title: 'Name', field: 'UserInstance.Title' },
    { title: 'Identifier', field: 'UserInstance.UserInstanceEmail' },
    {
      title: 'Owner Id',
      field: 'UserInstance.OwnerUserInstanceId',
      render: (data) => {
        const id = data.UserInstance.OwnerUserInstanceId;
        const show = id === 0 ? 'system' : id;
        return <div>{show}</div>;
      },
      editable: 'never'
    }
  ];

  const tableOptions = {
    selectionProps: (rowData) => ({
      color: 'primary'
    })
  };

  const handleNewUserUpdate = (e, name) => {
    setNewUser((s) => ({ ...s, [name]: e.target.value }));
  };

  const handleNewUserSubmit = async () => {
    console.log({ newUser });

    const typedData: IUpdateUserInstance = {
      ...newUser,
      UserDefinitionId: newUser.UserDefinitionId,
      OwnerUserInstanceId: newUser.OwnerUserInstanceId.toString(),
      IsPublished: true
    };

    const response = await getQuickLiteUser({
      baseUrl,
      ProcessDefinitionId: processDefinitionId,
      data: typedData,
      action: 'UPDATE'
    });

    const UserInstance = response?.UserInstance;
    if (UserInstance) {
      onSelect(UserInstance);
      return setData(null);
    }
  };

  return (
    <div>
      {data && (
        <>
          {showAdd && (
            <div>
              <Typography>Add New User</Typography>
              <br />
              <Grid
                container
                justifyContent="space-between"
                spacing={2}
                style={{
                  border: `${theme.palette.primary.main} solid 1px`,
                  borderRadius: theme.shape.borderRadius,
                  padding: theme.spacing(1),
                  position: 'relative'
                }}>
                <CancelIcon
                  onClick={() => setShowAdd(false)}
                  color="error"
                  style={{
                    position: 'absolute',
                    top: -10,
                    right: -10,
                    cursor: 'pointer'
                  }}
                />
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="Name"
                    onChange={(e) => handleNewUserUpdate(e, 'Title')}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    label="Email"
                    onChange={(e) =>
                      handleNewUserUpdate(e, 'UserInstanceEmail')
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button onClick={handleNewUserSubmit}>
                    Add
                    <CheckIcon style={{ color: 'green' }} />
                  </Button>
                </Grid>
              </Grid>
              <br />
            </div>
          )}

          <Table
            columns={columns}
            components={{
              EditRow: (props: any) => <MTableEditRow {...props} />
            }}
            data={data}
            options={tableOptions}
            title="Contacts"
            actions={[
              {
                icon: 'add',
                tooltip: 'add new user',
                isFreeAction: true,
                onClick: () => setShowAdd(true)
              }
            ]}
            editable={{
              isEditable: (rowData) =>
                rowData.UserInstance.OwnerUserInstanceId == user.Id,
              onRowUpdate: async (newData, oldData) => {
                return await updateUser({
                  baseUrl,
                  ProcessInstanceId: 0,
                  data: newData
                });
              }
            }}
            handleRowClick={(u) => {
              if (validateEmail(u.UserInstance.UserInstanceEmail)) {
                onSelect(u.UserInstance);
              } else {
                alert('a proxy must have a valid email address.');
              }
            }}
          />
        </>
      )}
    </div>
  );
};
