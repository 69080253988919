import { UserDefinitionIds, UserDefinitionNames } from './globalIdConfig';

export const mapUserDefinitionIdToName = (
  userDefinitionId: UserDefinitionIds
): UserDefinitionNames => {
  switch (userDefinitionId) {
    case UserDefinitionIds.CustomerUDID:
      return UserDefinitionNames.Customers;
    case UserDefinitionIds.ContactUDID:
      return UserDefinitionNames.Contacts;
    case UserDefinitionIds.LenderUDID:
      return UserDefinitionNames.Lenders;
    case UserDefinitionIds.SuppliersUDID:
      return UserDefinitionNames.Suppliers;
    case UserDefinitionIds.BrokersUDID:
      return UserDefinitionNames.Brokers;
    case UserDefinitionIds.AccountsUDID:
      return UserDefinitionNames.Accounts;
    case UserDefinitionIds.IntroducersUDID:
      return UserDefinitionNames.Introducers;

    default:
      return UserDefinitionNames.Customers;
  }
};
