import React from 'react';
import { useSelector } from 'react-redux';

import { firebase } from 'redux/firebase';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import Typography from '@material-ui/core/Typography';
// import { decorator } from 'common/Editor/decorators';
// import Editor from 'common/Editor/displayEditor';
import { EditorState, convertFromRaw } from 'draft-js';

import Time from 'common/Time';

const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

const Component = () => {
  const [data, setData] = React.useState({});
  const user = useSelector((s) => s.user.user);

  const deleteEntry = ({ key }) => {
    const ref = firebase.database().ref(`${hostname}/chatter/${key}`);
    ref.remove();
  };

  React.useEffect(() => {
    const ref = firebase.database().ref(`${hostname}/chatter`);
    ref.on('value', (snap) => snap.exists() && setData(snap.val()));

    return () => ref.off();
  }, []);

  if (Object.keys(data).length > 0) {
    return (
      <Timeline align="alternate">
        {Object.keys(data)
          .map((key, i) => {
            const json = JSON.parse(data[key].Draftjs);
            const convertedState = convertFromRaw(json);
            const editorState = EditorState.createWithContent(
              convertedState
              // decorator
            );

            return (
              <TimelineItem key={i}>
                <TimelineOppositeContent>
                  <Typography color="textSecondary">
                    <Time time={data[key].Time} type="timeago" />
                  </Typography>
                </TimelineOppositeContent>

                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>

                <TimelineContent>
                  <Typography>{data[key].Title}</Typography>

                  {/* <Editor editorState={editorState} /> */}

                  {data[key].Id === user.Id && (
                    <IconButton
                      color="secondary"
                      onClick={() => deleteEntry({ key })}>
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          })
          .reverse()}
      </Timeline>
    );
  }

  return '';
};

export default Component;
