import React from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import SingleObject from './Overview/SingleObject';
import RepeatingObject from './Overview/RepeatingObject';
import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { getFieldInstanceValue } from 'Utils';
import { Regulated } from 'components/User/NewUserButton';
import { CompleteObjectDefinition } from 'types/interfaces';
import LockIcon from '@material-ui/icons/Lock';
import { regulatedFilter } from 'components/Stepper/components/Steps/ActiveStep/helper/regulatedFilter';
import { useProcess } from 'hooks';
import { globalIds } from 'helpers/globalIdConfig';
import RepeatDynamicObject from './Overview/RepeatDynamicObject';
import { isHidden } from 'components/Stepper/components/Steps/ActiveStep/Forms/helpers';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`
  };
};

const useStyles = makeStyles((theme) => ({
  reg: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: theme.spacing(2)
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    width: '100%'
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    padding: 0,
    width: '100%'
  },
  label: {
    display: 'flex',
    alignSelf: 'flex-start',
    textAlign: 'left',
    alignItems: 'center'
  },
  tabPanel: { paddingLeft: theme.spacing(2) }
}));

interface IProps {
  CODL: CompleteObjectDefinition[];
  rowData: any;
  getData: () => void;
}

export default function Details({ CODL, rowData, getData }: IProps) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => setValue(newValue);
  const [EntityType, setEntityType] = React.useState<string | undefined>(
    undefined
  );
  const [redactedCODL, setRedactedCODL] = React.useState<
    CompleteObjectDefinition[] | null
  >(null);
  const { user } = useProcess();
  const isSuperAdmin = user.SystemAccess === 15;
  const GDPRObjectDefinition = [2849, 3658];

  React.useEffect(() => {
    if (EntityType === undefined) {
      let filteredCODL = CODL;

      if (!isSuperAdmin) {
        filteredCODL = filteredCODL.filter(
          (item: CompleteObjectDefinition) =>
            !GDPRObjectDefinition.includes(item.ObjectDefinition.Id)
        );
      }
      setRedactedCODL(filteredCODL);
    } else {
      const tempEntityType = getFieldInstanceValue({
        CODL,
        COIL: rowData.CompleteObjectInstanceList,
        DefinitionTitle: 'Entity',
        FieldTitle: 'Entity Type'
      });

      if (rowData.CompleteObjectInstanceList.length > 0) {
        setEntityType(tempEntityType);
        if (tempEntityType) {
          const isRegulatedParty = Regulated.includes(tempEntityType);

          // if non reg filter all non reg objects and any others
          // if reg filter all reg and no redflag objects
          const tempRedactedCODL = CODL.filter((i: CompleteObjectDefinition) =>
            regulatedFilter({
              item: i,
              entityType: tempEntityType,
              regulated: { isRegulatedParty: isRegulatedParty }
            })
          ).filter((item: CompleteObjectDefinition) => {
            if (isRegulatedParty) {
              return !ReadOnlyRedflag({
                ObjectDefinition: item.ObjectDefinition
              });
            }
            return item;
          });

          setRedactedCODL(tempRedactedCODL);
        } else {
          setRedactedCODL(CODL);
        }
      } else {
        setRedactedCODL(CODL);
      }
    }
  }, [CODL]);

  const regulated = EntityType && Regulated.includes(EntityType);
  return (
    <>
      {EntityType && (
        <div className={classes.reg}>
          <Typography variant="h5" display="block" color="primary">
            {regulated ? 'REGULATED' : 'NON-REGULATED'} PARTY
          </Typography>
        </div>
      )}

      <div className={classes.root}>
        {CODL.length > 0 && (
          <Grid container style={{ width: '100%' }}>
            <Grid item xs={3}>
              <Tabs
                textColor="primary"
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs"
                className={classes.tabs}>
                {redactedCODL &&
                  redactedCODL.map(
                    (tab: CompleteObjectDefinition, i: number) => {
                      const result = isHidden(
                        tab,
                        EntityType !== undefined ? EntityType : ''
                      );
                      if (result) return <div key={i} />;

                      let isReadOnly;
                      const AddressOB =
                        globalIds.customer.addresses.AddressObjectDefinition;
                      const shareholderOB =
                        globalIds.customer.shareholders
                          .ShareHolderObjectDefinition;
                      const EntityOB = globalIds.customer.entity;

                      if (
                        [AddressOB, shareholderOB].includes(
                          tab.ObjectDefinition.Id
                        ) ||
                        EntityOB.includes(tab.ObjectDefinition.Id)
                      )
                        isReadOnly = false;
                      else {
                        isReadOnly = ReadOnlyRedflag({
                          ObjectDefinition: tab.ObjectDefinition
                        });
                      }

                      return (
                        <Tab
                          key={i}
                          label={
                            <div className={classes.label}>
                              <div
                                key={i}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center'
                                }}>
                                {isReadOnly && (
                                  <Tooltip title="Data from companies house is not editable">
                                    <LockIcon
                                      style={{ marginRight: 10, fontSize: 20 }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                              {tab.ObjectDefinition.Title}
                            </div>
                          }
                          {...a11yProps(i)}
                        />
                      );
                    }
                  )}
              </Tabs>
            </Grid>
            <Grid item xs={9}>
              {redactedCODL &&
                redactedCODL.map((props, i) => {
                  return (
                    <TabPanel
                      editable
                      value={value}
                      index={i}
                      key={i}
                      className={classes.tabPanel}>
                      {props.ObjectDefinition.ObjectRepeat === 1 ? (
                        <SingleObject
                          {...props}
                          getData={getData}
                          rowData={rowData}
                        />
                      ) : (
                        <>
                          {props.ObjectDefinition.ObjectDescription.includes(
                            'repeat-dynamic'
                          ) ? (
                            <RepeatDynamicObject
                              FieldDefinitionDict={props.FieldDefinitionDict}
                              FieldDefinitionList={props.FieldDefinitionList}
                              ObjectDefinition={props.ObjectDefinition}
                              rowData={rowData}
                              getData={getData}
                            />
                          ) : (
                            <RepeatingObject {...props} rowData={rowData} />
                          )}
                        </>
                      )}
                    </TabPanel>
                  );
                })}
            </Grid>
          </Grid>
        )}
      </div>
    </>
  );
}

// const regulatedFilter = (item, regulated) => {
//   const ObjectDescription = item?.ObjectDefinition?.ObjectDescription;
//   const isRegObject = ObjectDescription.includes('regulated');
//   const isUnRegObject = ObjectDescription.includes('nonreg');
//   const isRegParty = regulated;
//   if (isRegParty) {
//     if (isUnRegObject) {
//       return false;
//     } else {
//       return true;
//     }
//   } else {
//     if (isRegObject) {
//       return false;
//     } else {
//       return true;
//     }
//   }
// };
