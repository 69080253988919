import {
  FollowupFlagStatus,
  Message,
  NullableOption
} from '@microsoft/microsoft-graph-types';
import {
  createContext,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { MessageHubContext } from './MessageHubContext';

import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
import { BugTracker } from 'Utils/Bugtracker';
import { callMsGraph } from 'services/microsoft/graph';
import { IConversation } from '../interfaces';
import * as gtag from 'Utils/gtag';

interface ICardContext {
  hoveredCard: string;
  setHovered: (conversationId: string) => void;
  clearHovered: () => void;
  toggleBookmark: (conversation: IConversation) => void;
  bookmarkedConversations: IConversation[];
  onRead: (conversationId: string) => void;
  readConversations: object;
  archiveMessage: ({
    conversation
  }: {
    conversation: IConversation;
  }) => Promise<void>;
  setFlaggedStatus: ({
    messageId,
    flagStatus,
    conversation,
    isBookmarked
  }: {
    messageId: string;
    conversation: IConversation;
    flagStatus: 'flagged' | 'complete' | 'notFlagged';
    isBookmarked: boolean;
  }) => Promise<void>;
  markAsRead: ({
    messageId,
    isRead
  }: {
    messageId: string;
    isRead: boolean | null | undefined;
  }) => Promise<void>;
  setBookmarkedConversations: React.Dispatch<SetStateAction<IConversation[]>>;
}

export const CardContext = createContext<ICardContext>({
  hoveredCard: '',
  setHovered: () => {},
  clearHovered: () => {},
  toggleBookmark: () => {},
  bookmarkedConversations: [],
  onRead: () => {},
  readConversations: {},
  archiveMessage: async () => {},
  setFlaggedStatus: async () => {},
  markAsRead: async () => {},
  setBookmarkedConversations: () => {}
});

const CardProvider = ({ children }: { children: React.ReactNode }) => {
  const { messages, validAccessToken, setData, getRefreshEmails } =
    useContext(MessageHubContext);

  const dispatch = useDispatch();

  const [readConversations, setReadConversations] = useState<object>({});
  const onRead = (conversationId) => {
    setReadConversations({
      ...readConversations,
      [conversationId]: true
    });
  };

  const [hoveredCard, setHoveredCard] = useState('');
  const [bookmarkedConversations, setBookmarkedConversations] = useState<
    IConversation[]
  >(() => {
    const storedFavorites = localStorage.getItem('favorites');
    if (!storedFavorites) return [];

    try {
      const parsedFavorites = JSON.parse(storedFavorites);
      if (
        Array.isArray(parsedFavorites) &&
        parsedFavorites.every((item) => typeof item === 'string')
      )
        return [];

      return parsedFavorites;
    } catch (error) {
      return [];
    }
  });

  const archiveMessage = async ({
    conversation
  }: {
    conversation: IConversation;
  }) => {
    const accessToken = await validAccessToken();
    const { parentMessage, replies } = conversation;
    const allMessages = [parentMessage, ...replies];

    for (const message of allMessages) {
      try {
        const moveResponse = await fetch(
          `https://graph.microsoft.com/v1.0/me/messages/${message.id}/move`,
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              destinationId: 'archive'
            })
          }
        );

        if (!moveResponse.ok) {
          const errorData = await moveResponse.json();
          throw new Error(
            `Failed to archive message: ${JSON.stringify(errorData)}`
          );
        }
      } catch (e) {
        BugTracker.notify(e);
      }
    }

    dispatch(createNotification(successNotif('Thread Archived')));
    gtag.event({
      feature: 'Inbox',
      action: 'Archive',
      message: `Email Archive ${conversation.parentMessage.id}`
    });

    await getRefreshEmails();
  };

  const toggleBookmark = (conversation: IConversation) => {
    const isBookmarked = bookmarkedConversations.includes(conversation);
    setBookmarkedConversations((prevConversations) =>
      isBookmarked
        ? prevConversations.filter((c) => c !== conversation)
        : [...prevConversations, conversation]
    );

    gtag.event({
      feature: 'Inbox',
      action: isBookmarked ? 'Unfavorited' : 'Favorited',
      message: `Email ${isBookmarked ? 'Unfavorite' : 'Favorite'} ${
        conversation.parentMessage.conversationId
      }`
    });
  };

  const setFlaggedStatus = async ({
    messageId,
    conversation,
    flagStatus,
    isBookmarked
  }: {
    messageId: string;
    conversation: IConversation;
    flagStatus: NullableOption<FollowupFlagStatus> | undefined;
    isBookmarked: boolean;
  }) => {
    const accessToken = await validAccessToken();
    const flagProperty = {
      flag: {
        flagStatus: flagStatus
      }
    };

    try {
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/messages/${messageId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(flagProperty)
        }
      );

      const responseData = await response.json();
      if (response.ok) {
        if (isBookmarked) {
          const currentBookmarked = JSON.parse(
            localStorage.getItem('favorites') || '[]'
          );
          const index = currentBookmarked.findIndex(
            (conv) => conv.parentMessage.id === conversation.parentMessage.id
          );

          if (index !== -1) {
            currentBookmarked[index].parentMessage = responseData;
          }

          conversation.parentMessage = responseData;
          localStorage.setItem('favorites', JSON.stringify(currentBookmarked));
        }

        dispatch(
          createNotification(successNotif('Flag status updated successfully'))
        );
      }

      gtag.event({
        feature: 'Inbox',
        action: 'Flagged',
        message: `Email Status ${flagStatus}`
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const markAsRead = async ({
    messageId,
    isRead
  }: {
    messageId: string;
    isRead: boolean | null | undefined;
  }) => {
    const accessToken = await validAccessToken();
    const readProperty = {
      isRead: isRead
    };

    try {
      const response = await fetch(
        `https://graph.microsoft.com/v1.0/me/messages/${messageId}`,
        {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(readProperty)
        }
      );

      if (response.ok) {
        gtag.event({
          feature: 'Inbox',
          action: 'ReadStatusUpdated',
          message: `Email Status ${isRead ? 'Read' : 'Unread'}`
        });
      }
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  useEffect(() => {
    const initializeReadConversations = () => {
      const initialState = {};

      if (Array.isArray(messages)) {
        messages.forEach((message: Message) => {
          if (message.conversationId && message.isRead) {
            initialState[message.conversationId] = message.isRead;
          }
        });

        setReadConversations(initialState);
      }
    };

    initializeReadConversations();
  }, [messages]);

  useEffect(() => {
    localStorage.setItem('favorites', JSON.stringify(bookmarkedConversations));
  }, [bookmarkedConversations]);

  const setHovered = (conversationId: string) => {
    setHoveredCard(conversationId);
  };

  const clearHovered = () => {
    setHoveredCard('');
  };

  return (
    <CardContext.Provider
      value={{
        hoveredCard,
        setHovered,
        clearHovered,
        toggleBookmark,
        bookmarkedConversations,
        onRead,
        readConversations,
        archiveMessage,
        setFlaggedStatus,
        markAsRead,
        setBookmarkedConversations
      }}>
      {children}
    </CardContext.Provider>
  );
};

export default CardProvider;
