import { validateEmail } from 'helpers/validateEmail';
import { Alert } from '@material-ui/lab';
import { Typography } from '@material-ui/core';
import { IData } from './interfaces';
import { CreateUserDialogProps } from './interfaces';

export const WarningNotice = ({
  is_directorship,
  hasEmail,
  data,
  props,
  isSupplier
}: {
  hasEmail: boolean;
  is_directorship: boolean;
  isSupplier: boolean;
  data: Partial<IData>;
  props: CreateUserDialogProps;
}) => {
  const isEmailValid: boolean = validateEmail(
    data?.UserInstance?.UserInstanceEmail
  );

  const surname = props?.data?.attributes?.surname;
  const first_name = props?.data?.attributes?.first_name;

  if (is_directorship) {
    if (!isEmailValid) {
      return (
        <Alert
          variant="filled"
          severity="warning"
          style={{ borderRadius: 0, justifyContent: 'center' }}>
          <Typography>
            In order to be able to create{' '}
            <b>
              {first_name} {surname}
            </b>{' '}
            as a <b>director </b>
            you must <b>enter a valid email address</b>.
          </Typography>
        </Alert>
      );
    }
    return (
      <Alert
        variant="filled"
        severity="success"
        style={{ borderRadius: 0, justifyContent: 'center' }}>
        <Typography>Verified User Configuration</Typography>
      </Alert>
    );
  } else if (isSupplier) {
    return (
      <Alert
        variant="filled"
        severity="warning"
        style={{ borderRadius: 0, justifyContent: 'center' }}>
        <Typography>
          In order to be able to create <b>{props?.data?.attributes?.name}</b>{' '}
          as a <b>Supplier Company</b> you must{' '}
          <b>enter a valid email address</b> for one or all directors.
        </Typography>
      </Alert>
    );
  } else if (!hasEmail && data !== undefined) {
    return (
      <Alert
        data-testid="Warning Alert"
        variant="filled"
        severity="warning"
        style={{ borderRadius: 0, justifyContent: 'center' }}>
        <Typography>
          In order to be able to create <b>{props?.data?.attributes?.name}</b>{' '}
          as a <b>company</b> you must <b>enter a valid email address</b> for
          all directors.
        </Typography>
      </Alert>
    );
  }
  return (
    <Alert
      data-testid="Successfully Alert"
      variant="filled"
      severity="success"
      style={{ borderRadius: 0, justifyContent: 'center' }}>
      <Typography>Verified Company Configuration</Typography>
    </Alert>
  );
};
