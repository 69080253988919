import { OffkeyCompany, OffkeyCompanyLite, SearchType } from '../interfaces';
// import { CreditSafeCompanyReport } from '../creditSafeInterfaces';
import axios, { AxiosRequestConfig } from 'axios';
import { firebase } from 'redux/firebase';

export const useCreditSafe = () => {
  // const companyReport = {} as CreditSafeCompanyReport;

  const getCompanyList: (
    query: string,
    type: SearchType
  ) => Promise<OffkeyCompanyLite[]> = async (query, type) => {
    let companies = [] as OffkeyCompanyLite[];

    const token = await firebase.auth().currentUser?.getIdToken();

    const config: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_PROXY}/credit-safe/company_search`,
      method: 'POST',
      headers: { token },
      data: { name: query, countries: 'GB', status: 'active' }
    };

    try {
      let res = await axios(config);
      const companiesList = res.data as OffkeyCompanyLite[];
      companies = companiesList;
    } catch (error) {
      console.log({ error });
    }

    return companies;
  };

  const getCompanyReport: (
    company_number: string
  ) => Promise<OffkeyCompany> = async (company_number) => {
    let company = {} as OffkeyCompany;

    const token = await firebase.auth().currentUser?.getIdToken();

    const config: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_PROXY}/credit-safe/company_report`,
      method: 'POST',
      headers: { token },
      data: {
        credit_safe_id: `GB-0-${company_number}`,
        company_number: company_number
      }
    };

    try {
      let res = await axios(config);
      const companyProfile = res.data;
      company = companyProfile;
    } catch (error) {
      console.log({ error });
    }

    return company;
  };

  const getCompanyPDF = async (company) => {
    const token = await firebase.auth().currentUser?.getIdToken();

    const config: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_PROXY}/credit-safe/download_company_report`,
      method: 'POST',
      headers: { token },
      data: { id: company.id },
      responseType: 'blob'
    };

    try {
      let res = await axios(config);
      return res.data;
    } catch (err) {
      console.log({ err });
    }
  };

  const getFilingHistoryDocument = async (id: string) => {
    const token = await firebase.auth().currentUser?.getIdToken();

    const config: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_PROXY}/credit-safe/download_filing_history_document`,
      method: 'POST',
      headers: { token },
      data: { id },
      responseType: 'blob'
    };

    try {
      let res = await axios(config);
      return res.data;
    } catch (err) {
      console.log({ err });
    }
  };

  const getDirectorships = async (id: string) => {
    const token = await firebase.auth().currentUser?.getIdToken();

    const config: AxiosRequestConfig = {
      url: `${process.env.REACT_APP_PROXY}/credit-safe/director_report`,
      method: 'POST',
      headers: { token },
      data: { id }
    };

    try {
      let res = await axios(config);
      return res.data;
    } catch (err) {
      console.log({ err });
    }
  };

  return {
    getCompanyList,
    getCompanyReport,
    getCompanyPDF,
    getFilingHistoryDocument,
    getDirectorships
  };
};
