import {
  Calculation,
  PaymentFrequency,
  SeasonalPaymentType
} from 'types/calculatorInterfaces';
import { Currency } from '../../Utils/displayFields';

export interface IPeriodColumns {
  title: string;
  field: string;
  lookup?: any;
  type?: string;
  disabled?: boolean;
  render?: (rowData: any) => any;
}

export interface IPeriods {
  columns: IPeriodColumns[];
}

export const extraPaymentOptions = {
  2: SeasonalPaymentType.SeasonalPayment
};

export const setINIT_PERIODS = (state: Calculation): IPeriods => {
  return {
    columns: [
      {
        title: 'Start Month',
        field: 'StartPeriod',
        type: 'date'
      },
      {
        title: 'End Month',
        field: 'EndPeriod',
        type: 'date',
        disabled: true
      },
      {
        title: 'Number',
        field: 'Number',
        type: 'numeric'
      },
      {
        title: 'Amount',
        field: 'Amount',
        type: 'numeric',
        render: (row: any) => <Currency value={row?.Amount} />
      },
      {
        title: 'Frequency',
        field: 'PaymentFrequency',
        type: 'select',
        lookup: {
          1: PaymentFrequency.Monthly,
          2: PaymentFrequency.Quarterly,
          3: PaymentFrequency.SemiAnnually,
          4: PaymentFrequency.Annually
        }
      },
      {
        title: 'Type',
        field: 'ExPayOverRide',
        type: 'select',
        lookup: extraPaymentOptions
      }
    ]
  };
};
