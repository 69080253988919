import React from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash-es';
import { Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import axios from 'axios';

import app from '../../../package.json';
import { updateTheme } from 'redux/actions/configActions';
import {
  SET_GLOBAL_HOSTNAME,
  SET_PUBLIC_SETTINGS,
  THEME_NAME
} from 'redux/actions/types';
import Bips from 'assets/logos/BIPS_White.png';
import { globalHost } from 'helpers/';
import { BugTracker } from 'Utils/Bugtracker';
import LoginForm from 'components/Authentication/LoginForm';
import { JediEntry } from './components/JediEntry';

const SignIn = () => {
  const [validInstance, setValidInstance] = React.useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = globalHost(window.location);

  React.useEffect(() => {
    // Lets set the hostname into redux here
    dispatch({ type: SET_GLOBAL_HOSTNAME, payload: location });
    let url = `${location.baseURL}publicsettingsapi.ashx`;

    axios
      .get(url)
      .then((res) => {
        updateTheme(res.data.Theme);
        dispatch({ type: THEME_NAME, payload: res.data.Theme });
        dispatch({ type: SET_PUBLIC_SETTINGS, payload: res.data });
      })
      .catch((e) => {
        BugTracker.notify(e);
        if (window.location.hostname !== 'localhost') {
          setValidInstance(false);
        }
      });
  }, []);

  return (
    <div
      className={classes.root}
      style={{
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative'
      }}>
      <Grid container className={classes.grid}>
        <Grid className={classes.quoteWrapper} item lg={6}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <img height={150} src={Bips} />
              </div>
            </div>
          </div>
        </Grid>

        <Grid className={classes.content} item lg={6} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              {validInstance ? (
                <LoginForm />
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <Typography variant="h1" color="primary">
                    Invalid Instance
                  </Typography>
                  <Typography variant="body1" color="primary">
                    This subdomain has no matching backend.
                  </Typography>
                </div>
              )}
            </div>
          </div>
        </Grid>
      </Grid>

      {(process.env.NODE_ENV === 'development' ||
        window.location.hostname === 'staging-bips.netlify.app') && (
        <JediEntry />
      )}

      <div className={classes.version}>
        <div style={{ flexGrow: 1 }} />
        <p>
          {location?.hostname} [{`${app.version}`}]
        </p>
      </div>
    </div>
  );
};

export default SignIn;
