import { makeStyles, useTheme, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    overflow: 'hidden',
    padding: 10
  },

  outsideroot: {
    overflow: 'hidden',
    padding: 10
  },
  root: {
    alignItems: 'center',
    backdropFilter: 'blur(10px)', // Blur effect
    backgroundColor: theme.palette.primary.light, // Light background with transparency
    border: '4px solid rgba(255, 255, 255, 0.18)', // Optional: Light border for glass effect
    borderRadius: 20, // Rounded corners
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', // Shadow effect
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginBottom: 10,
    padding: theme.spacing(2),
    position: 'relative',
    transition: 'all 0.3s ease-in-out'
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: theme.spacing(1)
    // width: '100%'
  },
  leftrow: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  userContainer: {
    width: '100%',
    display: 'grid',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2)
  },
  btn: {
    position: 'absolute',
    right: -45,
    display: 'flex'
  },
  workingArea: {
    position: 'absolute',
    right: -theme.spacing(12),
    top: theme.spacing(2)
  },
  primary: {
    fontSize: 10
  },
  button: {
    width: '100%'
  },
  input: {
    width: '100%'
  },
  avatar: {
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));
