import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * LEGACY DEAL API
 */
export const GetLegacyDeals = async ({ action, deal_Id }) => {
  let url = `LegacyDealAPI.ashx?`;
  if (action) url = `LegacyDealAPI.ashx?action=${action}&deal_Id=${deal_Id}`;
  const config: AxiosRequestConfig = { url, method: 'get' };
  return await dbQueue({ config, url });
};

export const GetLegacyDocument = async ({ action, id, responseType }) => {
  let url = `LegacyDealAPI.ashx?`;
  if (action) url = `LegacyDealAPI.ashx?action=${action}&id=${id}`;
  const method = 'get';
  responseType = responseType ? responseType : '';
  const config: AxiosRequestConfig = { url, method, responseType };
  return await dbQueue({ config, url });
};
