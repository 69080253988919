import { firebase } from 'redux/firebase';
import { Domain } from '../types';
import { BugTracker } from 'Utils/Bugtracker';

interface FetchDomains {
  isAFS: boolean;
  isSynergy: boolean;
  isSuperAdmin: boolean;
}

// NOTE: Only admins will be able to see the domain auth tab
export const fetchDomains = async ({
  isAFS,
  isSynergy,
  isSuperAdmin
}: FetchDomains): Promise<Domain[]> => {
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  const global = 'global';
  const isDual = isSynergy && isAFS;
  const isLocal = hostname === 'localhost';

  try {
    let ref: firebase.firestore.Query = firebase
      .firestore()
      .collection('domain');

    // super admins see ALL domains
    if (!isSuperAdmin) {
      ref = ref.where('hostname', 'in', [hostname, global]);
    }

    const snapshot = await ref.get();
    const domains: Domain[] = [];

    snapshot.forEach((doc) => {
      const domain = doc.data() as Domain;

      // Filter based on AFS or Synergy if the hostname is global
      if (domain.hostname === global) {
        if (!isDual) {
          if (isAFS && !domain.domain.includes('afs')) return;
          if (isSynergy && !domain.domain.includes('synergy')) return;
        }
      }

      domains.push(domain);
    });

    // NOTE: since removing subdomains, we only need to find a single root domain for each domain:
    const uniqueDomains = Array.from(new Set(domains.map((d) => d.domain))).map(
      (uniqueDomain) => {
        // Try to find a valid domain first
        const validDomain = domains.find(
          (d) => d.domain === uniqueDomain && d.valid === true
        );
        return validDomain || domains.find((d) => d.domain === uniqueDomain);
      }
    ) as Domain[];

    return uniqueDomains.filter((domain) => {
      // Filter global domains based on AFS or Synergy
      if (domain.hostname === global) {
        // return all:
        if (isDual || isLocal) return true;
        // Production:
        if (isAFS && domain.domain.includes('afs')) return true;
        if (isSynergy && domain.domain.includes('synergy')) return true;
      }

      return true;
    });
  } catch (error) {
    BugTracker.notify(error);
    console.error('Error fetching domains: ', error);
    return [];
  }
};
