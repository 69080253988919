import { StyleSheet } from '@react-pdf/renderer';
import { store } from 'redux/store';

const theme = store.getState().config.theme;
export const styles = StyleSheet.create({
  title: {
    paddingLeft: 10,
    paddingTop: 10,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  subTitle: {
    paddingLeft: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  item: {
    padding: 6,
    paddingLeft: 10,
    fontSize: 9,
    color: 'rgba(19,23,28,255)'
  },
  section: {
    margin: 8,
    padding: 3,
    border: '1px solid #EEE'
  },
  page: {
    height: '100%',
    width: '100%',
    padding: '15 3'
  },
  table: {
    display: 'flex',
    width: 'auto',
    height: 'auto'
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row'
  },
  tableCol: {
    width: '6%',
    borderBottom: '1px solid #EEE',
    borderRight: '1px solid #EEE',
    height: 'auto'
  },
  tableCell: {
    fontSize: 9,
    padding: 1,
    textAlign: 'center'
  },
  tableCellHeader: {
    fontSize: 9,
    padding: 1,
    textAlign: 'center'
  },
  tableCellData: {
    fontSize: 9,
    padding: 1,
    textAlign: 'center'
  }
});
