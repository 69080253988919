import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Drawer } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { Sidebar, Topbar } from './components';
import useStyles from './styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import * as gtag from 'Utils/gtag';
import { SET_DRAW } from '../../redux/actions/types';
import { Typography } from '@material-ui/core';

const Dashboard = (props) => {
  const padding = props.padding;
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = ['xs', 'sm', 'md'].includes(props.width);
  const [state, setState] = useState({ isOpen: !isMobile });
  const handleClose = () => setState({ ...state, isOpen: false });
  const handleToggleOpen = () => setState({ ...state, isOpen: !isOpen });
  const { title, children } = props;
  const isMob = useMediaQuery(theme.breakpoints.down('sm'));

  const { isOpen } = state;
  const shiftTopbar = isOpen && !isMobile;
  const shiftContent = isOpen && !isMobile;

  React.useEffect(() => {
    dispatch({ type: SET_DRAW, payload: { isMobile: isMob, isOpen } });
  }, [isMobile, isOpen, isMob]);

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production')
      gtag.pageview(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <Fragment>
      <Topbar
        className={classNames(classes.topbar, {
          [classes.topbarShift]: shiftTopbar
        })}
        isSidebarOpen={isOpen}
        onToggleSidebar={handleToggleOpen}
        title={title}
      />
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        onClose={handleClose}
        open={isOpen}
        variant={isMobile ? 'temporary' : 'persistent'}>
        <Sidebar className={classes.sidebar} />
      </Drawer>

      <main
        className={classNames(classes.content, {
          [classes.contentShift]: shiftContent
        })}
        style={{ padding: padding === 'noPadding' ? 0 : 15 }}>
        {children}
      </main>
    </Fragment>
  );
};

export default Dashboard;
