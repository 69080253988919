import { useState, useEffect, useRef } from 'react';
import { firebase } from 'redux/firebase';
import {
  Typography,
  Chip,
  makeStyles,
  Theme,
  createStyles,
  TextField,
  Button
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { IPlatformSettings } from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: { margin: 1 }
  })
);

export const PlatformSettings = () => {
  const [config, setConfig] = useState<IPlatformSettings>({
    FFFViews: [],
    processesThatUseQuotes: [],
    QuoteViews: [],
    SpecialObjects: [],
    SaveableObjects: [],
    IndicativeQuoteViews: [],
    TimeStampFields: [],
    SuperAdmins: []
  });

  const deleteChip = (id: number, key: string) => {
    const ref = firebase
      .firestore()
      .collection('globalSetting')
      .doc('displayConfig');
    ref.update({ [key]: firebase.firestore.FieldValue.arrayRemove(id) });
  };

  const submit = (id: number, key: string) => {
    const ref = firebase
      .firestore()
      .collection('globalSetting')
      .doc('displayConfig');
    ref
      .get()
      .then((res) => {
        config[key] = [id];
        if (!res.exists) return ref.set(config);
        return ref.update({
          [key]: firebase.firestore.FieldValue.arrayUnion(id)
        });
      })
      .catch((e) => {
        BugTracker.notify(e);
        // console.log({ e });
      });
  };

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection('globalSetting')
      .doc('displayConfig')
      .onSnapshot((doc) => {
        const data = doc.data() as IPlatformSettings;
        data && setConfig(data);
      });
    return () => unsub();
  }, []);

  // if (!config) return <CircularProgress />;
  return (
    <PlatformSettingsView
      config={config}
      deleteChip={deleteChip}
      submit={submit}
    />
  );
};

const PlatformSettingsView = ({
  config,
  deleteChip,
  submit
}: {
  config: IPlatformSettings;
  deleteChip: any;
  submit: any;
}) => {
  const classes = useStyles();

  return (
    <div>
      {Object.keys(config).map((key) => {
        return (
          <>
            <Typography> {key}</Typography>
            {config?.[key]?.map((id: number) => {
              return (
                <>
                  <Chip
                    key={id}
                    label={id}
                    className={classes.chip}
                    onDelete={() => deleteChip(id, key)}
                  />
                </>
              );
            })}
            <AddToArray name={key} submit={submit} />
          </>
        );
      })}
    </div>
  );
};

const AddToArray = ({ name, submit }) => {
  const [edit, setEdit] = useState(false);
  const toggle = () => setEdit(!edit);

  if (edit)
    return (
      <ClickAwayListener onClickAway={toggle}>
        <TextField
          placeholder="Enter Ids or Array Keys"
          style={{ marginLeft: 8 }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              submit(e.target.value, name);
              toggle();
            }
          }}
        />
      </ClickAwayListener>
    );
  return <Button onClick={toggle}>+</Button>;
};
