const AldermoreProposal = {
  createProposalRequest: {
    context: {
      informationSourceCode: '',
      proposalIdentifier: '',
      proposalReferenceIdentifier: '',
      proposalCreateDate: '',
      ProposalCompletedDate: '',
      submitterEmail: '',
      introducerIdentifier: '',
      userIdentifier: '',
      clientFacetoFaceIndicator: true
    },
    clientDetails: {
      clientTypeCode: '',
      existingClientIndicator: true,
      existingClientReference: '',
      consentToKYCIndicator: true,
      participantDetails: [
        {
          roleDetail: {
            rolePlayerTypeCode: ''
          },

          personalDetail: {
            nameTypeCode: '',
            title: '',
            preferredName: '',
            firstName: '',
            middleName: '',
            surname: '',
            maidenName: '',
            dateofBirth: '',
            birthCountry: '',
            birthTown: '',
            gender: '',
            nationality: ''
          },

          addressDetails: [
            {
              addressTypeCode: '',
              buildingName: '',
              buildingNumber: '',
              streetName: '',
              locality: '',
              town: '',
              county: '',
              postalCode: '',
              country: '',
              movedInDate: '',
              movedOutDate: ''
            }
          ],

          contactDetails: {
            contactTelephoneNumber: '',
            contactMobileNumber: '',
            contactEmailAddress: '',
            preferredContactType: ''
          }
        }
      ],
      businessDetails: {
        companiesHouseNumber: '',
        industryCode: '',
        industryTypeCode: '',
        businessName: '',
        establishedDate: '',
        vatStatus: '',
        vatCode: '',
        businessTradingName: '',
        clientBusinessRelationship: {
          directorIndicator: true,
          highNetWorthClientIndicator: true,
          businessActivitiesDescription: ''
        }
      },
      addressDetails: [
        {
          addressTypeCode: '',
          buildingName: '',
          buildingNumber: '',
          streetName: '',
          locality: '',
          town: '',
          county: '',
          postalCode: '',
          country: '',
          movedInDate: '',
          movedOutDate: ''
        }
      ]
    },

    productDetails: {
      productTypeCode: '',
      productSubTypeCode: ''
    },

    assetDetails: [
      {
        assetPurposeType: '',
        assetCategory: '',
        assetType: '',
        assetQuantity: 0,
        assetModel: '',
        assetManufacturerDetails: '',
        assetManufacturedYear: '',
        fuelType: '',
        assetDealerDetails: '',
        assetConditionType: '',
        assetRegistrationDate: '',
        assetRegistrationNumber: '',
        assetSerialNumber: '',
        assetCAPId: 0,
        assetAge: '',
        assetUsageDetails: '',
        assetNotesDetails: '',
        assetCost: 0,
        assetCostTaxAmount: 0,
        assetRating: '',
        assetsMaintenanceCostPerWeek: 0,
        buyBackProviderType: '',
        buyBackProviderName: '',
        assetResidualPercentage: 0,
        assetResidualAmount: 0
      }
    ],
    financialDetails: {
      totalAmountIncludingTax: 0,
      totalAmountexcludingTax: 0,
      depositAmount: 0,
      depositPercentage: 0,
      subsidyAmount: 0,
      subsidyPercentage: 0,
      totalResidualValue: 0,
      totalBalloonValue: 0,
      valueaddedTax: 0,
      netAdvanceAmount: 0,
      totalTaxAmount: 0,
      taxDeferralPeriod: 0,
      fundingRatePercentage: 0,
      nominalMarginPercentage: 0,
      yieldPercentage: 0,
      partExchangeAmount: 0,
      fundVatIndicator: true
    },
    paymentDetails: {
      paymentFrequencyType: '',
      totalNumberOfPayments: 0,
      paymentInAdvance: '',
      totalInitialPayments: 0
    },
    notesDetails: {
      brokerExternalNotes: [
        {
          type: ''
        }
      ]
    }
  }
};

export default AldermoreProposal;
