import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      // height: '100%',
      // width: '100%',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
      // background: `linear-gradient(to left, ${theme.palette.background.default} 75%, ${theme.palette.primary.light} )`
    },
    logoWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // height: '63px',
      flexShrink: 0,
      paddingTop: theme.spacing(2)
    },
    logoLink: { fontSize: 0, paddingTop: theme.spacing(2) },
    logoImage: {
      cursor: 'pointer',
      height: 35,
      margin: 15
    },
    logoDivider: { marginBottom: theme.spacing(2) },
    profile: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: 'fit-content'
    },
    avatar: { width: '100px', height: '100px', opacity: 0.5 },
    nameText: {
      // marginTop: theme.spacing(2)
    },
    bioText: {},
    profileDivider: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        fontSize: 10
      }
    },
    listSubheader: {
      color: theme.palette.text.secondary
    },
    listItem: {
      with: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      paddingLeft: theme.spacing(2),
      margin: 0,
      cursor: 'pointer',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
        borderLeft: `16px solid ${theme.palette.primary?.['maintrans']}`,
        borderRight: `16px solid ${theme.palette.primary?.['maintrans']}`,
        borderRadius: theme.shape.borderRadius,
        '& $listItemText': { color: theme.palette.primary.contrastText },
        '& $listItemIcon': {
          color: theme.palette.primary.contrastText,
          marginLeft: '-4px',
          [theme.breakpoints.down('sm')]: {
            margin: 0,
            paddingLeft: 0,
            marginLeft: 0,
            borderLeft: `4px solid ${theme.palette.primary?.['maintrans']}`,
            borderRight: `4px solid ${theme.palette.primary?.['maintrans']}`
          }
        }
      },
      '& + &': { marginTop: theme.spacing(1) },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0
      }
    },
    activeListItem: {
      with: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      backgroundColor: theme.palette.primary.light,
      borderLeft: `16px solid ${theme.palette.primary.main}`,
      borderRight: `16px solid ${theme.palette.primary.main}`,
      borderRadius: theme.shape.borderRadius,
      '& $listItemText': { color: theme.palette.primary.contrastText },
      '& $listItemIcon': {
        color: theme.palette.primary.contrastText,
        marginLeft: '-4px',
        [theme.breakpoints.down('sm')]: {
          margin: 0
        }
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        borderLeft: `4px solid ${theme.palette.primary.main}`,
        borderRight: `4px solid ${theme.palette.primary.main}`
      }
    },
    listItemIcon: { marginRight: 0 },
    listItemText: {
      fontWeight: 500,
      color: theme.palette.text.secondary
    },
    listDivider: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1)
    },
    plugin: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 64
      // color: theme.palette.text.secondary
    }
  });
