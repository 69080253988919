import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import { TabList, ItemList } from 'components/RedFlag/TabMenu/TabList';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, paddingTop: theme.spacing(2) },
  appBar: {
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    minHeight: theme.spacing(4)
  }
}));

const TabMenu = ({ company }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={2}>
          <TabList
            value={value}
            company={company}
            setValue={setValue}
            type="vertical"
            pluginRedflag="true"
          />
        </Grid>
        <Grid item xs={10} style={{ paddingLeft: 16 }}>
          <ItemList company={company} value={value} pluginRedflag />
        </Grid>
      </Grid>
    </div>
  );
};

export default TabMenu;
