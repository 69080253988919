import { TTypeSafeRecord } from '../interface';

export const createTypeSafeIds = <T extends object>(
  structure: T,
  builder: (key: TTypeSafeRecord<T>) => Partial<TTypeSafeRecord<T>>
): Partial<TTypeSafeRecord<T>> => {
  const proxy = new Proxy({} as TTypeSafeRecord<T>, {
    get: (target, prop: string | symbol) => {
      if (typeof prop === 'string' && !(prop in target)) {
        if (prop in structure) {
          const value = structure[prop as keyof T];
          if (Array.isArray(value)) {
            target[prop as keyof TTypeSafeRecord<T>] = [] as any;
          } else if (typeof value === 'object' && value !== null) {
            target[prop as keyof TTypeSafeRecord<T>] = createTypeSafeIds(
              value as any,
              (x) => x
            ) as any;
          } else {
            target[prop as keyof TTypeSafeRecord<T>] = undefined;
          }
        }
      }
      return target[prop as keyof TTypeSafeRecord<T>];
    }
  });

  return builder(proxy);
};
