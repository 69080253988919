import React from 'react';
import { useTypedSelector } from 'redux/reducers';
import Detail from 'components/Table/Detail';
import { getDefinition } from 'redux/actions/user';
import Container from '@material-ui/core/Container';
import { PortalLayout } from 'layouts';
import { LinearProgress } from '@material-ui/core';
import { BugTracker } from 'Utils/Bugtracker';

const Portal = () => {
  const token = useTypedSelector((s) => s.user.auth.token);
  const user = useTypedSelector((s) => s.user.user);
  const userList = useTypedSelector((s) => s.userAPI.userList);
  const [CompleteUserDefinition, setCompleteUserDefinition] =
    React.useState<any>();
  const { UserDefinitionId, Id } = user;
  const getUserDefinition = () => {
    return getDefinition({ token, action: 'define', UserDefinitionId })
      .then((res) => {
        setCompleteUserDefinition(res.data);
        // getUserInstanceDetail({
        //   token,
        //   UserInstanceId: Id,
        //   UserDefinitionId,
        //   ForceUpdate: undefined
        // });
      })
      .catch((e) => {
        // console.log({ e });
        BugTracker.notify(e);
      });
  };
  React.useEffect(() => {
    getUserDefinition();
  }, []);

  const CompleteUserInstanceList =
    userList?.[UserDefinitionId]?.[UserDefinitionId];
  const CompleteUserInstance = CompleteUserInstanceList?.find(
    (i) => i.UserInstance.Id == user.Id
  );

  console.log({ CompleteUserInstance });

  return (
    <PortalLayout>
      <Container>
        <br />
        {CompleteUserInstance ? (
          <Detail
            CODL={CompleteUserDefinition?.CompleteObjectDefinitionList}
            ID={UserDefinitionId}
            hasData={false}
            getAllData={() => {}}
            rowData={CompleteUserInstance}
          />
        ) : (
          <LinearProgress />
        )}
        <br />
      </Container>
    </PortalLayout>
  );
};
export default Portal;
