import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import { useTheme } from '@material-ui/core/styles';
import { FieldInstance, FieldDefinition } from 'types/interfaces';

interface Props {
  FieldInstance: FieldInstance;
  FieldDefinition: FieldDefinition;
  Required: boolean;
}

const FieldStatusIcon = ({
  FieldInstance,
  FieldDefinition,
  Required
}: Props) => {
  const theme = useTheme();
  const { FieldType } = FieldDefinition;

  switch (FieldType) {
    case 'Formatted Text': {
      const emptied = '%3Cp%3E%3Cbr%3E%3C%2Fp%3E';
      const isEmptyOrInvalid = (
        value: string | object | undefined
      ): boolean => {
        if (!value) return true;
        if (typeof value === 'string') return value === '' || value === emptied;
        if (typeof value === 'object') {
          if (Array.isArray(value)) return value.length === 0;
          return (
            Object.keys(value).length === 0 ||
            Object.values(value).every(isEmptyOrInvalid)
          );
        }
        return false;
      };

      if (!FieldInstance || isEmptyOrInvalid(FieldInstance.FieldValue)) {
        return (
          <div>
            {Required && (
              <WarningIcon
                style={{
                  color: theme.palette.warning.main,
                  marginRight: 10
                }}
              />
            )}
          </div>
        );
      }

      return null;
    }

    default:
      switch (FieldInstance?.FieldValue) {
        case undefined: {
          return (
            <div>
              {Required && (
                <WarningIcon
                  style={{
                    color: theme.palette.warning.main,
                    marginRight: 10
                  }}
                />
              )}
            </div>
          );
        }

        case 'false': {
          return (
            <div>
              {Required ? (
                <WarningIcon
                  style={{
                    color: theme.palette.warning.main,
                    marginRight: 10
                  }}
                />
              ) : (
                <div />
              )}
            </div>
          );
        }

        default: {
          return (
            <React.Fragment>
              {FieldInstance?.FieldValue !== '' ? (
                <CheckIcon
                  style={{
                    color: theme.palette.success.main,
                    marginRight: 10
                  }}
                />
              ) : (
                <div>
                  {Required && (
                    <WarningIcon
                      style={{
                        color: theme.palette.warning.main,
                        marginRight: 10
                      }}
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          );
        }
      }
  }
};

export default FieldStatusIcon;
