import { StepperContext } from 'components/Stepper/context';
import { useProcess } from 'hooks';
import { useObject } from 'hooks/useObject';
import { createContext, ReactNode, useContext, useRef, useState } from 'react';
import { useSpring } from 'react-spring';
import { getObjectInstanceDocument } from 'redux/database';
import { CompleteObjectInstance } from 'types/interfaces';
import { IProps } from '../interfaces';

interface QuoteObjectContext {
  publishObject: (object: CompleteObjectInstance) => Promise<void>;
  selectObject: (object: CompleteObjectInstance) => Promise<void>;
  declineObject: (object: CompleteObjectInstance) => Promise<void>;
  customDuplicateObject: (object: CompleteObjectInstance) => Promise<void>;
  duplicateObject: (object: CompleteObjectInstance) => Promise<void>;
  downloadObject: (object: CompleteObjectInstance) => Promise<void>;
  deleteObjectAndRefresh: (Id: number) => Promise<void>;
  springProps: any;
  newObjectRef: any;
}

export const QuoteObjectContext = createContext<QuoteObjectContext>({
  publishObject: async () => {},
  selectObject: async () => {},
  declineObject: async () => {},
  customDuplicateObject: async () => {},
  duplicateObject: async () => {},
  downloadObject: async () => {},
  deleteObjectAndRefresh: async () => {},
  springProps: {},
  newObjectRef: {}
});
const QuoteObjectProvider = ({
  children,
  props
}: {
  children: ReactNode;
  props: IProps;
}) => {
  const { currentDeal, user, stepdefdict } = useProcess();
  const { refreshDealData, setQuotesRefreshing } = useContext(StepperContext);
  const { FieldDefinitionList, ObjectDefinition } = props;
  const {
    duplicateObject,
    publishObject,
    deleteObject,
    selectObject,
    declineObject,
    loading
  } = useObject({
    ObjectDefinition,
    UserInstance: user,
    ProcessInstanceId: currentDeal.ProcessInstance.Id,
    Fields: FieldDefinitionList,
    ProcessStepSelectionType:
      stepdefdict?.ProcessStepDefinition?.ProcessStepSelectionType
  });

  const deleteObjectAndRefresh = async (Id: number) => {
    setQuotesRefreshing(true);
    await deleteObject(Id);
    const refresh = await refreshDealData();
    if (refresh) setQuotesRefreshing(false);
  };

  const downloadObject = async (object: CompleteObjectInstance) => {
    const res = await getObjectInstanceDocument({
      ObjectInstanceId: object.ObjectInstance.Id
    });
    if (res && res.status === 200) {
      const file = new Blob([res.data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  const newObjectRef = useRef<any>(null);
  const [springProps, setSpringProps] = useSpring(() => ({
    opacity: 1
  }));

  const customDuplicateObject = async (object: CompleteObjectInstance) => {
    setSpringProps({ opacity: 1 });
    setQuotesRefreshing(true);
    await duplicateObject(object);

    if (newObjectRef.current) {
      newObjectRef.current.scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        setSpringProps({ opacity: 1 });
        setQuotesRefreshing(false);
      }, 2000);
    }
  };

  const value = {
    publishObject,
    selectObject,
    declineObject,
    customDuplicateObject,
    downloadObject,
    duplicateObject,
    deleteObjectAndRefresh,
    springProps,
    newObjectRef
  };

  return (
    <QuoteObjectContext.Provider value={value}>
      {children}
    </QuoteObjectContext.Provider>
  );
};

export default QuoteObjectProvider;
