import React from 'react';
import NumberFormat from 'react-number-format';

export const Currency = ({ value }) => {
  if (value === 0) return <div>---</div>;
  return (
    <NumberFormat
      decimalScale={2}
      fixedDecimalScale
      displayType={'text'}
      prefix={'£'}
      thousandSeparator
      value={value}
    />
  );
};

export const Percentage = ({ value }) => {
  if (parseInt(value) === 0) return <div>---</div>;
  return (
    <NumberFormat
      decimalScale={5}
      displayType={'text'}
      suffix={'%'}
      thousandSeparator
      value={value}
    />
  );
};
