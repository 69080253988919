import { Button, createStyles, Dialog, makeStyles } from '@material-ui/core';
import { CalendarToday } from '@material-ui/icons';
import MainCalendar from 'components/Calendar';
import { useState } from 'react';
import { theme } from 'theme';

const useStyles = makeStyles((theme) =>
  createStyles({
    greenButton: {
      backgroundColor: theme.palette.primary.light,
      justifyContent: 'flex-start',
      textTransform: 'none',
      height: 35,
      '&:hover': {
        borderRight: `50px solid ${theme.palette.primary.main}`,
        background: theme.palette.grey[300],
        color: theme.palette.grey[800],
        cursor: 'pointer',
        '& > p': {
          color: theme.palette.grey[800]
        },
        '& > svg': {
          color: theme.palette.grey[800]
        }
      },
      color: 'white'
    }
  })
);

const StepperCalendar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}>
        <div style={{ margin: theme.spacing(2) }}>
          <MainCalendar />
        </div>
      </Dialog>

      <Button
        style={{
          width: '100%',
          textTransform: 'none',
          fontWeight: 'bold'
        }}
        startIcon={<CalendarToday style={{ marginLeft: 5 }} />}
        className={classes.greenButton}
        onClick={async () => {
          setOpen(true);
        }}>
        Calendar
      </Button>
    </div>
  );
};

export default StepperCalendar;
