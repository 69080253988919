import { useEffect } from 'react';
import { Asset } from 'types/calculatorInterfaces';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  CompleteProcessInstance,
  FieldDefinition,
  FieldInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { AssetToRender, IImportationData } from '../interface';

/**
 * Custom hook for comparing assets and detecting changes that need reporting.
 *
 * @param assetsToRender - Array of assets to be rendered and compared
 * @param currentDeal - The current process instance containing all deal information
 * @param assetsDefs - Array of asset definitions
 * @param setImportation - Function to set importation data when changes are detected
 */
const useAssetComparison = (
  assetsToRender: AssetToRender[],
  currentDeal: CompleteProcessInstance,
  assetsDefs: CompleteObjectDefinition[],
  setImportation: (data: IImportationData) => void
) => {
  useEffect(() => {
    /**
     * Retrieves the value of a specific field from field instances.
     *
     * @param fieldInstances - Array of field instances
     * @param fieldDefinitions - Array of field definitions
     * @param fieldName - Name of the field to retrieve
     * @returns The field value as string, number, or null if not found
     */
    const getFieldValue = (
      fieldInstances: FieldInstance[],
      fieldDefinitions: FieldDefinition[] | undefined,
      fieldName: string
    ): string | number | null => {
      if (!fieldDefinitions) return null;

      let fieldTitle = fieldName;
      switch (fieldName) {
        case 'Price':
          fieldTitle = 'Price Per Unit';
          break;
        case 'Non_VATable_item':
          fieldTitle = 'Non VATable Items';
          break;
      }

      const fieldDef = fieldDefinitions.find((def) => def.Title === fieldTitle);
      if (!fieldDef) return null;

      const fieldInstance = fieldInstances.find(
        (inst) => inst.FieldDefinitionId === fieldDef.Id
      );

      return fieldInstance ? fieldInstance.FieldValue : null;
    };

    /**
     * Compares two values, handling type mismatches between string and number.
     *
     * @param importedValue - The value from the imported asset
     * @param newValue - The new value to compare against
     * @returns True if values are different, false otherwise
     */
    const compareValues = (
      importedValue: string | number,
      newValue: string | number
    ): boolean => {
      if (typeof importedValue === 'number' && typeof newValue === 'string') {
        return importedValue.toString() !== newValue;
      }
      if (typeof importedValue === 'string' && typeof newValue === 'number') {
        return (
          !isNaN(parseFloat(importedValue)) &&
          parseFloat(importedValue) !== newValue
        );
      }
      if (typeof importedValue === 'number' && typeof newValue === 'number') {
        return (
          !isNaN(importedValue) &&
          !isNaN(newValue) &&
          importedValue !== newValue
        );
      }
      return importedValue !== newValue;
    };

    /**
     * Safely parses a string or number to a float, returning null for NaN.
     *
     * @param value - The value to parse
     * @returns The parsed number or null if NaN
     */
    const safeParseFloat = (value: string | number): number | null => {
      const parsed = typeof value === 'string' ? parseFloat(value) : value;
      return isNaN(parsed) ? null : parsed;
    };

    /**
     * Compares an imported asset with its complete object instance.
     *
     * @param importedAsset - The imported asset to compare
     * @param completeAsset - The complete object instance of the asset
     * @param assetDefinition - The definition of the asset
     * @returns An object containing needsReporting flag and newAssetData
     */
    const compareAssets = (
      importedAsset: AssetToRender,
      completeAsset: CompleteObjectInstance,
      assetDefinition: CompleteObjectDefinition
    ) => {
      const fieldInstances = getFieldInstances(completeAsset);
      const { FieldDefinitionList } = assetDefinition;

      const getValue = (name: string) =>
        getFieldValue(fieldInstances, FieldDefinitionList, name);

      // List of fields to compare in the asset
      const fieldsToCompare: (keyof Asset)[] = [
        'Price',
        'TotalPrice',
        'Make',
        'AssetCategory',
        'Equipments',
        'Vehicles',
        'Model',
        'Quantity',
        'Year',
        'Non_VATable_item',
        'Deposit',
        'VAT_Rate',
        'Vat'
      ];

      // Create new asset data by comparing each field
      const newAssetData = fieldsToCompare.reduce((acc, field) => {
        const value = getValue(field);

        if (value !== null) {
          switch (field) {
            case 'Price':
              acc[field] = value.toString();
              break;
            case 'TotalPrice':
            case 'Quantity':
            case 'Year':
            case 'Non_VATable_item':
            case 'Deposit':
            case 'VAT_Rate':
            case 'Vat':
              {
                const parsedValue = safeParseFloat(value);
                if (parsedValue !== null) {
                  acc[field] = parsedValue;
                }
              }
              break;
            case 'Make':
            case 'AssetCategory':
            case 'Equipments':
            case 'Vehicles':
            case 'Model':
              acc[field] = value.toString();
              break;
          }
        }
        return acc;
      }, {} as Partial<Asset>);

      // Check if any field needs reporting (i.e., has changed)
      const needsReporting = fieldsToCompare.some((field) => {
        const newValue = newAssetData[field];
        return (
          newValue !== undefined &&
          compareValues(importedAsset[field], newValue)
        );
      });

      return { needsReporting, newAssetData };
    };

    const assetsNeedingReport: AssetToRender[] = [];
    // Iterate through each asset to render and compare
    assetsToRender.forEach((asset) => {
      if (!asset.isImported) return;

      // Find the corresponding complete asset instance
      const completeAsset = Object.values(
        currentDeal.CompleteObjectInstanceDict
      ).find((obj) => obj.ObjectInstance.Id === asset.ObjectInstanceId);

      if (!completeAsset) return;

      // Find the asset definition
      const assetDefinition = assetsDefs.find(
        (def) =>
          def.ObjectDefinition.Id ===
          completeAsset.ObjectInstance.ObjectDefinitionId
      );

      if (!assetDefinition) return;

      // Compare the asset and add to assetsNeedingReport if reporting is needed
      const { needsReporting, newAssetData } = compareAssets(
        asset,
        completeAsset,
        assetDefinition
      );

      if (needsReporting) {
        assetsNeedingReport.push({ ...asset, ...newAssetData });
      }
    });

    // If there are assets needing report, set the importation data
    if (assetsNeedingReport.length > 0) {
      setImportation({
        needsReporting: true,
        Assets: assetsNeedingReport
      });
    }
  }, [assetsToRender]);
};

export default useAssetComparison;
