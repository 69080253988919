import React from 'react';
import Button from '@material-ui/core/Button';
import Office365 from 'assets/Icons/png/office365.png';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { loginRequest } from 'services/microsoft/authConfig';
import { Grid, CircularProgress } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import { BugTracker } from 'Utils/Bugtracker';

export const ConnectAccountButton = () => {
  const isAuth = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [listenerConnected, setListener] = React.useState(false);

  const handleLogin = () =>
    instance.loginPopup(loginRequest).catch((e) => BugTracker.notify(e));
  const handleLogout = () => instance.logoutPopup();
  const handleConnect = () => setListener(!listenerConnected);

  if (inProgress === 'login') return <CircularProgress />;
  if (isAuth) {
    return (
      <Grid container direction="column">
        <Grid item>
          <Button color="secondary" onClick={handleLogout}>
            <img src={Office365} height={50} width={50} />
            Disconnect Microsoft Office 365
          </Button>
        </Grid>
        <Grid item>
          <Switch checked={listenerConnected} onChange={handleConnect} />
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Button color="secondary" onClick={handleLogin}>
        <img src={Office365} height={50} width={50} />
        Connect Microsoft Office 365
      </Button>
    );
  }
};
