import React from 'react';
import MSO365 from 'assets/Icons/png/office365.png';
import { useIsAuthenticated } from '@azure/msal-react';

const ConnectedIcon = () => {
  const isAuth = useIsAuthenticated();
  return (
    <img
      src={MSO365}
      height="30"
      width="30"
      style={{
        filter: !isAuth ? 'grayscale(100%)' : '',
        opacity: !isAuth ? 0.5 : 1,
        marginRight: 8
      }}
    />
  );
};

export default ConnectedIcon;
