import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Theme, Typography } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { objectProgress } from 'components/Stepper/functions/objectprogress';
import { useProcess } from 'hooks/useProcess';
import Badge from '@material-ui/core/Badge';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { CompleteObjectDefinition } from 'types/interfaces';
import { IObjectLayoutProps } from '../interfaces';
import { theme } from 'theme';
import { isHidden } from '../helpers';
import { useTypedSelector } from 'redux/reducers';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper
    },
    primary: { color: theme.palette.text.primary },
    title: {
      color: theme.palette.text.secondary,
      fontWeight: 'bold',
      paddingTop: theme.spacing(2)
    }
  })
);

const GroupedObjectMenu = ({
  CompleteObjectDefinitionList,
  title,
  handleValueChange,
  value
}: IObjectLayoutProps) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [totalOutstandingRequiredFieldsForGroup, setTORFFG] = React.useState(0);
  const { entityType } = useTypedSelector((s) => s.process);
  const { currentDeal } = useProcess();

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const isSelectedGroup = CompleteObjectDefinitionList.find(
    (el) => el.ObjectDefinition.Id === value
  );

  const handleClose = () => setAnchorEl(null);

  React.useEffect(() => {
    const reqFields: number[] = CompleteObjectDefinitionList.map(
      (CompleteObjectDefinition: CompleteObjectDefinition) => {
        const { _requiredFieldsTODO: badgeContent } = objectProgress({
          CompleteObjectDefinition,
          currentDeal,
          entityType
        });
        return badgeContent;
      }
    );
    setTORFFG(reqFields.reduce((partialSum, a) => partialSum + a, 0));
  }, [currentDeal, CompleteObjectDefinitionList]);

  return (
    <div
      className={classes.root}
      style={{
        background: isSelectedGroup ? theme.palette.primary.main : '',
        borderRadius: isSelectedGroup ? 10 : 0,
        padding: isSelectedGroup ? 8 : 0
      }}>
      <ClickAwayListener onClickAway={handleClose}>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center'
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleClickListItem(e);
          }}>
          <Typography
            style={{
              color: isSelectedGroup ? 'white' : theme.palette.text.secondary,
              fontWeight: 500
            }}>
            {title}
          </Typography>

          <KeyboardArrowDownIcon
            style={{
              color: isSelectedGroup ? 'white' : theme.palette.text.secondary
            }}
          />

          {totalOutstandingRequiredFieldsForGroup > 0 && (
            <Badge
              badgeContent={totalOutstandingRequiredFieldsForGroup}
              style={{ color: theme.palette.warning.main }}>
              {totalOutstandingRequiredFieldsForGroup && (
                <ErrorOutlineIcon
                  style={{
                    color: theme.palette.warning.main,
                    marginLeft: 10
                  }}
                />
              )}
            </Badge>
          )}
        </div>
      </ClickAwayListener>

      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        onMouseLeave={() => handleClose()}
        open={Boolean(anchorEl)}>
        {CompleteObjectDefinitionList.map(
          (CompleteObjectDefinition: CompleteObjectDefinition) => {
            const { Title, Id } = CompleteObjectDefinition.ObjectDefinition;
            const isReqFields: boolean =
              objectProgress({
                CompleteObjectDefinition,
                currentDeal,
                entityType
              })._requiredFieldsTODO > 0;
            const { _requiredFieldsTODO: badgeContent } = objectProgress({
              CompleteObjectDefinition,
              currentDeal,
              entityType
            });

            const result = isHidden(CompleteObjectDefinition, entityType);
            if (result) return <div key={Id} />;
            return (
              <MenuItem
                data-cy="grouped-object-menu-item"
                selected={Id === value}
                key={Id}
                onClick={(event) => {
                  handleValueChange(Id);
                  handleClose();
                }}>
                {Title}
                {isReqFields && (
                  <Badge
                    badgeContent={badgeContent}
                    style={{ color: theme.palette.warning.main }}>
                    <ErrorOutlineIcon
                      style={{
                        color: theme.palette.warning.main,
                        marginLeft: 10
                      }}
                    />
                  </Badge>
                )}
              </MenuItem>
            );
          }
        )}
      </Menu>
    </div>
  );
};

export default GroupedObjectMenu;
