import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core';

function ConfirmationDialogRaw(props) {
  const {
    onClose,
    value,
    value: valueProp,
    open,
    options,
    handleChange,
    state,
    ...other
  } = props;
  // const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef(null);

  React.useEffect(() => {
    if (!open) {
      // setValue(valueProp);
      handleChange('type', valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };

  const handleUpdate = (e) => {
    // setValue(event.target.value);
    handleChange('type', e.target.value, state);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      onEntering={handleEntering}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}>
      <DialogTitle id="confirmation-dialog-title">Activity Type</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={value}
          onChange={handleUpdate}>
          {options.map((option) => (
            <FormControlLabel
              value={option}
              key={option}
              control={<Radio />}
              label={option}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialogRaw.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 160,
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    width: '75%',
    maxHeight: 435,
    maxWidth: 500
  }
}));

export default function ConfirmationDialog(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  // const [value, setValue] = React.useState('Note');

  const handleClickListItem = () => setOpen(true);
  const handleClose = (newValue) => {
    setOpen(false);
    // if (newValue) setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <List component="div" role="list">
        <ListItem
          button
          divider
          // aria-haspopup="true"
          // aria-controls="ringtone-menu"
          // aria-label="phone ringtone"
          onClick={handleClickListItem}
          role="listitem">
          <ListItemText primary="Event Type" secondary={props.value} />
        </ListItem>

        <ConfirmationDialogRaw
          classes={{ paper: classes.paper }}
          id="ringtone-menu"
          keepMounted
          open={open}
          onClose={handleClose}
          value={props.value}
          options={props.options}
          handleChange={props.handleChange}
          state={props.state}
        />
      </List>
    </div>
  );
}
