import { useState, useEffect } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { countRequiredFields, findFinanceParentValue } from '../functions';
import { computeHiddenFields } from '../helper';
import { IProposal, TLenderAPIInterfaces } from '../interface';

/**
 * Custom hook for field validation in forms
 * @param control - The control object from react-hook-form
 * @returns An object containing the total required fields, completed fields, and error count
 */
const useFieldValidation = (
  control: Control<TLenderAPIInterfaces, object>,
  proposal: IProposal
) => {
  const watchedForm = useWatch({ control });
  const [totalRequired, setTotalRequired] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [errors, setErrors] = useState(0);
  const [financeParentValue, setFinanceParentValue] = useState<string>('');

  useEffect(() => {
    const hiddenFields = computeHiddenFields(watchedForm, proposal);
    const financeParentValue = findFinanceParentValue(
      watchedForm as TLenderAPIInterfaces
    );

    const { total, completed, errors } = countRequiredFields({
      data: watchedForm,
      hiddenFields,
      financeParentValue
    });

    setFinanceParentValue(financeParentValue);
    setTotalRequired(total);
    setCompleted(completed);
    setErrors(errors);
  }, [watchedForm, financeParentValue]);

  return { totalRequired, completed, errors, watchedForm, financeParentValue };
};

export default useFieldValidation;
