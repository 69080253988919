import { UPDATE_FORM_VALUE } from 'redux/actions/types';

const initialState = {
  value: 0
};

interface UpdateValue {
  type: typeof UPDATE_FORM_VALUE;
  payload: number;
}

type formAction = UpdateValue;

export default (state = initialState, action: formAction) => {
  switch (action.type) {
    case UPDATE_FORM_VALUE:
      return { ...state, value: action.payload };
    default:
      return state;
  }
};
