import axios from 'axios';
import * as gtag from 'Utils/gtag';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

interface DeleteSender {
  id: number;
  key: string;
}

export const deleteSender = async ({
  id,
  key
}: DeleteSender): Promise<void> => {
  try {
    const token = await firebase.auth().currentUser?.getIdToken();
    const url = `${process.env.REACT_APP_PROXY}/marketing/senders/${id}/${key}`;

    await axios.delete(url, { headers: { token } });

    gtag.event({
      feature: 'Marketing',
      action: 'DeleteSender: Success',
      message: 'Success'
    });
  } catch (e) {
    return BugTracker.notify(e);
  }
};
