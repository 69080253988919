export const JediEntry = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 10,
        right: 10,
        background: 'white',
        color: 'black',
        padding: 10
      }}>
      Jedi Entry{' '}
      <select>
        <option value={1} label="synergy" />
        <option value={2} label="afs" />
        <option value={3} label="other" />
      </select>
    </div>
  );
};
