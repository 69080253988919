/**
 * component main responsibility is to display the active & closed deals within a table element (with additional details too)
 */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Dialog from 'components/Stepper/components/Overview/Entry';

import { getSortedColumns, useIsMounted } from './functions';
import { useTypedSelector } from 'redux/reducers';
import { GetProcessByUserFinishedOrUnfinished } from 'redux/database';
import { BugTracker } from 'Utils/Bugtracker';

import { ProcessSummary } from 'types/interfaces';

import {
  IColumn,
  IColumnOrder,
  IProcessTableProps,
  ITableData
} from './interface';
import { columns } from './functions/ColumRendering';
import Table from './components';
import { useColumnSaver } from './hooks/useColumnSaver';
import { GetAllProcessesFinished } from 'redux/database/Process Summary API';
import { CircularProgress, LinearProgress } from '@material-ui/core';

const Component = ({ title, getAllData }: IProcessTableProps) => {
  const dispatch = useDispatch();

  const [loadingColumns, setLoading] = useState(false);
  const { processSummaries } = useTypedSelector((state) => state.process);
  const { Id, SystemAccess } = useTypedSelector((s) => s.user.user);

  const [open, setOpen] = React.useState(false);
  const [saveColumnLayout, setSaveColumnLayout] = React.useState<IColumn[]>();
  const [saveJSON, setSaveJSON] = useState<IColumnOrder[]>([]);

  const [isActiveDealList, setIsActiveDealList] = useState<boolean>(true);
  const [closedData, setClosedData] = useState<ProcessSummary[]>(
    [] as ProcessSummary[]
  );

  const { handleColumnSaving } = useColumnSaver(Id, saveJSON);
  const mounted = useIsMounted();

  useEffect(() => {
    return () => {
      if (!mounted()) handleColumnSaving();
    };
  }, [mounted]);

  const fetchFinishedProcessByUser = async () => {
    setLoading(true);
    try {
      if (SystemAccess <= 5) {
        const res = await GetProcessByUserFinishedOrUnfinished({
          Id: Id.toString(),
          finishedDeal: true
        });
        if (res.status === 200) {
          const closedProcessSummaries = res.data as ProcessSummary[];
          setClosedData(closedProcessSummaries);
        }
      } else {
        const res = await GetAllProcessesFinished();
        if (res.status === 200) {
          const closedProcessSummaries = res.data as ProcessSummary[];
          setClosedData(closedProcessSummaries);
        }
      }
      handleGettingColumnLayout();
    } catch (error) {
      BugTracker.notify(error);
    }

    setLoading(false);
  };

  const handleGettingColumnLayout = async () => {
    const savedColumnLayout: IColumnOrder[] = await getSortedColumns(
      Id.toString()
    );

    if (savedColumnLayout.length !== columns.length) {
      setSaveColumnLayout([...columns]);
      return;
    }

    let newColumns = [...columns];
    newColumns.forEach((column: IColumn) => {
      if (savedColumnLayout) {
        const savedTableData = Object.values(savedColumnLayout).find(
          (el: IColumnOrder) => el.title === column.title
        );

        if (savedTableData) {
          if (!column.tableData) {
            column.tableData = {} as ITableData;
          }

          if ('columnOrder' in savedTableData) {
            column.tableData.columnOrder = Number(savedTableData.columnOrder);
            column.tableData.id = Number(savedTableData.columnOrder);
          }

          if ('hidden' in savedTableData) {
            column.hidden = savedTableData.hidden;
          }
        }
      }
    });

    newColumns.sort((a, b) => {
      const orderA = a.tableData?.columnOrder || 0;
      const orderB = b.tableData?.columnOrder || 0;
      return orderA - orderB;
    });

    setSaveColumnLayout(newColumns);
    setLoading(false);
  };

  useEffect(() => {
    fetchFinishedProcessByUser();
  }, []);

  const handleCloseDialog = () => {
    getAllData();
    setOpen(false);
  };

  if (loadingColumns) return <LinearProgress />;
  return (
    <div data-cy="deal-table" style={{ maxWidth: '100%' }}>
      <Dialog handleClose={handleCloseDialog} open={open} />

      {saveColumnLayout && (
        <Table
          saveColumnLayout={saveColumnLayout}
          title={title}
          getAllData={getAllData}
          setOpen={setOpen}
          isActiveDealList={isActiveDealList}
          setIsActiveDealList={setIsActiveDealList}
          closedData={closedData}
          processSummaries={processSummaries}
          setSaveJSON={setSaveJSON}
        />
      )}
    </div>
  );
};

export default Component;
