/**pdf component which displays the object data of the deal
 * object data depended on the type of object it is: eg. singleObject / repeatableObject / etc
 */
import { Document, Text, View, Page, StyleSheet } from '@react-pdf/renderer';
import { ObjectDefinition } from 'types/interfaces';
import { objectType } from '../../Forms/FormContent';
import SingleObject_pdf from './SingleObject_pdf';
import RepeatableObject_pdf from './RepeatableObject_pdf';
import RepeatDynamicObject_pdf from './RepeatDynamicObject_pdf';
import RepeatDynamicAssetObject_pdf from './RepeatDynamicAssetObject_pdf';
import { IProps } from './interface';

// Create styles
const styles = StyleSheet.create({
  page: {
    height: '100%',
    width: '100%',
    padding: '20 60'
  },
  section: {
    margin: '3 0 6 0'
  },
  heading: {
    fontWeight: 'extrabold',
    fontSize: 17
  }
});

const PdfDownloadFile = (props: IProps) => {
  const { currentObjectType, ObjectDefinition } = props;

  if (currentObjectType === objectType.singleObject) {
    return <SingleObject_pdf {...props} />;
  } else if (currentObjectType === objectType.repeatableObject) {
    return <RepeatableObject_pdf {...props} />;
  } else if (currentObjectType === objectType.repeatDynamicObject) {
    return <RepeatDynamicObject_pdf {...props} />;
  } else if (currentObjectType === objectType.repeatDynamicAssetObject) {
    return <RepeatDynamicAssetObject_pdf {...props} />;
  }

  // objectType.nullObject or the other object types which is not in the conditional statement, will show no data because either it is file type or broken in some way
  return (
    <Document>
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.heading}>{ObjectDefinition?.Title}</Text>
        </View>

        <View style={styles.section}>
          <Text>No data to show</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDownloadFile;
