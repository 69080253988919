import React from 'react';
import { BugTracker } from 'Utils/Bugtracker';
import { useProcess } from './useProcess';
import { useTypedSelector } from 'redux/reducers';
import { IRegulated } from 'types/interfaces';

export const useRegulated = () => {
  // TODO: Implement the useRegulated hook for non useProcess use cases here
  // as this re-fetches the data on every render currently

  let { checkRegulatedPartyStatus, landingpage } = useProcess();

  const [regulated, setRegulated] = React.useState<IRegulated>({
    hasEntity: false,
    isRegulatedParty: false
  });

  const regulatedStatus = useTypedSelector((s) => s.process.regulatedStatus);
  const entityTypeFromRedux = useTypedSelector((s) => s.process.entityType);

  const regCheck = async () => {
    try {
      const response = await checkRegulatedPartyStatus();
      setRegulated(response);
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  React.useEffect(() => {
    if (!landingpage) {
      setRegulated({
        hasEntity: entityTypeFromRedux !== '',
        isRegulatedParty: regulatedStatus
      });
    } else {
      regCheck();
    }
  }, [landingpage, regulatedStatus, entityTypeFromRedux]);

  return { regulated };
};
