import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * SETTINGS API
 */
export const GetSettings = async () => {
  let url = `SettingsAPI.ashx`;
  const config: AxiosRequestConfig = { url, method: 'get' };
  return await dbQueue({ config, url, method: 'GetSettings' });
};
