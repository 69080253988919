import { Grid, Typography } from '@material-ui/core';
import { theme } from 'theme';
import { Asset } from 'types/calculatorInterfaces';

interface ITotalInfoRowProps {
  title: string;
  value: string;
}

const TotalInfo = ({ assetsValues }: { assetsValues: Asset[] }) => {
  let Total = {
    grandTotalBeforeVAT: 0,
    totalNonVATableItems: 0,
    grandTotal: 0
  };

  const filteredAssets = assetsValues.filter((asset: Asset) => {
    const totalPrice = asset.TotalPrice * asset.Quantity;
    return !isNaN(totalPrice) && totalPrice !== 0;
  });

  filteredAssets.forEach((asset: Asset) => {
    const totalPrice = asset.TotalPrice;
    const quantity = asset.Quantity;
    const nonVATableItems = asset.Non_VATable_item;

    Total.grandTotalBeforeVAT += (totalPrice - nonVATableItems) * quantity;
    Total.totalNonVATableItems += nonVATableItems * quantity;
    Total.grandTotal += totalPrice * quantity;
  });

  const formattedGrandTotalBeforeVAT = formatToGBP(Total.grandTotalBeforeVAT);
  const formattedTotalNonVATableItems = formatToGBP(Total.totalNonVATableItems);
  const formattedGrandTotal = formatToGBP(Total.grandTotal);
  return (
    <Grid container justifyContent="space-between">
      <Typography
        data-cy="asset-summary-title"
        style={{
          paddingBottom: theme.spacing(1),
          paddingTop: theme.spacing(1)
        }}
        variant="h5">
        Assets
      </Typography>
      <TotalInfoRow
        title="Grand Total Before VAT"
        value={formattedGrandTotalBeforeVAT}
      />
      <TotalInfoRow
        title="Total Non-VATable Items"
        value={formattedTotalNonVATableItems}
      />
      <TotalInfoRow title="Grand Total" value={formattedGrandTotal} />
    </Grid>
  );
};

export default TotalInfo;

export const formatToGBP = (amount: number): string => {
  return new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP'
  }).format(amount);
};

const TotalInfoRow = ({ title, value }: ITotalInfoRowProps) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography
          style={{ fontWeight: 'bold', color: theme.palette.grey[500] }}
          variant="h6">
          {title}:
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          data-cy="asset-grand-total"
          style={{ fontWeight: 'bold', color: theme.palette.grey[500] }}
          variant="h6">
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};
