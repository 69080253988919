import React, { useEffect } from 'react';
import { useRedFlagGQLAdaptor } from 'components/OffkeyCompanyAndDirectorSearch/adaptors/useRedFlagGQLAdaptor';
import { useTypedSelector } from 'redux/reducers';
import { CompanyDetails } from './TabMenu/components/CompanyDetails';
import { DirectorsDetail } from './TabMenu/components/Directors';
import TabMenu from './TabMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { LinearProgress, Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';
import './styles.css';
import { IRedflagAPIData, IRedFlagObject } from 'types/redflagInterfaces';
import { OffkeyCompanyAndDirectorSearch } from 'components/OffkeyCompanyAndDirectorSearch';
import { useCreditSafe } from '../OffkeyCompanyAndDirectorSearch/adaptors';
import {
  IDirectorCompany,
  OffkeyCompany,
  SearchType
} from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IRedFlagDirectorship } from 'components/OffkeyCompanyAndDirectorSearch/helper';
import { RedflagClient } from 'App';

const { Company, Director } = SearchType;

const Redflag = ({
  importedCompany,
  lock,
  directorshipToCompanyData,
  ProcessDefinitionId,
  action,
  showDirectorCompany = { company: false, director: false },
  hideCreateUser = false,
  selectCreated,
  setValidCompany
}: {
  importedCompany?: IRedflagAPIData;
  lock?: SearchType | 'both';
  directorshipToCompanyData?: IRedFlagDirectorship | IDirectorCompany;
  ProcessDefinitionId?: string;
  action?: (data: IRedflagAPIData | IRedFlagDirectorship) => void;
  showDirectorCompany?: undefined | { company: boolean; director: boolean };
  hideCreateUser?: boolean;
  selectCreated?: (Id: string) => void | undefined;
  setValidCompany?: (attributes) => void;
}) => {
  const theme = useTheme();
  const { companyNavigator } = useTypedSelector((s) => s.config);

  // Credit Safe
  const { getCompanyPDF } = useCreditSafe();

  const { getCompanyPDFGQL, getRFGQLCompany } =
    useRedFlagGQLAdaptor(RedflagClient);

  const [selected, setSelected] = React.useState<Partial<IRedFlagObject>>(
    importedCompany?.data || ({} as Partial<IRedFlagObject>)
  );
  const [company, setCompany] = React.useState<IRedflagAPIData>(
    importedCompany || ({} as IRedflagAPIData)
  );

  const [SearchType, setSearchType] = React.useState<SearchType>(Company);

  const [loading, setLoading] = React.useState(false);
  const [pdfDnLoad, setPdfDnLoad] = React.useState(false);
  const [directorship, setDirectorship] =
    React.useState<IRedFlagDirectorship>();

  const isSelected = selected && Object.keys(selected).length > 0;
  const isCompany = Object.keys(company).length > 0;

  const canViewCompany = company?.data?.attributes?.company_number;

  const [download, setDownload] = React.useState<boolean>(false);
  const [eta, setEta] = React.useState<number | null>(null);

  const downloadFile = (fileLocation: string) => {
    const link = document.createElement('a');
    link.href = fileLocation;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadPDF = async () => {
    try {
      let base64 = '';
      if (companyNavigator === 'CreditSafe') {
        base64 = await getCompanyPDF(selected);

        const file = new Blob([base64], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setPdfDnLoad(false);
        window.open(fileURL);
        gtag.event({
          action: 'PDF Downloaded',
          feature: 'Red Flag',
          message: 'User downloaded top level company PDF'
        });
      }
      if (companyNavigator === 'RedflagGRaphQL') {
        setDownload(true);
      }
    } catch (err) {
      BugTracker.notify(err);
      setPdfDnLoad(false);
    }
  };

  const handlePoll = (eta) => {
    setEta(eta);
  };

  useEffect(() => {
    if (companyNavigator === 'RedflagGRaphQL' && download) {
      getCompanyPDFGQL(selected, handlePoll).then(
        ({ completed, eta, fileExport }) => {
          if (completed) {
            downloadFile(fileExport.downloadLocation);
            setDownload(false);
          }
        }
      );
    }
  }, [download]);

  const countdownRef = React.useRef(eta);
  const countdownDisplayRef: any = React.useRef();

  useEffect(() => {
    if (typeof eta === 'number') {
      countdownRef.current = eta;
      const intervalId = setInterval(() => {
        if (typeof countdownRef.current === 'number') {
          countdownRef.current -= 1;
          if (countdownDisplayRef.current) {
            countdownDisplayRef.current.textContent =
              countdownRef.current > 0
                ? `Your PDF will be ready in ${countdownRef.current} second${
                    countdownRef.current > 1 ? 's' : ''
                  }.`
                : 'Almost There, Please Wait';
          }
          if (countdownRef.current <= 0) {
            clearInterval(intervalId);
          }
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [eta]);

  React.useEffect(() => {
    if (showDirectorCompany) {
      if (showDirectorCompany.company) setSearchType(Company);
      if (showDirectorCompany.director) setSearchType(Director);
    }
  }, [showDirectorCompany]);

  React.useEffect(() => {
    if (showDirectorCompany.company) {
      if (action) action(company);
    }
    if (showDirectorCompany.director) {
      if (action) {
        action(directorship as IRedFlagDirectorship);
      }
    }
  }, [company, directorship]);

  // Action for InlineFields
  React.useEffect(() => {
    if (ProcessDefinitionId) {
      if (SearchType === Company) {
        if (!company.data) return;
        if (action) action(company);
      }

      if (SearchType === Director) {
        const director = directorship as IRedFlagDirectorship;
        if (!director) return;
        if (action) action(director);
      }
    }
  }, [company, directorship]);

  const handleCompanySelect = (props) => {
    const {
      third_party_apis,
      third_party_apis: { redflagOG, redflagOG_Source, RedflagGRaphQL }
    } = props;

    if (companyNavigator === 'CreditSafe') {
      setCompany(redflagOG);
      setSelected(redflagOG.data);
    } else if (companyNavigator === 'RedflagOG') {
      setCompany(redflagOG_Source);
      setSelected(redflagOG_Source.data);
    } else if (companyNavigator === 'RedflagGRaphQL') {
      setCompany(redflagOG);
      setSelected(redflagOG.data);
    }
  };

  const goGetCompanyReport = async (company_number) => {
    setLoading(true);
    const res = await getRFGQLCompany(company_number.toString());
    handleCompanySelect(res);
    setLoading(false);
  };

  React.useEffect(() => {
    let company_number;

    if (directorshipToCompanyData) {
      if (
        'data' in directorshipToCompanyData &&
        directorshipToCompanyData.data?.attributes?.company_id
      ) {
        company_number = directorshipToCompanyData.data.attributes.company_id;
      } else if ('company_id' in directorshipToCompanyData) {
        company_number = directorshipToCompanyData.company_id;
      }
    }

    if (company_number) {
      goGetCompanyReport(company_number);
    }
  }, [directorshipToCompanyData]);

  const ShowCompanySummary = () => (
    <Grid item>
      <CompanyDetails
        ProcessDefinitionId={ProcessDefinitionId}
        company={undefined}
        setCompany={setCompany}
        data={selected}
        downloadPDF={downloadPDF}
        loading={pdfDnLoad}
        countdownDisplayRef={countdownDisplayRef}
        isCompleted={download}
        mapLoading={loading}
        directorships={undefined}
        hideCreateUser={hideCreateUser}
        selectCreated={selectCreated}
        setValidCompany={setValidCompany}
      />
    </Grid>
  );

  const ShowCompanyTabs = () => (
    <Grid item style={{ width: '100%' }}>
      <TabMenu company={company} />
    </Grid>
  );

  return (
    <Grid container direction="column" style={{ padding: theme.spacing(1) }}>
      {!directorshipToCompanyData && !importedCompany && (
        <>
          <Grid item xs={12}>
            <OffkeyCompanyAndDirectorSearch
              lock={lock}
              onSelect={handleCompanySelect}
              setSearchType={setSearchType}
              setDirectorship={setDirectorship}
              setLoading={setLoading}
              isNewRecord={showDirectorCompany.director}
              searchType={SearchType}
              setParentCompany={setCompany}
            />
          </Grid>

          {SearchType === Director && (
            <Grid item xs={12}>
              <DirectorsDetail directorship={directorship} />
            </Grid>
          )}
        </>
      )}

      {loading && <LinearProgress />}
      {isSelected && canViewCompany && <ShowCompanySummary />}
      {isCompany && canViewCompany && <ShowCompanyTabs />}
    </Grid>
  );
};

export default Redflag;
