import { useState, useEffect } from 'react';
import {
  Grid,
  Checkbox,
  useTheme,
  Dialog,
  DialogContent,
  Paper,
  Typography,
  IconButton,
  Tooltip
} from '@material-ui/core';

import { useFileStorage, useProcess } from 'hooks';
import { GetAssignedUsersByProcess } from 'redux/database';
import { AssignedUser, FbFileRef } from 'types/interfaces';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { useTypedSelector } from 'redux/reducers';
import { CustomDialog } from 'common/Dialog';
import Xpansion, { XpansionIsolated } from 'common/Xpansion';
import { theme } from 'theme';
import { Alert } from '@material-ui/lab';
import { CheckBox, Close, HelpOutline } from '@material-ui/icons';

interface Props {
  open: boolean;
  global: boolean | undefined;
  item: { [key: string]: FbFileRef } | FbFileRef;
  id?: string;
  handleChange: ({
    key,
    user,
    checked,
    isGrouped
  }: {
    key: string;
    user: AssignedUser | string;
    checked: boolean;
    isGrouped: boolean;
  }) => Promise<void>;
  toggle;
}

const PermissionsPicker = (props: Props) => {
  return (
    <div style={{ padding: 10, width: '100%' }}>
      <CustomDialog
        maxSize="sm"
        open={props.open}
        handleClose={props.toggle}
        alert={{
          title: `Access Control`,
          description: `Assign a User or Group to View This Document`,
          type: 'warning'
        }}>
        <DialogContent style={{ padding: theme.spacing(2) }}>
          <Contents {...props} />
        </DialogContent>
      </CustomDialog>
    </div>
  );
};

export default PermissionsPicker;

const Contents = ({ global, item, id, handleChange, open }: Props) => {
  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const { currentDeal, landingpage, user } = useProcess();
  const ProcessInstanceId = global ? 0 : currentDeal?.ProcessInstance?.Id || 0;
  const [assignedUsers, setAssignedUsers] = useState<AssignedUser[]>([]);
  const subSystemUser = user.SystemAccess <= 4;
  const isASystemUser = user.SystemAccess === 5;
  const isProcessOwner =
    currentDeal?.ProcessInstance?.UserInstanceId === user.Id;
  const isSystemUserAndNotProcessOwner = isASystemUser && !isProcessOwner;

  const canFetchAssignedUsers = () => {
    return !(subSystemUser || isSystemUserAndNotProcessOwner);
  };

  const getAssignedUsers = async () => {
    if (!landingpage && canFetchAssignedUsers()) {
      const res = await GetAssignedUsersByProcess({ Id: ProcessInstanceId });
      console.log({ res });
      const assignedUsers = res.data as AssignedUser[];
      setAssignedUsers(assignedUsers);
    }
  };

  useEffect(() => {
    if (open) getAssignedUsers();
  }, [open]);

  const groupedAssignedUsers = assignedUsers?.reduce(
    (acc: { [key: number]: AssignedUser[] }, user) => {
      if (!acc[user.UserDefinitionId]) {
        acc[user.UserDefinitionId] = [];
      }
      acc[user.UserDefinitionId].push(user);
      return acc;
    },
    {}
  );

  let mergedUsers: string[] = [];
  if ('users' in item && Array.isArray(item.users)) {
    mergedUsers = item.users;
  } else if (typeof item === 'object' && item !== null) {
    mergedUsers = Object.values(item as { [key: string]: FbFileRef }).reduce<
      string[]
    >((acc, curr) => {
      return curr.users && Array.isArray(curr.users)
        ? [...acc, ...curr.users]
        : acc;
    }, []);
  }

  const handleSelectAll = async (
    userDefinitionId: number,
    isChecked: boolean
  ) => {
    const usersInGroup = groupedAssignedUsers[userDefinitionId];

    try {
      for (const user of usersInGroup) {
        if (!id) {
          for (const [key, singleItem] of Object.entries(item)) {
            const idToUse = singleItem.fileId || key;
            await handleChange({
              key: idToUse,
              user,
              checked: isChecked,
              isGrouped: true
            });
          }
        } else {
          await handleChange({
            key: id || '',
            user,
            checked: isChecked,
            isGrouped: false
          });
        }
      }
    } catch (error) {
      console.error(
        `Error in handleSelectAll for UserDefinitionId: ${userDefinitionId}`,
        error
      );
    }
  };

  return (
    <>
      {groupedAssignedUsers &&
        Object.keys(groupedAssignedUsers)
          .reverse()
          .map((userDefinitionIdStr) => {
            const userDefinitionId = parseInt(userDefinitionIdStr);
            const usersInGroup = groupedAssignedUsers[userDefinitionId];

            const atLeastOneChecked = anyChecked(usersInGroup, mergedUsers);
            const isGroupMismatch = isMismatchInGroup(usersInGroup, item, id);
            const allChecked = usersInGroup.every((user: AssignedUser) => {
              return mergedUsers.includes(user.UserInstanceId.toString());
            });

            const userDefinition = UserDefinitionList.find(
              (ud) => ud.Id === userDefinitionId
            );
            const userDefinitionTitle = userDefinition
              ? userDefinition.Title
              : 'Unknown';

            return (
              <XpansionIsolated
                summary={
                  <>
                    <Tooltip arrow title={allChecked ? 'Deselect' : 'Select'}>
                      <Checkbox
                        checkedIcon={
                          isGroupMismatch ? <HelpOutlineInBox /> : <CheckBox />
                        }
                        onChange={() =>
                          handleSelectAll(userDefinitionId, allChecked)
                        }
                        style={{
                          color:
                            isGroupMismatch && atLeastOneChecked
                              ? 'orange'
                              : allChecked
                              ? theme.palette.success.main
                              : 'gray'
                        }}
                        checked={allChecked}
                      />
                    </Tooltip>
                    <div style={{ marginTop: theme.spacing(1.4) }}>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {`Select Everyone in Group: ${userDefinitionTitle}`}
                      </Typography>
                    </div>
                  </>
                }
                expanded={allChecked ? false : true}
                key={userDefinitionId}>
                <Grid container direction="column" spacing={1}>
                  {isGroupMismatch && atLeastOneChecked && (
                    <div
                      style={{
                        margin: theme.spacing(1),
                        paddingBottom: theme.spacing(1)
                      }}>
                      <Alert elevation={2} severity="warning">
                        <Typography style={{ fontWeight: 'bold' }}>
                          There Is An Inconsistency Among The Selected
                          Documents. Some Documents Contain Users That Are Not
                          Present In Others.
                        </Typography>
                      </Alert>
                    </div>
                  )}
                  {usersInGroup.map((user: AssignedUser) => {
                    const userId = user.UserInstanceId.toString();
                    const isMismatch = !isUserInAllObjects(userId, item, id);
                    const checked = mergedUsers.includes(
                      user.UserInstanceId.toString()
                    );

                    return (
                      <>
                        <Grid container direction="row">
                          <Grid
                            key={user.UserInstanceId}
                            item
                            style={{ padding: theme.spacing(0) }}>
                            <Tooltip
                              arrow
                              title={checked ? 'Deselect' : 'Select'}>
                              <Checkbox
                                checkedIcon={
                                  isMismatch ? (
                                    <HelpOutlineInBox />
                                  ) : (
                                    <CheckBox />
                                  )
                                }
                                onChange={async () => {
                                  if (!id) {
                                    try {
                                      for (const [
                                        key,
                                        singleItem
                                      ] of Object.entries(item)) {
                                        console.log(key, singleItem);
                                        const idToUse =
                                          singleItem.fileId || key;
                                        await handleChange({
                                          key: idToUse,
                                          user,
                                          checked,
                                          isGrouped: true
                                        });
                                      }
                                    } catch (error) {
                                      // Add this in for whenever it breaks
                                      console.error(
                                        'Error handling Grouped Items:',
                                        error
                                      );
                                    }
                                  } else {
                                    await handleChange({
                                      key: id || '',
                                      user,
                                      checked,
                                      isGrouped: false
                                    });
                                  }
                                }}
                                checked={checked}
                                style={{
                                  color:
                                    isMismatch && checked
                                      ? 'orange'
                                      : checked
                                      ? theme.palette.success.main
                                      : 'gray'
                                }}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item style={{ paddingLeft: theme.spacing(1) }}>
                            <QuickUserFromId
                              UserInstanceId={user.UserInstanceId.toString()}
                            />
                          </Grid>
                        </Grid>
                      </>
                    );
                  })}
                </Grid>
              </XpansionIsolated>
            );
          })}
    </>
  );
};

const anyChecked = (usersInGroup: AssignedUser[], mergedUsers: string[]) => {
  return usersInGroup.some((user) => {
    return mergedUsers.includes(user.UserInstanceId.toString());
  });
};

const isMismatchInGroup = (
  usersInGroup: AssignedUser[],
  item: FbFileRef | { [key: string]: FbFileRef },
  id: string | undefined
) => {
  return usersInGroup.some((user: AssignedUser) => {
    const userId = user.UserInstanceId.toString();
    return !isUserInAllObjects(userId, item, id);
  });
};

const isUserInAllObjects = (
  user: string,
  items: FbFileRef | { [key: string]: FbFileRef },
  id: string | undefined
): boolean => {
  if (typeof items === 'object' && !id) {
    if ('users' in items && Array.isArray(items.users)) {
      return items.users.includes(user);
    } else if (!('users' in items)) {
      return Object.values(items).every(
        (item) => Array.isArray(item.users) && item.users.includes(user)
      );
    }
  }
  return true;
};

const HelpOutlineInBox = () => (
  <div
    style={{
      width: '21px',
      height: '21px',
      display: 'flex',
      backgroundColor: 'orange',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px'
    }}>
    <HelpOutline style={{ color: 'white', fontSize: '18px' }} />
  </div>
);
