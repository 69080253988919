import {
  EDocumentResults,
  EDocumentType
} from 'graphql/FileStorageAPI/interface';
import {
  createFraudulentDocumentUUID,
  getFraudResultsAction
} from 'redux/database/File Storage API/Actions';
import { UserInstance } from 'types/interfaces';
import * as gtag from 'Utils/gtag';

const mapApiResponseToEnum = (response: string): EDocumentResults => {
  switch (response) {
    case 'Acceptable':
      return EDocumentResults.ACCEPTABLE;
    case 'High Risk':
      return EDocumentResults.HIGH_RISK;
    case 'Risk':
      return EDocumentResults.RISK;
    case 'Low Risk':
      return EDocumentResults.LOW_RISK;
    default:
      return EDocumentResults.NONE;
  }
};

const handleDocumentProgress = async ({
  documentType,
  updateStatus,
  file,
  user
}: {
  documentType: EDocumentType;
  updateStatus: (status: string) => void;
  file: File;
  user: UserInstance;
}): Promise<{
  documentProgress: { documentUUID: string; documentStatus: EDocumentResults };
}> => {
  const documentProgress: {
    documentUUID: string;
    documentStatus: EDocumentResults;
  } = {
    documentUUID: '',
    documentStatus: EDocumentResults.NONE
  };

  if (documentType !== EDocumentType.OTHER) {
    updateStatus('Generating Fraudulent document key, please wait');

    const UUID = await createFraudulentDocumentUUID({
      input: {
        type: documentType,
        side: 'front',
        file,
        account_holder: user.Title
      }
    });

    if (UUID && UUID !== '') {
      documentProgress.documentUUID = UUID;
      updateStatus('Checking status of document, almost finished uploading');

      const response = await getFraudResultsAction({
        collectionId: UUID
      });

      if (response) {
        const status = response.result;
        const documentStatus = mapApiResponseToEnum(status);
        documentProgress.documentStatus = documentStatus;

        // Log the Fraudulent Document on Google Analytics
        gtag.event({
          feature: 'File Explorer',
          action: 'Created Fraudulent Document',
          message: `${documentStatus}`
        });
      }
    }
  }

  return { documentProgress };
};

export { handleDocumentProgress };
