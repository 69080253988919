const initialState = {
  loaded: false,
  access_token: '',
  expires_in: '3600',
  refresh_token: '',
  token_type: 'Bearer',
  willExpire: 0
};
interface SetToken {
  type: 'SET_CRM_TOKEN';
  payload: {
    access_token: string;
    expires_in: string;
    refresh_token: string;
    token_type: 'Bearer';
  };
}

type crmAction = SetToken;

const crmReducer = (state = initialState, action: crmAction) => {
  switch (action.type) {
    case 'SET_CRM_TOKEN':
      return {
        ...state,
        loaded: true,
        willExpire: Date.now() + 1000 * 60 * 15,
        ...action.payload
      };
    default:
      return state;
  }
};

export default crmReducer;
