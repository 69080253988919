import { Grid, Radio, useTheme } from '@material-ui/core';
import { RedflagClient } from 'App';
import { useRedFlagGQLAdaptor } from 'components/OffkeyCompanyAndDirectorSearch/adaptors';
import {
  CompanyList,
  CompanySearch
} from 'components/OffkeyCompanyAndDirectorSearch/components/Company';
import { OffkeyCompanyLite } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { useCompanyAndDirectorSearch } from 'components/OffkeyCompanyAndDirectorSearch/hooks/useCompanyAndDirectorSearch';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { IRedflagAPIData } from 'types/redflagInterfaces';

const GraphQlSearchTable = ({
  setCompany
}: {
  setCompany: Dispatch<SetStateAction<IRedflagAPIData>>;
}) => {
  const theme = useTheme();

  const { getRFGQLCompanyList, getRFGQLCompany } =
    useRedFlagGQLAdaptor(RedflagClient);
  const { companyNavigator } = useTypedSelector((s) => s.config);

  const [companies, setCompanies] = useState<OffkeyCompanyLite[] | null>(null);
  const [localLoading, setLocalLoading] = useState<boolean>(false);

  const { query, handleCompanySearch, companySearch, companyClear } =
    useCompanyAndDirectorSearch({
      setParentCompany: setCompany,
      companyNavigator,
      setLocalLoading,
      setCompanies,
      getRFGQLCompanyList
    });

  const getCompany = async (company: OffkeyCompanyLite) => {
    setLocalLoading(true);
    setCompanies(null);

    if (companyNavigator === 'RedflagGRaphQL') {
      const result = await getRFGQLCompany(company.redflag_GraphQL_id);
      const companyData = result.third_party_apis.redflagOG as IRedflagAPIData;

      setCompany(companyData);
    }

    setLocalLoading(false);
  };

  return (
    <Grid container spacing={1}>
      <Grid
        item
        style={{
          marginBottom: theme.spacing(0.8),
          marginTop: theme.spacing(0.8)
        }}>
        <Radio
          disabled
          checked
          value="company"
          name="radio-buttons"
          inputProps={{ 'aria-label': 'company' }}
        />
      </Grid>

      <CompanySearch
        handleSearch={handleCompanySearch}
        query={query}
        search={companySearch}
        clear={companyClear}
      />

      <Grid item xs={12}>
        <CompanyList
          companies={companies}
          localLoading={localLoading}
          getCompany={getCompany}
        />
      </Grid>
    </Grid>
  );
};

export default GraphQlSearchTable;
