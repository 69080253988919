// search box for finding FCA companies details

import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Dashboard as DashboardLayout } from 'layouts';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import { firebase } from 'redux/firebase';
import { createNotification } from 'react-redux-notify';
import { warningNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';
import { FCAFirm } from './interfaces';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    },
    overflowX: 'auto',
    margin: '10px 0'
  },
  section: { padding: theme.spacing(2) },
  btn: { margin: theme.spacing(2), marginLeft: 0 },
  check: { color: theme.palette.success.main },
  error: { color: theme.palette.error.main },
  subtitle: { display: 'flex', alignItems: 'center' },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
  },
  search: { marginBottom: theme.spacing(1) },
  contact: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    background: theme.palette.primary.light,
    color: theme.palette.primary.contrastText
  }
}));

const Component = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [query, setQuery] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(-1);
  const [data, setData] = React.useState([]);
  const [type, setType] = React.useState<'firm' | 'individual' | 'fund'>(
    'firm'
  );

  const handleChange = (e) => setQuery(e.target.value);
  const handleTypeChange = (e) => setType(e.target.value);

  // Boolean state for displaying company firm details after user clicks on 'MORE DETAIL' button
  const [displayCompanyFirmDetails, setDisplayCompanyFirmDetails] =
    React.useState(false);

  // search box submit for Fca company
  const submit = async () => {
    // reset displayCompanyFirmDetails state to false before next fca search submit
    setDisplayCompanyFirmDetails(false);

    setLoading(true);
    const token = await firebase.auth().currentUser?.getIdToken();
    const config: AxiosRequestConfig = {
      method: 'post',
      url: `${process.env.REACT_APP_PROXY}/fca`,
      data: { query, type },
      headers: { token }
    };

    axios(config)
      .then(async (res) => {
        if (res.data && res.data.Data) setData(res.data.Data);
        setLoading(false);
        gtag.event({ action: 'FCA Search', feature: 'FCA', message: query });
      })
      .catch((e) => {
        if (e.response.data.error === 'no-search-query') {
          dispatch(
            createNotification(warningNotif('No Search Query Entered.'))
          );
        } else {
          dispatch(
            createNotification(warningNotif('Insufficient permissions.'))
          );
        }
        BugTracker.notify(e);
        setLoading(false);
      });
  };

  // fetch more details about the fca company after the 'More detail' button is clicked
  const getDetail = async ({ query, index }) => {
    setLoadingMore(index);
    // We will need the token if we move this into a private area in the proxy server
    // const token = await firebase.auth().currentUser?.getIdToken();
    const config: AxiosRequestConfig = {
      method: 'post',
      url: `${process.env.REACT_APP_PROXY}/fca/detail`,
      data: { query }
      // headers: { token }
    };

    return axios(config)
      .then((res) => {
        if (res.data) {
          // data[index].firm = res.data;
          let newData = JSON.parse(JSON.stringify(data));
          newData[index].firm = res.data;
          setData(newData);
          setLoadingMore(-1);

          setDisplayCompanyFirmDetails(true);
        }
      })
      .catch(BugTracker.notify);
  };

  // Display the 'More detail' or 'Hide detail' toggle button for opening or collapsing the company firm info
  // The button will trigger to fetch more of the Fca company details
  // the button will only appear once user search submit for a Fca company (& fetch must be successful too)
  const detail_toggleButton = (company, i) => {
    // if an Fca company has been search submit by the user, then display 'More detail' button
    if (!displayCompanyFirmDetails) {
      return (
        <Button
          data-cy="more-detail-button"
          className={classes.btn}
          color="primary"
          onClick={() => {
            // if company.firm property does not exist within the 'data' state then fetch fca for more details
            if ('firm' in data[i] === false) {
              getDetail({ query: company['URL'], index: i });
              return;
            }
            // if company.firm property already exist within the 'data' state, then do not re-fetch (as it is unnecesarily since we already have it stored)
            else {
              setDisplayCompanyFirmDetails(true);
              return;
            }
          }}
          variant="contained">
          More Detail
        </Button>
      );
    }

    // display 'Hide details' button to collapse Fca extra details
    return (
      <Button
        data-cy="hide-detail-button"
        className={classes.btn}
        color="primary"
        onClick={() => {
          setDisplayCompanyFirmDetails(false);
        }}
        variant="contained">
        Hide Detail
      </Button>
    );
  }; // END detail_toggleButton

  return (
    <DashboardLayout title="FCA">
      {loading ? (
        <LinearProgress />
      ) : (
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Grid
              alignItems="center"
              container
              justifyContent="flex-start"
              spacing={1}>
              <Grid item style={{ flexGrow: 1, padding: 0 }}>
                <TextField
                  data-cy="enter-fca-number"
                  fullWidth
                  margin="dense"
                  onChange={handleChange}
                  onKeyDown={(e) => e.key === 'Enter' && submit()}
                  placeholder="Enter FCA number ..."
                  type="search"
                  value={query}
                  variant="outlined"
                />
              </Grid>
              <Grid item>
                <FormControl variant="outlined" margin="dense">
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={type}
                    onChange={handleTypeChange}
                    label="Type"
                    data-cy="select-type">
                    <MenuItem value="firm">Firm</MenuItem>
                    <MenuItem value="individual">Individual</MenuItem>
                    <MenuItem value="fund">Fund</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button color="primary" onClick={submit} variant="contained">
                  search
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {data.map((company: FCAFirm, i) => {
              return (
                <Paper
                  key={i}
                  square={false}
                  style={{ padding: 15, marginBottom: 15 }}
                  variant="outlined">
                  <h1 data-cy="company-name">{company['Name']}</h1>
                  <div className={classes.subtitle}>
                    <h2 style={{ marginRight: 5 }}>{company['Status']}</h2>
                    {company['Status'] === 'Authorised' ? (
                      <CheckCircleIcon className={classes.check} />
                    ) : (
                      <ErrorIcon className={classes.error} />
                    )}
                  </div>
                  <br />
                  <p data-cy="company-type">
                    Type: {company['Type of business or Individual']}
                  </p>
                  <p data-cy="company-reference">
                    Reference Number: {company['Reference Number']}
                  </p>
                  {loadingMore === i ? (
                    <LinearProgress />
                  ) : (
                    // display either 'More detail' or 'Hide detail' toggle button
                    detail_toggleButton(company, i)
                  )}

                  {/* Display extra details of the Fca company firm if user clicks 'More detail' button, otherwise return null */}
                  {displayCompanyFirmDetails ? (
                    <div>
                      <div data-cy="co-business-type">
                        Business Type: {company?.firm?.['Business Type']}
                      </div>
                      <div data-cy="co-status-date">
                        Status Effective Date:{' '}
                        {company?.firm?.['Status Effective Date']}
                      </div>

                      {company?.firm?.Address?.Data && (
                        <div className={classes.root}>
                          <h4>Contact</h4>
                          {company.firm.Address.Data.map((item, i) => (
                            <div className={classes.contact} key={i}>
                              <p> {item['Address Type']} </p>
                              <p> {item['Phone Number']} </p>
                              <p> {item['Website Address']} </p>
                            </div>
                          ))}
                        </div>
                      )}

                      {company?.firm?.Individuals?.Data && (
                        <div className={classes.root}>
                          <h4>Individuals</h4>
                          {company.firm.Individuals.Data.map(({ Name }, i) => (
                            <Chip
                              key={i}
                              label={Name}
                              style={{
                                color: theme.palette.primary.contrastText
                              }}
                            />
                          ))}
                        </div>
                      )}

                      {company?.firm?.Permission?.Data && (
                        <div className={classes.root}>
                          <h4>Permissions</h4>
                          {Object.keys(company.firm.Permission.Data).map(
                            (key, i) => (
                              <Chip
                                key={i}
                                label={key}
                                style={{
                                  // color: theme.palette.primary.contrastText
                                  color: 'white',
                                  backgroundColor: '#2c2e6f'
                                }}
                              />
                            )
                          )}
                        </div>
                      )}
                    </div>
                  ) : null}
                </Paper>
              );
            })}
          </Grid>
        </Grid>
      )}
    </DashboardLayout>
  );
};
export default Component;

function validURL(str) {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
}

const Firm = () => {};
