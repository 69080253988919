import React from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import { useParams } from 'react-router-dom';
import Detail from 'components/Table/Detail';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useTypedSelector } from 'redux/reducers';
import { getUserInstanceDetail } from 'redux/actions/GraphQlActions';

const Component = () => {
  const [loading, setLoading] = React.useState<boolean>(false);

  const userList = useTypedSelector((s) => s.userAPI.userList);
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const { ID, InstanceId }: any = useParams();

  const UserInstanceId = parseInt(InstanceId);
  const UserDefinitionId = parseInt(ID);
  let correct = false;

  const userDef = useTypedSelector(
    (s) => s.userAPI.userDefinition[UserDefinitionId]
  );

  const getData = async () => {
    setLoading(true);
    await getUserInstanceDetail({
      baseUrl,
      UserInstanceId
    });

    setLoading(false);
  };

  React.useEffect(() => {
    getData();
  }, [ID, InstanceId]);

  const current =
    userList &&
    userList[UserDefinitionId] &&
    userList[UserDefinitionId][UserDefinitionId].find(
      (el) => el.UserInstance.Id === UserInstanceId
    );

  correct = userDef && current && current.UserInstance.Id === UserInstanceId;
  return (
    <DashboardLayout
      title={correct ? current.UserInstance.Title : 'Loading ...'}>
      {loading ? (
        <LinearProgress />
      ) : (
        <React.Fragment>
          {!correct ? (
            <LinearProgress />
          ) : (
            <Detail
              getAllData={getData}
              CODL={userDef[UserDefinitionId].CompleteObjectDefinitionList}
              ID={UserDefinitionId}
              hasData
              rowData={current}
            />
          )}
        </React.Fragment>
      )}
    </DashboardLayout>
  );
};

export default Component;
