import { useState } from 'react';
import {
  Box,
  Typography,
  MenuItem,
  useTheme,
  LinearProgress,
  Grid,
  TextField,
  Button,
  Paper,
  Chip
} from '@material-ui/core';
import {
  IQuery,
  OffkeyCompanyLite,
  IFormField,
  ICompanySearchCriteria
} from '../interfaces';
import { getValue } from '../helper';
import { hasSearchCriteria } from '../functions';
import AdvancedFilterDialog from './AdvancedFilterDialog';

const booleanOptions = [
  {
    value: 'true',
    label: 'True'
  },
  {
    value: 'false',
    label: 'False'
  }
];

const formFields: IFormField[] = [
  {
    name: 'company_name',
    label: 'Company Name',
    type: 'text'
  },
  {
    name: 'company_number',
    label: 'Company Number',
    type: 'text'
  },
  {
    name: 'company_type',
    label: 'Company Type',
    type: 'text'
  },
  {
    name: 'legal_status',
    label: 'Legal Status',
    type: 'text'
  },
  {
    name: 'postcode',
    label: 'Postcode',
    type: 'text'
  },
  {
    name: 'is_trading',
    label: 'Is Trading?',
    type: 'select',
    options: booleanOptions
  },
  {
    name: 'is_limited',
    label: 'Is Limited?',
    type: 'select',
    options: booleanOptions
  }
];

const CompanyList = ({
  companies,
  localLoading,
  getCompany
}: {
  companies: OffkeyCompanyLite[] | null;
  localLoading: boolean;
  getCompany: (company: OffkeyCompanyLite) => Promise<void>;
}): JSX.Element => {
  const theme = useTheme();

  if (localLoading) return <LinearProgress />;
  if (!companies) return <div />;

  const loadedCompanies = companies.map((company: OffkeyCompanyLite) => (
    <div key={company.company_number} style={{ padding: theme.spacing(0.5) }}>
      <Paper elevation={1}>
        <MenuItem
          data-cy="company-list-item"
          data-company-number={company.title}
          onClick={() => getCompany(company)}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'flex-start'
            }}>
            <Typography variant="h6">{company.title}</Typography>
          </Box>

          <Typography
            variant="caption"
            style={{ color: theme.palette.grey[500] }}>
            {company.description}
          </Typography>

          <Typography style={{ color: theme.palette.success.main }}>
            {company.company_number}
          </Typography>
        </MenuItem>
      </Paper>
    </div>
  ));

  return <>{loadedCompanies}</>;
};

const CompanySearch = ({
  handleSearch,
  query,
  search,
  clear
}: {
  handleSearch: (name: string, value: string) => void;
  query: IQuery;
  search: () => Promise<void>;
  clear: () => void;
}): JSX.Element => {
  const theme = useTheme();
  const [advanceSearch, setAdvanceSearch] = useState<boolean>(false);

  const hasAdditionalCriteria = (): boolean => {
    const { company_name, company_number, ...otherCriteria } = query.company;
    return Object.keys(otherCriteria).some((key) =>
      getValue(otherCriteria[key])
    );
  };

  const RenderChips = (): JSX.Element[] => {
    return formFields
      .filter(({ name }) => getValue(query.company[name]))
      .map(({ name, label }) => (
        <Chip
          key={name}
          label={`${label}: ${getValue(query.company[name])}`}
          onDelete={() => handleSearch(name, '')}
          style={{ margin: '0 4px' }}
        />
      ));
  };

  const allowSearching = !hasSearchCriteria(query.company);
  const allowTyping = !hasAdditionalCriteria();

  const value = allowTyping
    ? query.company?.company_name?.eq || query.company?.company_number?.eq || ''
    : '';

  return (
    <>
      <Grid item xs={8}>
        <TextField
          data-cy="search-bar"
          onChange={(e) => {
            const company_name: keyof ICompanySearchCriteria = 'company_name';
            handleSearch(company_name, e.target.value);
          }}
          value={value}
          fullWidth
          variant="outlined"
          margin="dense"
          placeholder="Company Name or Company Number"
          label={
            allowTyping ? 'Company Name or Company Number' : 'Search Criteria'
          }
          onKeyDown={(e) => e.key === 'Enter' && search()}
          disabled={!allowTyping}
          InputProps={{
            startAdornment: !allowTyping && RenderChips(),
            style: { paddingRight: 0, minWidth: '100px' }
          }}
        />
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          data-cy="search-button"
          onClick={search}
          disabled={allowSearching}
          style={{ margin: theme.spacing(1) }}>
          Search
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={() => setAdvanceSearch(true)}
          data-cy="filter-button"
          style={{ margin: theme.spacing(1) }}>
          Filter
        </Button>
      </Grid>
      <Grid item xs={1}>
        <Button
          variant="contained"
          fullWidth
          color="primary"
          onClick={clear}
          style={{ margin: theme.spacing(1) }}>
          Clear
        </Button>
      </Grid>
      <AdvancedFilterDialog
        isOpen={advanceSearch}
        setIsOpen={setAdvanceSearch}
        handleSearch={handleSearch}
        formFields={formFields}
        entity={query.company}
        alertText={
          'Use Advanced Search to refine your results with specific search criteria such as Company Type, Legal Status and Postcode. This allows for more targeted searches.'
        }
      />
    </>
  );
};

export { CompanyList, CompanySearch };
