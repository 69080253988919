/**this component provides a selection of input (type) element for the app forms */

// import React from 'react';
// import DateField from '../DateField';
// import EditableDateField from './components/EditableDateField';
import EditableEditor from './components/EditableEditor';
import EditableInput from './components/EditableInput';
import EditableCheckbox from './components/EditableCheckbox';
import EditableDocument from './components/EditableDocument';
import EditableSignature from './components/EditableSignature';
import EditableDropDown from './components/EditableDropDown';
import Editable_Select_Create_From_All from './components/EditableDrop-downAdvancedUserInstance';
import EditableVRM from './components/EditableVRM';
import ReadOnlySelectedUserInstance from './components/ReadOnlySelectedUserInstance';

import { IFieldsProps } from './interface';
import EditableStarRating from './components/EditableStarRating';
import { DropDownProvider } from './components/EditableDropDown/context';

export enum EInlineTextField {
  ReadOnlyCurrency = 'Read Only Currency',
  ReadOnlyText = 'Read Only Text',
  ReadOnlyWholeNumber = 'Read Only Whole Number',
  ReadOnlyDecimal = 'Read Only Decimal',
  ReadOnlyDate = 'Read Only Date',
  ReadOnlyTickbox = 'Read Only Tickbox',
  ReadOnlyPercentage = 'Read Only Percentage',
  WholeNumber = 'Whole Number',
  Decimal = 'Decimal',
  Email = 'Email',
  DropDown = 'Drop-down',
  DropDownAdvancedUserInstance = 'Drop-downAdvancedUserInstance',
  DropDownAdvancedUserInstanceAutoAssign = 'Drop-downAdvancedUserInstanceAutoAssign',
  ReadOnlySelectedUserInstance = 'Read Only Selected UserInstance',
  StarRating = 'Star_Rating',
  Signature = 'Signature',
  Document = 'Document',
  FilePath = 'File Path',
  Tickbox = 'Tickbox',
  FormattedText = 'Formatted Text',
  VRM = 'VRM'
}

/**
 * List of read-only field types.
 */
const readonlyFieldTypes = [
  EInlineTextField.ReadOnlyCurrency,
  EInlineTextField.ReadOnlyText,
  EInlineTextField.ReadOnlyWholeNumber,
  EInlineTextField.ReadOnlyDecimal,
  EInlineTextField.ReadOnlyDate,
  EInlineTextField.ReadOnlyTickbox,
  EInlineTextField.ReadOnlyPercentage
];

/**
 * This component provides a selection of input elements for the app forms based on field types.
 * It handles various editable input types such as text, number, email, dropdowns, checkboxes, signatures, etc.
 */

const InlineTextField = (props: IFieldsProps) => {
  let { type, FieldDefinition, readOnly } = props;

  // Check if the field type is read-only
  if (
    readonlyFieldTypes.includes(FieldDefinition.FieldType as EInlineTextField)
  ) {
    readOnly = true;
  }

  // Handle different field types using if statements
  if (
    type === EInlineTextField.WholeNumber ||
    type === EInlineTextField.Decimal
  ) {
    type = EInlineTextField.WholeNumber;
  }

  if (type === EInlineTextField.Email) {
    type = EInlineTextField.Email;
  }

  // DD = Dropdown
  const DDAutoAssign = EInlineTextField.DropDownAdvancedUserInstanceAutoAssign;
  const DDUserInstance = EInlineTextField.DropDownAdvancedUserInstance;
  if (type.includes(EInlineTextField.DropDown)) {
    if (
      FieldDefinition.FieldType === DDUserInstance ||
      FieldDefinition.FieldType === DDAutoAssign
    ) {
      return <Editable_Select_Create_From_All {...props} />;
    }
    return (
      <DropDownProvider dropdownProps={props}>
        <EditableDropDown />
      </DropDownProvider>
    );
  }

  if (type === EInlineTextField.ReadOnlySelectedUserInstance) {
    return <ReadOnlySelectedUserInstance {...props} />;
  }

  if (
    type === EInlineTextField.WholeNumber &&
    FieldDefinition.FieldDescription.includes(EInlineTextField.StarRating)
  ) {
    return <EditableStarRating {...props} />;
  }

  if (type === EInlineTextField.Signature) {
    return <EditableSignature {...props} />;
  }

  if (
    type === EInlineTextField.Document ||
    type === EInlineTextField.FilePath
  ) {
    return <EditableDocument {...props} />;
  }

  if (type === EInlineTextField.Tickbox) {
    return <EditableCheckbox {...props} />;
  }

  if (type === EInlineTextField.FormattedText) {
    return <EditableEditor {...props} editable />;
  }

  if (type === EInlineTextField.VRM) {
    return <EditableVRM {...props} />;
  }

  // Default: Render editable input for type Date / text / email / number
  return (
    <div
      style={{ width: '100%' }}
      data-cy={readOnly ? 'readonly-fields' : 'not-readonly-fields'}>
      <div data-cy="editable-input">
        <EditableInput {...props} editable readOnly={readOnly} />
      </div>
    </div>
  );
};

export default InlineTextField;
