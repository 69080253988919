import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import { Paper } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import { CashflowTitles } from 'components/RedFlag/TabMenu/Titles';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' },
  detail: { padding: theme.spacing(2), margin: theme.spacing(2) }
}));

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

interface Column {
  title: string;
  field: string;
}

const Component = ({ company }) => {
  const classes = useStyles();
  const [columns, setColumns] = React.useState<Column[]>([] as Column[]);
  const [data, setData] = React.useState<{ [attributeName: string]: string }[]>(
    [] as { [attributeName: string]: string }[]
  );

  React.useEffect(() => {
    if (company.cashflow && company.cashflow.length > 0) {
      let columns = [{ title: ' ', field: 'attributeName' }];
      company.cashflow.forEach(({ attributes }, i) => {
        if (i <= 4) {
          columns.push({
            title: attributes.account_date,
            field: attributes.account_date
          });
        }
      });
      let data = [] as { [attributeName: string]: string }[];
      const search = '_';
      const replaceWith = ' ';
      CashflowTitles.forEach((title) => {
        const noUnderscore = title.split(search).join(replaceWith);
        const attributeName = noUnderscore.replace(/\b\w/g, (c) =>
          c.toUpperCase()
        );

        console.log({ noUnderscore, attributeName });

        const row = { attributeName };
        company.cashflow.forEach(({ attributes }) => {
          row[attributes.account_date] =
            attributes[title] === 'GBP' ? (
              attributes[title]
            ) : (
              <Currency value={attributes[title]} />
            );
        });
        data.push(row);
      });

      console.log({ columns, data });

      setColumns(columns);
      setData(data);
    }
  }, []);

  return (
    <Paper elevation={3} className={classes.root}>
      <Table
        title="Cashflow"
        data={data}
        columns={columns}
        options={{ pageSize: 10 }}
      />
    </Paper>
  );
};

export default Component;
