import { Grid, Paper, Typography } from '@material-ui/core';
import { FieldValuesTypes } from 'components/Stepper/components/Steps/ActiveStep/IndicativeQuoteObject';
import { useProcess } from 'hooks';
import { theme } from 'theme';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  CompleteProcessStepDefinition,
  FieldInstance,
  ObjectDefinition
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

const LENDER_COMMISSION_INVOICE_HIDDEN_FIELD = [22820, 23106];
const LENDER_COMMISSION_INVOICE_OBJECT_DEFINITION_ID = 3486;
const CommissionInvoiceSummary = ({
  commissionInvoiceValues
}: {
  commissionInvoiceValues: CompleteObjectInstance[];
}) => {
  const { currentProcess } = useProcess();
  return (
    <div>
      {commissionInvoiceValues.map(
        (invoice: CompleteObjectInstance, idx: number) => {
          const FieldInstanceList = getFieldInstances(invoice);

          const findCommissionInvoices:
            | CompleteProcessStepDefinition
            | undefined = Object.values(
            currentProcess.CompleteProcessStepDefinitionDict
          ).find(
            (CompleteProcessStepDefinition: CompleteProcessStepDefinition) =>
              CompleteProcessStepDefinition.ProcessStepDefinition.Title ===
              'Discovery'
          );

          if (!findCommissionInvoices) return <div key={idx} />;
          const CompleteObjectDefinition = Object.values(
            findCommissionInvoices.CompleteObjectDefinitionDict
          ).find(
            (CompleteObjectDefinition: CompleteObjectDefinition) =>
              CompleteObjectDefinition.ObjectDefinition.Id ===
              LENDER_COMMISSION_INVOICE_OBJECT_DEFINITION_ID
          );

          if (!CompleteObjectDefinition) return null;
          return (
            <>
              <div style={{ paddingTop: theme.spacing(1) }} />
              <Paper key={idx} style={{ width: '100%', padding: 16 }}>
                <Typography
                  style={{
                    paddingBottom: theme.spacing(1),
                    paddingTop: theme.spacing(1)
                  }}
                  variant="h5">
                  Commission Invoice Id: {invoice.ObjectInstance.Id}
                </Typography>
                <Grid container justifyContent="space-between">
                  {FieldInstanceList.map((FieldInstance: FieldInstance) =>
                    getFieldInstanceComponent(
                      FieldInstance,
                      CompleteObjectDefinition
                    )
                  )}
                </Grid>
              </Paper>
            </>
          );
        }
      )}
    </div>
  );
};

const getFieldInstanceComponent = (
  FieldInstance: FieldInstance,
  CompleteObjectDefinition: CompleteObjectDefinition
) => {
  const FieldDefinition =
    CompleteObjectDefinition.FieldDefinitionDict[
      FieldInstance.FieldDefinitionId
    ];

  if (
    !FieldDefinition ||
    LENDER_COMMISSION_INVOICE_HIDDEN_FIELD.includes(FieldDefinition.Id)
  ) {
    return <div key={FieldInstance.Id} />;
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      key={FieldInstance.Id}>
      <Grid item>
        <Typography
          style={{ fontWeight: 'bold', color: theme.palette.grey[500] }}
          variant="h6">
          {FieldDefinition.Title}:
        </Typography>
      </Grid>
      <Grid item>
        <FieldValuesTypes
          FieldDefinition={FieldDefinition}
          FieldInstance={FieldInstance}
        />
      </Grid>
    </Grid>
  );
};

export default CommissionInvoiceSummary;
