import { objectProgress } from 'components/Stepper/functions/objectprogress';
import { makeStyles, Badge, Typography } from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { CompleteObjectDefinition } from 'types/interfaces';
import { theme } from 'theme';

const useStyles = makeStyles((theme) => ({
  label: {
    alignSelf: 'flex-start',
    display: 'flex',
    textAlign: 'left',
    width: '100%'
  },
  badge: { color: theme.palette.warning.main },
  icon: {
    color: theme.palette.warning.main,
    marginLeft: 10
  }
}));

export const Label = ({
  comObDef,
  value
}: {
  comObDef: CompleteObjectDefinition;
  value: number;
}) => {
  const classes = useStyles();
  let { currentDeal } = useProcess();
  const { Title, Id } = comObDef.ObjectDefinition;
  const areAnyRequiredFields =
    objectProgress({ CompleteObjectDefinition: comObDef, currentDeal })
      ._requiredFieldsTODO > 0;
  const isReqFields = areAnyRequiredFields;
  const { _requiredFieldsTODO: badgeContent } = objectProgress({
    CompleteObjectDefinition: comObDef,
    currentDeal
  });

  const isSelectedObject = Id === value;
  return (
    <div
      className={classes.label}
      style={{
        background: isSelectedObject ? theme.palette.primary.main : '',
        borderRadius: isSelectedObject ? 10 : 0,
        padding: isSelectedObject ? 8 : 0
      }}>
      <Typography
        style={{
          color: isSelectedObject ? 'white' : theme.palette.text.secondary,
          fontWeight: 500
        }}>
        {Title}
      </Typography>
      {isReqFields && (
        <Badge badgeContent={badgeContent} className={classes.badge}>
          <ErrorOutlineIcon className={classes.icon} />
        </Badge>
      )}
    </div>
  );
};
