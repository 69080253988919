import { useProcess } from 'hooks/useProcess';
import { QuoteStepView } from './QuoteStepView';
import { DefaultLandingPageView } from './DefaultLandingPage';
import { FactFindFormView } from './FactFindFormView';
import { useGlobal } from 'hooks';
import { IndicativeQuoteView } from './IndicativeQuoteView';

export const LandingPageUserViews = () => {
  const { config } = useGlobal();
  const { QuoteViews, FFFViews, IndicativeQuoteViews } = config;
  const { stepdefdict } = useProcess();
  const { ProcessStepDefinition } = stepdefdict;
  const { Id } = ProcessStepDefinition;
  if (QuoteViews.includes(Id.toString())) return <QuoteStepView />;
  if (FFFViews.includes(Id.toString())) return <FactFindFormView />;
  if (IndicativeQuoteViews.includes(Id.toString())) {
    return <IndicativeQuoteView />;
  }

  return <DefaultLandingPageView />;
};
