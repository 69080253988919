import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: { minWidth: 300 }
}));

const Component = ({ title }) => {
  const classes = useStyles();
  return (
    <TextField
      label={title}
      className={classes.root}
      variant="outlined"
      margin="dense"
    />
  );
};

export default Component;
