import { gql } from '@apollo/client';

/**
 * Fetches CompleteUserInstanceDetail.
 * @param {String} baseUrl - The base URL for the Hostname.
 * @param {Int} userInstanceId - The identifier of the UserId to fetch.
 */
export const GET_COMPLETE_USER_INSTANCE_DETAIL = gql`
  query GetUserInstanceDetail($baseUrl: String, $userInstanceId: Int) {
    GetUserInstanceDetail(baseURL: $baseUrl, UserInstanceId: $userInstanceId) {
      CompleteObjectInstanceList {
        ObjectInstance {
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectDescription
          ObjectProcessIndependent
          ObjectRepeat
          ProcessInstanceId
          Selected
          Title
          UserDefinitionId
          UserInstanceId
        }
        FieldInstanceList {
          FieldDefinitionId
          FieldValue
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectInstanceId
          ProcessInstanceId
          Title
          UserDefinitionId
          UserInstanceId
        }
      }
      UserInstance {
        ConsoleUserInstanceId
        DefaultMessage
        DocumentFooter
        DocumentHeader
        EmailFooter
        FromAddress
        FromName
        Id
        IsPublished
        ItemOrder
        LastModified
        LogoURL
        NickName
        OwnerUserInstanceId
        ProfilePicture
        ProxyUserInstanceId
        RelatedUserPermission
        RelationshipStatus
        SmtpHost
        SmtpPassword
        SmtpPort
        SmtpSSL
        SmtpUsername
        SystemAccess
        TelephoneNumber
        ThirdPartyId
        Title
        UserDefinitionId
        UserInstanceEmail
        UserInstancePassword
      }
    }
  }
`;

/** * Retrieves Basic User Instance Details.
 * @param {String} baseUrl - The base URL for the Hostname.
 * @param {Int} userInstanceId - The identifier of the UserId to fetch.
 */
export const GET_USER_INSTANCE_DETAIL = gql`
  query GetUserInstance($baseUrl: String, $userInstanceId: Int) {
    GetUserInstanceDetail(baseURL: $baseUrl, UserInstanceId: $userInstanceId) {
      UserInstance {
        ConsoleUserInstanceId
        DefaultMessage
        DocumentFooter
        DocumentHeader
        EmailFooter
        FromAddress
        FromName
        Id
        IsPublished
        ItemOrder
        LastModified
        LogoURL
        NickName
        OwnerUserInstanceId
        ProfilePicture
        ProxyUserInstanceId
        RelatedUserPermission
        RelationshipStatus
        SmtpHost
        SmtpPassword
        SmtpPort
        SmtpSSL
        SmtpUsername
        SystemAccess
        TelephoneNumber
        ThirdPartyId
        Title
        UserDefinitionId
        UserInstanceEmail
        UserInstancePassword
      }
    }
  }
`;

/**
 * Fetches all object and field instances associated with a user.
 * @param {String} baseUrl - The base URL for the Hostname.
 * @param {Int} userInstanceId - The identifier of the UserId to fetch.
 */
export const GET_COMPLETE_OBJECT_INSTANCE = gql`
  query GetCompleteObjectInstance($baseUrl: String, $userInstanceId: Int) {
    GetUserInstanceDetail(baseURL: $baseUrl, UserInstanceId: $userInstanceId) {
      CompleteObjectInstanceList {
        ObjectInstance {
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectDescription
          ObjectProcessIndependent
          ObjectRepeat
          ProcessInstanceId
          Selected
          Title
          UserDefinitionId
          UserInstanceId
        }
        FieldInstanceList {
          FieldDefinitionId
          FieldValue
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectInstanceId
          ProcessInstanceId
          Title
          UserDefinitionId
          UserInstanceId
        }
      }
    }
  }
`;
