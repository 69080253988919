export interface Event {
  email: string;
  event: EventNames;
  ip: string | null;
  timestamp: number;
  url?: string;
  count?: number;
}

export enum EventNames {
  Click = 'click',
  Open = 'open',
  Unsubscribe = 'Unsubscribe',
  Bounce = 'bounce',
  Delivered = 'delivered',
  SpamReport = 'spam_report',
  Dropped = 'dropped'
}

export interface EventDetail {
  event: EventNames | null;
  description: string;
  isNegative: boolean;
}

export interface IStats {
  bounce_drops: number;
  bounces: number;
  clicks: number;
  unique_clicks: number;
  delivered: number;
  invalid_emails: number; // add
  opens: number;
  unique_opens: number;
  requests: number;
  spam_report_drops: number; // add
  spam_reports: number;
  unsubscribes: number;
}

export enum Status {
  Triggered = 'triggered',
  Draft = 'draft',
  Scheduled = 'scheduled'
}

export const EventDetails = {
  opens: {
    event: EventNames.Open,
    description:
      'The total number of times your emails were opened by recipients',
    isNegative: false
  },
  clicks: {
    event: EventNames.Click,
    description: 'The number of links that were clicked in your emails',
    isNegative: false
  },
  unique_clicks: {
    event: null,
    description:
      'The number of unique recipients who have clicked links in your emails',
    isNegative: false
  },
  unique_opens: {
    event: null,
    description:
      'The number of first time opens by recipients (subsequent opens will not count)',
    isNegative: false
  },
  delivered: {
    event: EventNames.Delivered,
    description:
      'The number of emails SendGrid was able to confirm were actually delivered to a recipient',
    isNegative: false
  },
  requests: {
    event: null,
    description: 'The number of emails you requested to send via SendGrid',
    isNegative: false
  },
  bounces: {
    event: EventNames.Bounce,
    description: 'The number of emails that bounced instead of being delivered',
    isNegative: true
  },
  spam_reports: {
    event: EventNames.SpamReport,
    description: 'The number of recipients who have marked your email as spam',
    isNegative: true
  },
  bounce_drops: {
    event: null,
    description:
      "This event occurs when SendGrid does not attempt to send an email because the recipient's address is already known to be problematic from previous bounces",
    isNegative: true
  },
  spam_report_drops: {
    event: null,
    description:
      'This event occurs when the email was not sent because the recipient had previously marked an email from the sender as spam. SendGrid automatically suppresses sending to these addresses to avoid further spam complaints and to protect sender reputation',
    isNegative: true
  },
  invalid_emails: {
    event: null,
    description:
      'This event signals that an email address is malformed or does not conform to standard email format rules',
    isNegative: true
  },
  unsubscribes: {
    event: EventNames.Unsubscribe,
    description:
      'The number of recipient that have opted out from receiving further emails',
    isNegative: true
  }
};

export const INITIAL_STATS = Object.keys(EventDetails).reduce((acc, key) => {
  acc[key] = 0;
  return acc;
}, {}) as IStats;
