import React from 'react';
import { GetLegacyDeals } from 'redux/database/index';
import LegacyTable from './LegacyTable';
import { useDispatch } from 'react-redux';

const LegacyDealsViewer = () => {
  const [list, setList] = React.useState({});
  const dispatch = useDispatch();

  const getListData = async () => {
    const res = await GetLegacyDeals({ action: 'list', deal_Id: '' });
    if (res) setList(res);
  };

  const getActivityData = async ({ dealID }) => {
    const res = await GetLegacyDeals({
      action: 'activity',
      deal_Id: dealID
    });
    if (res) dispatch({ type: 'SET_LEGACY_DEAL_ACTIVITY', payload: res.data });
  };

  const getDocumentList = async ({ dealID }) => {
    const res = await GetLegacyDeals({
      action: 'documentlist',
      deal_Id: dealID
    });
    if (res)
      dispatch({ type: 'SET_LEGACY_DEAL_DOCUMENTLIST', payload: res.data });
  };

  React.useEffect(() => {
    getListData();
  }, []);

  return (
    <div>
      <LegacyTable
        getActivityData={getActivityData}
        getDocumentList={getDocumentList}
        list={list}
      />
    </div>
  );
};

export default LegacyDealsViewer;
