import { useState, useEffect } from 'react';
import { firebase } from 'redux/firebase';
import { IPlatformSettings } from 'types/interfaces';

export const useGlobal = () => {
  const [config, setConfig] = useState<IPlatformSettings>({
    FFFViews: [],
    processesThatUseQuotes: [],
    QuoteViews: [],
    IndicativeQuoteViews: [],
    SpecialObjects: [],
    SaveableObjects: [],
    TimeStampFields: [],
    SuperAdmins: []
  } as IPlatformSettings);
  const JediUsers = [6862, 8298, 8245];

  useEffect(() => {
    const unsub = firebase
      .firestore()
      .collection('globalSetting')
      .doc('displayConfig')
      .onSnapshot((doc: any) => {
        doc.exists && setConfig(doc.data());
      });
    return () => {
      unsub();
    };
  }, []);
  return { config, JediUsers };
};
