import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import { useTypedSelector } from 'redux/reducers';
import { LinearProgress } from '@material-ui/core';
import {
  UserInstance,
  CompleteUserInstance,
  UserDefinition
} from 'types/interfaces';
import { IRelationshipTable } from './interfaces';
import { UserRelationships } from './UserRelationships';
import { ActivityRelationships } from './ActivityRelationships';
import { AlertExplainer } from './Alert';
import { EmailCompositionCreationProvider } from 'components/MessageHub/components/ComposeWindows/context/ComposeWindowsContext';
import { useToken } from 'components/MessageHub/hooks/useToken';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { Alert } from '@material-ui/lab';
import { theme } from 'theme';
import { getRelationshipListAll } from 'redux/actions/GraphQlActions/cachedRequests';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex'
  },
  tabs: { borderRight: `1px solid ${theme.palette.divider}` },
  label: {
    display: 'flex',
    alignSelf: 'flex-start',
    textAlign: 'left'
  },
  item: {
    paddingLeft: theme.spacing(2)
  }
}));

interface IProps {
  relationships: UserInstance[];
  getRelationships: () => Promise<void>;
  rowData: CompleteUserInstance;
  loading: boolean;
}

const Component = ({
  relationships,
  getRelationships,
  rowData,
  loading
}: IProps) => {
  const history = useHistory();
  const classes = useStyles();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const UserDefinitions = useTypedSelector(
    (state) => state.config.settings.UserDefinitionList
  );
  const [tabs, setTabs] = React.useState<number[]>([]);
  const [selectedTab, setSelectedTab] = React.useState<number>(0);
  const [data, setData] = React.useState<UserInstance[]>([]);
  const [title, setTitle] = React.useState<string>('');
  const { Id } = rowData.UserInstance;

  // Find the user definition
  // Does the description
  const search = ',';
  const UserDefinition = UserDefinitions.find((el) => el.Id === selectedTab);
  const keywords = UserDefinition && UserDefinition.Description.split(search);

  const refreshData = async () => {
    await getRelationships();
    updateData(selectedTab);
  };
  const handleChange = (event: any, newValue: number) => {
    updateData(newValue);
    setSelectedTab(newValue);
  };

  const handleRowClick: (props: IRelationshipTable) => void = (props) => {
    history.push(`/UserDefinition/${props.UserDefinitionId}/${props.Id}`);
  };

  const initTabs = (hasSelected?: number) => {
    const newTabs: number[] = [];
    const { Id, UserDefinitionId } = rowData.UserInstance;

    const hasCachedData = getRelationshipListAll({
      UserInstanceId: Id,
      UserDefinitionId,
      baseUrl
    });

    let UserInstanceList: UserInstance[] = [];
    if (hasCachedData) {
      UserInstanceList = hasCachedData;
    } else {
      UserInstanceList = relationships;
    }

    console.log({ UserInstanceList });

    UserInstanceList.forEach(
      ({ UserDefinitionId }: { UserDefinitionId: number }) =>
        newTabs.push(UserDefinitionId)
    );

    if (newTabs.length > 0) {
      let uniq = [...new Set(newTabs)];
      setTabs(uniq);
      setSelectedTab(uniq[0]);
      return updateData(hasSelected ? hasSelected : uniq[0]);
    }
  };

  const updateData: (selectedTab: number) => void = (selectedTab) => {
    const { Id, UserDefinitionId } = rowData.UserInstance;

    const hasCachedData = getRelationshipListAll({
      UserInstanceId: Id,
      UserDefinitionId,
      baseUrl
    });

    let data: UserInstance[] = [];
    if (hasCachedData) {
      data = hasCachedData.filter(
        (UserInstance: UserInstance) =>
          UserInstance.UserDefinitionId === selectedTab
      );
    } else {
      data = relationships.filter(
        (UserInstance: UserInstance) =>
          UserInstance.UserDefinitionId === selectedTab
      );
    }

    data.sort((a, b) => b.Id - a.Id);
    setData(data);

    if (selectedTab !== 0) {
      const UserDefinition = UserDefinitions.find(
        (el) => el.Id === selectedTab
      );
      if (UserDefinition) setTitle(UserDefinition.Title);
    }
  };

  React.useEffect(() => {
    initTabs();
    console.log('Rerunning');
  }, [relationships, loading]);

  const isAuth = useIsAuthenticated();
  const { accounts, instance, inProgress } = useMsal();
  const getValidToken = async () => {
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });
    if (accessToken) return accessToken;
    else return undefined;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Tabs
            aria-label="Vertical tabs example"
            className={classes.tabs}
            onChange={handleChange}
            orientation="vertical"
            value={selectedTab}
            variant="scrollable">
            {tabs.map((UserDefinitionId: number) => {
              const UserDefinition = UserDefinitions.find(
                (el: UserDefinition) => el.Id === UserDefinitionId
              );
              if (!UserDefinition) return null;
              return (
                <Tab
                  data-cy="contact-tab"
                  key={UserDefinitionId}
                  label={<Label text={UserDefinition?.Title} />}
                  value={UserDefinitionId}
                />
              );
            })}
          </Tabs>
        </Grid>

        <Grid className={classes.item} item xs={10}>
          {keywords ? (
            keywords.includes('calendar') ? (
              <ActivityRelationships
                handleRowClick={handleRowClick}
                data={data}
                title={title}
                selectedTab={selectedTab}
                setData={setData}
                refreshData={refreshData}
                rowData={rowData}
              />
            ) : (
              <EmailCompositionCreationProvider
                validAccessToken={getValidToken}
                getRefreshEmails={false}>
                {!isAuth && (
                  <div style={{ paddingBottom: theme.spacing(1) }}>
                    <Alert severity="warning">
                      <Typography
                        style={{
                          fontWeight: 'bold'
                        }}>
                        Office 365 Is Disconnected. Please Connect To Enable
                        Email Linking.
                      </Typography>
                    </Alert>
                  </div>
                )}
                <UserRelationships
                  handleRowClick={handleRowClick}
                  data={data}
                  title={title}
                  primaryUserInstance={rowData.UserInstance}
                  getRelationships={getRelationships}
                  setData={setData}
                  Id={Id}
                />
              </EmailCompositionCreationProvider>
            )
          ) : (
            <LinearProgress />
          )}
        </Grid>

        <Grid item xs={12}>
          <AlertExplainer />
        </Grid>
      </Grid>
    </div>
  );
};

export default Component;

const Label = ({ text }) => {
  const classes = useStyles();
  return <div className={classes.label}>{text}</div>;
};
