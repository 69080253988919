import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  CircularProgress,
  Card,
  CardActionArea,
  CardMedia,
  CardContent
} from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { MdDashboardCustomize } from 'react-icons/md';
import { useQuery } from 'react-query';

import Paginator from 'components/Marketing/components/Paginator';
import EmailViewer from './EmailViewer';
import { useInstance } from 'hooks/useInstance';
import { fetchDesigns, fetchDesign } from './functions';
import { IDesign } from './types';
import { QueryKeys } from '../types';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      marginTop: 20
    },
    lists: {
      border: `1px solid ${theme.palette.grey.A100}`,
      width: '30%',
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      borderRadius: theme.shape.borderRadius
    },
    actionArea: {
      '&:hover $focusHighlight': {
        opacity: 0
      }
    },
    list: {
      border: `1px solid ${theme.palette.grey.A100}`,
      width: '70%',
      padding: theme.spacing(2),
      margin: theme.spacing(2),
      borderRadius: theme.shape.borderRadius
    },
    img: {
      borderRadius: theme.spacing(2)
    },
    item: {
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      border: `1px ${theme.palette.grey.A100} solid`,
      margin: '0 auto',
      maxWidth: 340,
      height: 250,
      '&:hover': {
        transition: 'all 0.3s ease-in-out',
        border: `1px ${theme.palette.grey.A100} solid`,
        boxShadow: `10px 10px ${theme.palette.primary.main}`
      }
    },
    highlight: {
      border: '4px solid #FFB347',
      '&:hover': {
        border: '4px solid #FFB347',
        boxShadow: `10px 10px #FFB347`
      }
    }
  })
);

interface Props {
  setDesignForCampaign?: ({
    design,
    open
  }: {
    design: IDesign;
    open?: boolean;
  }) => void;
  renderHeader?: boolean;
  designId?: string;
}

const Designs = ({
  renderHeader = true,
  setDesignForCampaign,
  designId
}: Props) => {
  const { EmailFooter } = useTypedSelector((s) => s.user.user);
  const classes = useStyles();
  const { isAFS, isSynergy, isLocal } = useInstance();
  const [open, setOpen] = React.useState(false);
  const [selectedDesignId, setSelectedDesignId] = React.useState('');

  const { data: designs = [], isLoading } = useQuery(
    [QueryKeys.FetchDesigns],
    () =>
      fetchDesigns({
        isAFS,
        isSynergy,
        isLocal
      }),
    {
      enabled: true
    }
  );

  // move designId first in the array
  const sortedDesigns = designs.sort((a, b) => {
    if (a.id === designId) return -1;
    if (b.id === designId) return 1;
    return 0;
  });

  const [paginatedDesigns, setPaginatedDesigns] =
    React.useState<IDesign[]>(sortedDesigns);

  // NOTE: Need to fetch designs individually as they have more data
  const { data: selectedDesign } = useQuery(
    [QueryKeys.FetchDesign, selectedDesignId],
    () => fetchDesign(selectedDesignId),
    {
      enabled: !!selectedDesignId,
      onSuccess: (design) => {
        setOpen(true);

        setDesignForCampaign?.({
          design: design as IDesign,
          open: true
        });
      }
    }
  );

  useEffect(() => {
    if (designId && !selectedDesignId) setSelectedDesignId(designId);
  }, [designId, selectedDesignId]);

  if (isLoading)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <CircularProgress />
      </div>
    );
  else
    return (
      <>
        {renderHeader && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px 0',
              opacity: 0.8
            }}>
            <MdDashboardCustomize
              style={{
                marginRight: 10,
                fontSize: 50,
                color: 'grey'
              }}
            />
            <Typography
              variant="h3"
              style={{
                color: 'grey'
              }}>
              Email Designs
            </Typography>
          </div>
        )}

        <div className={classes.container}>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center">
            {paginatedDesigns.map((d, i) => {
              const category = d.categories.filter(
                (cat) => cat !== 'public'
              )[0];

              const highlightDesign = selectedDesignId
                ? d.id === selectedDesignId
                : d.id === designId;

              return (
                <Grid
                  item
                  justifyContent="center"
                  key={i}
                  sm={4}
                  onClick={() => {
                    setSelectedDesignId(d.id);
                  }}>
                  <Card
                    className={`${classes.item} ${
                      highlightDesign ? classes.highlight : ''
                    }`}>
                    <CardActionArea
                      classes={{
                        root: classes.actionArea
                      }}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={d.thumbnail_url}
                        alt={d.name}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {category?.toUpperCase()}
                        </Typography>
                        <Typography variant="body2" color="secondary">
                          {d.name}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              );
            })}
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Paginator
                  allItems={sortedDesigns}
                  setPaginatedItems={setPaginatedDesigns}
                />
              </Box>
            </Grid>
          </Grid>
          {selectedDesign && !designId && (
            <EmailViewer
              email={selectedDesign}
              open={open}
              setOpen={setOpen}
              disclaimerHtml={EmailFooter}
            />
          )}
        </div>
      </>
    );
};

export default Designs;
