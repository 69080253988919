import { Dashboard as DashboardLayout } from 'layouts';
import InstanceBuilder from 'components/InstanceBuilder';
import { useTypedSelector } from 'redux/reducers';

const Component = () => {
  const { SystemAccess } = useTypedSelector((s) => s.user.user);

  // Introducers Cannot See Noticeboard
  return (
    <>
      {SystemAccess > 4 ? (
        <DashboardLayout title="Bips Noticeboard">
          <InstanceBuilder />
        </DashboardLayout>
      ) : (
        <div />
      )}
    </>
  );
};

export default Component;
