import React, { useState, ChangeEvent, useContext, useEffect } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { useProcess } from 'hooks';
import { useDispatch } from 'react-redux';
import {
  CompleteUserInstance,
  UserDefinition,
  UserInstance
} from 'types/interfaces';
import { NewContactAutomation } from 'redux/database/Custom API';
import { validateEmail } from 'helpers/validateEmail';
import { StepperContext } from 'components/Stepper/context';
import { createNotification } from 'react-redux-notify';
import { successNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';
import { Alert } from '@material-ui/lab';
import { Clear, Close, Search } from '@material-ui/icons';
import { useTypedSelector } from 'redux/reducers';
import { IMaterialUISelect, StyledSelect } from 'common/StyledSelect';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { getCompleteUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { CustomDialog } from 'common/Dialog';

export const PrimaryContactAutomation = () => {};

export const NewPrimaryContactAutomation = () => {
  const dispatch = useDispatch();
  const stepper = useContext(StepperContext);

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [UserInstanceEmail, setUserInstanceEmail] = useState<string>('');
  const [Title, setTitle] = useState<{
    title: string;
    UserInstanceId?: number;
  }>({ title: '', UserInstanceId: 0 });
  const { currentStep } = useProcess();
  const { UserInstanceListForCurrentStep, UserInstanceDictForCurrentStep } =
    currentStep;
  const Customer = UserInstanceListForCurrentStep[0];
  const CustomerName = Object.values(UserInstanceDictForCurrentStep)[0];
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [contactNameFilled, setContactNameFilled] = useState(false);
  const disabled = !validateEmail(UserInstanceEmail);

  const [loadSearchFields, setLoadSearchFields] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);
  const { deal } = useTypedSelector((s) => s.dealSummary);
  const { user } = useProcess();
  let UserInstanceFromFieldInstance = user.Id.toString();

  const toggle = () => setOpen(!open);
  const close = () => {
    setLoadSearchFields(false);
    setState({
      UserDefinition: null,
      UserInstance: null
    });

    setOpen(false);
  };

  useEffect(() => {
    setContactNameFilled(!!Title.title.trim());
  }, [Title.title]);

  const handleChange = (e: ChangeEvent, name: 'email' | 'title') => {
    const { value } = e.target as HTMLInputElement;
    if (name === 'email') setUserInstanceEmail(value);
    if (name === 'title') setTitle({ ...Title, title: value });
  };

  const submit = async () => {
    setLoading(true);
    const data = emptyUserInstance();
    data.UserInstanceEmail = UserInstanceEmail;
    data.Title = Title.title;

    if (Title.UserInstanceId) data.Id = Title.UserInstanceId;
    const { ProcessInstanceId, UserInstanceId } = Customer;
    const res = await NewContactAutomation({
      data,
      ProcessInstanceId,
      PrimaryUserInstanceId: UserInstanceId
    });
    setLoading(false);
    if (res && res.status === 200) {
      stepper.getData({});
      close();
      dispatch(
        createNotification(
          successNotif(
            `Success! A New Primary Contact Linked To ${CustomerName} Has Been Successfully Created.`
          )
        )
      );

      return gtag.event({
        feature: 'New Primary Contact Automation',
        action: 'New Contact Automation',
        message: `${data.UserInstanceEmail}`
      });
    } else {
      setLoadError(true);
    }
  };

  interface ICustomUI extends UserInstance {
    value: number;
    label: string;
  }

  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const { token } = useTypedSelector((s) => s.user.auth);

  const [UDList, setUDList] = useState<UserDefinition[]>([]);
  const [userOption, setUserOptions] = React.useState<ICustomUI[]>([]);

  const [state, setState] = React.useState({
    UserDefinition: null as number | null,
    UserInstance: null as UserInstance | null
  });

  const handleSearch = (v: number | UserInstance, name: string) => {
    if (name === 'UserInstance' && typeof v !== 'number') {
      setUserInstanceEmail(v.UserInstanceEmail || '');
      setTitle({
        title: v.Title || '',
        UserInstanceId: v.Id
      });
      return setState({ ...state, UserInstance: v });
    }
    if (name === 'UserDefinition' && typeof v === 'number') {
      return setState({ ...state, UserInstance: null, [name]: v });
    }
  };

  const getUserInstances = async () => {
    if (state?.UserDefinition) {
      const users = await getUserInstanceSummaryList({
        token,
        UserDefinitionId: state.UserDefinition
      });
      const options = [] as ICustomUI[];
      const userList = users.data as CompleteUserInstance[];
      Object.values(userList)
        .filter((CompleteUserInstance: CompleteUserInstance) => {
          return validateEmail(
            CompleteUserInstance.UserInstance.UserInstanceEmail
          );
        })
        .forEach((item: CompleteUserInstance) => {
          const Extension = item.UserInstance as ICustomUI;
          Extension.value = item.UserInstance.Id;
          Extension.label = item.UserInstance.Title;
          options.push(Extension);
        });

      setUserOptions(options);
    }
  };

  useEffect(() => {
    if (state.UserDefinition) getUserInstances();
  }, [state.UserDefinition]);

  useEffect(() => {
    if (state.UserInstance as UserInstance) {
      setUserInstanceEmail(state.UserInstance?.UserInstanceEmail || '');
      setTitle({
        ...Title,
        title: state.UserInstance?.Title || '',
        UserInstanceId: state.UserInstance?.Id
      });
      setLoadSearchFields(false);
    }
  }, [state.UserInstance]);

  useEffect(() => {
    let options: UserDefinition[] = [];
    const filteredUserDefinitionList = UserDefinitionList?.filter(
      (item: UserDefinition) =>
        ['Contacts', 'Suppliers', 'Brokers', 'Introducer'].includes(item.Title)
    );

    filteredUserDefinitionList?.forEach((item: any) => {
      item.value = item.Id;
      item.label = item.Title;
      options.push(item);
    });

    setUDList(options);
  }, [loadSearchFields]);

  const [openSelectDef, setOpenSelectDef] = useState<boolean>(false);
  const StyleSelectUserDefinitionProps: IMaterialUISelect = {
    label: 'User Definitions',
    value: state?.UserDefinition,
    onChange: ({ target: { value } }) => handleSearch(value, 'UserDefinition'),
    options: UDList,
    sortAlphabetically: true,
    sortNumerically: false,
    open: openSelectDef,
    handleClose: () => setOpenSelectDef(false)
  };

  const [openSelectIns, setOpenSelectIns] = useState<boolean>(false);
  const StyleSelectUserInstanceProps: IMaterialUISelect = {
    label: 'User Instances',
    value: state?.UserInstance?.Id || '',
    open: openSelectIns,
    handleClose: () => setOpenSelectIns(false),
    onChange: async ({ target: { value } }) => {
      const UserInstance = (await getCompleteUserInstanceDetail({
        baseUrl,
        UserInstanceId: value,
        action: 'UserInstance'
      })) as UserInstance;

      if (UserInstance) {
        handleSearch(UserInstance, 'UserInstance');
      }
    },
    options: userOption,
    sortAlphabetically: true,
    sortNumerically: false
  };

  return (
    <>
      <Button data-cy="new-primary-contact-btn" onClick={toggle}>
        + new
      </Button>

      <CustomDialog
        open={open}
        maxSize="sm"
        handleClose={close}
        alert={{
          title: loadError
            ? 'Search for Existing Records'
            : 'Adding a New Contact?',
          description: loadError
            ? 'Already Have a Primary Contact? Use Our Search to Find Them!'
            : 'Create New Primary Contact Email Address',
          type: loadError ? 'warning' : 'info'
        }}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                disabled={state.UserInstance ? true : false}
                placeholder="Contact Name"
                type="text"
                value={Title.title}
                onChange={(e: ChangeEvent) => handleChange(e, 'title')}
                InputProps={{
                  style: { color: state.UserInstance ? 'gray' : 'black' }
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                variant="outlined"
                fullWidth
                margin="dense"
                disabled={
                  !contactNameFilled ? true : state.UserInstance ? true : false
                }
                placeholder="Email Address"
                helperText={
                  !contactNameFilled ? "Please Enter Contacts's Name First" : ''
                }
                type="text"
                value={UserInstanceEmail}
                onChange={(e: ChangeEvent) => handleChange(e, 'email')}
                InputProps={{
                  style: { color: state.UserInstance ? 'gray' : 'black' }
                }}
              />
            </Grid>
          </Grid>

          {!loadSearchFields ? (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  style={{ textTransform: 'none' }}
                  endIcon={<Search />}
                  onClick={() => setLoadSearchFields(true)}>
                  Search Records
                </Button>
              </Grid>
              {state.UserInstance && (
                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    style={{ textTransform: 'none' }}
                    endIcon={<Clear />}
                    onClick={() => {
                      setUserInstanceEmail('');
                      setTitle({ ...Title, title: '', UserInstanceId: 0 });
                      setState({
                        ...state,
                        UserDefinition: null,
                        UserInstance: null
                      });
                    }}>
                    Clear Record
                  </Button>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={state?.UserDefinition ? 6 : 12}>
                <Box
                  onMouseDown={(event) => {
                    event.preventDefault();
                    setOpenSelectDef(true);
                  }}>
                  <StyledSelect
                    {...StyleSelectUserDefinitionProps}
                    useMaterialUI
                  />
                </Box>
              </Grid>
              {state?.UserDefinition && (
                <Grid item xs={6}>
                  <Box
                    onMouseDown={(event) => {
                      event.preventDefault();
                      setOpenSelectIns(true);
                    }}>
                    <StyledSelect
                      {...StyleSelectUserInstanceProps}
                      useMaterialUI
                    />
                  </Box>
                </Grid>
              )}
            </Grid>
          )}

          <br />
          {`This will create ${UserInstanceEmail} with a write relationship to ${CustomerName}
          and add them to the GDPR contacts for this deal. The deal
          information will be refreshed.`}
        </DialogContent>

        <DialogActions>
          {loading ? (
            <CircularProgress size={30} />
          ) : (
            <Button
              data-cy="primary-contact-create-btn"
              onClick={submit}
              color="primary"
              variant="contained"
              disabled={disabled}>
              Create
            </Button>
          )}
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export const emptyUserInstance = () => {
  const UserInstance: UserInstance = {
    Id: 0,
    Title: '',
    ItemOrder: 0,
    LastModified: '0001-01-01T00:00:00',
    IsPublished: false,
    UserDefinitionId: 0,
    UserInstanceEmail: '',
    UserInstancePassword: '',
    OwnerUserInstanceId: 0,
    SystemAccess: 0,
    ProfilePicture: '',
    ProxyUserInstanceId: 0,
    ThirdPartyId: '',
    RelationshipStatus: 0,
    RelatedUserPermission: 0,
    ConsoleUserInstanceId: 0,
    SmtpHost: '',
    SmtpPort: 0,
    SmtpSSL: false,
    SmtpUsername: '',
    SmtpPassword: '',
    FromName: '',
    FromAddress: '',
    LogoURL: '',
    DefaultMessage: '',
    EmailFooter: '',
    DocumentHeader: '',
    DocumentFooter: '',
    SummaryFields: undefined,
    NickName: '',
    TelephoneNumber: ''
  };
  return UserInstance;
};
