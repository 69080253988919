import React from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import { resendLandingpageEmailLink } from 'redux/actions/email';
import Dialog from '../Dialog';
import QuickUser from 'components/User/UserLozenge';
import CheckIcon from '@material-ui/icons/Check';
import { CircularProgress } from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import { validateEmail } from 'helpers/validateEmail';
import { UserInstance, UserInstanceListForCurrentStep } from 'types/interfaces';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';

const Component = () => {
  const [open, setOpen] = React.useState(false);
  const token = useSelector<any>((s) => s.user.auth.token);
  const user: any = useSelector<any>((s) => s.user.user);

  const {
    currentProcess,
    currentDeal,
    currentStep,
    currentStepId,
    ProcessInstanceId,
    currentStepCanCompleteList
  } = useProcess();

  const [data, setData] = React.useState<UserInstanceListForCurrentStep>([]);
  const [loading, setLoading] = React.useState(false);

  const submit = async ({ UserInstanceId }) => {
    setLoading(true);
    const res = await resendLandingpageEmailLink({
      token,
      ProcessInstanceId,
      ProcessStepDefinitionId: currentStepId,
      UserInstanceId
    });

    if (res.status === 200) setOpen(true);
    setLoading(false);
  };

  const handleClose = () => setOpen(false);

  const canSubmitCurrentStep = currentStep.UserInstanceListForCurrentStep.find(
    (el) => el.UserInstanceId === user.Id
  );
  const owner = currentDeal?.ProcessInstance?.UserInstanceId == user?.Id;
  const admin = user?.SystemAccess >= 10;
  const permission = owner || admin || canSubmitCurrentStep;

  React.useEffect(() => {
    setData(currentStepCanCompleteList);
  }, [currentStep]);

  const ProcessStepDefinitionId =
    currentDeal?.ProcessInstance?.ProcessStepDefinitionId;

  const stepdefdict =
    currentProcess?.CompleteProcessStepDefinitionDict?.[
      ProcessStepDefinitionId
    ];

  const allowingUserToSendEmail = () => {
    let allowToSendEmail = false;

    const ProcessStepInstance = currentDeal?.ProcessStepInstanceList?.filter(
      (el) => el.ProcessStepDefinitionId === currentStepId
    );

    if (
      Object.values(ProcessStepInstance).map((dataStepId: any) => {
        if (dataStepId?.ProcessStepDefinitionId === currentStepId) {
          allowToSendEmail = true;
        }
      })
    )
      return allowToSendEmail;
  };

  if (data.length < 1)
    return (
      <Alert variant="outlined" severity="warning">
        <Typography>
          Nobody has been assigned to this step of the process yet.
        </Typography>
      </Alert>
    );

  return (
    <Paper
      style={{
        padding: 15,
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none'
      }}>
      <Dialog
        handleClose={handleClose}
        message="A new email link has been sent"
        open={open}
        title="RE-SENT"
      />
      <Grid container direction="column" spacing={2}>
        <Grid
          item
          style={{ display: 'flex', alignItems: 'center', opacity: 0.5 }}>
          <InfoIcon style={{ marginRight: 5 }} />
          <Typography color="textSecondary">
            {'The following users are able to submit the current step'}
            {permission && ', click the resend button to send a reminder.'}
          </Typography>
        </Grid>

        {loading ? (
          <CircularProgress />
        ) : (
          data &&
          data.map(({ UserInstanceId, Selected }, idx) => (
            <ResendEmailListItem
              Selected={Selected}
              UserInstanceId={UserInstanceId}
              allowToSendEmail={allowingUserToSendEmail()}
              idx={idx}
              key={idx}
              permission={permission}
              stepdefdict={stepdefdict}
              submit={submit}
              token={token}
            />
          ))
        )}
      </Grid>
    </Paper>
  );
};

export default Component;

const ResendEmailListItem = ({
  idx,
  token,
  UserInstanceId,
  Selected,
  permission,
  submit,
  allowToSendEmail,
  stepdefdict
}) => {
  return (
    <Grid item key={idx}>
      <Paper
        style={{
          padding: 15,
          display: 'flex',
          alignItems: 'center',
          border: '1px solid rgba(0, 0, 0, .125)',
          boxShadow: 'none'
        }}>
        <div style={{ flexGrow: 1 }}>
          <User
            Selected={Selected}
            UserInstanceId={UserInstanceId}
            token={token}
            permission={permission}
            submit={submit}
            allowToSendEmail={allowToSendEmail}
            stepdefdict={stepdefdict}
          />
        </div>
      </Paper>
    </Grid>
  );
};

const User = ({
  token,
  UserInstanceId,
  Selected,
  permission,
  submit,
  allowToSendEmail,
  stepdefdict
}) => {
  const theme = useTheme();
  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const [user, setUser] = React.useState<UserInstance | undefined>();
  const validEmail = validateEmail(user?.UserInstanceEmail);

  const getUser = async () => {
    const response = await getQuickLiteUser({
      baseUrl,
      UserInstanceId,
      action: 'GET'
    });

    const UserInstance = response?.UserInstance;
    if (UserInstance) setUser(UserInstance);
  };

  React.useEffect(() => {
    getUser();
    return () => setUser(undefined);
  }, [UserInstanceId]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexGrow: 1 }}>
        {user && <QuickUser user={user} />}

        {Selected && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <CheckIcon
              style={{
                marginLeft: 5,
                marginRight: 5,
                color: theme.palette.success.main
              }}
            />
            <Typography variant="caption">Selected User</Typography>
          </div>
        )}
      </div>

      {permission && (
        <div>
          {allowToSendEmail && stepdefdict && validEmail ? (
            <Button
              color="primary"
              onClick={() => {
                submit({ UserInstanceId });
              }}
              variant="contained">
              Send
            </Button>
          ) : (
            <div />
          )}
        </div>
      )}
    </div>
  );
};
