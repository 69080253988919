import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import {
  GET_FLEXIMIZE_DOCUMENT_TYPES,
  GET_PROPOSAL_STATUS,
  GET_PROPOSALS_FOR_DEAL
} from 'graphql/LenderAPI/queries';
import { BugTracker } from 'Utils/Bugtracker';
import { IProposalStatusResponse, IProposalList } from './interface';

/**
 * Retrieves a list of proposals for a given deal ID by querying the GraphQL API.
 *
 * @param {string} dealId - The unique identifier for the deal.
 * @returns {Promise<IProposalList[] | ApolloError>} - A promise that resolves to an array of proposals or an ApolloError.
 */
export const GetProposalList = async (
  dealId: string,
  refetch?: boolean
): Promise<IProposalList[] | ApolloError> => {
  try {
    const { data, errors } = await apolloClient.query({
      query: GET_PROPOSALS_FOR_DEAL,
      variables: {
        dealId
      },
      fetchPolicy: refetch ? 'network-only' : 'cache-first'
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const responsePayload = data.GetPropsforDeal;
    return responsePayload;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Retrieves the status of a Fleximize proposal by querying the GraphQL API.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.proposalId - The unique identifier for the proposal.
 * @param {string} params.dealId - The unique identifier for the deal.
 * @returns {Promise<IFleximizeProposalStatus | ApolloError>} - A promise that resolves to the proposal status or an ApolloError.
 */
export const GetProposalStatus = async ({
  lender,
  proposalId,
  dealId
}: {
  lender: string;
  proposalId: string;
  dealId: string;
}): Promise<IProposalStatusResponse | ApolloError> => {
  try {
    const { data, errors } = await apolloClient.query({
      query: GET_PROPOSAL_STATUS,
      variables: {
        proposalId,
        dealId,
        lender
      },
      fetchPolicy: 'network-only'
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const responsePayload = data.GetProposalStatus;
    return responsePayload;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Retrieves the list of Fleximize document types by querying the GraphQL API.
 *
 * @returns {Promise<{ id: string; type: string } | ApolloError>} - A promise that resolves to the document types or an ApolloError.
 */
export const GetFleximizeDocumentTypes = async (): Promise<
  { id: string; type: string } | ApolloError
> => {
  try {
    const { data, errors } = await apolloClient.query({
      query: GET_FLEXIMIZE_DOCUMENT_TYPES
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const responsePayload = data.GetFleximizeDocumentTypes;
    return responsePayload;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};
