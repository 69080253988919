import { CustomDialog } from 'common/Dialog';
import { Typography, IconButton, Button, Paper, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

const RFRDialog = ({ item, open, setOpen, handleClose }) => {
  return (
    <div>
      <Button onClick={() => setOpen(true)} color="primary" variant="contained">
        Additional RFR And Comments
      </Button>
      <CustomDialog
        open={open}
        handleClose={null}
        alert={{
          title: `Additional RFR And Comments`,
          description: `List Of All RFR & Comments`,
          type: 'info'
        }}>
        <Paper style={{ maxHeight: 500, overflow: 'scroll' }}>
          {Object.values(item.rfrAndComments).map(
            (comments: any, index: number) => (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                key={index}>
                <Grid item style={{ paddingTop: 7 }}>
                  <Typography
                    style={{
                      color: 'grey'
                    }}>
                    Type: {comments.type}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>{comments.text}</Typography>
                </Grid>
                <Grid item style={{ paddingBottom: 7 }}>
                  <Typography>
                    Is Dangerous:{' '}
                    {comments.dangerous ? comments.dangerous : 'false'}
                  </Typography>
                </Grid>
              </Grid>
            )
          )}
        </Paper>
      </CustomDialog>
    </div>
  );
};

export default RFRDialog;

const Panel = ({ handleClose }) => {
  return (
    <Alert
      style={{ borderRadius: 0, justifyContent: 'center' }}
      severity="info"
      variant="filled">
      <div>
        <Typography style={{ color: 'white', fontWeight: 'bold' }}>
          List Of All RFR & Comments
        </Typography>
      </div>

      <IconButton
        style={{ position: 'absolute', right: 3, top: 3, color: 'white' }}
        onClick={() => handleClose()}>
        <CloseIcon />
      </IconButton>
    </Alert>
  );
};
