import React from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Time from 'common/Time';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    position: 'relative',
    margin: 1
  },
  time: {
    position: 'absolute',
    top: 8,
    right: 8,
    color: 'black',
    fontSize: 10
  }
}));

const Overlay = ({ notifications, onClose }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [logOpen, setLogOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    if (onClose !== undefined) onClose();
  };
  const toggleLog = () => {
    setLogOpen(!logOpen);
  };

  React.useEffect(() => {
    if (notifications?.length > 0) setOpen(true);
  }, [notifications]);

  return (
    <Dialog open={open} fullWidth maxWidth="md">
      {/* <DialogTitle id="alert-dialog-title">{'Execution Log'}</DialogTitle> */}
      <DialogContent>
        <Button
          data-cy="execution-log"
          variant="outlined"
          fullWidth
          onClick={toggleLog}>
          {`${logOpen ? 'Hide' : 'View'} Execution Log Detail`}
        </Button>

        {logOpen && (
          <>
            {notifications
              ?.filter((i) => !i.event.includes('Relationship'))
              ?.sort((a, b) => a.timestamp - b.timestamp)
              .map((notification, idx) => {
                switch (notification.type) {
                  case 'FAILED':
                    return (
                      <div className={classes.item} key={idx}>
                        <Panel
                          severity="error"
                          notification={notification}
                          classes={classes}
                        />
                      </div>
                    );
                  case 'SUCCESS':
                    return (
                      <div className={classes.item} key={idx}>
                        <Panel
                          severity="success"
                          notification={notification}
                          classes={classes}
                        />
                      </div>
                    );
                  default:
                    return (
                      <div className={classes.item} key={idx}>
                        <Panel
                          severity="warning"
                          notification={notification}
                          classes={classes}
                        />
                      </div>
                    );
                }
              })}

            {notifications?.filter(
              (i) => i.event.includes('Relationship') && i.type === 'SUCCESS'
            )?.length > 0 && (
              <div className={classes.item}>
                <Panel
                  severity="success"
                  notification={{
                    event: 'Relationships Creation',
                    type: 'SUCCESS',
                    message: `${
                      notifications?.filter(
                        (i) =>
                          i.event.includes('Relationship') &&
                          i.type === 'SUCCESS'
                      )?.length
                    } relationships Successfully created`
                  }}
                  classes={classes}
                />
              </div>
            )}

            {notifications?.filter(
              (i) => i.event.includes('Relationship') && i.type === 'FAILED'
            )?.length > 0 && (
              <div className={classes.item}>
                <Panel
                  severity="error"
                  notification={{
                    event: 'Relationships Creation',
                    type: 'FAILED',
                    message: `${
                      notifications?.filter(
                        (i) =>
                          i.event.includes('Relationship') &&
                          i.type === 'FAILED'
                      )?.length
                    } relationships failed to create`
                  }}
                  classes={classes}
                />
              </div>
            )}
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button data-cy="overlay-ok-btn" onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Overlay;

const Panel = ({ severity, notification, classes }) => (
  <Alert severity={severity} variant="filled" style={{ borderRadius: 10 }}>
    <AlertTitle>{notification?.event}</AlertTitle>
    <Typography>{notification?.message}</Typography>
    <Typography variant="body2">[ {notification?.type} ]</Typography>
    {notification?.timestamp && (
      <div className={classes.time}>
        <Time time={notification?.timestamp} type="mymaskC" />
      </div>
    )}
  </Alert>
);
