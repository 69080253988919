import { ObjectInstance } from 'types/interfaces';
import { store } from 'redux/store';
import { SET_CURRENT_DEAL } from 'redux/actions/types';

const { dispatch } = store;

export const updateProcessObjectInstanceInRedux = async ({
  ObjectInstance
}: {
  ObjectInstance: ObjectInstance;
}) => {
  const { Id } = ObjectInstance;
  const currentDeal = store.getState().process.currentDeal;
  const dealExists = Object.keys(currentDeal).length > 0;
  if (!dealExists) return;
  const updatedCurrentDeal = JSON.parse(JSON.stringify(currentDeal));
  const objExists = updatedCurrentDeal?.CompleteObjectInstanceDict?.[Id];
  if (!objExists) updatedCurrentDeal.CompleteObjectInstanceDict[Id] = {};
  updatedCurrentDeal.CompleteObjectInstanceDict[Id].ObjectInstance =
    ObjectInstance;

  dispatch({ type: SET_CURRENT_DEAL, payload: updatedCurrentDeal });
  return ObjectInstance;
};
