import React, { useEffect, useState } from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import { useSelector, useDispatch } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';
import { getAllProcesses } from 'redux/actions/processes';
import ProcessTable from 'components/ProcessTable';
import { useHistory } from 'react-router-dom';
import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';
import { useTypedSelector } from 'redux/reducers';

export const Deals = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const { processSummaries } = useTypedSelector((s) => s.process);
  const { ProcessesName } = useTypedSelector(
    (state) => state.config.instanceSettings
  );

  const getAllData = async () => {
    setLoading(true);
    getAllProcesses()
      .then((res) => {
        setLoading(false);
      })
      .catch((e) => {
        dispatch(
          createNotification(
            errorNotif(
              'There was an issue retrieving deal data from the server. Try refreshing the deal data. '
            )
          )
        );
        history.push('/dashboard');
      });
  };

  useEffect(() => {
    // if (processSummaries && Object.keys(processSummaries).length === 0)
    getAllData();
  }, []);

  useEffect(() => {
    if (processSummaries && Object.keys(processSummaries).length > 0)
      setLoading(false);
  }, [processSummaries]);

  return (
    <DashboardLayout title={ProcessesName ? `${ProcessesName}s` : 'Deals'}>
      {loading ? (
        <LinearProgress />
      ) : (
        <ProcessTable
          getAllData={getAllData}
          title={ProcessesName ? `${ProcessesName}s` : 'Deals'}
        />
      )}
    </DashboardLayout>
  );
};

export default Deals;
