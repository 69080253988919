import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import { FileAttachment } from '@microsoft/microsoft-graph-types';
import { Progress } from 'components/FileStorage';
import { useFileStorage } from 'hooks';
import { UserInstance } from 'types/interfaces';
import UploadFileToFileStorage from './UploadFileToFileStorage';
import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
import * as gtag from 'Utils/gtag';

export const downloadAttachment = async (a: FileAttachment) => {
  if (a.contentBytes) {
    let contentType = a.contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(a.contentBytes);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);
      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    const file = new Blob(byteArrays, { type: contentType });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
};

const PreviewAttachment = ({
  attachment,
  handleClose
}: {
  attachment: FileAttachment;
  handleClose: (event: any) => void;
}) => {
  const handlePreview = (event) => {
    downloadAttachment(attachment);
    gtag.event({
      feature: 'Inbox',
      action: 'Upload',
      message: `Email Attachment Previewed ${attachment.name}`
    });

    handleClose(event);
  };

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={(event) => {
        handlePreview(event);
      }}>
      Preview Attachment
    </Button>
  );
};

const UploadingFiles = ({
  isOpen,
  attachment,
  user,
  handleClose
}: {
  isOpen: boolean;
  attachment: FileAttachment | undefined;
  user: UserInstance;
  handleClose: (event: any) => void;
}) => {
  const dispatch = useDispatch();
  const { uploadProgress } = useFileStorage();
  const closeButton = (
    <IconButton
      edge="end"
      color="inherit"
      onClick={(e) => {
        e.preventDefault();
        handleClose(e);
      }}
      aria-label="close"
      style={{ position: 'absolute', right: '10px', top: '-2px' }}>
      <Close fontSize="medium" />
    </IconButton>
  );

  if (!attachment) {
    dispatch(createNotification(errorNotif('No Attachment Found')));
    return <div />;
  }

  return (
    <Dialog
      style={{ backdropFilter: `blur(3px)` }}
      open={isOpen}
      onClose={(event, reason) => {
        if (reason === 'backdropClick') {
          return;
        }
      }}>
      <Paper style={{ width: '100%', height: '100%', position: 'relative' }}>
        <Alert severity="info" action={closeButton}>
          <Typography style={{ width: 400 }}>
            <b>What Actions Would You Like To Take With {attachment.name}?</b>
          </Typography>
        </Alert>
        <Progress progress={uploadProgress} />
        <DialogActions>
          <PreviewAttachment
            attachment={attachment}
            handleClose={handleClose}
          />
          <UploadFileToFileStorage
            attachment={attachment}
            user={user}
            handleClose={handleClose}
          />
        </DialogActions>
      </Paper>
    </Dialog>
  );
};

export default UploadingFiles;
