import React from 'react';
// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/line
import { ResponsiveLine } from '@nivo/line';
import { init_data } from './nivo-chart';
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

import { format } from 'fecha';
import { useTheme } from '@material-ui/core/styles';

function Last30Days() {
  var result = [];
  for (var i = 0; i < 30; i++) {
    var d = new Date();
    d.setDate(d.getDate() - i);
    result.push(d);
  }

  return result.reverse();
}
const dates = Last30Days();

const MyResponsiveLine = ({ MyProcessInstances }) => {
  const theme = useTheme();
  const [data, setData] = React.useState(init_data);

  React.useEffect(() => {
    let totals = [];
    // Order them by time stamp.
    //  For each of the past 30 days
    dates.forEach((date) => {
      // filter the ones with a match on the date
      const matches = MyProcessInstances.filter((el) => {
        const actionDate = new Date(el.LastModified);
        const actionDateMonth = actionDate.getMonth();
        const actionDateDay = actionDate.getDate();
        const month = date.getMonth();
        const day = date.getDate();

        return actionDateMonth === month && actionDateDay === day;
      });
      totals.push({ x: format(date, 'short'), y: matches.length });
      // totals.push({ x: date, y: matches.length });
    });
    const newData = [
      {
        id: 'Activity',
        color: 'hsl(44, 70%, 50%)',
        // color: theme.palette.primary.lighttrans,
        data: totals
      }
    ];
    setData(newData);

    // Get the number per date in the columns
    // time since last mofified
  }, [MyProcessInstances]);

  return (
    <ResponsiveLine
      theme={{
        // background: 'blue',
        textColor: theme.palette.primary.light,
        grid: {
          line: {
            stroke: theme.palette.background.default
          }
        }
      }}
      axisBottom={{
        orient: 'bottom',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: -45
      }}
      axisLeft={{
        orient: 'left',
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Activity',
        legendOffset: -40,
        legendPosition: 'middle'
      }}
      axisRight={null}
      axisTop={null}
      // tickValues={5}
      colors={theme.palette.primary.light}
      curve="catmullRom"
      data={data}
      enableArea
      margin={{ top: 30, right: 30, bottom: 50, left: 60 }}
      useMesh
      xScale={{ type: 'point' }}
      yFormat=" >-.2f"
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false
      }}
    />
  );
};

export default MyResponsiveLine;
