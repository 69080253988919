import { gql } from '@apollo/client';

export const SEARCH_COMPANIES = gql`
  query Companies($where: CompanySearchFilterInput) {
    companies(where: $where) {
      items {
        rfa_rating
        company_id
        company_name
        company_number
        company_type
        legal_status
        email
        credit_score
        auditor
        turnover
        estimated_turnover
        employees
        vat_number
        website
        addresses {
          address_line_1
          location {
            lat
            lon
          }
          postcode
        }
      }
    }
  }
`;

export const GET_COMPANY_REPORT = gql`
  query Company($reportRequest: CompanyReportRequestInput) {
    companyReport(reportRequest: $reportRequest) {
      company_name
      company_number
      company_type
      company_id
      credit_limit
      credit_score
      employees
      estimated_employees
      estimated_turnover
      incorporation_date
      legal_status
      ordered_rfa_rating
      rfa_rating
      rfa_rating_score
      turnover
      website

      rfa_rating_score
      raw_rating_score
      rfa_rating
      generated_rfa_rating
      ordered_rfa_rating

      addresses {
        address_line_1
        address_line_2
        address_line_3
        address_line_4
        country
        county
        location {
          lat
          lon
        }
        postcode
        town
        type
      }

      active_directors {
        id
        person_id
        source
        is_current
        appointment
        company_name
        rfa_rating
        first_name
        surname
        nationality
        town
        county
        postcode
        occupation
        locality
        address
        title
        appointment_type
        birthdate
        date_appointed
        date_resigned
        other_appointments
        company
        companyId
        company_id
      }

      cashflow {
        account_date
        consolidated
        currency
        increase_in_cash
        net_before_financing
        net_from_financing
        net_from_operating_activities
        net_from_return_investment_servicing
        no_weeks
      }

      contacts {
        email_address
        telephone_number
      }

      corporate_structure {
        company {
          company_id
          turnover
          estimated_turnover
        }
        company_id
        company_name
        company_number
        country
        id
        level
        parent
        ultimate_parent
      }

      balance_sheet {
        balance_sheet_id
        companies_company_id
        auditor_comments
        account_date
        no_weeks
        currency
        tangible_fixed_assets
        intangible_assets
        total_fixed_assets
        stocks
        trade_debtors
        cash
        misc_current_assets
        total_current_assets
        creditors_amounts_falling
        total_assets_less_liabilities
        total_long_term_liabilities
        total_liabilities
        share_capital_reserve
        pl_account_reserve
        revaluation_reserve
        shareholders_funds
        working_capital
        net_worth
        total_assets
        contingent_liabilities
      }

      ccjs {
        currency
        companyId
        address
        amount
        case_number
        court_name
        date_of_birth
        date_of_judgement
        entity_type
        given_name
        judgement_type
        rtl_image_number
        run_date
        record_type
        satisfaction_date
        suffix
        surname
        title
        trading_style
      }

      directors {
        age_years
        appointment_duration_years
        title
        occupation
        nationalities
        nationalities_iso3
      }

      filing_history {
        download_url
        description
        date
        action_date
        description_values {
          made_up_date
          officer_name
          termination_date
          description
          appointment_date
          charge_creation_date
          charge_number
          brought_down_date
          old_address
          change_date
          new_address
        }
      }

      mortgages {
        date_created
        type
        holder
        secured_on
        form_no
        satisfied
        details
        mortgage_id
        mortgage_no
        companies_company_id
        satisfied_date
      }

      profit_loss {
        profit_loss_id
        account_date
        no_weeks
        consolidated
        currency
        turnover
        cost_of_sales
        gross_profit
        operating_profit
        non_trading_income
        interest_payable
        pre_tax_profit
        taxation
        post_tax_profit
        dividends
        retained_profit
        value_added
        audit_fee
        director_renum
        non_audit_fee
        employees
        companies_company_id
        ebitda
      }

      ratio_analysis {
        liquidity_ratio
        profit_capital_employed
        current_ratio
        profit_sales
        interest_pre_interest_profit
        profit_total_assets
        profit_shareholder_funds
        turnover_total_assets
        turnover_fixed_assets
        working_capital_turnover
        total_debt_net_worth
        shareholders_funds_total_assets
        long_term_debt_net_worth
        total_debt_working_capital
        average_employee_remuneration
        wages_sales
        profit_per_employee
        sales_per_employee
        capital_employed_per_employee
        total_fixed_assets_per_employee
        total_assets_per_employee
        creditors_debtors
        debtors_total_assets
        current_liabilities_stocks
        export_sales
        sales_audit_fees
        total_assets_audit_fees
        account_date
      }

      sic07 {
        code
        description
        division
        division_description
        priority
        section
        section_description
        sic_class
        sic_class_description
        source
        sub_class
        sub_class_description
      }

      shareholders {
        company_flag
        title
        first_name
        surname
        shareholder_class
        share_quantity
        share_value
      }
    }
  }
`;

export const GET_COMPANY_DETAIL = gql`
  query Company($companyId: Any!) {
    company(id: $companyId) {
      history {
        event_type
        company_id
        description
        published_date
        id
        rating
        score
      }
    }
  }
`;

export const GET_GENERATED_COMPANY_PDF = gql`
  query FileExports($where: FileExportFilterInput) {
    fileExports(where: $where) {
      items {
        status
        id
        timestamp
        createdDate
        completedDate
        relativePath
        exportType
        description
        etaSeconds
        isCancelled
        legacyId
        fileLocation
        downloadLocation
        meta {
          pdf {
            companyName
            companyNumber
          }
        }
      }
    }
  }
`;

export const QUICK_USER_QUERY = gql`
  query QuickLiteUser($userInstanceId: Int, $baseUrl: String) {
    QuickLiteUser(UserInstanceId: $userInstanceId, baseURL: $baseUrl) {
      Id
      Title
      UserInstanceEmail
      SystemAccess
    }
  }
`;

export const USERS_FOR_PROCESS_QUERY = gql`
  query UsersForProcess(
    $baseUrl: String
    $processInstanceId: Int
    $processDefinitionId: Int
    $userDefinitionId: Int
    $action: UsersForProcessActions
  ) {
    UsersForProcess(
      baseURL: $baseUrl
      ProcessInstanceId: $processInstanceId
      ProcessDefinitionId: $processDefinitionId
      UserDefinitionId: $userDefinitionId
      action: $action
    ) {
      Id
      Title
    }
  }
`;
