import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'redux/reducers';
import { BugTracker } from 'Utils/Bugtracker';
import { createNotification } from 'react-redux-notify';
import { errorNotif, successNotif } from 'components/Notifications';

interface IInstanceBuilderData extends IInstanceBase {
  image?: string | null;
  title?: string | null;
}

interface IInstanceBase {
  text: string;
  timestamp: string;
}

export const useNoticeboardBuilder = () => {
  const dispatch = useDispatch();
  const { InstanceNoticeBoard, BrandList, BrandNoticeBoard } = useTypedSelector(
    (s) => s.config.settings
  );
  const { globalHostName } = useTypedSelector((s) => s.config.hostname);
  const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;
  const [history, setHistory] = useState<IInstanceBuilderData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<IInstanceBuilderData>({
    image: null,
    title: null,
    text: '',
    timestamp: ''
  });

  const [dialogOpen, setDialogOpen] = useState<boolean>(true);
  const [selectedBrand, setSelectedBrand] = useState('');
  const [availableBrands, setAvailableBrands] = useState<string[]>([]);
  const [builderType, setBuilderType] = useState<string>('');

  const handleGlobalBuilder = () => {
    setBuilderType('global');
    setDialogOpen(false);
  };
  const handleInstanceBuilder = () => {
    setBuilderType('instance');
    setDialogOpen(false);
  };

  const handleBrandSelect = () => {
    if (BrandList) {
      const brandsArray = BrandList.split(',');
      setAvailableBrands(brandsArray);
      setDialogOpen(true);
      setBuilderType('brand');
    } else {
      dispatch(
        createNotification(
          errorNotif(
            'No Brands Available. Please Add Brands To The Configurations.'
          )
        )
      );
    }
  };

  const handleSaveBrand = async (brand: string) => {
    if (!availableBrands.includes(brand)) {
      dispatch(createNotification(errorNotif('No brand selected')));
      return;
    }
    setSelectedBrand(brand);
    setBuilderType('brand');
    setDialogOpen(false);
  };

  const getHistoryPath = () => {
    switch (builderType) {
      case 'global':
        return 'history';
      case 'instance':
        return `${hostname}.history`;
      case 'brand':
        return selectedBrand ? `${selectedBrand}.history` : null;
      default:
        return null;
    }
  };

  const saveToGlobalHistory = async (historyEntry: {
    text: string;
    timestamp: string;
  }) => {
    setData({ image: null, text: '', timestamp: '', title: null });

    firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .set(
        { history: firebase.firestore.FieldValue.arrayUnion(historyEntry) },
        { merge: true }
      )
      .catch((e) => BugTracker.notify(e));
  };

  const saveToInstanceHistory = async (
    historyEntry: {
      text: string;
      timestamp: string;
    },
    isNoticeboard: boolean
  ) => {
    setData({ image: null, text: '', timestamp: '', title: null });

    const instanceKey = hostname;
    const instanceData = {
      currentNoticeboardInstance: !isNoticeboard
        ? InstanceNoticeBoard
        : historyEntry,
      history: firebase.firestore.FieldValue.arrayUnion(historyEntry)
    };

    const instanceUpdate = {
      [instanceKey]: instanceData
    };

    firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .set(instanceUpdate, { merge: true })
      .catch((e) => BugTracker.notify(e));
  };

  const saveToBrandHistory = async (
    historyEntry: {
      text: string;
      timestamp: string;
      title: string | null;
    },
    isNoticeboard: boolean
  ) => {
    const brandKey = selectedBrand;

    if (!brandKey) {
      throw new Error('No brand selected');
    }

    const brandData = {
      currentNoticeboardInstance: !isNoticeboard
        ? historyEntry
        : BrandNoticeBoard || historyEntry,
      history: firebase.firestore.FieldValue.arrayUnion(historyEntry)
    };

    const brandUpdate = { [brandKey]: brandData };

    await firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .set(brandUpdate, { merge: true });

    setData({ image: null, text: '', timestamp: '', title: null });
    dispatch(
      createNotification(
        successNotif(`Successfully saved to ${brandKey} brand noticeboard`)
      )
    );
  };

  const saveToHistory = async () => {
    if (builderType === 'global') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: { text: string; timestamp: string } = {
        ...data,
        timestamp: historyEntryTimestamp
      };

      await saveToGlobalHistory(historyEntry);
      dispatch(
        createNotification(
          successNotif(`Successfully Created A Bips Global History`)
        )
      );
    } else if (builderType === 'instance') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };
      await saveToInstanceHistory(historyEntry, false);
      dispatch(
        createNotification(
          successNotif(`Successfully Created A Bips Instance History`)
        )
      );
    } else if (builderType === 'brand') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };
      await saveToBrandHistory(historyEntry, false);
      dispatch(
        createNotification(
          successNotif(`Successfully Created A Bips Brand History`)
        )
      );
    } else console.error('Builder Type Is Not Set.');
  };

  const handleSelectLayout = () => {
    if (builderType === 'global') {
      const createdTime = new Date().toISOString();
      const historyEntry = {
        text: data.text,
        timestamp: createdTime
      };

      return firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .set({ currentNoticeboardInstance: historyEntry }, { merge: true })
        .then(() => {
          saveToGlobalHistory(historyEntry);
          dispatch(
            createNotification(
              successNotif(
                'The Bips Global Noticeboard Has Been Updated Successfully!'
              )
            )
          );
        })
        .catch((e) => BugTracker.notify(e));
    } else if (builderType === 'instance') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };

      const instanceData = {
        currentNoticeboardInstance: historyEntry,
        history: firebase.firestore.FieldValue.arrayUnion(historyEntry)
      };

      const instanceUpdate = { [hostname]: instanceData };
      return firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .set(instanceUpdate, { merge: true })
        .then(async () => {
          await saveToInstanceHistory(historyEntry, true);
          dispatch(
            createNotification(
              successNotif(
                `The Bips Instance Noticeboard Has Been Updated Successfully!`
              )
            )
          );
        })
        .catch((e) => BugTracker.notify(e));
    } else if (builderType === 'brand') {
      const historyEntryTimestamp = new Date().toISOString();
      const historyEntry: {
        text: string;
        timestamp: string;
        title: string | null;
      } = {
        ...data,
        title: data.title || null,
        timestamp: historyEntryTimestamp
      };

      const brandData = {
        currentNoticeboardInstance: historyEntry,
        history: firebase.firestore.FieldValue.arrayUnion(historyEntry)
      };
      const brandUpdate = { [selectedBrand]: brandData };
      return firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .set(brandUpdate, { merge: true })
        .then(async () => {
          await saveToBrandHistory(historyEntry, true);
          dispatch(
            createNotification(
              successNotif(
                `The Bips Brand Noticeboard Has Been Updated Successfully!`
              )
            )
          );
        })
        .catch((e) => BugTracker.notify(e));
    } else console.error('Builder Type Is Not Set.');
  };

  const deleteHistoryItem = async (itemToDelete: IInstanceBuilderData) => {
    setIsLoading(true);
    try {
      const historyPath = getHistoryPath();
      if (!historyPath) {
        throw new Error('Invalid builder type or missing brand');
      }

      if (itemToDelete.image) {
        const storageRef = firebase.storage().refFromURL(itemToDelete.image);
        await storageRef.delete();
      }

      const itemToRemove: IInstanceBuilderData = {
        text: itemToDelete.text,
        timestamp: itemToDelete.timestamp,
        ...(itemToDelete.image && { image: itemToDelete.image }),
        ...(itemToDelete.title && { title: itemToDelete.title })
      };

      await firebase
        .firestore()
        .collection('globalSetting')
        .doc('noticeboard_instance')
        .update({
          [historyPath]: firebase.firestore.FieldValue.arrayRemove(itemToRemove)
        });

      setHistory((currentHistory) =>
        currentHistory.filter(
          (item) => item.timestamp !== itemToDelete.timestamp
        )
      );

      dispatch(createNotification(successNotif('Item deleted successfully')));
    } catch (error) {
      dispatch(createNotification(errorNotif('Failed to delete item')));
      BugTracker.notify(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnsubscribeGlobal = () => {
    const unsubscribe = firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data && data.history) {
              setHistory(data.history);
            }
          }
        },
        (e) => {
          BugTracker.notify(e);
        }
      );

    return unsubscribe;
  };

  const handleUnsubscribeInstance = () => {
    const unsubscribe = firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data && data[hostname] && data[hostname].history) {
              const instanceHistory = data[hostname].history.map(
                (item: IInstanceBuilderData) => ({
                  image: item.image || null,
                  text: item.text,
                  timestamp: item.timestamp,
                  title: item.title || null
                })
              );
              setHistory(instanceHistory);
            } else {
              setHistory([]);
            }
          }
        },
        (e) => {
          BugTracker.notify(e);
        }
      );

    return unsubscribe;
  };

  const handleUnsubscribeBrand = () => {
    if (!selectedBrand) {
      return;
    }

    const unsubscribe = firebase
      .firestore()
      .collection('globalSetting')
      .doc('noticeboard_instance')
      .onSnapshot(
        (doc) => {
          if (doc.exists) {
            const data = doc.data();
            if (data && data[selectedBrand] && data[selectedBrand].history) {
              const brandHistory = data[selectedBrand].history.map(
                (item: IInstanceBuilderData) => ({
                  image: item.image || null,
                  text: item.text,
                  timestamp: item.timestamp,
                  title: item.title || null
                })
              );
              setHistory(brandHistory);
            } else {
              setHistory([]);
            }
          }
        },
        (e) => {
          BugTracker.notify(e);
        }
      );

    return unsubscribe;
  };

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;
    const showLink = [
      'process-builder',
      'processbuildercosneticscouk',
      'process-builder-dev'
    ].includes(globalHostName);

    if (showLink) {
      switch (builderType) {
        case 'global':
          unsubscribe = handleUnsubscribeGlobal();
          break;
        case 'instance':
          unsubscribe = handleUnsubscribeInstance();
          break;
        case 'brand':
          unsubscribe = handleUnsubscribeBrand();
          break;
      }
    } else {
      unsubscribe = handleUnsubscribeInstance();
      setBuilderType('instance');
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [builderType]);

  return {
    saveToHistory,
    handleSelectLayout,
    handleGlobalBuilder,
    handleInstanceBuilder,
    handleSaveBrand,
    handleBrandSelect,
    deleteHistoryItem,
    history,
    data,
    dialogOpen,
    builderType,
    setData,
    setDialogOpen,
    availableBrands,
    setSelectedBrand,
    selectedBrand
  };
};
