import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

const Component = () => {
  const [query, setQuery] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const handleChange = (e) => setQuery(e.target.value);

  const submit = async () => {
    setLoading(true);
    const token = await firebase.auth().currentUser?.getIdToken();
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_PROXY}/fca`,
      data: { query },
      headers: { token }
    };

    axios(config)
      .then((res) => {
        // console.log({ res });
        setData(res.data.Data);
        setLoading(false);
      })
      .catch((e) => {
        // console.log({ e }));
        BugTracker.notify(e);
      });
  };

  if (loading) return <LinearProgress />;
  return (
    <React.Fragment>
      <input
        onChange={handleChange}
        placeholder="Enter FCA number ..."
        type="search"
        value={query}
      />
      <button onClick={submit}>submit</button>
      <br />
      <br />
      <br />
      <br />

      <div>
        {data.map((company, i) => {
          return (
            <Paper key={i} style={{ padding: 15, marginBottom: 15 }}>
              <h1>{company['Name']}</h1>
              <br />
              <h2>Status: {company['Status']}</h2>
              <br />
              <p>Type: {company['Type of business or Individual']}</p>
              <p>Reference Number: {company['Reference Number']}</p>
              <p>Url: {company['URL']}</p>
            </Paper>
          );
        })}
      </div>
    </React.Fragment>
  );
};
export default Component;
