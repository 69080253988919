import { Asset } from 'types/calculatorInterfaces';

export const mergeDuplicateAssets = (assetsToMerge): Asset[] => {
  const mergedAssets: Asset[] = [];

  // NOTE: old assets used to be duplicated for every quantity. This code makes those assets backwards compatible:
  assetsToMerge.forEach((currentAsset) => {
    const currentId = currentAsset.ObjectInstanceId;

    const alreadyMerged = mergedAssets.some(
      (a) => a.ObjectInstanceId === currentId
    );

    if (alreadyMerged) return;
    const duplicates = assetsToMerge.filter(
      (a) => a.ObjectInstanceId === currentId
    );

    if (duplicates.length === 0) {
      mergedAssets.push(currentAsset);
      return;
    }

    const mergedQuantity = duplicates.reduce((acc, current) => {
      acc += current.Quantity;
      return acc;
    }, 0);

    mergedAssets.push({
      ...currentAsset,
      Quantity: mergedQuantity
    });
  });

  return mergedAssets;
};
