import { PaymentFrequency } from 'types/calculatorInterfaces';
import { getMonthsMultiplier } from '../../SeasonalPaymentsTable/functions';
import { formatDate } from 'Utils';

export const calculateEndDate = (
  startDate: string | Date,
  fundingProfile: number,
  paymentFrequency: PaymentFrequency
) => {
  const { Monthly, Quarterly, SemiAnnually, Annually } = PaymentFrequency;

  let mapFrequencyToNum = '1';

  switch (paymentFrequency) {
    case Monthly:
      mapFrequencyToNum = '1';
      break;

    case Quarterly:
      mapFrequencyToNum = '2';
      break;

    case SemiAnnually:
      mapFrequencyToNum = '3';
      break;

    case Annually:
      mapFrequencyToNum = '4';
      break;

    default:
      break;
  }

  // funding profile x frequency = term (total months)
  const months = fundingProfile * getMonthsMultiplier(mapFrequencyToNum);

  const startD = new Date(startDate);

  const endDate = startD.setMonth(startD.getMonth() + months);

  return formatDate(endDate);
};
