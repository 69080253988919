import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Pagination from '@material-ui/lab/Pagination';

import { Box } from '@material-ui/core';

interface Props {
  itemsPerPage?: number;
  allItems: any[];
  setPaginatedItems: Dispatch<SetStateAction<any[]>>;
}

const Paginator = ({
  itemsPerPage = 9,
  allItems = [],
  setPaginatedItems
}: Props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(allItems?.length / itemsPerPage);

  useEffect(() => {
    const paginatedItems = allItems.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    setPaginatedItems(paginatedItems);
  }, [currentPage, itemsPerPage, allItems]);

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={(event, value) => setCurrentPage(value)}
      />
    </Box>
  );
};

export default Paginator;
