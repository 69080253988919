const NorthridgeProposal = {
  id: 0,
  username: '',
  otheR1: '',
  otheR2: '',
  otheR3: '',
  otheR4: '21321321',
  adS_REQUEST: {
    loaN_PROPOSAL: {
      control: {
        dealerref: '',
        repno: '',
        agreementno: '',
        cusT_ID1: '',
        cusT_ID2: '',
        cusT_ID3: '',
        action: '',
        replY_EMAIL: '',
        notes: {
          noteline: ''
        }
      },
      finance: {
        noteline: '',
        financetype: '',
        templatelist: '',
        invoiceprice: 0,
        settlementamount: '',
        settlement: '',
        term: 84,
        instalment: '',
        flatrate: '',
        ppp: '',
        deposit: '',
        directdeposit: '',
        parT_EXCHANGE: '',
        extras: '',
        accfee: '',
        residual: '',
        optioN_FEE: '',
        starT_DATE: '',
        residuaL_DATE: '',
        subsidY_AMT: '',
        subsidY_RATE: '',
        initiaL_RENTAL: '',
        iniT_RENT_VAT: '',
        advancE_PAYMTS: '',
        remaininG_PAYMTS: '',
        esT_ANNUAL_MILEAGE: '',
        commission: '',
        accfeE_SPREAD: '',
        paY_FREQ: '',
        breakmtH1: '',
        breakmtH2: '',
        ccA_EXEMPTION: '',
        depositcash: '',
        sourceofdeposit: '',
        addonsubsidy: '',
        addonloanamount: '',
        addonpayment: '',
        addonfirstpay: '',
        totalsubsidy: '',
        totalpayment: ''
      },
      loaN_PURPOSE: {
        vehicle: {
          merchcode: '',
          make: '',
          model: '',
          trim: '',
          reG_DATE: '',
          reG_NO: '',
          chassisno: '',
          enginE_SIZE: '',
          mileage: '',
          bodystyle: '',
          neW_USED: '',
          manuaL_AUTO: '',
          petroL_DIESEL: '',
          insurancecompany: '',
          policyno: '',
          capcode: '',
          commercial: '',
          vaT_QUALIFYING: ''
        },
        nonvehicle: {
          merchcode: '',
          descriptioN1: '',
          descriptioN2: '',
          descriptioN3: '',
          serialno: '',
          netcost: '',
          age: 0
        }
      },
      contracT_HIRE_DETAILS: {
        admiN_PW: '',
        contracT_HIRE: '',
        contracT_HIRE_TEMPLATE: '',
        roaD_FUND_LICENCE: '',
        roaD_FUND_LICENCE_ANNUAL_NET_AMOUNT: '',
        upfronT_ROAD_TAX_NET_AMOUNT: '',
        totaL_ADDITIONAL_ROAD_TAX_NET_AMOUNT: '',
        totaL_ADDITIONAL_ROAD_TAX_VAT_AMOUNT: '',
        totaL_ADDITIONAL_ROAD_TAX_AMOUNT: '',
        maintenance: '',
        totaL_MAINTENANCE_NET_AMOUNT: '',
        totaL_MAINTENANCE_VAT_AMOUNT: '',
        totaL_MAINTENANCE_AMOUNT: '',
        maintaineR_NAME: '',
        speciaL_CONDITIONS: '',
        relieF_VEHICLE: '',
        relieF_VEHICLE_FREE_OF_CHARGE: '',
        relieF_VEHICLE_CONDITIONS: '',
        unfiT__FOR_CONTINUOUS_PERIOD: '',
        basiC_ANNUAL_MILEAGE: '',
        maximuM_MILEAGE_LIMIT: '',
        firsT_EXCESS_MILEAGE: '',
        firsT_EXCESS_MILEAGE_PENCE_PER_MILE: '',
        seconD_EXCESS_MILEAGE: '',
        seconD_EXCESS_MILEAGE_PENCE_PER_MILE: '',
        thirD_EXCESS_MILEAGE: '',
        thirD_EXCESS_MILEAGE_PENCE_PER_MILE: '',
        additionaL_EXCESS_MILEAGE_PENCE_PER_MILE: '',
        roaD_SIDE_ASSISTANCE: '',
        roaD_SIDE_ASSISTANCE_AMOUNT: '',
        roaD_SIDE_ASSISTANCE_PROVIDER: '',
        extrA_FINANCE_EXPEND_AMT: '',
        accidenT_MANAGEMENT: '',
        accidenT_MANAGEMENT_PROVIDER: ''
      },
      hireR_DETAILS: {
        address: {
          facetoface: '',
          title: '',
          forename: '',
          secondname: '',
          surname: '',
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mnths: '',
          telephone: '',
          mobile: '',
          email: ''
        },
        prevaddr: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR2: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR3: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR4: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        personal: {
          dateofbirth: '',
          gender: '',
          marital: '',
          dependants: '',
          residential: '',
          driverlicence: '',
          mothersmaidenname: '',
          countryofbirth: '',
          nationality: ''
        },
        occupation: {
          occupation: '',
          occupationcode: '',
          status: '',
          employer: '',
          addresS1: '',
          addresS2: '',
          addresS3: '',
          employertelephone: '',
          yrs: 0,
          mths: ''
        },
        prevoccupation: {
          occupation: '',
          occupationcode: '',
          status: '',
          employer: '',
          addresS1: '',
          addresS2: '',
          addresS3: '',
          employertelephone: '',
          yrs: 0,
          mths: ''
        },
        bank: {
          sortcode: '',
          accountname: '',
          accountno: '',
          bankname: '',
          street: '',
          locality: '',
          town: '',
          yrs: '',
          mths: '',
          chequE_CARD: '',
          visA_CARD: '',
          mastercard: '',
          amex: '',
          other: '',
          payementmethod: '',
          payfundsby: ''
        },
        income: {
          netmonthly: '',
          netweekly: '',
          grossannual: '',
          changeindicator: '',
          other: '',
          salarymandated: '',
          salarydate: '',
          mortgagemth: '',
          mortgagebalance: '',
          housevalue: '',
          rent: '',
          counciltaX_RATES: '',
          loanshpmth: '',
          loanshpbal: '',
          creditcardmth: '',
          creditcardbal: '',
          overdraftmth: '',
          overdraftbal: '',
          overdraftfacility: '',
          othermth: '',
          otherbal: ''
        }
      },
      jointhireR_DETAILS: {
        address: {
          facetoface: '',
          title: '',
          forename: '',
          secondname: '',
          surname: '',
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: 0,
          yrs: 0,
          mnths: '',
          telephone: '',
          mobile: '',
          email: ''
        },
        prevaddr: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR2: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR3: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR4: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        personal: {
          dateofbirth: '',
          gender: '',
          marital: '',
          dependants: '',
          residential: '',
          driverlicence: '',
          mothersmaidenname: '',
          countryofbirth: '',
          nationality: ''
        },
        occupation: {
          occupation: '',
          occupationcode: '',
          status: '',
          employer: '',
          addresS1: '',
          addresS2: '',
          addresS3: '',
          employertelephone: '',
          yrs: 0,
          mths: ''
        },
        prevoccupation: {
          occupation: '',
          occupationcode: '',
          status: '',
          employer: '',
          addresS1: '',
          addresS2: '',
          addresS3: '',
          employertelephone: '',
          yrs: 0,
          mths: ''
        },
        bank: {
          sortcode: '',
          accountname: '',
          accountno: '',
          bankname: '',
          street: '',
          locality: '',
          town: '',
          yrs: 0,
          mths: 0,
          chequE_CARD: '',
          visA_CARD: '',
          mastercard: '',
          amex: '',
          other: '',
          payementmethod: '',
          payfundsby: ''
        },
        income: {
          netmonthly: '',
          netweekly: '',
          grossannual: '',
          changeindicator: '',
          other: '',
          salarymandated: '',
          salarydate: '',
          mortgagemth: '',
          mortgagebalance: '',
          housevalue: '',
          rent: '',
          counciltaX_RATES: '',
          loanshpmth: '',
          loanshpbal: '',
          creditcardmth: '',
          creditcardbal: '',
          overdraftmth: '',
          overdraftbal: '',
          overdraftfacility: '',
          othermth: '',
          otherbal: ''
        }
      },
      guarantoR_DETAILS: {
        address: {
          facetoface: '',
          title: '',
          forename: '',
          secondname: '',
          surname: '',
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: 0,
          yrs: 0,
          mnths: '',
          telephone: '',
          mobile: '',
          email: ''
        },
        prevaddr: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR2: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR3: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        prevaddR4: {
          houseno: '',
          apartno: '',
          housename: '',
          postcode: '',
          street: '',
          locality: '',
          posttown: '',
          county: '',
          country: '',
          yrs: 0,
          mths: ''
        },
        personal: {
          dateofbirth: '',
          gender: '',
          marital: '',
          dependants: '',
          residential: '',
          driverlicence: '',
          mothersmaidenname: '',
          countryofbirth: '',
          nationality: ''
        },
        occupation: {
          occupation: '',
          occupationcode: '',
          status: '',
          employer: '',
          addresS1: '',
          addresS2: '',
          addresS3: '',
          employertelephone: '',
          yrs: 0,
          mths: ''
        },
        prevoccupation: {
          occupation: '',
          occupationcode: '',
          status: '',
          employer: '',
          addresS1: '',
          addresS2: '',
          addresS3: '',
          employertelephone: '',
          yrs: 0,
          mths: ''
        },
        bank: {
          sortcode: '',
          accountname: '',
          accountno: '',
          bankname: '',
          street: '',
          locality: '',
          town: '',
          yrs: '',
          mths: '',
          chequE_CARD: '',
          visA_CARD: '',
          mastercard: '',
          amex: '',
          other: '',
          payementmethod: '',
          payfundsby: ''
        },
        income: {
          netmonthly: '',
          netweekly: '',
          grossannual: '',
          changeindicator: '',
          other: '',
          salarymandated: '',
          salarydate: '',
          mortgagemth: '',
          mortgagebalance: '',
          housevalue: '',
          rent: '',
          counciltaX_RATES: '',
          loanshpmth: '',
          loanshpbal: '',
          creditcardmth: '',
          creditcardbal: '',
          overdraftmth: '',
          overdraftbal: '',
          overdraftfacility: '',
          othermth: '',
          otherbal: ''
        }
      },
      business: {
        businesS_TYPE: '',
        businesS_DETAILS: {
          tradinG_NAME: '',
          businesS_DESCRIPTION: '',
          yearS_TRADING: 0,
          vaT_NO: '',
          numbeR_OF_PARTNERS: '',
          companY_REGNO: '',
          parenT_COMPANY: '',
          contacT_NAME: '',
          tradinG_ADDRESS: {
            houseno: '',
            apartno: '',
            housename: '',
            postcode: '',
            street: '',
            locality: '',
            posttown: '',
            county: '',
            country: 0,
            yrs: 0,
            mnths: '',
            telephone: ''
          },
          tradinG_PREVADDR: {
            houseno: '',
            apartno: '',
            housename: '',
            postcode: '',
            street: '',
            locality: '',
            posttown: '',
            county: '',
            country: 0,
            yrs: 0,
            mths: ''
          },
          businesS_BANK: {
            sortcode: '',
            accountname: '',
            accountno: '',
            bankname: '',
            street: '',
            locality: '',
            town: '',
            yrs: 0,
            mths: 0,
            chequE_CARD: '',
            visA_CARD: '',
            mastercard: '',
            amex: '',
            other: '',
            payementmethod: '',
            payfundsby: ''
          }
        }
      },
      supplieR_DETAILS: {
        supplier: '',
        addresS1: '',
        posttown: '',
        postcode: '',
        telephone: '',
        status: '',
        detailstofollow: ''
      },
      agrI_DETAILS: {
        faD_HAS_HORSES: '',
        faD_HAS_PIGS: '',
        faD_HAS_POULTRY: '',
        faD_HAS_HORTICULTURAL: '',
        faD_HAS_FRUIT: '',
        faD_HAS_HAULAGE: '',
        faD_HAS_BEEF: '',
        faD_HAS_SHEEP: '',
        faD_HAS_DAIRY: '',
        faD_HAS_VEGETABLES: '',
        faD_IS_CONTRACTOR: '',
        faD_IS_DEALER: '',
        faD_OTHER_CATEGORY_DESC: '',
        faD_NO_ACRES_OWNED: '',
        faD_NO_ACRES_RENTED: '',
        faD_NO_ACRES_SHARED_FARMED: '',
        faD_NO_ACRES_CONTRACT_FARMED: '',
        faD_VALUE_PER_ACRE: '',
        faD_TENANCY_REMAINING: '',
        faD_LANDLORD_NAME: '',
        faD_LAND_GRADE: '',
        faD_ACRES_WHEAT: '',
        faD_ACRES_BARLEY: '',
        faD_ACRES_OILSEEDS: '',
        faD_ACRES_PEAS_BEANS: '',
        faD_ACRES_POTATOES: '',
        faD_ACRES_SUGAR_BEET: '',
        faD_ACRES_OTHER_ARABLE: '',
        faD_ACRES_LEYS: '',
        faD_ACRES_PERMANENT_PASTURE: '',
        faD_ACRES_ROUGH_GRAZING: '',
        faD_ACRES_OTHER: '',
        faD_NO_DAIRY_COWS: '',
        faD_NO_DAIRY_REPLACEMENTS: '',
        faD_AMT_MILK_QUOTA_OWNED: '',
        faD_AMT_MILK_QUOTA_LEASED: '',
        faD_AVG_YIELD_PER_COW: '',
        faD_AVG_MONTHLY_MILK_CHQ: '',
        faD_NO_BEEF_SUCKLERS: '',
        faD_NO_BEEF_FATTENERS: '',
        faD_NO_EWES: '',
        faD_NO_FATTENING_LAMBS: '',
        faD_NO_PIGS_SOWS: '',
        faD_NO_PIGS_WEANERS: '',
        faD_NO_PIGS_FATTENERS: '',
        faD_NO_POULTRY_BROILERS: '',
        faD_NO_POULTRY_LAYERS: '',
        faD_NO_POULTRY_OTHERS: '',
        faD_SUBSIDIES_PER_ANNUM: '',
        faD_TOT_VALUE: '',
        faD_ACRES_SET_ASIDE: ''
      }
    }
  }
};

export default NorthridgeProposal;
