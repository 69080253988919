import { FunderDoc } from '../../interfaces';
import { Calculation } from 'types/calculatorInterfaces';
import FunderFacility from './Facility';
import FunderFinanceCharges from './FinanceCharges';
import FunderTermStructure from './TermStructure';
import FunderInstalment from './Instalment';
import FunderCommissionInvoice from './CommissionInvoice';

export const funderDocCalculations: (Calculation: Calculation) => FunderDoc = (
  Output: Calculation
) => {
  const FUNDER_DOC: FunderDoc = INIT_FUNDER_DOC;

  /** FINANCE PRODUCT */
  FUNDER_DOC.FINANCE_PRODUCT = Output?.FinanceProduct;
  FUNDER_DOC.INITIAL_RENTALS = Output?.InitialRentals;

  /* FACILITY */
  FunderFacility({ FUNDER_DOC, Output });

  /* FINANCE CHARGES */
  FunderFinanceCharges({ FUNDER_DOC, Output });

  /* TERM STRUCTURE */
  FunderTermStructure({ FUNDER_DOC, Output });

  /* RATES & RETURNS */
  FUNDER_DOC.MARGIN = Output?.MarginRate;
  FUNDER_DOC.YIELD = Output?.Yield;
  FUNDER_DOC.APR = Output?.APR;
  FUNDER_DOC.FLAT_RATE = Output?.FlatRate;
  FUNDER_DOC.NOMINAL_FLAT_RATE = Output?.NominalFlatRate;
  FUNDER_DOC.RATE_PER_ANNUM = Output?.RateOfInterestPerAnnum;
  FUNDER_DOC.FUNDING_RATE = Output?.FundingRate;

  /** INSTALMENT */
  FunderInstalment({ FUNDER_DOC, Output });

  /** COMMISSION INVOICE */
  FunderCommissionInvoice({ FUNDER_DOC, Output });

  return FUNDER_DOC;
};

const INIT_FUNDER_DOC: FunderDoc = {
  VAT_DEFERRAL_PERIOD: 0,
  VAT_DEFERRAL_AMOUNT: 0,
  COST_EXCL_VAT_AND_NON_VATABLE_ITEMS: 0,
  VAT: 0,
  VAT_UPFRONT: false,
  NON_VATABLE_ITEMS: 0,
  I_COST_INC_VAT: 0,
  II_ADVANCE_REPAYMENTS: 0,
  III_ADDITIONAL_REPAYMENT: 0,
  IV_NET_PART_EXCHANGE_EXCL_VAT: 0,
  V_VAT_ON_NET_PART_EXCHANGE: 0,
  VI_NET_VAT_PAID_UPFRONT: 0,
  TOTAL_INIT_PAYMENT: 0,
  AMOUNT_TO_FINANCE: 0,
  REPAYMENTS: '',

  INTEREST_CHARGES_AMOUNT: 0,
  INTEREST_CHARGES_VAT: 0,
  INTEREST_CHARGES_TOTAL: 0,
  DOCUMENTATION_FEE_AMOUNT: 0,
  DOCUMENTATION_FEE_VAT: 0,
  DOCUMENTATION_FEE_TOTAL: 0,
  OPTION_TO_PURCHASE_AMOUNT: 0,
  OPTION_TO_PURCHASE_VAT: 0,
  OPTION_TO_PURCHASE_TOTAL: 0,
  TOTAL_CHARGE_FOR_FINANCE_AMOUNT: 0,
  TOTAL_CHARGE_FOR_FINANCE_VAT: 0,
  TOTAL_CHARGE_FOR_FINANCE_TOTAL: 0,
  TOTAL_AMOUNT_PAYABLE_INC_VAT: 0,

  HIRE_PERIOD: 0,
  FUNDING_PROFILE: 0,
  DOCUMENTATION_FEE_PAYABLE: '',

  MARGIN: 0,
  YIELD: 0,
  APR: 0,
  FLAT_RATE: 0,
  FUNDING_RATE: 0,
  NOMINAL_FLAT_RATE: 0,
  RATE_PER_ANNUM: 0,

  INITIAL_RENTALS: 0,
  INSTALMENTS_NUMBER: 0,
  INSTALMENTS_TYPE: '',
  INSTALMENTS_AMOUNT: 0,
  INSTALMENTS_DATE: '',
  HALF_AMOUNT_PAYABLE: 0,
  THIRD_AMOUNT_PAYABLE: 0,
  OVERRIDE_PAYMENTS: [],

  VAT_PAID_UPFRONT_AMOUNT: 0,
  VAT_PAID_UPFRONT_VAT: 0,
  VAT_PAID_UPFRONT_TOTAL: 0,

  ADVANCE_PAYMENTS_AMOUNT: 0,
  ADVANCE_PAYMENTS_VAT: 0,
  ADVANCE_PAYMENTS_TOTAL: 0,

  ADDITIONAL_DEPOSIT_AMOUNT: 0,
  ADDITIONAL_DEPOSIT_VAT: 0,
  ADDITIONAL_DEPOSIT_TOTAL: 0,

  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_AMOUNT: 0,
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_VAT: 0,
  PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL: 0,

  I_DOCUMENT_FEE_STANDALONE_AMOUNT: 0,
  I_DOCUMENT_FEE_STANDALONE_VAT: 0,
  I_DOCUMENT_FEE_STANDALONE_TOTAL: 0,
  II_DOCUMENT_FEE_UPSELL_AMOUNT: 0,
  II_DOCUMENT_FEE_UPSELL_VAT: 0,
  II_DOCUMENT_FEE_UPSELL_TOTAL: 0,
  II_TOTAL_DOCUMENT_FEE_AMOUNT: 0,
  II_TOTAL_DOCUMENT_FEE_VAT: 0,
  II_TOTAL_DOCUMENT_FEE_TOTAL: 0,
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_AMOUNT: 0,
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_VAT: 0,
  DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_TOTAL: 0,
  GROSS_COMMISSION_AMOUNT: 0,
  GROSS_COMMISSION_VAT: 0,
  GROSS_COMMISSION_TOTAL: 0,
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT: 0,
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_VAT: 0,
  GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_TOTAL: 0,
  INTRODUCER_FEE_AMOUNT: 0,
  INTRODUCER_FEE_VAT: 0,
  INTRODUCER_FEE_TOTAL: 0,
  NET_COMMISSION_AMOUNT: 0,
  NET_COMMISSION_VAT: 0,
  NET_COMMISSION_TOTAL: 0,

  BALLOON: 0,
  CONTRACT_PERIOD: 0,
  FUNDING_PROFILE_DISPLAY: '',
  SETTLEMENT_FIGURE: 0,
  FINANCE_PRODUCT: '',
  SUBSIDY: 0
};
