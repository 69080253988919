import { Text, View } from '@react-pdf/renderer';

export const CommissionInvoice = ({ state, Currency, TableStyle, styles }) => {
  const isLease = (product) => product.toLowerCase().startsWith('lease');

  return (
    <div>
      <View style={TableStyle.table}>
        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cell]} />
          <Text style={[TableStyle.cell]} />
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.cell, styles.tableTitleText]}>
                Amount
              </Text>
              <Text style={[TableStyle.cellMiddleAlone, styles.tableTitleText]}>
                VAT
              </Text>
              <Text style={[TableStyle.cellRight, styles.tableTitleText]}>
                Total
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight, styles.tableTitleText]}>
              Amount
            </Text>
          )}
        </View>

        <div style={styles.line} />

        <View style={[TableStyle.row, TableStyle.greyBackground]}>
          <Text style={[TableStyle.cellLeft]}>
            (i) Documentation Fee, Standalone
          </Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.I_DOCUMENT_FEE_STANDALONE_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.I_DOCUMENT_FEE_STANDALONE_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.I_DOCUMENT_FEE_STANDALONE_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.I_DOCUMENT_FEE_STANDALONE_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cellLeft]}>
            (ii) Documentation Fee, Upsell
          </Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.II_DOCUMENT_FEE_UPSELL_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.II_DOCUMENT_FEE_UPSELL_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.II_DOCUMENT_FEE_UPSELL_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.II_DOCUMENT_FEE_UPSELL_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row, TableStyle.greyBackground]}>
          <Text style={[TableStyle.cellLeft]}>
            Total Documentation Fee, (i) + (ii)
          </Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.II_TOTAL_DOCUMENT_FEE_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.II_TOTAL_DOCUMENT_FEE_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.II_TOTAL_DOCUMENT_FEE_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.II_TOTAL_DOCUMENT_FEE_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row, TableStyle.greyBackground]}>
          <Text style={[TableStyle.cellLeft]}>Gross Commission</Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.GROSS_COMMISSION_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.GROSS_COMMISSION_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.GROSS_COMMISSION_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.GROSS_COMMISSION_AMOUNT || 0} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cellLeft]}>
            Gross Commission + Total Documentation Fee
          </Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency
                  value={
                    state.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT
                  }
                />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency
                  value={
                    state.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_VAT
                  }
                />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency
                  value={
                    state.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_TOTAL
                  }
                />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency
                value={
                  state.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT ||
                  0
                }
              />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row, TableStyle.greyBackground]}>
          <Text style={[TableStyle.cellLeft]}>Introducer Fee</Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.INTRODUCER_FEE_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.INTRODUCER_FEE_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.INTRODUCER_FEE_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.INTRODUCER_FEE_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cellLeft]}>Net Commission</Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.NET_COMMISSION_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.NET_COMMISSION_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.NET_COMMISSION_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.NET_COMMISSION_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>
      </View>
    </div>
  );
};
