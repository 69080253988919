import React, { useContext } from 'react';
import {
  FieldDefinitionDict,
  FieldDefinition,
  ObjectDefinition,
  CompleteObjectInstance,
  FieldInstance,
  CompleteUserInstance,
  UserInstance
} from 'types/interfaces';
import { useDispatch } from 'react-redux';
import { useProcess } from 'hooks';
import {
  InviteToGDPR,
  InviteToConsent,
  DeleteGDPRContact,
  NewContactAutomation
} from 'redux/database/Custom API';
import {
  Grid,
  Checkbox,
  useTheme,
  Button,
  Tooltip,
  CircularProgress,
  makeStyles,
  createStyles,
  Theme,
  Divider,
  Box,
  DialogContent,
  DialogActions,
  Typography
} from '@material-ui/core';
import QuickUser from 'components/User/UserLozenge';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import { errorNotif, successNotif } from 'components/Notifications';
import { createNotification } from 'react-redux-notify';
import { NewGdprContactDialog } from './NewGdprContactDialog';
import { StepperContext } from 'components/Stepper/context';
import { firebase } from 'redux/firebase';
import CachedIcon from '@material-ui/icons/Cached';
import { getUserInstanceWithObjects } from 'redux/actions/GraphQlActions';
import { useTypedSelector } from 'redux/reducers';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { Alert, AlertTitle } from '@material-ui/lab';
import { current } from '@reduxjs/toolkit';
import { IOptionType, StyledSelect } from 'common/StyledSelect';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import { CustomDialog } from 'common/Dialog';
import { Check, People } from '@material-ui/icons';
import GDPRRequestAlert from './components/GDPRRequestAlert';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    newBox: {
      height: '100%',
      minHeight: '35px',
      width: '100%',
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      margin: `4px 0px`
    },
    divider: {
      height: 25,
      width: 1,
      color: 'grey',
      margin: `0px 10px`
    },
    ListGridItem: {
      height: '100%',
      minHeight: '35px',
      width: '100%',
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,

      margin: `4px 0px`
    },
    TypeBox: {
      position: 'relative',
      // border: `1px ${theme.palette.grey[300]} solid`,
      display: 'flex',
      alignItems: 'center',
      // margin: 20,
      borderRadius: theme.shape.borderRadius
    },
    TypeBoxTitle: {
      // position: 'absolute',
      // top: 0,
      background: theme.palette.background.default,
      padding: `0px 2px`
    },

    proceedButton: {
      backgroundColor: (props: { isChecked: boolean }) =>
        props.isChecked ? '#4CAF50' : theme.palette.grey[300],
      color: (props: { isChecked: boolean }) =>
        props.isChecked
          ? theme.palette.common.white
          : theme.palette.text.secondary,
      '&:hover': {
        backgroundColor: (props: { isChecked: boolean }) =>
          props.isChecked ? '#45a049' : theme.palette.grey[400]
      }
    }
  })
);

interface Deal {
  GDPRSent: number[];
  ConsentSent: number[];
}

interface IProps {
  FieldDefinitionDict: FieldDefinitionDict;
  FieldDefinitionList: FieldDefinition[];
  ObjectDefinition: ObjectDefinition;
  ObjectDefinitionIdList: number[];
  ContactsFieldDefinitionIdToGet: number[];
}
interface IState {
  UserDefinitionId: string;
  UserInstanceId: string;
  type: string;
}
interface IOptionState {
  title: keyof IState;
  value: string | number;
}
const INIT_STATE = {
  UserDefinitionId: '',
  UserInstanceId: '',
  type: 'two-way'
};

const GDPRContacts = ({
  ObjectDefinition,
  ObjectDefinitionIdList,
  ContactsFieldDefinitionIdToGet
}: IProps) => {
  const theme = useTheme();
  const stepper = useContext(StepperContext);
  const dispatch = useDispatch();
  const { currentDeal, user, currentStep } = useProcess();
  const { userList } = useTypedSelector((s) => s.userAPI);
  const { token } = useTypedSelector((s) => s.user.auth);
  const subSystemUser = user.SystemAccess <= 4;

  const baseUrl = useTypedSelector((s) => s.config.baseURL);
  const UserDefinitions = useTypedSelector(
    (state) => state.config.settings.UserDefinitionList
  );
  const [contactIds, setContactIds] = React.useState<string[]>([]);
  const [contacts, setContacts] = React.useState<
    Partial<CompleteUserInstance>[]
  >([]);

  const [tempUser, setTempUser] = React.useState<number | string | undefined>(
    undefined
  );
  const [deal, setDeal] = React.useState<Deal>({} as Deal);
  const [open, setOpen] = React.useState(false);
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [optionsDef, setOptionsDef] = React.useState<IOptionType[]>([]);
  const [optionsIns, setOptionsIns] = React.useState<IOptionType[]>([]);
  const [state, setState] = React.useState<IState>(INIT_STATE);

  const classes = useStyles({ isChecked: !state.UserInstanceId });

  const { UserInstanceListForCurrentStep } = currentStep;
  const Customer = UserInstanceListForCurrentStep[0];

  React.useEffect(() => {
    let list: IOptionType[] = [];
    if (UserDefinitions) {
      UserDefinitions.forEach(({ Id, Title }) =>
        list.push({ value: Id, label: Title })
      );
    }
    const filteredList = list.filter(({ label }) =>
      subSystemUser
        ? ['Customers', 'Introducer', 'Suppliers'].includes(label)
        : ['Contacts', 'Introducer', 'Suppliers'].includes(label)
    );
    setOptionsDef(filteredList);
    return () => setState(INIT_STATE);
  }, [UserDefinitions]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = ({ title, value }: IOptionState) => {
    setState({ ...state, [title]: value });
  };

  const getData = async () => {
    if (state.UserDefinitionId !== '') {
      setLoadingCreate(true);
      const { UserDefinitionId } = state;
      await getUserInstanceSummaryList({
        token,
        UserDefinitionId
      });
      let optionsIns: IOptionType[] = [];
      const selectedList = userList[UserDefinitionId][UserDefinitionId];
      selectedList.forEach(({ UserInstance: { Id, Title } }) => {
        optionsIns.push({ value: Id, label: Title });
      });
      setOptionsIns(optionsIns);
      setLoadingCreate(false);
    }
  };

  React.useEffect(() => {
    setOptionsIns([]);
    getData();
  }, [state.UserDefinitionId]);

  const listAllUsers = () => {
    const tempContactIds = [] as string[];
    ObjectInstances.forEach(
      (CompleteObjectInstance: CompleteObjectInstance) => {
        const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
        FieldInstanceList.find((FieldInstance: FieldInstance) => {
          if (
            ContactsFieldDefinitionIdToGet.includes(
              FieldInstance.FieldDefinitionId
            )
          ) {
            tempContactIds.push(FieldInstance.FieldValue);
          }
        });
      }
    );
    setContactIds(tempContactIds);
  };

  const fetchExistingContacts = async () => {
    if (contactIds.length > 0) {
      const data = {
        UserInstanceIdList: contactIds,
        ObjectDefinitionIdList
      };
      const result = await getUserInstanceWithObjects({
        baseUrl,
        ProcessInstanceId: currentDeal.ProcessInstance.Id,
        data
      });
      if (result) {
        setContacts(result);
      }
    }
  };

  React.useEffect(() => {
    if (contactIds.length > 0) fetchExistingContacts();
  }, [contactIds, ObjectDefinition.Id]);

  const handleAddContact = async () => {
    setLoading(true);
    const data: UserInstance = createNewUserInstance();
    data.Id = parseInt(state.UserInstanceId);
    data.LastModified = `${new Date().toISOString()}`;
    if (data) {
      const res = await NewContactAutomation({
        PartiesAndContacts: false,
        data,
        ProcessInstanceId: currentDeal.ProcessInstance.Id,
        PrimaryUserInstanceId: Customer.UserInstanceId
      });

      if (res.status === 200) {
        setTempUser(data.Id);
        setOpen(false);
        setLoading(false);

        await stepper.refreshDealData();
      }
    }
  };

  const ObjectInstances: CompleteObjectInstance[] = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).filter(
    (el: CompleteObjectInstance) =>
      el.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
  );

  const getAllContactsData = async (contactIds: string[]) => {
    const data = {
      UserInstanceIdList: contactIds,
      ObjectDefinitionIdList
    };

    const result = await getUserInstanceWithObjects({
      baseUrl,
      ProcessInstanceId: currentDeal.ProcessInstance.Id,
      data
    });

    if (result) {
      setContacts(result);
    }
  };

  const send: (
    UserInstance: UserInstance,
    type: 'GDPR' | 'Consent'
  ) => Promise<boolean | undefined> = async (UserInstance, type) => {
    if (type === 'GDPR') return await sendGDPR(UserInstance);
    if (type === 'Consent') return await sendConsent(UserInstance);
  };

  const sendGDPR = async (UserInstance: UserInstance) => {
    const res = await InviteToGDPR({
      UserInstanceId: UserInstance.Id,
      ProcessInstanceId: currentDeal.ProcessInstance.Id
    });
    if (res.status === 200) {
      dispatch(
        createNotification(
          successNotif(`GDPR request sent to ${UserInstance.UserInstanceEmail}`)
        )
      );
      // Mark the user here as having been sent the GDPR?
      const ref = firebase
        .firestore()
        .collection('deal')
        .doc(currentDeal.ProcessInstance.Id.toString());

      // When sending GDPR we attach the ID of the User to the Consent as well.
      ref.update({
        GDPRSent: firebase.firestore.FieldValue.arrayUnion(UserInstance.Id),
        ConsentSent: firebase.firestore.FieldValue.arrayUnion(UserInstance.Id)
      });
      getFbDeal();
      return true;
    } else {
      dispatch(createNotification(errorNotif(`GDPR Not sent`)));
      return false;
    }
  };

  const sendConsent = async (UserInstance: UserInstance) => {
    const res = await InviteToConsent({
      UserInstanceId: UserInstance.Id,
      ProcessInstanceId: currentDeal.ProcessInstance.Id
    });
    if (res.status === 200) {
      dispatch(
        createNotification(
          successNotif(
            `Consent request sent to ${UserInstance.UserInstanceEmail}`
          )
        )
      );
      // Mark the user here as having been sent the Consent
      const ref = firebase
        .firestore()
        .collection('deal')
        .doc(currentDeal.ProcessInstance.Id.toString());
      ref.update({
        ConsentSent: firebase.firestore.FieldValue.arrayUnion(UserInstance.Id)
      });
      getFbDeal();
      return true;
    } else {
      dispatch(createNotification(errorNotif(`Consent not sent`)));
      return false;
    }
  };

  const getFbDeal = () => {
    const ref = firebase
      .firestore()
      .collection('deal')
      .doc(currentDeal.ProcessInstance.Id.toString());
    ref
      .get()
      .then((doc: firebase.firestore.DocumentData) => setDeal(doc.data()));
  };

  const deleteUser = async (UserInstance: UserInstance) => {
    const CompleteObjectInstance: CompleteObjectInstance | undefined =
      ObjectInstances.find((CompleteObjectInstance: CompleteObjectInstance) => {
        const FieldInstanceList = getFieldInstances(CompleteObjectInstance);

        const found = FieldInstanceList.find((FieldInstance: FieldInstance) => {
          if (FieldInstance.FieldValue === UserInstance.Id.toString()) {
            return FieldInstance.ObjectInstanceId;
          }
        });

        return found;
      });

    if (CompleteObjectInstance) {
      const res = await DeleteGDPRContact({
        UserInstanceId: UserInstance.Id,
        ProcessInstanceId: currentDeal.ProcessInstance.Id,
        ObjectInstanceId: CompleteObjectInstance.ObjectInstance.Id
      });

      if (res.status === 200) return stepper.refreshDealData();
      return;
    }
  };

  React.useEffect(() => {
    listAllUsers();
    getFbDeal();
  }, [currentDeal]);

  React.useEffect(() => {
    if (contactIds.length > 0) getAllContactsData(contactIds);
  }, [contactIds, ObjectDefinition.Id]);

  const dealCondition = stepper.isDealClosed || stepper.isDealTransferred;
  return (
    <>
      <Grid
        style={{ padding: theme.spacing(1) }}
        className={`${dealCondition ? classes.newBox : ''}`}>
        <Grid item>
          <Button
            fullWidth
            onClick={handleOpen}
            size="small"
            style={{
              color: 'white',
              backgroundColor: theme.palette.primary.light
            }}
            variant="contained">
            Add / Create GDPR Contact
          </Button>
        </Grid>
        {tempUser !== undefined && (
          <Grid item style={{ paddingTop: theme.spacing(1) }}>
            <GDPRRequestAlert username={tempUser} />
          </Grid>
        )}
      </Grid>

      <CustomDialog
        maxSize="sm"
        open={open}
        handleClose={handleClose}
        alert={{
          title: 'Add an Existing Contact',
          description:
            'Select from the dropdown below to add an existing contact to the GDPR Contacts.',
          type: 'info'
        }}>
        <DialogContent>
          <NewGdprContactDialog
            contactIds={contactIds}
            setDialogOpen={setOpen}
            setTempUser={setTempUser}
          />
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Box
                width={'100%'}
                onMouseDown={(event) => {
                  event.preventDefault();
                }}>
                <StyledSelect
                  label="User Definition"
                  value={state.UserDefinitionId}
                  onChange={(event) =>
                    handleChange({
                      title: 'UserDefinitionId',
                      value: event.target.value
                    })
                  }
                  options={optionsDef}
                  useMaterialUI
                />
              </Box>
            </Grid>
            {state.UserDefinitionId !== '' && (
              <Grid item>
                <Box width={'100%'}>
                  <StyledSelect
                    label="User Instance"
                    value={state.UserInstanceId}
                    onChange={(event) =>
                      handleChange({
                        title: 'UserInstanceId',
                        value: event.target.value
                      })
                    }
                    options={optionsIns}
                    loading={loadingCreate}
                    useMaterialUI
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddContact}
            style={{
              backgroundColor: !state.UserInstanceId
                ? theme.palette.grey[300]
                : theme.palette.success.main,
              color: !state.UserInstanceId
                ? theme.palette.text.secondary
                : theme.palette.common.white
            }}
            disabled={!state.UserInstanceId}
            startIcon={loading ? <CircularProgress size={20} /> : <People />}>
            {loading ? 'Adding Existing Contact...' : 'Add Existing Contact'}
          </Button>
        </DialogActions>
      </CustomDialog>
      <Grid container direction="column">
        {contacts &&
          [...contacts]
            .sort(
              (
                a: Partial<CompleteUserInstance>,
                b: Partial<CompleteUserInstance>
              ) => {
                const statusA = GDPRstatus(
                  a?.CompleteObjectInstanceList ||
                    ([] as CompleteObjectInstance[])
                );
                let numA = statusA ? 1 : 0;

                const statusB = GDPRstatus(
                  b?.CompleteObjectInstanceList ||
                    ([] as CompleteObjectInstance[])
                );
                let numB = statusB ? 1 : 0;

                return numB - numA;
              }
            )
            .map((item: Partial<CompleteUserInstance>) => {
              // Handle number comparison
              if (typeof tempUser === 'number') {
                if (item.UserInstance?.Id.toString() === tempUser.toString()) {
                  setTempUser(undefined);
                }
              }

              // Handle string comparison
              if (typeof tempUser === 'string') {
                if (item.UserInstance?.Title === tempUser) {
                  setTempUser(undefined);
                }
              }

              return (
                <ListItem
                  key={item.UserInstance?.Id}
                  UserInstance={item?.UserInstance || ({} as UserInstance)}
                  send={send}
                  deleteUser={deleteUser}
                  item={item?.CompleteObjectInstanceList || []}
                  deal={deal}
                />
              );
            })}
      </Grid>
    </>
  );
};

const createNewUserInstance = () => {
  const obj: UserInstance = {
    ConsoleUserInstanceId: 0,
    DefaultMessage: '',
    DocumentFooter: '',
    DocumentHeader: '',
    EmailFooter: '',
    FromAddress: '',
    FromName: '',
    Id: 0,
    IsPublished: false,
    ItemOrder: 0,
    LastModified: '',
    LogoURL: '',
    NickName: '',
    OwnerUserInstanceId: 0,
    ProfilePicture: '',
    ProxyUserInstanceId: 0,
    RelatedUserPermission: 0,
    RelationshipStatus: 0,
    SmtpHost: '',
    SmtpPassword: '',
    SmtpPort: 0,
    SmtpSSL: false,
    SmtpUsername: '',
    SummaryFields: undefined,
    SystemAccess: 0,
    TelephoneNumber: '',
    ThirdPartyId: '',
    Title: '',
    UserDefinitionId: 0,
    UserInstanceEmail: '',
    UserInstancePassword: ''
  };
  return obj;
};

export default GDPRContacts;

const ListItem = ({
  item,
  UserInstance,
  send,
  deleteUser,
  deal
}: {
  item: CompleteObjectInstance[];
  UserInstance: UserInstance;
  send: (
    UserInstance: UserInstance,
    type: 'GDPR' | 'Consent'
  ) => Promise<boolean | undefined>;
  deleteUser: (UserInstance: UserInstance) => void;
  deal: Deal;
}) => {
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const { currentDeal } = useTypedSelector((s) => s.process);
  const [loadingGDPR, setLoadingGDPR] = React.useState(false);
  const [loadingConsent, setLoadingConsent] = React.useState(false);

  const classes = useStyles({ isChecked: false });
  const theme = useTheme();
  const [deleting, setDeleting] = React.useState(false);

  const hasGDPR: boolean = GDPRstatus(item);
  const hasBeenSentGDPR: boolean = deal?.GDPRSent?.includes(UserInstance.Id);

  const hasConsent = ConsentStatus(item);
  const hasBeenSentConsent: boolean = deal?.ConsentSent?.includes(
    UserInstance.Id
  );
  const isCTSDisabled =
    currentDeal?.ProcessInstance?.ProcessDefinitionId === 542;

  const ShowGDPRCheckBox = () => (
    <Checkbox
      name="gdpr_checkbox"
      disabled
      checked={hasGDPR}
      style={{
        color: hasGDPR ? theme.palette.success.main : theme.palette.error.main
      }}
    />
  );

  const ShowConsentCheckbox = () => (
    <Checkbox
      name="consent_checkbox"
      disabled
      checked={hasConsent}
      style={{
        color: isCTSDisabled
          ? theme.palette.grey[500]
          : hasConsent
          ? theme.palette.success.main
          : theme.palette.error.main
      }}
    />
  );

  const ShowSendIcon = () => (
    <CachedIcon style={{ color: theme.palette.warning.main, marginLeft: 8 }} />
  );

  const handleSend = async (
    UserInstance: UserInstance,
    type: 'GDPR' | 'Consent'
  ) => {
    if (type === 'GDPR') {
      setLoadingGDPR(true);
      await send(UserInstance, type);
      setLoadingGDPR(false);
    }
    if (type === 'Consent') {
      setLoadingConsent(true);
      await send(UserInstance, type);
      setLoadingConsent(false);
    }
  };

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <Grid
      item
      key={UserInstance.Id}
      className={classes.ListGridItem}
      style={{ borderTopLeftRadius: 17, borderBottomLeftRadius: 17 }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item style={{ minWidth: 300 }}>
          <QuickUser user={UserInstance} />
        </Grid>

        <Divider className={classes.divider} />

        {loadingGDPR ? (
          <Grid item className={classes.TypeBox}>
            <CircularProgress size={20} />
          </Grid>
        ) : (
          <Grid item className={classes.TypeBox}>
            <div className={classes.TypeBoxTitle}>GDPR</div>
            {hasBeenSentGDPR ? (
              hasGDPR ? (
                <ShowGDPRCheckBox />
              ) : (
                <ShowSendIcon />
              )
            ) : (
              <ShowGDPRCheckBox />
            )}

            {!hasGDPR && !dealCondition && (
              <Tooltip title={hasBeenSentGDPR ? 'ReSend' : 'Send'}>
                <Button
                  disabled={dealCondition}
                  onClick={() => handleSend(UserInstance, 'GDPR')}
                  size="small">
                  <SendIcon
                    style={{
                      color: hasBeenSentGDPR
                        ? theme.palette.warning.main
                        : theme.palette.error.main
                    }}
                  />
                </Button>
              </Tooltip>
            )}
          </Grid>
        )}

        <Divider className={classes.divider} />

        {loadingConsent ? (
          <Grid item className={classes.TypeBox}>
            <CircularProgress size={20} />
          </Grid>
        ) : (
          <Grid item className={classes.TypeBox}>
            <Tooltip title="Consent to search">
              <div
                className={classes.TypeBoxTitle}
                style={{
                  color: isCTSDisabled ? theme.palette.grey[500] : undefined
                }}>
                CTS
              </div>
            </Tooltip>

            {hasBeenSentConsent ? (
              hasConsent ? (
                <ShowConsentCheckbox />
              ) : (
                <ShowSendIcon />
              )
            ) : (
              <ShowConsentCheckbox />
            )}

            {!hasConsent && !dealCondition && !isCTSDisabled && (
              <Tooltip title={hasBeenSentConsent ? 'ReSend' : 'Send'}>
                <Button
                  disabled={dealCondition}
                  onClick={() => handleSend(UserInstance, 'Consent')}
                  size="small">
                  <SendIcon
                    style={{
                      color: hasBeenSentConsent
                        ? theme.palette.warning.main
                        : theme.palette.error.main
                    }}
                  />
                </Button>
              </Tooltip>
            )}
          </Grid>
        )}

        <div style={{ flexGrow: 1 }} />

        {!dealCondition && (
          <Grid item>
            <Tooltip title="Remove contact from this deal">
              <Button
                onClick={() => {
                  setDeleting(true);
                  deleteUser(UserInstance);
                }}>
                {deleting ? <CircularProgress size={18} /> : <DeleteIcon />}
              </Button>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const GDPRstatus: (
  value: CompleteObjectInstance | CompleteObjectInstance[]
) => boolean = (value) => {
  const dev = 18373;
  const CTSFieldDefinitionId = [dev];

  const checkInstance = (
    CompleteObjectInstance: CompleteObjectInstance
  ): boolean => {
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
    for (const FieldInstance of FieldInstanceList) {
      if (CTSFieldDefinitionId.includes(FieldInstance.FieldDefinitionId)) {
        if (FieldInstance.FieldValue === 'true') {
          return true;
        }
      }
    }
    return false;
  };

  if (Array.isArray(value)) {
    for (const instance of value) {
      if (checkInstance(instance)) {
        return true;
      }
    }
    return false;
  } else {
    return checkInstance(value);
  }
};

const ConsentStatus: (
  value: CompleteObjectInstance | CompleteObjectInstance[]
) => boolean = (value) => {
  const dev = 23520;
  const production = 23589;
  const CTSFieldDefinitionId = [dev, production];

  const checkInstance = (
    CompleteObjectInstance: CompleteObjectInstance
  ): boolean => {
    const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
    for (const FieldInstance of FieldInstanceList) {
      if (CTSFieldDefinitionId.includes(FieldInstance.FieldDefinitionId)) {
        if (FieldInstance.FieldValue === 'true') {
          return true;
        }
      }
    }
    return false;
  };

  if (Array.isArray(value)) {
    for (const instance of value) {
      if (checkInstance(instance)) {
        return true;
      }
    }
    return false;
  } else {
    return checkInstance(value);
  }
};
