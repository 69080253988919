interface SET_TARGET_TYPE {
  type: 'SET_TARGET';
  payload: {
    pdid: string;
    piid: string;
    psdid: string;
  };
}

interface MOVE_TARGET_TO_VISITED {
  type: 'MOVE_TARGET_TO_VISITED';
}

type Actions = SET_TARGET_TYPE | MOVE_TARGET_TO_VISITED;

const INITIAL_STATE = {
  notifications: [],
  target: {},
  visitedTargets: []
};

const notificationReducer = (state = INITIAL_STATE, action: Actions) => {
  switch (action.type) {
    case 'SET_TARGET':
      return { ...state, target: action.payload };
    case 'MOVE_TARGET_TO_VISITED': {
      const newState = JSON?.parse(JSON?.stringify(state));
      newState.visitedTargets.push(state.target);
      return { ...newState, target: {} };
    }
    default:
      return state;
  }
};

export default notificationReducer;
