import { FileAttachment, Message } from '@microsoft/microsoft-graph-types';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import { createContext, useContext, useState } from 'react';

import { useDispatch } from 'react-redux';
import { createNotification } from 'react-redux-notify';
import { successNotif, errorNotif } from 'components/Notifications';
import { BugTracker } from 'Utils/Bugtracker';
import { IConversationContext, IForwardMessage, IReply } from './interface';
import { IFile } from 'components/MessageHub/interfaces';
import { ThreadContext } from '../../Threads/context/ThreadsContext';
import { CardContext } from 'components/MessageHub/context/HoverCardContext';

export const ConversationContext = createContext<IConversationContext>({
  replyToMessage: async () => {},
  replyAll: async () => {},
  forwardMessage: async () => {},
  handleMarkReply: async () => {}
});
const ConversationProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const { validAccessToken } = useContext(MessageHubContext);
  const { markAsRead } = useContext(CardContext);
  const { openThreads, selectedThread, refreshThreadReplies } =
    useContext(ThreadContext);

  const replyToMessage = async ({
    messageId,
    replyContent,
    files,
    message
  }: IReply) => {
    const accessToken = await validAccessToken();
    const replyUrl = `https://graph.microsoft.com/v1.0/me/messages/${messageId}/createReply`;

    let headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };

    try {
      const draftResponse = await fetch(replyUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          message: {
            toRecipients: message?.sender
              ? [
                  {
                    emailAddress: {
                      address: message?.sender?.emailAddress?.address,
                      name: message?.sender?.emailAddress?.name
                    }
                  }
                ]
              : []
          },
          comment: replyContent
        })
      });

      const draft = await draftResponse.json();

      if (files && files.length > 0) {
        await attachFilesToDraft({ draftId: draft.id, files, headers });
      }

      const sendDraftUrl = `https://graph.microsoft.com/v1.0/me/messages/${draft.id}/send`;
      await fetch(sendDraftUrl, {
        method: 'POST',
        headers
      }).then(async () => {
        dispatch(
          createNotification(
            successNotif(`Reply sent ${message?.sender?.emailAddress?.address}`)
          )
        );
        await refreshThreadReplies({
          conversationId: message.conversationId || ''
        });
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const handleMarkReply = async (replyId: string) => {
    try {
      await markAsRead({
        messageId: replyId,
        isRead: true
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const replyAll = async ({
    messageId,
    replyContent,
    files,
    message
  }: IReply) => {
    const accessToken = await validAccessToken();
    const replyAllUrl = `https://graph.microsoft.com/v1.0/me/messages/${messageId}/createReplyAll`;

    let headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };

    try {
      const toRecipients =
        openThreads[selectedThread]?.parentMessage?.toRecipients || [];
      const ccRecipients =
        openThreads[selectedThread]?.parentMessage?.ccRecipients || [];

      const draftResponse = await fetch(replyAllUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify({
          message: {
            toRecipients: toRecipients || [],
            ccRecipients: ccRecipients || []
          },
          comment: replyContent
        })
      });

      const draft = await draftResponse.json();

      if (files && files.length > 0) {
        await attachFilesToDraft({ draftId: draft.id, files, headers });
      }

      const sendDraftUrl = `https://graph.microsoft.com/v1.0/me/messages/${draft.id}/send`;
      await fetch(sendDraftUrl, {
        method: 'POST',
        headers
      }).then(async () => {
        const allRecipients: string[] = [];
        if (toRecipients.length > 0) {
          allRecipients.push(`To: ${toRecipients.join(', ')}`);
        }
        if (ccRecipients.length > 0) {
          allRecipients.push(`Cc: ${ccRecipients.join(', ')}`);
        }

        const notificationMessage = `Reply Sent ${allRecipients.join(' ')}`;
        dispatch(createNotification(successNotif(notificationMessage)));
      });

      await refreshThreadReplies({
        conversationId: message.conversationId || ''
      });
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const forwardMessage = async ({
    messageId,
    recipients,
    content,
    subject,
    message
  }: IForwardMessage) => {
    const accessToken = await validAccessToken();

    const createForwardUrl = `https://graph.microsoft.com/v1.0/me/messages/${messageId}/createForward`;
    let headers = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    };

    const forwardData = {
      message: {
        subject: `Fwd: ${subject}`,
        body: {
          contentType: 'html',
          content: `<p>Please see the forwarded email below.</p>${content}`
        },
        toRecipients: recipients.to
          ? recipients.to.map((email) => ({
              emailAddress: { address: email }
            }))
          : [],
        ccRecipients: recipients.cc
          ? recipients.cc.map((email) => ({
              emailAddress: { address: email }
            }))
          : [],
        bccRecipients: recipients.bcc
          ? recipients.bcc.map((email) => ({
              emailAddress: { address: email }
            }))
          : []
      }
    };

    try {
      const forwardResponse = await fetch(createForwardUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(forwardData)
      });

      const forward = await forwardResponse.json();
      const sendForwardUrl = `https://graph.microsoft.com/v1.0/me/messages/${forward.id}/send`;
      await fetch(sendForwardUrl, {
        method: 'POST',
        headers
      }).then(async () => {
        const allRecipients: string[] = [];
        if (recipients.to.length > 0) {
          allRecipients.push(`To: ${recipients.to.join(', ')}`);
        }
        if (recipients.cc.length > 0) {
          allRecipients.push(`Cc: ${recipients.cc.join(', ')}`);
        }
        if (recipients.bcc.length > 0) {
          allRecipients.push(`Bcc: ${recipients.bcc.join(', ')}`);
        }

        const notificationMessage = `Forwarded Message ${allRecipients.join(
          ' '
        )}`;

        dispatch(createNotification(successNotif(notificationMessage)));
        await refreshThreadReplies({
          conversationId: message.conversationId || ''
        });
      });
    } catch (error) {
      BugTracker.notify(error);
    }
  };

  return (
    <ConversationContext.Provider
      value={{
        replyToMessage,
        replyAll,
        forwardMessage,
        handleMarkReply
      }}>
      {children}
    </ConversationContext.Provider>
  );
};

const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
};

const attachFilesToDraft = async ({
  draftId,
  files,
  headers
}: {
  draftId: string;
  files: IFile[];
  headers: any;
}) => {
  const attachmentPromises = files.map(
    (file: any) =>
      new Promise<void>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = async (event) => {
          const result = event.target?.result;
          if (result instanceof ArrayBuffer) {
            const attachment = {
              '@odata.type': '#microsoft.graph.fileAttachment',
              name: file.name,
              contentType: file.type,
              contentBytes: arrayBufferToBase64(result)
            };

            const addAttachmentUrl = `https://graph.microsoft.com/v1.0/me/messages/${draftId}/attachments`;
            await fetch(addAttachmentUrl, {
              method: 'POST',
              headers,
              body: JSON.stringify(attachment)
            });

            resolve();
          }
        };
        fileReader.onerror = () => {
          reject(new Error('Failed to read file'));
        };
      })
  );

  await Promise.all(attachmentPromises);
};

export default ConversationProvider;
