import { IOption } from 'automations/NewSupplierContactAutomation';
import { OffkeyCompanyLite } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IDontDeal } from 'hooks/useDealList';
import { getCrmToken } from 'redux/actions/GraphqlCrmActions';
import {
  CompanySearchResource,
  CompanySearchResourceItem
} from 'types/companiesHouseInterfaces';
import { IEntityOption } from '../Entity_Single';

/**
 * Merges the company list with the bad company list, setting the badCompany flag.
 * @param {IEntityOption[]} companyList - List of companies to be merged.
 * @param {OffkeyCompanyLite[]} badCompanyList - List of bad companies.
 * @returns {IOption[]} - Merged list of companies with badCompany flag set.
 */

const mergeCompanyLists = (
  companyList: IEntityOption[],
  badCompanyList: IDontDeal[]
): IOption[] => {
  return companyList.map((company) => {
    const isBadCompany = badCompanyList.some(
      (badCompany) => badCompany.company_number === company.company_number
    );
    return { ...company, badCompany: isBadCompany };
  });
};

/**
 * Type guard to check if a company is of type OffkeyCompanyLite.
 * @param {CompanySearchResourceItem | OffkeyCompanyLite} company - Company to check.
 * @returns {company is OffkeyCompanyLite} - True if the company is OffkeyCompanyLite, false otherwise.
 */
const isOffkeyCompanyLite = (
  company: CompanySearchResourceItem | OffkeyCompanyLite
): company is OffkeyCompanyLite => {
  return (company as OffkeyCompanyLite).redflag_GraphQL_id !== undefined;
};

/**
 * Maps items to an array of Option objects.
 * @param {CompanySearchResourceItem[] | OffkeyCompanyLite[]} companies - List of companies from data.
 * @returns {Option[]} - Mapped list of Option objects.
 */
const mapItemsToOptions = (
  companies: CompanySearchResourceItem[] | OffkeyCompanyLite[],
  type: string
): IOption[] => {
  return companies.map(
    (company: CompanySearchResourceItem | OffkeyCompanyLite) => ({
      value: 0,
      title: company.title,
      company_number: company.company_number,
      label: company.company_number,
      redflagId: isOffkeyCompanyLite(company)
        ? company.redflag_GraphQL_id
        : undefined,
      badCompany: false,
      type
    })
  );
};

interface IGraphQlState {
  loaded: boolean;
  access_token: string;
  expires_in: string;
  refresh_token: string;
  token_type: string;
  willExpire: number;
}

/**
 * Checks the CRM token state and retrieves a new token if necessary.
 * @param {GraphqlCrmState} graphqlCrmState - The current state of the CRM token.
 * @returns {Promise<string | undefined>} - The new token if it was retrieved, otherwise undefined.
 */
const checkAndRetrieveToken = async (
  graphqlCrmState: IGraphQlState
): Promise<string | undefined> => {
  let newToken: string | undefined;

  if (graphqlCrmState.access_token === '') {
    if (!graphqlCrmState.loaded || graphqlCrmState.willExpire <= Date.now()) {
      const crmToken = await getCrmToken();
      newToken = crmToken.access_token;
    }
  }

  return newToken;
};

export { mergeCompanyLists, mapItemsToOptions, checkAndRetrieveToken };
