import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { format } from 'fecha';
import { Alert } from '@material-ui/lab';
import { useQuery } from 'react-query';

import Table from 'components/SimpleTable';
import EmailViewer from '../../../Designs/EmailViewer';
import { SecondaryButton } from 'common/Button';
import { INITIAL_STATS, EventNames, Event } from '../../types';
import EventStats from '../EventStats';
import { fetchCampaignsStats, fetchEventDetails } from '../../functions';
import { Email, QueryKeys } from '../../../types';
import { useTypedSelector } from 'redux/reducers';

const useStyles = makeStyles((theme: Theme) => {
  const event = {
    height: '8rem',
    width: '8rem',
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    border: `5px solid ${theme.palette.grey.A100}`,
    padding: theme.spacing(1),
    borderRadius: '100%',
    textAlign: 'center' as const
  };

  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      padding: 10
    },
    event,
    eventHover: {
      ...event,
      '&:hover': {
        transition: 'all 0.3s ease-in-out',
        boxShadow: `0 0 10px 0px ${theme.palette.primary.main}`,
        cursor: 'pointer'
      }
    },
    eventPositive: {
      ...event,
      border: `5px solid #66CDAA`
    },
    eventNegativeStat: {
      ...event,
      border: `5px solid #FF6347`
    },
    number: {
      fontSize: 30
    },
    date: {
      color: '#BEBEBE',
      marginTop: theme.spacing(1)
    }
  });
});

const { Click, Open, Delivered } = EventNames;

const Reports = () => {
  const classes = useStyles();
  const history = useHistory();
  const locationState = useLocation<{ email: Email }>().state?.email || {};
  const { EmailFooter } = useTypedSelector((s) => s.user.user);

  const [tableTitle, setTableTitle] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [eventName, setEventName] = React.useState<EventNames | ''>('');

  // persist email state across re-renders
  const email = useRef<Email>(locationState).current;

  const {
    data: stats = INITIAL_STATS,
    isLoading,
    refetch
  } = useQuery(
    [QueryKeys.FetchCampaignsStats, email.id],
    () => fetchCampaignsStats([email.id]),
    {
      enabled: !!email.id
    }
  );

  const { data: events = [], isLoading: loadingEvents } = useQuery(
    [QueryKeys.FetchEventDetails, email.id, eventName],
    () =>
      fetchEventDetails({
        emailId: email.id,
        eventName: eventName as EventNames
      }),
    {
      enabled: !!(eventName && email.id)
    }
  );

  useEffect(() => {
    // scroll to top after first navigating to this page
    window.scrollTo(0, 0);

    const capitalizeFirstLetter = (s) => s[0].toUpperCase() + s.slice(1);

    // Render Delivered stats as the default
    setEventName(Delivered);
    setTableTitle(capitalizeFirstLetter(Delivered));
  }, []);

  return (
    <div className={classes.root}>
      <SecondaryButton
        onClick={() => {
          history.push('/marketing/dashboard');
        }}
        style={{ marginBottom: 20, width: 'fit-content', color: 'grey' }}>
        {`« Back`}
      </SecondaryButton>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          gap: 10
        }}>
        <div>
          <Typography variant="h1" style={{ color: 'grey' }}>
            {email?.name}
          </Typography>
          {email.send_at && (
            <Typography variant="h6" className={classes.date}>
              {format(new Date(email?.send_at), 'ddd MMM Do, YYYY [at] HH:mm')}
            </Typography>
          )}
        </div>

        <div>
          <Button
            onClick={() => refetch()}
            variant="contained"
            style={{ marginRight: 15 }}>
            <RefreshIcon />
            <span style={{ marginLeft: 5 }}>Refresh</span>
          </Button>
          <Button onClick={() => setOpen(true)} variant="contained">
            <VisibilityIcon />
            <span style={{ marginLeft: 5 }}>View Email</span>
          </Button>
        </div>
      </div>

      <Alert severity="info" style={{ margin: '20px 0' }}>
        <Typography>{`Click each stat below to get more detailed info about the contacts that triggered the event.`}</Typography>
      </Alert>

      <EventStats
        stats={stats}
        loading={isLoading}
        fetchEventDetails={(event, title) => {
          if (event === Click || event === Open) {
            const { clicks, opens, unique_clicks, unique_opens } = stats;

            const totalCount = event === Click ? clicks : opens;
            const uniqueCount = event === Click ? unique_clicks : unique_opens;

            title = `Total ${title} (${totalCount}) — Unique ${title} (${uniqueCount})`;
          }

          setEventName(event);
          setTableTitle(title);
        }}
      />
      <div id="campaign-table">
        <Table
          title={tableTitle}
          data={events}
          isLoading={loadingEvents}
          options={{
            pageSize: 10
          }}
          columns={(() => {
            const clickEvent = events[0]?.event === EventNames.Click;
            const openEvent = events[0]?.event === EventNames.Open;

            if (!events.length) return [];

            return [
              { title: 'Email', field: 'email' },
              clickEvent && { title: 'Url clicked', field: 'url' },
              clickEvent && { title: 'Clicks', field: 'count' },
              openEvent && { title: 'Opens', field: 'count' },
              {
                title: 'Date',
                field: 'timestamp',
                render: (d: Event) => {
                  const date = d.timestamp * 1000;

                  return format(
                    new Date(date),
                    'dddd MMMM Do, YYYY [at] HH:mm'
                  );
                }
              },
              { title: 'IP Address', field: 'ip' }
            ].filter(Boolean);
          })()}
        />
      </div>
      <EmailViewer
        email={email}
        open={open}
        setOpen={setOpen}
        disclaimerHtml={EmailFooter}
      />
    </div>
  );
};

export default Reports;
