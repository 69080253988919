import { ICustomTreeData, ICustomTreeDataChildren, ISafeguardAction } from '..';
import { IConditionalObjectDefinitionIds, IHideShowIds } from '../interface';

const haveArraysChanged = (
  array_1: ISafeguardAction[],
  array_2: ISafeguardAction[]
): boolean => {
  const set1 = new Set(array_1);
  const set2 = new Set(array_2);
  if (set1.size !== set2.size) return true;
  for (let item of set1) {
    if (!set2.has(item)) return true;
  }
  return false;
};

export const compareSafeGuardIdsInChildren = (
  children_1: ICustomTreeDataChildren[],
  children_2: ICustomTreeDataChildren[]
): boolean => {
  if (children_1.length !== children_2.length) return true;

  for (let i = 0; i < children_1.length; i++) {
    const child1 = children_1[i];
    const child2 = children_2.find((c) => c.ruleId === child1.ruleId);

    if (!child2) return true;
    if (haveArraysChanged(child1.safeGuards, child2.safeGuards)) return true;
  }

  return false;
};

export const compareSafeGuardIds = (
  treeNode1: ICustomTreeData[],
  treeNode2: ICustomTreeData[]
): boolean => {
  if (treeNode1.length !== treeNode2.length) return true;

  for (let i = 0; i < treeNode1.length; i++) {
    const node1 = treeNode1[i];
    const node2 = treeNode2.find((n) => n.id === node1.id);

    if (!node2) return true;
    if (node1.children && node2.children) {
      if (compareSafeGuardIdsInChildren(node1.children, node2.children))
        return true;
    } else if (node1.children || node2.children) {
      return true;
    }
  }

  return false;
};

/**
 * Retrieves the hide/show ID from the given FieldInstance.
 *
 * @param {number | IHideShowIds} fieldInstanceId - The FieldInstanceId or an object containing hide/show ID.
 * @returns {number} - The hide/show ID.
 */
export const getHideShowId = (fieldInstanceId: number | IHideShowIds): number =>
  typeof fieldInstanceId === 'number'
    ? fieldInstanceId
    : fieldInstanceId.hideShowId;

/**
 * Retrieves the nested selection value from the given FieldInstance.
 *
 * @param {number | IHideShowIds} fieldInstanceId - The FieldInstanceId or an object containing nested selection.
 * @returns {'Yes' | 'No'} - The nested selection value.
 */
export const getNestedSelection = (
  fieldInstanceId: number | IHideShowIds
): 'Yes' | 'No' =>
  typeof fieldInstanceId === 'number'
    ? 'No'
    : fieldInstanceId.nestedSelection || 'No';

/**
 * Retrieves the nested action value from the given FieldInstance.
 *
 * @param {number | IHideShowIds} fieldInstanceId - The FieldInstanceId or an object containing nested action.
 * @returns {'Sent' | 'Completed' | 'Starred' | 'None'} - The nested action value.
 */
export const getNestedAction = (
  fieldInstanceId: number | IHideShowIds
): 'Sent' | 'Completed' | 'Starred' | 'None' =>
  typeof fieldInstanceId === 'number'
    ? 'None'
    : fieldInstanceId.nestedAction || 'None';

/**
 * Retrieves the nested property value (either FieldDefinitionId or FieldValueName) from the given FieldInstance.
 *
 * @param {number | IHideShowIds} fieldInstanceId - The FieldInstanceId or an object containing nested name.
 * @param {'Id' | 'Name'} propertyType - The type of property to retrieve ('Id' for FieldDefinitionId, 'Name' for FieldValueName).
 * @returns {string | number} - The value of the specified nested property.
 */
export const getNestedProperty = (
  fieldInstanceId: number | IHideShowIds
): string | number => {
  if (typeof fieldInstanceId === 'number') {
    return '';
  }

  const nestedName = fieldInstanceId.nestedName;
  if (!nestedName) {
    return '';
  } else return nestedName;
};

/**
 * Retrieves the nested child ID from the given FieldInstance.
 *
 * @param {number | IHideShowIds} fieldInstanceId - The FieldInstanceId or an object containing nested child ID.
 * @param {string} [type] - Optional string to specify 'regulated', 'non-regulated', or default to nestedChildId.
 * @returns {number} - The nested child ID.
 */
export const getNestedId = (
  fieldInstanceId: number | IHideShowIds,
  type?: 'regulated' | 'non-regulated'
): number => {
  if (typeof fieldInstanceId === 'number') {
    return fieldInstanceId;
  } else if (
    'nestedChildId' in fieldInstanceId &&
    typeof fieldInstanceId.nestedChildId === 'number'
  ) {
    return fieldInstanceId.nestedChildId;
  } else if (
    'nestedChildId' in fieldInstanceId &&
    typeof fieldInstanceId.nestedChildId === 'object'
  ) {
    const nestedChildId =
      fieldInstanceId.nestedChildId as IConditionalObjectDefinitionIds;
    if (type === 'regulated') {
      return nestedChildId.objectDefinitionIdReg;
    } else if (type === 'non-regulated') {
      return nestedChildId.objectDefinitionIdNonReg;
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

/**
 * Retrieves the FieldInstanceId from the given FieldInstance.
 *
 * @param {number | IHideShowIds} fieldInstanceId - The FieldInstanceId or an object containing FieldInstanceId.
 * @returns {number} - The FieldInstanceId.
 */
export const getFieldInstanceId = (
  fieldInstanceId: number | IHideShowIds
): number =>
  typeof fieldInstanceId === 'number'
    ? fieldInstanceId
    : fieldInstanceId.fieldInstanceId;
