import { updateObject } from 'redux/actions/objectUpdater';
import { store } from 'redux/store';

import {
  FieldDefinitionDict,
  FieldInstance,
  CompleteObjectInstance,
  ProcessInstance,
  FieldDefinition
} from 'types/interfaces';

import { Calculation } from 'types/calculatorInterfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import {
  funderDocCalculations,
  funderDocOutputConverter
} from 'components/Calculator/Calculator/functions';
import { ConvertedFunderDoc } from 'components/Calculator/Calculator/interfaces';

interface Props {
  calculation: Calculation;
  FieldDefinitionDict: FieldDefinitionDict;
  ObjectInstance: CompleteObjectInstance;
  ProcessInstance: ProcessInstance;
  ProcessInstanceId: number;
}

//? Review For Calculator Regulated / Non-Regulated
export const handleCalculatorObject = ({
  props,
  output,
  IntroducerAccess
}: {
  props: Props;
  output?: Calculation;
  IntroducerAccess?: boolean;
}) => {
  const process = store.getState().process;

  let FieldInstanceList: FieldInstance[] = [];
  const newObjectInstance: CompleteObjectInstance = JSON.parse(
    JSON.stringify(props.ObjectInstance)
  );

  const FieldInstanceLists = getFieldInstances(newObjectInstance);
  Object.values(props.FieldDefinitionDict).forEach(
    (FieldDefinition: FieldDefinition) => {
      let Title: string | undefined = Object.keys(
        IntroducerAccess ? output || props.calculation : props.calculation
      ).find((key) => key === FieldDefinition.Title);

      //? QuoteName highjackes Title as a FieldValue
      const uniqueNames = [{ type: 'QuoteName', highjacked: 'Title' }];
      const getUniqueTitleObject = uniqueNames.find(
        (item: { type: string; highjacked: string }) =>
          item.type === FieldDefinition.Title
      );

      const FieldInstance: FieldInstance | undefined = FieldInstanceLists.find(
        (FieldValue) => FieldValue.FieldDefinitionId === FieldDefinition.Id
      );

      let FieldValue = '';
      if (Title) {
        const source = IntroducerAccess
          ? output?.[Title]
          : props.calculation[Title];

        const isObject = typeof source === 'object';
        FieldValue = isObject ? JSON.stringify(source) : source;

        if (Title === '_FunderDoc') FieldValue = encodeURIComponent(FieldValue);
      } else if (FieldDefinition.Title === '_FunderDoc' && !IntroducerAccess) {
        const _FunderDoc = funderDocOutputConverter(
          funderDocCalculations(props.calculation),
          props.calculation
        );

        FieldValue = encodeURIComponent(JSON.stringify(_FunderDoc));
      } else if (!Title && FieldDefinition.Title.charAt(0) !== '_') {
        if (FieldDefinition.Title === 'Entity Type') {
          // console.log(
          //   'Update...',
          //   FieldDefinition.Title,
          //   process.entityType,
          //   FieldValue
          // );
          FieldValue = process.entityType;
        } else if (FieldDefinition.Title === 'Regulated Status') {
          // console.log(
          //   'Update...',
          //   FieldDefinition.Title,
          //   process.regulatedStatus,
          //   FieldValue
          // );
          FieldValue = process.regulatedStatus ? 'regulated' : 'non-regulated';
        } else {
          const Val = IntroducerAccess
            ? output?.[FieldDefinition.Title]
            : props.calculation[FieldDefinition.Title];

          FieldValue = Val;
        }
      }

      if (getUniqueTitleObject) {
        const source = IntroducerAccess
          ? output?.[getUniqueTitleObject.highjacked]
          : props.calculation[getUniqueTitleObject.highjacked];

        const isObject = typeof source === 'object';
        FieldValue = isObject ? JSON.stringify(source) : source;
      }

      if (FieldInstance) {
        FieldInstance.FieldValue = FieldValue;
      } else {
        if (Title) {
          if (Title === 'Entity Type') {
            // console.log('Create...', Title, process.entityType);
            FieldValue = process.entityType as string;
          } else if (Title === 'Regulated Status') {
            // console.log('Create...', Title, process.regulatedStatus);
            FieldValue = process.regulatedStatus
              ? 'regulated'
              : 'non-regulated';
          } else {
            FieldValue = JSON.stringify(
              IntroducerAccess ? output?.[Title] : props.calculation[Title]
            );
          }
        }

        const newFieldInstance: FieldInstance = {
          FieldDefinitionId: FieldDefinition.Id,
          FieldValue,
          Id: 0,
          ObjectDefinitionId: 0,
          ObjectInstanceId: props.ObjectInstance.ObjectInstance.Id,
          ProcessInstanceId: 0,
          Title: FieldDefinition.Title,
          UserDefinitionId: 0,
          UserInstanceId: 0
        };
        FieldInstanceList.push(newFieldInstance);
      }
    }
  );

  newObjectInstance.FieldInstanceList = FieldInstanceLists;

  if (FieldInstanceList.length > 0) {
    newObjectInstance.FieldInstanceDict = {};
    newObjectInstance.FieldInstanceList = [
      ...newObjectInstance.FieldInstanceList,
      ...FieldInstanceList
    ];
  }

  return updateObject({
    token: store.getState().user.auth.token,
    data: newObjectInstance,
    props
  });
};
