import { Button, Typography, TextField } from '@material-ui/core';
import { useDoNotDealListNonRedFlag } from 'hooks/useDoNotDealNonRedFlag';
import Alert from '@material-ui/lab/Alert';
import { useTypedSelector } from 'redux/reducers';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

interface IProps {
  name: string;
  email: string;
  UserDefinitionId?: number;
  setHistoryAdded?: Dispatch<SetStateAction<boolean>>;
}

export const ExtremeCautionAlertContact = ({
  name,
  email,
  UserDefinitionId,
  setHistoryAdded
}: IProps) => {
  const { user } = useTypedSelector((s) => s.user);
  const isAdmin = user.SystemAccess >= 15;
  const {
    addContactToDoNotDealList,
    badContact,
    updateContactDoNotDealListEntry,
    removeContactFromDoNotDealList,
    addToHistory
  } = useDoNotDealListNonRedFlag(email);

  const [edit, setEdit] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [reportedBy, setReportedBy] = useState<string>('');

  useEffect(() => {
    if (badContact) {
      setDescription(badContact?.Description || '');
      setReportedBy(badContact?.ReportedBy || '');
      setEdit(true);
    } else {
      setEdit(false);
    }
  }, [badContact]);

  const handleAdd = async () => {
    try {
      await addContactToDoNotDealList({
        email,
        name,
        UserDefinitionId: UserDefinitionId || 0,
        Description: '',
        ReportedBy: '',
        dateCreated: new Date()
      });
      setEdit(true);
    } catch (e) {
      console.error('Error adding company to DoNotDealList:', e);
    }
  };

  const handleRemove = async () => {
    try {
      await addToHistory();
      if (badContact) {
        await removeContactFromDoNotDealList(badContact);
        setEdit(false);
        setDescription('');
        setReportedBy('');
        setHistoryAdded && setHistoryAdded(true);
      }
    } catch (e) {
      console.error('Error removing company from DoNotDealList:', e);
    }
  };

  const handleBlur = async (
    event: React.ChangeEvent<{ name: string; value: string }>
  ) => {
    const { name, value } = event.target;
    if (badContact) {
      const updatedContact = {
        ...badContact,
        [name]: value
      };
      return await updateContactDoNotDealListEntry(updatedContact);
    }
  };

  if (edit) {
    return (
      <Alert severity="error" style={{ marginBottom: 8 }}>
        <Typography variant="h4">Extreme Caution</Typography>
        <Typography>{`This contact is on the ‘Proceed with extreme caution’ list. Under no circumstances should applications be made for this counter party without consulting the ‘Proceed with Caution’ list first`}</Typography>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: 275,
            marginBottom: 8
          }}>
          <TextField
            label="Description"
            onBlur={handleBlur}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            name="Description"
            value={description}
          />
          <TextField
            label="Reported by"
            onBlur={handleBlur}
            onChange={(e) => {
              setReportedBy(e.target.value);
            }}
            name="ReportedBy"
            value={reportedBy}
          />
        </div>

        {isAdmin && (
          <Button onClick={handleRemove} variant="contained">
            Remove from extreme caution list
          </Button>
        )}
      </Alert>
    );
  }

  return (
    <>
      {isAdmin && (
        <Button
          onClick={handleAdd}
          variant="contained"
          style={{ marginBottom: 8 }}>
          Add to Extreme caution list
        </Button>
      )}
    </>
  );
};
