import { globalHost } from 'helpers';
import { useProcess } from 'hooks';
import { ListItem } from '../Stepper';

const MobileHeader = ({ classes }) => {
  const { currentDeal, currentProcess, user } = useProcess();
  let location = globalHost(window.location);

  return (
    <div className={classes.titlebox} style={{ flexDirection: 'row' }}>
      <div style={{ filter: 'drop-shadow(2px 2px 1px black)' }}>
        <img
          height={37}
          src={location.logo}
          style={{ filter: 'grayscale(100%)' }}
        />
      </div>

      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingLeft: '1em'
        }}>
        <div>
          <ListItem
            primary="Product Type"
            secondary={currentProcess?.ProcessDefinition?.Title}
          />
          <ListItem
            primary="Process Name"
            secondary={currentDeal?.ProcessInstance?.Title}
          />
        </div>

        <div>
          <ListItem primary="Customer" secondary={user?.Title} />
          <ListItem primary="Landing Page" secondary="Powered by Bips" />
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
