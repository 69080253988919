import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress
} from '@material-ui/core';
import { Check } from '@material-ui/icons';
import { CustomDialog } from 'common/Dialog';
import ReactHtmlParser from 'react-html-parser';
import { IChecklistTypes, IRuleConfirmationProps } from '../interface';
import { useAlertDialogStyles } from '../styles';
import RuleDialogConfig from './RuleDialogConfig';
import { useTypedSelector } from 'redux/reducers';

const RuleConfirmation = ({
  open,
  handleClose,
  action,
  onSubmit,
  loading,
  ruleId
}: IRuleConfirmationProps) => {
  const { currentDeal } = useTypedSelector((s) => s.process);
  const [checklist, setChecklist] = useState<IChecklistTypes>(
    {} as IChecklistTypes
  );

  const handleChecklistChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    setChecklist((prev) => ({
      ...prev,
      [name]: checked
    }));
  };

  const uniqueRequirements = RuleDialogConfig({
    ruleId,
    ProcessDefinitionId: currentDeal.ProcessInstance.ProcessDefinitionId,
    allChecked: Object.values(checklist).every(Boolean),
    checklist,
    handleChecklistChange
  });

  useEffect(() => {
    const initialChecklist: IChecklistTypes = {} as IChecklistTypes;
    if (uniqueRequirements.checkboxes) {
      uniqueRequirements.checkboxes.forEach((checkbox) => {
        initialChecklist[checkbox.name] = false;
      });
    }
    if (uniqueRequirements.showAssignedViewers) {
      initialChecklist.assignedViewers = false;
    }
    setChecklist(initialChecklist);
  }, [open]);

  const allChecked =
    !uniqueRequirements.showChecklist ||
    Object.keys(checklist).length === 0 ||
    Object.values(checklist).every(Boolean);

  const classes = useAlertDialogStyles({ allChecked });
  return (
    <CustomDialog
      open={open}
      maxSize="sm"
      handleClose={handleClose}
      alert={{
        title: action.Title,
        description: uniqueRequirements.dialogTitle,
        type: 'warning'
      }}>
      <DialogContent>
        <Grid container spacing={2}>
          {action.Instruction && (
            <Grid item xs={12}>
              <Typography>{ReactHtmlParser(action.Instruction)}</Typography>
            </Grid>
          )}

          {uniqueRequirements.showChecklist && (
            <Grid item xs={12}>
              <Box className={classes.sectionContainer}>
                {uniqueRequirements.additionalContent}
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
        <Button
          data-cy="rule-proceed-btn"
          onClick={onSubmit}
          variant="contained"
          className={classes.proceedButton}
          disabled={!allChecked || loading}
          startIcon={loading ? <CircularProgress size={20} /> : <Check />}>
          {loading ? 'Processing...' : 'Proceed'}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default RuleConfirmation;
