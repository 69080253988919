import { ObjectDefinition, FieldDefinition } from 'types/interfaces';
import { globalIds } from './globalIdConfig';

/**
 * List of field titles that are considered special and exempt from redflag readonly rules
 */
const SpecialFieldTitles = [
  'Phone',
  'VAT Number',
  'Email',
  'FCA Permission Number',
  'Type',
  'Sic07 Codes',
  'Birthdate',
  'Surname',
  'Address',
  'Town',
  'Postcode',
  'County',
  'Country',
  'Address Line 1',
  'Address Line 2',
  'Address Line 3',
  'Address Line 4',
  'Nationality',
  'Title',
  'First Name',
  'Date of Birth',
  'Residential Status',
  'Time at Address',
  'Contact Number',
  'Previous Address',
  'Website'
];

/**
 * Determines if a field should be read-only based on redflag rules and object definitions
 *
 * @param {string[] | undefined} editable - List of editable field identifiers
 * @param {ObjectDefinition} ObjectDefinition - The object definition containing metadata
 * @param {FieldDefinition} [FieldDefinition] - Optional field definition for specific field checks
 *
 * @returns {boolean} Returns true if the field should be read-only, false otherwise
 *
 * @description
 * This function implements the following logic:
 * 1. Addresses and shareholders are never read-only
 * 2. Special fields (defined in SpecialFieldTitles) are exempt from read-only rules
 * 3. Directors have unique redflag handling
 * 4. For other objects:
 *    - If they contain 'redflag' in description, they're locked unless:
 *      a) They're in the editable list
 *      b) They're a special field
 *      c) They're a unique redflag
 */
export const ReadOnlyRedflag = ({
  editable,
  ObjectDefinition,
  FieldDefinition
}: {
  editable?: string[] | undefined;
  ObjectDefinition: ObjectDefinition;
  FieldDefinition?: FieldDefinition;
}): boolean => {
  // Initialize flags
  let ReadOnly = false;

  // Step 1: Check for excluded object types (addresses and shareholders)
  const AddressOB = globalIds.customer.addresses.AddressObjectDefinition;
  const shareholderOB =
    globalIds.customer.shareholders.ShareHolderObjectDefinition;
  if ([AddressOB, shareholderOB].includes(ObjectDefinition.Id)) {
    return false;
  }

  // Step 2: Check for special fields
  const isSpecialField = FieldDefinition
    ? SpecialFieldTitles.includes(FieldDefinition.Title)
    : false;

  // Step 3: Check for unique redflag cases (directors)
  const isUniqueRedflag =
    ObjectDefinition.Id ===
    globalIds.customer.directors.DirectorsObjectDefinition;

  // Step 4: Determine if object should be locked
  const locked =
    ObjectDefinition.ObjectDescription.includes('redflag') &&
    !isSpecialField &&
    !isUniqueRedflag;

  // Step 5: Apply editable rules if object is locked
  if (locked) {
    if (editable === undefined) {
      ReadOnly = true;
    } else {
      // Default to read-only unless explicitly made editable
      ReadOnly = !editable.some((element) =>
        ObjectDefinition.ObjectDescription.includes(element)
      );
    }
  }

  return ReadOnly;
};
