import { Calculation } from 'types/calculatorInterfaces';
import { Extra } from '../interfaces';

export const handleBalloonPayment = (
  state: Calculation,
  zeroPayments: Extra[]
) => {
  if (state._Balloon) {
    let { ContractPeriod, TotalBalloon } = state._Balloon;
    TotalBalloon = TotalBalloon.toString() === '' ? 0 : TotalBalloon;
    const { Term } = state;
    const typedTerm = parseInt(Term.toString());

    // Modify the Extras object depending on the Balloon Contract period selection.
    if (ContractPeriod === 0) {
      // Add the ballon Payment to the last payment of the term
      let extrasArray = [...zeroPayments];
      const mockData = {
        StartPeriod: typedTerm,
        EndPeriod: typedTerm,
        Amount: TotalBalloon,
        ExPayOverRide: '1'
      };
      // Check if anything exists at this month and remove it (aka do not push duplicates to array)
      if (extrasArray.length > 0) {
        let myArray: any = [];
        extrasArray.forEach((e) => {
          e.StartPeriod !== typedTerm &&
            e.StartPeriod !== typedTerm + 1 &&
            myArray.push(e);
        });
        extrasArray = myArray;
      }
      extrasArray.push(mockData);
      return extrasArray;
    }

    if (ContractPeriod === 1) {
      // Create a secret last month that is not displayed to the user and submit that
      // Add the balloon Payment to the last payment of the term
      let extrasArray = [...zeroPayments];
      const mockData = {
        StartPeriod: typedTerm + 1,
        EndPeriod: typedTerm + 1,
        Amount: TotalBalloon,
        ExPayOverRide: '2'
      };
      // Check if anything exists at this month and remove it (aka do not push duplicates to array)
      if (extrasArray.length > 0) {
        let myArray: any = [];
        extrasArray.forEach((e) => {
          e.StartPeriod !== typedTerm &&
            e.StartPeriod !== typedTerm + 1 &&
            myArray.push(e);
        });
        extrasArray = myArray;
      }
      extrasArray.push(mockData);
      return extrasArray;
    }
  }
  return zeroPayments;
};
