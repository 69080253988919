/**essentially contains & deploys all the data (stored within seperate objects) to the ui (particularly in CRUD form elements) app */
import { useState, useEffect, useContext } from 'react';
import {
  Tooltip,
  Typography,
  CircularProgress,
  Button,
  Paper,
  DialogActions,
  ButtonGroup
} from '@material-ui/core';
import { ITabState } from './interfaces';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  ObjectDefinition
} from 'types/interfaces';
import RepeatableObject from '../RepeatableObject';
import RepeatDynamicAssetObject from '../RepeatDynamicAssetObject';
import RepeatDynamicPropertyObject from '../RepeatDynamicPropertyObject';
import RepeatAttachmentsObject from '../RepeatAttachmentsObject';
import RepeatDynamicObject from '../RepeatDynamicObject';
import CustomAddressObject from '../CustomAddressesObject';
import SingleObject from '../SingleObject';
import Calculator from '../CalculatorObject';

import Help from 'components/Help';
import { useForm } from 'hooks/useForm';
import { useProcess } from 'hooks/useProcess';

import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { arrayContains } from './helpers';

import PdfDownloadButton from 'common/PdfDownloadButton';
import PdfDownloadFile from '../components/PdfDownloadFile';
import { useTypedSelector } from 'redux/reducers';
import { ISettings } from 'redux/reducers/configReducer';
import { globalHost } from 'helpers';
import GDPRContacts from '../GDPRObject';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { Skeleton } from '@material-ui/lab';
import { useRedflagUpdate } from 'hooks/useRedflagUpdate';
import { IProps } from '../CalculatorObject/interfaces';
import { StepperContext } from 'components/Stepper/context';
import { useDispatch } from 'react-redux';
import { CustomDialog } from 'common/Dialog';
import { theme } from 'theme';

import { createNotification } from 'react-redux-notify';
import {
  errorNotif,
  successNotif,
  warningNotif
} from 'components/Notifications';
import { globalIds } from 'helpers/globalIdConfig';
import { useSaving } from 'hooks/useDeals/useSaving';
import { getCompleteUserInstanceDetail } from 'redux/actions/GraphQlActions';
import { ICompleteUserInstanceDetail } from 'redux/actions/GraphQlActions/interface';

export enum objectType {
  nullObject = 'nullObject',
  singleObject = 'singleObject',
  repeatableObject = 'repeatableObject',
  repeatDynamicObject = 'repeatDynamicObject',
  repeatDynamicAssetObject = 'repeatDynamicAssetObject',
  repeatDynamicPropertyObject = 'repeatDynamicPropertyObject',
  repeatAttachmentsObject = 'repeatAttachmentsObject'
}

export const FormContent = () => {
  const { value, displayObjects } = useForm();

  const [displayObject, setDisplayObject] =
    useState<CompleteObjectDefinition | null>(null);
  const ObjectDefinition = displayObject?.ObjectDefinition;

  useEffect(() => {
    const updatedDisplayObjects: CompleteObjectDefinition | undefined =
      displayObjects.find(
        (el: CompleteObjectDefinition) => el.ObjectDefinition.Id === value
      );
    if (updatedDisplayObjects) setDisplayObject(updatedDisplayObjects);
  }, [value]);

  if (ObjectDefinition && displayObject.FieldDefinitionList) {
    return (
      <Display
        ObjectDefinition={ObjectDefinition}
        displayObject={displayObject}
      />
    );
  } else return <CircularProgress />;
};

const Display = ({ ObjectDefinition, displayObject }) => {
  const { isDealClosed, isDealTransferred } = useContext(StepperContext);
  const {
    handleSaving,
    handleLoading,
    extractGroupIdentifier,
    loading: loadingSavedData,
    extractedId
  } = useSaving();
  const {
    stepdefdict,
    calculation,
    currentDeal,
    currentStep,
    landingpage,
    user
  } = useProcess();
  const { refreshDealData } = useContext(StepperContext);
  const dispatch = useDispatch();

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const [loading, setLoading] = useState<boolean>(false);
  const [redflagDialog, setRedflagDialog] = useState<boolean>(false);
  const { importData } = useRedflagUpdate();
  // const [parent, enableAnimations] = useAutoAnimate(/* optional config */);

  const settings = useTypedSelector<ISettings>(
    (state) => state.config.settings
  );
  const [state, setState] = useState<ITabState | null>(null);
  const location = globalHost(window.location);
  // keep track of what object data the ui (user) is currently on. eg. 'singleObject' / 'repeatableObject' / etc
  const [currentObjectType, setCurrentObjectType] = useState<objectType>(
    objectType.nullObject
  );
  // essentially collecting each object data which could be anything
  const [objectData, setObjectData] = useState<unknown[]>([]);

  const CompleteProcessStepDefinition = stepdefdict;
  const dealCondition = isDealClosed || isDealTransferred;
  useEffect(() => {
    if (ObjectDefinition) {
      const isReadOnly: () => boolean = () =>
        ReadOnlyRedflag({ ObjectDefinition });

      const isRepeatable: boolean = ObjectDefinition.ObjectRepeat !== 1;
      const isRepeatDynamic: boolean = arrayContains({
        description: ObjectDefinition.ObjectDescription,
        query: 'repeat-dynamic'
      });
      const isRepeatDynamicAsset: boolean = arrayContains({
        description: ObjectDefinition.ObjectDescription,
        query: 'repeat-dynamic-asset'
      });
      const isRepeatDynamicProperty: boolean = arrayContains({
        description: ObjectDefinition.ObjectDescription,
        query: 'repeat-dynamic-property'
      });
      const isCustomAddress: boolean = arrayContains({
        description: ObjectDefinition.ObjectDescription,
        query: 'custom-address'
      });
      const isAttachments: boolean = arrayContains({
        description: ObjectDefinition.ObjectDescription,
        query: 'attachments'
      });

      const isIndependent: boolean = ObjectDefinition.ObjectProcessIndependent;
      const isCalculator: boolean =
        ObjectDefinition.ObjectDescription.includes('calculator');

      setState({
        isReadOnly,
        isRepeatable,
        isRepeatDynamic,
        isRepeatDynamicAsset,
        isRepeatDynamicProperty,
        isCustomAddress,
        isAttachments,
        isIndependent,
        isCalculator
      });
    }
  }, [displayObject]); //useEffect

  // pdf download button component
  const PdfDataButton = PdfDownloadButton({
    PdfComponent: () => (
      <PdfDownloadFile
        ObjectDefinition={ObjectDefinition ? ObjectDefinition : null}
        objectData={objectData}
        location={location}
        settings={settings}
        currentObjectType={currentObjectType}
        currentDeal={currentDeal}
      />
    ),
    fileName: `${ObjectDefinition ? ObjectDefinition?.Title : 'File data'}`
  }); //END PdfDataButton

  if (
    state?.isCalculator &&
    ObjectDefinition &&
    displayObject.FieldDefinitionList
  ) {
    const props: IProps = {
      display: 'systemView',
      ObjectDefinition: ObjectDefinition,
      FieldDefinitionDict: displayObject.FieldDefinitionDict,
      FieldDefinitionList: displayObject.FieldDefinitionList
    };

    return <Calculator props={props} />;
  }

  const handleUpdatingRedflag = async () => {
    setLoading(true);

    const UserInstance = currentStep.UserInstanceListForCurrentStep[0];
    const CompleteUserInstanceList = (await getCompleteUserInstanceDetail({
      baseUrl,
      UserInstanceId: UserInstance.UserInstanceId,
      action: 'CompleteUserInstanceDetail'
    })) as ICompleteUserInstanceDetail;

    const newCompleteObject: CompleteObjectDefinition[] = [];
    const redFlagIdentifier = 'redflag';

    Object.values(
      CompleteProcessStepDefinition.CompleteObjectDefinitionDict
    ).map((element) => {
      if (
        element.ObjectDefinition.ObjectDescription.toLowerCase().includes(
          redFlagIdentifier
        )
      ) {
        newCompleteObject.push(element);
      }
    });

    if (CompleteUserInstanceList.CompleteObjectInstanceList.length > 0) {
      const CompanyDetailsId = 2839;
      const hasBasicCompanyDetails =
        CompleteUserInstanceList.CompleteObjectInstanceList.find(
          (CompleteObjectInstance: CompleteObjectInstance) => {
            return (
              CompleteObjectInstance.ObjectInstance.ObjectDefinitionId ===
              CompanyDetailsId
            );
          }
        );

      if (hasBasicCompanyDetails) {
        await importData({
          rowData: CompleteUserInstanceList,
          CompleteObjectDefinition: newCompleteObject,
          isCompany: true,
          company: null
        });

        const refresh = refreshDealData();
        if (await refresh) {
          dispatch(
            createNotification(
              successNotif('Successfully Updated Redflag Information')
            )
          );
        }
      } else {
        dispatch(
          createNotification(
            warningNotif(
              'Update not allowed: This customer is not associated with a valid Redflag company.'
            )
          )
        );
      }
    }

    setLoading(false);
  };

  if (ObjectDefinition?.Id === 3388) {
    // Objects
    const gdprObjectDefinitionId = 2849;
    const devObjectConsent = 3693;
    const prodObjectConsent = 3700;
    const ContactsObjectDefinitionIdList = [
      gdprObjectDefinitionId,
      devObjectConsent,
      prodObjectConsent
    ];

    // Fields
    const gdprFieldDefId = 21122;
    const devFieldConsent = 23520;
    const prodFieldConsent = 23589;
    const ContactsFieldDefinitionIdToGet = [
      gdprFieldDefId,
      devFieldConsent,
      prodFieldConsent
    ];
    return (
      <>
        <Header
          PdfDataButton={PdfDataButton}
          ObjectDefinition={ObjectDefinition}
          state={state}
        />

        <GDPRContacts
          ObjectDefinition={ObjectDefinition}
          FieldDefinitionDict={displayObject?.FieldDefinitionDict}
          FieldDefinitionList={displayObject?.FieldDefinitionList}
          ObjectDefinitionIdList={ContactsObjectDefinitionIdList}
          ContactsFieldDefinitionIdToGet={ContactsFieldDefinitionIdToGet}
        />
      </>
    );
  }

  const shouldRenderDirectors =
    ObjectDefinition.Id ===
      globalIds.customer.directors.DirectorsObjectDefinition ||
    (state?.isReadOnly() && !landingpage);

  return (
    <div style={{ width: '100%' }}>
      <CustomDialog
        maxSize="sm"
        open={redflagDialog}
        handleClose={() => setRedflagDialog(false)}
        alert={{
          title: `This Action Cannot Be Undone.`,
          description:
            'Request to Update Information from RedFlag and Override Current Data',
          type: 'warning'
        }}>
        <Paper style={{ width: '100%', height: '100%' }}>
          <Typography style={{ padding: theme.spacing(2) }} align="left">
            By choosing the update, you will be making a request to update the
            information from RedFlag. This will override the information you
            currently have stored. Would you like to proceed?
          </Typography>
          <DialogActions>
            <Button
              onClick={() => {
                setRedflagDialog(false);
              }}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={async (e) => {
                await handleUpdatingRedflag();
                setRedflagDialog(false);
              }}>
              {!loading ? 'Proceed' : <CircularProgress size={24} />}
            </Button>
          </DialogActions>
        </Paper>
      </CustomDialog>
      <Typography variant="h4">{ObjectDefinition.Title}</Typography>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: 8
        }}>
        {state?.isIndependent ? (
          <Tooltip title="This data is linked to the user">
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <AccountCircleIcon
                color="primary"
                style={{ fontSize: 25, marginRight: 8 }}
              />
              <Typography color="primary" variant="caption">
                USER DATA
              </Typography>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="This data is linked to the deal">
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <AssignmentIndIcon
                color="primary"
                style={{ fontSize: 25, marginRight: 8 }}
              />
              <Typography color="primary" variant="caption">
                DEAL DATA
              </Typography>
            </div>
          </Tooltip>
        )}

        <div style={{ flexGrow: 1 }} />

        {extractedId.includes(ObjectDefinition.Id) && !landingpage && (
          <>
            <ButtonGroup disabled={dealCondition} variant="outlined">
              {!loadingSavedData ? (
                <Button onClick={() => handleSaving(ObjectDefinition)}>
                  Save{' '}
                  {extractGroupIdentifier(ObjectDefinition.ObjectDescription)}
                </Button>
              ) : (
                <Skeleton
                  animation="wave"
                  style={{
                    borderRadius: theme.shape.borderRadius,
                    borderEndEndRadius: 0,
                    borderStartEndRadius: 0
                  }}>
                  <Button variant="outlined" color="secondary">
                    Save{' '}
                    {extractGroupIdentifier(ObjectDefinition.ObjectDescription)}
                  </Button>
                </Skeleton>
              )}

              {!loadingSavedData ? (
                <Button
                  onClick={async () => await handleLoading(ObjectDefinition)}>
                  Load{' '}
                  {extractGroupIdentifier(ObjectDefinition.ObjectDescription)}
                </Button>
              ) : (
                <Skeleton
                  animation="wave"
                  style={{
                    borderRadius: theme.shape.borderRadius,
                    borderStartStartRadius: 0,
                    borderEndStartRadius: 0
                  }}>
                  <Button variant="outlined" color="secondary">
                    Load{' '}
                    {extractGroupIdentifier(ObjectDefinition.ObjectDescription)}
                  </Button>
                </Skeleton>
              )}
            </ButtonGroup>
          </>
        )}

        {/*{process.env.NODE_ENV === 'development' && <PdfDataButton />}*/}

        {shouldRenderDirectors && !landingpage && (
          <div>
            {loading ? (
              <Skeleton animation="wave">
                <Button variant="outlined" color="secondary">
                  Update Redflag
                  <CloudDownloadIcon style={{ marginLeft: 10 }} />
                </Button>
              </Skeleton>
            ) : (
              <Button
                disabled={dealCondition}
                variant="outlined"
                color="secondary"
                onClick={() => setRedflagDialog(true)}>
                Update Redflag
                <CloudDownloadIcon style={{ marginLeft: 10 }} />
              </Button>
            )}
          </div>
        )}

        {/* <Help url="docs/intro" /> */}
      </div>

      {state?.isRepeatable ? (
        state?.isAttachments ? (
          // for attach files
          <RepeatAttachmentsObject
            ObjectDefinition={ObjectDefinition}
            FieldDefinitionDict={displayObject.FieldDefinitionDict}
            FieldDefinitionList={displayObject.FieldDefinitionList}
            stepdefdict={CompleteProcessStepDefinition}
            setCurrentObjectType={setCurrentObjectType}
            currentObjectType={currentObjectType}
          />
        ) : state?.isRepeatDynamic ? (
          <RepeatDynamicObject
            display={'systemView'}
            ObjectDefinition={ObjectDefinition}
            FieldDefinitionDict={displayObject.FieldDefinitionDict}
            FieldDefinitionList={displayObject.FieldDefinitionList}
            stepdefdict={CompleteProcessStepDefinition}
            setObjectData={setObjectData}
            setCurrentObjectType={setCurrentObjectType}
            currentObjectType={currentObjectType}
          />
        ) : state?.isRepeatDynamicAsset ? (
          <RepeatDynamicAssetObject
            ObjectDefinition={ObjectDefinition}
            FieldDefinitionDict={displayObject.FieldDefinitionDict}
            FieldDefinitionList={displayObject.FieldDefinitionList}
            stepdefdict={CompleteProcessStepDefinition}
            setObjectData={setObjectData}
            setCurrentObjectType={setCurrentObjectType}
            currentObjectType={currentObjectType}
          />
        ) : state?.isRepeatDynamicProperty ? (
          <RepeatDynamicPropertyObject
            ObjectDefinition={ObjectDefinition}
            FieldDefinitionDict={displayObject.FieldDefinitionDict}
            FieldDefinitionList={displayObject.FieldDefinitionList}
            stepdefdict={CompleteProcessStepDefinition}
            setCurrentObjectType={setCurrentObjectType}
            currentObjectType={currentObjectType}
          />
        ) : state?.isCustomAddress ? (
          <CustomAddressObject
            ObjectDefinition={ObjectDefinition}
            FieldDefinitionDict={displayObject.FieldDefinitionDict}
            FieldDefinitionList={displayObject.FieldDefinitionList}
            stepdefdict={CompleteProcessStepDefinition}
            setObjectData={setObjectData}
            setCurrentObjectType={setCurrentObjectType}
            currentObjectType={currentObjectType}
          />
        ) : (
          // repeatable key value pair data (such as table data)
          <RepeatableObject
            ObjectDefinition={ObjectDefinition}
            FieldDefinitionDict={displayObject.FieldDefinitionDict}
            FieldDefinitionList={displayObject.FieldDefinitionList}
            stepdefdict={CompleteProcessStepDefinition}
            setObjectData={setObjectData}
            setCurrentObjectType={setCurrentObjectType}
            currentObjectType={currentObjectType}
          />
        )
      ) : state?.isRepeatDynamic ? (
        <RepeatDynamicObject
          display={'systemView'}
          ObjectDefinition={ObjectDefinition}
          FieldDefinitionDict={displayObject.FieldDefinitionDict}
          FieldDefinitionList={displayObject.FieldDefinitionList}
          stepdefdict={CompleteProcessStepDefinition}
          setObjectData={setObjectData}
          setCurrentObjectType={setCurrentObjectType}
          currentObjectType={currentObjectType}
        />
      ) : (
        // simple key value pair data
        <SingleObject
          calculation={calculation}
          stepdefdict={CompleteProcessStepDefinition}
          ObjectDefinition={ObjectDefinition}
          FieldDefinitionDict={displayObject.FieldDefinitionDict}
          FieldDefinitionList={displayObject.FieldDefinitionList}
          setObjectData={setObjectData}
          setCurrentObjectType={setCurrentObjectType}
          currentObjectType={currentObjectType}
        />
      )}
    </div>
  );
};

const Header = ({
  ObjectDefinition,
  state,
  PdfDataButton
}: {
  ObjectDefinition: ObjectDefinition;
  state: ITabState | null;
  PdfDataButton: () => JSX.Element;
}) => {
  // Here we will use a hook
  return (
    <>
      <Typography variant="h4">{ObjectDefinition.Title}</Typography>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          marginBottom: 8
        }}>
        {state?.isIndependent ? (
          <Tooltip title="This data is linked to the user">
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <AccountCircleIcon
                color="primary"
                style={{ fontSize: 25, marginRight: 8 }}
              />
              <Typography color="primary" variant="caption">
                USER DATA
              </Typography>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="This data is linked to the deal">
            <div
              style={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <AssignmentIndIcon
                color="primary"
                style={{ fontSize: 25, marginRight: 8 }}
              />
              <Typography color="primary" variant="caption">
                DEAL DATA
              </Typography>
            </div>
          </Tooltip>
        )}

        <div style={{ flexGrow: 1 }} />

        {/* button for downloading pdf file of the object data */}
        {process.env.NODE_ENV === 'development' && <PdfDataButton />}

        <Help url="docs/intro" />
      </div>
    </>
  );
};
