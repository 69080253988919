/**collects company extreme cautious info, & history data too
 * use of firestore db, & most function are CRUD
 */
import React from 'react';
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';
import {
  SET_DND_USERS,
  ADD_DND_USER,
  REMOVE_DND_USER,
  UPDATE_DND_USER
} from 'redux/actions/types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';

// company detail object
export interface IDontDeal {
  company_number: string;
  UserDefinitionId: number;
  name: string;
  Description?: string;
  ReportedBy?: string;
  dateCreated: Date;
  dateRemoved?: Date;
  docId?: string;
}

interface dontDealDocField {
  nameOfDocField: string;
  value: string | number;
}

const DoNotDealList = 'Do_Not_Deal_List';
const History = 'History';

export const useDealList = (company_number: string) => {
  const dispatch = useDispatch();
  const doNotDealList = useSelector(
    (state: RootState) => state.dndReducer.dndusers
  );
  const badCompany = useSelector((state: RootState) =>
    state.dndReducer.dndusers.find(
      (user) =>
        'company_number' in user && user.company_number === company_number
    )
  ) as IDontDeal | undefined;
  const [historyList, setHistoryList] = React.useState<IDontDeal[]>([]);

  const addToDoNotDealList = async ({
    name,
    company_number,
    UserDefinitionId,
    Description = '',
    ReportedBy = ''
  }) => {
    const ref = await firebase
      .firestore()
      .collection(DoNotDealList)
      .doc(company_number);
    const newCompany = {
      name,
      UserDefinitionId: UserDefinitionId ? UserDefinitionId : 0,
      company_number,
      Description,
      ReportedBy,
      dateCreated: firebase.firestore.Timestamp.fromDate(new Date())
    };
    await ref.set(newCompany).catch((e) => {
      console.log('Failed Error: SET, add to do not deal list', e);
      BugTracker.notify('Failed Error: SET, add to do not deal list', e);
    });
    dispatch({ type: ADD_DND_USER, payload: newCompany });
    await getDoNotDealList();
    return ref;
  };

  const removeFromDoNotDealList = async ({ company_number }) => {
    const ref = firebase
      .firestore()
      .collection(DoNotDealList)
      .doc(company_number);

    try {
      await ref.delete();
      await getAllHistory();
    } catch (e) {
      console.log('Failed Error: DELETE, remove do not list', e);
      BugTracker.notify('Failed Error: DELETE, remove do not list', e);
    }
    dispatch({ type: REMOVE_DND_USER, payload: company_number });
    await getDoNotDealList();
    return ref;
  };

  /**update doc field value */
  const updateDoNotDealListEntry = async ({ company_number, name, value }) => {
    const ref = firebase
      .firestore()
      .collection(DoNotDealList)
      .doc(company_number);

    await ref.update({ [name]: value }).catch((e) => {
      console.log('Failed Error: UPDATE, update do not deal', e);
      BugTracker.notify('Failed Error: UPDATE, update do not deal', e);
    });
    dispatch({
      type: UPDATE_DND_USER,
      payload: { company_number, [name]: value }
    });
    await getDoNotDealList();
    await getAllHistory();
  };

  const updateMultipleDoNotDealListEntry = async ({
    company_number,
    docFieldList
  }: {
    company_number: string;
    docFieldList: dontDealDocField[];
  }) => {
    const ref = firebase
      .firestore()
      .collection(DoNotDealList)
      .doc(company_number);

    const updateDocFields = {};

    docFieldList.forEach(({ nameOfDocField, value }) => {
      updateDocFields[nameOfDocField] = value;
    });

    try {
      await ref.update(updateDocFields);
    } catch (e) {
      console.log('Failed Error: UPDATE, update do not deal', e);
      BugTracker.notify('Failed Error: UPDATE, update do not deal', e);
    }

    await getDoNotDealList();
    await getAllHistory();
  };

  const getDoNotDealList = async () => {
    const ref = firebase.firestore().collection(DoNotDealList);
    return ref
      .get()
      .then((res) => {
        const doNotDealList: IDontDeal[] = [];
        res.forEach((doc) => doNotDealList.push(doc.data() as IDontDeal));
        dispatch({ type: SET_DND_USERS, payload: doNotDealList });
        return doNotDealList;
      })
      .catch((e) => {
        console.log('Failed Error: GET, fetch do not deal list', e);
        BugTracker.notify('Failed Error: GET, fetch do not deal list', e);
      });
  };

  const addToHistory = async () => {
    const timeStampNow = firebase.firestore.Timestamp.fromDate(new Date());

    const subRef = await firebase
      .firestore()
      .collection(DoNotDealList)
      .doc(company_number)
      .collection(History);

    subRef
      .add({
        ...badCompany,
        dateRemoved: timeStampNow
      })
      .then(async (docRef) => {
        // add the doc id to the newly created badcompany within the history subcolleciton as docId
        await docRef.update({ docId: docRef.id }).catch((e) => {
          console.log(
            'Failed Error - UPDATE, did not update with the desired docId',
            e
          );
          BugTracker.notify(
            'Failed Error - UPDATE, did not update with the desired docId',
            e
          );
        });
      })
      .catch((e) => {
        console.log('Failed Error: ADD, add previous company report', e);
        BugTracker.notify('Failed Error: ADD, add previous company report', e);
      });
  };

  /**GET all History subcollection list
   * this will update the exported historyList state
   */
  const getAllHistory = async () => {
    if (company_number.length > 0) {
      const subRef = await firebase
        .firestore()
        .collection(DoNotDealList)
        .doc(company_number)
        .collection(History);

      // sort by date of removal
      const sortedDateRef = subRef.orderBy('dateRemoved', 'desc');

      return await sortedDateRef
        .get()
        .then((res) => {
          const HistoryList: IDontDeal[] = [];

          res.forEach((doc) => {
            HistoryList.push(doc.data() as IDontDeal);
          });

          setHistoryList(HistoryList);

          return HistoryList;
        })
        .catch((e) => {
          console.log(
            'Failed Error: GET, previous company report subcollection',
            e
          );
          BugTracker.notify(
            'Failed Error: GET, previous company report subcollection',
            e
          );
        });
    }
  };

  React.useEffect(() => {
    getAllHistory();
  }, [badCompany]);

  return {
    addToDoNotDealList,
    badCompany,
    doNotDealList,
    getDoNotDealList,
    removeFromDoNotDealList,
    updateDoNotDealListEntry,
    updateMultipleDoNotDealListEntry,
    getAllHistory,
    addToHistory,
    historyList
  };
};
