import { graphConfig } from './authConfig';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { BugTracker } from 'Utils/Bugtracker';
import { Message } from '@microsoft/microsoft-graph-types';

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */

interface IGetProps {
  accessToken: string;
  action: string;
  queryParams?: { [key: string]: string };
}

interface IDetailProps {
  accessToken: string;
  url: string;
  method: 'GET' | 'POST';
  data?: any;
  queryParams?: { [key: string]: string };
}

export const callMsGraph = async ({
  accessToken,
  action,
  parentFolderId,
  queryParams = {}
}: {
  accessToken: string;
  action: string;
  parentFolderId?: string;
  queryParams?: {};
}) => {
  let url = graphConfig[action];

  if (parentFolderId) {
    if (action === 'ChildFolders') {
      url = `https://graph.microsoft.com/v1.0/me/mailFolders/${parentFolderId}/childFolders`;
    } else if (action === 'ParentFolders') {
      url = `https://graph.microsoft.com/v1.0/me/mailFolders/${parentFolderId}/messages`;
    }
  }

  const config = {
    url,
    method: 'GET' as Method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
      IdType: 'ImmutableId'
    },
    params: queryParams
  };

  return axios(config)
    .then((res) => {
      if (action === 'messages') {
        // Filtering Out Duplicates From The API
        const seenMessageHashes = new Set();
        const filteredMessages = res.data.value.filter((message: Message) => {
          const hash = `${message.subject}-${message.sender?.emailAddress?.name}-${message.body?.content}`;
          if (seenMessageHashes.has(hash)) {
            return false;
          } else {
            seenMessageHashes.add(hash);
            return true;
          }
        });

        res.data.value = filteredMessages;
      }

      return res.data;
    })
    .catch((e) => {
      console.log('API error:', e);
      BugTracker.notify(e);
    });
};

export const callMsGraphDetailed = async ({
  accessToken,
  url,
  method,
  data,
  queryParams = {}
}: IDetailProps) => {
  const config: AxiosRequestConfig = {
    url,
    method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
      IdType: 'ImmutableId'
    },
    params: queryParams,
    data
  };

  try {
    const response = await axios(config);
    // console.log('API call successful:', {
    //   status: response?.status,
    //   hasData: !!response?.data,
    //   url: url
    // });
    return response;
  } catch (e) {
    // console.log('API error:', {
    //   error: e,
    //   url: url
    // });

    BugTracker.notify(e);
    return null;
  }
};

interface IPostProps extends IGetProps {
  data: any;
}

export const postGraph = async ({ accessToken, action, data }: IPostProps) => {
  const config: AxiosRequestConfig = {
    url: graphConfig[action],
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'content-type': 'application/json',
      IdType: 'ImmutableId'
    },
    data
  };
  return axios(config)
    .then((res) => res)
    .catch((e) => BugTracker.notify(e));
};
