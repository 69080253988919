import React from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import Dashboard from 'layouts/Dashboard';
import { BugTracker } from 'Utils/Bugtracker';
const Component = () => {
  React.useEffect(() => {
    axios({
      method: 'GET',
      url: 'https://apidojo-yahoo-finance-v1.p.rapidapi.com/auto-complete',
      headers: {
        'content-type': 'application/octet-stream',
        'x-rapidapi-host': 'apidojo-yahoo-finance-v1.p.rapidapi.com',
        'x-rapidapi-key': '310035dccfmsha33cb15f86adac6p15eb33jsn9683d1d5431f',
        useQueryString: true
      },
      params: {
        region: 'US',
        q: 'tesla'
      }
    })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        // console.log({ error });
        BugTracker.notify(error);
      });
  }, []);

  return <Dashboard title="Forex Tracker">FOREX TRACKER</Dashboard>;
};
export default Component;
