import { capitalize, Grid, MenuItem, TextField } from '@material-ui/core';
import { Schedule, AccessTime, Repeat } from '@material-ui/icons';
import { useContext, useEffect } from 'react';
import { theme } from 'theme';
import { CalendarContext } from '../../context/CalendarContext';
import { EventContext } from '../../context/EventContext';
import { DateTimePicker } from '@material-ui/pickers';
import { ERecurringEvent } from 'components/Calendar/interface';

const CalendarTimeBody = () => {
  const { edit } = useContext(CalendarContext);
  return (
    <div
      style={{
        border: `2px ${theme.palette.grey[200]} solid`,
        padding: theme.spacing(1),
        borderRadius: theme.shape.borderRadius
      }}>
      <CalendarDate />
      <div style={{ paddingBottom: theme.spacing(1) }} />
      <CalendarRecurring />
    </div>
  );
};

export default CalendarTimeBody;

const CalendarDate = () => {
  const { formValues, setError, error } = useContext(CalendarContext);
  const { handleDateChange } = useContext(EventContext);

  useEffect(() => {
    if (
      !formValues.endDate &&
      formValues.recurring === ERecurringEvent.DoesNotRepeat
    ) {
      setError({
        reason: "End Date Is Required For 'Does Not Repeat' Events",
        mark: true,
        component: 'Date'
      });
    } else if (!formValues.startDate) {
      setError({
        reason: 'Start Date Is Required For Events',
        mark: true,
        component: 'Date'
      });
    } else {
      setError({
        reason: '',
        mark: false,
        component: 'None'
      });
    }
  }, [formValues]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}>
      <Grid
        data-cy="date-picker-start"
        item
        xs={formValues.recurring !== ERecurringEvent.DoesNotRepeat ? 12 : 6}>
        <DateTimePicker
          label="Start Time"
          inputVariant="outlined"
          value={formValues.startDate}
          onChange={handleDateChange('startDate')}
          fullWidth
          margin="normal"
          InputProps={{
            startAdornment: (
              <AccessTime
                fontSize="medium"
                style={{ paddingRight: theme.spacing(1) }}
              />
            )
          }}
        />
      </Grid>
      {formValues.recurring === ERecurringEvent.DoesNotRepeat && (
        <Grid data-cy="date-picker-end" item xs={6}>
          <DateTimePicker
            label="End Time"
            inputVariant="outlined"
            value={formValues.endDate}
            onChange={handleDateChange('endDate')}
            fullWidth
            error={error.component === 'Date' ? error.mark : false}
            helperText={error.component === 'Date' ? error.reason : ''}
            margin="normal"
            InputProps={{
              startAdornment: (
                <Schedule
                  fontSize="medium"
                  style={{ paddingRight: theme.spacing(1) }}
                />
              )
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

const CalendarRecurring = () => {
  const { formValues, setFormValues } = useContext(CalendarContext);
  const { handleRecurringChange, handleInputChange, handleDateChange } =
    useContext(EventContext);

  let day = capitalize(
    formValues.startDate.toLocaleString('en-GB', { weekday: 'long' })
  );

  let date = formValues.startDate.getDate();
  let month = formValues.startDate.toLocaleString('en-GB', { month: 'long' });

  const getOrdinalSuffix = (date: number) => {
    if (date > 3 && date < 21) return 'th';
    switch (date % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  // useEffect(() => {
  //   let newEndDate = new Date(formValues.startDate);
  //   switch (formValues.recurring) {
  //     case ERecurringEvent.Daily:
  //       newEndDate.setDate(newEndDate.getDate() + 1);
  //       break;
  //     case ERecurringEvent.Weekly:
  //       newEndDate.setDate(newEndDate.getDate() + 7);
  //       break;
  //     case ERecurringEvent.Monthly:
  //       newEndDate.setMonth(newEndDate.getMonth() + 1);
  //       break;
  //     case ERecurringEvent.Quarterly:
  //       newEndDate.setMonth(newEndDate.getMonth() + 3);
  //       break;
  //     case ERecurringEvent.Annually:
  //       newEndDate.setFullYear(newEndDate.getFullYear() + 1);
  //       break;
  //     default:
  //       newEndDate = formValues.endDate;
  //       break;
  //   }
  //   setFormValues((prevValues) => ({
  //     ...prevValues,
  //     endDateRRule: newEndDate
  //   }));
  // }, [formValues.recurring, formValues.startDate, setFormValues]);

  return (
    <Grid container spacing={2}>
      <Grid
        data-cy="repeat-notice"
        item
        xs={formValues.recurring !== ERecurringEvent.DoesNotRepeat ? 4 : 12}>
        <TextField
          variant="outlined"
          fullWidth
          select
          label="Recurring"
          value={formValues.recurring}
          onChange={handleRecurringChange}
          InputProps={{
            startAdornment: (
              <Repeat style={{ paddingRight: theme.spacing(1) }} />
            )
          }}>
          <MenuItem value={ERecurringEvent.DoesNotRepeat}>
            Does Not Repeat
          </MenuItem>
          <MenuItem value={ERecurringEvent.Daily}>Daily</MenuItem>
          <MenuItem
            value={ERecurringEvent.Weekly}>{`Weekly On ${day}`}</MenuItem>
          <MenuItem
            value={
              ERecurringEvent.Monthly
            }>{`Monthly On The ${date}${getOrdinalSuffix(date)}`}</MenuItem>
          <MenuItem
            value={
              ERecurringEvent.Quarterly
            }>{`Quarterly On The ${date}${getOrdinalSuffix(date)}`}</MenuItem>
          <MenuItem
            value={
              ERecurringEvent.Annually
            }>{`Annually On ${month} ${date}`}</MenuItem>
        </TextField>
      </Grid>
      {formValues.recurring !== ERecurringEvent.DoesNotRepeat && (
        <>
          <Grid item xs={4}>
            <DateTimePicker
              required
              label="End Recurrence Date"
              inputVariant="outlined"
              value={formValues.endDateRRule || formValues.endDate}
              onChange={handleDateChange('endDateRRule')}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              required
              label="Interval"
              variant="outlined"
              fullWidth
              disabled={formValues.recurring === ERecurringEvent.Quarterly}
              name="interval"
              type="number"
              value={
                formValues.recurring === ERecurringEvent.Quarterly
                  ? 3
                  : formValues.interval
              }
              onChange={handleInputChange}
              InputProps={{
                inputProps: { min: 1 }
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
