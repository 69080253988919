import { Grid, Typography } from '@material-ui/core';
import { useCalculationStyles } from '../../styles';
import { _Balloon } from 'types/calculatorInterfaces';
import { format } from '../../..';

const BalloonItem = ({ balloon }: { balloon: _Balloon }) => {
  const classes = useCalculationStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Amount</Typography>
        <Typography>£{format(balloon.Balloon.toString())}</Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>VAT</Typography>
        <Typography>£{format(balloon.Balloon_Vat.toString())}</Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>VAT Rate</Typography>
        <Typography>{balloon.Balloon_VatRate}%</Typography>
      </Grid>
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Payment Taken</Typography>
        <Typography>
          {`Contract Period ${balloon.ContractPeriod === 0 ? '' : '+1'} `}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default BalloonItem;
