import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' },
  detail: { padding: theme.spacing(2), margin: theme.spacing(2) }
}));

const Component = ({ company }) => {
  const classes = useStyles();
  return (
    <Paper elevation={3} className={classes.root}>
      <Table
        title="Mortgage"
        data={company.mortgage}
        columns={[
          { title: 'Holder', field: 'attributes.holder' },
          { title: 'Details', field: 'attributes.details' },
          // { title: 'Doc. No.', field: 'attributes.form_no' },
          // { title: 'Type', field: 'attributes.type' },
          { title: 'Satisfied', field: 'attributes.satisfied' },
          { title: 'Date Created', field: 'attributes.date_created' },
          { title: 'Satisfied Date', field: 'attributes.satisfied_date' }
        ]}
      />
    </Paper>
  );
};

export default Component;
