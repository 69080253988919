import { AxiosRequestConfig } from 'axios';
import { RelationShipActions } from '.';
import { dbQueue } from '../Interceptor';

interface RelationshipsAPIInterface {
  action: RelationShipActions;
  PrimaryUserInstanceId?: null | string;
  RelatedUserInstanceId?: null | string;
  Title?: null | string;
  data?: any;
  UserInstanceId?: null | string;
  UserDefinitionId?: null | string;
  keyword?: null | string;
  Status?: number | null;
  ProcessInstanceId?: number;
  AssignedUserDefinitionId?: string;
  RelatedUserDefinitionId?: string;
}

export const OldRelationshipsAPI = async ({
  action,
  data,
  UserInstanceId,
  UserDefinitionId,
  PrimaryUserInstanceId,
  RelatedUserInstanceId,
  keyword,
  Title,
  Status,
  ProcessInstanceId,
  AssignedUserDefinitionId,
  RelatedUserDefinitionId
}: RelationshipsAPIInterface) => {
  let url: string;
  let method: 'get' | 'post' = 'get';

  switch (action) {
    case 'LISTFORKEYWORD':
      url = `RelationshipAPI.ashx?action=${action}&userinstanceid=${UserInstanceId}&keyword=${keyword}`;
      break;

    case 'DELETE':
      url = `RelationshipAPI.ashx?action=${action}&primaryuserinstanceid=${PrimaryUserInstanceId}&relateduserinstanceid=${RelatedUserInstanceId}`;
      break;

    case 'CREATE':
      method = 'post';
      url = `RelationshipAPI.ashx?action=${action}`;
      break;

    case 'POST':
      data = {
        PrimaryUserInstanceId,
        RelatedUserInstanceId,
        Title
      };
      method = 'post';
      url = `RelationshipAPI.ashx?action=${action}&primaryuserinstanceid=${PrimaryUserInstanceId}&relateduserinstanceid=${RelatedUserInstanceId}`;
      break;

    case 'RELATEDTOASSIGNED':
      url = `RelationshipAPI.ashx?action=${action}&processinstanceid=${ProcessInstanceId}&assigneduserdefinitionid=${AssignedUserDefinitionId}&relateduserdefinitionid=${RelatedUserDefinitionId}`;
      break;

    default:
      url = `RelationshipAPI.ashx?action=${action}&userinstanceid=${UserInstanceId}&userdefinitionid=${UserDefinitionId}`;
      break;
  }

  let config: AxiosRequestConfig = { method, url, data };
  return await dbQueue({ config, url });
};
