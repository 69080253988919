import { Calculation } from 'types/calculatorInterfaces';
import { FunderDoc } from '../../interfaces';
import { EVatProfile } from './interface';

const FunderInstalment = ({
  FUNDER_DOC,
  Output
}: {
  FUNDER_DOC: FunderDoc;
  Output: Calculation;
}) => {
  const isLease = (product: string) =>
    product.toLowerCase().startsWith('lease');

  FUNDER_DOC.INSTALMENTS_NUMBER = Output['Term'];
  FUNDER_DOC.INSTALMENTS_TYPE = Output['PaymentFrequency'];
  FUNDER_DOC.INSTALMENTS_AMOUNT = Output['MonthlyPayment'];
  FUNDER_DOC.INSTALMENTS_DATE = `${Output['StartDate']}`;
  FUNDER_DOC.VAT_DEFERRAL_PERIOD = 0;
  FUNDER_DOC.VAT_DEFERRAL_AMOUNT = 0;
  if (Output['_NetAdvance']?.Vat_Payment === 'DEFER_VAT') {
    FUNDER_DOC.VAT_DEFERRAL_PERIOD = Output['_NetAdvance']?.VatDeferral;
    FUNDER_DOC.VAT_DEFERRAL_AMOUNT = Output['_NetAdvance']?.VatTotal;
  }
  if (
    Output['_NetAdvance'] &&
    Output['_NetAdvance'].Vat_Payment === 'VAT_UPFRONT'
  ) {
    FUNDER_DOC.VAT_DEFERRAL_PERIOD = 0;
    FUNDER_DOC.VAT_DEFERRAL_AMOUNT = 0;
  }

  /** TERMINATION AND POSSESSION */
  FUNDER_DOC.HALF_AMOUNT_PAYABLE = Output?.Halves;
  FUNDER_DOC.THIRD_AMOUNT_PAYABLE = Output?.Thirds;

  /** PAYMENT REQUIRED ON SIGNING */
  if (
    Output?.['_NetAdvance']?.Vat_Payment === EVatProfile.ADD_VAT_TO_NET ||
    Output?.['_NetAdvance']?.Vat_Payment === EVatProfile.DEFER_VAT
  ) {
    // LEASE DEAL - you don’t pay all the VAT upfront on a Lease
    if (isLease(FUNDER_DOC.FINANCE_PRODUCT)) {
      FUNDER_DOC.VAT_PAID_UPFRONT_AMOUNT = 0;
      FUNDER_DOC.VAT_PAID_UPFRONT_VAT = 0;
      FUNDER_DOC.VAT_PAID_UPFRONT_TOTAL = 0;
    }
  } else {
    // HP DEAL
    const VAT_TOTAL =
      Output['_NetAdvance']?.Purchase_Vat -
      FUNDER_DOC.V_VAT_ON_NET_PART_EXCHANGE;

    FUNDER_DOC.VAT_PAID_UPFRONT_AMOUNT = VAT_TOTAL;
    FUNDER_DOC.VAT_PAID_UPFRONT_VAT = 0;
    FUNDER_DOC.VAT_PAID_UPFRONT_TOTAL = VAT_TOTAL;
  }

  FUNDER_DOC.ADVANCE_PAYMENTS_AMOUNT =
    Output['InitialRentals'] * Output['MonthlyPayment']; // Amount – the sum of the initial rentals;
  FUNDER_DOC.ADVANCE_PAYMENTS_VAT =
    FUNDER_DOC.ADVANCE_PAYMENTS_AMOUNT *
    (Output['_NetAdvance']?.Purchase_VatRate / 100); // VAT – the VAT on those initial rentals (only payable on a Lease)
  FUNDER_DOC.ADVANCE_PAYMENTS_TOTAL =
    FUNDER_DOC.ADVANCE_PAYMENTS_AMOUNT + FUNDER_DOC.ADVANCE_PAYMENTS_VAT; // Total – the initial rental amount plus the VAT on that sum

  FUNDER_DOC.ADDITIONAL_DEPOSIT_AMOUNT = Output['_NetAdvance']?.Deposit;
  FUNDER_DOC.ADDITIONAL_DEPOSIT_VAT = Output['_NetAdvance']?.Deposit_Vat;
  FUNDER_DOC.ADDITIONAL_DEPOSIT_TOTAL =
    FUNDER_DOC.ADDITIONAL_DEPOSIT_AMOUNT + FUNDER_DOC.ADDITIONAL_DEPOSIT_VAT;

  FUNDER_DOC.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_AMOUNT =
    FUNDER_DOC.VAT_PAID_UPFRONT_AMOUNT +
    FUNDER_DOC.ADVANCE_PAYMENTS_AMOUNT +
    FUNDER_DOC.ADDITIONAL_DEPOSIT_AMOUNT +
    FUNDER_DOC.DOCUMENTATION_FEE_AMOUNT;

  FUNDER_DOC.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_VAT =
    FUNDER_DOC.VAT_PAID_UPFRONT_VAT +
    FUNDER_DOC.ADVANCE_PAYMENTS_VAT +
    FUNDER_DOC.ADDITIONAL_DEPOSIT_VAT +
    FUNDER_DOC.DOCUMENTATION_FEE_VAT;

  if (isLease(FUNDER_DOC.FINANCE_PRODUCT)) {
    FUNDER_DOC.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL =
      FUNDER_DOC.VAT_PAID_UPFRONT_AMOUNT +
      FUNDER_DOC.ADVANCE_PAYMENTS_AMOUNT +
      Output['_NetAdvance']?.Deposit +
      Output['_DocumentFee'].DocFee;
  } else {
    FUNDER_DOC.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL =
      FUNDER_DOC.VAT_PAID_UPFRONT_TOTAL +
      FUNDER_DOC.ADVANCE_PAYMENTS_TOTAL +
      FUNDER_DOC.ADDITIONAL_DEPOSIT_TOTAL +
      FUNDER_DOC.DOCUMENTATION_FEE_TOTAL;
  }

  return FUNDER_DOC;
};

export default FunderInstalment;
