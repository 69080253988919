// RecentDeals component will show a list of recent deals user has done

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Chip, Typography, Paper, Tooltip } from '@material-ui/core';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import {
  AiFillCloseSquare,
  AiFillCheckSquare,
  AiFillPlusSquare
} from 'react-icons/ai';

import Time from 'common/Time';
import { CustomStatusRender } from 'components/ProcessTable/functions/ColumRendering/rendering';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 300,
    overflow: 'auto',
    borderRadius: 3,
    borderWidth: 0,
    padding: theme.spacing(1)
  },
  listitem: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    display: 'flex',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      background: `linear-gradient(0.25turn, ${theme.palette.primary['lighttrans']}, ${theme.palette.primary.light})`,
      cursor: 'pointer',
      color: theme.palette.primary.contrastText,
      borderRight: `30px solid ${theme.palette.primary.light}`
    }
  },
  icon: {}
}));

interface IRecentDeals {
  loading: boolean;
  openDeal: (props: {
    ProcessInstanceId: number;
    ProcessDefinitionId: number;
    ProcessStepDefinitionId: number;
  }) => void;
  data: () => ICustomDealData[];
}

interface ICustomDealData {
  AssignedUserList: IAssignedUser[];
  Guid: string;
  LastModified: string;
  ProcessDefinitionId: number;
  ProcessDefinitionTitle: string;
  ProcessInstanceId: number;
  ProcessInstanceTitle: string;
  ProcessStepDefinitionId: number;
  ProcessStepDefinitionTitle: string;
  ProcessStepGroupList: [
    {
      ProcessStepGroupId: number;
      ProcessStepGroupLowestMandatoryStatus: number;
      ProcessStepGroupTitle: string;
    }
  ];
  SelectedFieldList: [];
  ThirdPartyId: string;
  UserDefinitionId: number;
  UserDefinitionTitle: string | null;
  UserInstanceId: number;
  UserInstanceTitle: string;
}

interface IAssignedUser {
  ProxyUserInstanceId: number;
  UserDefinitionId: number;
  UserDefinitionTitle: string;
  UserInstanceId: number;
  UserInstanceTitle: string;
}

const RecentDeals = (props: IRecentDeals) => {
  const { loading, openDeal, data } = props;
  const theme = useTheme();
  const classes = useStyles();

  if (loading) return <div />;

  return (
    <div className={classes.container}>
      {data().map((props: ICustomDealData, i) => {
        const {
          ProcessStepGroupList,
          ProcessInstanceTitle,
          UserInstanceTitle,
          ProcessDefinitionTitle,
          LastModified,
          AssignedUserList
        } = props;

        const findCustomers = AssignedUserList.find(
          (element: IAssignedUser) =>
            element.UserDefinitionId === 523 ||
            element.UserDefinitionTitle.includes('Customers')
        );

        if (i > 3) return <div />;

        return (
          <div key={i} style={{ paddingBottom: theme.spacing(1) }}>
            <Paper
              variant="outlined"
              className={classes.listitem}
              onClick={() =>
                openDeal({
                  ProcessInstanceId: props.ProcessInstanceId,
                  ProcessDefinitionId: props.ProcessDefinitionId,
                  ProcessStepDefinitionId: props.ProcessStepDefinitionId
                })
              }>
              <div>
                {/* deal title */}
                <Typography style={{ fontWeight: 'bold' }}>
                  {findCustomers?.UserInstanceTitle}
                </Typography>
                <Typography color="primary">
                  {ProcessDefinitionTitle}
                </Typography>
                <Typography variant="body2" color="primary">
                  <Time time={LastModified} type="timeago" />
                </Typography>
                <div style={{ padding: 5 }} />
                <CustomStatusRender value={props} />
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center">
                  <Grid item>
                    <Chip
                      disabled
                      style={{ width: '100%', marginTop: 8 }}
                      label={
                        <Typography align="left" variant="body2">
                          Broker Owner: {UserInstanceTitle}
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>

                  <div style={{ padding: 5, paddingTop: 10 }}>-</div>

                  <Grid item>
                    <Chip
                      disabled
                      style={{ width: '100%', marginTop: 8 }}
                      label={
                        <Typography align="left" variant="body2">
                          Deal Name: {ProcessInstanceTitle}
                        </Typography>
                      }
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                </Grid>
              </div>

              <div
                style={{
                  flexGrow: 1,
                  display: 'flex',
                  alignItems: 'center'
                }}>
                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                />
                <DoubleArrowIcon className={classes.icon} />
              </div>
            </Paper>
          </div>
        );
      })}
    </div>
  );
};

export default RecentDeals;
