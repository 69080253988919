import React from 'react';
import {
  Typography,
  Button,
  Paper,
  Grid,
  ButtonGroup
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { DialogVRM } from '../../../../VRM/DialogVRM';
import { handleUpdateField } from '../../functions';
import { useTypedSelector } from 'redux/reducers';

const Component = (props) => {
  const theme = useTheme();
  const { token } = useTypedSelector((s) => s.user.auth);

  const [isOpen, setIsOpen] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  console.log({ props });
  const isNotEmpty = props?.FieldInstance?.FieldValue?.length > 0;

  const onChange: any = handleUpdateField({
    setUpdating,
    token,
    props
  });

  const parseFieldInstance = () => {
    if (isNotEmpty) {
      return JSON.parse(props?.FieldInstance?.FieldValue);
    } else return '';
  };

  if (props.isOverview) {
    if (isNotEmpty) {
      return <Typography>{parseFieldInstance().regNumber}</Typography>;
    } else return null;
  }

  return (
    <div style={{ paddingTop: theme.spacing(1) }}>
      <ButtonGroup color="primary" variant="contained">
        <Button data-cy="add-vrm-btn" onClick={() => setIsOpen(true)}>
          {isNotEmpty ? 'Edit' : 'Add'} Reg Number
        </Button>

        {!updating && (
          <>
            {isNotEmpty && (
              <Button
                color="primary"
                onClick={async () => {
                  await onChange('');
                }}>
                Clear Reg
              </Button>
            )}
          </>
        )}
      </ButtonGroup>

      <br />
      {isNotEmpty && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(1)
          }}>
          <div>Registration Number:</div>
          <Typography
            style={{ fontWeight: 'bold', marginLeft: theme.spacing(1) }}>
            {parseFieldInstance().regNumber}
          </Typography>
        </div>
      )}
      {isOpen && (
        <DialogVRM
          FieldInstance={props?.FieldInstance}
          FieldDefinition={props?.FieldDefinition}
          FieldDefinitionList={props?.FieldDefinitionList}
          CompleteObjectInstance={props?.CompleteObjectInstance}
          isOpen={isOpen}
          refresh={props?.refresh}
          setIsOpen={setIsOpen}
        />
      )}
    </div>
  );
};
export default Component;
