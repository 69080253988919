import Extensions from 'components/Extensions';
import { Dashboard as DashboardLayout } from 'layouts';

const Component = () => {
  return (
    <DashboardLayout title="Extensions">
      <Extensions />
    </DashboardLayout>
  );
};

export default Component;
