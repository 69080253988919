import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * AUTH API.ashx
 */
export const SignIn = async ({ email, password }) => {
  let url = `AuthAPI.ashx`;
  const data = { UserInstanceEmail: email, UserInstancePassword: password };
  const config: AxiosRequestConfig = { url, method: 'post', data };
  return await dbQueue({ config, url, method: 'SignIn' });
};

export const RefreshToken = async ({ token }) => {
  let url = `AuthAPI.ashx`;
  const method = 'get';
  const config: AxiosRequestConfig = { url, headers: { Auth: token }, method };
  return await dbQueue({ config, url, method: 'get' });
};

export const PasswordResetLink = async ({ data }) => {
  let url = `AuthAPI.ashx?action=PASSWORDRESETLINK`;
  const config: AxiosRequestConfig = { method: 'POST', url, data };
  return await dbQueue({ config, url });
};
