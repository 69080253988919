import { InteractionStatus } from '@azure/msal-browser';
import { loginRequest } from 'services/microsoft/authConfig';
import { BugTracker } from 'Utils/Bugtracker';

export const useToken = async ({ isAuth, accounts, instance, inProgress }) => {
  const getValidAccessToken = () => {
    if (!isAuth && inProgress === InteractionStatus.None) return;
    const account = accounts[0];
    const accessTokenRequest = { ...loginRequest, account };
    return instance
      .acquireTokenSilent(accessTokenRequest)
      .then((accessTokenResponse) => accessTokenResponse.accessToken)
      .catch((e) => {
        // console.log('Failed to get token silently so having to pop up: ', e);
        BugTracker.notify(e);
        return instance
          .acquireTokenPopup(accessTokenRequest)
          .then((accessTokenResponse) => accessTokenResponse.accessToken)
          .catch((e) => {
            // console.log('Failed to get token via pop up: ', e);
            BugTracker.notify(e);
          });
      });
  };
  return await getValidAccessToken();
};
