import React, { useContext } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTypedSelector } from 'redux/reducers';
import { useDispatch } from 'react-redux';
import { CircularProgress, Typography, Grid, Paper } from '@material-ui/core';
import DealCompleteDialog from './components/Dialog/DealCompleteDialog';
import { StepSetUp, SubmittingRule } from './functions/stepperStepUp';
import { useHistory } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import StepTabs from './components/StepTabs';
import { getStepData } from 'redux/actions/processes/getStepData';
import ActionBar from './components/ActionBar';
import {
  CompleteProcessDefinition,
  CompleteProcessInstance,
  ProcessDefinition,
  ProcessStepGroup,
  UserInstance,
  CompleteProcessStepDefinitionDict
} from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import { useProcess, useGlobal, useMobile } from 'hooks';
import { useRegulated } from 'hooks/useRegulated';
import DealButtons from './components/DealButtons';
import { FileSection } from 'views/LandingPage/MobileView';
import useRequiredCompletion from 'hooks/useDeals/useRequiredCompletion';
import useConfirmAcceptLandingPage from 'hooks/useDeals/useConfirmAccept';
import { DealSummary } from 'components/DealSummary';
import StepperCalendar from './components/Calander';
import useDealAnalytics from 'hooks/useDeals/useDealAnalytics';
import { StepperContext } from './context';
import { AlertTitle } from '@material-ui/lab';
// import MessageLog from './components/Overview/components/MessageLog';

const useStyles = makeStyles((theme) => ({
  root: { width: '100%', display: 'flex', flexDirection: 'row' },
  gridItem: {
    paddingTop: theme.spacing(1)
  },
  appBar: { borderRadius: theme.shape.borderRadius },
  button: { marginRight: theme.spacing(1) },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  steps: { overflowX: 'scroll' },
  overlay: { opacity: 0.3 },
  ToolBar: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  titlebk: {
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.primary.light,
    borderRadius: theme.shape.borderRadius
  },
  arrows: { fontSize: 48, padding: 0 },
  assign: {},
  warning: { color: theme.palette.warning.main }
}));

const GateWay = (props) => {
  const { landingpage } = useTypedSelector((s) => s.utility);
  const { target } = useTypedSelector((s) => s.communication);
  const { currentDeal, currentProcess, currentStepId, user, token } =
    useProcess();
  const currentStepCanCompleteList: any = useTypedSelector(
    (state) => state.process.currentStep.UserInstanceDictForCurrentStep
  );
  let parsed: any = landingpage ? props.parsed : undefined;

  if (target && target.psdid)
    parsed = { psdid: target.psdid, pdid: target.pdid, piid: target.piid };

  const bigProps = {
    ...props,
    currentDeal,
    currentProcess,
    currentStepCanCompleteList,
    currentStepId,
    user,
    target,
    token,
    parsed
  };

  try {
    if (
      currentDeal === undefined ||
      Object.values(currentDeal).length === 0 ||
      currentProcess === undefined ||
      currentStepId === undefined ||
      currentStepCanCompleteList === undefined ||
      user === undefined ||
      token === undefined
    ) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: `30vh`
          }}>
          <Alert
            variant="outlined"
            severity="warning"
            style={{ textAlign: 'center' }}>
            <Typography>
              It looks like we are having difficulty getting the data for this{' '}
              <b>Deal.</b>
              There could be a few reasons why this could be happening:
            </Typography>
            <br />
            <Typography>
              1. Could not load the <b>Current Deal Data</b>. If you have
              clicked this <b>Deal</b> by <b>Notification</b> then please exit
              this, and proceed to the Deal connected to this{' '}
              <b>Notification</b>
            </Typography>
            <br />
            <Typography>
              2. We cannot display the <b>Active step</b> tab. You may find
              critical data is missing under the other tabs.
            </Typography>
            <br />
            <Typography>
              Please report this to the system administrators.
            </Typography>
            <br />
            <Typography>
              You can attempt to refresh the deal data in the top right, and if
              your issue is to do with intermittent internet connectivity this
              may solve the problem.
            </Typography>
          </Alert>
        </div>
      );
    }
  } catch (e) {
    BugTracker.notify(e);
  }
  return <Component {...bigProps} />;
};

interface IStepper {
  ProcessDefinition: ProcessDefinition;
  currentDeal: CompleteProcessInstance;
  currentProcess: CompleteProcessDefinition;
  currentStepCanCompleteList: any;
  currentStepId: number;
  dealRefreshing: boolean;
  parsed: any | undefined;
  refreshDealData: (activeStep: number, move: boolean) => void;
  token: string;
  user: UserInstance;
  activeStep: number;
}

const Component = (props: IStepper) => {
  const theme = useTheme();
  const {
    parsed,
    refreshDealData,
    currentDeal,
    currentProcess,
    currentStepCanCompleteList,
    currentStepId,
    user,
    token
  } = props;
  const { config } = useGlobal();
  const isMobile = useMobile();
  const { loadingAccept, setSuccess, setOpen, open } =
    useConfirmAcceptLandingPage({ currentDeal, parsed, user });

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isDealClosed,
    isDealTransferred,
    ReqCmp,
    handleUpdateReqCmp,
    ReqFields
  } = useContext(StepperContext);

  const [loadingStepData, setLoadingStepsData] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeDealStep, setActiveDealStep] = React.useState(0);
  const [stepGroupIndex, setStepGroupIndex] = React.useState(0);
  const [selected, setSelected] = React.useState<any>(null);
  const [dialogInfo, setDialogInfo] = React.useState<any>({
    title: null,
    message: null
  });
  const [complete, setComplete] = React.useState(false);

  const [loadNextStepSelect, setLoadNextStepSelect] = React.useState(false);
  const [landingStep, setLandingStep] = React.useState<number | null>(null);
  const landingpage = useTypedSelector((s) => s.utility.landingpage);

  const steps: CompleteProcessStepDefinitionDict =
    currentProcess.CompleteProcessStepDefinitionDict;
  const ProcessStepGroupDict: any = currentProcess.ProcessStepGroupDict;
  const ProcessGroupList: ProcessStepGroup[] =
    Object.values(ProcessStepGroupDict);
  const { ProcessStepDefinitionSequence } = currentProcess;

  const ProcessStepDefinitionId: number =
    currentDeal?.ProcessInstance?.ProcessStepDefinitionId;

  // Get the title of the current step
  const ProcessStepDefinitionTitle: string | undefined =
    currentProcess?.CompleteProcessStepDefinitionDict?.[currentStepId]
      ?.ProcessStepDefinition?.Title;

  React.useEffect(() => {
    const index = ProcessStepDefinitionSequence?.[activeStep];
    dispatch({ type: 'SET_CURRENT_STEP_ID', payload: index });
  }, [activeStep]);

  const handleStepChange = async (newValue: number, stepGroupIndex: number) => {
    const isDiscoveryGroup =
      ProcessGroupList[stepGroupIndex]?.Title.toLowerCase() === 'discovery';

    if (!parsed && isDiscoveryGroup) {
      const index: number =
        ProcessGroupList[stepGroupIndex].ProcessStepDefinitionIdList[newValue];

      const newActiveStep: number = ProcessStepDefinitionSequence.findIndex(
        (e: number) => e === index
      );

      if (!landingStep) setActiveStep(newActiveStep);
      setLoadingStepsData(true);

      const success = await getStepData({
        index,
        ProcessInstanceId: currentDeal?.ProcessInstance?.Id,
        ProcessDefinitionId: currentProcess?.ProcessDefinition?.Id
      });

      if (success) setLoadingStepsData(false);
    }
  };

  const SubmitRule = () => {
    return SubmittingRule({
      token,
      currentDeal,
      currentProcess,
      selected,
      parsed,
      currentStepId,
      ProcessStepDefinitionTitle,
      setComplete,
      setLoadNextStepSelect,
      setSelected,
      setSuccess
    });
  };

  React.useEffect(() => {
    handleUpdateReqCmp();
  }, [currentDeal, config, currentStepId]);

  React.useEffect(() => {
    // This Happens When Refreshing Deal (Send User Back To Main Step)
    setLoadingStepsData(props.dealRefreshing);
    setActiveStep(props.activeStep);
    setStepGroupIndex(props.activeStep);
  }, [props.activeStep]);

  /**
   * ACTIVE STEPS ON STEP CHANGES
   */

  React.useEffect(() => {
    const fetchData = async () => {
      await StepSetUp({
        landingPage: landingpage,
        HandleUpdateReqCmp: handleUpdateReqCmp,
        ProcessStepDefinitionSequence,
        parsed,
        ProcessGroupList,
        setStepGroupIndex,
        setActiveDealStep,
        setLandingStep,
        setActiveStep,
        ProcessStepDefinitionId,
        user,
        currentProcess,
        currentDeal,
        token
      });
    };

    fetchData();
  }, []);

  // show or hide the ChildTabs component (the second nav tab) when user clicks on ParentTabs component
  const [show, setShow] = React.useState<boolean>(false);

  // options from the ParentTabs: 'discovery' / 'instruction' / 'application' / 'completion'

  const [tabData, setTabData] = React.useState({});
  const [visibleTabContent, setVisibleTabContent] = React.useState<
    string | null
  >(null);

  const [currentSelectedTitle, setCurrentSelectedTitle] = React.useState('');

  React.useEffect(() => {
    const allTabData = {};
    Object.values(ProcessStepGroupDict).forEach((StepGroup: any) => {
      const titleKey = StepGroup.Title.toLowerCase();
      allTabData[titleKey] = {
        id: StepGroup.Id,
        title: StepGroup.Title,
        ProcessStepDefinitionIdList: StepGroup.ProcessStepDefinitionIdList
      };
    });

    setTabData(allTabData);
  }, [ProcessStepGroupDict, currentProcess.CompleteProcessStepDefinitionDict]);

  const handleArrowIconClick = (Title: string, index: number) => {
    const normalizedTitle = Title.toLowerCase();
    if (currentSelectedTitle === normalizedTitle) {
      setVisibleTabContent(show ? null : normalizedTitle);
      setShow(!show);
    } else {
      setVisibleTabContent(normalizedTitle);
      setShow(true);
      setCurrentSelectedTitle(normalizedTitle);
      setStepGroupIndex(index);
    }
  };

  const ableToSubmit = () => {
    let result = false;
    let requiredFields = ReqCmp._reqfieldsnum.length;
    let requiredPercent = ReqCmp._Percent_Required_Fields_Complete;

    if (selected) {
      result = true;
      if (requiredFields === 0) result = true;
      else {
        if (requiredPercent >= 100) result = true;
        else result = false;
      }
    } else result = false;

    return result;
  };

  if (loadingStepData)
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ paddingTop: '25%' }}>
          <CircularProgress size={50} />
        </Grid>
      </div>
    );

  return (
    <>
      <Grid
        container
        direction={landingpage ? 'column' : 'row'}
        style={{ padding: theme.spacing(2) }}
        spacing={isMobile ? 0 : 2}>
        {!landingpage && (
          <Grid item xs={9} className={classes.gridItem}>
            <Paper style={{ background: theme.palette.background.default }}>
              <StepTabs
                activeDealStep={activeDealStep}
                activeStep={activeStep}
                currentSelectedTitle={currentSelectedTitle}
                handleStepChange={handleStepChange}
                landingStep={landingStep}
                parsed={parsed}
                setCurrentSelectedTitle={setCurrentSelectedTitle}
                setShow={setShow}
                setStepGroupIndex={setStepGroupIndex}
                show={show}
                stepGroupIndex={stepGroupIndex}
                steps={steps}
                visibleTabContent={visibleTabContent}
                setVisibleTabContent={setVisibleTabContent}
                tabData={tabData}
                handleArrowIconClick={handleArrowIconClick}
              />
            </Paper>
          </Grid>
        )}

        <Grid item xs={landingpage ? 12 : 3} className={classes.gridItem}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              {isDealClosed ? (
                <Alert severity="info">
                  <AlertTitle>Deal Closed</AlertTitle>
                  <Typography variant="h6" color="textSecondary">
                    The deal has been closed and no further rules can be sent.
                    Please reactivate the deal if you wish to continue.
                  </Typography>
                </Alert>
              ) : isDealTransferred ? (
                <Alert severity="info">
                  <AlertTitle>Deal Transferred</AlertTitle>
                  <Typography variant="h6" color="textSecondary">
                    The deal has been transferred and no further rules can be
                    sent.
                  </Typography>
                </Alert>
              ) : (
                <ActionBar
                  abletosubmit={ableToSubmit}
                  activeDealStep={activeDealStep}
                  activeStep={activeStep}
                  complete={complete}
                  parsed={parsed}
                  currentStepCanCompleteList={currentStepCanCompleteList}
                  loadingAccept={loadingAccept}
                  loadingStepData={loadingStepData}
                  ProcessStepDefinitionTitle={ProcessStepDefinitionTitle}
                  refreshDealData={refreshDealData}
                  ReqCmp={ReqCmp}
                  ReqFields={ReqFields}
                  submit={SubmitRule}
                  setOpen={setOpen}
                  open={open}
                />
              )}
            </Grid>
            {landingpage && isMobile && (
              <Grid item>
                <FileSection />
              </Grid>
            )}

            {!landingpage && (
              <Grid item>
                <DealButtons />
              </Grid>
            )}
          </Grid>
        </Grid>

        {landingpage && (
          <Grid item xs={12} className={classes.gridItem}>
            <Paper style={{ background: theme.palette.background.default }}>
              <StepTabs
                activeDealStep={activeDealStep}
                activeStep={activeStep}
                currentSelectedTitle={currentSelectedTitle}
                handleStepChange={handleStepChange}
                landingStep={landingStep}
                parsed={parsed}
                setCurrentSelectedTitle={setCurrentSelectedTitle}
                setShow={setShow}
                setStepGroupIndex={setStepGroupIndex}
                show={show}
                stepGroupIndex={stepGroupIndex}
                steps={steps}
                visibleTabContent={visibleTabContent}
                setVisibleTabContent={setVisibleTabContent}
                tabData={tabData}
                handleArrowIconClick={handleArrowIconClick}
              />
            </Paper>
          </Grid>
        )}
      </Grid>

      {!landingpage && (
        <DealCompleteDialog
          handleClose={() => {
            setComplete(false);
            history.push('/dashboard');
          }}
          open={complete}
          message={dialogInfo.message}
          title={dialogInfo.title}
        />
      )}
    </>
  );
};

export default GateWay;
