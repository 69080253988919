const UltimateFinanceProposal = {
  productType: '',
  businessName: '',
  businessType: '',
  crn: '',
  applicantFirstName: '',
  applicantLastName: '',
  applicantEmail: '',
  applicantDoB: '',
  applicantType: '',
  TradingAddressLine1: '',
  TradingAddressLine2: '',
  TradingAddressLine3: '',
  TradingAddressCity: '',
  TradingAddressPostcode: '',
  RegAddressLine1: '',
  RegAddressLine2: '',
  RegAddressLine3: '',
  RegAddressCity: '',
  RegAddressPostcode: '',
  estPayoutDate: '',
  amountRequested: 0,
  introducerCode: '',
  brokerDealID: '',
  costIncVAT: 0,
  totalDeposit: 0,
  term: 0,
  salesComments: '',
  brokerRate: 0,
  balloonPayment: 0,
  targetYield: 0,
  goods: [
    {
      make: '',
      model: '',
      type: '',
      description: '',
      cost: 0,
      quantity: 0,
      condition: '',
      yearOfMake: 0,
      age: 0,
      vehicleRegNo: '',
      extras: '',
      currentMileage: '',
      supplierName: '',
      vatRate: 0
    }
  ],
  suppliers: [
    {
      name: '',
      businessRegNo: '',
      contactFirstName: '',
      contactSurname: '',
      contactEmail: ''
    }
  ],
  contacts: [
    {
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      homeAddressLine1: '',
      homeAddressLine2: '',
      homeAddressLine3: '',
      homeAddressCity: '',
      homeAddressCounty: '',
      homeAddressPostcode: ''
    }
  ]
};

export default UltimateFinanceProposal;
