import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Draggable } from 'react-beautiful-dnd';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Typography from '@material-ui/core/Typography';

import TaskDetail from '../TaskDetail';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: isDragging => isDragging && theme.palette.primary.main,
    color: isDragging => isDragging && theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
      cursor: 'pointer'
    },
    '&:focus': { outline: 'blue' }
  }
}));

const Component = ({ task, index, updateTask }) => {
  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <Item
          task={task}
          provided={provided}
          snapshot={snapshot}
          updateTask={updateTask}
        />
      )}
    </Draggable>
  );
};

const Item = ({ task, provided, snapshot, updateTask }) => {
  const isDragging = snapshot.isDragging;
  const classes = useStyles(isDragging);

  const [editTitle, setEditTitle] = React.useState(false);
  const toggleTitle = () => setEditTitle(!editTitle);

  return (
    <Paper
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className={classes.root}
      elevation={isDragging ? 3 : 1}>
      <EditableInput {...{ task, editTitle, toggleTitle, updateTask }} />
    </Paper>
  );
};

const EditableInput = ({ task, editTitle, toggleTitle, updateTask }) => {
  const [open, setOpen] = React.useState(false);
  if (editTitle) {
    return (
      <InputBase
        fullWidth
        defaultValue={task.title}
        onBlur={toggleTitle}
        onKeyPress={e => e.charCode === 13 && toggleTitle()}
        autoFocus
        inputProps={{ color: 'red' }}
        onChange={e =>
          updateTask({ id: task.id, value: e.target.value, field: 'title' })
        }
      />
    );
  } else {
    return (
      <React.Fragment>
        <TaskDetail
          task={task}
          open={open}
          setOpen={setOpen}
          updateTask={updateTask}
        />
        <Typography onClick={() => setOpen(!open)} onDoubleClick={toggleTitle}>
          {task.title}
        </Typography>
      </React.Fragment>
    );
  }
};

export default Component;
