import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import Paper from '@material-ui/core/Paper';
import NumberFormat from 'react-number-format';
import { useTypedSelector } from 'redux/reducers';
import Time from 'common/Time';

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' },
  detail: { padding: theme.spacing(2), margin: theme.spacing(2) }
}));

const Component = ({ company }) => {
  const classes = useStyles();
  const { companyNavigator } = useTypedSelector((s) => s.config);

  let columns = [] as object[];

  if (companyNavigator === 'RedflagOG') {
    columns = [
      { title: 'Status', field: 'attributes.status' },
      { title: 'Court', field: 'attributes.court' },
      { title: 'Date Satisfied', field: 'attributes.date_satisfied' },
      {
        title: 'Amount',
        render: (rowData) => <Currency value={rowData.attributes.amount} />
      },
      { title: 'Address', field: 'attributes.address' },
      { title: 'Date', field: 'attributes.date' },
      { title: 'Type', field: 'attributes.type' }
    ];
  }

  if (companyNavigator === 'RedflagGRaphQL') {
    columns = [
      {
        title: 'Amount',
        render: (rowData) => <Currency value={rowData.attributes.amount} />
      },
      {
        title: 'Date',
        field: 'attributes.date_of_judgement',
        render: (props) => (
          <Time time={props.attributes.date_of_judgement} type="date" />
        )
      },
      { title: 'Date Satisfied', field: 'attributes.date_satisfied' },
      { title: 'Address', field: 'attributes.address' },
      { title: 'Court', field: 'attributes.court_name' },
      { title: 'Case No.', field: 'attributes.case_number' },
      { title: 'Type', field: 'attributes.judgement_type' }
    ];
  }

  return (
    <Paper elevation={3} className={classes.root}>
      <Table title="CCJs" data={company.ccjs} columns={columns} />
    </Paper>
  );
};

export default Component;
