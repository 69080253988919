import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import { UPDATE_QUICK_USER } from 'graphql/UserInstanceAPI/GetQuickUser/mutations';
import { GET_QUICK_LITE_USER } from 'graphql/UserInstanceAPI/GetQuickUser/queries';
import { UPDATE_USER } from 'graphql/UserInstanceAPI/mutations';
import { IUpdateUserInstance } from 'redux/actions/GraphQlActions/interface';
import {
  TUserSummaryLite,
  UserInstance,
  CompleteUserInstance
} from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';

/**
 * Updates userDetails and associated CompleteObjectInstances.
 * Previously known as `postData` mutation.
 * @param {String} baseUrl - The base URL for the Hostname.
 * @param {TUserSummaryLite} data - The data object containing the a UserInstance & CompleteObjectInstanceList.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to update.
 * @returns {Promise<TUserSummaryLite | ApolloError>} - A promise resolving to a UserInstance & CompleteObjectInstanceList or an ApolloError.
 */

export const UpdateUser = async ({
  baseUrl,
  ProcessInstanceId,
  data: payload
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  data: Partial<CompleteUserInstance>;
}): Promise<Partial<CompleteUserInstance> | ApolloError> => {
  try {
    const newData = removeTypenames(payload);

    const { data: responsePayload, errors } = await apolloClient.mutate({
      mutation: UPDATE_USER,
      variables: {
        baseUrl,
        ProcessInstanceId,
        data: newData
      }
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    return responsePayload.UpdateUser as Partial<CompleteUserInstance>;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Updates a QuickLiteUser using the provided parameters.
 * @param {string} baseUrl - The base URL for the hostname.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to update.
 * @param {IUpdateUserInstance} data - The data object containing the updated user information.
 * @returns {Promise<{ data: UserInstance; loading: boolean } | ApolloError>} - A promise resolving to the Updated UserInstance or an ApolloError. After successfully updating, it re-fetches the UserInstances to ensure the latest information is reflected.
 */
export const UpdateQuickLiteUser = async ({
  baseUrl,
  ProcessDefinitionId,
  data: payload
}: {
  baseUrl: string;
  ProcessDefinitionId: number;
  data: IUpdateUserInstance;
}): Promise<{ data: UserInstance; loading: boolean } | ApolloError> => {
  try {
    const newData = removeTypenames(payload);
    const UserInstanceId = payload && payload?.Id;

    const { data, errors } = await apolloClient.mutate({
      mutation: UPDATE_QUICK_USER,
      variables: {
        baseUrl,
        processdefinitionid: ProcessDefinitionId,
        data: newData
      },
      refetchQueries: [
        {
          query: GET_QUICK_LITE_USER,
          variables: {
            baseUrl,
            userInstanceId: parseInt(UserInstanceId?.toString() || '0')
          }
        }
      ]
    });

    if (errors && errors.length > 0) {
      return new ApolloError({
        errorMessage: errors.map((err) => err.message).join(', ')
      });
    }

    const responsePayload = data.QuickAddUser;
    return { data: responsePayload, loading: false } as {
      data: UserInstance;
      loading: boolean;
    };
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

export const removeTypenames = <T extends object>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map((item) => removeTypenames(item)) as unknown as T;
  } else if (obj !== null && typeof obj === 'object') {
    const newObj = {} as T;
    Object.entries(obj).forEach(([key, value]) => {
      if (key !== '__typename') {
        (newObj as any)[key] = removeTypenames(value);
      }
    });
    return newObj;
  }
  return obj;
};
