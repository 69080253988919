import React, { Fragment } from 'react';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CreateCalendarUserTypeDialog from './createCalendarUserTypeDialog';

const CreateUserDialog = ({
  UserDefinitionId,
  selectedUser,
  getData,
  onSuccess
}) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <IconButton onClick={() => setOpen(true)}>
        <AddIcon />
      </IconButton>

      <CreateCalendarUserTypeDialog
        open={open}
        setOpen={setOpen}
        UserDefinitionId={UserDefinitionId}
        selectedUser={selectedUser}
        getData={getData}
        onSuccess={onSuccess}
      />
    </Fragment>
  );
};

export default CreateUserDialog;
