import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useCalculationStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuContainer: {
      backgroundColor: theme.palette.grey[300]
    },
    storeItem: {
      width: '100%',
      '&:hover': {
        cursor: 'pointer'
      }
    },
    storeItemPaper: {
      padding: 8,
      margin: '10px 0',
      width: '100%',
      background: theme.palette.grey[300],
      color: theme.palette.grey[800],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    },
    displayContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(0.5),
      borderBottom: '1px solid',
      borderImage:
        'linear-gradient(to right, transparent, #A9A9A9, transparent) 1'
    },
    itemHeading: {
      fontWeight: 500,
      color: '#68696b',
      padding: theme.spacing(0.4)
    }
  })
);
