import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Search from './Search';
import { TabList, ItemList } from 'components/RedFlag/TabMenu/TabList';
import { CompanyDetails } from '../../RedFlag/TabMenu/components';
import { DirectorsDetail } from 'components/RedFlag/TabMenu/components/Directors';
import {
  CompleteUserInstance,
  UserDefinition,
  CompleteObjectDefinition
} from 'types/interfaces';
import { useRedflagUpdate } from 'hooks/useRedflagUpdate';
import { CustomDialog } from 'common/Dialog';
import { DialogActions, Paper, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { theme } from 'theme';

import type { IRedflagAPIData, IRedflagObjects } from 'types/redflagInterfaces';
import { useRedFlagGQLAdaptor } from 'components/OffkeyCompanyAndDirectorSearch/adaptors';
import { useTypedSelector } from 'redux/reducers';
import { getCrmToken } from 'redux/actions/GraphqlCrmActions';
import { OffkeyDirectorLite } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { IRedFlagDirectorship } from 'components/OffkeyCompanyAndDirectorSearch/helper';
import { RedflagClient } from 'App';
import GraphQlSearchTable from './GraphQlSearch';
import { BugTracker } from 'Utils/Bugtracker';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const Item = ({ i, v, name, children, classes }) => (
  <TabPanel value={v} index={i} className={classes.item}>
    {children ? children : name}
  </TabPanel>
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column'
  },
  searchContainer: { padding: theme.spacing(1) },
  search: { flexGrow: 1 },
  info: {
    background: 'lightgrey',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    opacity: 0.5
  },
  item: { paddingLeft: theme.spacing(2) },
  header: {
    textTransform: 'uppercase',
    textAlign: 'left',
    fontSize: 16
  }
}));

interface IProps {
  CODL: CompleteObjectDefinition[];
  rowData: CompleteUserInstance;
  UserDefinition: UserDefinition;
}

export default function RedFlag({ CODL, rowData, UserDefinition }: IProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const graphqlCrmState = useTypedSelector((s) => s.graphqlCrm);
  const [value, setValue] = React.useState(0);

  const [company, setCompany] = React.useState<IRedflagAPIData>(
    {} as IRedflagAPIData
  );

  const [director, setDirector] = React.useState<
    IRedFlagDirectorship | undefined
  >({} as IRedFlagDirectorship);

  const [companyNo, setCompanyNo] = React.useState(null);
  const [directorNo, setDirectorNo] = React.useState(null);
  const [valid, setValid] = React.useState<boolean>(false);
  const [pdfDnLoad, setPdfDnLoad] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<boolean>(false);

  //? This is for loading Companies on Data Section of the Application
  const [loadingCompany, setLoadingCompany] = React.useState<boolean>(false);

  const [redflagDialog, setRedflagDialog] = useState<boolean>(false);
  const { importData } = useRedflagUpdate();

  const isDirector = directorNo;
  const isCompany = companyNo || Object.keys(company).length > 0;

  const [download, setDownload] = React.useState<boolean>(false);
  const [eta, setEta] = React.useState<number | null>(null);

  const downloadFile = (fileLocation: string) => {
    const link = document.createElement('a');
    link.href = fileLocation;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // GQL
  const { getCompanyByCompanyNumber, getRFGQLDirector, getCompanyPDFGQL } =
    useRedFlagGQLAdaptor(RedflagClient);

  const downloadPDF = async () => {
    try {
      setDownload(true);
    } catch (err) {
      BugTracker.notify(err);
      setPdfDnLoad(false);
    }
  };
  const handlePoll = (eta) => {
    setEta(eta);
  };

  const getCompanyData = async (companyNo: string) => {
    if (companyNo && graphqlCrmState.access_token) {
      try {
        setLoadingCompany(true);
        const result = await getCompanyByCompanyNumber(companyNo);

        setCompany(result);
        setLoadingCompany(false);
      } catch (e) {
        BugTracker.notify(e);
        setLoadingCompany(false);
      }
    }
  };

  const getDirectorData = async (directorNo: string) => {
    if (directorNo && graphqlCrmState.access_token) {
      const result = await getRFGQLDirector(directorNo);
      setDirector(result);
    }
  };

  const loadGraphqlToken = async () => {
    if (!graphqlCrmState.loaded || graphqlCrmState.willExpire <= Date.now()) {
      getCrmToken(dispatch);
    }
  };

  const handleImportingRedflag = async () => {
    setLoading(true);

    const config = {
      rowData,
      CompleteObjectDefinition: CODL,
      isCompany,
      company
    };

    await importData(config);
    setLoading(false);
  };

  React.useEffect(() => {
    if (download) {
      getCompanyPDFGQL(company.data, handlePoll).then(
        ({ completed, eta, fileExport }) => {
          if (completed) {
            downloadFile(fileExport.downloadLocation);
            setDownload(false);
          }
        }
      );
    }
  }, [download]);

  const countdownRef = React.useRef(eta);
  const countdownDisplayRef: any = React.useRef();

  React.useEffect(() => {
    if (typeof eta === 'number') {
      countdownRef.current = eta;
      const intervalId = setInterval(() => {
        if (typeof countdownRef.current === 'number') {
          countdownRef.current -= 1;
          if (countdownDisplayRef.current) {
            countdownDisplayRef.current.textContent =
              countdownRef.current > 0
                ? `Your PDF will be ready in ${countdownRef.current} second${
                    countdownRef.current > 1 ? 's' : ''
                  }.`
                : 'Almost There, Please Wait';
          }
          if (countdownRef.current <= 0) {
            clearInterval(intervalId);
          }
        }
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [eta]);

  React.useEffect(() => {
    loadGraphqlToken();
    getCompanyNumber({
      CODL,
      rowData,
      setCompanyNo,
      setDirectorNo,
      setValid,
      UserDefinition
    });
  }, [rowData, CODL, UserDefinition]);

  React.useEffect(() => {
    if (companyNo) getCompanyData(companyNo);
  }, [companyNo]);

  React.useEffect(() => {
    if (directorNo) getDirectorData(directorNo);
  }, [directorNo]);

  if (isDirector && director && Object.values(director).length > 0) {
    return <DirectorsDetail directorship={director} />;
  }

  //? This LinearProgress happens when loading the Fetch Request to get Company by number
  if (loadingCompany) return <LinearProgress />;
  return (
    <div className={classes.root}>
      <CustomDialog
        maxSize="sm"
        open={redflagDialog}
        handleClose={() => setRedflagDialog(false)}
        alert={{
          title: `This Action Cannot Be Undone.`,
          description:
            'Request to Update Information from RedFlag and Override Current Data',
          type: 'warning'
        }}>
        <Paper style={{ width: '100%', height: '100%' }}>
          <Typography style={{ padding: theme.spacing(2) }} align="left">
            By choosing the update, you will be making a request to update the
            information from RedFlag. This will override the information you
            currently have stored. Would you like to proceed?
          </Typography>
          <DialogActions>
            <Button
              onClick={() => {
                setRedflagDialog(false);
              }}>
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={async (e) => {
                await handleImportingRedflag();
                setRedflagDialog(false);
              }}>
              {!loading ? 'Proceed' : <CircularProgress size={24} />}
            </Button>
          </DialogActions>
        </Paper>
      </CustomDialog>
      {valid && (
        <React.Fragment>
          <Alert
            severity="warning"
            style={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
            <Typography>
              <b>No Company Number Found</b> - Account Has No Redflag Data
            </Typography>
          </Alert>
          <LinearProgress />
          <br />
          <GraphQlSearchTable setCompany={setCompany} />
        </React.Fragment>
      )}

      {isCompany && (
        <React.Fragment>
          <div
            style={{
              display: 'flex',
              padding: 8,
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            {company && (
              <>
                {Boolean(isCompany) && loading ? (
                  <CircularProgress />
                ) : (
                  <Tooltip title="Update / Override Details With Redflag Data">
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={Boolean(!isCompany)}
                      onClick={() => setRedflagDialog(true)}
                      endIcon={<CloudDownloadIcon />}>
                      Import / Update
                    </Button>
                  </Tooltip>
                )}
              </>
            )}
          </div>

          <Grid container>
            <Grid item xs={2}>
              <TabList
                value={value}
                company={company}
                setValue={setValue}
                type="vertical"
                pluginRedflag="false"
              />
            </Grid>
            <Grid item xs={10} style={{ paddingTop: 0 }}>
              <Item i={0} v={value} classes={classes} name={undefined}>
                <CompanyDetails
                  company={company}
                  setCompany={setCompany}
                  data={company?.data}
                  downloadPDF={downloadPDF}
                  loading={pdfDnLoad}
                  directorships={undefined}
                  countdownDisplayRef={countdownDisplayRef}
                  mapLoading={loading}
                  isCompleted={download}
                  ProcessDefinitionId={undefined}
                  hideCreateUser
                  selectCreated={undefined}
                  rowData={rowData}
                />
              </Item>

              <div style={{ marginLeft: 16 }}>
                <ItemList
                  company={company}
                  value={value}
                  pluginRedflag={false}
                />
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
}

const getCompanyNumber = ({
  CODL,
  rowData,
  setCompanyNo,
  setDirectorNo,
  setValid,
  UserDefinition
}) => {
  if (UserDefinition.Description.includes('directorship')) {
    const DirectorDetailsDEF = CODL.find(
      (el) => el.ObjectDefinition.Title === 'Director Details'
    );
    if (DirectorDetailsDEF) {
      const DirectorNumberDEF = DirectorDetailsDEF.FieldDefinitionList.find(
        (el) => el.Title === 'Director Number'
      );
      if (DirectorNumberDEF) {
        const { Id, ObjectDefinitionId } = DirectorNumberDEF;
        const CompanyDetailsINS = rowData.CompleteObjectInstanceList.find(
          (el) => el.ObjectInstance.ObjectDefinitionId === ObjectDefinitionId
        );
        if (CompanyDetailsINS) {
          const CompanyNumberINS = CompanyDetailsINS.FieldInstanceList.find(
            (el) => el.FieldDefinitionId === Id
          );
          let CompanyNumber = null;
          if (CompanyNumberINS) {
            CompanyNumber = CompanyNumberINS.FieldValue;
          }
          return setDirectorNo(CompanyNumber);
        } else return setValid(true);
      }
    }
  } else {
    const CompanyDetailsDEF = CODL.find(
      (el) => el.ObjectDefinition.Title === 'Company Details'
    );
    if (CompanyDetailsDEF) {
      const CompanyNumberDEF = CompanyDetailsDEF.FieldDefinitionList.find(
        (el) => el.Title === 'Company Number'
      );
      if (CompanyNumberDEF) {
        const { Id, ObjectDefinitionId } = CompanyNumberDEF;

        const CompanyDetailsINS = rowData.CompleteObjectInstanceList.find(
          (el) => el.ObjectInstance.ObjectDefinitionId === ObjectDefinitionId
        );
        if (CompanyDetailsINS) {
          const CompanyNumberINS = CompanyDetailsINS.FieldInstanceList.find(
            (el) => el.FieldDefinitionId === Id
          );
          let CompanyNumber = null;
          if (CompanyNumberINS) {
            CompanyNumber = CompanyNumberINS.FieldValue;
          }
          return setCompanyNo(CompanyNumber);
        } else return setValid(true);
      }
    }
  }
};
