/**PDF download button HOC
 * A button component, & you can insert any custom pdf component you want.
 *  * But the pdf component must be from the @react-pdf/renderer module
 */

/*eg. how to use in a typical component:
  const PdfButton = PdfDownloadButton({
    PdfComponent: () => (
      <CustomPdfComponent
        anyPropsYouWant={anyPropValueYouWant}
      />
    ),
    fileName: 'any file name you want
  }); //END PdfButton
*/

import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ReactPDF, { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1) },
  pdfLink: { color: theme.palette.primary.main }
}));

interface HocPropType {
  PdfComponent: () => JSX.Element;
  fileName?: string;
}

/**HOC for any custom pdf components which uses @react-pdf module
 * @param {jsx component} PdfComponent - Your custom pdf component
 * @param {string} fileName - Optional custom file name
 */
const PdfDownloadButton = ({ PdfComponent, fileName }: HocPropType) => {
  const PdfButtonComponent = () => {
    const classes = useStyles();
    const [disableButton, setDisableButton] = useState<boolean>(true);

    const download = async () => {
      const blob = await ReactPDF.pdf(<PdfComponent />).toBlob();
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL);
    };

    return (
      <Button
        data-cy="pdf-button"
        variant="outlined"
        className={classes.button}
        onClick={download}>
        Pdf download
      </Button>
    );
  };

  return PdfButtonComponent;
}; //END PdfDownloadButton

export default PdfDownloadButton;

{
  /* <PDFDownloadLink
          document={<PdfComponent />}
          fileName={fileName ? `${fileName}.pdf` : 'AFS file'}
          className={classes.pdfLink}>
          {({ blob, url, loading, error }) => {
            console.log({ blob });

            if (loading) {
              return <p>Pdf download</p>;
            }

            setDisableButton(false);

            return <p>Pdf download</p>;
          }}
        </PDFDownloadLink> */
}
