import { Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import { CarLogo } from 'components/AssetLogos';
import NumberFormat from 'react-number-format';
import { FieldDefinition, FieldInstance } from 'types/interfaces';

export const ContentTitle = ({
  FieldDefinitionList,
  FieldInstanceList
}: {
  FieldDefinitionList: FieldDefinition[] | undefined;
  FieldInstanceList: FieldInstance[];
}) => {
  const getValue = (name: string): string => {
    const Id =
      FieldDefinitionList &&
      FieldDefinitionList.find((i) => i.Title === name)?.Id;
    if (Id) {
      const instObj: FieldInstance | undefined = FieldInstanceList.find(
        (FieldInstance: FieldInstance) => FieldInstance.FieldDefinitionId === Id
      );

      if (!instObj) return '';
      return instObj.FieldValue;
    }

    return '';
  };

  const quantity = getValue('Quantity');
  let newQuantity = quantity !== '' ? parseFloat(quantity) : 1;

  const price = getValue('Price Per Unit');
  const newPrice = parseFloat(price);

  const make = getValue('Make');
  const model = getValue('Model');
  const year = getValue('Year');
  const assetCategory = getValue('Asset Category');
  const equipments = getValue('Equipment');
  const vehicles = getValue('Vehicles');
  const nonVatableItemCost = getValue('Non VATable Items');

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0
        }}>
        <CarLogo
          Manufacturer={make}
          AssetCategory={assetCategory}
          Equipments={equipments}
          Vehicles={vehicles}
          style={{ fontSize: 50 }}
        />
      </div>

      <div style={{ minHeight: 50 }}>
        <Typography variant="h3" style={{ margin: 5 }}>
          {make} {model}{' '}
          {year && <Typography variant="caption">{`(${year})`}</Typography>}
        </Typography>
        <Typography variant="caption" style={{ margin: 5 }}>
          {assetCategory}{' '}
          {assetCategory === 'Equipment' ? equipments : vehicles}
        </Typography>
      </div>

      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Chip
            disabled
            size="small"
            style={{ width: '100%' }}
            variant="outlined"
            label={
              <Typography variant="body2">
                <NumberFormat
                  value={newPrice * newQuantity}
                  prefix={'£'}
                  thousandSeparator
                  displayType={'text'}
                  decimalScale={2}
                />
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Chip
            disabled
            size="small"
            style={{ width: '100%' }}
            variant="outlined"
            label={
              <Typography variant="body2">
                <NumberFormat
                  value={newPrice}
                  prefix={'£'}
                  thousandSeparator
                  displayType={'text'}
                />
                ea.
              </Typography>
            }
          />
        </Grid>
        <Grid item xs={4}>
          <Chip
            disabled
            size="small"
            style={{ width: '100%' }}
            variant="outlined"
            label={<Typography variant="body2">{newQuantity} x</Typography>}
          />
        </Grid>

        {parseFloat(nonVatableItemCost) > 0 && (
          <Grid item xs={4}>
            <Tooltip title="Non-Vatable Item Cost">
              <div>
                <Chip
                  disabled
                  size="small"
                  style={{ width: '100%' }}
                  variant="outlined"
                  label={
                    <Typography variant="body2">
                      <NumberFormat
                        value={nonVatableItemCost}
                        prefix={'£'}
                        thousandSeparator
                        displayType={'text'}
                      />
                    </Typography>
                  }
                />
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </div>
  );
};
