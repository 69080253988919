import {
  CompleteObjectDefinition,
  CompleteProcessInstance,
  CompleteProcessStepDefinition,
  FieldDefinition,
  FieldInstance,
  CompleteObjectInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

export interface IgetFieldValueWithFieldDefinitionTitle {
  stepdefdict: CompleteProcessStepDefinition;
  currentDeal: CompleteProcessInstance;
  FieldDefinitionTitle: string;
}

export const getFieldValueWithFieldDefinitionTitle: (
  props: IgetFieldValueWithFieldDefinitionTitle
) => string = ({ stepdefdict, currentDeal, FieldDefinitionTitle }) => {
  let result = '';

  stepdefdict?.CompleteObjectDefinitionDict &&
    Object.values(stepdefdict.CompleteObjectDefinitionDict).forEach(
      (CompleteObjectDefinition: CompleteObjectDefinition) => {
        return Object.values(
          CompleteObjectDefinition.FieldDefinitionDict
        ).forEach((FieldDefinition: FieldDefinition) => {
          if (FieldDefinition.Title === FieldDefinitionTitle) {
            return Object.values(
              currentDeal.CompleteObjectInstanceDict
            ).forEach((CompleteObjectInstance: CompleteObjectInstance) => {
              const { ObjectDefinitionId } =
                CompleteObjectInstance.ObjectInstance;
              if (ObjectDefinitionId === FieldDefinition.ObjectDefinitionId) {
                const FieldInstanceList = getFieldInstances(
                  CompleteObjectInstance
                );

                return FieldInstanceList.forEach(
                  (FieldInstance: FieldInstance) => {
                    const { FieldValue } = FieldInstance;
                    return (result = FieldValue);
                  }
                );
              }
            });
          }
        });
      }
    );

  return result;
};
