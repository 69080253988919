import { EntityType } from 'types/interfaces';

export const EntityTypes: { label: EntityType; value: EntityType }[] = [
  { label: 'Limited Company', value: 'Limited Company' },
  { label: 'Unlimited Company', value: 'Unlimited Company' },
  { label: 'Sole Trader', value: 'Sole Trader' },
  {
    label: 'Partnership of 3 and less',
    value: 'Partnership of 3 and less'
  },
  { label: 'Partnership (4+)', value: 'Partnership (4+)' },
  { label: 'Private Individual', value: 'Private Individual' },
  {
    label: 'Private Individual - High Net Worth',
    value: 'Private Individual - High Net Worth'
  },
  {
    label: 'Limited Liability Partnership',
    value: 'Limited Liability Partnership'
  },
  { label: 'PLC', value: 'PLC' },
  { label: 'Government Body', value: 'Government Body' },
  { label: 'Non-Profit', value: 'Non-Profit' },
  {
    label: 'Non-Regulated Partnership 4+',
    value: 'Non-Regulated Partnership 4+'
  },
  { label: 'Local Authority', value: 'Local Authority' },
  { label: 'Other-Regulated', value: 'Other-Regulated' },
  { label: 'Other-Non-Regulated', value: 'Other-Non-Regulated' }
];

export const SupplierEntities: { label: EntityType; value: EntityType }[] = [
  { label: 'Limited Company', value: 'Limited Company' },
  { label: 'Sole Trader', value: 'Sole Trader' },
  {
    label: 'Partnership of 3 and less',
    value: 'Partnership of 3 and less'
  },
  { label: 'Partnership (4+)', value: 'Partnership (4+)' },
  { label: 'Private Individual', value: 'Private Individual' },
  {
    label: 'Limited Liability Partnership',
    value: 'Limited Liability Partnership'
  },
  { label: 'PLC', value: 'PLC' }
];

export const UserGroupType = (options) => {
  if (options !== undefined) {
    const hasCompany = options.filter((hasCompany) =>
      hasCompany.Description.includes('company')
    );

    if (hasCompany) {
      return SupplierEntities;
    } else return EntityTypes;
  } else return EntityTypes;
};
