import { Grid, Paper, Typography } from '@material-ui/core';
import { FieldValuesTypes } from 'components/Stepper/components/Steps/ActiveStep/IndicativeQuoteObject';
import { useProcess } from 'hooks';
import { theme } from 'theme';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  FieldInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

const INDICATIVE_QUOTE_PROCESS_STEP = 1738;
const INDICATIVE_QUOTE_OBJECT_DEFINITION_ID = 3565;
const LENDER_NAME_ID = 22794;
const INDICATIVE_QUOTE_DECLINE_IDS = [23525, 23592];

const getFieldInstanceComponent = (
  FieldInstance: FieldInstance,
  CompleteObjectDefinition: CompleteObjectDefinition
) => {
  const FieldDefinition =
    CompleteObjectDefinition.FieldDefinitionDict[
      FieldInstance.FieldDefinitionId
    ];

  if (
    !FieldDefinition ||
    FieldDefinition.Id === LENDER_NAME_ID ||
    INDICATIVE_QUOTE_DECLINE_IDS.includes(FieldDefinition.Id)
  ) {
    return null;
  }

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      key={FieldInstance.FieldDefinitionId}>
      <Grid item>
        <Typography
          style={{ fontWeight: 'bold', color: theme.palette.grey[500] }}
          variant="h6">
          {FieldDefinition.Title}:
        </Typography>
      </Grid>
      <Grid item>
        <FieldValuesTypes
          FieldDefinition={FieldDefinition}
          FieldInstance={FieldInstance}
        />
      </Grid>
    </Grid>
  );
};

const IndicativeQuoteSummary = ({
  indicativeQuoteValues
}: {
  indicativeQuoteValues: CompleteObjectInstance[];
}) => {
  const { currentProcess } = useProcess();

  return (
    <div>
      {indicativeQuoteValues.map(
        (quote: CompleteObjectInstance, index: number) => {
          const FieldInstanceList = getFieldInstances(quote);
          const FindIndicativeQuotes =
            currentProcess?.CompleteProcessStepDefinitionDict[
              INDICATIVE_QUOTE_PROCESS_STEP
            ]?.CompleteObjectDefinitionDict;

          if (!FindIndicativeQuotes) return <div key={index} />;
          const CompleteObjectDefinition = Object.values(
            FindIndicativeQuotes
          ).find(
            (def) =>
              def.ObjectDefinition.Id === INDICATIVE_QUOTE_OBJECT_DEFINITION_ID
          );

          if (!CompleteObjectDefinition) return <div key={index} />;
          return (
            <Paper
              key={index}
              style={{ width: '100%', padding: theme.spacing(1) }}>
              <Typography
                style={{
                  paddingBottom: theme.spacing(1),
                  paddingTop: theme.spacing(1)
                }}
                variant="h5">
                Indicative Quote Id: {quote.ObjectInstance.Id}
              </Typography>
              <Grid container justifyContent="space-between">
                {FieldInstanceList.map((FieldInstance: FieldInstance) =>
                  getFieldInstanceComponent(
                    FieldInstance,
                    CompleteObjectDefinition
                  )
                )}
              </Grid>
            </Paper>
          );
        }
      )}
    </div>
  );
};

export default IndicativeQuoteSummary;
