import { useState, useEffect } from 'react';
import { TextField, InputAdornment, Typography } from '@material-ui/core';
import { Calculation, PaymentFrequency } from 'types/calculatorInterfaces';
import { theme } from 'theme';

const FundingProfile = ({
  config,
  state,
  handleChange
}: {
  state: Calculation;
  config: any;
  handleChange: any;
}) => {
  const [units, setUnits] = useState<number[]>();
  const [unitLabel, setUnitLabel] = useState('(months)');

  useEffect(() => {
    const tempUnits = calculateTempUnits(state);
    setUnits(tempUnits.reverse());

    // Update unit label based on PaymentFrequency
    switch (state.PaymentFrequency) {
      case PaymentFrequency.Monthly:
        setUnitLabel('(Months)');
        break;
      case PaymentFrequency.Quarterly:
        setUnitLabel('(Quarters)');
        break;
      case PaymentFrequency.SemiAnnually:
        setUnitLabel('(Semi-Annually)');
        break;
      case PaymentFrequency.Annually:
        setUnitLabel('(Years)');
        break;
      default:
        setUnitLabel('(Months)');
    }
  }, [state]);

  useEffect(() => {
    const tempUnits = calculateTempUnits(state);
    const event = { target: { value: tempUnits[tempUnits.length - 1] } };
    handleChange('FundingProfile')(event);
  }, [state.PaymentFrequency]);

  return (
    <TextField
      data-cy="fund-profile"
      disabled={!config.FundingProfile.show}
      fullWidth
      label="Funding Profile"
      margin="dense"
      onChange={handleChange('FundingProfile')}
      value={state.FundingProfile}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Typography
              variant="body1"
              style={{
                fontWeight: 'bold',
                color: theme.palette.info.main,
                whiteSpace: 'nowrap'
              }}>
              {unitLabel}
            </Typography>
          </InputAdornment>
        )
      }}
    />
  );
};

export default FundingProfile;

// Helper functions remain the same
const arrayCreator = (i: number) => {
  const length = Math.max(0, Math.round(i));
  return Array(length)
    .fill(null)
    .map((_, index) => index + 1);
};

const calculateTempUnits = (state: Calculation) => {
  let factor = 1;

  switch (state.PaymentFrequency) {
    case PaymentFrequency.Monthly:
      factor = 1;
      break;
    case PaymentFrequency.Quarterly:
      factor = 1 / 4;
      break;
    case PaymentFrequency.SemiAnnually:
      factor = 1 / 6;
      break;
    case PaymentFrequency.Annually:
      factor = 1 / 12;
      break;
    default:
      break;
  }

  return arrayCreator(state.Term * factor);
};
