/** FileExplorer component for FireExplorer plugin
 * contains most of the logic & fetch request needed.
 * Also a container for the FileExplorerUI component
 */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GetFileExplorer } from 'redux/database/index';
import { useSelector } from 'react-redux';
import FileExplorerUI from './FileExplorerUI';
import { BugTracker } from 'Utils/Bugtracker';

const useStyles = makeStyles({ page: { padding: 20 } });

interface FileListObject {
  Name: string;
}

interface FolderListObject {
  FolderList: FolderListObject[];
  FileList: FileListObject[];
  Name: string;
}

const FileExplorer = ({
  fileSelect,
  isImport
}: {
  fileSelect?: (file: File, url: string) => void;
  isImport: boolean;
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  // this is an array object of all directories, nested direcotories & files
  const [tree, setTree] = React.useState<FolderListObject[]>([]);

  // this is for the search bar textbox value, controlled onChange state
  const [searchItem, setSearchItem] = React.useState('');

  // when user clicks on the file name, either they will be transferred to a new tab to view the file (pdf files only), or the file will be downloaded to their device
  const getFileData = async ({
    Name,
    path
  }: {
    Name: string;
    path: string;
  }) => {
    setLoading(true);
    try {
      const res = await GetFileExplorer({
        action: 'getfile',
        path: encodeURIComponent(path),
        responseType: 'blob'
      });

      if (res && !loading) {
        const file = new File([res.data], Name, {
          type: res.headers['content-type']
        });

        const fileURL = URL.createObjectURL(file);
        if (isImport && fileSelect) {
          fileSelect(file, res.config.url || fileURL);
        } else {
          if (res.headers['content-type'] === 'application/pdf') {
            window.open(fileURL);
            setSearchItem('');
          } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = fileURL;
            a.download = Name;
            a.click();
          }
        }

        URL.revokeObjectURL(fileURL);
      }
    } catch (e) {
      BugTracker.notify(e);
      setLoading(false);
    }
    setLoading(false);
  };

  // Function for fetching the api tree data, & setting the 'tree' state
  const getTreeData = async () => {
    setLoading(true);
    // fetches response ojbect, which contains the directories & files
    const res = await GetFileExplorer({
      action: 'tree',
      path: '/',
      responseType: ''
    });

    setLoading(false);

    if (res && res.data && !loading)
      if (res.data.Name === '') setTree(res.data.FolderList);
      else setTree([...tree, res.data]);
  }; // END getTreeData

  // On Fold load, it fetches the api tree data & sets the 'tree' state
  React.useEffect(() => {
    getTreeData();
  }, []);

  return (
    <div className={classes.page}>
      <FileExplorerUI
        getFileData={getFileData}
        loading={loading}
        setSearchItem={setSearchItem}
        tree={tree}
        searchItem={searchItem}
      />
    </div>
  );
}; // END FileExplorer component

export default FileExplorer;
