// Palette
// import Palette from '../palette';
// import { store } from 'redux/store';

export default (theme) => {
  return {
    root: {
      color: theme.palette.text.secondary,
      height: '56px',
      '&$selected': { backgroundColor: theme.palette.background.default },
      '&$hover': {
        '&:hover': {
          background: theme.palette.primary.light,
          color: theme.palette.primary.contrastText
        }
      }
    }
  };
};
