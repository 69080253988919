// Desc: This file contains the CustomerTypeForm component which is used to display the customer type of the user.
import React, { useEffect } from 'react';
import { UserInstance, CompleteUserInstance } from 'types/interfaces';
import CustomerTypeChip from 'components/Table/Timeline/components/CustomerType';

// firebase
import firebase from 'firebase';

// types
import {
  CustomerType,
  FirestoreDocType,
  HistoryEvent,
  INestedEventType
} from 'components/Table/Timeline/interface';

interface IState {
  selectedType: CustomerType;
  fileNotes: string;
  typeHistory: HistoryEvent[];
  fileTypeHistory: HistoryEvent[];
}

const CustomerTypeForm = ({ UserInstance }: { UserInstance: UserInstance }) => {
  const [state, setState] = React.useState<IState>({
    selectedType: CustomerType.None,
    fileNotes: '',
    typeHistory: [],
    fileTypeHistory: []
  });

  const handleTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const eventType = 'Customer Type';
    const newType = event.target.value as CustomerType | string;
    const prevType = state.selectedType;
    const newHistoryEvent: HistoryEvent = {
      name: newType as CustomerType,
      timestamp: Date.now(),
      prevType
    };

    // ref to the document
    const docRef = firebase
      .firestore()
      .collection('timeline')
      .doc(UserInstance.Id.toString());

    // get the document
    docRef.get().then((doc) => {
      const data = doc.data() as FirestoreDocType;
      const updatedHistory = [
        ...(data[eventType].history || []),
        newHistoryEvent
      ];

      const updatedData = {
        history: updatedHistory,
        ...(eventType === 'Customer Type'
          ? { selected: newType }
          : { note: newType })
      };

      // update the document
      return docRef.update({ [eventType]: updatedData }).then(() => {
        setState((prevState) => ({
          ...prevState,
          selectedType: newType as CustomerType,
          typeHistory: updatedHistory
        }));
      });
    });
  };

  useEffect(() => {
    if (UserInstance) {
      const docRef = firebase
        .firestore()
        .collection('timeline')
        .doc(UserInstance.Id.toString());

      // get the document
      const unsubscribe = docRef.onSnapshot((doc) => {
        if (doc.exists) {
          const data = doc.data() as FirestoreDocType;

          setState((prevState) => ({
            ...prevState,
            selectedType: data['Customer Type']?.selected || CustomerType.None,
            typeHistory: data['Customer Type']?.history || [],
            fileNotes: data['File Note']?.note || '',
            fileTypeHistory: data['File Note']?.history || []
          }));
        } else {
          // create the document
          const newHistoryEvent: HistoryEvent = {
            name: 'None',
            timestamp: Date.now(),
            prevType: 'None'
          };

          const initialData: FirestoreDocType = {
            'Customer Type': createInitialNestedEvent({
              eventType: 'Customer Type',
              newType: 'None',
              newHistoryEvent
            }),
            Notes: '',
            TODO: '',
            Call: '',
            Birthdays: '',
            Deal_Expiry_Date: '',
            others: '',
            log: '',
            open: '',
            click: '',
            'File Note': { history: [], note: '' },
            'Deal Note': ''
          };

          // create the document
          return docRef.set(initialData).then(() => {
            setState((prevState) => {
              return {
                ...prevState,
                selectedType: CustomerType.None,
                typeHistory: [newHistoryEvent]
              };
            });
          });
        }
      });
    }
  }, []);

  return (
    <CustomerTypeChip
      selectedType={state.selectedType}
      UserInstance={UserInstance}
      handleTypeChange={handleTypeChange}
    />
  );
};

export default CustomerTypeForm;

const createInitialNestedEvent = ({
  eventType,
  newType,
  newHistoryEvent
}: {
  eventType: string;
  newType: CustomerType | string;
  newHistoryEvent: HistoryEvent;
}): INestedEventType => {
  if (eventType === 'Customer Type') {
    return {
      history: [newHistoryEvent],
      selected: newType as CustomerType
    };
  } else if (eventType === 'File Note') {
    return {
      history: [newHistoryEvent],
      note: newType as string
    };
  } else {
    return {
      history: [],
      selected: CustomerType.None
    };
  }
};
