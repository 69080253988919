import React from 'react';
import Tabs from './components/Tabs';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import { useTypedSelector } from 'redux/reducers';
import { UserInstance } from 'types/interfaces';
import { getRelationship } from 'redux/actions/GraphQlActions';
import RelationshipCreationDialog from './components/Dialog';

interface IProps {
  rowData: any;
  title: boolean;
}

const Component = ({ rowData, title }: IProps) => {
  const { Id, UserDefinitionId } = rowData.UserInstance;
  const [relationships, setRelationships] = React.useState<UserInstance[]>([]);
  const [loading, setLoading] = React.useState(false);
  const isRelationships = Boolean(relationships?.length > 0);

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const getRelationships: () => Promise<void> = async () => {
    const response = (await getRelationship({
      action: 'LIST_ALL',
      UserInstanceId: Id,
      UserDefinitionId,
      baseUrl
    })) as UserInstance[] | undefined;

    if (response) {
      setRelationships(response);
    } else {
      setRelationships([]);
    }
  };

  React.useEffect(() => {
    getRelationships();
  }, []);

  if (loading) return <LinearProgress />;
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          padding: 8,
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
        {title && (
          <Tooltip placement="top" title="Create A New Relationship">
            <Typography
              style={{
                textTransform: 'uppercase',
                textAlign: 'left',
                fontSize: 16
              }}>
              Relationships
            </Typography>
          </Tooltip>
        )}
        <RelationshipCreationDialog
          getRelationships={getRelationships}
          rowData={rowData}
          setLoading={setLoading}
        />
      </div>
      {isRelationships && (
        <Tabs
          getRelationships={getRelationships}
          relationships={relationships}
          rowData={rowData}
          loading={loading}
        />
      )}
    </React.Fragment>
  );
};
export default Component;
