import { store } from 'redux/store';
import { UPDATE_LOADING } from 'redux/actions/types';
import { getDealData } from 'redux/actions/processes/getDealData';
import {
  FieldInstance,
  FieldDefinition,
  ObjectDefinition,
  ObjectInstance,
  ProcessInstance,
  UserInstance
} from 'types/interfaces';
import { customFieldActions } from './customFieldActions';
import { updateFieldInstance } from '../GraphQlActions';

const { dispatch } = store;

export interface Props {
  token: string;
  data: FieldInstance;
  props: {
    FieldDefinition: FieldDefinition;
    FieldInstance: FieldInstance;
    ObjectDefinition: ObjectDefinition;
    ObjectInstance: ObjectInstance;
    ProcessInstance: ProcessInstance;
    ProcessStepSelectionType: string;
    UserInstance: UserInstance;
    fields: FieldDefinition[];
    type: string;
    value: string;
  };
}

export const updateField = async ({ token, data, props }: Props) => {
  const baseUrl: string = store.getState().config.baseURL;

  let newData: FieldInstance | undefined;
  if (props.FieldInstance) {
    const hasId = props.FieldInstance.Id !== 0;
    newData = {
      ...data,
      Id: hasId ? props.FieldInstance.Id : 0
    };
  }

  const response = await updateFieldInstance({
    baseUrl,
    ProcessInstanceId: props.ProcessInstance.Id,
    data: newData ? newData : data
  });

  if (response) {
    await customFieldActions({ token, data, props });
    return await updateFieldInRedux({ res: response, data, props, token });
  }

  return;
};

interface ReduxProps {
  res: FieldInstance;
  token: string;
  data: FieldInstance;
  props: {
    FieldDefinition: FieldDefinition;
    FieldInstance: FieldInstance;
    ObjectDefinition: ObjectDefinition;
    ObjectInstance: ObjectInstance;
    ProcessInstance: ProcessInstance;
    ProcessStepSelectionType: string;
    UserInstance: UserInstance;
    fields: FieldDefinition[];
    type: string;
    value: string;
  };
}

// TODO: FIELD UPDATER:
// Very interesting field this one

const updateFieldInRedux = async ({ res, data, props, token }: ReduxProps) => {
  // const isDependent = props.ObjectDefinition.ObjectProcessIndependent;
  const isFromProcess = props.ProcessInstance.Id !== 0;

  // 1. IS this from a Process?
  // YES then update the process
  // NO then update the User

  if (!isFromProcess) {
    // console.log('existing instance ...');

    const userList = store.getState().userAPI.userList;
    // When do we populate this and how often?
    const { UserDefinitionId, UserInstanceId, ObjectInstanceId } = res;
    const updatedUserList = JSON.parse(JSON.stringify(userList));

    const existingUserList = updatedUserList[UserDefinitionId];

    // Does User Instance Exist in Redux?
    if (existingUserList) {
      const targetInstance = existingUserList[UserDefinitionId].find(
        (item) => item.UserInstance.Id === UserInstanceId
      );

      if (targetInstance) {
        const targetObject = targetInstance.CompleteObjectInstanceList.find(
          (item) => item.ObjectInstance.Id === ObjectInstanceId
        );

        if (targetObject) {
          // Initialize FieldInstanceList if it doesn't exist
          if (!targetObject.FieldInstanceList) {
            targetObject.FieldInstanceList = [];
          }

          // Now it's safe to push
          targetObject.FieldInstanceList.push(res);

          // Initialize FieldInstanceDict if it doesn't exist
          if (!targetObject.FieldInstanceDict) {
            targetObject.FieldInstanceDict = {};
          }

          // Now it's safe to set a property
          targetObject.FieldInstanceDict[res.Id] = res;
        }
      }
    } else {
      // And if not we need to go and get it ...
      // console.log(
      //   'The user does not exist in redux so we need to go and get it...'
      // );
    }

    // targetObject.FieldInstanceDict[ObjectInstanceId].FieldValue = ;
    Promise.all([
      dispatch({ type: 'SET_ALL_LIST', payload: updatedUserList }),
      dispatch({ type: 'UPDATING' })
      // dispatch({ type: 'CLEAR_PROCESS_OVERVIEW' })
    ]);

    // Should we go and get the user instance again at this stage so the user defs are in sync ...
  } else {
    // IS FROM PROCESS
    // We need to update the current deal
    const currentDeal = store.getState().process.currentDeal;
    const currentStepId = store.getState().process.currentStepId;
    const { ObjectInstanceId, Id } = res;
    const updatedCurrentDeal = JSON.parse(JSON.stringify(currentDeal));

    if (!updatedCurrentDeal.CompleteObjectInstanceDict[ObjectInstanceId]) {
      updatedCurrentDeal.CompleteObjectInstanceDict[ObjectInstanceId] = {};
    }

    if (
      !updatedCurrentDeal.CompleteObjectInstanceDict[ObjectInstanceId]
        .FieldInstanceDict
    ) {
      updatedCurrentDeal.CompleteObjectInstanceDict[
        ObjectInstanceId
      ].FieldInstanceDict = {};
    }

    let existingFieldInstance = '';
    Object.keys(
      updatedCurrentDeal.CompleteObjectInstanceDict[ObjectInstanceId]
        .FieldInstanceDict
    ).forEach((fieldId) => {
      if (
        updatedCurrentDeal.CompleteObjectInstanceDict[ObjectInstanceId]
          .FieldInstanceDict[fieldId].FieldDefinitionId ===
        res.FieldDefinitionId
      ) {
        existingFieldInstance = fieldId;
      }
    });

    if (existingFieldInstance) {
      updatedCurrentDeal.CompleteObjectInstanceDict[
        ObjectInstanceId
      ].FieldInstanceDict[existingFieldInstance] = res;
    } else {
      updatedCurrentDeal.CompleteObjectInstanceDict[
        ObjectInstanceId
      ].FieldInstanceDict[Id] = res;
    }

    if (
      !updatedCurrentDeal.CompleteObjectInstanceDict[ObjectInstanceId]
        .FieldInstanceList
    ) {
      updatedCurrentDeal.CompleteObjectInstanceDict[
        ObjectInstanceId
      ].FieldInstanceList = [];
    }

    const fieldInstanceIndex = updatedCurrentDeal.CompleteObjectInstanceDict[
      ObjectInstanceId
    ].FieldInstanceList.findIndex(
      (fieldInstance) =>
        fieldInstance.FieldDefinitionId === res.FieldDefinitionId
    );

    if (fieldInstanceIndex > -1) {
      updatedCurrentDeal.CompleteObjectInstanceDict[
        ObjectInstanceId
      ].FieldInstanceList[fieldInstanceIndex] = res;
    } else {
      updatedCurrentDeal.CompleteObjectInstanceDict[
        ObjectInstanceId
      ].FieldInstanceList.push(res);
    }

    Promise.all([
      dispatch({ type: 'SET_CURRENT_DEAL', payload: updatedCurrentDeal }),
      dispatch({ type: 'UPDATING' })
    ]);

    const ProcessDefinitionId = props.ProcessInstance.ProcessDefinitionId || 0;
    const ProcessInstanceId = props.ProcessInstance.Id;
    const ProcessStepDefinitionId = currentStepId;

    const config = {
      ProcessDefinitionId,
      ProcessInstanceId,
      ProcessStepDefinitionId,
      token
    };

    // If the FieldDefinition Title is Deal Type
    if (props?.FieldDefinition?.Title === 'Deal Type') {
      await getDealData(config);
    }
    // Lets refresh the overview data, this now needs to be called with a step definition id we could use the current step id in redux?
    // return refreshOverViewData(config);
  }

  // Is this update from a Process?
  // YES
  // Clear the user instance data
  // if (isFromProcess) dispatch({ type: 'CLEAR_USER_LIST' });

  // This is causing issues in the portal

  // NO
  // Clear the current deal
  // if (!isFromProcess) dispatch({ type: '' }); Just now we don't need to do anything but when we start holding the current deal in state we will need it

  return res;
};

/**
 * REMOVE SPECIFIC PROCESS DATA OVERVIEW DATA
 */

// Clear out the "Deal Overview" forcing a new download when "Deal OverView" opened again so data is in sync.
//   const ProcessInstanceFields = store.getState().process.processInstanceFields;
//   const updateProcessInstanceFields = JSON.parse(
//     JSON.stringify(ProcessInstanceFields)
//   );
//   if (ProcessInstanceFields[props.ProcessInstance.Id]) {
//     // Remove the entry in Redux.
//     updateProcessInstanceFields[props.ProcessInstance.Id] = null;
//     dispatch({
//       type: 'SET_PROCESS_FIELDS',
//       payload: updateProcessInstanceFields
//     });
//   }
