import React, { useState } from 'react';
import {
  Box,
  Typography,
  Divider,
  makeStyles,
  useTheme,
  Grid,
  Checkbox
} from '@material-ui/core';
import { FbFileRef } from 'types/interfaces';
import Time from 'common/Time';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { useFileStorage } from 'hooks';
import { useTypedSelector } from 'redux/reducers';
import PDFViewerComponent from './FilePDFViewer';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.success.main,
    transition: 'all 0.3s ease-in-out',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.success.dark,
      transform: 'scale(1.01)',
      boxShadow: theme.shadows[3]
    }
  },
  nameTypography: {
    fontWeight: 'bold',
    color: 'white',
    marginBottom: theme.spacing(1)
  },
  metadataContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  metadataDivider: {
    height: 24,
    width: 1,
    margin: theme.spacing(0, 2)
  },
  metadataTypography: {
    color: 'white',
    fontSize: 14
  }
}));

const SimplifiedDocumentCard = ({
  item,
  proposalForm
}: {
  item: FbFileRef;
  proposalForm?: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { currentDeal } = useTypedSelector((s) => s.process);
  const { downloadFile } = useFileStorage();
  const [pdfUrl, setPdfUrl] = useState<string | undefined>(undefined);
  const [openPDF, setOpenPDF] = useState(false);

  const handleDownload = async () => {
    const ProcessInstanceId = currentDeal.ProcessInstance.Id;

    const url = await downloadFile({
      ProcessInstanceId,
      item,
      isSelecting: false
    });
    if (url) {
      setOpenPDF(true);
      setPdfUrl(url);
    }
  };

  const handlePDFDownload = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const ProcessInstanceId = currentDeal.ProcessInstance.Id;

    await downloadFile({
      ProcessInstanceId,
      item,
      isSelecting: false,
      downloadPdf: true
    });
  };

  return (
    <>
      <Box className={classes.container} onClick={handleDownload}>
        <Typography variant="subtitle1" className={classes.nameTypography}>
          {item.nickName || item.name}
        </Typography>
        <Box className={classes.metadataContainer}>
          <QuickUserFromId UserInstanceId={item.UserInstanceId.toString()} />
          <Divider orientation="vertical" className={classes.metadataDivider} />
          <div style={{ color: 'white' }}>
            <Time time={item.uploaded} type="timeago" />
          </div>
          <Divider orientation="vertical" className={classes.metadataDivider} />
          <Typography variant="body2" className={classes.metadataTypography}>
            Size: {`${(item.size / 1000000).toFixed(2)} MB`}
          </Typography>
        </Box>

        {!proposalForm && (
          <div style={{ padding: theme.spacing(1) }}>
            <Alert severity="success">
              <Typography style={{ fontWeight: 'bold' }}>
                List of Authorized Viewers for This Document:
              </Typography>

              {item.users.map((userId) => {
                const checked = item.users.includes(userId.toString());

                return (
                  <Grid container direction="row" key={userId} spacing={1}>
                    <Grid item>
                      <Checkbox
                        disabled
                        checked={checked}
                        style={{
                          color: checked
                            ? theme.palette.success.main
                            : 'default'
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <QuickUserFromId UserInstanceId={userId.toString()} />
                    </Grid>
                  </Grid>
                );
              })}
            </Alert>
          </div>
        )}
      </Box>

      {pdfUrl && (
        <PDFViewerComponent
          fileUrl={pdfUrl}
          openPDF={openPDF}
          setOpenPDF={setOpenPDF}
          handlePDFDownload={handlePDFDownload}
        />
      )}
    </>
  );
};

export default SimplifiedDocumentCard;
