import { useContext, useEffect, useState, useRef } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Tooltip,
  DialogContent,
  Button,
  ButtonGroup
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { Dialog } from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { useDispatch } from 'react-redux';
import { Alert } from '@material-ui/lab';
import { theme } from 'theme';

import { Calculation } from 'types/calculatorInterfaces';
import { useTypedSelector } from 'redux/reducers';
import { SET_CALCULATION_ID } from 'redux/actions/types';
import { useCalculationStyles } from '../styles';
import DisplayItem from './DisplayItem';
import { CalculatorStorageContext } from '../../context/CalculatorContext';
import { SaveLabels, Tools } from 'components/Calculator/Calculator/interfaces';
import SearchByName from './SearchByName';
import { useCalculator } from '../../../Calculator/hooks/useCalculator';
import { useValidateCalculationName } from '../../../Calculator/hooks/useValidateCalculationName';
import { SnackBar } from '../../../SnackBar';

interface CalculationWithId extends Calculation {
  id: string;
}

const { Autosave, Standalone } = SaveLabels;

const MyCalculationsDialog = ({
  state,
  tools,
  quoteId
}: {
  state: Calculation;
  tools: Tools;
  quoteId: number;
}) => {
  const {
    setDialogData,
    dialogData,
    toggleMyCalculations: toggle,
    openMyCalculations: open,
    saveCalculation,
    getMyCalculations,
    loadCalculation,
    deleteCalculation,
    updateCalculation
  } = useContext(CalculatorStorageContext);

  const { setSnackBar, snackBar } = useCalculator(tools);

  const { validateCalculationName } = useValidateCalculationName({
    setSnackBar
  });

  const { calculationId } = useTypedSelector((s) => s.calculator);
  const [hoveringStates, setHoveringStates] = useState({});

  const dispatch = useDispatch();
  const classes = useCalculationStyles();

  const [calculations, setCalculations] = useState<CalculationWithId[]>([]);
  const [calculationNameQuery, setCalculationNameQuery] = useState('');

  const quoteCalcId = quoteId?.toString();

  const { current: quoteCalculation = null } = quoteId
    ? useRef({
        ...tools.props.calculation,
        id: quoteCalcId
      })
    : {};

  useEffect(() => {
    const initalFilteredCalculations: CalculationWithId[] = Object.entries(
      dialogData
    )
      .map(([key, value]) => ({
        ...value,
        id: key
      }))
      .filter(
        (c) =>
          !c.id.includes(Autosave) ||
          (quoteId
            ? c.id === `${Autosave} ${quoteId}`
            : c.id === `${Autosave} ${Standalone}`)
      )
      .sort((a, b) => {
        // selected calculation should always be at the top
        if (a.id === calculationId) return -1;
        if (b.id === calculationId) return 1;

        // ...followed by autosave
        const aTitleIncludesAutosave = a.Title?.includes(Autosave) ?? false;
        const bTitleIncludesAutosave = b.Title?.includes(Autosave) ?? false;

        if (aTitleIncludesAutosave && !bTitleIncludesAutosave) return -1;
        if (!aTitleIncludesAutosave && bTitleIncludesAutosave) return 1;

        return 0;
      });

    // Display calculation that is tied to Quote as first item in My calculations
    if (quoteCalculation) {
      initalFilteredCalculations.unshift(quoteCalculation);
    }

    if (!calculationNameQuery || calculationNameQuery.length < 2) {
      setCalculations(initalFilteredCalculations);
      return;
    }

    const filteredByName = initalFilteredCalculations.filter(
      (c) =>
        c.Title?.toLowerCase().includes(calculationNameQuery.toLowerCase()) ??
        false
    );

    if (filteredByName.length > 0) {
      setCalculations(filteredByName);
      return;
    }

    const filteredById = initalFilteredCalculations.filter(
      (c) =>
        c.id?.toLowerCase().includes(calculationNameQuery.toLowerCase()) ??
        false
    );

    setCalculations(filteredById);
  }, [calculationNameQuery, dialogData, calculationId]);

  // select quoteCalculation on mount
  useEffect(() => {
    if (!quoteCalculation) return;

    dispatch({
      type: SET_CALCULATION_ID,
      payload: quoteCalcId
    });
  }, [quoteCalculation]);

  return (
    <Dialog open={open} onClose={toggle}>
      <DialogContent style={{ minWidth: 500, minHeight: 500 }}>
        <Grid container>
          <Grid
            item
            className={classes.storeItem}
            style={{ marginBottom: theme.spacing(1) }}>
            <Typography variant="h4">{`Calculations (${calculations.length})`}</Typography>
          </Grid>
          <Grid
            item
            className={classes.storeItem}
            style={{ marginBottom: theme.spacing(1) }}>
            <SearchByName
              calculationNameQuery={calculationNameQuery}
              setCalculationNameQuery={setCalculationNameQuery}
            />
          </Grid>
          <Grid item className={classes.storeItem}>
            <Tooltip title="Save the current state of this calculation to 'My Calculations'">
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={async () => {
                  try {
                    const extras = state?.extras?.length ? state.extras : null;

                    const isCopyOfQuoteCalculation =
                      quoteCalculation &&
                      quoteCalculation.Title?.toLowerCase() ===
                        state.Title?.toLowerCase();

                    const newState = {
                      ...state,
                      extras: extras ? extras : tools.extras,
                      ...(isCopyOfQuoteCalculation && {
                        Title: `${state.Title} Copy`
                      })
                    };

                    const { isInvalid, existingCalculationId } =
                      await validateCalculationName(newState.Title);

                    if (isInvalid) return;

                    let id;
                    let updatedCalculations;
                    let message = '';

                    if (existingCalculationId) {
                      await updateCalculation(newState, existingCalculationId);
                      id = existingCalculationId;
                      message = `UPDATED calculation with Name: ${newState.Title}`;
                    } else {
                      id = (await saveCalculation(newState)).id;
                      message = `SAVED Calculation with Name: ${newState.Title}`;
                    }

                    updatedCalculations = await getMyCalculations();

                    if (updatedCalculations) {
                      setDialogData(updatedCalculations);

                      dispatch({
                        type: SET_CALCULATION_ID,
                        payload: id
                      });

                      setSnackBar({
                        open: !snackBar.open,
                        message,
                        variant: 'success'
                      });
                    }
                  } catch (err) {
                    setSnackBar({
                      open: !snackBar.open,
                      message: 'Something went wrong.',
                      variant: 'warning'
                    });
                  }
                }}>
                <AddIcon /> SAVE TO MY CALCULATIONS
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container spacing={1} style={{ padding: 8 }}>
          {calculations.map((calculation) => {
            const id = calculation.id;
            const current = id === calculationId;

            return (
              <Grid
                key={id}
                item
                xs={12}
                className={classes.storeItem}
                onMouseEnter={() =>
                  setHoveringStates((prev) => ({ ...prev, [id]: true }))
                }
                onMouseLeave={() =>
                  setHoveringStates((prev) => ({ ...prev, [id]: false }))
                }>
                {id === quoteCalcId && (
                  <div style={{ paddingTop: theme.spacing(1) }}>
                    <Alert severity="info" style={{ padding: '0 0 0 5px' }}>
                      <Typography variant="body1">
                        This Calculation is Exclusively tied to this Quote
                      </Typography>
                    </Alert>
                  </div>
                )}

                {current && (
                  <div style={{ paddingTop: theme.spacing(1) }}>
                    <Alert severity="success" style={{ padding: '0 0 0 5px' }}>
                      <Typography variant="body1">
                        Selected Calculation Successfully Imported into
                        Calculator
                      </Typography>
                    </Alert>
                  </div>
                )}

                {id.includes(
                  `${Autosave} ${quoteId ? quoteId : Standalone}`
                ) && (
                  <div style={{ paddingTop: theme.spacing(1) }}>
                    <Alert severity="info" style={{ padding: '0 0 0 5px' }}>
                      <Typography variant="body1">
                        This Calculation Is An Autosave
                      </Typography>
                    </Alert>
                  </div>
                )}
                <Paper
                  elevation={3}
                  onClick={() => {
                    loadCalculation(calculation, id);
                    setSnackBar({
                      open: !snackBar.open,
                      message: `Successfully Imported ${calculation.Title} Calculation into Calculator`,
                      variant: 'success'
                    });
                  }}
                  className={classes.storeItemPaper}
                  style={{
                    border: current
                      ? `3px solid ${green[400]}`
                      : hoveringStates[id]
                      ? `2px solid ${grey[400]}`
                      : ``
                  }}>
                  <DisplayItem calculation={calculation} id={id} />
                  <div style={{ paddingBottom: theme.spacing(1) }} />
                  {!id.includes(
                    `${Autosave} ${quoteId ? quoteId : Standalone}`
                  ) &&
                    !(id === quoteCalcId) && (
                      <ButtonGroup
                        variant="contained"
                        color="primary"
                        fullWidth>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();

                            deleteCalculation(id)?.then(async () => {
                              const updatedCalculations =
                                await getMyCalculations();

                              if (updatedCalculations) {
                                setDialogData(updatedCalculations);

                                setSnackBar({
                                  open: !snackBar.open,
                                  message: 'Deleted Calculation',
                                  variant: 'success'
                                });
                              }
                            });
                          }}>
                          Delete
                        </Button>
                      </ButtonGroup>
                    )}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
        {calculations.length === 0 && (
          <Grid style={{ marginTop: theme.spacing(5) }}>
            <Typography variant="h5" align="center">
              No Calculations
            </Typography>
          </Grid>
        )}
      </DialogContent>
      <SnackBar
        message={snackBar.message}
        open={snackBar.open}
        toggle={() =>
          setSnackBar({
            open: !snackBar.open,
            message: '',
            variant: 'success'
          })
        }
        variant={snackBar.variant}
      />
    </Dialog>
  );
};

export default MyCalculationsDialog;
