import { Typography, Link } from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';

export const FieldDefinitionTitle = ({
  title,
  height
}: {
  title: string;
  height: any;
}) => {
  const { baseURL } = useTypedSelector((s) => s.config);

  if (title !== '') {
    let pattern = /\((.*)\)/;
    let text: string = title;
    let result = text.match(pattern);
    const toRemove: any = result?.[0];
    const Description = result?.[1];
    const Title = text.replace(toRemove, '');

    // Does the description contain a link?
    let hashPattern = /#(\w+)\b/;
    const linkId = Description?.match(hashPattern)?.[1];

    return (
      <div>
        <Typography
          variant={height}
          style={{ flexGrow: 1 }}
          color="textPrimary">
          {`${Title}`.toString()}
        </Typography>
        {linkId ? (
          <Link
            data-cy="landing-page-download-link"
            href={`${baseURL}/documentAPI.ashx?documentId=${linkId}`}
            target="_blank"
            style={{ color: 'blue' }}>
            Download
          </Link>
        ) : (
          <Typography variant="caption">{Description}</Typography>
        )}
      </div>
    );
  } else {
    return (
      <Typography variant={height} style={{ flexGrow: 1 }} color="textPrimary">
        {`${title}`.toString()}
      </Typography>
    );
  }
};
