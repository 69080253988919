import { firebase } from 'redux/firebase';
import { ListFb } from '../types';
import { BugTracker } from 'Utils/Bugtracker';

const db = firebase.firestore();
const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

export const fetchLists = async (userId: number): Promise<ListFb[]> => {
  try {
    const ref = db
      .collection('list')
      .where('hostname', '==', hostname)
      .where('userInstanceId', '==', userId);

    const snap = await ref.get();

    const lists: ListFb[] = [];

    snap.forEach((doc: any) => {
      lists.push(doc.data() as ListFb);
    });

    return lists;
  } catch (e) {
    BugTracker.notify(e);
    return [];
  }
};
