import { ReactComponent as ReactLogo } from 'assets/Icons/svg/calculator.svg';
import NumberFormat from 'react-number-format';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import WarningIcon from '@material-ui/icons/Warning';
import { TextField } from '@material-ui/core';
import { useEffect, useRef, useState } from 'react';

const useMainFieldStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  paper: {
    paddingLeft: 8,
    minHeight: 35,
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  label: {
    fontSize: 10,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: -5,
    left: 15
  },
  value: {
    flexGrow: 1
  },
  num: {
    position: 'absolute',
    top: 10,
    left: 12
  },
  divider: {
    height: 24,
    width: 1,
    marginLeft: 8
  },
  btn: {
    marginLeft: 8
  },
  textField: {
    width: '100%'
  }
}));

interface Props {
  required: boolean;
  name: string;
  value: number;
  onClick: () => void;
  disabled?: boolean;
  isLoanDeal?: boolean;
  calculate: () => void;
  valueToChange: number;
  handleChange: (name: string, type?: string) => (event: any) => void;
  type: string;
}

const MainField = (props: Props) => {
  const {
    required,
    name,
    value,
    onClick,
    disabled,
    handleChange,
    type,
    isLoanDeal,
    valueToChange,
    calculate
  } = props;
  const classes = useMainFieldStyles();
  const theme = useTheme();

  const [isEditing, setIsEditing] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);

  const handleEditStart = () => {
    if (!disabled) setIsEditing(true);
  };

  const handleEditEnd = (event: React.FocusEvent) => {
    if (!fieldRef.current?.contains(event.relatedTarget as Node)) {
      setIsEditing(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        fieldRef.current &&
        !fieldRef.current.contains(event.target as Node)
      ) {
        setIsEditing(false);
      }
    };

    if (isEditing) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEditing]);

  return (
    <div className={classes.container}>
      {value <= 0 && required && (
        <WarningIcon
          style={{
            color: theme.palette.warning.main,
            marginRight: 10
          }}
        />
      )}
      <div
        ref={fieldRef}
        style={{ opacity: disabled ? 0.5 : 1, width: '100%' }}>
        <Paper className={classes.paper} onClick={handleEditStart}>
          {isEditing ? (
            <TextField
              data-cy="text-field-input"
              InputProps={{ inputComponent: NumberFormatCustom }}
              className={classes.textField}
              disabled={disabled}
              fullWidth
              margin="dense"
              label={name}
              required={required}
              variant="outlined"
              onBlur={handleEditEnd}
              onChange={(event) => handleChange(type)(event)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && calculate) {
                  calculate();
                  setIsEditing(false);
                }
              }}
              value={valueToChange}
              onFocus={(e) => e.target.select()}
            />
          ) : (
            <>
              <Typography className={classes.label}>
                {name}
                {required && '*'}
              </Typography>
              <Typography className={classes.value}>
                <div data-cy="advance-amount" className={classes.num}>
                  <NumberFormat
                    decimalScale={2}
                    displayType={'text'}
                    prefix={'£'}
                    thousandSeparator
                    value={value}
                    onFocus={handleEditStart}
                  />
                </div>
              </Typography>
            </>
          )}

          <Divider className={classes.divider} orientation="vertical" />

          <Button
            data-cy="mini-calculator"
            className={classes.btn}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
            disabled={disabled}>
            <ReactLogo
              fill={disabled ? 'lightgrey' : theme.palette.primary.main}
              height="24"
              width="24"
            />
          </Button>
        </Paper>
      </div>
    </div>
  );
};

export default MainField;

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      decimalScale={2}
      fixedDecimalScale
      getInputRef={inputRef}
      onValueChange={(values) => {
        const { floatValue } = values;
        onChange({ target: { value: floatValue } });
      }}
      prefix="£"
      thousandSeparator
      values={{ formattedValue: '0.00' }}
    />
  );
};
