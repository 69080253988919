import MomentUtils from '@date-io/moment';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { EventContext } from 'components/Calendar/context/EventContext';
import { useContext, useState } from 'react';
import Draggable from 'react-draggable';
import { CalendarContext } from '../../context/CalendarContext';
import CalendarAddressBody from './CalendarAddress';
import CalendarBody from './CalendarBody';
import CalendarTimeBody from './CalendarDate';
import CalendarRelationship from './CalendarRelationship';
import CalendarSubject from './CalendarSubject';
import CalendarTeams from './CalendarTeams';

const PaperComponent = (props) => (
  <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const CalendarModule = ({ edit }: { edit?: boolean }) => {
  const { handleCheckboxChange } = useContext(EventContext);
  const {
    dialogOpen,
    handleClose,
    handleCalendarUploading,
    error,
    formValues
  } = useContext(CalendarContext);

  return (
    <Dialog
      PaperComponent={PaperComponent}
      open={dialogOpen}
      onClose={handleClose}
      hideBackdrop
      maxWidth="sm">
      <DialogContent>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid style={{ width: '100%' }} container spacing={1}>
            <Grid item xs={12}>
              <CalendarSubject />
            </Grid>
            <Grid item xs={12}>
              <CalendarRelationship />
            </Grid>
            {/* <Grid item xs={12}>
              <CalendarTeams />
            </Grid> */}
            <Grid item xs={12}>
              <CalendarTimeBody />
            </Grid>
            <Grid item xs={12}>
              <CalendarAddressBody />
            </Grid>
            <Grid item xs={12}>
              <CalendarBody />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Box display="flex" alignItems="center">
          <Checkbox
            checked={formValues.notification}
            onChange={(e) => {
              const checked = e.target.checked;
              handleCheckboxChange(e);
            }}
            color="primary"
            inputProps={{ 'aria-label': 'Enable notifications' }}
          />
          <Typography variant="h6" color="textSecondary">
            Allow Notification Sounds
          </Typography>
        </Box>

        <Tooltip title={error.reason} disableHoverListener={!error.mark}>
          <Button
            data-cy="event-submit"
            disabled={error.mark}
            variant="contained"
            color="primary"
            onClick={handleCalendarUploading}>
            {edit ? 'Save Event' : 'Create Event'}
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CalendarModule;
