import React, { SetStateAction } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import { getUserInstanceSummaryList } from 'redux/actions/user';
import Grid from '@material-ui/core/Grid';
import { StyledSelect } from 'common/StyledSelect';
import CreateIcon from '@material-ui/icons/Create';
import Box from '@material-ui/core/Box';

import { IMaterialUISelect } from 'common/StyledSelect';
import { useTypedSelector } from 'redux/reducers';
import { useProcess } from 'hooks';
import { getRelationship } from 'redux/actions/GraphQlActions';
import {
  IRelationshipData,
  RelatedUserPermission
} from 'redux/actions/GraphQlActions/interface';
import { UserInstance } from 'types/interfaces';
import { getRelationshipListAll } from 'redux/actions/GraphQlActions/cachedRequests';

const INIT_STATE = {
  UserDefinitionId: '',
  UserInstanceId: '',
  type: 'two-way'
};

interface IState {
  UserDefinitionId: string;
  UserInstanceId: string;
  type: string;
}

interface IOptionState {
  title: keyof IState;
  value: string | number;
}

interface IOptionType {
  label: string;
  value: string | number;
}

const RelationshipCreationDialog = ({
  rowData,
  getRelationships,
  setLoading
}: {
  rowData: { UserInstance: UserInstance };
  getRelationships: () => Promise<void>;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
}) => {
  const { token } = useTypedSelector((s) => s.user.auth);
  const { Id, UserDefinitionId } = rowData.UserInstance;

  const [open, setOpen] = React.useState(false);
  const [loadingCreate, setLoadingCreate] = React.useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [state, setState] = React.useState<IState>(INIT_STATE);
  const [optionsDef, setOptionsDef] = React.useState<IOptionType[]>([]);
  const [optionsIns, setOptionsIns] = React.useState<IOptionType[]>([]);

  const { userList } = useTypedSelector((s) => s.userAPI);
  const UserDefinitions = useTypedSelector(
    (state) => state.config.settings.UserDefinitionList
  );

  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const handleSubmit = async () => {
    setLoading(true);
    const primaryData: IRelationshipData = {
      Title: '',
      PrimaryUserInstanceId: parseInt(state.UserInstanceId),
      RelatedUserInstanceId: Id,
      Status: 0,
      RelatedUserPermission: RelatedUserPermission.Standard
    };

    const relatedData: IRelationshipData = {
      Title: '',
      PrimaryUserInstanceId: Id,
      RelatedUserInstanceId: parseInt(state.UserInstanceId),
      Status: 0,
      RelatedUserPermission: RelatedUserPermission.Standard
    };

    await getRelationship({
      action: 'CREATE',
      data: primaryData,
      baseUrl,
      UserDefinitionId: parseInt(UserDefinitionId.toString()),
      UserInstanceId: parseInt(Id.toString())
    });

    await getRelationship({
      action: 'CREATE',
      data: relatedData,
      baseUrl,
      UserDefinitionId: parseInt(UserDefinitionId.toString()),
      UserInstanceId: parseInt(Id.toString())
    });

    await getRelationships();
    setOpen(false);
    setLoading(false);
  };

  React.useEffect(() => {
    let list: IOptionType[] = [];
    if (UserDefinitions) {
      UserDefinitions.forEach(({ Id, Title }) =>
        list.push({ value: Id, label: Title })
      );
    }
    setOptionsDef(list);
    return () => setState(INIT_STATE);
    // eslint-disable-next-line
  }, []);

  const handleChange = ({ title, value }: IOptionState) => {
    setState({ ...state, [title]: value });
  };

  const getData = async () => {
    if (state.UserDefinitionId !== '') {
      setLoadingCreate(true);

      const { UserDefinitionId } = state;
      // Get the User summary with the definition
      await getUserInstanceSummaryList({
        token,
        UserDefinitionId
      });
      // Set the userList into the option list for the react select component
      let optionsIns: IOptionType[] = [];
      const selectedList = userList[UserDefinitionId][UserDefinitionId];
      selectedList.forEach(({ UserInstance: { Id, Title } }) => {
        optionsIns.push({ value: Id, label: Title });
      });
      setOptionsIns(optionsIns);
      setLoadingCreate(false);
    }
  };

  React.useEffect(() => {
    setOptionsIns([]);
    getData();
    // eslint-disable-next-line
  }, [state.UserDefinitionId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}>
      <Button
        data-cy="new-relationship-btn"
        color="secondary"
        onClick={handleClickOpen}
        size="small"
        variant="contained">
        New Relationship
        <CreateIcon />
      </Button>
      <Dialog
        aria-labelledby="form-dialog-title"
        fullWidth
        onClose={handleClose}
        open={open}
        style={{ padding: 15 }}>
        <DialogTitle id="form-dialog-title">Create Relationship</DialogTitle>
        <DialogContent style={{ height: '100%' }}>
          <DialogComp
            loading={loadingCreate}
            handleChange={handleChange}
            optionsDef={optionsDef}
            optionsIns={optionsIns}
            state={state}
          />
        </DialogContent>

        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            data-cy="create-relationship-btn"
            color="primary"
            onClick={handleSubmit}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DialogComp = ({
  loading,
  handleChange,
  state,
  optionsDef,
  optionsIns
}: {
  loading: boolean;
  handleChange: (changeState: IOptionState) => void;
  state: IState;
  optionsDef: IOptionType[];
  optionsIns: IOptionType[];
}) => {
  const { user } = useProcess();
  const [openSelectDef, setOpenSelectDef] = React.useState(false);
  const [openSelectIns, setOpenSelectIns] = React.useState(false);
  const subSystemUser = user.SystemAccess <= 4;

  const handleCustomSelectChange = (event, title) => {
    const selectedOption =
      title === 'UserDefinitionId'
        ? optionsDef.find((option) => option.value === event)
        : optionsIns.find((option) => option.value === event);
    const value = selectedOption ? selectedOption.value : '';

    handleChange({ title, value });
  };

  const handleFocus = (selectType) => {
    if (selectType === 'def') {
      setOpenSelectDef(false);
    } else {
      setOpenSelectIns(false);
    }
  };

  const StyleSelectUserDefinitionProps: IMaterialUISelect = {
    label: 'User Definition',
    value: state.UserDefinitionId,
    open: openSelectDef,
    handleClose: () => handleFocus('def'),
    onChange: (event) =>
      handleCustomSelectChange(event.target.value, 'UserDefinitionId'),

    options: optionsDef.filter(({ value, label }) =>
      subSystemUser
        ? ['Customers', 'Introducer', 'Suppliers'].includes(label)
        : [
            'Customers',
            'Contacts',
            'Lenders',
            'Brokers',
            'Introducer',
            'Suppliers'
          ].includes(label)
    ),
    sortAlphabetically: true,
    sortNumerically: false,
    loading
  };

  const StyleSelectUserInstanceProps: IMaterialUISelect = {
    label: 'User Instance',
    value: state.UserInstanceId,
    open: openSelectIns,
    handleClose: () => handleFocus('ins'),
    onChange: (event) =>
      handleCustomSelectChange(event.target.value, 'UserInstanceId'),
    options: optionsIns,
    sortAlphabetically: true,
    sortNumerically: false,
    loading
  };

  return (
    <Grid data-cy="dropdown-options" container direction="column" spacing={2}>
      <Grid item>
        <Box
          width={'100%'}
          onMouseDown={(event) => {
            event.preventDefault();
            setOpenSelectDef(true);
          }}>
          <StyledSelect {...StyleSelectUserDefinitionProps} useMaterialUI />
        </Box>
      </Grid>
      {state.UserDefinitionId !== '' && (
        <>
          <Grid data-cy="dropdown-options-two" item>
            <Box
              width={'100%'}
              onMouseDown={(event) => {
                event.preventDefault();
                setOpenSelectIns(true);
              }}>
              <StyledSelect {...StyleSelectUserInstanceProps} useMaterialUI />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RelationshipCreationDialog;
