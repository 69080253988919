import { updateField } from 'redux/actions/fieldUpdater';
import { updateObject } from 'redux/actions/objectUpdater';
import { ObjectInstance } from 'types/interfaces';
import { getDealData } from 'redux/actions/processes/getDealData';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { getProcessById } from 'redux/actions/processes';
import { useTypedSelector } from 'redux/reducers';

export const useField = () => {
  const landingpage = useTypedSelector((s) => s.utility.landingpage);
  const history = useHistory();

  const updateExistingFieldInExistingObject = async ({
    setUpdating,
    val,
    token,
    props,
    Title
  }) => {
    setUpdating(true);
    const updatedFieldInstance = {
      ...props.FieldInstance,
      FieldValue: val,
      Title: Title ? Title : props.FieldInstance.Title
    };

    await updateField({
      token,
      data: updatedFieldInstance,
      props
    });

    if (props.type.includes('Calculation')) {
      await getDealData({
        ProcessInstanceId: props?.ProcessInstance?.Id,
        ProcessStepDefinitionId:
          props?.ProcessInstance?.ProcessStepDefinitionId, // if it's a landing page then this is wrong
        ProcessDefinitionId: props?.ProcessInstance?.ProcessDefinitionId
      });
    }
    setUpdating(false);
  };

  const publishField = async ({ field, props, token }: any) => {
    const newField = JSON.parse(JSON.stringify(field));
    newField.IsPublished = !field.IsPublished;
    return await updateField({
      token,
      data: newField,
      props
    });
  };

  const createNewFieldInExistingObject = async ({
    setUpdating,
    val,
    token,
    props,
    UserInstance,
    Title
  }) => {
    const create = async () => {
      setUpdating(true);

      // Process instance id should be determined by if this is an independent object
      const isIndependent = props.ObjectDefinition.ObjectProcessIndependent;

      const data = {
        Id: 0,
        ProcessInstanceId: isIndependent ? 0 : props.ProcessInstance.Id,
        FieldDefinitionId: props.FieldDefinition.Id,
        ObjectDefinitionId: props.ObjectDefinition.Id,
        ObjectInstanceId: props.ObjectInstance.Id,
        UserInstanceId: UserInstance.Id, // DETERMINES WHOSE FIELD IS BEING EDITED
        UserDefinitionId: UserInstance.UserDefinitionId,
        FieldValue: val,
        Title,
        IsPublished: true
      };

      // console.log('CREATING: field in existing object and setting new value', {
      //   props,
      //   data
      // });

      await updateField({
        token,
        data,
        props
      });
      setUpdating(false);
      props.toggleTitle && props.toggleTitle();
    };

    return create();
  };

  const createNewObjectAndAllFields = async ({
    setUpdating,
    val,
    token,
    props,
    UserInstance
  }) => {
    setUpdating(true);

    const createNewObject = async () => {
      const NewObject: ObjectInstance | {} | any = {};
      NewObject['FieldInstanceList'] = [];
      NewObject['FieldInstanceDict'] = {};
      const ObjectInstance = {
        Id: 0,
        ProcessInstanceId: props.ProcessInstance.Id,
        Title: props.ObjectDefinition.Title,
        ObjectDefinitionId: props.ObjectDefinition.Id,
        UserDefinitionId: UserInstance.UserDefinitionId,
        UserInstanceId: UserInstance.Id, // DETERMINES WHOSE FIELD IS BEING EDITED
        IsPublished: true
      };
      NewObject['ObjectInstance'] = ObjectInstance;
      props.fields.forEach((field) => {
        const FieldInstance = {
          Id: 0,
          FieldDefinitionId: field.Id,
          ObjectDefinitionId: props.ObjectDefinition.Id,
          ObjectInstanceId: 0,
          UserDefinitionId: UserInstance.UserDefinitionId,
          FieldValue: props.FieldDefinition.Id === field.Id ? val : '',
          ProcessInstanceId: props.ProcessInstance.Id,
          UserInstanceId: UserInstance.Id, // DETERMINES WHOSE FIELD IS BEING EDITED
          IsPublished: true
        };
        NewObject['FieldInstanceList'].push(FieldInstance);
      });

      // console.log(
      //   'CREATING: field in non existing object and setting new value',
      //   {
      //     props,
      //     NewObject
      //   }
      // );

      const updateResult = await updateObject({
        token,
        data: NewObject,
        props
      });

      setUpdating(false);
      if (props.toggleTitle) {
        props.toggleTitle();
      }
      return updateResult;
    };

    return createNewObject();
  };

  return {
    updateExistingFieldInExistingObject,
    createNewFieldInExistingObject,
    createNewObjectAndAllFields,
    publishField
  };
};
