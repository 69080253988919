import Paper from '@material-ui/core/Paper';
import { withStyles, useTheme } from '@material-ui/core/styles';
import { Steps } from '../Steps';
import Badge from '@material-ui/core/Badge';
import { ChildTabs } from './ChildTabs';
import { ParentTabs } from './ParentTabs';
import { IStepTabs } from './interfaces';
import { useProcess } from 'hooks/useProcess';

const StepTabs = ({
  activeStep,
  handleStepChange,
  landingStep,
  parsed,
  setStepGroupIndex,
  stepGroupIndex,
  steps,
  show,
  setShow,
  currentSelectedTitle,
  setCurrentSelectedTitle,
  tabData,
  visibleTabContent,
  setVisibleTabContent,
  handleArrowIconClick
}: IStepTabs) => {
  const theme = useTheme();

  const { currentProcess, landingpage } = useProcess();
  const { ProcessStepGroupDict, ProcessStepDefinitionSequence } =
    currentProcess;

  return (
    <>
      {!landingpage && (
        <Paper
          elevation={0}
          style={{
            zIndex: 2,
            position: 'relative',
            background: theme.palette.grey[300]
          }}>
          <ParentTabs
            parsed={parsed}
            setStepGroupIndex={setStepGroupIndex}
            handleStepChange={handleStepChange}
            stepGroupIndex={stepGroupIndex}
            ProcessStepGroupDict={ProcessStepGroupDict}
            theme={theme}
            currentSelectedTitle={currentSelectedTitle}
            setCurrentSelectedTitle={setCurrentSelectedTitle}
            show={show}
            setShow={setShow}
            setVisibleTabContent={setVisibleTabContent}
          />
        </Paper>
      )}

      {show && !landingpage && (
        <ChildTabs
          handleStepChange={handleStepChange}
          stepGroupIndex={stepGroupIndex}
          landingStep={landingStep}
          activeStep={activeStep}
          ProcessStepGroupDict={ProcessStepGroupDict}
          ProcessStepDefinitionSequence={ProcessStepDefinitionSequence}
          steps={steps}
          tabData={tabData}
          visibleTabContent={visibleTabContent}
          setVisibleTabContent={setVisibleTabContent}
        />
      )}

      <Steps />
    </>
  );
};

export default StepTabs;

export const StyledBadge = withStyles((theme) => ({
  colorPrimary: { backgroundColor: theme.palette.success.main },
  colorSecondary: { backgroundColor: theme.palette.warning.main },
  colorError: { backgroundColor: theme.palette.error.main },
  badge: {
    right: -3,
    top: 0,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px'
  },
  root: {
    padding: '3px 0 0 0'
  }
}))(Badge);
