import { Text, View } from '@react-pdf/renderer';

export const PaymentRequired = ({ state, Currency, TableStyle, styles }) => {
  const isLease = (product) => product.toLowerCase().startsWith('lease');

  return (
    <div>
      <View style={TableStyle.table}>
        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cell]} />
          <Text style={[TableStyle.cell]} />
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.cell, styles.tableTitleText]}>
                Amount
              </Text>
              <Text style={[TableStyle.cellMiddleAlone, styles.tableTitleText]}>
                VAT
              </Text>
              <Text style={[TableStyle.cellRight, styles.tableTitleText]}>
                Total
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight, styles.tableTitleText]}>
              Amount
            </Text>
          )}
        </View>

        <div style={styles.line} />

        {/* Only show VAT paid upfront for non-lease products */}
        {!isLease(state.FINANCE_PRODUCT) && (
          <View style={[TableStyle.row, TableStyle.greyBackground]}>
            <Text style={[TableStyle.cellLeft]}>VAT paid upfront</Text>
            <Text style={[TableStyle.childCell]}>
              <Currency value={state.VAT_PAID_UPFRONT_AMOUNT} />
            </Text>
            <Text style={[TableStyle.cell]}>
              <Currency value={state.VAT_PAID_UPFRONT_VAT} />
            </Text>
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.VAT_PAID_UPFRONT_TOTAL} />
            </Text>
          </View>
        )}

        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cellLeft]}>Advance Payments</Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.ADVANCE_PAYMENTS_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.ADVANCE_PAYMENTS_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.ADVANCE_PAYMENTS_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.ADVANCE_PAYMENTS_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row, TableStyle.greyBackground]}>
          <Text style={[TableStyle.cellLeft]}>Additional Deposit</Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.ADDITIONAL_DEPOSIT_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.ADDITIONAL_DEPOSIT_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.ADDITIONAL_DEPOSIT_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.ADDITIONAL_DEPOSIT_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cellLeft]}>Documentation Fee</Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency value={state.DOCUMENTATION_FEE_AMOUNT} />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.DOCUMENTATION_FEE_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency value={state.DOCUMENTATION_FEE_TOTAL} />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.DOCUMENTATION_FEE_AMOUNT} />
              <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
            </Text>
          )}
        </View>

        <View style={[TableStyle.row, TableStyle.greyBackground]}>
          <Text style={[TableStyle.cellLeft]}>Total</Text>
          {!isLease(state.FINANCE_PRODUCT) ? (
            <>
              <Text style={[TableStyle.childCell]}>
                <Currency
                  value={state.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_AMOUNT}
                />
              </Text>
              <Text style={[TableStyle.cell]}>
                <Currency value={state.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_VAT} />
              </Text>
              <Text style={[TableStyle.cellRight]}>
                <Currency
                  value={state.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL}
                />
              </Text>
            </>
          ) : (
            <Text style={[TableStyle.cellRight]}>
              <Currency value={state.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL} />
            </Text>
          )}
        </View>
      </View>
    </div>
  );
};
