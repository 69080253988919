import { IAddressApiFullAddress } from 'types/address';
import { FbFileRef } from 'types/interfaces';

export interface IEvent {
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  extendedProps: {
    dealInformation?: {
      pdid: number;
      piid: number;
      psdid: number;
    };
    calendarOwner: number;
    location: string;
    eventType: TEventType;
    rrule?: string;
    guests: IGuest[];
    invitedUserInstanceIds: number[];
    recurring: ERecurringEvent;
    attachments: FbFileRef[];
    address: IAddressApiFullAddress;
    sequence: number;
    description: string;
    processInstanceId: number;
    notification: boolean;
  };
  id?: string;
}

export interface IGuest {
  title: string;
  UserInstanceEmail: string;
  UserInstanceId: number;
  UserDefinitionId: number;
  status: number;
}

export interface ITeams {
  title: string;
  start: Date;
  url: string;
}

export type TEventType =
  | 'Meeting'
  | 'Call'
  | 'TODO'
  | 'Notes'
  | 'Birthdays'
  | 'Deal_Expiry_Date'
  | 'End_of_Lease_Date'
  | 'Passing_Time_Date'
  | 'Personal_Note'
  | 'Annual_Leave'
  | 'Buying_Appointment'
  | 'Other';

export enum ERecurringEvent {
  DoesNotRepeat = 'Does Not Repeat',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  Annually = 'Annually'
}

export interface IEventFormValues {
  title: string;
  startDate: Date;
  endDate: Date;
  location: string;
  attachments: FbFileRef[];
  guests: IGuest[];
  invitedUserInstanceIds: number[];
  eventType: TEventType;
  recurring: ERecurringEvent;
  description: string;
  address: IAddressApiFullAddress;
  teams: ITeams;
  sequence: number;
  endDateRRule?: Date;
  interval?: number;
  notification?: boolean;
}

export const INIT_FORM: IEventFormValues = {
  title: '',
  startDate: new Date(),
  endDate: new Date(),
  notification: false,
  location: '',
  guests: [],
  teams: {
    title: '',
    start: new Date(),
    url: ''
  },
  sequence: 1,
  invitedUserInstanceIds: [],
  attachments: [],
  eventType: 'Notes',
  recurring: ERecurringEvent.DoesNotRepeat,
  description: '',
  interval: 1,
  address: {
    line_1: '',
    line_2: '',
    line_3: '',
    postcode: '',
    postal_town: '',
    thoroughfare: '',
    building_number: '',
    building_name: '',
    organization_name: '',
    country: '',
    county: ''
  }
};
