import { gql } from '@apollo/client';

export const GET_USER_INSTANCES_WITH_OBJECTS = gql`
  query GetUserInstancesWithObjects(
    $baseUrl: String
    $processInstanceId: Int
    $data: GetUserInstanceInput
  ) {
    GetUserInstancesWithObjects(
      baseURL: $baseUrl
      ProcessInstanceId: $processInstanceId
      data: $data
    ) {
      UserInstance {
        ConsoleUserInstanceId
        DefaultMessage
        DocumentFooter
        DocumentHeader
        EmailFooter
        FromAddress
        FromName
        Id
        IsPublished
        ItemOrder
        LastModified
        LogoURL
        NickName
        OwnerUserInstanceId
        ProfilePicture
        ProxyUserInstanceId
        RelatedUserPermission
        RelationshipStatus
        SmtpHost
        SmtpPassword
        SmtpPort
        SmtpSSL
        SmtpUsername
        SystemAccess
        TelephoneNumber
        ThirdPartyId
        Title
        UserDefinitionId
        UserInstanceEmail
        UserInstancePassword
      }
      CompleteObjectInstanceList {
        ObjectInstance {
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectDescription
          ObjectProcessIndependent
          ObjectRepeat
          ProcessInstanceId
          Selected
          Title
          UserDefinitionId
          UserInstanceId
        }
        FieldInstanceList {
          FieldDefinitionId
          FieldValue
          Id
          IsPublished
          ItemOrder
          LastModified
          ObjectDefinitionId
          ObjectInstanceId
          ProcessInstanceId
          Title
          UserDefinitionId
          UserInstanceId
        }
      }
    }
  }
`;
