import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
import {
  CompleteObjectInstance,
  CompleteUserInstance,
  UserInstance
} from 'types/interfaces';
import { ApolloError } from '@apollo/client';
import { apolloClient } from 'graphql/apolloClient';
import { BugTracker } from 'Utils/Bugtracker';
import {
  GET_COMPLETE_OBJECT_INSTANCE,
  GET_COMPLETE_USER_INSTANCE_DETAIL,
  GET_USER_INSTANCE_DETAIL
} from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import {
  IUserForProcess,
  IUserForProcessRequest,
  IUserInstanceObjectData
} from 'redux/actions/GraphQlActions/interface';
import { GET_QUICK_LITE_USER } from 'graphql/UserInstanceAPI/GetQuickUser/queries';
import { GET_USER_INSTANCES_WITH_OBJECTS } from 'graphql/UserInstanceAPI/GetUserInstancesWithObjects/queries';
import { USERS_FOR_PROCESS_QUERY } from 'graphql/queries';
/**
 * USER INSTANCE API
 */
export const GetUserInstanceSummaryList = async ({ UserDefinitionId }) => {
  let url = `UserInstanceAPI.ashx?action=SUMMARYDICT&userdefinitionid=${UserDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

/**
 * Fetches users associated with a specific ProcessInstance.
 * @param {number} ProcessInstanceId - The identifier for the ProcessInstance.
 * @param {number} ProcessDefinitionId - The identifier for the ProcessDefinition.
 * @param {number|string} UserDefinitionId - The user identifier which can be a number or a string.
 * @param {string} action - The action type, which can be one of 'USERSFORPROCESS', 'ALLUSERSFORPROCESS', 'ASSIGNEDUSERSFORPROCESS', or 'SELECTEDUSERFORPROCESS'.
 * @param {string} baseUrl - The base URL for the Hostname.
 * @returns {Promise<IUserForProcess>} - Returns a promise which resolves to the UserProcess or an "Apollo Error".
 */

export const GetUsersForProcess = async ({
  ProcessInstanceId,
  ProcessDefinitionId,
  UserDefinitionId,
  fetchQueries,
  action,
  baseUrl
}: IUserForProcessRequest): Promise<IUserForProcess[] | ApolloError> => {
  try {
    const { data, error } = await apolloClient.query({
      query: USERS_FOR_PROCESS_QUERY,
      variables: {
        baseUrl,
        processInstanceId: ProcessInstanceId,
        processDefinitionId: ProcessDefinitionId,
        userDefinitionId: UserDefinitionId,
        action
      },
      fetchPolicy: fetchQueries ? 'network-only' : 'cache-first'
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    const { UsersForProcess } = data;

    return UsersForProcess as IUserForProcess[];
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Fetches Both UserInstance & CompleteObjectInstance for a given User.
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} UserInstanceId - The identifier of the UserId to fetch.
 * @returns {Promise<Partial<CompleteUserInstance> | ApolloError>} - A promise that resolves to CompleteUserInstance & UserInstance or an "Apollo Error".
 */

export const GetCompleteUserInstanceDetail = async ({
  baseUrl,
  UserInstanceId,
  fetchPolicy
}: {
  baseUrl: string;
  UserInstanceId: number;
  fetchPolicy?: boolean;
}): Promise<Partial<CompleteUserInstance> | ApolloError> => {
  try {
    const { data, error } = await apolloClient.query({
      query: GET_COMPLETE_USER_INSTANCE_DETAIL,
      variables: {
        baseUrl,
        userInstanceId: UserInstanceId
      },
      fetchPolicy: fetchPolicy ? 'network-only' : 'cache-first'
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    const result = data.GetUserInstanceDetail as CompleteUserInstance;

    // Making Sure THe UserInstanceId is always a number
    let newResult;
    if (result.UserInstance) {
      newResult = {
        ...result,
        UserInstance: {
          ...result.UserInstance,
          Id: parseInt(result.UserInstance.Id.toString())
        }
      };
    } else newResult = result;

    return newResult as CompleteUserInstance;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Fetches UserInstance for a given User.
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} UserInstanceId - The identifier of the UserId to fetch.
 * @returns {Promise<UserInstance | ApolloError>} - A promise that resolves to UserInstance Detail or an "Apollo Error".
 */
export const GetUserInstanceDetail = async ({
  baseUrl,
  UserInstanceId
}: {
  baseUrl: string;
  UserInstanceId: number;
}): Promise<UserInstance | ApolloError> => {
  try {
    const { data, error } = await apolloClient.query({
      query: GET_USER_INSTANCE_DETAIL,
      variables: {
        baseUrl,
        userInstanceId: UserInstanceId
      }
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    return data.GetUserInstanceDetail.UserInstance as UserInstance;
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Fetches Only CompleteObjectInstance for a given User.
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} UserInstanceId - The identifier of the UserId to fetch.
 * @returns {Promise<CompleteObjectInstance | ApolloError>} - A promise that resolves to CompleteUserInstance & UserInstance or an "Apollo Error".
 */

export const GetCompleteUserObjectInstanceDetail = async ({
  baseUrl,
  UserInstanceId
}: {
  baseUrl: string;
  UserInstanceId: number;
}): Promise<CompleteObjectInstance[] | ApolloError> => {
  try {
    const { data, error } = await apolloClient.query({
      query: GET_COMPLETE_OBJECT_INSTANCE,
      variables: {
        baseUrl,
        userInstanceId: UserInstanceId
      }
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    const { GetUserInstanceDetail } = data;
    return GetUserInstanceDetail.CompleteObjectInstanceList as CompleteObjectInstance[];
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * Fetches UserInstance for a given User.
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {string} UserInstanceId - The identifier of the UserId to fetch.
 * @returns {Promise<UserInstance | ApolloError>} - A promise that resolves to UserInstance or an "Apollo Error".
 */

export const GetQuickLiteUser = async ({
  baseUrl,
  UserInstanceId
}: {
  baseUrl: string;
  UserInstanceId: number;
}): Promise<{ data: UserInstance; loading: boolean } | ApolloError> => {
  try {
    const { data, error, loading } = await apolloClient.query({
      query: GET_QUICK_LITE_USER,
      variables: {
        baseUrl,
        userInstanceId: UserInstanceId
      }
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    return { data: data.QuickLiteUser, loading } as {
      data: UserInstance;
      loading: boolean;
    };
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

/**
 * This is primarily used in the Custom GDPR step to return the GDPR object for the given user,
 * but can be used to retrieve a specific object instance(s) for a given list of Object Definition Ids
 * @param {string} baseUrl - The base URL for the Hostname.
 * @param {number} ProcessInstanceId - The identifier of the ProcessInstanceId to fetch.
 * @param {IUserInstanceObjectData} data - The data object containing the UserInstanceIdList and ObjectDefinitionIdList.
 * @returns {Promise<{ UserInstance: UserInstance; CompleteObjectInstanceList: CompleteObjectInstance[] } | ApolloError>} - A promise that resolves a UserInstance & CompleteObjectInstanceList or an "Apollo Error".
 */

const convertToInts = (obj: {
  ObjectDefinitionIdList: number[] | string[];
  UserInstanceIdList: number[] | string[];
}) => {
  return {
    ObjectDefinitionIdList: obj.ObjectDefinitionIdList.map(Number),
    UserInstanceIdList: obj.UserInstanceIdList.map(Number)
  };
};

export const GetUserInstanceWithObjects = async ({
  baseUrl,
  ProcessInstanceId,
  data: payload
}: {
  baseUrl: string;
  ProcessInstanceId: number;
  data: IUserInstanceObjectData;
}): Promise<Partial<CompleteUserInstance>[] | ApolloError> => {
  try {
    const convertedPayload = convertToInts(payload);
    const { data, error } = await apolloClient.query({
      query: GET_USER_INSTANCES_WITH_OBJECTS,
      variables: {
        baseUrl,
        processInstanceId: ProcessInstanceId,
        data: convertedPayload
      },
      fetchPolicy: 'network-only'
    });

    if (error) return new ApolloError({ errorMessage: error.message });
    return data.GetUserInstancesWithObjects as {
      UserInstance: UserInstance;
      CompleteObjectInstanceList: CompleteObjectInstance[];
    }[];
  } catch (e) {
    BugTracker.notify(e);

    const error = e as Error;
    const errorMessage = error.message || 'An unexpected error occurred';
    return new ApolloError({ errorMessage });
  }
};

export const GetData = async ({ action, UserDefinitionId }) => {
  let url = '';
  if (action === 'define')
    url = `UserDefinitionAPI.ashx?action=${action}&userdefinitionid=${UserDefinitionId}`;
  if (action === 'list')
    url = `UserInstanceAPI.ashx?action=${action}&userdefinitionid=${UserDefinitionId}`;
  if (action === 'definitionlist')
    url = `UserDefinitionAPI.ashx?action=${action}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const PostPasswordReset = async (data: UserInstance) => {
  let url = `UserInstanceAPI.ashx?action=PASSWORDRESET`;
  const config: AxiosRequestConfig = { method: 'POST', url, data };
  return await dbQueue({ config, url });
};
