import { Grid, Paper, Typography } from '@material-ui/core';
import Time from 'common/Time';
import { useProcess } from 'hooks';
import { useState } from 'react';
import { theme } from 'theme';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance
} from 'types/interfaces';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { formatToGBP } from './AssetSummary';

interface IQuoteInfoValues {
  FinanceProduct: string;
  NetAdvance: string;
  Deposit: string;
  FundingProfile: string;
  RepaymentValue: string;
  PaymentFrequency: string;
  StartDate: string;
  FirstPaymentDate: string;
}

interface ICalculatorInfoRowProps {
  title: string;
  value: string | number;
  CalculatorValues: IQuoteInfoValues;
}

const fieldIdToKeyMap = {
  21815: 'FinanceProduct',
  18321: 'NetAdvance',
  18331: 'StartDate',
  23271: 'FundingProfile',
  18322: 'RepaymentValue',
  18333: 'PaymentFrequency',
  18325: 'Deposit'
};

const QuoteSummaryParent = ({
  quoteValues
}: {
  quoteValues: CompleteObjectInstance[];
}) => {
  const { currentProcess } = useProcess();
  const { filteredDeclined } = isDeclined(quoteValues);
  const [showAll, setShowAll] = useState(false);

  const quotesToShow: CompleteObjectInstance[] = showAll
    ? quoteValues
    : filteredDeclined;
  return (
    <>
      {Object.values(quotesToShow).map(
        (CompleteObjectInstance: CompleteObjectInstance, idx: number) => {
          const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
          let isEmpty = FieldInstanceList.every(
            (FieldInstance: FieldInstance) =>
              18321 !== FieldInstance.FieldDefinitionId ||
              FieldInstance.FieldValue === ''
          );

          if (isEmpty) return <div key={idx} />;
          return (
            <Paper
              key={idx}
              style={{
                width: '100%',
                padding: theme.spacing(1)
              }}>
              <Typography
                style={{
                  paddingBottom: theme.spacing(1),
                  paddingTop: theme.spacing(1)
                }}
                variant="h5">
                Quote Id: {CompleteObjectInstance.ObjectInstance.Id}
              </Typography>
              <QuoteSummaryText
                CompleteObjectInstance={CompleteObjectInstance}
              />
            </Paper>
          );
        }
      )}
    </>
  );
};

export default QuoteSummaryParent;

const QuoteSummaryText = ({
  CompleteObjectInstance
}: {
  CompleteObjectInstance: CompleteObjectInstance;
}) => {
  let CalculatorValues: IQuoteInfoValues = {
    FinanceProduct: '',
    NetAdvance: '',
    Deposit: '',
    FundingProfile: '',
    RepaymentValue: '',
    PaymentFrequency: '',
    StartDate: '',
    FirstPaymentDate: ''
  };

  const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
  FieldInstanceList.forEach((FieldInstance: FieldInstance) => {
    const fieldDefId = FieldInstance.FieldDefinitionId;
    const fieldKey = fieldIdToKeyMap[fieldDefId];

    if (fieldKey) {
      CalculatorValues[fieldKey] = FieldInstance.FieldValue as string | number;
    }
  });

  const firstPayment = firstPaymentDate(
    CalculatorValues.StartDate as string,
    CalculatorValues.PaymentFrequency as string
  );

  CalculatorValues.FirstPaymentDate = new Date(
    firstPayment
  ).toLocaleDateString();
  return (
    <Grid container justifyContent="space-between">
      {Object.entries(CalculatorValues).map(([key, value]) => (
        <QuoteInfoRow
          title={key}
          value={value}
          key={key}
          CalculatorValues={CalculatorValues}
        />
      ))}
    </Grid>
  );
};

const QuoteInfoRow = ({
  title,
  value,
  CalculatorValues
}: ICalculatorInfoRowProps) => {
  const splitTitle = title.replace(/([A-Z])/g, ' $1').trim();

  const isNumeric = (str: any): str is number | string => {
    if (typeof str === 'number') return true;
    if (typeof str !== 'string') return false;
    return !isNaN(parseFloat(str as string)) && isFinite(Number(str));
  };

  const isValidDate = (dateString: string) => {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date as any);
  };

  let displayValue;
  if (title === 'FundingProfile' && typeof value === 'string') {
    const formattedFrequency = formatFrequency(
      CalculatorValues.PaymentFrequency
    );
    displayValue = `${value} ${formattedFrequency}`;
  } else if (isNumeric(value) && title !== 'FundingProfile') {
    displayValue = formatToGBP(parseFloat(value as string));
  } else if (isValidDate(value as string)) {
    displayValue = <Time time={new Date(value as string)} type="date" />;
  } else {
    displayValue = value;
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography
          style={{ fontWeight: 'bold', color: theme.palette.grey[500] }}
          variant="h6">
          {splitTitle}:
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          data-cy="quote-summary-value"
          style={{ fontWeight: 'bold', color: theme.palette.grey[500] }}
          variant="h6">
          {displayValue}
        </Typography>
      </Grid>
    </Grid>
  );
};

const isDeclined = (quoteValues: CompleteObjectInstance[]) => {
  const DeclinedFieldInstances: number[] = [23524, 23591];

  const declined: CompleteObjectInstance[] = quoteValues.filter(
    (CompleteObjectInstance: CompleteObjectInstance) => {
      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
      return FieldInstanceList.some((FieldInstance: FieldInstance) => {
        return (
          DeclinedFieldInstances.includes(FieldInstance.FieldDefinitionId) &&
          FieldInstance.FieldValue.includes('true')
        );
      });
    }
  );

  const filteredDeclined: CompleteObjectInstance[] = quoteValues.filter(
    (CompleteObjectInstance: CompleteObjectInstance) => {
      const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
      return FieldInstanceList.some((FieldInstance: FieldInstance) => {
        return (
          DeclinedFieldInstances.includes(FieldInstance.FieldDefinitionId) &&
          (FieldInstance.FieldValue.includes('false') ||
            FieldInstance.FieldValue === '')
        );
      });
    }
  );

  return { declined, filteredDeclined };
};

let firstPaymentDate = (StartDate, freq) => {
  let firstPay: Date | number = new Date(StartDate);
  switch (freq) {
    case 'Monthly':
      firstPay = firstPay.setMonth(firstPay.getMonth() + 1);
      break;
    case 'Quarterly':
      firstPay = firstPay.setMonth(firstPay.getMonth() + 3);
      break;
    case 'SemiAnnually':
      firstPay = firstPay.setMonth(firstPay.getMonth() + 6);
      break;
    case 'Annually':
      firstPay = firstPay.setMonth(firstPay.getMonth() + 12);
      break;
    default:
      firstPay = firstPay.setMonth(firstPay.getMonth() + 1);
  }
  return firstPay;
};

const formatFrequency = (freq: string) => {
  if (['SemiAnnually', 'Annually'].includes(freq)) {
    return freq.replace('ly', '');
  } else {
    return `${freq.replace('ly', '')}s`;
  }
};
