import { gql } from '@apollo/client';

export const GET_QUICK_LITE_USER = gql`
  query QuickLiteUser($userInstanceId: Int, $baseUrl: String) {
    QuickLiteUser(UserInstanceId: $userInstanceId, baseURL: $baseUrl) {
      ConsoleUserInstanceId
      DefaultMessage
      DocumentFooter
      DocumentHeader
      EmailFooter
      FromAddress
      FromName
      Id
      IsPublished
      ItemOrder
      LastModified
      LogoURL
      NickName
      OwnerUserInstanceId
      ProfilePicture
      ProxyUserInstanceId
      RelatedUserPermission
      RelationshipStatus
      SmtpHost
      SmtpPassword
      SmtpPort
      SmtpSSL
      SmtpUsername
      SystemAccess
      TelephoneNumber
      ThirdPartyId
      Title
      UserDefinitionId
      UserInstanceEmail
      UserInstancePassword
    }
  }
`;
