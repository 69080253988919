import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * PROCESS STEP API
 */
export const GetSearchUser = async ({
  ProcessStepDefinitionId,
  ProcessInstanceId
}) => {
  if (!ProcessInstanceId) ProcessInstanceId = 0;
  let url = `ProcessStepApi.ashx?action=STEPUSERS&processstepdefinitionid=${ProcessStepDefinitionId}&processinstanceid=${ProcessInstanceId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetStepUsers = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId
}) => {
  let url = `ProcessStepAPI.ashx?processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetStepDocumentAsHTML = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId
}) => {
  let url = `ProcessStepAPI.ashx?action=GETPREVIEW&processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetStepSystemDocument = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId
}) => {
  let url = `ProcessStepAPI.ashx?action=GETPDF&processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  const method = 'get';
  const config: AxiosRequestConfig = { method, responseType: 'blob', url };
  return await dbQueue({ config, url });
};

export const GetGeneratedStepSystemDocument = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId,
  DocumentStepDefinitionId,
  action = 'ExistingPDF'
}) => {
  let url = `ProcessStepAPI.ashx?action=${action}&DocumentProcessStepDefinitionId=${DocumentStepDefinitionId}&processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}`;
  const method = 'get';
  const config: AxiosRequestConfig = { method, responseType: 'blob', url };
  return await dbQueue({ config, url });
};

export const GetPopulatedEmail = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId,
  RecipientUserInstanceId,
  TargetUserInstanceId
}) => {
  let url = `ProcessStepAPI.ashx?action=GETPOPULATEDEMAIL&processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}&RecipientUserInstanceId=${RecipientUserInstanceId}&TargetUserInstanceId=${TargetUserInstanceId}`;
  const method = 'get';
  const config: AxiosRequestConfig = { method, url };

  // Should return this
  interface CustomEmail {
    Subject: string;
    Body: string;
    Attachment: null;
  }

  return await dbQueue({ config, url });
};
