import { CSSProperties, ReactNode, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Dialog from './dialog';
import CashFlowChart from '../CashFlowChart';
import Time from 'common/Time';
import NumberFormat from 'react-number-format';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import OutputLight from './outputLight';
import { StepperContext } from 'components/Stepper/context';
import { useProcess } from 'hooks';
import SkeletonComponent from 'common/Skeletons/CalculatorQutoes';
import { OutputContext } from './context/OutputContext';
import { Currency, ForFrom, NotesUI, StringSpacing } from './components';
import { Grid } from '@material-ui/core';
import OutputHeader from './components/OutputHeader';
import { FieldDefinition, FieldInstance } from 'types/interfaces';
import { theme } from 'theme';
import QuoteHeader from './components/QuoteHeader';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    borderRadius: theme.shape.borderRadius
  },
  nested: { paddingLeft: theme.spacing(4) },
  display: {
    display: 'flex',
    justifyContent: 'space-around',
    background: theme.palette.primary.main,
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius
  },
  displayBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.primary.contrastText
  },
  displayBoxMetric: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  commissionIndex: {
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
    border: 0,
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)',
    color: 'white',
    height: 38,
    padding: '0 10px',
    margin: 1
  },
  skeletonCommission: {
    borderRadius: theme.shape.borderRadius,
    background: `linear-gradient(45deg, ${theme.palette.primary.main} 30%, ${theme.palette.primary.light} 90%)`,
    border: 0,
    boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .3)'
  }
}));

const Output = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { quotesRefreshing } = useContext(StepperContext);
  const { landingpage, currentProcess } = useProcess();
  const {
    props,
    checkEmptyAmountAndCashFlow,
    open,
    handleClick,
    lists,
    regulated,
    QuoteId,
    subSystemUser,
    OverviewQuoteId
  } = useContext(OutputContext);
  const { ObjectInstance: CompleteObjectInstance } = props;

  const { isEmpty, hasCashFlow } = checkEmptyAmountAndCashFlow();

  if (isEmpty && props.display === 'clientView') return null;
  if (subSystemUser) {
    return (
      <div
        style={{
          backgroundColor: props.selected
            ? theme.palette.success.light
            : 'inherit'
        }}>
        <OutputLight classes={classes} />
      </div>
    );
  }

  if (open.calculator) {
    return (
      <Dialog
        QuoteId={QuoteId}
        handleClose={() => handleClick('calculator')}
        open={open.calculator}
        {...props}
      />
    );
  }

  let isLoanDeal = false;
  if (Object.keys(currentProcess).length !== 0) {
    const Title = currentProcess?.ProcessDefinition?.Title;
    isLoanDeal = Title.includes('Loan');
  }

  if (quotesRefreshing) return <SkeletonComponent landing_page={false} />;
  return (
    <StyledPaper>
      <QuoteHeader />
      {!isEmpty && <div style={{ paddingBottom: theme.spacing(2) }} />}

      <Grid container alignItems="center">
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}>
          {!isEmpty && <OutputHeader />}
        </Grid>
        <Grid item xs={12}>
          <List
            className={classes.root}
            component="nav"
            style={{ background: isEmpty ? theme.palette.success.light : '' }}
            subheader={
              <ListSubheader
                component="div"
                disableSticky
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.primary.main,
                  padding: 0
                }}>
                {isEmpty && !props.selected && (
                  <div>
                    <Typography style={{ fontWeight: 'bold' }}>{`Quote Id: ${
                      !props.isOverview ? QuoteId : OverviewQuoteId
                    }`}</Typography>
                  </div>
                )}

                <div style={{ flexGrow: 1 }} />

                {isEmpty && <OutputHeader />}
              </ListSubheader>
            }>
            {isEmpty ? (
              <div />
            ) : (
              <div>
                <div className={classes.display}>
                  {lists.primarymetrics.map((FieldDefinition, i) => {
                    const FieldInstanceList = getFieldInstances(
                      CompleteObjectInstance
                    );
                    const FieldInstance: FieldInstance | undefined =
                      FieldInstanceList.find(
                        (FieldInstance: FieldInstance) =>
                          FieldInstance.FieldDefinitionId === FieldDefinition.Id
                      );

                    let FieldValue = FieldInstance && FieldInstance.FieldValue;
                    let Title = FieldDefinition.Title;
                    let startAdornment = '';
                    let endAdornment = '';

                    if (Title === 'MonthlyPayment') {
                      startAdornment = '£ ';
                      Title = 'Repayment Value';
                    }
                    if (Title === 'Amount') {
                      startAdornment = '£ ';
                      Title = 'Advance';
                    }
                    if (Title === 'Term') {
                      Title = 'Term';
                      endAdornment = ' MTS';
                    }
                    return (
                      <div className={classes.displayBox} key={i}>
                        <div>{Title}</div>
                        <div className={classes.displayBoxMetric}>
                          <h1 style={{ marginRight: 3 }}>{startAdornment}</h1>
                          <NumberFormat
                            decimalScale={2}
                            displayType={'text'}
                            style={{
                              fontSize: '1.5rem',
                              fontWeight: 800
                            }}
                            thousandSeparator
                            value={FieldValue}
                          />
                          <h1 style={{ marginLeft: 3 }}>{endAdornment}</h1>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div>
                  {lists.notes.map(
                    (FieldDefinition: FieldDefinition, i: number) => {
                      return (
                        <Paper square key={i}>
                          <NotesUI
                            FieldDefinition={FieldDefinition}
                            props={props}
                          />
                        </Paper>
                      );
                    }
                  )}
                </div>
                <div>
                  {props.display !== 'clientView' && (
                    <div>
                      {lists.calculationtype.map(
                        (FieldDefinition: FieldDefinition, i: number) => {
                          const FieldInstanceList = getFieldInstances(
                            CompleteObjectInstance
                          );
                          const FieldInstance: FieldInstance | undefined =
                            FieldInstanceList.find(
                              (FieldInstance: FieldInstance) =>
                                FieldInstance.FieldDefinitionId ===
                                FieldDefinition.Id
                            );

                          let FieldValue =
                            FieldInstance && FieldInstance.FieldValue;

                          return <ForFrom value={FieldValue} key={i} />;
                        }
                      )}

                      <div
                        style={{
                          paddingTop: 10,
                          paddingBottom: 10,
                          paddingLeft: 5
                        }}>
                        <Button
                          onClick={() => handleClick('showChart')}
                          className={classes.commissionIndex}>
                          {open.showChart ? (
                            <>
                              Hide Chart <ArrowDropDownIcon />
                            </>
                          ) : (
                            <>
                              Show Chart <ArrowRightIcon />
                            </>
                          )}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  {props.FieldDefinitionDict &&
                    props.display !== 'clientView' &&
                    Object.values(props.FieldDefinitionDict).map(
                      (FieldDefinition, i) => {
                        if (FieldDefinition.Title === 'CashFlow') {
                          const FieldInstanceList = getFieldInstances(
                            CompleteObjectInstance
                          );
                          const FieldInstance: FieldInstance | undefined =
                            FieldInstanceList.find(
                              (FieldInstance: FieldInstance) =>
                                FieldInstance.FieldDefinitionId ===
                                FieldDefinition.Id
                            );

                          if (
                            FieldInstance &&
                            FieldInstance?.FieldValue?.length > 0
                          ) {
                            const cashflow = JSON.parse(
                              FieldInstance.FieldValue
                            );

                            if (open.showChart) {
                              return (
                                <CashFlowChart
                                  key={i}
                                  data={cashflow}
                                  display={props.display}
                                />
                              );
                            } else return <div />;
                          }
                        }
                      }
                    )}
                </div>
                {lists.mainlist.map((FieldDefinition, i) => {
                  const FieldInstanceList = getFieldInstances(
                    CompleteObjectInstance
                  );
                  const FieldInstance: FieldInstance | undefined =
                    FieldInstanceList.find(
                      (FieldInstance: FieldInstance) =>
                        FieldInstance.FieldDefinitionId === FieldDefinition.Id
                    );

                  let FieldValue = FieldInstance && FieldInstance.FieldValue;
                  let Title = FieldDefinition.Title;

                  if (Title === '_Balloon') {
                    const FieldInstance: FieldInstance | undefined =
                      FieldInstanceList.find(
                        (FieldInstance: FieldInstance) =>
                          FieldInstance.FieldDefinitionId === FieldDefinition.Id
                      );

                    const objConvertor: number | string | undefined =
                      FieldInstance?.FieldValue &&
                      JSON.parse(FieldInstance?.FieldValue);

                    const hiddenParameters = [
                      'Balloon_VatRate',
                      'Balloon_Vat',
                      'TotalBalloon',
                      'ContractPeriod'
                    ];

                    if (!objConvertor) return;
                    return Object.entries(objConvertor).map((item, key) => {
                      if (hiddenParameters.includes(item[0])) return null;

                      return (
                        <ListItem key={key} style={{ alignItems: 'flex-end' }}>
                          <p>{item[0]}</p>

                          <div
                            style={{
                              flexGrow: 1,
                              borderBottom: '1px dotted lightgrey',
                              marginLeft: 15,
                              marginRight: 15
                            }}
                          />

                          <Currency value={item[1]} />
                        </ListItem>
                      );
                    });
                  }

                  return (
                    <div key={i}>
                      {props.display !== 'clientView' && (
                        <ListItem style={{ alignItems: 'flex-end' }}>
                          <p>{FieldDefinition.Title}</p>
                          <div
                            style={{
                              flexGrow: 1,
                              borderBottom: '1px dotted lightgrey',
                              marginLeft: 15,
                              marginRight: 15
                            }}
                          />

                          {Title === 'StartDate' && FieldValue ? (
                            <Time time={FieldValue} type="date" />
                          ) : (
                            <NumberFormat
                              decimalScale={2}
                              displayType={'text'}
                              prefix={'£'}
                              thousandSeparator
                              value={FieldValue}
                            />
                          )}
                        </ListItem>
                      )}
                    </div>
                  );
                })}

                <ListItem button onClick={() => handleClick('rates')}>
                  <Typography color="secondary" style={{ flexGrow: 1 }}>
                    {'Rates'.toUpperCase()}
                  </Typography>
                  {!open.rates ? (
                    <ExpandLess color="secondary" />
                  ) : (
                    <ExpandMore color="secondary" />
                  )}
                </ListItem>
                <Collapse in={!open.rates} timeout="auto" unmountOnExit>
                  {lists.rateslist.map((FieldDefinition, i) => {
                    const FieldInstanceList = getFieldInstances(
                      CompleteObjectInstance
                    );
                    const FieldInstance: FieldInstance | undefined =
                      FieldInstanceList.find(
                        (FieldInstance: FieldInstance) =>
                          FieldInstance.FieldDefinitionId === FieldDefinition.Id
                      );

                    const non_regulated = ['NominalFlatRate'];
                    const clientViewExcludes = [
                      'Yield',
                      'MarginRate',
                      'NominalFlatRate'
                    ];

                    if (
                      regulated.isRegulatedParty &&
                      non_regulated.includes(FieldDefinition.Title)
                    ) {
                      return null;
                    }

                    if (
                      props.display === 'clientView' &&
                      clientViewExcludes.includes(FieldDefinition.Title)
                    ) {
                      return null;
                    }

                    if (isLoanDeal && FieldDefinition.Title === 'VATPercentage')
                      return null;

                    return (
                      <ListItem key={i} style={{ alignItems: 'flex-end' }}>
                        <p style={{ paddingLeft: 15 }}>
                          {StringSpacing(FieldDefinition.Title)}
                        </p>

                        <div
                          style={{
                            flexGrow: 1,
                            borderBottom: '1px dotted lightgrey',
                            marginLeft: 15,
                            marginRight: 15
                          }}
                        />

                        <NumberFormat
                          decimalScale={5}
                          displayType={'text'}
                          suffix={'%'}
                          thousandSeparator
                          value={FieldInstance && FieldInstance.FieldValue}
                        />
                      </ListItem>
                    );
                  })}
                </Collapse>
                <ListItem button onClick={() => handleClick('fees')}>
                  <Typography color="secondary" style={{ flexGrow: 1 }}>
                    {'Fees'.toUpperCase()}
                  </Typography>
                  {open.fees ? (
                    <ExpandLess color="secondary" />
                  ) : (
                    <ExpandMore color="secondary" />
                  )}
                </ListItem>
                <Collapse in={open.fees} timeout="auto" unmountOnExit>
                  {lists.advancedlist.map((FieldDefinition, i) => {
                    const FieldInstanceList = getFieldInstances(
                      CompleteObjectInstance
                    );
                    const FieldInstance: FieldInstance | undefined =
                      FieldInstanceList.find(
                        (FieldInstance: FieldInstance) =>
                          FieldInstance.FieldDefinitionId === FieldDefinition.Id
                      );

                    const clientViewExcludes = [
                      'NPV',
                      'CustomerYield',
                      'DocFeeUpsell'
                    ];

                    if (
                      props.display === 'clientView' &&
                      clientViewExcludes.includes(FieldDefinition.Title)
                    )
                      return null;
                    return (
                      <ListItem key={i} style={{ alignItems: 'flex-end' }}>
                        <p style={{ paddingLeft: 15 }}>
                          {StringSpacing(FieldDefinition.Title)}
                        </p>

                        <div
                          style={{
                            flexGrow: 1,
                            borderBottom: '1px dotted lightgrey',
                            marginLeft: 15,
                            marginRight: 15
                          }}
                        />

                        <NumberFormat
                          decimalScale={2}
                          displayType={'text'}
                          prefix={'£'}
                          thousandSeparator
                          value={FieldInstance && FieldInstance.FieldValue}
                        />
                      </ListItem>
                    );
                  })}
                </Collapse>

                {props.display !== 'clientView' && !landingpage && (
                  <>
                    <ListItem button onClick={() => handleClick('commission')}>
                      <Typography color="secondary" style={{ flexGrow: 1 }}>
                        {'Commission'.toUpperCase()}
                      </Typography>
                      {open.commission ? (
                        <ExpandLess color="secondary" />
                      ) : (
                        <ExpandMore color="secondary" />
                      )}
                    </ListItem>
                    <Collapse in={open.commission} timeout="auto" unmountOnExit>
                      {lists.commissionlist.map((FieldDefinition, i) => {
                        const FieldInstanceList = getFieldInstances(
                          CompleteObjectInstance
                        );

                        const FieldInstance: FieldInstance | undefined =
                          FieldInstanceList.find(
                            (FieldInstance: FieldInstance) =>
                              FieldInstance.FieldDefinitionId ===
                              FieldDefinition.Id
                          );

                        const Title = FieldDefinition.Title;
                        if (Title === '_Commission') {
                          const FieldInstance: FieldInstance | undefined =
                            FieldInstanceList.find(
                              (FieldInstance: FieldInstance) =>
                                FieldInstance.FieldDefinitionId ===
                                FieldDefinition.Id
                            );

                          const objConvertor: number | string | undefined =
                            FieldInstance?.FieldValue &&
                            JSON.parse(FieldInstance?.FieldValue);

                          const hiddenParameters = [
                            'Broker_Commission',
                            'Broker_Rate',
                            'Broker_Vat',
                            'Broker_VatRate',
                            'Funder_Vat',
                            'Funder_VatRate',
                            'Introducer_Vat',
                            'Introducer_VatRate',
                            'TotalPayable',
                            'TotalPayable_IncVat'
                          ];

                          if (!objConvertor) return;
                          return Object.entries(objConvertor).map(
                            (item, key) => {
                              if (hiddenParameters.includes(item[0]))
                                return null;

                              return (
                                <ListItem
                                  key={key}
                                  style={{ alignItems: 'flex-end' }}>
                                  <p style={{ paddingLeft: 15 }}>
                                    {StringSpacing(item[0])}
                                  </p>

                                  <div
                                    style={{
                                      flexGrow: 1,
                                      borderBottom: '1px dotted lightgrey',
                                      marginLeft: 15,
                                      marginRight: 15
                                    }}
                                  />

                                  <Currency value={item[1]} />
                                </ListItem>
                              );
                            }
                          );
                        }

                        return (
                          <ListItem key={i} style={{ alignItems: 'flex-end' }}>
                            <p style={{ paddingLeft: 15 }}>
                              {StringSpacing(FieldDefinition.Title)}
                            </p>

                            <div
                              style={{
                                flexGrow: 1,
                                borderBottom: '1px dotted lightgrey',
                                marginLeft: 15,
                                marginRight: 15
                              }}
                            />

                            {FieldDefinition.Title ===
                            'CommissionPercentage' ? (
                              <NumberFormat
                                decimalScale={5}
                                displayType={'text'}
                                suffix={'%'}
                                thousandSeparator
                                value={
                                  FieldInstance && FieldInstance.FieldValue
                                }
                              />
                            ) : (
                              <NumberFormat
                                decimalScale={2}
                                displayType={'text'}
                                prefix={'£'}
                                thousandSeparator
                                value={
                                  FieldInstance && FieldInstance.FieldValue
                                }
                              />
                            )}
                          </ListItem>
                        );
                      })}
                    </Collapse>
                  </>
                )}
              </div>
            )}

            <Grid container direction="column">
              {regulated?.isRegulatedParty && (
                <Grid
                  item
                  style={{
                    paddingLeft:
                      props.selected || props.isDeclined ? theme.spacing(1) : ''
                  }}>
                  <Tooltip
                    placement="top"
                    title={
                      'The commission index displays the order of quotes based on the amount of commission included, 1 being the least.'
                    }>
                    <Typography variant="caption">
                      Commission Index: {props.index + 1}
                    </Typography>
                  </Tooltip>
                </Grid>
              )}

              {!isEmpty && (
                <Grid
                  item
                  container
                  style={{
                    paddingLeft:
                      props.selected || props.isDeclined
                        ? theme.spacing(1)
                        : '',
                    paddingBottom: theme.spacing(1)
                  }}>
                  <Grid item>
                    <Typography variant="caption">Last Modified: </Typography>
                  </Grid>
                  <Grid
                    item
                    style={{
                      paddingLeft: 2
                    }}>
                    <Typography variant="caption">
                      <Time
                        time={
                          CompleteObjectInstance.ObjectInstance.LastModified
                        }
                        type="timeago"
                      />
                    </Typography>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </List>
        </Grid>
      </Grid>
    </StyledPaper>
  );
};

export default Output;

const StyledPaper = ({ children }: { children: ReactNode }) => {
  const { props, checkEmptyAmountAndCashFlow } = useContext(OutputContext);
  const { isEmpty } = checkEmptyAmountAndCashFlow();
  const { selected, isDeclined } = props;

  const styles: CSSProperties = {
    position: 'relative',
    padding: theme.spacing(2),
    marginBottom: 10,

    background: selected
      ? theme.palette.success.light
      : isDeclined
      ? theme.palette.error.light
      : isEmpty
      ? theme.palette.success.light
      : 'inherit',
    borderRadius: theme.shape.borderRadius,
    borderColor: isEmpty
      ? theme.palette.success.main
      : isDeclined
      ? theme.palette.error.main
      : 'inherit',
    border: selected
      ? `2px solid ${theme.palette.success.main}`
      : isDeclined
      ? `2px solid ${theme.palette.error.main}`
      : `1px solid ${theme.palette.grey[300]}`
  };

  return <Paper style={styles}>{children}</Paper>;
};

/* {!props.readOnly && (
              <Tooltip
                placement="top"
                title={
                  'The commission index displays the order of quotes based on the amount of commission included, 1 being the least.'
                }>
                <Button className={classes.commissionIndex}>
                  Commission Index: {props.index + 1}
                </Button>
              </Tooltip>
            )} */
