import { successNotif } from 'components/Notifications';
import { Props } from './';
import { selectObjectInstance } from 'redux/actions/objectUpdater';
import { store } from 'redux/store';
import { createNotification } from 'react-redux-notify';

export const customFieldActions = async ({ token, data, props }: Props) => {
  // If the lender field is updated
  if (
    [21961, 21159].includes(data.FieldDefinitionId) &&
    props.ObjectInstance.Selected
  ) {
    // Then lets re-select the object here
    await selectObjectInstance({
      token,
      data: {
        ObjectInstance: props.ObjectInstance,
        FieldInstanceDict: {},
        FieldInstanceList: []
      },
      props: { ProcessInstanceId: props.ProcessInstance.Id },
      action: 'SELECT'
    });

    return store.dispatch(createNotification(successNotif('Lender Updated')));
  }
};
