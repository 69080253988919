import { Text, View } from '@react-pdf/renderer';

export const FinanceCharges = ({ state, Currency, TableStyle, styles }) => {
  const isLease = (product: string) =>
    product.toLowerCase().startsWith('lease');

  const LeaseRow = ({ label, value, isGrey = false, isTotal = false }) => (
    <View
      style={[
        TableStyle.row,
        isGrey ? TableStyle.greyBackground : null,
        isTotal ? styles.totalRow : null
      ]}>
      <Text style={[TableStyle.cellLeft]}>{label}</Text>
      <Text style={[TableStyle.cell]} />
      <Text style={[TableStyle.cell]} />
      <Text style={[TableStyle.aloneCell]}>
        <Currency value={value} />
        <Text style={{ position: 'absolute', right: 0 }}> + VAT</Text>
      </Text>
    </View>
  );

  const NonLeaseRow = ({
    label,
    amount,
    vat,
    total,
    isGrey = false,
    isTotal = false
  }) => (
    <View
      style={[
        TableStyle.row,
        isGrey ? TableStyle.greyBackground : null,
        isTotal ? styles.totalRow : null
      ]}>
      <Text style={[TableStyle.cellLeft]}>{label}</Text>
      <Text style={[TableStyle.cell]}>
        <Currency value={amount} />
      </Text>
      <Text style={[TableStyle.cell]}>
        <Currency value={vat} />
      </Text>
      <Text style={[TableStyle.cellRight]}>
        <Currency value={total} />
      </Text>
    </View>
  );

  const leaseRows = [
    { label: 'Interest Charges', value: state.INTEREST_CHARGES_AMOUNT },
    { label: 'Documentation Fee', value: state.DOCUMENTATION_FEE_AMOUNT },
    { label: 'Option to Purchase Fee', value: state.OPTION_TO_PURCHASE_AMOUNT },
    {
      label: 'Total Charge for Finance',
      value: state.TOTAL_CHARGE_FOR_FINANCE_AMOUNT
    },
    {
      label: 'Total Amount Payable',
      value: state.TOTAL_AMOUNT_PAYABLE_INC_VAT,
      isTotal: true
    }
  ];

  const nonLeaseRows = [
    {
      label: 'Interest Charges',
      amount: state.INTEREST_CHARGES_AMOUNT,
      vat: state.INTEREST_CHARGES_VAT,
      total: state.INTEREST_CHARGES_TOTAL
    },
    {
      label: 'Documentation Fee',
      amount: state.DOCUMENTATION_FEE_AMOUNT,
      vat: state.DOCUMENTATION_FEE_VAT,
      total: state.DOCUMENTATION_FEE_TOTAL
    },
    {
      label: 'Option to Purchase Fee',
      amount: state.OPTION_TO_PURCHASE_AMOUNT,
      vat: state.OPTION_TO_PURCHASE_VAT,
      total: state.OPTION_TO_PURCHASE_TOTAL
    },
    {
      label: 'Total Charge for Finance',
      amount: state.TOTAL_CHARGE_FOR_FINANCE_AMOUNT,
      vat: state.TOTAL_CHARGE_FOR_FINANCE_VAT,
      total: state.TOTAL_CHARGE_FOR_FINANCE_TOTAL
    },
    {
      label: 'Total Amount Payable Incl. VAT',
      amount: state.TOTAL_AMOUNT_PAYABLE_INC_VAT,
      vat: '',
      total: '',
      isTotal: true
    }
  ];

  return (
    <div>
      <View style={TableStyle.table}>
        <View style={[TableStyle.row]}>
          <Text style={[TableStyle.cell]} />
          <Text style={[TableStyle.cell]} />
          <Text style={[TableStyle.cellRight, styles.tableTitleText]}>
            Amount
          </Text>
          {!isLease(state.FINANCE_PRODUCT) && (
            <>
              <Text style={[TableStyle.cellMiddleAlone, styles.tableTitleText]}>
                VAT
              </Text>
              <Text style={[TableStyle.cellRight, styles.tableTitleText]}>
                Total
              </Text>
            </>
          )}
        </View>

        <div style={styles.line} />

        {isLease(state.FINANCE_PRODUCT)
          ? leaseRows.map((row, index) => (
              <LeaseRow
                key={row.label}
                label={row.label}
                value={row.value}
                isGrey={index % 2 === 1}
                isTotal={row.isTotal}
              />
            ))
          : nonLeaseRows.map((row, index) => (
              <NonLeaseRow
                key={row.label}
                label={row.label}
                amount={row.amount}
                vat={row.vat}
                total={row.total}
                isGrey={index % 2 === 1}
                isTotal={row.isTotal}
              />
            ))}
      </View>
    </div>
  );
};
