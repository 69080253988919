import Alert from '@material-ui/lab/Alert';
import { CircularProgress, Typography } from '@material-ui/core';
import { MdSend } from 'react-icons/md';
import { useQuery } from 'react-query';

import UpgradeDialog from 'components/Upgrade';
import SendersGrid from './components/SendersGrid';
import { useTypedSelector } from 'redux/reducers';
import { fetchSenders } from './functions';
import { QueryKeys } from '../types';
import { useInstance } from 'hooks/useInstance';
import UpsertSenderDialog from './components/UpsertSenderDialog';

const Senders = () => {
  const { isAFS, isSynergy } = useInstance();
  const user = useTypedSelector((s) => s.user.user);
  const isAdmin = user.SystemAccess >= 10;
  const isSuperAdmin = user.SystemAccess === 15;

  const { data: senders = [], isLoading } = useQuery(
    [QueryKeys.FetchSenders],
    () =>
      fetchSenders({
        isAFS,
        isSynergy,
        isAdmin,
        isSuperAdmin,
        userInstanceId: user.Id
      }),
    {
      enabled: true
    }
  );

  // Each user can create one sender each
  const userHasCreatedSender = senders.find(
    (s) => s.userInstanceId === user.Id
  );

  if (isLoading) return <CircularProgress />;
  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          margin: '10px 0',
          opacity: 0.8
        }}>
        <MdSend
          style={{
            marginRight: 10,
            fontSize: 40,
            color: 'grey'
          }}
        />
        <Typography
          variant="h3"
          style={{
            color: 'grey'
          }}>
          Email Senders
        </Typography>
      </div>

      <Alert severity="info" style={{ marginBottom: 15 }}>
        <Typography>
          To validate a Sender you must authenticate your email business domain.
          (the {<b>From Email</b>} field of your sender). This can be done in
          the {<b>Domain Authentication</b>} tab.
          {<br />}
          Multiple senders will be available in the future as an{' '}
          {<UpgradeDialog />}
        </Typography>
      </Alert>

      {(!userHasCreatedSender || isSuperAdmin) && <UpsertSenderDialog />}

      {!!senders?.length && <SendersGrid senders={senders} />}
    </div>
  );
};

export default Senders;
