import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Tooltip
} from '@material-ui/core';
import ExtensionIcon from '@material-ui/icons/Extension';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import AssignmentIcon from '@material-ui/icons/Assignment';
import styles from './styles';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import DividerCommon from 'common/DividerCommon';
import { GiFlyingFlag } from 'react-icons/gi';
import { AiFillDashboard } from 'react-icons/ai';
import { FaHandshake } from 'react-icons/fa';
import { ImLocation } from 'react-icons/im';
import { globalHost } from 'helpers';
import { PluginList } from './PluginList';
import { useTypedSelector } from 'redux/reducers';
import { UserDefinition } from 'types/interfaces';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.primary.light,
    color: 'white',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
    borderRadius: 3
  }
}))(Tooltip);

const Sidebar = (props) => {
  const { classes } = props;
  const hostname = useTypedSelector((s) => s.config.hostname);
  const settings = useTypedSelector((s) => s.config.settings);
  const user = useTypedSelector((s) => s.fb.auth);
  const { isMobile } = useTypedSelector((s) => s.config.navStatus);
  const location = globalHost(window.location);

  const NavlinkForward = React.forwardRef(
    (props: any, ref: React.Ref<unknown>) => (
      <NavLink ref={ref} {...props} activeClassName={classes.activeListItem} />
    )
  );

  return (
    <nav className={classes.root}>
      <div className={classes.logoWrapper}>
        {settings && settings.Logo ? (
          isMobile ? (
            <img
              src={location.logo}
              style={{ filter: 'grayscale(100%)' }}
              width={50}
            />
          ) : (
            <Tooltip title={settings.Title.toUpperCase()}>
              <img
                height={37}
                src={location.logo}
                style={{ filter: 'grayscale(100%)' }}
              />
            </Tooltip>
          )
        ) : (
          <div>
            {settings && settings.Title && (
              <Typography color="textPrimary">{`${settings.Title.toUpperCase()}`}</Typography>
            )}
          </div>
        )}
      </div>

      <DividerCommon
        className={classes.profileDivider}
        text=""
        vertical={false}
      />
      <DynamicMenu
        classes={classes}
        isMobile={isMobile}
        NavlinkForward={NavlinkForward}
      />

      <div style={{ flexGrow: 1 }} />

      <div style={{ padding: 8 }}>
        <DividerCommon
          className={classes.profileDivider}
          text=""
          vertical={false}
        />

        <Typography
          align="center"
          color="textSecondary"
          style={{ fontSize: isMobile ? 8 : 10 }}
          variant="body2">
          {settings && settings.SiteFooter}
        </Typography>
      </div>
    </nav>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Sidebar);

const DynamicMenu = ({ classes, NavlinkForward, isMobile }) => {
  const { ProcessesName } = useTypedSelector((s) => s.config.instanceSettings);
  const { user } = useTypedSelector((s) => s.user);
  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const { features } = useTypedSelector((s) => s.fb.proxy_instance_settings);
  const disabled = process.env.REACT_APP_ENV === 'production';

  return (
    <List component="div" disablePadding style={{ flexGrow: 1 }}>
      {/* DASHBOARD*/}
      {features.dashboard && (
        <ListItem
          key={'dashboard'}
          className={classes.listItem}
          component={NavlinkForward}
          disabled={disabled}
          to={!disabled && '/dashboard'}>
          <HtmlTooltip placement="right" title="Dashboard">
            <ListItemIcon className={classes.listItemIcon}>
              <AiFillDashboard fontSize={24} />
            </ListItemIcon>
          </HtmlTooltip>

          {!isMobile && (
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Dashboard"
            />
          )}
        </ListItem>
      )}

      {/* DEALS */}
      {!disabled && features.deal && (
        <ListItem
          key={'deals'}
          className={classes.listItem}
          component={NavlinkForward}
          disabled={disabled}
          to={!disabled && '/deals'}>
          <HtmlTooltip placement="right" title="Deals">
            <ListItemIcon className={classes.listItemIcon}>
              <FaHandshake fontSize={24} />
            </ListItemIcon>
          </HtmlTooltip>
          {!isMobile && (
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={`${ProcessesName}s`}
            />
          )}
        </ListItem>
      )}

      {/* DATA */}
      {!isMobile && !disabled && features.data && (
        <DividerCommon
          className={classes.profileDivider}
          text="data"
          vertical={false}
        />
      )}

      {features.data && (
        <>
          {/* USE SQL */}
          {process.env.REACT_APP_ENV !== 'production' &&
            UserDefinitionList &&
            UserDefinitionList.filter((filter) => {
              if (user.SystemAccess > 4) {
                return true;
              } else if (filter.Title === 'Customers') {
                return true;
              }
              return false;
            }).map((item: UserDefinition) => {
              const { Title, Id, ShowInNav, SystemAccess } = item;
              if (!ShowInNav) return <React.Fragment key={Id} />;
              if (!item.IsPublished) return <React.Fragment key={Id} />;
              return (
                <ListItem
                  className={classes.listItem}
                  component={NavlinkForward}
                  key={Id}
                  to={`/UserDefinition/${Id}`}>
                  <HtmlTooltip placement="right" title={Title}>
                    <ListItemIcon className={classes.listItemIcon}>
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </HtmlTooltip>
                  {!isMobile && (
                    <ListItemText
                      classes={{ primary: classes.listItemText }}
                      primary={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <p style={{ flexGrow: 1 }}>{Title}</p>
                          {/* <Badge
                        style={{ marginRight: 20 }}
                        badgeContent={SystemAccess}
                        color="secondary">
                        <LockIcon />
                      </Badge> */}
                        </div>
                      }
                    />
                  )}
                </ListItem>
              );
            })}
        </>
      )}

      {/* PLUGINS */}
      {!isMobile && features.data && (
        <div>
          {/* <div style={{ paddingTop: 5, paddingBottom: 5 }} /> */}

          <DividerCommon
            className={classes.profileDivider}
            text="plugins"
            vertical={false}
          />
        </div>
      )}

      {features.redflag && (
        <ListItem
          key={'company-navigator'}
          className={classes.listItem}
          component={NavlinkForward}
          to="/company-navigator">
          <HtmlTooltip placement="right" title="Company Navigator">
            <ListItemIcon className={classes.listItemIcon}>
              {process.env.REACT_APP_ENV === 'production' ? (
                <GiFlyingFlag fontSize={24} />
              ) : (
                <ImLocation fontSize={24} />
              )}
            </ListItemIcon>
          </HtmlTooltip>
          {!isMobile && (
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary={
                process.env.REACT_APP_ENV === 'production'
                  ? 'Redflag'
                  : 'Company Navigator'
              }
            />
          )}
        </ListItem>
      )}

      <div style={{ paddingTop: 5, paddingBottom: 5 }} />

      {Object.values(PluginList).map((item, index) => {
        const canAccessFeature = features[item.env];
        const isSubsystemUserAndBelow = user.SystemAccess <= 4;
        if (!canAccessFeature) return null;

        let itemName = item.name;
        if (isSubsystemUserAndBelow && item.name === 'Bips Noticeboard') {
          itemName = 'Individual IAR Noticeboard';
        }

        //if (canNotAccessFeature || isSubsystemUserAndBelow) return <div />;

        const hasAccess = () => {
          if (isSubsystemUserAndBelow) {
            const accessibleFeatures = [
              'Inbox',
              'VRM Lookup',
              'Address lookup',
              'Marketing Campaigns'
            ];

            return accessibleFeatures.includes(itemName);
          }
          return canAccessFeature;
        };
        if (!hasAccess()) return null;

        return (
          <div key={index}>
            <ListItem
              className={classes.listItem}
              component={NavlinkForward}
              disabled={disabled}
              to={!disabled && `/${item.path}`}>
              <HtmlTooltip placement="right" title={item.name}>
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
              </HtmlTooltip>
              {!isMobile && (
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={itemName}
                />
              )}
            </ListItem>

            {item.env && <div style={{ paddingTop: 5, paddingBottom: 5 }} />}
          </div>
        );
      })}

      {/* TOOLS */}
      {!isMobile && (
        <DividerCommon
          className={classes.profileDivider}
          text="tools"
          vertical={false}
        />
      )}
      <ListItem
        key={'support'}
        className={classes.listItem}
        component={NavlinkForward}
        disabled={disabled}
        to={process.env.REACT_APP_ENV !== 'production' && '/support'}>
        <HtmlTooltip placement="right" title="Support">
          <ListItemIcon className={classes.listItemIcon}>
            <LiveHelpIcon />
          </ListItemIcon>
        </HtmlTooltip>
        {!isMobile && (
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Support"
          />
        )}
      </ListItem>

      {features.audit && (
        <ListItem
          key={'audit'}
          className={classes.listItem}
          component={NavlinkForward}
          disabled={disabled}
          to={process.env.REACT_APP_ENV !== 'production' && '/audit'}>
          <HtmlTooltip placement="right" title="Audit">
            <ListItemIcon className={classes.listItemIcon}>
              <AssignmentIcon />
            </ListItemIcon>
          </HtmlTooltip>
          {!isMobile && (
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Audit"
            />
          )}
        </ListItem>
      )}

      {/* Extensions */}
      {process.env.NODE_ENV === 'development' && (
        <>
          {/* <DividerCommon className={classes.profileDivider} text="Extensions" /> */}
          <ListItem
            key={'extensions'}
            className={classes.listItem}
            component={NavlinkForward}
            disabled={disabled}
            to={`/Extensions`}>
            <HtmlTooltip placement="right" title="Extensions">
              <ListItemIcon className={classes.listItemIcon}>
                <ExtensionIcon />
              </ListItemIcon>
            </HtmlTooltip>
            {!isMobile && (
              <ListItemText
                classes={{ primary: classes.listItemText }}
                primary="Extensions"
              />
            )}
          </ListItem>
        </>
      )}
    </List>
  );
};

const FilteringSystemAccess = (filterElement, user) => {
  if (user.SystemAccess > 4) {
    return true;
  } else if (filterElement.Title === 'Customers') {
    return true;
  }
  return false;
};
