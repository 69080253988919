import React from 'react';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Grid, Popover } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Divider, Typography } from '@material-ui/core';
import { HelpSwitch, Questions } from './components';

// Component styles
const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    background: theme.palette.background.default,
    // background: `linear-gradient(to right, ${theme.palette.background.default}, ${theme.palette.primary.light})`,
    position: 'fixed',
    bottom: 0,
    width: '100%',
    maxHeight: 64,
    paddingRight: theme.spacing(4),
    paddingBottom: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  logoImage: { opacity: process.env.REACT_APP_ENV === 'production' ? 0.5 : 1 }
});

const Footer = ({ classes, className, width }) => {
  const settings = useSelector((s) => s.config.settings);
  const theme = useTheme();
  const rootClassName = classNames(classes.root, className);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const dark = theme.palette.type === 'dark';
  const logo = dark
    ? require('../../../../assets/Icons/QA_ICON[Dark Theme].png')
    : require('../../../../assets/Icons/QA_ICON.png');

  return (
    <React.Fragment>
      <div className={rootClassName} style={{ width, zIndex: 10 }}>
        <Divider />

        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          spacing={2}>
          {process.env.REACT_APP_ENV !== 'production' && (
            <Grid item style={{ flexGrow: 1 }}>
              <Typography>{settings.SiteFooter}</Typography>
            </Grid>
          )}

          <Grid item>
            <Typography>
              {process.env.REACT_APP_ENV !== 'production' &&
                `${process.env.REACT_APP_ENV}: v_${process.env.REACT_APP_VERSION}`}
            </Typography>
          </Grid>
          {process.env.REACT_APP_ENV !== 'dev' && (
            <React.Fragment>
              <Grid item>
                <img
                  alt="Offkey logo"
                  className={classes.logoImage}
                  src={logo}
                  height="24"
                  onClick={
                    process.env.REACT_APP_ENV !== 'production' && handleClick
                  }
                />
              </Grid>
              <Grid item>
                <HelpSwitch />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </div>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}>
        <Questions />
      </Popover>
    </React.Fragment>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
