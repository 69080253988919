import React from 'react';
import { Dashboard as DashboardLayout } from 'layouts';
import GraphqlCrm from 'components/GraphqlCRM';
import BetaBanner from 'components/BetaBanner';

const GraphqlCrmView = () => (
  <DashboardLayout title="Graphql CRM" padding="noPadding">
    <BetaBanner />
    <GraphqlCrm />
  </DashboardLayout>
);

export default GraphqlCrmView;
