import React, { useState, useEffect, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { PercentageField, CurrencyField } from '../Utils/inputFields';
import MainField from '../components/MainField';
import { Tools } from '../Calculator/interfaces';
import { useCalculator } from '../Calculator/hooks/useCalculator';

const Balloon = (tools: Tools) => {
  const { state, config } = tools;
  const { handleChange } = useCalculator(tools);
  const [open, setOpen] = useState(false);
  const [fullWidth] = React.useState(true);
  const shouldOpen = () => setOpen(true);
  const calculate = () => null;
  const onSubmit = () => setOpen(false);
  const balloonVis = config && config.Balloon.show;

  return (
    <Fragment>
      <MainField
        name="Balloon"
        onClick={shouldOpen}
        required={false}
        value={state._Balloon.TotalBalloon}
        disabled={!balloonVis}
        valueToChange={state._Balloon.TotalBalloon}
        handleChange={handleChange}
        type={'Balloon'}
        calculate={calculate}
      />
      <Dialog fullWidth={fullWidth} maxWidth="md" open={open}>
        <DialogTitle>Select Balloon / RV</DialogTitle>
        <DialogContent>
          <Grid
            alignItems="center"
            container
            direction="column"
            justifyContent="center"
            spacing={3}>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={!balloonVis}
                    error={undefined}
                    name="Balloon"
                    onBlur={undefined}
                    onChange={handleChange('Balloon')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._Balloon.Balloon}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <PercentageField
                    InputLabelProps={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled={!balloonVis}
                    name="VAT Rate %"
                    onBlur={undefined}
                    onChange={handleChange('Balloon_VatRate')}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    style={undefined}
                    type={undefined}
                    value={state._Balloon.Balloon_VatRate}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <CurrencyField
                    InputLabelProps={undefined}
                    adornment={undefined}
                    calculate={calculate}
                    classes={undefined}
                    disabled
                    error={undefined}
                    name="VAT"
                    onBlur={undefined}
                    onChange={undefined}
                    onClick={undefined}
                    onKeyUp={undefined}
                    required={undefined}
                    startAdornment={undefined}
                    value={state._Balloon.Balloon_Vat}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Typography>Payment Taken</Typography>
              <RadioGroup
                onChange={handleChange('ContractPeriod')}
                row
                value={state._Balloon.ContractPeriod}>
                <FormControlLabel
                  control={<Radio />}
                  label="Contract Period"
                  value={0}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Contract Period + 1"
                  value={1}
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            data-cy="balloon-ok-btn"
            color="primary"
            onClick={onSubmit}
            variant="contained">
            ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Balloon;
