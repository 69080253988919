import axios from 'axios';

import { firebase } from 'redux/firebase';
import { Contact } from '../../types';

export const fetchListContacts = async (listId: string): Promise<Contact[]> => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const contactsUrl = `${process.env.REACT_APP_PROXY}/marketing/lists/${listId}/contacts`;

  const r = await axios({
    method: 'GET',
    url: contactsUrl,
    headers: { token }
  });

  return r.data;
};
