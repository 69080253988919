import React from 'react';
import InlineTextField from 'components/Fields/InlineTextField';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import Table from '../Table';

import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';
import { globalIds } from 'helpers/globalIdConfig';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

const RepeatingObject = (props) => {
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const {
    FieldDefinitionDict,
    FieldDefinitionList,
    ObjectDefinition,
    rowData
  } = props;
  const UserInstance = rowData.UserInstance; // This allows us to update the data of this user

  const isReadOnly = () => {
    const AddressOD = globalIds.customer.addresses.AddressObjectDefinition;
    if (props.ObjectDefinition.Id === AddressOD) {
      return false;
    } else {
      const redflagValues = ReadOnlyRedflag({
        ObjectDefinition: props.ObjectDefinition
      });

      return redflagValues;
    }
  };

  React.useEffect(() => {
    let data = [];
    let columns = [];

    /**
     * DATA
     */

    const groups =
      rowData &&
      rowData.CompleteObjectInstanceList &&
      rowData.CompleteObjectInstanceList.filter(
        (el) => el.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
      );

    let group = {};
    for (const key in groups) {
      group = groups[key];
      data.push(group);
    }

    /**
     * COLUMNS
     */

    FieldDefinitionList.forEach((FieldDefinition) => {
      const { Title, FieldType, Id } = FieldDefinition;

      columns.push({
        title: Title,
        field: Id.toString(),
        type: FieldType,
        render: (rowData) => {
          const isReadOnly = ReadOnlyRedflag({
            ObjectDefinition: props.ObjectDefinition,
            FieldDefinition
          });

          const FieldInstanceList = getFieldInstances(rowData);
          const FieldInstance = FieldInstanceList.find(
            (FieldInstance) => FieldInstance.FieldDefinitionId === Id
          );

          return (
            <InlineTextField
              readOnly={isReadOnly}
              type={FieldType}
              FieldDefinition={FieldDefinition}
              FieldInstance={FieldInstance}
              ObjectDefinition={ObjectDefinition}
              ObjectInstance={rowData.ObjectInstance}
              UserInstance={UserInstance}
              fields={Object.values(FieldDefinitionDict)}
              value={FieldInstance && FieldInstance.FieldValue}
              ProcessInstance={{ Id: 0 }}
              isDetails
            />
          );
        }
      });
    });

    setColumns(columns);
    setData(data);
  }, [rowData]);

  const actions = [
    {
      icon: () => (
        <div>
          {!isReadOnly() && (
            <AddFieldAndObjectButton
              ObjectDefinition={ObjectDefinition}
              Fields={Object.values(FieldDefinitionDict)}
              UserInstance={UserInstance}
              ProcessInstanceId={0}
            />
          )}
        </div>
      ),
      tooltip: 'Add Record',
      isFreeAction: true
    }
  ];

  return (
    <div style={{ width: '100%' }}>
      <Table
        title={ObjectDefinition.Title}
        data={data.reverse()}
        columns={columns}
        actions={actions}
      />
    </div>
  );
};

export default RepeatingObject;
