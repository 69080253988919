import { Box, Button, ButtonGroup, Grid, Typography } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { EventContext } from 'components/Calendar/context/EventContext';
import { useContext } from 'react';
import { useTypedSelector } from 'redux/reducers';
import { theme } from 'theme';
import { FbFileRef } from 'types/interfaces';

const EventAttachments = ({ eventDialogOpen }) => {
  const user = useTypedSelector((s) => s.user.user);
  const { handleDownload } = useContext(EventContext);

  const eventProps = eventDialogOpen.event.extendedProps;
  if (
    eventProps &&
    eventProps.attachments &&
    eventProps.attachments.length > 0
  ) {
    return (
      <div
        style={{
          border: `2px ${theme.palette.grey[200]} solid`,
          borderRadius: theme.shape.borderRadius
        }}>
        {eventDialogOpen.event.extendedProps.attachments.map(
          (attachment: FbFileRef, index: number) => {
            const filePermission = attachment.users
              ? attachment.users.find((id: string) => id === user.Id.toString())
              : attachment.UserInstanceId === user.Id;

            if (filePermission) {
              return (
                <div
                  key={index}
                  style={{ padding: theme.spacing(1), width: '100%' }}>
                  <Grid
                    container
                    style={{
                      backgroundColor: theme.palette.success.main,
                      borderRadius: theme.shape.borderRadius
                    }}
                    alignItems="center"
                    justifyContent="center">
                    <Grid item style={{ padding: 8 }}>
                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start'
                        }}>
                        <Typography
                          variant="h6"
                          style={{
                            color: theme.palette.primary.contrastText,
                            marginRight: 8
                          }}>
                          Name:
                          {attachment.nickName
                            ? attachment.nickName
                            : attachment.name}
                        </Typography>
                      </Box>

                      <Box
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start'
                        }}>
                        <Typography
                          variant="caption"
                          style={{
                            color: theme.palette.primary.contrastText
                          }}>
                          Size: {`${(attachment.size / 1024).toFixed(2)} KB`}
                        </Typography>
                      </Box>
                    </Grid>

                    <div style={{ flexGrow: 1 }} />

                    <Grid item style={{ padding: 8 }}>
                      <ButtonGroup
                        variant="outlined"
                        style={{ color: 'white' }}>
                        <Button
                          onClick={(e) => handleDownload(e, attachment)}
                          style={{
                            borderColor: 'white',
                            color: 'white'
                          }}>
                          <CloudDownload style={{ color: 'white' }} />
                        </Button>
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                </div>
              );
            }
          }
        )}
      </div>
    );
  }
  return <div />;
};

export default EventAttachments;
