import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@material-ui/core';
import { CalendarContext } from 'components/Calendar/context/CalendarContext';
import { useContext } from 'react';
import Draggable from 'react-draggable';
import { Edit, Delete } from '@material-ui/icons';
import EventGuest from './EventGuest';
import EventAttachments from './EventAttachments';
import EventBody from './EventBody';
import { IEvent } from 'components/Calendar/interface';
import CalendarModule from '../CalendarModules';
import { useTypedSelector } from 'redux/reducers';
import DownloadCalendar from './DownloadCalendar';
import { convertEventToFormValues } from 'components/Calendar/functions';
import EventDealLinked from './EventDealLinked';
import { useProcess } from 'hooks';

const PaperComponent = (props) => (
  <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
    <Paper {...props} />
  </Draggable>
);

const EventModules = () => {
  const user = useTypedSelector((s) => s.user.user);
  const { currentDeal } = useProcess();
  const {
    setEventDialogOpen,
    eventDialogOpen,
    setFormValues,
    formValues,
    setDialogOpen,
    setEdit,
    edit,
    handleCalendarDeletion
  } = useContext(CalendarContext);

  const eventId: string | undefined =
    eventDialogOpen.event && eventDialogOpen.event.id;

  const handleEdit = (event: IEvent) => {
    const values = convertEventToFormValues(event);
    setFormValues({ ...formValues, ...values });

    setEdit(true);
    setDialogOpen(true);
    if (eventId) setEventDialogOpen({ ...eventDialogOpen, [eventId]: false });
  };

  const handleClose = () => {
    if (eventId) {
      setEventDialogOpen({
        ...eventDialogOpen,
        event: undefined,
        [eventId]: false
      });
    }
  };

  if (edit) return <CalendarModule edit />;
  if (!eventDialogOpen.event) return <div />;

  const isDealLinked = (): boolean => {
    const eventProps = eventDialogOpen.event.extendedProps;
    const pdid = eventProps?.dealInformation?.pdid;
    const piid = eventProps?.dealInformation?.piid;
    const psdid = eventProps?.dealInformation?.psdid;
    if (pdid && piid && psdid) return true;
    return false;
  };

  const linked = isDealLinked();
  return (
    <Dialog
      PaperComponent={PaperComponent}
      open={eventId ? eventDialogOpen[eventId] : false}
      onClose={handleClose}
      hideBackdrop
      maxWidth="sm">
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <div
                  style={{
                    backgroundColor: eventDialogOpen.event.backgroundColor,
                    width: '20px',
                    height: '20px',
                    borderRadius: '25%'
                  }}
                />
              </Grid>
              <Grid item style={{ maxWidth: '75%' }}>
                <Typography data-cy="event-title" variant="h4">
                  {eventDialogOpen.event.title}
                </Typography>
                <Typography variant="body2">
                  {formatDateRange(
                    eventDialogOpen.event.start,
                    eventDialogOpen.event.end
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {eventDialogOpen.event.extendedProps.calendarOwner === user.Id && (
            <Grid item>
              <IconButton
                data-cy="edit-event-btn"
                onClick={() => handleEdit(eventDialogOpen.event)}>
                <Edit />
              </IconButton>
              <IconButton onClick={handleCalendarDeletion}>
                <Delete />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid style={{ width: '100%' }} container spacing={1}>
          <Grid item xs={12}>
            <EventBody eventDialogOpen={eventDialogOpen} />
          </Grid>
          <Grid item xs={12}>
            <DownloadCalendar event={eventDialogOpen.event} />
          </Grid>
          {linked &&
            eventDialogOpen.event.extendedProps.calendarOwner === user.Id &&
            currentDeal?.ProcessInstance?.Id !==
              eventDialogOpen.event.extendedProps?.dealInformation?.piid && (
              <Grid item xs={12}>
                <EventDealLinked
                  event={eventDialogOpen.event}
                  handleClose={handleClose}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <EventGuest eventDialogOpen={eventDialogOpen} />
          </Grid>
          <Grid item xs={12}>
            <EventAttachments eventDialogOpen={eventDialogOpen} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EventModules;

const formatDateRange = (start: Date, end: Date) => {
  if (!(start instanceof Date) || !(end instanceof Date)) return;

  const startDay = start.getDate();
  const startMonth = start.toLocaleString('default', { month: 'long' });
  const startTime = start.toLocaleTimeString().slice(0, -3);
  const endDay = end.getDate();
  const endMonth = end.toLocaleString('default', { month: 'long' });
  const endTime = end.toLocaleTimeString().slice(0, -3);
  const year = start.getFullYear();

  if (startMonth === endMonth) {
    return `${startMonth} ${startDay} ${startTime} – ${endDay} ${endTime}, ${year}`;
  } else {
    return `${startMonth} ${startDay} ${startTime} – ${endMonth} ${endDay} ${endTime}, ${year}`;
  }
};

// const handleFiles = async () => {
//   const extendedProps = eventDialogOpen.event.extendedProps;
//   if (extendedProps && extendedProps.attachments.length > 0) {
//     const promises = eventDialogOpen.event.extendedProps.attachments.map(
//       async (element: FbFileRef) => {
//         if (element.fileId !== '') {
//           const getFiles = firebase
//             .firestore()
//             .collection('deal')
//             .doc('0')
//             .collection('files')
//             .doc(element.fileId);

//           const snapshot = await getFiles.get();
//           const dataFromSnapshot = snapshot.data();

//           if (dataFromSnapshot) {
//             if (
//               element.users &&
//               (!dataFromSnapshot.users ||
//                 element.users.length !== dataFromSnapshot.users.length)
//             ) {
//               dataFromSnapshot.users = element.users;
//             }
//           }

//           console.log({ dataFromSnapshot });
//           return dataFromSnapshot;
//         } else {
//           return element;
//         }
//       }
//     );

//     const results = await Promise.all(promises);
//     setAttachedFiles(results);
//   }
// };
