import { IAddressApiFullAddress } from 'types/address';
import {
  FieldDefinitionDict,
  CompleteObjectInstance,
  ProcessInstance,
  FieldInstance,
  FieldDefinition
} from 'types/interfaces';

import { updateObject } from 'redux/actions/objectUpdater';
import { store } from 'redux/store';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';

export const handleAddressObject = ({
  FieldDefinitionDict,
  CompleteObjectInstance,
  ProcessInstance,
  ProcessInstanceId,
  fullAddress
}: {
  FieldDefinitionDict: FieldDefinitionDict;
  CompleteObjectInstance: CompleteObjectInstance;
  ProcessInstance: ProcessInstance;
  ProcessInstanceId: number;
  fullAddress: IAddressApiFullAddress;
}) => {
  let FieldInstanceList: FieldInstance[] = [];

  const newObjectInstance: CompleteObjectInstance = JSON.parse(
    JSON.stringify(CompleteObjectInstance)
  );

  const FieldInstanceLists = getFieldInstances(newObjectInstance);
  Object.values(FieldDefinitionDict).forEach(
    (FieldDefinition: FieldDefinition) => {
      const newFieldDefinition = FieldDefinition.Title.replace(
        /\s+/g,
        '_'
      ).toLowerCase();

      let Title: string | undefined = Object.keys(fullAddress).find(
        (key) => key.toLowerCase() === newFieldDefinition
      );

      const FieldInstance: FieldInstance | undefined = FieldInstanceLists.find(
        (i) => i.FieldDefinitionId === FieldDefinition.Id
      );

      if (FieldInstance) {
        if (Title) {
          const value =
            typeof fullAddress[Title] === 'object'
              ? JSON.stringify(fullAddress[Title])
              : fullAddress[Title];
          FieldInstance.FieldValue = value;
        } else {
          if (FieldDefinition.Title.charAt(0) === '_') {
            const keys = FieldDefinition.Title.split('/');
            FieldInstance.FieldValue = JSON.stringify(
              fullAddress[keys[0]]?.[keys[1]] || ''
            );
          } else {
            FieldInstance.FieldValue = fullAddress[FieldDefinition.Title];
          }
        }
      } else {
        if (!Title && FieldDefinition.Title.charAt(0) === '_') {
          const keys = FieldDefinition.Title.split('/');
          const FieldValue = JSON.stringify(fullAddress[keys[0]]?.[keys[1]]);

          const newFieldInstance: FieldInstance = {
            FieldDefinitionId: FieldDefinition.Id,
            FieldValue,
            Id: 0,
            ObjectDefinitionId: 0,
            ObjectInstanceId: CompleteObjectInstance.ObjectInstance.Id,
            ProcessInstanceId: 0,
            Title: '',
            UserDefinitionId: 0,
            UserInstanceId: 0
          };
          FieldInstanceList.push(newFieldInstance);
        }
      }
    }
  );

  newObjectInstance.FieldInstanceList = FieldInstanceLists;
  if (FieldInstanceList.length > 0) {
    newObjectInstance.FieldInstanceDict = {};
    newObjectInstance.FieldInstanceList = [
      ...newObjectInstance.FieldInstanceList,
      ...FieldInstanceList
    ];
  }

  return updateObject({
    token: store.getState().user.auth.token,
    data: newObjectInstance,
    props: {
      ProcessInstanceId,
      ProcessInstance
    }
  });
};
