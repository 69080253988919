import { CompleteProcessInstance, CurrentStep } from 'types/interfaces';
import {
  UserInstance,
  CompleteObjectInstance,
  FieldInstance
} from 'types/interfaces';
import { globalIds } from 'helpers/globalIdConfig';
import { Asset } from 'types/calculatorInterfaces';
import {
  getQuickLiteUser,
  getUsersForProcess
} from 'redux/actions/GraphQlActions';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import { IUserForProcess } from 'redux/actions/GraphQlActions/interface';

enum ActionType {
  USERS_FOR_PROCESS = 'USERSFORPROCESS',
  ALL_USERS_FOR_PROCESS = 'ALLUSERSFORPROCESS',
  ASSIGNED_USERS_FOR_PROCESS = 'ASSIGNEDUSERSFORPROCESS',
  SELECTED_USER_FOR_PROCESS = 'SELECTEDUSERFORPROCESS'
}

const getUsersForProcessByAction = async (
  UserDefinitionId: number,
  ProcessInstanceId: string,
  ProcessDefinitionId: string,
  action: ActionType,
  fetchQueries: boolean,
  baseUrl: string
): Promise<IUserForProcess[] | undefined> => {
  const response = (await getUsersForProcess({
    ProcessInstanceId: parseInt(ProcessInstanceId),
    ProcessDefinitionId: parseInt(ProcessDefinitionId),
    UserDefinitionId,
    fetchQueries,
    action,
    baseUrl
  })) as IUserForProcess[] | undefined;

  if (response) return response;
};

const getUsers = async (
  UserDefinitionId: number,
  currentDeal: CompleteProcessInstance,
  baseUrl: string
): Promise<{
  selected: IUserForProcess[] | undefined;
  assigned: IUserForProcess[] | undefined;
  all: IUserForProcess[] | undefined;
}> => {
  const ProcessDefinitionId =
    currentDeal?.ProcessInstance?.ProcessDefinitionId.toString();

  const ProcessInstanceId = currentDeal?.ProcessInstance?.Id.toString();
  const getSelected = await getUsersForProcessByAction(
    UserDefinitionId,
    ProcessInstanceId,
    ProcessDefinitionId,
    ActionType.SELECTED_USER_FOR_PROCESS,
    true,
    baseUrl
  );
  const getAssigned = await getUsersForProcessByAction(
    UserDefinitionId,
    ProcessInstanceId,
    ProcessDefinitionId,
    ActionType.ASSIGNED_USERS_FOR_PROCESS,
    false,
    baseUrl
  );
  const getAll = await getUsersForProcessByAction(
    UserDefinitionId,
    ProcessInstanceId,
    ProcessDefinitionId,
    ActionType.USERS_FOR_PROCESS,
    false,
    baseUrl
  );

  return {
    selected: getSelected ? getSelected : undefined,
    assigned: getAssigned ? getAssigned : undefined,
    all: getAll ? getAll : undefined
  };
};

export const formDeal = async ({
  currentDeal,
  currentStep,
  assets,
  baseUrl
}: {
  currentDeal: CompleteProcessInstance;
  currentStep: CurrentStep;
  assets: Asset[];
  baseUrl: string;
}) => {
  try {
    // USERS
    const usersList = [] as UserInstance[];
    const getUserInstanceIdByFieldDefinitionId: (
      FieldDefinitionId: number
    ) => string = (FieldDefinitionId) => {
      let result = '';
      Object.values(currentDeal.CompleteObjectInstanceDict).map(
        (CompleteObjectInstance: CompleteObjectInstance) => {
          const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
          return FieldInstanceList.map((FieldInstance: FieldInstance) => {
            if (FieldInstance.FieldDefinitionId === FieldDefinitionId) {
              result = FieldInstance.FieldValue;
            }
          });
        }
      );
      return result;
    };

    const customerUDID = globalIds.userDefinitionIds.customerUDID;
    const lenderUDID = globalIds.userDefinitionIds.lenderUDID;
    const contactUDID = globalIds.userDefinitionIds.contactUDID;
    const suppliersUDID = globalIds.userDefinitionIds.suppliersUDID;
    const brokersUDID = globalIds.userDefinitionIds.brokersUDID;
    const accountsUDID = globalIds.userDefinitionIds.accountsUDID;
    const introducersUDID = globalIds.userDefinitionIds.introducersUDID;

    // ID's
    const PrimaryContactId = getUserInstanceIdByFieldDefinitionId(
      globalIds.customer.partiesAndContacts.PrimaryContact
    );

    // Customer
    const customers = await getUsers(customerUDID, currentDeal, baseUrl);
    const lenders = await getUsers(lenderUDID, currentDeal, baseUrl);
    const contacts = await getUsers(contactUDID, currentDeal, baseUrl);
    const suppliers = await getUsers(suppliersUDID, currentDeal, baseUrl);
    const brokers = await getUsers(brokersUDID, currentDeal, baseUrl);
    const accounts = await getUsers(accountsUDID, currentDeal, baseUrl);
    const introducers = await getUsers(introducersUDID, currentDeal, baseUrl);

    let PrimaryContact: UserInstance | undefined;
    if (PrimaryContactId) {
      const newUserInstance: number = parseInt(PrimaryContactId);
      const response = await getQuickLiteUser({
        baseUrl,
        UserInstanceId: newUserInstance,
        action: 'GET'
      });

      const UserInstance = response?.UserInstance;
      if (UserInstance) {
        PrimaryContact = UserInstance;
      }
    }

    // Lender
    // const getSelectedLenderDict = await getSelectedUsers(lenderUDID);
    // const getAssignedLenderDict = await getAssignedUsers(lenderUDID);
    // const SelectedLenderId = Object.keys(getSelectedLenderDict.data)[0];

    // const LenderProposalContactId =
    //   getUserInstanceIdByFieldDefinitionId(23116);
    // const LenderPayoutContactId =
    //   getUserInstanceIdByFieldDefinitionId(23109);

    // User Instances
    // const getSelectedCustomer = await QuickGetLiteUser({
    //   UserInstanceId: CustomerId
    // });

    // const PrimaryContact = await QuickGetLiteUser({
    //   UserInstanceId: PrimaryContactId
    // });
    // const getSelectedLender = await QuickGetLiteUser({
    //   UserInstanceId: SelectedLenderId
    // });
    // const LenderProposalContact = findUserById(LenderProposalContactId);
    // const LenderPayoutContact = findUserById(LenderPayoutContactId);

    // const Introducer = getUserInstanceIdByFieldDefinitionId(23277);
    // const Supplier = getUserInstanceIdByFieldDefinitionId(23278);

    // Assets

    // Quotes
    const quotes: CompleteObjectInstance[] = Object.values(
      currentDeal.CompleteObjectInstanceDict
    ).filter((coi: CompleteObjectInstance) => {
      return coi.ObjectInstance.ObjectDefinitionId === 2840;
    });

    // Indicative
    const indicativeQuotes: CompleteObjectInstance[] = Object.values(
      currentDeal.CompleteObjectInstanceDict
    ).filter((coi: CompleteObjectInstance) => {
      return coi.ObjectInstance.ObjectDefinitionId === 3565;
    });

    // Invoices
    const commissionInvoice: CompleteObjectInstance[] = Object.values(
      currentDeal.CompleteObjectInstanceDict
    ).filter((coi: CompleteObjectInstance) => {
      return [3402, 3486].includes(coi.ObjectInstance.ObjectDefinitionId);
    });

    const deal = {
      accounts,
      assets,
      brokers,
      commissionInvoice,
      contacts,
      customers,
      indicativeQuotes,
      introducers,
      lenders,
      primaryContact: PrimaryContact ? PrimaryContact : undefined,
      quotes,
      suppliers
    };

    return deal;
  } catch (err) {
    console.log({ err });
  }
};

export const dealSummaryLender = async ({
  currentDeal,
  baseUrl
}: {
  currentDeal: CompleteProcessInstance;
  baseUrl: string;
}) => {
  const lenderUDID = globalIds.userDefinitionIds.lenderUDID;
  const lenders = await getUsers(lenderUDID, currentDeal, baseUrl);

  if (lenders) {
    const newLender: {
      selected?: IUserForProcess[];
      assigned?: IUserForProcess[];
      all?: IUserForProcess[];
    } = {
      selected: lenders.selected,
      assigned: lenders.assigned,
      all: lenders.all
    };

    return newLender;
  }
};

export const dealSummaryCustomer = async ({
  currentDeal,
  baseUrl
}: {
  currentDeal: CompleteProcessInstance;
  baseUrl: string;
}) => {
  const customerUDID = globalIds.userDefinitionIds.customerUDID;
  const customers = await getUsers(customerUDID, currentDeal, baseUrl);

  return customers;
};
