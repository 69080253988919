import { Asset } from 'types/calculatorInterfaces';

export const grossUpdate = (
  grossValue,
  Amount,
  NonVATableItemsCost,
  VAT,
  VATPos,
  VATPer,
  LoanAmount,
  deposit,
  name
) => {
  if (grossValue === 'gross') {
    const grossPrice = Amount - NonVATableItemsCost;
    VAT = (grossPrice / VATPos) * VATPer;
    if (name !== 'Deposit') deposit = VAT;
    const netPrice = grossPrice - VAT;
    LoanAmount = netPrice + NonVATableItemsCost + VAT - deposit;
  }
  return { VAT, LoanAmount, deposit };
};

export const netUpdate = (
  grossValue,
  VAT,
  Amount,
  NonVATableItemsCost,
  VATPer,
  deposit,
  LoanAmount,
  name
) => {
  if (grossValue === 'net') {
    VAT = (Amount - NonVATableItemsCost) * VATPer;
    // if (isNaN(VAT)) console.log("net VAT: ", VAT);
    if (name !== 'Deposit') deposit = VAT;
    LoanAmount = Amount + VAT - deposit;
  }
  return { VAT, deposit, LoanAmount };
};

export const commissionUpdate = (
  CommissionPercentage,
  CommissionAmount,
  Amount,
  LoanAmount,
  deposit
) => {
  CommissionAmount = LoanAmount * (CommissionPercentage / 100);
  // CommissionPercentage = (CommissionAmount * 100) / LoanAmount;
  return { CommissionAmount, CommissionPercentage };
};

export const introducerLogic = () => {};

export const calculateQuantityValues = (
  selectedQuanity: number,
  asset: Asset
) => {
  if (selectedQuanity < 1) selectedQuanity = 1;

  const totalPrice = parseFloat(asset.Price) * selectedQuanity;
  const totalNon_VATable_item = asset.Non_VATable_item * selectedQuanity;
  const totalDeposit = asset.Deposit * selectedQuanity;

  // NOTE: asset.Vat value is already multiplied by quanitity. We need to get the single unit:
  const originalVat = asset.Vat / asset.Quantity;

  const totalVat =
    selectedQuanity === asset.Quantity
      ? asset.Vat
      : originalVat * selectedQuanity;

  return {
    totalPrice,
    totalNon_VATable_item,
    totalDeposit,
    totalVat
  };
};
