import { UPDATE_DEAL, CLEAR_DEAL_SUMMARY } from 'redux/actions/types';
import { IDealSummary } from 'hooks/useDealSummary/interface';

const initialState = {
  deal: {} as IDealSummary
};

interface UpdateDealSummary {
  type: typeof UPDATE_DEAL;
  payload: IDealSummary;
}

interface ClearDealSummary {
  type: typeof CLEAR_DEAL_SUMMARY;
  payload: IDealSummary;
}

type formAction = UpdateDealSummary | ClearDealSummary;

export default (state = initialState, action: formAction) => {
  switch (action.type) {
    case UPDATE_DEAL:
      return { ...state, deal: action.payload };
    case CLEAR_DEAL_SUMMARY:
      return { ...state, deal: {} as IDealSummary };
    default:
      return state;
  }
};
