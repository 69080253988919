import { IEvent, TEventType } from '../interface';
import { firebase } from 'redux/firebase';
import { theme } from 'theme';

const mapEventData = (
  doc:
    | firebase.firestore.DocumentSnapshot
    | firebase.firestore.QueryDocumentSnapshot
) => {
  const data = doc.data();

  if (!data) {
    throw new Error(`Document ${doc.id} does not exist`);
  }

  const eventData = data as IEvent;
  const color = getEventColor(eventData.extendedProps.eventType);

  return {
    id: doc.id,
    ...eventData,
    backgroundColor: color,
    borderColor: color,
    extendedProps: {
      ...eventData.extendedProps,
      rrule: data.rrule || ''
    }
  };
};

export default mapEventData;

export const getEventColor = (eventType: TEventType) => {
  switch (eventType) {
    case 'Call':
      return `${theme.palette.primary.light}66`;
    case 'Meeting':
      return '#0d730d';
    case 'Notes':
      return '#e69e19';
    case 'TODO':
      return '#245985';
    case 'Birthdays':
      return '#05696B';
    case 'Deal_Expiry_Date':
      return '#800080';
    case 'End_of_Lease_Date':
      return '#FF0000';
    case 'Passing_Time_Date':
      return '#ff007f';
    case 'Personal_Note':
      return '#FFFF00';
    case 'Buying_Appointment':
      return '#557397';
    case 'Annual_Leave':
      return '#0096FF';
    default:
      return 'grey';
  }
};
