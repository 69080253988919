import { useProcess } from 'hooks/useProcess';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import ViewSelector from './views';

const FullActiveStep = () => {
  const { stepdefdict } = useProcess();
  if (!stepdefdict)
    return (
      <Grid alignItems="center" container direction="column">
        <Alert severity="info" variant="outlined">
          <Typography>
            <b>No Active Step, Inactive process.</b>
          </Typography>
        </Alert>
      </Grid>
    );
  return <ViewSelector />;
};

export default FullActiveStep;
