import { FunderDoc, ConvertedFunderDoc } from '../interfaces';
import { Calculation } from 'types/calculatorInterfaces';

const format = (arg: string) => {
  return Number(arg).toLocaleString('en', {
    minimumFractionDigits: 2
  });
};

const formatRepaymentValues = (arg: string | number) => {
  const strArg = typeof arg === 'number' ? arg.toString() : arg;
  const parts = strArg.split(/(.*x\s)/);

  if (parts.length === 3) {
    const amountStr = parts[2].replace('£', '');

    const formattedNumber = parseFloat(amountStr).toLocaleString('en', {
      minimumFractionDigits: 2
    });

    return `${parts[1]}£${formattedNumber}`;
  } else {
    return format(strArg);
  }
};

export const funderDocOutputConverter: (
  FunderDoc: FunderDoc,
  Calculation: Calculation
) => ConvertedFunderDoc = (FunderDoc, Calculation) => {
  const ConvertedFunderDoc = {
    // FACILITY
    COST_EXCL_VAT_AND_NON_VATABLE_ITEMS: `£ ${format(
      FunderDoc.COST_EXCL_VAT_AND_NON_VATABLE_ITEMS.toFixed(2)
    )}`,
    VAT: `£ ${format(FunderDoc.VAT.toFixed(2))}`,
    NON_VATABLE_ITEMS: `£ ${format(FunderDoc.NON_VATABLE_ITEMS.toFixed(2))}`,
    I_COST_INC_VAT: `£ ${format(FunderDoc.I_COST_INC_VAT.toFixed(2))}`,
    II_ADVANCE_REPAYMENTS: `£ ${format(
      FunderDoc.II_ADVANCE_REPAYMENTS.toFixed(2)
    )}`,
    III_ADDITIONAL_REPAYMENT: `£ ${format(
      FunderDoc.III_ADDITIONAL_REPAYMENT.toFixed(2)
    )}`,
    IV_NET_PART_EXCHANGE_EXCL_VAT: `£ ${format(
      FunderDoc.IV_NET_PART_EXCHANGE_EXCL_VAT.toFixed(2)
    )}`,
    V_VAT_ON_NET_PART_EXCHANGE: `£ ${format(
      FunderDoc.V_VAT_ON_NET_PART_EXCHANGE.toFixed(2)
    )}`,
    VI_NET_VAT_PAID_UPFRONT: `£ ${format(
      FunderDoc.VI_NET_VAT_PAID_UPFRONT.toFixed(2)
    )}`,
    TOTAL_INIT_PAYMENT: `£ ${format(FunderDoc.TOTAL_INIT_PAYMENT.toFixed(2))}`,
    AMOUNT_TO_FINANCE: `£ ${format(FunderDoc.AMOUNT_TO_FINANCE.toFixed(2))}`,
    REPAYMENTS: formatRepaymentValues(FunderDoc.REPAYMENTS),

    // FINANCE CHARGES
    INTEREST_CHARGES_AMOUNT: `£ ${format(
      FunderDoc.INTEREST_CHARGES_AMOUNT.toFixed(2)
    )}`,
    INTEREST_CHARGES_VAT: `£ ${format(
      FunderDoc.INTEREST_CHARGES_VAT.toFixed(2)
    )}`,
    INTEREST_CHARGES_TOTAL: `£ ${format(
      FunderDoc.INTEREST_CHARGES_TOTAL.toFixed(2)
    )}`,
    DOCUMENTATION_FEE_AMOUNT: `£ ${format(
      FunderDoc.DOCUMENTATION_FEE_AMOUNT.toFixed(2)
    )}`,
    DOCUMENTATION_FEE_VAT: `£ ${format(
      FunderDoc.DOCUMENTATION_FEE_VAT.toFixed(2)
    )}`,
    DOCUMENTATION_FEE_TOTAL: `£ ${format(
      FunderDoc.DOCUMENTATION_FEE_TOTAL.toFixed(2)
    )}`,
    OPTION_TO_PURCHASE_AMOUNT: `£ ${format(
      FunderDoc.OPTION_TO_PURCHASE_AMOUNT.toFixed(2)
    )}`,
    OPTION_TO_PURCHASE_VAT: `£ ${format(
      FunderDoc.OPTION_TO_PURCHASE_VAT.toFixed(2)
    )}`,
    OPTION_TO_PURCHASE_TOTAL: `£ ${format(
      FunderDoc.OPTION_TO_PURCHASE_TOTAL.toFixed(2)
    )}`,
    TOTAL_CHARGE_FOR_FINANCE_AMOUNT: `£ ${format(
      FunderDoc.TOTAL_CHARGE_FOR_FINANCE_AMOUNT.toFixed(2)
    )}`,
    TOTAL_CHARGE_FOR_FINANCE_VAT: `£ ${format(
      FunderDoc.TOTAL_CHARGE_FOR_FINANCE_VAT.toFixed(2)
    )}`,
    TOTAL_CHARGE_FOR_FINANCE_TOTAL: `£ ${format(
      FunderDoc.TOTAL_CHARGE_FOR_FINANCE_TOTAL.toFixed(2)
    )}`,
    TOTAL_AMOUNT_PAYABLE_INC_VAT: `£ ${format(
      FunderDoc.TOTAL_AMOUNT_PAYABLE_INC_VAT.toFixed(2)
    )}`,

    // TERM STRUCTURE
    HIRE_PERIOD: `${Calculation.PaymentFrequency} x ${FunderDoc.HIRE_PERIOD} `,
    FUNDING_PROFILE: `${Calculation.InitialRentals} + ${FunderDoc.FUNDING_PROFILE}`,
    DOCUMENTATION_FEE_PAYABLE: `${FunderDoc.DOCUMENTATION_FEE_PAYABLE}`,

    // RATES AND RETURNS
    MARGIN: `${FunderDoc.MARGIN}`,
    YIELD: `${FunderDoc.YIELD}`,
    APR: `${FunderDoc.APR}`,
    FLAT_RATE: `${FunderDoc.FLAT_RATE}`,
    FUNDING_RATE: `${FunderDoc.FUNDING_RATE}`,
    NOMINAL_FLAT_RATE: `${FunderDoc.NOMINAL_FLAT_RATE}`,
    RATE_PER_ANNUM: `${FunderDoc.RATE_PER_ANNUM}`,

    // PAYMENTS ON SIGNING
    VAT_PAID_UPFRONT_AMOUNT: `${FunderDoc.VAT_PAID_UPFRONT_AMOUNT}`,
    VAT_PAID_UPFRONT_VAT: `${FunderDoc.VAT_PAID_UPFRONT_VAT}`,
    VAT_PAID_UPFRONT_TOTAL: `${FunderDoc.VAT_PAID_UPFRONT_TOTAL}`,
    ADVANCE_PAYMENTS_AMOUNT: `${FunderDoc.ADVANCE_PAYMENTS_AMOUNT}`,
    ADVANCE_PAYMENTS_VAT: `${FunderDoc.ADVANCE_PAYMENTS_VAT}`,
    ADVANCE_PAYMENTS_TOTAL: `${FunderDoc.ADVANCE_PAYMENTS_TOTAL}`,
    ADDITIONAL_DEPOSIT_AMOUNT: `${FunderDoc.ADDITIONAL_DEPOSIT_AMOUNT}`,
    ADDITIONAL_DEPOSIT_VAT: `${FunderDoc.ADDITIONAL_DEPOSIT_VAT}`,
    ADDITIONAL_DEPOSIT_TOTAL: `${FunderDoc.ADDITIONAL_DEPOSIT_TOTAL}`,
    PAYMENT_REQUIRED_ON_SIGNING_TOTAL_AMOUNT: `${FunderDoc.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_AMOUNT}`,
    PAYMENT_REQUIRED_ON_SIGNING_TOTAL_VAT: `${FunderDoc.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_VAT}`,
    PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL: `${FunderDoc.PAYMENT_REQUIRED_ON_SIGNING_TOTAL_TOTAL}`,

    // COMMISSION INVOICE
    I_DOCUMENT_FEE_STANDALONE_AMOUNT: `${FunderDoc.I_DOCUMENT_FEE_STANDALONE_AMOUNT}`,
    I_DOCUMENT_FEE_STANDALONE_VAT: `${FunderDoc.I_DOCUMENT_FEE_STANDALONE_VAT}`,
    I_DOCUMENT_FEE_STANDALONE_TOTAL: `${FunderDoc.I_DOCUMENT_FEE_STANDALONE_TOTAL}`,
    II_DOCUMENT_FEE_UPSELL_AMOUNT: `${FunderDoc.II_DOCUMENT_FEE_UPSELL_AMOUNT}`,
    II_DOCUMENT_FEE_UPSELL_VAT: `${FunderDoc.II_DOCUMENT_FEE_UPSELL_VAT}`,
    II_DOCUMENT_FEE_UPSELL_TOTAL: `${FunderDoc.II_DOCUMENT_FEE_UPSELL_TOTAL}`,
    II_TOTAL_DOCUMENT_FEE_AMOUNT: `${FunderDoc.II_TOTAL_DOCUMENT_FEE_AMOUNT}`,
    II_TOTAL_DOCUMENT_FEE_VAT: `${FunderDoc.II_TOTAL_DOCUMENT_FEE_VAT}`,
    II_TOTAL_DOCUMENT_FEE_TOTAL: `${FunderDoc.II_TOTAL_DOCUMENT_FEE_TOTAL}`,
    DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_AMOUNT: `${FunderDoc.DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_AMOUNT}`,
    DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_VAT: `${FunderDoc.DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_VAT}`,
    DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_TOTAL: `${FunderDoc.DOCUMENTATION_FEE_PAYABLE_IN_PERIOD_TOTAL}`,
    GROSS_COMMISSION_AMOUNT: `${FunderDoc.GROSS_COMMISSION_AMOUNT}`,
    GROSS_COMMISSION_VAT: `${FunderDoc.GROSS_COMMISSION_VAT}`,
    GROSS_COMMISSION_TOTAL: `${FunderDoc.GROSS_COMMISSION_TOTAL}`,
    GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT: `${FunderDoc.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_AMOUNT}`,
    GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_VAT: `${FunderDoc.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_VAT}`,
    GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_TOTAL: `${FunderDoc.GROSS_COMMISSION_PLUS_TOTAL_DOCUMENTATION_FEE_TOTAL}`,
    INTRODUCER_FEE_AMOUNT: `${FunderDoc.INTRODUCER_FEE_AMOUNT}`,
    INTRODUCER_FEE_VAT: `${FunderDoc.INTRODUCER_FEE_VAT}`,
    INTRODUCER_FEE_TOTAL: `${FunderDoc.INTRODUCER_FEE_TOTAL}`,
    NET_COMMISSION_AMOUNT: `${FunderDoc.NET_COMMISSION_AMOUNT}`,
    NET_COMMISSION_VAT: `${FunderDoc.NET_COMMISSION_VAT}`,
    NET_COMMISSION_TOTAL: `${FunderDoc.NET_COMMISSION_TOTAL}`,
    BALLOON: `${FunderDoc.BALLOON}`
  } as ConvertedFunderDoc;

  return ConvertedFunderDoc;
};
