import React from 'react';
import {
  createStyles,
  Grid,
  Theme,
  useTheme,
  withStyles,
  WithStyles
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import SearchAndCreateUser from './CreateUser';
import NewUserButton from 'components/User/NewUserButton';

import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

import { handleUpdateField } from '../../functions';
import { useTypedSelector } from 'redux/reducers';
import {
  FieldDefinition,
  FieldInstance,
  ObjectDefinition,
  ObjectInstance,
  ProcessInstance,
  UserInstance,
  UserDefinition
} from 'types/interfaces';
import { CustomDialog } from 'common/Dialog';
import { Alert } from '@material-ui/lab';

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

interface Action {
  title: string;
  action: () => void;
  data: any;
}

interface Props {
  FieldDefinition: FieldDefinition;
  FieldInstance: FieldInstance;
  ObjectDefinition: ObjectDefinition;
  ObjectInstance?: ObjectInstance;
  ProcessInstance?: ProcessInstance | { Id: number };
  ProcessStepSelectionType?: string;
  UserInstance?: UserInstance;
  type: string;
  value: string;
  fields: FieldDefinition[];
}

export default function CustomizedDialogs(props) {
  const theme = useTheme();
  const token = useTypedSelector((s) => s.user.auth.token);
  const [updating, setUpdating] = React.useState(false);
  const [open, setOpen] = React.useState(props.edit);
  const [action, setAction] = React.useState<null | Action>(null);
  const [create, setCreate] = React.useState<string | null>(null);
  const handleClose = () => props.setEdit(false);
  const onChange: any = handleUpdateField({
    setUpdating,
    token,
    props
  });

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string | null
  ) => {
    setCreate(value);
  };

  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);
  const fieldDefinitionsFilterList = props.FieldDefinition.FieldOptions;
  const options = list(UserDefinitionList, fieldDefinitionsFilterList);

  return (
    <CustomDialog
      open={open}
      handleClose={handleClose}
      maxSize="md"
      alert={{
        title: 'Create or Select a User',
        description: 'Easily choose an existing user or set up a new profile.',
        type: 'info'
      }}>
      <DialogContent>
        <Grid
          container
          style={{ padding: theme.spacing(1), justifyContent: 'center' }}
          item
          xs={12}>
          <ToggleButtonGroup
            size="small"
            value={create}
            onChange={handleChange}
            exclusive>
            <ToggleButton color="primary" value="create">
              Create
            </ToggleButton>
            <ToggleButton color="primary" value="select">
              Select
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>

        {create !== 'select' && create !== 'create' && (
          <Typography style={{ margin: '20px', textAlign: 'center' }}>
            To manage directors, you can <strong>Select</strong> a director from
            the contacts, or <strong>Search</strong> for a director using the
            Redflag system. If needed, you have the option to{' '}
            <strong>Create</strong> a new director by providing the necessary
            details. Follow the prompts in each section to complete these
            actions.
          </Typography>
        )}

        {create === 'create' && (
          <NewUserButton
            options={options}
            handlecreatedUser={(id) => {
              handleClose();
              onChange(id);
            }}
          />
        )}

        {create === 'select' && (
          <SearchAndCreateUser
            handleClose={handleClose}
            setAction={setAction}
            options={options}
            {...props}
          />
        )}
      </DialogContent>

      <DialogActions>
        {action?.data && Object.keys(action?.data).length > 0 && (
          <Button onClick={action?.action} color="primary" variant="contained">
            {action?.title}
          </Button>
        )}
      </DialogActions>
    </CustomDialog>
  );
}

const list = (UserDefinitionList: UserDefinition[], inclusionList) => {
  const keywords = ['calendar', 'directorship', 'company'];
  const contactsId = 528;
  let newList = [] as {
    label: string;
    value: number;
    IsPublished: boolean;
    Description?: string;
    Id?: number;
  }[];
  UserDefinitionList.forEach((i: UserDefinition, idx) => {
    const search = ',';
    const desc_key = i.Description.split(search);

    if (desc_key.length > 0) {
      const exists = desc_key.filter((j) => {
        if (j !== '') return keywords.includes(j);
      });

      if (exists.length >= 1 || i.Id === contactsId) {
        const UserDefinitionArray = inclusionList.split(',');
        if (UserDefinitionArray.includes(i.Id.toString())) {
          newList.push({
            label: i.Title,
            value: i.Id,
            Description: i.Description,
            Id: i.Id,
            IsPublished: true
          });
        }
      }
    }
  });

  return newList;
};
