import { Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Message } from '@microsoft/microsoft-graph-types';
import { CardContext } from 'components/MessageHub/context/HoverCardContext';
import { TKnownCategory } from 'components/MessageHub/context/interface';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import useIntersectionObserver from 'components/MessageHub/hooks/useIntersectionObserver';
import { useSenderColors } from 'components/MessageHub/hooks/useSenderColors';
import { IConversation } from 'components/MessageHub/interfaces';
import { useWindowDimensions } from 'helpers/windowDimensions';
import { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { callMsGraph } from 'services/microsoft/graph';
import { theme } from 'theme';
import { SkeletonArchive, SkeletonThread } from '.';
import { ParentThreadContext } from '../context/ParentThreadsContext';
import { ThreadContext } from '../context/ThreadsContext';
import getSortedBookmarks, {
  isBookmarkInCurrentFolder
} from '../functions/getSortedBookmarks';
import { RenderParentThread } from './RenderParentThread';

const CustomFolderMessages = () => {
  const { customFolder, selectedCategory } = useContext(MessageHubContext);
  const { toggleBookmark, hoveredCard, bookmarkedConversations } =
    useContext(CardContext);

  const {
    handleOpen,
    customFolderConversations,
    selectedThread,
    openThreads,
    loadMoreMessages
  } = useContext(ThreadContext);
  const {
    updateFlagStatus,
    handleArchiveMessage,
    flaggedLoading,
    isArchiving,
    openStatus,
    setOpenStatus,
    isRead
  } = useContext(ParentThreadContext);

  const { height } = useWindowDimensions();
  const getSenderColor = useSenderColors();

  const conversationsArray: IConversation[] = Object.values(
    customFolderConversations
  );
  const sortedConversations: IConversation[] = getSortedBookmarks({
    conversationsArray,
    bookmarkedConversations
  });

  const loadMore = async (
    page: number,
    setPage: React.Dispatch<SetStateAction<number>>,
    setLoading: React.Dispatch<SetStateAction<boolean>>,
    setHasMore: React.Dispatch<SetStateAction<boolean>>
  ) => {
    const parentFolderId =
      customFolderConversations[0].parentMessage.parentFolderId || '';

    await loadMoreMessages(
      'customFolder',
      parentFolderId,
      page,
      setPage,
      setLoading,
      setHasMore
    );
  };

  const { loaderRef, loading, hasMore } = useIntersectionObserver(loadMore);
  const defaultDisplay = handleDefaultCategory(
    selectedCategory.category,
    height
  );

  if (defaultDisplay && customFolder) {
    if (customFolder?.value.length <= 0) return defaultDisplay;
    else {
      return (
        <Grid
          container
          spacing={3}
          style={{ maxHeight: '100%', overflowY: 'auto' }}>
          <Grid item xs={12}>
            {isBookmarkInCurrentFolder(
              bookmarkedConversations,
              selectedCategory
            ) && (
              <div
                style={{
                  marginLeft: theme.spacing(2),
                  marginRight: theme.spacing(2)
                }}>
                <Alert severity="info">
                  <Typography style={{ fontWeight: 'bold' }}>
                    Bookmarked Conversations
                  </Typography>
                </Alert>
              </div>
            )}
            {bookmarkedConversations.map((conversation, idx) => {
              if (
                conversation.parentMessage.parentFolderId ===
                selectedCategory.folderId
              ) {
                const messageId = conversation?.parentMessage?.id || '';
                const isLoading = [flaggedLoading, isArchiving, isRead].some(
                  (loadingArray) => loadingArray.includes(messageId)
                );

                if (isLoading) {
                  return (
                    <SkeletonArchive
                      conversation={conversation}
                      idx={idx}
                      key={idx}
                    />
                  );
                }

                return (
                  <RenderParentThread
                    key={`bookmarked-${idx}`}
                    isBookmarked
                    messages={bookmarkedConversations as any}
                    conversation={conversation}
                    conversations={bookmarkedConversations}
                    openThreads={openThreads}
                    selectedThread={selectedThread}
                    idx={idx}
                    openStatus={openStatus}
                    hoveredCard={hoveredCard}
                    setOpenStatus={setOpenStatus}
                    handleOpen={handleOpen}
                    updateFlagStatus={updateFlagStatus}
                    handleArchiveMessage={handleArchiveMessage}
                    toggleBookmark={toggleBookmark}
                    getSenderColor={getSenderColor}
                  />
                );
              }
            })}

            <div
              style={{
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2)
              }}>
              <Alert severity="info">
                <Typography style={{ fontWeight: 'bold' }}>
                  {`Exploring Your '${selectedCategory.category}' Email Collection`}
                </Typography>
              </Alert>
            </div>
            {sortedConversations
              .filter(
                (conversation) =>
                  !bookmarkedConversations.some(
                    (bookmarkedConversation) =>
                      bookmarkedConversation.parentMessage.conversationId ===
                      conversation.parentMessage.conversationId
                  )
              )
              .map((conversation: IConversation, idx: number) => {
                const messageId = conversation?.parentMessage?.id || '';
                const isLoading = [flaggedLoading, isArchiving, isRead].some(
                  (loadingArray) => loadingArray.includes(messageId)
                );

                if (isLoading) {
                  return (
                    <SkeletonArchive
                      conversation={conversation}
                      idx={idx}
                      key={idx}
                    />
                  );
                }

                return (
                  <RenderParentThread
                    key={idx}
                    messages={customFolder}
                    conversation={conversation}
                    conversations={sortedConversations}
                    openThreads={openThreads}
                    selectedThread={selectedThread}
                    idx={idx}
                    openStatus={openStatus}
                    hoveredCard={hoveredCard}
                    isBookmarked={false}
                    setOpenStatus={setOpenStatus}
                    handleOpen={handleOpen}
                    updateFlagStatus={updateFlagStatus}
                    handleArchiveMessage={handleArchiveMessage}
                    toggleBookmark={toggleBookmark}
                    getSenderColor={getSenderColor}
                  />
                );
              })}
            <div
              ref={loaderRef}
              style={{
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2)
              }}>
              {hasMore ? (
                loading && (
                  <Alert severity="info">
                    <Typography style={{ fontWeight: 'bold' }}>
                      {`Fetching More '${selectedCategory.category}' Emails Threads For You... Please Hold On!`}
                    </Typography>
                  </Alert>
                )
              ) : (
                <Alert severity="warning">
                  <Typography style={{ fontWeight: 'bold' }}>
                    {`No More '${selectedCategory.category}' Emails Threads To Load.`}
                  </Typography>
                </Alert>
              )}
            </div>
          </Grid>
        </Grid>
      );
    }
  } else return <div />;
};

export default CustomFolderMessages;

export const KnownCategories = new Set<TKnownCategory>([
  'Inbox',
  'Sent Items',
  'Drafts',
  'Favorited',
  'Line_1',
  'Line_2',
  'Line_3',
  'Logout',
  'Refresh',
  'Create New Email',
  'Flagged',
  'Archived'
]);

const handleDefaultCategory = (
  category: TKnownCategory | string,
  height: number
) => {
  if (!KnownCategories.has(category as TKnownCategory)) {
    const alertHeight = 100;
    return (
      <Grid
        direction="column"
        container
        style={{
          padding: theme.spacing(2)
        }}>
        <Grid item>
          <Alert
            style={{ width: '100%' }}
            severity="warning"
            variant="filled"
            elevation={4}>
            <Typography>
              The <strong>{category}</strong> Folder is Currently Empty. No
              Threads to Display.
            </Typography>
          </Alert>
        </Grid>
        <Grid item>
          <div
            style={{
              maxHeight: `${height - alertHeight}px`,
              overflowY: 'auto',
              overflowX: 'hidden'
            }}>
            <SkeletonThread amountToLoad={6} />
          </div>
        </Grid>
      </Grid>
    );
  }
  return null;
};
