import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import gold from 'assets/Icons/redflag/gold.png';
import silver from 'assets/Icons/redflag/silver.png';
import bronze from 'assets/Icons/redflag/bronze.png';
import amber from 'assets/Icons/redflag/amber.png';
import redflag1 from 'assets/Icons/redflag/redflag1.png';
import redflag2 from 'assets/Icons/redflag/redflag2.png';
import redflag3 from 'assets/Icons/redflag/redflag3.png';
import dissolved from 'assets/Icons/redflag/NoIcon.png';

import a from 'assets/Icons/redflag/A.png';
import b from 'assets/Icons/redflag/B.png';
import c from 'assets/Icons/redflag/C.png';
import d from 'assets/Icons/redflag/D.png';
import e from 'assets/Icons/redflag/E.png';

import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from '@material-ui/core/Tooltip';
import { CreateUserButton } from 'components/User/CreateAsUserButton';
import { useTheme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import DoNotDeal from 'components/DoNotDeal';
import { DoNotDealHistory } from '../../../../DoNotDeal/DoNotDealHistory';
import { useDealList } from 'hooks/useDealList';
import { useTypedSelector } from 'redux/reducers';
import { useGoogleMapsAPI } from 'hooks/useGoogleMapsAPI';

import GoogleMapContainer from 'common/Maps';
import { Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import { theme } from 'theme';

import { IRedflagAPIData, IRedFlagObject } from 'types/redflagInterfaces';
import SankeyChart from 'components/RedFlag/Sankey';
import { LinearProgress } from '@material-ui/core';
import { useCompaniesHouse } from 'hooks/useCompaniesHouse';
import { OffkeyCompany } from 'components/OffkeyCompanyAndDirectorSearch/interfaces';
import { CompleteUserInstance } from 'types/interfaces';

const codes = {
  gold,
  silver,
  bronze,
  one_red_flag: redflag1,
  two_red_flags: redflag2,
  three_red_flags: redflag3,
  not_trading: dissolved,
  insolvent: dissolved,
  strike_off: dissolved,
  newly_incorporated: amber,
  provisional_one_red_flag: redflag1,
  provisional_two_red_flags: redflag2,
  dissolved,
  pre_insolvent: dissolved,
  inactive: dissolved,
  provisional_bronze: bronze,
  provisional_silver: silver
};

const fields = [
  'company_type',
  'incorporation_date',
  'legal_status',
  'estimated_turnover',
  'estimated_employees',
  'auditor',
  'dissolution_status',
  'email',
  'sic07_codes',
  'sic07_descriptions'
];

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2) },
  infobox: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: `2px solid ${theme.palette.primary.light}`,
    borderRadius: theme.shape.borderRadius
  }
}));

interface CompanyDetails {
  data: any;
  rowData?: CompleteUserInstance;
  setCompany: (company: IRedflagAPIData) => void;
  company: any;
  downloadPDF: () => void;
  loading: boolean;
  directorships: any;
  countdownDisplayRef?: any;
  isCompleted?: boolean;
  ProcessDefinitionId: any;
  hideCreateUser: boolean;
  selectCreated: any;
  mapLoading: boolean | undefined;
  setValidCompany?: (attributes) => void | undefined;
}

// // const { badCompany } = useDealList(attributes.company_number);
export const CompanyDetails = (details: CompanyDetails) => {
  const {
    data,
    company,
    downloadPDF,
    countdownDisplayRef,
    isCompleted,
    mapLoading,
    setCompany,
    setValidCompany,
    rowData
  } = details;
  const classes = useStyles();
  const theme = useTheme();
  const { getCompanyProfile, company: companyHouse } = useCompaniesHouse();

  const [showMap, setShowMap] = React.useState(false);

  const handleCompanyHouseSearch = async () => {
    getCompanyProfile(
      rowData?.UserInstance.UserInstanceEmail.toString() || '',
      true
    );
  };

  useEffect(() => {
    if (companyHouse) {
      const { third_party_apis } = companyHouse as OffkeyCompany;
      setCompany(third_party_apis.redflagOG);
    }
  }, [companyHouse]);

  if (!data && !data?.attributes) {
    return (
      <>
        <Alert severity="error">
          <AlertTitle>No Data Found</AlertTitle>
          <Typography variant="h6" color="textSecondary">
            No relevant data found for this company. Please try again later. If
            you believe this is a mistake, please contact support.
          </Typography>

          <Typography variant="h6" color="textSecondary">
            {`We couldn't retrieve any data from our third-party sources. Please
            try searching for the company on Companies House by clicking the
            button below.`}
          </Typography>
          <div style={{ paddingTop: theme.spacing(1) }}>
            <Button
              onClick={handleCompanyHouseSearch}
              variant="contained"
              color="primary">
              Try With Companies House
            </Button>
          </div>
        </Alert>
      </>
    );
  }

  const { attributes } = data;
  if (setValidCompany !== undefined) setValidCompany(attributes);

  return (
    <div>
      <DoNotDeal
        name={attributes.name}
        company_number={attributes.company_number}
        UserDefinitionId={rowData?.UserInstance.UserDefinitionId}
        type="company"
      />
      <Paper className={classes.root} elevation={3}>
        <Grid container>
          <Grid item xs={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Tooltip title="credit score" placement="right-start">
                  <Badge
                    badgeContent={attributes?.rfa_rating?.credit_score}
                    color="error">
                    <Typography
                      data-cy="redflag-co-name"
                      color="textPrimary"
                      variant="h3">
                      {attributes?.name}
                    </Typography>
                  </Badge>
                </Tooltip>

                {/* Hiding this for now as it creates a memory leak if used here */}
                {/* {features.marketing &&
                    process.env.REACT_APP_ENV !== 'production' &&
                    !hideCreateUser &&
                    !badCompany && (
                      <CreateUserButton
                        ProcessDefinitionId={ProcessDefinitionId}
                        company={company}
                        data={data}
                        directorships={directorships}
                        downloadPDF={downloadPDF}
                        loading={loading}
                        selectCreated={selectCreated}
                      />
                    )} */}

                <Typography data-cy="redflag-co-number" color="textPrimary">
                  {attributes?.company_number}
                </Typography>
                <Link
                  data-cy="redflag-co-website"
                  href={`https://${attributes.website}`}
                  rel="noopener"
                  target="_blank">
                  {attributes.website}
                </Link>
              </Grid>

              <Grid item>
                <Grid container spacing={2}>
                  {!isCompleted ? (
                    <>
                      <Grid item>
                        <Button
                          data-cy="pdf-download-button"
                          color="primary"
                          onClick={downloadPDF}
                          variant="contained">
                          PDF
                          <GetAppIcon />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => setShowMap(!showMap)}>
                          {showMap ? 'Hide Google maps' : 'Show Google maps'}
                          <GetAppIcon />
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <div style={{ padding: theme.spacing(1) }}>
                      <Alert severity="info">
                        <Typography
                          ref={countdownDisplayRef}
                          style={{ fontWeight: 'bold' }}>
                          Starting Download... Please Wait
                        </Typography>
                      </Alert>
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Divider flexItem orientation="vertical" />
          </Grid>

          <Grid item xs={6}>
            <Grid alignItems="center" container justifyContent="flex-end">
              <Grid item>
                <div style={{ color: 'blue', textAlign: 'center' }}>
                  <Tooltip
                    title={attributes?.rfa_rating?.long_description || ''}>
                    <div>
                      <Scoring
                        code={attributes?.rfa_rating?.short_code.toLowerCase()}
                      />
                    </div>
                  </Tooltip>

                  <Typography color="textPrimary" variant="caption">
                    {attributes?.rfa_rating?.short_description}
                  </Typography>

                  {/* <Typography color="textPrimary" style={{ fontSize: 10 }}>
                  {attributes.rfa_rating.long_description}
                </Typography> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Divider style={{ marginTop: 15 }} />

        <Grid className={classes.infobox} container>
          {fields.map((field, i) => (
            <Grid item key={i}>
              <Field attributes={attributes} field={field} />
            </Grid>
          ))}
        </Grid>

        <Grid container spacing={2}>
          {company?.contact?.length &&
            company?.contact?.length > 0 &&
            company.contact.map(({ attributes }, i) => {
              const { email, telephone } = attributes;

              if (email && telephone) {
                return (
                  <Grid item key={i}>
                    <Paper className={classes.root}>
                      <Typography
                        data-cy="redflag-co-email"
                        color="textSecondary">
                        Email: {email}
                      </Typography>
                      <Typography
                        data-cy="redflag-co-tel"
                        color="textPrimary"
                        variant="caption">
                        Telephone: {telephone}
                      </Typography>
                    </Paper>
                  </Grid>
                );
              }
            })}
        </Grid>
        <RenderingGoogleMap
          attributes={attributes}
          company={company}
          mapLoading={mapLoading}
          showMap={showMap}
        />
        {/* <div style={{ paddingTop: theme.spacing(1) }}>
          <SankeyChart company={company} />
        </div> */}
      </Paper>
    </div>
  );
};

const mapContainerStyle = {
  width: 'calc(100%)',
  height: '400px'
};

const RenderingGoogleMap = ({ mapLoading, showMap, attributes, company }) => {
  if (mapLoading) {
    return (
      <>
        <div
          style={{
            ...mapContainerStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CircularProgress />
        </div>
      </>
    );
  }
  if (!showMap) return <div />;

  return (
    <>
      {attributes && (
        <>
          <br />
          <GoogleMapContainer
            redflagLocation={
              attributes.addresses
                ? attributes.addresses
                : company && company.addresses
                ? Object.values(company.addresses).map(
                    (element: any) => element.attributes
                  )
                : []
            }
            setWarning={() => false}
          />
        </>
      )}
    </>
  );
};

const Scoring = ({ code }) => (
  <img alt="scoring" height="64" src={codes[code] || dissolved} width="64" />
);

const Field = ({ field, attributes }) => {
  const search = '_';
  const replaceWith = ' ';
  const noUnderscore = field.split(search).join(replaceWith);
  const CasedTitle = noUnderscore.replace(/\b\w/g, (c) => c.toUpperCase());

  if (attributes[field] && attributes[field].length === 0) return <div />;
  if (!attributes[field]) return <div />;
  return (
    <ListItem
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
      }}>
      <Typography color="textSecondary">{CasedTitle}</Typography>
      <Typography
        color="textPrimary"
        variant="caption">{`${attributes[field]}`}</Typography>
      {/* <ListItemText secondary={`${attributes[field]}`} primary={CasedTitle} /> */}
    </ListItem>
  );
};
