import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'redux/reducers';
import {
  Typography,
  Paper,
  List,
  Grid,
  Button,
  DialogContent,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import Draggable from 'react-draggable';
import { ResizableBox } from 'react-resizable';
import { makeStyles } from '@material-ui/core/styles';
import {
  LegacyDocumentListData,
  LegacyDetailData,
  LegacyActivityData
} from './LegacyPanels';
import { GetLegacyDocument } from 'redux/database/index';

const useStyles = makeStyles({
  resizable: {
    position: 'sticky',
    '& .react-resizable-handle': {
      position: 'fixed',
      width: 50,
      height: 50,
      bottom: 0,
      right: 25,
      background:
        "url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+')",
      'background-position': 'bottom right',
      padding: '0 5px 5px 0',
      paddingTop: 25,
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'content-box',
      boxSizing: 'border-box',
      cursor: 'se-resize'
    }
  }
});

export type GrabbingDocumentData = (params: {
  id: string;
  name: string;
}) => void;

const LegacyDialog = () => {
  const dispatch = useDispatch();
  const legacyDealState = useTypedSelector((s) => s.legacyDealReducer);

  const handleClose = () =>
    dispatch({ type: 'SET_LEGACY_DEAL_BOOL', payload: false });

  const GrabbingDocumentData: GrabbingDocumentData = ({ id, name }) => {
    getDocument({ id: id, name: name });
  };

  return (
    <div>
      {legacyDealState.legacyBool ? (
        <Dialog
          open
          PaperComponent={PaperComponent}
          maxWidth={'md'}
          aria-labelledby="draggable-dialog-title"
          fullWidth={false}
          disableEscapeKeyDown={false}
          BackdropProps={{ invisible: true }}
          disableBackdropClick
          disableEnforceFocus
          hideBackdrop
          style={{
            top: '10%',
            left: '27%',
            height: 'fit-content',
            width: 'fit-content',
            zIndex: 10
          }}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title" />

          <DialogContent>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ padding: 10 }}>
              <Paper style={{ maxHeight: 500, width: '45%', overflow: 'auto' }}>
                <Typography
                  variant="h5"
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}>
                  Deal Details: {legacyDealState.legacyDealDetail?.deal_id}
                </Typography>
                <List>
                  <Grid
                    container
                    direction="row-reverse"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item>
                      {LegacyDetailData({
                        current: legacyDealState.legacyDealDetail
                      })}
                    </Grid>
                  </Grid>
                </List>
              </Paper>

              <Paper style={{ height: 500, width: '53%', overflow: 'auto' }}>
                <Typography
                  variant="h5"
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}>
                  Activity
                </Typography>
                <List>
                  <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ paddingLeft: 10 }}>
                    <Grid item sm={8}>
                      {LegacyActivityData({
                        activity: legacyDealState.legacyDealActivity
                      })}
                    </Grid>
                  </Grid>
                </List>
              </Paper>
            </Grid>
            <div style={{ padding: 10 }}>
              <Paper style={{ height: 300, overflow: 'auto' }}>
                <Typography
                  variant="h5"
                  style={{
                    paddingLeft: 10,
                    paddingTop: 10,
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                  }}>
                  Deal Documents
                </Typography>
                <List>
                  {LegacyDocumentListData({
                    GrabbingDocumentData: GrabbingDocumentData,
                    documentList: legacyDealState.legacyDealDocumentList
                  })}
                </List>
              </Paper>
            </div>
          </DialogContent>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            <DialogActions>
              <Button
                color="primary"
                variant="contained"
                autoFocus
                onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </DialogTitle>
        </Dialog>
      ) : (
        <div />
      )}
    </div>
  );
};

const PaperComponent = (props) => {
  const classes = useStyles();
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}>
      <ResizableBox
        height={700}
        width={800}
        minConstraints={[400, 400]}
        maxConstraints={[1000, 1000]}
        className={classes.resizable}>
        <Paper
          square
          {...props}
          style={{
            margin: 20,
            marginLeft: 50,
            marginTop: 10,
            maxHeight: '100%',
            overflow: 'scroll'
          }}
        />
      </ResizableBox>
    </Draggable>
  );
};

const getDocument = async ({ id, name }) => {
  const res = await GetLegacyDocument({
    action: 'document',
    id: encodeURIComponent(id),
    responseType: 'blob'
  });

  if (res) {
    const file = new Blob([res.data], {
      type: res.headers['content-type']
    });
    const fileURL = URL.createObjectURL(file);
    if (res.headers['content-type'] === 'application/pdf') {
      window.open(fileURL);
    } else {
      var a = document.createElement('a');
      document.body.appendChild(a);
      a.href = fileURL;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(fileURL);
    }
  }
};

export default LegacyDialog;
