import Button from '@material-ui/core/Button';
import HelpIcon from '@material-ui/icons/Help';
import Link from '@material-ui/core/Link';
import * as gtag from 'Utils/gtag';

export default function AlertDialog({ url }) {
  const baseUrl = 'https://financial-manager-userguide.netlify.app/';
  const analytics = () => {
    gtag.event({ action: 'Help Search', feature: 'HELP', message: url });
  };
  return (
    <Link
      href={`${baseUrl}/${url}`}
      target="_blank"
      rel="noopener"
      onClick={analytics}>
      <Button color="primary" variant="outlined">
        <HelpIcon style={{ marginRight: 8 }} />
        Help
      </Button>
    </Link>
  );
}
