import Address from 'components/Address';
import { Dashboard as DashboardLayout } from 'layouts';

const Component = () => {
  return (
    <DashboardLayout title="Address Lookup" padding="noPadding">
      <Address />
    </DashboardLayout>
  );
};

export default Component;
