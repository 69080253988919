import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' },
  detail: { padding: theme.spacing(2), margin: theme.spacing(2) }
}));

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

const Component = ({ company }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <Table
        title="Unpaid Debt"
        data={company.unpaid_debt}
        options={{ pageSize: 10 }}
        columns={[
          {
            title: 'Creditor Company Name',
            render: (rowData) => (
              <Typography style={{ fontSize: '1vw', fontWeight: 900 }}>
                {rowData.attributes.creditor_company_name}
              </Typography>
            )
          },
          {
            title: 'Creditor Company Number',
            field: 'attributes.creditor_company_number'
          },
          {
            title: 'Debtor Company Name',
            field: 'attributes.debtor_company_name'
          },
          {
            title: 'Debtor Company Number',
            field: 'attributes.debtor_company_number'
          },
          { title: 'Statement Date', field: 'attributes.statement_date' },
          {
            title: 'Amount',
            render: (rowData) => <Currency value={rowData.attributes.amount} />
          }
        ]}
      />
    </Paper>
  );
};

export default Component;
