import { Grid, Typography } from '@material-ui/core';

import { useCalculationStyles } from '../../styles';
import { SeasonalPayment } from 'types/calculatorInterfaces';
import { formatDate } from 'Utils';

const SeasonalPaymentItem = ({
  seasonalPayment
}: {
  seasonalPayment: SeasonalPayment;
}) => {
  const classes = useCalculationStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center">
      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Type</Typography>
        <Typography>{seasonalPayment.type}</Typography>
      </Grid>

      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>Amount</Typography>
        <Typography>£{seasonalPayment.amount}</Typography>
      </Grid>

      <Grid item className={classes.displayContainer}>
        <Typography className={classes.itemHeading}>
          Start / End Period
        </Typography>
        <Typography>{`
        ${formatDate(seasonalPayment.startPeriod)} 
        — 
        ${formatDate(seasonalPayment.endPeriod)}
        `}</Typography>
      </Grid>
    </Grid>
  );
};

export default SeasonalPaymentItem;
