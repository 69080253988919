import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import Link from '@material-ui/core/Link';
import * as gtag from 'Utils/gtag';
import { BugTracker } from 'Utils/Bugtracker';
import { CircularProgress, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useCreditSafe } from 'components/OffkeyCompanyAndDirectorSearch/adaptors';
import { useTypedSelector } from 'redux/reducers';
import axios from 'axios';
import Time from 'common/Time';
import { IRedflagAPIData } from 'types/redflagInterfaces';

// const hostname = `${window.location.hostname.replace(/[^a-zA-Z ]/g, '')}`;

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' }
}));

const Component = ({ company }: { company: IRedflagAPIData }) => {
  const { companyNavigator } = useTypedSelector((s) => s.config);

  const classes = useStyles();
  const [loading, setLoading] = React.useState(null);
  let userAgentString = navigator.userAgent;

  const detectBrowserType = () => {
    if (
      (userAgentString.indexOf('Opera') || userAgentString.indexOf('OPR')) != -1
    ) {
      return 'Opera';
    } else if (userAgentString.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (userAgentString.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (userAgentString.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else {
      return 'Unknown';
    }
  };

  let columns = [] as object[];

  if (companyNavigator === 'RedflagOG') {
    columns = [
      { title: 'Category', field: 'attributes.category' },
      {
        title: 'Description',
        field: 'attributes.text_description'
      },
      {
        title: 'Date',
        field: 'attributes.date'
      },
      {
        title: 'Link',
        // field: 'relationships.document.links.related',
        render: (props) => (
          <Doc {...props} loading={loading} setLoading={setLoading} />
        )
      }
    ];
  }

  if (companyNavigator === 'RedflagGRaphQL') {
    columns = [
      { title: 'Description', field: 'attributes.description' },
      {
        title: 'Date',
        field: 'attributes.action_date',
        render: (props) => <Time time={props.attributes.date} type="timeago" />
      },
      {
        title: 'Download',
        render: (props) => (
          <Link
            target="_blank"
            href={props.attributes.download_url}
            rel="noreferrer">
            Link
          </Link>
        )
      }
    ];
  }

  return (
    <Paper className={classes.root} elevation={3}>
      {detectBrowserType() == 'Safari' ? (
        <Alert severity="warning">
          <Typography>
            If you want to open <strong>Links</strong> please allow{' '}
            <strong>Popups</strong> or switch to other <strong>Browser</strong>
          </Typography>
        </Alert>
      ) : (
        <Alert severity="info">
          <Typography>
            Please wait a few seconds for <strong>Link</strong> to open.
          </Typography>
        </Alert>
      )}

      <br />

      <Table
        columns={columns}
        data={company.filing_history}
        title="Filing History"
        options={{ pageSize: 9 }}
      />
    </Paper>
  );
};

const Doc = (props) => {
  const { companyNavigator } = useTypedSelector((s) => s.config);
  const { getFilingHistoryDocument } = useCreditSafe();

  const getDoc = async () => {
    if (companyNavigator === 'CreditSafe') {
      const { id, setLoading } = props;
      setLoading(id);
      try {
        const base64 = await getFilingHistoryDocument(id);
        const file = new Blob([base64], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        var openNewWindowWithBlank = window.open('', '_blank');
        if (openNewWindowWithBlank === null) return;
        openNewWindowWithBlank.document.write('Loading preview...');
        openNewWindowWithBlank.location.href = fileURL;
        gtag.event({
          action: `Filing History Downloaded`,
          feature: 'Credit Safe',
          message: `User downloaded a filing history record`
        });
      } catch (err) {
        BugTracker.notify(err);
      } finally {
        setLoading(null);
      }
    }

    if (companyNavigator === 'RedflagOG') {
      // From red flag directly
      const url = `${props?.relationships?.document?.links?.related}`;
      const auth = {
        username: process.env.REACT_APP_RF_USERNAME,
        password: process.env.REACT_APP_RF_PASSWORD
      };

      const config: any = {
        method: 'get',
        responseType: 'blob',
        url,
        auth,
        headers: { Accept: 'application/pdf' },
        timeout: 30000,
        onDownloadProgress: (progressEvent) => {}
      };
      axios(config)
        .then(async (res) => {
          const file = new Blob([res.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          var openNewWindowWithBlank = window.open('', '_blank');
          if (openNewWindowWithBlank === null) return;
          openNewWindowWithBlank.document.write('Loading preview...');
          openNewWindowWithBlank.location.href = fileURL;
          // console.log({ fileURL });
          gtag.event({
            action: `Filing History Downloaded`,
            feature: 'Red Flag',
            message: `User downloaded a filing history record`
          });
        })
        .catch((e) => {
          BugTracker.notify(e);
        });
    }
  };

  return (
    <>
      {props.loading === props.id ? (
        <CircularProgress />
      ) : (
        <Link onClick={() => getDoc()}>Link</Link>
      )}
    </>
  );
};

export default Component;
