/** dashboard badge component for showing user deals in metric numbers (eg total deals / active deals / paid out / declined / deals not taken up */

import styles from './styles';
import { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { GetUserStats } from 'redux/database';
import { BugTracker } from 'Utils/Bugtracker';
import { useTypedSelector } from 'redux/reducers';

interface dealMetricType {
  totalDeals: number;
  activeDeals: number;
  paidOut: number;
  declined: number;
  dealsNotTakenUp: number;
}

// fetch response data, deal metric object
interface userStatsMetricDealType {
  Open?: number;
  Closed_Won?: number;
  Closed_Lost?: number;
  Abandoned?: number;
}

const Progress = ({ classes }) => {
  const token = useTypedSelector((s) => s.user.auth.token);

  // deal metric numbers
  const [deal, setDeal] = useState<dealMetricType>({
    totalDeals: 0,
    activeDeals: 0,
    paidOut: 0,
    declined: 0,
    dealsNotTakenUp: 0
  });

  // add all the deals within the state total deals
  useEffect(() => {
    // fetch request deal summary metric numbers function
    const fetchDealMetric = async () => {
      try {
        const res = await GetUserStats();

        // collect the deals data from the fetch request
        const data: userStatsMetricDealType = res.data;

        const tempDealData: dealMetricType = {
          totalDeals: 0,
          activeDeals: 0,
          paidOut: 0,
          declined: 0,
          dealsNotTakenUp: 0
        };

        // updated activeDeals if exist
        if ('Open' in data) {
          tempDealData.activeDeals = data.Open as number;
        }

        // update paidOut if exist
        if ('Closed_Won' in data) {
          tempDealData.paidOut = data.Closed_Won as number;
        }

        // update declined if exist
        if ('Closed_Lost' in data) {
          tempDealData.declined = data.Closed_Lost as number;
        }

        // update dealsNotTakenUp
        if ('Abandoned' in data) {
          tempDealData.dealsNotTakenUp = data.Abandoned as number;
        }

        // add all the deals as total deal
        tempDealData.totalDeals =
          tempDealData.activeDeals +
          tempDealData.paidOut +
          tempDealData.declined +
          tempDealData.dealsNotTakenUp;

        // update deal state
        setDeal((s) => ({ ...s, ...tempDealData }));
      } catch (err) {
        BugTracker.notify(err);
      }
    }; //END fetchDealMetric

    fetchDealMetric();
  }, []); //END useEffect

  return (
    <div className={classes.content}>
      {/* total deals */}
      <div className={classes.details}>
        <Typography className={classes.title} variant="body2">
          Total Deals
        </Typography>
        <Typography className={classes.value} color="primary" variant="h1">
          {deal.totalDeals}
        </Typography>
      </div>

      {/* active deals */}
      <div className={classes.details}>
        <Typography className={classes.title} variant="body2">
          Active Deals
        </Typography>
        <Typography className={classes.value} color="primary" variant="h1">
          {deal.activeDeals}
        </Typography>
      </div>

      {/* paid out */}
      <div className={classes.details}>
        <Typography className={classes.title} variant="body2">
          Paid Out
        </Typography>
        <Typography className={classes.value} color="primary" variant="h1">
          {deal.paidOut}
        </Typography>
      </div>

      {/* declined */}
      <div className={classes.details}>
        <Typography className={classes.title} variant="body2">
          Declined
        </Typography>
        <Typography className={classes.value} color="primary" variant="h1">
          {deal.declined}
        </Typography>
      </div>

      {/* Deals not taken up */}
      <div className={classes.details}>
        <Typography className={classes.title} variant="body2">
          Deals Not Taken Up
        </Typography>
        <Typography className={classes.value} color="primary" variant="h1">
          {deal.dealsNotTakenUp}
        </Typography>
      </div>
    </div>
  );
}; //END Progress

export default withStyles(styles)(Progress);
