import React, { SetStateAction, useContext, useEffect, useState } from 'react';
import { useProcess } from 'hooks/useProcess';
import {
  Typography,
  Grid,
  makeStyles,
  createStyles,
  useTheme,
  Box,
  Button,
  CircularProgress,
  IconButton,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  ListItemIcon,
  Menu
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import DealProgressOverview from '../DealProgressOverView';
import { GetUserDefinitions } from 'redux/database';
import { BsFillFileEarmarkPdfFill, BsFillCalculatorFill } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { MdOutlineChecklistRtl, MdStorage } from 'react-icons/md';

import LPCalculatorObjects from '../Steps/ActiveStep/LPCalculatorObjects';
import InfoIcon from '@material-ui/icons/Info';
import AttachmentsLog from '../Overview/components/AttachmentsLog';
import { useGlobal } from 'hooks';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance,
  IRegulated
} from 'types/interfaces';
import { Step } from 'components/Stepper/components/Overview/components/DetailView';

import { MessageHub, OverviewDrawer } from '..';

import FileStorageView from 'components/FileStorage/views';
import { useTypedSelector } from 'redux/reducers';
import { useDealSummary } from 'hooks/useDealSummary';
import { DealSummary } from 'components/DealSummary';
import StepperCalendar from '../Calander';
import SpecialObjectOverview from '../DealProgressOverView/SpecalObjectOverview';
import { grey } from '@material-ui/core/colors';
import { Close, DoubleArrow } from '@material-ui/icons';
import { getSysDocFileUrl } from 'helpers/documentRetrieval';
import { useDispatch } from 'react-redux';
import Checklist from '../Overview/components/Checklist';
import { Regulated } from 'Utils/RegulatedParties';
import { StepperContext } from 'components/Stepper/context';
import { CustomDialog } from 'common/Dialog';
import ActionMenu from '../ActionMenu';
import { IFirestoreAuditLog } from 'hooks/useDeals/useAuditLog';
import { globalIds } from 'helpers/globalIdConfig';

const useStyles = makeStyles(() => createStyles({ item: { margin: 1 } }));

interface IObject {
  odid: number;
  psdid: number;
}

const DealButtons = () => {
  const { isDealClosed, isDealTransferred, auditLogs } =
    useContext(StepperContext);
  const { config } = useGlobal();
  const classes = useStyles();
  const theme = useTheme();
  const { syncProcessInstance } = useDealSummary();

  const {
    entityType,
    currentStep,
    currentOverView,
    currentDeal,
    currentProcess
  } = useTypedSelector((s) => s.process);
  const { deal } = useTypedSelector((s) => s.dealSummary);

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const handleClose = () => setIsDialogOpen(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [isStatusDialogOpen, setIsStatusDialogOpen] = useState<boolean>(false);

  const {
    landingpage,
    user,
    checkRegulatedPartyStatus,
    activeObjects,
    processInstanceFields,
    prostepdefid
  } = useProcess();

  const [loadingDocument, setLoadingDocument] = useState<
    Record<string, boolean>
  >({});

  const [UserDefinition, setUserDefinition] = useState<string>('');
  const [regulated, setRegulated] = useState<IRegulated>({
    hasEntity: false,
    isRegulatedParty: false
  });

  const ProcessInstanceId = currentDeal.ProcessInstance.Id;
  const InstanceFields: FieldInstance[] =
    processInstanceFields[ProcessInstanceId];

  const owner = currentDeal?.ProcessInstance?.UserInstanceId == user?.Id;
  const admin = user?.SystemAccess >= 10;
  const subSystemUser = user?.SystemAccess <= 4;
  const fiveOrAboveUserAccess = user.SystemAccess >= 5;

  const FindUserDefinition = async () => {
    const res = await GetUserDefinitions({
      UserDefinitionId: user.UserDefinitionId
    });

    setUserDefinition(res.data.UserDefinition.Title);
  };
  const ShowDisplays = () => {
    if (landingpage) {
      if (UserDefinition === 'Lenders') {
        return 'lender';
      } else if (
        UserDefinition === 'Contacts' ||
        UserDefinition === 'Customers'
      ) {
        return 'customer';
      }
    } else return 'overview';
  };

  const ProcessDefinitionId = currentProcess.ProcessDefinition.Id.toString();
  const displayQuote: boolean =
    ShowDisplays() === 'overview' &&
    config.processesThatUseQuotes?.includes(ProcessDefinitionId);
  const displayMessageHub: boolean = ShowDisplays() !== 'lender';
  const displayExtraInfo = !landingpage;

  React.useEffect(() => {
    if (landingpage) FindUserDefinition();
  }, [currentDeal]);

  const hasEntityChanged: CompleteObjectInstance = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).find((CompleteObjectInstance: CompleteObjectInstance) => {
    return CompleteObjectInstance.ObjectInstance.ObjectDefinitionId === 3464;
  });

  const dealClosed =
    Object.values(currentDeal).length > 0 &&
    currentDeal.ProcessInstance.ProcessStepDefinitionId < 1;

  useEffect(() => {
    if (!isDealClosed || !isDealTransferred) {
      const targetId = globalIds.stepProcesses.commissionInvoices;

      let isCompleted = false;
      let latestCompletedTimestamp = 0;

      auditLogs.forEach((entry: IFirestoreAuditLog) => {
        const hasEntry = targetId.includes(parseInt(entry.payload));

        if (hasEntry) {
          if (
            entry.firebaseStatus === 'Completed' &&
            entry.timestamp > latestCompletedTimestamp
          ) {
            isCompleted = true;
            latestCompletedTimestamp = entry.timestamp;
          } else if (
            entry.firebaseStatus !== 'Completed' &&
            entry.timestamp > latestCompletedTimestamp
          ) {
            isCompleted = false;
          }
        }
      });

      if (isCompleted) setIsDialogOpen(true);
    }
  }, [isDealClosed, isDealTransferred, auditLogs]);

  useEffect(() => {
    const regCheck = async () => {
      const response = await checkRegulatedPartyStatus();
      setRegulated(response);
    };

    regCheck();
  }, [hasEntityChanged]);

  const dealCondition = isDealClosed || isDealTransferred;
  return (
    <>
      <Grid data-cy="type-of-entity" container direction="column">
        {regulated?.hasEntity && (
          <div style={{ paddingBottom: theme.spacing(0.6) }}>
            <Box
              border={0.9}
              style={{
                borderRadius: theme.shape.borderRadius
              }}
              borderColor={grey[600]}>
              <Grid item style={{ padding: theme.spacing(1) }}>
                <Grid container>
                  <Grid item>
                    <Typography variant="h5" display="block" color="primary">
                      {regulated.isRegulatedParty
                        ? 'REGULATED'
                        : 'NON-REGULATED'}{' '}
                      PARTY
                    </Typography>
                  </Grid>
                  <div style={{ flexGrow: 1 }} />
                  <Grid item>
                    <Typography variant="h5" display="block" color="primary">
                      {entityType}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}

        {displayMessageHub && (
          <Grid data-cy="message-hub-btn" item className={classes.item}>
            <DealProgressOverview
              disabled={dealCondition}
              fullScreen={false}
              Icon={(props) => <AiOutlineMail {...props} />}
              title="Message Hub"
              syncProcessInstance={syncProcessInstance}>
              <MessageHub />
            </DealProgressOverview>
          </Grid>
        )}

        {!landingpage && !subSystemUser && (
          <Grid item className={classes.item}>
            <DealProgressOverview
              disabled={dealCondition}
              fullScreen={false}
              Icon={(props) => <MdOutlineChecklistRtl {...props} />}
              title="Checklist">
              <Checklist />
            </DealProgressOverview>
          </Grid>
        )}

        {displayQuote && (
          <Grid data-cy="deal-quotes-btn" item className={classes.item}>
            <DealProgressOverview
              disabled={dealCondition}
              fullScreen={false}
              Icon={(props) => <BsFillCalculatorFill {...props} />}
              title="Quotes">
              <LPCalculatorObjects />
            </DealProgressOverview>
          </Grid>
        )}

        {displayExtraInfo && (
          <>
            <Grid item className={classes.item}>
              <DealProgressOverview
                fullScreen={false}
                Icon={(props) => <InfoIcon {...props} />}
                title="Document Preview">
                <AttachmentsLog />
              </DealProgressOverview>
            </Grid>
          </>
        )}

        {deal &&
          Object.values(deal).length > 0 &&
          !landingpage &&
          !dealCondition && (
            <Grid item className={classes.item}>
              <DealSummary />
            </Grid>
          )}

        {!landingpage && !dealCondition && (
          <Grid item className={classes.item}>
            <StepperCalendar />
          </Grid>
        )}

        <Grid item className={classes.item}>
          <DealProgressOverview
            fullScreen={false}
            Icon={(props) => <MdStorage {...props} />}
            title="File Storage">
            <FileStorageView />
          </DealProgressOverview>
        </Grid>

        <Grid item className={classes.item}>
          <OverviewDrawer />
        </Grid>

        <div
          style={{
            margin: 10,
            background: theme.palette.grey[100],
            height: 1
          }}
        />

        {config.SpecialObjects.map((SpecialObject: string) => {
          // Early return if no activeObjects
          if (!activeObjects) {
            return null;
          }

          // Find the existing object and handle potential undefined case
          const exists = activeObjects.find(
            (el) => el.odid?.toString() === SpecialObject
          );

          // Early return if object doesn't exist
          if (!exists?.psdid || !exists?.odid) {
            return null;
          }

          // Safely access the step with null checking
          const step =
            currentProcess?.CompleteProcessStepDefinitionDict?.[exists.psdid];
          if (!step) {
            console.warn(`No step found for psdid: ${exists.psdid}`);
            return null;
          }

          // Safely access the object definition with null checking
          const objectDef: CompleteObjectDefinition | undefined =
            step.CompleteObjectDefinitionDict?.[exists.odid];
          if (!objectDef?.ObjectDefinition) {
            console.warn(`No object definition found for odid: ${exists.odid}`);
            return null;
          }

          // Safely destructure ProcessStepDefinition
          const stepId = step.ProcessStepDefinition?.Id;
          if (!stepId) {
            console.warn('No ProcessStepDefinition Id found');
            return null;
          }

          // Check if step is active
          const isActive = activeObjects
            .map((object: IObject) => object.psdid)
            .includes(stepId);

          // Filter existing field instances
          const existingFieldInstanceList: FieldInstance[] = (
            InstanceFields ?? []
          ).filter(
            (item) => item?.ObjectDefinitionId === parseInt(SpecialObject)
          );

          // Early return if no field instances
          if (existingFieldInstanceList.length <= 0) {
            return <div key={SpecialObject} />;
          }

          // Handle special object completion logic
          let hideSpecialObjectUntilCompletion = false;
          if ([2849, 2846, 3613].includes(objectDef.ObjectDefinition.Id)) {
            const showObject = shouldShowObject(
              objectDef,
              existingFieldInstanceList
            );

            hideSpecialObjectUntilCompletion = showObject;
          } else {
            hideSpecialObjectUntilCompletion = true;
          }

          // Early return if object should be hidden
          if (!hideSpecialObjectUntilCompletion) {
            return <div key={SpecialObject} />;
          }

          // Render the special object if all conditions are met
          return (
            <Grid item className={classes.item} key={SpecialObject}>
              <SpecialObjectOverview
                type="success"
                fullScreen={false}
                Icon={(props) => <AssignmentTurnedInIcon {...props} />}
                title={objectDef.ObjectDefinition.Title}>
                {isActive && activeObjects && (
                  <Step
                    SpecialObject={SpecialObject}
                    activeObjects={activeObjects}
                    key={exists.odid}
                    step={step}
                  />
                )}
              </SpecialObjectOverview>
            </Grid>
          );
        })}

        <CustomDialog
          open={isDialogOpen}
          handleClose={handleClose}
          maxSize="sm"
          alert={{
            title: 'Status Update Notification',
            description:
              'A Commission Invoice has been issued. Please update the deal status accordingly',
            type: 'warning'
          }}>
          <DialogContent>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              Accounts have raised the Lender Commission Invoice. Please Close
              the Deal.
            </Typography>

            {!dealClosed && fiveOrAboveUserAccess && isStatusDialogOpen && (
              <Select style={{ width: '100%', marginTop: '20px' }}>
                <>
                  <ActionMenu
                    type="Deal Paid Out"
                    handleCloseMenu={() => setIsStatusDialogOpen(true)}
                  />
                  <ActionMenu
                    type="Deal Not Taken Up"
                    handleCloseMenu={() => setIsStatusDialogOpen(true)}
                  />
                  <ActionMenu
                    type="Decline Deal"
                    handleCloseMenu={() => setIsStatusDialogOpen(true)}
                  />
                </>
              </Select>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsStatusDialogOpen(!isStatusDialogOpen)}>
              {loading ? (
                <CircularProgress size={20} />
              ) : isStatusDialogOpen ? (
                'Hide Options'
              ) : (
                'Update Status'
              )}
            </Button>
          </DialogActions>
        </CustomDialog>
      </Grid>
    </>
  );
};

const ComputerSaysNo = () => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%'
    }}>
    <Alert severity="info">
      <Typography>
        You do not have a sufficient permission level to access this resource.
      </Typography>
    </Alert>
  </div>
);

export default DealButtons;

const shouldShowObject = (
  objectDef: CompleteObjectDefinition,
  existingFieldInstanceList: FieldInstance[]
): boolean => {
  let requiredFields: FieldDefinition[];

  if (objectDef.FieldDefinitionList) {
    requiredFields = objectDef.FieldDefinitionList.filter(
      (field) => field.FieldRequired
    );
  } else if (objectDef.FieldDefinitionDict) {
    requiredFields = Object.values(objectDef.FieldDefinitionDict).filter(
      (field) => field.FieldRequired
    );
  } else {
    console.error(
      'No FieldDefinitionList or FieldDefinitionDict found in objectDef'
    );
    return false;
  }

  return requiredFields.every((requiredField) => {
    const matchingInstance = existingFieldInstanceList.find(
      (instance) => instance.FieldDefinitionId === requiredField.Id
    );

    return (
      matchingInstance &&
      matchingInstance.FieldValue !== undefined &&
      matchingInstance.FieldValue !== ''
    );
  });
};

//! Shamila has Requested For This to be Removed Ticket ID: 006170
// // <Grid container direction="column">
// //             {config.SpecialObjects.filter((SpecialObject: string) =>
// //               filterSpecialObjects.includes(SpecialObject)
// //             ).map((FilteredSpecialObject: string) => {
// //               const exists = activeObjects?.find(
// //                 (el) => el.odid.toString() === FilteredSpecialObject
// //               );
// //               if (!exists) return;

// //               const CPSDD =
// //                 currentProcess.CompleteProcessStepDefinitionDict;
// //               const step = CPSDD[exists?.psdid];

// //               const objectDef: CompleteObjectDefinition =
// //                 step.CompleteObjectDefinitionDict[exists.odid];

// //               const { Id } = step.ProcessStepDefinition;
// //               const isActive = activeObjects
// //                 ?.map((object: IObject) => object.psdid)
// //                 .includes(Id);

// //               const existingFieldInstanceList: FieldInstance[] =
// //                 InstanceFields.filter(
// //                   (item: FieldInstance) =>
// //                     item.ObjectDefinitionId ===
// //                     parseInt(FilteredSpecialObject)
// //                 );

// //               if (existingFieldInstanceList.length <= 0) return;
// //               if (!isActive) return;

// //               const isLoading =
// //                 loadingDocument[objectDef.ObjectDefinition.Id];
// //               return (
// //                 <Grid
// //                   item
// //                   style={{ margin: 2 }}
// //                   key={FilteredSpecialObject}>
// //                   <Button
// //                     variant="outlined"
// //                     fullWidth
// //                     color="primary"
// //                     onClick={async () => {
// //                       const getAndGoToFile = async () => {
// //                         const _inst: FieldInstance[] =
// //                           processInstanceFields[
// //                             currentOverView.ProcessInstanceId
// //                           ];

// //                         const filterDocuments: string[] = [
// //                           'Broker Fee to Customer',
// //                           'Commission Invoice / PayOuts',
// //                           'Customer Proposal Decision',
// //                           'Customer Appraisal Fee'
// //                         ];

// //                         const zeroField: FieldInstance[] = _inst
// //                           .filter((el) => el.Id === 0)
// //                           .filter((fieldInstance) =>
// //                             filterDocuments.includes(fieldInstance.Title)
// //                           );

// //                         const objectTitleMap = {
// //                           'Appraisal Fee': 'Customer Appraisal Fee',
// //                           'Broker Fee': 'Broker Fee to Customer',
// //                           'Commission Invoice':
// //                             'Commission Invoice / PayOuts',
// //                           'Lender Decision': 'Customer Proposal Decision'
// //                         };

// //                         const clickedObject = zeroField.find(
// //                           (FieldInstance: FieldInstance) =>
// //                             FieldInstance.Title ===
// //                             objectTitleMap[objectDef.ObjectDefinition.Title]
// //                         );

// //                         if (clickedObject) {
// //                           setLoadingDocument((prevLoading) => ({
// //                             ...prevLoading,
// //                             [objectDef.ObjectDefinition.Id]: true
// //                           }));
// //                           const config = {
// //                             ProcessInstanceId:
// //                               currentOverView.ProcessInstanceId,
// //                             ProcessStepDefinitionId: prostepdefid,
// //                             DocumentStepDefinitionId: parseInt(
// //                               clickedObject.FieldValue
// //                             ),
// //                             action: 'GetPDF'
// //                           };
// //                           const res = await getSysDocFileUrl(config);
// //                           if (res) window.open(res);

// //                           setLoadingDocument((prevLoading) => ({
// //                             ...prevLoading,
// //                             [objectDef.ObjectDefinition.Id]: false
// //                           }));
// //                         }
// //                       };

// //                       await getAndGoToFile();
// //                     }}>
// //                     <Grid container>
// //                       {objectDef.ObjectDefinition.Title} Document &nbsp;
// //                       {!isLoading ? (
// //                         <DoubleArrow />
// //                       ) : (
// //                         <CircularProgress
// //                           size={'1rem'}
// //                           style={{ marginTop: 5 }}
// //                         />
// //                       )}
// //                     </Grid>
// //                   </Button>
// //                 </Grid>
// //               );
// //             })}
// //           </Grid>

// // const [isCacheUpdating, setIsCacheUpdating] = useState(false);
// // React.useEffect(() => {
// //   let isMounted = true;

// //   const compareAndClearCache = async () => {
// //     const UserInstanceId =
// //       currentStep.UserInstanceListForCurrentStep[0].UserInstanceId;

// //     const getCustomKey = `UserInstance_${UserInstanceId}`;
// //     if (!regulatedParty[getCustomKey]) {
// //       await regCheck();
// //       return;
// //     }

// //     const regulatedInstance: FieldInstanceDict =
// //       regulatedParty[getCustomKey][0].FieldInstanceDict;
// //     const findEntityFieldInstance: FieldInstance = Object.values(
// //       regulatedInstance
// //     ).find((FieldInstance: FieldInstance) => {
// //       return FieldInstance.ObjectDefinitionId === 3464;
// //     });

// //     const findPrevEntityFieldInstance: FieldInstance = Object.values(
// //       hasEntityChanged.FieldInstanceDict
// //     ).find((FieldInstance: FieldInstance) => {
// //       return FieldInstance.ObjectDefinitionId === 3464;
// //     });

// //     if (
// //       findEntityFieldInstance.FieldValue !==
// //       findPrevEntityFieldInstance.FieldValue
// //     ) {
// //       setIsCacheUpdating(true);
// //       if (isMounted) {
// //         await regCheck();
// //         setIsCacheUpdating(false);
// //       }
// //     } else {
// //       setRegulated({
// //         hasEntity: true,
// //         isRegulatedParty: Regulated.includes(
// //           findEntityFieldInstance.FieldValue
// //         )
// //       });
// //     }
// //   };
// //   if (isMounted && !isCacheUpdating) {
// //     compareAndClearCache();
// //   }
// //   return () => {
// //     isMounted = false;
// //   };
// // }, [hasEntityChanged, isCacheUpdating]);

// // <Grid item className={classes.item}>
// //   <DealProgressOverview
// //     fullScreen={false}
// //     Icon={(props) => <BsFillFileEarmarkPdfFill {...props} />}
// //     title="Document">
// //     <Documents />
// //     {!permission ? <ComputerSaysNo /> : <StepDocumentHTML />}
// //   </DealProgressOverview>
// // </Grid>

// // ADD REGULATED FIELDS AGAGIN
// // const regCheck = async () => {
// //   const res: SetStateAction<{
// //     hasEntity: boolean;
// //     isRegulatedParty: undefined | boolean;
// //   }> = await checkRegulatedPartyStatus();
// //   setRegulated(res);
// // };
