import { makeStyles, Theme } from '@material-ui/core/styles';

export const useAlertDialogStyles = makeStyles((theme: Theme) => ({
  sectionContainer: {
    border: '2px solid #FFA500',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  warningBox: {
    backgroundColor: '#FFEBEE',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  warningIcon: {
    color: '#D32F2F',
    marginRight: theme.spacing(1)
  },
  checklistItem: {
    marginBottom: theme.spacing(1)
  },
  checkbox: {
    color: '#FFA500',
    '&$checked': {
      color: '#4CAF50'
    }
  },
  checked: {},
  boldText: {
    fontWeight: 'bold'
  },
  proceedButton: {
    backgroundColor: (props: { allChecked: boolean }) =>
      props.allChecked ? '#4CAF50' : theme.palette.grey[300],
    color: (props: { allChecked: boolean }) =>
      props.allChecked
        ? theme.palette.common.white
        : theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: (props: { allChecked: boolean }) =>
        props.allChecked ? '#45a049' : theme.palette.grey[400]
    }
  },
  checklistText: {
    fontSize: '0.9rem'
  },
  sectionHeader: {
    fontSize: '1rem',
    marginBottom: theme.spacing(1)
  }
}));
