import { UserInstance, CompleteObjectInstance } from 'types/interfaces';

// Used By GetUsersForProcess
export interface IUserForProcess {
  Id: number;
  Title: string;
}

// Used By GetUsersForProcess
export interface IUserForProcessRequest {
  ProcessInstanceId: number;
  ProcessDefinitionId: number;
  UserDefinitionId: number;
  fetchQueries?: boolean;
  action:
    | 'USERSFORPROCESS'
    | 'ALLUSERSFORPROCESS'
    | 'ASSIGNEDUSERSFORPROCESS'
    | 'SELECTEDUSERFORPROCESS';
  baseUrl: string;
}

// Used By GetCompleteUserInstanceDetail
export interface ICompleteUserInstanceDetail {
  UserInstance: UserInstance;
  CompleteObjectInstanceList: CompleteObjectInstance[];
}

// Used By GetQuickLiteUser
export interface IGetQuickLiteUser {
  baseUrl: string;
  UserInstanceId: number;
  action: 'GET';
}

// Used By GetQuickLiteUser
export interface IUpdateQuickLiteUser {
  baseUrl: string;
  ProcessDefinitionId: number;
  data: IUpdateUserInstance;
  action: 'UPDATE';
}

// Used By UpdateQuickLiteUser
export interface IUpdateUserInstance {
  Title: string;
  UserDefinitionId: number;
  UserInstanceEmail: string;
  OwnerUserInstanceId?: string;
  Id?: number;
  UserInstancePassword?: string;
  IsPublished: boolean;
}

// Used By GetUserInstanceWithObjects
export interface IUserInstanceObjectData {
  UserInstanceIdList: string[];
  ObjectDefinitionIdList: number[];
}

// Used By CreateRelationship
export interface IRelationshipInstance {
  Id: number;
  Title: String;
  PrimaryUserInstanceId: number;
  RelatedUserInstanceId: number;
  Status: number;
  ProcessInstanceId: number;
  AssignedUserDefinitionId: number;
  RelatedUserDefinitionId: number;
}

// Used By CreateRelationship
export enum RelatedUserPermission {
  Standard = 'Standard',
  Read = 'Read',
  Write = 'Write'
}

export interface IRelationshipData {
  Title: string;
  PrimaryUserInstanceId: number;
  RelatedUserInstanceId: number;
  Status: number;
  RelatedUserPermission: RelatedUserPermission;
}

// Used By GetRelationship
export interface IRelationshipListForKeyword {
  baseUrl: string;
  action: 'LISTFORKEYWORD';
  UserInstanceId: number;
  keyword: string;
}

// Used By GetRelationship
export interface IDeleteRelationship {
  baseUrl: string;
  action: 'DELETE';
  UserDefinitionId: number;
  PrimaryUserInstanceId: number;
  RelatedUserInstanceId: number;
}

// Used By GetRelationship
export interface ICreateRelationship {
  baseUrl: string;
  action: 'CREATE';
  data: IRelationshipData;
  UserDefinitionId: number;
  UserInstanceId: number;
}

// Used By GetRelationship
export interface IGetRelationshipsRelatedToAssigned {
  baseUrl: string;
  action: 'RELATEDTOASSIGNED';
  ProcessInstanceId: number;
  AssignedUserDefinitionId: number;
  RelatedUserDefinitionId: number;
  fetchPolicy?: boolean;
}

// Used By GetRelationship
export interface IGetRelationshipList {
  baseUrl: string;
  action: 'LIST' | 'LIST_ALL';
  UserInstanceId: number;
  UserDefinitionId: number;
  fetchPolicy?: boolean;
}
