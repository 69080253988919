import { Drawer } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

const NotificationDrawer = ({ children, open, onClose }) => {
  return (
    <Hidden smUp implementation="css">
      <Drawer anchor="right" open={open} onClose={onClose}>
        {children}
      </Drawer>
    </Hidden>
  );
};

export default NotificationDrawer;
