import React from 'react';
import { IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomDeletionDialog } from 'common/Dialog';

interface IDeleteObject {
  object: any;
  title: string;
  deleteObject: (Id: number) => void;
  isDeclined?: boolean;
}

const DeleteObjectButton = (props: IDeleteObject) => {
  const { deleteObject, object, title } = props;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleDelete = () => {
    setOpen(false);
    deleteObject(object?.ObjectInstance?.Id);
  };

  return (
    <>
      <CustomDeletionDialog
        open={open}
        setOpen={setOpen}
        confirmedDeleted={handleDelete}
        type="delete"
        title={title}
      />

      <Tooltip title="Delete Object">
        <IconButton onClick={handleOpen}>
          <DeleteIcon style={{ color: props.isDeclined ? 'white' : '' }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default DeleteObjectButton;
