import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';
import { Dashboard as DashboardLayout } from 'layouts';

import Emails from './Emails';
import Settings from 'components/Settings';
import NoticeBoard from './NoticeBoard';
import User from 'components/User';

const Profile = () => {
  const user = useTypedSelector((s) => s.user.user);
  const [loading, setLoading] = React.useState(true);

  return (
    <DashboardLayout>
      <Typography color="textPrimary">
        <User data={user} getData={() => {}} />
        <br />

        <Settings />
      </Typography>
    </DashboardLayout>
  );
};

export default Profile;
