import {
  IConversation,
  ICustomMessage,
  IMicrosoftMessages
} from 'components/MessageHub/interfaces';
import { sortConversations } from '.';

const getSortedArchives = ({
  archived
}: {
  archived: IMicrosoftMessages | null;
}) => {
  if (!archived) return {};

  let newConversations: { [key: string]: IConversation } = {};

  archived.value.forEach((message: ICustomMessage) => {
    const { conversationId, receivedDateTime } = message;
    if (!conversationId) return;

    if (!newConversations[conversationId]) {
      newConversations[conversationId] = {
        parentMessage: message,
        replies: [],
        totalAttachments: []
      };
    } else {
      const existingParentDateTime =
        newConversations[conversationId].parentMessage.receivedDateTime;
      if (
        new Date(receivedDateTime || 0).getTime() <
        new Date(existingParentDateTime || 0).getTime()
      ) {
        newConversations[conversationId].replies.push(
          newConversations[conversationId].parentMessage
        );
        newConversations[conversationId].parentMessage = message;
      } else {
        newConversations[conversationId].replies.push(message);
      }
    }
  });

  Object.values(newConversations).forEach((conversation) => {
    const attachmentHashes = new Set();
    const addAttachments = (attachments) => {
      attachments.forEach((attachment) => {
        const hash = `${attachment.name}`;
        if (!attachmentHashes.has(hash)) {
          conversation.totalAttachments.push(attachment);
          attachmentHashes.add(hash);
        }
      });
    };

    conversation.totalAttachments = [];
    if (conversation.parentMessage?.attachments) {
      addAttachments(conversation.parentMessage.attachments);
    }

    conversation.replies.forEach((reply) => {
      if (reply.attachments) {
        addAttachments(reply.attachments);
      }
    });
  });

  Object.values(newConversations).forEach((conversation) => {
    conversation.replies.sort(
      (a, b) =>
        new Date(a.receivedDateTime || 0).getTime() -
        new Date(b.receivedDateTime || 0).getTime()
    );
  });

  let newConversationsArray: IConversation[] = Object.values(newConversations);
  let sortedConversations = sortConversations(newConversationsArray);
  return sortedConversations;
};

export default getSortedArchives;
