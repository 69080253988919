import React from 'react';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { handleUpdateField } from '../../functions';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { IFieldsProps } from '../../interface';
import { useTypedSelector } from 'redux/reducers';
import FileStoragePicker from 'components/FileStorage/components/FileStoragePicker';
import './styles.css';
import { FbFileRef } from 'types/interfaces';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useProcess } from 'hooks';
import { GetFileExplorer, GetUserGeneratedDocument } from 'redux/database';
import { BugTracker } from 'Utils/Bugtracker';

const useStyles = makeStyles((theme) => ({
  removeicon: { '&:hover': { color: 'red' } },
  link: { color: 'blue' },
  fieldInput: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    minHeight: '35px',
    width: '100%',
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius
  },
  label: {
    display: 'flex',
    flexDirection: 'column'
  },
  fileUpload: {
    border: `1px solid #ccc`,
    display: `inline-block`,
    padding: `6px 12px`,
    cursor: `pointer`,
    background: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius
  }
}));

const Component = (props: IFieldsProps) => {
  const { value } = props;
  const { token } = useTypedSelector((s) => s.user.auth);
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const onChange: any = handleUpdateField({ setUpdating, token, props });

  const getAndGoToFile = async () => {
    const res = await GetUserGeneratedDocument({
      FieldInstanceId: props.FieldInstance?.Id,
      ProcessInstanceId: props.ProcessInstance,
      ProcessStepDefinitionId: props.ProcessStepDefinition?.Id
    });

    const isFilePath = /^(\/[\w\s]+)+\/[\w\s]+\.\w+$/.test(res.data.FieldValue);
    if (isFilePath && res) {
      const path = res.data.FieldValue;
      const Name = path.split('/').pop();

      try {
        const response = await GetFileExplorer({
          action: 'getfile',
          path: encodeURIComponent(path),
          responseType: 'blob'
        });

        if (response) {
          const file = new File([response.data], Name, {
            type: response.headers['content-type']
          });

          const fileURL = URL.createObjectURL(file);
          if (response.headers['content-type'] === 'application/pdf') {
            window.open(fileURL);
          } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.href = fileURL;
            a.download = Name;
            a.click();
            document.body.removeChild(a);
          }

          URL.revokeObjectURL(fileURL);
        }
      } catch (e) {
        BugTracker.notify(e);
      }
    } else if (res) {
      const value = res.data.FieldValue;
      fetch(value).then(async (res) => {
        const file = await res.blob();
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      });
    }
  };

  const onSelect = async ({
    url,
    item,
    type
  }: {
    url: string;
    item: FbFileRef | File;
    type?: 'File Explorer' | 'File Storage';
  }) => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = () => {
      var blob = xhr.response;
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      if (type && type === 'File Explorer') {
        const fileUrl = url;
        const pathMatch = fileUrl.match(/path=(.+)/);
        if (pathMatch) {
          const decodedPath = decodeURIComponent(pathMatch[1]);
          reader.onload = () => onChange(decodedPath, item.name);
        }
      } else reader.onload = () => onChange(reader.result, item.name);
      reader.onerror = (e) => console.log(e);
    };
    xhr.open('GET', url);
    return xhr.send();
  };

  if (updating) return <CircularProgress />;
  if (value) {
    return (
      <div className={classes.fieldInput}>
        <Link href="#" onClick={getAndGoToFile} className={classes.link}>
          {props && props.FieldInstance && props.FieldInstance.Title !== ''
            ? props.FieldInstance.Title
            : 'link'}
        </Link>
        {!props.readOnly && (
          <React.Fragment>
            <div style={{ flexGrow: 1 }} />
            <Tooltip title="Remove File">
              <IconButton onClick={handleClickOpen}>
                <RemoveCircleIcon className={classes.removeicon} />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">Remove File ?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to permanently remove the file from this record, are
              you sure?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                onChange('');
                handleClose();
              }}
              color="primary"
              autoFocus>
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  // const FileComponent = () => {
  //   if (landingpage) {
  //     return (
  //       <input
  //         type="file"
  //         onChange={(e: any) => {
  //           const Title = e.target.files[0].name;
  //           let reader = new FileReader();
  //           reader.readAsDataURL(e.target.files[0]);
  //           reader.onload = () => onChange(reader.result, Title);
  //           reader.onerror = (e) => console.log(e);
  //         }}
  //       />
  //     );
  //   } else {
  //     return;
  //   }
  // };
  return (
    <div className={classes.fieldInput}>
      {!props.readOnly ? (
        <FileStoragePicker
          inlineFieldProps={props}
          plugin={props.isDetails ? true : false}
          title={'Choose File'}
          onSelect={onSelect}
        />
      ) : (
        'No File'
      )}
    </div>
  );
};

export default Component;
