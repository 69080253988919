import { Dashboard as DashboardLayout } from '../../layouts';
import Chatter from 'components/Chatter';
import BetaBanner from 'components/BetaBanner';

const Component = (props) => (
  <DashboardLayout title="Chatter">
    <BetaBanner />
    <Chatter />
  </DashboardLayout>
);

export default Component;
