import { useEffect, useState } from 'react';
import { useFileStorage } from 'hooks';
import {
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  ButtonGroup,
  Checkbox,
  Paper,
  Popover
} from '@material-ui/core';
import { useTheme } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { FbFileRef, AssignedUser } from 'types/interfaces';
import QuickUserFromId from 'components/User/QuickUserFromId';
import { Alert, AlertTitle } from '@material-ui/lab';
import RefreshIcon from '@material-ui/icons/Refresh';
import DeleteIcon from '@material-ui/icons/Delete';
import PermissionsPickerDialog from './PermissionsPicker';

import { createNotification } from 'react-redux-notify';
import { errorNotif } from 'components/Notifications';
import {
  Backspace,
  FilterList,
  GetApp,
  LibraryAddCheck,
  PeopleAlt
} from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import DocumentCard from '../components/DocumentCard';
import { useTypedSelector } from 'redux/reducers';
import DocumentFilterPanel from './DocumentFilterPanel';
import { AnimatePresence, motion } from 'framer-motion';
import {
  EDocumentResults,
  EDocumentType
} from 'graphql/FileStorageAPI/interface';
import {
  ADD_USER_TO_FILE,
  REMOVE_FILE,
  REMOVE_USER_FROM_FILE
} from 'redux/actions/types';

/**
 * DocumentGrid component for displaying and managing a grid of document files.
 *
 * @param {FbFileRef[]} FileRefs - An array of file reference objects to display.
 * @param {() => void} get - Function to fetch or refresh the file list.
 * @param {(key: string) => void} onSelect - Callback function when a file is selected.
 * @param {boolean} global - Flag indicating if the component is in a global context.
 * @param {boolean} loading - Flag indicating if data is currently being loaded.
 * @param {FbFileRef[]} selected - Array of currently selected file references.
 * @param {React.Dispatch<React.SetStateAction<FbFileRef[]>>} setSelected - Function to update the selected files.
 * @param {boolean} isDealSummary - Flag indicating if this is a deal summary view.
 * @param {boolean} hideControls - Flag to hide certain control elements.
 */
const DocumentGrid = ({
  FileRefs,
  get,
  onSelect,
  global,
  loading,
  selected,
  setSelected,
  isDealSummary,
  hideControls
}: {
  FileRefs: { [key: string]: FbFileRef };
  get: () => void;
  onSelect?: ({ url, item }: { url: string; item: FbFileRef }) => Promise<void>;
  global?: boolean;
  loading: boolean;
  selected: { [key: string]: FbFileRef };
  setSelected: React.Dispatch<
    React.SetStateAction<{ [key: string]: FbFileRef }>
  >;
  isDealSummary?: boolean;
  hideControls?: boolean;
}) => {
  const dispatch = useDispatch();
  const { currentDeal } = useTypedSelector((s) => s.process);
  const { user } = useTypedSelector((s) => s.user);
  const { landingpage } = useTypedSelector((s) => s.utility);

  const { updatePermission, deleteFile, downloadAllFiles } = useFileStorage();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);

  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [selectedView, setSelectedView] = useState<boolean>(false);
  const [filterState, setFilterState] = useState<{
    documentType: EDocumentType | '';
    documentStatus: EDocumentResults | '';
    fileType: string;
    sizeRange: [number, number];
  }>({
    documentType: '',
    documentStatus: '',
    fileType: '',
    sizeRange: [0, Infinity]
  });

  const [allDocumentsSelected, setAllDocumentsSelected] =
    useState<boolean>(false);

  const [filteredFiles, setFilteredFiles] = useState<FbFileRef[]>(
    Object.values(FileRefs)
  );

  const toggle = () => setSelectedView(!selectedView);
  const handleFilterClick = (event) => setAnchorEl(event.currentTarget);
  const handleFilterClose = () => setAnchorEl(null);

  const openFilter = Boolean(anchorEl);
  const ProcessInstanceId = global ? 0 : currentDeal?.ProcessInstance?.Id || 0;

  const applyFilter = () => {
    const filtered = Object.values(FileRefs).filter((file: FbFileRef) => {
      const typeMatch =
        !filterState.documentType ||
        (file.documentStatus?.documentType &&
          file.documentStatus.documentType === filterState.documentType);
      const statusMatch =
        !filterState.documentStatus ||
        (file.documentStatus?.documentStatus &&
          file.documentStatus.documentStatus === filterState.documentStatus);
      const fileTypeMatch =
        !filterState.fileType || file.type === filterState.fileType;
      const sizeMatch =
        file.size >= filterState.sizeRange[0] &&
        file.size <= filterState.sizeRange[1];

      return typeMatch && statusMatch && fileTypeMatch && sizeMatch;
    });

    setFilteredFiles(filtered);
    setIsFilterApplied(true);
  };

  const handleChange = async ({
    key,
    user,
    checked,
    isGrouped
  }: {
    key: string;
    user: AssignedUser | string;
    checked: boolean;
    isGrouped: boolean;
  }) => {
    let UserInstanceId = '';
    if (typeof user === 'string') {
      UserInstanceId = user;
    } else if ('UserInstanceId' in user) {
      UserInstanceId = user.UserInstanceId.toString();
    }

    const res = await updatePermission({
      key,
      UserInstanceId,
      ProcessInstanceId,
      checked
    });

    if (res === 'document successfully updated') {
      await get();
      if (isGrouped) {
        setSelected((prevSelected) => {
          const newSelected = JSON.parse(JSON.stringify(prevSelected));
          const currentItem = newSelected[key];

          if (currentItem && currentItem.users) {
            if (!checked) {
              currentItem.users.push(UserInstanceId);
            } else {
              const index = currentItem.users.indexOf(UserInstanceId);
              if (index > -1) {
                currentItem.users.splice(index, 1);
              }
            }
          }

          return newSelected;
        });
      }

      if (checked) {
        dispatch({
          type: REMOVE_USER_FROM_FILE,
          payload: { fileId: key, userId: UserInstanceId }
        });
      } else if (!checked) {
        dispatch({
          type: ADD_USER_TO_FILE,
          payload: { fileId: key, userId: UserInstanceId }
        });
      }
    }
  };

  useEffect(() => {
    const maxFileSize = Math.max(
      ...Object.values(FileRefs).map((file) => file.size),
      0
    );
    setFilterState((prevState) => ({
      ...prevState,
      sizeRange: [0, maxFileSize]
    }));
  }, [FileRefs]);

  useEffect(() => {
    () => get();
  }, [FileRefs]);

  const toggleSelect = (key) => {
    const item = FileRefs[key] as FbFileRef;

    setSelected((prevSelected) => {
      const newSelected = { ...prevSelected };
      if (newSelected[item.fileId || key]) {
        delete newSelected[item.fileId || key];
      } else {
        newSelected[item.fileId || key] = item;
      }

      return newSelected;
    });
  };

  const selectAllDocuments = () => {
    setSelected((prevSelected) => {
      const newSelected = { ...prevSelected };
      Object.keys(FileRefs).forEach((key) => {
        const item = FileRefs[key] as FbFileRef;
        newSelected[item.fileId || key] = item;
      });
      return newSelected;
    });
  };

  const [downloadingAll, setDownloadingAll] = useState(false);
  const handleDownloadAll = async (e: React.MouseEvent) => {
    e.stopPropagation();

    setDownloadingAll(true);
    await downloadAllFiles({
      ProcessInstanceId,
      items: landingpage ? Object.values(FileRefs) : Object.values(selected)
    });

    setDownloadingAll(false);
  };

  const handleDeleteAll = async () => {
    const deletePromises = Object.keys(selected).map(async (key: string) => {
      const item = selected[key] as FbFileRef;
      const isMine = user.Id === item.UserInstanceId;
      const amMoreThanSubSystemUser = user.SystemAccess > 4;
      const canDelete = amMoreThanSubSystemUser || isMine;

      if (canDelete) {
        const deleted = await deleteFile({
          amMoreThanSubSystemUser,
          ProcessInstanceId,
          item,
          key
        });
        if (deleted) {
          dispatch({
            type: REMOVE_FILE,
            payload: key
          });
        }
      } else {
        dispatch(
          createNotification(
            errorNotif(
              `You Do Not have Permission To Delete Document ${
                item.nickName || key
              }`
            )
          )
        );
        return false;
      }
    });

    const results = await Promise.all(deletePromises);
    if (results.every((success) => success)) {
      setSelected({});
      get();
    }
  };

  const getBackgroundColor = (file) => {
    if (user.SystemAccess < 5) {
      return theme.palette.success.main;
    }

    if (!file?.documentStatus) {
      return theme.palette.success.main;
    }

    return file.documentStatus.documentStatus === EDocumentResults.NONE ||
      file.documentStatus.documentStatus === EDocumentResults.ACCEPTABLE
      ? theme.palette.success.main
      : theme.palette.error.main;
  };

  useEffect(() => {
    const allSelected =
      Object.keys(selected).length === Object.keys(FileRefs).length;
    setAllDocumentsSelected(allSelected);
  }, [selected, FileRefs]);

  useEffect(() => {
    if (Object.keys(FileRefs).length === 1) {
      setSelected({});
    }
  }, [FileRefs, setSelected]);

  useEffect(() => {
    if (!isFilterApplied) {
      setFilteredFiles(Object.values(FileRefs));
    }
  }, [FileRefs, isFilterApplied]);

  useEffect(() => {
    applyFilter();
  }, [filterState]);

  const inDeal = ProcessInstanceId > 0;
  if (Object.values(FileRefs).length <= 0) return <div />;
  return (
    <>
      <Grid item xs={12}>
        {!isDealSummary && (
          <PermissionsPickerDialog
            open={selectedView}
            global={global}
            item={selected}
            handleChange={handleChange}
            toggle={toggle}
          />
        )}
      </Grid>

      <Paper>
        <div style={{ margin: theme.spacing(1) }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}>
              {Object.values(selected).length > 1 ? (
                <ButtonGroup variant="outlined" color="primary">
                  {inDeal && !landingpage && (
                    <Tooltip title="Set Permissions for Multiple Viewers">
                      <Button
                        style={{ textTransform: 'none', fontWeight: 'bold' }}
                        onClick={() => {
                          setSelectedView(true);
                        }}
                        endIcon={<PeopleAlt />}>
                        View Selections
                      </Button>
                    </Tooltip>
                  )}

                  {!landingpage && (
                    <Tooltip title="Permanently Remove Selected Documents">
                      <Button
                        style={{ textTransform: 'none', fontWeight: 'bold' }}
                        onClick={handleDeleteAll}
                        endIcon={<DeleteIcon />}>
                        Delete All
                      </Button>
                    </Tooltip>
                  )}

                  {selected && Object.keys(selected).length > 1 && (
                    <Tooltip title="Download All Selected Documents">
                      <Button
                        style={{ textTransform: 'none', fontWeight: 'bold' }}
                        onClick={async (e) => await handleDownloadAll(e)}
                        endIcon={
                          downloadingAll ? (
                            <CircularProgress size={20} />
                          ) : (
                            <GetApp />
                          )
                        }>
                        Download All
                      </Button>
                    </Tooltip>
                  )}

                  <Tooltip title="Refresh Documents">
                    <Button
                      style={{ textTransform: 'none', fontWeight: 'bold' }}
                      onClick={() => get()}
                      endIcon={
                        loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <RefreshIcon />
                        )
                      }>
                      Refresh
                    </Button>
                  </Tooltip>

                  {Object.values(selected).length >= 1 && (
                    <Tooltip title="Deselect All Documents">
                      <Button
                        style={{ textTransform: 'none', fontWeight: 'bold' }}
                        onClick={() => setSelected({})}
                        endIcon={<LibraryAddCheck />}>
                        Deselect All
                      </Button>
                    </Tooltip>
                  )}
                  {!allDocumentsSelected && !landingpage && (
                    <Tooltip title="Select All Documents">
                      <Button
                        style={{ textTransform: 'none', fontWeight: 'bold' }}
                        onClick={selectAllDocuments}
                        endIcon={<LibraryAddCheck />}>
                        Select All
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              ) : (
                <ButtonGroup variant="outlined" color="primary">
                  {FileRefs &&
                    Object.keys(FileRefs).length > 1 &&
                    landingpage && (
                      <Tooltip title="Download All Selected Documents">
                        <Button
                          style={{ textTransform: 'none', fontWeight: 'bold' }}
                          onClick={async (e) => await handleDownloadAll(e)}
                          endIcon={
                            downloadingAll ? (
                              <CircularProgress size={20} />
                            ) : (
                              <GetApp />
                            )
                          }>
                          Download All
                        </Button>
                      </Tooltip>
                    )}

                  {!landingpage && (
                    <Tooltip title="Filter Documents">
                      <Button
                        style={{ textTransform: 'none', fontWeight: 'bold' }}
                        onClick={handleFilterClick}
                        endIcon={
                          <FilterList
                            color={isFilterApplied ? 'secondary' : 'inherit'}
                          />
                        }>
                        Filter
                      </Button>
                    </Tooltip>
                  )}

                  <Tooltip title="Refresh Documents">
                    <Button
                      style={{ textTransform: 'none', fontWeight: 'bold' }}
                      onClick={() => get()}
                      endIcon={
                        loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <RefreshIcon />
                        )
                      }>
                      Refresh
                    </Button>
                  </Tooltip>
                  {!hideControls &&
                    !allDocumentsSelected &&
                    !global &&
                    !landingpage &&
                    !isDealSummary && (
                      <Tooltip title="Select All Documents">
                        <Button
                          style={{ textTransform: 'none', fontWeight: 'bold' }}
                          onClick={selectAllDocuments}
                          endIcon={<LibraryAddCheck />}>
                          Select All
                        </Button>
                      </Tooltip>
                    )}
                  {Object.values(selected).length >= 1 && (
                    <Tooltip title="Deselect All Documents">
                      <Button
                        style={{ textTransform: 'none', fontWeight: 'bold' }}
                        onClick={() => setSelected({})}
                        endIcon={<Backspace />}>
                        Deselect All
                      </Button>
                    </Tooltip>
                  )}
                </ButtonGroup>
              )}
            </Grid>
          </Grid>

          <Popover
            open={openFilter}
            anchorEl={anchorEl}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}>
            <div style={{ padding: theme.spacing(2) }}>
              <DocumentFilterPanel
                files={FileRefs}
                setIsFilterApplied={setIsFilterApplied}
                onClose={handleFilterClose}
                setFilterState={setFilterState}
                filterState={filterState}
              />
            </div>
          </Popover>

          <Grid item xs={12}>
            <AnimatePresence>
              {filteredFiles.length > 0 ? (
                filteredFiles.map((file: FbFileRef) => (
                  <motion.div
                    key={file.fileId || file.name}
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.3 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {!hideControls &&
                          !global &&
                          !landingpage &&
                          !isDealSummary && (
                            <Grid container direction="row" alignItems="center">
                              <Grid item>
                                <Checkbox
                                  onChange={() =>
                                    toggleSelect(file.fileId || file.name)
                                  }
                                  checked={!!selected[file.fileId || file.name]}
                                  style={{
                                    color: selected[file.fileId || file.name]
                                      ? theme.palette.success.main
                                      : 'gray'
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <Typography style={{ fontWeight: 'bold' }}>
                                  {`${
                                    selected[file.fileId || file.name]
                                      ? 'Selected'
                                      : 'Select'
                                  } Document: ${file.nickName || file.name}`}
                                </Typography>
                              </Grid>
                            </Grid>
                          )}
                        <div
                          style={{
                            background: getBackgroundColor(file),
                            margin: 8,
                            borderRadius: 10
                          }}>
                          <DocumentCard
                            item={file}
                            get={get}
                            id={file.fileId || file.name}
                            global={global}
                            handleChange={handleChange}
                            onSelect={onSelect}
                            hideControls={hideControls}
                          />

                          {!landingpage && !global && (
                            <>
                              {!(
                                (file.users.length === 1 &&
                                  file.users[0] ===
                                    file.UserInstanceId.toString()) ||
                                file.users.length <= 0
                              ) && (
                                <div style={{ padding: theme.spacing(1) }}>
                                  <Alert
                                    severity={
                                      file?.documentStatus
                                        ? file?.documentStatus
                                            ?.documentStatus ===
                                            EDocumentResults.NONE ||
                                          file?.documentStatus
                                            ?.documentStatus ===
                                            EDocumentResults.ACCEPTABLE
                                          ? 'success'
                                          : 'error'
                                        : 'success'
                                    }>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                      List of Authorized Viewers for This
                                      Document:
                                    </Typography>

                                    {file.users.map((userId) => {
                                      const checked = file.users.includes(
                                        userId.toString()
                                      );

                                      return (
                                        <Grid
                                          container
                                          direction="row"
                                          key={userId}
                                          spacing={1}>
                                          <Grid item>
                                            <Checkbox
                                              onChange={() =>
                                                handleChange({
                                                  key: file.fileId || file.name,
                                                  user: userId,
                                                  checked: true,
                                                  isGrouped: false
                                                })
                                              }
                                              checked={checked}
                                              style={{
                                                color: checked
                                                  ? theme.palette.success.main
                                                  : 'default'
                                              }}
                                            />
                                          </Grid>
                                          <Grid item>
                                            <QuickUserFromId
                                              UserInstanceId={userId.toString()}
                                            />
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </Alert>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </motion.div>
                ))
              ) : (
                <div
                  style={{
                    paddingTop: theme.spacing(1),
                    paddingBottom: theme.spacing(1)
                  }}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}>
                    <Alert severity="info">
                      <AlertTitle>No Matching Documents Found</AlertTitle>
                      No documents match the current filter criteria.
                    </Alert>
                  </motion.div>
                </div>
              )}
            </AnimatePresence>
          </Grid>
        </div>
      </Paper>
    </>
  );
};

export default DocumentGrid;
