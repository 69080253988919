import { Typography, Grid, ListItem, Button } from '@material-ui/core';
import { Capitalize } from './Capitalize';
import {
  LegacyDealDocument,
  LegacyDealActivity
} from 'redux/reducers/legacyDealReducer';
import { GrabbingDocumentData } from './LegacyDialog';

export const LegacyDetailData = ({ current }) =>
  Object.entries(current).map((currentLegacyDetail: any, key: any) => {
    const notAllowedToDisplay = currentLegacyDetail[0] === 'tableData';
    return (
      <ListItem key={key}>
        <Typography style={{ paddingRight: 10, fontWeight: 'bold' }}>
          {notAllowedToDisplay ? (
            <div />
          ) : (
            Capitalize(currentLegacyDetail[0] + ': ')
          )}
        </Typography>
        <Typography>
          {notAllowedToDisplay ? (
            <div />
          ) : currentLegacyDetail[1] !== null ? (
            currentLegacyDetail[1]
          ) : (
            'No Data'
          )}
        </Typography>
      </ListItem>
    );
  });

export const LegacyDocumentListData = ({
  GrabbingDocumentData,
  documentList
}: {
  GrabbingDocumentData: GrabbingDocumentData;
  documentList: LegacyDealDocument[];
}) =>
  Object.values(documentList).map((documentList) => {
    return (
      <ListItem key={documentList.ID}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center">
          <Grid item sm={8}>
            <Typography style={{ fontWeight: 'bold' }}>
              Document Name:
            </Typography>
            <Typography style={{ paddingBottom: 5 }}>
              {Capitalize(documentList['Document Name'])}
            </Typography>
          </Grid>
          <Grid item sm={8}>
            <Typography style={{ fontWeight: 'bold' }}>LocalPath:</Typography>
            <Typography style={{ paddingBottom: 10 }}>
              {documentList.ID}
            </Typography>
          </Grid>
          <Grid
            item
            sm={6}
            style={{
              fontWeight: 'bold',
              paddingBottom: 20
            }}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                GrabbingDocumentData({
                  id: documentList.ID,
                  name: Capitalize(documentList['Document Name'])
                })
              }>
              Download Document
            </Button>
          </Grid>
        </Grid>
      </ListItem>
    );
  });

export const LegacyActivityData = ({
  activity
}: {
  activity: LegacyDealActivity[];
}) =>
  Object.values(activity).map((activityData: LegacyDealActivity) => {
    return (
      <Typography align="left" key={activityData.ID} style={{ padding: 5 }}>
        {activityData}
      </Typography>
    );
  });
