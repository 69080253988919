/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import { Button, Grid, Link, Tooltip, Typography } from '@material-ui/core';
import { useProcess } from 'hooks';
import { useForm } from 'hooks/useForm';
import CheckIcon from '@material-ui/icons/Check';
import NumberFormat from 'react-number-format';
import { theme } from 'theme';
import {
  CompleteObjectDefinition,
  CompleteObjectInstance,
  FieldDefinition,
  FieldInstance,
  ObjectDefinition,
  UserInstance
} from 'types/interfaces';
import DOMPurify from 'dompurify';
import { green, red } from '@material-ui/core/colors';
import { ContentWrapper } from 'components/Fields/InlineTextField/components/EditableEditor';
import { getFieldInstances } from 'Utils/FieldInstanceChecker';
import isPreviewMode from '../helper/previewMode';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { getProcessById } from 'redux/actions/processes';
import { useIndicativeQuoteSelection } from './hooks';

const IndicativeQuoteSelectionObject = 3613;
const IndicativeQuoteSelect = 22712;
const IndicativeQuoteObjectDefinitionId = 3565;

/**
 * IndicativeQuoteView component for displaying and managing indicative quotes.
 */
const IndicativeQuoteView = () => {
  const history = useHistory();
  const { currentDeal, landingpage } = useProcess();
  const { displayObjects } = useForm();
  const { handleSelectObjectInstance } =
    useIndicativeQuoteSelection(displayObjects);

  const [fieldValue, setFieldValue] = useState<string | undefined>();
  const [previousFieldValue, setPreviousFieldValue] = useState<
    string | undefined
  >();

  const parsed = queryString.parse(history.location.search) as {
    piid: string;
    psdid: string;
  };

  const IndicativeCOI = Object.values(
    currentDeal.CompleteObjectInstanceDict
  ).find(
    (COI: CompleteObjectInstance) =>
      COI.ObjectInstance.ObjectDefinitionId === IndicativeQuoteSelectionObject
  );

  useEffect(() => {
    if (IndicativeCOI) {
      const FieldInstanceList = getFieldInstances(IndicativeCOI);
      const fieldInstance = FieldInstanceList.find(
        (FieldInstance: FieldInstance) =>
          FieldInstance.FieldDefinitionId === IndicativeQuoteSelect
      );

      if (fieldInstance) {
        setFieldValue(fieldInstance.FieldValue);
      }
    }
  }, [currentDeal]);

  useEffect(() => {
    const handleSelection = async () => {
      if (fieldValue !== previousFieldValue) {
        try {
          await getProcessById({
            Id: parseInt(parsed.piid),
            ProcessStepDefinitionId: parseInt(parsed.psdid)
          });
          setPreviousFieldValue(fieldValue);
        } catch (error) {
          console.error('Error fetching process:', error);
        }
      }
    };

    if (landingpage) {
      handleSelection();
    }
  }, [landingpage, fieldValue, previousFieldValue, parsed.piid, parsed.psdid]);

  const isPreview = isPreviewMode(window.location.href, landingpage);
  return (
    <div style={{ width: '100%' }}>
      {Object.values(currentDeal.CompleteObjectInstanceDict)
        .filter(
          (COI: CompleteObjectInstance) =>
            COI.ObjectInstance.ObjectDefinitionId ===
            IndicativeQuoteObjectDefinitionId
        )
        .map((CompleteObjectInstance: CompleteObjectInstance) => {
          const CompleteObjectDefinition = displayObjects.find(
            (COD: CompleteObjectDefinition) =>
              COD.ObjectDefinition.Id === IndicativeQuoteObjectDefinitionId
          );

          if (!CompleteObjectDefinition) return null;

          const FieldInstanceList = getFieldInstances(CompleteObjectInstance);
          const isSelected = CompleteObjectInstance.ObjectInstance.Selected;
          const isDeclined = FieldInstanceList.some((FI: FieldInstance) =>
            FI.FieldValue.includes('true')
          );
          const isPublished = CompleteObjectInstance.ObjectInstance.IsPublished;

          if (!isPublished) return null;

          return (
            <div key={CompleteObjectInstance.ObjectInstance.Id}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                {!isDeclined && !isPreview && (
                  <Grid item>
                    <Tooltip title="Select Indicative Quote">
                      <Button
                        onClick={async () => {
                          const findIndicativeQuoteSelection = Object.values(
                            currentDeal.CompleteObjectInstanceDict
                          ).find(
                            (
                              CompleteObjectInstance: CompleteObjectInstance
                            ) => {
                              return (
                                CompleteObjectInstance.ObjectInstance
                                  .ObjectDefinitionId ===
                                IndicativeQuoteSelectionObject
                              );
                            }
                          );

                          if (CompleteObjectDefinition) {
                            if (!findIndicativeQuoteSelection) {
                              await handleSelectObjectInstance({
                                shouldCreateNew: true,
                                CompleteObjectInstance,
                                CompleteObjectDefinition
                              });
                            } else {
                              await handleSelectObjectInstance({
                                shouldCreateNew: false,
                                CompleteObjectInstance,
                                CompleteObjectDefinition
                              });
                            }
                          }
                        }}
                        style={{
                          color: isSelected
                            ? theme.palette.success.main
                            : 'grey',
                          backgroundColor: isSelected
                            ? theme.palette.success.light
                            : theme.palette.grey[50],
                          border: `${isSelected ? '2px' : '1px'} solid ${
                            isSelected
                              ? theme.palette.success.main
                              : theme.palette.grey[300]
                          }`
                        }}>
                        <CheckIcon
                          fontSize={isSelected ? 'large' : 'default'}
                          style={{ marginRight: 4 }}
                        />
                      </Button>
                    </Tooltip>
                    <div style={{ paddingBottom: theme.spacing(1) }} />
                  </Grid>
                )}
              </Grid>

              <div
                style={{
                  position: 'relative',
                  border: `${isSelected || isDeclined ? '2px' : '1px'} solid ${
                    isSelected
                      ? theme.palette.success.main
                      : isDeclined
                      ? theme.palette.error.main
                      : theme.palette.grey[300]
                  }`,
                  borderRadius: theme.shape.borderRadius,
                  background: isSelected
                    ? theme.palette.success.light
                    : isDeclined
                    ? theme.palette.error.light
                    : theme.palette.grey[50],
                  padding: theme.spacing(3)
                }}>
                {isDeclined ? (
                  <>
                    <div
                      style={{
                        position: 'absolute',
                        top: -22,
                        borderRadius: theme.shape.borderRadius,
                        background: theme.palette.error.light,
                        color: theme.palette.error.main,
                        padding: 10,
                        borderColor: theme.palette.error.main,
                        border: `2px solid ${theme.palette.error.main}`
                      }}>
                      <Typography
                        style={{
                          color: 'white',
                          fontWeight: 'bold'
                        }}>
                        {`Declined Indicative Quote ${CompleteObjectInstance.ObjectInstance.Id} `}
                      </Typography>
                    </div>
                  </>
                ) : (
                  <div>
                    {isSelected ? (
                      <>
                        <div
                          style={{
                            position: 'absolute',
                            top: -22,
                            borderRadius: theme.shape.borderRadius,
                            background: theme.palette.success.light,
                            color: theme.palette.success.main,
                            padding: 10,
                            borderColor: theme.palette.success.main,
                            border: `2px solid ${theme.palette.success.main}`
                          }}>
                          <Typography
                            style={{
                              color: theme.palette.success.main,
                              fontWeight: 'bold'
                            }}>
                            {`Selected Indicative Quote ${CompleteObjectInstance.ObjectInstance.Id} `}
                          </Typography>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          position: 'absolute',
                          border: `1px solid ${theme.palette.grey[300]}`,
                          borderRadius: theme.shape.borderRadius,
                          background: theme.palette.grey[50],
                          top: -22,
                          padding: 10
                        }}>
                        <Typography
                          style={{
                            fontWeight: 'bold'
                          }}>
                          {`Indicative Quote ${CompleteObjectInstance.ObjectInstance.Id} `}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
                <div style={{ paddingBottom: theme.spacing(2) }} />
                {FieldInstanceList.map((FieldInstance: FieldInstance) => {
                  if (CompleteObjectDefinition) {
                    const FieldDefinition =
                      CompleteObjectDefinition.FieldDefinitionDict[
                        FieldInstance.FieldDefinitionId
                      ];

                    const LenderNameId = 22794;
                    const IndicativeQuoteDeclineId = [23525, 23592];
                    if (
                      FieldDefinition.Id === LenderNameId ||
                      IndicativeQuoteDeclineId.includes(FieldDefinition.Id)
                    )
                      return <div />;
                    if (
                      FieldInstance.FieldDefinitionId === FieldDefinition.Id
                    ) {
                      return (
                        <>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              backgroundColor: isSelected
                                ? green[50]
                                : isDeclined
                                ? red[100]
                                : theme.palette.grey[100],
                              padding: 5,
                              borderRadius: theme.shape.borderRadius
                            }}>
                            <Typography style={{ fontWeight: 'bold' }}>
                              {FieldDefinition.Title}
                            </Typography>
                            <FieldValuesTypes
                              FieldDefinition={FieldDefinition}
                              FieldInstance={FieldInstance}
                            />
                          </div>
                          <div style={{ paddingTop: theme.spacing(1) }} />
                        </>
                      );
                    }
                  }
                  return null;
                })}
              </div>
              <div style={{ padding: theme.spacing(2) }} />
            </div>
          );
        })}
    </div>
  );
};

export default IndicativeQuoteView;

/**
 * Renders the appropriate field value based on the field type.
 */
export const FieldValuesTypes: React.FC<{
  FieldDefinition: FieldDefinition;
  FieldInstance: FieldInstance;
}> = ({ FieldDefinition, FieldInstance }) => {
  const getAndGoToFile = (value: string) => {
    fetch(value).then(async (res) => {
      const file = await res.blob();
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    });
  };

  const hasFieldInstanceValue = FieldInstance.FieldValue !== '';

  switch (FieldDefinition.FieldType) {
    case 'Currency':
      return hasFieldInstanceValue ? (
        <NumberFormat
          decimalScale={2}
          displayType={'text'}
          prefix={'£'}
          fixedDecimalScale
          thousandSeparator
          value={FieldInstance.FieldValue}
        />
      ) : (
        <Typography>---</Typography>
      );
    case 'Document':
      return hasFieldInstanceValue ? (
        <Link
          href="#"
          onClick={() => getAndGoToFile(FieldInstance.FieldValue)}
          style={{ color: 'blue' }}>
          {FieldInstance.Title}
        </Link>
      ) : (
        <Typography>No File</Typography>
      );
    case 'Formatted Text': {
      let sanitizedHTML = DOMPurify.sanitize(FieldInstance.FieldValue);
      sanitizedHTML = decodeURIComponent(sanitizedHTML);

      return sanitizedHTML === '' ? (
        <Typography>{FieldInstance.FieldValue || '---'}</Typography>
      ) : (
        <ContentWrapper>
          <div
            style={{
              overflowWrap: 'break-word',
              inlineSize: '100%',
              textAlign: 'right'
            }}
            dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          />
        </ContentWrapper>
      );
    }
    case 'Calculation Percentage':
    case 'Percentage':
      return (
        <Typography>
          {hasFieldInstanceValue ? `${FieldInstance.FieldValue}%` : '---'}
        </Typography>
      );
    default:
      return <Typography>{FieldInstance.FieldValue || '---'}</Typography>;
  }
};
