// Here we will move the Rule Prevention & Checklist schemas
import {
  SET_CHECKLIST_SCHEMA,
  UPDATE_CHECKLIST_RULE
} from 'redux/actions/types';

export interface IChecklistRules {
  [key: string]: {
    isMandatory: boolean;
    regulationType: 'Regulated' | 'Non-Regulated' | 'Both';
  };
}

interface SET_CHECKLIST_SCHEMA {
  type: typeof SET_CHECKLIST_SCHEMA;
  payload: IChecklistRules;
}

interface UPDATE_CHECKLIST_RULE {
  type: typeof UPDATE_CHECKLIST_RULE;
  payload: { dealTypeId: string; ruleId: number; ruleData: IChecklistRules };
}

const INITIAL_STATE = {
  checklistRules: {} as IChecklistRules
};

type TAction = SET_CHECKLIST_SCHEMA | UPDATE_CHECKLIST_RULE;
export default (state = INITIAL_STATE, action: TAction) => {
  switch (action.type) {
    case SET_CHECKLIST_SCHEMA:
      return { ...state, checklistRules: action.payload };

    case UPDATE_CHECKLIST_RULE: {
      const { dealTypeId, ruleId, ruleData } = action.payload;
      return {
        ...state,
        checklistRules: {
          ...state.checklistRules,
          [dealTypeId]: {
            ...(state.checklistRules[dealTypeId] || {}),
            [ruleId]: ruleData
          }
        }
      };
    }

    default:
      return state;
  }
};
