import { useContext, useEffect, useState } from 'react';
import { ConnectAccountButton } from 'components/Settings/365Login';
import {
  AuthenticatedTemplate,
  useIsAuthenticated,
  useMsal
} from '@azure/msal-react';
import { CustomDialog } from 'common/Dialog';
import { Grid, Paper } from '@material-ui/core';
import Selection from './components/Selection';

import { MessageHubContext } from './context/MessageHubContext';
import MessageHubProvider from './context/MessageHubContext';
import { useWindowDimensions } from 'helpers/windowDimensions';
import { theme } from 'theme';

import { ParentComponentComposeWindows } from './components/ComposeWindows';
import HoverCardProvider from './context/HoverCardContext';
import ConversationProvider from './components/Conversations/context/ConversationContext';
import Conversations from './components/Conversations';
import { EmailCompositionCreationProvider } from './components/ComposeWindows/context/ComposeWindowsContext';
import ThreadProvider, {
  ThreadContext
} from './components/Threads/context/ThreadsContext';
import SelectionProvider from './components/Selection/context/SelectionContext';
import ParentThread from './components/Threads';
import { useToken } from './hooks/useToken';

const MessageProvider = () => {
  return (
    <MessageHubProvider>
      <EmailCompositionCreationProvider>
        <HoverCardProvider>
          <ThreadProvider>
            <SelectionProvider>
              <ConversationProvider>
                <MessageHub />
              </ConversationProvider>
            </SelectionProvider>
          </ThreadProvider>
        </HoverCardProvider>
      </EmailCompositionCreationProvider>
    </MessageHubProvider>
  );
};

const MessageHub = () => {
  const { instance, inProgress, accounts } = useMsal();
  const isAuth = useIsAuthenticated();

  const { height } = useWindowDimensions();
  const { setToken, fullScreen } = useContext(MessageHubContext);
  const { conversations, customFolderConversations, archivedConversations } =
    useContext(ThreadContext);

  const [open, setOpen] = useState<boolean>(false);
  const [hasMessage, setHasMessage] = useState<boolean>(false);

  const accessToken = async () => {
    const accessToken = await useToken({
      isAuth,
      accounts,
      instance,
      inProgress
    });
    setToken(accessToken);
  };

  useEffect(() => {
    accessToken();
  }, [isAuth]);

  useEffect(() => {
    if (accounts.length <= 0) setOpen(true);
    else setOpen(false);
  }, [accounts]);

  return (
    <>
      <CustomDialog
        maxSize="sm"
        open={open}
        handleClose={() => setOpen(false)}
        alert={{
          title:
            inProgress === 'login'
              ? 'Connecting Your Microsoft Office 365 Account'
              : 'Connect Your Microsoft Office 365 Account',
          description:
            inProgress === 'login'
              ? 'Your Office 365 account is currently being connected. After login, your inbox will be accessible via the plugin.'
              : `To access your inbox with the Office 365 plugin, first connect your account. Without this, the plugin can't function.`,
          type: inProgress === 'login' ? 'info' : 'warning'
        }}>
        <ConnectAccountButton />
      </CustomDialog>

      <AuthenticatedTemplate>
        <ParentComponentComposeWindows />
        <Grid
          container
          spacing={1}
          style={{ padding: theme.spacing(1.5), height: '100%' }}>
          <Grid item xs={hasMessage ? 1 : 3}>
            <Paper
              style={{
                backgroundColor: '#fbfbfa',
                height: '100%'
              }}>
              <Selection
                setHasMessage={setHasMessage}
                conversations={conversations}
              />
            </Paper>
          </Grid>

          {fullScreen ? (
            <div />
          ) : (
            <Grid
              item
              xs={hasMessage ? 4 : 9}
              style={
                Object.values(customFolderConversations).length >= 1 ||
                Object.values(conversations).length >= 1 ||
                Object.values(archivedConversations).length >= 1
                  ? {
                      marginTop: theme.spacing(1.5)
                    }
                  : {}
              }>
              <Paper style={{ backgroundColor: '#fbfbfa' }}>
                <ParentThread />
              </Paper>
            </Grid>
          )}
          {hasMessage ? (
            <Grid item xs={fullScreen ? 11 : 7}>
              <Conversations setHasMessage={setHasMessage} />
            </Grid>
          ) : (
            <Conversations setHasMessage={setHasMessage} />
          )}
        </Grid>
      </AuthenticatedTemplate>
    </>
  );
};

export default MessageProvider;
