import { useRef, useLayoutEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4plugins_forceDirected from '@amcharts/amcharts4/plugins/forceDirected';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { store } from 'redux/store';

am4core.useTheme(am4themes_animated);

const App = ({ company, setOpen, setChildMeta }) => {
  const chart: any = useRef(null);

  const doIt = () => {
    const companyNavigator = store.getState().config.companyNavigator;
    const isGQL = companyNavigator === 'RedflagGRaphQL';

    // Top Level Variable Data
    let ultimate_parent = company?.group_companies?.ultimate_parent;
    let ultimate_parent_name = ultimate_parent?.attributes?.name;
    let ultimate_parent_value =
      company?.attributes?.rfa_rating?.trading_payment_parity || 0;
    let ultimate_parent_id = ultimate_parent?.id;

    let descendant_companies = company?.group_companies?.descendant_companies;

    if (isGQL) {
      interface gplItem {
        attributes: { level: number };
      }

      const corporate_structure = company?.corporate_structure;

      // Ultimate Parent
      ultimate_parent = corporate_structure?.find(
        (item: gplItem) => item?.attributes?.level === 0
      );

      const turnover = ultimate_parent?.attributes?.company?.turnover;
      const estimated_turnover =
        ultimate_parent?.attributes?.company?.estimated_turnover;

      ultimate_parent_value = estimated_turnover
        ? estimated_turnover
        : turnover;
      ultimate_parent_name = ultimate_parent?.attributes?.company_name;
      ultimate_parent_id = ultimate_parent?.attributes?.id;

      // Descendent Companies
      descendant_companies = corporate_structure?.filter(
        (item: gplItem) => item?.attributes?.level !== 0
      );
    }

    // END Top Level Variable Data

    let x = am4core.create(
      'chartdiv',
      am4plugins_forceDirected.ForceDirectedTree
    );
    let networkSeries = x.series.push(
      new am4plugins_forceDirected.ForceDirectedSeries()
    );

    x.zoomable = true;
    // x.mouseWheelBehavior = 'none';

    // 1 Set the Ultimate Parent Company
    if (ultimate_parent) {
      x.data = [
        {
          name: ultimate_parent_name,
          id: ultimate_parent?.attributes?.company_number,
          value: ultimate_parent_value,
          fixed: true
        }
      ];

      descendant_companies?.forEach((el) => {
        el.parent_company = el.attributes.parent_company_number;
      });

      const selectedName = company?.data?.attributes?.name;
      const selectedId = company?.data?.id;

      const self = {
        name: selectedName,
        id: selectedId,
        value: ultimate_parent_value
      };

      let structure = descendant_companies
        ? nest(descendant_companies, ultimate_parent_id, companyNavigator)
        : self;

      x.data[0].children = structure;
    }

    console.log({
      descendant_companies,
      ultimate_parent,
      data: x.data,
      networkSeries
    });

    networkSeries.dataFields.value = 'value';
    networkSeries.dataFields.name = 'name';
    networkSeries.dataFields.id = 'id';
    networkSeries.dataFields.children = 'children';

    networkSeries.nodes.template.tooltipText =
      '{name} \n Turnover: {value} \n Company Number: {id}';
    networkSeries.nodes.template.fillOpacity = 1;
    networkSeries.nodes.template.togglable = false;

    networkSeries.nodes.template.label.text = '{name}';
    networkSeries.fontSize = 8;

    networkSeries.links.template.strokeWidth = 1;

    let hoverState = networkSeries.links.template.states.create('hover');
    hoverState.properties.strokeWidth = 3;
    hoverState.properties.strokeOpacity = 1;

    networkSeries.nodes.template.events.on('over', function (event) {
      event.target.dataItem.childLinks.each(function (link) {
        link.isHover = true;
      });
      if (event.target.dataItem.parentLink) {
        event.target.dataItem.parentLink.isHover = true;
      }
    });

    networkSeries.nodes.template.events.on('out', function (event) {
      event.target.dataItem.childLinks.each(function (link) {
        link.isHover = false;
      });
      if (event.target.dataItem.parentLink) {
        event.target.dataItem.parentLink.isHover = false;
      }
    });

    var hl = networkSeries.nodes.template.states.create('selected');
    hl.properties.fill = am4core.color('#c55');

    var selectedNode;
    networkSeries.nodes.template.events.on('hit', function (event) {
      if (selectedNode == event.target) {
        selectedNode.fill = selectedNode.defaultState.properties.fill;
        selectedNode = undefined;
      } else {
        if (selectedNode) {
          selectedNode.fill = selectedNode.defaultState.properties.fill;
        }
        selectedNode = event.target;
        selectedNode.setState('selected');

        setChildMeta({
          name: selectedNode.dataItem.name,
          id: selectedNode.dataItem.id
        });

        setOpen(true);
      }
    });

    chart.current = x;
  };

  useLayoutEffect(() => {
    doIt();
  }, []);

  useLayoutEffect(() => {
    doIt();
  }, [company]);

  return <div id="chartdiv" style={{ width: '100%', height: '400px' }} />;
};
export default App;

const nest = (data, parentId = null, companyNavigator) =>
  data.reduce((r, e) => {
    const isGQL = companyNavigator === 'RedflagGRaphQL';
    let obj = Object.assign({}, e);
    let parent_comp_id = e.parent_company;
    let desc_value =
      obj?.attributes?.rfa_rating?.trading_payment_parity || 'No Data';
    let desc_name = obj.attributes.name;

    if (isGQL) {
      desc_value = obj.attributes?.company?.turnover;
      desc_name = obj.attributes?.company_name;
      parent_comp_id = e?.attributes?.parent;
    }

    obj.name = desc_name;
    obj.value = desc_value;
    obj.color = 'green';

    // If its a child of the parent
    if (parentId === parent_comp_id) {
      // get a reduced nested list of the children
      let currentId = isGQL ? e.attributes.id : e.id;
      let children = nest(data, currentId, companyNavigator);
      console.log({ parentId, parent_comp_id, e, children });
      if (children.length) obj.children = children;
      r.push(obj);
    }
    return r;
  }, []);
