import { OffkeyDirectorLite, IStringQuery, INumberQuery } from '../interfaces';
import { IShareholder } from 'types/redflagInterfaces';

export interface IRedFlagDirectorship {
  data: IRedFlagDirectorshipData;
}

export interface IRedflagDirectorArray {
  data: IRedFlagDirectorshipData[];
}

export interface IRedFlagDirectorshipData {
  relationships: IRedFlagDirectorshipRelationships;
  attributes: OffkeyDirectorLite;
  type: string;
  id: string;
  links: IRedFlagDirectorshipLinks;
}

interface IRedFlagDirectorshipRelationships {
  director: IRedFlagDirectorshipRelationshipLink;
  company: IRedFlagDirectorshipRelationshipLink;
}

interface IRedFlagDirectorshipRelationshipLink {
  links: {
    related: string;
  };
}

interface IRedFlagDirectorshipLinks {
  self: string;
}

export const createJsonFromDirector = (
  director: OffkeyDirectorLite | OffkeyDirectorLite[]
): IRedFlagDirectorship | IRedflagDirectorArray => {
  if (Array.isArray(director)) {
    const dataList = director.map((dir) => createOGRedflagData(dir));
    return { data: dataList };
  } else {
    const singleData = createOGRedflagData(director);
    return { data: singleData };
  }
};

export const createOGRedflagData = (
  director: OffkeyDirectorLite,
  type?: string
): IRedFlagDirectorshipData => {
  return {
    relationships: {
      director: {
        links: {
          related: `https://api2.redflagalert.net/v4/directors/${director.person_id}/`
        }
      },
      company: {
        links: {
          related: `https://api2.redflagalert.net/v4/companies/${director.company_id}/`
        }
      }
    },
    attributes: {
      ...director
    },
    type: type ? type : 'directorship',
    id: director.id,
    links: {
      self: director.source
    }
  };
};

export const getValue = (
  fieldValue:
    | IStringQuery
    | INumberQuery
    | string
    | number
    | boolean
    | undefined
): string => {
  if (typeof fieldValue === 'string' || typeof fieldValue === 'number') {
    return fieldValue.toString();
  } else if (fieldValue && typeof fieldValue === 'object') {
    return fieldValue.eq ? fieldValue.eq.toString() : '';
  }

  return '';
};

export const mapShareholders = (report): IShareholder[] => {
  const shareholdersData =
    report?.third_party_apis?.redflagOG?.shareholders || [];
  const totalShares =
    shareholdersData.reduce(
      (sum: number, shareholder: IShareholder) =>
        sum + shareholder.attributes.share_quantity,
      0
    ) || 1;

  const shareholders: IShareholder[] = shareholdersData.map(
    (shareholder: IShareholder) => ({
      id: shareholder.id,
      type: shareholder.type,
      attributes: {
        first_name: shareholder?.attributes?.first_name,
        surname: shareholder?.attributes?.surname,
        class_percentage: (
          (shareholder?.attributes?.share_quantity / totalShares) *
          100
        ).toFixed(2),
        shareholder_class: shareholder?.attributes?.shareholder_class,
        share_quantity: shareholder?.attributes?.share_quantity,
        share_value: shareholder?.attributes?.share_value
      }
    })
  );

  return shareholders;
};
