import { useEffect, useRef, useState } from 'react';
import { convertNodeToElement } from 'react-html-parser';
import ReactDOM from 'react-dom';
import {
  Attachment,
  FileAttachment,
  ItemBody,
  NullableOption
} from '@microsoft/microsoft-graph-types';
import { UserInstance } from 'types/interfaces';
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import { theme } from 'theme';
import UploadingFiles from 'components/MessageHub/utils/Files/UploadingFiles';

import {
  AttachFile,
  InsertDriveFile,
  MoreVert,
  Note,
  Slideshow,
  TableChart
} from '@material-ui/icons';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@material-ui/icons/Description';
import * as DOMPurify from 'dompurify';
import { store } from 'redux/store';
import { Provider } from 'react-redux';

export const Body = ({
  body,
  attachments,
  user
}: {
  body: NullableOption<ItemBody> | undefined;
  attachments: FileAttachment[] | NullableOption<Attachment[]>;
  user: UserInstance;
}) => {
  const shadowHostRef: any = useRef(null);
  useEffect(() => {
    if (shadowHostRef.current && !shadowHostRef.current.shadowRoot) {
      const shadowRoot = shadowHostRef.current.attachShadow({ mode: 'open' });

      const inlineImagesContainer = document.createElement('div');
      shadowRoot.appendChild(inlineImagesContainer);

      ReactDOM.render(
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <InlineImages attachments={attachments} user={user} />
          </ThemeProvider>
        </Provider>,
        inlineImagesContainer
      );

      if (body && 'content' in body) {
        const { contentType, content } = body;
        const createCidMapping = (attachments) => {
          const cidMap = {};
          attachments.forEach((attachment) => {
            cidMap[attachment.contentId] = attachment.contentBytes;
          });
          return cidMap;
        };

        const style = document.createElement('style');
        style.textContent = `
      .content-container img {
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    `;
        shadowRoot.appendChild(style);

        const cidMap = createCidMapping(attachments);
        let containerForContent = document.createElement('div');
        containerForContent.className = 'content-container';

        switch (contentType) {
          case 'html': {
            let sanitizedHTML = DOMPurify.sanitize(content);

            Object.keys(cidMap).forEach((cid) => {
              if (cidMap[cid].startsWith('iVBOR')) {
                cidMap[cid] = `data:image/png;base64,${cidMap[cid]}`;
              } else if (cidMap[cid].startsWith('/9j/')) {
                cidMap[cid] = `data:image/jpeg;base64,${cidMap[cid]}`;
              }
              sanitizedHTML = sanitizedHTML.replace(
                new RegExp(`cid:${cid}`, 'g'),
                cidMap[cid]
              );
            });

            containerForContent.innerHTML = `<div>${sanitizedHTML}</div>`;
            break;
          }
          default:
            containerForContent.innerHTML = content ?? '';
            break;
        }

        shadowRoot.appendChild(containerForContent);
        const links = containerForContent.querySelectorAll('a');
        links.forEach((link) => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener');
        });
      }
    }
  }, [body, attachments, user]);

  return <div ref={shadowHostRef} />;
};

const InlineImages = ({
  attachments,
  user
}: {
  attachments: FileAttachment[] | NullableOption<Attachment[]>;
  user: UserInstance;
}) => {
  const [isUploadingFilesOpen, setUploadingFilesOpen] =
    useState<boolean>(false);
  const [currentAttachment, setCurrentAttachment] = useState<FileAttachment>();
  const [hoverStates, setHoverStates] = useState<object>({});

  const handleAttachmentClick = (event, attachment) => {
    if (hoverStates[attachment.id]) {
      event?.stopPropagation();
      event?.preventDefault();

      setCurrentAttachment(attachment);
      setUploadingFilesOpen(hoverStates[attachment.id]);
      setHoverStates((prevState) => ({
        ...prevState,
        [attachment.id.toString()]: false
      }));
    }
  };

  const handleClose = () => {
    setUploadingFilesOpen(false);
  };

  const images: JSX.Element[] = [];
  const documents: JSX.Element[] = [];

  attachments?.forEach((attachment: FileAttachment, index: number) => {
    const isImage = attachment.contentType?.startsWith('image');
    const isInline = attachment.isInline;

    const elementID = (attachment && attachment.id) || '';
    const fileElement = (
      <div
        key={index}
        style={{
          display: 'inline-block',
          padding: '5px',
          width: isImage ? '140px' : 'auto'
        }}
        onClick={(e) => handleAttachmentClick(e, attachment)}
        onMouseEnter={() => {
          setHoverStates((prevState) => ({
            ...prevState,
            [elementID.toString()]: true
          }));
        }}
        onMouseLeave={() => {
          setHoverStates((prevState) => ({
            ...prevState,
            [elementID.toString()]: false
          }));
        }}>
        <FileRenderer fileData={attachment} hoverStates={hoverStates} />
      </div>
    );

    const documentElement = (
      <div
        key={index}
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingLeft: theme.spacing(1),
          paddingRight: theme.spacing(1),
          marginBottom: theme.spacing(2),
          marginTop: theme.spacing(1),
          backgroundColor: '#f3f3f3',
          borderRadius: theme.shape.borderRadius,
          boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',

          width: 'fit-content',
          cursor: 'pointer'
        }}
        onClick={(e) => handleAttachmentClick(e, attachment)}
        onMouseEnter={() => {
          setHoverStates((prevState) => ({
            ...prevState,
            [elementID.toString()]: true
          }));
        }}
        onMouseLeave={() => {
          setHoverStates((prevState) => ({
            ...prevState,
            [elementID.toString()]: false
          }));
        }}>
        <DocumentRenderer fileData={attachment} />
      </div>
    );

    if (isImage && !isInline) {
      images.push(fileElement);
    } else if (!isInline) {
      documents.push(documentElement);
    }
  });

  if (isUploadingFilesOpen && currentAttachment) {
    return (
      <UploadingFiles
        isOpen={isUploadingFilesOpen}
        user={user}
        attachment={currentAttachment}
        handleClose={handleClose}
      />
    );
  }

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>{images}</div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>{documents}</div>
    </div>
  );
};

const DocumentRenderer = ({ fileData }: { fileData: any }) => {
  const { '@odata.mediaContentType': mediaContentType, name, size } = fileData;

  if (!fileData || !name) return <div />;
  let icon;

  switch (true) {
    case name.endsWith('.docx'):
    case name.endsWith('.doc'):
      icon = <DescriptionIcon />;
      break;
    case name.endsWith('.pdf'):
      icon = <PictureAsPdfIcon />;
      break;
    case name.endsWith('.xlsx'):
    case name.endsWith('.xls'):
      icon = <TableChart />;
      break;
    case name.endsWith('.pptx'):
    case name.endsWith('.ppt'):
      icon = <Slideshow />;
      break;
    case name.endsWith('.txt'):
      icon = <Note />;
      break;
    default:
      icon = <InsertDriveFile />;
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ height: 25, width: 25, paddingRight: theme.spacing(1) }}>
          {icon}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <Typography
            variant="body2"
            style={{ fontWeight: 'bold', marginBottom: '1px' }}>
            {name}
          </Typography>
          <Typography variant="caption">
            {(size / 1024).toFixed(2)} KB
          </Typography>
        </div>
      </div>
    </>
  );
};

const FileRenderer = ({
  fileData,
  hoverStates
}: {
  fileData: any;
  hoverStates: object;
}) => {
  const {
    '@odata.mediaContentType': mediaContentType,
    contentBytes,
    name,
    size
  } = fileData;

  if (fileData) {
    let icon: JSX.Element = <AttachFile />;
    if (!name) return <div />;
    if (name.endsWith('.docx') || name.endsWith('.doc')) {
      icon = <DescriptionIcon />;
    } else if (name.endsWith('.pdf')) {
      icon = <PictureAsPdfIcon />;
    }

    if (mediaContentType === undefined) return <div />;
    if (mediaContentType.startsWith('message')) return <div />;
    if (mediaContentType.startsWith('image')) {
      const src = `data:${mediaContentType};base64,${contentBytes}`;

      return (
        <div
          style={{
            position: 'relative',
            width: '140px',
            height: '140px',
            borderRadius: theme.shape.borderRadius,
            overflow: 'hidden',
            margin: 'auto',
            border: '1px solid #555'
          }}>
          <img
            alt={name}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={src}
          />
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              padding: '5px',
              background: 'rgba(0, 0, 0, 0.25)',
              color: 'white',
              display: hoverStates[fileData.id] ? 'flex' : 'none',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexDirection: 'column',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}>
            <div
              style={{
                fontWeight: 'bold',
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {name}
            </div>
            <div>{(size / 1024).toFixed(2)} KB</div>
          </div>
        </div>
      );
    } else if (mediaContentType.startsWith('application')) {
      const href = `data:${mediaContentType};base64,${contentBytes}`;
      return (
        <Button
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            borderRadius: theme.shape.borderRadius,
            border: '1px solid #555'
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '120px',
              height: '130px'
            }}>
            {icon}
          </div>

          <div style={{ marginRight: '8px', width: '120px' }}>
            <a
              href={href}
              download={name}
              style={{ textDecoration: 'none', color: 'inherit' }}>
              {name}
            </a>
          </div>

          <Typography variant="caption">
            {(size / 1024).toFixed(2)} KB
          </Typography>
        </Button>
      );
    }
  }
  return <Typography>Unknown file type</Typography>;
};
