export const aldermoreAssets = {
  Agriculture: {
    types: [
      'Tractor',
      'Solar Panels',
      'Wind Turbines',
      'Anaerobic Digestion',
      'Combine Harvester',
      'Forager',
      'Ploughs',
      'Balers',
      'Harvester For Forestry',
      'Grounds Maintenance',
      'Forwarder',
      'Cattle'
    ],
    manufacturers: [
      '01-Other',
      'AGCO',
      'CASE',
      'CAT',
      'FORD',
      'FRONTIER',
      'JOHN DEERE',
      'KUBOTA',
      'MASSEY FERGUSON',
      'NEW HOLLAND',
      'WESTENDORF',
      'WESTFIELD',
      'WILLMAR'
    ]
  },
  'Broadcast, Television and Sound': {
    types: [
      'Lighting',
      'Audio/Broadcast',
      'Amplifier',
      'Cam Corder/Video Recording',
      'Loudspeaker',
      'Microphone',
      'Mixing Console',
      'Plasma Display Panel',
      'Post Production'
    ],
    manufacturers: {
      Lighting: ['01-Other', 'ARRI', 'CANON', 'JVC', 'PANASONIC', 'SONY'],
      'Audio/Broadcast': [
        '01-Other',
        'ARRI',
        'CANON',
        'JVC',
        'PANASONIC',
        'SONY'
      ],
      Amplifier: ['01-Other', 'ARRI', 'CANON', 'JVC', 'PANASONIC', 'SONY'],
      'Cam Corder/Video Recording': [
        '01-Other',
        'ARRI',
        'CANON',
        'JVC',
        'PANASONIC',
        'SONY'
      ],
      Loudspeaker: ['01-Other', 'ARRI', 'CANON', 'JVC', 'PANASONIC', 'SONY'],
      Microphone: ['01-Other', 'ARRI', 'CANON', 'JVC', 'PANASONIC', 'SONY'],
      'Mixing Console': [
        '01-Other',
        'ARRI',
        'CANON',
        'JVC',
        'PANASONIC',
        'SONY'
      ],
      'Plasma Display Panel': [
        '01-Other',
        'ARRI',
        'CANON',
        'JVC',
        'PANASONIC',
        'SONY'
      ],
      'Post Production': [
        '01-Other',
        'ARRI',
        'CANON',
        'JVC',
        'PANASONIC',
        'SONY'
      ]
    }
  },
  Cars: {
    types: [
      'Funeral Vehicles',
      'Cars',
      'Hackney Cabs',
      'Back to Back funding of cars',
      'Passenger car contract hire',
      'Chauffeur Hire Cars',
      'Mini Cabs',
      'Private Hire Taxis'
    ],
    manufacturers: [
      '01-Other',
      'ABARTH',
      'AIXAM',
      'ALFA ROMEO',
      'ASTON MARTIN',
      'AUDI',
      'BENTLEY',
      'BMW',
      'CADILLAC',
      'CHEVROLET',
      'CHRYSLER',
      'CITROEN',
      'CORVETTE',
      'DAEWOO',
      'DAIHATSU',
      'DAIMLER',
      'DODGE',
      'FERRARI',
      'FIAT',
      'FORD',
      'HONDA',
      'HUMMER',
      'HYUNDAI',
      'INFINITI',
      'ISUZU',
      'JAGUAR',
      'JEEP',
      'KIA',
      'KTM',
      'LAMBORGHINI',
      'LAND ROVER',
      'LEXUS',
      'LOTUS',
      'MASERATI',
      'MAYBACH',
      'MAZDA',
      'MERCEDES BENZ',
      'MG',
      'MICROCAR',
      'MINI',
      'MITSUBISHI',
      'MORGAN',
      'NISSAN',
      'PERODUA',
      'PEUGEOT',
      'PORSCHE',
      'PROTON',
      'RANGE ROVER',
      'RENAULT',
      'ROLLS-ROYCE',
      'ROVER',
      'SAAB',
      'SEAT',
      'SKODA',
      'SMART',
      'SSANGYONG',
      'SUBARU',
      'SUZUKI',
      'TOYOTA',
      'TVR',
      'VAUXHALL',
      'VAUXHALL OPEL',
      'VOLKSWAGEN',
      'VOLVO'
    ]
  },
  Catering: {
    types: [
      'Refrigeration',
      'Microwaves, Bain Maries',
      'Ice Makers',
      'Dish and Bottle Washer',
      'Cookers',
      'Grills'
    ],
    manufacturers: ['01-Other']
  },
  Transportation: {
    types: [
      'Commercial Vans',
      'Sweeper Trucks',
      'Trailer Box',
      'Trailer Platform',
      'Truck Refrigerated',
      'Truck Box',
      'Tractor Unit',
      'Truck Curtain Side',
      'Truck Tipper',
      'Truck Platform',
      'Jet Vacuum tanker',
      'Bus and Coach',
      'Tanker',
      'Minibus',
      'Distribution Tractor',
      'Bus',
      'Midi-coach',
      'Recovery Truck',
      'Trailer Skeletal',
      'Trailer Curtain Side',
      'Trailer Refrigerated',
      'Trailer Container',
      'Trailer Tipper',
      'Gully Emptier',
      'Garbage and Refuse'
    ],
    manufacturers: [
      '01-Other',
      'AIXAM',
      'ALEXANDER DENNIS',
      'BMC',
      'BOVA',
      'CARTWRIGHT',
      'CLAYTON',
      'CRANE FRUEHAUF',
      'CRAVEN TASKER',
      'DAEWOO',
      'DAF',
      'DAIHATSU',
      'DART',
      'DENNIS',
      'DENNISON',
      'DIAHATSU',
      'DON BUR',
      'ERF',
      'FIAT',
      'FODEN',
      'FORD',
      'FRUEHAUF',
      'GRAY AND ADAMS',
      'HINO',
      'HYUNDAI',
      'IKARUS',
      'ISUZU',
      'IVECO',
      'JEEP',
      'KASSBOHRER',
      'KEL-BERG',
      'KOGEL',
      'KRONE',
      'LAND ROVER',
      'LAURENCE DAVID',
      'LDV',
      'LEYLAND',
      'MAN',
      'MARCO POLO',
      'MAZDA',
      'MCW METROBUS',
      'MERCEDES',
      'MERCEDES BENZ',
      'MG',
      'MITSUBISHI',
      'MONTRACON',
      'NEOPLAN',
      'NISSAN',
      'NORTHERN COUNTIES',
      'OPTARE',
      'PEUGEOT',
      'PIAGGIO',
      'PROTON',
      'RENAULT',
      'ROTHDEAN',
      'ROVER',
      'SCANIA',
      'SCHMITZ',
      'SDC',
      'SEAT',
      'SEDDON ATKINSON',
      'SETRA',
      'SKODA',
      'SPITZER',
      'SSANGYONG',
      'SUZUKI',
      'TATA',
      'THOMPSON',
      'TOYOTA',
      'UTILITY',
      'VAN HOOL',
      'VAUXHALL',
      'VDL',
      'VOLKSWAGEN',
      'VOLVO',
      'W TRAILER COMPANY',
      'WEIGHTLIFTER',
      'WHALE',
      'WILCOX',
      'WILSON',
      'WRIGHTS'
    ]
  },
  'Construction and Mining': {
    types: [
      'Excavator-Wheeled',
      'Cranes (Mobile)',
      'PortaCabins',
      'Convenience Blocks / Vehicles',
      'Concrete Mixer',
      'Drilling',
      'Graders',
      'Jackhammer',
      'Motor Grader',
      'Scraper',
      'Skid Steerloader',
      'Excavator-Tracked',
      'Site Dumpers',
      'Shovel and Wheel Loader',
      'Bowsers',
      'Backhoe Loader',
      'Telescopic Handlers',
      'Crusher and Screener',
      'Compressors',
      'Pavers',
      'Planers',
      'Excavator-Other',
      'Cranes',
      'Scaffolding',
      'Tower Cranes',
      'Material handler',
      'Re-Handler',
      'Units',
      'Excavator-Mini',
      'Loader-Wheeled',
      'Loader-Tracked',
      'Artic. Dump Trucks',
      'Bulldozer',
      'Compactors and Roller Compactor'
    ],
    manufacturers: [
      '01-Other',
      'AGRIA',
      'AMERICAN CRANE',
      'ATLAS',
      'AUSA',
      'BKT',
      'BOBCAT',
      'BOMAG',
      'BRONTO',
      'CASE',
      'CAT',
      'COMANSA',
      'DAEWOO',
      'DEMAG',
      'DOOSAN',
      'DYNAPAC',
      'FERMEC',
      'GROVE',
      'HAMM',
      'HITACHI',
      'HYDREMA',
      'HYUNDAI',
      'IHI',
      'JCB',
      'JONES',
      'KATO',
      'KOBELCO',
      'KOMATSU',
      'KROLL',
      'KUBOTA',
      'LIEBHERR',
      'MANITOU',
      'MANITOWOC',
      'MERLO',
      'MOXY',
      'NCK',
      'O AND K',
      'PALFINGER',
      'PEINER',
      'POTAIN',
      'PPM',
      'SANDVIK',
      'SUMITOMO',
      'TADANO',
      'TADANO FAUN',
      'TAKEUCHI',
      'TEREX',
      'THWAITES',
      'VIBROMAX',
      'YANMAR'
    ]
  },
  Vending: {
    types: [
      'Amusement Machines',
      'Food / snacks',
      'Desk Tops',
      'Can',
      'Water Dispensers',
      'Photo Booths',
      'Kettles'
    ],
    manufacturers: [
      '01-Other',
      'AMG',
      'ASTRA',
      'BARCREST',
      'BELL FRUIT',
      'BRENT LEISURE',
      'CROWN LEISURE T/A CROWN DIRECT',
      'ELECTROCOIN',
      'EMPIRE GAMES',
      'GAMES WAREHOUSE',
      'HARRY LEVY',
      'LAI',
      'MEDIATHEME',
      'NORTHERN LEISURE',
      'NSM',
      'OM VENDING',
      'PROJECT COIN',
      'REFLEX',
      'RLMS',
      'SEGA',
      'SOUNDLEISURE'
    ]
  },
  Furniture: {
    types: ['Desking', 'Chairs', 'Cabinets'],
    manufacturers: ['01-Other', 'AARON', 'BISLEY', 'HERMAN MILLER']
  },
  'Garage Equipment': {
    types: [
      'MOT Test equipment',
      'Hydraulic Lifts',
      'Compressors',
      'Garage Equipment',
      'Diagnostic Equipment'
    ],
    manufacturers: ['01-Other', 'Crypton', 'GEG']
  },
  IT: {
    types: [
      'PC s',
      'Laptops',
      'Servers',
      'Networks',
      'Software',
      'Printers',
      'Computer Storage Device',
      'Storage',
      'CAD/CAM Equipment'
    ],
    manufacturers: [
      '01-Other',
      'ACER',
      'APPLE',
      'AVAYA',
      'CANON',
      'DELL',
      'EMC',
      'FUJITSU',
      'HEWLETT PACKARD',
      'KODAK',
      'MICROSOFT',
      'SAMSUNG',
      'XENON'
    ]
  },
  'Machine Tool': {
    types: [
      'Pressing',
      'Punch Machine',
      'Saws',
      'Laser Cutting',
      'Threader',
      'Woodworking',
      'Wire Working Machine',
      'Welding Equipment',
      'Lathe',
      'CNC Machining Centre',
      'Robots',
      'Plastic Injection Moulding',
      'Turning Centres',
      'Blades',
      'Consumables',
      'Tooling',
      'Blow Moulding',
      'Extruding',
      'Cutting Machine',
      'Drilling',
      'Eroding Machine',
      'Grinder',
      'Milling Machine'
    ],
    manufacturers: [
      '01-Other',
      'AGIE',
      'AJAX',
      'AMADA',
      'ARBURG',
      'BILLION',
      'BOY',
      'BRIDGEPORT',
      'CHARMILLES',
      'CHIRON',
      'CINCINNATI',
      'CITIZEN',
      'COLCHESTER',
      'CORREA',
      'DECKEL MAHO GUILDERMEISTER',
      'DEMAG',
      'DIAWOO AND PUMA',
      'ECOCCA',
      'EMCO',
      'ENGEL',
      'FADAL',
      'FANUC',
      'FINN - POWER, GUIFIL',
      'HAAS',
      'HARDINGE AND KELLENBERGER',
      'HARRISON',
      'HITACHI SEIKI',
      'HOMAG',
      'HURCO',
      'HYUNDAI',
      'JONES AND SHIPMAN',
      'KRAUS MAFFEI',
      'LEADWELL',
      'LVD SHAPE',
      'MATSURA',
      'MIKRON',
      'MITUTOYO',
      'MIYANO',
      'MORI SEIKI',
      'NEGRIBOSSI',
      'OKUMA',
      'RODER',
      'SANDRETTO',
      'SODICK',
      'STAR',
      'SUMITOMO',
      'TAKISAWA',
      'TRAUB',
      'TRUMPF',
      'VICTOR',
      'VOLLMER',
      'WALTER',
      'XYZ',
      'YAMAZAKI',
      'YIZUMI'
    ]
  },
  'Mailroom Equipment': {
    types: ['Mailing Scales', 'Franking Machines'],
    manufacturers: ['01-Other']
  },
  'Medical Equipment': {
    types: [
      'Laboratory Equipment',
      'Dentistry',
      'Optical Equipment',
      'Endoscopy equipment',
      'Ultrasound equipment',
      'Mammography equipment',
      'Scanning equipment',
      'Veterinary',
      'Angiography',
      'Radiography',
      'Laser'
    ],
    manufacturers: [
      '01-Other',
      'ABBOTT',
      'BRIOT',
      'ESSILOR',
      'HILL-ROM',
      'KEELER',
      'MIDMARK',
      'NIDEX',
      'OHMEDA',
      'OLYPMUS',
      'PHILIPS',
      'RODENSTOCK',
      'STERIS',
      'STRYKER',
      'WELCH',
      'ZEISS'
    ]
  },
  'Materials Handling': {
    types: [
      'Counterbalance Electric',
      'Warehouse Equipment',
      'Batteries',
      'Street/Precinct sweeper',
      'Scissor lift',
      'Pallett Truck',
      'All Terrain Fork Truck',
      'Reach truck Electric',
      'Aerial Platforms',
      'Counterbalance Diesel and Gas',
      'Reach truck Diesel and Gas',
      'Telescopic booms',
      'Container Handler',
      'Sideloaders',
      'Tugs'
    ],
    manufacturers: [
      'HANGCHA',
      'JLG',
      '01-Other',
      'ATLET',
      'AUSA',
      'BT ROLATRUC',
      'CARGOTECH',
      'CATERPILLAR',
      'CESAB',
      'CLARK',
      'COMBILIFT',
      'CROWN',
      'DAEWOO',
      'DOOSAN',
      'FANTUZZI',
      'FIAT',
      'GENIE',
      'GROVE',
      'HAKI',
      'HAKO',
      'HANIX',
      'HAULOTTE',
      'HELI',
      'HYSTER',
      'HYUNDAI',
      'JCB',
      'JUNGHEINRICH',
      'KALMAR',
      'KOMATSU',
      'LINCOLN',
      'LINDE',
      'MANITOU',
      'MITSUBISHI',
      'NARROW AISLE',
      'NEXEN',
      'NIFTYLIFT',
      'NISSAN',
      'NOVA DETUS'
    ]
  },
  Other: {
    types: [
      'Standalone Air Conditioning',
      'Warehouse / Storage Racking',
      'Hospital Beds / Mattresses',
      'Biomass Boilers',
      'Gym Equipment',
      'Soft Assets - Miscellaneous',
      '01-Other'
    ],
    manufacturers: ['01-Other']
  },
  'Food Processing': {
    types: [
      'Cartoning Machine',
      'Loading and Robotics',
      'Cleaning Rinsing and Pasteurising',
      'Conveyer',
      'Filling and Closing Machine',
      'Inserting Machines',
      'Labelling Machine',
      'Packing and Palletising',
      'Forming Machine'
    ],
    manufacturers: ['01-Other', 'ACCUTEK', 'DTM', 'YIZUMI']
  },
  'Industrial Power': {
    types: ['UPS Power Systems', 'CHP Units', 'Generators'],
    manufacturers: [
      '01-Other',
      'ELCOS',
      'EVOPOWER',
      'GENSET',
      'HYUNDAI',
      'MOSA',
      'RIELLO',
      'STEPHILL'
    ]
  },
  'Printing and Paper Processing': {
    types: [
      'Print Processor for Minilab',
      'Postpress',
      'Adhesive Binder',
      'Cutter',
      'Folder',
      'Label System',
      'Newspaper Postpress',
      'Sheetfed Press',
      'Binding Lines',
      'Saddlestitchers',
      'Booklet Makers',
      'Book Binders',
      '3-Knife Trimmers',
      'Casemakers',
      'Book Sewing Machines',
      'Guillotines',
      'Folders',
      'Die-Cutters',
      'Folder Gluers',
      'Die Stamping / Embossing / Foiling Presses',
      'Laminators',
      'Cutting Conversions',
      'Digital Die-Cutters',
      'UV Coating Machines',
      'Digital Printing Press',
      'Direct Imaging',
      'CTP (Digital Pre-Press)',
      'Digital Post Press'
    ],
    manufacturers: ['01-Other']
  },
  Reprographic: {
    types: [
      'Copiers',
      'Colour Copiers',
      'Duplicators',
      'AO Copiers',
      'Fax',
      'Plan printers'
    ],
    manufacturers: ['01-Other']
  },
  Security: {
    types: ['CCTV', 'Alarm Systems', 'Fire Systems', 'Access Control'],
    manufacturers: [
      '01-Other',
      'ADT',
      'DYNO',
      'FOSPAT',
      'GEMTEC',
      'GRANGE',
      'KABA',
      'MRFS',
      'YALE'
    ]
  },
  'Satellite Tracking Equipment': {
    types: ['Tracking', 'Telematics'],
    manufacturers: ['01-Other']
  },
  'Telecoms (Hardware)': {
    types: [
      'Phone Systems',
      'Voice and Data',
      'Datacomms',
      'Hubs',
      'Routers',
      'Video Conferencing',
      'Mobile Phones'
    ],
    manufacturers: [
      '01-Other',
      'ALCATEL-LUCENT',
      'AVAYA',
      'CABLE AND WIRELESS',
      'CISCO',
      'ERICSSON',
      'HP',
      'MARCONI',
      'MITEL',
      'NOKIA',
      'NORTEL',
      'PLANTRONICS',
      'SIEMENS',
      'VERINT'
    ]
  },
  'Textile and Leather Industrial Machinery': {
    types: [
      'Embroidery Machine',
      'Quilting Machine',
      'Sewing Machine',
      'Spinning Frame',
      'Drum Drying Machine',
      'Knitting Machine'
    ],
    manufacturers: [
      '01-Other',
      'BROTHER',
      'COOPER',
      'ELNA',
      'JANOME',
      'SILVER',
      'SINGER',
      'TAJIMA',
      'TOYOTA',
      'WILCOM',
      'YAMATA',
      'ZSK'
    ]
  },
  Waste: {
    types: [
      'Compactors',
      'Balers',
      'Shredding equipment',
      'Recycling equipment'
    ],
    manufacturers: [
      '01-Other',
      'ARJES',
      'DENNIS EAGLE',
      'DOPPSTADT',
      'IFE',
      'KIVERCO',
      'MCCLUSKY',
      'RENTEC',
      'TAURUS'
    ]
  },
  Miscellaneous: {
    types: [
      'Weapons',
      'Garage Diagnostics',
      'Fairground equipment',
      'Chicken / Animal houses',
      'Marine (on or below water)',
      'Aviation (Fixed wing or helicopters)'
    ],
    manufacturers: ['01-Other']
  }
};
