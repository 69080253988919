interface ICountryOption {
  label: string;
  value: string;
}

export const countryOptions: ICountryOption[] = [
  {
    label: 'United Kingdom',
    value: '1'
  },
  {
    label: 'England',
    value: '2'
  },
  {
    label: 'Scotland',
    value: '3'
  },
  {
    label: 'Wales',
    value: '4'
  },
  {
    label: 'Northern Ireland',
    value: '5'
  },
  {
    label: 'Republic of Ireland',
    value: '6'
  },
  {
    label: 'Afghanistan',
    value: '7'
  },
  {
    label: 'Akrotiri',
    value: '8'
  },
  {
    label: 'Albania',
    value: '9'
  },
  {
    label: 'Algeria',
    value: '10'
  },
  {
    label: 'American Samoa',
    value: '11'
  },
  {
    label: 'Andorra',
    value: '12'
  },
  {
    label: 'Angola',
    value: '13'
  },
  {
    label: 'Anguilla',
    value: '14'
  },
  {
    label: 'Antarctica',
    value: '15'
  },
  {
    label: 'Antigua and Barbuda',
    value: '16'
  },
  {
    label: 'Argentina',
    value: '17'
  },
  {
    label: 'Armenia',
    value: '18'
  },
  {
    label: 'Aruba',
    value: '19'
  },
  {
    label: 'Ashmore and Cartier Islands',
    value: '20'
  },
  {
    label: 'Australia',
    value: '21'
  },
  {
    label: 'Austria',
    value: '22'
  },
  {
    label: 'Azerbaijan',
    value: '23'
  },
  {
    label: 'Bahamas, The',
    value: '24'
  },
  {
    label: 'Bahrain',
    value: '25'
  },
  {
    label: 'Bangladesh',
    value: '26'
  },
  {
    label: 'Barbados',
    value: '27'
  },
  {
    label: 'Bassas da India',
    value: '28'
  },
  {
    label: 'Belarus',
    value: '29'
  },
  {
    label: 'Belgium',
    value: '30'
  },
  {
    label: 'Belize',
    value: '31'
  },
  {
    label: 'Benin',
    value: '32'
  },
  {
    label: 'Bermuda',
    value: '33'
  },
  {
    label: 'Bhutan',
    value: '34'
  },
  {
    label: 'Bolivia',
    value: '35'
  },
  {
    label: 'Bosnia and Herzegovina',
    value: '36'
  },
  {
    label: 'Botswana',
    value: '37'
  },
  {
    label: 'Bouvet Island',
    value: '38'
  },
  {
    label: 'Brazil',
    value: '39'
  },
  {
    label: 'British Indian Ocean Territory',
    value: '40'
  },
  {
    label: 'British Virgin Islands',
    value: '41'
  },
  {
    label: 'Brunei',
    value: '42'
  },
  {
    label: 'Bulgaria',
    value: '43'
  },
  {
    label: 'Burkina Faso',
    value: '44'
  },
  {
    label: 'Burma',
    value: '45'
  },
  {
    label: 'Burundi',
    value: '46'
  },
  {
    label: 'Cambodia',
    value: '47'
  },
  {
    label: 'Cameroon',
    value: '48'
  },
  {
    label: 'Canada',
    value: '49'
  },
  {
    label: 'Cape Verde',
    value: '50'
  },
  {
    label: 'Cayman Islands',
    value: '51'
  },
  {
    label: 'Central African Republic',
    value: '52'
  },
  {
    label: 'Chad',
    value: '53'
  },
  {
    label: 'Chile',
    value: '54'
  },
  {
    label: 'China',
    value: '55'
  },
  {
    label: 'Christmas Island',
    value: '56'
  },
  {
    label: 'Clipperton Island',
    value: '57'
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: '58'
  },
  {
    label: 'Colombia',
    value: '59'
  },
  {
    label: 'Comoros',
    value: '60'
  },
  {
    label: 'Congo, Democratic Republic of the',
    value: '61'
  },
  {
    label: 'Congo, Republic of the',
    value: '62'
  },
  {
    label: 'Cook Islands',
    value: '63'
  },
  {
    label: 'Coral Sea Islands',
    value: '64'
  },
  {
    label: 'Costa Rica',
    value: '65'
  },
  {
    label: 'Cote d Ivoire',
    value: '66'
  },
  {
    label: 'Croatia',
    value: '67'
  },
  {
    label: 'Cuba',
    value: '68'
  },
  {
    label: 'Cyprus',
    value: '69'
  },
  {
    label: 'Czech Republic',
    value: '70'
  },
  {
    label: 'Denmark',
    value: '71'
  },
  {
    label: 'Dhekelia',
    value: '72'
  },
  {
    label: 'Djibouti',
    value: '73'
  },
  {
    label: 'Dominica',
    value: '74'
  },
  {
    label: 'Dominican Republic',
    value: '75'
  },
  {
    label: 'Ecuador',
    value: '76'
  },
  {
    label: 'Egypt',
    value: '77'
  },
  {
    label: 'El Salvador',
    value: '78'
  },
  {
    label: 'Equatorial Guinea',
    value: '79'
  },
  {
    label: 'Eritrea',
    value: '80'
  },
  {
    label: 'Estonia',
    value: '81'
  },
  {
    label: 'Ethiopia',
    value: '82'
  },
  {
    label: 'Europa Island',
    value: '83'
  },
  {
    label: 'Falkland Islands (Islas Malvinas)',
    value: '84'
  },
  {
    label: 'Faroe Islands',
    value: '85'
  },
  {
    label: 'Fiji',
    value: '86'
  },
  {
    label: 'Finland',
    value: '87'
  },
  {
    label: 'France',
    value: '88'
  },
  {
    label: 'French Guiana',
    value: '89'
  },
  {
    label: 'French Polynesia',
    value: '90'
  },
  {
    label: 'French Southern and Antarctic Lands',
    value: '91'
  },
  {
    label: 'Gabon',
    value: '92'
  },
  {
    label: 'Gambia, The',
    value: '93'
  },
  {
    label: 'Gaza Strip',
    value: '94'
  },
  {
    label: 'Georgia',
    value: '95'
  },
  {
    label: 'Germany',
    value: '96'
  },
  {
    label: 'Ghana',
    value: '97'
  },
  {
    label: 'Gibraltar',
    value: '98'
  },
  {
    label: 'Glorioso Islands',
    value: '99'
  },
  {
    label: 'Greece',
    value: '100'
  },
  {
    label: 'Greenland',
    value: '101'
  },
  {
    label: 'Grenada',
    value: '102'
  },
  {
    label: 'Guadeloupe',
    value: '103'
  },
  {
    label: 'Guam',
    value: '104'
  },
  {
    label: 'Guatemala',
    value: '105'
  },
  {
    label: 'Guernsey',
    value: '106'
  },
  {
    label: 'Guinea',
    value: '107'
  },
  {
    label: 'Guinea-Bissau',
    value: '108'
  },
  {
    label: 'Guyana',
    value: '109'
  },
  {
    label: 'Haiti',
    value: '110'
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: '111'
  },
  {
    label: 'Holy See (Vatican City)',
    value: '112'
  },
  {
    label: 'Honduras',
    value: '113'
  },
  {
    label: 'Hong Kong',
    value: '114'
  },
  {
    label: 'Hungary',
    value: '115'
  },
  {
    label: 'Iceland',
    value: '116'
  },
  {
    label: 'India',
    value: '117'
  },
  {
    label: 'Indonesia',
    value: '118'
  },
  {
    label: 'Iran',
    value: '119'
  },
  {
    label: 'Iraq',
    value: '120'
  },
  {
    label: 'Isle of Man',
    value: '121'
  },
  {
    label: 'Israel',
    value: '122'
  },
  {
    label: 'Italy',
    value: '123'
  },
  {
    label: 'Jamaica',
    value: '124'
  },
  {
    label: 'Jan Mayen',
    value: '125'
  },
  {
    label: 'Japan',
    value: '126'
  },
  {
    label: 'Jersey',
    value: '127'
  },
  {
    label: 'Jordan',
    value: '128'
  },
  {
    label: 'Juan de Nova Island',
    value: '129'
  },
  {
    label: 'Kazakhstan',
    value: '130'
  },
  {
    label: 'Kenya',
    value: '131'
  },
  {
    label: 'Kiribati',
    value: '132'
  },
  {
    label: 'Korea, North',
    value: '133'
  },
  {
    label: 'Korea, South',
    value: '134'
  },
  {
    label: 'Kuwait',
    value: '135'
  },
  {
    label: 'Kyrgyzstan',
    value: '136'
  },
  {
    label: 'Laos',
    value: '137'
  },
  {
    label: 'Latvia',
    value: '138'
  },
  {
    label: 'Lebanon',
    value: '139'
  },
  {
    label: 'Lesotho',
    value: '140'
  },
  {
    label: 'Liberia',
    value: '141'
  },
  {
    label: 'Libya',
    value: '142'
  },
  {
    label: 'Liechtenstein',
    value: '143'
  },
  {
    label: 'Lithuania',
    value: '144'
  },
  {
    label: 'Luxembourg',
    value: '145'
  },
  {
    label: 'Macau',
    value: '146'
  },
  {
    label: 'Macedonia',
    value: '147'
  },
  {
    label: 'Madagascar',
    value: '148'
  },
  {
    label: 'Malawi',
    value: '149'
  },
  {
    label: 'Malaysia',
    value: '150'
  },
  {
    label: 'Maldives',
    value: '151'
  },
  {
    label: 'Mali',
    value: '152'
  },
  {
    label: 'Malta',
    value: '153'
  },
  {
    label: 'Marshall Islands',
    value: '154'
  },
  {
    label: 'Martinique',
    value: '155'
  },
  {
    label: 'Mauritania',
    value: '156'
  },
  {
    label: 'Mauritius',
    value: '157'
  },
  {
    label: 'Mayotte',
    value: '158'
  },
  {
    label: 'Mexico',
    value: '159'
  },
  {
    label: 'Micronesia, Federated States of',
    value: '160'
  },
  {
    label: 'Moldova',
    value: '161'
  },
  {
    label: 'Monaco',
    value: '162'
  },
  {
    label: 'Mongolia',
    value: '163'
  },
  {
    label: 'Montserrat',
    value: '164'
  },
  {
    label: 'Morocco',
    value: '165'
  },
  {
    label: 'Mozambique',
    value: '166'
  },
  {
    label: 'Namibia',
    value: '167'
  },
  {
    label: 'Nauru',
    value: '168'
  },
  {
    label: 'Navassa Island',
    value: '169'
  },
  {
    label: 'Nepal',
    value: '170'
  },
  {
    label: 'Netherlands',
    value: '171'
  },
  {
    label: 'Netherlands Antilles',
    value: '172'
  },
  {
    label: 'New Caledonia',
    value: '173'
  },
  {
    label: 'New Zealand',
    value: '174'
  },
  {
    label: 'Nicaragua',
    value: '175'
  },
  {
    label: 'Niger',
    value: '176'
  },
  {
    label: 'Nigeria',
    value: '177'
  },
  {
    label: 'Niue',
    value: '178'
  },
  {
    label: 'Norfolk Island',
    value: '179'
  },
  {
    label: 'Northern Mariana Islands',
    value: '180'
  },
  {
    label: 'Norway',
    value: '181'
  },
  {
    label: 'Oman',
    value: '182'
  },
  {
    label: 'Pakistan',
    value: '183'
  },
  {
    label: 'Palau',
    value: '184'
  },
  {
    label: 'Panama',
    value: '185'
  },
  {
    label: 'Papua New Guinea',
    value: '186'
  },
  {
    label: 'Paracel Islands',
    value: '187'
  },
  {
    label: 'Paraguay',
    value: '188'
  },
  {
    label: 'Peru',
    value: '189'
  },
  {
    label: 'Philippines',
    value: '190'
  },
  {
    label: 'Pitcairn Islands',
    value: '191'
  },
  {
    label: 'Poland',
    value: '192'
  },
  {
    label: 'Portugal',
    value: '193'
  },
  {
    label: 'Puerto Rico',
    value: '194'
  },
  {
    label: 'Qatar',
    value: '195'
  },
  {
    label: 'Reunion',
    value: '196'
  },
  {
    label: 'Romania',
    value: '197'
  },
  {
    label: 'Russia',
    value: '198'
  },
  {
    label: 'Rwanda',
    value: '199'
  },
  {
    label: 'Saint Helena',
    value: '200'
  },
  {
    label: 'Saint Kitts and Nevis',
    value: '201'
  },
  {
    label: 'Saint Lucia',
    value: '202'
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: '203'
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: '204'
  },
  {
    label: 'Samoa',
    value: '205'
  },
  {
    label: 'San Marino',
    value: '206'
  },
  {
    label: 'Sao Tome and Principe',
    value: '207'
  },
  {
    label: 'Saudi Arabia',
    value: '208'
  },
  {
    label: 'Senegal',
    value: '209'
  },
  {
    label: 'Serbia and Montenegro',
    value: '210'
  },
  {
    label: 'Seychelles',
    value: '211'
  },
  {
    label: 'Sierra Leone',
    value: '212'
  },
  {
    label: 'Singapore',
    value: '213'
  },
  {
    label: 'Slovakia',
    value: '214'
  },
  {
    label: 'Slovenia',
    value: '215'
  },
  {
    label: 'Solomon Islands',
    value: '216'
  },
  {
    label: 'Somalia',
    value: '217'
  },
  {
    label: 'South Africa',
    value: '218'
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: '219'
  },
  {
    label: 'Spain',
    value: '220'
  },
  {
    label: 'Spratly Islands',
    value: '221'
  },
  {
    label: 'Sri Lanka',
    value: '222'
  },
  {
    label: 'Sudan',
    value: '223'
  },
  {
    label: 'Suriname',
    value: '224'
  },
  {
    label: 'Svalbard',
    value: '225'
  },
  {
    label: 'Swaziland',
    value: '226'
  },
  {
    label: 'Sweden',
    value: '227'
  },
  {
    label: 'Switzerland',
    value: '228'
  },
  {
    label: 'Syria',
    value: '229'
  },
  {
    label: 'Taiwan',
    value: '230'
  },
  {
    label: 'Tajikistan',
    value: '231'
  },
  {
    label: 'Tanzania',
    value: '232'
  },
  {
    label: 'Thailand',
    value: '233'
  },
  {
    label: 'Timor-Leste',
    value: '234'
  },
  {
    label: 'Togo',
    value: '235'
  },
  {
    label: 'Tokelau',
    value: '236'
  },
  {
    label: 'Tonga',
    value: '237'
  },
  {
    label: 'Trinidad and Tobago',
    value: '238'
  },
  {
    label: 'Tromelin Island',
    value: '239'
  },
  {
    label: 'Tunisia',
    value: '240'
  },
  {
    label: 'Turkey',
    value: '241'
  },
  {
    label: 'Turkmenistan',
    value: '242'
  },
  {
    label: 'Turks and Caicos Islands',
    value: '243'
  },
  {
    label: 'Tuvalu',
    value: '244'
  },
  {
    label: 'Uganda',
    value: '245'
  },
  {
    label: 'Ukraine',
    value: '246'
  },
  {
    label: 'United Arab Emirates',
    value: '247'
  },
  {
    label: 'United Kingdom',
    value: '248'
  },
  {
    label: 'United States',
    value: '249'
  },
  {
    label: 'Uruguay',
    value: '250'
  },
  {
    label: 'Uzbekistan',
    value: '251'
  },
  {
    label: 'Vanuatu',
    value: '252'
  },
  {
    label: 'Venezuela',
    value: '253'
  },
  {
    label: 'Vietnam',
    value: '254'
  },
  {
    label: 'Virgin Islands',
    value: '255'
  },
  {
    label: 'Wake Island',
    value: '256'
  },
  {
    label: 'Wallis and Futuna',
    value: '257'
  },
  {
    label: 'West Bank',
    value: '258'
  },
  {
    label: 'Western Sahara',
    value: '259'
  },
  {
    label: 'Yemen',
    value: '260'
  },
  {
    label: 'Zambia',
    value: '261'
  },
  {
    label: 'Zimbabwe',
    value: '262'
  }
];
