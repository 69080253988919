import OutPut from 'components/Calculator/Output';
import Grid from '@material-ui/core/Grid';
import { useProcess } from 'hooks/useProcess';
import {
  ObjectInstance,
  ObjectDefinition,
  FieldDefinitionDict,
  FieldDefinition,
  CompleteObjectInstance,
  CompleteObjectInstanceDict
} from 'types/interfaces';

interface Props {
  ObjectDefinition: ObjectDefinition;
  FieldDefinitionDict: FieldDefinitionDict;
  display: 'clientView' | 'systemView';
  CompleteObjectInstanceList?: CompleteObjectInstance[];
  CompleteObjectInstanceDict?: CompleteObjectInstanceDict;
  isOverview: boolean;
}

const CalculatorObject = (props: Props) => {
  const { processInstanceFields, currentDeal } = useProcess();

  let ObjectInstanceDict = {};
  let FieldInstanceDict = {};

  const InstanceFields = processInstanceFields[currentDeal.ProcessInstance.Id];

  const FieldInstanceList = InstanceFields.filter(
    (item) => item.ObjectDefinitionId === props.ObjectDefinition.Id
  );

  for (const i in FieldInstanceList) {
    const key = FieldInstanceList[i].Id;
    const { ObjectInstanceId, ObjectDefinitionId } = FieldInstanceList[i];

    // gather groups by object Instance Id
    if (!ObjectInstanceDict[ObjectDefinitionId])
      ObjectInstanceDict[ObjectDefinitionId] = {};

    if (!ObjectInstanceDict[ObjectDefinitionId][ObjectInstanceId])
      ObjectInstanceDict[ObjectDefinitionId][ObjectInstanceId] = {};

    if (!ObjectInstanceDict[ObjectDefinitionId][ObjectInstanceId][key])
      ObjectInstanceDict[ObjectDefinitionId][ObjectInstanceId][key] =
        FieldInstanceList[i];

    FieldInstanceDict[key] = FieldInstanceList[i];
  }

  return (
    <Grid container spacing={1}>
      TODO use Other Calculator Object
      {/* {ObjectInstanceDict[props.object.ObjectDefinition.Id] &&
        Object.values(ObjectInstanceDict[props.object.ObjectDefinition.Id]).map(
          (FieldInstanceDict, i) => {
            const ObjectInstance = {
              FieldInstanceDict,
              FieldInstanceList: Object.values(FieldInstanceDict)
            };

            return (
              <Grid item key={i} md={6} xs={12}>
                <OutPut
                  FieldDefinitionDict={props.object.FieldDefinitionDict}
                  FieldDefinitionList={Object.values(
                    props.object.FieldDefinitionDict
                  )}
                  ObjectInstance={ObjectInstance}
                  index={1}
                  isOverview={props.isOverview}
                  readOnly
                />
              </Grid>
            );
          }
        )} */}
    </Grid>
  );
};

export default CalculatorObject;
