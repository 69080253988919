import { AxiosRequestConfig } from 'axios';
import { dbQueue } from '../Interceptor';
/**
 * EMAIL API
 */
export const EmailByProcess = async ({ ProcessInstanceId }) => {
  let url = `EmailAPI.ashx?action=PROCESSDICT&processinstanceid=${ProcessInstanceId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const EmailByUser = async ({ UserInstanceId }) => {
  let url = `emailAPI.ashx?action=LIST&userinstanceid=${UserInstanceId}`;
  let config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const GetCurrentEmail = async ({ Id }) => {
  let url = `EmailAPI.ashx?action=DETAIL&id=${Id}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};

export const ResendLandingpageEmailLink = async ({
  ProcessInstanceId,
  ProcessStepDefinitionId,
  UserInstanceId
}) => {
  const url = `EmailAPI.ashx?action=RESEND&processinstanceid=${ProcessInstanceId}&processstepdefinitionid=${ProcessStepDefinitionId}&userinstanceid=${UserInstanceId}`;
  const config: AxiosRequestConfig = { method: 'get', url };
  return await dbQueue({ config, url });
};
