export default (theme) => ({
  label: { color: theme.palette.primary.contrastText },
  root: {
    // flexGrow: 1,
    // flexDirection: 'row',
    padding: theme.spacing(2)
  },
  form: { width: '' },
  solveContainer: {
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'row',
      height: '100%'
    }
  },
  group: {
    flexGrow: 1,
    // flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    // height: "100%",
    padding: theme.spacing(1),
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      // backgroundColor: "green",
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: theme.spacing(4)
    }
  },
  groupLeft: {
    // flexDirection: 'row',
    justifyContent: 'flex-start',
    height: '100%'
  },
  field: {
    margin: theme.spacing(3)
  },
  textField: {},
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  column: {
    padding: '10px',
    display: 'flex'
  },
  spacer: {
    // margin: theme.spacing(2),

    // marginBottom: theme.spacing(2),
    // marginLeft: theme.spacing(4),
    // marginRight: theme.spacing(4),
    height: 1
  },
  button: { width: '100%' },
  toggleContainer: {
    padding: 4,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      backgroundColor: theme.palette.primary.main
    }
    // borderTopLeftRadius: 10,
    // borderTopRightRadius: 10
    // boxShadow: "0px 5px 8px -5px black;"
  },
  paper: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    // position: 'relative',
    overflow: 'hidden',
    borderStartStartRadius: 0
    // minHeight: 650,
  },
  screen: { padding: theme.spacing(1) },
  output: {
    margin: theme.spacing(2),
    marginTop: 0,
    marginBottom: 0,
    padding: theme.spacing(1),
    paddingTop: 0,
    borderRadius: theme.shape.borderRadius,
    border: theme.palette.border,
    boxShadow:
      theme.palette.type === 'light'
        ? '0px 10px 10px -10px black'
        : 'inset 5px 5px 30px -5px black'
  },
  resultRow: {
    display: 'flex'
    // flexDirection: 'row'
  },
  screentext: {
    color: theme.palette.type === 'dark' ? 'lightgreen' : 'inherit',
    padding: 2
  },
  extraPaymentsModal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem',
    outline: 0
  },
  rightIcon: { marginLeft: theme.spacing(1) },
  modal: {
    // position: 'absolute',
    // overflow: 'scroll',
    // top: '10%',
    // left: '10%',
    background: 'rgba(0,0,0,0.8)'
  },
  fullField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    // background: "green",
  },
  fundingProfile: {
    padding: theme.spacing(2),
    margin: 0,
    border: `1px solid ${theme.palette.grey[400]}`,
    boxShadow: 'none'
  },
  select: {
    color: 'white'
  }
});
