import React from 'react';
import { useTypedSelector } from 'redux/reducers';
import { firebase } from 'redux/firebase';
import { PROXY_SETTINGS } from 'redux/actions/types';
import { BugTracker } from 'Utils/Bugtracker';
import { useDispatch } from 'react-redux';
import DashboardNotificationList from './DashboardNotificationList';

export interface INotification {
  ProcessInstanceTitle: string;
  id: string;
  msg: string;
  pdid: number;
  piid: number;
  psdid: number;
  read: boolean;
  ts: {
    nanoseconds: number;
    seconds: number;
  };
  type: string;
}

const DashboardNotification = () => {
  const dispatch = useDispatch();
  const Id = useTypedSelector((s) => s.user.user.Id);
  const uid = useTypedSelector((s) => s.fb.auth.uid);

  const [state, setState] = React.useState({
    notifications: [],
    notificationsLimit: 4,
    notificationsCount: 0
  });

  const { notifications } = state;

  const deleteNotification = (notification) => {
    const deleteRef = firebase
      .firestore()
      .collection('userAccount')
      .doc(Id.toString())
      .collection('notifications')
      .doc(notification.id);

    deleteRef.delete().catch((e) => BugTracker.notify(e));
  };

  const deleteAllNotifications = (notification: INotification[]) => {
    notification.forEach((notifi: INotification) => {
      const deleteRef = firebase
        .firestore()
        .collection('userAccount')
        .doc(Id.toString())
        .collection('notifications')
        .doc(notifi.id);

      deleteRef.delete().catch((e) => BugTracker.notify(e));
    });
  };

  React.useEffect(() => {
    // can we set up a listener here
    const unsub = firebase
      .firestore()
      .collection('userAccount')
      .doc(Id.toString())
      .onSnapshot((doc) =>
        dispatch({ type: PROXY_SETTINGS, payload: doc.data() })
      );

    const notifRef = firebase
      .firestore()
      .collection('userAccount')
      .doc(uid)
      .collection('notifications')
      .where('read', '==', false)
      .orderBy('ts', 'desc');

    const unsubscribe = notifRef.onSnapshot((snap) => {
      let notifications: any = [];
      snap.forEach((doc) => {
        const item = { ...doc.data(), id: doc.id };
        if (doc.exists) notifications.push(item);
      });
      setState((s) => ({ ...s, ['notifications']: notifications }));
    });
    return () => {
      unsub();
      unsubscribe();
    };
  }, []);

  const handleCloseNotifications = (notification) => {
    dispatch({ type: 'SET_TARGET', payload: notification });
  };

  return (
    <DashboardNotificationList
      notifications={notifications}
      onSelect={handleCloseNotifications}
      deleteNotification={deleteNotification}
      deleteAllNotifications={deleteAllNotifications}
    />
  );
};

export default DashboardNotification;
