import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  LinearProgress,
  makeStyles
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { AlertTitle, Alert } from '@material-ui/lab';
import QuickUserFromId from 'components/User/QuickUserFromId';

const useStyles = makeStyles((theme) => ({
  alertContainer: {
    width: '100%'
  },
  alert: {
    width: '100%',
    '& .MuiAlert-icon': {
      fontSize: '2rem',
      color: '#3182ce'
    },
    '& .MuiAlert-message': {
      width: '100%'
    }
  },
  progress: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    height: 4,
    backgroundColor: '#e2e8f0',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#718096'
    }
  },
  mainText: {
    color: '#718096',
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1)
  },
  subText: {
    color: '#718096',
    marginTop: theme.spacing(1)
  },
  title: {
    fontWeight: 500
  }
}));

const GDPRRequestAlert = ({
  username
}: {
  username: number | string | undefined;
}) => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  const renderUsername = () => {
    if (typeof username === 'number') {
      return <QuickUserFromId UserInstanceId={username.toString()} />;
    }
    return username;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return 100;
        }
        return oldProgress + 10;
      });
    }, 1500);

    return () => clearInterval(timer);
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} className={classes.alertContainer}>
        <Alert severity="info" className={classes.alert} icon={<InfoIcon />}>
          <AlertTitle className={classes.title}>
            GDPR Data Requested:
          </AlertTitle>
          <Typography variant="body1" className={classes.mainText}>
            Loading personal data for user: {renderUsername()}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={progress}
            className={classes.progress}
          />
          <Typography variant="body2" className={classes.subText}>
            Please wait while we securely retrieve the requested information
          </Typography>
        </Alert>
      </Grid>
    </Grid>
  );
};

export default GDPRRequestAlert;
