import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '../../../Table';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: { padding: theme.spacing(2), background: 'inherit' },
  detail: { padding: theme.spacing(2), margin: theme.spacing(2) }
}));

const Component = ({ company }) => {
  const classes = useStyles();

  return (
    <Paper elevation={3} className={classes.root}>
      <Table
        title="Addresses"
        data={company.addresses}
        columns={[
          { title: 'Type', field: 'attributes.type' },
          { title: 'Line 1', field: 'attributes.address_line_1' },
          { title: 'Line 2', field: 'attributes.address_line_2' },
          { title: 'Line 3', field: 'attributes.address_line_3' },
          { title: 'Line 4', field: 'attributes.address_line_4' },
          { title: 'Town', field: 'attributes.town' },
          { title: 'County', field: 'attributes.county' },
          { title: 'Postcode', field: 'attributes.postcode' },
          { title: 'Country', field: 'attributes.country' }
        ]}
        options={{ pageSize: 10 }}
      />
    </Paper>
  );
};

export default Component;
