import { Calculation } from 'types/calculatorInterfaces';
import { ForValue, FromValue } from '../Calculator/interfaces';

const forValues: ForValue[] = [
  ForValue.ForPayment,
  ForValue.ForCommission,
  ForValue.ForAmount
];
const fromValues: FromValue[] = [
  FromValue.FromYield,
  FromValue.FromRate,
  FromValue.FromFlatRate,
  FromValue.FromAPR,
  FromValue.FromNominalFlatRate
];

export const validateForm = (
  submitObj: Calculation,
  forValue: ForValue,
  fromValue: FromValue
) => {
  const isEmpty = (value: number) => value === undefined || value === 0;

  if (forValue === ForValue.ForPayment) {
    if (isEmpty(submitObj.Amount))
      return { status: false, message: 'Required Advance Field is empty' };
  }

  if (forValue === ForValue.ForAmount) {
    if (isEmpty(submitObj.MonthlyPayment))
      return { status: false, message: 'Enter a Repayment Value' };
  }

  if (
    (forValue === ForValue.ForPayment || forValue === ForValue.ForAmount) &&
    fromValue === FromValue.FromNominalFlatRate
  ) {
    if (isEmpty(submitObj.NominalFlatRate))
      return { status: false, message: 'Enter a Nominal Flat Rate' };
  }

  if (forValue === ForValue.ForRates) {
  } else if (
    forValue === ForValue.ForPayment &&
    fromValue === FromValue.FromYield
  ) {
  } else if (
    forValue === ForValue.ForPayment &&
    fromValue === FromValue.FromRate
  ) {
  } else if (
    forValue === ForValue.ForPayment &&
    fromValue === FromValue.FromFlatRate
  ) {
  } else if (
    forValue === ForValue.ForPayment &&
    fromValue === FromValue.FromAPR
  ) {
  } else if (
    forValue === ForValue.ForPayment &&
    fromValue === FromValue.FromNominalFlatRate
  ) {
  } else if (
    forValue === ForValue.ForCommission &&
    fromValue === FromValue.FromYield
  ) {
  } else if (
    forValue === ForValue.ForCommission &&
    fromValue === FromValue.FromRate
  ) {
  } else if (
    forValue === ForValue.ForAmount &&
    fromValue === FromValue.FromYield
  ) {
  } else if (
    forValue === ForValue.ForAmount &&
    fromValue === FromValue.FromRate
  ) {
  } else if (
    forValue === ForValue.ForAmount &&
    fromValue === FromValue.FromFlatRate
  ) {
  } else if (
    forValue === ForValue.ForAmount &&
    fromValue === FromValue.FromAPR
  ) {
  } else if (
    forValue === ForValue.ForAmount &&
    fromValue === FromValue.FromNominalFlatRate
  ) {
  } else {
  }
  return { status: true, message: 'ok to send' };
};

// export const submissionValidation = (stateObj, submitObj) => {
//   // Convert percentages
//   if (stateObj.Rate !== 0) submitObj.Rate = stateObj.Rate / 100;
//   if (stateObj.Yield !== 0) submitObj.Yield = stateObj.Yield / 100;
//   if (stateObj.APR !== 0) submitObj.APR = stateObj.APR / 100;
//   if (stateObj.FlatRate !== 0) submitObj.FlatRate = stateObj.FlatRate / 100;
//   if (stateObj.CommissionPercentage !== 0)
//     submitObj.CommissionPercentage = stateObj.CommissionPercentage / 100;

//   if (stateObj.Term === "") submitObj.Term = "36";
//   if (stateObj.Counting === "") submitObj.Counting = "Actual365";
//   return stateObj;
// };
