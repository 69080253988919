import React from 'react';
import { useSelector } from 'react-redux';
import CreateCalendarUserType from './createCalendarUserType';
import CreateUser from './createUser';

const SelectComponent = (props) => {
  const Description = useSelector(
    (s) => s.config.settings.UserDefinitionList
  ).find((el) => el.Id === parseInt(props.UserDefinitionId)).Description;
  const search = ',';
  const keywords = Description.split(search);
  if (keywords.includes('calendar'))
    return <CreateCalendarUserType {...props} />;
  return <CreateUser {...props} />;
};

export default SelectComponent;
