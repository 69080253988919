import Bugsnag from '@bugsnag/browser';
import {
  Button,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Switch,
  Typography,
  useTheme
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { AxiosResponse } from 'axios';
import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { getProcessDefinitions } from 'redux/actions/processes';
import { GetProcessDefinitionLite } from 'redux/database';
import { useTypedSelector } from 'redux/reducers';
import {
  CompleteProcessDefinition,
  ProcessDefinition,
  Rule
} from 'types/interfaces';
import { BugTracker } from 'Utils/Bugtracker';
import {
  compareSafeGuardIds,
  compareSafeGuardIdsInChildren
} from './functions';
import { IObjectIds, IStatus } from './interface';
import { Tree } from './Tree';

export interface ICustomRule {
  Title: string;
  Id: string;
  ActionFunction: string;
  ActionValue: string;
  ProcessStepDefinitionId: string;
}

export interface ICustomProcessDefinition {
  dealProcessId: string;
  dealProduct: string;
  ruleList: ICustomRule[];
}

export interface ISafeguardAction {
  id: IObjectIds;
  action: 'Sent' | 'Starred' | 'Accepted' | 'Completed';
  status: IStatus;
  completed?: boolean;
  uniqueId: string;
}

export interface ICustomTreeDataChildren {
  ruleId: string;
  ruleName: string;
  ruleAction: string;
  safeGuards: ISafeguardAction[];
  allSafeguardsCompleted?: boolean;
}

export interface ICustomTreeData {
  id: string;
  name: string;
  ids?: string[];
  children?: ICustomTreeDataChildren[];
}

export const RuleSchema = () => {
  const theme = useTheme();
  const { globalHostName } = useTypedSelector((s) => s.config.hostname);

  const [loading, setLoading] = useState<boolean>(true);
  const [treeData, setTreeData] = useState<ICustomTreeData[]>([]);
  const [isMismatch, setIsMismatch] = useState<boolean>(false);
  const [mode, setMode] = useState<'Local' | 'Global'>('Local');
  const [firestoreTreeData, setFirestoreTreeData] = useState<ICustomTreeData[]>(
    []
  );

  const getCompleteProcessDefinition = async ({ getProcess }) => {
    try {
      const rules: ICustomProcessDefinition[] = await Promise.all(
        getProcess.map(async (process: ProcessDefinition) => {
          const res = await GetProcessDefinitionLite({
            processDefinitionId: process.Id
          });

          if (!res) return null;

          return {
            dealProcessId: res.data.ProcessDefinition.Id,
            dealProduct: res.data.ProcessDefinition.Title,
            ruleList: res.data.RuleList.map((rule: Rule) => ({
              Title: rule.Title,
              Id: rule.Id,
              ActionFunction: rule.ActionFunction,
              ActionValue: rule.ActionValue,
              ProcessStepDefinitionId: rule.ProcessStepDefinitionId
            }))
          };
        })
      );

      const filteredRules = rules.filter((rule) => rule !== null);
      return filteredRules;
    } catch (e) {
      BugTracker.notify(e);
    }
  };

  const compareAndUpdateFirestore = async (
    newData: ICustomTreeData[],
    isUpdating: boolean
  ) => {
    const hostname = globalHostName;
    const baseRef = firebase.firestore().collection('globalSetting');
    const docRef = baseRef.doc('ruleSchema');

    try {
      const docSnapshot = await docRef.get();
      let treeDataToUpdate = {};

      if (!docSnapshot.exists) {
        treeDataToUpdate =
          mode === 'Global'
            ? { ruleSchema: newData }
            : { [hostname]: { ruleSchema: newData } };
        await docRef.set(treeDataToUpdate);
        return newData;
      } else {
        const existingData = docSnapshot.data();
        let existingTreeData =
          mode === 'Global'
            ? existingData?.ruleSchema
            : existingData?.[hostname]?.ruleSchema;
        let updateRequired = false;

        if (!existingTreeData) {
          existingTreeData = [];
        }

        newData.forEach((newDeal) => {
          const existingDeal = existingTreeData.find(
            (deal) => deal.id === newDeal.id
          );
          if (!existingDeal) {
            existingTreeData.push(newDeal);
            updateRequired = true;
          } else {
            newDeal.children?.forEach((newRule) => {
              const existingRule = existingDeal.children?.find(
                (r) => r.ruleId === newRule.ruleId
              );
              if (!existingRule) {
                existingDeal.children = [
                  ...(existingDeal.children || []),
                  newRule
                ];
                updateRequired = true;
              } else if (
                compareSafeGuardIdsInChildren(
                  existingDeal.children || [],
                  newDeal.children || []
                )
              ) {
                if (isUpdating) {
                  existingRule.safeGuards = newRule.safeGuards;
                  updateRequired = true;
                }
              }
            });
          }
        });

        if (updateRequired) {
          treeDataToUpdate =
            mode === 'Global'
              ? { ruleSchema: existingTreeData }
              : { [hostname]: { ruleSchema: existingTreeData } };
          await docRef.update(treeDataToUpdate);
        }

        return existingTreeData;
      }
    } catch (e) {
      console.error('Failed to update Firestore:', e);
      BugTracker.notify(e);
    }
  };

  const handleUpdateFirestore = async () => {
    const updatedData = await compareAndUpdateFirestore(treeData, true);

    if (updatedData) {
      setFirestoreTreeData(updatedData);
      setTreeData(updatedData);
      setIsMismatch(false);
    }
  };

  const generateUniqueId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
  };

  const handleAddIdToRule = (
    ruleId: string,
    newId: IObjectIds,
    action: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    status: IStatus
  ) => {
    setTreeData((prevTreeData) => {
      const treeDataCopy = JSON.parse(JSON.stringify(prevTreeData));

      const addIdToRule = (rules: ICustomTreeDataChildren[]) => {
        rules.forEach((rule) => {
          if (rule.ruleId === ruleId) {
            const newSafeguard: ISafeguardAction = {
              id: newId,
              action,
              status,
              uniqueId: generateUniqueId()
            };
            const updatedSafeGuards = rule.safeGuards
              ? [...rule.safeGuards, newSafeguard]
              : [newSafeguard];
            rule.safeGuards = updatedSafeGuards;
          }
        });
      };

      treeDataCopy.forEach((parent) => {
        if (parent.children) {
          addIdToRule(parent.children);
        }
      });

      return treeDataCopy;
    });
    setIsMismatch(true);
  };

  const handleEditIdInRule = (
    ruleId: string,
    uniqueId: string,
    updatedId: IObjectIds,
    updatedAction: 'Sent' | 'Starred' | 'Accepted' | 'Completed',
    updatedStatus: IStatus
  ) => {
    setTreeData((prevTreeData) => {
      const treeDataCopy = JSON.parse(JSON.stringify(prevTreeData));

      const editIdInRule = (rules: ICustomTreeDataChildren[]) => {
        rules.forEach((rule) => {
          if (rule.ruleId === ruleId) {
            rule.safeGuards = rule.safeGuards.map((safeGuard) =>
              safeGuard.uniqueId === uniqueId
                ? {
                    ...safeGuard,
                    id: updatedId,
                    action: updatedAction,
                    status: updatedStatus
                  }
                : safeGuard
            );
          }
        });
      };

      treeDataCopy.forEach((parent) => {
        if (parent.children) {
          editIdInRule(parent.children);
        }
      });

      return treeDataCopy;
    });
    setIsMismatch(true);
  };

  const handleRemoveIdFromRule = (ruleId: string, uniqueId: string) => {
    setTreeData((prevTreeData) => {
      const treeDataCopy = JSON.parse(JSON.stringify(prevTreeData));

      const removeIdFromRule = (rules: ICustomTreeDataChildren[]) => {
        rules.forEach((rule) => {
          if (rule.ruleId === ruleId) {
            rule.safeGuards = rule.safeGuards.filter(
              (safeGuard) => safeGuard.uniqueId !== uniqueId
            );
          }
        });
      };

      treeDataCopy.forEach((parent) => {
        if (parent.children) {
          removeIdFromRule(parent.children);
        }
      });

      return treeDataCopy;
    });
    setIsMismatch(true);
  };

  const transformToTreeData = (rules): ICustomTreeData[] => {
    return rules.map((rule: ICustomProcessDefinition) => ({
      id: rule.dealProcessId,
      name: rule.dealProduct,
      children: rule.ruleList.map((subRule: ICustomRule) => ({
        ruleId: subRule.Id.toString(),
        ruleName: `${subRule.Title} (${subRule.Id})`,
        ruleAction: `${subRule.ActionFunction} - ${subRule.ActionValue}`,
        safeGuards: [] as ISafeguardAction[]
      }))
    }));
  };

  const handleModeChange = async (event) => {
    const globalSettingsRef = firebase.firestore().collection('globalSetting');
    const ruleSchema = globalSettingsRef.doc('ruleSchema');

    const newMode = event.target.checked ? 'Global' : 'Local';
    setMode(newMode);
    await ruleSchema.update({ mode: newMode });
  };

  useEffect(() => {
    const globalSettingsRef = firebase.firestore().collection('globalSetting');
    const ruleSchema = globalSettingsRef.doc('ruleSchema');
    const fetchMode = async () => {
      await ruleSchema.get().then((doc) => {
        if (doc.exists) {
          const data = doc.data();
          if (data) setMode(data.mode);
        } else {
          ruleSchema.set({ value: 'Local' });
        }
      });
    };

    fetchMode();
  }, []);

  useEffect(() => {
    const getDealProcesses = async () => {
      setLoading(true);
      const getProcess = await getProcessDefinitions({ action: 'LIST' })
        .then((res: AxiosResponse<any> | undefined) => {
          if (!res) return;
          return res.data;
        })
        .catch((e) => {
          BugTracker.notify(e);
        });

      if (!getProcess) return;
      const processRules = await getCompleteProcessDefinition({ getProcess });
      const newTreeData = transformToTreeData(processRules);

      await compareAndUpdateFirestore(newTreeData, false)
        .then((treeData: ICustomTreeData[] | undefined) => {
          if (treeData) {
            setTreeData(treeData);
            setFirestoreTreeData(treeData);
          }
        })
        .catch((e) => {
          BugTracker.notify(e);
        });

      setLoading(false);
    };

    getDealProcesses();
  }, [mode]);

  useEffect(() => {
    const isDataMismatched = compareSafeGuardIds(treeData, firestoreTreeData);
    setIsMismatch(isDataMismatched);
  }, [treeData, firestoreTreeData]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={11}>
        <Alert severity="info">
          <Typography>
            {`Safe Guard Ids Are ObjectDefinition Ids That Are Used To Protect
            From "Sending Rules" Without Completion`}
          </Typography>
        </Alert>
      </Grid>

      <Grid item xs={1} style={{ paddingTop: theme.spacing(1) }}>
        <FormControlLabel
          control={
            <Switch
              checked={mode === 'Global'}
              onChange={handleModeChange}
              color="primary"
            />
          }
          label={mode === 'Global' ? 'Global Mode' : 'Local Mode'}
        />
      </Grid>
      <Grid item xs={12}>
        {isMismatch && (
          <Alert severity="warning">
            <Typography color="error">
              Mismatch Detected In Rule Schema!
            </Typography>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleUpdateFirestore}>
              Update Firestore
            </Button>
          </Alert>
        )}
      </Grid>

      {loading ? (
        <Grid item xs={12} md={12} style={{ width: '100%' }}>
          <LinearProgress />
        </Grid>
      ) : (
        <Grid item xs={12} md={12} style={{ width: '100%' }}>
          <Tree
            treeData={treeData}
            handleAddIdToRule={handleAddIdToRule}
            handleRemoveIdFromRule={handleRemoveIdFromRule}
            handleEditIdInRule={handleEditIdInRule}
          />
        </Grid>
      )}
    </Grid>
  );
};
