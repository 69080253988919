import { makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.background.default,
      borderRadius: theme.shape.borderRadius,
      margin: '8px 0px',
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-start'
    },
    leverCol: {
      width: 270,
      paddingRight: theme.spacing(2),
      borderRight: `1px ${theme.palette.grey['A100']} solid`
    },
    otherCol: {
      paddingLeft: theme.spacing(2),
      width: '100%'
    },
    hr: {
      borderRadius: 10,
      background: theme.palette.grey['A100'],
      margin: 5
    },
    inlinetext: {
      color: theme.palette.secondary.main
    },
    paper: {
      border: `1px ${theme.palette.grey['A100']} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`
    },
    TotalsBox: {
      border: `1px ${theme.palette.grey.A100} solid`,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(2),
      margin: `8px 0px`,
      background: theme.palette.primary.main,
      display: 'flex',
      justifyContent: 'space-around'
    },
    TotalsBoxText: {
      color: theme.palette.primary.contrastText
    }
  })
);
