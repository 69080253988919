import { ObjectInstance, CompleteUserInstance } from 'types/interfaces';
import { store } from 'redux/store';
import { SET_ALL_LIST } from 'redux/actions/types';
import { updateProcessObjectInstanceInRedux } from './updateProcessObjectInstanceInRedux';

const { dispatch } = store;
/**
 * Interface defining the structure of the user list in the Redux store
 * @interface IUserList
 * @property {Object.<number, Object>} [UserDefinitionId] - Mapping of user definition IDs to their instances
 */
interface IUserList {
  [UserDefinitionId: number]: {
    [UserDefinitionId: number]: CompleteUserInstance[];
    Id: string;
  };
}

/**
 * Updates both user and process object instances in Redux store
 *
 * This function performs two main operations:
 * 1. Updates the user object instance in the user list
 * 2. Updates the corresponding process object instance
 *
 * @async
 * @param {Object} params - The parameters object
 * @param {ObjectInstance} params.ObjectInstance - The object instance to update
 * @param {boolean} params.processDependent - Flag indicating if the update is process dependent
 *
 * @returns {Promise<ObjectInstance>} The updated object instance
 *
 * @throws {TypeError} When required nested objects are undefined
 *
 * @example
 * const updatedInstance = await updateUserANDProcessObjectInstanceInRedux({
 *   ObjectInstance: myObjectInstance,
 *   processDependent: true
 * });
 */
export const updateUserANDProcessObjectInstanceInRedux = async ({
  ObjectInstance,
  processDependent
}: {
  ObjectInstance: ObjectInstance;
  processDependent: boolean;
}) => {
  // Get current state from Redux store
  const userList: IUserList = store.getState().userAPI.userList;
  const { UserDefinitionId, UserInstanceId } = ObjectInstance;

  // Create deep copy of user list to maintain immutability
  const updatedUserList: IUserList = JSON.parse(JSON.stringify(userList));
  const existingUserList = updatedUserList[UserDefinitionId];

  if (existingUserList) {
    const UserInstance = updatedUserList[UserDefinitionId][UserDefinitionId];

    // Find the target instance that matches the UserInstanceId
    const targetInstance = UserInstance?.find(
      (item: CompleteUserInstance) => item.UserInstance.Id === UserInstanceId
    );

    // Update the object instance if all required properties exist
    if (targetInstance && targetInstance.CompleteObjectInstanceDict) {
      const targetObjectInstance =
        targetInstance.CompleteObjectInstanceDict[ObjectInstance.Id];

      if (targetObjectInstance) {
        targetObjectInstance.ObjectInstance = ObjectInstance;
      }
    }

    // Dispatch the updated list to Redux store
    dispatch({ type: SET_ALL_LIST, payload: updatedUserList });
  }

  // Update the process object instance
  await updateProcessObjectInstanceInRedux({ ObjectInstance });
  return ObjectInstance;
};
