import { useProcess } from 'hooks/useProcess';
import { Rule, RuleGroup } from 'types/interfaces';

export const getFilteredAndGroupedRules = (
  ruleDict: { [key: string]: Rule },
  isAdminOrProcessOwner: boolean
): RuleGroup => {
  const { currentProcess } = useProcess();
  const { RuleList } = currentProcess;
  const list = isAdminOrProcessOwner ? RuleList : ruleDict;

  const sortedRules: Rule[] = Object.values(list).sort(
    (a: Rule, b: Rule) => a.ItemOrder - b.ItemOrder
  );

  const groupedRules: any = sortedRules.filter(
    (item: { Description: string }) => item.Description.includes('group#')
  );

  let groups = {};
  groupedRules.forEach((item: { Description: string }) => {
    const split = item?.Description.split(',');
    const group_name_unsplit = split.find((e) => e.includes('group'));
    const group_name = group_name_unsplit?.split('#')[1];
    if (group_name) {
      if (!groups[group_name]) groups[group_name] = [];
      groups[group_name].push(item);
    }
  });

  return groups;
};
