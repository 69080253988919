import PraeturaProposal from '.';
import { ELender, ELenderAPIType } from '../../interface';
import { createTypeSafeIds } from '../../utils';

export const getPraeturaIds = () => {
  return createTypeSafeIds(PraeturaProposal, (_) => ({
    customer: {
      website: {
        FieldDefinitionId: 18316,
        isReadonly: false,
        FieldDefinition: {
          type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
          newIdList: [
            {
              entityType: 'Sole Trader',
              FieldDefinitionId: 21869
            },
            {
              entityType: 'Partnership',
              FieldDefinitionId: 21869
            },
            {
              entityType: 'Private Individual',
              FieldDefinitionId: 0
            }
          ],
          requirement: {
            maxLength: 100,
            pattern:
              '^https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b(?:[-a-zA-Z0-9()@:%_\\+.~#?&\\/=]*)$',
            message:
              'Please enter a valid website URL (e.g., https://example.com)'
          }
        }
      },
      industry: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.OptionsList,
          options: [
            {
              label: 'Agriculture Forestry and Fishing',
              value: 'agriculture_forestry_and_fishing'
            },
            {
              label: 'Architecture and Engineering',
              value: 'architecture_and_engineering'
            },
            {
              label: 'Business Support Services',
              value: 'business_support_services'
            },
            {
              label: 'Car Dealers Garages and Mechanics',
              value: 'car_dealers_garages_and_mechanics'
            },
            {
              label: 'Cleaning Landscaping and Gardening',
              value: 'cleaning_landscaping_and_gardening'
            },
            {
              label: 'Construction',
              value: 'construction'
            },
            {
              label: 'Education and Training',
              value: 'education_and_training'
            },
            {
              label: 'Finance and Insurance',
              value: 'finance_and_insurance'
            },
            {
              label: 'Health and Care',
              value: 'health_and_care'
            },
            {
              label: 'Hotels Restaurants and Pubs',
              value: 'hotels_restaurants_and_pubs'
            },
            {
              label: 'It and Communications',
              value: 'it_and_communications'
            },
            {
              label: 'Leisure and Other Services',
              value: 'leisure_and_other_services'
            },
            {
              label: 'Manufacturing Food Processing and Printing',
              value: 'manufacturing_food_processing_and_printing'
            },
            {
              label: 'Professional Services',
              value: 'professional_services'
            },
            {
              label: 'Property and Real Estate',
              value: 'property_and_real_estate'
            },
            {
              label: 'Publishing Film and Media Production',
              value: 'publishing_film_and_media_production'
            },
            {
              label: 'Retail',
              value: 'retail'
            },
            {
              label: 'Recruitment Agencies',
              value: 'recruitment_agencies'
            },
            {
              label: 'Transport and Logistics',
              value: 'transport_and_logistics'
            },
            {
              label: 'Wholesale',
              value: 'wholesale'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]
        }
      },
      sic_code: {
        FieldDefinitionId: 21881,
        isReadonly: false
      },
      company_type: {
        FieldDefinitionId: 21745,
        isReadonly: true,
        required: true,
        FieldDefinition: {
          type: [
            ELenderAPIType.OptionsList,
            ELenderAPIType.EntityDependentParent,
            ELenderAPIType.Conversion
          ],
          options: [
            {
              label: 'Limited Company',
              value: 'limited_liability_company'
            },
            {
              label: 'Limited Liability Partnership',
              value: 'limited_liability_partnership'
            },
            {
              label: 'Sole Trader',
              value: 'sole_trader'
            },
            {
              label: 'Partnership',
              value: 'partnership'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]
        }
      },
      trading_name: {
        FieldDefinitionId: 18317,
        isReadonly: false,
        FieldDefinition: {
          type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
          newIdList: [
            {
              entityType: 'Sole Trader',
              FieldDefinitionId: 21866
            },
            {
              entityType: 'Partnership',
              FieldDefinitionId: 21866
            },
            {
              entityType: 'Private Individual',
              FieldDefinitionId: 0
            }
          ],
          requirement: {
            maxLength: 100
          }
        }
      },
      trading_address: {
        street: {
          FieldDefinitionId: 18431,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 100
            }
          }
        },

        city_or_town: {
          FieldDefinitionId: 18435,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 100
            }
          }
        },
        county_or_state: {
          FieldDefinitionId: 18436,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 100
            }
          }
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'GB'
        },
        postcode: {
          FieldDefinitionId: 18438,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        }
      },
      vat_information: {
        vat_number: {
          FieldDefinitionId: 23547,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.EntityChange,
            newIdList: [
              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 21875
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 21875
              },
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 0
              }
            ]
          }
        },
        is_registered_for_vat: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        }
      },
      trading_from_date: {
        FieldDefinitionId: 18450,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: [
            ELenderAPIType.Date,
            ELenderAPIType.Requirement,
            ELenderAPIType.EntityChange
          ],
          newIdList: [
            {
              entityType: 'Sole Trader',
              FieldDefinitionId: 23533
            },
            {
              entityType: 'Partnership',
              FieldDefinitionId: 23533
            },
            {
              entityType: 'Private Individual',
              FieldDefinitionId: 0
            }
          ],
          requirement: {
            pattern: '^\\d{4}-\\d{2}-\\d{2}$'
          }
        }
      },
      registered_address: {
        street: {
          FieldDefinitionId: 18431,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: [ELenderAPIType.Requirement, ELenderAPIType.EntityChange],
            newIdList: [
              {
                entityType: 'Sole Trader',
                FieldDefinitionId: 23684
              },
              {
                entityType: 'Partnership',
                FieldDefinitionId: 23984
              },
              {
                entityType: 'Private Individual',
                FieldDefinitionId: 0
              }
            ],
            requirement: {
              maxLength: 100
            }
          }
        },
        city_or_town: {
          FieldDefinitionId: 18435,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 100
            }
          }
        },
        county_or_state: {
          FieldDefinitionId: 18436,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 100
            }
          }
        },
        country: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'GB'
        },
        postcode: {
          FieldDefinitionId: 18438,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 12,
              pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
              message:
                "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
            }
          }
        }
      },

      registered_company_name: {
        FieldDefinitionId: 18317,
        isReadonly: false,
        FieldDefinition: {
          type: ELenderAPIType.EntityDependent,
          options: ['Limited Company', 'Limited Liability Partnership']
        }
      },

      company_registration_number: {
        FieldDefinitionId: 18315,
        isReadonly: false,
        FieldDefinition: {
          type: [ELenderAPIType.Requirement, ELenderAPIType.EntityDependent],
          options: ['Limited Company', 'Limited Liability Partnership'],
          requirement: {
            maxLength: 100
          }
        }
      },
      has_consented_to_credit_check: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      },
      has_consented_to_privacy_policy: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        info: 'Must be set to true, otherwise request will be rejected.',
        FieldDefinition: {
          type: ELenderAPIType.Boolean
        }
      }
    },
    loanApplication: {
      urgency: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.OptionsList,
          options: [
            {
              label: 'ASAP',
              value: 'asap'
            },
            {
              label: 'In the following weeks',
              value: 'in_the_following_weeks'
            },
            {
              label: 'In the following months',
              value: 'in_the_following_months'
            },
            {
              label: 'Other',
              value: 'other'
            }
          ]
        }
      },
      party_to_contact: {
        FieldDefinitionId: 0,
        isReadonly: false,
        required: true,
        FieldDefinition: {
          type: ELenderAPIType.Dropdown,
          options: ['applicant', 'introducer']
        }
      },
      external_customer_id: {
        FieldDefinitionId: 0,
        isReadonly: false,
        info: 'Either External Customer Id or Customer Id should be provided. Should not be provided both.',
        FieldDefinition: {
          type: ELenderAPIType.Requirement,
          requirement: {
            maxLength: 50
          }
        }
      },
      applicants: [
        {
          first_name: {
            FieldDefinitionId: 18420,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 50
              }
            }
          },
          middle_name: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 100
              }
            }
          },
          last_name: {
            FieldDefinitionId: 18427,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 50
              }
            }
          },
          has_consented_to_credit_check: {
            FieldDefinitionId: 0,
            info: 'Specifies whether Praetura allowed to do credit checks against this Applicant.',
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          has_consented_to_privacy_policy: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          date_of_birth: {
            FieldDefinitionId: 18416,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^\\d{4}-\\d{2}-\\d{2}$'
              }
            }
          },
          is_main_applicant: {
            FieldDefinitionId: 0,
            isReadonly: false,
            info: "Specifies whether this applicant is a primary person for any contacts. There should be at least one Applicant with 'true' in this property if party_to_contact is 'applicant'.",
            FieldDefinition: {
              type: ELenderAPIType.Boolean
            }
          },
          address: {
            street: {
              FieldDefinitionId: 18414,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 100
                }
              }
            },
            city_or_town: {
              FieldDefinitionId: 18429,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 100
                }
              }
            },
            county_or_state: {
              FieldDefinitionId: 18417,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 100
                }
              }
            },
            country: {
              FieldDefinitionId: 0,
              isReadonly: true,
              forcedValue: 'GB'
            },
            postcode: {
              FieldDefinitionId: 18425,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 12,
                  pattern: '^[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$',
                  message:
                    "'Please enter a valid UK postcode (e.g., SW1A 1AA or M1 1AA)'"
                }
              }
            }
          },
          company_role: {
            FieldDefinitionId: 0,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.OptionsList,
              options: [
                {
                  label: 'Director',
                  value: 'director'
                },
                {
                  label: 'Shareholder',
                  value: 'shareholder'
                },
                {
                  label: 'Business Owner',
                  value: 'business_owner'
                },
                {
                  label: 'Company Secretary',
                  value: 'company_secretary'
                },
                {
                  label: 'Partner',
                  value: 'partner'
                },
                {
                  label: 'Other',
                  value: 'other'
                }
              ]
            }
          },
          contact_details: {
            mobile_phone_number: {
              FieldDefinitionId: 23704,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: [
                  ELenderAPIType.Phone,
                  ELenderAPIType.EntityChange,
                  ELenderAPIType.Requirement
                ],
                newIdList: [
                  {
                    entityType: 'Sole Trader',
                    FieldDefinitionId: 23500
                  },
                  {
                    entityType: 'Partnership',
                    FieldDefinitionId: 23979
                  },
                  {
                    entityType: 'Private Individual',
                    FieldDefinitionId: 0
                  }
                ],
                requirement: {
                  pattern: '^(?:(?:\\+44)|0)\\d{10}$',
                  message:
                    'Please enter a valid UK phone number (e.g., 07123456789 or +447123456789)'
                }
              }
            },
            other_phone_number: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.Phone, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^(?:(?:\\+44)|0)\\d{10}$',
                  message:
                    'Please enter a valid UK phone number (e.g., 07123456789 or +447123456789)'
                }
              }
            },
            email: {
              FieldDefinitionId: 18452,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Requirement,
                requirement: {
                  maxLength: 255,
                  pattern:
                    '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                  message: 'Please enter a valid email address'
                }
              }
            }
          },
          guarantees: {
            can_provide_personal_guarantee: {
              FieldDefinitionId: 0,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.Boolean
              }
            }
          }
        }
      ],
      introducer: {
        id: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 50
            }
          }
        },
        external_id: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Requirement,
            requirement: {
              maxLength: 50
            }
          }
        },
        contact_details: {
          first_name: {
            FieldDefinitionId: 20712,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 50
              }
            }
          },
          middle_name: {
            FieldDefinitionId: 20712,
            isReadonly: false,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 50
              }
            }
          },
          last_name: {
            FieldDefinitionId: 27274,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 50
              }
            }
          },
          mobile_phone_number: {
            FieldDefinitionId: 18525,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: [ELenderAPIType.Phone, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^(?:(?:\\+44)|0)\\d{10}$',
                message:
                  'Please enter a valid UK phone number (e.g., 07123456789 or +447123456789)'
              }
            }
          },
          other_phone_number: {
            FieldDefinitionId: 0,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Phone, ELenderAPIType.Requirement],
              requirement: {
                pattern: '^(?:(?:\\+44)|0)\\d{10}$',
                message:
                  'Please enter a valid UK phone number (e.g., 07123456789 or +447123456789)'
              }
            }
          },
          email: {
            FieldDefinitionId: 18452,
            isReadonly: false,
            required: true,
            FieldDefinition: {
              type: ELenderAPIType.Requirement,
              requirement: {
                maxLength: 255,
                pattern:
                  '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                message: 'Please enter a valid email address'
              }
            }
          }
        }
      },
      asset_finance_request: {
        assets: [
          {
            manufacturer: {
              FieldDefinitionId: 18309,
              isReadonly: false,
              required: true
            },
            model: {
              FieldDefinitionId: 18310,
              isReadonly: false,
              required: true
            },
            category: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'Access Machinery and Equipment',
                    value: 'access_machinery_and_equipment'
                  },
                  {
                    label:
                      'Agricultural Forestry Landscaping Machinery and Equipment',
                    value:
                      'agricultural_forestry_landscaping_machinery_and_equipment'
                  },
                  {
                    label: 'Audio Visual and Broadcasting Equipment',
                    value: 'audio_visual_and_broadcasting_equipment'
                  },
                  {
                    label: 'Building Systems and Components',
                    value: 'building_systems_and_components'
                  },
                  {
                    label: 'Buses and Coaches',
                    value: 'buses_and_coaches'
                  },
                  {
                    label: 'Cars and Motorcycles',
                    value: 'cars_and_motorcycles'
                  },
                  {
                    label: 'Commercial Equipment and Tools',
                    value: 'commercial_equipment_and_tools'
                  },
                  {
                    label: 'Commercial Furniture and Fittings',
                    value: 'commercial_furniture_and_fittings'
                  },
                  {
                    label: 'Computer Software',
                    value: 'computer_software'
                  },
                  {
                    label:
                      'Computers Peripherals Security and Telephony Equipment',
                    value:
                      'computers_peripherals_security_and_telephony_equipment'
                  },
                  {
                    label:
                      'Construction and Civil Engineering Machinery and Equipment',
                    value:
                      'construction_and_civil_engineering_machinery_and_equipment'
                  },
                  {
                    label:
                      'Electric Motors Generators Transformers and Accumulators',
                    value:
                      'electric_motors_generators_transformers_and_accumulators'
                  },
                  {
                    label: 'Engineering Machinery and Equipment',
                    value: 'engineering_machinery_and_equipment'
                  },
                  {
                    label: 'Heavy Commercial Vehicles',
                    value: 'heavy_commercial_vehicles'
                  },
                  {
                    label: 'Light Commercial Vehicles',
                    value: 'light_commercial_vehicles'
                  },
                  {
                    label: 'Manufacturing Machinery and Equipment',
                    value: 'manufacturing_machinery_and_equipment'
                  },
                  {
                    label: 'Marine Vessels and Aircrafts',
                    value: 'marine_vessels_and_aircrafts'
                  },
                  {
                    label:
                      'Materials Lifting Handling and Storage Machinery and Equipment',
                    value:
                      'materials_lifting_handling_and_storage_machinery_and_equipment'
                  },
                  {
                    label: 'Medical and Dental Equipment and Tools',
                    value: 'medical_and_dental_equipment_and_tools'
                  },
                  {
                    label:
                      'Mining Quarrying and Aggregate Machinery and Equipment',
                    value:
                      'mining_quarrying_and_aggregate_machinery_and_equipment'
                  },
                  {
                    label: 'Other',
                    value: 'other'
                  },
                  {
                    label: 'Printing and Graphics Machinery and Equipment',
                    value: 'printing_and_graphics_machinery_and_equipment'
                  },
                  {
                    label:
                      'Recycling and Waste Management Machinery and Equipment',
                    value:
                      'recycling_and_waste_management_machinery_and_equipment'
                  },
                  {
                    label: 'Trailers',
                    value: 'trailers'
                  }
                ]
              }
            },
            condition: {
              FieldDefinitionId: 18311,
              isReadonly: false,
              FieldDefinition: {
                type: ELenderAPIType.OptionsList,
                options: [
                  {
                    label: 'New',
                    value: 'new'
                  },
                  {
                    label: 'Used',
                    value: 'used'
                  }
                ]
              }
            },
            description: {
              FieldDefinitionId: 18445,
              isReadonly: false
            },
            is_insured: {
              FieldDefinitionId: 0,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Boolean
              }
            },
            vat_per_unit: {
              FieldDefinitionId: 21166,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            cost_per_unit: {
              FieldDefinitionId: 18314,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Currency
              }
            },
            serial_number: {
              FieldDefinitionId: 18454,
              isReadonly: false
            },
            number_of_units: {
              FieldDefinitionId: 18313,
              isReadonly: false,
              required: true,
              FieldDefinition: {
                type: ELenderAPIType.Number
              }
            },
            manufacture_date: {
              FieldDefinitionId: 20637,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.Date, ELenderAPIType.Requirement],
                requirement: {
                  pattern: '^\\d{4}-\\d{2}-\\d{2}$'
                }
              }
            },
            registration_number: {
              FieldDefinitionId: 21938,
              isReadonly: false
            },
            manufacture_year: {
              FieldDefinitionId: 18312,
              isReadonly: false,
              FieldDefinition: {
                type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
                requirement: {
                  maxLength: 4,
                  minAmount: 1230,
                  maxAmount: 2130
                }
              }
            }
          }
        ],
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'GBP'
        },
        duration: {
          unit: {
            FieldDefinitionId: 0,
            isReadonly: true,
            forcedValue: 'months'
          },
          amount: {
            FieldDefinitionId: 23820,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
              requirement: {
                minAmount: 1,
                maxAmount: 60
              }
            }
          }
        },
        is_refinance: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Boolean
          }
        },
        product_type: {
          FieldDefinitionId: 21815,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.OptionsList,
            options: [
              {
                label: 'Hire Purchase',
                value: 'hire_purchase'
              },
              {
                label: 'Finance Lease',
                value: 'finance_lease'
              }
            ]
          }
        },
        balloon_amount: {
          FieldDefinitionId: 23853,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        vat_paid_up_front_amount: {
          FieldDefinitionId: 24388,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        deposit_or_initial_rental_amount: {
          FieldDefinitionId: 23824,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        }
      },

      commercial_loan_request: {
        currency: {
          FieldDefinitionId: 0,
          isReadonly: true,
          forcedValue: 'GBP'
        },
        amount: {
          FieldDefinitionId: 23996,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Currency
          }
        },
        duration: {
          unit: {
            FieldDefinitionId: 0,
            isReadonly: true,
            forcedValue: 'months'
          },
          amount: {
            FieldDefinitionId: 23996,
            isReadonly: false,
            FieldDefinition: {
              type: [ELenderAPIType.Number, ELenderAPIType.Requirement],
              requirement: {
                minAmount: 1,
                maxAmount: 60
              }
            }
          }
        }
      },
      invoice_finance_request: {
        annual_turnover: {
          FieldDefinitionId: 0,
          isReadonly: false,
          FieldDefinition: {
            type: ELenderAPIType.Number
          }
        },
        currency: {
          FieldDefinitionId: 0,
          isReadonly: false,
          required: true,
          FieldDefinition: {
            type: ELenderAPIType.Dropdown,
            options: ['GBP', 'USD', 'EUR']
          }
        }
      }
    }
  }));
};
