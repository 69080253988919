import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button color="primary" onClick={handleClickOpen} size="small">
        Upgrade
      </Button>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClose}
        open={open}>
        <DialogTitle id="alert-dialog-title">Upgrades</DialogTitle>
        <DialogContent>
          <Alert severity="info">
            <Typography>
              Upgrades are coming soon. They will be in the form of feature
              expansion and upgrades to existing functionality AND completely
              new plugins giving you extra tools sets. If interested in Upgrades
              please speak to your administrator. Upgrades will be available
              directly on the platform as they become available.
            </Typography>
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button autoFocus color="primary" onClick={handleClose}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
