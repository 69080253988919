import React from 'react';
import { useSelector } from 'react-redux';
import InlineTextField from 'components/Fields/InlineTextField';
import { AddFieldAndObjectButton } from 'components/Fields/InlineTextField/components/AddFieldButton';
import { DateParse } from 'common/dateParse';
import Table from '../Table';
import {
  BalanceSheetTitles,
  CashflowTitles,
  ProfitLossTitles,
  RatioAnalysis
} from 'components/RedFlag/TabMenu/Titles';
import NumberFormat from 'react-number-format';

import { ReadOnlyRedflag } from 'helpers/readOnlyRedflag';

const Currency = ({ value }) => (
  <NumberFormat
    value={value}
    displayType={'text'}
    thousandSeparator
    prefix={'£'}
    decimalScale={2}
  />
);

const RepeatingObject = (props) => {
  const { FieldDefinitionList, ObjectDefinition, rowData } = props;
  const [data, setData] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const UserInstance = rowData.UserInstance; // This allows us to update the data of this user

  const typeOfTitle = () => {
    switch (ObjectDefinition.Title) {
      case 'Balance Sheet':
        return BalanceSheetTitles;
      case 'Cashflow':
        return CashflowTitles;
      case 'Profit Loss':
        return ProfitLossTitles;
      case 'Ratio Analysis':
        return RatioAnalysis;
    }
  };

  const isReadOnly = () => {
    const redflagValues = ReadOnlyRedflag({
      ObjectDefinition: props.ObjectDefinition
    });

    return redflagValues;
  };

  React.useEffect(() => {
    const search = '_';
    const replaceWith = ' ';
    /**
     * COLUMNS
     */
    // 1. filter the complete object instance by object definition Id
    const ObjectInstanceList = rowData.CompleteObjectInstanceList.filter(
      (el) => el.ObjectInstance.ObjectDefinitionId === ObjectDefinition.Id
    );
    // 2. Make the columns with the corresponding definition Id for Account Date
    const PivotDefinition = FieldDefinitionList.find(
      (el) => el.Title === 'Account Date'
    );

    // 3. Set the columns array with an empty column for the field definition title and then columns for each object instance.
    const columns = [{ title: '', field: 'title' }];
    ObjectInstanceList.forEach(({ FieldInstanceList }, idx) => {
      // console.log({ FieldInstanceList });
      if (idx <= 4) {
        const date = FieldInstanceList.find(
          (el) => el.FieldDefinitionId === PivotDefinition.Id
        );
        columns.push({ title: date.FieldValue, field: date.ObjectInstanceId });
      }
    });
    setColumns(columns);

    /**
     * ROWS
     */

    // We need to create rows with the first cell being the definition title and subsequent values from each instance
    const rows = [];
    // Use the Titles list to Create the first cell

    typeOfTitle().forEach((title) => {
      const noUnderscore = title.split(search).join(replaceWith);
      const attributeName = noUnderscore.replace(/\b\w/g, (c) =>
        c.toUpperCase()
      );
      // Get the definition id from the title name
      const FieldDefinition = FieldDefinitionList.find(
        (el) => el.Title === attributeName
      );
      const row = { title: attributeName };
      if (FieldDefinition) {
        const RowFieldDefinitionId = FieldDefinition.Id;
        ObjectInstanceList.forEach(
          ({ ObjectInstance, FieldInstanceList }, idx) => {
            if (idx <= 4) {
              const FieldInstance = FieldInstanceList.find(
                (el) => el.FieldDefinitionId === RowFieldDefinitionId
              );
              row[ObjectInstance.Id] = (
                <Currency value={FieldInstance.FieldValue} />
              );
            }
          }
        );
        rows.unshift(row);
      }
    });

    setData(rows);
  }, []);

  const actions = [
    {
      icon: () => (
        <div>
          {!isReadOnly() && (
            <AddFieldAndObjectButton
              ObjectDefinition={ObjectDefinition}
              Fields={FieldDefinitionList}
              UserInstance={UserInstance}
              ProcessInstanceId={0}
            />
          )}
        </div>
      ),
      tooltip: 'Add Record',
      isFreeAction: true
    }
  ];

  return (
    <div style={{ width: '100%' }}>
      <Table
        title={ObjectDefinition.Title}
        data={data.reverse()}
        columns={columns}
        actions={actions}
        options={{ pageSize: 25 }}
        dontranspose
      />
    </div>
  );
};

export default RepeatingObject;
