import { Page, StyleSheet, Document, View, Text } from '@react-pdf/renderer';
import { useEffect } from 'react';
import { ObjectDefinition } from 'types/interfaces';
import { IProps } from './interface';
import { styles } from './styles';
import Time from 'common/Time';

const RepeatableObject_pdf = ({
  ObjectDefinition,
  objectData,
  currentDeal
}: IProps) => {
  const tableRowHeaders = () => {
    let uniqNumber = 9999;

    return (
      <View style={styles.tableRow}>
        {Object.keys(objectData[0]).map((header) => {
          uniqNumber++;

          return (
            <View key={uniqNumber} style={styles.tableCol}>
              <Text style={styles.tableCellHeader}>{header}</Text>
            </View>
          );
        })}
      </View>
    );
  }; //END tableRowHeaders

  /**table row with the inserted data */
  const tableRowData = () => {
    const rowData = objectData.map((eachObject, indx) => {
      let uniqNumber = 999;

      return (
        <View
          style={[
            styles.tableRow,
            indx % 2 === 0
              ? { backgroundColor: 'rgb(239, 239, 240)' }
              : { backgroundColor: 'rgb(255,255,255)' }
          ]}
          key={indx}>
          {Object.keys(objectData[0]).map((title) => {
            uniqNumber++;

            return (
              <View style={styles.tableCol} key={uniqNumber}>
                <Text style={styles.tableCellData}>{eachObject[title]}</Text>
              </View>
            );
          })}
        </View>
      );
    });

    return rowData;
  }; //END tableRowData

  if (ObjectDefinition && objectData && objectData.length > 0) {
    return (
      <Document>
        <Page size="A4" orientation="landscape" style={styles.page}>
          {
            <View style={styles.table}>
              <View style={styles.section}>
                <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
                <Text style={styles.subTitle}>
                  Deal ID: {currentDeal.ProcessInstance.Id}
                </Text>
                <Text style={styles.subTitle}>
                  Date: <Time time={new Date()} type="date" />
                </Text>
                <Text style={{ paddingBottom: 6 }} />
              </View>

              {/* table headers row*/}
              {tableRowHeaders()}

              {/* table data row */}
              {tableRowData()}
            </View>
          }
        </Page>
      </Document>
    );
  }

  return (
    <Document>
      <View style={styles.section}>
        <Text style={styles.title}>{ObjectDefinition?.Title}</Text>
      </View>

      <Page style={styles.page}>
        <View style={styles.section}>
          <Text>No data to show</Text>
        </View>
      </Page>
    </Document>
  );
};

export default RepeatableObject_pdf;
