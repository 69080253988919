import {
  UserEvent,
  UserInstance,
  UserDefinition,
  CompleteUserInstance
} from 'types/interfaces';
import {
  SET_USER,
  SET_TOKEN,
  SET_SESSION,
  SET_COSNETICS_STATE,
  SET_EVENTS,
  SET_UPDATE_USER
} from '../actions/types';
import { IData } from 'components/User/CreateAsUserButton/interfaces';

interface User {
  user: UserInstance;
  auth: { token: string; timeout: null | number | string };
  events: UserEvent[] | [];
  cosnetics: boolean;
  updatedUser: { isUpdated: boolean; Id: number };
}

const INITIAL_STATE: User = {
  user: {} as UserInstance,
  auth: { token: '', timeout: null },
  events: [],
  cosnetics: false,
  updatedUser: { isUpdated: false, Id: 0 }
};

interface SET_USER {
  type: typeof SET_USER;
  payload: UserInstance;
}

interface SET_TOKEN {
  type: typeof SET_TOKEN;
  payload: string;
}

interface SET_SESSION {
  type: typeof SET_SESSION;
  payload: string | null | number;
}

interface SET_COSNETICS_STATE {
  type: typeof SET_COSNETICS_STATE;
  payload: boolean;
}

interface SET_EVENTS {
  type: typeof SET_EVENTS;
  payload: [];
}

interface SET_UPDATED_USER {
  type: typeof SET_UPDATE_USER;
  payload: { isUpdated: boolean; Id: number };
}

type IAction =
  | SET_USER
  | SET_TOKEN
  | SET_SESSION
  | SET_COSNETICS_STATE
  | SET_EVENTS
  | SET_UPDATED_USER;

export default (state = INITIAL_STATE, action: IAction) => {
  switch (action.type) {
    case SET_USER:
      return { ...state, user: action.payload };
    case SET_TOKEN:
      return {
        ...state,
        auth: { ...state.auth, token: action.payload }
      };
    case SET_SESSION:
      return {
        ...state,
        auth: {
          ...state.auth,
          timeout: action.payload
        }
      };
    case SET_COSNETICS_STATE:
      return { ...state, cosnetics: action.payload };
    case SET_EVENTS:
      return { ...state, events: action.payload };
    case SET_UPDATE_USER:
      return { ...state, updatedUser: action.payload };

    default:
      return state;
  }
};
