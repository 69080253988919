/**CRUD deal ownership
 * use of firestore db, & most function are CRUD for deal ownership
 */
import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

export interface IDealOwnership {
  userInstanceId: number; //user Id number
  ProcessInstanceId: number; //deal id
  ownerStartDate: Date; //start date of owner
  ownerEndDate?: Date; //end date of owner // if owner does not have this property than they are the current owner of the deal
}

// main collection name
const deal = 'deal';

export const useDealOwnershipHistory = () => {
  /**the first time the deal has been created, add the owner with the deal history */
  const createNewDealOwnership = async (
    ProcessInstanceId: number,
    userInstanceId: number
  ) => {
    const timeStampNow: any = firebase.firestore.Timestamp.fromDate(new Date());

    const createDealOwnership: IDealOwnership = {
      userInstanceId,
      ProcessInstanceId,
      ownerStartDate: timeStampNow
    };

    // collect the ref to update
    const ref = await firebase
      .firestore()
      .collection(deal)
      .doc(`${ProcessInstanceId}`);

    ref.get().then(async (res: any) => {
      // if ownershipHistory does not exist
      if (res.data()?.ownershipHistory === undefined) {
        const dealData = {
          ...res.data(),
          ownershipHistory: [createDealOwnership]
        };

        ref.set(dealData).catch((err) => {
          console.log(
            'Failed Error: SET, did not create new deal owner within firestore',
            err
          );
          BugTracker.notify(
            'Failed Error: SET, did not create new deal owner within firestore',
            err
          );
        });
      }
    });
  }; //END createNewDealOwnership

  /**collect & filter for the dealOwnershipHistory state
   * @return {object} - you get ownerhistory list / current owner / last previous owner
   */
  const getDealOwnershipHistory = async (ProcessInstanceId: number) => {
    const ref = await firebase
      .firestore()
      .collection(deal)
      .doc(`${ProcessInstanceId}`);

    const response: any = await ref.get();

    // check if ownershipHistory exist with the deal first
    if (response.data()?.ownershipHistory) {
      const dealObject = response.data();

      if (
        dealObject?.ownershipHistory &&
        dealObject?.ownershipHistory.length > 0
      ) {
        const ownershipHistory = dealObject.ownershipHistory;

        const currentDealOwner = ownershipHistory.find(
          (ele: IDealOwnership) => !ele.ownerEndDate
        );

        const lastPreviousOwner = ownershipHistory.find(
          (ele: any) =>
            currentDealOwner?.ownerStartDate.seconds ===
            ele?.ownerEndDate?.seconds
        );

        return {
          ownershipHistory,
          currentDealOwner,
          lastPreviousOwner
        };
      }
    }

    // return 'ownership history does not exist for this deal';
    return null;
  }; //END getDealOwnershipHistory

  /**When the deal is transferred to another owner */
  const transferDealToNewOwner = async (
    ProcessInstanceId: number,
    userInstanceId: number
  ) => {
    const timeStampNow: any = firebase.firestore.Timestamp.fromDate(new Date());

    const newDealOwner: IDealOwnership = {
      userInstanceId,
      ProcessInstanceId,
      ownerStartDate: timeStampNow
    };

    // collect the ref to update
    const ref = await firebase
      .firestore()
      .collection(deal)
      .doc(`${ProcessInstanceId}`);

    ref.get().then(async (res: any) => {
      const prevOwnershipHistory = res.data().ownershipHistory;

      let allDealOwners: IDealOwnership[] = [...prevOwnershipHistory];

      // add ownerEndDate property to old owner
      allDealOwners.find((object) => {
        if (
          object?.ownerEndDate === undefined &&
          userInstanceId !== object?.userInstanceId
        ) {
          object.ownerEndDate = timeStampNow;
          return;
        }
      });

      let newOwnerAlreadyExist = true;

      // add new owner
      allDealOwners.forEach((obj) => {
        if (
          obj?.ownerEndDate === undefined &&
          userInstanceId === obj?.userInstanceId
        ) {
          newOwnerAlreadyExist = false;
        }
      });

      if (newOwnerAlreadyExist) {
        allDealOwners.push(newDealOwner);
      }

      return ref.update({ ownershipHistory: allDealOwners }).catch((err) => {
        console.log(
          'Failed Error: UPDATE, did not transfer new deal owner within firestore',
          res
        );
        BugTracker.notify(
          'Failed Error: UPDATE, did not transfer new deal owner within firestore',
          err
        );
      });
    });
  }; //END transferDealToNewOwner

  return {
    getDealOwnershipHistory,
    createNewDealOwnership,
    transferDealToNewOwner
  };
}; //END useDealOwnershipHistory
