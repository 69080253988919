import { useState } from 'react';

export const useDeviceType = () => {
  const userAgent = window.navigator.userAgent;

  let initialDeviceType = 'desktop';
  if (
    /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)
  ) {
    initialDeviceType = 'mobile';
  } else if (/iPad/i.test(userAgent)) {
    initialDeviceType = 'tablet';
  }

  const [deviceType] = useState(initialDeviceType);

  return deviceType;
};
