import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';
import styles from './styles';

interface Props {
  className?: string;
  classes?: any;
  title: string;
  amount: number;
  Icon: any;
  backgroundColor?: string;
}

const EmailTotals = (props: Props) => {
  const { classes, className, title, amount, Icon, backgroundColor } = props;
  const rootClassName = classNames(classes.root, className);

  return (
    <Paper className={rootClassName} style={{ backgroundColor }}>
      <div className={classes.content}>
        <div className={classes.details}>
          <Typography className={classes.title} variant="body2">
            {title}
          </Typography>
          <Typography className={classes.value} variant="h3">
            {amount}
          </Typography>
        </div>
        <div className={classes.iconWrapper}>
          <Icon className={classes.icon} style={{ color: backgroundColor }} />
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(EmailTotals);
