import moment from 'moment';
import {
  ELenderAPIType,
  IRequirement,
  LenderFieldDefinition
} from '../interface';

interface IValidationResult {
  error: string | null;
  isNumber: boolean;
  isDate: boolean;
  isOnBlur: boolean;
  isCurrency: boolean;
  isRequirement: boolean;
}

/**
 * Checks if a field definition includes a specific ELenderAPIType.
 *
 * @param {LenderFieldDefinition | undefined} fieldDefinition - The field definition to check.
 * @param {ELenderAPIType} type - The type to check for.
 * @returns {boolean} True if the field is of the specified type, false otherwise.
 *
 * @example
 * const isNumberField = isFieldOfType(fieldDefinition, ELenderAPIType.Number);
 */
export const isFieldOfType = (
  fieldDefinition: any,
  type: ELenderAPIType
): boolean => {
  return Array.isArray(fieldDefinition?.type)
    ? fieldDefinition?.type.includes(type)
    : fieldDefinition?.type === type;
};

/**
 * Ensures that a pattern is a RegExp object.
 *
 * @param {string | RegExp} pattern - The pattern to convert.
 * @returns {RegExp} A RegExp object.
 *
 * @example
 * const regex = ensureRegExp('^[a-z0-9]+$');
 * if (regex.test('abc123')) {
 *   console.log('Valid input');
 * }
 */
const ensureRegExp = (pattern: string | RegExp): RegExp => {
  return pattern instanceof RegExp ? pattern : new RegExp(pattern);
};

/**
 * Validates a field value based on its definition and requirements.
 *
 * @param {string} value - The value to validate.
 * @param {LenderFieldDefinition | undefined} fieldDefinition - The field definition.
 * @param {IRequirement | undefined} requirement - The field requirements.
 * @returns {IValidationResult | undefined} The validation result or undefined if no field definition.
 *
 * @example
 * const result = validateField('2023-01-01', dateFieldDefinition, dateRequirement);
 * if (result?.error) {
 *   console.error(result.error);
 * } else {
 *   console.log('Valid date');
 * }
 */
export const validateField = (
  value: string,
  fieldDefinition: LenderFieldDefinition | undefined,
  requirement: IRequirement | undefined
): IValidationResult | undefined => {
  if (!fieldDefinition) return;

  const isNumber = isFieldOfType(fieldDefinition, ELenderAPIType.Number);
  const isDate = isFieldOfType(fieldDefinition, ELenderAPIType.Date);
  const isOnBlur = isFieldOfType(fieldDefinition, ELenderAPIType.OnBlur);
  const isCurrency = isFieldOfType(fieldDefinition, ELenderAPIType.Currency);
  const isRequirement = isFieldOfType(
    fieldDefinition,
    ELenderAPIType.Requirement
  );

  let error: string | null = null;
  if (isNumber) {
    const numValue = parseFloat(value);
    if (
      requirement?.maxAmount !== undefined &&
      numValue > requirement.maxAmount
    ) {
      error = `Value must be less than or equal to ${requirement.maxAmount}`;
    }
    if (
      requirement?.minAmount !== undefined &&
      numValue < requirement.minAmount
    ) {
      error = `Value must be greater than or equal to ${requirement.minAmount}`;
    }
  }

  if (isDate) {
    if (requirement?.pattern) {
      const dateFormatRegex = ensureRegExp(
        requirement?.pattern || /^\d{4}-\d{2}-\d{2}$/
      );

      if (!dateFormatRegex.test(value)) {
        error = 'Invalid Date Format Found. Please use YYYY-MM-DD';
      } else {
        const date = moment(value, 'YYYY-MM-DD', true);
        if (!date.isValid()) {
          error = 'Invalid date. Please Enter A Valid Date.';
        } else if (requirement?.dateLength) {
          const maxDate = moment().subtract(requirement.dateLength, 'years');
          if (date.isAfter(maxDate)) {
            error = requirement.message || 'Date is too recent';
          }
        }
      }
    }
  }

  if (requirement?.pattern) {
    const regex = ensureRegExp(requirement.pattern);
    if (!regex.test(value)) {
      if (!isOnBlur) {
        error = requirement.message || 'Invalid input';
      }
    }
  }

  return { error, isNumber, isDate, isOnBlur, isCurrency, isRequirement };
};

/**
 * Validates a field on blur event.
 *
 * @param {string} value - The value to validate.
 * @param {LenderFieldDefinition | undefined} fieldDefinition - The field definition.
 * @param {IRequirement | undefined} requirement - The field requirements.
 * @returns {boolean} True if the field is valid, false otherwise.
 *
 * @example
 * const isValid = validateFieldOnBlur('abc123', fieldDefinition, requirement);
 * if (!isValid) {
 *   console.error('Invalid input on blur');
 * }
 */
export const validateFieldOnBlur = (
  value: string,
  fieldDefinition: LenderFieldDefinition | undefined,
  requirement: IRequirement | undefined
): boolean => {
  if (!fieldDefinition || !requirement?.pattern) return true;

  const isOnBlur = isFieldOfType(fieldDefinition, ELenderAPIType.OnBlur);
  if (!isOnBlur) return true;

  const regex = ensureRegExp(requirement.pattern);
  return regex.test(value);
};

/**
 * Auto-corrects a value based on a given pattern.
 *
 * @param {string} value - The value to auto-correct.
 * @param {string} pattern - The pattern to match.
 * @returns {string} The auto-corrected value.
 *
 * @example
 * const correctedValue = validateAutoCorrectValue('Hello World 123', '^[a-z0-9-]+$');
 * console.log(correctedValue); // Outputs: 'hello-world-123'
 *
 * @example
 * const correctedPhone = validateAutoCorrectValue('1234567890', '^\d{3}-\d{3}-\d{4}$');
 * console.log(correctedPhone); // Outputs: '123-456-7890'
 */
export const validateAutoCorrectValue = (
  value: string,
  pattern: string
): string => {
  const regex = new RegExp(pattern);
  if (regex.test(value)) return value;

  let correctedValue = value.toLowerCase();
  const characterClasses: { [key: string]: string } = {
    '\\d': '[0-9]',
    '\\w': '[a-zA-Z0-9_]',
    '\\s': '[ \\t\\r\\n\\f]'
  };

  Object.entries(characterClasses).forEach(([key, expanded]) => {
    pattern = pattern.replace(new RegExp(key, 'g'), expanded);
  });

  const allowedChars =
    pattern
      .match(/\[(.*?)\]/g)
      ?.map((char) => char.slice(1, -1))
      .join('') || '';

  if (allowedChars.includes('-')) {
    correctedValue = correctedValue.replace(/\s+/g, '-');
  }

  correctedValue = correctedValue
    .split('')
    .filter((char) => allowedChars.includes(char))
    .join('');

  while (!new RegExp(`^${pattern}$`).test(correctedValue)) {
    if (correctedValue.length > 1) {
      correctedValue = correctedValue.slice(0, -1);
    } else {
      break;
    }
  }

  return correctedValue;
};

export const isValidPhoneNumber = (phone: string, country: any): boolean => {
  if (!phone || !country) return false;
  const numberOnly = phone.replace(/\D/g, '');
  if (!numberOnly.startsWith(country.dialCode)) return false;

  const nationalNumber = numberOnly.slice(country.dialCode.length);
  const minLength = country.format.split('.').length - 1;
  const maxLength = country.format.replace(/[^\.]/g, '').length;

  return (
    nationalNumber.length >= minLength && nationalNumber.length <= maxLength
  );
};
