import { AlertTitle } from '@material-ui/lab';
import { makeStyles, useTheme, Typography } from '@material-ui/core';
import { useProcess } from 'hooks/useProcess';
import Alert from '@material-ui/lab/Alert';
import CanEditUserList from '../../../CanEditUserList';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Button } from '@material-ui/core';
import { Forms } from '../Forms';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column'
  }
}));

const SystemUserView = () => {
  const classes = useStyles();
  const { canviewform, objects, landingpage, user } = useProcess();
  const admin = user?.SystemAccess >= 10;
  const permission = admin || canviewform;

  return (
    <div className={classes.root}>
      <Instructions />
      <br />
      {permission && objects && <Forms />}
      {!permission && !objects && (
        <Grid item>
          {landingpage && <div>Thank you for your submission</div>}
          {!landingpage && <CanEditUserList />}
        </Grid>
      )}
    </div>
  );
};

export default SystemUserView;

export const Instructions = () => {
  const theme = useTheme();
  const { summarycontent, landingpage } = useProcess();
  const [readMore, setReadMore] = React.useState(false);

  if (summarycontent !== '') {
    // if user is within landing page show instruction as alert
    if (landingpage) {
      return (
        <div
          // severity="info"
          style={{
            backgroundColor: 'white',
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}>
          <Typography>{ReactHtmlParser(summarycontent)}</Typography>
        </div>
      );
    }

    return (
      <>
        <div
          style={{
            height: readMore ? 500 : 0,
            overflow: readMore ? 'scroll' : 'hidden',
            transition: 'all 0.3s ease-in-out'
          }}>
          {ReactHtmlParser(summarycontent)}
        </div>
        <Button
          fullWidth
          onClick={() => setReadMore(!readMore)}
          style={{
            background: theme.palette.primary.light,
            color: 'white'
          }}
          variant="contained">
          {readMore ? 'Close Instructions' : 'Open Instructions'}
        </Button>
      </>
    );
  }

  return (
    <Alert severity="info">
      <AlertTitle>No Deal Overview Found</AlertTitle>
      There are <strong>no deal overview</strong> in this deal
    </Alert>
  );
};
