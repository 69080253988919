import { gql } from '@apollo/client';

export const CREATE_FLEXIMIZE_PROPOSAL = gql`
  mutation CreateFleximizeProposal($input: CompleteFleximizeProposalInput!) {
    CreateFleximizeProposal(input: $input) {
      ... on CosneticsCreateProposalResponse {
        message
        thirdPartyId
        meta {
          _dealId
          _lender
          _status
          _proposalId
          _decision
          _targetObjectInstanceId
          _action
        }
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

export const CREATE_ALDERMORE_PROPOSAL = gql`
  mutation CreateAldermoreProposal($input: CompleteAldermoreProposalInput!) {
    CreateAldermoreProposal(input: $input) {
      ... on CosneticsCreateProposalResponse {
        message
        thirdPartyId
        meta {
          _dealId
          _lender
          _status
          _proposalId
          _decision
          _targetObjectInstanceId
          _action
        }
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

export const CREATE_INVESTEC_QUANTUM_PROPOSAL = gql`
  mutation CreateInvestec_QuantumProposal(
    $input: CompleteInvestec_QuantumProposalInput!
  ) {
    CreateInvestec_QuantumProposal(input: $input) {
      ... on CosneticsCreateProposalResponse {
        message
        thirdPartyId
        meta {
          _dealId
          _lender
          _status
          _decision
          _proposalId
          _targetObjectInstanceId
          _action
        }
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

export const CREATE_ULTIMATE_FINANCE_PROPOSAL = gql`
  mutation CreateUltimateFinanceProposal(
    $input: CompleteUltimateFinanceProposalInput!
  ) {
    CreateUltimateFinanceProposal(input: $input) {
      ... on CosneticsCreateProposalResponse {
        message
        thirdPartyId
        meta {
          _action
          _dealId
          _decision
          _lender
          _proposalId
          _status
          _targetObjectInstanceId
        }
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

export const CREATE_CAS_PROPOSAL = gql`
  mutation CreateCASProposal($input: CompleteCASProposalInput!) {
    CreateCASProposal(input: $input) {
      ... on CosneticsCreateProposalResponse {
        message
        thirdPartyId
        meta {
          _action
          _dealId
          _decision
          _lender
          _proposalId
          _status
          _targetObjectInstanceId
        }
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

export const CREATE_PRAETURA_PROPOSAL = gql`
  mutation CreatePraeturaProposal($input: CompletePraeturaProposalInput!) {
    CreatePraeturaProposal(input: $input) {
      ... on CosneticsCreateProposalResponse {
        message
        thirdPartyId
        meta {
          _action
          _dealId
          _decision
          _lender
          _proposalId
          _status
          _targetObjectInstanceId
        }
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

export const CREATE_NORTHRIDGE_PROPOSAL = gql`
  mutation CreateNorthridgeProposal($input: CompleteNorthridgeProposalInput!) {
    CreateNorthridgeProposal(input: $input) {
      ... on CosneticsCreateProposalResponse {
        message
        thirdPartyId
        meta {
          _action
          _dealId
          _decision
          _lender
          _proposalId
          _status
          _targetObjectInstanceId
        }
      }
      ... on CosneticsBadRequestResponse {
        type
        title
        status
        detail
        errors
      }
    }
  }
`;

/** UPLOADING DOCUMENTS */
export const ADD_FLEXIMIZE_DOCUMENTS_TO_PROPOSAL = gql`
  mutation AddDocumentsToFleximizeProposal($input: Fleximize_DocumentInput!) {
    AddDocumentsToFleximizeProposal(input: $input) {
      name
      reason
      status
      success
      fileId
    }
  }
`;

export const ADD_PRAETURA_DOCUMENTS_TO_PROPOSAL = gql`
  mutation AddDocumentsToPraeturaProposal($input: DocumentUpdateInput!) {
    AddDocumentsToPraeturaProposal(input: $input) {
      name
      status
      success
      reason
      fileId
    }
  }
`;

export const UPDATE_PROPOSAL_STATUS = gql`
  mutation UpdateProposalStatus($input: UpdateProposalStatusInput!) {
    UpdateProposalStatus(input: $input) {
      success
      message
    }
  }
`;

export const GENERATE_PROPOSAL_DOCUMENT = gql`
  mutation GenerateProposalDocument($input: Fleximize_DocumentInput!) {
    GenerateProposalDocument(input: $input)
  }
`;
