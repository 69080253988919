import {
  Avatar,
  CardContent,
  Grid,
  Paper,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Time from 'common/Time';
import { TKnownCategory } from 'components/MessageHub/context/interface';
import { MessageHubContext } from 'components/MessageHub/context/MessageHubContext';
import useIntersectionObserver from 'components/MessageHub/hooks/useIntersectionObserver';
import { useSenderColors } from 'components/MessageHub/hooks/useSenderColors';
import { IConversation } from 'components/MessageHub/interfaces';
import Attachments from 'components/MessageHub/utils/Attachments/DropdownAttachments';
import InlineAttachments from 'components/MessageHub/utils/Attachments/InlineAttachments';
import { useWindowDimensions } from 'helpers/windowDimensions';
import { SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { CustomThreadHover, SkeletonThread } from '.';
import { ThreadContext } from '../context/ThreadsContext';
import { KnownCategories } from './CustomFolderMessages';

const ArchivedMessages = () => {
  const { messages, selectedCategory, archived } =
    useContext(MessageHubContext);
  const {
    handleOpen,
    archivedConversations,
    selectedThread,
    openThreads,
    loadMoreMessages
  } = useContext(ThreadContext);

  const { height } = useWindowDimensions();
  const getSenderColor = useSenderColors();

  const loadMore = async (
    page: number,
    setPage: React.Dispatch<SetStateAction<number>>,
    setLoading: React.Dispatch<SetStateAction<boolean>>,
    setHasMore: React.Dispatch<SetStateAction<boolean>>
  ) => {
    await loadMoreMessages(
      'archived',
      '',
      page,
      setPage,
      setLoading,
      setHasMore
    );
  };

  const { loaderRef, loading, hasMore } = useIntersectionObserver(loadMore);
  if (!messages || selectedCategory.category !== 'Archived') return null;

  if (Object.values(archivedConversations).length <= 0) {
    if (KnownCategories.has(selectedCategory.category as TKnownCategory)) {
      return (
        <Grid
          direction="column"
          container
          style={{
            padding: theme.spacing(2)
          }}>
          <Grid item>
            <Alert
              style={{ width: '100%' }}
              severity="warning"
              variant="filled"
              elevation={4}>
              <Typography>
                The <strong>{selectedCategory.category}</strong> Folder is
                Currently Empty. No Threads to Display.
              </Typography>
            </Alert>
          </Grid>
          <Grid item>
            <div
              style={{
                maxHeight: `${height - 100}px`,
                overflowY: 'auto',
                overflowX: 'hidden'
              }}>
              <SkeletonThread amountToLoad={6} />
            </div>
          </Grid>
        </Grid>
      );
    }
  }

  const hasMessage = selectedThread && openThreads[selectedThread];
  return (
    <Grid
      container
      spacing={3}
      style={{ maxHeight: '100%', overflowY: 'auto' }}>
      <Grid item xs={12}>
        {archivedConversations &&
          (Object.values(archivedConversations) as IConversation[]).map(
            (conversation: IConversation, idx) => {
              return (
                <>
                  {idx === 0 && (
                    <div
                      style={{
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2)
                      }}>
                      <Alert severity="info">
                        <Typography style={{ fontWeight: 'bold' }}>
                          {`Exploring Your '${selectedCategory.category}' Email Collection`}
                        </Typography>
                      </Alert>
                    </div>
                  )}

                  <RenderArchivedThread
                    conversation={conversation}
                    conversations={archivedConversations}
                    hasMessage={hasMessage}
                    idx={idx}
                    handleOpen={handleOpen}
                    getSenderColor={getSenderColor}
                    archived={archived}
                  />
                </>
              );
            }
          )}
        <div
          ref={loaderRef}
          style={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2)
          }}>
          {hasMore ? (
            loading && (
              <Alert severity="info">
                <Typography style={{ fontWeight: 'bold' }}>
                  {`Fetching More '${selectedCategory.category}' Emails Threads For You... Please Hold On!`}
                </Typography>
              </Alert>
            )
          ) : (
            <Alert severity="warning">
              <Typography style={{ fontWeight: 'bold' }}>
                {`No More '${selectedCategory.category}' Emails Threads To Load.`}
              </Typography>
            </Alert>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const RenderArchivedThread = ({
  conversation,
  conversations,
  hasMessage,
  idx,
  handleOpen,
  getSenderColor,
  archived
}: {
  conversation: IConversation;
  conversations: string | { [key: string]: IConversation };
  hasMessage: '' | IConversation | null;
  idx: number;
  handleOpen: (
    conversationId: string,
    index: number,
    conversations: string | { [key: string]: IConversation }
  ) => Promise<void>;
  getSenderColor: (sender: string) => string;
  archived: any;
}) => {
  const { totalAttachments } = conversation;
  const hasAttachments = totalAttachments.length > 0;
  const parentEmailAddress = conversation.parentMessage?.from?.emailAddress;
  const parentEmailAddressName = parentEmailAddress?.name || '';
  const parentEmailAddressEmail = parentEmailAddress?.address || '';

  const renderAttachments = () => (
    <div style={{ marginLeft: '8px' }}>
      <Attachments attachments={totalAttachments} />
    </div>
  );

  const renderInlineAttachments = () => (
    <InlineAttachments attachments={totalAttachments} />
  );

  const content = (
    <CardContent>
      <Grid container spacing={2}>
        <Grid
          item
          xs={hasMessage ? 3 : 1}
          style={{
            borderRight: '1px solid #ccc',
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 30
          }}>
          <Avatar
            style={{
              backgroundColor: getSenderColor(parentEmailAddressName)
            }}>
            {parentEmailAddressName[0]}
          </Avatar>
        </Grid>
        <Grid item xs={7}>
          <Typography variant="h5" component="h2">
            {`${parentEmailAddressName} (${parentEmailAddressEmail})`}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontWeight: 'bold', paddingRight: theme.spacing(1) }}>
              Subject:
            </Typography>

            <Typography
              variant="body1"
              color="textSecondary"
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                maxWidth: hasMessage ? '300px' : 'none'
              }}>
              {conversation.parentMessage?.subject}
            </Typography>
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              variant="body1"
              color="textSecondary"
              style={{ fontWeight: 'bold', paddingRight: theme.spacing(1) }}>
              Date Received:
            </Typography>

            <Typography component={'div'} color="textSecondary">
              <Time
                time={conversation.parentMessage?.receivedDateTime}
                type="date"
              />
            </Typography>
          </div>
          {!hasMessage && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant="body1"
                color="textSecondary"
                style={{ fontWeight: 'bold', paddingRight: theme.spacing(1) }}>
                Number of Replies in Thread:
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {conversation.replies.length}
              </Typography>
            </div>
          )}
        </Grid>
        {!hasMessage && hasAttachments && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: '100%'
            }}>
            {renderInlineAttachments()}
            {renderAttachments()}
          </Grid>
        )}
      </Grid>
    </CardContent>
  );

  return CustomThreadHover({
    key: idx,
    handleOpen: () =>
      handleOpen(
        conversation.parentMessage.conversationId || '',
        idx,
        conversations
      ),
    messages: archived,
    conversation: conversation.parentMessage,
    content
  });
};

export default ArchivedMessages;
