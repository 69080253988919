import {
  EmailByProcess,
  EmailByUser,
  GetCurrentEmail,
  ResendLandingpageEmailLink
} from '../database';
import { store } from 'redux/store';
import { UPDATE_LOADING, SET_USER_DEFINITIONS } from 'redux/actions/types';
import { AxiosResponse } from 'axios';

const { dispatch } = store;

export const emailsByUser = async ({ UserInstanceId }) =>
  await EmailByUser({ UserInstanceId });

export const emailByProcess = async ({ ProcessInstanceId }) => {
  const res: AxiosResponse = await EmailByProcess({ ProcessInstanceId });
  if (res && res.headers && res.headers.auth) {
    Promise.all([
      dispatch({ type: UPDATE_LOADING, payload: false }),
      dispatch({ type: SET_USER_DEFINITIONS, payload: res.data })
    ]);
    if (res && res.status === 200) return res.data;
  }
};

export const getCurrentEmail = async ({ Id }) => {
  const res = await GetCurrentEmail({ Id });
  if (res && res.status === 200) return res.data;
};

export const resendLandingpageEmailLink = async ({
  token,
  ProcessInstanceId,
  ProcessStepDefinitionId,
  UserInstanceId
}) => {
  const res = await ResendLandingpageEmailLink({
    ProcessInstanceId,
    ProcessStepDefinitionId,
    UserInstanceId
  });
  return res;
};
