import {
  SET_DND_USERS,
  ADD_DND_USER,
  REMOVE_DND_USER,
  UPDATE_DND_USER
} from '../actions/types';
import { IContactDoNotDeal } from 'hooks/useDoNotDealNonRedFlag';
import { IDontDeal } from 'hooks/useDealList';

type DNDUser = IContactDoNotDeal | IDontDeal;
interface DNDState {
  dndusers: DNDUser[];
}
const INITIAL_STATE: DNDState = {
  dndusers: [] as DNDUser[]
};
interface SET_DND_USERS {
  type: typeof SET_DND_USERS;
  payload: DNDUser[];
}
interface ADD_DND_USER {
  type: typeof ADD_DND_USER;
  payload: DNDUser;
}
interface REMOVE_DND_USER {
  type: typeof REMOVE_DND_USER;
  payload: string;
}
interface UPDATE_DND_USER {
  type: typeof UPDATE_DND_USER;
  payload: DNDUser;
}

type DNDAction =
  | SET_DND_USERS
  | ADD_DND_USER
  | REMOVE_DND_USER
  | UPDATE_DND_USER;

export default (state = INITIAL_STATE, action: DNDAction) => {
  switch (action.type) {
    case SET_DND_USERS:
      return { ...state, dndusers: action.payload };
    case ADD_DND_USER:
      return { ...state, dndusers: [...state.dndusers, action.payload] };
    case REMOVE_DND_USER:
      return {
        ...state,
        dndusers: state.dndusers.filter((user) =>
          'email' in user
            ? user.email !== action.payload
            : user.company_number !== action.payload
        )
      };
    case UPDATE_DND_USER:
      return {
        ...state,
        dndusers: state.dndusers.map((user) =>
          ('email' in user &&
            'email' in action.payload &&
            user.email === action.payload.email) ||
          ('company_number' in user &&
            'company_number' in action.payload &&
            user.company_number === action.payload.company_number)
            ? action.payload
            : user
        )
      };
    default:
      return state;
  }
};
