import { IAddressApiFullAddress } from 'types/address';

export const normalizeIdealPostcodeData = (
  data: any
): IAddressApiFullAddress => {
  return {
    line_1: data.line_1,
    line_2: data.line_2,
    line_3: data.line_3,
    postcode: data.postcode,
    postal_town: data.post_town,
    thoroughfare: data.thoroughfare,
    building_number: data.building_number,
    building_name: data.building_name,
    organization_name: data.organisation_name,
    location: {
      lat: data.latitude,
      lng: data.longitude
    },
    country: data.country,
    county: data.county
  };
};

export const normalizeGoogleData = (data: any): IAddressApiFullAddress => {
  const addressInfo: { [key: string]: string } = {};

  data.address_components.forEach((component) => {
    const key = component.types[0];
    addressInfo[key] = component.long_name;
  });

  return {
    line_1:
      `${addressInfo['street_number']} ${addressInfo['route']}`.trim() || '',
    line_2: addressInfo['locality'] || '',
    line_3: addressInfo['administrative_area_level_1'] || '',
    postcode: addressInfo['postal_code'] || '',
    postal_town: addressInfo['postal_town'] || '',
    thoroughfare: addressInfo['route'] || '',
    building_number: addressInfo['street_number'] || '',
    building_name: addressInfo['premise'] || '',
    organization_name: addressInfo['establishment'] || '',
    location: {
      lat: data.geometry.location.lat,
      lng: data.geometry.location.lng
    },
    country: addressInfo['country'] || '',
    county: addressInfo['administrative_area_level_2'] || ''
  };
};
