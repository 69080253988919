import {
  CompleteObjectDefinition,
  CompleteProcessDefinition,
  CompleteProcessInstance,
  CompleteProcessStepDefinition
} from 'types/interfaces';

import { getFieldValueWithFieldDefinitionTitle } from 'components/Stepper/functions';

export const sortGroups: ({
  currentProcess,
  grouped,
  stepdefdict,
  currentDeal
}: {
  currentProcess: CompleteProcessDefinition;
  grouped: CompleteObjectDefinition[];
  stepdefdict: CompleteProcessStepDefinition;
  currentDeal: CompleteProcessInstance;
}) => {
  groups: { [group_name: string]: CompleteObjectDefinition[] };
  groupObjectDefinitionIdList: number[];
} = ({ currentProcess, grouped, stepdefdict, currentDeal }) => {
  // What is the Deal Name
  // const { Title } = currentProcess.ProcessDefinition;

  // This gets the deal title from a Deal Type Field (Should be able to abstract and use global helper method)
  let Title = getFieldValueWithFieldDefinitionTitle({
    stepdefdict,
    currentDeal,
    FieldDefinitionTitle: 'Deal Type'
  });

  // END: GRAB DEAL TYPE FUNCTION

  let groups = {} as { [group_name: string]: CompleteObjectDefinition[] };
  let groupObjectDefinitionIdList = [] as number[];
  grouped.forEach((item: CompleteObjectDefinition) => {
    const split = item?.ObjectDefinition?.ObjectDescription.split(',');

    // first look for Deal=<DealTitle> and see if it include the Process Instance Title
    const deal_name_unsplit: string[] = split.filter((e) =>
      e.includes('&Deal=')
    );

    // if it contains any then get the one that matches the current deal
    if (deal_name_unsplit.length > 0) {
      const matchesCurrentDeal: string | undefined = deal_name_unsplit.find(
        (el: string) => el.toUpperCase().includes(Title.toUpperCase())
      );

      if (matchesCurrentDeal) {
        const after_hash = matchesCurrentDeal?.split('#')[1];
        const group_name = after_hash?.split('&Deal=')[0];

        if (group_name) {
          if (!groups[group_name]) groups[group_name] = [];
          groups[group_name].push(item);
          groupObjectDefinitionIdList.push(item.ObjectDefinition.Id);
        }
      }
    } else {
      // Does not have a matching &Deal=
      const group_name_unsplit = split.find((e) => e.includes('group'));
      const group_name = group_name_unsplit?.split('#')[1];
      if (group_name) {
        if (!groups[group_name]) groups[group_name] = [];
        groups[group_name].push(item);
      }
    }
  });
  return { groups, groupObjectDefinitionIdList };
};
