import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { BsFilter } from 'react-icons/bs';

import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  Fade,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1)
  },
  textColour: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

export const CommissionSortUI = ({ openBooleans, setOpenBooleans }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const currentSelectedCheckBox = () => {
    if (openBooleans.HighestToLowest) return 'Highest To Lowest (Commission)';
    else if (openBooleans.LowestToHighest)
      return 'Lowest To Highest (Commission)';
    else if (openBooleans.LastModified) return 'Last Modified (Time)';
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenBooleans((s) => {
      return { ...s, open: true };
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenBooleans((s) => {
      return { ...s, open: false };
    });
  };

  const handleSorting = (name?: string) => {
    switch (name) {
      case 'Highest':
        setOpenBooleans(
          Object.keys(openBooleans).reduce((object: any, name: string) => {
            return { ...object, [name]: 'HighestToLowest' === name };
          })
        );
        break;
      case 'Lowest':
        setOpenBooleans(
          Object.keys(openBooleans).reduce((object: any, name: string) => {
            return { ...object, [name]: 'LowestToHighest' === name };
          })
        );
        break;
      case 'LastModified':
        setOpenBooleans(
          Object.keys(openBooleans).reduce((object: any, name: string) => {
            return { ...object, [name]: 'LastModified' === name };
          })
        );
        break;
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center">
      <Grid item>
        <Typography color="primary" className={classes.textColour}>
          {currentSelectedCheckBox()}
        </Typography>
      </Grid>
      <Grid item className={classes.root}>
        <IconButton
          aria-controls={openBooleans.open ? 'fade-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openBooleans.open ? 'true' : undefined}
          onClick={handleClick}>
          <BsFilter
            style={{
              width: 35,
              height: 35
            }}
          />
        </IconButton>
      </Grid>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={openBooleans.open}
        onClose={handleClose}
        TransitionComponent={Fade}>
        <FormGroup>
          <MenuItem onClick={() => handleSorting('Highest')}>
            <FormControlLabel
              control={<Checkbox checked={openBooleans.HighestToLowest} />}
              label="Highest To Lowest (Commission)"
            />
          </MenuItem>
          <MenuItem onClick={() => handleSorting('Lowest')}>
            <FormControlLabel
              control={<Checkbox checked={openBooleans.LowestToHighest} />}
              label="Lowest To Highest (Commission)"
            />
          </MenuItem>
          <MenuItem onClick={() => handleSorting('LastModified')}>
            <FormControlLabel
              control={<Checkbox checked={openBooleans.LastModified} />}
              label="Last Updated (Time)"
            />
          </MenuItem>
        </FormGroup>
      </Menu>
    </Grid>
  );
};
