import {
  IStringQuery,
  INumberQuery,
  IDirectorSearchCriteria,
  ERfaRating,
  ICompanySearchCriteria
} from '../interfaces';

const isStringQuery = (query): query is IStringQuery => {
  return query && typeof query.eq === 'string';
};

const isNumberQuery = (query): query is INumberQuery => {
  return query && typeof query.eq === 'number';
};

const isRfaRating = (value): value is ERfaRating => {
  return Object.values(ERfaRating).includes(value);
};

const cleanSearchCriteria = (
  criteria: IDirectorSearchCriteria | ICompanySearchCriteria
): IDirectorSearchCriteria => {
  const cleanedCriteria = {} as
    | IDirectorSearchCriteria
    | ICompanySearchCriteria;

  Object.entries(criteria).forEach(([key, value]) => {
    if (key === 'rfa_rating' && isRfaRating(value)) {
      cleanedCriteria[key] = value;
    } else if (isStringQuery(value)) {
      if (value.eq !== '') {
        cleanedCriteria[key] = value as IStringQuery;
      }
    } else if (isNumberQuery(value)) {
      if (value.eq !== undefined) {
        cleanedCriteria[key] = value as INumberQuery;
      }
    }
  });

  return cleanedCriteria;
};

const hasSearchCriteria = (
  criteria: IDirectorSearchCriteria | ICompanySearchCriteria
): boolean => {
  return Object.values(criteria).some((value) => {
    if (typeof value === 'object' && value !== null) {
      return value.hasOwnProperty('eq') && value.eq !== '';
    }
    return false;
  });
};

export { cleanSearchCriteria, hasSearchCriteria };
