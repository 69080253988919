import { gql } from '@apollo/client';

/**
 * GraphQL query to get fraud check results for a specific collection.
 * @typedef {Object} GetFraudResults
 * @property {string} collectionId - The ID of the collection to check for fraud.
 *
 * @typedef {Object} GetFraudResultsData
 * @property {Object} getFraudResults - The fraud check results.
 * @property {string} getFraudResults.uuid - The unique identifier of the fraud check.
 * @property {string} getFraudResults.result - The overall result of the fraud check.
 * @property {Array<Object>} getFraudResults.documents - List of documents checked for fraud.
 *
 * @type {import('@apollo/client').DocumentNode}
 */
export const GET_FRAUD_RESULTS = gql`
  query GetFraudResults($collectionId: ID!) {
    getFraudResults(collectionId: $collectionId) {
      uuid
      result
      documents {
        name
        type
        result
      }
    }
  }
`;

/**
 * GraphQL query to get account data results for a specific collection.
 * @typedef {Object} GetDataResults
 * @property {string} collectionId - The ID of the collection to retrieve account data for.
 *
 * @typedef {Object} GetDataResultsData
 * @property {Object} getDataResults - The account data results.
 * @property {string} getDataResults.status - The status of the account data retrieval.
 * @property {Array<Object>} getDataResults.accounts - List of accounts and their details.
 *
 * @type {import('@apollo/client').DocumentNode}
 */
export const GET_DATA_RESULTS = gql`
  query GetDataResults($collectionId: ID!) {
    getDataResults(collectionId: $collectionId) {
      status
      accounts {
        uuid
        identifiers {
          number
          sortcode
          iban
          swift
          bank
        }
        balance
        transactions {
          date
          description
          amount
          running_balance
        }
      }
    }
  }
`;
