import axios from 'axios';

import { firebase } from 'redux/firebase';
import { BugTracker } from 'Utils/Bugtracker';

export const fetchCampaign = async (id: number) => {
  const token = await firebase.auth().currentUser?.getIdToken();

  const url = `${process.env.REACT_APP_PROXY}/marketing/campaign/${id}`;
  const config = { headers: { token } };

  const res = await axios.get(url, config).catch((e) => {
    BugTracker.notify(e);
    console.log('fetchCampaign Error: ', { e });
    throw e;
  });

  if (!res?.data) return {};

  return res.data;
};
