import { store } from 'redux/store';
import { UPDATE_LOADING } from 'redux/actions/types';
import {
  GetAllSummaries,
  GetCurrentAudit,
  GetAuditByProcessId,
  GetAuditByUser
} from '../database';
import { BugTracker } from 'Utils/Bugtracker';

const { dispatch } = store;

export const getAllSummaries = async ({ token }) => {
  dispatch({ type: UPDATE_LOADING, payload: true });

  const res = await GetAllSummaries({ token });
  if (res) {
    dispatch({ type: UPDATE_LOADING, payload: false });

    await dispatch({ type: 'SET_AUDIT_SUMMARIES', payload: res.data });

    if (res && res.status === 200) return Boolean(res.data);
  }

  if (!res) {
    // console.log('Your account is being used on another device');
  }
};

export const getCurrentAudit = ({ token, Id }) => {
  // dispatch({ type: UPDATE_LOADING, payload: true });
  return GetCurrentAudit({ token, Id })
    .then((res) => {
      // console.log('getCurrentAudit', { res });
      // dispatch({ type: UPDATE_LOADING, payload: false });

      // await dispatch({ type: 'SET_AUDIT_SUMMARIES', payload: res.data });
      if (res && res.status === 200) return res.data;
    })
    .catch((e) => {
      // console.log({ e });
      BugTracker.notify(e);
    });
};

export const getAuditByProcessId = async ({ token, ProcessInstanceId }) => {
  dispatch({ type: UPDATE_LOADING, payload: true });
  const res = await GetAuditByProcessId({ token, ProcessInstanceId });
  if (res) {
    dispatch({ type: UPDATE_LOADING, payload: false });

    if (res && res.status === 200) return res.data;
  }
  if (!res) {
    // console.log('Your account is being used on another device');
  }
};

export const getAuditByUser = async ({ token, UserInstanceId }) => {
  return await GetAuditByUser({ token, UserInstanceId });
};
