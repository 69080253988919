import { AddressFinder } from '@ideal-postcodes/address-finder';
import { InputBase } from '@material-ui/core';
import axios from 'axios';
import { UdprnLookup_API } from 'components/Address/functions';
import { normalizeIdealPostcodeData } from 'components/Address/functions/apiMiddleware';
import { SetStateAction, useEffect, useRef, useState } from 'react';

type HandleUpdateFieldType =
  | void
  | ((val: string, Title?: string) => Promise<any>);

const EditableAddress = ({
  value,
  onChange,
  setEdit,
  edit
}: {
  value: string;
  onChange: HandleUpdateFieldType;
  setEdit: React.Dispatch<SetStateAction<boolean>>;
  edit: boolean;
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [currentValue, setCurrentValue] = useState(value);

  const retrieveUDPRNData = async (address) => {
    axios(await UdprnLookup_API(address.udprn)).then((response) => {
      if (response) {
        const idealPostcodeData = response.data.Address.result;
        const address = normalizeIdealPostcodeData(idealPostcodeData);

        // Update Field
        const locationString = [
          address.line_1,
          address.line_2,
          address.postal_town,
          address.postcode,
          address.country
        ]
          .filter(Boolean)
          .join(', ');
        if (typeof onChange === 'function') {
          onChange(locationString);
        }
        setEdit(false);
      }
    });
  };

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement> | KeyboardEvent
  ) => {
    if (e.key === 'Enter') {
      const value = inputRef.current ? inputRef.current.value : '';
      if (typeof onChange === 'function') {
        onChange(value);
      }
      setEdit(false);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      AddressFinder.setup({
        inputField: inputRef.current,
        apiKey: process.env.REACT_APP_ADDRESS_API_KEY || '',
        onAddressSelected: (address) => retrieveUDPRNData(address),
        containerStyle: {
          width: '100%'
        },
        onKeyDown: (e) => handleKeyPress(e)
      });
    }
  }, []);

  return (
    <InputBase
      autoFocus
      fullWidth
      defaultValue={value}
      inputProps={{ color: 'red' }}
      inputRef={inputRef}
    />
  );
};

export default EditableAddress;
