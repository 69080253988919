import { Broker } from 'components/Marketing/types';

const defaultDisclaimers = {
  afs: `<div style="color:grey;font-size:0.8rem;text-align:center;width:100%;"><p style="margin:10px auto;max-width:600px;">AFS is an independent asset finance brokerage not a lender, as such we can introduce you to a wide range of finance providers depending on your requirements and circumstances. We are not independent financial advisors and so are unable to provide you with independent financial advice. AFS will receive payment(s) in the form of commission from the finance provider if you decide to enter into an agreement with them, these payments are factored into the interest rate you pay.</p>
<p>AFS is an Appointed Representative of AFS Compliance Limited which is Authorised and Regulated by the Financial Conduct Authority No. 625035</p>
</div>`,
  synergy:
    '<div style="color:grey;font-size:0.8rem;text-align:center;width:100%;"><p style="margin:10px auto;max-width:600px;">Synergy is an independent commercial finance broker not a lender, as such we can introduce you to a wide range of finance providers depending on your requirements and circumstances. We are not independent financial advisors and so are unable to provide you with independent financial advice. Synergy will receive payment(s) in the form of commission from the finance provider if you decide to enter into an agreement with them, these payments are factored into the interest rate you pay. Synergy is an appointed representative of AFS Compliance Ltd which is authorised and regulated by the Financial Conduct Authority under number 625035.</p></div>'
};

export const injectEmailDisclaimer = ({
  disclaimerHtml,
  emailHtml,
  broker
}: {
  disclaimerHtml?: string;
  emailHtml: string;
  broker: Broker;
}): string => {
  // each individual broker can create their own discliamer in the admin panel which is available on user.user.EmailFooter, or we default to a generic one:
  const defaultDisclaimer = defaultDisclaimers[broker.toLowerCase()];

  const disclaimer = disclaimerHtml || defaultDisclaimer;

  // Use a regular expression to find the unsubscribe module (data-role="module-unsubscribe")
  const unsubscribeDivRegex =
    /(<div[^>]*data-role=["']module-unsubscribe["'][^>]*>)/i;

  // Check if the unsubscribe div exists
  if (unsubscribeDivRegex.test(emailHtml)) {
    // Insert the disclaimerHtml above the unsubscribe div
    const combinedHtml = emailHtml.replace(
      unsubscribeDivRegex,
      disclaimer + '$1'
    );

    return combinedHtml;
  }

  return '';
};
