/**badge (using chip component) of users. Also click & open dialog summary of user */
import React, { useEffect, useRef } from 'react';
import {
  Box,
  Chip,
  Tooltip,
  createStyles,
  makeStyles,
  Typography
} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import UserDialog from './UserDialog';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import { validateEmail } from 'helpers/validateEmail';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTheme } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTypedSelector } from 'redux/reducers';
import { UserInstance } from 'types/interfaces';
import { TextSlide } from 'common/Animations';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      transition: 'opacity 0.5s ease, transform 0.5s ease',
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      backdropFilter: 'blur(10px)',
      borderRadius: 20,
      padding: 1,
      maxWidth: 320,
      overflow: 'auto',

      // Hide scrollbar while maintaining functionality
      '&::-webkit-scrollbar': {
        display: 'none' // Chrome, Safari, Opera
      },
      scrollbarWidth: 'none', // Firefox
      msOverflowStyle: 'none', // IE/Edge

      // Glass effect styling
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      border: '1px solid rgba(255, 255, 255, 0.18)',

      // Prevent horizontal scrolling
      overflowX: 'hidden'
    },
    chip: {
      display: 'flex',
      justifyContent: 'flex-start',
      width: '100%',
      height: 40,
      minWidth: 300,
      maxWidth: '100%', // Add this to prevent overflow
      transition: 'max-width 1s ease-in-out',
      overflow: 'hidden' // Add this to prevent content overflow
    },
    deleteIcon: {
      marginLeft: 'auto' // Pushes the delete icon to the right
    },
    chipLabel: {
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
      transition: 'opacity 0.5s ease, transform 0.5s ease',
      width: '100%', // Full width
      overflow: 'hidden',
      whiteSpace: 'nowrap'
    },
    '@keyframes scrollToEndAndBack': {
      '0%': { transform: 'translateX(0%)' },
      '50%': { transform: 'translateX(-50%)' }, // Assuming the text width is double the container width
      '100%': { transform: 'translateX(0%)' }
    },
    scrollingText: {
      display: 'inline-block',
      animation: `$scrollToEndAndBack 5s linear infinite` /* Adjust time as needed */
    }
  })
);

interface IProps {
  deletable?: boolean;
  deleteFunction?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  HandleLandingPageUser?: (user: UserInstance) => void | undefined;
  handleParentClickEvent?: (event: React.MouseEvent<HTMLDivElement>) => void;
  loginUser?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  user: UserInstance;
}

const UserLozenge = ({
  deletable,
  deleteFunction,
  HandleLandingPageUser,
  handleParentClickEvent,
  loginUser = false,
  setEdit,
  user
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const token = useTypedSelector((s) => s.user.auth.token);
  const [open, setOpen] = React.useState(false);

  const [userIcon, setUserIcon] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(false);
  const [shouldScroll, setShouldScroll] = React.useState(false);

  // Refs
  const chipLabelRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const secondaryTextRef = useRef<HTMLDivElement>(null);

  const { UserDefinitionList } = useTypedSelector((s) => s.config.settings);

  const UserDefinition =
    UserDefinitionList?.find((i) => i?.Id === user?.UserDefinitionId) ?? null;

  const isCompany = UserDefinition?.Description?.includes('company');
  const isValid =
    (!isCompany && validateEmail(user?.UserInstanceEmail)) || isCompany;

  const DeleteIconComp = ({ icon }: { icon: boolean }) => {
    if (deletable) {
      return <CloseIcon />;
    }

    if (isValid || (HandleLandingPageUser && HandleLandingPageUser(user))) {
      if (icon && loginUser) return <DeleteIcon />;
      return <VerifiedUserIcon style={{ color: theme.palette.success.main }} />;
    } else {
      if (icon && loginUser) {
        return (
          <Tooltip title="Unverified email address">
            <DeleteIcon />
          </Tooltip>
        );
      }
      return (
        <Tooltip title="Unverified email address">
          <CancelIcon style={{ color: theme.palette.error.main }} />
        </Tooltip>
      );
    }
  };

  const src =
    user?.ProfilePicture !== '' ? (
      <Avatar src={user?.ProfilePicture} />
    ) : isCompany ? (
      <Avatar>
        <BusinessIcon style={{ fontSize: 15 }} />
      </Avatar>
    ) : (
      <Avatar
        src={user?.Title?.split(' ')
          .map((n: string) => n[0])
          .join('')
          .toUpperCase()}
      />
    );

  const handleClick = async (event: React.MouseEvent<HTMLDivElement>) => {
    handleParentClickEvent && handleParentClickEvent(event);
    event.stopPropagation();
    setOpen(true);
    setEdit?.(false);
  };

  // Slide effect for the secondary text
  const secondaryText = [
    user?.UserInstanceEmail?.split('-')[0] || 'Email Address',
    user?.TelephoneNumber?.split('-')[0] || '+44 (0) XXXX XXX XXX'
  ];
  const { opacity, currentIndex, transform } = TextSlide(secondaryText);
  // END

  useEffect(() => {
    const chipLabelWidth = chipLabelRef.current?.offsetWidth ?? 0;
    const textWidth = textRef.current?.scrollWidth ?? 0;
    setShouldScroll(textWidth > chipLabelWidth);
  }, [user?.NickName || user?.Title]);

  return (
    <Box
      className={classes.container}
      style={{
        overflowX: 'hidden',
        position: 'relative',
        width: '100%'
      }}>
      <Chip
        className={classes.chip}
        onClick={handleClick}
        avatar={src}
        label={
          <div className={classes.chipLabel} ref={chipLabelRef}>
            <Typography
              className={shouldScroll ? classes.scrollingText : ''}
              ref={textRef}>
              {user?.NickName || user?.Title}
            </Typography>
            <Typography
              ref={secondaryTextRef}
              variant="caption"
              style={{
                fontSize: 10,
                opacity: opacity,
                transform: transform,
                transition: 'opacity 0.5s ease, transform 0.5s ease'
              }}>
              {secondaryText[currentIndex]}
            </Typography>
          </div>
        }
        clickable
        deleteIcon={
          <div className={classes.deleteIcon}>
            <DeleteIconComp icon={userIcon} />
          </div>
        }
        onMouseEnter={() => setUserIcon(true)}
        onMouseLeave={() => setUserIcon(false)}
        onDelete={(e: React.MouseEvent<SVGSVGElement, MouseEvent>) =>
          deleteFunction && deleteFunction(e)
        }
      />
      {open && (
        <UserDialog
          user={user}
          open={open}
          setOpen={setOpen}
          setEdit={setEdit}
          loading={loading}
          setLoading={setLoading}
          showOnlyHistoryButton
        />
      )}
    </Box>
  );
}; //END Component

export default UserLozenge;
