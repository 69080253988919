import { Button } from '@material-ui/core';
import createICalendarContent from 'components/Calendar/functions/ICS';
import { IEvent } from 'components/Calendar/interface';
import * as gtag from 'Utils/gtag';
import { CloudDownload } from '@material-ui/icons';
import { theme } from 'theme';
import { grey } from '@material-ui/core/colors';

const DownloadCalendar = ({ event }: { event: IEvent }) => {
  const handleDownloadClick = () => {
    const calendarContent = createICalendarContent(event);
    const element = document.createElement('a');

    const file = new Blob([calendarContent], { type: 'text/calendar' });
    element.href = URL.createObjectURL(file);
    element.download = `${event.title}.ics`;
    document.body.appendChild(element);
    element.click();

    gtag.event({
      action: 'Event Download',
      feature: 'CALENDAR',
      message: 'Downloading event to calendar'
    });
  };

  return (
    <Button
      fullWidth
      onClick={handleDownloadClick}
      variant="outlined"
      startIcon={
        <CloudDownload
          fontSize="large"
          style={{
            paddingRight: theme.spacing(1),
            color: grey[500],
            width: '30px'
          }}
          color="secondary"
        />
      }>
      Download to calendar
    </Button>
  );
};

export default DownloadCalendar;
