/**parent badge (using chip component) of users. Also click & view summary of user */
import React, { useEffect, useRef, useState } from 'react';
import { UserInstance } from 'types/interfaces';
import QuickUser from './UserLozenge';
import { LinearProgress, Typography } from '@material-ui/core';
import { useTypedSelector } from 'redux/reducers';
import { getQuickLiteUser } from 'redux/actions/GraphQlActions';

// useQuery test imports
import { useQuery } from '@apollo/client';
import { GET_COMPLETE_USER_INSTANCE_DETAIL } from 'graphql/UserInstanceAPI/GetUserInstanceDetail/queries';
import { apolloClient } from 'graphql/apolloClient';

interface IProps {
  deletable?: boolean;
  deleteFunction?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
  HandleLandingPageUser?: (user) => void;
  handleParentClickEvent?: (event: React.MouseEvent<HTMLDivElement>) => void;
  loginUser?: boolean;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  UserInstanceId: string;
}

const QuickUserFromId = (props: IProps) => {
  const baseUrl = useTypedSelector((s) => s.config.baseURL);

  const {
    deletable,
    deleteFunction,
    HandleLandingPageUser,
    handleParentClickEvent,
    loginUser,
    setEdit,
    UserInstanceId
  } = props;

  // Implementing Apollo useQuery instead of useEffect
  const { data, error, loading } = useQuery(GET_COMPLETE_USER_INSTANCE_DETAIL, {
    variables: { baseUrl, userInstanceId: parseInt(UserInstanceId) },
    client: apolloClient
  });
  const user = data?.GetUserInstanceDetail?.UserInstance;
  // END

  if (error || loading) return <LinearProgress variant="indeterminate" />;
  return (
    <QuickUser
      deletable={deletable}
      deleteFunction={deleteFunction}
      HandleLandingPageUser={HandleLandingPageUser}
      handleParentClickEvent={handleParentClickEvent}
      loginUser={loginUser}
      setEdit={setEdit}
      user={user}
    />
  );
};

export default QuickUserFromId;

export const useUserFromId = ({
  UserInstanceId,
  baseUrl
}: {
  UserInstanceId: number;
  baseUrl: string;
}) => {
  const [user, setUser] = useState<UserInstance>({} as UserInstance);

  useEffect(() => {
    const getUser = async () => {
      const response = await getQuickLiteUser({
        baseUrl,
        UserInstanceId,
        action: 'GET'
      });

      const UserInstance = response?.UserInstance;
      if (UserInstance) setUser(UserInstance);
    };

    getUser();
  }, [UserInstanceId]);

  return user;
};
